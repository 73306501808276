import { Management } from '../../../api-models/management'
import { BREAK_POINTS } from '../../../theme_new/dimensions'
import { lg, md, sm } from '../../../theme'

export const getInitialState = () => {
  return {
    expandedIndex: undefined,
    selectedIndex: undefined,
    loadingUserList: false,
  }
}

export const sortVesselList = (
  vesselA: Management.Vessel.VesselRegistry,
  vesselB: Management.Vessel.VesselRegistry,
) => {
  const sortParamA = vesselA.vesselName.toLocaleLowerCase()
  const sortParamB = vesselB.vesselName.toLocaleLowerCase()
  return sortParamA.localeCompare(sortParamB)
}

export const getStyleSizeFromPropName = (propName: string): number => {
  let screenSize
  if (window.innerWidth > BREAK_POINTS.large) {
    screenSize = lg
  } else if (window.innerWidth > BREAK_POINTS.medium) {
    screenSize = md
  } else {
    screenSize = sm
  }

  return parseInt(screenSize[propName])
}

export const getSelectedVesselIndexFromParams = (
  filteredUserList: Array<Management.Vessel.VesselRegistry>,
  imoNo: number,
): number | undefined => {
  const selectedVesselIndex = filteredUserList
    ? filteredUserList.findIndex((vessel) => vessel.imoNo === imoNo)
    : undefined

  return selectedVesselIndex === -1 ? undefined : selectedVesselIndex
}
