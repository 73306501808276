import styled from 'styled-components'

export const StockContainer = styled.div`
  padding: 0 0 0 44px;
  margin-top: 16px;
`

export const InfoText = styled.div`
  color: #666e79;
  font-size: 14px;
  line-height: 17px;
  margin: 20px 16px 0;
`

export const BatchSelectionWrapper = styled.div`
  position: relative;
  padding: 16px;
  margin-bottom: 16px;
`

export const StockContainerWrapper = styled.div`
  position: relative;
  padding: 16px;
  margin-bottom: 16px;
`

export const FuelTotalsContainerWrapper = styled.div`
  position: relative;
  padding: 16px;
`

export const Label = styled.div`
  color: #666e79;
  font-weight: bold;
  position: absolute;
  transform: rotate(-90deg);
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
`

export const ServiceTanks = styled(Label)`
  left: -56px;
  top: 102px;
`

export const StorageTanks = styled(Label)`
  left: 3px;
  top: 53px;
`

export const FuelTypeTotal = styled(Label)`
  left: -4px;
  top: 45px;
`

export const ButtonBanner = styled.div`
  display: flex;
  padding: 16px 16px 8px;
  background-color: ${(props) => props.theme.colors.grey[200]};
  border-top: 1px solid ${(props) => props.theme.colors.grey[300]};

  mc-button {
    white-space: nowrap;
  }

  > mc-button:last-child {
    margin-left: auto;
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  > mc-button {
    margin: 0 16px 8px 0;
  }
`

export const ConfirmRecovery = styled.div`
  display: flex;
  align-items: center;

  svg {
    color: #e0280f;
    margin-right: 16px;
  }

  white-space: pre-wrap;
  max-width: 450px;
`
