import { useContext } from 'react'
import { useField } from 'formik'
import { McSelectNative } from '@maersk-global/mds-react-wrapper'

import { WindowContext } from '../../contexts'
import { type TWindowSize } from '../../theme_new/styled'

type Props = {
  name: string
  id: string
  label: string
  placeholder?: string
  fit?: TWindowSize
  options: Array<{
    value: string | number
    label: string
  }>
  hideErrorMessage?: boolean
}

/**
 * @description A thin wrapper for Select that provides two-way data binding
 * for Formik form values based on `name` prop
 */
export const FormSelect = (props: Props) => {
  const { windowSize } = useContext(WindowContext)
  const [field, meta, helpers] = useField(props.name)
  const { options, fit, ...rest } = props

  const handleChange = ({ detail }: CustomEvent) => {
    if (detail.selectedOptions[0] === undefined) {
      helpers.setValue(null)
      return
    }

    /*
     * The McSelect component only works with string options, so we need to
     * determine if given options are numbers or strings and cast selected
     * option to number if needed.
     */
    const areOptionsNumeric = typeof options[0].value === 'number'
    const value = detail.selectedOptions[0].value

    helpers.setValue(areOptionsNumeric ? +value : value)
  }

  /*
   * Transform the value properties of options to strings in order to work
   * with McSelect component.
   */
  const _options = options.map((option) => ({
    ...option,
    value: String(option.value),
  }))

  /*
   * Determine the selected option (if any) from the field value of the form and
   * put it into an array (the format needed for McSelect component).
   */
  const selectedOption = _options.find(
    (option) => option.value === String(field.value),
  )

  const _selectedOption = selectedOption ? [selectedOption] : []

  return (
    <McSelectNative
      {...rest}
      fit={fit ?? windowSize}
      options={_options}
      change={handleChange}
      value={_selectedOption}
      invalid={meta.touched && !!meta.error}
      errormessage={
        !props.hideErrorMessage && meta.touched ? meta.error : undefined
      }
    />
  )
}
