import { getDifference } from './EnginePerformanceTestReportsComparison.utils'
import { formatValue } from '../../../utils'

type Props = {
  reportsCompared: Array<GandalfApi.MetcReport>
}

const Fuel = ({ reportsCompared }: Props) => {
  const a = reportsCompared[0].data
  const b = reportsCompared[1].data

  return (
    <>
      <tr>
        <th>Fuel</th>
        <th />
        <th />
        <th />
      </tr>
      <tr>
        <td>Fuel batch</td>
        {reportsCompared.map((rc) => (
          <td key={rc.reportId} className='mds-table__cell--text-right'>
            {rc.data.general.batchName}
          </td>
        ))}
        <td className='mds-table__cell--number'>-</td>
      </tr>
      <tr>
        <td>Fuel LCV [kJ/kg]</td>
        {reportsCompared.map((rc) => (
          <td key={rc.reportId} className='mds-table__cell--number'>
            {formatValue(rc.data.general.batchLcv, 0)}
          </td>
        ))}
        <td className='mds-table__cell--number'>
          {getDifference(a.general.batchLcv!, b.general.batchLcv!)}
        </td>
      </tr>
      <tr>
        <td>Fuel CCAI</td>
        {reportsCompared.map((rc) => (
          <td key={rc.reportId} className='mds-table__cell--number'>
            {formatValue(rc.data.general.batchCcai.value, 0)}
          </td>
        ))}
        <td className='mds-table__cell--number'>
          {getDifference(
            a.general.batchCcai.value!,
            b.general.batchCcai.value!,
          )}
        </td>
      </tr>
      <tr>
        <td>Fuel consumption [kg]</td>
        {reportsCompared.map((rc) => (
          <td key={rc.reportId} className='mds-table__cell--number'>
            {formatValue(rc.data.general.consumedMass.value, 0)}
          </td>
        ))}
        <td className='mds-table__cell--number'>
          {getDifference(
            a.general.consumedMass.value!,
            b.general.consumedMass.value!,
          )}
        </td>
      </tr>
      <tr>
        <td>SFOC measured [g/kWh]</td>
        {reportsCompared.map((rc) => (
          <td key={rc.reportId} className='mds-table__cell--number'>
            {formatValue(rc.data.result.general.sfoc, 2)}
          </td>
        ))}
        <td className='mds-table__cell--number'>
          {getDifference(a.result.general.sfoc!, b.result.general.sfoc!)}
        </td>
      </tr>
      <tr>
        <td>SFOC LCV Corrected to 42700 kg/kJ [g/kWh]</td>
        {reportsCompared.map((rc) => (
          <td key={rc.reportId} className='mds-table__cell--number'>
            {formatValue(rc.data.result.general.sfocLcvCorrected, 2)}
          </td>
        ))}
        <td className='mds-table__cell--number'>
          {getDifference(
            a.result.general.sfocLcvCorrected!,
            b.result.general.sfocLcvCorrected!,
          )}
        </td>
      </tr>
    </>
  )
}

export default Fuel
