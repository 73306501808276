import { useContext, useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { McButton } from '@maersk-global/mds-react-wrapper'

import { EStockNotificationType } from '../../../api-models/performance/common'
import { ContentCard } from '../../../commons'
import Notifications from '../../../components/Notifications/Notifications'
import TemNotifications from '../../../components/TemNotifications/TemNotifications'
import PerformanceAlerts from '../../../components/PerformanceAlerts/PerformanceAlerts'
import { VesselPageContext } from '../../../contexts'
import { getFilteredNotifications } from '../../../features/stock-management/utils'
import ContentLayout from '../../../layout/ContentLayout/ContentLayout'
import {
  getLubeOilEntries,
  getLubeOilStatus,
} from '../../../services/performance'
import { displayErrorModal } from '../../../utils'
import * as S from '../style'
import { PadContent } from '../../../layout/styles'
import { ELubeOilEntryType } from '../types'
import Lube from '../../ActivityLogPage/Lube/Lube'
import { entryModals } from '../../ActivityLogPage/Lube/helpers'

const LubeOil = () => {
  const { imoNo } = useContext(VesselPageContext).configuration!

  const [activeModal, setActiveModal] = useState<ELubeOilEntryType>()
  const [advice, setAdvice] = useState<string[]>()
  const [alerts, setAlerts] = useState<string[]>()
  const [warnings, setWarnings] = useState<string[]>()

  const entriesQuery = useQuery(
    ['lubeOilEntries', String(imoNo)],
    () => getLubeOilEntries(String(imoNo)),
    { staleTime: 600000 },
  )

  useEffect(() => {
    const getStockNotifications = async () => {
      try {
        const { notifications } = await getLubeOilStatus(imoNo)
        setAdvice(
          getFilteredNotifications(notifications, EStockNotificationType.Info),
        )
        setWarnings(
          getFilteredNotifications(
            notifications,
            EStockNotificationType.Warning,
          ),
        )
        setAlerts(
          getFilteredNotifications(notifications, EStockNotificationType.Error),
        )
      } catch (err) {
        void displayErrorModal({
          statusText: 'Could not fetch lube oil stock status',
          message: err.message || 'Please try to refresh the page',
        })
      }
    }

    void getStockNotifications()
  }, [imoNo, entriesQuery.data])

  const handleAddEntryModalClosed = () => {
    setActiveModal(undefined)
  }

  const EntryModal = activeModal && entryModals[activeModal]

  return (
    <>
      <ContentLayout header='Lube management'>
        <Notifications advices={advice} alerts={alerts} warnings={warnings} />
        <TemNotifications />
        <PerformanceAlerts />
        <ContentCard id='lube-overview' title='Lube events'>
          <S.NewEntryOptions>
            <McButton
              fit='small'
              click={() => setActiveModal(ELubeOilEntryType.Sounding)}
              trailingicon='plus'
              data-e2e='Sounding'
            >
              Sounding
            </McButton>
            <McButton
              fit='small'
              click={() => setActiveModal(ELubeOilEntryType.Bunkering)}
              trailingicon='plus'
              data-e2e='Bunkering'
            >
              Bunkering
            </McButton>
            <McButton
              fit='small'
              click={() => setActiveModal(ELubeOilEntryType.Transfer)}
              trailingicon='plus'
              data-e2e='Transfer'
            >
              Transfer
            </McButton>
            <McButton
              fit='small'
              click={() => setActiveModal(ELubeOilEntryType.Loss)}
              trailingicon='plus'
              data-e2e='Loss'
            >
              Loss
            </McButton>
            <p>Choose from above event types to start adding relevant event.</p>
          </S.NewEntryOptions>
          <PadContent>
            <Lube />
          </PadContent>
        </ContentCard>
      </ContentLayout>
      {EntryModal && <EntryModal onClose={handleAddEntryModalClosed} />}
    </>
  )
}

export default LubeOil
