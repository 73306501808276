import { useContext, useEffect, useState } from 'react'
import { Field, Form, Formik } from 'formik'
import * as yup from 'yup'
import moment, { type Moment } from 'moment'
import { isEqual } from 'lodash'
import { McButton } from '@maersk-global/mds-react-wrapper'

import {
  FormDateTimeInput,
  FormSelect,
  InfoBox,
  Loading,
  ModalControls,
} from '../../../../commons'
import {
  displayConfirmModal,
  displayErrorModal,
  FuelLineType,
  FuelType,
  isShoreContext,
} from '../../../../utils'
import { Performance } from '../../../../api-models'
import { StockEntryContext, VesselPageContext } from '../../../../contexts'
import * as PerformanceAPI from '../../../../services/performance'
import styled, { errorTheme } from '../../../../theme'
import { UserEntryTypes } from '../../../stock-management'

const FormContainer = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;

  .field-container {
    display: flex;
    margin-bottom: 10px;
    align-items: center;

    .field-label {
      width: 240px;
      font-size: 14px;
      text-align: right;
      margin-right: 8px;
    }
  }
`

const ErrorContainer = styled.div`
  margin-left: 250px;
`

interface Option {
  label: string
  value: FuelType
}

const fuelTypeOptions: Option[] = [
  { label: FuelType[FuelType.HS], value: FuelType.HS },
  { label: FuelType[FuelType.VLS], value: FuelType.VLS },
  { label: FuelType[FuelType.ULS], value: FuelType.ULS },
  { label: FuelType[FuelType.MDO], value: FuelType.MDO },
]

type Props = {
  fuelLineType: FuelLineType
  currentlySelectedFuelType?: Performance.FDL.FuelTypeSelectionResponse
  batches: Performance.FuelOilStock.BatchResponse[]
  closeHandler: () => void
  submit: (fuelTypeSelection: Performance.FDL.FuelTypeSelection) => void
  entryId: string | undefined
  userEntryType?: UserEntryTypes
  readonly?: boolean
}

interface FuelLineChangeFormValues {
  timestamp?: Moment
  fuelType?: FuelType
}

const fuelLineChangeValidationSchema = yup.object().shape({
  fuelType: yup
    .number()
    .oneOf([FuelType.HS, FuelType.VLS, FuelType.ULS, FuelType.MDO]),
  timestamp: yup
    .date()
    .required('Time of fuel type changeover is required field'),
})

export const FuelLineChangeForm = ({
  closeHandler,
  fuelLineType,
  submit,
  currentlySelectedFuelType,
  batches,
  entryId,
  userEntryType,
  readonly,
}: Props) => {
  const imoNo = useContext(VesselPageContext).imoNo!
  const { deleteEntry } = useContext(StockEntryContext)

  const [openWindow, setOpenWindow] =
    useState<Performance.FuelOilStock.OpenWindow>()

  useEffect(() => {
    PerformanceAPI.getStockOpenWindow(imoNo)
      .then(setOpenWindow)
      .catch((e) =>
        displayErrorModal({
          statusText: 'Could not get allowed time interval',
          message: e.message,
        }),
      )
  }, [imoNo])

  if (!openWindow) {
    return <Loading width='unset' />
  }

  const getFuelTypeOptions = (values: FuelLineChangeFormValues) => {
    if (!values.timestamp) {
      return []
    }

    if (batches.length <= 0) {
      return fuelTypeOptions
    }

    const batchTypesInTimeFrame = batches
      .filter((batch) =>
        moment(batch.timestamp).isSameOrBefore(values.timestamp),
      )
      .map((batch) => batch.fuel.type)

    return fuelTypeOptions.filter(
      (option) =>
        batchTypesInTimeFrame.findIndex((batch) => batch === option.value) > -1,
    )
  }

  const handleDeleteEntry = () => {
    displayConfirmModal({
      title: '',
      message: 'Are you sure you want to delete this entry?',
    })
      .then(() =>
        deleteEntry(userEntryType!, entryId!).then(() => closeHandler()),
      )
      .catch((e) => {
        if (e !== 'cancel')
          void displayErrorModal({
            statusText: 'Something went wrong...',
            message: 'Unexpected error when trying to delete entry',
          })
      })
  }

  const initialValues = {
    timestamp: entryId
      ? moment.utc(currentlySelectedFuelType?.timestamp)
      : null,
    fuelType: fuelTypeOptions.find(
      (ft) => ft.value === currentlySelectedFuelType?.fuelType,
    )?.value,
  } as FuelLineChangeFormValues

  const disabled = isShoreContext()

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={fuelLineChangeValidationSchema}
      onSubmit={(values) =>
        values.fuelType &&
        submit({
          timestamp: values.timestamp?.toISOString() ?? '',
          fuelType: values.fuelType,
          fuelLineType,
        })
      }
    >
      {({ isSubmitting, isValid, errors, touched, values }) => (
        <Form>
          <FormContainer>
            <div className='field-container'>
              <span className='field-label'>Latest fuel type change</span>
              <div>
                {currentlySelectedFuelType?.fuelType
                  ? `${moment
                      .utc(currentlySelectedFuelType.timestamp)
                      .format('DD MMM YYYY HH:mm')} - ${
                      FuelType[currentlySelectedFuelType.fuelType] || ''
                    }`
                  : 'No previous fuel type selection'}
              </div>
            </div>
            <div className='field-container'>
              <span className='field-label'>
                When did you change fuel type?
              </span>
              <div>
                <Field
                  component={(props) => (
                    <FormDateTimeInput
                      {...props}
                      minuteSpecific={false}
                      minDate={moment.utc(openWindow.period.from)}
                      maxDate={moment.utc(openWindow.period.to)}
                    />
                  )}
                  name='timestamp'
                  disabled={disabled}
                />
              </div>
            </div>
            <div className='field-container'>
              <span className='field-label'>
                What fuel type did you change to?
              </span>
              <Field
                component={FormSelect}
                name='fuelType'
                options={getFuelTypeOptions(values)}
                width='120px'
                menuWidth='120px'
                disabled={disabled}
              />
            </div>
            <ErrorContainer>
              {errors?.timestamp && touched.timestamp && (
                <InfoBox
                  hideIcons={true}
                  theme={errorTheme}
                  boxMargin='0 0 8px 0'
                >
                  <small>{errors.timestamp}</small>
                </InfoBox>
              )}
            </ErrorContainer>
          </FormContainer>
          <ModalControls>
            {userEntryType && (
              <McButton
                label='Delete'
                appearance='error'
                className='left'
                disabled={readonly}
                click={handleDeleteEntry}
                type='button'
              />
            )}
            <McButton
              label='Cancel'
              appearance='neutral'
              click={closeHandler}
              type='button'
            />
            <McButton
              label='Change fuel type'
              appearance='primary'
              disabled={
                isSubmitting ||
                disabled ||
                !isValid ||
                isEqual(values, initialValues)
              }
              type='submit'
            />
          </ModalControls>
        </Form>
      )}
    </Formik>
  )
}
