import { NotificationTypes } from '../../queries/NotificationsApi/NotificationsApi.consts'

type PerformanceAlertTypes = typeof NotificationTypes.MESFOC

export const AlertTypeDisplayName: Record<PerformanceAlertTypes, string> = {
  MESFOC: 'ME SFOC',
}

export const AlertTypeDescription: Record<PerformanceAlertTypes, string> = {
  MESFOC: 'SFOC high',
}
