import { McButton, McIcon, McTooltip } from '@maersk-global/mds-react-wrapper'

import {
  HeaderWrapper,
  InnerWrapper,
  RankItem,
} from '../PerformanceOverview.styles'
import { calculateAdoptionPercentage } from '../PerformanceOverview.utils'
import Podium from './RankingCardPodium/RankingCardPodium'

type Props = {
  data?: Array<BalrogApi.Adoption>
  vesselName: string
}

const AdoptionCard = ({ data, vesselName }: Props) => {
  const ranking = data
    ?.map((x) => {
      return {
        name: x.vesselName,
        value: calculateAdoptionPercentage(x),
      }
    })
    .sort((a, b) => b.value - a.value)
    .map((x, i) => ({ ...x, rank: i + 1 }))
    .filter((x, i) => i < 3 || x.name === vesselName)

  return (
    <div>
      <HeaderWrapper>
        <h4>Vessel StarConnect Advice Compliance</h4>
        <McTooltip position='top-center' appearance='inverse'>
          <McButton
            slot='trigger'
            appearance='primary'
            fit='small'
            variant='plain'
          >
            <McIcon icon='info-circle' />
          </McButton>
          <span>
            This figure shows the vessels best in class, <br />
            which have the highest compliance of <br />
            advice sent by StarConnect for Main Engine <br />
            Performance and Total Energy Management.
          </span>
        </McTooltip>
      </HeaderWrapper>
      <InnerWrapper>
        <Podium />
        <div>
          {ranking?.map((x) => (
            <RankItem key={x.name} emphasize={x.name === vesselName}>
              <span>{x.rank}.</span>
              <span>{x.name}</span>
            </RankItem>
          ))}
        </div>
      </InnerWrapper>
    </div>
  )
}

export default AdoptionCard
