import { type FieldProps } from 'formik'

import { InputField } from '../InputField'

interface Props extends FieldProps {
  disabled?: boolean
  min?: string
  onChange?: (value) => void
  placeholder?: string
  type: string
  unit?: string
  className?: string
  width?: string
}

export const FormInput = ({
  field,
  form,
  min,
  onChange,
  ...restProps
}: Props) => {
  return (
    <InputField
      min={min || ''}
      value={field.value}
      onBlur={() => form.setFieldTouched(field.name)}
      onChange={(event) => {
        const { value } = event.target
        form.setFieldValue(field.name, value)
        if (onChange) onChange(value)
      }}
      {...restProps}
    />
  )
}
