export enum StatusTypeEnum {
  DONE = 1, // submitted by Bridge and ER and no longer editable
  EDITABLE, // submitted by Bridge and ER, but still editable
  BRIDGE_INCOMPLETE, // submitted by ER
  ENGINE_ROOM_INCOMPLETE, // submitted by Bridge
  DRAFT, // started but not submitted by either
  EDIT, // both Bridge and ER are in edit state
  INVALID, // Bridge or ER are in invalid state
  UNKNOWN, // we can't determine state
}
