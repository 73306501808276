import React, { useContext, useEffect } from 'react'

import * as PerformanceApi from '../../../../../services/performance'
import { Performance } from '../../../../../api-models'
import { VesselPageContext } from '../../../../../contexts'
import { displayErrorModal } from '../../../../../utils'

type Props = {
  setOpenWindow: React.Dispatch<
    React.SetStateAction<Performance.FuelOilStock.OpenWindow | undefined>
  >
}

/* The OpenWindowUpdater fetches the time interval when Stock changes are allowed to determine available dates in the datepicker */
export const OpenWindowUpdater = ({ setOpenWindow }: Props) => {
  const imoNo = useContext(VesselPageContext).imoNo!

  useEffect(() => {
    if (imoNo) {
      PerformanceApi.getStockOpenWindow(imoNo)
        .then(setOpenWindow)
        .catch((e) =>
          displayErrorModal({
            statusText: 'Could not get allowed time interval',
            message: e.message,
          }),
        )
    }
  }, [imoNo, setOpenWindow])

  return null
}
