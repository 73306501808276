import styled from 'styled-components'

export const ValueCol = styled.col`
  min-width: 224px;
  @media (min-width: ${(props) => props.theme.breakPoints.medium}px) {
    min-width: 256px;
  }
  @media (min-width: ${(props) => props.theme.breakPoints.large}px) {
    min-width: 272px;
  }
`

export const InputCol = styled.col<{ n: number }>`
  width: ${({ n }) => `${60 / n}%`};
`

export const CutOutTcLabel = styled.span`
  font-size: var(--mds_brand_typography_text_small_desktop_font-size);
  @media (min-width: ${(props) => props.theme.breakPoints.medium}px) {
    font-size: var(--mds_brand_typography_text_medium_desktop_font-size);
  }

  font-weight: bold;
  text-decoration: line-through;
  cursor: help;
`
