import { useCallback } from 'react'
import moment from 'moment'
import { Field, useFormikContext } from 'formik'
import { McButton } from '@maersk-global/mds-react-wrapper'

import {
  FormInputWithUnit,
  InfoBox,
  NotFound,
  SimpleTable,
} from '../../../../commons'
import styled, { errorTheme } from '../../../../theme'
import { formatValue, FuelType, isShoreContext } from '../../../../utils'
import { type FormBatch, type InitialRobBatch } from '../../models'
import {
  hasBatchMissingInformation,
  type InitialRobFormValues,
} from './InitialRobForm'

const Wrapper = styled.div`
  padding: 16px;

  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 16px;

    button {
      padding: 6px 8px;

      svg {
        margin-right: 8px;
      }
    }

    span {
      font-size: 14px;
    }
  }
`

const ErrorContainer = styled.div`
  margin-top: 8px;
`

const TH = styled.th`
  text-align: left;
  white-space: nowrap;
`

const TableBody = styled.tbody`
  width: 100%;
  font-size: 14px;

  td {
    white-space: nowrap;
  }

  tr:hover {
    background-color: rgba(100, 178, 212, 0.1);
  }

  .text-cell {
    display: flex;
    align-items: center;
    height: 100%;
  }

  .entry-type {
    cursor: pointer;
  }

  .number-cell {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
  }

  input {
    text-align: right;
  }
`

const BatchInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const getTotalRob = (
  values: InitialRobFormValues,
  entries: InitialRobBatch[],
) =>
  entries
    .map((e) => e.id)
    .reduce(
      (total, batchId) => total + (values?.manualrob.rob[batchId] || 0),
      0,
    )

type Props = {
  data: InitialRobBatch[]
  fuelType: number
  setSelectedBatch: (batch: FormBatch) => void
  setShowBunkeringModal: (show: boolean) => void
  hideReactTour: () => void
}

export const ManualRobTable = ({
  data,
  fuelType,
  setSelectedBatch,
  setShowBunkeringModal,
  hideReactTour,
}: Props) => {
  const { values, errors, touched } = useFormikContext<InitialRobFormValues>()

  const validateRob = useCallback((value, batch) => {
    if (typeof value === 'number' && value < 0) {
      return 'ROB must be more than or equal to 0'
    }

    // prevent error on small differences, especially an issue on Recovery where Quantity per C/E is not displayed
    // BE will snap +- 1MT to Quantity per C/E during Startup and Recovery
    if (value > batch.quantityPerChiefEngineer + 0.5) {
      return 'ROB must be less than or equal to quantity per C/E'
    }
    return null
  }, [])

  return (
    <Wrapper>
      {!!data.length ? (
        <SimpleTable style={{ width: '100%' }}>
          <thead>
            <tr>
              <TH style={{ width: '40%', maxWidth: '380px' }}>Batch</TH>
              <TH style={{ width: '15%' }}>Date bunkered</TH>
              <TH style={{ width: '15%' }}>Quantity per C/E</TH>
              <TH style={{ width: '15%' }}>Remaining on board</TH>
            </tr>
          </thead>
          <TableBody>
            {data.map((batch, idx) => {
              const hasBatchError = hasBatchMissingInformation(
                batch,
                values,
                touched,
              )
              return (
                <tr key={`${batch.id}`}>
                  <td
                    className='entry-type'
                    onClick={() => {
                      setSelectedBatch(batch)
                      setShowBunkeringModal(true)
                      hideReactTour()
                    }}
                  >
                    <BatchInfo>
                      <div className='text-cell'>{batch.displayName}</div>
                      <div className='input-cell'>
                        {!!hasBatchError && (
                          <InfoBox theme={errorTheme}>
                            Missing information
                          </InfoBox>
                        )}
                      </div>
                    </BatchInfo>
                  </td>
                  <td>
                    <div className='text-cell'>
                      {moment
                        .utc(batch.timestamp)
                        .format('DD MMM YYYY - HH:mm')}
                    </div>
                  </td>
                  <td>
                    <div className='number-cell'>
                      {`+ ${formatValue(batch.quantityPerChiefEngineer, 2)}`}
                    </div>
                  </td>
                  <td>
                    <div className='input-cell joyride-input-rob'>
                      <Field
                        component={FormInputWithUnit}
                        disabled={isShoreContext()}
                        validate={(value) => validateRob(value, batch)}
                        key={`manualrob.rob-${batch.id}`}
                        name={`manualrob.rob.${batch.id}`}
                        placeholder='0.00'
                        type='number'
                        unit='MT'
                        unitClassName='input-unit'
                        onClick={hideReactTour}
                        tabIndex={idx + 1}
                      />
                      {errors?.manualrob?.rob &&
                        errors.manualrob.rob[batch.id] &&
                        touched?.manualrob?.rob &&
                        touched.manualrob.rob[batch.id] && (
                          <ErrorContainer>
                            <InfoBox
                              theme={errorTheme}
                              hideIcons
                              boxMargin='0 0 8px 0'
                            >
                              <small>{errors.manualrob.rob[batch.id]}</small>
                            </InfoBox>
                          </ErrorContainer>
                        )}
                    </div>
                  </td>
                </tr>
              )
            })}
          </TableBody>
        </SimpleTable>
      ) : (
        <NotFound text={`No bunkerings for ${FuelType[fuelType]} found...`} />
      )}
      <div className='footer'>
        <McButton
          label='Bunkering'
          appearance='primary'
          icon='plus'
          className='bunkering-button'
          click={() => {
            setShowBunkeringModal(true)
            hideReactTour()
          }}
          tabIndex={data.length + 1}
          type='button'
        />
        <span>
          Total {FuelType[fuelType]} remaining on board{' '}
          <b>{formatValue(getTotalRob(values, data), 2)}</b>
        </span>
      </div>
    </Wrapper>
  )
}
