import { useCallback, useContext, useMemo } from 'react'
import styled from 'styled-components'
import { McButton, McIcon } from '@maersk-global/mds-react-wrapper'

import {
  ApiReportState,
  ApiReportType,
} from '../../../../api-models/hdc/report'
import { updateReportStateToEdit } from '../../../../services/hdc'
import { green, grey, red } from '../../../../theme'
import { displayErrorModal } from '../../../../utils'
import { HDCContext } from '../../context'
import { BridgeFormStep, EngineRoomFormStep, HDCFormType } from '../../types'
import { getFilteredByStepWarnings } from '../../utils'

const Wrapper = styled.div`
  flex: 1 1 auto;
  padding: 16px 24px 32px;
`

const Header = styled.div`
  text-align: center;

  h3 {
    margin-bottom: 8px;
  }

  p {
    margin-bottom: 32px;
  }
`

const ReportTypes = styled.div`
  display: flex;
  gap: 16px;
`

const ReportType = styled.div<{ disabled?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 1 auto;
  padding: 24px;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  border: 1px solid ${(props) => props.theme.colors.grey[300]};
  border-radius: 6px;
  text-align: center;

  mc-icon {
    margin: 16px 0 24px;

    &::part(icon) {
      transform: scale(2.5);
    }
  }

  mc-button {
    margin-top: 16px;
  }
`

interface ButtonDefinition {
  label: string
  text?: string
  appearance: 'primary' | 'neutral'
  onClick: (formType: HDCFormType) => void
}

const ReportStart = () => {
  const {
    setFormSteps,
    setFormType,
    setHasValidationWarning,
    setReport,
    state: HDCState,
  } = useContext(HDCContext)
  const { report } = HDCState
  const { engineRoom, bridge, id, imo } = report!
  const { state: engineRoomState } = engineRoom
  const bridgeState = bridge.state

  const startForm = useCallback(
    (formType: HDCFormType) => {
      const steps =
        formType === HDCFormType.BRIDGE_FORM
          ? Object.values(BridgeFormStep)
          : Object.values(EngineRoomFormStep)
      setFormSteps(steps)
      setFormType(formType)
      steps.forEach((step) => {
        const stepWarnings = getFilteredByStepWarnings(
          report!.warnings,
          formType,
          step,
        )
        if (Object.keys(stepWarnings).length) {
          setHasValidationWarning(formType, step, true)
        }
      })
    },
    [report, setFormSteps, setFormType, setHasValidationWarning],
  )

  const handleCreateClick = useCallback(
    (formType: HDCFormType) => {
      startForm(formType)
    },
    [startForm],
  )

  const handleEditClick = useCallback(
    async (formType: HDCFormType) => {
      try {
        const response = await updateReportStateToEdit(imo, id, formType)
        setReport(response)
        startForm(formType)
      } catch (error) {
        displayErrorModal({
          statusText: 'Failed to proceed to editing',
          message:
            error.body.error ||
            'Failed to proceed to editing, please try again.',
        })
        return
      }
    },
    [id, imo, setReport, startForm],
  )

  const createButtonDefinition = useCallback(
    (label: string): ButtonDefinition => {
      return {
        label: label,
        appearance: 'primary',
        onClick: handleCreateClick,
      }
    },
    [handleCreateClick],
  )

  const editButtonDefinition = useCallback(
    (label: string, state?: ApiReportState): ButtonDefinition => ({
      label: label,
      text:
        state === ApiReportState.SUBMITTED
          ? 'Submitted'
          : state === ApiReportState.INVALID
          ? 'Invalid'
          : 'In edit mode',
      appearance: 'neutral',
      onClick: handleEditClick,
    }),
    [handleEditClick],
  )

  const bridgeButton = useMemo((): ButtonDefinition => {
    if (bridgeState === ApiReportState.DRAFT) {
      return createButtonDefinition('Create bridge report')
    }
    return editButtonDefinition('Edit bridge report', bridgeState)
  }, [createButtonDefinition, editButtonDefinition, bridgeState])

  const engineRoomButton = useMemo((): ButtonDefinition => {
    if (engineRoomState === ApiReportState.DRAFT) {
      return createButtonDefinition('Create engine report')
    }
    return editButtonDefinition('Edit engine report', engineRoomState)
  }, [createButtonDefinition, editButtonDefinition, engineRoomState])

  return (
    <Wrapper>
      <Header>
        <h3>Bridge or engine room report</h3>
        <p>Choose which kind of report you need to make.</p>
      </Header>
      <ReportTypes>
        <ReportType disabled={report?.reportType === ApiReportType.ALONGSIDE}>
          <div className='mds-headline--small'>BRIDGE</div>
          <McIcon icon='binoculars' />
          <p
            style={{
              color: `${
                bridgeState === ApiReportState.SUBMITTED
                  ? green[500]
                  : bridgeState === ApiReportState.INVALID
                  ? red[700]
                  : grey[500]
              }`,
            }}
          >
            {bridgeButton?.text}
          </p>
          <McButton
            appearance={bridgeButton.appearance}
            disabled={report?.reportType === ApiReportType.ALONGSIDE}
            click={() => bridgeButton.onClick(HDCFormType.BRIDGE_FORM)}
            data-e2e='create-bridge-report'
          >
            {bridgeButton.label}
          </McButton>
        </ReportType>
        <ReportType>
          <div className='mds-headline--small'>ENGINE ROOM</div>
          <McIcon icon='propellor' />
          <p
            style={{
              color: `${
                engineRoomState === ApiReportState.SUBMITTED
                  ? green[500]
                  : engineRoomState === ApiReportState.INVALID
                  ? red[700]
                  : grey[500]
              }`,
            }}
          >
            {engineRoomButton?.text}
          </p>
          <McButton
            appearance={engineRoomButton.appearance}
            click={() => engineRoomButton.onClick(HDCFormType.ENGINE_FORM)}
            data-e2e='create-engine-report'
          >
            {engineRoomButton.label}
          </McButton>
        </ReportType>
      </ReportTypes>
    </Wrapper>
  )
}

export default ReportStart
