import React, { useEffect, FunctionComponent } from 'react'
import Joyride, { ACTIONS, EVENTS } from 'react-joyride'
import { usePrevious } from '../../../../../hooks'

interface StockTourProps {
  tourState: any
  dispatch: any
  currentInitialRobStep: number
}

// Tour component
const StockTour: FunctionComponent<StockTourProps> = ({
  tourState,
  dispatch,
  currentInitialRobStep,
}) => {
  const prevInitialRobStep = usePrevious(currentInitialRobStep)

  useEffect(() => {
    // Auto start the tour if the tour is not viewed before
    dispatch({ type: 'START' })
  }, [dispatch])

  useEffect(() => {
    if (currentInitialRobStep > prevInitialRobStep) {
      dispatch({ type: 'CHANGE_STEPS' })
    }
  }, [currentInitialRobStep, prevInitialRobStep, dispatch])

  // Listen to callback and dispatch state changes
  const callback = (event) => {
    const { index, action, type } = event
    if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
      dispatch({
        type: 'NEXT_OR_PREV',
        payload: { stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) },
      })
    }
  }

  return (
    <>
      <Joyride
        {...tourState}
        callback={callback}
        disableScrolling={true}
        styles={{
          options: {
            zIndex: 1000,
          },
        }}
      />
    </>
  )
}

export default StockTour
