export const enum EDataPointGroupName {
  CAMS = 'CAMS',
  CONSUMPTION = 'Consumption',
}

export const hubMethods = {
  subscribe: 'subscribe',
  unsubscribe: 'unsubscribe',
  update: 'update',
}
