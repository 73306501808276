import React, { FunctionComponent } from 'react'
import { FuelConsumptionBlock } from '../../fuel-consumption'
import styled from 'styled-components'
import { Block } from '../../../commons'
import { Performance } from '../../../api-models'

export const Wrapper = styled.div`
  margin-bottom: -8px; /* To compensate and not add padding to last element */
  .header {
    font-size: 12px;
    text-align: center;
    margin-bottom: 8px;
  }
  label {
    margin-bottom: 8px;
  }
`

interface StatusBlockProps {
  header: string
  dataFuelLines?: Performance.Status.FuelLine[] | null
  unit: string
  fuelLineTypes: number[] | undefined
}

export const StatusBlock: FunctionComponent<StatusBlockProps> = ({
  header,
  dataFuelLines,
  unit,
  fuelLineTypes,
}) => {
  return (
    <Wrapper>
      <Block>
        <div className='header'>{header}</div>
        {fuelLineTypes &&
          fuelLineTypes.map((fuelLineType, i) => {
            const fuelLine = dataFuelLines
              ? dataFuelLines.find(
                  (fuelLine) => fuelLine?.fuelLineType === fuelLineType,
                )
              : undefined
            return (
              <FuelConsumptionBlock
                key={i}
                fuelLine={fuelLine}
                fuelLineType={fuelLineType}
                unit={unit}
              />
            )
          })}
      </Block>
    </Wrapper>
  )
}
