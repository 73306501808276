import * as React from 'react'
import { Block, FancyLabel, ValueLabel } from '../../../commons'
import { formatValue } from '../../../utils'
import { UNITS } from '../../../utils/constants'
import debounce from 'lodash/debounce'

interface EngineLabelProps {
  title: string
  enginePowerStatus?: number
  isDisabled: boolean
  colorScheme: any
  onChangeEngineLabel: any
}

export const EngineLabel: React.FunctionComponent<EngineLabelProps> = (
  props: EngineLabelProps,
) => {
  const {
    title,
    enginePowerStatus,
    colorScheme,
    isDisabled,
    onChangeEngineLabel,
  } = props
  return (
    <Block>
      <FancyLabel
        colorScheme={colorScheme}
        value={isDisabled}
        onChange={debounce(() => onChangeEngineLabel(), 200)}
      >
        {title}
      </FancyLabel>
      <ValueLabel unit={UNITS.SFOC}>
        {formatValue(enginePowerStatus, 1) || '-'}
      </ValueLabel>
    </Block>
  )
}
