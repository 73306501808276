import { ECharts } from 'echarts'
import { Performance } from '../../../api-models'
import {
  ManualCorrectionOverwrite,
  ManualCorrectionRepair,
} from '../../../api-models/performance/manual-corrections'

export interface CorrectionsChartProps {
  batchSeries: any
  id: string
  isShown: boolean
  losses: Performance.FuelConsumption.FuelLineDataLoss[]
  overwrites: ManualCorrectionOverwrite[]
  queryPeriod: Performance.Common.Period
  repairs: ManualCorrectionRepair[]
  setChartRef: React.Dispatch<React.SetStateAction<ECharts | undefined>>
  setIsDataLossAccordionOpen: Function
  refreshFuelConsumption: Function
}

export enum EEntryType {
  Loss,
  Overwrite,
  Regular,
  Repair,
}

export const ENTRY_COLOR_MAP = {
  [EEntryType.Loss]: '#e36666',
  [EEntryType.Overwrite]: '#42b0d5',
  [EEntryType.Regular]: '#ededed',
  [EEntryType.Repair]: '#40ab35',
}
