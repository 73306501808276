import styled from 'styled-components'
import { McInput } from '@maersk-global/mds-react-wrapper'

export const StyledMcInput = styled(McInput)<{
  isManualInput?: boolean
  isIgnored?: boolean
}>`
  opacity: ${(props) => (props.isIgnored ? '0.5' : '1')};

  ::part(field),
  ::part(input) {
    background-color: ${(props) =>
      props.isManualInput
        ? 'var(--mds_brand_appearance_primary_subtle_background-color)'
        : 'initial'};
  }

  ::part(input) {
    font-variant: tabular-nums;
  }
`
