import React from 'react'
import styled from 'styled-components'

interface FramedSectionProps {
  header: string
  children: React.ReactNode
}

const Wrapper = styled.section`
  margin-bottom: 10px;
  padding: 8px;
  border: 1px solid #d8d8d8;
  border-radius: 3px;
`

const Header = styled.h5`
  margin-bottom: 16px !important;
`

const FramedSection = ({
  header,
  children,
}: FramedSectionProps): JSX.Element => {
  return (
    <Wrapper>
      <Header>{header}</Header>
      {children}
    </Wrapper>
  )
}

export default FramedSection
