import * as React from 'react'
import * as moment from 'moment'
import styled from '../../../../theme'
import { FullRobMarkEntry } from '../../models'
import { RobMarkTable } from './RobMarkTable'
import { TextArea } from '../../../../commons/TextArea'

const ContentWrapper = styled.div`
  padding: 16px;

  .notes-label {
    text-align: right;
    font-size: 14px;
    min-width: 144px;
    margin-right: 12px;
    white-space: nowrap;
  }
`

interface RobMarkInfoProps {
  entry?: FullRobMarkEntry
}

export class RobMarkInfo extends React.Component<RobMarkInfoProps> {
  constructor(props: RobMarkInfoProps) {
    super(props)

    this.state = {}
  }

  public render() {
    const { entry } = this.props
    if (!entry) {
      return null
    }
    const { timestamp, notes, batchQuantities } = entry

    return (
      <ContentWrapper>
        <div>
          <p
            style={{
              fontSize: '14px',
              padding: '6px 0',
              color: '#666E79',
            }}
          >
            Time of ROB UTC{' '}
            <span style={{ color: '#000' }}>
              {timestamp
                ? moment.utc(timestamp).format('DD MMM YYYY HH:mm')
                : ''}
            </span>
          </p>
        </div>

        <RobMarkTable batchQuantities={batchQuantities} />

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginTop: '16px',
          }}
        >
          <div className='notes-label'>Notes</div>
          <TextArea disabled={true} value={notes} />
        </div>
      </ContentWrapper>
    )
  }
}
