import * as React from 'react'
import { maritimeBlue, grey } from '../theme'
import { getXPercentageOfNumber } from '../utils'
import { BAR_HEIGHT, BAR_DEFAULT_WIDTH } from './SimpleBar'

interface SimpleBarRefLineProps {
  percentage: number | null
  label?: string | number | null
  stroke?: string
  dashed?: boolean
  barWidth?: number
}

interface SimpleBarRefLineState {}

export class SimpleBarRefLine extends React.Component<
  SimpleBarRefLineProps,
  SimpleBarRefLineState
> {
  public render() {
    const { percentage, label, stroke, barWidth, dashed } = this.props
    const barY =
      BAR_HEIGHT -
      (percentage ? getXPercentageOfNumber(BAR_HEIGHT, percentage) : 0)
    const width = barWidth ? barWidth : BAR_DEFAULT_WIDTH
    const barStart = !!label ? width : 0
    const barEnd = !!label ? width * 2 : width
    if (!percentage) return null
    return (
      <g>
        <line
          x1={barStart}
          x2={barEnd}
          y1={`${barY}%`}
          y2={`${barY}%`}
          stroke={stroke ? stroke : maritimeBlue[500]}
          strokeDasharray={dashed ? 4 : 0}
        />
        <text
          x={width / 2}
          y={`${barY}%`}
          alignmentBaseline='middle'
          textAnchor='middle'
          fontSize='12px'
          color={grey[700]}
        >
          {label}
        </text>
      </g>
    )
  }
}
