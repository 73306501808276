import styled, { grey, red } from '../theme'

interface TextareaProps
  extends React.DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {
  error?: boolean
  width?: string
  height?: string
}

export const TextArea = styled.textarea<TextareaProps>`
  width: ${(props) => props.width || '100%'};
  height: ${(props) => props.height || '112px'};
  border-radius: 4px;
  padding: 4px;
  font-size: 14px;
  border: 1px solid ${(props) => (props.error ? red[500] : grey[200])};
  box-sizing: border-box;
`
