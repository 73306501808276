import {
  type QueryFunctionContext,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'

import {
  getUrl,
  parseNotification,
  parseNotifications,
} from './NotificationsApi.utils'
import {
  NotificationCategories,
  NotificationState,
  activeMeSfocAlertsQueryFilter,
  meSfocAlertsQueryFilter,
} from './NotificationsApi.consts'

import {
  displayConfirmModal,
  displayErrorModal,
  doGet,
  doPost,
  getNotificationsApiUrl,
} from '../../utils'

export const keys = {
  Healthcheck: { NotificationsApi: 'Healthcheck' } as const,
  Notification: { NotificationsApi: 'Notification' } as const,
  Notifications: { NotificationsApi: 'Notifications' } as const,
  healthcheck: (imoNo: number) => [{ ...keys.Healthcheck, imoNo }] as const,
  notification: (id: string) => [{ ...keys.Notification, id }] as const,
  // prettier-ignore
  notifications: (filter: NotificationsAPI.NotificationsQueryFilter) => [{
    ...keys.Notifications,
    filter,
  }] as const,
}

const getHealthcheck = async ({
  queryKey: [{ imoNo }],
}: QueryFunctionContext<
  ReturnType<(typeof keys)['healthcheck']>
>): Promise<boolean> => {
  try {
    const data = (await doGet(
      getUrl({
        category: NotificationCategories.Energy,
        imo_no: imoNo,
      }),
    )) as Array<any>
    return data.length > 0
  } catch (_) {
    return false
  }
}

export const useNotificationsApiHealthcheck = (imoNo: number) => {
  return useQuery({
    queryKey: keys.healthcheck(imoNo),
    queryFn: getHealthcheck,
    staleTime: Infinity,
  })
}

const isNpsFeedbackEmpty = async ({
  queryKey: [{ filter }],
}: QueryFunctionContext<
  ReturnType<(typeof keys)['notifications']>
>): Promise<boolean> => {
  try {
    const data = (await doGet(getUrl(filter))) as Array<any>
    return data.length === 0
  } catch (_) {
    return false
  }
}

export const useNotificationsApiNpsFeedback = (
  filter: NotificationsAPI.NotificationsQueryFilter,
) => {
  return useQuery({
    queryKey: keys.notifications(filter),
    queryFn: isNpsFeedbackEmpty,
    refetchInterval: 1000 * 60 * 60 * 24, // 1 day in milliseconds,
  })
}

const getNotification = async <T>({
  queryKey: [{ id }],
}: QueryFunctionContext<
  ReturnType<(typeof keys)['notification']>
>): Promise<T> => {
  return await doGet(`${getNotificationsApiUrl()}/v1/VesselNotifications/${id}`)
}

export const useGetNotification = <
  T extends NotificationsAPI.Notification<any, any>,
>(
  id: string,
) => {
  return useQuery({
    queryKey: keys.notification(id),
    queryFn: getNotification<T>,
    select: parseNotification<T>,
  })
}

const getNotifications = async <T>({
  queryKey: [{ filter }],
}: QueryFunctionContext<ReturnType<(typeof keys)['notifications']>>): Promise<
  Array<T>
> => {
  return await doGet(getUrl(filter))
}

export const useNotifications = <
  T extends NotificationsAPI.Notification<any, any>,
>(
  filter: NotificationsAPI.NotificationsQueryFilter,
) => {
  return useQuery({
    queryKey: keys.notifications(filter),
    queryFn: getNotifications<T>,
    refetchInterval: 60000,
    refetchIntervalInBackground: false,
    refetchOnMount: false,
    select: parseNotifications<T>,
  })
}

export const useDismissNotification = (
  filter: NotificationsAPI.NotificationsQueryFilter,
) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: postNotificationResponse,
    onMutate: async (payload: NotificationsAPI.NotificationResponsePayload) => {
      /* Snapshot of current cache, in case we need to roll back. */
      const notificationsSnapshot = queryClient.getQueryData(
        keys.notifications(filter),
      )

      /* Optimistically update cache */
      queryClient.setQueryData(keys.notifications(filter), (oldData) => {
        return (oldData as Array<NotificationsAPI.TemAdvice>).map(
          (notification) => {
            if (notification.id !== payload.notificationId) {
              return notification
            } else {
              return {
                ...notification,
                state: NotificationState.Dismissed,
                notification_responses: [
                  ...notification.notification_responses,
                  payload,
                ],
              }
            }
          },
        )
      })

      /* Return a context object with the snapshot cache value */
      return { notificationsSnapshot }
    },
    /* If mutation fails, use the context returned from onMutate to roll back */
    onError: (err, notificationResponse, context) => {
      if (context?.notificationsSnapshot) {
        queryClient.setQueryData(
          keys.notifications(filter),
          context.notificationsSnapshot,
        )
      }
      handleMutationError(err)
    },
    onSuccess: handleMutationSuccess,
    /* Always refetch after error or success */
    onSettled: () => {
      void queryClient.invalidateQueries({
        queryKey: [keys.Notifications],
        refetchType: 'all',
      })
    },
  })
}

export const useCreateNotification = () => {
  return useMutation({
    mutationFn: postNotification,
    retry: 3,
    retryDelay: 1000,
    onError: (err, notificationResponse, context) => {
      console.log('Error submitting feedback!')
    },
    onSuccess: () => {
      console.log('Feedback submitted!')
    },
  })
}

const handleMutationSuccess = () => {
  const content = {
    title: 'Reason submitted',
    message: 'You have successfully submitted the dismissal reason.',
  }

  return displayConfirmModal(content, 'OK', 'Cancel', true)
}

const handleMutationError = (error: any) => {
  let message = ''
  if (typeof error === 'string') {
    message = error
  }

  if (error.message && typeof error.message === 'string') {
    message = error.message
  }

  if (message.charAt(message.length - 1) !== '.') {
    message += '. '
  }

  message = message += 'The advice was not dismissed.'

  void displayErrorModal({ message: message })
}

const postNotificationResponse = (
  payload: NotificationsAPI.NotificationResponsePayload,
): Promise<void> => {
  return doPost(
    `${getNotificationsApiUrl()}/v1/VesselNotifications/${
      payload.notificationId
    }/Responses`,
    payload,
  )
}

const postNotification = (
  payload: NotificationsAPI.NotificationPayload,
): Promise<void> => {
  return doPost(`${getNotificationsApiUrl()}/v1/VesselNotifications/`, payload)
}

export const usePostPerformanceAlertResponse = (alertId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: postPerformanceAlertResponse(alertId),
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: keys.notifications(activeMeSfocAlertsQueryFilter),
        }),
        queryClient.invalidateQueries({
          queryKey: keys.notifications(meSfocAlertsQueryFilter),
        }),
      ])
    },
  })
}

const postPerformanceAlertResponse =
  (alertId: string) =>
  (payload: NotificationsAPI.Performance.ResponsePayload): Promise<void> => {
    return doPost(
      `${getNotificationsApiUrl()}/v1/VesselNotifications/${alertId}/Responses`,
      payload,
    )
  }
