import { McIcon } from '@maersk-global/mds-react-wrapper'
import { Circle, Txt, Wrapper } from './Legend.styles'

const Legend = () => {
  return (
    <Wrapper>
      <McIcon icon='info-circle' />
      <Txt>Sensor data overwritten</Txt>
      <Circle />
      <Txt>Manual input</Txt>
    </Wrapper>
  )
}

export default Legend
