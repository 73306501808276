import { useContext } from 'react'
import { Form, Formik } from 'formik'
import * as yup from 'yup'
import moment from 'moment'
import { McButton } from '@maersk-global/mds-react-wrapper'

import { Performance } from '../../../../api-models'
import { Modal, ModalControls } from '../../../../commons'
import { VesselPageContext } from '../../../../contexts'
import * as PerformanceApi from '../../../../services/performance'
import styled from '../../../../theme'
import { displayErrorModal, FuelType, isShoreContext } from '../../../../utils'
import {
  generateDisplayName,
  mapBatchToBunkeringFormValues,
  mapBunkeringFormValuesToBatch,
} from '../../utils'
import { BunkeringFormFields } from './BunkeringFormFields'
import { useFuelGrades } from '../../../../queries/MasterDataApi/MasterDataApi'

const Wrapper = styled.div`
  overflow-y: auto;
  max-height: 80vh;
`

export interface BunkeringFormValues {
  fuelType: number
  displayName: string
  isDistillate: boolean
  timestamp?: moment.Moment
  orderId: string
  portCode: string
  fuelGrade: string
  quantityOrdered: string
  quantityAgreed: string
  quantityPerChiefEngineer: string
  marpolSeal: string
  density15: string
  lcv: string
  water: string
  ash: string
  sulphur: string
  bioPercentage: number
  notes: string
}

export const defaultInitialValues: BunkeringFormValues = {
  timestamp: undefined,
  fuelType: 0,
  isDistillate: false,
  orderId: '',
  portCode: '',
  fuelGrade: '',
  displayName: '',
  quantityOrdered: '',
  quantityAgreed: '',
  quantityPerChiefEngineer: '',
  marpolSeal: '',
  density15: '',
  lcv: '',
  water: '',
  ash: '',
  sulphur: '',
  bioPercentage: 0,
  notes: '',
}

export const validationSchema = (
  fuelGrades: MasterDataApi.Common.FuelGrade[],
) =>
  yup.object().shape({
    fuelGrade: yup.string().required('Fuel grade is a required field'),
    fuelType: yup.number().min(1, 'Fuel type is a required field'),
    isDistillate: yup.boolean().when('fuelType', {
      is: (value) => [FuelType.VLS, FuelType.ULS].includes(value), // alternatively: (val) => val == true
      then: yup.boolean().required('Distillate type is a required field'),
      otherwise: yup.boolean().notRequired(),
    }),
    portCode: yup.string().required('Port code is a required field'),
    orderId: yup
      .string()
      .max(100, 'Order ID must be between 1 and 100 characters long')
      .required('Order ID is a required field'),
    bioPercentage: yup.number().when('fuelGrade', {
      is: (value) =>
        fuelGrades.find((fuelGrade) => fuelGrade.data.code === value)?.data
          .isBiofuel,
      then: yup
        .number()
        .required('Bio percentage is a required field')
        .max(100, 'Percentage bio must be a value between 0 and 100')
        .positive('Percentage bio must be greater than 0'),
      otherwise: yup.number().notRequired(),
    }),
    quantityOrdered: yup
      .number()
      .required('Quantity ordered is a required field')
      .min(1, 'Quantity ordered must be a value between 1 and 20000 MT')
      .max(20000, 'Quantity ordered must be a value between 1 and 20000 MT'),
    quantityAgreed: yup
      .number()
      .required('Quantity agreed is a required field')
      .min(1, 'Quantity agreed must be a value between 1 and 20000 MT')
      .max(20000, 'Quantity agreed must be a value between 1 and 20000 MT'),
    quantityPerChiefEngineer: yup
      .number()
      .required('Quantity received is a required field')
      .min(1, 'Quantity received must be a value between 1 and 20000 MT')
      .max(20000, 'Quantity received must be a value between 1 and 20000 MT'),
    marpolSeal: yup
      .string()
      .max(25, 'Marpol seal must be between 0 and 25 characters long')
      .notRequired(),
    timestamp: yup.date().required('Time of bunkering is a required field'),
    notes: yup
      .string()
      .notRequired()
      .max(1000, 'Notes cannot be more than 1000 characters long'),
    water: yup
      .number()
      .notRequired()
      .min(0, 'Water must have a value between 0 and 10')
      .max(10, 'Water must have a value between 0 and 10'),
    ash: yup
      .number()
      .notRequired()
      .min(0, 'Ash must have a value between 0 and 10')
      .max(10, 'Ash must have a value between 0 and 10'),
    sulphur: yup
      .number()
      .notRequired()
      .min(0, 'Sulphur must have a value between 0 and 5')
      .max(5, 'Sulphur must have a value between 0 and 5'),
    lcv: yup
      .number()
      .notRequired()
      .min(37000, 'LCV must have a value between 37000 and 45000')
      .max(45000, 'LCV must have a value between 37000 and 45000'),
    density15: yup
      .number()
      .notRequired()
      .min(700, 'Density 15 must have a value between 700 and 1200')
      .max(1200, 'Density 15 must have a value between 700 and 1200'),
  })

type Props = {
  batch?: Performance.FuelOilStock.BunkeredBatchResponse
  closeHandler: (refresh?: boolean) => void
}

export const BunkeringForm = ({ closeHandler, batch }: Props) => {
  const imoNo = useContext(VesselPageContext).imoNo!
  const { data: fuelGrades, isSuccess: isFuelGradesSuccess } = useFuelGrades()
  const isEditMode = !!batch
  const submitText = isEditMode ? 'Save changes' : 'Add new bunkering'
  const disabled =
    isShoreContext() || (!!batch && batch.readonly && batch.labReportReadonly)
  const isDeleteForbidden = isShoreContext() || (!!batch && batch.readonly)
  const initialValues = batch
    ? mapBatchToBunkeringFormValues(batch)
    : defaultInitialValues
  const submitBunkering = (
    values: BunkeringFormValues,
    setSubmitting: (isSubmitting: boolean) => void,
  ) => {
    const batchRequestBody = mapBunkeringFormValuesToBatch(values)
    const request = batch
      ? PerformanceApi.putBunkeredBatch(imoNo, batchRequestBody, batch.id)
      : PerformanceApi.postBunkeredBatch(imoNo, batchRequestBody)
    request
      .then(() => closeHandler(true))
      .catch((e) => {
        setSubmitting(false)
        void displayErrorModal(e)
      })
  }

  const deleteBunkering = (batchId, setSubmitting) => {
    setSubmitting(true)
    PerformanceApi.deleteBunkeredBatch(imoNo, batchId)
      .then(() => closeHandler(true))
      .catch((e) => {
        setSubmitting(false)
        void displayErrorModal(e)
      })
  }

  return (
    <Modal
      title={!!batch ? 'Edit bunkered batch' : 'Add new bunkered batch'}
      visible
      closeHandler={closeHandler}
      helpTextKey='stock/bunkering'
    >
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) => {
          if (!values.displayName.trim()) {
            values.displayName = generateDisplayName(
              values.fuelType,
              values.isDistillate,
              values.quantityPerChiefEngineer,
              values.portCode,
              values.timestamp?.toISOString(),
            )
          }
          submitBunkering(values, setSubmitting)
        }}
        validationSchema={validationSchema(fuelGrades ?? [])}
        validateOnChange={false}
      >
        {({ isSubmitting, setSubmitting, isValid }) => {
          return (
            <Form>
              <Wrapper>
                <BunkeringFormFields
                  disabled={disabled}
                  isBaseFormReadonly={!!batch?.readonly}
                  isLabReportReadonly={batch?.labReportReadonly}
                  showErrorsForUntouchedFields={false}
                  fuelGrades={fuelGrades}
                  isFuelGradesSuccess={isFuelGradesSuccess}
                />
              </Wrapper>
              <ModalControls>
                {!!batch && (
                  <McButton
                    label='Delete'
                    appearance='error'
                    type='button'
                    className='left'
                    disabled={isSubmitting || isDeleteForbidden}
                    click={() => deleteBunkering(batch.id, setSubmitting)}
                  />
                )}
                <McButton
                  label='Cancel'
                  appearance='neutral'
                  type='button'
                  click={() => closeHandler()}
                />
                <McButton
                  label={submitText}
                  appearance='primary'
                  disabled={isSubmitting || disabled || !isValid}
                  type='submit'
                  data-e2e='submitBunkering'
                />
              </ModalControls>
            </Form>
          )
        }}
      </Formik>
    </Modal>
  )
}
