export interface Clock {
  utcNow: string
  utcNowSeconds: string
  utcNowMinutes: string
}

export interface Mode {
  vessel: ModeVessel
  isVessel: boolean
  isShore: boolean
}

interface ModeVessel {
  imoNo: number
  name: string
}
