import { type MouseEvent, useContext, useRef, useState } from 'react'
import {
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  type Row,
  type SortingState,
  useReactTable,
} from '@tanstack/react-table'

import { getColumns } from './PerformanceAlertsLog.utils'
import { Wrapper } from './PerformanceAlertsLog.styles'
import PerformanceAlertsLogFilters from './PerformanceAlertsLogFilters'
import { meSfocAlertsQueryFilter } from '../../../queries/NotificationsApi/NotificationsApi.consts'
import { useNotifications } from '../../../queries/NotificationsApi/NotificationsApi'
import { useVesselMainEngines } from '../../../queries/MasterDataApi/MasterDataApi'
import Table, { type ITableRef } from '../../../components/Table/Table'
import Diagnostics from '../../../components/PerformanceAlerts/Diagnostics/Diagnostics'
import { isShoreContext } from '../../../utils'
import { VesselPageContext, WindowContext } from '../../../contexts'
import { Loading } from '../../../commons'

const PerformanceAlertsLog = () => {
  const imoNo = useContext(VesselPageContext).imoNo!
  const { windowSize } = useContext(WindowContext)
  const [selectedAlertId, setSelectedAlertId] = useState<string>()

  const [sorting, setSorting] = useState<SortingState>([
    { desc: true, id: 'created_at' },
  ])

  const tableRef = useRef<ITableRef>(null)

  const filter = meSfocAlertsQueryFilter
  if (isShoreContext()) {
    filter.imo_no = +imoNo
  }

  const { data: alerts, isSuccess: isAlertsSuccess } =
    useNotifications<NotificationsAPI.PerformanceAlert>(filter)

  const mainEngines = useVesselMainEngines(imoNo)
  const isTwinEngine = mainEngines.data?.engines.length === 2

  const handleDiagnosticsClosed = () => {
    tableRef.current?.deselectRow()
    setSelectedAlertId(undefined)
  }

  const handleRowClick = (
    row: Row<NotificationsAPI.PerformanceAlert>,
    event: MouseEvent,
  ) => {
    const target = event.target as HTMLElement
    if (target.closest('mc-button, mc-list-item, .copy-to-clipboard')) {
      /* Return if clicking on a menu button, menu item or copy-to-clipboard cell. */
      tableRef.current?.deselectRow()
      return
    }

    setSelectedAlertId(row.original.id)
  }

  const table = useReactTable({
    data: alerts || [],
    columns: getColumns(windowSize, isTwinEngine),
    initialState: {
      pagination: { pageSize: 25 },
    },
    state: { sorting },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  })

  if (!isAlertsSuccess) {
    return <Loading />
  }

  return (
    <>
      <Wrapper>
        <PerformanceAlertsLogFilters
          table={table}
          isTwinEngine={isTwinEngine}
        />
        <Table table={table} onRowSelect={handleRowClick} ref={tableRef} />
      </Wrapper>
      {selectedAlertId && (
        <Diagnostics
          alertId={selectedAlertId}
          onClosed={handleDiagnosticsClosed}
        />
      )}
    </>
  )
}

export default PerformanceAlertsLog
