import { getColor, getTableValues } from './TrimTable.utils'
import { Table, Wrapper } from './TrimTable.styles'
import { whiteColorScheme } from '../../../theme'
import { FancyLabel } from '../../../commons'
import { type Hydra } from '../../../api-models/hydra'

type Props = {
  className: string
  res: Hydra.Trim.Trim
  meanDraughtReference: number
}

export const TrimTable = ({ className, res, meanDraughtReference }: Props) => {
  const nbrOfCols = res.trim.length + 1
  const nbrOfRows = res.mean_draught.length + 1
  const [tableValues, trimValues] = getTableValues(
    res.mean_draught,
    res.trim,
    res.propulsion_power,
    meanDraughtReference,
  )

  return (
    <Wrapper>
      <div className='table-container' data-e2e='TrimTable'>
        <Table
          className={className}
          nbrOfRows={nbrOfRows}
          nbrOfCols={nbrOfCols}
        >
          <div className='draught-label-row'>
            <FancyLabel
              className='draught-value-label'
              colorScheme={whiteColorScheme}
            >
              Draught (m)
            </FancyLabel>
          </div>
          {tableValues.map(({ val, isReference }: TrimTableVal, i: number) => {
            const isHeader = i % nbrOfCols === 0
            return isHeader ? (
              <div key={i} className='cell header-left'>
                {val}
              </div>
            ) : (
              <div
                key={i}
                className={`${isReference ? 'reference-point' : 'cell'}  ${
                  val === null ? 'empty' : ''
                }`}
                style={{
                  background: getColor(val),
                }}
              >
                {val}
                {val !== null ? '%' : null}
              </div>
            )
          })}
          {trimValues.map((trimVal, i) => (
            <div className='cell header-bottom' key={i}>
              {trimVal}
            </div>
          ))}
          <div className='trim-label-row'>
            <FancyLabel
              className='trim-value-label'
              colorScheme={whiteColorScheme}
            >
              Stern down trim (m)
            </FancyLabel>
            <FancyLabel
              className='trim-value-label'
              colorScheme={whiteColorScheme}
            >
              Bow down trim (m)
            </FancyLabel>
          </div>
        </Table>
      </div>
    </Wrapper>
  )
}
