import { useContext } from 'react'
import { McButton, McTooltip } from '@maersk-global/mds-react-wrapper'

import { WindowContext } from '../../../../../contexts'
import { Wrapper } from './styles'

type Props = {
  comment: string | null
  onEditClick: () => void
  onDelete?: () => void
}

const ActionButtons = ({ comment, onEditClick, onDelete }: Props) => {
  const { windowSize } = useContext(WindowContext)

  return (
    <Wrapper>
      {comment && (
        <McTooltip position='top-center' appearance='inverse' width={360}>
          <McButton
            slot='trigger'
            label='Comment'
            hiddenlabel={windowSize === 'small'}
            trailingicon='comment'
            fit='small'
            appearance='secondary'
          />
          <span>{comment}</span>
        </McTooltip>
      )}
      {onDelete && (
        <McButton
          label='Delete'
          hiddenlabel={windowSize === 'small'}
          trailingicon='trash'
          fit='small'
          appearance='secondary'
          click={onDelete}
        />
      )}
      {onDelete && (
        <McButton
          label='Edit'
          hiddenlabel={windowSize === 'small'}
          trailingicon='pencil'
          fit='small'
          appearance='secondary'
          click={onEditClick}
        />
      )}
    </Wrapper>
  )
}

export default ActionButtons
