import moment from 'moment'
import { type State } from '@maersk-global/mds-components-core/mc-step-indicator-item/types'

import {
  Actor,
  getDefaultStepsState,
  StepId,
  steps,
  StepState,
} from './Diagnostics.consts'
import { getDataQualityChecks } from './Technical/Technical.utils'
import { getEquipmentName } from '../PerformanceAlerts.utils'

/**
 * Maps the incoming performance alert data to form data for the diagnostics form
 */
export const resolveInitialFormValues = (
  alert: NotificationsAPI.PerformanceAlert,
  hasManualOverflowEntry: boolean | undefined,
): Diagnostics.FormData => {
  const { data, notification_responses } = alert
  const latestResponse = getLatestVesselResponseForStep(notification_responses)

  const operationalIssues: Diagnostics.OperationalIssues = {
    items: {},
    resolvedBy: null,
    comment: undefined,
  }
  data.operationalIssues
    .filter((issue) => issue.hasIssue)
    .forEach((issue) => {
      operationalIssues.items[issue.name] = false
    })
  if (latestResponse?.data.formData?.operationalIssues) {
    operationalIssues.items =
      latestResponse.data.formData.operationalIssues.items
    operationalIssues.resolvedBy =
      latestResponse.data.formData.operationalIssues.resolvedBy
  }

  const technicalIssues: Diagnostics.TechnicalIssues = {
    items: {},
    dataQuality: {},
    resolvedBy: null,
    comment: undefined,
  }
  data.technicalIssues.forEach((issue) => {
    technicalIssues.items[issue.name] = false
  })

  if (latestResponse?.data.formData?.technicalIssues) {
    technicalIssues.items = latestResponse.data.formData.technicalIssues.items
    technicalIssues.resolvedBy =
      latestResponse.data.formData.technicalIssues.resolvedBy
  }

  const filteredDataQualityChecks = getDataQualityChecks(hasManualOverflowEntry)

  const dataQuality: Record<string, boolean> = filteredDataQualityChecks.reduce(
    (acc, item) => {
      acc[item.id] = false
      return acc
    },
    {},
  )
  if (latestResponse?.data.formData?.technicalIssues?.dataQuality) {
    filteredDataQualityChecks.forEach((item) => {
      dataQuality[item.id] =
        latestResponse.data.formData!.technicalIssues!.dataQuality![item.id]
    })
  }
  technicalIssues.dataQuality = dataQuality

  const scheduleMetc: Diagnostics.ScheduleMetc = {
    scheduledAt: null,
    comment: undefined,
  }
  if (latestResponse?.data.formData?.scheduleMetc) {
    scheduleMetc.scheduledAt =
      latestResponse.data.formData.scheduleMetc.scheduledAt
  }

  return {
    operationalIssues,
    technicalIssues,
    scheduleMetc,
  }
}

export const getFirstVesselResponse = (
  responses: Array<
    NotificationsAPI.NotificationResponse<NotificationsAPI.Performance.Response>
  >,
):
  | NotificationsAPI.NotificationResponse<NotificationsAPI.Performance.Response>
  | undefined => {
  if (responses.length === 0) {
    return undefined
  }
  return responses
    .filter((r) => r.data.actor === Actor.Vessel)
    .sort((a, b) => moment.utc(a.created_at).diff(moment.utc(b.created_at)))[0]
}

/**
 * Returns the latest user response for a given step
 */
export const getLatestVesselResponseForStep = (
  responses: Array<
    NotificationsAPI.NotificationResponse<NotificationsAPI.Performance.Response>
  >,
  stepId?: string,
):
  | NotificationsAPI.NotificationResponse<NotificationsAPI.Performance.Response>
  | undefined => {
  if (responses.length === 0) {
    return undefined
  }

  return responses
    .filter(
      (r) =>
        r.data.actor === Actor.Vessel && (!stepId || r.data.step === stepId),
    )
    .sort((a, b) => moment.utc(b.created_at).diff(moment.utc(a.created_at)))[0]
}

/**
 * Resolves the [state]{@link Diagnostics.StepState} of a given
 * [step]{@link Diagnostics.Step} in the diagnostics form based on the given
 * [performance alert]{@link NotificationsAPI.PerformanceAlert}.
 */
const resolveFormStepState = (
  step: Diagnostics.Step,
  alert: NotificationsAPI.PerformanceAlert,
): State | undefined => {
  const latestResponse = getLatestVesselResponseForStep(
    alert.notification_responses,
    step.id,
  )

  if (step.id === StepId.OperationalIssues) {
    if (latestResponse) {
      // Check form data for all operational issues resolved
      const formData = latestResponse.data.formData?.operationalIssues
      if (!formData) {
        return StepState.Current
      }
      if (
        Object.values(formData.items).every(Boolean) && // All issues resolved
        moment.utc(formData.resolvedBy).isBefore(moment.utc()) // Resolved by date is in the past
      ) {
        return StepState.Completed
      }

      return StepState.Current
    }
    // Check alert data for any operational issues
    return alert.data.operationalIssues.every((issue) => !issue.hasIssue)
      ? StepState.Completed
      : StepState.Current
  } else if (step.id === StepId.TechnicalIssues) {
    if (!latestResponse?.data.formData) {
      // We always have to address the data quality checks
      return StepState.Current
    }
    let technicalIssuesResolved = false
    let dataQualityIssuesResolved = false

    const technicalIssuesFormData = latestResponse.data.formData.technicalIssues
    if (technicalIssuesFormData) {
      // Check form data for all technical issues resolved
      technicalIssuesResolved = Object.values(
        technicalIssuesFormData.items,
      ).every(Boolean)

      if (technicalIssuesFormData.dataQuality) {
        dataQualityIssuesResolved = Object.values(
          technicalIssuesFormData.dataQuality,
        ).every(Boolean)
      }
    }

    return technicalIssuesResolved && dataQualityIssuesResolved
      ? StepState.Completed
      : StepState.Current
  }

  return undefined
}

/**
 * Assigns the initial [state]{@link Diagnostics.StepsState} to each step in the
 * diagnostics form
 */
export const resolveInitialStepsState = (
  alert: NotificationsAPI.PerformanceAlert,
): Diagnostics.StepsState => {
  // Initial states
  const states: Diagnostics.StepsState = getDefaultStepsState()

  // For each step, assign the state
  steps.forEach((step, idx) => {
    const state = resolveFormStepState(step, alert)
    if (state) {
      states[step.id] = state
      if (state === StepState.Completed) {
        // The step directly following the completed step should be the current step
        const nextStep = steps[idx + 1]
        if (nextStep) {
          states[nextStep.id] = StepState.Current
        }
      }
      if (state === StepState.Current) {
        // The step directly following the current step should be pending
        const prevStep = steps[idx - 1]
        if (prevStep) {
          const prevState = states[prevStep.id]
          if (prevState === StepState.Current) {
            states[step.id] = StepState.Pending
          }
        }
      }
    }
  })

  return states
}

/**
 * Picks the initial diagnostics [step]{@link Diagnostics.Step} from the list of
 * steps based on the given [step states]{@link Diagnostics.StepsState}.
 */
export const getInitialStep = (
  steps: Array<Diagnostics.Step>,
  stepsState: Diagnostics.StepsState,
): Diagnostics.Step => {
  for (let i = 0; i < steps.length; i++) {
    const step = steps[i]
    if (stepsState[step.id] === StepState.Current) {
      return step
    }
  }

  return steps[0]
}

export const getTechnicalIssuesResolvedCounts = (
  technicalIssues: Diagnostics.TechnicalIssues | null,
) => {
  const result = { resolved: 0, total: 0 }
  if (!technicalIssues) {
    return result
  }

  const allItems = {
    ...technicalIssues.items,
    ...technicalIssues.dataQuality,
  }

  return Object.values(allItems).reduce((acc, v) => {
    if (v) acc.resolved += 1
    acc.total += 1
    return acc
  }, result)
}

export const hasUnresolvedTechnicalIssues = (
  issues: Diagnostics.TechnicalIssues | null,
): boolean => {
  const { resolved, total } = getTechnicalIssuesResolvedCounts(issues)
  return resolved < total
}

export const getSubheading = (
  alert: NotificationsAPI.PerformanceAlert,
  isTwinEngine: boolean,
  terminals: Array<MasterDataApi.Common.Terminal> | undefined,
): string => {
  const equipment = getEquipmentName(
    alert.type,
    isTwinEngine ? alert.data.instance : undefined,
  )

  let str = `We have detected that the ${equipment.toLowerCase()} SFOC is too high`

  if (
    alert.data.estimatedWasteToPort !== null &&
    alert.data.estimatedWasteToPort > 0
  ) {
    str += ` and predict excess fuel consumption of ${alert.data.estimatedWasteToPort} MT before arriving at `

    const terminalName = getTerminalName(
      alert.data.portCall?.arrivalTerminalCode,
      terminals,
    )
    if (terminalName) {
      str += terminalName
    } else {
      str += 'destination'
    }
  }

  return str + '.'
}

/**
 * Returns the terminal name for a given terminal code
 */
const getTerminalName = (
  terminalCode: string | null | undefined,
  terminals: Array<MasterDataApi.Common.Terminal> | undefined,
): string | undefined => {
  if (!terminalCode) {
    return undefined
  }

  if (!terminals) {
    return terminalCode
  }

  const terminal = terminals.find(
    (terminal) => terminal.data.code === terminalCode,
  )

  if (!terminal) {
    return terminalCode
  }

  return terminal.data.name
}
