import styled from 'styled-components'

const ErrorCount = styled.span`
  height: 20px;
  width: 20px;
  font-size: var(--mds_brand_typography_text_x-small_desktop_font-size);
  @media (min-width: ${(props) => props.theme.breakPoints.medium}px) {
    height: 24px;
    width: 24px;
    font-size: var(--mds_brand_typography_text_small_desktop_font-size);
  }
  line-height: 1.7;
  margin-left: 8px;
  border-radius: 50%;
  background-color: ${(props) => `${props.theme.colors.feedback.danger.light}`};
  text-align: center;
`

export default ErrorCount
