import type { EChartsOption, EChartsType } from 'echarts/types/dist/shared'
import * as echarts from 'echarts/core'
import { BarChart } from 'echarts/charts'
import {
  DatasetComponent,
  GridComponent,
  LegendComponent,
  TooltipComponent,
} from 'echarts/components'
import { UniversalTransition } from 'echarts/features'
import { CanvasRenderer } from 'echarts/renderers'

import { resolveDataset } from './OverallWasteChart.data'
import {
  AXIS_LABEL,
  AXIS_TICK,
  TEXT_STYLE,
  TOOLTIP,
} from '../../../EnginePerformanceTestPage/EnginePerformanceTestReportPage/Common/ChartConfig'

echarts.use([
  BarChart,
  CanvasRenderer,
  DatasetComponent,
  GridComponent,
  LegendComponent,
  TooltipComponent,
  UniversalTransition,
])

export const initChart = (domId: string) => {
  const chartDom = document.getElementById(domId)

  if (!chartDom) {
    console.error(`Chart DOM element with ID '${domId}' not found`)
    return
  }

  const options: EChartsOption = {
    barCategoryGap: '35%',
    barGap: 0,
    grid: {
      containLabel: true,
      left: 32,
    },
    // @ts-ignore
    tooltip: {
      ...TOOLTIP,
      axisPointer: {
        type: 'shadow',
      },
      order: 'valueAsc',
    },
    legend: {
      type: 'scroll',
      orient: 'vertical',
      top: 'middle',
      right: 0,
      padding: [32, 32],
      icon: 'circle',
      itemGap: 16,
      itemWidth: 16,
      itemHeight: 16,
      textStyle: TEXT_STYLE,
    },
    xAxis: {
      type: 'category',
      axisLabel: AXIS_LABEL,
      axisTick: AXIS_TICK,
    },
    yAxis: {
      type: 'value',
      name: 'Deviation [%]',
      nameTextStyle: AXIS_LABEL,
      axisLabel: AXIS_LABEL,
    },
  }

  const chart = echarts.init(chartDom)
  chart.setOption(options)

  return chart
}

export const updateChart = (
  energyMetrics: Array<BalrogApi.EnergyMetrics>,
  wasteData: Array<BalrogApi.WasteData>,
  chart?: EChartsType,
) => {
  if (!chart) {
    console.error('Chart not initialized')
    return
  }

  const dataset = resolveDataset(energyMetrics, wasteData)

  let maxNameLength = 0
  dataset.forEach((row) => {
    const name = row[0].toString()
    if (name.length > maxNameLength) {
      maxNameLength = name.length
    }
  })

  chart.setOption({
    grid: {
      right: maxNameLength > 18 ? 256 : 208,
    },
    dataset: {
      source: dataset,
    },
    xAxis: [{ type: 'category', gridIndex: 0 }],
    series: [
      ...dataset.slice(1, dataset.length).map((row) => ({
        type: 'bar',
        seriesLayoutBy: 'row',
        emphasis: {
          focus: 'series',
        },
      })),
    ],
  })
}
