import * as React from 'react'
import styled from 'styled-components'
import { grey, maerskBlue, red, sm, md, lg, media } from '../../../theme'
import { hasDataPoints } from '../mapper'
import { NotFound } from '../../../commons'
import { getDataPointFeedtype } from '../utils'

const Wrapper = styled.div`
  padding: 16px;
  --stats-width: 72px;
  --unit-width: 80px;
  --value-status-width: 90px;
  --scrollbar-width: 4px;
  table {
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
    table-layout: fixed;
    td,
    th {
      padding: 8px;
      border-right: 1px solid ${grey[200]};
      box-sizing: border-box;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      &.icon {
        width: 24px;
        border-right: none;
      }
      &.var,
      &.med,
      &.min,
      &.max {
        width: var(--stats-width);
        text-align: right;
      }
      &.unit {
        width: var(--unit-width);
      }
      &.status,
      &.val,
      &.logVal {
        width: var(--value-status-width);
      }
      &.val,
      &.logVal {
        text-align: right;
      }
      &:last-child {
        border-right: none;
      }
      &.blank {
        background-color: transparent;
        border: none;
      }
    }
    thead {
      font-weight: bold;
      text-align: left;
      font-size: 12px;
      color: ${grey[700]};
      tr {
        border-top: 1px solid ${grey[200]};
        th {
          background-color: ${grey[100]};
          &:last-child {
            padding-right: 12px;
            width: calc(var(--stats-width) + var(--scrollbar-width));
          }
          &.val,
          &.var,
          &.med,
          &.min,
          &.max {
            text-align: left;
          }
        }
        #actual {
          width: calc(var(--value-status-width) * 2);
          border-top: 1px solid ${grey[200]};
          border-right: none;
        }
        #payload-bar {
          width: calc(
            (var(--stats-width) * 4) + var(--value-status-width) +
              var(--scrollbar-width)
          );
          border-top: 1px solid ${grey[200]};
          position: relative;
          span {
            position: absolute;
            right: 8px;
          }
          #progress-bar {
            position: absolute;
            left: 0;
            bottom: 0;
            height: 4px;
            background-color: ${maerskBlue[500]};
          }
        }
      }
    }
    tbody {
      font-size: 14px;
      tr {
        width: 100%;
        border-top: 1px solid ${grey[200]};
        &:last-child {
          border-bottom: 1px solid ${grey[200]};
        }
        &:hover {
          background-color: rgba(100, 178, 212, 0.1);
        }
        &.collapse-row {
          cursor: pointer;
          background-color: ${grey[50]};
          td {
            border-right: none;
          }
        }
        td {
          span {
            color: ${grey[700]};
          }
          &.logVal {
            span {
              font-size: 12px;
              color: ${grey[700]};
              display: block;
              margin-bottom: -4px;
            }
          }
          &.error {
            color: ${red[700]};
            background-color: ${red[200]};
          }
        }
      }
    }
  }
  .scrollable {
    overflow-y: scroll;
    display: block;
    ${media.sm`max-height: calc(100vh - ${sm.BIG_BAR_HEIGHT}) - 256px - 94px`}
    ${media.md`max-height: calc(100vh - ${md.BIG_BAR_HEIGHT}) - 256px - 94px`}
    ${media.lg`max-height: calc(100vh - ${lg.BIG_BAR_HEIGHT}) - 256px - 94px`}
  }
`

interface DataPointsTableProps {
  dataPointGroups: Array<SensorStatusApi.System.DataPointGroup>
  dataPoints: Array<SensorStatusApi.SignalR.DataPoint>
  elapsedSeconds: number
  elapsedPercentage: number
  remainingSeconds: number
  activeFeed: 'CAMS' | 'Consumption' | 'Bunkering'
}

interface DataPointsTableState {
  activeGroups: {
    [groupName: string]: boolean
  }
}

export class DataPointsTable extends React.Component<
  DataPointsTableProps,
  DataPointsTableState
> {
  constructor(props: DataPointsTableProps) {
    super(props)
    this.state = {
      activeGroups: {},
    }
  }

  public render() {
    const {
      dataPointGroups,
      dataPoints,
      remainingSeconds,
      elapsedPercentage,
      activeFeed,
    } = this.props
    const { activeGroups } = this.state
    const secondsLeft = remainingSeconds
    const minutes = Math.floor(secondsLeft / 60)
    const seconds = secondsLeft % 60
    const activeFeedType = getDataPointFeedtype(activeFeed)
    if (dataPoints.length === 0) {
      return <NotFound text='No data points to display ' />
    }
    return (
      <Wrapper>
        <table>
          <thead>
            <tr style={{ borderTop: 'none' }}>
              <th className='icon blank' />
              <th className='name blank' />
              <th id='actual' colSpan={2}>
                Actual
              </th>
              <th className='unit blank' />
              <th id='payload-bar' colSpan={5}>
                Next payload
                <span>{`in ${minutes}m ${seconds}s`}</span>
                <div
                  id='progress-bar'
                  style={{ width: `${elapsedPercentage}%` }}
                />
              </th>
            </tr>
          </thead>
        </table>
        <table>
          <thead>
            <tr>
              <th colSpan={2} className='name'>
                Name
              </th>
              <th className='status'>Status</th>
              <th className='val'>Value</th>
              <th className='unit'>Unit</th>
              <th className='val'>Value</th>
              <th className='var'>Var</th>
              <th className='med'>Med</th>
              <th className='min'>Min</th>
              <th className='max'>Max</th>
            </tr>
          </thead>
        </table>
        <div className='scrollable'>
          <table>
            <tbody>
              {dataPointGroups
                .filter(
                  (group) =>
                    hasDataPoints(group, dataPoints) &&
                    (activeFeedType === group.type || activeFeedType === null),
                )
                .map((group) => {
                  return (
                    <React.Fragment key={group.name}>
                      <tr
                        className='collapse-row'
                        onClick={() => {
                          const updatedActive = {
                            [group.name]: !activeGroups[group.name],
                          }
                          this.setState({
                            activeGroups: { ...activeGroups, ...updatedActive },
                          })
                        }}
                      >
                        <td
                          className={`icon ${
                            activeGroups[group.name] ? 'downwards' : ''
                          }`}
                        >
                          {!activeGroups[group.name] && (
                            <span>
                              <i className='fal fa-chevron-right' />
                            </span>
                          )}
                          {activeGroups[group.name] && (
                            <span>
                              <i
                                className='fal fa-chevron-right'
                                data-fa-transform='rotate-90'
                              />
                            </span>
                          )}
                        </td>
                        <td className='name'>{group.name}</td>
                        <td className='status' />
                        <td className='val' />
                        <td className='unit' />
                        <td className='val' />
                        <td className='var' />
                        <td className='med' />
                        <td className='min' />
                        <td className='max' />
                      </tr>
                      {activeGroups[group.name] &&
                        group.dataPoints
                          .filter((dataPointInfo) =>
                            dataPoints.some(
                              (point) => point.id === dataPointInfo.id,
                            ),
                          )
                          .map((dataPointInfo) => {
                            const { id, name, unit } = dataPointInfo
                            const dataPoint = dataPoints.find(
                              (point) => point.id === dataPointInfo.id,
                            ) || {
                              actValid: false,
                              logValid: false,
                              actStatus: '',
                              actVal: '',
                              logVal: '',
                              logVar: '',
                              logMed: '',
                              logMax: '',
                              logMin: '',
                            }
                            const actValid = dataPoint && dataPoint.actValid
                            const logValid = dataPoint && dataPoint.logValid
                            return (
                              <tr key={id}>
                                <td
                                  className={`icon ${
                                    actValid && logValid ? '' : 'error'
                                  }`}
                                />
                                <td
                                  className={`name ${
                                    actValid && logValid ? '' : 'error'
                                  }`}
                                >
                                  <span>{id}</span>
                                  {` ${name}`}
                                </td>
                                <td
                                  className={`status ${
                                    actValid ? '' : 'error'
                                  }`}
                                >
                                  {dataPoint.actStatus}
                                </td>
                                <td
                                  className={`val ${actValid ? '' : 'error'}`}
                                >
                                  {dataPoint.actVal}
                                </td>
                                <td
                                  className={`unit ${
                                    !actValid && !logValid ? 'error' : ''
                                  }`}
                                >
                                  {unit}
                                </td>
                                <td
                                  className={`logVal ${
                                    logValid ? '' : 'error'
                                  }`}
                                >
                                  {!!dataPoint.logVal && (
                                    <span>{dataPointInfo.daqMode}</span>
                                  )}
                                  {dataPoint.logVal}
                                </td>
                                <td
                                  className={`var ${logValid ? '' : 'error'}`}
                                >
                                  {dataPoint.logVar}
                                </td>
                                <td
                                  className={`med ${logValid ? '' : 'error'}`}
                                >
                                  {dataPoint.logMed}
                                </td>

                                <td
                                  className={`min ${logValid ? '' : 'error'}`}
                                >
                                  {dataPoint.logMin}
                                </td>
                                <td
                                  className={`max ${logValid ? '' : 'error'}`}
                                >
                                  {dataPoint.logMax}
                                </td>
                              </tr>
                            )
                          })}
                    </React.Fragment>
                  )
                })}
            </tbody>
          </table>
        </div>
      </Wrapper>
    )
  }
}
