import styled from 'styled-components'

export const ValueCol = styled.col`
  min-width: 136px;
  @media (min-width: ${(props) => props.theme.breakPoints.medium}px) {
    min-width: 160px;
  }
  @media (min-width: ${(props) => props.theme.breakPoints.large}px) {
    min-width: 176px;
  }
`

export const InputCol = styled.col`
  min-width: 136px;
  @media (min-width: ${(props) => props.theme.breakPoints.medium}px) {
    min-width: 168px;
  }
  @media (min-width: ${(props) => props.theme.breakPoints.large}px) {
    min-width: 200px;
  }
`
