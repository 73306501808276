import { type TWindowSize } from './styled'

export const BREAK_POINTS: Record<TWindowSize, number> = {
  small: 0,
  medium: 1400,
  large: 1900,
}

export const GAPS: Record<TWindowSize, number> = {
  small: 12,
  medium: 20,
  large: 30,
}
