import { Event, EventType } from '../../../api-models/hdc/events'
import { ApiReportType, IReport } from '../../../api-models/hdc/report'
import { FuelLineDataLoss } from '../../../api-models/performance/fuel-consumption'
import { ESubReport, HDCFormType } from '../types'
import { ActionType, ReducerAction, ReportTimestamp } from './reducer'

export const setReports = (reports: IReport[]): ReducerAction => {
  return {
    type: ActionType.SET_REPORTS,
    data: reports,
  }
}

export const updateReport = (report: IReport): ReducerAction => {
  return {
    type: ActionType.SET_REPORT,
    data: report,
  }
}

export const updateReportType = (type: ApiReportType | null): ReducerAction => {
  return {
    type: ActionType.SET_REPORT_TYPE,
    data: type,
  }
}

export const updateReportTimestamp = (
  timestamp: ReportTimestamp,
): ReducerAction => {
  return {
    type: ActionType.SET_REPORT_TIMESTAMP,
    data: timestamp,
  }
}

export const updateEvents = (
  events: Event[],
  subReport: ESubReport,
): ReducerAction => {
  return {
    type: ActionType.SET_EVENTS,
    data: { events, subReport },
  }
}

export const updateEventTypes = (eventTypes: EventType[]): ReducerAction => {
  return {
    type: ActionType.SET_EVENT_TYPES,
    data: eventTypes,
  }
}

export const updateFormType = (formType: HDCFormType | null): ReducerAction => {
  return {
    type: ActionType.SET_FORM_TYPE,
    data: formType,
  }
}

export const updateFormCurrentStep = (stepCount: number): ReducerAction => {
  return {
    type: ActionType.SET_FORM_CURRENT_STEP,
    data: stepCount,
  }
}

export const updateHasUnsavedEvent = (hasEvent: boolean): ReducerAction => {
  return {
    type: ActionType.SET_HAS_UNSAVED_EVENT,
    data: hasEvent,
  }
}

export const updateHasValidationError = (
  formType,
  step,
  isInvalid,
): ReducerAction => {
  return {
    type: ActionType.SET_HAS_VALIDATION_ERROR,
    data: { formType, step, isInvalid },
  }
}

export const updateHasValidationWarning = (
  formType,
  step,
  hasWarning,
): ReducerAction => ({
  type: ActionType.SET_HAS_VALIDATION_WARNING,
  data: { formType, step, hasWarning },
})

export const updateFormSteps = (steps: string[] | null): ReducerAction => {
  return {
    type: ActionType.SET_FORM_STEPS,
    data: steps,
  }
}
export const updateFormCustomDestination = (
  type: 'useCustomArrival' | 'useCustomDeparture',
  value: boolean,
): ReducerAction => {
  return {
    type: ActionType.SET_FORM_USE_CUSTOM_PORT,
    data: {
      portType: type,
      value,
    },
  }
}

export const updateFuelDataLosses = (
  losses: FuelLineDataLoss[],
): ReducerAction => ({
  type: ActionType.SET_FUEL_DATA_LOSSES,
  data: losses,
})

export const updateInvalidReports = (reportIds: string[]): ReducerAction => {
  return {
    type: ActionType.SET_INVALID_REPORTS,
    data: reportIds,
  }
}

export const resetState = (): ReducerAction => {
  return {
    type: ActionType.RESET,
  }
}
