import {
  ELubeOilContainer,
  ELubeOilEntryType,
  ELubeOilLossReasonCode,
  ELubeOilType,
} from './types'

export const LUBE_OIL_CONTAINER_NAME = {
  [ELubeOilContainer.AE1]: 'AE 1',
  [ELubeOilContainer.AE2]: 'AE 2',
  [ELubeOilContainer.AE3]: 'AE 3',
  [ELubeOilContainer.AE4]: 'AE 4',
  [ELubeOilContainer.AE5]: 'AE 5',
  [ELubeOilContainer.BOB]: 'BOB',
  [ELubeOilContainer.DayTank]: 'Day Tank', // 'Day Tank Port' if twin ME
  [ELubeOilContainer.DayTankStbd]: 'Day Tank Stbd', // twin ME only
  [ELubeOilContainer.SpareClean]: 'Spare Clean',
  [ELubeOilContainer.SpareDirty]: 'Spare Dirty',
  [ELubeOilContainer.Working]: 'Working', // 'Working Port' if twin ME
  [ELubeOilContainer.WorkingStbd]: 'Working Stbd', // twin ME only
}

export const LUBE_OIL_ENTRY_TYPE_NAME = {
  [ELubeOilEntryType.Bunkering]: 'Bunkering',
  [ELubeOilEntryType.Loss]: 'Loss',
  [ELubeOilEntryType.Sounding]: 'Sounding',
  [ELubeOilEntryType.Transfer]: 'Transfer',
} as const

export const LUBE_OIL_LOSS_REASON_NAME = {
  [ELubeOilLossReasonCode.Contamination]: 'Oil has been contaminated',
  [ELubeOilLossReasonCode.Leakage]: 'Oil has leaked and cannot be recovered',
  [ELubeOilLossReasonCode.Other]: 'None of the above reasons',
}

export const LUBE_OIL_TYPE_NAME = {
  [ELubeOilType.AE]: 'AE',
  [ELubeOilType.CLO]: 'CLO',
  [ELubeOilType.CLOAdditive]: 'CLO Additive',
  [ELubeOilType.ME]: 'ME',
  [ELubeOilType.Other]: 'Other',
}
