import { useState } from 'react'
import moment from 'moment'

import { useInterval } from '../hooks'

const UtcTime = ({ id }) => {
  const [time, setTime] = useState(moment.utc().format('HH:mm MMM DD'))
  useInterval(() => {
    setTime(moment.utc().format('HH:mm MMM DD'))
  }, 1000)

  return <div id={id} style={{ color: 'grey' }}>{`UTC Time: ${time}`}</div>
}

export default UtcTime
