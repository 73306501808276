import { useContext } from 'react'
import { McIcon } from '@maersk-global/mds-react-wrapper'

import { Circle } from './TabPrefix.styles'
import { TabStatus } from '../../EnginePerformanceTestReportPage.consts'
import { WindowContext } from '../../../../../contexts'

type Props = {
  tabStatus: TabStatus
}

const TabPrefix = ({ tabStatus }: Props) => {
  const { windowSize } = useContext(WindowContext)

  if (tabStatus === TabStatus.SUCCESS) {
    return (
      <McIcon
        slot='prefix'
        icon='check-circle'
        size={windowSize === 'large' ? '24' : '20'}
        color='#40AB35'
      />
    )
  }

  if (tabStatus === TabStatus.ERROR) {
    return (
      <McIcon
        slot='prefix'
        icon='exclamation-circle'
        size={windowSize === 'large' ? '24' : '20'}
        color='#B80012'
      />
    )
  }

  return <Circle slot='prefix' />
}

export default TabPrefix
