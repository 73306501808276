import type { EChartsOption, EChartsType } from 'echarts/types/dist/shared'
import * as echarts from 'echarts/core'

import {
  chartTypeToId,
  chartTypeToYAxisName,
  unitsConfig,
} from './CommonMetricsChart.consts'
import {
  AXIS_LABEL,
  AXIS_TICK,
  TEXT_STYLE,
  TOOLTIP,
} from '../../../../EnginePerformanceTestPage/EnginePerformanceTestReportPage/Common/ChartConfig'
import { formatValue } from '../../../../../utils'
import {
  AXIS_LINE,
  AXIS_SPLIT_LINE,
  TOOLBOX_SCATTER,
} from '../../../../../chart-lib/constants'
import { grey } from '../../../../../theme'

export const getChartId = (chartType: string) =>
  `svc-me-${chartTypeToId[chartType]}-metrics-chart`

export const initChart = (domId: string, chartType: string) => {
  const chartDom = document.getElementById(domId)
  if (!chartDom) {
    console.error(`Chart DOM element with ID '${domId}' not found`)
    return
  }
  const options: EChartsOption = {
    barCategoryGap: '35%',
    barGap: 0,
    grid: { containLabel: true, left: 32 },
    tooltip: {
      ...TOOLTIP,
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        snap: true,
        label: {
          show: false,
        },
      },
      textStyle: {
        color: grey[50],
      },
      backgroundColor: 'rgba(50,50,50,0.9)',
    },
    toolbox: {
      ...TOOLBOX_SCATTER,
      feature: {
        dataZoom: {
          filterMode: 'none',
        },
      },
    },
    legend: {
      type: 'scroll',
      orient: 'vertical',
      top: 'middle',
      right: 0,
      padding: [32, 32],
      icon: 'circle',
      itemGap: 16,
      itemWidth: 16,
      itemHeight: 16,
      textStyle: TEXT_STYLE,
    },
    xAxis: {
      type: 'value',
      name: '% MCR',
      axisLabel: AXIS_LABEL,
      nameTextStyle: AXIS_LABEL,
      axisTick: AXIS_TICK,
      axisLine: AXIS_LINE,
      splitLine: AXIS_SPLIT_LINE,
      min: 0,
      max: 100,
    },
    yAxis: {
      type: 'value',
      name: chartTypeToYAxisName[chartType],
      nameTextStyle: AXIS_LABEL,
      axisLabel: AXIS_LABEL,
      axisTick: AXIS_TICK,
      axisLine: AXIS_LINE,
      splitLine: AXIS_SPLIT_LINE,
      min: (value) => Math.floor(value.min - 10),
      max: (value) => Math.floor(value.max + 10),
    },
  }

  const chart = echarts.init(chartDom)
  chart.setOption(options)
  return chart
}

export const updateChart = (
  data: Array<BalrogApi.PerformanceMetrics>,
  chartType: string,
  chart?: EChartsType,
) => {
  if (!chart) {
    console.error('Chart not initialized')
    return
  }

  const dataset = data.map((entry) => ({
    name: entry.vesselName ?? 'Unnamed vessel-' + entry.imo,
    type: 'line',
    showSymbol: false,
    smooth: true,
    data: entry.dataPoints[chartTypeToId[chartType]].load.map(
      (load: number, index: number) => [
        load,
        entry.dataPoints[chartTypeToId[chartType]][chartTypeToId[chartType]][
          index
        ],
      ],
    ),
  }))

  let maxNameLength = dataset.reduce(
    (max, { name }) => Math.max(max, name ? name.length : 0),
    0,
  )

  chart.setOption({
    grid: { right: maxNameLength > 18 ? 312 : 248 },
    dataset: { source: dataset },
    yAxis: {
      type: 'value',
      name: chartTypeToYAxisName[chartType],
    },
    tooltip: {
      ...TOOLTIP,
      formatter: (params: any) => {
        let result = `<strong style="font-variant: tabular-nums">${formatValue(
          params[0].axisValue,
          1,
        )}</strong> %MCR`
        params.forEach((item: any) => {
          result += `<br/>${item.marker} ${
            item.seriesName
          }: <strong style="font-variant: tabular-nums">${formatValue(
            item.data[1],
            1,
          )} ${unitsConfig[chartType]}</strong>`
        })
        return result
      },
    },
    series: dataset,
  })
}
