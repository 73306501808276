import { type CellContext, createColumnHelper } from '@tanstack/react-table'

import { type VesselsOverviewData } from '../../api-models/frontend'
import { NumericCell, NumericHeader } from '../../components/Table/Table.styles'
import { formatValue, kiloConverter } from '../../utils'
import { VesselNameCell } from './styles'

import { StatusPill } from '../../commons'

const columnHelper = createColumnHelper<VesselsOverviewData>()

export const columns = [
  columnHelper.accessor('vesselName', {
    header: 'Vessel name',
    cell: (props) => (
      <VesselNameCell>
        {props.getValue()}
        <StatusPill imoNo={props.row.original.imoNo} />
      </VesselNameCell>
    ),
  }),
  columnHelper.accessor('sog', {
    header: () => <NumericHeader>SOG (knots)</NumericHeader>,
    cell: (props) => (
      <NumericCell>{formatValue(props.getValue(), 1)}</NumericCell>
    ),
  }),
  columnHelper.accessor('stw', {
    header: () => <NumericHeader>STW (knots)</NumericHeader>,
    cell: (props) => (
      <NumericCell>{formatValue(props.getValue(), 1)}</NumericCell>
    ),
  }),
  {
    id: 'mainEngConsumption',
    header: () => <NumericHeader>ME consumption (MT/h)</NumericHeader>,
    accessorFn: (data: VesselsOverviewData) =>
      data.mainEng2Consumption
        ? data.mainEng1Consumption! + data.mainEng2Consumption
        : data.mainEng1Consumption,
    cell: ({ row }: CellContext<VesselsOverviewData, any>) => (
      <NumericCell>
        {formatValue(row.original.mainEng1Consumption, 1)}
        {row.original.mainEng2Consumption
          ? ` / ${formatValue(row.original.mainEng2Consumption, 1)}`
          : ''}
      </NumericCell>
    ),
  },
  columnHelper.accessor('auxEngConsumption', {
    header: () => <NumericHeader>AE consumption (kg/h)</NumericHeader>,
    cell: (props) => (
      <NumericCell>
        {formatValue(kiloConverter(props.getValue()), 0)}
      </NumericCell>
    ),
  }),
  columnHelper.accessor('boilerConsumption', {
    header: () => <NumericHeader>Boiler consumption (kg/h)</NumericHeader>,
    cell: (props) => (
      <NumericCell>
        {formatValue(kiloConverter(props.getValue()), 0)}
      </NumericCell>
    ),
  }),
  {
    id: 'mainEngPow',
    header: () => <NumericHeader>ME Power (MW)</NumericHeader>,
    accessorFn: (data: VesselsOverviewData) =>
      data.mainEng2Power
        ? data.mainEng1Power! + data.mainEng2Power
        : data.mainEng1Power,
    cell: ({ row }: CellContext<VesselsOverviewData, any>) => (
      <NumericCell>
        {formatValue(row.original.mainEng1Power, 1)}
        {row.original.mainEng2Power
          ? ` / ${formatValue(row.original.mainEng2Power, 1)}`
          : ''}
      </NumericCell>
    ),
  },
  {
    id: 'mainEngShaftRpm',
    header: () => <NumericHeader>RPM</NumericHeader>,
    accessorFn: (data: VesselsOverviewData) =>
      data.mainEng2ShaftRpm
        ? (data.mainEng1ShaftRpm! + data.mainEng2ShaftRpm) / 2
        : data.mainEng1ShaftRpm,
    cell: ({ row }: CellContext<VesselsOverviewData, any>) => (
      <NumericCell>
        {formatValue(row.original.mainEng1ShaftRpm, 1)}
        {row.original.mainEng2ShaftRpm
          ? ` / ${formatValue(row.original.mainEng2ShaftRpm, 1)}`
          : ''}
      </NumericCell>
    ),
  },
  columnHelper.accessor('trim', {
    header: () => <NumericHeader>Trim (m)</NumericHeader>,
    cell: (props) => (
      <NumericCell>
        {/*{props.getValue() > 0 ? '+' : ''}*/}
        {formatValue(props.getValue(), 1)}
      </NumericCell>
    ),
  }),
  columnHelper.accessor('meanDraught', {
    header: () => <NumericHeader>Mean draught (m)</NumericHeader>,
    cell: (props) => (
      <NumericCell>{formatValue(props.getValue(), 1)}</NumericCell>
    ),
  }),
]
