import * as styledComponents from 'styled-components'
import { lightTheme } from '../theme_new'

const {
  default: styled,
  css,
  keyframes,
} = styledComponents as styledComponents.ThemedStyledComponentsModule<
  IThemeInterface | typeof lightTheme
>

export default styled
export * from './themes'
export * from './color-schemes'
export * from './colors'
export { default as Icons } from './icons'
export * from './sizes'
export { css, keyframes }

export interface IThemeInterface {
  bg: string
  fg: string
  border: string
  hover?: string
  active?: string
  font?: string
  icon?: string
}

export interface IColorScheme {
  stroke: string
  fill: string
  font: string
}
