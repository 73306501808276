import { useContext } from 'react'

import RangeValidationTag from './RangeValidationTag'
import { TableContainer } from '../GeneralTab/GenaralTab.styles'
import { formatValue } from '../../../../utils'
import { WindowContext } from '../../../../contexts'

type Props = {
  meEfficiency: GandalfApi.Metc.MeEfficiencyResults
}

const MainEngineEfficiency = ({ meEfficiency }: Props) => {
  const { windowSize } = useContext(WindowContext)

  return (
    <TableContainer className={`mds-table mds-table--${windowSize}`}>
      <table>
        <thead>
          <tr>
            <th>Main Engine Efficiency</th>
            <th>Adjusted to ISO Conditions</th>
            <th>Model</th>
            <th>Deviation to model</th>
            <th className='mds-table__cell--text-center'>Validation</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Indicated power</td>
            <td>
              {formatValue(
                meEfficiency.indicatedPower.isoConditionAdjustedValue,
                0,
              )}{' '}
              kW
            </td>
            <td>-</td>
            <td>-</td>
            <td className='mds-table__cell--text-center'>-</td>
          </tr>
          <tr>
            <td>Mechanical efficiency</td>
            <td>
              {formatValue(
                meEfficiency.mechanicalEfficiency.isoConditionAdjustedValue,
                1,
              )}{' '}
              %
            </td>
            <td>-</td>
            <td>-</td>
            <td className='mds-table__cell--text-center'>
              <RangeValidationTag
                rangeValidation={
                  meEfficiency.mechanicalEfficiency.rangeValidation
                }
              />
            </td>
          </tr>
          <tr>
            <td>SFOC</td>
            <td>
              {formatValue(meEfficiency.sfoc.isoConditionAdjustedValue, 2)}{' '}
              g/kWh
            </td>
            <td>{formatValue(meEfficiency.sfoc.modelValue, 2)} g/kWh</td>
            <td>{formatValue(meEfficiency.sfoc.modelDeviation, 2)} %</td>
            <td className='mds-table__cell--text-center'>
              <RangeValidationTag
                rangeValidation={meEfficiency.sfoc.rangeValidation}
              />
            </td>
          </tr>
          <tr>
            <td>Pcomp</td>
            <td>
              {formatValue(meEfficiency.pcomp.isoConditionAdjustedValue, 2)} bar
            </td>
            <td>{formatValue(meEfficiency.pcomp.modelValue, 2)} bar</td>
            <td>{formatValue(meEfficiency.pcomp.modelDeviation, 2)} %</td>
            <td className='mds-table__cell--text-center'>
              <RangeValidationTag
                rangeValidation={meEfficiency.pcomp.rangeValidation}
              />
            </td>
          </tr>
          <tr>
            <td>Pmax</td>
            <td>
              {formatValue(meEfficiency.pmax.isoConditionAdjustedValue, 2)} bar
            </td>
            <td>{formatValue(meEfficiency.pmax.modelValue, 2)} bar</td>
            <td>{formatValue(meEfficiency.pmax.modelDeviation, 2)} %</td>
            <td className='mds-table__cell--text-center'>
              <RangeValidationTag
                rangeValidation={meEfficiency.pmax.rangeValidation}
              />
            </td>
          </tr>
          <tr>
            <td>Pressure rise (Pmax - Pcomp)</td>
            <td>
              {formatValue(
                meEfficiency.pressureRise.isoConditionAdjustedValue,
                2,
              )}{' '}
              bar
            </td>
            <td>{formatValue(meEfficiency.pressureRise.modelValue, 2)} bar</td>
            <td>
              {formatValue(meEfficiency.pressureRise.modelDeviation, 2)} %
            </td>
            <td className='mds-table__cell--text-center'>
              <RangeValidationTag
                rangeValidation={meEfficiency.pressureRise.rangeValidation}
              />
            </td>
          </tr>
          <tr>
            <td>SFOC due to Pmax deviation</td>
            <td>
              {formatValue(
                meEfficiency.sfocDuePmax.isoConditionAdjustedValue,
                2,
              )}{' '}
              g/kWh
            </td>
            <td>-</td>
            <td>-</td>
            <td className='mds-table__cell--text-center'>-</td>
          </tr>
        </tbody>
      </table>
    </TableContainer>
  )
}

export default MainEngineEfficiency
