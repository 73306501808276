import styled from 'styled-components'

export const Table = styled.table`
  border: none;
  width: 100%;
  border-collapse: collapse;
`

export const Thead = styled.thead`
  background-color: #ffffff;
  box-shadow: none !important;
  border-top: 1px solid #e7e8e9;
  border-bottom: 1px solid #e7e8e9;
  font-weight: 500;
  font-size: 12px;
  color: #474e56;
`

export const Th = styled.th`
  font-weight: 600;
  font-size: 11px;
  line-height: 11px;
  padding: 25px 8px 13px 8px;
  border: 1px solid #e7e8e9;
  text-align: left;
  vertical-align: baseline;
`

export const Tr = styled.tr<{ cursor?: string }>`
  padding: 8px;
  border: 1px solid #e7e8e9;
  width: 100%;
  cursor: ${({ cursor }) => cursor || 'default'};
`

export const Tbody = styled.tbody<{
  striped?: boolean
}>`
  overflow: auto;
  font-size: 14px;
  padding: 0 !important;
  border-bottom: 1px solid #e7e8e9;
  ${({ striped }) =>
    striped &&
    `
      tr:nth-child(odd) {
        background: #f8f8f8;
      }
    `}

  td {
    border: 1px solid #e7e8e9;
    padding: 8px;
  }
`
