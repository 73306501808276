import { utc } from 'moment'
import { ReportState } from '../../queries/GandalfApi/GandalfApi.consts'

type Message = {
  warning?: string
  alert?: string
}

/**
 * Returns a warning <em>or</em> alert message if the last submitted report is
 * older than 25 or 30 days respectively.
 */
export const getMessage = (
  reports: Array<GandalfApi.MetcReport>,
): Message | undefined => {
  const submittedReports = reports
    .filter((r) => r.reportState === ReportState.Submitted)
    .sort((a, b) => utc(b.startTimestamp).diff(utc(a.startTimestamp)))

  if (submittedReports.length === 0) {
    return
  }

  const daysSinceLastSubmission = utc().diff(
    utc(submittedReports[0].startTimestamp),
    'days',
  )

  if (daysSinceLastSubmission < 25) {
    return
  }

  const msg = `A Main Engine test has not been submitted the past ${daysSinceLastSubmission} days. Please plan for the next test when time and conditions permit.`

  if (daysSinceLastSubmission >= 30) {
    return { alert: msg }
  }

  return { warning: msg }
}
