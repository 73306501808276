import { useMemo } from 'react'
import { getCoreRowModel, useReactTable } from '@tanstack/react-table'

import { mapEnergyManagementResponse } from '../mapper'
import { type Hydra } from '../../../api-models/hydra'
import { type Performance } from '../../../api-models'
import { type AuxEngParams, TEMConfiguration } from '../models'
import { columns } from './helpers'
import Table from '../../../components/Table/Table'

type Props = {
  vesselConfiguration: Performance.VesselConfiguration.Configuration
  auxEngParams: AuxEngParams
  temConfiguration: TEMConfiguration
  data?: Hydra.TEM.EnergyManagement
}

const EnergyManagementResultsTable = (props: Props) => {
  const { data, vesselConfiguration, auxEngParams, temConfiguration } = props
  const tableData = useMemo(
    () =>
      mapEnergyManagementResponse(
        vesselConfiguration,
        auxEngParams,
        temConfiguration,
        data,
      ),
    [vesselConfiguration, auxEngParams, temConfiguration, data],
  )

  const table = useReactTable({
    data: tableData,
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <>
      <Table table={table} maxFit='small' hidePagination />
    </>
  )
}

export default EnergyManagementResultsTable
