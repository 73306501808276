import * as React from 'react'
import styled, { grey } from '../theme'

const InputFieldWrapper = styled.div<{ color?: string; iconColor?: string }>`
  position: relative;
  height: 34px;
  input:focus {
    box-shadow: 0 0 0 4px rgba(100, 178, 212, 0.2);
  }

  input {
    border: 1px solid ${(p) => (p.color ? p.color : grey[400])} !important;
    border-radius: 4px;
    height: calc(100%);
    width: 100%;
    padding-left: 8px;
    box-sizing: border-box;
  }
  span {
    position: absolute;
    right: 0;
    top: 0;
    border: 1px solid ${(p) => (p.color ? p.color : grey[400])};
    background-color: ${(p) => (p.color ? p.color : grey[100])};
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    height: calc(100% - 2px);
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${(p) => (p.iconColor ? p.iconColor : grey[700])};
    box-sizing: unset;
  }
`

interface InputFieldFilterProps {
  change: Function
  color?: string
  iconColor?: string
  placeholder?: string
  filter?: string
  id?: string
}

interface InputFieldFilterState {
  filter: string
}

export class InputFieldFilter extends React.Component<
  InputFieldFilterProps,
  InputFieldFilterState
> {
  constructor(props: InputFieldFilterProps) {
    super(props)

    this.state = {
      filter: props.filter ? props.filter : '',
    }
  }

  componentDidUpdate(
    prevProps: InputFieldFilterProps,
    prevState: InputFieldFilterState,
  ) {
    const { filter } = this.props
    const { filter: prevFilter } = prevProps
    if (prevFilter !== filter && typeof filter === 'string') {
      this.setState({ filter })
    }
  }

  onChange(filter: string) {
    const { change } = this.props
    this.setState({ filter })
    change(filter)
  }

  render() {
    const { filter } = this.state
    const { color, iconColor, placeholder, id } = this.props
    return (
      <InputFieldWrapper color={color} iconColor={iconColor}>
        <input
          id={`${id}-input`}
          placeholder={placeholder}
          onChange={(event) => this.onChange(event.target.value)}
          value={filter}
        />
        <span>
          <i className='fal fa-search' />
        </span>
      </InputFieldWrapper>
    )
  }
}
