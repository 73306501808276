import * as React from 'react'
import styled, { IThemeInterface } from '../theme'

const Box = styled.div<{
  theme: IThemeInterface
  boxMargin?: string
  maxWidth?: string
  width?: string
}>`
  margin: ${({ boxMargin }) => boxMargin || '8px 0'};
  border-radius: 4px;
  border: ${(props) => `1px solid ${props.theme.border}`};
  display: flex;
  align-items: center;
  padding: 8px;
  background-color: ${(props) => props.theme.bg};
  font-size: 13px;
  color: ${(props) => props.theme.fg};
  max-width: ${({ maxWidth = '100%' }) => maxWidth};
  width: ${({ width }) => width};
  .icon {
    color: ${(props) => props.theme.fg};
    margin-right: 8px;
    display: flex;
    align-items: center;
    width: 24px;
    svg {
      height: 24px;
      width: 24px;
    }
  }
`

interface InfoBoxProps {
  icon?: string
  theme: IThemeInterface
  className?: string
  boxMargin?: string
  maxWidth?: string
  width?: string
  hideIcons?: boolean
  children?: React.ReactNode
}

export const InfoBox = ({
  icon,
  children,
  hideIcons,
  ...rest
}: InfoBoxProps) => {
  const iconDescription = icon || rest.theme.icon
  return (
    <Box {...rest}>
      {!hideIcons && iconDescription && (
        <span className='icon'>
          <i className={`fal fa-${iconDescription}`} />
        </span>
      )}
      {children}
    </Box>
  )
}
