import { Moment } from 'moment'
import { Event } from '../../api-models/hdc/events'
import {
  ApiReportState,
  ApiReportType,
  Batch,
  CylinderLubeOil,
  DataPoint,
  TWarning,
} from '../../api-models/hdc/report'
import { Configuration } from '../../api-models/performance/vessel-configuration'
import { FuelType } from '../../utils'
import { HDCReducerState } from './context/reducer'

export enum HDCFormType {
  BRIDGE_FORM = 'bridge',
  ENGINE_FORM = 'engineroom',
}

export enum ValidationErrorType {
  CROSSFIELD = 'crossfield',
  CROSSECTION = 'crossection',
  REQUIRED = 'required',
  OUT_OF_RANGE = 'out_of_range',
}

export type HDCFormValidationContext = {
  useCustomArrival: boolean
  useCustomDeparture: boolean
  vesselConfig: Configuration
  report: HDCReportFormValues
  initialValues: HDCReportFormValues
  state: HDCReducerState
}

export interface HDCHoursMinutes {
  hours: DataPoint<number>
  minutes: DataPoint<number>
}

export interface FormMainEngine {
  number: number
  averagePower: DataPoint<number>
  averageShaftRpm: DataPoint<number>
  tcco: DataPoint<boolean> | null
  runTime: HDCHoursMinutes
}

export interface FormEquipment {
  number?: number
  energy: DataPoint<number>
  runTime: HDCHoursMinutes
}

export type FuelTypeConsumption = {
  fuelType: number
  batches: Batch[]
}

// These types are used with the mapping of PAPI data
// and Gandalf Report values, to get up to date info on
// batches onboard.
export interface HDCBatch extends Batch {
  name: string
}

export type HDCFuelTypeConsumption = {
  fuelType: FuelType
  batches: HDCBatch[]
}
export type dateFormat = {
  dateValue: string
  hoursValue: number
  minutesValue: number
}
export type PeriodDetails = {
  date: string
  energy: number
  dateFormat: dateFormat
}

export type Period = {
  periodStart: PeriodDetails
  periodEnd: PeriodDetails
  totalEnergy: number
  totalDays: number
  totalHoursMinutes: string
}

export type HDCReportFormValues = {
  id: string
  imo: string
  seqNo: number
  reportType: ApiReportType
  periodStart: Moment
  periodEnd: Moment
  bridge: {
    draughtFore: DataPoint<number>
    draughtAft: DataPoint<number>
    ballastWater: DataPoint<number>
    waterTemperature: DataPoint<number>
    waterDepth: DataPoint<number>
    distanceLogged: DataPoint<number>
    distanceObserved: DataPoint<number>
    departureTerminal: {
      code: DataPoint<string>
      name: DataPoint<string>
    } | null
    arrivalTerminal: {
      code: DataPoint<string>
      name: DataPoint<string>
    } | null
    arrivalTerminalOriginal: {
      code: DataPoint<string>
      name: DataPoint<string>
    } | null
    eta: DataPoint<Moment>
    etaOriginal: DataPoint<Moment>
    remainingDistanceToPilotStation: DataPoint<number>
    remainingDistanceToPilotStationOriginal: DataPoint<number>
    remainingTimeToPilotStation: DataPoint<number>
    comment: string

    // Custom Port: code & name
    alternativeArrivalTerminal: {
      name: string
      code: string
    } | null
    alternativeArrivalTerminalOriginal: {
      name: string
      code: string
    } | null
    alternativeDepartureTerminal: {
      name: string
      code: string
    } | null
    state: ApiReportState
    events: Event[] | null
  }
  engineRoom: {
    shorePowerPeriods: {
      periods: Period[]
      numberOfPeriods: number
      grandTotalEnergy: number
      grandTotalDays: number
      grandTotalHoursMinutes: string
      shorePowerUsed: string | null
      reason: string
      comment: string
    } | null
    mainEngines: FormMainEngine[]
    auxEngines: FormEquipment[]
    shaftMotors: FormEquipment[]
    shaftGenerators: FormEquipment[]
    auxBlowers: FormEquipment[]
    scrubberSystem: FormEquipment | null
    reeferEnergy: DataPoint<number>
    numberOfReefers: DataPoint<number>
    wasteHeatRecovery: FormEquipment | null
    fuelConsumption: {
      batches: Batch[]
      sludge: {
        total: number
      }
      leakageMass: number
    }
    cylindersLubeOil: CylinderLubeOil[]
    comment: string //this is a random comment that can be provided if relevant
    state: ApiReportState
    events: Event[] | null
  }
  warnings: TWarning[]
}

export enum EventTypeName {
  ETA_CHANGE = 'ETA change',
  ROUTE_CHANGE = 'Route change',
  ANCHORAGE = 'Anchor',
  DISCHARGE_SLUDGE = 'Discharge sludge to shore',
  TECHNICAL_OFF_SERVICE = 'Technical Off-service',
  OPERATIONAL_OFF_SERVICE = 'Operational Off-service',
}

export enum BridgeFormStep {
  START = 'Start',
  VOYAGE = 'Voyage',
  EVENTS = 'Events',
  SUMMARY = 'Summary',
}

export enum EngineRoomFormStep {
  START = 'Start',
  PROPULSION = 'Propulsion',
  ELECTRICAL = 'Electrical',
  CONSUMPTION = 'Consumption',
  EVENTS = 'Events',
  SUMMARY = 'Summary',
}

export enum ESubReport {
  Bridge = 'bridge',
  EngineRoom = 'engineRoom',
}
