import styled from 'styled-components'

export const Wrapper = styled.div<{ hasFilter?: boolean }>`
  width: 100%;

  .content {
    max-width: 1800px;
    padding: 24px;
    padding-right: ${({ hasFilter }) => (hasFilter ? '60px' : '24px')};
    @media (min-width: ${(props) => props.theme.breakPoints.medium}px) {
      padding: 32px;
      padding-right: ${({ hasFilter }) => (hasFilter ? '80px' : '32px')};
    }
    @media (min-width: ${(props) => props.theme.breakPoints.large}px) {
      padding: 48px 56px;
      padding-right: ${({ hasFilter }) => (hasFilter ? '104px' : '56px')};
    }

    .wrapped-components {
      display: flex;
      flex-wrap: wrap;
      margin: -16px -16px 0 0;
      padding-bottom: 16px;

      > * {
        flex: 1 1 auto;
        margin: 16px 16px 0 0;
      }
    }

    .full-component {
      display: block;
      width: 100%;
      margin-bottom: 16px;
    }

    .full-components {
      > * {
        display: block;
        width: 100%;
        margin-bottom: 16px;
      }
    }
  }
`

export const VesselNameWrapper = styled.div`
  display: flex;
  align-items: center;

  > mc-tag {
    transform: translateY(-2px);
  }
`

export const VesselName = styled.h5`
  margin-right: 8px;
  line-height: 24px;
  color: ${(props) => props.theme.colors.blue[800]};
`

export const Heading = styled.div`
  margin-bottom: 32px;
`
