import React from 'react'
import imgOverview from '../../../../assets/images/help-section/lube-overview.png'
import imgOverviewNotifications from '../../../../assets/images/help-section/lube-overview-notifications.png'

export const Overview = React.forwardRef<HTMLDivElement>((props, ref) => (
  <div ref={ref} id='help-section-subtopic'>
    <h4>Purpose</h4>
    <p>
      The purpose of this guide is to explain the functions in the overview page
      in the Lube Oil Management and to explain the structure of how we
      categorize types of Lube Oil in StarConnect. Lube oil module is replacing
      the MSPS Lube oil reporting functions and is introducing new functions for
      vessel and shore to enable better tracking of events. We will start with
      low functionality and improve the module with new SC releases.
    </p>
    <h4>Lube oil management overview page layout</h4>
    <p>
      Click Lube Management in the navigation menu on the left hand side to
      arrive at the Lube Management module.
    </p>
    <img src={imgOverview} alt='Overview' />
    <p>In the Lube oil module, there are 6 functions for the user:</p>
    <ul>
      <li>Add Sounding event</li>
      <li>Add Bunkering event</li>
      <li>Add Transfer event</li>
      <li>Add Loss event</li>
      <li>Use the filter function to sort in previously entered events</li>
      <li>Open, edit and delete previously entered events</li>
    </ul>
    <p className='title'>Definition of oil types and sub-categories</p>
    <p>CLO = Cylinder Lubrication Oil and the sub-categories are:</p>
    <ul>
      <li>
        SPARE CLEAN = bunker/storage tank(s); you can have multiple rows to
        reflect different TBN values
      </li>
      <li>
        DAY TANK(s); you can only record 1 row here, therefore you should sum up
        all day tanks (if there are multiple)
      </li>
    </ul>
    <p>
      CLO ADDITIVES = Additives needed for Cylinder Oil “Blending On-Board”
      (BOB) vesselsand the sub-category is and the sub-category is:
    </p>
    <ul>
      <li>
        SPARE CLEAN = bunker/storage tank(s); you can have multiple rows to
        reflect different TBN values
      </li>
    </ul>
    <p className='note'>Note: only enter values here if you are a BOB vessel</p>
    <p>ME = Main Engine System Oil, and the sub-categories are:</p>
    <ul>
      <li>
        SPARE CLEAN = bunker/storage tank(s), oil not previous used in main
        engine(s)
      </li>
      <li>WORKING = main engine sump tank(s)</li>
      <li>
        SPARE DIRTY = settling/storage tank(s), oil previous used in main
        engine(s)
      </li>
    </ul>
    <p>AE = Auxiliary Engines System Oil, and the sub-category is:</p>
    <ul>
      <li>
        SPARE CLEAN = bunker/storage tank(s), oil not previous used in auxiliary
        engine(s)
      </li>
    </ul>
    <p>
      OTHER = all other oils not belonging to the above categories, normally
      grease - Hydraulic. Oil etc. in smaller quantities
    </p>
    <ul>
      <li>SPARE CLEAN = bunker/storage tank(s)</li>
    </ul>
    <p className='note'>
      Note: In OTHER oil section, you can add as many rows/oil types as you
      wish, in order to keep track of your Other oils / greases.
    </p>
    <p className='title'>Notifications</p>
    <p>
      A notification will pop-up when the period for Month End Sounding starts
      (which is from 25th to the end of the month) and will disappear after a
      Full sounding is entered. BOB vessels will also have to ensure that
      transferred base oil (ME, Spare Dirty) amount is updated just prior to
      entering the Full sounding event.
    </p>
    <img
      className='long'
      src={imgOverviewNotifications}
      alt='Overview notifications'
    />
  </div>
))
