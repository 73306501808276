import styled from 'styled-components'

export const DateTimeWrapper = styled.div`
  display: flex;
  gap: 12px;

  mc-input-date {
    flex-grow: 1;
  }
`

export const Row = styled.div`
  margin-top: 24px;
`

export const SparePartsWrapper = styled.div`
  margin-top: 8px;
`
