import React from 'react'
import styled from 'styled-components'
import moment from 'moment'

import { getVesselName } from '../ClassComparisonPage.utils'
import { prettyDuration } from '../../../features/hybrid-data-collector/utils'

type Props = {
  tabIndex: number
  reportsCompared: Array<GandalfApi.MetcReport>
  children: React.ReactNode
  sisterVesselsData: MasterDataApi.SisterVessel.SisterVesselResponse
}

const EnginePerformanceTestReportsTable = ({
  tabIndex,
  reportsCompared,
  children,
  sisterVesselsData,
}: Props) => (
  <Wrapper
    slot='panel'
    className='mds-table mds-table--header-sticky mds-table--scrollable'
  >
    <table>
      <thead>
        <tr>
          <th />
          {[1, 2].includes(tabIndex) && (
            <th className='mds-table__cell--text-right'>
              {tabIndex === 1 ? 'Cyl. #' : 'TC #'}
            </th>
          )}
          {reportsCompared.map((rc) => (
            <th key={rc.reportId} className='mds-table__cell--text-right'>
              <div>{getVesselName(+rc.imoNo, sisterVesselsData)}</div>
              <div className='mds-table__subtext'>
                Report #{rc.reportNo} (
                {moment(rc.startTimestamp).format('DD MMM YYYY')})
              </div>
              <div className='mds-table__subtext'>
                Duration:{' '}
                {prettyDuration(
                  moment.utc(rc.startTimestamp),
                  moment.utc(rc.endTimestamp),
                )}
              </div>
            </th>
          ))}
          <th className='mds-table__cell--text-right'>
            <div>Difference [%]</div>
            <div className='mds-table__subtext'>&nbsp;</div>
          </th>
        </tr>
      </thead>
      <tbody>{children}</tbody>
    </table>
  </Wrapper>
)

export default EnginePerformanceTestReportsTable

const Wrapper = styled.div`
  margin-bottom: 64px;
`
