import React from 'react'
import { Field, getIn } from 'formik'
import { InfoBox } from '../../../commons'
import { errorTheme } from '../../../theme'

interface ErrorMessageProps {
  name: string
  width?: string
  showErrorsForUntouchedFields?: boolean
  children?: React.ReactNode
}

export const ErrorMessage = ({
  name,
  showErrorsForUntouchedFields,
  width,
}: ErrorMessageProps) => (
  <Field name={name}>
    {({ form }) => {
      const error = getIn(form.errors, name)
      const touch = getIn(form.touched, name)
      const shouldShowError = showErrorsForUntouchedFields
        ? error && typeof error == 'string'
        : touch && error && typeof error == 'string'
      return shouldShowError ? (
        <InfoBox hideIcons={true} width={width} theme={errorTheme}>
          <small>{error}</small>
        </InfoBox>
      ) : null
    }}
  </Field>
)
