import * as echarts from 'echarts/core'
import { type EChartsOption, type EChartsType } from 'echarts/types/dist/shared'
import { LineChart } from 'echarts/charts'
import {
  DatasetComponent,
  GridComponent,
  LegendComponent,
  TooltipComponent,
} from 'echarts/components'
import { UniversalTransition } from 'echarts/features'
import { CanvasRenderer } from 'echarts/renderers'

import {
  AXIS_LABEL,
  AXIS_TICK,
  COLORS,
  GRID,
  NAME_LOCATION,
  TEXT_STYLE,
  TOOLTIP,
  X_AXIS_NAME_GAP,
} from '../../Common/ChartConfig'
import { formatValue } from '../../../../../utils'

echarts.use([
  CanvasRenderer,
  DatasetComponent,
  GridComponent,
  LegendComponent,
  LineChart,
  TooltipComponent,
  UniversalTransition,
])

type ChartConfig = {
  domId: string
  xLabel: string
  xUnit: string
  yLabel: string
  yUnit: string
  truncateYAxis?: number
}

export const initChart = (config: ChartConfig) => {
  const chartDom = document.getElementById(config.domId)

  if (!chartDom) {
    console.error(`Chart DOM element with ID '${config.domId}' not found`)
    return
  }

  const options: EChartsOption = {
    grid: {
      ...GRID,
      bottom: 32,
      top: 32,
      left: 48,
      right: 40,
    },
    legend: {
      selectedMode: false,
      top: 0,
      right: 40,
      textStyle: TEXT_STYLE,
      itemHeight: 8,
      itemWidth: 16,
    },
    // @ts-ignore
    tooltip: {
      ...TOOLTIP,
      formatter: (params: any) => {
        let result = `<strong style="font-variant: tabular-nums">${formatValue(
          params[0].axisValue,
          1,
        )}</strong> %MCR`
        params.forEach((item: any) => {
          result += `<br/>${item.marker} ${
            item.seriesName
          }: <strong style="font-variant: tabular-nums">${formatValue(
            item.data[1],
            1,
          )} ${config.yUnit}</strong>`
        })
        return result
      },
    },
    xAxis: {
      name: `${config.xLabel} [${config.xUnit}]`,
      nameLocation: NAME_LOCATION,
      nameGap: X_AXIS_NAME_GAP,
      axisLabel: AXIS_LABEL,
      nameTextStyle: AXIS_LABEL,
      axisTick: AXIS_TICK,
      min: 0,
      max: 100,
    },
    yAxis: {
      name: `${config.yLabel} [${config.yUnit}]`,
      nameLocation: NAME_LOCATION,
      nameTextStyle: AXIS_LABEL,
      axisLabel: AXIS_LABEL,
      axisTick: { show: false },
    },
    truncateYAxis: config.truncateYAxis,
  }

  const chart = echarts.init(chartDom)
  chart.setOption(options)

  return chart
}

export type ModelData = {
  x: Array<number>
  y: Array<number>
  name: string
}

export type TestData = {
  x: number | null
  y: number | null
  name: string
}

export const updateChart = (
  modelData: Array<ModelData>,
  testData: Array<TestData>,
  chart?: EChartsType,
) => {
  if (!chart) {
    console.error('Chart not initialized')
    return
  }

  const modelDataset = modelData.map((d) => ({
    // Transform and transpose d to match the dataset format:
    // from: { x: [1, 2, 3], y: [4, 5, 6] }
    // to:   { source: [[1, 4], [2, 5], [3, 6]] }
    source: d.x.map((x, i) => [x, d.y[i]]),
  }))

  const testDataset = testData.map((d) => ({
    source: [[d.x, d.y]],
  }))

  const modelSeries = modelData.map((d, i) => ({
    type: 'line',
    name: d.name,
    datasetIndex: i,
    symbolSize: 6,
    color: COLORS[i % 2],
  }))

  const testSeries = testData.map((d, i) => ({
    type: 'scatter',
    name: d.name,
    datasetIndex: modelSeries.length + i,
    symbol: 'circle',
    symbolSize: 8,
    color: COLORS[i % 2],
  }))

  const allModelYs = modelData.flatMap((d) => d.y).map((y) => Math.floor(y))
  const allTestYs = testData
    .map((d) => d.y)
    .map((y) => Math.floor(y || Infinity))

  const maxYTickLabelLength = String(Math.max(...allModelYs)).length

  let minY: number | undefined
  if (chart.getOption().truncateYAxis) {
    minY =
      Math.min(...allModelYs, ...allTestYs) -
      (chart.getOption().truncateYAxis as number)

    // Round down to nearest 10
    minY = Math.floor(minY / 10) * 10
  }

  chart.setOption({
    dataset: [...modelDataset, ...testDataset],
    series: [...modelSeries, ...testSeries],
    yAxis: {
      nameGap: maxYTickLabelLength > 3 ? 56 : 42,
      min: minY,
    },
  })
}
