import {
  purpleTheme,
  maritimeBlueTheme,
  seaGreenTheme,
  fillOpacity,
  maerskBlueTheme,
  orangeTheme,
} from '../../theme'

export const power = {
  stroke: purpleTheme.bg,
  fill: fillOpacity(purpleTheme, 0.2).bg,
  font: '#000',
}

export const shaftRpm = {
  stroke: maritimeBlueTheme.bg,
  fill: fillOpacity(maritimeBlueTheme, 0.2).bg,
  font: '#000',
}

export const stw = {
  stroke: seaGreenTheme.bg,
  fill: fillOpacity(seaGreenTheme, 0.2).bg,
  font: '#000',
}

export const consumption = {
  stroke: maerskBlueTheme.bg,
  fill: fillOpacity(maerskBlueTheme, 0.2).bg,
  font: '#000',
}

export const trim = {
  stroke: orangeTheme.bg,
  fill: fillOpacity(orangeTheme, 0.2).bg,
  font: '#000',
}
