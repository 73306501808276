import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: -12px;
  margin-bottom: -16px;
  padding-right: 32px;
  padding-bottom: 24px;
  //border-bottom: 2px solid var(--mds_brand_appearance_neutral_default_border-color);

  > div {
    > div {
      font-weight: bold;
    }
  }
`
