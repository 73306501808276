import styled from 'styled-components'

export const Wrapper = styled.div`
  .vessel-name {
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 8px;
    color: ${(props) => props.theme.colors.grey[700]};
  }

  .pills {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-grow: 1;
    margin-right: 12px;
  }

  button {
    display: flex;
    align-items: center;
    width: 100%;
    font-size: 16px;
    background-color: transparent;
    padding: 12px;
    text-align: left;

    &:hover {
      background-color: ${(props) => props.theme.colors.grey[100]};
    }

    svg {
      margin-right: 8px;
    }
  }

  .downwards {
    .vessel-name {
      color: ${(props) => props.theme.colors.grey[900]};
    }

    svg {
      transform: rotate(90deg);
      transition: transform 0.1s linear;
    }
  }

  .right {
    svg {
      transform: rotate(0deg);
      transition: transform 0.3s ease-out;
    }
  }

  .neglected-vessel {
    color: #a3a8ad;
    padding-left: 28px;
    cursor: auto;

    &:hover {
      background-color: transparent;
    }

    svg {
      height: 20px;
      width: 20px;
      fill: ${(props) => props.theme.colors.blue[300]};
    }
  }

  button:focus {
    outline: none;
  }

  .pages {
    background-color: ${(props) => props.theme.colors.grey[200]};
    box-sizing: border-box;
    gap: 0;
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      &:hover {
        background-color: ${(props) => props.theme.colors.grey[100]};
      }

      a {
        position: relative;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        padding: 8px 16px;
        color: ${(props) => props.theme.colors.grey[700]};
        text-decoration: none;
        width: 100%;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        border-left: transparent 0.4rem solid;

        svg {
          display: inline-block;
          font-size: inherit;
          height: 1em;
          overflow: visible;
          vertical-align: -0.125em;
          fill: #fff;
          width: 20px;
        }
      }

      a.active {
        background-color: ${(props) => props.theme.colors.grey[100]};
        color: ${(props) => props.theme.colors.grey[900]};
        border-left-color: ${(props) => props.theme.colors.blue[400]};

        svg {
          fill: #64b2d4;
          display: inline-block;
          font-size: inherit;
          height: 1em;
          overflow: visible;
          vertical-align: -0.125em;
        }
      }
    }
  }
`

export const GroupNameItem = styled.div`
  cursor: default;
  font-weight: bold;
  padding: 8px 16px;
  border-left: transparent 0.4rem solid;
`
