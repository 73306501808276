import React, { FC } from 'react'
import { StockNotificationColors } from '../../../theme'
import { InfoBox } from '../../../commons'
import { Performance } from '../../../api-models'

interface StockRobNotificationsProps {
  rob?: Performance.FuelOilStock.RobResponse
}

export const StockRobNotifications: FC<StockRobNotificationsProps> = (
  props,
) => {
  const { rob } = props
  return (
    <div>
      {rob?.notifications &&
        rob.notifications.map((notification, index) => (
          <InfoBox
            className='info-box'
            key={`stock-rob-notification-${index}`}
            theme={StockNotificationColors[notification.type]}
          >
            <small>{notification.message}</small>
          </InfoBox>
        ))}
    </div>
  )
}
