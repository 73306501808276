import React from 'react'

import { QueryPeriod } from '../../api-models/performance/common'
import {
  BatchMonitor,
  FuelConsumptionStatusResponse,
  Monitor,
} from '../../api-models/performance/fuel-consumption'
import { ManualCorrections } from '../../api-models/performance/manual-corrections'
import { FuelConsumptionState } from './reducer'

export interface RefreshFuelConsumption {
  (period?: QueryPeriod): Promise<
    [
      FuelConsumptionStatusResponse | null,
      ManualCorrections | null,
      BatchMonitor | null,
      Monitor | null,
    ]
  >
}
/**
 * FuelConsumptionContext
 * @description Context from where fuel consumption data can be fetched and mapped to various usages
 *
 * @property {string} imoNo a string representing the unique vessel ID.
 *
 * @property {object} consumption a map of diffrent data that presents: `monitor`, `losses`, `corrections` & `repairs`
 * @function getLosses(QueryPeriod) The data fetched by `getLosses` will be pushed to the key: `consumptionLosses` and be returned for any further usage.
 * @function getCorrections() The data fetched by `getLosses` will be pushed to the key: `consumptionCorrections` & `consumptionRepairs` an be returned for any further usage.
 *
 * @function getBatchMonitor Fetched batch monitor information used for the batch monitor graph in fuel consumption.
 * @param {QueryPeriod} period period that batch monitor should retrive data from.
 *
 * @function getOilMonitor Fetched oil monitor information used for the consumer graph in fuel consumption.
 * @param {QueryPeriod} period period that oil monitor should retrive data from.
 *
 * @function refreshFuelConsumption Refetches all fuel consumption data, used for refreshing after various actions, and initialize the consumption graphs.
 * @param {QueryPeriod} period period that all data fetches shares to be in line.  (Defaults to last 10 days)
 */
export interface IFuelConsumptionContext {
  imoNo: string | undefined
  consumption: FuelConsumptionState
  getStatus: () => Promise<FuelConsumptionStatusResponse | null>
  getCorrections: (period: QueryPeriod) => Promise<ManualCorrections | null>
  getBatchMonitor: (period: QueryPeriod) => Promise<BatchMonitor | null>
  getOilMonitor: (period: QueryPeriod) => Promise<Monitor | null>
  refreshFuelConsumption: RefreshFuelConsumption
  resetConsumption: () => void
}

export const FuelConsumptionContext =
  React.createContext<IFuelConsumptionContext>({} as IFuelConsumptionContext)
