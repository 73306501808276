import styled from 'styled-components'

export const Wrapper = styled.div`
  padding: 20px;
  width: 800px;
`

export const Container = styled.div`
  position: relative;
`

export const Actions = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  background-color: #f8f8f8;
  padding: 10px;
`

export const Description = styled.p`
  font-size: 16px;
  line-height: 1.4;
  margin-bottom: 15px;
`

export const Header = styled.h5`
  border-bottom: 2px solid #63b2d4;
  margin: 8px 0 16px !important;
`

export const Label = styled.p`
  font-size: 16px !important;
  font-weight: 400;
  margin: 24px 0 1px 0 !important;
`

export const ErrorLabel = styled(Label)`
  color: red;
`

export const Button = styled.div`
  margin: 32px 0;
`

export const Loading = styled.div`
  background-color: rgba(255, 255, 255, 0.95);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1;
`
