import * as yup from 'yup'

const TBN_MIN = 10
const TBN_MAX = 175

const consumptionValidationSchema = yup.object().shape({
  engineRoom: yup.object().shape({
    cylindersLubeOil: yup.array().of(
      yup.object().shape({
        consumptionLiters: yup.object().shape({
          value: yup.number().nullable().required('Consumption is required'),
        }),
        density: yup.object().shape({
          value: yup.number().nullable().required('Density is required'),
        }),
        avgTemperature: yup.object().shape({
          value: yup
            .number()
            .nullable()
            .required('Average temperature is required'),
        }),
        totalBaseNumber: yup.object().shape({
          value: yup
            .number()
            .nullable()
            .required('TBN is required')
            .min(TBN_MIN, `TBN should be in range ${TBN_MIN} to ${TBN_MAX}`)
            .max(TBN_MAX, `TBN should be in range ${TBN_MIN} to ${TBN_MAX}`),
        }),
        oilCode: yup.object().shape({
          value: yup.string().nullable().required('Oil code is required'),
        }),
      }),
    ),
  }),
})

export default consumptionValidationSchema
