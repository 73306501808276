import React from 'react'

import { Loading, Modal, ModalControls, Tabs } from '../../../commons'
import styled, { red } from '../../../theme'
import { displayErrorModal, isNumber, isShoreContext } from '../../../utils'
import { type Performance } from '../../../api-models'
import { VesselSettingsForm } from '../forms/VesselSettingsForm'
import { VesselSettingsTable } from '../tables/VesselSettingsTable'
import { SystemInfoTable } from '../tables/SystemInfoTable'
import VersionHistoryTable from '../tables/VersionHistory/VersionHistoryTable'
import { getVesselSettings } from '../../../services/performance'
import { McButton } from '@maersk-global/mds-react-wrapper'

export const Wrapper = styled.div`
  table {
    padding: 16px;

    td:last-of-type {
      width: 78px;
    }
  }

  .description {
    color: ${red[700]};
  }

  .logs {
    padding: 16px;
  }

  .form-error {
    color: ${red[500]};
  }
`

interface VesselSettingsProps {
  imoNo: string
  configuration: Performance.VesselConfiguration.Configuration
  vesselMetadata: MasterDataApi.VesselParameters.General.BasicInfo.Data
  closeHandler: () => void
}

interface VesselSettingsState {
  estimatedLeakage?: number
  requestInProgress?: boolean
  logs?: Performance.VesselSettings.SettingsLog[]
}

export default class VesselSettings extends React.Component<
  VesselSettingsProps,
  VesselSettingsState
> {
  constructor(props: VesselSettingsProps) {
    super(props)
    this.state = {
      requestInProgress: false,
    }
  }

  componentDidMount() {
    const { configuration } = this.props
    this.setState({ requestInProgress: true })

    const promises: Promise<any>[] = []
    if (configuration.hasManualOverflowEntry)
      promises.push(this.loadVesselSettings())

    Promise.all(promises)
      .then(() => this.setState({ requestInProgress: false }))
      .catch((err) => {
        void displayErrorModal(err)
        this.setState({ requestInProgress: false })
      })
  }

  loadVesselSettings() {
    const { imoNo } = this.props
    return getVesselSettings(imoNo).then(
      (json: Performance.VesselSettings.Settings) => {
        this.setState({
          estimatedLeakage: isNumber(json.estimatedLeakage)
            ? json.estimatedLeakage
            : undefined,
          logs: json.logs,
          requestInProgress: false,
        })
      },
    )
  }

  renderSettings() {
    const { closeHandler, imoNo, configuration } = this.props
    const { estimatedLeakage } = this.state
    const displayLeakage = configuration.hasManualOverflowEntry
    // This means that we have no settings
    if (!displayLeakage) {
      return null
    }
    return isShoreContext() ? (
      <VesselSettingsTable
        imoNo={imoNo}
        displayLeakage={displayLeakage}
        estimatedLeakage={estimatedLeakage}
        closeHandler={closeHandler}
      />
    ) : (
      <VesselSettingsForm
        displayLeakage={displayLeakage}
        initialLeakage={estimatedLeakage as number}
        closeHandler={closeHandler}
        imoNo={imoNo}
      />
    )
  }

  render() {
    const { closeHandler, imoNo, configuration, vesselMetadata } = this.props
    const { logs, requestInProgress } = this.state
    return requestInProgress ? (
      <Modal closeHandler={closeHandler} visible={true} title={'Processing'}>
        <Loading />
      </Modal>
    ) : (
      <Modal
        closeHandler={() => {
          closeHandler()
        }}
        helpTextKey={
          configuration.hasManualOverflowEntry ? 'vessel-settings-form' : ''
        }
        visible={true}
        title='Vessel settings'
      >
        <Wrapper>
          <Tabs
            startIndex={0}
            tabButtons={[
              {
                name: 'System info',
                onClick: () => {},
              },
              {
                name: 'Settings',
                onClick: () => {},
                hide: !configuration.hasManualOverflowEntry,
              },
              {
                name: 'History',
                onClick: () => {},
                hide: !configuration.hasManualOverflowEntry,
              },
            ]}
          >
            <div>
              <SystemInfoTable
                imoNo={imoNo}
                vesselName={vesselMetadata.name}
                flowMeterScenario={configuration.flowMeterScenario}
              />
              <ModalControls>
                <McButton
                  label='Close'
                  appearance='primary'
                  click={closeHandler}
                  type='button'
                />
              </ModalControls>
            </div>
            <div>{this.renderSettings()}</div>
            <div>
              <div className='logs'>
                <VersionHistoryTable logs={!!logs ? logs : []} />
              </div>
              <ModalControls>
                <McButton
                  label='Close'
                  appearance='primary'
                  click={closeHandler}
                  type='button'
                />
              </ModalControls>
            </div>
          </Tabs>
        </Wrapper>
      </Modal>
    )
  }
}
