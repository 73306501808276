import * as React from 'react'
import styled, { grey } from '../theme'

const SelectFieldWrapper = styled.div`
  position: relative;
  width: 100;

  select {
    border: 1px solid ${grey[400]} !important;
    border-radius: 4px;
    height: 34px;
    padding-left: 8px;
    width: 100px;
    cursor: pointer;
  }
  select:focus {
    box-shadow: 0 0 0 4px rgba(100, 178, 212, 0.2);
  }
  span.select-arrow-icon {
    pointer-events: none;
    border-color: #999 transparent transparent;
    border-style: solid;
    border-width: 5px 5px 2.5px;
    display: inline-block;
    height: 0;
    width: 0;
    position: absolute;
    top: 14px;
    right: 10px;
    z-index: 0;
  }
`

interface SelectFieldFilterProps {
  change: Function
  filter: any
  options: any[]
}

export class SelectFieldFilter extends React.Component<
  SelectFieldFilterProps,
  any
> {
  render() {
    return (
      <SelectFieldWrapper>
        <select
          onChange={(event) => this.props.change(event.target.value)}
          style={{ width: '100%' }}
          value={this.props.filter ? this.props.filter.value : 'all'}
        >
          <option value=''>All</option>
          {this.props.options.map((element) => {
            return (
              <option key={`option-${element}`} value={element}>
                {element}
              </option>
            )
          })}
        </select>
        <span className='select-arrow-icon' />
      </SelectFieldWrapper>
    )
  }
}
