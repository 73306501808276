import * as yup from 'yup'
import { DataPoint } from '../../../../api-models/hdc/report'
import { isNumber } from '../../../../utils'
import {
  FormMainEngine,
  HDCHoursMinutes,
  ValidationErrorType,
} from '../../types'

const emptyRunTimeError = (type: string) => `run ${type} cannot be empty.`
const negativeRunTimeError = (type: string) =>
  `run ${type} cannot be negative! Must atleast be 0.`

const createEngineName = (
  engineNumber: number,
  hasTwoMainEngines: boolean,
): string => {
  if (hasTwoMainEngines) {
    return engineNumber === 1
      ? 'Port side Main engine'
      : 'Stbd side Main engine'
  } else {
    return 'Main engine'
  }
}

const propulsionValidationSchema = yup.object().shape({
  engineRoom: yup.object().shape({
    mainEngines: yup.array().of(
      yup.object().shape({
        averagePower: yup
          .object()
          .test(
            ValidationErrorType.REQUIRED,
            '',
            function (power: DataPoint<number>) {
              const engine = this.parent as FormMainEngine
              const { context } = this.options as any

              const engineName = createEngineName(
                engine.number,
                context.vesselConfig.hasTwoMainEngines,
              )

              if (!isNumber(power.value)) {
                return this.createError({
                  message: `${engineName} avg. power cannot be empty`,
                  path: `${this.path}.value`,
                })
              }

              if (power.value < 0) {
                return this.createError({
                  message: `${engineName} avg. power cannot be negative! Must atleast be 0`,
                  path: `${this.path}.value`,
                })
              }

              return true
            },
          ),
        averageShaftRpm: yup
          .object()
          .test(
            ValidationErrorType.REQUIRED,
            '',
            function (shaftRpm: DataPoint<number>) {
              const engine = this.parent as FormMainEngine
              const { context } = this.options as any

              const engineName = createEngineName(
                engine.number,
                context.vesselConfig.hasTwoMainEngines,
              )

              if (!isNumber(shaftRpm.value)) {
                return this.createError({
                  message: `${engineName} avg. shaft rpm cannot be empty`,
                  path: `${this.path}.value`,
                })
              }

              if (shaftRpm.value < 0) {
                return this.createError({
                  message: `${engineName} avg. shaft rpm cannot be negative! Must atleast be 0`,
                  path: `${this.path}.value`,
                })
              }

              return true
            },
          ),
        runTime: yup
          .object()
          .test(
            ValidationErrorType.REQUIRED,
            '',
            function ({ hours, minutes }: HDCHoursMinutes) {
              const engine = this.parent as FormMainEngine
              const { context } = this.options as any

              const engineName = createEngineName(
                engine.number,
                context.vesselConfig.hasTwoMainEngines,
              )

              if (!isNumber(hours.value)) {
                return this.createError({
                  message: `${engineName} ${emptyRunTimeError('hours')}`,
                  path: this.path,
                })
              } else if (hours.value < 0) {
                return this.createError({
                  message: `${engineName} ${negativeRunTimeError('hours')}`,
                  path: this.path,
                })
              } else if (!isNumber(minutes.value)) {
                return this.createError({
                  message: `${engineName} ${emptyRunTimeError('minutes')}`,
                  path: this.path,
                })
              } else if (minutes.value < 0) {
                return this.createError({
                  message: `${engineName} ${negativeRunTimeError('minutes')}`,
                  path: this.path,
                })
              }

              return true
            },
          ),
        tcco: yup
          .object()
          .test(
            ValidationErrorType.REQUIRED,
            '',
            function (tcco: DataPoint<boolean>) {
              // in the event that TCCO is not on board the ship dont validate it.
              if (tcco === undefined) {
                return true
              }

              const engine = this.parent as FormMainEngine
              const { context } = this.options as any

              const engineName = createEngineName(
                engine.number,
                context.vesselConfig.hasTwoMainEngines,
              )

              if (tcco.value === null) {
                return this.createError({
                  message: `TCCO for engine ${engineName} is missing`,
                  path: this.path,
                })
              }

              return true
            },
          )
          .nullable(),
      }),
    ),
  }),
})

export default propulsionValidationSchema
