import * as React from 'react'
import styled, { grey, maritimeBlue, sizes } from '../../../theme'
import { SimpleDial } from './SimpleDial'
import { Performance } from '../../../api-models'
import { formatValue } from '../../../utils'
import { withFeatureToggling } from '../../../higher-order-components'

const Wrapper = styled.div<{ minHeight?: number; hasTwoMainEngines: boolean }>`
  display: flex;
  justify-content: center;
  width: ${(props) => (props.hasTwoMainEngines ? sizes.half : sizes.quarter)}px;
  .dials {
    width: ${sizes.quarter}px;
    min-height: ${(props) =>
      props.minHeight ? `${props.minHeight}px` : 'initial'};
    &.border {
      border-right: 1px solid ${grey[200]};
    }
  }
  align-items: center;
`

interface MainEngineLimit {
  value: number | null
  label: string
  hasDialHeader: boolean
  className: string
}

export interface MainEnginePowerDialProps {
  vesselStatus: Performance.Status.Status
  vesselConfiguration: Performance.VesselConfiguration.Configuration
  minHeight?: number
}

export interface MainEnginePowerDialState {}

export class MainEnginePowerDial extends React.Component<
  MainEnginePowerDialProps,
  MainEnginePowerDialState
> {
  getEngLimits = (
    mainEngConfig: Performance.VesselConfiguration.MainEng,
  ): Array<MainEngineLimit> => {
    const { vesselConfiguration } = this.props
    const whr = vesselConfiguration.whr
    const isDualEngine = vesselConfiguration.hasTwoMainEngines
    return [
      {
        value: mainEngConfig ? mainEngConfig.tccoLimit : null,
        label: 'TCCO',
        hasDialHeader: isDualEngine,
        className: 'tcco',
      },
      {
        value: mainEngConfig ? mainEngConfig.auxBlowersLimit : null,
        label: 'Blower',
        hasDialHeader: isDualEngine,
        className: 'blower',
      },
      {
        value: whr ? whr.powerTurbineLimit : null,
        label: 'PT',
        hasDialHeader: isDualEngine,
        className: 'power-turbine',
      },
      {
        value: whr ? whr.steamTurbineLimit : null,
        label: 'STG',
        hasDialHeader: isDualEngine,
        className: 'steam-turbine-generator',
      },
    ] as any
  }

  public render() {
    const { vesselStatus, vesselConfiguration, minHeight } = this.props
    const isDualEngine = vesselConfiguration.hasTwoMainEngines
    const padding = isDualEngine ? 48 : 32
    const { mainEng1, mainEng2 } = vesselStatus
    return (
      <Wrapper
        minHeight={minHeight}
        hasTwoMainEngines={vesselConfiguration.hasTwoMainEngines}
      >
        <div className={`dials ${isDualEngine ? 'border' : ''}`}>
          <SimpleDial
            id='dials-me-p-1'
            value={mainEng1 ? mainEng1.load : null}
            color={maritimeBlue[500]}
            minValue={0}
            maxValue={100}
            unit='% MCR'
            label={
              <g>
                <text
                  x='50%'
                  y={90 + padding - 10}
                  textAnchor='middle'
                  fill={grey[900]}
                  alignmentBaseline='baseline'
                >
                  {mainEng1 ? `${formatValue(mainEng1.power, 0)} ` : '- '}
                  <tspan fill={grey[500]} fontSize='14px'>
                    MW
                  </tspan>
                </text>
                <text
                  x='50%'
                  y={90 + padding + 10}
                  textAnchor='middle'
                  fill={grey[900]}
                  fontSize='16px'
                  alignmentBaseline='baseline'
                >
                  {mainEng1 ? `${formatValue(mainEng1.load, 0)} ` : '- '}
                  <tspan fill={grey[500]} fontSize='14px'>
                    % MCR
                  </tspan>
                </text>
              </g>
            }
            dialHeader={isDualEngine ? 'Port' : ''}
            referenceLines={this.getEngLimits(vesselConfiguration.mainEng1)}
          />
        </div>
        {isDualEngine && (
          <div className='dials'>
            <SimpleDial
              id='dials-me-p-2'
              value={mainEng2 ? mainEng2.load : 0}
              color={maritimeBlue[500]}
              minValue={0}
              maxValue={100}
              unit='% MCR'
              dialHeader={'Stbd'}
              label={
                <g>
                  <text
                    x='50%'
                    y={90 + padding - 10}
                    textAnchor='middle'
                    fill={grey[900]}
                    alignmentBaseline='baseline'
                  >
                    {mainEng2 ? `${formatValue(mainEng2.power, 0)} ` : '- '}
                    <tspan fill={grey[700]} fontSize='14px'>
                      MW
                    </tspan>
                  </text>
                  <text
                    x='50%'
                    y={90 + padding + 10}
                    textAnchor='middle'
                    fill={grey[900]}
                    fontSize='16px'
                    alignmentBaseline='baseline'
                  >
                    {mainEng2 ? `${formatValue(mainEng2.load, 0)} ` : '- '}
                    <tspan fill={grey[700]} fontSize='14px'>
                      % MCR
                    </tspan>
                  </text>
                </g>
              }
              referenceLines={this.getEngLimits(vesselConfiguration.mainEng2)}
            />
          </div>
        )}
      </Wrapper>
    )
  }
}

export default withFeatureToggling(MainEnginePowerDial)
