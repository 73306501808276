import { useState } from 'react'
import moment from 'moment'
import {
  McButton,
  McModal,
  McTextarea,
  McIcon,
} from '@maersk-global/mds-react-wrapper'

import { Wrapper } from './NetPromoterScoreModal.styles'
import {
  useCreateNotification,
  useNotificationsApiNpsFeedback,
} from '../../../queries/NotificationsApi/NotificationsApi'
import {
  NotificationCategories,
  NotificationTypes,
} from '../../../queries/NotificationsApi/NotificationsApi.consts'

const NetPromoterScoreModal = () => {
  const dataVersion = 1
  const defaultIconColor = '#000000'
  const [modalClosed, setModalClosed] = useState(false)
  const [npsRating, setNpsRating] = useState<number | null>(null)
  const [npsInformation, setNpsInformation] = useState('')
  const postNotification = useCreateNotification()

  const filter: NotificationsAPI.NotificationsQueryFilter = {
    category: NotificationCategories.Energy,
    type: NotificationTypes.NPS,
    started_at: moment().utc().subtract(1, 'months').format('YYYY-MM-DD'),
    ended_at: moment().utc().add(1, 'days').format('YYYY-MM-DD'),
  }
  const isNpsNotificationEmpty = useNotificationsApiNpsFeedback(filter)
  if (
    !isNpsNotificationEmpty.isSuccess ||
    !isNpsNotificationEmpty.data ||
    modalClosed
  ) {
    return null
  }

  const handleNpsInformationInputChange = ({ target }: InputEvent) => {
    const value = (target as HTMLTextAreaElement).value
    setNpsInformation(value)
  }

  const npsModalSubmit = async () => {
    if (!npsRating) return
    const input: NetPromoterScoreModalTypes.SubmitModalInputs = {
      dataVersion: dataVersion,
      rating: npsRating,
      comment: npsInformation,
    }
    const notificationToBeCreated: NotificationsAPI.NotificationPayload = {
      category: NotificationCategories.Energy,
      type: NotificationTypes.NPS,
      data: JSON.stringify(input),
    }
    try {
      await postNotification.mutateAsync(notificationToBeCreated)
    } catch (error) {
      setModalClosed(true)
    }
    setModalClosed(true)
  }

  const handleRatingIconClick = (rating: number) => {
    setNpsRating(rating)
  }

  return (
    <Wrapper>
      <form onSubmit={npsModalSubmit}>
        <McModal
          heading='Let Your Voice Be Heard...! '
          open
          height='auto'
          hiddenclose
          fit='medium'
          dimension='small'
          escapecloseactiondisabled
          backdropcloseactiondisabled
        >
          <div className='net-score-promoter-radio'>
            <McIcon
              icon='face-very-sad'
              size='24'
              className='radioIcon'
              onClick={() => handleRatingIconClick(1)}
              color={
                npsRating === 1
                  ? 'var(--mds_brand_appearance_success_default_text-color)'
                  : defaultIconColor
              }
            />

            <McIcon
              icon='face-sad'
              size='24'
              className='radioIcon'
              onClick={() => handleRatingIconClick(2)}
              color={
                npsRating === 2
                  ? 'var(--mds_brand_appearance_success_default_text-color)'
                  : defaultIconColor
              }
            />

            <McIcon
              icon='face-indifferent'
              size='24'
              className='radioIcon'
              onClick={() => handleRatingIconClick(3)}
              color={
                npsRating === 3
                  ? 'var(--mds_brand_appearance_success_default_text-color)'
                  : defaultIconColor
              }
            />

            <McIcon
              icon='face-happy'
              size='24'
              className='radioIcon'
              onClick={() => handleRatingIconClick(4)}
              color={
                npsRating === 4
                  ? 'var(--mds_brand_appearance_success_default_text-color)'
                  : defaultIconColor
              }
            />

            <McIcon
              icon='face-very-happy'
              size='24'
              className='radioIcon'
              onClick={() => handleRatingIconClick(5)}
              color={
                npsRating === 5
                  ? 'var(--mds_brand_appearance_success_default_text-color)'
                  : defaultIconColor
              }
            />
          </div>

          <McTextarea
            name='additionalFeedback'
            label='Additional feedback'
            placeholder=''
            fit='medium'
            maxlength={250}
            rows={5}
            input={handleNpsInformationInputChange}
            value={npsInformation}
          />

          <McButton
            slot='primaryAction'
            type='submit'
            dialogaction='ok'
            disabled={npsRating === null}
          >
            Submit
          </McButton>
        </McModal>
      </form>
    </Wrapper>
  )
}

export default NetPromoterScoreModal
