import { useContext } from 'react'
import { McIcon } from '@maersk-global/mds-react-wrapper'

import { IconCell } from '../Table/Table.styles'
import { ReportState } from '../../queries/GandalfApi/GandalfApi.consts'
import { WindowContext } from '../../contexts'

type Props = {
  state: GandalfApi.ReportState
}

export const ReportStateTableCell = ({ state }: Props) => {
  const { windowSize } = useContext(WindowContext)

  if (isDraft(state)) {
    return (
      <span style={{ display: 'inline-flex' }}>
        <McIcon icon='pencil' size={windowSize === 'small' ? '20' : '24'} />
        &nbsp;Draft
      </span>
    )
  } else if (isSubmitted(state)) {
    return (
      <span
        style={{
          display: 'inline-flex',
          color: 'var(--mds_brand_appearance_success_default_text-color)',
        }}
      >
        <McIcon
          icon='check-circle'
          size={windowSize === 'small' ? '20' : '24'}
          color='var(--mds_brand_appearance_success_default_text-color)'
        />
        &nbsp;Submitted
      </span>
    )
  } else {
    return state
  }
}

export const ReportSubmittedTableCell = ({ state }: Props) => {
  const { windowSize } = useContext(WindowContext)

  return (
    <IconCell>
      {(isSubmitted(state) && (
        <McIcon
          icon='check-circle'
          size={windowSize === 'small' ? '20' : '24'}
          color='var(--mds_brand_appearance_success_default_text-color)'
        />
      )) ||
        '-'}
    </IconCell>
  )
}

const isDraft = (state: string): boolean => {
  return state.toLowerCase() === ReportState.Draft
}

const isSubmitted = (state: string): boolean => {
  return state.toLowerCase() === ReportState.Submitted
}
