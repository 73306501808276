import moment from 'moment/moment'

import { DATE_TIME_FORMAT } from '../../../components/formik/InputDateTime/InputDateTime.utils'

export const booleanToYesNo = (value: boolean | null): string => {
  if (value === null) {
    return '-'
  }

  return value ? 'Yes' : 'No'
}

export const withSuffix = (value: number | null, suffix: string): string => {
  if (value === null) {
    return '-'
  }

  return `${value} ${suffix}`
}

export const formatDate = (date: string | null): string => {
  if (date === null) {
    return '-'
  }

  return moment.utc(date).format(DATE_TIME_FORMAT)
}
