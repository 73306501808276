import * as yup from 'yup'
import { ELubeOilType } from '../types'
import {
  getQuantityValidationSchema,
  integerValidationSchema,
  OIL_TYPE_TO_TBN_RANGE,
  REQUIRED_MESSAGE,
  timestampValidationSchema,
} from './utils'

const MAX_QUANTITY = 200000
const MIN_QUANTITY = 1

const bunkeringValidationSchema = yup.object().shape({
  deliveryDetails: yup.object().shape({
    orderId: yup.string().required(REQUIRED_MESSAGE),
    portCode: yup.string().required(REQUIRED_MESSAGE),
    quantityAgreed: getQuantityValidationSchema(MIN_QUANTITY, MAX_QUANTITY),
    quantityOrdered: getQuantityValidationSchema(MIN_QUANTITY, MAX_QUANTITY),
  }),
  oilType: yup.number().nullable().required(REQUIRED_MESSAGE),
  quantityPerChiefEngineer: getQuantityValidationSchema(
    MIN_QUANTITY,
    MAX_QUANTITY,
  ).required(REQUIRED_MESSAGE),
  tbn: yup.number().when('oilType', {
    is: ELubeOilType.Other,
    then: integerValidationSchema.oneOf(
      [null],
      'Not applicable for oil type Other',
    ),
    otherwise: integerValidationSchema
      .test('is-required', REQUIRED_MESSAGE, function (value) {
        const { oilType } = this.parent

        if (!oilType) {
          return true
        }

        return !!value
      })
      .test('is-in-range', '', function (value) {
        const { oilType } = this.parent

        if (!oilType) {
          return true
        }

        const [min, max] = OIL_TYPE_TO_TBN_RANGE[oilType]

        if (value < min || value > max) {
          return this.createError({
            path: this.path,
            message: `Should be in range ${min} to ${max}`,
          })
        }

        return true
      }),
  }),
  timestamp: timestampValidationSchema,
})

export default bunkeringValidationSchema
