import React, { useEffect } from 'react'
import moment, { type Moment } from 'moment'

import { Performance } from '../../../../../api-models'
import { FuelType } from '../../../../../utils'
import { usePrevious } from '../../../../../hooks'
import { sortBatchesByTimestampDescending } from '../../../../stock-management/utils'

type Props = {
  batches?: Performance.FuelOilStock.BatchResponse[]
  batchId: string
  timeOfBatchChangeover?: Moment
  currentlySelectedBatch?: Performance.FuelOilStock.CurrentBatchSelectionResponse
  fuelTypeForBatchSelection: FuelType
  setBatchOptions: React.Dispatch<React.SetStateAction<any[]>>
  batchOptions: any[]
  setFieldTouched: (
    field: string,
    isTouched?: boolean | undefined,
    shouldValidate?: boolean | undefined,
  ) => void
  readonly?: boolean
}

export const BatchFiltering = ({
  batches,
  batchId,
  timeOfBatchChangeover,
  currentlySelectedBatch,
  fuelTypeForBatchSelection,
  setBatchOptions,
  batchOptions,
  setFieldTouched,
  readonly,
}: Props) => {
  const prevBatchOptions = usePrevious(batchOptions)

  useEffect(() => {
    if (batches) {
      setBatchOptions(
        // only show batches that were bunkered before selected timestamp
        // if a batch is already selected don't include it as an option
        // if no batch at all is selected, return true (!currentlySelectedBatch)
        // only show batches of given fuel type.
        batches
          .filter(
            (batch) =>
              moment
                .utc(batch.timestamp)
                .isSameOrBefore(timeOfBatchChangeover) &&
              ((currentlySelectedBatch &&
                currentlySelectedBatch.batchId !== batch.id) ||
                !currentlySelectedBatch) &&
              batch.fuel.type === fuelTypeForBatchSelection,
          )
          .sort(sortBatchesByTimestampDescending)
          .map(({ displayName, id, hasLabReport }) => ({
            label: displayName,
            value: id,
            isDisabled: !hasLabReport,
          })),
      )
    }
  }, [
    batches,
    timeOfBatchChangeover,
    fuelTypeForBatchSelection,
    currentlySelectedBatch,
    setBatchOptions,
  ])

  useEffect(() => {
    /* Checking if prevBatchOptions is undefined prevents validation before initialization */
    /* And we only want to validate batchId if it is set */
    if (!readonly && !!prevBatchOptions && batchId) {
      setFieldTouched('batchId', true, true)
    }
  }, [batchId, prevBatchOptions, setFieldTouched, readonly])

  return null
}
