import { formatValue } from '../../../utils'
import { getDifference } from './EnginePerformanceTestReportsComparison.utils'

type Props = {
  reportsCompared: Array<GandalfApi.MetcReport>
}

const Cylinders = ({ reportsCompared }: Props) => {
  const a = reportsCompared[0].data
  const b = reportsCompared[1].data

  const renderRow = (header: string, field: string) => (
    <tr>
      <td>{header}</td>
      <td className='mds-table__cell--number'>
        {Array.from({ length: a.cylinders.length }).map((_, i) => (
          <div key={i}>{i + 1}</div>
        ))}
      </td>
      {reportsCompared.map((rc) => (
        <td key={rc.reportId} className='mds-table__cell--number'>
          {rc.data.cylinders.map((c) => (
            <div key={`${rc.reportId}_${c.number}`}>
              {formatValue(c[field].value, 2)}
            </div>
          ))}
        </td>
      ))}
      <td className='mds-table__cell--number'>
        {a.cylinders.map((cylinder, idx) => (
          <div key={cylinder.number}>
            {getDifference(
              cylinder[field].value!,
              b.cylinders[idx][field].value!,
            )}
          </div>
        ))}
      </td>
    </tr>
  )

  return (
    <>
      {renderRow('Pmax [bar]', 'pmax')}
      {renderRow('Pcomp [bar]', 'pcomp')}
      {renderRow('MIP [bar]', 'mip')}
      {renderRow('Exh. Gas Out. [°C]', 'exhaustGasTempOut')}
    </>
  )
}

export default Cylinders
