import React, { useContext, useMemo, useState } from 'react'
import { McButton, McPagination } from '@maersk-global/mds-react-wrapper'

import { default as releaseNotes } from './ReleaseNotes.json'
import { scrollTop, VISIBLE_COUNT } from './ReleaseNotes.utils'
import { PaginationWrapper, Wrapper } from './ReleaseNotes.styles'
import { WindowContext } from '../../../contexts'

const pageCount = Math.round(releaseNotes.length / VISIBLE_COUNT)

const ReleaseNotes = () => {
  const { windowSize } = useContext(WindowContext)
  const [selectedPage, setSelectedPage] = useState(1)

  const startIndex = VISIBLE_COUNT * (selectedPage - 1)
  const endIndex = selectedPage * VISIBLE_COUNT - 1

  const visibleReleaseNotes: Array<ReleaseNote> = useMemo(
    () =>
      releaseNotes
        .slice(0)
        .reverse()
        .filter((_, i) => i >= startIndex && i <= endIndex),
    [startIndex, endIndex],
  )

  const handlePageChange = ({ detail: newPage }: CustomEvent<number>) => {
    setSelectedPage(newPage)
  }

  return (
    <Wrapper>
      {visibleReleaseNotes.map((note) => (
        <React.Fragment key={note.version}>
          <h4>v{note.version}</h4>
          <hr />
          {note.sections.map((section, index) => (
            <React.Fragment key={index}>
              <h5>{section.title}</h5>
              <p>{section.description}</p>
            </React.Fragment>
          ))}
        </React.Fragment>
      ))}
      <PaginationWrapper>
        <McPagination
          hiddenlabels
          fit={windowSize}
          totalpages={pageCount}
          currentpage={selectedPage}
          visiblepages={5}
          pagechange={handlePageChange}
        />
        <McButton
          fit={windowSize}
          label='Back to Top'
          appearance='neutral'
          click={scrollTop}
        />
      </PaginationWrapper>
    </Wrapper>
  )
}

export default ReleaseNotes
