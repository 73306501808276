import React from 'react'

import { DefaultChartWrapper } from './TrimChart.styles'
import { getUnderKeelValue } from '../TrimPage.utils'
import vesselIconNormal from '../../../assets/images/vesselIconNormal.svg'
import { formatValue } from '../../../utils'

type Props = {
  data: TrimChartData
  hideTopRow?: boolean
  ChartWrapper?: any
}

export default class TrimChart extends React.PureComponent<Props, any> {
  render() {
    const {
      trimDegrees,
      aftDraught,
      meanDraught,
      foreDraught,
      trim,
      isHogging,
      hullDeflection,
      waterDepth,
    } = this.props.data
    const { hideTopRow } = this.props
    const ChartWrapper = this.props.ChartWrapper
      ? this.props.ChartWrapper
      : DefaultChartWrapper

    return (
      <ChartWrapper rotation={trimDegrees ? trimDegrees : 0}>
        {!hideTopRow && (
          <div>
            <div className='valueContainer top left'>
              <span className='value-title'>Trim</span>
              <span className='values'>
                {trim === null ? '-' : `${formatValue(trim, 1)} m`}
              </span>
            </div>

            <div className='valueContainer top middle'>
              <span className='value-title'>
                {isHogging ? 'Hogging' : 'Sagging'}
              </span>
              <span className='values'>
                {hullDeflection === null
                  ? '-'
                  : `${formatValue(hullDeflection, 1)} m`}
              </span>
            </div>

            <div className='valueContainer top right'>
              <span className='value-title'>Under keel clearance</span>
              <span className='values'>{getUnderKeelValue(waterDepth)}</span>
            </div>
          </div>
        )}

        <hr className='line' />
        <img
          className='vesselIcon'
          src={vesselIconNormal}
          style={{ transition: '1s ease-in-out 0.2s' }}
          alt=''
        />

        <div className='valueContainer bottom left'>
          <span className='value-title'>Aft draught</span>
          <span className='values'>
            {aftDraught === null ? '-' : `${formatValue(aftDraught, 1)} m`}
          </span>
        </div>

        <div className='valueContainer bottom middle'>
          <span className='value-title'>Mid draught</span>
          <span className='values'>
            {meanDraught === null ? (
              '-'
            ) : (
              <React.Fragment>
                <span>{formatValue(meanDraught, 1)}</span> m
              </React.Fragment>
            )}
          </span>
        </div>

        <div className='valueContainer bottom right'>
          <span className='value-title'>Fore draught</span>
          <span className='values'>
            {foreDraught === null ? '-' : `${formatValue(foreDraught, 1)} m`}
          </span>
        </div>
      </ChartWrapper>
    )
  }
}
