import { useFormikContext } from 'formik'

import { Row } from '../MalfunctioningEquipmentReportPage.styles'
import { booleanOptions } from '../MalfunctioningEquipmentReportPage.utils'
import MfeInputRadioGroup from '../MfeInputRadioGroup/MfeInputRadioGroup'
import { PadContent } from '../../../../layout/styles'

const Solution = () => {
  const { values: report, setFieldValue } =
    useFormikContext<GandalfApi.MfeReport>()

  const handleRequireSparePartsChange = (requireSpareParts: boolean) => {
    if (!requireSpareParts) {
      void setFieldValue('data.solution.haveSpareParts', null)
    }
  }

  return (
    <PadContent>
      <h3>Solution</h3>
      <Row>
        <MfeInputRadioGroup<boolean>
          name='data.solution.requireSpareParts'
          label='Do you require spare parts to fix the problem?'
          options={booleanOptions}
          onChange={handleRequireSparePartsChange}
        />
        {report.data.solution.requireSpareParts && (
          <>
            <MfeInputRadioGroup<boolean>
              name='data.solution.haveSpareParts'
              label='Do you have spare parts on board?'
              options={booleanOptions}
            />
          </>
        )}
      </Row>
    </PadContent>
  )
}

export default Solution
