import React, { useState, FunctionComponent } from 'react'
import {
  useFormikContext,
  getIn,
  FieldArray,
  FieldArrayRenderProps,
} from 'formik'
import { SoundingTableRow } from './SoundingTableRow'
import { VolumeToMassCalculator } from '../../../volume-to-mass-calculator'
import { VolumeToMassCalculatorState } from '../../../volume-to-mass-calculator/components/VolumeToMassCalculator'
import { SimpleTable } from '../../../../commons'

import styled, {
  lightBlueColorScheme,
  mariTimeColorScheme,
  purpleColorScheme,
  lightOrangeColorScheme,
  maritimeBlue,
} from '../../../../theme'

const Spacer = styled.tr`
  height: 6px;
`

const Wrapper = styled.div`
  max-width: 600px;
  table {
    min-width: 500px;
    overflow-x: auto;
    table-layout: fixed;
    white-space: nowrap;

    width: 100%;
    font-size: 14px;
    border-collapse: separate;
    border-spacing: 0px;
    tbody tr:hover {
      background-color: rgba(100, 178, 212, 0.1);
    }
    th {
      text-align: left;

      div {
        &:nth-child(2) {
          text-align: left;
          font-style: italic;
          font-weight: 500;
          width: 100%;
        }
      }

      &.fuel-type {
        width: 40%;
      }
      &.sounding {
        width: 30%;
      }
      &.calculated,
      &.adjustment {
        width: 15%;
      }
    }
    td {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      position: relative;
      padding: 8px;
      border-top: 0;
      border-left: none;
      &:last-child {
        border-right: none;
      }

      &:first-child {
        text-align: left;
      }
      span.calculator {
        padding-left: 16px;
        display: flex;
        align-items: center;
        cursor: pointer;
        > i {
          font-size: 16px;
          line-height: 16px;
          > svg {
            color: ${maritimeBlue[500]};
            height: 24px;
            width: 24px;
          }
        }
      }
      text-align: right;
    }
  }
`

const roundedValue = (val: number): number =>
  Math.round((val + Number.EPSILON) * 100) / 100

export const SoundingTable: FunctionComponent<{
  isShoreMode: boolean
  readonly: boolean
}> = ({ isShoreMode, readonly }) => {
  const [calculatorStore, setCalculatorStore] = useState({})
  const [batchName, setBatchName] = useState<string>()
  const { values, setFieldValue, setFieldTouched } = useFormikContext<any>()

  return (
    <Wrapper>
      <SimpleTable>
        <thead>
          <tr>
            <th className='fuel-type'>
              <div>Fuel</div>
              <div>&nbsp;</div>
            </th>
            <th className='calculated'>
              <div>Calculated</div>
              <div>MT</div>
            </th>
            <th className='sounding'>
              <div>Sounding as per C/E</div>
              <div>MT</div>
            </th>
            <th className='adjustment'>
              <div>Adjustment</div>
              <div>MT</div>
            </th>
          </tr>
        </thead>
        <tbody>
          <Spacer />
          <FieldArray name='hsValues'>
            {(fieldArray: FieldArrayRenderProps) => (
              <SoundingTableRow
                {...fieldArray}
                disabled={isShoreMode}
                readonly={readonly}
                onCalcClick={setBatchName}
                scheme={mariTimeColorScheme}
              />
            )}
          </FieldArray>
          <Spacer />
          <FieldArray name='vlsValues'>
            {(fieldArray: FieldArrayRenderProps) => (
              <SoundingTableRow
                {...fieldArray}
                disabled={isShoreMode}
                readonly={readonly}
                onCalcClick={setBatchName}
                scheme={purpleColorScheme}
              />
            )}
          </FieldArray>
          <Spacer />
          <FieldArray name='ulsValues'>
            {(fieldArray: FieldArrayRenderProps) => (
              <SoundingTableRow
                {...fieldArray}
                disabled={isShoreMode}
                readonly={readonly}
                onCalcClick={setBatchName}
                scheme={lightBlueColorScheme}
              />
            )}
          </FieldArray>
          <Spacer />
          <FieldArray name='mdoValues'>
            {(fieldArray: FieldArrayRenderProps) => (
              <SoundingTableRow
                {...fieldArray}
                disabled={isShoreMode}
                readonly={readonly}
                onCalcClick={setBatchName}
                scheme={lightOrangeColorScheme}
              />
            )}
          </FieldArray>
        </tbody>
      </SimpleTable>

      {!!batchName && (
        <VolumeToMassCalculator
          labelText={getIn(values, `${batchName}.displayName`)}
          onCancel={() => setBatchName(undefined)}
          onClose={() => setBatchName(undefined)}
          storedState={!!batchName ? calculatorStore[batchName] : undefined}
          onSave={(totalSum: string, state: VolumeToMassCalculatorState) => {
            if (totalSum === '-' || isNaN(+totalSum) || isShoreMode) {
              setBatchName(undefined)
              return
            }
            const sum = parseFloat(totalSum)
            const quantity = getIn(values, `${batchName}.quantity`)
            const adjustment = roundedValue(sum - quantity)
            setFieldValue(`${batchName}.rob`, sum)
            setFieldValue(`${batchName}.adjustment`, adjustment)
            setFieldTouched(batchName)
            setBatchName(undefined)
            setCalculatorStore({ ...calculatorStore, [batchName]: state })
          }}
        />
      )}
    </Wrapper>
  )
}
