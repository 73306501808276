import { useContext } from 'react'

import { WindowContext } from '../../../../../contexts'
import { StyledMcInput } from './MetcReadonlyInputField.styles'

type Props = {
  value: string
  unit?: string
  tooltip?: string
}

const MetcReadonlyInputField = ({ value, unit, tooltip }: Props) => {
  const { windowSize } = useContext(WindowContext)

  return (
    <StyledMcInput
      disabled
      hiddenlabel
      fit={windowSize}
      value={value}
      suffix={unit}
      title={tooltip}
    />
  )
}

export default MetcReadonlyInputField
