import { Hydra } from '../../api-models/hydra'
import { RunningModeStatus } from './types'

export function mapRunningModeToStatus(
  result: Hydra.Propulsion.RunningModeOverTime,
): RunningModeStatus {
  return {
    ...result,
    calculatedResults: {
      timestamp: result.calculatedResults.timestamp[0],
      deviationFromPredictedMe1: mapRunningModeToStatusAttribute(
        result.calculatedResults.deviationFromPredictedMe1,
      ),
      loadPercentageMe1: mapRunningModeToStatusAttribute(
        result.calculatedResults.loadPercentageMe1,
      ),
      deviationFromPredictedMe2: mapRunningModeToStatusAttribute(
        result.calculatedResults.deviationFromPredictedMe2,
      ),
      loadPercentageMe2: mapRunningModeToStatusAttribute(
        result.calculatedResults.loadPercentageMe2,
      ),
    },
  }
}

function mapRunningModeToStatusAttribute(attribute: number[] | null) {
  if (attribute === null || attribute.length === 0) return null
  return attribute[0]
}
