import { useContext } from 'react'
import { McInput } from '@maersk-global/mds-react-wrapper'
import { type Table } from '@tanstack/react-table'

import { WindowContext } from '../../../../contexts'
import { type UserEntry } from '../../../../api-models/performance/fuel-oil-stock'

type Props = {
  table: Table<UserEntry>
}

const BatchNameFilter = ({ table }: Props) => {
  const { windowSize } = useContext(WindowContext)

  const handleBatchNameChange = ({ target }: Event) => {
    const { value } = target as HTMLInputElement
    table.getColumn('batchName')!.setFilterValue(value)
  }

  return (
    <McInput
      className='batch-name-filter'
      fit={windowSize}
      label='Batch name'
      placeholder='Batch name'
      input={handleBatchNameChange}
      clearbutton
      keepclearbuttonvisible
      data-e2e='BatchNameInput'
    />
  )
}

export default BatchNameFilter
