import * as React from 'react'
import * as moment from 'moment'
import styled from 'styled-components'
import { grey } from '../theme'
import { getTodayOrTimestamp } from '../utils'

const Wrapper = styled.div`
  display: flex;
  margin: 0 16px 0 16px;
  flex-direction: row;
  align-items: center;
  border-radius: 3px;

  border: 1px solid #e0e0e0;
  height: 110px;

  .status,
  .ae-control {
    text-align: center;
    width: 100px;
    margin: 0 5px 0 5px;

    height: 100%;
    div {
      padding: 0;
    }

    svg.green {
      position: absolute;
      top: 14px;
      left: 5px;
      width: 8px;
      height: 8px;
      line-height: 8px;
      font-size: 8px;
      color: #71d285;
      border: 1px solid white;
      border-radius: 50%;
    }
  }

  .status {
    border-right: 1px solid #e0e0e0;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;

    .date {
      font-weight: normal;
      font-size: 12px;
      color: ${grey[700]};

      display: flex;
      align-items: center;
      svg.red {
        margin: auto 5px auto auto;
        display: block;
        color: red;
        font-size: 8px;
      }
    }
    .time {
      font-size: 16px;
      color: #000;
      font-weight: bold;
    }
  }

  .block {
    display: flex;
    margin: 14px 4px 6px;
    position: relative;
    div {
      width: 100%;
      height: 34px;
    }
  }
`

interface VesselStatusBlockHorizontalProps {
  timestamp: string | number | null
  children?: React.ReactNode
}

export const VesselStatusBlockHorizontal = ({
  children,
  timestamp,
}: VesselStatusBlockHorizontalProps) => {
  const [...content] = React.Children.toArray(children).map((child) => child)
  return (
    <Wrapper>
      <div className='status'>
        <div className='date'>
          {timestamp ? `${getTodayOrTimestamp(timestamp)} UTC` : '-'}
        </div>
        <div className='time'>
          {timestamp ? moment.utc(timestamp).format('HH:mm') : '-'}
        </div>
        <div className='date'>
          <span className='fas fa-circle red' /> Last 6 hours
        </div>
      </div>
      {content}
    </Wrapper>
  )
}
