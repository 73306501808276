import { match } from 'react-router'
import { type Location } from 'history'

import { type ApplicationConfig, type ErrorResponse } from './models'
import { Management } from '../api-models/management'
import { msalInstance } from '.'

export interface FeatureToggles {
  [feature: string]: Toggle
}

export interface Environments {
  [env: string]: boolean
}

export interface Toggle {
  toggleInactive: Environments
  shore: boolean
  allVessels: boolean
  vessels: number[]
}

export const getApplicationConfig: () => ApplicationConfig = () =>
  (window as any).config

export const getFeatureToggleConfig: () => FeatureToggles = () =>
  (window as any).featureToggles

export const isFeatureEnabled: (featureId: string) => boolean = (featureId) => {
  const featureConfig = getFeatureToggleConfig()[featureId]
  const currentVessel = getVesselRunContext()
  switch (true) {
    case featureConfig === undefined:
    case isSit() && isToggleInactive('sit', featureConfig):
    case isDev() && isToggleInactive('dev', featureConfig):
    case isPreProd() && isToggleInactive('pprod', featureConfig):
    case isLocalhost() && isToggleInactive('localhost', featureConfig):
    case !!featureConfig && featureConfig.shore && isShoreContext():
    case !!featureConfig && featureConfig.allVessels && isVesselContext():
    case !!featureConfig &&
      isVesselContext() &&
      !!featureConfig.vessels.find(
        (vesselImo) => `${vesselImo}` === currentVessel,
      ):
      return true
    default:
      return false
  }
}

export const isFeatureEnabledForVessel: (
  imoNo: number | string,
  featureId: string,
) => boolean = (imoNo, featureId) => {
  const featureConfig = getFeatureToggleConfig()[featureId]
  switch (true) {
    case featureConfig === undefined:
    case isSit() && isToggleInactive('sit', featureConfig):
    case isDev() && isToggleInactive('dev', featureConfig):
    case isLocalhost() && isToggleInactive('localhost', featureConfig):
    case !!featureConfig && featureConfig.allVessels:
    case !!featureConfig &&
      !!featureConfig.vessels.find(
        (vesselImo) => `${vesselImo}` === `${imoNo}`,
      ):
      return true
    default:
      return false
  }
}

function isToggleInactive(env: string, featureConfig: Toggle): boolean {
  if (
    !!featureConfig &&
    !!featureConfig.toggleInactive &&
    featureConfig.toggleInactive[env] !== undefined
  ) {
    return featureConfig.toggleInactive[env].valueOf() === true
  }
  return true
}

const devTestBeds = [
  'msksc101',
  'msksc102',
  'msksc103',
  'msksc104',
  'msksc105',
  'msksc106',
]
const sitTestBeds = ['msksc201', 'msksc202', 'msksc203', 'msksc204']
const preProdTestBeds = ['msksc301', 'msksc302', 'msksc303', 'msksc304']
export const isPreProd: () => boolean = () =>
  window.location.origin.includes('pprod') ||
  (isVesselContext() &&
    !!preProdTestBeds.find((env) => window.location.origin.includes(env)))
export const isSit: () => boolean = () =>
  window.location.origin.includes('stst') ||
  (isVesselContext() &&
    !!sitTestBeds.find((env) => window.location.origin.includes(env)))
export const isDev: () => boolean = () =>
  window.location.origin.includes('dev') ||
  (isVesselContext() &&
    !!devTestBeds.find((env) => window.location.origin.includes(env)))
export const isProd: () => boolean = () =>
  'https://www.maerskstarconnect.com'.includes(window.location.origin)
export const isLocalhost: () => boolean = () =>
  window.location.origin.includes('localhost')

export const isShoreContext = () =>
  getApplicationConfig().APP_RUN_CONTEXT === 'isShore'
export const isVesselContext = () =>
  getApplicationConfig().APP_RUN_CONTEXT === 'isVessel'
export const getVesselRunContext: () => string = () =>
  getApplicationConfig().currentVesselImo

export const getPagePath: (path: string) => string = (path) => {
  const array = path.replace('/:vesselId', '').split('/')
  return array[array.length - 1].replace('/', '')
}

export const getUserRoles = (): string[] | undefined =>
  msalInstance.getAllAccounts()[0]?.idTokenClaims?.['roles']

export const hasRole = (role: string): boolean => {
  const roles = getUserRoles()
  if (!!roles) return roles.some((value) => role === value)
  return false
}

export const searchUserList: (
  filter: string,
  data: Management.Vessel.VesselRegistry[],
) => Management.Vessel.VesselRegistry[] = (filter, data) => {
  if (!filter) return data
  return data.filter((vessel) =>
    Object.keys(vessel).some(
      (key) => !!`${vessel[key]}`.toLowerCase().includes(filter.toLowerCase()),
    ),
  )
}

export const isVesselPage = (pathname: string) => {
  return pathname.split('/')[2] === 'vessel'
}

export interface DataFetchError {
  statusText: string
  message: string
  statusCode: number
}

export class DataFetchError {
  constructor(response: Response) {
    response.json().then((json: ErrorResponse) => {
      this.statusText = `${response.status}: ${response.statusText}`
      this.message = json.message
      this.statusCode = response.status
    })
  }
}

export const getIsActivePageFunctionForVesselSidebar: (
  pagePath: string,
  imoNo: number,
  isExact?: boolean,
) => <Params extends { [K in keyof Params]?: string }>(
  match: match<Params>,
  location: Location,
) => boolean = (pagePath, imoNo, isExact) => (match, location) => {
  const isCurrentVessel = window.location.pathname.includes(String(imoNo))
  switch (true) {
    case !!match && match.isExact && isExact && isCurrentVessel:
    case location.pathname.includes(pagePath) && !isExact && isCurrentVessel:
    // Subpages
    // fall through
    case pagePath === '/stock-management' &&
      location.pathname.includes('/stock-adjustments') &&
      !isExact &&
      isCurrentVessel:
    case pagePath === '/fuel-consumption' &&
      location.pathname.includes('/mass-balance') &&
      !isExact &&
      isCurrentVessel:
      return true
    case pagePath === '/hdc/overview' &&
      location.pathname.includes('/hdc/report') &&
      !isExact &&
      isCurrentVessel:
      return true
    case pagePath === `/metc` &&
      location.pathname.includes(`/metc/report`) &&
      isCurrentVessel:
      return true
    case pagePath === `/mfe` &&
      location.pathname.includes(`/mfe/report`) &&
      isCurrentVessel:
      return true
    default:
      return false
  }
}

export function getPerformanceApiUrl() {
  return `${getApplicationConfig().performanceApiResource.baseAddress}/api`
}

export function getMasterDataApiUrl() {
  return `${getApplicationConfig().masterDataApiResource.baseAddress}`
}

export function getMasterDataApiShoreUrl() {
  return `${getApplicationConfig().masterDataApiShoreResource.baseAddress}`
}

export function getManagementApiUrl() {
  return `${getApplicationConfig().managementApiResource.baseAddress}`
}

export function getHydraApiUrl() {
  return `${getApplicationConfig().hydraApiResource.baseAddress}`
}

export function getSensorApiUrl() {
  return `${getApplicationConfig().sensorApiResource.baseAddress}/api`
}

export function getSensorApiSocketAddress() {
  return getApplicationConfig().sensorApiResource.socketAddress
}

export function getGandalfUrl() {
  return `${getApplicationConfig().gandalfResource.baseAddress}/v1`
}

export function getGandalfV2Url() {
  return `${getApplicationConfig().gandalfResource.baseAddress}/v2`
}

export function getGandalfV2ShoreUrl() {
  return `${getApplicationConfig().gandalfShoreResource.baseAddress}/v2`
}

export function getNotificationsApiUrl() {
  return `${getApplicationConfig().notificationsApiResource.baseAddress}`
}

export function getBalrogApiUrl() {
  return `${getApplicationConfig().balrogApiResource.baseAddress}`
}
