import { FieldArray, getIn, useFormikContext } from 'formik'

import { Table, Tbody, Th, Thead, Tr } from '../../../commons/tables/styles'
import { UNITS } from '../../../utils/constants'
import { HDCReportFormValues } from '../types'
import { equipmentName } from '../utils'
import HDCInputField from './hdc-input-field'
import { EquipmentTh, RuntimeTd, RuntimeTh } from './styles'

type Props = {
  warnings: { [path: string]: string }
}

const ElectricalConsumersTable = ({ warnings }: Props) => {
  const { values, errors } = useFormikContext<HDCReportFormValues>()
  const { auxBlowers, shaftMotors, scrubberSystem } = values.engineRoom

  const getSection = (consumer: string, title: string) => (
    <Tr key={consumer}>
      <td>{title}</td>
      <td>
        <HDCInputField
          addon={UNITS.KILO_WATT_HOUR}
          decimals={0}
          error={getIn(errors, `engineRoom.${consumer}.energy`)}
          hasWarning={!!warnings[`engineRoom.${consumer}.energy`]}
          name={`engineRoom.${consumer}.energy.value`}
          placeholder='Energy'
          type='number'
        />
      </td>
      <RuntimeTd>
        <HDCInputField
          addon='hr'
          decimals={0}
          error={getIn(errors, `engineRoom.${consumer}.runTime`)}
          hasWarning={!!warnings[`engineRoom.${consumer}.runTime`]}
          name={`engineRoom.${consumer}.runTime.hours.value`}
          placeholder='Hours'
          type='number'
        />
        <HDCInputField
          addon='mins'
          decimals={0}
          error={getIn(errors, `engineRoom.${consumer}.runTime`)}
          hasWarning={!!warnings[`engineRoom.${consumer}.runTime`]}
          name={`engineRoom.${consumer}.runTime.minutes.value`}
          placeholder='Minutes'
          type='number'
        />
      </RuntimeTd>
    </Tr>
  )

  return (
    <Table>
      <Thead>
        <Tr>
          <EquipmentTh>Consumer</EquipmentTh>
          <Th>Energy</Th>
          <RuntimeTh>Runtime</RuntimeTh>
        </Tr>
      </Thead>
      <Tbody striped={true}>
        <FieldArray
          name='engineRoom.shaftMotors'
          render={() =>
            shaftMotors.map((motor, idx) =>
              getSection(
                `shaftMotors.${idx}`,
                `Shaft motors ${equipmentName(shaftMotors.length, motor)}`,
              ),
            )
          }
        />
        <FieldArray
          name='engineRoom.auxBlowers'
          render={() =>
            auxBlowers.map((blower, idx) =>
              getSection(
                `auxBlowers.${idx}`,
                `Aux blowers ${equipmentName(auxBlowers.length, blower)}`,
              ),
            )
          }
        />
        {scrubberSystem && getSection('scrubberSystem', 'Scrubber system')}
      </Tbody>
    </Table>
  )
}

export default ElectricalConsumersTable
