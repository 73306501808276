import { useContext, useMemo, useRef } from 'react'
import { type FieldInputProps, useField } from 'formik'
import { McTextarea } from '@maersk-global/mds-react-wrapper'

import { WindowContext } from '../../../contexts'

type Props = {
  name: string
  label: string
}

const HDCTextareaField = (props: Props) => {
  const { windowSize } = useContext(WindowContext)
  const [field, meta] = useField<FieldInputProps<string>>(props.name)

  const fieldValue = useRef(field.value).current
  const renderField = useMemo(
    () => typeof fieldValue !== 'undefined',
    [fieldValue],
  )

  if (!renderField) {
    console.error(
      `HDCTextareaField for name "${props.name}" was unable to render as it was not part of a form.`,
    )
    return null
  }

  return (
    <McTextarea
      {...props}
      fit={windowSize}
      maxlength={1000}
      rows={3}
      invalid={!!meta.error}
      value={field.value as unknown as string}
      input={field.onChange}
      data-e2e={props.name}
    />
  )
}

export default HDCTextareaField
