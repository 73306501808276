export const getLoadingText = () =>
  LOADING_TEXTS[Math.floor(Math.random() * LOADING_TEXTS.length)]

const LOADING_TEXTS = [
  'Please hang in there while we fetch your data',
  'Fresh data coming right up!',
  'We are fishing for your data, please stay patient',
  'Who ordered an extra large portion of data?',
  'You want data? You get data!',
  "Today's special with extra data coming up!",
  'SFOC? Trim? Fuel Consumption? You want it? We got it!',
  'Our artists are drawing your graph as we speak',
  'May the data be with you!',
  'Your data. Delivered.',
  'Data. Shaken, not stirred.',
  'Wait for it... Wait for it...',
  'Data is a dish best served cold.',
  "Hello, is it data you're looking for?",
  'Stop... Data loading time!',
  'Initializing Skynet…',
  'Dialing up...',
  'Your data. All the way.',
  "We're going all the way, for your data.",
  'In data we trust.',
  'Your data. Trusted. Open. Brave.',
]
