import { type Table } from '@tanstack/react-table'

import {
  MainEngInstanceName,
  StaticModelType,
  StaticModelTypeNames,
} from '../../../queries/MasterDataApi/MasterDataApi.consts'

export const getEquipmentOptions = (
  table: Table<GandalfApi.MetcReport>,
  mainEngines?: MasterDataApi.VesselParameters.Machinery.MainEngine.Data,
) => {
  const isTwinEngineVessel = mainEngines?.engines.length === 2

  return Array.from(
    new Set(
      table.getCoreRowModel().rows.map((row) => {
        const equipment = row.original.data.equipment
        let instance: number | string = ''
        if (equipment.staticModelType === StaticModelType.MainEng) {
          if (isTwinEngineVessel) {
            instance = MainEngInstanceName[equipment.instanceNo]
          }
        } else {
          instance = equipment.instanceNo
        }
        return `${StaticModelTypeNames[equipment.staticModelType]} ${instance}`
      }),
    ),
  ).map((equipment) => ({
    label: equipment,
    value: equipment,
  }))
}
