import React, { FunctionComponent } from 'react'
import { SimpleTable } from '../../../../commons'
import styled, { grey } from '../../../../theme'
import { MergedBatch } from './MixedBatchDetails'
import { formatValue } from '../../../../utils'
import { MixedBatchTableRow } from './MixedBatchTableRow'

const Spacer = styled.tr`
  height: 16px;
`

const FooterRow = styled.tr`
  background: ${grey[100]};
  font-size: 12px;
  font-weight: 700;
`

const Wrapper = styled.div`
  max-width: 400px;
  table {
    min-width: 350px;
    overflow-x: auto;
    table-layout: fixed;
    white-space: nowrap;

    width: 100%;
    font-size: 14px;
    border-collapse: separate;
    border-spacing: 0px;
    tbody tr:hover {
      background-color: rgba(100, 178, 212, 0.1);
    }
    th {
      text-align: left;

      div {
        &:nth-child(2) {
          text-align: left;
          font-style: italic;
          font-weight: 500;
          width: 100%;
        }
      }

      &.batch {
        width: 40%;
      }
      &.quantity {
        width: 15%;
      }
    }
    td {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      position: relative;
      padding: 0px 8px;
      border-top: 0;
      border-left: none;
      &:last-child {
        border-right: none;
      }

      &:first-child {
        text-align: left;
      }
      text-align: right;
    }
  }
`

export const MixedBatchTable: FunctionComponent<{
  mixedBatches?: MergedBatch[]
  quantityCE?: number
}> = ({ mixedBatches, quantityCE }) => {
  return (
    <Wrapper>
      <SimpleTable>
        <thead>
          <tr>
            <th className='batch'>
              <div>Batches mixed</div>
              <div>&nbsp;</div>
            </th>
            <th className='quantity'>
              <div>Quantity</div>
              <div>MT</div>
            </th>
          </tr>
        </thead>
        <tbody>
          {mixedBatches && <MixedBatchTableRow batches={mixedBatches} />}
        </tbody>
        <tfoot>
          <FooterRow>
            <td style={{ padding: '8px' }}>Total quantity according to C/E</td>
            <td>{formatValue(quantityCE, 2)}</td>
          </FooterRow>
          <Spacer />
        </tfoot>
      </SimpleTable>
    </Wrapper>
  )
}
