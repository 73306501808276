import { McButton, McTab, McTabBar } from '@maersk-global/mds-react-wrapper'
import { ContentLayout } from '../../layout'
import ReleaseNotes from './ReleaseNotes/ReleaseNotes'
import TemNotifications from '../../components/TemNotifications/TemNotifications'
import PerformanceAlerts from '../../components/PerformanceAlerts/PerformanceAlerts'

type Props = {
  topics: JSX.Element
}

export const HelpSection = ({ topics }: Props) => {
  const handleCreateTicket = () => {
    const newWindow = window.open(
      'mailto:VITSERVICEDESK@maersk.com',
      '_blank',
      'noopener, noreferrer',
    )

    if (newWindow) {
      newWindow.opener = null
    }
  }

  return (
    <ContentLayout header='Energy help section'>
      <TemNotifications />
      <PerformanceAlerts />
      <div id='help-section' className='mds-content'>
        <McTabBar currentindex={0}>
          <McTab slot='tab' label='Topics' />
          <div slot='panel'>
            {topics}
            <div className='help-desk-box'>
              <div className='mds-headline--medium'>
                Didn't find the help you needed here?
              </div>
              <p>
                As we want to make sure you get the right help you require, we
                ask you to contact us by making a ticket in help desk.
              </p>
              <McButton
                click={handleCreateTicket}
                fit='small'
                trailingicon='arrow-right'
              >
                Create a ticket to help desk
              </McButton>
            </div>
          </div>

          <McTab slot='tab' label='Release Notes' />
          <div slot='panel'>
            <ReleaseNotes />
          </div>
        </McTabBar>
      </div>
    </ContentLayout>
  )
}
