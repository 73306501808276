interface Option {
  value: string
  label: string
}

export const STOCK_LOSS_REASON_OPTIONS: Option[] = [
  { value: 'LEAKAGE', label: 'Fuel has leaked and cannot be recovered' },
  {
    value: 'FLUSHING',
    label: 'Flushing of fuel lines due to fuel change over',
  },
  { value: 'CONTAMINATION', label: 'Fuel has been contaminated' },
  { value: 'OTHER', label: 'None of the above reasons' },
]

export const STOCK_DEBUNKERING_REASON_OPTIONS: Option[] = [
  { value: 'ARBITRAGE', label: 'Arbitrage' },
  { value: 'DRY_DOCK', label: 'Going into dry dock' },
  { value: 'FUEL_OUT_OF_SPEC', label: 'Fuel does not meet specification' },
  { value: 'TC_REDELIVERY', label: 'TC vessel redelivery' },
  { value: 'OTHER', label: 'None of the above reasons' },
]
