import * as React from 'react'
import { FormElements, ModalControls } from '../../../commons'
import { formatValue } from '../../../utils'
import { calculateEstimatedLeakagePerHour } from '../utils'
import styled, { grey } from '../../../theme'
import { UNITS } from '../../../utils/constants'
import { McButton } from '@maersk-global/mds-react-wrapper'

const Wrapper = styled.div`
  .ReactTable .rt-table .rt-thead .rt-th {
    min-height: unset;
    padding: 4px;
  }
  .rt-table .rt-tbody .rt-td {
    display: flex;
    justify-content: center;
  }
  td.fuel-types-on-board {
    display: flex;
    &:last-of-type {
      width: 100%;
    }
    * {
      margin-right: 4px;
    }
  }
`

const LeakageContainer = styled.div`
  display: flex;
  width: 220px;
`

const Text = styled.div`
  font-size: 14px;
  margin-right: 4px;
`

interface VesselSettingsTableProps {
  imoNo: string
  displayLeakage: boolean
  estimatedLeakage?: number
  closeHandler: () => void
}

export function VesselSettingsTable(props: VesselSettingsTableProps) {
  const { displayLeakage, estimatedLeakage, closeHandler } = props
  const formattedEstimatedLeakage =
    estimatedLeakage != null ? formatValue(estimatedLeakage, 3) : '1.200'
  const estimatedLeakagePerHour =
    estimatedLeakage != null
      ? calculateEstimatedLeakagePerHour(estimatedLeakage)
      : 50

  return (
    <Wrapper>
      <FormElements.ReadonlyTable>
        <tbody>
          {displayLeakage && (
            <tr>
              <td>
                <FormElements.Label>ME leakage</FormElements.Label>
              </td>
              <td>
                <FormElements.Value>
                  <LeakageContainer>
                    <Text>{formattedEstimatedLeakage}</Text>
                    <Text>
                      {UNITS.MT_PER_24_HOUR}{' '}
                      <span style={{ color: grey[700] }}>
                        ({estimatedLeakagePerHour} {UNITS.KG_PER_HOUR})
                      </span>
                    </Text>
                  </LeakageContainer>
                </FormElements.Value>
              </td>
            </tr>
          )}
        </tbody>
      </FormElements.ReadonlyTable>
      <ModalControls>
        <McButton
          label='Close'
          appearance='primary'
          click={closeHandler}
          type='button'
        />
      </ModalControls>
    </Wrapper>
  )
}
