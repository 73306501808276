import { useContext, useEffect, useRef } from 'react'

import { TextAreaWrapper, Wrapper } from './History.styles'
// import { mockMessages, mockMessagesShort } from './mock'
import Message from './Message/Message'
import NoMessages from './NoMessages'
import { FormTextArea } from '../../../formik'
import { WindowContext } from '../../../../contexts'

type Props = {
  alert: NotificationsAPI.PerformanceAlert
  stepId: string
  isReadonly: boolean
}

const History = ({ alert, stepId, isReadonly }: Props) => {
  const { windowSize } = useContext(WindowContext)
  const wrapperRef = useRef<HTMLDivElement>(null)

  let messages: Array<Diagnostics.ChatMessage> = alert.notification_responses
    .filter((r) => r.data.message)
    .map((r) => ({
      id: r.id,
      message: r.data.message!,
      sender: r.data.actor,
      user: r.data.user,
      timestamp: r.created_at,
    }))

  // messages = mockMessages
  // messages = mockMessagesShort
  // messages = []

  messages = messages.sort(
    (a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime(),
  )

  useEffect(() => {
    setTimeout(() => {
      if (wrapperRef.current) {
        wrapperRef.current.scrollTop = wrapperRef.current.scrollHeight
      }
    }, 100)
  }, [messages])

  return (
    <Wrapper ref={wrapperRef}>
      {messages.length > 0 ? (
        messages.map((m) => <Message key={m.id} msg={m} />)
      ) : (
        <NoMessages />
      )}
      <TextAreaWrapper>
        <FormTextArea
          fit={windowSize}
          id={`${stepId}.comment`}
          name={`${stepId}.comment`}
          label=''
          placeholder='Message to vessel performance team'
          rows={4}
          disabled={isReadonly}
        />
      </TextAreaWrapper>
    </Wrapper>
  )
}

export default History
