import React from 'react'
import { Icon } from './Icon'
import styled, { grey, maerskBlue } from '../theme'

const StepContainer = styled.div<{ padding: string | undefined }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({ padding }) => padding || '16px'};
  width: 100%;
  box-sizing: border-box;

  div:not(:first-of-type) {
    margin-left: 5px;
  }
`

interface IFormContent {
  show: boolean
}

export const FormContent = styled.div<IFormContent>`
  display: ${({ show }) => (show ? 'block' : 'none')};
`

const FormStepCount = styled.span<{ show: boolean }>`
  display: ${({ show }) => (show ? 'inline-block' : 'none')};
  background-color: #ffffff;
  font-weight: normal;
  padding: 6px 10px;
  border-radius: 100%;
  color: ${grey[500]};
  margin-left: 12px;

  [class*='fa-'] {
    width: 9px;
  }
`

const FormStep = styled.div<{ isActive: boolean; isClickable: boolean }>`
  flex: 1;
  padding: 12px 19px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  border-radius: 3px;
  background-color: ${(props) =>
    props.isActive ? `${maerskBlue[500]}` : `${grey[100]}`};
  color: ${(props) => (props.isActive ? 'white' : `${grey[500]}`)};
  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'default')};
`

interface FormProgressBarProps {
  currentStep: number
  icons?: JSX.Element[]
  steps: string[]
  showStepCount?: boolean
  padding?: string
  onClick?: (index: number) => void
  children?: React.ReactNode
}

export const FormProgressBar = ({
  currentStep,
  icons,
  steps,
  children,
  showStepCount,
  padding,
  onClick,
}: FormProgressBarProps) => {
  return (
    <React.Fragment>
      <StepContainer padding={padding}>
        {steps.map((step, index) => (
          <FormStep
            key={`${step}-${index}`}
            isActive={currentStep === index}
            isClickable={!!onClick}
            onClick={() => {
              if (onClick) {
                onClick(index)
              }
            }}
            data-e2e={`${step}`}
          >
            {step}
            {icons && icons[index]}
            <FormStepCount show={!!showStepCount}>
              {index < currentStep ? <Icon icon='fal fa-check' /> : index + 1}
            </FormStepCount>
          </FormStep>
        ))}
      </StepContainer>
      {!!children && children}
    </React.Fragment>
  )
}
