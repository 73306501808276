import { type IColorScheme } from '../../theme'
import * as colors from './themes'
import { type Performance } from '../../api-models'
import { type EngineChartModel } from './models'
import {
  formatValue,
  tooltip,
  FUEL_TYPE_NAMES,
  singleCurveTooltip,
  stripDecimals,
  linearInterpolate,
  findAdjacentPoints,
} from '../../utils'
import { UNITS } from '../../utils/constants'
import { mapShopTestModelsData } from './mapper'
import { StaticModelType } from '../../queries/MasterDataApi/MasterDataApi.consts'

export const getLinearInterpolationValue = (
  modelLoad: Array<number>,
  staticData: Array<number>,
  load: number,
): number | undefined => {
  const adjacentPoints = findAdjacentPoints(modelLoad, staticData, load)

  if (!adjacentPoints) {
    return undefined
  }

  return linearInterpolate(...adjacentPoints, load)
}

export const calcShopTestModel = (
  currentLoad: number,
  conditionCode: string,
  staticModels?: Array<
    MasterDataApi.StaticModels.StaticModelWithInstanceNumbers<MasterDataApi.StaticModels.StaticModelData>
  >,
) => {
  if (!currentLoad || !staticModels) return null

  const shoptestPoints: Array<Array<number>> | null = getChartData(
    staticModels,
    conditionCode,
  )

  if (!shoptestPoints) {
    return null
  }

  return getLinearInterpolationValue(
    shoptestPoints.map((item) => item[0]),
    shoptestPoints.map((item) => item[1]),
    currentLoad,
  )
}

export const calcVesselSpecific = (
  load: number | null,
  dataShopTestModels?: MasterDataApi.StaticModels.VesselStaticModels<MasterDataApi.StaticModels.StaticModelData>,
  vesselSpecificBaseline?: HydraApi.SfocBaselineModel.BaselineModel,
) => {
  if (!vesselSpecificBaseline || !dataShopTestModels || !load) return null
  return getLinearInterpolationValue(
    vesselSpecificBaseline.load,
    vesselSpecificBaseline.predictedSfoc10Percentile,
    load,
  )
}

export const tooltipFormatter = (
  dataIndex: number,
  engines: Array<EngineChartModel>,
  hasTwoMainEngines: boolean,
  hasVesselSpecificEnabled: boolean,
  data: HydraApi.Sfoc.MainEngine,
  dataVesselSpecific?: HydraApi.SfocBaselineModel.BaselineModel,
  dataShopTestModels?: MasterDataApi.StaticModels.VesselStaticModels<MasterDataApi.StaticModels.StaticModelData>,
) => {
  const { staticModels } = mapShopTestModelsData(dataShopTestModels)
  const code =
    engines[0].data.loadSeries[dataIndex] >= staticModels[0].data.tccoLimit
      ? 'DEFAULT'
      : 'TCCO'
  const tooltipSeries: any[] = []
  let columnHeaders
  if (hasTwoMainEngines) {
    columnHeaders = {
      property: '',
      mix: 'Mix MEs',
      me1: 'Port ME',
      me2: 'Stbd ME',
      unit: 'Unit',
    }

    tooltipSeries.push({
      property: 'SFOC',
      mix: formatValue(engines[0].data.sfocSeries[dataIndex], 1),
      me1: formatValue(engines[1].data.sfocSeries[dataIndex], 1),
      me2: formatValue(engines[2].data.sfocSeries[dataIndex], 1),
      unit: UNITS.SFOC,
    })

    tooltipSeries.push({
      property: 'Shop Test',
      mix: formatValue(
        calcShopTestModel(
          engines[0].data.loadSeries[dataIndex],
          code,
          staticModels,
        ),
        1,
      ),
      me1: formatValue(
        calcShopTestModel(
          engines[1].data.loadSeries[dataIndex],
          code,
          staticModels,
        ),
        1,
      ),
      me2: formatValue(
        calcShopTestModel(
          engines[2].data.loadSeries[dataIndex],
          code,
          staticModels,
        ),
        1,
      ),
      unit: UNITS.SFOC,
    })

    if (hasVesselSpecificEnabled) {
      const vesselSpecificValue = formatValue(
        calcVesselSpecific(
          engines[0].data.powerSeries[dataIndex],
          dataShopTestModels,
          dataVesselSpecific,
        ),
        1,
      )
      tooltipSeries.push({
        property: 'Vessel Specific',
        mix: vesselSpecificValue,
        me1: vesselSpecificValue,
        me2: vesselSpecificValue,
        unit: UNITS.SFOC,
      })
    }

    tooltipSeries.push({
      property: 'Load',
      mix: formatValue(engines[0].data.loadSeries[dataIndex], 1),
      me1: formatValue(engines[1].data.loadSeries[dataIndex], 1),
      me2: formatValue(engines[2].data.loadSeries[dataIndex], 1),
      unit: UNITS.ENGINE_LOAD,
    })

    tooltipSeries.push({
      property: 'Power',
      mix: formatValue(engines[0].data.powerSeries[dataIndex], 1),
      me1: formatValue(engines[1].data.powerSeries[dataIndex], 1),
      me2: formatValue(engines[2].data.powerSeries[dataIndex], 1),
      unit: UNITS.MEGA_WATT,
    })

    tooltipSeries.push({
      property: 'Fuel type',
      mix: FUEL_TYPE_NAMES[data.fuelTypeSeries[dataIndex]] || '-',
      me1: '',
      me2: '',
      unit: '',
    })
  } else {
    columnHeaders = {
      property: '',
      me1: 'ME',
      unit: 'Unit',
    }
    tooltipSeries.push({
      property: 'SFOC',
      me1: formatValue(engines[0].data.sfocSeries[dataIndex], 1),
      unit: UNITS.SFOC,
    })

    tooltipSeries.push({
      property: 'Shop Test',
      me1: formatValue(
        calcShopTestModel(
          engines[0].data.loadSeries[dataIndex],
          code,
          staticModels,
        ),
        1,
      ),
      unit: UNITS.SFOC,
    })

    if (hasVesselSpecificEnabled) {
      tooltipSeries.push({
        property: 'Vessel specific',
        me1: formatValue(
          calcVesselSpecific(
            engines[0].data.loadSeries[dataIndex],
            dataShopTestModels,
            dataVesselSpecific,
          ),
          1,
        ),
        unit: UNITS.SFOC,
      })
    }

    tooltipSeries.push({
      property: 'Load',
      me1: formatValue(engines[0].data.loadSeries[dataIndex], 1),
      unit: UNITS.ENGINE_LOAD,
    })

    tooltipSeries.push({
      property: 'Power',
      me1: formatValue(engines[0].data.powerSeries[dataIndex], 1),
      unit: UNITS.MEGA_WATT,
    })

    tooltipSeries.push({
      property: 'Fuel type',
      me1: FUEL_TYPE_NAMES[data.fuelTypeSeries[dataIndex]] || '-',
      unit: '',
    })
  }

  return tooltip(data.timestamps[dataIndex], tooltipSeries, [columnHeaders])
}

export const getEngineTheme = (
  engineId: number | string,
  timelineColors?: boolean,
): IColorScheme => {
  switch (engineId) {
    case 1:
    case 'ME1':
      return colors.meEng1
    case 2:
    case 'ME2':
      return colors.meEng2
    case 0:
    case 'Mix':
      return timelineColors ? colors.meMixInvisible : colors.meMix
    default:
      return colors.meEng1
  }
}

export const getEngineData = (
  data: Performance.Sfoc.MeSfocData,
  hasTwoMainEngines: boolean,
): EngineChartModel[] => {
  let engines = [
    {
      name: 'ME1',
      data: data.mainEng1,
    },
  ]
  if (hasTwoMainEngines) {
    engines.push({
      name: 'ME2',
      data: data.mainEng2,
    })
    engines.unshift({
      name: 'Mix',
      data: data.mainEngMix,
    })
  }
  return engines
}

export const getTooltipData = (params: any, seriesName: string) => {
  if (!params.value) return null
  return singleCurveTooltip(
    [
      {
        property: seriesName,
        me1: stripDecimals(params.value[1], 1),
        unit: 'g/kWh',
      },
      {
        property: 'Load',
        me1: stripDecimals(params.value[0], 1),
        unit: '% MCR',
      },
    ],
    [{ property: '', me1: 'ME', unit: 'UNIT' }],
  )
}

export const getChartData = (
  staticModel: Array<
    MasterDataApi.StaticModels.StaticModelWithInstanceNumbers<MasterDataApi.StaticModels.StaticModelData>
  >,
  conditionCode: string,
) => {
  const mainEngineStaticModel = staticModel.find(
    (item) => item.type === StaticModelType.MainEng,
  )
  if (!mainEngineStaticModel) return null

  const operatingConditions =
    mainEngineStaticModel.data.operatingConditions.find(
      (oc) => oc.code === conditionCode,
    )
  if (!operatingConditions) return null

  const {
    referenceCondition: { load },
    isoCondition: { sfoc },
  } = operatingConditions

  const tccoLimit = mainEngineStaticModel.data.tccoLimit

  let filteredData = load.map((item, index) => [item, sfoc[index]])

  // Check if tccoLimit exists in load, if not, interpolate and add to filteredData
  if (tccoLimit && !load.includes(tccoLimit)) {
    const value = getLinearInterpolationValue(load, sfoc, tccoLimit) as number

    // Depending on conditionCode, insert value at the beginning or end of filteredData
    if (conditionCode === 'DEFAULT') {
      filteredData = [[tccoLimit, value], ...filteredData]
    } else if (conditionCode === 'TCCO') {
      filteredData.push([tccoLimit, value])
    }
  }

  filteredData = filteredData.filter(([loadValue]) =>
    conditionCode === 'DEFAULT'
      ? loadValue >= tccoLimit
      : conditionCode === 'TCCO'
      ? loadValue <= tccoLimit
      : false,
  )

  return filteredData
}
