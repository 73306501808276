import { ReactNode, useContext, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Form,
  Formik,
  type FormikErrors,
  type FormikHelpers,
  type FormikValues,
  validateYupSchema,
  yupToFormErrors,
} from 'formik'
import moment from 'moment'
import styled, { useTheme } from 'styled-components'
import { type IMcNotification } from '@maersk-global/mds-components-core/mc-notification/types'

import {
  generateErrorMessage,
  getErrorPersistingTabs,
  getFuelDataLossAlert,
  getMfeEtaWarnings,
} from './report.utils'

import {
  GandalfReportValidationError,
  TWarning,
} from '../../api-models/hdc/report'
import { FuelLineDataLoss } from '../../api-models/performance/fuel-consumption'
import Notifications from '../../components/Notifications/Notifications'
import TemNotifications from '../../components/TemNotifications/TemNotifications'
import PerformanceAlerts from '../../components/PerformanceAlerts/PerformanceAlerts'
import { VesselPageContext } from '../../contexts'
import HDCReportCard from '../../features/hybrid-data-collector/components/report-card'
import { FORM_STEP_TO_FIELDS } from '../../features/hybrid-data-collector/constants'
import { HDCContext } from '../../features/hybrid-data-collector/context'
import {
  BridgeFormStep,
  EngineRoomFormStep,
  ESubReport,
  HDCFormType,
  HDCFormValidationContext,
  HDCReportFormValues,
} from '../../features/hybrid-data-collector/types'
import {
  getFilteredByStepWarnings,
  transformFormikReportToUpdateBridgeReportRequest,
  transformFormikReportToUpdateEngineRoomReportRequest,
} from '../../features/hybrid-data-collector/utils'
import getPartialValidationSchema from '../../features/hybrid-data-collector/validation/validationSchemas'
import { ContentLayout } from '../../layout'
import {
  submitBridgeReport,
  submitEngineRoomReport,
  updateBridgeReport,
  updateEngineRoomReport,
  updateReportStateToEdit,
} from '../../services/hdc'
import { displayConfirmModal, displayErrorModal } from '../../utils'
import ModalContent from '../../features/hybrid-data-collector/components/modal-content'
import { useGetReports } from '../../queries/GandalfApi/GandalfApi'
import {
  MfeStatus,
  ReportState,
  ReportType,
} from '../../queries/GandalfApi/GandalfApi.consts'

const FormNotificationsWrapper = styled.div`
  margin-top: 16px;
  text-align: start;
`

const HDCReportPage = () => {
  const theme = useTheme()
  const { push } = useHistory()
  const {
    imoNo,
    resetState,
    setHasUnsavedEvent,
    setHasValidationError,
    setHasValidationWarning,
    setReport,
    state: HDCState,
  } = useContext(HDCContext)
  const configuration = useContext(VesselPageContext).configuration!

  const getMfeReports = useGetReports<GandalfApi.Mfe.ReportData>(
    imoNo,
    ReportType.Mfe,
  )

  const mfeReports: Array<GandalfApi.MfeReport> = useMemo(() => {
    if (!getMfeReports.isSuccess) {
      return []
    }
    return getMfeReports.data.reports.filter(
      (r) =>
        r.reportState === ReportState.Submitted &&
        r.data.status === MfeStatus.Ongoing &&
        r.data.impact.etaChange.hasEtaChange,
    )
  }, [getMfeReports.data])

  const { report, form, fuelDataLosses } = HDCState
  const {
    currentStep,
    hasUnsavedEvent,
    steps,
    type,
    useCustomArrival,
    useCustomDeparture,
    hasValidationError,
  } = form

  // In case no report have been set on the
  // report page push the user to the overview
  if (!report) {
    push(`/MaerskStarConnect/vessel/${imoNo}/hdc/overview`)
  }

  const warnings: Array<ReactNode | IMcNotification> = [
    ...getMfeEtaWarnings(mfeReports, imoNo),
  ]

  const alerts: Array<ReactNode | IMcNotification> = [
    ...getFuelDataLossAlert(fuelDataLosses, imoNo),
  ]

  /**
   * @description Notifies user about the successful submission of the report
   * @param {HDCFormType} formType will be injected into a message from the `ReportSubmittedMessage` component.
   */
  const displaySuccessModal = (formType: HDCFormType) => {
    const title = 'Report submitted'
    const message = (
      <ModalContent
        icon='check-circle'
        iconColor={theme.colors.feedback.success.main}
        title={`You have successfully submitted your ${
          formType === HDCFormType.BRIDGE_FORM ? 'bridge' : 'engine room'
        } report`}
      />
    )
    const options = { title, message }

    return displayConfirmModal(options, 'OK', 'Cancel', true)
  }

  const displaySuccessModalWithWarnings = async (
    warnings: string[],
    type: HDCFormType,
  ) => {
    const title = 'Report submitted'
    const modalContentMsg = (
      <div>
        Please be aware that the report contains warnings. You can edit the
        report now or choose to ignore these warnings:
        <FormNotificationsWrapper>
          <Notifications warnings={warnings} />
        </FormNotificationsWrapper>
      </div>
    )
    const message = (
      <ModalContent
        icon='exclamation-triangle-solid'
        iconColor={theme.colors.feedback.warning.main}
        message={modalContentMsg}
        title={`You have successfully submitted your ${
          type === HDCFormType.BRIDGE_FORM ? 'Bridge' : 'Engine room'
        } report!`}
      />
    )
    return displayConfirmModal(
      { title, message },
      'Edit report',
      'Go to reports',
    )
  }

  const displayUnsavedEventWarning = async () => {
    const title = 'Event has not been saved'
    const message = (
      <ModalContent
        icon='exclamation-triangle-solid'
        iconColor={theme.colors.feedback.warning.main}
        message='Please stay and add event before proceeding.'
        title='You have unsaved event!'
      />
    )
    return displayConfirmModal(
      { title, message },
      'Stay',
      'Discard event and proceed',
    )
  }

  const getFuelDataLossWarningMessage = (losses: FuelLineDataLoss[]) => {
    const modalContentMsg = (
      <div>
        The fuel consumption data for this report period is incomplete. Please
        go to the Fuel consumption page and repair the detected data{' '}
        {losses.length === 1 ? 'loss' : 'losses'}: <br />
        <br />
        {losses.map((loss, idx) => (
          <div key={idx}>
            {moment(loss.startTimestamp).utc().format('DD MMM YYYY HH:mm')} -{' '}
            {moment(loss.endTimestamp).utc().format('DD MMM YYYY HH:mm')}
          </div>
        ))}
      </div>
    )
    return (
      <ModalContent
        icon='exclamation-triangle-solid'
        iconColor={theme.colors.feedback.warning.main}
        message={modalContentMsg}
        title='The report cannot be submitted!'
      />
    )
  }

  /**
   * getReportFuelDataLosses
   * @description Returns fuel data losses that fall under the report period
   */
  const getReportFuelDataLosses = () =>
    (fuelDataLosses || []).filter(
      (loss) =>
        moment(loss.startTimestamp).isBetween(
          report?.periodStart,
          report?.periodEnd,
          undefined,
          '[]',
        ) ||
        (moment(loss.startTimestamp).isBefore(report?.periodStart) &&
          ((loss.hasEnded &&
            moment(loss.endTimestamp).isAfter(report?.periodStart)) ||
            !loss.hasEnded)),
    )

  /**
   * invalidateFormSteps
   * @description Sets current step status as invalid. On form submit, makes sure to invalidate all steps with validation errors.
   * @param {FormikErrors<FormikValues>} errors Formik errors.
   */
  const invalidateFormSteps = (errors: FormikErrors<FormikValues>): void => {
    if (
      (steps[currentStep] !== BridgeFormStep.SUMMARY &&
        steps[currentStep] !== EngineRoomFormStep.SUMMARY) ||
      !type
    ) {
      setHasValidationError(type, steps[currentStep], true)
      return
    }
    const subReport =
      type === HDCFormType.BRIDGE_FORM
        ? ESubReport.Bridge
        : ESubReport.EngineRoom
    steps.forEach(
      (step) =>
        FORM_STEP_TO_FIELDS[type][step] &&
        FORM_STEP_TO_FIELDS[type][step].some(
          (field) => errors && errors[subReport] && errors[subReport]![field],
        ) &&
        setHasValidationError(type, step, true),
    )
  }

  const setValidationWarnings = (
    warnings: TWarning[],
    type: HDCFormType,
    steps: (BridgeFormStep | EngineRoomFormStep)[],
  ) => {
    steps.forEach((step) => {
      const stepWarnings = getFilteredByStepWarnings(warnings, type, step)
      if (Object.keys(stepWarnings).length) {
        setHasValidationWarning(type, step, true)
      }
    })
  }

  /**
   * internalServerErrorModal
   * @description error modal for all 500+ error requests made while attempting to patch or submit report.
   */
  const showInternalServerErrorModal = () =>
    displayErrorModal({
      statusText: 'Failed to update report',
      message:
        'Sorry sailor, we could not update your report due to a internal server error, please try and refresh your app, if issue persists contact us.',
    })

  const getValidationWarnings = (
    warnings: TWarning[],
    type: HDCFormType,
    step: BridgeFormStep | EngineRoomFormStep,
  ) => {
    const filteredWarnings = getFilteredByStepWarnings(warnings, type, step)
    return [...new Set(Object.values(filteredWarnings))]
  }

  const submitReport = async (
    values: HDCReportFormValues,
    actions: FormikHelpers<HDCReportFormValues>,
  ) => {
    const handleApiValidationErrors = (
      errors: GandalfReportValidationError[],
    ) => {
      const formikErrors = {}
      errors?.forEach((e) => {
        e.paths.forEach((path: string) => {
          actions.setFieldError(
            `${path.replace(/\.\./g, '.')}.value`,
            e.message,
          )
          const [subReport, field] = path.split('.')
          formikErrors[subReport] = { [field]: e.message }
        })
      })
      invalidateFormSteps(formikErrors)
    }

    // Display warning if user didn't save event
    if (
      [BridgeFormStep.EVENTS, EngineRoomFormStep.EVENTS].includes(
        steps[currentStep],
      ) &&
      hasUnsavedEvent
    ) {
      try {
        await displayUnsavedEventWarning()
        return
      } catch (err) {
        // User discarded the dialog
        setHasUnsavedEvent(false)
      }
    }

    if (type === HDCFormType.BRIDGE_FORM) {
      // Submit bridge report flow
      if (steps[currentStep] === BridgeFormStep.SUMMARY) {
        try {
          // Validate if Error exists on progress bar tabs of Bridge Form
          const errorPersistingTabsOnBridge = getErrorPersistingTabs(
            hasValidationError,
            HDCFormType.BRIDGE_FORM,
          )
          if (errorPersistingTabsOnBridge.length > 0) {
            const errorMessage = generateErrorMessage(
              errorPersistingTabsOnBridge,
            )
            if (errorMessage) {
              void displayErrorModal({
                statusText: 'Failed to submit report',
                message: errorMessage,
              })
            }
          } else {
            const response = await submitBridgeReport(imoNo, values.id)
            const warnings = getValidationWarnings(
              response.warnings,
              type,
              BridgeFormStep.SUMMARY,
            )
            if (warnings.length) {
              await displaySuccessModalWithWarnings(warnings, type)
              setValidationWarnings(
                response.warnings,
                type,
                Object.values(BridgeFormStep),
              )
              // change report state to `edit`, otherwise user won't be able to update data
              await updateReportStateToEdit(imoNo, response.id, type)
              setReport(response)
              window.scrollTo({ top: 0, behavior: 'smooth' })
            } else {
              await displaySuccessModal(type)
              resetState()
              push(`/MaerskStarConnect/vessel/${imoNo}/hdc/overview`)
            }
          }
        } catch (error) {
          if (error.statusCode >= 500) {
            return showInternalServerErrorModal()
          }
          if (error === 'cancel') {
            resetState()
            push(`/MaerskStarConnect/vessel/${imoNo}/hdc/overview`)
            return
          }
          if (error.body?.errors) {
            return handleApiValidationErrors(error.body.errors)
          }
          void displayErrorModal({
            statusText: 'Failed to proceed',
            message:
              error?.body?.error || 'Error occurred, please refresh the page.',
          })
        }
        return
      }

      // Patch bridge report flow
      const bridgeReport = transformFormikReportToUpdateBridgeReportRequest(
        values,
        HDCState,
      )

      // When user saves the progress, only fields from current step should be sent to BE
      // That allows to save the data even if other steps have validation errors
      const filteredBridgeReport = Object.keys(bridgeReport.bridge)
        .filter((field) =>
          FORM_STEP_TO_FIELDS[type][steps[currentStep]].includes(field),
        )
        .reduce(
          (result, field) => {
            result.bridge[field] = bridgeReport.bridge[field]
            return result
          },
          { bridge: {} },
        )

      try {
        await updateBridgeReport(imoNo, values.id, filteredBridgeReport)
      } catch (error) {
        if (error.statusCode >= 500) {
          return showInternalServerErrorModal()
        }
        handleApiValidationErrors(error.body.errors)
      }
    } else if (type === HDCFormType.ENGINE_FORM) {
      // Submit engine room report flow
      if (steps[currentStep] === EngineRoomFormStep.SUMMARY) {
        const reportFuelDataLosses = getReportFuelDataLosses()
        if (reportFuelDataLosses.length) {
          try {
            await displayConfirmModal(
              {
                title: 'Warning!',
                message: getFuelDataLossWarningMessage(reportFuelDataLosses),
              },
              'Go to Fuel consumption',
              'Cancel',
            )
            push(`/MaerskStarConnect/vessel/${imoNo}/fuel-consumption`)
          } catch {
            return
          }
        }
        try {
          // Validate if Error exists on progress bar tabs of Engine Form
          const errorPersistingTabsOnEngine = getErrorPersistingTabs(
            hasValidationError,
            HDCFormType.ENGINE_FORM,
          )
          if (errorPersistingTabsOnEngine.length > 0) {
            const errorMessage = generateErrorMessage(
              errorPersistingTabsOnEngine,
            )
            if (errorMessage) {
              void displayErrorModal({
                statusText: 'Failed to submit report',
                message: errorMessage,
              })
            }
          } else {
            const response = await submitEngineRoomReport(imoNo, values.id)
            const warnings = getValidationWarnings(
              response.warnings,
              type,
              EngineRoomFormStep.SUMMARY,
            )
            if (warnings.length) {
              await displaySuccessModalWithWarnings(warnings, type)
              setValidationWarnings(
                response.warnings,
                type,
                Object.values(EngineRoomFormStep),
              )
              // change report state to `edit`, otherwise user won't be able to update data
              await updateReportStateToEdit(imoNo, response.id, type)
              setReport(response)
              window.scrollTo({ top: 0, behavior: 'smooth' })
            } else {
              await displaySuccessModal(type)
              resetState()
              push(`/MaerskStarConnect/vessel/${imoNo}/hdc/overview`)
            }
          }
        } catch (error) {
          if (error.statusCode >= 500) {
            return showInternalServerErrorModal()
          }
          if (error === 'cancel') {
            resetState()
            push(`/MaerskStarConnect/vessel/${imoNo}/hdc/overview`)
            return
          }
          if (error.body?.errors) {
            return handleApiValidationErrors(error.body.errors)
          }
          void displayErrorModal({
            statusText: 'Failed to proceed',
            message:
              error?.body?.error || 'Error occurred, please refresh the page.',
          })
        }
        return
      }

      // Patch engine room report flow
      const engineRoomReport =
        transformFormikReportToUpdateEngineRoomReportRequest(values)

      // When user saves the progress, only fields from current step should be sent to BE
      // That allows to save the data even if other steps have validation errors
      const filteredEngineRoomReport = Object.keys(engineRoomReport.engineRoom)
        .filter((field) =>
          FORM_STEP_TO_FIELDS[type][steps[currentStep]].includes(field),
        )
        .reduce(
          (result, field) => {
            result.engineRoom[field] = engineRoomReport.engineRoom[field]
            return result
          },
          { engineRoom: {} },
        )

      try {
        await updateEngineRoomReport(imoNo, values.id, filteredEngineRoomReport)
      } catch (error) {
        if (error.statusCode >= 500) {
          return showInternalServerErrorModal()
        }
        handleApiValidationErrors(error.body.errors)
      }
    } else {
      void displayErrorModal({
        statusText: 'Whoopsie',
        message:
          'Sorry sailor! Something have gone horribly wrong, if you see this message contact the dev team.',
      })
    }
  }

  const partialValidationSchema = useMemo(() => {
    return getPartialValidationSchema(
      type,
      steps[currentStep],
      report?.reportType,
    )
  }, [currentStep, type, steps, report?.reportType])

  return (
    <ContentLayout header='Reporting'>
      <Notifications alerts={alerts} warnings={warnings} />
      <TemNotifications />
      <PerformanceAlerts />
      {report && (
        <Formik
          initialValues={report}
          onSubmit={submitReport}
          validateOnChange={false}
          validateOnBlur={false}
          validate={(values: HDCReportFormValues) => {
            try {
              void validateYupSchema<HDCReportFormValues>(
                values,
                partialValidationSchema,
                true,
                {
                  useCustomArrival,
                  useCustomDeparture,
                  report: values,
                  vesselConfig: configuration,
                  state: HDCState,
                  initialValues: report,
                } as HDCFormValidationContext,
              )
              setHasValidationError(type, steps[currentStep], false)
            } catch (err) {
              const formErrors = yupToFormErrors(err)
              invalidateFormSteps(formErrors)
              return formErrors
            }
            return {}
          }}
        >
          <Form>
            <HDCReportCard />
          </Form>
        </Formik>
      )}
    </ContentLayout>
  )
}

export default HDCReportPage
