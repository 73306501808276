import React, { FunctionComponent } from 'react'
import styled, { IColorScheme, FuelColors } from '../../../../theme'
import { formatValue } from '../../../../utils'
import { MergedBatchRob } from '../../models'
import { FuelType } from '../../../../utils/models'
import { sortBatchesByTimestampDescending } from '../../utils'

const SumRow = styled.tr<IColorScheme>`
  background: ${({ fill }) => fill};
  color: ${({ font }) => font};

  &:hover {
    background-color: ${({ fill }) => fill} !important;
  }

  td {
    border: ${({ font }) => `1px solid ${font}`} !important;
    padding-top: 4px !important;
    padding-bottom: 4px !important;

    &:first-child {
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
    }

    &:last-child {
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
    }

    &:not(:first-child) {
      border-left: none !important;
    }
  }
`

interface RobMarkTableRowProps {
  batches: MergedBatchRob[]
  fuelType: number
}

export const RobMarkTableRow: FunctionComponent<RobMarkTableRowProps> = ({
  batches,
  fuelType,
}) => {
  const displayName = FuelType[fuelType]
  const fuelColorScheme = FuelColors[FuelType[fuelType]]

  const calculateRobSum = (batches: MergedBatchRob[]) => {
    let totalRob = 0
    batches.forEach((batch, index) => (totalRob = totalRob + batch.rob))
    return totalRob
  }
  return (
    <>
      <SumRow {...fuelColorScheme}>
        <td>{displayName}</td>
        <td>{formatValue(calculateRobSum(batches), 0)}</td>
      </SumRow>

      {batches.length > 0 &&
        batches.sort(sortBatchesByTimestampDescending).map((entry, index) => (
          <React.Fragment key={`name-${index}`}>
            <tr>
              <td title={entry.displayName}>{entry.displayName}</td>
              <td>{formatValue(entry.rob, 2)}</td>
            </tr>
          </React.Fragment>
        ))}
    </>
  )
}
