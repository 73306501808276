import styled from 'styled-components'
import { McLoadingIndicator } from '@maersk-global/mds-react-wrapper'

type Props = {
  width?: string
  spinnerSize?: number
  padding?: string
}

const Wrapper = styled.div<Props>`
  width: ${(props) => props.width || '100%'};
  height: 100%;
  padding: ${({ padding }) => (padding ? padding : '24px 0')};
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Loading = ({ spinnerSize, padding }: Props) => (
  <Wrapper padding={padding}>
    <McLoadingIndicator
      label='Loading'
      hiddenlabel
      fit='large'
      style={{ transform: `scale(${spinnerSize ? spinnerSize * 0.5 : 2})` }}
    />
  </Wrapper>
)
