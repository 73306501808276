import { formatValue } from '../../../utils'
import { getDifference } from './EnginePerformanceTestReportsComparison.utils'

type Props = {
  reportsCompared: Array<GandalfApi.MetcReport>
}

const Turbochargers = ({ reportsCompared }: Props) => {
  const a = reportsCompared[0].data
  const b = reportsCompared[1].data

  const renderRow = (header: string, field: string) => (
    <tr>
      <td>{header}</td>
      <td className='mds-table__cell--number'>
        {Array.from({ length: a.turbochargers.length }).map((_, i) => (
          <div key={i}>{i + 1}</div>
        ))}
      </td>
      {reportsCompared.map((rc) => (
        <td key={rc.reportId} className='mds-table__cell--number'>
          {rc.data.turbochargers.map((t) => (
            <div key={`${rc.reportId}_${t.number}`}>
              {formatValue(t[field].value, 2)}
            </div>
          ))}
        </td>
      ))}
      <td className='mds-table__cell--number'>
        {a.turbochargers.map((t, idx) => (
          <div key={t.number}>
            {getDifference(t[field].value!, b.turbochargers[idx][field].value!)}
          </div>
        ))}
      </td>
    </tr>
  )

  return (
    <>
      {renderRow('Speed [RPM]', 'speed')}
      {renderRow('Press Drop Air Filter [mm H₂O]', 'presDropAirFilter')}
      {renderRow('Press Drop Air Cooler [mm H₂O]', 'presDropAirCooler')}
      {renderRow('Exh Temp before TC [°C]', 'exhaustGasTempBeforeTc')}
      {renderRow('Exh Temp after TC [°C]', 'exhaustGasTempAfterTc')}
      {renderRow('Air temp. before cooler [°C]', 'airTempBeforeCooler')}
      {renderRow('Air temp. after cooler [°C]', 'airTempAfterCooler')}
      {renderRow('CW temp. before cooler [°C]', 'cwTempBeforeCooler')}
      {renderRow('CW temp. after cooler [°C]', 'cwTempAfterCooler')}
    </>
  )
}

export default Turbochargers
