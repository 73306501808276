import React, { useContext } from 'react'

import styled, { lg, md, media, sm } from '../theme'
import { HelpText } from '../features/help-texts'
import Notifications from '../components/Notifications/Notifications'
import { WindowContext } from '../contexts'

const FooterContainer = styled.div`
  ${media.sm`padding: ${sm.CONTENT_CARD_HEADER_PADDING}`}
  ${media.md`padding: ${md.CONTENT_CARD_HEADER_PADDING}`}
  ${media.lg`padding: ${lg.CONTENT_CARD_HEADER_PADDING}`}
  border-top: 1px solid ${(props) => props.theme.colors.grey[200]};
`

const Wrapper = styled.div<{
  width?: string
  height?: string
  contentPadding?: string
  contentHeight?: string
}>`
  box-shadow: ${(props) => props.theme.shadows[4]};
  background-color: ${(props) => props.theme.colors.card};
  height: ${(props) => props.height || 'fit-content'};
  max-width: ${(props) => props.width || '100%'};
  min-width: ${(props) => props.width || '100%'};
  border-radius: ${(props) => props.theme.borderRadius};

  .content-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    font-size: 16px;

    padding: 24px 16px 32px;
    @media (min-width: ${(props) => props.theme.breakPoints.medium}px) {
      padding: 32px 24px 40px;
    }
    @media (min-width: ${(props) => props.theme.breakPoints.large}px) {
      padding: 40px 32px 48px;
    }

    ${media.sm`height: ${sm.BIG_BAR_HEIGHT}`}
    ${media.md`height: ${md.BIG_BAR_HEIGHT}`}
    ${media.lg`height: ${lg.BIG_BAR_HEIGHT}`}
    &--title {
      display: flex;
      align-items: center;
    }

    .additionalInfo {
      color: ${(props) => props.theme.colors.grey[700]};
      ${media.sm`font-size: ${sm.CONTENT_CARD_ADDITIONAL_INFO_FONT_SIZE}`}
      ${media.md`font-size: ${md.CONTENT_CARD_ADDITIONAL_INFO_FONT_SIZE}`}
      ${media.lg`font-size: ${lg.CONTENT_CARD_ADDITIONAL_INFO_FONT_SIZE}`}
    }
  }

  .header-content {
    display: flex;
    align-items: center;
  }

  .content-card-content {
    height: ${(props) =>
      props.contentHeight ? props.contentHeight : 'fit-content'};
    padding: ${(props) =>
      props.contentPadding ? `${props.contentPadding}` : '1px'};
  }
`

type Props = {
  additionalInfo?: ReadonlyArray<React.ReactNode> | React.ReactNode | string
  className?: string
  headerChildren?: ReadonlyArray<React.ReactNode> | React.ReactNode
  height?: string
  helpTextKey?: string
  hide?: boolean
  id: string
  notification?: string
  title: string
  width?: string
  contentPadding?: string
  contentHeight?: string
  footerContent?: React.ReactNode
  children?: React.ReactNode
  hideHeader?: boolean
}

export const ContentCard = (props: Props) => {
  const { windowSize } = useContext(WindowContext)

  const {
    additionalInfo,
    children,
    className,
    headerChildren,
    height,
    helpTextKey,
    hide,
    id,
    notification,
    title,
    width,
    contentPadding,
    contentHeight,
    footerContent,
    hideHeader,
  } = props

  if (hide) return null

  const titleSizeClassName =
    windowSize === 'large' ? 'mds-headline--small' : 'mds-headline--x-small'

  return (
    <Wrapper
      id={id}
      className={className}
      width={width}
      height={height}
      contentPadding={contentPadding}
      contentHeight={contentHeight}
    >
      {!hideHeader && (
        <div className='content-card-header'>
          <div className={`${titleSizeClassName} content-card-header--title`}>
            {title}
            {!!helpTextKey && <HelpText textKey={helpTextKey} />}
          </div>
          <div className='header-content'>
            {additionalInfo ? (
              <span className='additionalInfo'>{additionalInfo}</span>
            ) : null}
            {headerChildren}
          </div>
        </div>
      )}
      {!!notification && <Notifications advices={[notification]} />}
      <div className='content-card-content'>{children}</div>
      {footerContent && <FooterContainer>{footerContent}</FooterContainer>}
    </Wrapper>
  )
}
