import EfficiencyResultCell from './EfficiencyResultCell'
import { getDifference } from './EnginePerformanceTestReportsComparison.utils'
import { MetcDataFormat } from '../../../queries/GandalfApi/GandalfApi.consts'

type Props = {
  reportsCompared: Array<GandalfApi.MetcReport>
}

const TcEfficiency = ({ reportsCompared }: Props) => {
  const a = reportsCompared[0].data
  const b = reportsCompared[1].data

  // Get the lowest data format of the two reports. This is used to determine
  // if we should render certain fields or not.
  const dataFormatMin = Math.min(
    reportsCompared[0].dataFormat,
    reportsCompared[1].dataFormat,
  )

  return (
    <>
      <tr>
        <th>Turbocharger Efficiency</th>
        <th />
        <th />
        <th />
      </tr>
      <tr>
        <td>Scavenging Air Pressure [bar]</td>
        {reportsCompared.map((rc) => (
          <td key={rc.reportId} className='mds-table__cell--number'>
            <EfficiencyResultCell
              efficiencyResult={rc.data.result.tcEfficiency.scavAirPres}
              precision={2}
            />
          </td>
        ))}
        <td className='mds-table__cell--number'>
          {getDifference(
            a.result.tcEfficiency.scavAirPres.isoConditionAdjustedValue!,
            b.result.tcEfficiency.scavAirPres.isoConditionAdjustedValue!,
          )}
        </td>
      </tr>
      <tr>
        <td>Temperature before TC [°C]</td>
        {reportsCompared.map((rc) => (
          <td key={rc.reportId} className='mds-table__cell--number'>
            <EfficiencyResultCell
              efficiencyResult={rc.data.result.tcEfficiency.tempBeforeTc}
              precision={1}
            />
          </td>
        ))}
        <td className='mds-table__cell--number'>
          {getDifference(
            a.result.tcEfficiency.tempBeforeTc.isoConditionAdjustedValue!,
            b.result.tcEfficiency.tempBeforeTc.isoConditionAdjustedValue!,
          )}
        </td>
      </tr>
      {dataFormatMin >= MetcDataFormat.physicalCondition && (
        <>
          <tr>
            <td>Air Filter Pressure Drop [mm H₂O]</td>
            {reportsCompared.map((rc) => (
              <td key={rc.reportId} className='mds-table__cell--number'>
                <EfficiencyResultCell
                  efficiencyResult={
                    rc.data.result.tcEfficiency.airFilterPresDrop
                  }
                  precision={1}
                />
              </td>
            ))}
            <td className='mds-table__cell--number'>
              {getDifference(
                a.result.tcEfficiency.airFilterPresDrop
                  .isoConditionAdjustedValue!,
                b.result.tcEfficiency.airFilterPresDrop
                  .isoConditionAdjustedValue!,
              )}
            </td>
          </tr>
          <tr>
            <td>Air Cooler Pressure Drop [mm H₂O]</td>
            {reportsCompared.map((rc) => (
              <td key={rc.reportId} className='mds-table__cell--number'>
                <EfficiencyResultCell
                  efficiencyResult={
                    rc.data.result.tcEfficiency.airCoolerPresDrop
                  }
                  precision={1}
                />
              </td>
            ))}
            <td className='mds-table__cell--number'>
              {getDifference(
                a.result.tcEfficiency.airCoolerPresDrop
                  .isoConditionAdjustedValue!,
                b.result.tcEfficiency.airCoolerPresDrop
                  .isoConditionAdjustedValue!,
              )}
            </td>
          </tr>
        </>
      )}
      <tr>
        <td>TC RPM</td>
        {reportsCompared.map((rc) => (
          <td key={rc.reportId} className='mds-table__cell--number'>
            <EfficiencyResultCell
              efficiencyResult={rc.data.result.tcEfficiency.tcSpeed}
              precision={0}
            />
          </td>
        ))}
        <td className='mds-table__cell--number'>
          {getDifference(
            a.result.tcEfficiency.tcSpeed.isoConditionAdjustedValue!,
            b.result.tcEfficiency.tcSpeed.isoConditionAdjustedValue!,
          )}
        </td>
      </tr>
      <tr>
        <td>Total Efficiency [%]</td>
        {reportsCompared.map((rc) => (
          <td key={rc.reportId} className='mds-table__cell--number'>
            <EfficiencyResultCell
              efficiencyResult={rc.data.result.tcEfficiency.totalEfficiency}
              precision={1}
            />
          </td>
        ))}
        <td className='mds-table__cell--number'>
          {getDifference(
            a.result.tcEfficiency.totalEfficiency.isoConditionAdjustedValue!,
            b.result.tcEfficiency.totalEfficiency.isoConditionAdjustedValue!,
          )}
        </td>
      </tr>
      <tr>
        <td>Compressor Efficiency [%]</td>
        {reportsCompared.map((rc) => (
          <td key={rc.reportId} className='mds-table__cell--number'>
            <EfficiencyResultCell
              efficiencyResult={
                rc.data.result.tcEfficiency.compressorEfficiency
              }
              precision={1}
            />
          </td>
        ))}
        <td className='mds-table__cell--number'>
          {getDifference(
            a.result.tcEfficiency.compressorEfficiency
              .isoConditionAdjustedValue!,
            b.result.tcEfficiency.compressorEfficiency
              .isoConditionAdjustedValue!,
          )}
        </td>
      </tr>
      <tr>
        <td>Turbine Efficiency [%]</td>
        {reportsCompared.map((rc) => (
          <td key={rc.reportId} className='mds-table__cell--number'>
            <EfficiencyResultCell
              efficiencyResult={rc.data.result.tcEfficiency.turbineEfficiency}
              precision={1}
            />
          </td>
        ))}
        <td className='mds-table__cell--number'>
          {getDifference(
            a.result.tcEfficiency.turbineEfficiency.isoConditionAdjustedValue!,
            b.result.tcEfficiency.turbineEfficiency.isoConditionAdjustedValue!,
          )}
        </td>
      </tr>
    </>
  )
}

export default TcEfficiency
