import moment from 'moment'
import * as yup from 'yup'
import {
  EventTypeName,
  HDCReportFormValues,
  ValidationErrorType,
} from '../types'

interface IEventValidationContext {
  report: HDCReportFormValues
}

const alternativeArrivalTerminal = yup
  .object()
  .shape({
    code: yup.string(),
    name: yup.string(),
  })
  .test(ValidationErrorType.CROSSFIELD, '', function () {
    const {
      useCustomArrival,
      remainingDistanceToPilotStation,
      alternativeArrivalTerminal,
    } = this.parent
    if (
      useCustomArrival &&
      !remainingDistanceToPilotStation &&
      (!alternativeArrivalTerminal.code || !alternativeArrivalTerminal.name)
    ) {
      return this.createError({
        path: this.path,
        message:
          'Either arrival port or remaining distance to PS should be provided',
      })
    }
    return true
  })

const arrivalTerminal = yup
  .object()
  .shape({
    code: yup.string(),
  })
  .test(ValidationErrorType.CROSSFIELD, '', function () {
    const {
      useCustomArrival,
      remainingDistanceToPilotStation,
      arrivalTerminal,
    } = this.parent
    if (
      !useCustomArrival &&
      !remainingDistanceToPilotStation &&
      !arrivalTerminal.code
    ) {
      return this.createError({
        path: this.path,
        message:
          'Either arrival port or remaining distance to PS should be provided',
      })
    }
    return true
  })

const comment = yup.string().nullable().required('Comment cannot be empty')

const getEventValidationSchema = (type: EventTypeName) =>
  validationSchemaMap[type]

const endTimestamp = yup
  .string()
  .test(ValidationErrorType.OUT_OF_RANGE, '', function (datestring: string) {
    const { report } = this.options.context as IEventValidationContext
    const endTimestamp = moment(datestring).utc()

    if (!report) {
      return this.createError({
        path: this.path,
        message:
          'Validation failed due to missing report, please refresh and try again',
      })
    }

    if (endTimestamp.isAfter(report.periodEnd)) {
      return this.createError({
        path: this.path,
        message: 'Event end date/time cannot be after report end date/time',
      })
    }

    if (endTimestamp.isSameOrBefore(report.periodStart)) {
      return this.createError({
        path: this.path,
        message: 'Event end date/time should be after report start date/time',
      })
    }

    if (endTimestamp.isSameOrBefore(this.parent.startTimestamp)) {
      return this.createError({
        path: this.path,
        message: 'End date/time should be after start date/time',
      })
    }

    return true
  })
  .nullable()
  .required('End date/time cannot be empty')

const eta = yup
  .string()
  .test(ValidationErrorType.OUT_OF_RANGE, '', function (datestring: string) {
    const { report } = this.options.context as IEventValidationContext
    const eta = moment(datestring).utc()

    if (eta.isBefore(report.periodEnd)) {
      return this.createError({
        path: this.path,
        message: 'New ETA should be after report end date/time',
      })
    }

    if (eta.isSameOrBefore(moment(this.parent.timestamp).utc())) {
      return this.createError({
        path: this.path,
        message: 'New ETA should be after ETA change date/time',
      })
    }

    return true
  })
  .nullable()
  .required('New ETA cannot be empty')

const port = yup
  .object()
  .shape({
    code: yup.string(),
    name: yup.string(),
  })
  .test(
    ValidationErrorType.REQUIRED,
    '',
    function (port: { code: string; name: string }) {
      if (!port.code || !port.name) {
        return this.createError({
          path: this.path,
          message: 'Port code and name cannot be empty',
        })
      }
      return true
    },
  )

const quantity = yup
  .number()
  .nullable()
  .required('Quantity cannot be empty')
  .positive('Quantity should be more than 0')

const remainingDistanceToPilotStation = yup
  .number()
  .test(ValidationErrorType.CROSSFIELD, '', function () {
    const {
      useCustomArrival,
      remainingDistanceToPilotStation,
      arrivalTerminal,
      alternativeArrivalTerminal,
    } = this.parent
    if (
      useCustomArrival &&
      !remainingDistanceToPilotStation &&
      (!alternativeArrivalTerminal.code || !alternativeArrivalTerminal.name)
    ) {
      return this.createError({
        path: this.path,
        message:
          'Either arrival port or remaining distance to PS should be provided',
      })
    }
    if (
      !useCustomArrival &&
      !remainingDistanceToPilotStation &&
      !arrivalTerminal.code
    ) {
      return this.createError({
        path: this.path,
        message:
          'Either arrival port or remaining distance to PS should be provided',
      })
    }
    return true
  })
  .nullable()

const startTimestamp = yup
  .string()
  .test(ValidationErrorType.OUT_OF_RANGE, '', function (datestring: string) {
    const { report } = this.options.context as IEventValidationContext
    const startTimestamp = moment(datestring).utc()

    if (!report) {
      return this.createError({
        path: this.path,
        message:
          'Validation failed due to missing report, please refresh and try again',
      })
    }

    if (startTimestamp.isBefore(report.periodStart)) {
      return this.createError({
        path: this.path,
        message:
          'Event start date/time cannot be before report start date/time',
      })
    }

    if (startTimestamp.isSameOrAfter(report.periodEnd)) {
      return this.createError({
        path: this.path,
        message: 'Event start date/time should be before report end date/time',
      })
    }

    if (startTimestamp.isSameOrAfter(this.parent.endTimestamp)) {
      return this.createError({
        path: this.path,
        message: 'Start date/time should be before end date/time',
      })
    }

    return true
  })
  .nullable()
  .required('Start date/time cannot be empty')

const timeLoss = yup
  .object()
  .test(ValidationErrorType.REQUIRED, '', function ({ days, hours, minutes }) {
    const { periodStart, periodEnd } = this.parent
    if (!periodStart || !periodEnd) {
      return true
    }

    if (days < 0 || hours < 0 || minutes < 0) {
      return this.createError({
        path: this.path,
        message: 'Time loss cannot be negative',
      })
    }

    if ((days || 0) + (hours || 0) + (minutes || 0) === 0) {
      return this.createError({
        path: this.path,
        message: 'Time loss should be more than 0',
      })
    }

    return true
  })

const timestamp = yup
  .string()
  .test(ValidationErrorType.OUT_OF_RANGE, '', function (datestring: string) {
    const { report } = this.options.context as IEventValidationContext
    const timestamp = moment(datestring).utc()

    if (timestamp.isBefore(report.periodStart)) {
      return this.createError({
        path: this.path,
        message: 'Date/time cannot be before report start date/time',
      })
    }

    if (timestamp.isAfter(report.periodEnd)) {
      return this.createError({
        path: this.path,
        message: 'Date/time cannot be after report end date/time',
      })
    }

    return true
  })
  .nullable()
  .required('Date/time cannot be empty')

const typeId = yup.number().required('Off-service reason cannot be empty')

const validationSchemaMap = {
  [EventTypeName.ANCHORAGE]: yup
    .object()
    .shape({ startTimestamp, endTimestamp }),
  [EventTypeName.DISCHARGE_SLUDGE]: yup.object().shape({ timestamp, quantity }),
  [EventTypeName.ETA_CHANGE]: yup.object().shape({ timestamp, eta }),
  [EventTypeName.ROUTE_CHANGE]: yup.object().shape({
    timestamp,
    arrivalTerminal,
    alternativeArrivalTerminal,
    remainingDistanceToPilotStation,
  }),
  [EventTypeName.TECHNICAL_OFF_SERVICE]: yup
    .object()
    .shape({ typeId, startTimestamp, endTimestamp, timeLoss, comment }),
  [EventTypeName.OPERATIONAL_OFF_SERVICE]: yup
    .object()
    .shape({ typeId, startTimestamp, endTimestamp, timeLoss, port, comment }),
}

export default getEventValidationSchema
