import { useCallback, useContext, useEffect, useMemo } from 'react'
import { type RouteComponentProps, withRouter } from 'react-router'
import { Field, Formik, FormikHelpers } from 'formik'
import moment, { type Moment } from 'moment'
import styled, { useTheme } from 'styled-components'
import { McButton } from '@maersk-global/mds-react-wrapper'

import { ApiReportType } from '../../../../api-models/hdc/report'
import { FormDateTimeInput, Loading, Modal } from '../../../../commons'
import Notifications from '../../../../components/Notifications/Notifications'
import FormRow from '../../../../components/form/row'
import {
  displayConfirmModal,
  displayErrorModal,
  isShoreContext,
} from '../../../../utils'
import OverlayLoader from '../../components/overlay-loader'
import { ReportTypeNames } from '../../constants'
import { HDCContext } from '../../context'
import {
  flattenFormikErrors,
  getFormikTouchedFieldsErrors,
  getLatestReport,
  isReportSubmitted,
} from '../../utils'
import ModalContent from '../modal-content'
import validationSchema from './validation'

interface Props extends RouteComponentProps {
  visible: boolean
  reportType: ApiReportType
  onClose: () => void
}

interface InitialValues {
  reportType: ApiReportType
  from: Moment | undefined
  to: Moment | undefined
}

const Wrapper = styled.div`
  max-width: 500px;
  position: relative;
`

const Header = styled.p`
  text-align: justify;
  margin-bottom: 24px !important;
`

const Footer = styled.div`
  display: flex;
  gap: 1.6rem;
  flex-direction: row;
  justify-content: flex-end;
  background-color: #f8f8f8;
  padding: 10px 20px;
  border-top: 1px solid #e7e8e9;
`

const LatestReport = styled.p`
  font-size: 14px;
  margin-bottom: 16px;
`

const HDCNewReportModal = ({
  visible,
  reportType,
  onClose,
  location,
  history,
}: Props) => {
  const theme = useTheme()
  const { imoNo, initiateReport, setReport, getReports, state } =
    useContext(HDCContext)

  const latestReport = useMemo(() => getLatestReport(state.reports), [state])

  const displaySuccessfulDraftReportCreationModalWithWarnings = async () => {
    const title = 'Report not yet created'
    const modalContentMsg = (
      <div>
        If ETA has changed since last report, please continue and add ETA change
        event in this report. Otherwise please align closing time for this Sea
        report with your ETA for Pilot station.
      </div>
    )
    const message = (
      <ModalContent
        icon='exclamation-triangle-solid'
        iconColor={theme.colors.feedback.warning.main}
        message={modalContentMsg}
        title={`The selected closing time for this sea report is later than ETA to Pilot Station of the previous sea report.`}
      />
    )
    return displayConfirmModal(
      { title, message },
      'Create the report anyway',
      'Change the closing date',
    )
  }

  useEffect(() => {
    // Get reports if /report pages is the first shown in the flow,
    // to fetch older reports.
    if (state.reports === undefined) {
      void getReports()
    }
  }, [state, getReports])

  const handleSubmit = async (
    values: InitialValues,
    helpers: FormikHelpers<any>,
  ): Promise<void> => {
    if (
      reportType === ApiReportType.SEA &&
      !!state.reports &&
      state.reports.length >= 1
    ) {
      if (latestReport !== null && isReportSubmitted(latestReport)) {
        const newDraftReportPeriodEnd = new Date(values.to!.toISOString())
        const previousReportBridgeEtaValue = new Date(
          Date.parse(latestReport.bridge.eta.value!),
        )
        // if closing date of the submitted draft report is later than previous report ETA date (applies to sea reports only)
        if (
          newDraftReportPeriodEnd.getTime() >
          previousReportBridgeEtaValue.getTime()
        ) {
          await displaySuccessfulDraftReportCreationModalWithWarnings()
        }
      }
    }

    try {
      const response = await initiateReport({
        reportType: reportType,
        periodStart: values.from!.toISOString(),
        periodEnd: values.to!.toISOString(),
      })
      await getReports()

      setReport(response.report)
      if (location.pathname.includes('/hdc/overview')) {
        history.push(`/MaerskStarConnect/vessel/${imoNo}/hdc/report`)
      } else {
        onClose()
      }
    } catch (error) {
      return displayErrorModal({
        statusText: 'Error occurred',
        message: error?.body?.error || 'Please refresh the page and try again',
      })
    } finally {
      helpers.setSubmitting(false)
    }
  }

  const reportToTimestamp = useCallback(
    (fromTimestamp: Moment | undefined): Moment | undefined => {
      if (reportType === ApiReportType.SEA) {
        if (latestReport) {
          return moment(latestReport.periodEnd).utc().add(36, 'h')
        } else {
          return moment(fromTimestamp).utc().add(36, 'h')
        }
      } else {
        return undefined
      }
    },
    [latestReport, reportType],
  )

  return (
    <Modal
      minWidth='500px'
      title={`Add new ${ReportTypeNames[reportType]}`}
      visible={visible}
      closeHandler={onClose}
    >
      {state.reports === undefined ? (
        <Loading />
      ) : (
        <Formik
          initialValues={{
            reportType,
            from: latestReport
              ? moment(latestReport.periodEnd).utc()
              : undefined,
            to: undefined,
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            errors: formikErrors,
            isSubmitting,
            submitForm,
            touched,
            values,
          }) => {
            const errors = getFormikTouchedFieldsErrors(formikErrors, touched)
            return (
              <Wrapper>
                <div style={{ padding: '20px' }}>
                  <Header>
                    {reportType === ApiReportType.SEA && (
                      <span>
                        The sea report covers the period from previous report
                        closing time to the timestamp you enter here.
                      </span>
                    )}
                    {reportType === ApiReportType.ARRIVAL && (
                      <span>
                        The Arrival report covers the period from "Arrival Pilot
                        Station" to "All Fast". Please make sure to align with
                        your log book / PortModule and insert the timestamp for
                        "All Fast".
                      </span>
                    )}
                    {reportType === ApiReportType.ALONGSIDE && (
                      <span>
                        The Alongside report covers the period from "All Fast"
                        to "Last Line". Please make sure to align with your log
                        book / PortModule and insert the timestamp for "Last
                        Line".
                      </span>
                    )}
                    {reportType === ApiReportType.DEPARTURE && (
                      <span>
                        The Departure report covers the period from "Last Line"
                        to "Pilot Off". Please make sure to align with your log
                        book / PortModule and Insert the timestamp for "Pilot
                        Off".
                      </span>
                    )}
                    {reportType === ApiReportType.CANAL && (
                      <span>
                        The Canal report covers the period from Pilot station
                        and until you drop canal Pilot.
                      </span>
                    )}
                  </Header>
                  <Notifications alerts={flattenFormikErrors(errors)} />
                  <FormRow direction='column'>
                    {latestReport ? (
                      <LatestReport>
                        Latest report was closed at:{' '}
                        {moment(latestReport.periodEnd)
                          .utc()
                          .format('DD MMM YYYY HH:mm')}{' '}
                        UTC
                      </LatestReport>
                    ) : (
                      <>
                        <Field
                          name='from'
                          component={FormDateTimeInput}
                          selected={values.from}
                          id='new-report-from'
                          minuteSpecific={true}
                          error={errors.from}
                          label='Report start timestamp, UTC'
                          zIndex='2'
                        />
                      </>
                    )}
                  </FormRow>
                  <FormRow direction='column'>
                    <>
                      <Field
                        name='to'
                        component={FormDateTimeInput}
                        selected={values.from}
                        id='new-report-to'
                        minuteSpecific={true}
                        error={errors.to}
                        minDate={
                          latestReport
                            ? moment(latestReport.periodEnd)
                                .utc()
                                .add(10, 'minutes')
                            : undefined
                        }
                        maxDate={reportToTimestamp(values.from)}
                        disabled={!values.from}
                        label='Report end timestamp, UTC'
                        zIndex='1'
                      />
                    </>
                  </FormRow>
                </div>
                <Footer>
                  <McButton
                    label='Cancel'
                    appearance='neutral'
                    click={onClose}
                    disabled={isSubmitting}
                  />
                  {!isShoreContext() && (
                    <McButton
                      label='Save'
                      appearance='primary'
                      click={submitForm}
                      disabled={
                        !!flattenFormikErrors(errors).length || isSubmitting
                      }
                      type='button'
                      data-e2e='SubmitReport'
                    />
                  )}
                </Footer>
                {isSubmitting && <OverlayLoader padding='0px' />}
              </Wrapper>
            )
          }}
        </Formik>
      )}
    </Modal>
  )
}

export default withRouter(HDCNewReportModal)
