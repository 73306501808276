import * as React from 'react'
import { EChartOption, init } from 'echarts'
import { getEngineData, getEngineTheme, tooltipFormatter } from '../utils'
import { grey } from '../../../theme'
import { stripDecimals } from '../../../utils'
import { Chart } from '../../../utils/models'
import {
  ACTIVATE_ZOOM_ACTION,
  AXIS_LABEL,
  AXIS_LINE,
  AXIS_SPLIT_LINE,
  AXIS_TICK,
  GRID,
  TOOLBOX_TIMELINE,
  X_AXIS_TIMELINE,
} from '../../../utils/constants'
import { EngineChartModel, ToggleInMeSfocChart } from '../models'

interface MeSfocVsTimeChartProps {
  data: HydraApi.Sfoc.MainEngine
  dataShopTestModels?: MasterDataApi.StaticModels.VesselStaticModels<MasterDataApi.StaticModels.StaticModelData>
  dataVesselSpecific?: HydraApi.SfocBaselineModel.BaselineModel
  timeLineMinValue: number
  hasTwoMainEngines: boolean
  hasVesselSpecificEnabled: boolean
  toggleInChart: ToggleInMeSfocChart
}

interface MeSfocVsTimeChartState {}

export class MeSfocVsTimeChart extends React.Component<
  MeSfocVsTimeChartProps,
  MeSfocVsTimeChartState
> {
  private chartNode: React.RefObject<HTMLDivElement>
  private chart?: Chart

  constructor(props: MeSfocVsTimeChartProps) {
    super(props)

    this.state = {}
    this.chartNode = React.createRef()
  }

  getOptions = (data: HydraApi.Sfoc.MainEngine): EChartOption => {
    const { toggleInChart, timeLineMinValue, hasTwoMainEngines } = this.props
    let adviceTipRightSide = true
    const engines = getEngineData(data, hasTwoMainEngines)
    return {
      grid: {
        ...GRID,
        bottom: 60,
      },
      xAxis: {
        ...X_AXIS_TIMELINE,
      },
      yAxis: [
        {
          id: 'power-vs-time',
          type: 'value',
          axisTick: AXIS_TICK,
          axisLine: { ...AXIS_LINE, onZero: true },
          splitLine: AXIS_SPLIT_LINE,
          axisLabel: {
            formatter: (value: any) => stripDecimals(value, 1),
            ...AXIS_LABEL,
          },
          min: 'dataMin',
          max: 'dataMax',
          boundaryGap: ['0%', '10%'],
        },
      ],
      legend: {
        show: false,
        selected: {
          Mix: toggleInChart.Mix,
          ME1: toggleInChart.ME1,
          ME2: toggleInChart.ME2,
        },
      },

      toolbox: TOOLBOX_TIMELINE,
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'line',
          axis: 'x',
        },
        textStyle: {
          color: grey[50],
        },
        backgroundColor: 'rgba(50,50,50,0.9)',
        position: (pos, params, dom, rect, size: any) => {
          var obj: any = { top: size.viewSize[1] / 4 }
          adviceTipRightSide = (pos[0] as number) < size.viewSize[0] / 2
          const xPos = adviceTipRightSide
            ? +pos[0] + 20
            : size.viewSize[0] - (+pos[0] - 20)
          obj[['right', 'left'][+adviceTipRightSide]] = xPos
          return obj
        },
        formatter: (params: any) => this.tooltipFormatter(engines, params),
      },
      dataZoom: [
        {
          type: 'slider',
          startValue: timeLineMinValue,
          minValueSpan: 3600 * 12 * 1000,
          zoomOnMouseWheel: false,
          bottom: 20,
        },
      ],
      series: engines.map((engine) => {
        const seriesColor = getEngineTheme(engine.name, true)
        return {
          name: engine.name,
          type: 'line',
          data: data.timestamps.map((timestamp, index) => [
            timestamp,
            engine.data.sfocSeries[index],
          ]),
          symbol: 'none',
          areaStyle: {
            color: seriesColor.fill,
          },
          lineStyle: {
            color: seriesColor.stroke,
          },
        }
      }),
    }
  }

  tooltipFormatter = (engines: EngineChartModel[], params: any) => {
    const {
      data,
      dataShopTestModels,
      dataVesselSpecific,
      hasTwoMainEngines,
      hasVesselSpecificEnabled,
    } = this.props
    return tooltipFormatter(
      params[0].dataIndex,
      engines,
      hasTwoMainEngines,
      hasVesselSpecificEnabled,
      data,
      dataVesselSpecific,
      dataShopTestModels,
    )
  }

  zoomOut = (silent?: boolean) => {
    if (this.chart) {
      this.chart.dispatchAction(
        {
          type: 'dataZoom',
          startValue: this.props.timeLineMinValue,
          endValue:
            this.props.data.timestamps[this.props.data.timestamps.length - 1],
        },
        !!silent,
      )
    }
  }

  zoomIn = (action: object) => {
    if (this.chart) {
      this.chart.dispatchAction(action, true)
    }
  }

  componentDidMount() {
    const { data } = this.props
    if (this.chartNode.current) {
      const chart = init(this.chartNode.current) as Chart
      chart.setOption(this.getOptions(data), { lazyUpdate: true })
      chart.dispatchAction(ACTIVATE_ZOOM_ACTION)
      chart.group = 'me-sfoc'
      this.chart = chart
      this.chart.on('dataZoom', this.zoomIn)
    }
  }

  componentDidUpdate(prevProps: MeSfocVsTimeChartProps) {
    const { data, toggleInChart } = this.props
    const { ME1, ME2, Mix } = toggleInChart
    if (this.chart) {
      if (prevProps.data !== data) {
        this.chart.setOption(this.getOptions(data))
      }
      if (this.chart && ME1 !== prevProps.toggleInChart.ME1) {
        this.chart.dispatchAction({
          type: 'legendToggleSelect',
          name: 'ME1',
        })
      }
      if (ME2 !== prevProps.toggleInChart.ME2) {
        this.chart.dispatchAction({
          type: 'legendToggleSelect',
          name: 'ME2',
        })
      }
      if (Mix !== prevProps.toggleInChart.Mix) {
        this.chart.dispatchAction({
          type: 'legendToggleSelect',
          name: 'Mix',
        })
      }
    }
  }

  public render() {
    return (
      <div
        onDoubleClick={() => {
          this.zoomOut(false)
        }}
        id='me-power-vs-time-chart'
        ref={this.chartNode}
      />
    )
  }
}
