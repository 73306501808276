import { orangeTheme, fillOpacity, normal } from '../../theme'

export const whr = {
  stroke: orangeTheme.border,
  fill: fillOpacity(orangeTheme, 0.2).bg,
  font: orangeTheme.fg,
}

export const load = {
  stroke: normal.border,
  fill: normal.bg,
  font: normal.fg,
}
