import { useEffect, useState, useContext } from 'react'
import Moment from 'moment'
import { McButton } from '@maersk-global/mds-react-wrapper'

import { DateTimeInput, Modal, Loading } from '../../../../commons'
import { getTotalizerResponse } from '../../../../services/performance'
import {
  FuelLineTotalizer,
  TotalizerLog,
} from '../../../../api-models/performance/fuel-consumption'
import { VesselPageContext } from '../../../../contexts'
import { displayErrorModal } from '../../../../utils'
import * as S from './style'

type Props = {
  visible: boolean
  handleClose: () => void
  timestamp: string
}

export const TotalizerModal = ({ visible, timestamp, handleClose }: Props) => {
  const imoNo = useContext(VesselPageContext).imoNo!
  const [loaded, setLoaded] = useState(false)
  const [totalizers, setTotalizers] = useState<FuelLineTotalizer>()
  const [currentTotalizer, setCurrentTotalizer] = useState<TotalizerLog>()
  const [customTimestamp, setCustomTimestamp] = useState(timestamp)

  // Reset custom timestamp when you open the totalizer
  useEffect(() => setCustomTimestamp(timestamp), [visible, timestamp])

  // Fetch totalizer data from PAPI using the custom timestamp
  useEffect(() => {
    if (visible) {
      setLoaded(false)
      getTotalizerResponse(imoNo, customTimestamp)
        .then((response: FuelLineTotalizer) => {
          setTotalizers(response)
        })
        .catch((err) => {
          void displayErrorModal(err)
        })
        .finally(() => setLoaded(true))
    }
  }, [visible, imoNo, customTimestamp])

  // Find the current totalizer and store in state
  useEffect(() => {
    setCurrentTotalizer(totalizers?.logs.find((item) => item.closest))
  }, [totalizers])

  return (
    <Modal
      title='Totalizer at specific time'
      visible={visible}
      closeHandler={handleClose}
    >
      <S.Wrapper>
        <S.Description>
          Here you can see the details of the calculated totalizer. If you wish
          to change it, insert a timestamp to get totalizer values for your
          flowmeters.
        </S.Description>
        <DateTimeInput
          onChange={(val) => setCustomTimestamp(val.toISOString())}
          selected={Moment.utc(customTimestamp)}
        />

        {loaded && (
          <>
            <S.Header>Totalizer values</S.Header>
            <S.Totalizers>
              {totalizers?.hasData &&
                currentTotalizer?.valueSeries.map((totalizer) => (
                  <S.Totalizer key={totalizer.dataPointId}>
                    <div>{totalizer.dataPointName}</div>
                    <div>{totalizer.dataPointValue}</div>
                  </S.Totalizer>
                ))}

              {!totalizers?.hasData && <p>No data</p>}
            </S.Totalizers>
          </>
        )}

        {!loaded && <Loading />}
      </S.Wrapper>
      <S.Actions>
        <McButton label='Ok' appearance='primary' click={handleClose} />
      </S.Actions>
    </Modal>
  )
}

export default TotalizerModal
