import { useMemo } from 'react'
import { getCoreRowModel, useReactTable } from '@tanstack/react-table'

import { columns } from './EnergyConsumption.utils'
import { type Performance } from '../../../../api-models'
import Table from '../../../../components/Table/Table'

type Props = {
  data: Performance.Counter.ElectricalConsumption
}

const EnergyConsumption = ({ data }: Props) => {
  const consumers = useMemo(
    () =>
      data.consumers.map((consumer) => ({
        ...consumer,
        ...(consumer.dataAvailability < 100 && {
          cellFeedback: { energy: 'danger', runningMinutes: 'danger' },
        }),
      })),
    [data],
  )

  const table = useReactTable({
    data: consumers,
    columns,
    getCoreRowModel: getCoreRowModel(),
  })

  return <Table table={table} hidePagination showFooter />
}

export default EnergyConsumption
