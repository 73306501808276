import { type FilterFn } from '@tanstack/react-table'

import { LUBE_OIL_TYPE_NAME } from '../../../lube-oil/constants'
import { ELubeOilType, type TLubeOilEntry } from '../../../lube-oil/types'

export const oilTypeFilterOptions = Object.entries(LUBE_OIL_TYPE_NAME).map(
  ([value, label]) => ({ value, label }),
)

export const oilTypeFilter: FilterFn<TLubeOilEntry> = (
  row,
  columnId,
  oilType: ELubeOilType,
) => row.original.oilType === oilType
