import React, { FunctionComponent } from 'react'
import { Field, getIn, FieldArrayRenderProps } from 'formik'
import { Icon, InfoBox } from '../../../../commons'

import styled, { IColorScheme, errorTheme } from '../../../../theme'
import { FormInputWithUnit } from '../../../../commons'
import { SoundingWarning } from './SoundingWarning'
import { formatValue, commaAgnostic } from '../../../../utils'

const SumRow = styled.tr<IColorScheme>`
  background: ${({ fill }) => fill};
  color: ${({ font }) => font};

  &:hover {
    background-color: ${({ fill }) => fill} !important;
  }

  td {
    border: ${({ font }) => `1px solid ${font}`} !important;
    padding-top: 4px !important;
    padding-bottom: 4px !important;

    &:first-child {
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
    }

    &:last-child {
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
    }

    &:not(:first-child) {
      border-left: none !important;
    }
  }
`

interface SoundingTableRowProps {
  onCalcClick: (val: string) => void
  scheme: IColorScheme
  disabled: boolean
  readonly: boolean
}

const roundedValue = (val: number): number =>
  Math.round((val + Number.EPSILON) * 100) / 100

const DISPLAY_NAME_MAPPER = {
  hsValues: 'HS',
  ulsValues: 'ULS',
  vlsValues: 'VLS',
  mdoValues: 'MDO',
}

export const SoundingTableRow: FunctionComponent<
  FieldArrayRenderProps & SoundingTableRowProps
> = ({ form, name, scheme, onCalcClick, disabled, readonly }) => {
  const { setFieldValue } = form
  const values = getIn(form.values, name)
  const displayName = DISPLAY_NAME_MAPPER[name]

  return (
    <>
      <SumRow {...scheme}>
        <td>{displayName}</td>
        <td>
          {values
            ? formatValue(
                values.reduce((acc, cv) => acc + cv.quantity, 0),
                3,
              )
            : 0}
        </td>
        <td>
          {values
            ? formatValue(
                values.reduce(
                  (acc, cv) =>
                    acc +
                    parseFloat(
                      (typeof cv.rob === 'string'
                        ? commaAgnostic(cv.rob)
                        : cv.rob) || 0,
                    ),
                  0,
                ),
                3,
              )
            : 0}
        </td>
        <td>
          {values
            ? formatValue(
                values.reduce((acc, cv) => acc + cv.adjustment, 0),
                3,
              )
            : 0}
        </td>
      </SumRow>

      {values.length > 0 &&
        values.map((entry, index) => (
          <React.Fragment key={`name-${index}`}>
            <tr>
              <td title={entry.displayName}>{entry.displayName}</td>
              <td>{formatValue(entry.quantity, 3)}</td>
              <td
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                {readonly ? (
                  <div style={{ textAlign: 'right', width: '100%' }}>
                    {formatValue(entry.rob, 3)}
                  </div>
                ) : (
                  <div style={{ display: 'inherit' }}>
                    <div className='input-cell'>
                      <Field
                        component={FormInputWithUnit}
                        name={`${name}[${index}].rob`}
                        placeholder='0.00'
                        unit='MT'
                        type='number'
                        disabled={disabled}
                        unitClassName='input-unit'
                        onChange={(value) => {
                          const adjustment = roundedValue(
                            value -
                              (parseFloat(commaAgnostic(entry.quantity)) || 0),
                          )
                          setFieldValue(
                            `${name}[${index}].adjustment`,
                            adjustment,
                          )
                        }}
                      />
                    </div>
                    <span
                      className='calculator'
                      onClick={() => onCalcClick(`${name}[${index}]`)}
                    >
                      <Icon icon='fal fa-calculator' />
                    </span>
                  </div>
                )}
              </td>
              <td>{formatValue(entry.adjustment, 3)}</td>
            </tr>
            <ErrorMessage name={`${name}[${index}].rob`} />
            <SoundingWarning name={`${name}[${index}]`} />
          </React.Fragment>
        ))}
    </>
  )
}

const ErrorMessage = ({ name }) => (
  <Field
    name={name}
    render={({ form }) => {
      const error = getIn(form.errors, name)
      const touch = getIn(form.touched, name)
      return touch && error ? (
        <tr>
          <td colSpan={4}>
            <InfoBox hideIcons={true} theme={errorTheme}>
              {error}
            </InfoBox>
          </td>
        </tr>
      ) : null
    }}
  />
)
