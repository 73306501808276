import styled from 'styled-components'

import { PadContent } from '../../../layout/styles'

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;

  > h4 {
    margin-right: 4px;
  }

  > mc-tooltip mc-button mc-icon::part(icon) {
    fill: #9aa7b2;
  }
`

export const OuterWrapper = styled(PadContent)`
  display: flex;
  justify-content: space-between;

  > div {
    width: 50%;
    padding: 32px;
  }
`

export const InnerWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-top: 16px;

  > div {
    align-self: center;
    list-style: none;
  }
`

export const RankItem = styled.div<{ emphasize: boolean }>`
  padding: 4px 16px;
  border-radius: 16px;
  white-space: nowrap;

  background-color: ${(props) =>
    props.emphasize
      ? 'var(--mds_brand_appearance_neutral_weakest_background-color)'
      : 'transparent'};

  > span:first-of-type {
    display: inline-block;
    width: 32px;
    font-feature-settings: 'tnum' 1;
    font-weight: bold;
  }
`
