import moment from 'moment'
import { EChartOption } from 'echarts'
import { grey } from '../theme'

export const ACTIVATE_ZOOM_ACTION = {
  type: 'takeGlobalCursor',
  key: 'dataZoomSelect',
  dataZoomSelectActive: 1,
}

export const ACTIVATE_BRUSH_ACTION = {
  type: 'takeGlobalCursor',
  key: 'brush',
  brushOption: {
    brushType: 'lineX',
    brushMode: 'single',
  },
}

export const CLEAR_BRUSH_ACTION = {
  type: 'brush',
  areas: [],
}

export const TOOLBOX_TIMELINE = {
  top: 0,
  left: -100,
  feature: {
    dataZoom: {
      itemSize: 1,
      showSymbol: false,
      showTitle: false,
      yAxisIndex: false,
    },
  },
}

export const TOOLBOX_SCATTER = {
  top: 0,
  left: -100,
  feature: {
    dataZoom: {
      itemSize: 1,
      showSymbol: false,
      showTitle: false,
    },
  },
}

export const AXIS_POINTER_LABEL = {
  backgroundColor: 'rgba(5,5,5,0.5)',
  fontFamily: 'Maersk Text',
}

export const AXIS_LINE = {
  onZero: false,
  lineStyle: {
    color: grey[200],
  },
}

export const AXIS_SPLIT_LINE = {
  lineStyle: {
    color: grey[200],
  },
}

export const AXIS_TICK = {
  lineStyle: {
    color: grey[200],
  },
}

export const AXIS_LABEL = {
  show: true,
  color: '#000000',
  fontFamily: 'Maersk Text',
}

export const GRID = {
  containLabel: true,
  top: 16,
  left: 16,
  bottom: 16,
  right: 16,
  borderColor: grey[200],
}

export const X_AXIS_TIMELINE: EChartOption.XAxis = {
  id: 'timestamps',
  type: 'time',
  axisLabel: {
    formatter: (value: any) =>
      `{time|${moment.utc(value).format('HH mm')}}\n{date|${moment
        .utc(value)
        .format('DD MMM')}}`,
    rich: {
      time: {
        color: 'black',
        fontFamily: 'Maersk Text',
        lineHeight: 14,
        fontSize: 12,
      },
      date: {
        color: grey[700],
        fontFamily: 'Maersk Text',
        fontSize: 10,
      },
    },
  },
  axisTick: {
    ...AXIS_TICK,
  },
  axisLine: {
    ...AXIS_LINE,
  },
  splitLine: {
    show: false,
  },
}

export const DATA_ZOOM_TIMELINE: EChartOption.DataZoom = {
  id: 'timestamp',
  type: 'select',
  xAxisIndex: 0,
  minValueSpan: 60 * 60 * 1000,
  filterMode: 'none',
}
