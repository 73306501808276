import moment from 'moment/moment'
import type { MonthYearPickerValue } from '@maersk-global/mds-components-core/mc-month-year-picker/types'
import type { IDateRangeValue } from '@maersk-global/mds-components-core/mc-date-range/types'

export type NumericMonthYearPickerValue = Required<
  Pick<MonthYearPickerValue, 'month' | 'year'>
> & {
  month: number
  year: number
}

export const getInitialMonthYearFrom = (
  dateRange: IDateRangeValue,
): NumericMonthYearPickerValue => {
  const date = moment.utc(dateRange.from)

  return {
    month: date.month(),
    year: date.year(),
  }
}

export const getInitialMonthYearTo = (): NumericMonthYearPickerValue => {
  const date = moment.utc()

  return {
    month: date.month(),
    year: date.year(),
  }
}
