import { useContext, useEffect, useState } from 'react'

import { Performance } from '../../../../api-models'
import { Loading, Modal } from '../../../../commons'
import { VesselPageContext } from '../../../../contexts'
import * as PerformanceApi from '../../../../services/performance'
import { BunkeringForm } from './BunkeringForm'
import BatchDetailsModal from './BatchDetailsModal'
import { displayErrorModal } from '../../../../utils'

type Props = {
  entryId?: string
  closeHandler: (refresh?: boolean) => void
}

export const BunkeringModal = ({ closeHandler, entryId }: Props) => {
  const imoNo = useContext(VesselPageContext).imoNo!
  const [batch, setBatch] =
    useState<Performance.FuelOilStock.BunkeredBatchResponse>()

  useEffect(() => {
    if (entryId) {
      PerformanceApi.getBunkeredBatch(imoNo, entryId)
        .then((data: Performance.FuelOilStock.BunkeredBatchResponse) => {
          setBatch(data)
        })
        .catch((e) => displayErrorModal(e))
    }
  }, [entryId, imoNo])

  const isLoading = !!entryId && !batch
  const showBatchDetails =
    !isLoading &&
    !!entryId &&
    !!batch &&
    batch.labReport &&
    batch.labReportReadonly

  const showBunkeringForm =
    !isLoading && (!entryId || (batch && !showBatchDetails))

  if (isLoading) {
    return (
      <Modal
        title={!!batch ? 'Edit bunkered batch' : 'Add new bunkered batch'}
        visible
        closeHandler={closeHandler}
      >
        <Loading />
      </Modal>
    )
  }

  return (
    <>
      {showBatchDetails && (
        <BatchDetailsModal
          visible={true}
          closeHandler={closeHandler}
          bunkeredBatch={batch}
        />
      )}
      {showBunkeringForm && (
        <BunkeringForm batch={batch} closeHandler={closeHandler} />
      )}
    </>
  )
}
