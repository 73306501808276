import * as React from 'react'
import * as ECharts from 'echarts'
import { Performance } from '../../../api-models'
import { Hydra } from '../../../api-models/hydra'
import moment from 'moment'
import {
  Filters,
  getDateRange,
  filtersChanged,
  DefaultFilters,
} from '../../../features/filter'
import { TorqueOffsetChart } from './TorqueOffsetChart'
import {
  lightBlueColorScheme,
  mariTimeColorScheme,
  green,
  red,
} from '../../../theme'
import {
  getDateRangeFilterDifference,
  formatValue,
  getDefaultFilters,
} from '../../../utils'
import {
  ChartStencil,
  ChartContainer,
  NotFound,
  FancyLabel,
  ValueLabel,
  VesselStatusBlock,
  Block,
} from '../../../commons'
import { normal } from '../theme'
import { TorqueOffsetGraphModel } from '../types'
import styled from 'styled-components'
import { UNITS } from '../../../utils/constants'
import { displayErrorModal } from '../../../utils/modal-utils'
import {
  getTorqueOffsetOverTime,
  getTorqueOffsetOverTimeStatus,
} from '../../../services/hydra'

const Wrapper = styled.div`
  svg.red {
    color: ${red[500]};
    height: 8px;
    width: 8px;
    margin-left: 4px;
    display: inline-flex;
    align-self: center;
  }

  svg.green {
    color: ${green[500]};
    height: 8px;
    width: 8px;
    margin-left: 4px;
    display: inline-flex;
    align-self: center;
  }
`

interface TorqueOffsetChartContainerProps {
  filters?: Filters
  isMainEngine2: boolean
  vesselStatus?: Performance.Status.Status | null
  vesselConfiguration: Performance.VesselConfiguration.Configuration
}

interface TorqueOffsetType {
  staticOffset: number | null
  dynamicOffset: number | null
  threshold: number | null
  lowerThreshold: number | null
}

interface TorqueOffsetChartContainerState {
  data?: {
    queryPeriod: Hydra.Period
    mainEngine1Data: TorqueOffsetGraphModel
    mainEngine2Data: TorqueOffsetGraphModel
  }
  statusData?: {
    mainEngine1StatusData: TorqueOffsetType
    mainEngine2StatusData: TorqueOffsetType
  }
  defaultFilter: DefaultFilters
}

export default class TorqueOffsetChartContainer extends React.Component<
  TorqueOffsetChartContainerProps,
  TorqueOffsetChartContainerState
> {
  constructor(props: TorqueOffsetChartContainerProps) {
    super(props)
    ECharts.connect('torque-offset')
    this.state = {
      data: undefined,
      statusData: undefined,
      defaultFilter: getDefaultFilters(14, 'd'),
    }
  }

  loadTorqueOffsetData() {
    const { filters, vesselConfiguration } = this.props
    const { defaultFilter } = this.state
    const queryPeriod = getDateRange(defaultFilter.dateRange, filters)
    const imoNo = vesselConfiguration.imoNo
    if (getDateRangeFilterDifference(queryPeriod, 'd') >= 14) {
      getTorqueOffsetOverTime(imoNo.toString(), queryPeriod)
        .then((json: Hydra.Propulsion.TorqueOffset) => {
          const {
            timestamp,
            staticOffsetMe1,
            staticOffsetMe2,
            dynamicOffsetMe1,
            dynamicOffsetMe2,
            lowerThreshold1,
            lowerThreshold2,
            threshold1,
            threshold2,
          } = json.calculatedResults
          this.setState({
            data: {
              queryPeriod: json.queryPeriod,
              mainEngine1Data: {
                timestamp,
                staticOffsetSeries: staticOffsetMe1,
                dynamicOffsetSeries: dynamicOffsetMe1,
                lowerThreshold: lowerThreshold1,
                upperThreshold: threshold1,
              },
              mainEngine2Data: {
                timestamp,
                staticOffsetSeries: staticOffsetMe2,
                dynamicOffsetSeries: dynamicOffsetMe2,
                lowerThreshold: lowerThreshold2,
                upperThreshold: threshold2,
              },
            },
          })
        })
        .catch((e) => {
          displayErrorModal(e)
        })
    }
  }

  loadTorqueOffsetStatus() {
    const { vesselConfiguration, vesselStatus, filters } = this.props
    const { defaultFilter } = this.state
    const queryPeriod = getDateRange(defaultFilter.dateRange, filters)
    if (
      vesselStatus &&
      vesselStatus.hasData &&
      getDateRangeFilterDifference(queryPeriod, 'd') >= 14
    ) {
      getTorqueOffsetOverTimeStatus(
        vesselConfiguration.imoNo.toString(),
        moment.utc(vesselStatus.timestamp).toISOString(),
      )
        .then((json: Hydra.Propulsion.TorqueOffset) => {
          const {
            staticOffsetMe1,
            staticOffsetMe2,
            dynamicOffsetMe1,
            dynamicOffsetMe2,
            lowerThreshold1,
            lowerThreshold2,
            threshold1,
            threshold2,
          } = json.calculatedResults
          this.setState({
            statusData: {
              mainEngine1StatusData: {
                dynamicOffset: dynamicOffsetMe1[0],
                staticOffset: staticOffsetMe1[0],
                lowerThreshold: lowerThreshold1,
                threshold: threshold1,
              },
              mainEngine2StatusData: {
                dynamicOffset: dynamicOffsetMe2[0],
                staticOffset: staticOffsetMe2[0],
                lowerThreshold: lowerThreshold2,
                threshold: threshold2,
              },
            },
          })
        })
        .catch((e) => displayErrorModal(e))
    }
  }

  componentDidUpdate(prevProps: TorqueOffsetChartContainerProps) {
    const { filters, vesselConfiguration, vesselStatus } = this.props
    const imoNo = vesselConfiguration.imoNo
    if (
      filtersChanged(prevProps.filters, filters) ||
      prevProps.vesselStatus?.timestamp !== vesselStatus?.timestamp ||
      prevProps.vesselConfiguration.imoNo !== imoNo
    ) {
      this.setState({
        data: undefined,
        statusData: undefined,
        defaultFilter: getDefaultFilters(14, 'd'),
      })
      this.loadTorqueOffsetData()
      this.loadTorqueOffsetStatus()
    }
  }

  componentDidMount() {
    this.loadTorqueOffsetData()
    this.loadTorqueOffsetStatus()
  }

  getStopLight(offset: any, upperThreshold: number, lowerThreshold: number) {
    if (offset && upperThreshold && lowerThreshold)
      return (
        <span
          className={`fas fa-circle ${
            offset > upperThreshold || offset < lowerThreshold ? 'red' : 'green'
          }`}
        />
      )
    return null
  }

  render() {
    const { vesselStatus, filters, isMainEngine2 } = this.props
    const { data, statusData, defaultFilter } = this.state as any
    const graphMinHeight = 263
    const yLabel = { name: UNITS.KILO_NEWTON_METER, colorScheme: normal }
    if (
      getDateRangeFilterDifference(
        getDateRange(defaultFilter.dateRange, filters),
        'd',
      ) < 14
    ) {
      return (
        <NotFound text='Please select a period of at least 14 days to view torque offset.' />
      )
    }
    if (!data) {
      return (
        <ChartContainer y1Label={yLabel} minHeight={graphMinHeight}>
          <ChartStencil chartType='area' minHeight={graphMinHeight} />
        </ChartContainer>
      )
    }
    if (
      isMainEngine2
        ? data.mainEngine2Data.timestamp.length === 0
        : data.mainEngine1Data.timestamp.length === 0
    ) {
      return <NotFound text='No data for the selected period found.' />
    }
    return (
      <Wrapper>
        <ChartContainer
          y1Label={yLabel}
          minHeight={graphMinHeight}
          sideContentWidth={130}
        >
          <TorqueOffsetChart
            queryPeriod={data.queryPeriod}
            graphData={
              isMainEngine2 ? data.mainEngine2Data : data.mainEngine1Data
            }
          />
          {!!vesselStatus && !isMainEngine2 && (
            <VesselStatusBlock timestamp={vesselStatus.timestamp}>
              <Block>
                <FancyLabel colorScheme={mariTimeColorScheme}>
                  Dynamic
                </FancyLabel>
                <ValueLabel unit={UNITS.KILO_NEWTON_METER}>
                  {formatValue(
                    statusData
                      ? statusData.mainEngine1StatusData.dynamicOffset
                      : null,
                    0,
                  )}
                </ValueLabel>
                {!!statusData &&
                  this.getStopLight(
                    statusData.mainEngine1StatusData.dynamicOffset,
                    statusData.mainEngine1StatusData.threshold,
                    statusData.mainEngine1StatusData.lowerThreshold,
                  )}
              </Block>
              <Block>
                <FancyLabel colorScheme={lightBlueColorScheme}>
                  Static
                </FancyLabel>
                <ValueLabel unit={UNITS.KILO_NEWTON_METER}>
                  {formatValue(
                    statusData
                      ? statusData.mainEngine1StatusData.staticOffset
                      : null,
                    0,
                  )}
                </ValueLabel>
                {!!statusData &&
                  this.getStopLight(
                    statusData.mainEngine1StatusData.staticOffset,
                    statusData.mainEngine1StatusData.threshold,
                    statusData.mainEngine1StatusData.lowerThreshold,
                  )}
              </Block>
            </VesselStatusBlock>
          )}
          {!!vesselStatus && isMainEngine2 && (
            <VesselStatusBlock timestamp={vesselStatus.timestamp}>
              <Block>
                <FancyLabel colorScheme={mariTimeColorScheme}>
                  Dynamic
                </FancyLabel>
                <ValueLabel unit={UNITS.KILO_NEWTON_METER}>
                  {formatValue(
                    statusData
                      ? statusData.mainEngine2StatusData.dynamicOffset
                      : null,
                    0,
                  )}
                </ValueLabel>
                {!!statusData &&
                  this.getStopLight(
                    statusData.mainEngine2StatusData.dynamicOffset,
                    statusData.mainEngine2StatusData.threshold,
                    statusData.mainEngine2StatusData.lowerThreshold,
                  )}
              </Block>
              <Block>
                <FancyLabel colorScheme={lightBlueColorScheme}>
                  Static
                </FancyLabel>
                <ValueLabel unit={UNITS.KILO_NEWTON_METER}>
                  {formatValue(
                    statusData
                      ? statusData.mainEngine2StatusData.staticOffset
                      : null,
                    0,
                  )}
                </ValueLabel>
                {!!statusData &&
                  this.getStopLight(
                    statusData.mainEngine2StatusData.staticOffset,
                    statusData.mainEngine2StatusData.threshold,
                    statusData.mainEngine2StatusData.lowerThreshold,
                  )}
              </Block>
            </VesselStatusBlock>
          )}
        </ChartContainer>
      </Wrapper>
    )
  }
}
