import React, { useState, useCallback } from 'react'
import { withRouter, RouteComponentProps } from 'react-router'
import { routerParams } from '../routes'
import { isShoreContext, TEN_MINUTES_IN_MS } from '../utils'
import { Performance } from '../api-models'
import { AppContextConsumer, AppContextInterface } from './app-context'
import { Loading } from '../commons'
import { Management } from '../api-models/management'
import { getVesselStatus } from '../services/performance'
import { useVesselConfiguration } from '../hooks'

export interface IVesselPageContext {
  configuration?: Performance.VesselConfiguration.Configuration
  imoNo?: string
  installation?: Management.Vessel.SoftwareComponentsResponse // only used in shore mode
  vesselStatus: Performance.Status.Status | null
  updateVesselStatus?: () => void
}

export const VesselPageContext = React.createContext<IVesselPageContext>({
  vesselStatus: null,
})

export interface FleetContextProviderProps
  extends RouteComponentProps<routerParams> {
  installations?: Management.Vessel.SoftwareComponentsResponse[]
  children?: React.ReactNode
}

const FleetContextProvider = ({
  match,
  location,
  installations,
  children,
}: FleetContextProviderProps) => {
  const imoNo = match.params.vesselId
  const configuration = useVesselConfiguration(imoNo)
  const [installation, setInstallation] =
    useState<Management.Vessel.SoftwareComponentsResponse>()
  const [vesselStatus, setVesselStatus] =
    useState<Performance.Status.Status | null>(null)

  const updateVesselStatus = useCallback(() => {
    getVesselStatus(imoNo)
      .then(setVesselStatus)
      .catch((e) => {
        console.error(e)
      })
  }, [imoNo])

  React.useEffect(() => {
    updateVesselStatus()
    const id = setInterval(updateVesselStatus, TEN_MINUTES_IN_MS)
    return () => clearInterval(id)
  }, [imoNo, location.pathname, updateVesselStatus]) // watch `location.pathname` to fetch new vessel status data when switching page

  React.useEffect(() => {
    if (installations) {
      const installation = installations.find(
        (installation: Management.Vessel.SoftwareComponentsResponse) =>
          installation.imoNo.toString() === imoNo,
      )
      setInstallation(installation)
    }
  }, [imoNo, installations])

  if (configuration) {
    return (
      <VesselPageContext.Provider
        value={{
          configuration,
          installation,
          imoNo,
          vesselStatus,
          updateVesselStatus,
        }}
      >
        {children}
      </VesselPageContext.Provider>
    )
  }
  return <Loading padding='128px 0' />
}

export interface VesselContextProviderProps
  extends RouteComponentProps<routerParams> {
  children?: React.ReactNode
}

const VesselContextProvider = ({
  location,
  match,
  children,
}: VesselContextProviderProps) => {
  const imoNo = match.params.vesselId
  const configuration = useVesselConfiguration(imoNo)
  const [vesselStatus, setVesselStatus] =
    useState<Performance.Status.Status | null>(null)

  const updateVesselStatus = useCallback(() => {
    getVesselStatus(imoNo)
      .then(setVesselStatus)
      .catch((e) => {
        console.error(e)
      })
  }, [imoNo])

  React.useEffect(() => {
    updateVesselStatus()
    const id = setInterval(updateVesselStatus, TEN_MINUTES_IN_MS)
    return () => clearInterval(id)
  }, [imoNo, location.pathname, updateVesselStatus]) // watch `location.pathname` to fetch new vessel status data when switching page

  if (configuration) {
    return (
      <VesselPageContext.Provider
        value={{
          configuration,
          imoNo,
          vesselStatus,
          updateVesselStatus,
        }}
      >
        {children}
      </VesselPageContext.Provider>
    )
  }
  return <Loading />
}

export default withRouter((props: RouteComponentProps<routerParams>) => (
  <AppContextConsumer>
    {(appContext: AppContextInterface) =>
      isShoreContext() ? (
        <FleetContextProvider
          {...props}
          installations={appContext.installations}
        />
      ) : (
        <VesselContextProvider {...props} />
      )
    }
  </AppContextConsumer>
))
