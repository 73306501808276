import { useMemo } from 'react'
import styled from 'styled-components'
import { Form, Formik } from 'formik'
import * as yup from 'yup'
import moment from 'moment'
import { McButton } from '@maersk-global/mds-react-wrapper'

import { ModalControls } from '../../../../commons'
import { type FormBatch, type InitialRobBatch } from '../../models'
import { generateDisplayName, mapBatchToBunkeringFormValues } from '../../utils'
import { useFuelGrades } from '../../../../queries/MasterDataApi/MasterDataApi'
import {
  type BunkeringFormValues,
  defaultInitialValues,
  validationSchema as bunkeringSchema,
} from '../bunkering/BunkeringForm'
import { BunkeringFormFields } from '../bunkering/BunkeringFormFields'
import { isShoreContext } from '../../../../utils'

const Wrapper = styled.div`
  max-height: 80vh;
  overflow-y: auto;
`

type Props = {
  batch?: FormBatch
  batches: InitialRobBatch[]
  closeHandler: () => void
  submitBunkering: (values: BunkeringFormValues, batchId?: string) => void
  deleteBunkering: (batchId: string) => void
}

export const BunkeringFormInitialRob = ({
  closeHandler,
  batch,
  batches,
  submitBunkering,
  deleteBunkering,
}: Props) => {
  const { data: fuelGrades, isSuccess: isFuelGradesSuccess } = useFuelGrades()
  const submitText = !!batch ? 'Save changes' : 'Add new bunkering'
  const disabled = isShoreContext()
  const initialValues = batch
    ? mapBatchToBunkeringFormValues(batch)
    : defaultInitialValues
  const validationSchema = useMemo(() => {
    const uniqueTimeStamps = new Set(
      batches
        .filter(({ id }) => id !== batch?.id)
        .map(({ timestamp }) => moment.utc(timestamp).toISOString()),
    )
    return bunkeringSchema(fuelGrades ?? []).shape({
      timestamp: yup
        .date()
        .required('Time of bunkering is a required field')
        .test('isUnique', 'Time of bunkering must be unique', (value) => {
          const timestampString = moment.utc(value).toISOString()
          return !uniqueTimeStamps.has(timestampString)
        }),
    })
  }, [batches, batch])

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => {
        if (!values.displayName.trim()) {
          values.displayName = generateDisplayName(
            values.fuelType,
            values.isDistillate,
            values.quantityPerChiefEngineer,
            values.portCode,
            values.timestamp?.toISOString(),
          )
        }
        submitBunkering(values, batch ? batch.id : undefined)
      }}
      validationSchema={validationSchema}
    >
      {({ isSubmitting, isValid }) => (
        <Form>
          <Wrapper>
            <BunkeringFormFields
              showErrorsForUntouchedFields={!!batch}
              disabled={disabled}
              isBaseFormReadonly={false}
              isLabReportReadonly={false}
              disableOpenWindowValidation={true}
              fuelGrades={fuelGrades}
              isFuelGradesSuccess={isFuelGradesSuccess}
            />
          </Wrapper>
          <ModalControls>
            {!!batch && (
              <McButton
                label='Delete'
                appearance='error'
                className='left'
                disabled={isSubmitting || disabled}
                click={() => deleteBunkering(batch.id)}
                type='button'
              />
            )}
            <McButton
              label='Cancel'
              appearance='neutral'
              click={closeHandler}
              type='button'
            />
            <McButton
              label={submitText}
              appearance='primary'
              disabled={isSubmitting || disabled || !isValid}
              type='submit'
            />
          </ModalControls>
        </Form>
      )}
    </Formik>
  )
}
