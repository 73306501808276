import { useContext, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { sortBy } from 'lodash'
import moment from 'moment'
import { McNotification } from '@maersk-global/mds-react-wrapper'

import HDCContext from '../../context/context'
import { type Event } from '../../../../api-models/hdc/events'
import { UNITS } from '../../../../utils/constants'
import EditEvent from '../../reports/events/edit'
import { EventTypeName, type HDCReportFormValues } from '../../types'
import { getCalculatedRDPS, shouldCalculateRDPS } from '../../utils'
import OverlayLoader from '../overlay-loader'
import ReadonlyField from '../readonly-field'
import { Content, Duration, Header, MetadataRow, Wrapper } from './styles'
import ActionButtons from './ActionButtons/ActionButtons'
import { routerParams } from '../../../../routes'

type Props = {
  data: Event
  isLoading?: boolean
  onDelete?: () => void
  report: HDCReportFormValues
}

const EventRow = ({ data, isLoading = false, onDelete, report }: Props) => {
  const { vesselId: imoNo } = useParams<routerParams>()
  const { comment, endTimestamp, id, metadata, startTimestamp, typeName } = data
  const { state: HDCState } = useContext(HDCContext)
  const { reports } = HDCState

  const [isEditEventActive, setIsEditEventActive] = useState(false)

  const events = useMemo(
    () => (report.bridge.events || []).concat(report.engineRoom.events || []),
    [report.bridge.events, report.engineRoom.events],
  )

  const getDuration = (startTimestamp, endTimestamp, typeName) => {
    let duration =
      moment(startTimestamp).utc().format('DD MMM YYYY HH:mm') + ' UTC'
    if (
      ![
        EventTypeName.ETA_CHANGE,
        EventTypeName.ROUTE_CHANGE,
        EventTypeName.DISCHARGE_SLUDGE,
      ].includes(typeName)
    ) {
      duration += ` - ${moment(endTimestamp)
        .utc()
        .format('DD MMM YYYY HH:mm')} UTC`
    }
    return duration
  }

  const getPreviousETA = useMemo((): string => {
    const etaEvents = events?.filter(
      (event) => event.typeName === EventTypeName.ETA_CHANGE,
    )
    const sortedETAEvents = sortBy(etaEvents, 'startTimestamp')
    const currentEventIndex = sortedETAEvents.findIndex(
      (event) => event.id === id,
    )

    let previousETA = moment(
      sortedETAEvents[currentEventIndex - 1]?.metadata?.eta ||
        report?.bridge.etaOriginal.value,
    )
    return previousETA!.utc().format('DD MMM YYYY HH:mm') + ' UTC'
  }, [events, id, report])

  const getPreviousDestination = useMemo((): string => {
    const routeEvents = events?.filter(
      (event) => event.typeName === EventTypeName.ROUTE_CHANGE,
    )
    const sortedRouteEvents = sortBy(routeEvents, 'startTimestamp')
    const currentEventIndex = sortedRouteEvents.findIndex(
      (event) => event.id === id,
    )

    let previousDestination = sortedRouteEvents
      .slice(0, currentEventIndex)
      .reverse()
      .map(
        (event) =>
          event.metadata?.arrivalTerminal?.code ||
          event.metadata?.alternativeArrivalTerminal?.code,
      )
      .find((code) => !!code)

    if (!previousDestination) {
      previousDestination =
        report?.bridge.arrivalTerminalOriginal?.code.value ||
        report?.bridge.alternativeArrivalTerminalOriginal?.code
    }
    return previousDestination!
  }, [events, id, report])

  const getPreviousRemainingDistance = useMemo((): number | '-' => {
    const routeEvents = events?.filter(
      (event) => event.typeName === EventTypeName.ROUTE_CHANGE,
    )
    const sortedRouteEvents = sortBy(routeEvents, 'startTimestamp')
    const currentEventIndex = sortedRouteEvents.findIndex(
      (event) => event.id === id,
    )

    let previousRemainingDistance: any = sortedRouteEvents
      .slice(0, currentEventIndex)
      .reverse()
      .map((event) => event.metadata?.remainingDistanceToPilotStation)
      .find((distance) => !!distance)

    if (!previousRemainingDistance) {
      previousRemainingDistance = shouldCalculateRDPS(
        report,
        reports!,
        false,
        false,
      )
        ? getCalculatedRDPS(report, reports!)
        : report?.bridge.remainingDistanceToPilotStationOriginal.value
    }
    return previousRemainingDistance!
  }, [events, id, report, reports])

  const getTimeLossLabel = (timeLossInMinutes: number) => {
    const days = Math.floor(timeLossInMinutes / (60 * 24))
    const remainder = timeLossInMinutes % (60 * 24)
    const hours = Math.floor(remainder / 60)
    const minutes = remainder % 60
    const dayLabel = `${
      days === 0 ? '' : days === 1 ? `${days} day` : `${days} days`
    }`
    const hourLabel = `${
      hours === 0 ? '' : hours === 1 ? `${hours} hour` : `${hours} hours`
    }`
    const minuteLabel = `${
      minutes === 0
        ? ''
        : minutes === 1
        ? `${minutes} minute`
        : `${minutes} minutes`
    }`
    return `${dayLabel} ${hourLabel} ${minuteLabel}`
  }

  return (
    <Wrapper>
      <Content>
        {metadata?.mfeReportId && (
          <McNotification
            heading='Event automatically created from Malfunctioning Equipment report'
            appearance='warning'
            icon='file-copy'
            width='fit-content'
            actions={[
              {
                url: `/MaerskStarConnect/vessel/${imoNo}/mfe/report/${metadata.mfeReportId}`,
                label: `View report #${metadata.mfeReportNo}`,
                target: '_blank',
                rel: 'noreferrer',
              },
            ]}
          />
        )}
        <Header>{typeName}</Header>
        <Duration>
          {getDuration(startTimestamp, endTimestamp, typeName)}
        </Duration>
        {metadata && (
          <MetadataRow>
            {metadata.quantity && (
              <ReadonlyField
                label='Discharge quantity'
                value={metadata.quantity}
                addon={UNITS.VOLUME}
                name={`${id}-discharge-quantity`}
              />
            )}
            {metadata.eta && (
              <ReadonlyField
                label='New ETA to PS'
                value={`${moment(metadata.eta)
                  .utc()
                  .format('DD MMM YYYY HH:mm')} UTC`}
                name={`${id}-new-eta`}
              />
            )}
            {metadata.eta && (
              <ReadonlyField
                label='Previous ETA to PS'
                value={getPreviousETA}
                name={`${id}-previous-eta`}
              />
            )}
            {metadata.arrivalTerminal && (
              <ReadonlyField
                label='New arrival terminal code'
                value={metadata.arrivalTerminal.code}
                name={`${id}-new-destination`}
              />
            )}
            {metadata.alternativeArrivalTerminal && (
              <ReadonlyField
                label='New arrival terminal code'
                value={metadata.alternativeArrivalTerminal.code}
                name={`${id}-new-destination`}
              />
            )}
            {(metadata.arrivalTerminal ||
              metadata.alternativeArrivalTerminal) && (
              <ReadonlyField
                label='Previous arrival terminal code'
                value={getPreviousDestination}
                name={`${id}-previous-destination`}
              />
            )}
            {metadata.remainingDistanceToPilotStation && (
              <ReadonlyField
                label='New remaining distance to PS at closing time of this report'
                value={metadata.remainingDistanceToPilotStation}
                name={`${id}-new-remaining-distance`}
              />
            )}
            {metadata.remainingDistanceToPilotStation && (
              <ReadonlyField
                label='Previous remaining distance to PS at closing time of this report'
                value={getPreviousRemainingDistance}
                name={`${id}-previous-remaining-distance`}
              />
            )}
            {metadata.timeLoss && (
              <ReadonlyField
                value={getTimeLossLabel(metadata.timeLoss)}
                label='Time loss'
                name={`${id}-off-service-timeLoss`}
              />
            )}
            {metadata.port?.code && (
              <ReadonlyField
                label='Port code'
                name={`${id}-port-code`}
                value={metadata.port.code}
              />
            )}
          </MetadataRow>
        )}
      </Content>
      <ActionButtons
        comment={comment}
        onDelete={onDelete}
        onEditClick={() => setIsEditEventActive(true)}
      />
      {isLoading && <OverlayLoader spinnerSize={2.8} padding='0px' />}
      <EditEvent
        data={data}
        handleClose={() => setIsEditEventActive(false)}
        isVisible={isEditEventActive}
      />
    </Wrapper>
  )
}

export default EventRow
