import React, { FC, useContext, useEffect, useState } from 'react'
import { Formik, Form, Field, FieldArray } from 'formik'
import { maritimeBlue, red } from '../../../../theme'
import moment, { Moment } from 'moment'
import styled from 'styled-components'
import { McButton } from '@maersk-global/mds-react-wrapper'

import {
  Loading,
  Icon,
  ModalControls as FormControls,
  FormContainer,
} from '../../../../commons'
import {
  FormTextArea,
  FormDateTimeInput,
  FormInputWithUnit,
} from '../../../../commons'
import * as PerformanceApi from '../../../../services/performance'
import { Performance } from '../../../../api-models'
import { VesselPageContext } from '../../../../contexts'
import {
  displayErrorModal,
  formatValue,
  isShoreContext,
} from '../../../../utils'
import { Option, useBatchOptions } from '../../hooks'
import { BatchSelect } from '../../../fdl-4/components/batch-selection/BatchSelect'
import { RobUpdater } from './hook-components/RobUpdater'
import { CurrentBatchRobUpdater } from './hook-components/CurrentBatchRobUpdater'
import { VolumeToMassComponent } from './VolumeToMassComponent'
import { OpenWindowUpdater } from './hook-components/OpenWindowUpdater'
import { StockRobNotifications } from './../../components/StockRobNotifications'
import { validationSchema } from './validation-schemas'
import { ErrorMessage } from '../ErrorMessage'
import { MixWarning } from './MixWarning'

const Wrapper = styled.div`
  display: block;
  max-height: 80vh;
  overflow-y: auto;
`

const ErrorContainer = styled.div`
  width: 100%;
`

const BatchQuantityGroup = styled.div`
  align-items: center;
  margin-bottom: 16px;
`

const BatchQuantityField = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
`

const TimestampFieldLabel = styled.span`
  width: 128px;
  font-size: 14px;
  text-align: right;
  margin-right: 10px;
`

const BatchesFieldLabel = styled.span`
  width: 80px;
  font-size: 14px;
  text-align: right;
  margin-right: 10px;
`

const AddBatchContainer = styled.div`
  display: flex;
  padding-left: 90px;
  margin-bottom: 16px;
`

const RobContainer = styled.div`
  text-align: right;
  font-size: 14px;
  margin: 0 10px;
`
const QuantityFieldContainer = styled.div`
  width: 290px;
`

const QuantityInputContainer = styled.div`
  display: flex;
`

const CalculatorContainer = styled.div`
  padding-left: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  > i {
    font-size: 16px;
    line-height: 16px;
  }

  svg {
    color: ${maritimeBlue[500]};
    height: 24px;
    width: 24px !important;
  }
`

const InfoStyle = styled.div`
  box-sizing: border-box;
  margin: 16px 16px 0px;
  max-width: 510px;
`

interface MixedBatchFormProps {
  closeHandler: (refreshAdjustments?: boolean) => void
}
export interface MixedBatch {
  id: string
  quantity?: number
  currentRob?: number
  fuel?: Performance.FuelOilStock.Fuel
}
export interface FormValues {
  timestamp?: Moment
  mixedBatches: MixedBatch[]
  notes: string
}

const defaultInitialValues: FormValues = {
  timestamp: undefined,
  mixedBatches: [
    { id: '', quantity: undefined, currentRob: undefined },
    { id: '', quantity: undefined, currentRob: undefined },
  ],
  notes: '',
}

const MixedBatchForm: FC<MixedBatchFormProps> = ({ closeHandler }) => {
  const imoNo = useContext(VesselPageContext).imoNo!
  const [openWindow, setOpenWindow] =
    useState<Performance.FuelOilStock.OpenWindow>()
  const batchOptions = useBatchOptions(imoNo, true)
  const [rob, setRob] = useState<Performance.FuelOilStock.RobResponse>()
  const [batchOptionsAtTimeSelected, setBatchOptionsAtTimeSelected] =
    useState<Option[]>()
  const [shouldShowCalculator, setShouldShowCalculator] = useState(false)
  const [mixedBatchName, setMixedBatchName] = useState<string>()

  useEffect(() => {
    /*
     * If Stock is not in an operational state, rob returns no data.
     * Last known batches on board must then be fetched from batch endpoint
     */
    if (rob && rob.hasData) {
      const batchIdsAtTimeSelected = rob?.batchQuantities
        .filter(({ quantity }) => quantity > 0)
        .map((x) => x.batch.id)
      setBatchOptionsAtTimeSelected(
        batchOptions?.filter(({ value }) =>
          batchIdsAtTimeSelected?.includes(value),
        ),
      )
    } else {
      /* When ROB can't be calculated show the batches currently on board */
      setBatchOptionsAtTimeSelected(batchOptions)
    }
  }, [rob, batchOptions])

  const createNewMixedBatch = (
    values: FormValues,
    setSubmitting: (isSubmitting: boolean) => void,
  ) => {
    if (setSubmitting) setSubmitting(true)

    const requestBody: Performance.FuelOilStock.MixedBatchEntryRequest = {
      timestamp: values.timestamp?.toISOString() ?? '',
      batches: values.mixedBatches.map((mixedBatch) => ({
        id: mixedBatch.id,
        quantity: mixedBatch.quantity || 0,
      })),
      notes: values.notes,
    }
    PerformanceApi.postMixedBatch(imoNo, requestBody)
      .then(() => closeHandler(true))
      .catch((e) => {
        displayErrorModal({
          statusText: 'Could not submit mixed batch',
          message: e.message,
        })
      })
      .finally(() => setSubmitting(false))
  }

  if (!openWindow) {
    return (
      <>
        {/* The OpenWindowUpdater fetches the time interval when Stock changes are allowed to determine available dates in the datepicker */}
        <OpenWindowUpdater setOpenWindow={setOpenWindow} />
        <Loading />
      </>
    )
  }

  return (
    <Formik
      initialValues={defaultInitialValues}
      onSubmit={(values, { setSubmitting }) =>
        createNewMixedBatch(values, setSubmitting)
      }
      validationSchema={validationSchema}
      validateOnBlur={false}
    >
      {({ values, isSubmitting, isValidating, isValid, dirty }) => {
        const submitIsDisabled =
          isShoreContext() || isSubmitting || isValidating || !isValid || !dirty

        return (
          <Form>
            {/* The RobUpdater refreshes ROB for all the batches when timestamp is selected/changed
             * CurrentBatchRobUpdater sets the current ROB for the batch the user selected to be displayed next to the selector
             */}
            <RobUpdater setRob={setRob} />
            <CurrentBatchRobUpdater rob={rob} />

            {rob?.notifications && rob.notifications.length > 0 && (
              <InfoStyle>
                <StockRobNotifications rob={rob} />
              </InfoStyle>
            )}
            <InfoStyle>
              <MixWarning name='mixedBatches' />
            </InfoStyle>

            <Wrapper>
              <FormContainer>
                <div className='field-container'>
                  <TimestampFieldLabel>Time of mixing UTC</TimestampFieldLabel>
                  <Field
                    component={(props) => (
                      <FormDateTimeInput
                        {...props}
                        minDate={moment.utc(openWindow.period.from)}
                        maxDate={moment.utc(openWindow.period.to)}
                        minuteSpecific={false}
                      />
                    )}
                    name='timestamp'
                    disabled={false}
                  />
                </div>
                <ErrorContainer>
                  <ErrorMessage name='timestamp'></ErrorMessage>
                </ErrorContainer>

                <FieldArray
                  name='mixedBatches'
                  render={(arrayHelpers) => (
                    <div style={{ marginTop: '8px' }}>
                      <ErrorContainer>
                        <ErrorMessage name='mixedBatches'></ErrorMessage>
                      </ErrorContainer>
                      {values.mixedBatches && values.mixedBatches.length > 0
                        ? values.mixedBatches.map((mixedBatch, index) => (
                            <BatchQuantityGroup key={`mixedBatches-${index}`}>
                              <BatchQuantityField>
                                <BatchesFieldLabel>
                                  {values.mixedBatches.length > 1 && (
                                    <Icon
                                      icon='fal fa-trash-alt fa-sm'
                                      style={{
                                        color: red[500],
                                        cursor: 'pointer',
                                      }}
                                      onClick={() => {
                                        arrayHelpers.remove(index)
                                      }}
                                    />
                                  )}
                                  <span
                                    style={{ marginLeft: '16px' }}
                                  >{`Batch ${index + 1}`}</span>
                                </BatchesFieldLabel>
                                <Field
                                  name={`mixedBatches.${index}.id`}
                                  component={(props) => (
                                    <BatchSelect
                                      {...props}
                                      width='300px'
                                      menuHeaderText='Select batch to mix'
                                      noOptionsMessage={() =>
                                        'Select time of mixing to see options'
                                      }
                                    />
                                  )}
                                  options={batchOptionsAtTimeSelected}
                                  width='300px'
                                  disabled={false}
                                  tabIndex={1}
                                />

                                <RobContainer>
                                  <div>{`${
                                    typeof mixedBatch.currentRob === 'number'
                                      ? formatValue(mixedBatch.currentRob, 3)
                                      : '-'
                                  } MT`}</div>
                                  <div>at time selected</div>
                                </RobContainer>
                              </BatchQuantityField>
                              <ErrorContainer>
                                <ErrorMessage
                                  name={`mixedBatches[${index}].id`}
                                ></ErrorMessage>
                              </ErrorContainer>
                              <BatchQuantityField>
                                <BatchesFieldLabel>Quantity</BatchesFieldLabel>
                                <QuantityFieldContainer>
                                  <QuantityInputContainer>
                                    <Field
                                      key={`mixedBatches[${index}].quantity`}
                                      component={FormInputWithUnit}
                                      name={`mixedBatches[${index}].quantity`}
                                      type='number'
                                      unit='MT'
                                      width='120px'
                                      disabled={false}
                                      tabIndex={1}
                                      placeholder='0.000'
                                    />
                                    <CalculatorContainer
                                      onClick={() => {
                                        setMixedBatchName(
                                          `mixedBatches[${index}]`,
                                        )
                                        setShouldShowCalculator(true)
                                      }}
                                    >
                                      <Icon
                                        tabIndex={-1}
                                        icon='fal fa-calculator'
                                      />
                                    </CalculatorContainer>
                                  </QuantityInputContainer>
                                </QuantityFieldContainer>
                              </BatchQuantityField>
                              <ErrorContainer>
                                <ErrorMessage
                                  name={`mixedBatches[${index}].quantity`}
                                ></ErrorMessage>
                              </ErrorContainer>
                            </BatchQuantityGroup>
                          ))
                        : ''}
                      <VolumeToMassComponent
                        shouldShowCalculator={shouldShowCalculator}
                        mixedBatchName={`${mixedBatchName}.quantity`}
                        closeHandler={() => {
                          setMixedBatchName(undefined)
                          setShouldShowCalculator(false)
                        }}
                      />
                      {/* Use can max mix up to 4 different batches */}
                      {values.mixedBatches.length < 4 && (
                        <AddBatchContainer>
                          <McButton
                            label='Add batch'
                            appearance='primary'
                            padding='compact'
                            icon='plus'
                            type='button'
                            click={() => {
                              arrayHelpers.push({
                                id: '',
                                quantity: undefined,
                                currentRob: undefined,
                              })
                            }}
                          />
                        </AddBatchContainer>
                      )}
                    </div>
                  )}
                />

                <div className='field-container'>
                  <BatchesFieldLabel>Notes</BatchesFieldLabel>
                  <Field
                    component={FormTextArea}
                    name='notes'
                    width='338px'
                    tabIndex={1}
                  />
                </div>
                <ErrorContainer>
                  <ErrorMessage name='notes' />
                </ErrorContainer>
              </FormContainer>
            </Wrapper>
            <FormControls>
              <McButton
                label='Cancel'
                appearance='neutral'
                click={() => closeHandler()}
                type='button'
              />
              <McButton
                label='Create new mixed batch'
                appearance='primary'
                disabled={submitIsDisabled}
                type='submit'
              />
            </FormControls>
          </Form>
        )
      }}
    </Formik>
  )
}

export default MixedBatchForm
