import { IStockNotification } from '../../api-models/performance/common'
import {
  BatchMonitor,
  FuelLineDataLoss,
  Monitor,
} from '../../api-models/performance/fuel-consumption'
import { ManualCorrections } from '../../api-models/performance/manual-corrections'

export enum FuelConsumptionActionType {
  RESET_FUEL_CONSUMPTION_STATE = 'RESET_FUEL_CONSUMPTION_STATE',
  SET_CONSUMPTION_STATE = 'SET_CONSUMPTION_STATE',
  SET_NOTIFICATIONS = 'SET_NOTIFICATIONS',
  SET_CORRECTIONS = 'SET_CORRECTIONS',
  SET_LOSSES = 'SET_LOSSES',
  SET_MONITOR_BATCH = 'SET_MONITOR_BATCH',
  SET_MONITOR_OIL = 'SET_MONITOR_OIL',
}

export interface FuelConsumptionAction {
  type: FuelConsumptionActionType
  payload?: any
}

/**
 * setLosses
 * @description Actions that sets fuel consumption data loss's
 *
 * @type ACTION
 * @param {FuelLineDataLoss[]} losses Data loss's response from the backend.
 */
export const setLosses = (
  losses: FuelLineDataLoss[],
): FuelConsumptionAction => {
  return {
    type: FuelConsumptionActionType.SET_LOSSES,
    payload: losses,
  }
}

/**
 * setConsumptionState
 * @description Action that sets the fuel consumption state
 *
 * @type ACTION
 * @param {Number}
 */
export const setConsumptionState = (state: number) => ({
  type: FuelConsumptionActionType.SET_CONSUMPTION_STATE,
  payload: state,
})

export const setNotifications = (notifications: IStockNotification[]) => ({
  type: FuelConsumptionActionType.SET_NOTIFICATIONS,
  payload: notifications,
})

/**
 * setCorrections
 * @description Actions that sets the manual corrections to the FuelConsumption state
 *
 * @type ACTION
 * @param {ManualCorrections} corrections Corrections response from the backend.
 */
export const setCorrections = (
  corrections: ManualCorrections,
): FuelConsumptionAction => {
  return {
    type: FuelConsumptionActionType.SET_CORRECTIONS,
    payload: {
      overwrites: corrections.overwrites,
      repairs: corrections.repairs,
    },
  }
}

/**
 * setMonitorBatch
 * @description Actions that sets the fuel consumption batch monitor data on the FuelConsumption state.
 *
 * @type ACTION
 * @param {BatchMonitor} batchMonitor Batch monitor response from the backend.
 */
export const setMonitorBatch = (
  batchMonitor: BatchMonitor | undefined,
): FuelConsumptionAction => {
  return {
    type: FuelConsumptionActionType.SET_MONITOR_BATCH,
    payload: batchMonitor,
  }
}

/**
 * setMonitorOil
 * @description Actions that sets the fuel consumption oil monitor data on the FuelConsumption state.
 *
 * @type ACTION
 * @param {Monitor} oilMonitor Oil monitor response from the backend.
 */
export const setMonitorOil = (
  oilMonitor: Monitor | undefined,
): FuelConsumptionAction => {
  return {
    type: FuelConsumptionActionType.SET_MONITOR_OIL,
    payload: oilMonitor,
  }
}

/**
 * resetConsumption
 * @description Clears the Fuel Consumption context consumption state.
 *
 * @type ACTION
 */
export const resetConsumption = (): FuelConsumptionAction => {
  return {
    type: FuelConsumptionActionType.RESET_FUEL_CONSUMPTION_STATE,
  }
}
