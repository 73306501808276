import * as React from 'react'
import { McIcon } from '@maersk-global/mds-react-wrapper'

import styled, { maerskBlue, grey } from '../../../theme'

const Wrapper = styled.div`
  cursor: pointer;

  .tree-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;
    border-bottom: 1px solid #fff;
  }

  .title {
    display: flex;
    align-items: center;

    > mc-icon {
      margin-right: 12px;
    }
  }

  .collapsed > mc-icon::part(icon) {
    transform: rotate(0deg);
    transition: all 0.5s ease;
  }

  .open > mc-icon::part(icon) {
    transform: rotate(90deg);
    transition: all 0.5s ease;
  }

  .primary {
    background-color: ${maerskBlue[500]};
    color: #fff;

    mc-icon::part(icon) {
      fill: #fff;
    }
  }

  .secondary {
    background-color: ${grey[300]};
    color: ${grey[900]};

    mc-icon::part(icon) {
      fill: ${grey[800]};
    }
  }

  .button {
    background-color: transparent;
    padding: 0;
  }
`

export interface TreeCollapseProps {
  title: string
  name?: string
  isDeselect?: boolean
  isSelected?: boolean
  level: 'primary' | 'secondary'
  isOpened?: boolean
  onClick: () => void
  children?: React.ReactNode
}

export interface TreeCollapseState {
  isOpened: boolean
}

export default class TreeCollapse extends React.Component<
  TreeCollapseProps,
  TreeCollapseState
> {
  constructor(props: TreeCollapseProps) {
    super(props)
    this.state = {
      isOpened: !!props.isOpened,
    }
  }

  componentDidUpdate(prevProps: TreeCollapseProps) {
    if (prevProps.isOpened !== this.props.isOpened)
      this.setState({ isOpened: !!this.props.isOpened })
  }

  render() {
    const { title, isDeselect, isSelected, level, onClick } = this.props
    const { isOpened } = this.state

    return (
      <Wrapper>
        <div
          className={`tree-header ${level}`}
          onClick={() =>
            this.setState({ isOpened: !isOpened || !!this.props.isOpened })
          }
        >
          <div className={`title ${isOpened ? 'open' : 'collapsed'}`}>
            <McIcon icon='chevron-right' />
            {title}
          </div>
          {isSelected ? (
            <McIcon icon='check' />
          ) : (
            <button
              className='button'
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                onClick()
              }}
            >
              <McIcon icon={`${isDeselect ? 'minus' : 'plus'}`} />
            </button>
          )}
        </div>
        {isOpened && this.props.children}
      </Wrapper>
    )
  }
}
