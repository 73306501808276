import styled from 'styled-components'

export const Dot = styled.span<{ error: boolean }>`
  height: 8px;
  width: 8px;
  @media (min-width: ${(props) => props.theme.breakPoints.large}px) {
    height: 12px;
    width: 12px;
  }
  background-color: ${(props) =>
    props.error
      ? `${props.theme.colors.feedback.danger.main}`
      : `${props.theme.colors.feedback.success.main}`};
  border-radius: 50%;
  display: inline-block;
  margin: 0 8px 2px 0;
  @media (min-width: ${(props) => props.theme.breakPoints.large}px) {
    margin: 0 12px 0 0;
  }
`
