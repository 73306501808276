import * as yup from 'yup'

import {
  equipmentIsRequired,
  runtimeIsRequired,
  shorePowerUsedIsRequired,
  shorePowerReasonIsRequired,
  periodEnergyIsRequired,
  periodDateIsRequired,
  periodMinuteIsRequired,
  periodHourIsRequired,
} from '../../utils/validation'
import { isNumber } from '../../../../utils'

yup.addMethod(yup.object, 'equipmentIsRequired', equipmentIsRequired)
yup.addMethod(yup.object, 'runtimeIsRequired', runtimeIsRequired)
yup.addMethod(yup.string, 'shorePowerUsedIsRequired', shorePowerUsedIsRequired)
yup.addMethod(
  yup.string,
  'shorePowerReasonIsRequired',
  shorePowerReasonIsRequired,
)
yup.addMethod(yup.number, 'periodEnergyIsRequired', periodEnergyIsRequired)
yup.addMethod(yup.string, 'periodDateIsRequired', periodDateIsRequired)
yup.addMethod(yup.number, 'periodHourIsRequired', periodHourIsRequired)
yup.addMethod(yup.number, 'periodMinuteIsRequired', periodMinuteIsRequired)

const electricalValidationSchema = (reportType) => {
  return yup.object().shape({
    engineRoom: yup.object().shape({
      auxEngines: yup.array().of(
        yup.object().shape({
          energy: yup
            .object()
            .equipmentIsRequired('Aux engine', 'energy cannot be empty.'),
          runTime: yup.object().runtimeIsRequired('Aux engine'),
        }),
      ),
      shaftGenerators: yup.array().of(
        yup.object().shape({
          energy: yup
            .object()
            .equipmentIsRequired('Shaft generator', 'energy cannot be empty.'),
          runTime: yup.object().runtimeIsRequired('Shaft generator'),
        }),
      ),
      auxBlowers: yup.array().of(
        yup.object().shape({
          energy: yup
            .object()
            .equipmentIsRequired('Aux blower', 'energy cannot be empty.'),
          runTime: yup.object().runtimeIsRequired('Aux blower'),
        }),
      ),
      shaftMotors: yup.array().of(
        yup.object().shape({
          energy: yup
            .object()
            .equipmentIsRequired('Shaft motor', 'energy cannot be empty.'),
          runTime: yup.object().runtimeIsRequired('Shaft motor'),
        }),
      ),
      shorePowerPeriods: yup
        .object()
        .nullable()
        .shape({
          shorePowerUsed: yup
            .string()
            .nullable()
            .shorePowerUsedIsRequired(
              reportType,
              'Shore power - Please select Yes/No if shore power was connected.',
            ),
          reason: yup
            .string()
            .shorePowerReasonIsRequired(
              reportType,
              'Please select reason code for No shore power connected.',
            ),
          periods: yup.array().of(
            yup.object().shape({
              periodStart: yup.object().shape({
                energy: yup
                  .number()
                  .periodEnergyIsRequired(
                    reportType,
                    'start period',
                    'energy is required.',
                    true,
                  ),
                dateFormat: yup.object().shape({
                  hoursValue: yup
                    .number()
                    .periodHourIsRequired(
                      reportType,
                      'start period',
                      'hour is required',
                    ),
                  minutesValue: yup
                    .number()
                    .periodMinuteIsRequired(
                      reportType,
                      'start period',
                      'minute is required',
                    ),
                  dateValue: yup
                    .string()
                    .periodDateIsRequired(
                      reportType,
                      'start period',
                      'start date is required',
                    ),
                }),
              }),
              periodEnd: yup.object().shape({
                energy: yup
                  .number()
                  .periodEnergyIsRequired(
                    reportType,
                    'end period',
                    'energy is required.',
                    false,
                  ),
                dateFormat: yup.object().shape({
                  hoursValue: yup
                    .number()
                    .periodHourIsRequired(
                      reportType,
                      'end period',
                      'hour is required',
                    ),
                  minutesValue: yup
                    .number()
                    .periodMinuteIsRequired(
                      reportType,
                      'end period',
                      'minute is required',
                    ),
                  dateValue: yup
                    .string()
                    .periodDateIsRequired(
                      reportType,
                      'end period',
                      'end date is required',
                    ),
                }),
              }),
            }),
          ),
        }),
      scrubberSystem: yup
        .object()
        .nullable()
        .shape({
          energy: yup
            .object()
            .equipmentIsRequired('Scrubber system', 'power cannot be empty.'),
          runTime: yup
            .object()
            .runtimeIsRequired('Scrubber system', 'runtime cannot be empty.'),
        }),
      wasteHeatRecovery: yup
        .object()
        .nullable()
        .shape({
          energy: yup
            .object()
            .equipmentIsRequired(
              'Wasteheat recovery',
              'energy cannot be empty.',
            ),
          runTime: yup.object().runtimeIsRequired('Wasteheat recovery.'),
        }),
      reeferEnergy: yup.object().shape({
        value: yup
          .number()
          .nullable()
          .required('Reefer energy consumption is required.'),
      }),
      numberOfReefers: yup
        .object()
        .when('reeferEnergy.value', {
          is: (value) => isNumber(value) && value > 0,
          then: yup.object().shape({
            value: yup
              .number()
              .nullable()
              .moreThan(0, 'Number of reefers most be more than zero')
              .required(
                'Number of reefers are required, because of registrated reefer energy consumption.',
              ),
          }),
        })
        .when('reeferEnergy.value', {
          is: (value) => !isNumber(value) || value === 0,
          then: yup.object().shape({
            value: yup
              .number()
              .nullable()
              .max(
                0,
                "Number of reefers can't be more than zero, since no reefer energy was consumed",
              ),
          }),
        }),
    }),
  })
}
export default electricalValidationSchema
