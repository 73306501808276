import { type MouseEvent, useContext, useRef, useState } from 'react'
import { useHistory, useParams, useRouteMatch } from 'react-router-dom'
import {
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  type Row,
  type SortingState,
  useReactTable,
} from '@tanstack/react-table'
import { McButton } from '@maersk-global/mds-react-wrapper'
import { type McListItem } from '@maersk-global/mds-components-core'

import { getColumns } from './EnginePerformanceTestPage.utils'
import { MetcReportAction as Action } from './EnginePerformanceTestPage.consts'
import {
  TabIndex,
  MetcReportTabSearchParam,
} from './EnginePerformanceTestReportPage/EnginePerformanceTestReportPage.consts'
import EnginePerformanceTestFilters from './EnginePerformanceTestFilters/EnginePerformanceTestFilters'
import CreateMetcReportModal from './CreateMetcReportModal/CreateMetcReportModal'
import EmptyState from './EmptyState/EmptyState'

import { ContentLayout } from '../../layout'
import { ContentCard, Loading } from '../../commons'
import { type routerParams } from '../../routes'
import { WindowContext } from '../../contexts'
import { useGetReports } from '../../queries/GandalfApi/GandalfApi'
import {
  ReportState,
  ReportType,
} from '../../queries/GandalfApi/GandalfApi.consts'
import Table, { type ITableRef } from '../../components/Table/Table'
import { PadContent } from '../../layout/styles'
import EnginePerformanceTestNotifications from '../../components/EnginePerformanceTestNotifications/EnginePerformanceTestNotifications'
import TemNotifications from '../../components/TemNotifications/TemNotifications'
import PerformanceAlerts from '../../components/PerformanceAlerts/PerformanceAlerts'
import DeleteReportModal from '../../components/Reporting/DeleteReportModal'
import { useVesselMainEngines } from '../../queries/MasterDataApi/MasterDataApi'

const EnginePerformanceTestPage = () => {
  const history = useHistory()
  const { url } = useRouteMatch()
  const { vesselId: imoNo } = useParams<routerParams>()
  const vesselMainEngines = useVesselMainEngines(imoNo)
  const { windowSize } = useContext(WindowContext)
  const getReports = useGetReports<GandalfApi.Metc.ReportData>(
    imoNo,
    ReportType.Metc,
  )

  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [createModalOpen, setCreateModalOpen] = useState(false)
  const [selectedReportId, setSelectedReportId] = useState<string>()
  const [sorting, setSorting] = useState<SortingState>([
    { desc: true, id: 'startTimestamp' },
  ])

  const tableRef = useRef<ITableRef>(null)

  const handleCreateReportClick = () => {
    setCreateModalOpen(true)
  }

  const handleRowClick = (
    row: Row<GandalfApi.MetcReport>,
    event: MouseEvent,
  ) => {
    const target = event.target as HTMLElement
    if (target.closest('mc-button, mc-list-item')) {
      /* Return if clicking on a menu button or menu item. */
      tableRef.current?.deselectRow()
      return
    }

    let reportUrl = `${url}/report/${row.original.reportId}?${MetcReportTabSearchParam}=`
    if (row.original.reportState === ReportState.Submitted) {
      history.push(`${reportUrl}${TabIndex.RESULTS}`)
    } else {
      history.push(`${reportUrl}${TabIndex.GENERAL}`)
    }
  }

  const handleListClick =
    (reportId: string) =>
    ({ detail }: CustomEvent) => {
      setSelectedReportId(reportId)
      const item: McListItem = detail.item

      switch (item.label) {
        case Action.ViewResults:
          history.push(
            `${url}/report/${reportId}?${MetcReportTabSearchParam}=${TabIndex.RESULTS}`,
          )
          break
        case Action.EditTest:
          history.push(
            `${url}/report/${reportId}?${MetcReportTabSearchParam}=${TabIndex.GENERAL}`,
          )
          break
        case Action.Delete:
          setDeleteModalOpen(true)
          break
      }
    }

  const handleDeleteModalClose = () => {
    setSelectedReportId(undefined)
    setDeleteModalOpen(false)
  }

  const handleCreateModalClose = () => {
    setCreateModalOpen(false)
  }

  const table = useReactTable({
    data: getReports.data?.reports || [],
    columns: getColumns(windowSize, handleListClick, vesselMainEngines.data),
    initialState: {
      pagination: { pageSize: 25 },
    },
    state: { sorting },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  })

  if (!getReports.isSuccess) {
    return <Loading />
  }

  const selectedReport = getReports.data.reports.find(
    (report) => report.reportId === selectedReportId,
  )

  const renderButton = (
    <McButton
      fit={windowSize}
      label='Add Engine Test Report'
      click={handleCreateReportClick}
      data-e2e='add-engine-test-report-button'
    />
  )

  const hasReports = getReports.data.reports.length !== 0

  return (
    <>
      <ContentLayout header='Engine test'>
        <TemNotifications />
        <PerformanceAlerts />
        <EnginePerformanceTestNotifications />
        <ContentCard
          id='engine-test__reports-overview'
          title={hasReports ? 'Reports overview' : ''}
          additionalInfo={hasReports ? renderButton : null}
        >
          <PadContent>
            {hasReports ? (
              <>
                <EnginePerformanceTestFilters
                  table={table}
                  mainEngines={vesselMainEngines.data}
                />
                <Table
                  table={table}
                  onRowSelect={handleRowClick}
                  ref={tableRef}
                />
              </>
            ) : (
              <EmptyState>{renderButton}</EmptyState>
            )}
          </PadContent>
        </ContentCard>
      </ContentLayout>
      <CreateMetcReportModal
        open={createModalOpen}
        onClose={handleCreateModalClose}
      />
      {selectedReport && (
        <DeleteReportModal
          open={deleteModalOpen}
          onClose={handleDeleteModalClose}
          imoNo={imoNo}
          report={selectedReport}
        />
      )}
    </>
  )
}

export default EnginePerformanceTestPage
