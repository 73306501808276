import { UNITS } from '../../../../utils/constants'
import { formatValue, FuelType } from '../../../../utils'
import styled, { FuelColors, IColorScheme } from '../../../../theme'
import { StockOnBoard } from '../../models'
import { fuelTypeByDisplayOrder } from '../../utils'

const TotalsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  & > div:not(:last-child) {
    margin-right: 16px;
  }
`

const FuelTypeTotal = styled.div`
  text-align: center;
  width: 100%;
`

const Paragraph = styled.div<{
  colorScheme?: IColorScheme
}>`
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 3px;
  color: ${({ colorScheme }) => (colorScheme ? colorScheme.stroke : '#000000')};
`

const Metric = styled.div`
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 15px;
  text-transform: uppercase;
`

type Props = {
  stockOnBoard?: StockOnBoard
}

export const FuelTypeTotals = ({ stockOnBoard }: Props) => {
  return (
    <TotalsWrapper>
      {stockOnBoard?.fuelTypeQuantities
        .filter(
          // TODO: This is a temporary fix to hide methanol from the UI.
          //  In the future we should have a flag in 3MDS static models specifying if the engine is a dual fuel engine.
          (fuelTypeQuantity) => fuelTypeQuantity.fuelType !== FuelType.MM,
        )
        .sort(fuelTypeByDisplayOrder)
        .map((fuelTypeQuantity) => (
          <FuelTypeTotal
            key={`${fuelTypeQuantity.fuelType}-${fuelTypeQuantity.quantity}`}
          >
            <Paragraph
              colorScheme={FuelColors[FuelType[fuelTypeQuantity.fuelType]]}
            >
              Total {FuelType[fuelTypeQuantity.fuelType]}
            </Paragraph>
            <Metric>
              {formatValue(fuelTypeQuantity.quantity, 3)} {UNITS.METRIC_TON}
            </Metric>
          </FuelTypeTotal>
        ))}
    </TotalsWrapper>
  )
}
