import * as React from 'react'
import { PieChart, Pie } from 'recharts'
import { maerskBlue, grey, sizes } from '../../../theme'
import { findDialAngle, isNumber, calcPercentageSafely } from '../../../utils'
import { DialReferenceLine, mapToRefLine } from './DialReferenceLine'

export interface SimpleDialProps {
  id: string
  value: number | null
  dialHeader: string
  minValue: number | null
  maxValue: number | null
  unit: string
  color?: string
  label?: React.ReactElement<any>
  referenceLines?: DialReferenceLine[]
}

export interface SimpleDialState {}

export class SimpleDial extends React.Component<
  SimpleDialProps,
  SimpleDialState
> {
  defineRefLines: (
    refLines: DialReferenceLine[],
    maxData: number,
  ) => DialReferenceLine[] = (refLines, maxValue) => {
    let output: DialReferenceLine[] = []
    refLines.forEach((refLine) => {
      if (
        !output.some((line, index) => {
          if (!refLine.value || !line.value) return false
          const refLinePercentage = calcPercentageSafely(
            refLine.value,
            maxValue,
          )!
          const linePercentage = calcPercentageSafely(line.value, maxValue)!
          const isClose =
            Math.abs(refLinePercentage - linePercentage) < 3 ||
            Math.abs(linePercentage - refLinePercentage) < 3
          if (isClose) {
            output[
              index
            ].tooltip += `\n ${refLine.label} = ${refLine.value} ${this.props.unit}`
            output[index].label += `, ${refLine.label}`
            output[index].className += ` ${refLine.className}`
          }
          return isClose
        })
      ) {
        output.push({
          tooltip: `${refLine.label} = ${refLine.value} ${this.props.unit}`,
          ...refLine,
        })
      }
    })
    return output
  }

  public render() {
    const {
      value,
      color,
      minValue,
      maxValue,
      unit,
      dialHeader,
      label,
      referenceLines,
    } = this.props
    const data = [{ value }]
    const maxData = [{ value: maxValue ? maxValue : 180 }]
    const outerRadius = 96
    const innerRadius = 56
    const height = dialHeader ? sizes.quarter - 56 : sizes.quarter - 56 - 28
    const padding = dialHeader ? 48 : 32
    const refLines = referenceLines
      ? this.defineRefLines(referenceLines, maxData[0].value)
      : null
    return (
      <PieChart height={height} width={sizes.quarter}>
        <Pie
          data={maxData}
          dataKey='value'
          startAngle={180}
          endAngle={0}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          fill={grey[100]}
          stroke={grey[100]}
          cy={padding + 96}
          isAnimationActive={false}
        />
        <Pie
          data={data}
          dataKey='value'
          startAngle={180}
          endAngle={findDialAngle(value ? value : 0, maxValue ? maxValue : 100)}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          fill={color ? color : maerskBlue[500]}
          cy={padding + outerRadius}
        />
        {refLines && refLines.map(mapToRefLine)}
        <text
          x='50%'
          y='18'
          textAnchor='middle'
          fill={grey[900]}
          dominantBaseline='hanging'
        >
          {dialHeader}
        </text>
        <text
          fontSize={14}
          x={52}
          y={padding + outerRadius + 24}
          textAnchor='middle'
          fill={grey[700]}
        >
          {minValue}
        </text>
        <text
          x={sizes.quarter - 52}
          y={padding + outerRadius + 24}
          fontSize={14}
          textAnchor='middle'
          fill={grey[700]}
        >
          {maxValue}
        </text>
        {!!label ? (
          label
        ) : (
          <g>
            <text
              x='50%'
              y={padding + 90 - 10}
              textAnchor='middle'
              fill={grey[900]}
              fontSize='22px'
              alignmentBaseline='baseline'
            >
              {isNumber(value) ? value : '-'}
            </text>
            <text
              x='50%'
              y={padding + 90 + 10}
              textAnchor='middle'
              fontSize='14'
              fill={grey[700]}
              alignmentBaseline='baseline'
            >
              {unit}
            </text>
          </g>
        )}
      </PieChart>
    )
  }
}
