import React, { useContext } from 'react'
import {
  McRadio,
  McRadioGroup,
  McTextarea,
  McSelectNative,
} from '@maersk-global/mds-react-wrapper'
import { useFormikContext } from 'formik'

import { Wrapper } from './ShorePowerUsedComponent.styles'
import { SHORE_POWER_USED } from './ShorePowerUsedComponent.utils'

import { WindowContext } from '../../../../../contexts'
import { HDCReportFormValues } from '../../../types'

interface ShorePowerUsedComponentProps {
  onShorePowerUsedChange: (v: string) => (_: Event) => void
  onShorePowerReasonChange: (e) => void
}

const ShorePowerUsedComponent = (props: ShorePowerUsedComponentProps) => {
  const { windowSize } = useContext(WindowContext)
  const { values, setFieldValue } = useFormikContext<HDCReportFormValues>()
  const { shorePowerPeriods } = values.engineRoom

  const handleCommentsChange = (event) => {
    setFieldValue('engineRoom.shorePowerPeriods.comment', event.target.value)
  }

  if (!shorePowerPeriods) return null

  return (
    <Wrapper>
      <div className='shore-power-fields'>
        <McRadioGroup
          fit={windowSize}
          legend='Was shore power used'
          orientation='horizontal'
        >
          <McRadio
            name='engineRoom.shorePowerPeriods.shorePowerUsed'
            value='yes'
            label='Yes'
            checked={shorePowerPeriods.shorePowerUsed === 'yes'}
            change={props.onShorePowerUsedChange('yes')}
          />
          <McRadio
            name='engineRoom.shorePowerPeriods.shorePowerUsed'
            value='no'
            label='No'
            checked={shorePowerPeriods.shorePowerUsed === 'no'}
            change={props.onShorePowerUsedChange('no')}
          />
        </McRadioGroup>
        <McSelectNative
          name='engineRoom.shorePowerPeriods.reason'
          label='Reason why not'
          fit={windowSize}
          placeholder='Select an option'
          width={String(SHORE_POWER_USED.DEFAULT_WIDTH)}
          options={SHORE_POWER_USED.REASONS_LIST}
          change={props.onShorePowerReasonChange}
          disabled={
            shorePowerPeriods.shorePowerUsed === 'yes' ||
            shorePowerPeriods.shorePowerUsed === null
          }
          value={
            !shorePowerPeriods.reason ? [] : Array(shorePowerPeriods.reason)
          }
        />
      </div>
      <div className='comments'>
        <McTextarea
          fit={windowSize}
          name='engineRoom.shorePowerPeriods.comment'
          label='Comments'
          placeholder='Two periods due to...'
          rows={SHORE_POWER_USED.TEXT_AREA_ROWS}
          input={handleCommentsChange}
          value={shorePowerPeriods.comment}
        />
      </div>
    </Wrapper>
  )
}
export default ShorePowerUsedComponent
