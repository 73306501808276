export enum MfeReportAction {
  View = 'View',
  Edit = 'Edit',
  Delete = 'Delete',
}

export enum MfeEquipmentType {
  MainEngine = 'Main Engine',
  AuxEngines = 'Aux Engines',
  AuxSystems = 'Auxiliary Systems',
  Safety = 'Safety, Navigation And Deck',
  Vessel = 'Vessel',
}
