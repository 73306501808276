import { useFormikContext } from 'formik'

import MfeEngineModelField from './MfeEngineModelField'
import MfeInputSelect from '../MfeInputSelect/MfeInputSelect'
import { Row } from '../MalfunctioningEquipmentReportPage.styles'
import { meStatuses } from '../General/General.consts'
import { MfeDataFormat as DataFormat } from '../../../../queries/GandalfApi/GandalfApi.consts'
import { MfeEquipmentType as Equipment } from '../../MalfunctioningEquipmentPage.consts'

const MfeEngineSpecificFields = () => {
  const { values } = useFormikContext<GandalfApi.MfeReport>()

  const { dataFormat, data } = values

  if (
    !(
      data.equipment === Equipment.MainEngine ||
      data.equipment === Equipment.AuxEngines
    )
  ) {
    return null
  }

  return (
    <Row>
      {dataFormat >= DataFormat.staticModel && (
        <MfeEngineModelField equipment={data.equipment} />
      )}
      {data.equipment === Equipment.MainEngine ? (
        <MfeInputSelect
          name='data.meStatus'
          label='ME status'
          options={meStatuses.map((s) => ({ label: s, value: s }))}
        />
      ) : (
        <div />
      )}
      <div />
    </Row>
  )
}

export default MfeEngineSpecificFields
