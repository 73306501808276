import { useCallback, useEffect, useState } from 'react'
import moment from 'moment'

import { ContentCard, Tabs } from '../../../../commons'
import { BatchBurndownChartContainer } from './BatchBurndownChartContainer'
import * as PerformanceApi from '../../../../services/performance'
import { Performance } from '../../../../api-models'
import {
  displayErrorModal,
  FUEL_TYPE_NAMES,
  FuelType as FuelTypeEnum,
} from '../../../../utils'
import {
  BatchBurndownByFuelTypeSeries,
  UserEntriesByFuelTypeSeries,
} from './models'
import { mapBatchBurndownByFueltypes, mapUserEntriesByFuelType } from './utils'
import FuelAndStockModal from '../../../../pages/ActivityLogPage/FuelAndStock/FuelAndStockModal'

type Props = {
  imoNo: string
  useApi?: boolean // True if the feature component should call the api for data, otherwise sent in through props
  burndown?: Performance.FuelOilStock.BurndownModel | null
}

const FUEL_TYPES_ARR = [
  FuelTypeEnum.HS,
  FuelTypeEnum.VLS,
  FuelTypeEnum.ULS,
  FuelTypeEnum.MDO,
]

interface initialZoomInterface {
  startValue: number
  endValue: number
}

export const BatchBurndown = ({ imoNo, useApi, burndown }: Props) => {
  const [currentTab, setCurrentTab] = useState<string>(
    FUEL_TYPE_NAMES[FuelTypeEnum.HS],
  )
  const [burndownByFuelType, setBurndownByFuelType] =
    useState<BatchBurndownByFuelTypeSeries>()
  const [userEntriesByFuelType, setUserEntriesByFuelType] =
    useState<UserEntriesByFuelTypeSeries>()
  const [openWindow, setOpenWindow] =
    useState<Performance.FuelOilStock.OpenWindow>()

  const [period, setPeriod] = useState<Performance.Common.Period>()
  const [initialZoom, setInitialZoom] = useState<initialZoomInterface>({
    startValue: moment.utc().subtract(14, 'd').valueOf(),
    endValue: moment.utc().valueOf(),
  })

  const mapAndSetBurndownData = useCallback(
    (burndown?: Performance.FuelOilStock.BurndownModel | null) => {
      if (burndown) {
        const mappedResponse = mapBatchBurndownByFueltypes(burndown)
        setBurndownByFuelType(mappedResponse)
        setOpenWindow(burndown.openWindow)
        const mappedUserEntries = mapUserEntriesByFuelType(burndown)
        setUserEntriesByFuelType(mappedUserEntries)
        setPeriod(burndown.period)
      }
      if (burndown === null) setNoData()
    },
    [],
  )

  const getStockStatusWithBurndown = useCallback(
    (imoNo: string) => {
      const includeBurndown = true
      PerformanceApi.getStockStatus(imoNo, includeBurndown)
        .then((stockStatusResponse) => {
          mapAndSetBurndownData(stockStatusResponse?.burndown)
        })
        .catch((e) =>
          displayErrorModal({
            statusText: 'Could not get stock status',
            message: e.message,
          }).then(() => setNoData()),
        )
    },
    [mapAndSetBurndownData],
  )

  const setNoData = () => {
    const empty_data = {
      HS: [],
      VLS: [],
      ULS: [],
      MDO: [],
    }
    setBurndownByFuelType(empty_data as BatchBurndownByFuelTypeSeries)
    setUserEntriesByFuelType(empty_data as UserEntriesByFuelTypeSeries)
  }

  useEffect(() => {
    if (!useApi) mapAndSetBurndownData(burndown)
  }, [useApi, imoNo, burndown, mapAndSetBurndownData])

  useEffect(() => {
    if (useApi) getStockStatusWithBurndown(imoNo)
  }, [imoNo, useApi, getStockStatusWithBurndown])

  return (
    <ContentCard
      id={'batch-burndown'}
      title='Batch burndown'
      helpTextKey='stock/batch-burndown'
      headerChildren={<FuelAndStockModal />}
    >
      <Tabs
        tabButtons={FUEL_TYPES_ARR.map((fuelType: FuelTypeEnum) => ({
          name: FUEL_TYPE_NAMES[fuelType],
          onClick: () => setCurrentTab(FUEL_TYPE_NAMES[fuelType]),
        }))}
      >
        {FUEL_TYPES_ARR.map((fuelType: FuelTypeEnum) => (
          <BatchBurndownChartContainer
            key={fuelType}
            batchBurndownSeries={
              burndownByFuelType?.[FUEL_TYPE_NAMES[fuelType]]
            }
            userEntriesSeries={
              userEntriesByFuelType?.[FUEL_TYPE_NAMES[fuelType]]
            }
            initialZoom={initialZoom}
            setInitialZoom={setInitialZoom}
            openWindow={openWindow}
            period={period}
            id={FUEL_TYPE_NAMES[fuelType]}
            currentTab={currentTab}
          />
        ))}
      </Tabs>
    </ContentCard>
  )
}
