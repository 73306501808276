export const groupings = [
  {
    label: 'Fleet group',
    primaryKey: 'vesselType',
    secondaryKey: 'techManager',
  },
  {
    label: 'Vessel class',
    primaryKey: 'Vessel Class',
    secondaryKey: 'vesselClass',
  },
]

export const groupingOptions = groupings.map((group, index) => ({
  label: group.label,
  value: index.toString(),
}))

export const getInitialState = (): ManageFavouritesPage.State => {
  return {
    userList: [],
    selectedUserList: -1,
    filter: '',
    grouping: groupings[0],
    groupingIndex: 0,
    listName: '',
    isSaving: false,
  }
}
