import * as React from 'react'
import moment from 'moment'
import styled from 'styled-components'
import { IVesselPageContext } from '../../../contexts'
import {
  Filters,
  getDateRange,
  queryPeriodChanged,
  QueryPeriod,
  DefaultFilters,
} from '../../../features/filter'
import {
  getClosestTenMinute,
  mapFilterLegend,
  formatValue,
  getDefaultFilters,
} from '../../../utils'
import WHRvsME from './WasteHeatRecoveryChart'
import { Performance } from '../../../api-models'
import { mapWhrCurveData, recalculateWhrCurveData } from '../mapper'
import {
  ChartStencil,
  ContentCard,
  ChartContainer,
  NotFound,
  FancyLabel,
  ValueLabel,
  VesselStatusBlock,
  Block,
} from '../../../commons'
import { withVesselPageContext } from '../../../higher-order-components'
import { whr, load } from '../themes'
import { lightOrangeColorScheme } from '../../../theme'
import { UNITS } from '../../../utils/constants'
import { displayErrorModal } from '../../../utils/modal-utils'
import { MappedWHrCurveData } from '../models'
import { getWHRPowerMainEngLoad } from '../../../services/performance'

const Wrapper = styled.div``

export interface WasteHeatRecoveryChartContainerProps
  extends IVesselPageContext {
  filters?: Filters
}

interface WasteHeatRecoveryChartContainerState {
  whrMeData?: MappedWHrCurveData[]
  loading?: boolean
}

const samplePeriod = 90

class WasteHeatRecoveryChartContainer extends React.Component<
  WasteHeatRecoveryChartContainerProps,
  WasteHeatRecoveryChartContainerState
> {
  private whrVsMEChart: React.RefObject<WHRvsME>
  defaultFilters: DefaultFilters

  constructor(props: WasteHeatRecoveryChartContainerProps) {
    super(props)

    this.state = {}
    this.whrVsMEChart = React.createRef()
    this.defaultFilters = getDefaultFilters(6, 'h')
  }

  componentDidMount() {
    this.loadData()
  }

  componentDidUpdate(prevProps: WasteHeatRecoveryChartContainerProps) {
    const { imoNo, filters } = this.props

    if (prevProps.imoNo !== imoNo) {
      this.setState({ whrMeData: undefined })
      this.loadData()
    } else if (prevProps.filters !== filters) {
      const oldQueryPeriod = getDateRange(
        this.defaultFilters.dateRange,
        prevProps.filters,
      )
      const queryPeriod = getDateRange(this.defaultFilters.dateRange, filters)

      queryPeriodChanged(oldQueryPeriod, queryPeriod)
      this.updateDataSlice(queryPeriod)
    }
  }

  loadData() {
    const { imoNo, filters } = this.props
    if (imoNo) {
      const queryPeriod = {
        to: getClosestTenMinute(moment.utc()).toISOString(),
        from: getClosestTenMinute(
          moment.utc().subtract(samplePeriod, 'd'),
        ).toISOString(),
      }
      getWHRPowerMainEngLoad(imoNo, queryPeriod)
        .then((data: Performance.WHR.PowerMainEngLoadResponse) => {
          const queryFilterPeriod = getDateRange(
            this.defaultFilters.dateRange,
            filters,
          )
          this.setState({
            whrMeData: data.hasData
              ? mapWhrCurveData(data, queryFilterPeriod)
              : [],
          })
        })
        .catch((e) => {
          displayErrorModal(e)
        })
    }
  }

  updateDataSlice(queryFilterPeriod: QueryPeriod) {
    const { whrMeData } = this.state
    if (!whrMeData) return
    this.setState({
      whrMeData: recalculateWhrCurveData(whrMeData, queryFilterPeriod),
    })
  }

  mapAdditionalWhrGraphInfo(
    mainEngineDataSlice?: MappedWHrCurveData[],
  ): string {
    if (
      !mainEngineDataSlice ||
      (mainEngineDataSlice && mainEngineDataSlice.length === 0)
    ) {
      return 'No data points available for this date period.'
    } else {
      let hasData = mainEngineDataSlice.some((entry) => entry.filtered === true)
      return hasData ? '' : 'No data points available for this date period.'
    }
  }

  public render() {
    const { whrMeData } = this.state
    const { filters, vesselStatus } = this.props
    const queryPeriod = getDateRange(this.defaultFilters.dateRange, filters)
    return (
      <Wrapper>
        <div className='full-components'>
          <ContentCard
            id='whr-vs-me2'
            helpTextKey='waste-heat-recovery/whr-maineng-mcr'
            title='WHR vs ME % MCR'
            additionalInfo={this.mapAdditionalWhrGraphInfo(whrMeData)}
          >
            <ChartContainer
              y1Label={{ name: 'kW', colorScheme: whr }}
              xLabel={{ name: '% MCR', colorScheme: load }}
              sideContentWidth={136}
            >
              {!whrMeData && <ChartStencil chartType='scatter' />}
              {!!whrMeData && whrMeData.length === 0 && (
                <NotFound text='No data for the selected period found.' />
              )}
              {!!whrMeData && (
                <WHRvsME
                  id='whr-vs-me-chart'
                  data={whrMeData}
                  queryPeriod={queryPeriod}
                  ref={this.whrVsMEChart}
                />
              )}
              <div>
                {!!vesselStatus && (
                  <VesselStatusBlock timestamp={vesselStatus.timestamp}>
                    <Block>
                      <FancyLabel colorScheme={lightOrangeColorScheme}>
                        Production
                      </FancyLabel>
                      <ValueLabel unit={UNITS.KILO_WATT}>
                        {formatValue(
                          vesselStatus.whr ? vesselStatus.whr.power : null,
                          2,
                        )}
                      </ValueLabel>
                    </Block>
                  </VesselStatusBlock>
                )}
                {!!whrMeData && (
                  <div style={{ margin: '0 16px' }}>
                    <div className='legend-wrapper'>
                      <span className='fas fa-circle blue' />
                      <label>Last {samplePeriod} days</label>
                    </div>
                    <div className='legend-wrapper'>
                      <span className='fas fa-circle yellow' />
                      <label>{mapFilterLegend(queryPeriod)}</label>
                    </div>
                  </div>
                )}
              </div>
            </ChartContainer>
          </ContentCard>
        </div>
      </Wrapper>
    )
  }
}

export default withVesselPageContext(WasteHeatRecoveryChartContainer)
