import * as React from 'react'
import FilterPane from './FilterPane'
import moment, { DurationInputArg2, Moment } from 'moment'
import { DateTimeInput } from '../../../commons'
import { getClosestTenMinute, setMaxDateAsToday } from '../../../utils'
import styled, { maerskBlue } from '../../../theme'
import { McButton } from '@maersk-global/mds-react-wrapper'

const Styling = styled.div`
  label {
    width: 100%;
    display: block;
    font-size: 14px;
  }

  > label,
  > div {
    padding-top: 8px;
  }

  .quick-selection-buttons {
    display: flex;
    flex-wrap: wrap;

    mc-button::part(button) {
      margin: 4px 4px 0 0;

      &.hidden {
        opacity: 0;
        cursor: default;
      }

      &.active {
        background-color: ${maerskBlue[300]};
        font-style: italic;
        border-color: ${maerskBlue[500]};
      }
    }
  }
`

export interface DateRangeFilter {
  to?: Moment
  from?: Moment
  quickSelected?: number
}

export interface DateRangeConfig {
  show: boolean
  restrictNumberOfDays: number
  selections: { value: number; timeUnit: DurationInputArg2 }[]
  canSelectMinuteSpecific: boolean
}

export interface DateRangeFilterPaneProps {
  config: DateRangeConfig
  value?: DateRangeFilter
  onChange: (value: DateRangeFilter) => void
}

export interface DateRangeFilterPaneState {}

export default class DateRangeFilterPane extends React.Component<
  DateRangeFilterPaneProps,
  DateRangeFilterPaneState
> {
  constructor(props: DateRangeFilterPaneProps) {
    super(props)

    this.state = {}
  }

  decomposeValue = (value?: DateRangeFilter) => {
    if (value) {
      return {
        to: value.to || null,
        from: value.from || null,
        quickSelected: value.quickSelected || undefined,
      }
    } else {
      return {
        to: null,
        from: null,
        quickSelected: undefined,
      }
    }
  }

  getLimits = (to: Moment | null, from: Moment | null) => {
    const { config } = this.props
    const [toMax, toMin, fromMax, fromMin] = [
      from ? from.clone().add(config.restrictNumberOfDays, 'days') : undefined,
      from
        ? from.clone().subtract(config.restrictNumberOfDays, 'days')
        : undefined,
      to ? to.clone().add(config.restrictNumberOfDays, 'days') : undefined,
      to ? to.clone().subtract(config.restrictNumberOfDays, 'days') : undefined,
    ]
    return {
      toMax,
      toMin,
      fromMax,
      fromMin,
    }
  }

  quickSelectOnClick(value: number, timeUnit: DurationInputArg2) {
    const { onChange, config } = this.props
    const { canSelectMinuteSpecific } = config
    const now = canSelectMinuteSpecific
      ? moment.utc()
      : getClosestTenMinute(moment.utc())
    const then = canSelectMinuteSpecific
      ? moment.utc().subtract(value, timeUnit)
      : getClosestTenMinute(moment.utc()).subtract(value, timeUnit)
    onChange({
      from: now,
      to: then,
      quickSelected: value,
    })
  }

  public render() {
    const { config, value, onChange } = this.props
    const { show, selections, canSelectMinuteSpecific } = config
    const { to, from, quickSelected } = this.decomposeValue(value)
    const { toMax, toMin, fromMax, fromMin } = this.getLimits(to, from)
    if (show) {
      return (
        <FilterPane title='Date range'>
          <Styling>
            {selections.length > 0 && (
              <React.Fragment>
                <label>Quick Select</label>
                <div className='quick-selection-buttons'>
                  {selections.map((selection) => {
                    return (
                      <McButton
                        label={`${selection.value} ${selection.timeUnit}`}
                        appearance={
                          quickSelected === selection.value
                            ? 'primary'
                            : 'neutral'
                        }
                        id={`quick-selection-button-${selection.value}`}
                        key={`quick-selection-button-${selection.value}`}
                        type='button'
                        click={() =>
                          this.quickSelectOnClick(
                            selection.value,
                            selection.timeUnit,
                          )
                        }
                      />
                    )
                  })}
                </div>
              </React.Fragment>
            )}
            <label>Date Period UTC</label>
            <DateTimeInput
              id='date-range-filter-to'
              selected={to || null}
              onChange={(moment) => {
                onChange({
                  to: moment,
                  from: from || undefined,
                  quickSelected: undefined,
                })
              }}
              maxDate={setMaxDateAsToday(toMax)}
              minDate={toMin}
              minuteSpecific={canSelectMinuteSpecific}
            />
            <DateTimeInput
              id='date-range-filter-from'
              selected={from || null}
              onChange={(moment) => {
                onChange({
                  to: to || undefined,
                  from: moment,
                  quickSelected: undefined,
                })
              }}
              maxDate={setMaxDateAsToday(fromMax)}
              minDate={fromMin}
              minuteSpecific={canSelectMinuteSpecific}
            />
          </Styling>
        </FilterPane>
      )
    } else {
      return null
    }
  }
}
