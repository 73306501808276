import { useContext, useRef, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import {
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  type Row,
  type SortingState,
  useReactTable,
} from '@tanstack/react-table'

import type { UserEntry } from '../../../api-models/performance/fuel-oil-stock'
import { VesselPageContext } from '../../../contexts'
import { getFuelOilUserEntries } from '../../../services/performance'
import { columns, entryModals } from './helpers'
import FuelAndStockFilters from './FuelAndStockFilters'
import { Loading } from '../../../commons'
import Table, { type ITableRef } from '../../../components/Table/Table'

const FuelAndStock = () => {
  const imoNo = useContext(VesselPageContext).imoNo!

  const [sorting, setSorting] = useState<SortingState>([
    { desc: true, id: 'timestamp' },
  ])
  const [selectedUserEntry, setSelectedUserEntry] = useState<UserEntry>()

  const tableRef = useRef<ITableRef>(null)

  const { isLoading, isSuccess, data, refetch } = useQuery(
    ['userEntries', imoNo],
    () => getFuelOilUserEntries(imoNo),
  )

  const table = useReactTable({
    data: data || [],
    columns,
    initialState: {
      pagination: { pageSize: 25 },
    },
    state: { sorting },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  })

  const handleRowClick = (row: Row<UserEntry>) =>
    setSelectedUserEntry(row.original)

  const handleModalClosed = () => {
    void refetch()
    setSelectedUserEntry(undefined)
    tableRef.current?.deselectRow()
  }

  const EntryModal = selectedUserEntry && entryModals[selectedUserEntry.type]

  if (isLoading) return <Loading />
  if (!isSuccess) return null

  return (
    <>
      <FuelAndStockFilters table={table} />
      <Table table={table} onRowSelect={handleRowClick} ref={tableRef} />
      {EntryModal && (
        <EntryModal
          entryId={selectedUserEntry.id}
          closeHandler={handleModalClosed}
          submit={handleModalClosed}
          userEntryType={selectedUserEntry.type}
          readonly={selectedUserEntry.readonly}
        />
      )}
    </>
  )
}

export default FuelAndStock
