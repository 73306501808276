import React from 'react'
import styled from 'styled-components'

import { grey, maerskBlue } from '../../../../theme'

interface Props extends React.HTMLAttributes<HTMLElement> {
  activeKey: number
  showOrder?: boolean
  isActive?: boolean
  disabled?: boolean
  activeColor: string
  inactiveColor: string
}

const Component = styled.div<Partial<Props>>`
  display: flex;
  align-items: center;
  padding: 8px 12px;
  background-color: ${({ isActive, disabled, activeColor, inactiveColor }) => {
    if (disabled) {
      return grey[300]
    }

    return isActive ? activeColor : inactiveColor
  }};
  border-radius: 3px;
  margin-bottom: 8px;

  &:hover {
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  }
`

const Title = styled.div`
  flex: 1 1 auto;
  color: #ffffff;
  font-size: 12px;
`

const Indicator = styled.div<Partial<Props>>`
  background-color: #ffffff;
  padding: 4px 8px;
  text-align: center;
  border-radius: 100px;
  font-size: 12px;
  color: ${({ isActive }) => (isActive ? maerskBlue[500] : grey[300])};
  margin-right: 8px;
`

const Toggler = styled.div`
  color: #ffffff;
  font-size: 16px;
`

/**
 * Toggle
 * @description Toggle component is supposed to be used with an accordion,
 * this is the bar a user will see and is able to click to expand. The component inherits the
 * default React HTMLAttributes like click and styles etc.
 *
 * @param {number} activeKey The related index number it is located at in the accordion.
 * @param {boolean} isActive Determines whether the toggle is background is blue to indicate active state.
 * @param {boolean} showOrder Determines whether the number should be shown to the side, as the accordion could be in a series of steps.
 * @param {boolean} disabled Determines whether the toggle bar is grey or maersk blue, often used with not being the active index in the accordion.
 * @param {boolean} activeColor Determines color of active accordion items.
 * @param {boolean} inactiveColor Determines color of inactive accordion items.
 */
const Toggle = ({
  children,
  isActive,
  showOrder,
  activeKey,
  activeColor,
  inactiveColor,
  ...props
}: Props) => {
  return (
    <Component
      isActive={isActive}
      activeColor={activeColor}
      inactiveColor={inactiveColor}
      {...props}
    >
      {showOrder && <Indicator>{activeKey + 1}</Indicator>}
      <Title>{children}</Title>
      <Toggler>{isActive ? '+' : '-'}</Toggler>
    </Component>
  )
}

export default Toggle
