import React, { useCallback, useEffect, useReducer } from 'react'

import {
  FuelConsumptionContext as Context,
  FuelConsumptionReducer,
  initialState,
  resetConsumption as reset,
  setLosses,
  setCorrections,
} from '.'
import {
  BatchMonitor,
  FuelConsumptionStatusResponse as DataLossResponse,
  FuelConsumptionStatusResponse,
  Monitor,
} from '../../api-models/performance/fuel-consumption'
import {
  getFuelConsumptionBatchMonitor,
  getFuelConsumptionMonitor,
  getFuelConsumptionStatus,
  getManualCorrectionUserEntries,
} from '../../services/performance'
import { displayErrorModal, getDefaultFilters } from '../../utils'
import { ManualCorrections } from '../../api-models/performance/manual-corrections'
import { QueryPeriod } from '../../api-models/performance/common'
import {
  setConsumptionState,
  setNotifications,
  setMonitorBatch,
  setMonitorOil,
} from './actions'
import {
  getDateRange,
  getFiltersFromQueryParams,
} from '../../features/filter/utils'

type ProviderProps = {
  initialize?: boolean
  imoNo: string | undefined
  children?: React.ReactNode
}

/**
 * FuelConsumptionProvider
 * @description Fuel Consumption Provider, provides a tool kit fot handling fetching Consumption(TBD), Losses, Corrections.
 *
 * @param {boolean} initialize (default: false) The initialize flag tells the provider weather it should fetch the data once rendered.
 */
export const FuelConsumptionProvider = ({
  children,
  initialize = false,
  imoNo,
}: ProviderProps) => {
  const [consumption, dispatch] = useReducer(
    FuelConsumptionReducer,
    initialState,
  )

  const resetConsumption = useCallback(() => dispatch(reset()), [])

  const getStatus =
    useCallback(async (): Promise<FuelConsumptionStatusResponse | null> => {
      let response: FuelConsumptionStatusResponse | null = null

      if (!imoNo) {
        return response
      }

      try {
        response = await getFuelConsumptionStatus(imoNo)
        dispatch(setConsumptionState(response.state))
        dispatch(setNotifications(response.notifications))
        if (response.hasDataLoss) {
          dispatch(setLosses(response.fuelLineDataLosses))
        }
      } catch (err) {
        displayErrorModal({
          statusText: 'Failed to fetch fuel consumption status',
          message:
            'An error ocured while fetching fuel status, you will not see any data loses if any ocured.',
        })
      }
      return null
    }, [imoNo])

  const getCorrections = useCallback(
    async (period: QueryPeriod): Promise<ManualCorrections | null> => {
      let response: ManualCorrections | null = null

      if (!imoNo) {
        return response
      }

      try {
        response = await getManualCorrectionUserEntries(imoNo, period)
        dispatch(setCorrections(response))
      } catch (err) {
        displayErrorModal({
          statusText: 'Failed to fetch manual corrections',
          message:
            'An error ocured while fetching manual corrections information, you will not see any corrections that might have been reported.',
        })
      }

      return response
    },
    [imoNo],
  )

  const getBatchMonitor = useCallback(
    async (period: QueryPeriod): Promise<BatchMonitor | null> => {
      let response: BatchMonitor | null = null

      if (!imoNo) {
        return response
      }
      dispatch(setMonitorBatch(undefined))

      try {
        response = await getFuelConsumptionBatchMonitor(imoNo, period)
        dispatch(setMonitorBatch(response))
      } catch (error) {
        displayErrorModal({
          statusText: 'Failed to fetch',
          message: 'Failed to fetch fuel consomption batch monitor',
        })
      }

      return response
    },
    [imoNo],
  )
  const getOilMonitor = useCallback(
    async (period: QueryPeriod): Promise<Monitor | null> => {
      let response: Monitor | null = null

      if (!imoNo) {
        return response
      }
      dispatch(setMonitorOil(undefined))

      try {
        response = await getFuelConsumptionMonitor(imoNo, period)
        dispatch(setMonitorOil(response))
      } catch (error) {
        displayErrorModal({
          statusText: 'Failed to fetch',
          message: 'Failed to fetch fuel consomption oil monitor',
        })
      }

      return response
    },
    [imoNo],
  )

  const refreshFuelConsumption = useCallback(
    (
      period?: QueryPeriod,
    ): Promise<
      [
        DataLossResponse | null,
        ManualCorrections | null,
        BatchMonitor | null,
        Monitor | null,
      ]
    > => {
      const filters = getFiltersFromQueryParams()
      const queryPeriod: QueryPeriod = period
        ? period
        : getDateRange(getDefaultFilters(10, 'd').dateRange, filters)

      return Promise.all([
        getStatus(),
        getCorrections(queryPeriod),
        getBatchMonitor(queryPeriod),
        getOilMonitor(queryPeriod),
      ])
    },
    [getBatchMonitor, getCorrections, getStatus, getOilMonitor],
  )

  useEffect(() => {
    if (!imoNo || !initialize) {
      return
    }

    refreshFuelConsumption()
  }, [imoNo, initialize, refreshFuelConsumption])

  return (
    <Context.Provider
      value={{
        imoNo,
        consumption,
        resetConsumption,
        getStatus,
        getCorrections,
        getBatchMonitor,
        getOilMonitor,
        refreshFuelConsumption,
      }}
    >
      {children}
    </Context.Provider>
  )
}
