import { useMemo, useRef, useState } from 'react'
import {
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  type Row,
  type SortingState,
  useReactTable,
} from '@tanstack/react-table'

import { type Sensors } from '../../../../api-models/sensors'
import { PadContent } from '../../../../layout/styles'
import NoData from '../../../../components/NoData'

import Table, { type ITableRef } from '../../../../components/Table/Table'
import { columns } from './helpers'
import SensorDetails from '../../sensor-details/SensorDetails'

type Props = {
  errors: Array<Sensors.SensorResponse | Sensors.ConnectorErrorResponse>
}

const CurrentErrors = ({ errors }: Props) => {
  const [sorting, setSorting] = useState<SortingState>([
    { desc: false, id: 'name' },
  ])
  const [clickedSensor, setClickedSensor] = useState<Sensors.SensorResponse>()

  const tableRef = useRef<ITableRef>(null)

  const data = useMemo(
    () =>
      errors.map((error) => ({
        ...error,
        feedback: 'danger',
      })),
    [errors],
  )

  const table = useReactTable({
    data,
    columns,
    initialState: {
      pagination: { pageSize: 25 },
    },
    state: { sorting },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  })

  const handleRowClick = (row: Row<Sensors.SensorResponse>) => {
    setClickedSensor(row.original)
  }

  const handleModalClosed = () => {
    setClickedSensor(undefined)
    tableRef.current?.deselectRow()
  }

  return (
    <PadContent>
      {(data.length === 0 && (
        <NoData>No sensor or sub-system errors have been detected.</NoData>
      )) || (
        <>
          <Table table={table} onRowSelect={handleRowClick} ref={tableRef} />
          {clickedSensor && (
            <SensorDetails
              sensor={clickedSensor}
              sensorError={clickedSensor}
              onCancel={handleModalClosed}
            />
          )}
        </>
      )}
    </PadContent>
  )
}

export default CurrentErrors
