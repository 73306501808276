import { useContext, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { McTab, McTabBar } from '@maersk-global/mds-react-wrapper'

import { VesselPageContext, WindowContext } from '../contexts'
import { type Sensors } from '../api-models/sensors'
import {
  AllSensorsTable,
  ConnectorErrorBar,
  CurrentErrorsTable,
} from '../features/sensors'
import { ContentCard, Loading } from '../commons'
import ErrorCount from '../components/ErrorCount'
import TemNotifications from '../components/TemNotifications/TemNotifications'
import PerformanceAlerts from '../components/PerformanceAlerts/PerformanceAlerts'
import { ContentLayout } from '../layout'
import { getAllSensors, getCurrentErrors } from '../services/sensors'

const SensorsPage = () => {
  const imoNo = useContext(VesselPageContext).imoNo!
  const { windowSize } = useContext(WindowContext)

  const [tabIndex, setTabIndex] = useState(0)

  const errorsQuery = useQuery(
    ['currentErrors', imoNo],
    () => getCurrentErrors(imoNo!),
    { staleTime: 60000 },
  )

  const sensorsQuery = useQuery(
    ['allSensors', imoNo],
    () => getAllSensors(imoNo),
    { staleTime: 60000 },
  )

  const handleTabChange = ({ detail }: CustomEvent<number>) => {
    setTabIndex(detail)
  }

  const sensorErrorCount = errorsQuery.data?.sensorErrors.length ?? 0

  return (
    <ContentLayout header='Sensors' featureId='sensors'>
      {!!errorsQuery.data?.connectorErrors.length &&
        errorsQuery.data.connectorErrors.map(
          (connectorError: Sensors.ConnectorErrorResponse) => (
            <ConnectorErrorBar
              key={connectorError.name}
              connectorError={connectorError}
            />
          ),
        )}
      <TemNotifications />
      <PerformanceAlerts />
      <ContentCard
        id='sensors'
        title='Sensor status'
        helpTextKey='sensors/sensors'
      >
        <McTabBar
          currentindex={tabIndex}
          fit={windowSize}
          tabchange={handleTabChange}
        >
          <McTab slot='tab' label='Current errors'>
            {sensorErrorCount > 0 && (
              <ErrorCount slot='suffix'>{sensorErrorCount}</ErrorCount>
            )}
          </McTab>
          <div slot='panel'>
            {(errorsQuery.isLoading && <Loading />) ||
              (errorsQuery.isSuccess && (
                <CurrentErrorsTable
                  errors={[
                    ...errorsQuery.data.connectorErrors,
                    ...errorsQuery.data.sensorErrors,
                  ]}
                />
              ))}
          </div>
          <McTab slot='tab' label='All sensors' data-e2e='allSensorTab' />
          <div slot='panel'>
            {((sensorsQuery.isLoading || errorsQuery.isLoading) && (
              <Loading />
            )) ||
              (sensorsQuery.isSuccess && errorsQuery.isSuccess && (
                <AllSensorsTable
                  sensors={sensorsQuery.data.sensors}
                  sensorErrors={errorsQuery.data.sensorErrors.concat(
                    errorsQuery.data.connectorErrors.reduce(
                      (
                        acc: Array<Sensors.SensorResponse>,
                        err: Sensors.ConnectorErrorResponse,
                      ) => [...acc, ...err.sensorsAffected],
                      [],
                    ),
                  )}
                />
              ))}
          </div>
        </McTabBar>
      </ContentCard>
    </ContentLayout>
  )
}

export default SensorsPage
