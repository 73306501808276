import { Chart } from '../../utils'

export function connectChartsLineScatter(
  timelineChart: Chart,
  scatterChart: Chart,
  chartData: Array<Array<number | undefined>>,
) {
  scatterChart.on('hidetip', (event: any) => {
    const action = timelineChart.makeActionFromEvent(event)
    timelineChart.dispatchAction(
      { ...action, tooltipOption: { show: true } },
      true,
    )
  })

  timelineChart.on('hidetip', (event: any) => {
    const action = scatterChart.makeActionFromEvent(event)
    scatterChart.dispatchAction(
      { ...action, tooltipOption: { show: true } },
      true,
    )
  })
  timelineChart.on('updateAxisPointer', (event: any) => {
    scatterChart.dispatchAction({
      type: 'downplay',
      seriesName: ['Mix', 'AE1', 'AE2', 'AE3', 'AE4', 'AE5'],
    })

    if (event.axesInfo.length === 1) {
      scatterChart.dispatchAction(
        {
          type: 'highlight',
          seriesName: ['Mix', 'AE1', 'AE2', 'AE3', 'AE4', 'AE5'],
          dataIndex: event.dataIndex,
        },
        true,
      )

      let seriesIndex = chartData[event.dataIndex][0]
        ? 0
        : chartData[event.dataIndex].findIndex(
            (val: any) => typeof val === 'number',
          )

      scatterChart.dispatchAction(
        {
          type: 'showTip',
          seriesIndex: seriesIndex,
          dataIndex: event.dataIndex,
        },
        true,
      )
    }
  })

  scatterChart.on('mouseover', (event: any) => {
    let seriesIndex = chartData[event.dataIndex][0]
      ? 0
      : chartData[event.dataIndex].findIndex(
          (val: number | undefined) => typeof val === 'number',
        )
    const eventObj = {
      axesInfo: [
        {
          axisDim: 'x',
          axisIndex: 0,
          value: chartData[event.dataIndex][12],
        },
      ],
      dataIndex: event.dataIndex,
      dataIndexInside: event.dataIndex,
      seriesIndex: seriesIndex,
      type: 'updateaxispointer',
    }
    const action = timelineChart.makeActionFromEvent(eventObj)
    timelineChart.dispatchAction(
      { ...action, tooltipOption: { show: true } },
      true,
    )
  })
}

/*
  Something like this might be needed in near future, highlights scatter chart datapoints inside zoom of timeline chart
*/
// export function connectZoomAndHighlight(
//   timelineChart: Chart,
//   scatterChart: Chart
// ) {
//   timelineChart.on("dataZoom", (params: any) => {
//     if (params.batch) {
//       scatterChart.dispatchAction(
//         {
//           type: "highlight",
//           seriesName: ["AE1", "AE2", "AE3", "AE4", "AE5", "Mix"],
//           dataIndex: [0, 1, 2, 3, 4, 5, 4000, 4001, 4002, 40003, 4004],
//           tooltipOption: { show: false }
//         },
//         true
//       );
//     } else {
//       scatterChart.dispatchAction({
//         type: "downplay",
//         seriesName: ["AE1", "AE2", "AE3", "AE4", "AE5", "Mix"]
//       });
//     }
//   });
// }
