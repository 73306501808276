import styled from 'styled-components'

export const PodiumContainer = styled.div`
  display: flex;
  gap: 16px;
  justify-content: center;
  width: 352px;

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;

    > mc-icon {
      transform: translateY(8px) scale(1.4);
    }
  }
`

export const PerformanceBar = styled.div<{ height: string }>`
  height: ${(props) => props.height};
  width: 40px;
  background-color: var(--mds_brand_appearance_primary_weak_background-color);
  border-radius: 4px;
`
