import React, { FunctionComponent } from 'react'
import { FancyLabel, ValueLabel } from '../../../commons'
import { mapFuelTypeToColorScheme } from '../mappers'
import { mapFuelLineTypeToName } from '../../../utils/mappers/fdl4-mappers'
import { formatValue } from '../../../utils'
import {
  getDecimalsFromUnit,
  convertToFuelLineUnit,
} from '../utils/fuel-consumption'
import { whiteColorScheme } from '../../../theme'
import { Performance } from '../../../api-models'

interface FuelConsumptionBlockProps {
  fuelLine: Performance.Status.FuelLine | undefined
  unit: string
  fuelLineType: number
}

export const FuelConsumptionBlock: FunctionComponent<
  FuelConsumptionBlockProps
> = ({ fuelLine, fuelLineType, unit }) => {
  return fuelLine ? (
    <React.Fragment>
      <FancyLabel
        colorScheme={mapFuelTypeToColorScheme(fuelLine.fuelType)}
        value={true}
        style={{ cursor: 'unset' }}
      >
        {mapFuelLineTypeToName(fuelLine.fuelLineType)}
      </FancyLabel>
      <ValueLabel unit={unit}>
        {formatValue(
          convertToFuelLineUnit(fuelLine.consumption, fuelLine.fuelLineType),
          getDecimalsFromUnit(unit, false),
        )}
      </ValueLabel>
    </React.Fragment>
  ) : (
    <React.Fragment>
      <FancyLabel
        colorScheme={whiteColorScheme}
        value={true}
        style={{ cursor: 'unset' }}
      >
        {mapFuelLineTypeToName(fuelLineType)}
      </FancyLabel>
      <ValueLabel unit={unit}>
        {formatValue(null, getDecimalsFromUnit(unit, false))}
      </ValueLabel>
    </React.Fragment>
  )
}
