import * as React from 'react'
import styled, {
  grey,
  errorTheme,
  warningTheme,
  IThemeInterface,
  inputDefault,
} from '../theme'

const InputFieldWrapper = styled.div<{
  width: string | number | undefined
  height: string | number | undefined
  theme: IThemeInterface
}>`
  position: relative;
  height: ${(props) => (props.height ? props.height : '34px')};
  width: ${(props) => props.width};
  input {
    height: 100%;
    width: calc(100% - 0px);
    border-radius: 4px;
    border: 1px solid ${(props) => props.theme.border};
    box-sizing: border-box;
    padding-left: 8px;
  }

  input.number {
    text-align: right;
  }

  .input-span {
    position: absolute;
    right: 0;
    top: 0;
    border: 1px solid ${(props) => props.theme.border};
    background-color: ${(props) => props.theme.bg};
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    height: calc(100% - 2px);
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${(props) => props.theme.fg};
    pointer-events: none;
    font-size: 12px;
  }

  /* stylelint-disable */
  input.number::-webkit-inner-spin-button,
  input.number::-webkit-outer-spin-button {
    appearance: none;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }
  /* stylelint-enable */

  input::placeholder {
    color: ${grey[400]};
  }
`

interface InputFieldProps extends React.InputHTMLAttributes<any> {
  error?: boolean
  warning?: boolean
  className?: string
  step?: string
  min?: string
  pattern?: string
}

/**
 * This component is made in response to this ticket:
 * https://maersk-tl-conves.visualstudio.com/Connected%20Vesssel/_sprints/taskboard/Vessel%20Team/Connected%20Vesssel/Sprint%2054?workitem=48495
 */

export class InputField extends React.Component<InputFieldProps, any> {
  render() {
    const {
      className,
      placeholder,
      style,
      width,
      error,
      warning,
      height,
      type,
      pattern,
      step,
      min,
      value,
      ...restProps
    } = this.props

    let theme = inputDefault
    if (error) theme = errorTheme
    else if (warning) theme = warningTheme
    return (
      <InputFieldWrapper width={width} height={height} theme={theme}>
        <input
          placeholder={placeholder || '0.00'}
          style={{ ...style }}
          type={type || 'text'}
          value={value ?? ''}
          {...restProps}
          className={`${className} ${type === 'number' ? 'number' : ''}`}
          step={step ?? ''}
          min={min ?? ''}
        />
      </InputFieldWrapper>
    )
  }
}
