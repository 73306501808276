import { useFormikContext } from 'formik'

import { Row, Ruler } from '../MalfunctioningEquipmentReportPage.styles'
import { booleanOptions } from '../MalfunctioningEquipmentReportPage.utils'
import MfeInputRadioGroup from '../MfeInputRadioGroup/MfeInputRadioGroup'
import MfeInputNumber from '../MfeInputNumber/MfeInputNumber'
import { PadContent } from '../../../../layout/styles'

const ImpactCargoCapacity = () => {
  const { values: report, setFieldValue } =
    useFormikContext<GandalfApi.MfeReport>()

  const handleReeferCargoCapacityImpactChange = (hasImpact: boolean) => {
    if (!hasImpact) {
      void setFieldValue(
        'data.impact.cargoCapacity.electricalProductionReduction',
        null,
      )
      void setFieldValue('data.impact.cargoCapacity.maxReeferCapacity', null)
    }
  }

  return (
    <PadContent>
      <h3>Impact on cargo capacity</h3>
      <Row>
        <MfeInputRadioGroup<boolean>
          name='data.impact.cargoCapacity.hasCapacityChange'
          label='Is there impact on the reefer cargo capacity?'
          options={booleanOptions}
          onChange={handleReeferCargoCapacityImpactChange}
        />
        {report.data.impact.cargoCapacity.hasCapacityChange && (
          <>
            <MfeInputNumber
              name='data.impact.cargoCapacity.electricalProductionReduction'
              label='Reduced electrical production'
              unit='kW'
            />
            <MfeInputNumber
              name='data.impact.cargoCapacity.maxReeferCapacity'
              label='New maximum reefer capacity'
              unit='TEU'
            />
          </>
        )}
      </Row>
      <Ruler />
    </PadContent>
  )
}

export default ImpactCargoCapacity
