import moment from 'moment/moment'

export const totalInMinutes = (shorePowerPeriods) =>
  shorePowerPeriods.periods.reduce((accumulator, period) => {
    const hourMinArray: Array<string> = period.totalHoursMinutes.split(':')
    const hourToMin: number = Number(hourMinArray[0])
      ? Number(hourMinArray[0]) * 60
      : 0
    const minute: number = Number(hourMinArray[1]) ? Number(hourMinArray[1]) : 0
    return accumulator + period.totalDays * 24 * 60 + hourToMin + minute
  }, 0)
export const grandTotalEnergy = (shorePowerPeriods) =>
  shorePowerPeriods.periods.reduce(
    (accumulator, period) => accumulator + period.totalEnergy,
    0,
  )
export const totalCalculator = (shorePowerPeriods) => {
  const duration = moment.duration(totalInMinutes(shorePowerPeriods), 'minutes')
  shorePowerPeriods.grandTotalDays = Math.floor(duration.asDays())
  shorePowerPeriods.grandTotalHoursMinutes =
    Math.floor(duration.asHours() % 24) +
    ':' +
    Math.floor(duration.asMinutes() % 60)
  shorePowerPeriods.grandTotalEnergy = grandTotalEnergy(shorePowerPeriods)
}
