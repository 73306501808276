import styled from 'styled-components'

export const VesselNameCell = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  > span {
    margin-left: 8px;
  }
`
