import { useVesselBasicInfo } from '../../queries/MasterDataApi/MasterDataApi'
import { useVesselConfiguration } from '../../hooks'
import { ExportDataForm } from '../../features/export-data'
import { VesselSettingsContainer } from '../../features/vessel-settings'

type Props = {
  imoNo: string
  showDataExportModal: boolean
  setShowDataExportModal: (show: boolean) => void
  showVesselSettings: boolean
  setShowVesselSettings: (show: boolean) => void
}

const VesselOnly = (props: Props) => {
  const vesselBasicInfo = useVesselBasicInfo(props.imoNo)
  const vesselConfiguration = useVesselConfiguration(props.imoNo)

  return (
    <>
      {vesselBasicInfo.isSuccess && (
        <ExportDataForm
          visible={props.showDataExportModal}
          closeHandler={() => props.setShowDataExportModal(false)}
          imoNo={props.imoNo}
          vesselMetadata={vesselBasicInfo.data}
        />
      )}
      {vesselConfiguration &&
        vesselBasicInfo.isSuccess &&
        props.showVesselSettings && (
          <VesselSettingsContainer
            imoNo={props.imoNo}
            vesselMetadata={vesselBasicInfo.data}
            closeHandler={() => props.setShowVesselSettings(false)}
            configuration={vesselConfiguration}
          />
        )}
    </>
  )
}

export default VesselOnly
