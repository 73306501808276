import moment, { type Moment } from 'moment'

export const DATE_FORMAT = 'YYYY-MM-DD'
export const TIME_FORMAT = 'HH:mm'
export const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`
export const MONTH_YEAR_DATE_FORMAT = 'MMM YYYY'
export const MONTH_YEAR_DATE_FORMAT_FULL = 'MMMM YYYY'

export const getInternalValues = (
  min?: string | Moment,
  max?: string | Moment,
  isoDate?: string, // ISO 8601 date string
): InputDateTime.InternalValues => {
  return {
    min: min ? moment.utc(min).format(DATE_FORMAT) : '',
    max: max ? moment.utc(max).format(DATE_FORMAT) : '',
    date: isoDate ? moment.utc(isoDate).format(DATE_FORMAT) : '',
    time: isoDate ? moment.utc(isoDate).format(TIME_FORMAT) : '',
  }
}
