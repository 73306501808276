import styled, { layoutSizes, sizes } from '../../theme'

export const Wrapper = styled.div`
  #fuelConsumption {
    margin-right: ${sizes.spacing}px;
  }

  .cards {
    width: ${layoutSizes.full};
    display: flex;
    flex-wrap: wrap;
  }

  .row {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: ${sizes.spacing}px;
  }

  .column {
    display: flex;
    flex-direction: column;
  }

  .three-quarters {
    width: calc(75% - 4px);
  }

  .full {
    width: calc(100%);
  }

  .half {
    width: calc(50% - 8px);
  }

  .quarter {
    width: calc(25% - 12px);
  }

  .left {
    margin-right: ${sizes.spacing}px;
  }

  .top {
    margin-bottom: ${sizes.spacing}px;
  }

  .stock-on-board-light {
    display: flex;
    flex-direction: column;
  }
`

export const FooterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export const DialContainer = styled.div<{ hasTwoMainEngines: boolean }>`
  display: flex;
  height: ${(props) => (props.hasTwoMainEngines ? '200px' : '172px')};
  justify-content: center;
  align-items: center;
`
