import type { Table } from '@tanstack/react-table'

export const getEquipmentOptions = (table: Table<GandalfApi.MfeReport>) => {
  const equipmentNames = table
    .getCoreRowModel()
    .rows.map((row) => row.original.data.equipment)
    .filter((equipment) => equipment !== null) as Array<string>

  return Array.from(new Set(equipmentNames)).map((equipment) => ({
    label: equipment,
    value: equipment,
  }))
}

export const getMeStatusOptions = (table: Table<GandalfApi.MfeReport>) => {
  const meStatusNames = table
    .getCoreRowModel()
    .rows.map((row) => row.original.data.meStatus)
    .filter((meStatus) => meStatus !== null) as Array<string>

  return Array.from(new Set(meStatusNames)).map((meStatus) => ({
    label: meStatus,
    value: meStatus,
  }))
}
