import React from 'react'
import imgLossForm from '../../../../assets/images/help-section/lube-loss-form.png'
import imgLossReasons from '../../../../assets/images/help-section/lube-loss-reasons.png'

export const Bunkering = React.forwardRef<HTMLDivElement>((props, ref) => (
  <div ref={ref} id='help-section-subtopic'>
    <h4>In this guide you will learn</h4>
    <ul>
      <li>How to add a Bunkering event</li>
      <li>How to add a Transfer event</li>
      <li>How to add a Loss event</li>
    </ul>
    <h4>Purpose</h4>
    <p>
      The purpose of this guide is to explain the required steps for adding
      Bunkering, Transfer and Loss events. For adding a Sounding event refer to
      the Sounding guide.
    </p>
    <h4>Bunkering - Transfer - Loss dialog boxes </h4>
    <p>
      The same type of events cannot have identical timestamps. E.g., if
      bunkering finished for both CLO and ME oils at 10:00, then CLO can be
      10:00 and ME must then be entered as 10:10.
    </p>
    <p className='title'>Bunkering</p>
    <p>
      To create a Bunkering event, select the “+ Bunkering” button at the top of
      the Lube Management module.
    </p>
    <p>Required fields to fill in:</p>
    <ul>
      <li>Date/time</li>
      <li>Oil type</li>
      <li>TBN</li>
      <li>Port</li>
      <li>Order ID</li>
      <li>Quantity per C/E</li>
    </ul>
    <p>Optional fields to fill in:</p>
    <ul>
      <li>Quantity ordered</li>
      <li>Quantity agreed</li>
      <li>Comment</li>
    </ul>
    <p className='note'>
      Note: If valuable for reference, please add these fields.
    </p>
    <p className='title'>Transfer</p>
    <p>
      To create a Transfer event, select the “+ Transfer” button at the top of
      the Lube Management module.
    </p>
    <p>
      This function is only for{' '}
      <span className='note'>
        Main Engine & Auxiliary Engine System Oil categories.
      </span>{' '}
      This function allows you and shore users to keep track of transferred oil
      amounts in the two systems.
    </p>
    <p>Required fields to fill in:</p>
    <ul>
      <li>Date/time</li>
      <li>Oil type</li>
      <li>Quantity</li>
      <li>From</li>
      <li>To</li>
    </ul>
    <p>Optional fields to fill in:</p>
    <ul>
      <li>Comment</li>
    </ul>
    <p>
      There are multiple combinations in the FROM/TO dropdown menus. If the
      chosen combination is not valid a notification will appear. Referring to
      the example above; where FROM is “ME, SPARE DIRTY” and TO is “BOB” is used
      for a vessel where they are blending the CLO on-board.
    </p>
    <p>
      BOB vessels will have to update the transferred amount from “ME, SPARE
      DIRTY” to “BOB” before reporting the FULL sounding for Month End Lube Oil
      Report.
    </p>
    <p className='title'>Loss</p>
    <p>
      To create a Loss event, select the “+ Loss” button at the top of the Lube
      Management module.
    </p>
    <p>
      This function enables the reporting of loss on the different oil types
      with reason code and supporting notes as required. This data is also used
      for tracking on shore side.
    </p>
    <div className='image-container'>
      <img src={imgLossForm} alt='Loss form' />
      <img src={imgLossReasons} alt='Loss reasons' />
    </div>
    <p>Required fields to fill in:</p>
    <ul>
      <li>Date/time</li>
      <li>Oil type</li>
      <li>Reason</li>
      <li>Quantity</li>
    </ul>
    <p>Optional fields to fill in:</p>
    <ul>
      <li>Comment</li>
    </ul>
    <p>
      Referring to the example above, the reason codes that can be chosen are as
      shown.
    </p>
  </div>
))
