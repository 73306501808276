import styled from 'styled-components'

export const Wrapper = styled.div`
  h4 {
    margin-top: 64px !important;
  }

  h4:first-child {
    margin-top: 24px !important;
  }

  h5 {
    margin-bottom: -8px !important;
  }

  p {
    white-space: break-spaces;
    max-width: 800px;
  }
`

export const PaginationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  margin-top: 48px;
`
