import styled from 'styled-components'

export const FormNotificationsWrapper = styled.div`
  margin: 20px 20px 0;
`

export const SummaryModalContentWrapper = styled.div`
  max-height: 700px;
  overflow: auto;
  padding: 0 20px;
  width: 1000px;

  ul:first-of-type {
    background-color: rgba(255, 255, 255, 1);
    position: sticky;
    top: 0;
    z-index: 2;
  }
`

export const TabWrapper = styled.div`
  margin: 20px 0px;
`

export const Wrapper = styled.div`
  position: relative;
`
