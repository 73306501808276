import { TDepth } from './styled'

/**
 * Shadows as defined by
 * [Maersk Design System]{@link https://designsystem.maersk.com/foundations/shadows/index.html}
 */
export const shadows: Record<TDepth, string> = {
  1: '0px 1px 3px rgba(0, 0, 0, 0.04), 0px 2px 1px -1px rgba(0, 0, 0, 0.03), 0px 1px 1px rgba(0, 0, 0, 0.03)',
  2: '0px 1px 5px rgba(0, 0, 0, 0.04), 0px 3px 1px -2px rgba(0, 0, 0, 0.03), 0px 2px 2px rgba(0, 0, 0, 0.03)',
  3: '0px 1px 8px rgba(0, 0, 0, 0.04), 0px 3px 3px -2px rgba(0, 0, 0, 0.03), 0px 3px 4px rgba(0, 0, 0, 0.03)',
  4: '0px 3px 5px -1px rgba(0, 0, 0, 0.04), 0px 1px 18px rgba(0, 0, 0, 0.03), 0px 6px 10px rgba(0, 0, 0, 0.03)',
  5: '0px 5px 5px -3px rgba(0, 0, 0, 0.04), 0px 3px 14px 2px rgba(0, 0, 0, 0.03), 0px 8px 10px 1px rgba(0, 0, 0, 0.03)',
  6: '0px 5px 6px -3px rgba(0, 0, 0, 0.04), 0px 3px 16px 2px rgba(0, 0, 0, 0.03), 0px 9px 12px 1px rgba(0, 0, 0, 0.03)',
  7: '0px 7px 8px -4px rgba(0, 0, 0, 0.04), 0px 5px 22px 4px rgba(0, 0, 0, 0.03), 0px 12px 14px 2px rgba(0, 0, 0, 0.03)',
  8: '0px 8px 10px -5px rgba(0, 0, 0, 0.04), 0px 6px 30px 5px rgba(0, 0, 0, 0.03), 0px 16px 24px 2px rgba(0, 0, 0, 0.03)',
  9: '0px 11px 15px -7px rgba(0, 0, 0, 0.04), 0px 9px 46px 8px rgba(0, 0, 0, 0.03), 0px 24px 38px 3px rgba(0, 0, 0, 0.03)',
}
