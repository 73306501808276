import React from 'react'
import { Field, Form, Formik } from 'formik'
import { McButton, McCheckbox } from '@maersk-global/mds-react-wrapper'
import styled, { info } from '../../../theme'
import { InfoBox, Modal, ModalContent, ModalControls } from '../../../commons'
import { displayErrorModal } from '../../../utils'
import { Hydra } from '../../../api-models/hydra'
import { UNITS } from '../../../utils/constants'
import EnergyManagementResultsTable from '../tables/EnergyManagementResultsTable'
import { inputEmpty } from '../../../utils/form-validators/formik-validators'
import {
  validateElectricalDemand,
  validateMainEnginePower,
  validatePropulsionPower,
} from '../validators'
import { Performance } from '../../../api-models'
import {
  getAuxEngParams,
  getMainEngMcr,
  getMaxElectricalPowerProduction,
  getTEMConfiguration,
} from '../utils'
import { AuxEngParams, MeOperationalMode, TEMConfiguration } from '../models'
import { InputField } from './InputField'
import { mapMeOperationalModeToString } from '../mapper'
import { getTEM } from '../../../services/hydra'

const Wrapper = styled.div`
  padding: 0 16px 0 16px;

  .row {
    display: flex;
    min-width: 650px;

    .heading {
      border-bottom: 1px solid ${(props) => props.theme.colors.grey[200]};
      font-size: 16px;
      font-weight: normal;
      margin-bottom: 8px;
    }

    .column {
      min-width: 335px;
    }

    .form-input {
      width: 244px;
    }

    .results {
      width: 355px;
      margin-bottom: 16px;
    }
  }

  .input-group-wrapper {
    width: fit-content;
    display: flex;
    flex-direction: column;
  }

  #calculate-btn {
    margin: 8px 0;
    align-self: flex-end;
  }

  #checkbox {
    display: flex;
    justify-content: flex-end;
  }
`

interface EnergyManagementSimulatorProps {
  onClose: () => void
  imoNo: string
  vesselConfiguration: Performance.VesselConfiguration.Configuration
}

interface EnergyManagementSimulatorState {
  loading: boolean
  data?: Hydra.TEM.EnergyManagement
  auxEngParams: AuxEngParams
  mainEng1Mcr: number
  maxElectricalPower: number
  meOperationalMode: MeOperationalMode
  temConfiguration: TEMConfiguration
  synchronousLoading: boolean
}

interface InitialValues {
  requiredPower?: number
  otherPower?: number
  seaWaterTemp?: number
}

export class EnergyManagementSimulator extends React.Component<
  EnergyManagementSimulatorProps,
  EnergyManagementSimulatorState
> {
  constructor(props: EnergyManagementSimulatorProps) {
    super(props)
    const { auxEngs } = props.vesselConfiguration
    const auxEngParams = getAuxEngParams(auxEngs)
    const temConfiguration = getTEMConfiguration(props.vesselConfiguration)
    this.state = {
      loading: false,
      auxEngParams: auxEngParams,
      mainEng1Mcr: getMainEngMcr(props.vesselConfiguration),
      maxElectricalPower: getMaxElectricalPowerProduction(
        props.vesselConfiguration,
        auxEngParams,
      ),
      meOperationalMode: MeOperationalMode.VARIABLE_POWER,
      temConfiguration: temConfiguration,
      synchronousLoading: true,
    }
  }

  calculateEnergyManagement(
    requiredPower: number,
    otherPower: number,
    seaWaterTemp: number | undefined, // seaWaterTemp only set on vessels that have whr
    setFieldError: (field: string, message?: string) => void,
  ) {
    const { imoNo } = this.props
    const { meOperationalMode, synchronousLoading } = this.state
    const meOperationalModeString =
      mapMeOperationalModeToString(meOperationalMode)
    this.setState({ loading: true })
    getTEM(
      imoNo,
      requiredPower,
      otherPower,
      meOperationalModeString,
      seaWaterTemp,
      synchronousLoading,
    )
      .then((res: Hydra.TEM.EnergyManagement) => {
        this.setState({
          loading: false,
          data: res,
        })
      })
      .catch((res: ResponseError) => {
        this.setState({
          loading: false,
          data: undefined,
        })
        if (res.statusCode === 400) {
          const body: Hydra.ValidationErrorResponseBody = res.body
          body.errors.forEach((error) =>
            error.paths.forEach((path) => setFieldError(path, error.message)),
          )
        } else {
          void displayErrorModal(res)
        }
      })
  }

  checkRangeWaterTemperature(seaWaterTemp?: number): string | undefined {
    if (!seaWaterTemp) return undefined
    if (seaWaterTemp < 0) {
      return 'The selected temperature is lower than the limit of the model. The advice shown is therefore based on 0°C.'
    }
    if (seaWaterTemp > 35) {
      return 'The selected temperature is higher than the limit of the model. The advice shown is therefore based on 35°C.'
    }
  }

  public render() {
    const { onClose, vesselConfiguration } = this.props
    const {
      loading,
      data,
      mainEng1Mcr,
      auxEngParams,
      maxElectricalPower,
      meOperationalMode,
      temConfiguration,
    } = this.state
    const { mainEng1, shaftMotor1, hasTwoMainEngines, shaftMotor2, whr } =
      vesselConfiguration
    return (
      <Formik
        initialValues={
          {
            requiredPower: undefined,
            otherPower: undefined,
            seaWaterTemp: undefined,
            synchronous: true,
          } as InitialValues
        }
        onSubmit={(values, { setFieldError }) => {
          const { requiredPower, otherPower, seaWaterTemp } = values
          if (requiredPower && otherPower) {
            this.calculateEnergyManagement(
              requiredPower,
              otherPower,
              seaWaterTemp,
              setFieldError,
            )
          }
        }}
      >
        {({ values }) => {
          const waterTemperatureInfo = this.checkRangeWaterTemperature(
            values.seaWaterTemp,
          )

          return (
            <Modal
              visible
              title='Energy management simulator'
              closeHandler={onClose}
              helpTextKey='energy-management-simulator'
            >
              <Form>
                <ModalContent>
                  <Wrapper>
                    <InfoBox theme={info}>
                      This tool is under active development, please send your
                      feedback to&nbsp;
                      <a
                        href='mailto: VITServicedesk@maersk.com'
                        style={{ cursor: 'pointer' }}
                      >
                        VITServicedesk@maersk.com
                      </a>
                    </InfoBox>
                    {!!waterTemperatureInfo && (
                      <div style={{ marginTop: '4px' }}>
                        <InfoBox theme={info}>{waterTemperatureInfo}</InfoBox>
                      </div>
                    )}
                    <div
                      className='row'
                      style={{ justifyContent: 'space-between' }}
                    >
                      <div
                        className='heading form-input'
                        style={{ width: '244px' }}
                      >
                        Input
                      </div>
                      <div className='heading results'>Results</div>
                    </div>
                    <div className='row'>
                      <div className='column form-input'>
                        <div className='input-group-wrapper'>
                          <Field
                            name='requiredPower'
                            type='number'
                            unit={UNITS.KILO_WATT}
                            placeholder='0.0'
                            label='Propulsion power'
                            component={InputField}
                            validate={(value: string) => {
                              const error = inputEmpty(value)
                              if (error) return error
                              if (
                                meOperationalMode ===
                                MeOperationalMode.FIXED_POWER
                              )
                                return validateMainEnginePower(
                                  value,
                                  mainEng1.maxSetpoint as number,
                                  mainEng1Mcr,
                                )
                              const maxShaft1Power = shaftMotor1.isInstalled
                                ? shaftMotor1.maxPower
                                : 0
                              const maxShaft2Power =
                                hasTwoMainEngines && shaftMotor2.isInstalled
                                  ? shaftMotor2.maxPower
                                  : 0
                              return validatePropulsionPower(
                                value,
                                mainEng1.maxSetpoint as number,
                                maxShaft1Power as number,
                                maxShaft2Power as number,
                                mainEng1Mcr,
                              )
                            }}
                            width='100px'
                            labelWidth='160px'
                            height='36px'
                          />
                          <Field
                            name='otherPower'
                            type='number'
                            unit={UNITS.KILO_WATT}
                            placeholder='0.0'
                            component={InputField}
                            label={'Electrical demand'}
                            validate={(value: string) => {
                              const error = inputEmpty(value)
                              if (error) return error
                              return validateElectricalDemand(
                                value,
                                maxElectricalPower,
                              )
                            }}
                            width='100px'
                            labelWidth='160px'
                            height='36px'
                          />
                          {whr.isInstalled && (
                            <Field
                              name='seaWaterTemp'
                              type='number'
                              unit={UNITS.TEMPERATURE}
                              placeholder='0.0'
                              component={InputField}
                              label='Ambient temperature'
                              validate={inputEmpty}
                              width='100px'
                              labelWidth='160px'
                              height='36px'
                            />
                          )}
                          <div id='checkbox'>
                            <McCheckbox
                              label='Load synchronously'
                              checked={this.state.synchronousLoading}
                              change={() =>
                                this.setState((previousState) => ({
                                  synchronousLoading:
                                    !previousState.synchronousLoading,
                                }))
                              }
                            />
                          </div>
                          <McButton
                            type='submit'
                            id='calculate-btn'
                            label='Calculate'
                            loading={loading}
                            fit='small'
                          />
                        </div>
                      </div>
                      <div className='column results'>
                        <EnergyManagementResultsTable
                          data={data}
                          vesselConfiguration={vesselConfiguration}
                          auxEngParams={auxEngParams}
                          temConfiguration={temConfiguration}
                        />
                      </div>
                    </div>
                  </Wrapper>
                </ModalContent>
                <ModalControls>
                  <McButton
                    label='Close'
                    appearance='primary'
                    click={onClose}
                    type='button'
                  />
                </ModalControls>
              </Form>
            </Modal>
          )
        }}
      </Formik>
    )
  }
}
