import * as React from 'react'
import styled, { red, grey } from '../theme'

const Form = styled.form`
  font-size: 14px;
  label {
    align-self: center;
    font-size: 14px;
    white-space: nowrap;
    display: block;

    .form-label-description {
      color: ${grey[700]};
      font-size: 12px;
    }
  }
`

const Table = styled.table`
  padding: 16px;
  td {
    padding: 4px;
    label {
      text-align: right;
    }
    .value {
      min-width: 288px;
    }
  }
`

const ReadonlyTable = styled.table`
  padding: 16px;
  font-size: 14px;

  td {
    vertical-align: top;
    padding: 2px 0;
    &:nth-child(even) {
      padding-left: 2px;
    }
    &:nth-child(odd) {
      padding-right: 2px;
    }
    .value {
      min-width: fit-content;
    }
    label {
      color: ${grey[700]};
      text-align: right;
    }
  }
`

const Error = styled.div`
  font-size: 14px;
  color: ${red[600]};
  vertical-align: top;
  height: 28px;
`

const Value = (props: React.HTMLAttributes<{}>) => (
  <div className='value' {...props}>
    {props.children}
  </div>
)

interface ILabel extends React.HTMLAttributes<{}> {
  description?: string
}

const Label = (props: ILabel) => (
  <label>
    <div className='form-label'>{props.children}</div>
    {props.description && (
      <span className='form-label-description'>{props.description}</span>
    )}
  </label>
)

export const FormElements = {
  Table,
  Error,
  ReadonlyTable,
  Label,
  Value,
  Form,
}
