import React, { useContext } from 'react'
import { useRouteMatch } from 'react-router'
import { McTag } from '@maersk-global/mds-react-wrapper'

import {
  Heading,
  VesselName,
  VesselNameWrapper,
  Wrapper,
} from './ContentLayout.styles'
import { routerParams } from '../../routes'
import {
  isFeatureEnabled,
  isFeatureEnabledForVessel,
  isShoreContext,
  isVesselContext,
} from '../../utils'
import { NotificationBar } from './NotificationBar'
import { ContentUnavailable } from './ContentUnavailable'
import { INotification } from '../../features/notifications/models'
import { VesselPageContext } from '../../contexts'
import { useVesselBasicInfo } from '../../queries/MasterDataApi/MasterDataApi'
import { VesselStatus } from '../../queries/MasterDataApi/MasterDataApi.consts'

type Props = {
  header: string
  featureId?: string
  notifications?: INotification[]
  children?: React.ReactNode
  hasFilter?: boolean
}

const ContentLayout = (props: Props) => {
  const match = useRouteMatch<routerParams>()
  const imoNo = match.params.vesselId
  const vesselBasicInfo = useVesselBasicInfo(imoNo)
  const { configuration } = useContext(VesselPageContext)
  const { header, children, featureId, notifications } = props

  let contentAvailable = false
  if (
    featureId &&
    isShoreContext() &&
    isFeatureEnabledForVessel(imoNo, featureId)
  ) {
    contentAvailable = true
  } else if (featureId && isVesselContext() && isFeatureEnabled(featureId)) {
    contentAvailable = true
  } else if (!featureId) {
    contentAvailable = true
  }

  return (
    <Wrapper hasFilter={props.hasFilter}>
      {!!notifications &&
        notifications.map((notification) => (
          <NotificationBar theme={notification.theme} key={notification.id}>
            {notification.content}
          </NotificationBar>
        ))}

      <div className='content'>
        {isShoreContext() && (
          <VesselNameWrapper>
            <VesselName title={`Vessel class: ${configuration?.vesselClass}`}>
              {configuration?.vesselName ?? imoNo}
            </VesselName>
            {vesselBasicInfo.data?.status === VesselStatus.Outfleeted && (
              <McTag label='Outfleeted' appearance='warning' fit='small' />
            )}
          </VesselNameWrapper>
        )}
        <Heading className='mds-headline--medium' data-e2e='page-title'>
          {header}
        </Heading>
        {contentAvailable && children}
        {!contentAvailable && <ContentUnavailable />}
      </div>
    </Wrapper>
  )
}

export default ContentLayout
