import { useContext, useState } from 'react'

import { StyledMcTag } from './PerformanceAlerts.styles'
import {
  getLatestMeSfocAlertsPerInstance,
  getLatestNotificationResponse,
  getNotificationBody,
  getNotificationHeading,
} from './PerformanceAlerts.utils'

import Diagnostics from './Diagnostics/Diagnostics'
import { Actor } from './Diagnostics/Diagnostics.consts'
import {
  LoadingDots,
  StyledMcNotification,
  Wrapper,
} from '../Notifications/Notifications.styles'
import { VesselPageContext, WindowContext } from '../../contexts'
import { isShoreContext } from '../../utils'
import { useNotifications } from '../../queries/NotificationsApi/NotificationsApi'
import { activeMeSfocAlertsQueryFilter } from '../../queries/NotificationsApi/NotificationsApi.consts'
import { useVesselMainEngines } from '../../queries/MasterDataApi/MasterDataApi'

const PerformanceAlerts = () => {
  const imoNo = useContext(VesselPageContext).imoNo!
  const { windowSize } = useContext(WindowContext)
  const [selectedAlertId, setSelectedAlertId] = useState<string>()
  const [selectedAlertOpening, setSelectedAlertOpening] = useState<string>()

  const mainEngines = useVesselMainEngines(imoNo)
  const isTwinEngine = mainEngines.data?.engines.length === 2

  const meSfocFilter = activeMeSfocAlertsQueryFilter
  if (isShoreContext()) {
    meSfocFilter.imo_no = +imoNo
  }

  const { data: meSfocAlerts, isSuccess: isMeSfocAlertsSuccess } =
    useNotifications<NotificationsAPI.PerformanceAlert>(meSfocFilter)

  if (!isMeSfocAlertsSuccess || meSfocAlerts.length === 0) {
    return null
  }

  const handleDiagnosticsOpen = (alertId: string) => () => {
    setSelectedAlertOpening(alertId)
    setSelectedAlertId(alertId)
  }

  const handleDiagnosticsOpened = () => {
    setTimeout(() => setSelectedAlertOpening(undefined), 200)
  }

  const handleDiagnosticsClosed = () => {
    setSelectedAlertId(undefined)
  }

  const meSfocAlertsPerInstance = getLatestMeSfocAlertsPerInstance(meSfocAlerts)

  return Object.values(meSfocAlertsPerInstance).map((a) => (
    <Wrapper key={a.id}>
      <StyledMcNotification
        fit={windowSize}
        appearance='warning'
        heading={getNotificationHeading(a, isTwinEngine)}
        body={getNotificationBody(a)}
        icon='exclamation-triangle'
      >
        <span slot='actions'>
          <span className='action' onClick={handleDiagnosticsOpen(a.id)}>
            See details
            {selectedAlertOpening === a.id && <LoadingDots />}
          </span>
          {getLatestNotificationResponse(a)?.data.actor === Actor.Shore && (
            <StyledMcTag
              fit={windowSize}
              label={`Message from Shore`}
              appearance='error'
              icon='package'
              onClick={handleDiagnosticsOpen(a.id)}
            />
          )}
        </span>
        {selectedAlertId && (
          <Diagnostics
            alertId={selectedAlertId}
            onClosed={handleDiagnosticsClosed}
            onOpened={handleDiagnosticsOpened}
          />
        )}
      </StyledMcNotification>
    </Wrapper>
  ))
}

export default PerformanceAlerts
