import { useFormikContext } from 'formik'

import { Row, Ruler } from '../MalfunctioningEquipmentReportPage.styles'
import { booleanOptions } from '../MalfunctioningEquipmentReportPage.utils'
import MfeInputDateTime from '../MfeInputDateTime/MfeInputDateTime'
import MfeInputRadioGroup from '../MfeInputRadioGroup/MfeInputRadioGroup'
import MfeInputNumber from '../MfeInputNumber/MfeInputNumber'
import { PadContent } from '../../../../layout/styles'

const ImpactSpeed = () => {
  const { values, setFieldValue } = useFormikContext<GandalfApi.MfeReport>()
  const { impact } = values.data

  const handleHasStoppageChange = (hasStoppage: boolean) => {
    if (!hasStoppage) {
      void setFieldValue('data.impact.stoppage.startTimestamp', null)
      void setFieldValue('data.impact.stoppage.endTimestamp', null)
    }
  }

  const handleMePowerRestrictionChange = (hasRestriction: boolean) => {
    if (!hasRestriction) {
      void setFieldValue('data.impact.mePowerRestriction.maxPower', null)
    }
  }

  return (
    <PadContent>
      <h3>Impact on vessel speed</h3>
      <Row>
        <MfeInputRadioGroup<boolean>
          name='data.impact.stoppage.hasStoppage'
          label='Was there stoppage?'
          options={booleanOptions}
          onChange={handleHasStoppageChange}
        />
        {impact.stoppage.hasStoppage && (
          <>
            <MfeInputDateTime
              name='data.impact.stoppage.startTimestamp'
              label='Start of stoppage'
            />
            <MfeInputDateTime
              name='data.impact.stoppage.endTimestamp'
              label='End of stoppage'
            />
          </>
        )}
      </Row>
      <Row>
        <MfeInputRadioGroup<boolean>
          name='data.impact.etaChange.hasEtaChange'
          label='Was there any change in ETA?'
          options={booleanOptions}
        />
      </Row>
      <Row>
        <MfeInputRadioGroup<boolean>
          name='data.impact.mePowerRestriction.hasRestriction'
          label='Is there restriction of ME power?'
          options={booleanOptions}
          onChange={handleMePowerRestrictionChange}
        />
        {(impact.mePowerRestriction.hasRestriction && (
          <>
            <MfeInputNumber
              name='data.impact.mePowerRestriction.maxPower'
              label='Maximum ME Power'
              unit='kW'
            />
            <div />
          </>
        )) || (
          <>
            <div />
            <div />
          </>
        )}
      </Row>
      <Ruler />
    </PadContent>
  )
}

export default ImpactSpeed
