import { Performance } from '../../api-models'
import {
  mariTimeColorScheme,
  purpleColorScheme,
  lightOrangeColorScheme,
  lightBlueColorScheme,
  whiteColorScheme,
  greyColorScheme,
  maritimeBlue,
  purple,
  orange,
  maerskBlue,
} from '../../theme'
import {
  FuelConsumptionSeriesModel,
  Density15SeriesModel,
} from './models/fuel-consumption'
import { FuelType, FUEL_TYPE_NAMES } from '../../utils'
import { FuelLineType } from '../../utils'

export const getConsumptionSeries = (
  fuelLines:
    | Performance.FuelConsumption.FuelLineConsumptionSeries[]
    | undefined,
  convertToFuelLineUnit?: (
    consumption: number | null,
    fuelLineType: FuelLineType,
  ) => number | null,
): FuelConsumptionSeriesModel => {
  const acc = {
    HS: [],
    VLS: [],
    MDO: [],
    ULS: [],
    meSplitLine: {
      HS: [],
      VLS: [],
      MDO: [],
      ULS: [],
    },
  } as FuelConsumptionSeriesModel
  if (!fuelLines) {
    return acc
  }

  return fuelLines.reduce(
    (
      acc: FuelConsumptionSeriesModel,
      fuelLine:
        | Performance.FuelConsumption.FuelLineConsumptionSeries
        | undefined,
    ) => {
      if (!fuelLine) {
        return acc
      }

      return reduceFuelLine(fuelLine, acc, convertToFuelLineUnit)
    },
    acc,
  )
}

const reduceFuelLine = (
  fuelLine: Performance.FuelConsumption.FuelLineConsumptionSeries,
  acc: FuelConsumptionSeriesModel,
  convertToFuelLineUnit?: (
    consumption: number | null,
    fuelLineType: FuelLineType,
  ) => number | null,
) => {
  const getConsumption = (consumption: number | null, fuelLineType: number) => {
    if (convertToFuelLineUnit) {
      return convertToFuelLineUnit(consumption, fuelLineType)
    }

    return consumption
  }

  return fuelLine.fuelTypeSeries.reduce(
    (acc: FuelConsumptionSeriesModel, fuelType: number, i: number) => {
      if (acc.meSplitLine && fuelLine.consumption1Series) {
        if (fuelType > 0) {
          acc.meSplitLine[FUEL_TYPE_NAMES[fuelType]][i] =
            fuelLine.consumption1Series[i]
        }
      }

      const consumption = getConsumption(
        fuelLine.consumptionSeries[i],
        fuelLine.fuelLineType,
      )

      if (fuelType === FuelType.HS) {
        const totalConsumptionHS = acc.HS[i]
        acc.HS[i] =
          totalConsumptionHS && consumption
            ? totalConsumptionHS + consumption
            : consumption
      } else {
        acc.HS[i] = acc.HS[i] ? acc.HS[i] : null
      }

      if (fuelType === FuelType.VLS) {
        const totalConsumptionVLS = acc.VLS[i]
        acc.VLS[i] =
          totalConsumptionVLS && consumption
            ? totalConsumptionVLS + consumption
            : consumption
      } else {
        acc.VLS[i] = acc.VLS[i] ? acc.VLS[i] : null
      }

      if (fuelType === FuelType.MDO) {
        const totalConsumptionMDO = acc.MDO[i]
        acc.MDO[i] =
          totalConsumptionMDO && consumption
            ? totalConsumptionMDO + consumption
            : consumption
      } else {
        acc.MDO[i] = acc.MDO[i] ? acc.MDO[i] : null
      }

      if (fuelType === FuelType.ULS) {
        const totalConsumptionULS = acc.ULS[i]
        acc.ULS[i] =
          totalConsumptionULS && consumption
            ? totalConsumptionULS + consumption
            : consumption
      } else {
        acc.ULS[i] = acc.ULS[i] ? acc.ULS[i] : null
      }

      return acc
    },
    acc,
  )
}

export const mapDensity15Series = (
  fuelLines:
    | Performance.FuelConsumption.FuelLineConsumptionSeries[]
    | undefined,
): Density15SeriesModel => {
  return fuelLines
    ? fuelLines.reduce((acc, f) => {
        acc[f.fuelLineType] = f.density15Series
        return acc
      }, {})
    : {}
}

const MIXING_PERIODS = 5
export const mapFuelTypeToColorScheme = (type: number) => {
  switch (type) {
    case FuelType.HS:
      return mariTimeColorScheme
    case FuelType.VLS:
      return purpleColorScheme
    case FuelType.MDO:
      return lightOrangeColorScheme
    case FuelType.ULS:
      return lightBlueColorScheme
    case MIXING_PERIODS:
      return greyColorScheme
    default:
      return whiteColorScheme
  }
}

export const getSplitLineColorByName = (name: string) => {
  switch (name) {
    case 'HS':
      return maritimeBlue[200]
    case 'VLS':
      return purple[200]
    case 'MDO':
      return orange[200]
    case 'ULS':
      return maerskBlue[200]
    default:
      return whiteColorScheme
  }
}
