import React from 'react'
import ReactDOM from 'react-dom'
import DatePicker, {
  ReactDatePickerProps,
  registerLocale,
  setDefaultLocale,
} from 'react-datepicker'
import en from 'date-fns/locale/en-US' // the locale you want
import styled, { inputDefault } from '../theme'
import moment from 'moment'
registerLocale('en-US', en) // register it with the name you want
setDefaultLocale('en-US')

const Wrapper = styled.div`
  position: relative;
  width: 152px;
  input {
    cursor: pointer;
    border: 1px solid ${inputDefault.border};
    border-radius: 4px;
    height: 38px;
    padding-left: 8px;
    width: 100%;
  }
  input:focus {
    box-shadow: 0 0 0 4px rgba(100, 178, 212, 0.2);
  }
  span {
    position: absolute;
    right: 0;
    top: 0;
    border: 1px solid ${inputDefault.border};
    background-color: ${inputDefault.bg};
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    height: 100%;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #666e79;
    pointer-events: none;
  }
`

const CalendarContainer = ({ children }: { children: React.ReactNode }) => {
  const el = document.getElementById('calendar-portal')
  if (el) {
    return ReactDOM.createPortal(children, el)
  }
  return null
}

export class Datepicker extends React.Component<ReactDatePickerProps, any> {
  render() {
    return (
      <Wrapper>
        <DatePicker
          maxDate={moment.utc().toDate()}
          autoComplete='off'
          {...this.props}
          popperContainer={CalendarContainer}
        />
        <span>
          <i className='fal fa-calendar' />
        </span>
      </Wrapper>
    )
  }
}
