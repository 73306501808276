import {
  HttpTransportType,
  type IHttpConnectionOptions,
} from '@microsoft/signalr'

import { getSensorApiSocketAddress } from '../../utils'

const options: IHttpConnectionOptions = process.env.NODE_ENV.match(
  /development|test/,
)
  ? {
      headers: { Authorization: process.env.REACT_APP_POMERIUM! },
      transport: HttpTransportType.LongPolling,
    }
  : {}

export const connection = new signalR.HubConnectionBuilder()
  .withUrl(`${getSensorApiSocketAddress()}/dataPointHub`, options)
  .build()
