import React, { useContext, useMemo, useState } from 'react'
import {
  useMutation,
  useQuery,
  useQueryClient,
  type UseQueryResult,
} from '@tanstack/react-query'
import { Form, Formik } from 'formik'
import { McButton, McModal } from '@maersk-global/mds-react-wrapper'

import {
  FormInputDateTime,
  FormRadioAlt,
  FormTextArea,
  FormTextInput,
  FormTypeahead,
} from '../../../components/formik'
import { VesselPageContext } from '../../../contexts'
import OverlayLoader from '../../../features/hybrid-data-collector/components/overlay-loader'
import {
  deleteLubeOilEntry,
  getLubeOilEntry,
  postLubeOilEntry,
  putLubeOilEntry,
} from '../../../services/performance'
import { displayErrorModal, isShoreContext } from '../../../utils'
import * as S from '../style'
import {
  type IBunkeringFormValues,
  type IModalProps,
  type TLubeOilBunkering,
} from '../types'
import bunkeringValidationSchema from '../validation/bunkering'
import { getInitialValues, oilTypeOptions } from './helpers'
import { useTerminals } from '../../../queries/MasterDataApi/MasterDataApi'
import { getTerminalOption } from '../../../features/hybrid-data-collector/reports/reports.utils'

const Bunkering = (props: IModalProps) => {
  const { data: terminals, isSuccess: isTerminalsSuccess } = useTerminals()
  const { imoNo } = useContext(VesselPageContext).configuration!

  const [isConfirmingDeletion, setConfirmingDeletion] = useState(false)

  /**
   * @description `undefined` if adding new entry; `string` if editing existing entry
   */
  const entryId = props.entryId

  const queryClient = useQueryClient()

  const {
    data,
    isFetching,
    isSuccess,
    isError,
    error,
  }: UseQueryResult<TLubeOilBunkering> = useQuery(
    ['lubeOilEntry', 'Bunkering', entryId, imoNo],
    () => getLubeOilEntry('Bunkering', entryId!, imoNo),
    { enabled: !!entryId },
  )

  const activeTerminalOptions = useMemo(() => {
    if (!isTerminalsSuccess) return []
    return terminals.filter((t) => t.data.isActive).map(getTerminalOption)
  }, [terminals, isTerminalsSuccess])

  const putMutation = useMutation({
    mutationFn: (entry: TLubeOilBunkering) =>
      putLubeOilEntry('Bunkering', imoNo, entry.id!, entry),
    onSuccess: handleMutationSuccess,
    onError: handleMutationError,
  })

  const postMutation = useMutation({
    mutationFn: (entry: TLubeOilBunkering) =>
      postLubeOilEntry('Bunkering', imoNo, entry),
    onSuccess: handleMutationSuccess,
    onError: handleMutationError,
  })

  const deleteMutation = useMutation({
    mutationFn: (entry: TLubeOilBunkering) =>
      deleteLubeOilEntry('Bunkering', entry.id!, imoNo),
    onSuccess: handleMutationSuccess,
    onError: (error: any) => {
      void displayErrorModal({
        statusText: 'Failed to delete entry',
        message: error.message || 'Could not delete entry. Please try again.',
      })
    },
  })

  function handleMutationSuccess() {
    void queryClient.invalidateQueries({
      queryKey: ['lubeOilEntries', String(imoNo)],
    })
    props.onClose()
  }

  function handleMutationError(error) {
    void displayErrorModal({
      statusText: 'Failed to save bunkering',
      message: error.message || 'Could not save bunkering. Please try again.',
    })
  }

  const handleSubmit = (values: IBunkeringFormValues) => {
    if (entryId) {
      putMutation.mutate(values as TLubeOilBunkering)
    } else {
      postMutation.mutate(values as TLubeOilBunkering)
    }
  }

  const handleDelete = () => {
    if (!isConfirmingDeletion) {
      setConfirmingDeletion(true)
      return
    }

    deleteMutation.mutate(data!)
  }

  let initialValues: IBunkeringFormValues
  if (!entryId) {
    initialValues = getInitialValues()
  } else if (isFetching) {
    return <OverlayLoader />
  } else if (isSuccess) {
    initialValues = data
  } else {
    if (isError) console.error(error)
    return null
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={bunkeringValidationSchema}
    >
      {({ isSubmitting, submitForm }) => (
        <McModal
          open
          closed={() => props.onClose()}
          heading='Bunkering'
          height='auto'
        >
          <Form>
            <S.Description>
              Same event type cannot have multiple entries with same timestamp.
            </S.Description>
            <S.InputRow>
              <S.Input width='68%'>
                <FormInputDateTime
                  fit='medium'
                  name='timestamp'
                  label='Date and time of bunkering, UTC'
                  min={props.openWindow?.from ?? undefined}
                  max={props.openWindow?.to ?? undefined}
                />
              </S.Input>
              <S.Input width='32%' />
            </S.InputRow>
            <S.InputRow>
              <FormRadioAlt
                name='oilType'
                id='lube-bunkering-oil-type'
                legend='Oil type'
                options={oilTypeOptions}
              />
            </S.InputRow>
            <S.InputRow style={{ justifyContent: 'space-between' }}>
              <S.Input width='100%'>
                <FormTypeahead
                  fit='medium'
                  name='deliveryDetails.portCode'
                  id='lube-bunkering-port'
                  label='Port'
                  options={activeTerminalOptions}
                />
              </S.Input>
              <S.Input width='100%'>
                <FormTextInput
                  name='tbn'
                  id='lube-bunkering-tbn'
                  data-e2e={'TBN'}
                  label='TBN'
                />
              </S.Input>
              <S.Input width='100%'>
                <FormTextInput
                  name='deliveryDetails.orderId'
                  id='lube-bunkering-orderId'
                  data-e2e='ShipManagerOrderNumber'
                  label='ShipManager order number'
                />
              </S.Input>
            </S.InputRow>
            <S.InputRow>
              <S.Input width='50%'>
                <FormTextInput
                  name='deliveryDetails.quantityOrdered'
                  id='lube-bunkering-quantity-ordered'
                  data-e2e='QuantityOrdered'
                  label='Quantity ordered'
                  suffix='kg'
                />
              </S.Input>
              <S.Input width='50%'>
                <FormTextInput
                  name='deliveryDetails.quantityAgreed'
                  id='lube-bunkering-quantity-agreed'
                  data-e2e='QuantityAgreed'
                  label='Quantity agreed'
                  suffix='kg'
                />
              </S.Input>
              <S.Input width='50%'>
                <FormTextInput
                  name='quantityPerChiefEngineer'
                  id='lube-bunkering-quantity-per-ce'
                  data-e2e='QuantityPerCE'
                  label='Quantity per C/E'
                  suffix='kg'
                />
              </S.Input>
            </S.InputRow>
            <FormTextArea
              name='notes'
              id='lube-bunkering-comment'
              data-e2e='Comment'
              label='Comment'
              placeholder='Write your comment here'
              rows={3}
            />
            {!isShoreContext() && entryId && !data?.readonly && (
              <S.DeletionRow>
                <McButton
                  type='button'
                  data-e2e={'Delete'}
                  appearance='neutral'
                  trailingicon='trash'
                  click={handleDelete}
                >
                  Delete
                </McButton>
                {isConfirmingDeletion && (
                  <p className='mds-text--medium-bold'>
                    Are you sure you want to delete this entry?
                  </p>
                )}
              </S.DeletionRow>
            )}
            {(isSubmitting || deleteMutation.isLoading) && <OverlayLoader />}
          </Form>
          {((isShoreContext() || data?.readonly) && (
            <McButton
              slot='primaryAction'
              appearance='primary'
              click={() => props.onClose()}
            >
              Close
            </McButton>
          )) || (
            <>
              <McButton
                slot='primaryAction'
                appearance='primary'
                click={submitForm}
                data-e2e='Save'
              >
                Save
              </McButton>
              <McButton
                slot='secondaryAction'
                appearance='neutral'
                click={() => props.onClose()}
              >
                Cancel
              </McButton>
            </>
          )}
        </McModal>
      )}
    </Formik>
  )
}

export default Bunkering
