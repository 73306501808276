import { useEffect } from 'react'

/**
 *  Logic for syncing x axis zooming across several charts
 *
 * @param charts the charts that should sync with a zooming events
 */
export const useXAxisZoomSync = (...charts) => {
  const dispatchZoomXAxis = (chart: any, params: any) => {
    if (chart && params.batch) {
      const [startValue, endValue] = [
        Math.floor(params.batch[0].startValue),
        Math.ceil(params.batch[0].endValue),
      ]
      chart.dispatchAction(
        {
          type: 'dataZoom',
          batch: [
            {
              startValue,
              endValue,
            },
          ],
        },
        true,
      )
    }
  }

  useEffect(() => {
    charts.forEach((targetChart) => {
      if (targetChart) {
        targetChart.on('dataZoom', (params) => {
          charts.forEach((chart) => {
            if (chart !== targetChart) {
              dispatchZoomXAxis(chart, params)
            }
          })
        })
      }
    })
  }, [charts])
}
