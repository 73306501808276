import { FUEL_LINE_TYPES } from '../constants'

export const mapFuelLineTypeToName = (type: number): string => {
  switch (type) {
    case FUEL_LINE_TYPES.ME_MAIN:
    case FUEL_LINE_TYPES.AE_MAIN:
      return 'Main'
    case FUEL_LINE_TYPES.AE_SECONDARY:
      return 'Secondary'
    case FUEL_LINE_TYPES.AE_MDO:
    case FUEL_LINE_TYPES.BOILER_MDO:
      return 'MDO'
    case FUEL_LINE_TYPES.BOILER_MAIN:
      return 'Main'
    default:
      return 'Unknown'
  }
}
