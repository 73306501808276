import React, { useEffect, useContext } from 'react'
import { useFormikContext } from 'formik'
import moment from 'moment'

import * as PerformanceApi from '../../../../../services/performance'
import { Performance } from '../../../../../api-models'
import { VesselPageContext } from '../../../../../contexts'
import { displayErrorModal } from '../../../../../utils'

type Props = {
  setRob: React.Dispatch<
    React.SetStateAction<Performance.FuelOilStock.RobResponse | undefined>
  >
}

/*
 * The RobUpdater can be used inside a formik form to refresh ROB
 * for all fuel batches when timestamp is selected/changed
 */

export const RobUpdater = ({ setRob }: Props) => {
  // Grab values from context
  const { values, setFieldValue } = useFormikContext<any>()
  const imoNo = useContext(VesselPageContext).imoNo!

  useEffect(() => {
    if (values.timestamp) {
      PerformanceApi.getRob(imoNo, moment.utc(values.timestamp).toISOString())
        .then(setRob)
        .catch((_) =>
          displayErrorModal({
            statusText: 'Could not receive ROB',
            message: 'Could not receive remaining on board at time of loss',
          }),
        )
    }
  }, [values.timestamp, imoNo, setFieldValue, setRob])

  return null
}
