import { useContext, useState, useMemo } from 'react'
import { useFormikContext } from 'formik'
import { sortBy } from 'lodash'
import styled from 'styled-components'

import { type Event } from '../../../../api-models/hdc/events'
import { ApiReportType } from '../../../../api-models/hdc/report'
import { Tabs } from '../../../../commons'
import FormSection from '../../../../components/form/section'
import { deleteEvent } from '../../../../services/hdc'
import { displayErrorModal } from '../../../../utils'
import { HDCContext } from '../../context'
import EventRow from '../../components/EventRow/EventRow'
import {
  ESubReport,
  EventTypeName,
  HDCFormType,
  HDCReportFormValues,
} from '../../types'
import { isVoyageEditable, timeDataPointToMomentDataPoint } from '../../utils'
import AnchorageEvent from './anchorage'
import DischargeEvent from './discharge'
import ETAChangeEvent from './eta-change'
import OffServiceEvent from './off-service'
import RouteChangeEvent from './route-change'

const Wrapper = styled.div`
  margin-bottom: 32px;
`

const EventsOverview = () => {
  const { values: report, setFieldValue } =
    useFormikContext<HDCReportFormValues>()
  const {
    state: HDCState,
    imoNo,
    getReport,
    setEvents,
    setFormUseCustomArrival,
  } = useContext(HDCContext)
  const { form, report: HDCReport, reports, reportType } = HDCState
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [deleteEventID, setDeleteEventID] = useState<string>()

  const deleteReportEvent = async (event: Event) => {
    try {
      setIsLoading(true)
      setDeleteEventID(event.id)
      await deleteEvent(imoNo, event.id, report.id, form.type!)
      const subReport =
        form.type === HDCFormType.BRIDGE_FORM
          ? ESubReport.Bridge
          : ESubReport.EngineRoom
      const events = (HDCReport?.[subReport].events || []).filter(
        ({ id }) => id !== event.id,
      )
      setEvents(events, subReport)

      // if ETA or Route change event, fetch report to get updated values
      if (
        event.typeName !== EventTypeName.ETA_CHANGE &&
        event.typeName !== EventTypeName.ROUTE_CHANGE
      ) {
        return
      }
      const updatedReport = await getReport(report.id)
      if (!updatedReport.bridge) {
        throw new Error('Failed to update values on Voyage step')
      }
      setFieldValue(
        'bridge.eta',
        timeDataPointToMomentDataPoint(updatedReport.bridge.eta),
      )
      setFieldValue(
        'bridge.arrivalTerminal',
        updatedReport.bridge.arrivalTerminal,
      )
      setFieldValue(
        'bridge.alternativeArrivalTerminal',
        updatedReport.bridge.alternativeArrivalTerminal,
      )
      setFormUseCustomArrival(!!updatedReport.bridge.alternativeArrivalTerminal)
      setFieldValue(
        'bridge.remainingDistanceToPilotStation',
        updatedReport.bridge.remainingDistanceToPilotStation,
      )
    } catch (err) {
      void displayErrorModal({
        statusText: err.message || 'Failed to delete event',
        message: err.message || 'Please try to delete event again',
      })
    } finally {
      setIsLoading(false)
    }
  }

  const bridgeEvents = sortBy(HDCReport?.bridge.events, 'startTimestamp')

  const engineRoomEvents = sortBy(
    HDCReport?.engineRoom.events,
    'startTimestamp',
  )

  const getEventRows = (event: Event, isEditable: boolean) => (
    <EventRow
      data={event}
      isLoading={isLoading && event.id === deleteEventID}
      key={`report-event-${event.id}`}
      onDelete={isEditable ? () => deleteReportEvent(event) : undefined}
      report={report}
    />
  )

  const tabButtons = useMemo(() => {
    return [
      {
        name: 'ETA change',
        hide:
          reportType === ApiReportType.ALONGSIDE ||
          reportType === ApiReportType.ARRIVAL ||
          isVoyageEditable(HDCReport!, reports!),
      },
      {
        name: 'Route change',
        hide:
          reportType === ApiReportType.ALONGSIDE ||
          isVoyageEditable(HDCReport!, reports!),
      },
      { name: 'Technical off-service' },
      { name: 'Operational off-service' },
      {
        name: 'Anchorage',
        hide: reportType === ApiReportType.ALONGSIDE,
      },
      { name: 'Discharge sludge to shore' },
    ]
  }, [HDCReport, reportType, reports])

  const tabsStartIndex = useMemo(() => {
    return tabButtons.findIndex(({ hide }) => !hide)
  }, [tabButtons])

  return (
    <Wrapper>
      <FormSection header='Event reports - Added from Engine room'>
        {engineRoomEvents?.length ? (
          engineRoomEvents.map((event) =>
            getEventRows(event, form.type === HDCFormType.ENGINE_FORM),
          )
        ) : (
          <p>There are no events reported from the Engine Room yet.</p>
        )}
      </FormSection>
      <FormSection header='Event reports - Added from Bridge'>
        {bridgeEvents?.length ? (
          bridgeEvents.map((event) =>
            getEventRows(event, form.type === HDCFormType.BRIDGE_FORM),
          )
        ) : (
          <p>There are no events reported from the Bridge yet.</p>
        )}
      </FormSection>
      {tabsStartIndex >= 0 && (
        <Tabs tabButtons={tabButtons} startIndex={tabsStartIndex}>
          <ETAChangeEvent />
          <RouteChangeEvent />
          <OffServiceEvent
            key='technical-off-service'
            type={EventTypeName.TECHNICAL_OFF_SERVICE}
          />
          <OffServiceEvent
            key='operational-off-service'
            type={EventTypeName.OPERATIONAL_OFF_SERVICE}
          />
          <AnchorageEvent />
          <DischargeEvent />
        </Tabs>
      )}
    </Wrapper>
  )
}

export default EventsOverview
