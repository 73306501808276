import { Management } from '../api-models/management'

export const getConnectedVessels = (
  data: Management.Vessel.VesselRegistry[],
  connectedImoNbrs: string[],
): Management.Vessel.VesselRegistry[] =>
  data.filter((vessel) =>
    connectedImoNbrs.some(
      (connectedImoNo) => vessel.imoNo.toString() === connectedImoNo,
    ),
  )

export const getDisconnectedVessels = (
  data: Management.Vessel.VesselRegistry[],
  connectedImoNbrs: string[],
): Management.Vessel.VesselRegistry[] =>
  data.filter(
    (vessel) =>
      !connectedImoNbrs.some(
        (connectedImoNo) => vessel.imoNo.toString() === connectedImoNo,
      ),
  )

export const isConnectedVessel: (
  vesselImoNumber: string,
  connectedVessels: string[],
) => boolean = (vesselImoNumber, connectedVessels) => {
  const found = connectedVessels.find((imo: string) => {
    return imo === vesselImoNumber
  })

  return !!found
}
