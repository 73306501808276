import styled from 'styled-components'

export const SelectedPeriodWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 10px;
`

export const TableWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

export const FuelConsumptionWrapper = styled.div`
  margin-bottom: 14px;
`

export const VerticalTableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(50% - 8px);

  > div {
    margin-bottom: 16px;
  }
`
