import { FunctionComponent } from 'react'
import { createColumnHelper } from '@tanstack/react-table'
import moment from 'moment'
import { McIcon } from '@maersk-global/mds-react-wrapper'

import {
  Header,
  IconCell,
  NumericCell,
  NumericHeader,
} from '../../../components/Table/Table.styles'
import {
  ELubeOilEntryType,
  type IModalProps,
  type TLubeOilBunkeringDetails,
  type TLubeOilEntry,
  type TLubeOilEntryDetails,
  type TLubeOilLossDetails,
  type TLubeOilSoundingDetails,
  type TLubeOilTransferDetails,
} from '../../lube-oil/types'
import {
  LUBE_OIL_CONTAINER_NAME,
  LUBE_OIL_ENTRY_TYPE_NAME,
  LUBE_OIL_TYPE_NAME,
} from '../../lube-oil/constants'
import { dateFilter } from '../ActivityLogFilters/DateFilter/helpers'
import { entryTypeFilter } from '../ActivityLogFilters/EntryTypeFilter/helpers'
import { oilTypeFilter } from '../ActivityLogFilters/OilTypeFilter/helpers'
import Bunkering from '../../lube-oil/bunkering/bunkering'
import Sounding from '../../lube-oil/sounding/sounding'
import Loss from '../../lube-oil/loss/loss'
import Transfer from '../../lube-oil/transfer/transfer'

const columnHelper = createColumnHelper<TLubeOilEntry>()

export const columns = [
  columnHelper.accessor('timestamp', {
    header: 'Entered',
    cell: (info) => moment.utc(info.getValue()).format('DD MMM YYYY HH:mm'),
    filterFn: dateFilter,
  }),
  columnHelper.accessor((row) => LUBE_OIL_ENTRY_TYPE_NAME[row.type], {
    id: 'type',
    header: 'Entry type',
    filterFn: entryTypeFilter,
  }),
  columnHelper.accessor((row) => LUBE_OIL_TYPE_NAME[row.oilType], {
    id: 'oilType',
    header: 'Oil type',
    filterFn: oilTypeFilter,
  }),
  columnHelper.accessor('quantity', {
    header: () => <NumericHeader>Quantity (kg)</NumericHeader>,
    cell: (info) => <NumericCell>{info.getValue()}</NumericCell>,
  }),
  columnHelper.display({
    id: 'details',
    header: 'Details',
    cell: (props) =>
      getLubeEntryDetails(
        props.cell.row.original.details,
        props.cell.row.original.type,
      ),
  }),
  columnHelper.display({
    id: 'hasNotes',
    header: () => <Header icon>Notes</Header>,
    cell: (props) =>
      props.cell.row.original['hasNotes'] ? (
        <IconCell>
          <McIcon icon='comments' />
        </IconCell>
      ) : null,
  }),
  columnHelper.display({
    id: 'action',
    header: () => <Header icon>Action</Header>,
    cell: (props) => (
      <IconCell>
        {props.cell.row.original['readonly'] ? 'View' : 'Edit'}
      </IconCell>
    ),
  }),
]

const getLubeEntryDetails = (
  details: TLubeOilEntryDetails,
  type: ELubeOilEntryType,
) => {
  if (type === ELubeOilEntryType.Bunkering) {
    const { portCode, tbn } = details as TLubeOilBunkeringDetails
    return `Port: ${portCode}` + (tbn ? `, TBN: ${tbn}` : '')
  }
  if (type === ELubeOilEntryType.Loss) {
    const { reasonCode } = details as TLubeOilLossDetails
    return reasonCode
  }
  if (type === ELubeOilEntryType.Sounding) {
    const { isPartial } = details as TLubeOilSoundingDetails
    return `${isPartial ? 'Partial' : 'Full'} Sounding`
  }
  if (type === ELubeOilEntryType.Transfer) {
    const { fromOilContainer, toOilContainer } =
      details as TLubeOilTransferDetails
    return `${LUBE_OIL_CONTAINER_NAME[fromOilContainer]} to ${LUBE_OIL_CONTAINER_NAME[toOilContainer]}`
  }
  return 'Unknown entry type'
}

type TEntryModal = FunctionComponent<IModalProps>

export const entryModals: Record<ELubeOilEntryType, TEntryModal> = {
  [ELubeOilEntryType.Bunkering]: Bunkering,
  [ELubeOilEntryType.Sounding]: Sounding,
  [ELubeOilEntryType.Loss]: Loss,
  [ELubeOilEntryType.Transfer]: Transfer,
}
