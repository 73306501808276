import * as React from 'react'
import styled, { css, keyframes } from 'styled-components'
import { init, EChartOption } from 'echarts'
import { getLoadingText } from '../utils/loading-texts'
import { grey } from '../theme'
import {
  AXIS_TICK,
  AXIS_LINE,
  GRID,
  ACTIVATE_ZOOM_ACTION,
} from '../utils/constants'

const animationKeyframes = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`

const animation = () => css`
  animation: ${animationKeyframes} 2s ease infinite;
`

const Wrapper = styled.div<{ minHeight?: number }>`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  #chart-stencil {
    min-height: ${(props) =>
      props.minHeight ? `${props.minHeight}px` : '300px'};
    width: 100%;
  }
  .loading-text {
    position: absolute;
    z-index: 0;
  }
  .loading {
    position: absolute;
    z-index: 1;
    top: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.9)
    );
    background-size: 400% 400%;

    animation: ${animation};
  }
`

interface ChartStencilProps {
  chartType: 'scatter' | 'line' | 'area'
  minHeight?: number
}

interface ChartStencilState {
  loadingText: string
}

export class ChartStencil extends React.Component<
  ChartStencilProps,
  ChartStencilState
> {
  private chartNode: React.RefObject<HTMLDivElement>
  constructor(props: ChartStencilProps) {
    super(props)

    this.state = {
      loadingText: getLoadingText(),
    }

    this.chartNode = React.createRef()
  }

  getDummyData = () => {
    const data: number[][] = []
    switch (this.props.chartType) {
      case 'scatter':
        for (let i = 0; i < 10; i += 0.25) {
          data.push([i, Math.exp(-1 * i) + 1.5])
        }
        break
      case 'line':
      case 'area':
        for (let i = 0; i < 50; i++) {
          data.push([i, Math.random() * 6 + 1])
        }
        break
    }
    return data
  }

  getOptions = (): EChartOption => {
    const { chartType } = this.props
    return {
      xAxis: {
        type: 'value',
        axisTick: AXIS_TICK,
        axisLabel: {
          formatter: (value: any) => 'x',
        },
        axisLine: AXIS_LINE,
        splitLine: { show: chartType === 'scatter' },
      },
      yAxis: [
        {
          type: 'value',
          axisTick: AXIS_TICK,
          axisLabel: {
            formatter: (value: any) => 'y',
          },
          axisLine: AXIS_LINE,
        },
      ],
      grid: GRID,
      series: [
        {
          type: chartType === 'area' ? 'line' : chartType,
          symbol: chartType === 'scatter' ? 'circle' : 'none',
          symbolSize: 4,
          lineStyle: {
            color: grey[300],
          },
          itemStyle: {
            color: grey[300],
          },
          areaStyle:
            chartType === 'area'
              ? { color: grey[300], opacity: 0.2 }
              : undefined,
          data: this.getDummyData(),
        },
      ],
    }
  }

  componentDidMount() {
    if (this.chartNode.current) {
      const chart = init(this.chartNode.current)
      chart.setOption(this.getOptions())
      chart.dispatchAction(ACTIVATE_ZOOM_ACTION)
    }
  }

  public render() {
    return (
      <Wrapper minHeight={this.props.minHeight}>
        <div id='chart-stencil' ref={this.chartNode} />
        <div className='loading-text'>{this.state.loadingText}</div>
        <div className='loading' />
      </Wrapper>
    )
  }
}
