import { Event } from '../../../../api-models/hdc/events'
import { Modal } from '../../../../commons'
import { EventTypeName } from '../../types'
import AnchorageEvent from './anchorage'
import DischargeEvent from './discharge'
import ETAChangeEvent from './eta-change'
import OffServiceEvent from './off-service'
import RouteChangeEvent from './route-change'
import * as S from './style'

type Props = {
  data: Event
  handleClose: Function
  isVisible: boolean
}

const EditEvent = ({ data, handleClose, isVisible }: Props) => {
  return (
    <Modal title='Edit event' visible={isVisible} closeHandler={handleClose}>
      <S.EventModalContainer>
        {data.typeName === EventTypeName.ANCHORAGE && (
          <AnchorageEvent data={data} handleClose={handleClose} />
        )}
        {data.typeName === EventTypeName.DISCHARGE_SLUDGE && (
          <DischargeEvent data={data} handleClose={handleClose} />
        )}
        {data.typeName === EventTypeName.ETA_CHANGE && (
          <ETAChangeEvent data={data} handleClose={handleClose} />
        )}
        {data.typeName === EventTypeName.ROUTE_CHANGE && (
          <RouteChangeEvent data={data} handleClose={handleClose} />
        )}
        {data.typeName.split('/').shift() ===
          EventTypeName.TECHNICAL_OFF_SERVICE && (
          <OffServiceEvent
            data={data}
            handleClose={handleClose}
            type={EventTypeName.TECHNICAL_OFF_SERVICE}
          />
        )}
        {data.typeName.split('/').shift() ===
          EventTypeName.OPERATIONAL_OFF_SERVICE && (
          <OffServiceEvent
            data={data}
            handleClose={handleClose}
            type={EventTypeName.OPERATIONAL_OFF_SERVICE}
          />
        )}
      </S.EventModalContainer>
    </Modal>
  )
}

export default EditEvent
