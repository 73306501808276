import { QueryResponse } from './common'

export interface MainEngSfocCurve extends QueryResponse {
  mainEng1: MainEngSfocCurveData
  mainEng2: MainEngSfocCurveData | null
}

export interface MainEngSfocCurveData {
  timestamps: Array<number>
  loadSeries: Array<number>
  sfocSeries: Array<number>
  hasTcco: boolean
  tccoSeries: Array<boolean>
  fuelTypeSeries: Array<number>
}
export interface RunningEnginesSerie {
  load: number
  number: number
  power: number
}

export interface MeSfocData extends QueryResponse {
  timestamps: Array<number>
  fuelTypeSeries: Array<number>
  mainEng1: EngineSfocData
  mainEng2: EngineSfocData
  mainEngMix: EngineSfocData
}

export interface EngineSfocData {
  powerSeries: Array<number>
  loadSeries: Array<number>
  sfocSeries: Array<number>
  tccoSeries: Array<boolean>
  fuelTypeSeries: Array<number>
}

export interface AeSfocData extends QueryResponse {
  timestamps: Array<number>
  consumptionSeries: Array<number>
  fuelTypeSeries: Array<number>
  sfocSeries: Array<number>
  powerSeries: Array<number>
  runningEnginesSeries: Array<Array<RunningEnginesSerie>>
}
