import { MfeStatus } from '../../../../queries/GandalfApi/GandalfApi.consts'
import { statusToDisplayStr } from '../../MalfunctioningEquipmentPage.utils'

export const statusOptions = [
  { label: statusToDisplayStr(MfeStatus.Ongoing), value: MfeStatus.Ongoing },
  {
    label: statusToDisplayStr(MfeStatus.Completed),
    value: MfeStatus.Completed,
  },
  {
    label: statusToDisplayStr(MfeStatus.OutOfService),
    value: MfeStatus.OutOfService,
  },
]

export const meStatuses = [
  'Incomplete engine',
  'Reduced load',
  'Running with all units',
]
