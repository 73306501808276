import styled from 'styled-components'

export const Wrapper = styled.div`
  .net-score-promoter-radio {
    display: flex;
    justify-content: center;
  }
  .net-score-promoter-radio .radioIcon {
    display: inline-block;
    margin: 16px 24px 24px;
    transform: scale(2.5);
  }
`
