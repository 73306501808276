import styled, { keyframes } from '../theme'

type Props = {
  text: string
  detail?: string
  padding?: string
}

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const Wrapper = styled.div<{ padding?: string }>`
  width: 100%;
  min-height: 100%;
  padding: ${(props) => props.padding || '24px'};
  display: flex;
  justify-content: center;
  align-items: center;
  color: #777;
  flex-wrap: wrap;
  text-align: center;
  box-sizing: border-box;

  svg {
    animation: ${rotate360} 3.5s linear infinite;
    margin-bottom: 16px;
  }
`

export const NotFound = (props: Props) => (
  <Wrapper className='mds-headline--small' padding={props.padding}>
    <span>{props.text}</span>
    {typeof props.detail === 'string' && <small>{props.detail}</small>}
  </Wrapper>
)
