import { useContext, useEffect, useMemo, useState } from 'react'
import { Field, useField, useFormikContext } from 'formik'
import moment from 'moment'
import Select from 'react-select'

import { FormInput } from '../../../../commons/forms/FormInput'
import { FormDateTimeInput, FormInputWithUnit } from '../../../../commons/'
import { VesselPageContext } from '../../../../contexts'
import * as PerformanceAPI from '../../../../services/performance'
import { Performance } from '../../../../api-models'
import { displayErrorModal, formatValue, FuelType } from '../../../../utils'
import { FormContainer, Loading } from '../../../../commons'
import { McTagGroup } from '../McTagGroup'
import styled, { grey } from '../../../../theme'
import { type BunkeringFormValues } from './BunkeringForm'
import { fuelTypeOptions, fuelTypeSubOptions } from '../../models'
import { ErrorMessage } from '../ErrorMessage'
import { useTerminals } from '../../../../queries/MasterDataApi/MasterDataApi'
import { getFuelGradeOption } from '../../utils'
import { getTerminalOption } from '../../../hybrid-data-collector/reports/reports.utils'
import { calculateLcvValueForBioFuel } from './Bunkering.utils'

const LabReportTitle = styled.div`
  border-bottom: 1px solid ${grey[500]};
  padding: 10px;
  margin: 0 16px;

  .span {
    font-size: 16px;
  }
`

const Notes = styled.div`
  overflow-y: scroll;
  margin-right: 5px;
  min-height: 32px;
  max-height: 200px;
  max-width: 650px;
  font-size: 14px;
  white-space: pre-line;
`

const SelectWrapper = styled.div`
  width: 290px;
  font-size: 14px;
`

const ErrorContainer = styled.div`
  width: 100%;
`

type Props = {
  disabled: boolean
  showErrorsForUntouchedFields?: boolean
  isLabReportReadonly?: boolean
  isBaseFormReadonly: boolean
  disableOpenWindowValidation?: boolean
  fuelGrades?: Array<MasterDataApi.Common.FuelGrade>
  isFuelGradesSuccess: boolean
}

export const BunkeringFormFields = ({
  disabled,
  isLabReportReadonly,
  isBaseFormReadonly,
  showErrorsForUntouchedFields,
  disableOpenWindowValidation,
  fuelGrades,
  isFuelGradesSuccess,
}: Props) => {
  const { data: terminals, isSuccess: isTerminalsSuccess } = useTerminals()
  const imoNo = useContext(VesselPageContext).imoNo!
  const { initialValues, values, setFieldValue } =
    useFormikContext<BunkeringFormValues>()
  const { fuelType } = values
  const [openWindow, setOpenWindow] =
    useState<Performance.FuelOilStock.OpenWindow>()
  const [calculatedLcvValueforBioFuel, setCalculatedLcvValueforBioFuel] =
    useState(false)
  useEffect(() => {
    if (disableOpenWindowValidation) {
      setOpenWindow({
        period: {
          from: moment.utc().subtract(2, 'y').toString(),
          to: moment.utc().toString(),
          totalMinutes: moment
            .utc()
            .diff(moment.utc().subtract(2, 'y'), 'minutes'),
        },
      })
    } else {
      PerformanceAPI.getStockOpenWindow(imoNo)
        .then(setOpenWindow)
        .catch((e) =>
          displayErrorModal({
            statusText: 'Could not get allowed time interval',
            message: e.message,
          }),
        )
    }
  }, [imoNo, disableOpenWindowValidation])

  useEffect(() => {
    const fuelType = values?.fuelType
    const fieldName = 'isDistillate'
    if (fuelType === FuelType.HS) void setFieldValue(fieldName, false)
    if (fuelType === FuelType.MDO) void setFieldValue(fieldName, true)
  }, [fuelType, setFieldValue, values])

  const activeFuelGradeOptions = useMemo(() => {
    if (!isFuelGradesSuccess) return []
    return fuelGrades?.filter((fg) => fg.data.isActive).map(getFuelGradeOption)
  }, [fuelGrades, isFuelGradesSuccess])

  const activeTerminalOptions = useMemo(() => {
    if (!isTerminalsSuccess) return []

    const result = terminals
      .filter((t) => t.data.isActive)
      .map(getTerminalOption)

    if (
      initialValues.portCode &&
      !result.find((t) => t.value === initialValues.portCode)
    ) {
      // Workaround: Add the port code from the initial values if it is not in the list
      result.push({
        label: initialValues.portCode,
        value: initialValues.portCode,
      })
    }

    return result
  }, [terminals, isTerminalsSuccess, initialValues.portCode])

  if (!openWindow) {
    return <Loading width='unset' />
  }

  const handleBioPercentageChange = (value: number) => {
    const fuelGrade = fuelGrades?.find(
      (fg) => fg.data.code === values.fuelGrade,
    )
    if (fuelGrade?.data.isBiofuel) {
      // as bioPercentage is a percentage, we need to divide by 100
      const lcv = calculateLcvValueForBioFuel(fuelGrade, value / 100)
      setFieldValue('lcv', lcv)
      setCalculatedLcvValueforBioFuel(true)
    }
  }

  const handleFuelGradeChange = (value?: string) => {
    if (!value) {
      return
    }
    setFieldValue('fuelGrade', value)
    const fuelGrade = fuelGrades?.find((fg) => fg.data.code === value)
    if (fuelGrade) {
      setFieldValue('fuelType', fuelGrade.data.fuelType)
      setFieldValue('isDistillate', fuelGrade.data.refinementType === 1)
      setFieldValue('lcv', '')
      setCalculatedLcvValueforBioFuel(false)
      setFieldValue('bioPercentage', 0)
    }
  }

  return (
    <>
      <FormContainer width='800px' style={{ flexDirection: 'row' }}>
        <div style={{ flex: 1 }}>
          <div className='field-container'>
            <span className='field-label'>Time of bunkering</span>
            <Field
              component={(props) => (
                <FormDateTimeInput
                  {...props}
                  minuteSpecific={false}
                  minDate={moment.utc(openWindow.period.from)}
                  maxDate={moment.utc(openWindow.period.to)}
                />
              )}
              name='timestamp'
              disabled={isBaseFormReadonly || disabled}
            />
          </div>
          <ErrorContainer>
            <ErrorMessage
              name='timestamp'
              showErrorsForUntouchedFields={showErrorsForUntouchedFields}
            />
          </ErrorContainer>

          <div className='field-container' data-e2e='fuelGrade'>
            <span className='field-label'>Fuel grade</span>
            <Field
              component={(props) => (
                <SelectWrapper>
                  <Select
                    placeholder='Type to search...'
                    onBlur={() => props.form.setFieldTouched(props.field.name)}
                    onChange={(option) => handleFuelGradeChange(option?.value)}
                    value={
                      props.field?.value &&
                      props.options?.find(
                        ({ value }) => value === props.field.value,
                      )
                    }
                    options={props.options}
                    isOptionDisabled={({ isDisabled }) => isDisabled ?? false}
                    isSearchable={true}
                    isDisabled={isBaseFormReadonly || disabled}
                    styles={{
                      placeholder: (provided) => ({
                        ...provided,
                        fontSize: '14px',
                      }),
                      option: (provided) => ({
                        ...provided,
                        fontSize: '14px',
                      }),
                      valueContainer: (provided) => ({
                        ...provided,
                        fontSize: '14px',
                      }),
                    }}
                  />
                </SelectWrapper>
              )}
              options={activeFuelGradeOptions}
              name='fuelGrade'
              disabled={disabled}
            />
          </div>
          <ErrorContainer>
            <ErrorMessage
              name='fuelGrade'
              showErrorsForUntouchedFields={showErrorsForUntouchedFields}
            />
          </ErrorContainer>

          <div className='field-container'>
            <span className='field-label'>Fuel type</span>
            <McTagGroup name='fuelType' options={fuelTypeOptions} />
          </div>

          {values.fuelType !== 0 && values.fuelType !== FuelType.MM && (
            <>
              <div className='field-container'>
                <span className='field-label'>
                  Type of {FuelType[values.fuelType]}
                </span>
                <McTagGroup
                  name='isDistillate'
                  options={fuelTypeSubOptions[values.fuelType]}
                />
              </div>
              <ErrorContainer>
                <ErrorMessage
                  name='isDistillate'
                  showErrorsForUntouchedFields={showErrorsForUntouchedFields}
                />
              </ErrorContainer>
            </>
          )}

          {(fuelGrades?.find((fg) => fg.data.code === values.fuelGrade)?.data
            .isBiofuel ||
            initialValues.bioPercentage > 0) && (
            <>
              <div className='field-container'>
                <span className='field-label'>Percentage bio</span>
                <Field
                  component={FormInputWithUnit}
                  name='bioPercentage'
                  type='number'
                  unit='%mass'
                  placeholder='0'
                  width='120px'
                  disabled={isBaseFormReadonly || disabled}
                  data-e2e='percentageBio'
                  onChange={handleBioPercentageChange}
                />
              </div>
              <ErrorContainer>
                <ErrorMessage
                  name='bioPercentage'
                  showErrorsForUntouchedFields={showErrorsForUntouchedFields}
                />
              </ErrorContainer>
            </>
          )}

          <div className='field-container' data-e2e='bunkerPortCode'>
            <span className='field-label'>Bunker port code</span>
            <Field
              component={(props) => (
                <SelectWrapper>
                  <Select
                    placeholder='Type to search...'
                    onBlur={() => props.form.setFieldTouched(props.field.name)}
                    onChange={(option: any) => {
                      if (option?.value)
                        props.form.setFieldValue(props.field.name, option.value)
                    }}
                    value={
                      props.field?.value &&
                      props.options?.find(
                        ({ value }) => value === props.field.value,
                      )
                    }
                    options={props.options}
                    isOptionDisabled={({ isDisabled }) => isDisabled ?? false}
                    isDisabled={isBaseFormReadonly || disabled}
                    isSearchable={true}
                    styles={{
                      placeholder: (provided) => ({
                        ...provided,
                        fontSize: '13px',
                      }),
                      option: (provided) => ({
                        ...provided,
                        fontSize: '14px',
                      }),
                      valueContainer: (provided) => ({
                        ...provided,
                        fontSize: '13px',
                      }),
                    }}
                  />
                </SelectWrapper>
              )}
              options={activeTerminalOptions}
              name='portCode'
              disabled={isBaseFormReadonly || disabled}
            />
          </div>
          <ErrorContainer>
            <ErrorMessage
              name='portCode'
              showErrorsForUntouchedFields={showErrorsForUntouchedFields}
            />
          </ErrorContainer>
        </div>
        <div style={{ flex: 1 }}>
          <div className='field-container'>
            <span className='field-label extended'>
              Order ID (Nomination Number)
            </span>
            <Field
              component={FormInput}
              name='orderId'
              type='text'
              placeholder='Enter the 5 digit Order ID'
              width='190px'
              disabled={isBaseFormReadonly || disabled}
              data-e2e='orderID'
            />
          </div>
          <ErrorContainer>
            <ErrorMessage
              name='orderId'
              showErrorsForUntouchedFields={showErrorsForUntouchedFields}
            />
          </ErrorContainer>

          <div className='field-container'>
            <span className='field-label extended'>Quantity ordered</span>
            <Field
              component={FormInputWithUnit}
              disabled={isBaseFormReadonly || disabled}
              formatInputValue={(value) => formatValue(value, 3, '')}
              name='quantityOrdered'
              type='number'
              unit='MT'
              width='150px'
              data-e2e='quantity1'
            />
          </div>
          <ErrorContainer>
            <ErrorMessage
              name='quantityOrdered'
              showErrorsForUntouchedFields={showErrorsForUntouchedFields}
            />
          </ErrorContainer>

          <div className='field-container'>
            <span className='field-label extended'>
              Quantity received according to C/E
            </span>
            <Field
              component={FormInputWithUnit}
              disabled={isBaseFormReadonly || disabled}
              formatInputValue={(value) => formatValue(value, 3, '')}
              name='quantityPerChiefEngineer'
              type='number'
              unit='MT'
              width='150px'
              data-e2e='quantity2'
            />
          </div>
          <ErrorContainer>
            <ErrorMessage
              name='quantityPerChiefEngineer'
              showErrorsForUntouchedFields={showErrorsForUntouchedFields}
            />
          </ErrorContainer>
          <div className='field-container'>
            <span className='field-label extended'>
              Quantity agreed with supplier (BDN)
            </span>
            <Field
              component={FormInputWithUnit}
              disabled={isBaseFormReadonly || disabled}
              formatInputValue={(value) => formatValue(value, 3, '')}
              name='quantityAgreed'
              type='number'
              unit='MT'
              width='150px'
              data-e2e='quantity3'
            />
          </div>
          <ErrorContainer>
            <ErrorMessage
              name='quantityAgreed'
              showErrorsForUntouchedFields={showErrorsForUntouchedFields}
            />
          </ErrorContainer>
        </div>

        <div
          className='field-container'
          style={{ width: '100%', alignItems: 'baseline' }}
        >
          <span
            className='field-label'
            style={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            Notes
          </span>
          {!disabled && !isBaseFormReadonly ? (
            <BunkeringNotes disabled={disabled} name='notes' />
          ) : (
            (values.notes && <Notes>{values.notes}</Notes>) || '-'
          )}
        </div>
        <ErrorContainer>
          <ErrorMessage name='notes' />
        </ErrorContainer>
      </FormContainer>

      <>
        <LabReportTitle>
          <span>Lab report</span>
        </LabReportTitle>
        <FormContainer width='600px' height='150px'>
          <div style={{ flex: 1 }}>
            <div className='field-container'>
              <span className='field-label'>Marpol seal</span>
              <Field
                component={FormInput}
                name='marpolSeal'
                type='text'
                placeholder='Marpol seal'
                width='150px'
                disabled={isLabReportReadonly || disabled}
                data-e2e='marpolSeal'
              />
            </div>
            <ErrorContainer>
              <ErrorMessage name='marpolSeal' />
            </ErrorContainer>
            <div className='field-container'>
              <span className='field-label'>Density 15</span>
              <Field
                component={FormInputWithUnit}
                formatInputValue={(value) => formatValue(value, 2, '')}
                name='density15'
                type='number'
                unit='kg/m3'
                width='150px'
                disabled={isLabReportReadonly || disabled}
                data-e2e='density15'
              />
            </div>
            <ErrorContainer>
              <ErrorMessage name='density15' />
            </ErrorContainer>
            <div className='field-container'>
              <span className='field-label'>LCV</span>
              <Field
                component={FormInputWithUnit}
                formatInputValue={(value) => formatValue(value, 0, '')}
                name='lcv'
                type='number'
                unit='KJ/kg'
                width='150px'
                disabled={
                  isLabReportReadonly ||
                  disabled ||
                  calculatedLcvValueforBioFuel
                }
                data-e2e='lcv'
              />
            </div>
            <ErrorContainer>
              <ErrorMessage name='lcv' />
            </ErrorContainer>
          </div>
          <div style={{ flex: 1 }}>
            <div className='field-container'>
              <span className='field-label'>Water</span>
              <Field
                component={FormInputWithUnit}
                formatInputValue={(value) => formatValue(value, 2, '')}
                name='water'
                type='number'
                unit='%volume'
                width='150px'
                disabled={isLabReportReadonly || disabled}
                data-e2e='waterContent'
              />
            </div>
            <ErrorContainer>
              <ErrorMessage name='water' />
            </ErrorContainer>
            <div className='field-container'>
              <span className='field-label'>Ash</span>
              <Field
                component={FormInputWithUnit}
                formatInputValue={(value) => formatValue(value, 2, '')}
                name='ash'
                type='number'
                unit='%mass'
                width='150px'
                disabled={isLabReportReadonly || disabled}
                data-e2e='ashContent'
              />
            </div>
            <ErrorContainer>
              <ErrorMessage name='ash' />
            </ErrorContainer>
            <div className='field-container'>
              <span className='field-label'>Sulphur</span>
              <Field
                component={FormInputWithUnit}
                formatInputValue={(value) => formatValue(value, 2, '')}
                name='sulphur'
                type='number'
                unit='%mass'
                width='150px'
                disabled={isLabReportReadonly || disabled}
                data-e2e='sulphurContent'
              />
            </div>
            <ErrorContainer>
              <ErrorMessage name='sulphur' />
            </ErrorContainer>
          </div>
        </FormContainer>
      </>
    </>
  )
}

const TextArea = styled.textarea`
  border-radius: 3px;
  box-sizing: border-box;
  padding: 4px;
  border-color: ${grey[300]};
  overflow: auto;
  height: 90px;
  width: calc(100% - 148px);
  resize: vertical;
`

type BunkeringNotesProps = {
  name: string
  disabled: boolean
}

const BunkeringNotes = ({ name, disabled }: BunkeringNotesProps) => {
  const [field] = useField(name)

  return (
    <TextArea
      disabled={disabled}
      {...field}
      placeholder='Write your notes here'
      data-e2e='notes'
    />
  )
}
