import React, { useContext, useMemo } from 'react'
import { FieldArray, getIn, useFormikContext } from 'formik'
import { McRadio, McRadioGroup } from '@maersk-global/mds-react-wrapper'

import { HDCContext } from '../../context'
import { WindowContext } from '../../../../contexts'
import FormSection from '../../../../components/form/section'
import { UNITS } from '../../../../utils/constants'
import { SimpleFormRow } from '../../components/form-row'
import HDCInputField from '../../components/hdc-input-field'
import HDCTextareaField from '../../components/hdc-textarea-field'
import OverlayLoader from '../../components/overlay-loader'
import { type HDCReportFormValues } from '../../types'
import {
  flattenFormikErrors,
  getFilteredByStepErrors,
  getFilteredByStepWarnings,
  mainEngineName,
} from '../../utils'
import Notifications from '../../../../components/Notifications/Notifications'

const PropulsionStep = () => {
  const { windowSize } = useContext(WindowContext)
  const { state: HDCState } = useContext(HDCContext)
  const { errors, isSubmitting, setFieldValue, values } =
    useFormikContext<HDCReportFormValues>()
  const { mainEngines } = values.engineRoom
  const {
    form: { currentStep, steps, type },
    report,
  } = HDCState

  const warnings = useMemo(
    () =>
      getFilteredByStepWarnings(report!.warnings, type!, steps[currentStep]),
    [currentStep, report, steps, type],
  )

  const handleTccoChange = (i: number, v: string) => (_: Event) => {
    void setFieldValue(`engineRoom.mainEngines.${i}.tcco.value`, v === 'true')
  }

  return (
    <>
      <Notifications
        alerts={flattenFormikErrors(
          getFilteredByStepErrors(errors, type!, steps[currentStep]),
        )}
        warnings={[...new Set(Object.values(warnings))]}
      />
      <FormSection
        header={`Main engine${mainEngines.length > 1 ? 's' : ''}`}
        description='Please confirm/enter your main engine data for the report period.'
      >
        <FieldArray
          name='engineRoom.mainEngines'
          render={() =>
            mainEngines.map((engine, idx) => (
              <React.Fragment key={`main-engine-${engine.number}`}>
                <h4 className='mds-headline--x-small'>
                  {mainEngineName(mainEngines.length, engine.number)}
                </h4>
                <SimpleFormRow>
                  <HDCInputField
                    addon={UNITS.KILO_WATT}
                    decimals={0}
                    hasWarning={
                      !!warnings[`engineRoom.mainEngines.${idx}.averagePower`]
                    }
                    label='Avg. power'
                    name={`engineRoom.mainEngines.${idx}.averagePower.value`}
                    placeholder='-'
                    type='number'
                  />
                  <HDCInputField
                    addon={UNITS.RPM}
                    decimals={1}
                    hasWarning={
                      !!warnings[
                        `engineRoom.mainEngines.${idx}.averageShaftRpm`
                      ]
                    }
                    label='Avg. shaft RPM'
                    maxWidth='185px'
                    name={`engineRoom.mainEngines.${idx}.averageShaftRpm.value`}
                    placeholder='Avg. Shaft RPM'
                    type='number'
                  />
                  <div>
                    <HDCInputField
                      label='Runtime'
                      addon='hr'
                      decimals={0}
                      error={getIn(
                        errors,
                        `engineRoom.mainEngines.${idx}.runTime`,
                      )}
                      hasWarning={
                        !!warnings[`engineRoom.mainEngines.${idx}.runTime`]
                      }
                      name={`engineRoom.mainEngines.${idx}.runTime.hours.value`}
                      placeholder='Hours'
                      type='number'
                    />
                    <HDCInputField
                      label='&nbsp;'
                      addon='mins'
                      decimals={0}
                      error={getIn(
                        errors,
                        `engineRoom.mainEngines.${idx}.runTime`,
                      )}
                      hasWarning={
                        !!warnings[`engineRoom.mainEngines.${idx}.runTime`]
                      }
                      name={`engineRoom.mainEngines.${idx}.runTime.minutes.value`}
                      placeholder='Minutes'
                      type='number'
                    />
                  </div>
                </SimpleFormRow>
                {engine.tcco && (
                  <SimpleFormRow>
                    <McRadioGroup
                      fit={windowSize}
                      name='tcco'
                      legend='TCCO engaged'
                      orientation='horizontal'
                      invalid={
                        !!getIn(errors, `engineRoom.mainEngines.${idx}.tcco`)
                      }
                    >
                      <McRadio
                        name='tcco'
                        value='true'
                        label='Yes'
                        checked={getIn(engine, 'tcco.value') === true}
                        change={handleTccoChange(idx, 'true')}
                      />
                      <McRadio
                        name='tcco'
                        value='false'
                        label='No'
                        checked={getIn(engine, 'tcco.value') === false}
                        change={handleTccoChange(idx, 'false')}
                      />
                    </McRadioGroup>
                  </SimpleFormRow>
                )}
              </React.Fragment>
            ))
          }
        />
      </FormSection>
      <FormSection header='Report comments'>
        <SimpleFormRow>
          <HDCTextareaField
            name='engineRoom.comment'
            label='Additional reporting comments'
          />
        </SimpleFormRow>
      </FormSection>
      {isSubmitting && <OverlayLoader padding='0px' />}
    </>
  )
}

export default PropulsionStep
