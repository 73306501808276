import { type ReactNode, useContext } from 'react'
import {
  type IMcNotification,
  type NotificationAppearance,
} from '@maersk-global/mds-components-core/mc-notification/types'

import { StyledMcNotification, Wrapper } from './Notifications.styles'
import { WindowContext } from '../../contexts'

type Props = {
  alerts?: Array<ReactNode | IMcNotification>
  warnings?: Array<ReactNode | IMcNotification>
  advices?: Array<ReactNode | IMcNotification>
}

const isIMcNotification = (
  n: ReactNode | IMcNotification,
): n is IMcNotification => {
  return (
    (n as IMcNotification).body !== undefined ||
    (n as IMcNotification).heading !== undefined ||
    (n as IMcNotification).actions !== undefined
  )
}

const Notifications = (props: Props) => {
  const { windowSize } = useContext(WindowContext)

  if (Object.values(props).every((p) => !p?.length)) {
    return null
  }

  const renderNotification = (
    notification: ReactNode | IMcNotification,
    appearance: NotificationAppearance,
    icon: string,
    idx: number,
  ) => {
    if (isIMcNotification(notification)) {
      return (
        <StyledMcNotification
          key={idx}
          fit={windowSize}
          appearance={appearance}
          icon={icon}
          heading={notification.heading}
          body={notification.body}
          actions={notification.actions}
        />
      )
    }

    return (
      <StyledMcNotification
        key={idx}
        fit={windowSize}
        appearance={appearance}
        icon={icon}
      >
        {notification}
      </StyledMcNotification>
    )
  }

  return (
    <Wrapper>
      {props.alerts?.map((alert, idx) =>
        renderNotification(alert, 'error', 'exclamation-octagon', idx),
      )}
      {props.warnings?.map((warning, idx) =>
        renderNotification(warning, 'warning', 'exclamation-triangle', idx),
      )}
      {props.advices?.map((advice, idx) =>
        renderNotification(advice, 'info', 'light-bulb', idx),
      )}
    </Wrapper>
  )
}

export default Notifications
