import { useContext, useEffect, useState } from 'react'

import { Performance } from '../../../../api-models'
import * as PerformanceApi from '../../../../services/performance'
import { Loading, Modal } from '../../../../commons'
import { displayErrorModal, FUEL_TYPES_FDL4 } from '../../../../utils'
import { VesselPageContext } from '../../../../contexts'
import { BatchTransferForm } from './BatchTransferForm'

type Props = {
  entryId?: string
  closeHandler: (refreshAdjustments?: boolean) => void
}

export const EditBatchSelectionModal = ({ entryId, closeHandler }: Props) => {
  const imoNo = useContext(VesselPageContext).imoNo!
  const [batchSelection, setBatchSelection] =
    useState<Performance.FuelOilStock.BatchSelectionResponse>()
  const [batch, setBatch] = useState<Performance.FuelOilStock.BatchResponse>()

  useEffect(() => {
    if (entryId) {
      PerformanceApi.getBatchSelection(imoNo, entryId)
        .then(setBatchSelection)
        .catch((e) => {
          void displayErrorModal(e)
        })
    }
  }, [entryId, imoNo])

  useEffect(() => {
    if (batchSelection?.batchId) {
      PerformanceApi.getBatchesById(imoNo, [batchSelection?.batchId])
        .then((batchResponse) => {
          if (batchResponse?.length > 0) setBatch(batchResponse[0])
        })
        .catch((e) => {
          void displayErrorModal(e)
        })
    }
  }, [batchSelection, imoNo])

  const updateBatchSelection = (
    batchSelection: Performance.FuelOilStock.BatchSelection,
    entryId: string,
  ): any => {
    if (imoNo && entryId)
      return PerformanceApi.putBatchSelection(imoNo, entryId, batchSelection)
        .then(() => closeHandler(true))
        .catch((e) => {
          void displayErrorModal({
            statusText: 'Could not save batch selection',
            message: e.message,
          })
        })
  }

  const deleteBatchSelection = (entryId: string) => {
    if (imoNo && entryId)
      PerformanceApi.deleteBatchSelection(imoNo, entryId)
        .then(() => closeHandler(true))
        .catch((e) => {
          void displayErrorModal({
            statusText: 'Could not delete batch selection',
            message: e.message,
          })
        })
  }

  return (
    <Modal
      visible
      helpTextKey='consumption-selection/batch'
      title='Change of transfer from storage'
      closeHandler={closeHandler}
    >
      {(!entryId || !batch || !batchSelection) && <Loading width='unset' />}
      {batch && batchSelection && entryId && (
        <BatchTransferForm
          closeHandler={closeHandler}
          submit={(batchSelection: Performance.FuelOilStock.BatchSelection) =>
            updateBatchSelection(batchSelection, entryId)
          }
          fuelTypeForBatchSelection={FUEL_TYPES_FDL4[batch.fuel.type]}
          batchSelection={batchSelection}
          deleteHandler={() => deleteBatchSelection(entryId)}
        />
      )}
    </Modal>
  )
}
