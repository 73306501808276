import { Bunkering, Overview, Sounding } from './lube-management'
import {
  CanalTransit,
  Events,
  PortCall,
  RemainingDistance,
  ReportNavigation,
  ReportTypes,
} from './reporting'

export const HELP_SECTION_TOPICS = {
  fuel: {
    name: 'Fuel',
    topics: {
      lube: {
        name: 'Lube management',
        path: 'lube-management',
        updatedAt: '08-02-2022',
        subtopics: [
          { name: 'Overview of lube', component: Overview },
          { name: 'Bunkering, transfer and loss', component: Bunkering },
          { name: 'Sounding', component: Sounding },
        ],
      },
      reporting: {
        name: 'Reporting',
        path: 'reporting',
        updatedAt: '23-03-2022',
        subtopics: [
          { name: 'Canal transit', component: CanalTransit },
          { name: 'Events', component: Events },
          { name: 'Reporting navigation', component: ReportNavigation },
          { name: 'Report types', component: ReportTypes },
          { name: 'Reporting port call', component: PortCall },
          {
            name: 'Reporting remaining distance',
            component: RemainingDistance,
          },
        ],
      },
    },
  },
}
