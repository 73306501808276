import React, { FunctionComponent, useState } from 'react'
import { Dropdown } from '../commons'
import { VolumeToMassCalculator } from '../features/volume-to-mass-calculator'
import { EnergyManagementSimulator } from '../features/energy-management-simulator'
import { useVesselConfiguration } from '../hooks'
import { LcvCalculator } from '../features/lcv-calculator'

interface ToolboxProps {
  imoNo: string
  isVesselSpecificPage: boolean
}

export const Toolbox: FunctionComponent<ToolboxProps> = ({
  imoNo,
  isVesselSpecificPage,
}) => {
  const vesselConfiguration = useVesselConfiguration(imoNo)
  const [showVolumeToMassCalculator, setShowVolumeToMassCalculator] =
    useState<boolean>(false)
  const [showLcvCalculator, setShowLcvCalculator] = useState<boolean>(false)
  const [showEnergyManagementSimulator, setShowEnergyManagementSimulator] =
    useState<boolean>(false)

  const closeVolumeToMassCalculator = () => setShowVolumeToMassCalculator(false)

  const closeLcvCalculator = () => setShowLcvCalculator(false)

  return (
    <React.Fragment>
      <Dropdown
        id='toolbox'
        triggerId='toolbox-trigger'
        triggerChildren='Tools'
        menuPosition={{ right: '0px' }}
      >
        <li
          id='mass-calculator'
          onClick={() => {
            setShowVolumeToMassCalculator(true)
          }}
        >
          <span style={{ width: '226px', display: 'block' }}>
            Volume to MT Calculator
          </span>
        </li>
        <li
          id='lcv-calculator'
          onClick={() => {
            setShowLcvCalculator(true)
          }}
        >
          <span style={{ width: '226px', display: 'block' }}>
            LCV Calculator
          </span>
        </li>
        {isVesselSpecificPage && vesselConfiguration && (
          <li
            id='energy-simulator'
            onClick={() => {
              setShowEnergyManagementSimulator(true)
            }}
          >
            <span style={{ width: '226px', display: 'block' }}>
              Energy management simulator
            </span>
          </li>
        )}
      </Dropdown>
      {showVolumeToMassCalculator && (
        <VolumeToMassCalculator
          onCancel={closeVolumeToMassCalculator}
          onSave={closeVolumeToMassCalculator}
          onClose={closeVolumeToMassCalculator}
          toolContext
          labelText='Fuel type'
        />
      )}
      {showLcvCalculator && <LcvCalculator onClose={closeLcvCalculator} />}
      {isVesselSpecificPage &&
        vesselConfiguration &&
        showEnergyManagementSimulator && (
          <EnergyManagementSimulator
            onClose={() => setShowEnergyManagementSimulator(false)}
            imoNo={imoNo}
            vesselConfiguration={vesselConfiguration}
          />
        )}
    </React.Fragment>
  )
}
