import { formatValue } from '../../utils'
import { UNITS } from '../../utils/constants'

export function validateMainEnginePower(
  value: string,
  mainEng1MaxSetPoint: number,
  mainEng1Mcr: number,
) {
  const mePower = parseInt(value)
  const maxLoad = mainEng1MaxSetPoint * 0.01 * mainEng1Mcr
  const minLoad = mainEng1Mcr * 0.1
  if (mePower > maxLoad)
    return `The input is higher than the engine's max load (${formatValue(
      maxLoad - 1,
      0,
    )} kW)`
  if (mePower < minLoad)
    return `The input is lower than the engine's min load (${formatValue(
      minLoad + 1,
      0,
    )} ${UNITS.KILO_WATT})`
  return undefined
}

export function validatePropulsionPower(
  value: string,
  mainEng1MaxSetPoint: number,
  maxShaft1Power: number,
  maxShaft2Power: number,
  mainEng1Mcr: number,
) {
  const mePower = parseInt(value)
  const maxLoad =
    mainEng1MaxSetPoint * 0.01 * mainEng1Mcr + maxShaft1Power + maxShaft2Power
  const minLoad = mainEng1Mcr * 0.1
  if (mePower > maxLoad)
    return `The input is higher than max propulsion power for this vessel (${formatValue(
      maxLoad - 1,
      0,
    )} kW)`
  if (mePower < minLoad)
    return `The input is lower than the engine's min load (${formatValue(
      minLoad + 1,
      0,
    )} ${UNITS.KILO_WATT})`
  return undefined
}

export function validateElectricalDemand(
  value: string,
  maxElectricalPower: number,
) {
  const electricalDemand = parseInt(value)
  if (electricalDemand > maxElectricalPower)
    return `The input is more than the vessel can produce (${formatValue(
      maxElectricalPower,
      0,
    )} ${UNITS.KILO_WATT})`
  if (electricalDemand < 0) return `Input cannot be less than 0`
  return undefined
}
