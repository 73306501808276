import React from 'react'
import { Event } from '../../../api-models/hdc/events'
import {
  ApiReportType,
  HdcReportResponse,
  IReport,
} from '../../../api-models/hdc/report'
import { FuelLineDataLoss } from '../../../api-models/performance/fuel-consumption'
import { CreateInitialReportPayload } from '../../../services/hdc'
import { ESubReport, HDCFormType } from '../types'
import { HDCReducerState, ReportTimestamp } from './reducer'

export interface HDCContextProps {
  imoNo: string
  state: HDCReducerState
  initiateReport: (
    initialReport: CreateInitialReportPayload,
  ) => Promise<HdcReportResponse>
  getReport: (id) => Promise<IReport>
  getReports: () => Promise<IReport[]>
  setReportType: (type: ApiReportType | null) => void
  setReportTimestamp: (timestamp: ReportTimestamp) => void
  setReport: (report: IReport) => void
  setEvents: (events: Event[], subReport: ESubReport) => void
  setFormType: (formType: HDCFormType | null) => void
  setFormCurrentStep: (stepCount: number) => void
  setFormSteps: (steps: string[] | null) => void
  setFormUseCustomArrival: (useCustomPort: boolean) => void
  setFormUseCustomDeparture: (customDeparture: boolean) => void
  setHasUnsavedEvent: (hasEvent: boolean) => void
  setHasValidationError: (formType, step, isInvalid) => void
  setHasValidationWarning: (formType, step, hasWarning) => void
  setFuelDataLosses: (losses: FuelLineDataLoss[]) => void
  setInvalidReports: (reportIds: string[]) => void
  resetState: () => void
}

const HDCContext = React.createContext<HDCContextProps>({} as HDCContextProps)

export default HDCContext
