import React, { useContext } from 'react'
import { formatTimeAgo } from '../utils'
import { Pill } from './Pill'
import { ConnectionStatusesContext } from '../contexts'
import { feedback, grey } from '../theme_new/colors'

interface StatusPillProps {
  imoNo: number
}

const getStatusColor: (status: number) => string = (status) => {
  switch (status) {
    case 1:
      return feedback.success.light
    case 2:
    case 3:
      return feedback.danger.light
    default:
      return grey[300]
  }
}

export const StatusPill = (props: StatusPillProps) => {
  const connectionStatusesContext = useContext(ConnectionStatusesContext)
  const connectionStatus = connectionStatusesContext.connectionStatuses
    ? connectionStatusesContext.connectionStatuses[props.imoNo]
    : undefined
  return connectionStatus ? (
    <StatusPillMemo
      status={connectionStatus.lastLogStatus}
      seconds={
        connectionStatus.lastLogSeconds + connectionStatusesContext.seconds
      }
    />
  ) : null
}

interface StatusPillMemoProps {
  status: number
  seconds: number
}

const StatusPillMemo = React.memo(
  (props: StatusPillMemoProps) => (
    <Pill bg={getStatusColor(props.status)}>
      {formatTimeAgo(props.seconds)}
    </Pill>
  ),
  (prevProps: StatusPillMemoProps, nextProps: StatusPillMemoProps) =>
    formatTimeAgo(prevProps.seconds) === formatTimeAgo(nextProps.seconds) &&
    prevProps.status === nextProps.status,
)
