import { useState } from 'react'

import styled from '../theme'
import { NotificationBadge } from '../commons'

const TabNav = styled.ul`
  display: block !important;
  width: 100%;
  border-bottom: 4px solid #c4c4c4;
  height: 44px;
  margin: 16px 0 !important;
  padding-top: 4px !important;

  li {
    display: inline-flex;
    font-size: 15px;
    padding: 0 16px !important;
    color: #878787;
    white-space: nowrap;
    align-items: center;
    justify-content: center;
    box-sizing: content-box;

    span {
      margin: auto;
    }

    &:hover {
      cursor: pointer;
      color: #093d5f;
    }

    &.active {
      height: 100%;
      border-bottom: 4px solid #093d5f;
      color: #093d5f;
    }
  }
`

type TabConfig = {
  name: string
  notifications?: number
  hide?: boolean
  onClick?: () => void
  bg?: string
  fg?: string
}

type Props = {
  startIndex?: number
  tabButtons: Array<TabConfig>
  children: ReadonlyArray<JSX.Element>
}

export const Tabs = ({ tabButtons, startIndex = 0, children }: Props) => {
  const [activeIndex, setActiveIndex] = useState<number>(startIndex)

  if (children.length === tabButtons.length) {
    return (
      <div>
        <TabNav>
          {tabButtons.map((tab, i) => {
            if (!tab.hide) {
              return (
                <li
                  className={i === activeIndex ? 'active' : ''}
                  key={tab.name}
                  onClick={() => {
                    setActiveIndex(i)
                    if (tab.onClick) tab.onClick()
                  }}
                >
                  <div style={{ marginRight: '4px' }}>{tab.name}</div>
                  {!!tab.notifications && (
                    <NotificationBadge
                      notificationCount={tab.notifications}
                      bg={tab.bg}
                      fg={tab.fg}
                    />
                  )}
                </li>
              )
            }
            return ''
          })}
        </TabNav>
        {children.filter((page, i) => {
          return i === activeIndex
        })}
      </div>
    )
  } else {
    return <h3>The length of children should be the same as tab buttons</h3>
  }
}
