import React, { ReactNode, useEffect, useState } from 'react'
import styled from 'styled-components'
import { maerskBlue } from '../../../../theme'

import Collapse from './collapse'
import Toggle from './toggle'

const Component = styled.div``

type Props = {
  defaultActiveKey?: number | null
  selectedActiveKey?: number | null
  collapsables: Array<{
    content: string | ReactNode
    header?: string
    disabled?: boolean
    showOrder?: boolean
    activeColor?: string
    inactiveColor?: string
  }>
}

/**
 * Accordion
 * @description TBD
 * @param {number} defaultActiveKey Default active key defines which accordion child that should be open on init.
 * @param {number} selectedActiveKey Selected active key allows opening accordion child from parent component.
 */
const Accordion = ({
  defaultActiveKey = null,
  selectedActiveKey = null,
  collapsables,
}: Props) => {
  const [activeKey, setActiveKey] = useState<number | null>(defaultActiveKey)

  useEffect(() => {
    setActiveKey(selectedActiveKey)
  }, [selectedActiveKey])

  return (
    <Component>
      {collapsables.map(
        (
          {
            header,
            disabled,
            content,
            showOrder = false,
            activeColor = maerskBlue[400],
            inactiveColor = maerskBlue[400],
          },
          idx,
        ) => (
          <React.Fragment key={`${header}-${idx}`}>
            {header ? (
              <>
                <Toggle
                  activeKey={idx}
                  isActive={idx !== activeKey}
                  disabled={disabled}
                  showOrder={showOrder}
                  activeColor={activeColor}
                  inactiveColor={inactiveColor}
                  onClick={() => {
                    if (disabled) {
                      return
                    }

                    const nextActiveKey = activeKey === idx ? null : idx
                    return setActiveKey(nextActiveKey)
                  }}
                >
                  {header}
                </Toggle>
                <Collapse activeKey={idx} isActive={activeKey === idx}>
                  {content}
                </Collapse>
              </>
            ) : (
              <>{content}</>
            )}
          </React.Fragment>
        ),
      )}
    </Component>
  )
}

export default Accordion
