import styled from 'styled-components'

export const Wrapper = styled.div`
  padding: 20px;
  width: 500px;
`

export const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  background-color: #f8f8f8;
  padding: 10px;
`

export const Description = styled.p`
  font-size: 16px;
  line-height: 1.4;
  margin-bottom: 15px;
`

export const Totalizers = styled.div`
  margin-top: 15px;
`

export const Totalizer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 7px 0;

  div:first-child {
    font-size: 14px;
    font-weight: 600;
  }

  div:last-child {
    font-size: 14px;
    font-weight: 400;
  }
`

export const Header = styled.h2`
  font-size: 16px;
  font-weight: 600;
  border-bottom: 1px solid #e7e7e7;
  margin-top: 15px;
  padding: 0 0 10px;
`
