import styled from 'styled-components'
import { McTag } from '@maersk-global/mds-react-wrapper'

export const StyledMcTag = styled(McTag)`
  position: relative;
  float: right;
  bottom: -17px;
  cursor: pointer;

  @media (min-width: ${(props) => props.theme.breakPoints.medium}px) {
    bottom: -19px;
  }

  @media (min-width: ${(props) => props.theme.breakPoints.large}px) {
    bottom: -21px;
  }
`
