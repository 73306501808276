import { useHistory, useParams } from 'react-router'
import { McButton } from '@maersk-global/mds-react-wrapper'

import { EFuelConsumptionState } from '../../../api-models/performance/fuel-consumption'
import { Icon } from '../../../commons'
import { routerParams } from '../../../routes'
import styled, { info, lg, md, media, sm } from '../../../theme'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  ${media.sm`font-size: ${sm.NOTIFICATION_BAR_FONT_SIZE};`};
  ${media.md`font-size: ${md.NOTIFICATION_BAR_FONT_SIZE};`};
  ${media.lg`font-size: ${lg.NOTIFICATION_BAR_FONT_SIZE};`};
  ${media.sm`height: ${sm.NOTIFICATION_BAR_HEIGHT};`};
  ${media.md`height: ${md.NOTIFICATION_BAR_HEIGHT};`};
  ${media.lg`height: ${lg.NOTIFICATION_BAR_HEIGHT};`};

  button {
    padding: 4px 8px;
    margin-left: 8px;
    font-size: 13px;
  }

  span {
    vertical-align: middle;
    display: inline-block;
  }

  i svg.fa-info-circle {
    width: 20px;
    height: 20px;
  }
`

type Props = {
  type: EFuelConsumptionState
}

const ConsumptionStateNotification = ({ type }: Props) => {
  const history = useHistory()
  const { vesselId } = useParams<routerParams>()

  const content = {
    [EFuelConsumptionState.Recoverable]:
      'Could not detect data loss because latest ROB is too far back in time. Please recover stock and current batch selection to get back on track',
    [EFuelConsumptionState.StartUp]:
      'Could not detect data loss because stock management has not been started',
  }

  return (
    <Wrapper>
      <Icon
        icon='fal fa-info-circle'
        style={{ width: '20px', height: '20px', marginRight: '8px' }}
      />
      <span>{content[type]}</span>
      <McButton
        label='Go to Stock Management'
        appearance='neutral'
        click={() => {
          history.push(`/MaerskStarConnect/vessel/${vesselId}/stock-management`)
        }}
        type='button'
      />
    </Wrapper>
  )
}

export const getStartUpNotification = () => ({
  id: 'start-up-notification',
  theme: info,
  content: (
    <ConsumptionStateNotification type={EFuelConsumptionState.StartUp} />
  ),
})

export const getRecoveryNotification = () => ({
  id: 'start-up-notification',
  theme: info,
  content: (
    <ConsumptionStateNotification type={EFuelConsumptionState.Recoverable} />
  ),
})
