import { useContext, useState } from 'react'
import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import Moment from 'moment'
import { v4 as uuidv4 } from 'uuid'
import { McButton } from '@maersk-global/mds-react-wrapper'

import {
  getConsumedMassInPeriod,
  postManualCorrectionOverwrite,
} from '../../../../services/performance'
import {
  displayErrorModal,
  FuelLineType,
  getClosestTenMinute,
} from '../../../../utils'
import { VesselPageContext } from '../../../../contexts'
import {
  DateTimeInput,
  FormSelect,
  Loading,
  TextArea,
} from '../../../../commons'
import TotalizerModal from '../totalizer-modal'
import * as S from './style'
import { amountPerHour } from '../../utils/fuel-consumption'
import HDCInputField from '../../../hybrid-data-collector/components/hdc-input-field'
import { UNITS } from '../../../../utils/constants'

type TFuelLine = {
  label: string
  value: FuelLineType
}

type TReason = { value: number; label: string }

type Props = {
  refreshData: Function
  fuelLineOptions: TFuelLine[]
  id: string
}

const FuelConsumptionOverwriteForm = ({
  refreshData,
  fuelLineOptions,
  id,
}: Props) => {
  const imoNo = useContext(VesselPageContext).imoNo!
  const initialValues = {
    startTimestamp: getClosestTenMinute(
      Moment().subtract(10, 'minutes'),
    ).toISOString(),
    endTimestamp: getClosestTenMinute(Moment()).toISOString(),
    consumedMass: null,
    reason: null,
    fuelLineType: null,
    notes: '',
  }
  const [totalizerActive, setTotalizerActive] = useState(false)
  const [calculatedConsumption, setCalculatedConsumption] = useState<
    number | null
  >(null)

  const getCalculatedConsumption = async (
    startDate: string,
    endDate: string,
    fuelLineType: number | null,
  ) => {
    if (!startDate || !endDate || fuelLineType === null) {
      return null
    }
    const response = await getConsumedMassInPeriod(
      imoNo,
      { from: startDate, to: endDate },
      fuelLineType,
    )
    return response.hasData ? response.consumedMass : null
  }

  // Submit handler
  const handleSubmit = async (values, helpers) => {
    try {
      await postManualCorrectionOverwrite(imoNo, values)
      refreshData()
      helpers.resetForm()
    } catch (err) {
      void displayErrorModal({
        statusText: 'Failed to overwrite',
        message: err.message,
      })
    } finally {
      helpers.setSubmitting(false)
    }
  }

  // Data loss reasons
  const reasons: TReason[] = [
    { value: 10, label: 'Calibrating flowmeters to zero (Constant)' },
    { value: 11, label: 'Unexpected leaks (Factor)' },
    { value: 15, label: 'Flushing (Constant)' },
    { value: 20, label: 'Inaccurate measurement (Factor)' },
    { value: 25, label: 'Defect flowmeter (Constant)' },
  ]

  // Formik validation schema
  const validationSchema = Yup.object().shape({
    startTimestamp: Yup.date().required('Please provide a start time'),
    endTimestamp: Yup.date().required('Please provide an end time'),
    consumedMass: Yup.number()
      .nullable()
      .typeError('Please provide consumed mass')
      .required('Please provide consumed mass'),
    reason: Yup.mixed().required('Please provide a reason'),
    fuelLineType: Yup.mixed().required('Please select fuel line'),
    notes: Yup.string().max(1000, 'Limit of 1000 characters exceeded'),
  })

  return (
    <>
      <S.Paragraph>
        Add your data estimation to the data shown in the above fuel line. The
        overwrite will effect the graph, and it will also be shown per fuel line
        indicated in blue.
      </S.Paragraph>

      <Formik
        key={id}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        // validateOnChange={false}
      >
        {({
          values,
          touched,
          errors,
          handleChange,
          setFieldValue,
          isSubmitting,
        }) => (
          <Form>
            <S.Container>
              {isSubmitting && (
                <S.Loading>
                  <Loading />
                </S.Loading>
              )}
              <S.LossContainer>
                <S.Header>Overwrite period</S.Header>
                <S.Label>Overwrite from</S.Label>
                <Field
                  value={values.startTimestamp}
                  name='startTimestamp'
                  component={(props) => (
                    <DateTimeInput
                      id={uuidv4()}
                      {...props}
                      selected={Moment.utc(values.startTimestamp)}
                      onChange={async (val) => {
                        const newDate = val.toISOString()
                        setFieldValue('startTimestamp', newDate)
                        const calculatedConsumedMass =
                          await getCalculatedConsumption(
                            newDate,
                            values.endTimestamp,
                            values.fuelLineType,
                          )
                        if (calculatedConsumedMass !== null) {
                          setCalculatedConsumption(calculatedConsumedMass)
                          setFieldValue('consumedMass', calculatedConsumedMass)
                        }
                      }}
                    />
                  )}
                />

                <S.Label>Overwrite to</S.Label>
                <Field
                  value={values.endTimestamp}
                  name='endTimestamp'
                  component={(props) => (
                    <DateTimeInput
                      id={uuidv4()}
                      {...props}
                      selected={Moment.utc(props.field.value)}
                      minDate={Moment.utc(values.startTimestamp).add(10, 'm')}
                      maxDate={Moment.utc(new Date())}
                      onChange={async (val) => {
                        const newDate = val.toISOString()
                        setFieldValue('endTimestamp', newDate)
                        const calculatedConsumedMass =
                          await getCalculatedConsumption(
                            values.startTimestamp,
                            newDate,
                            values.fuelLineType,
                          )
                        if (calculatedConsumedMass !== null) {
                          setCalculatedConsumption(calculatedConsumedMass)
                          setFieldValue('consumedMass', calculatedConsumedMass)
                        }
                      }}
                    />
                  )}
                  onChange={handleChange}
                />

                <S.Button>
                  <McButton
                    type='button'
                    variant='outlined'
                    label='Totalizer at specific time'
                    icon='cog'
                    click={() => setTotalizerActive(true)}
                  />
                </S.Button>

                <S.Label>Insert comment</S.Label>
                <TextArea
                  name='notes'
                  value={values.notes}
                  onChange={handleChange}
                />
                {touched.notes && errors.notes && (
                  <S.ErrorLabel>{errors.notes}</S.ErrorLabel>
                )}
              </S.LossContainer>

              <S.ConsumptionContainer>
                <S.Header>Consumption & Reason</S.Header>

                <S.Label>Choose fuelline</S.Label>
                <Field
                  component={FormSelect}
                  name='fuelLineType'
                  options={fuelLineOptions}
                  onChange={async (val) => {
                    setFieldValue('fuelLineType', val.value)
                    const calculatedConsumedMass =
                      await getCalculatedConsumption(
                        values.startTimestamp,
                        values.endTimestamp,
                        val.value,
                      )
                    if (calculatedConsumedMass !== null) {
                      setCalculatedConsumption(calculatedConsumedMass)
                      setFieldValue('consumedMass', calculatedConsumedMass)
                    }
                  }}
                />
                {touched.fuelLineType && errors.fuelLineType && (
                  <S.ErrorLabel>{errors.fuelLineType}</S.ErrorLabel>
                )}

                <S.ConsumptionField>
                  <HDCInputField
                    fit='medium'
                    name='consumedMass'
                    type='number'
                    label='Insert est. consumption'
                    decimals={3}
                    addon={UNITS.METRIC_TON}
                    error={errors.consumedMass}
                    note={
                      calculatedConsumption !== null &&
                      values.consumedMass !== calculatedConsumption
                        ? `Original value was ${calculatedConsumption}`
                        : ' '
                    }
                  />
                  {!!values.consumedMass && (
                    <S.ConsumptionPerHour>
                      {`${amountPerHour(
                        values.startTimestamp,
                        values.endTimestamp,
                        values.consumedMass!,
                      )} MT/h`}
                    </S.ConsumptionPerHour>
                  )}
                </S.ConsumptionField>

                <S.Label>Choose a reason</S.Label>
                <Field component={FormSelect} name='reason' options={reasons} />
                {touched.reason && errors.reason && (
                  <S.ErrorLabel>{errors.reason}</S.ErrorLabel>
                )}
              </S.ConsumptionContainer>
            </S.Container>

            <S.Actions>
              <McButton label='Save' appearance='primary' type='submit' />
            </S.Actions>
          </Form>
        )}
      </Formik>

      <TotalizerModal
        handleClose={() => setTotalizerActive(false)}
        visible={totalizerActive}
        timestamp={new Date().toISOString()}
      />
    </>
  )
}

export default FuelConsumptionOverwriteForm
