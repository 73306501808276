import * as React from 'react'
import moment from 'moment'
import { Performance } from '../../../api-models'
import { IVesselPageContext } from '../../../contexts'
import {
  Filters,
  getDateRange,
  filtersChanged,
  DefaultFilters,
} from '../../../features/filter'
import {
  formatValue,
  isFeatureEnabled,
  getTimestampFromVesselStatus,
} from '../../../utils'
import { withVesselPageContext } from '../../../higher-order-components'
import {
  ChartStencil,
  ContentCard,
  FancyLabel,
  ChartContainer,
  NotFound,
  ValueLabel,
  VesselStatusBlock,
  Block,
} from '../../../commons'
import { UNITS } from '../../../utils/constants'
import { greenColorScheme, lightOrangeColorScheme } from '../../../theme'
import { Hydra } from '../../../api-models/hydra'
import { CostElectricalProductionChart } from '../'
import { getDefaultFilters } from '../../../utils'
import { displayErrorModal } from '../../../utils'
import {
  getElectricalProductionCost,
  getElectricalProductionCostStatus,
} from '../../../services/hydra'

interface CostElectricalProductionChartContainerProps
  extends IVesselPageContext {
  vesselStatus: Performance.Status.Status
  filters?: Filters
}

interface CostElectricalProductionChartContainerState {
  dataElectricalProduction?: Hydra.ElectricalProduction.ElectricalProductionCost
  electricalProductionStatus?: Hydra.ElectricalProduction.ElectricalProductionCost
  queryPeriod?: Hydra.Period
  hasData?: boolean
  showTEMAdvice: boolean
}

export class CostElectricalProductionChartContainer extends React.Component<
  CostElectricalProductionChartContainerProps,
  CostElectricalProductionChartContainerState
> {
  defaultFilters: DefaultFilters

  constructor(props: CostElectricalProductionChartContainerProps) {
    super(props)
    this.defaultFilters = getDefaultFilters(10, 'd')
    this.state = {
      showTEMAdvice: true,
    }
  }

  loadData() {
    this.loadElectricalCostData()
    this.loadElectricalCostStatus()
  }

  loadElectricalCostData() {
    const { imoNo, filters } = this.props
    if (
      imoNo &&
      isFeatureEnabled('electrical-production/electrical-production-costs')
    ) {
      const queryPeriod = getDateRange(this.defaultFilters.dateRange, filters)
      getElectricalProductionCost(imoNo, queryPeriod)
        .then((json: Hydra.ElectricalProduction.ElectricalProductionCost) => {
          this.setState({
            dataElectricalProduction: json,
            hasData: json.hasData,
            queryPeriod: json.queryPeriod,
          })
        })
        .catch((e) => displayErrorModal(e))
    }
  }

  loadElectricalCostStatus() {
    const { imoNo, vesselStatus } = this.props
    if (
      imoNo &&
      isFeatureEnabled('electrical-production/electrical-production-costs') &&
      vesselStatus &&
      vesselStatus.hasData
    ) {
      getElectricalProductionCostStatus(
        imoNo,
        moment.utc(vesselStatus.timestamp).toISOString(),
      )
        .then((json: Hydra.ElectricalProduction.ElectricalProductionCost) => {
          this.setState({
            electricalProductionStatus: json,
          })
        })
        .catch((e) => displayErrorModal(e))
    }
  }

  componentDidMount() {
    this.loadData()
  }

  componentDidUpdate(prevProps: CostElectricalProductionChartContainerProps) {
    const { imoNo, filters, vesselStatus } = this.props
    if (
      imoNo !== prevProps.imoNo ||
      filtersChanged(prevProps.filters, filters) ||
      prevProps.vesselStatus?.timestamp !== vesselStatus?.timestamp
    ) {
      this.setState(
        {
          dataElectricalProduction: undefined,
          electricalProductionStatus: undefined,
        },
        () => {
          this.loadData()
        },
      )
    }
  }

  render() {
    const {
      dataElectricalProduction,
      queryPeriod,
      electricalProductionStatus,
      hasData,
      showTEMAdvice,
    } = this.state
    const { vesselStatus } = this.props
    return (
      <div className='full-components'>
        <ContentCard
          id='electrical-production-bars-fc'
          title='Fuel cost for electrical production'
          width=''
          helpTextKey='electrical-balance/fuel-cost'
          additionalInfo={
            <span>{getTimestampFromVesselStatus(vesselStatus)}</span>
          }
        >
          {hasData !== false && (
            <ChartContainer
              y1Label={{
                name: UNITS.GRAM_KILOWATT_HOUR,
                colorScheme: lightOrangeColorScheme,
              }}
              sideContentWidth={136}
            >
              {!dataElectricalProduction && <ChartStencil chartType='line' />}
              {!!dataElectricalProduction && queryPeriod && (
                <CostElectricalProductionChart
                  dataElectricalProduction={dataElectricalProduction}
                  queryPeriod={queryPeriod}
                  showTEMAdvice={showTEMAdvice}
                />
              )}
              {!!vesselStatus && (
                <VesselStatusBlock timestamp={vesselStatus.timestamp}>
                  <Block>
                    <FancyLabel colorScheme={lightOrangeColorScheme}>
                      Fuel cost
                    </FancyLabel>
                    <ValueLabel unit={UNITS.GRAM_KILOWATT_HOUR}>
                      {formatValue(
                        electricalProductionStatus &&
                          electricalProductionStatus.hasData
                          ? electricalProductionStatus.calculatedResults
                              .costOfElectricalProduction[0]
                          : null,
                        2,
                      )}
                    </ValueLabel>
                  </Block>
                  <Block>
                    <FancyLabel
                      colorScheme={greenColorScheme}
                      value={showTEMAdvice}
                      onChange={() =>
                        this.setState({ showTEMAdvice: !showTEMAdvice })
                      }
                    >
                      Energy advice
                    </FancyLabel>
                    <ValueLabel unit={UNITS.GRAM_KILOWATT_HOUR}>
                      {formatValue(
                        electricalProductionStatus &&
                          electricalProductionStatus.hasData
                          ? electricalProductionStatus.calculatedResults
                              .temCost[0]
                          : null,
                        2,
                      )}
                    </ValueLabel>
                  </Block>
                </VesselStatusBlock>
              )}
            </ChartContainer>
          )}
          {hasData === false && (
            <NotFound text='No data for the selected period found.' />
          )}
        </ContentCard>
      </div>
    )
  }
}

export default withVesselPageContext(CostElectricalProductionChartContainer)
