import React, { FC, useState, useEffect } from 'react'
import Select, { components } from 'react-select'
import styled, { FuelColors, grey } from '../../../../theme'
import { FuelType as FuelTypeEnum } from '../../../../utils/models'
import { FieldProps } from 'formik'

interface BatchSelectProps extends FieldProps {
  options?: Option[]
  width?: string
  menuHeaderText: string
  onChange?: (value?: any) => void
  onBlur: () => void
  noOptionsMessage?: (obj: { inputValue: string }) => string | null
}

interface Option {
  label: string
  value: string
  fuelType: string
  isDisabled?: boolean
}

interface OptionGroup {
  label: string
  options: Option[]
  isDisabled?: boolean
}

const SelectWrapper = styled.div<{ width?: string }>`
  width: ${({ width }) => width || '200px'};
`

const MenuListHeader = styled.div`
  padding: 5px;
`

const GroupHeadingWrapper = styled.div<{
  fill: string
  stroke: string
}>`
  background: ${({ fill }) => fill};
  border-top: 1px solid ${({ stroke }) => stroke};
  border-bottom: 1px solid ${({ stroke }) => stroke};
`

const HeaderText = styled.span<{
  font: string
}>`
  color: ${({ font }) => font};
`

const MenuContainer = styled.div`
  position: relative;
  width: 300px;
`

const Menu = (props) => {
  return (
    <MenuContainer>
      <components.Menu {...props}>{props.children}</components.Menu>
    </MenuContainer>
  )
}

const MenuList = (props) => {
  return (
    <components.MenuList {...props}>
      <MenuListHeader>{props.selectProps.menuHeaderText}</MenuListHeader>
      <hr />
      {props.children}
    </components.MenuList>
  )
}

const GroupHeading = (props) => {
  let theme = FuelColors[props.children]
  // This happens when the category is other
  if (!theme) {
    theme = {
      fill: grey[100],
      font: grey[900],
      stroke: grey[500],
    }
  }
  return (
    <GroupHeadingWrapper {...theme}>
      <components.GroupHeading {...props} style={{ margin: 0 }}>
        <HeaderText {...theme}>{props.children}</HeaderText>
      </components.GroupHeading>
    </GroupHeadingWrapper>
  )
}

const FuelTypesStrings = ['HS', 'VLS', 'ULS', 'MDO']

export const BatchSelect: FC<BatchSelectProps> = (props) => {
  const { field, form, menuHeaderText, noOptionsMessage, options, width } =
    props
  const [optionGroups, setOptionGroups] = useState<OptionGroup[]>([])
  useEffect(() => {
    const wasteOption = options?.find(({ value }) => value === 'waste')
    const optionHasOnlyWaste = wasteOption && options?.length === 1
    if (options && options?.length > 0 && !optionHasOnlyWaste) {
      const groups = FuelTypesStrings.map((fuelTypeString) => {
        const currentOptionGroup: OptionGroup = {
          label: fuelTypeString,
          options: [],
        }
        const optionsForCurrentFuelType = options.filter(
          ({ fuelType }) => fuelType === fuelTypeString,
        )
        if (optionsForCurrentFuelType.length > 0) {
          currentOptionGroup.options.push(...optionsForCurrentFuelType)
        } else {
          currentOptionGroup.options.push({
            fuelType: FuelTypeEnum[fuelTypeString],
            isDisabled: true,
            label: 'No batch on board for this fuel type',
            value: '',
          })
        }
        return currentOptionGroup
      })
      if (wasteOption) {
        groups.push({
          label: 'Other',
          options: [wasteOption],
        })
      }
      setOptionGroups(groups)
    }
  }, [options])

  return (
    optionGroups && (
      <SelectWrapper width={width}>
        <Select
          onMenuClose={() => form.setFieldTouched(field.name)}
          onChange={(option: any) => {
            if (option?.value) form.setFieldValue(field.name, option.value)
          }}
          value={
            field?.value && options?.find(({ value }) => value === field.value)
          }
          menuHeaderText={menuHeaderText}
          components={{
            GroupHeading,
            MenuList,
            Menu,
          }}
          styles={{
            group: (provided) => ({
              ...provided,
              paddingBottom: 0,
              paddingTop: 0,
            }),
            option: (provided) => ({
              ...provided,
              fontSize: '13px',
            }),
            valueContainer: (provided) => ({
              ...provided,
              fontSize: '13px',
            }),
          }}
          options={optionGroups}
          noOptionsMessage={noOptionsMessage}
          isOptionDisabled={({ isDisabled }) => isDisabled ?? false}
          menuPosition='fixed'
        />
      </SelectWrapper>
    )
  )
}
