import * as React from 'react'
import styled, { red } from '../../../theme'
import { InputFieldWithUnit } from '../../../commons'

const Wrapper = styled.div`
  .input-group {
    padding: 8px 0 2px 0;
    display: flex;
    align-items: center;

    label {
      padding: 4px 4px 4px 0;
      align-self: center;
      font-size: 14px;
      white-space: nowrap;
      display: flex;
      justify-content: flex-end;
    }
  }

  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  .input-field {
    flex: 1 1 0;
  }

  .input-feedback {
    font-size: 14px;
    color: ${red[600]};
    display: flex;
    justify-content: flex-end;
    width: 260px;
    padding-bottom: 8px;
  }
`

export const InputField = ({ id, label, field, form, ...props }: any) => {
  const error = form.errors[field.name]
  const touched = form.touched[field.name]
  return (
    <Wrapper>
      <div className={'input-group'}>
        {label && (
          <Label
            htmlFor={id}
            error={error}
            width={props.labelWidth ? props.labelWidth : '140px'}
          >
            {label}
          </Label>
        )}

        <div className='input-field'>
          <InputFieldWithUnit
            error={error && touched}
            onChange={(el: any) => {
              if (typeof el.target.value === 'string') {
                form.setFieldValue(field.name, el.target.value)
              }
            }}
            {...field}
            {...props}
          />
        </div>
      </div>
      {error && touched && <InputFeedback error={error} />}
    </Wrapper>
  )
}

const Label = ({ error, className, children, ...props }: any) => {
  return (
    <label className='label' style={{ width: props.width }} {...props}>
      {children}
    </label>
  )
}

const InputFeedback = ({ error }: any) =>
  error ? <div className='input-feedback'>{error}</div> : null
