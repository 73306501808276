import React, { useState, useCallback, FC } from 'react'
import { FieldProps } from 'formik'
import { FuelType } from '../../../../utils/models'
import { Performance } from '../../../../api-models'
import styled from '../../../../theme'
import { FormFuelLineChangeModal } from './FormFuelLineChangeModal'
import { FuelConsumers } from '../../../fdl-4/components/consumer-selection/FuelConsumers'

const ConsumersWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  & > div:not(:last-child) {
    margin-right: 16px;
  }
`

const SelectionTitle = styled.div`
  font-size: 14px;
  margin-bottom: 16px;
  display: flex;
  justify-content: left;
`

interface FormFuelTypeSelectionProps extends FieldProps {
  uniqueFuelTypesOnboard: FuelType[]
}

export const FormFuelTypeSelection: FC<FormFuelTypeSelectionProps> = ({
  field,
  form,
  uniqueFuelTypesOnboard,
}) => {
  const [selectedFuelLine, setSelectedFuelLine] = useState<number>()
  const [showFuelLineChangeModal, setShowFuelLineChangeModal] = useState(false)

  const addFuelTypeSelection = useCallback(
    (fuelType: FuelType) => {
      const newFuelTypeSelections = field.value.filter(
        (selection) => selection.fuelLineType !== selectedFuelLine,
      )
      if (selectedFuelLine)
        newFuelTypeSelections.push({
          fuelType,
          fuelLineType: selectedFuelLine,
        })
      form.setFieldValue(field.name, newFuelTypeSelections)
      setShowFuelLineChangeModal(false)
    },
    [field.name, field.value, form, selectedFuelLine],
  )

  const openFuelLineChangeModal = useCallback(
    (
      fuelLine:
        | Performance.Status.FuelLine
        | Performance.Status.MainEngFuelLine,
    ) => {
      const { fuelLineType } = fuelLine
      setSelectedFuelLine(fuelLineType)
      setShowFuelLineChangeModal(true)
    },
    [setSelectedFuelLine, setShowFuelLineChangeModal],
  )

  const closeFuelLineChangeModal = useCallback(() => {
    setShowFuelLineChangeModal(false)
    form.setFieldTouched(field.name)
  }, [field.name, form])
  return (
    <>
      <SelectionTitle>Consumers</SelectionTitle>
      <ConsumersWrapper>
        <FuelConsumers
          onClick={openFuelLineChangeModal}
          fuelTypeSelections={field.value}
        />
      </ConsumersWrapper>
      {showFuelLineChangeModal && selectedFuelLine && (
        <FormFuelLineChangeModal
          currentlySelectedFuelType={field.value?.find(
            (selection) => selection.fuelLineType === selectedFuelLine,
          )}
          uniqueFuelTypesOnboard={uniqueFuelTypesOnboard}
          fuelLineType={selectedFuelLine}
          closeHandler={closeFuelLineChangeModal}
          onSave={addFuelTypeSelection}
        />
      )}
    </>
  )
}
