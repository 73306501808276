import { useContext } from 'react'
import { useField } from 'formik'
import { McCheckbox } from '@maersk-global/mds-react-wrapper'

import { WindowContext } from '../../../contexts'

type Props = {
  name: string
  label: string
  disabled?: boolean
}

/**
 * A wrapper for McCheckbox that provides two-way data binding for Formik form
 * values based on the `name` prop.
 */
const Checkbox = ({ name, label, disabled }: Props) => {
  const { windowSize } = useContext(WindowContext)

  const [field, meta, helpers] = useField<boolean>(name)

  const handleChange = async (event: CustomEvent<boolean>) => {
    const target = event.target as HTMLInputElement

    await helpers.setValue(target.checked)
  }

  return (
    <McCheckbox
      fit={windowSize}
      name={name}
      label={label}
      checked={field.value ?? undefined}
      change={handleChange}
      invalid={Boolean(meta.error)}
      errormessage={meta.error}
      disabled={disabled}
    />
  )
}

export default Checkbox
