import { useContext, useState } from 'react'
import { McButton } from '@maersk-global/mds-react-wrapper'

import * as S from './StockOnBoard.styles'
import FuelAndStockModal from '../../../../../pages/ActivityLogPage/FuelAndStock/FuelAndStockModal'
import { StockEntryContext } from '../../../../../contexts'
import { ContentCard, Icon, Loading, NotFound } from '../../../../../commons'
import {
  EntryType,
  type StockOnBoard as IStockOnBoard,
  StockState,
} from '../../../models'
import { FuelTypeBatches } from '../FuelTypeBatches'
import { RobModal } from '../../rob/RobModal'
import { displayConfirmModal, displayErrorModal } from '../../../../../utils'
import { BatchSelectionsContainer } from '../../../../fdl-4/components/batch-selection/new/BatchSelectionsContainer'
import { Performance } from '../../../../../api-models'
import { FuelTypeTotals } from '../FuelTypeTotals'

type Props = {
  stockOnBoard?: IStockOnBoard | null
  stateOfStock?: StockState | null
  batchSelections?: Array<Performance.FuelOilStock.CurrentBatchSelectionResponse> | null
  setShowRecoveryModal: Function
  onSelectionsUpdate: () => void
}

export const StockOnBoard = ({
  stockOnBoard,
  stateOfStock,
  batchSelections,
  setShowRecoveryModal,
  onSelectionsUpdate,
}: Props) => {
  const { setCurrentEntry } = useContext(StockEntryContext)
  const [showROBAtSpecificTime, setShowROBAtSpecificTime] = useState(false)

  return (
    <ContentCard
      id='bunkering'
      title='Stock on board'
      helpTextKey='stock/stock-on-board'
      headerChildren={<FuelAndStockModal />}
    >
      <S.ButtonBanner>
        <S.ButtonContainer>
          <McButton
            fit='small'
            trailingicon='plus'
            click={() => setCurrentEntry(EntryType.Batch)}
            data-e2e='bunkering-button'
          >
            Bunkering
          </McButton>
          <McButton
            appearance='neutral'
            fit='small'
            trailingicon='plus'
            click={() => {
              if (stateOfStock === StockState.LOST) {
                void displayErrorModal({
                  message:
                    "Cannot access sounding because of data loss.\n\nThis can happen for many reasons, most likely connected to equipment outage, sensor issues etc.\n\nWhen those issues are fixed and we're seeing enough new data tick in, you'll be able to recover stock and sounding will become available!",
                  statusText: 'Sounding not available',
                })
              } else if (stateOfStock === StockState.RECOVERY) {
                displayConfirmModal(
                  {
                    message: (
                      <S.ConfirmRecovery>
                        <Icon icon='fal fa-exclamation-circle fa-3x' />
                        {`Cannot access sounding due to data loss, but you are now able to track consumption again.\n\nClick "Recovery" to insert ROB and select batches in use to start up the stock module again!`}
                      </S.ConfirmRecovery>
                    ),
                    title: 'Data recovery available',
                  },
                  'Recovery',
                  'Not now',
                ).then(() => {
                  setShowRecoveryModal(true)
                })
              } else {
                setCurrentEntry(EntryType.Sounding)
              }
            }}
          >
            Sounding
          </McButton>
          <McButton
            appearance='neutral'
            fit='small'
            trailingicon='plus'
            click={() => setCurrentEntry(EntryType.Loss)}
          >
            Loss
          </McButton>
          <McButton
            appearance='neutral'
            fit='small'
            trailingicon='plus'
            click={() => setCurrentEntry(EntryType.Debunkering)}
          >
            Debunkering
          </McButton>
          <McButton
            appearance='neutral'
            fit='small'
            trailingicon='plus'
            click={() => setCurrentEntry(EntryType.MixedBatch)}
          >
            Mix batches
          </McButton>
        </S.ButtonContainer>
        <McButton
          appearance='neutral'
          fit='small'
          trailingicon='calculator'
          click={() => setShowROBAtSpecificTime(true)}
        >
          Get ROB at a specific time
        </McButton>
      </S.ButtonBanner>
      <S.FuelTotalsContainerWrapper>
        <S.FuelTypeTotal>Total ROB</S.FuelTypeTotal>
        <S.StockContainer>
          {stockOnBoard === undefined && <Loading />}
          {stockOnBoard === null && <NotFound text='No data available' />}
          {stockOnBoard && <FuelTypeTotals stockOnBoard={stockOnBoard} />}
        </S.StockContainer>
      </S.FuelTotalsContainerWrapper>
      <hr />
      <S.InfoText>
        Below you can see which batches that are selected for transfer from
        storage tanks to service/settling tanks.
      </S.InfoText>
      <S.BatchSelectionWrapper>
        <S.ServiceTanks>Service/Settling tanks</S.ServiceTanks>
        <S.StockContainer>
          {batchSelections === undefined && <Loading />}
          {batchSelections === null && (
            <NotFound text='No batch selections available' />
          )}
          {batchSelections && (
            <BatchSelectionsContainer
              onSelectionsUpdate={() => onSelectionsUpdate()}
              batchSelections={batchSelections}
            />
          )}
        </S.StockContainer>
      </S.BatchSelectionWrapper>
      <hr />
      <S.InfoText>
        Below you can see which batches are currently on-board.
      </S.InfoText>
      <S.StockContainerWrapper>
        <S.StorageTanks>Batches</S.StorageTanks>
        <S.StockContainer>
          {stockOnBoard === undefined && <Loading />}
          {stockOnBoard === null && <NotFound text='No data available' />}
          {stockOnBoard && <FuelTypeBatches stockOnBoard={stockOnBoard} />}
        </S.StockContainer>
      </S.StockContainerWrapper>
      {showROBAtSpecificTime && (
        <RobModal closeHandler={() => setShowROBAtSpecificTime(false)} />
      )}
    </ContentCard>
  )
}
