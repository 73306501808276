import { useContext, useEffect, useState } from 'react'
import moment, { Moment } from 'moment'
import styled from 'styled-components'
import { Field, Form, Formik, validateYupSchema, yupToFormErrors } from 'formik'
import { isEqual } from 'lodash'
import { McButton } from '@maersk-global/mds-react-wrapper'

import { type IOption } from '../../../../commons/FormSelect'
import { VesselPageContext } from '../../../../contexts'
import { Performance } from '../../../../api-models'
import { errorTheme } from '../../../../theme'
import {
  FormContainer,
  FormDateTimeInput,
  FormInputWithUnit,
  FormSelect,
  InfoBox,
  Loading,
  ModalControls,
} from '../../../../commons'
import { FuelType, isShoreContext } from '../../../../utils'
import * as PerformanceAPI from '../../../../services/performance'
import { batchTransferValidationSchema } from './validation-schemas'
import { BatchFiltering } from './hook-components/BatchFiltering'

const CurrentlySelectedBatch = styled.span`
  font-size: 14px;
`

const ErrorContainer = styled.div`
  margin-left: 310px;
`

interface FormValues {
  timestamp: moment.Moment | undefined
  batchId: string
  previousBatchDepleted?: boolean
  remainingOfPreviousBatch?: number
}

const mapBatchSelectionToFormValues = (
  batchSelection: Performance.FuelOilStock.BatchSelectionResponse,
): FormValues => {
  return {
    batchId: batchSelection?.batchId,
    timestamp: moment.utc(batchSelection?.timestamp),
    previousBatchDepleted: batchSelection?.previousBatchDepleted,
    remainingOfPreviousBatch:
      batchSelection?.remainingOfPreviousBatch >= 0
        ? batchSelection.remainingOfPreviousBatch
        : undefined,
  }
}

const mapFormValuesToBatchSelection = (
  formValues: FormValues,
): Performance.FuelOilStock.BatchSelection => {
  return {
    batchId: formValues.batchId,
    timestamp: formValues.timestamp?.toISOString() || '',
    previousBatchDepleted: formValues.previousBatchDepleted,
    remainingOfPreviousBatch: formValues.remainingOfPreviousBatch,
  }
}

type Props = {
  closeHandler: () => void
  submit: (
    batchSelection: Performance.FuelOilStock.BatchSelection,
  ) => Promise<Performance.FuelOilStock.BatchSelection>
  currentlySelectedBatch?: Performance.FuelOilStock.CurrentBatchSelectionResponse
  fuelTypeForBatchSelection: FuelType
  batchSelection?: Performance.FuelOilStock.BatchSelectionResponse
  deleteHandler?: () => void
}

export const BatchTransferForm = ({
  closeHandler,
  submit,
  currentlySelectedBatch,
  fuelTypeForBatchSelection,
  batchSelection,
  deleteHandler,
}: Props) => {
  const disabled = isShoreContext() || batchSelection?.readonly
  const defaultInitialValues: FormValues = {
    batchId: '',
    timestamp: undefined,
    previousBatchDepleted: undefined,
    remainingOfPreviousBatch: undefined,
  }

  const [batchOptions, setBatchOptions] = useState<Array<IOption>>([])
  const imoNo = useContext(VesselPageContext).imoNo!
  const [batches, setBatches] =
    useState<Performance.FuelOilStock.BatchResponse[]>()
  const [timeOfBatchChangeover, setTimeOfBatchChangeover] = useState<Moment>()
  const [openWindow, setOpenWindow] =
    useState<Performance.FuelOilStock.OpenWindow>()

  useEffect(() => {
    if (imoNo) {
      /* if readonly the selection might be so old that batch is no longer onboard and we must look at all to get the display name */
      PerformanceAPI.getBatches(
        imoNo,
        '',
        batchSelection?.readonly ? undefined : true,
      )
        .then(setBatches)
        .catch((e) =>
          e.displayErrorModal({
            statusText: 'Could not find available batches',
            message: e.message,
          }),
        )
    }
  }, [imoNo, batchSelection])

  useEffect(() => {
    setTimeOfBatchChangeover(moment.utc(batchSelection?.timestamp))
  }, [batchSelection])

  useEffect(() => {
    PerformanceAPI.getStockOpenWindow(imoNo)
      .then(setOpenWindow)
      .catch((e) =>
        e.displayErrorModal({
          statusText: 'Could not get allowed time interval',
          message: e.message,
        }),
      )
  }, [imoNo])
  if (!openWindow) {
    return <Loading width='unset' />
  }

  const initialValues = batchSelection
    ? mapBatchSelectionToFormValues(batchSelection)
    : defaultInitialValues

  return (
    <Formik
      initialValues={initialValues}
      validate={(values: FormValues) => {
        try {
          void validateYupSchema<FormValues>(
            values,
            batchTransferValidationSchema,
            true,
            {
              batchOptions: batchOptions,
            },
          )
        } catch (err) {
          return yupToFormErrors(err)
        }
        return {}
      }}
      onSubmit={(values: FormValues, { setSubmitting }) =>
        submit(mapFormValuesToBatchSelection(values)).finally(() =>
          setSubmitting(false),
        )
      }
    >
      {({
        values,
        isSubmitting,
        setSubmitting,
        isValid,
        errors,
        touched,
        dirty,
        setFieldValue,
        setFieldTouched,
      }) => (
        <Form>
          <BatchFiltering
            batches={batches}
            batchId={values.batchId}
            timeOfBatchChangeover={timeOfBatchChangeover}
            currentlySelectedBatch={currentlySelectedBatch}
            fuelTypeForBatchSelection={fuelTypeForBatchSelection}
            setBatchOptions={setBatchOptions}
            batchOptions={batchOptions}
            setFieldTouched={setFieldTouched}
            readonly={batchSelection?.readonly}
          />
          <FormContainer labelWidth='350px'>
            {!batchSelection && (
              <div className='field-container'>
                <span className='field-label'>
                  Latest transfer from storage
                </span>
                {currentlySelectedBatch ? (
                  <CurrentlySelectedBatch>
                    {`${moment
                      .utc(currentlySelectedBatch?.timestamp)
                      .format('DD MMM YYYY HH:mm')} - ${
                      currentlySelectedBatch?.displayName
                    }`}
                  </CurrentlySelectedBatch>
                ) : (
                  <CurrentlySelectedBatch>
                    No previous transfers
                  </CurrentlySelectedBatch>
                )}
              </div>
            )}
            <div className='field-container'>
              <span className='field-label'>
                When did you change to the new batch?
              </span>
              <Field
                component={(props) => (
                  <FormDateTimeInput
                    {...props}
                    minuteSpecific={false}
                    minDate={moment.utc(openWindow.period.from)}
                    maxDate={moment.utc(openWindow.period.to)}
                    onChange={(value) => {
                      setTimeOfBatchChangeover(value)
                    }}
                  />
                )}
                name='timestamp'
                disabled={disabled}
              />
            </div>
            <ErrorContainer>
              {errors?.timestamp && touched.timestamp && (
                <InfoBox
                  hideIcons={true}
                  theme={errorTheme}
                  boxMargin='0 0 8px 0'
                >
                  <small>{errors.timestamp}</small>
                </InfoBox>
              )}
            </ErrorContainer>

            <div className='field-container'>
              <span className='field-label'>What batch did you change to?</span>
              <Field
                component={FormSelect}
                name='batchId'
                options={batchOptions}
                width='288px'
                disabled={disabled}
              />
            </div>
            <ErrorContainer>
              {errors?.batchId && touched.batchId && (
                <InfoBox
                  hideIcons={true}
                  theme={errorTheme}
                  boxMargin='0 0 8px 0'
                >
                  <small>{errors.batchId}</small>
                </InfoBox>
              )}
            </ErrorContainer>

            <div className='field-container'>
              <span className='field-label'>
                Has the current batch been depleted in storage tanks?
              </span>
              <Field
                component={FormSelect}
                options={[
                  { value: true, label: 'Yes' },
                  { value: false, label: 'No' },
                ]}
                width='100px'
                name='previousBatchDepleted'
                disabled={disabled}
                onChange={(option) => {
                  if (option.value === true) {
                    setFieldValue('remainingOfPreviousBatch', '', false)
                    setFieldTouched('remainingOfPreviousBatch', false, false)
                  }
                }}
              />
            </div>
            <ErrorContainer>
              {errors.previousBatchDepleted &&
                touched.previousBatchDepleted && (
                  <InfoBox
                    hideIcons={true}
                    theme={errorTheme}
                    boxMargin='0 0 8px 0'
                  >
                    <small>{errors.previousBatchDepleted}</small>
                  </InfoBox>
                )}
            </ErrorContainer>
            {typeof values.previousBatchDepleted === 'boolean' &&
              !values.previousBatchDepleted && (
                <>
                  <div className='field-container'>
                    <span className='field-label'>
                      Remaining in service/settling tank of current batch
                    </span>
                    <Field
                      component={FormInputWithUnit}
                      name='remainingOfPreviousBatch'
                      disabled={disabled}
                      type='number'
                      unit='MT'
                      width='120px'
                    />
                  </div>
                  <ErrorContainer>
                    {errors?.remainingOfPreviousBatch &&
                      touched.remainingOfPreviousBatch && (
                        <InfoBox
                          hideIcons={true}
                          theme={errorTheme}
                          boxMargin='0 0 8px 0'
                        >
                          <small>{errors.remainingOfPreviousBatch}</small>
                        </InfoBox>
                      )}
                  </ErrorContainer>
                </>
              )}
          </FormContainer>
          <ModalControls>
            {batchSelection &&
              !batchSelection.readonly &&
              !isShoreContext() && (
                <McButton
                  className='left'
                  label='Delete'
                  appearance='error'
                  type='button'
                  click={() => {
                    if (deleteHandler) {
                      setSubmitting(true)
                      deleteHandler()
                    }
                  }}
                />
              )}
            <McButton
              label='Cancel'
              appearance='neutral'
              click={closeHandler}
            />
            <McButton
              label='Change batch'
              appearance='primary'
              disabled={
                isSubmitting ||
                disabled ||
                !isValid ||
                isEqual(values, initialValues)
              }
              type='submit'
            />
          </ModalControls>
        </Form>
      )}
    </Formik>
  )
}
