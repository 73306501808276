import { FuelType } from '../../../../utils'

export const calculateLcvValueForBioFuel = (
  fuelGrade: MasterDataApi.Common.FuelGrade,
  bioPercentage: number,
) => {
  if (!fuelGrade || !bioPercentage) return ''
  switch (fuelGrade.data.fuelType) {
    case FuelType.HS:
      return bioPercentage * 37500 + (1 - bioPercentage) * 40290
    case FuelType.VLS:
      return bioPercentage * 37500 + (1 - bioPercentage) * 41000
    case FuelType.ULS:
      return bioPercentage * 37500 + (1 - bioPercentage) * 42200
    case FuelType.MDO:
      return bioPercentage * 37500 + (1 - bioPercentage) * 42700
    default:
      return ''
  }
}
