import React from 'react'
import imgSoundingNotifications from '../../../../assets/images/help-section/lube-sounding-notifications.png'
import imgSoundingFull from '../../../../assets/images/help-section/lube-sounding-full.png'
import imgSoundingTime from '../../../../assets/images/help-section/lube-sounding-time.png'
import imgSoundingOilTypes from '../../../../assets/images/help-section/lube-sounding-oil-types.png'
import imgSoundingAddRow from '../../../../assets/images/help-section/lube-sounding-add-row.png'
import imgSoundingValidation from '../../../../assets/images/help-section/lube-sounding-validation.png'
import imgSoundingPartial from '../../../../assets/images/help-section/lube-sounding-partial.png'
import imgSoundingOverviewTable from '../../../../assets/images/help-section/lube-sounding-overview-table.png'

export const Sounding = React.forwardRef<HTMLDivElement>((props, ref) => (
  <div ref={ref} id='help-section-subtopic'>
    <h4>In this guide you will learn</h4>
    <ul>
      <li>Adding sounding event</li>
      <li>Difference between Full and Partial sounding events</li>
      <li>How to Add a sounding event</li>
    </ul>
    <h4>Purpose</h4>
    <p>
      The purpose of this guide is to explain how to add soundings events.
      Sounding function will enable you to carry out the required Month End Lube
      Oil Reporting and ad-hoc inventory keeping for vessels own use.
    </p>
    <h4>Sounding</h4>
    <p>
      To carry out the required Month End Lube Oil Reporting, the values in the
      sounding function must be entered in the time period for Month End Lube
      Oil Reporting, “Type of sounding” have to be Full, and all oil type
      quantities have to be recorded. Notification will pop-up when period for
      Month End Lube Oil Reporting starts and disappear when Full sounding are
      carried out. BOB vessels will also have to ensure that transferred base
      oil (ME, Spare Dirty) amount is updated with a timestamp just prior to the
      Full sounding event timestamp as needed.
    </p>
    <img
      className='long'
      src={imgSoundingNotifications}
      alt='Sounding notifications'
    />
    <p>General information:</p>
    <ul>
      <li>
        A full sounding will lock entries before the full sounding timestamp, so
        these cannot be edited or deleted
      </li>
      <li>
        A full sounding will lock for entering events with timestamp before the
        full soundings timestamp
      </li>
      <li>
        A full sounding cannot be added to the previous month. E.G., when in
        February a full sounding cannot be entered with a January timestamp
      </li>
    </ul>
    <p>
      Vessel can use “Type of sounding” Full at any time during the month to
      make a complete recordkeeping of oils on-board, but it will not be used
      for Month End Lube Oil Reporting if it's not inside the Month End Lube Oil
      Reporting period.
    </p>
    <p>NON-BOB vessels will need to record “0” for CLO Additives in ROB, kg.</p>
    <p>
      The “Type of sounding” Partial are for you to be able to track events
      during the month for vessels own record keeping and will not be used in
      the Month End Lube Oil Reporting regardless of being added inside the time
      period for Month End Lube Oil Reporting. The “Type of sounding” Partial
      allows you to record sounded oil quantity for just 1 oil type.
    </p>
    <div className='image-container'>
      <img src={imgSoundingFull} alt='Full sounding' />
      <img src={imgSoundingTime} alt='Time of sounding' />
    </div>
    <p className='title'>Entering values in sounding</p>
    <p>
      Date and time are mandatory. Time must be in 10 minutes intervals. Eg.
      10:30 and not 10:29. Please be aware of the AM/PM setup in time selector
      depends on browser settings.
    </p>
    <p>Oil types:</p>
    <ul>
      <li>CLO, cylinder lubrication oil</li>
      <li>CLO Additives, used for vessel that are blending the CLO on-board</li>
      <li>ME, main engine system oil</li>
      <li>AE, auxiliary engine system oil</li>
      <li>
        Other, all other oils not belonging to be above categories, normally
        grease - hydr. oil etc. in smaller quantities. In Other, 1 row with
        total sum of all other oils are sufficient.
      </li>
    </ul>
    <img className='long' src={imgSoundingOilTypes} alt='Oil types' />
    <p>
      Date and time are mandatory. Time must be in 10 minutes intervals. Eg.
      10:30 and not 10:29. Please be aware of the AM/PM setup in time selector
      depends on browser settings.
    </p>
    <p>Under CLO you can enter Spare Clean and Day Tank values:</p>
    <ul>
      <li>TBN, total base number, range 10-175</li>
      <li>ROB, remaining on-board</li>
      <li>Unpumpable, amount in tank that cannot be pumped out</li>
    </ul>
    <p>
      Even if field are present it may not be required to enter value or even
      possible as in this form value cannot be entered in Unpumpable in the Day
      Tank line.
    </p>
    <p className='note'>
      Please note dual main engine vessels will have a Day Tank Port and a Day
      Tank Stbd line.
    </p>
    <p>
      If vessel have more then 1 TBN type oil. Eg. Having TBN 25 and TBN 75 oils
      on-board vessel will have to use “+ Add row” to have 2 rows, so details
      can be entered on both oils.
    </p>
    <div className='image-container'>
      <img src={imgSoundingAddRow} alt='Add row' />
      <img src={imgSoundingValidation} alt='Validation' />
    </div>
    <p>
      If pressing “Save” before required details have been entered, the save
      will not be successful.
    </p>
    <p>
      After required details have been entered the “SAVE” action will
      automatically close Sounding dialog box and show sounding event on
      overview page. Pressing “Cancel” during the process will close dialog box
      and entered details will be lost.
    </p>
    <p>
      A “Partial” sounding only requires date and time and sounding details on 1
      oil under either of the types “CLO-CLO Additives-ME-AE-Other” to be
      entered before it can be saved and shown on overview page.
    </p>
    <div className='image-container'>
      <img
        className='one-off'
        src={imgSoundingPartial}
        alt='Partial sounding'
      />
      <img
        className='one-off'
        src={imgSoundingOverviewTable}
        alt='Overview table'
      />
    </div>
  </div>
))
