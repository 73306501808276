import * as React from 'react'
import moment from 'moment'
import { green, grey, orangeTheme } from '../../../theme'
import { Chart } from '../../../utils/models'
import { formatValue, getClosestMinuteFromTimestamp } from '../../../utils'

import * as ECharts from 'echarts'
import {
  ACTIVATE_ZOOM_ACTION,
  AXIS_LABEL,
  AXIS_LINE,
  AXIS_SPLIT_LINE,
  AXIS_TICK,
  DATA_ZOOM_TIMELINE,
  GRID,
  TOOLBOX_TIMELINE,
  UNITS,
  X_AXIS_TIMELINE,
} from '../../../utils/constants'
import { Hydra } from '../../../api-models/hydra'

interface CostElectricalProductionChartProps {
  dataElectricalProduction: Hydra.ElectricalProduction.ElectricalProductionCost
  queryPeriod: Hydra.Period
  showTEMAdvice: boolean
}

export default class CostElectricalProductionChart extends React.Component<CostElectricalProductionChartProps> {
  private chartNode: React.RefObject<HTMLDivElement>
  private chart?: Chart

  constructor(props: CostElectricalProductionChartProps) {
    super(props)
    this.chartNode = React.createRef()
  }

  componentDidMount() {
    const { dataElectricalProduction } = this.props
    if (this.chartNode.current && dataElectricalProduction) {
      const chart = ECharts.init(this.chartNode.current) as Chart
      chart.setOption(this.getOptions())
      chart.dispatchAction(ACTIVATE_ZOOM_ACTION)
      this.chart = chart
      chart.on('dataZoom', this.zoomIn)
    }
  }

  componentDidUpdate(prevProps: CostElectricalProductionChartProps) {
    const { dataElectricalProduction, showTEMAdvice } = this.props
    if (
      this.chart &&
      dataElectricalProduction !== prevProps.dataElectricalProduction
    ) {
      this.chart.setOption(this.getOptions())
    } else if (this.chart && showTEMAdvice !== prevProps.showTEMAdvice) {
      this.chart.dispatchAction({
        type: 'legendToggleSelect',
        name: 'tem-cost',
      })
    }
  }

  getOptions = (): ECharts.EChartOption | any => {
    const { dataElectricalProduction, queryPeriod, showTEMAdvice } = this.props
    const min = moment(moment.utc(queryPeriod.from)).local().valueOf()
    const max = moment(moment.utc(queryPeriod.to)).local().valueOf()
    const { timestamp, costOfElectricalProduction, temCost } =
      dataElectricalProduction.calculatedResults
    return {
      xAxis: {
        ...X_AXIS_TIMELINE,
        min: min,
        max: max,
      },
      yAxis: [
        {
          id: 'production-cost',
          type: 'value',
          axisTick: AXIS_TICK,
          axisLabel: {
            formatter: (value: any) => formatValue(value, 1),
            ...AXIS_LABEL,
          },
          axisLine: AXIS_LINE,
          splitLine: AXIS_SPLIT_LINE,
        },
      ],
      grid: GRID,
      toolbox: TOOLBOX_TIMELINE,
      dataZoom: [
        {
          type: 'slider',
          zoomOnMouseWheel: false,
          show: false,
        },
        DATA_ZOOM_TIMELINE,
      ],
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'line',
        },
        textStyle: {
          color: grey[50],
        },
        backgroundColor: 'rgba(50,50,50,0.9)',
        formatter: this.formatter,
      },
      legend: {
        show: false,
        selected: {
          'tem-cost': showTEMAdvice,
        },
      },
      series: [
        {
          animation: false,
          name: 'production-cost',
          type: 'line',
          symbol: 'none',
          lineStyle: {
            color: orangeTheme.bg,
          },
          yAxisIndex: 0,
          data: timestamp.map((timestamp, i) => {
            return [timestamp, costOfElectricalProduction[i]]
          }),
        },
        {
          animation: false,
          name: 'tem-cost',
          type: 'line',
          symbol: 'none',
          lineStyle: {
            normal: {
              color: green[500],
            },
          },
          yAxisIndex: 0,
          data: timestamp.map((timestamp, i) => {
            return [timestamp, temCost[i]]
          }),
        },
        {
          animation: false,
          name: 'tem-cost',
          type: 'line',
          symbol: 'none',
          lineStyle: {
            normal: {
              opacity: 0,
            },
          },
          yAxisIndex: 0,
          data: timestamp.map((timestamp, i) => {
            return [
              timestamp,
              temCost[i] !== null ? Math.max(0, temCost[i] - 50) : null,
            ]
          }),
          stack: 'band',
        },
        {
          animation: false,
          name: 'tem-cost',
          type: 'line',
          symbol: 'none',
          lineStyle: {
            normal: {
              opacity: 0,
            },
          },
          areaStyle: {
            normal: {
              color: green[100],
            },
          },
          yAxisIndex: 0,
          data: timestamp.map((timestamp, i) => {
            if (temCost[i] === null) return [timestamp, null]
            return [timestamp, temCost[i] <= 50 ? temCost[i] + 50 : 100]
          }),
          stack: 'band',
        },
      ],
    }
  }

  formatter = (params: any) => {
    const { dataElectricalProduction } = this.props
    // dataIndex relates to the actual index in the original arrays
    let dataIndex = params[0].dataIndex
    const dataFuelCost: Hydra.ElectricalProduction.CalculatedResult =
      dataElectricalProduction.calculatedResults
    const headers = ['', 'Fuel cost', 'Energy Advice', '']
    const visibleColumns = this.props.showTEMAdvice ? [0, 1, 2, 3] : [0, 1, 3]
    const timestamp = !!dataFuelCost.timestamp[dataIndex]
      ? moment.utc(dataFuelCost.timestamp[dataIndex]).format('DD MMM HH:mm UTC')
      : '-- --- --:-- UTC'
    return `
      <table class="tooltip">
        <thead>
          <tr>
            <th colspan="${visibleColumns.length}">${timestamp}</th>
          </tr>
          <tr class="header-bottom">
            ${headers.reduce(
              (acc, header, i) =>
                visibleColumns.includes(i) ? acc + `<th>${header}</th>` : acc,
              '',
            )}
          </tr>
        </thead>
        <tbody>
          <tr>
              ${this.getTooltipTableRow(
                [
                  'Cost',
                  formatValue(
                    dataFuelCost.costOfElectricalProduction[dataIndex],
                    1,
                  ),
                  formatValue(dataFuelCost.temCost[dataIndex], 1),
                  UNITS.GRAM_KILOWATT_HOUR,
                ],
                visibleColumns,
                true,
              )}
          </tr>
          <tr>
          ${this.getTooltipTableRow(
            [
              'Consumption',
              formatValue(dataFuelCost.metricTonsPerHour[dataIndex], 2),
              formatValue(dataFuelCost.temConsumption[dataIndex], 2),
              UNITS.MT_PER_HOUR,
            ],
            visibleColumns,
            true,
          )}
          </tr>
        ${this.getTooltipTableRow(
          [
            'WHR',
            formatValue(dataFuelCost.whrTotalPower.value[dataIndex], 0),
            formatValue(dataFuelCost.temWhrPowerOutput[dataIndex], 0),
            UNITS.KILO_WATT,
          ],
          visibleColumns,
          dataFuelCost.whrTotalPower.isInstalled,
        )}
        ${this.tooltipSG(dataIndex)}
        ${this.tooltipAEs(dataIndex)}
          ${this.getTooltipTableRow(
            [
              'Total Elec Power',
              formatValue(
                dataFuelCost.totalPower[dataIndex]
                  ? dataFuelCost.totalPower[dataIndex]
                  : null,
                0,
              ),
              formatValue(
                dataFuelCost.temTotalPower[dataIndex]
                  ? dataFuelCost.temTotalPower[dataIndex]
                  : null,
                0,
              ),
              UNITS.KILO_WATT,
            ],
            visibleColumns,
            true,
          )}
          ${this.tooltipSMs(dataIndex)}
        </tbody>
      </table>
    `
  }

  getTooltipTableRow = (
    contentCells: string[],
    visibleColumns: number[],
    visible: boolean,
  ) => {
    if (!visible) return ''
    return `<tr>
      ${contentCells.reduce(
        (acc, cellContent, index) =>
          visibleColumns.includes(index)
            ? acc + `<td>${cellContent}</td>`
            : acc,
        '',
      )}
    </tr>
    `
  }

  tooltipSG = (dataIndex: number) => {
    const { dataElectricalProduction, showTEMAdvice } = this.props
    const dataFuelCost: Hydra.ElectricalProduction.CalculatedResult =
      dataElectricalProduction.calculatedResults
    if (!dataFuelCost.me1ShaftGeneratorPower.isInstalled) return ''
    if (!dataFuelCost.me2ShaftGeneratorPower.isInstalled) {
      return `<tr>
      <td>ME 1 SG</td>
      <td>${formatValue(
        dataFuelCost.me1ShaftGeneratorPower.value[dataIndex],
        0,
      )}</td>
      ${
        showTEMAdvice
          ? `<td>${formatValue(
              dataFuelCost.temSg1ElectricalPower[dataIndex],
              0,
            )}</td>`
          : ''
      }
      <td style="vertical-align: top;">${UNITS.KILO_WATT}</td>
    </tr>`
    }
    return `<tr>
      <td>ME 1 SG</td>
      <td>${formatValue(
        dataFuelCost.me1ShaftGeneratorPower.value[dataIndex],
        0,
      )}</td>
      ${
        showTEMAdvice
          ? `<td>${formatValue(
              dataFuelCost.temSg1ElectricalPower[dataIndex],
              0,
            )}</td>`
          : ''
      }
      <td>${UNITS.KILO_WATT}</td>
    </tr>
    <tr>
      <td>ME 2 SG</td>
      <td>${formatValue(
        dataFuelCost.me2ShaftGeneratorPower.value[dataIndex],
        0,
      )}</td>
      ${
        showTEMAdvice
          ? `<td>${formatValue(
              dataFuelCost.temSg2ElectricalPower[dataIndex],
              0,
            )}</td>`
          : ''
      }
      <td>${UNITS.KILO_WATT}</td>
    </tr>
    `
  }

  tooltipSMs = (dataIndex: number) => {
    const { dataElectricalProduction, showTEMAdvice } = this.props
    const dataFuelCost: Hydra.ElectricalProduction.CalculatedResult =
      dataElectricalProduction.calculatedResults
    if (!dataFuelCost.me1ShaftMotorPower.isInstalled) return ''
    if (!dataFuelCost.me2ShaftMotorPower.isInstalled) {
      return `<tr>
      <td>ME 1 SM</td>
      <td>${formatValue(
        dataFuelCost.me1ShaftMotorPower.value[dataIndex],
        0,
      )}</td>
      ${
        showTEMAdvice
          ? `<td>${formatValue(
              dataFuelCost.temSm1ElectricalPower[dataIndex],
              0,
            )}</td>`
          : ''
      }
      <td>${UNITS.KILO_WATT}</td>
    </tr>`
    }
    return `<tr>
      <td>ME 1 SM</td>
      <td>${formatValue(
        dataFuelCost.me1ShaftMotorPower.value[dataIndex],
        0,
      )}</td>
      ${
        showTEMAdvice
          ? `<td>${formatValue(
              dataFuelCost.temSm1ElectricalPower[dataIndex],
              0,
            )}</td>`
          : ''
      }
      <td>${UNITS.KILO_WATT}</td>
    </tr>
    <tr>
      <td>ME 2 SM</td>
      <td>${formatValue(
        dataFuelCost.me2ShaftMotorPower.value[dataIndex],
        0,
      )}</td>
      ${
        showTEMAdvice
          ? `<td>${formatValue(
              dataFuelCost.temSm2ElectricalPower[dataIndex],
              0,
            )}</td>`
          : ''
      }
      <td>${UNITS.KILO_WATT}</td>
    </tr>
    `
  }

  tooltipAEs = (dataIndex: number) => {
    const { dataElectricalProduction, showTEMAdvice } = this.props
    const dataFuelCost: Hydra.ElectricalProduction.CalculatedResult =
      dataElectricalProduction.calculatedResults
    const auxEngs = [
      {
        aePower: dataFuelCost.ae1Power,
        temAePower: dataFuelCost.temAe1Power,
        auxEngNumber: 1,
      },
      {
        aePower: dataFuelCost.ae2Power,
        temAePower: dataFuelCost.temAe2Power,
        auxEngNumber: 2,
      },
      {
        aePower: dataFuelCost.ae3Power,
        temAePower: dataFuelCost.temAe3Power,
        auxEngNumber: 3,
      },
      {
        aePower: dataFuelCost.ae4Power,
        temAePower: dataFuelCost.temAe4Power,
        auxEngNumber: 4,
      },
      {
        aePower: dataFuelCost.ae5Power,
        temAePower: dataFuelCost.temAe5Power,
        auxEngNumber: 5,
      },
    ].filter((ae) => ae.aePower.isInstalled)
    return auxEngs.reduce(
      (acc, auxEng) =>
        acc +
        `<tr>
            <td>AE ${auxEng.auxEngNumber}</td>
            <td style="text-align: right;">
              ${formatValue(auxEng.aePower.value[dataIndex], 0)}
            </td>
            ${
              showTEMAdvice
                ? `<td style="vertical-align: top;">
              <div>${formatValue(auxEng.temAePower[dataIndex], 0)}</div>
            </td>`
                : ''
            }
            <td style="vertical-align: top;">
              ${UNITS.KILO_WATT}
            </td>
        </tr>`,
      '',
    )
  }

  zoomOut = (silent?: boolean) => {
    if (this.chart) {
      this.chart.dispatchAction(
        {
          type: 'dataZoom',
          start: 0,
          end: 100,
        },
        !!silent,
      )
    }
  }

  zoomIn = (params: any) => {
    if (this.chart && params.batch) {
      const batchData = params.batch[0]
      let startValue = Math.floor(batchData.startValue)
      let endValue = Math.floor(batchData.endValue)
      if (endValue - startValue < 1000 * 3600) {
        const paddingNeeded = (1000 * 3600 - (endValue - startValue)) / 2
        startValue -= paddingNeeded
        endValue += paddingNeeded
      }

      startValue = getClosestMinuteFromTimestamp(startValue, 10)
      endValue = getClosestMinuteFromTimestamp(endValue, 10)

      this.chart.dispatchAction(
        {
          type: 'dataZoom',
          filterMode: 'none',
          batch: [{ id: 'timestamp', startValue, endValue }],
        },
        true,
      )
    }
  }

  render() {
    return (
      <div
        onDoubleClick={() => {
          this.zoomOut(false)
        }}
        id='production-cost'
        ref={this.chartNode}
      />
    )
  }
}
