import { useContext, useMemo } from 'react'
import { useFormikContext } from 'formik'

import { ApiReportType } from '../../../api-models/hdc/report'
import { Tabs } from '../../../commons'
import BridgeSummary from '../components/bridge-summary'
import EngineRoomSummary from '../components/engine-room-summary'
import OverlayLoader from '../components/overlay-loader'
import { HDCContext } from '../context'
import { HDCFormType, HDCReportFormValues } from '../types'
import { flattenFormikErrors, getFilteredByStepWarnings } from '../utils'
import * as S from './style'
import Notifications from '../../../components/Notifications/Notifications'
import FormSection from '../../../components/form/section'

const SummaryStep = () => {
  const { state: HDCState } = useContext(HDCContext)
  const {
    form: { currentStep, steps, type },
    report,
    reports,
  } = HDCState

  const {
    errors,
    isSubmitting,
    values: formValues,
  } = useFormikContext<HDCReportFormValues>()
  const flattenErrors = flattenFormikErrors(errors)

  const description = `
      Please review the collected data before submitting the report. If you need
      to update the data you can navigate to the original tab via the report bar
      at the top. Kindly note that report will only be submitted to shore when
      both Bridge and Engine sections have been completed.
    `

  const warnings = useMemo(
    () =>
      getFilteredByStepWarnings(report!.warnings, type!, steps[currentStep]),
    [currentStep, report, steps, type],
  )

  return (
    <>
      <Notifications
        alerts={flattenErrors}
        warnings={[...new Set(Object.values(warnings))]}
      />
      <Tabs
        startIndex={type === HDCFormType.BRIDGE_FORM ? 0 : 1}
        tabButtons={[
          {
            hide: report?.reportType === ApiReportType.ALONGSIDE,
            name: 'Bridge report',
          },
          {
            name: 'Engine room report',
          },
        ]}
      >
        <S.Wrapper>
          <FormSection
            header='Bridge report summary'
            description={description}
          />
          <BridgeSummary
            bridgeEvents={report?.bridge.events || []}
            engineRoomEvents={report?.engineRoom.events || []}
            report={formValues}
            reports={reports!}
          />
          {isSubmitting && <OverlayLoader padding='0px' />}
        </S.Wrapper>
        <S.Wrapper>
          <FormSection
            header='Engine room report summary'
            description={description}
          />
          <EngineRoomSummary
            bridgeEvents={report?.bridge.events || []}
            engineRoomEvents={report?.engineRoom.events || []}
            report={formValues}
          />
          {isSubmitting && <OverlayLoader padding='0px' />}
        </S.Wrapper>
      </Tabs>
    </>
  )
}

export default SummaryStep
