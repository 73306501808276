import { useContext, useMemo, useState } from 'react'
import {
  type ExpandedState,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable,
} from '@tanstack/react-table'

import { type FuelTypeGroup, type IHdcFuelConsumptionTableData } from './types'
import { VesselPageContext } from '../../../../contexts'
import Table from '../../../../components/Table/Table'
import { getColumns } from './helpers'
import { type TWindowSize } from '../../../../theme_new/styled'

type Props = {
  fuelTypeGroups: Array<FuelTypeGroup>
  tableSize?: TWindowSize
}

export const HdcFuelConsumptionTable = ({
  fuelTypeGroups,
  tableSize,
}: Props) => {
  const { fuelLineTypes } = useContext(VesselPageContext).configuration!

  const [expanded, setExpanded] = useState<ExpandedState>({
    ...fuelLineTypes.reduce(
      (acc, curr, idx) => ({
        ...acc,
        [idx]: true,
      }),
      {},
    ),
  })

  const data = useMemo(
    () =>
      fuelTypeGroups.map((item) => {
        const subRows: Array<IHdcFuelConsumptionTableData> =
          item.batchConsumption.map((item) => {
            const subRow = {
              fuelType: item.batchName,
              total: item.totalConsumedMass,
              rob: item.rob,
            }

            if (item.fuelLines) {
              for (const fuelLine of item.fuelLines) {
                subRow[fuelLine.fuelLine] = fuelLine.consumedMass
              }
            }

            return subRow
          })

        if (subRows.length === 0) {
          subRows.push({
            fuelType: 'No batches to report for this fuel type',
            rob: null,
            total: null,
          })
        }

        const row: IHdcFuelConsumptionTableData = {
          fuelType: item.fuelType,
          fuelTypeColor: item.fuelType.toLowerCase(),
          total: item.fuelTypeTotal,
          rob: item.fuelTypeRobTotal,
          subRows,
        }

        for (const subRow of subRows) {
          const { fuelType, total, rob, ...fuelLines } = subRow
          for (const [fuelLine, consumedMass] of Object.entries(fuelLines)) {
            if (row[fuelLine]) {
              row[fuelLine] += consumedMass
            } else {
              row[fuelLine] = consumedMass
            }
          }
        }

        return row
      }),
    [fuelTypeGroups],
  )

  const table = useReactTable({
    data,
    columns: getColumns(fuelLineTypes),
    state: { expanded },
    onExpandedChange: setExpanded,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getSubRows: (row) => row.subRows,
  })

  if (!fuelTypeGroups || fuelTypeGroups.length === 0) return null

  return (
    <Table
      table={table}
      maxFit={tableSize}
      hidePagination
      showFooter
      disableRowHighlightOnHover
      inert
    />
  )
}
