import styled from 'styled-components'
import moment from 'moment'

import { DATE_TIME_FORMAT } from '../../../../formik/InputDateTime/InputDateTime.utils'
import { Actor } from '../../Diagnostics.consts'

export const Wrapper = styled.div<{
  sender: Omit<NotificationsAPI.Performance.Actor, 'System'>
}>`
  position: relative;
  display: flex;
  align-items: flex-end;
  flex-direction: ${({ sender }) =>
    sender === Actor.Vessel ? 'row-reverse' : 'row'};
  align-self: ${({ sender }) =>
    sender === Actor.Vessel ? 'flex-end' : 'flex-start'};
  width: 75%;

  > mc-icon {
    padding: 6px;
    @media (min-width: ${({ theme }) => theme.breakPoints.medium}px) {
      padding: 8px;
    }
    border-radius: 50%;
    background-color: ${({ sender }) =>
      sender === Actor.Vessel ? '#42B0D5' : '#545454'};

    &::part(icon) {
      fill: white;
    }
  }
`

export const MessageBubble = styled.div<{
  msg: Diagnostics.ChatMessage
}>`
  background-color: ${({ msg }) =>
    msg.sender === Actor.Vessel ? '#E9F6FC' : '#F7F7F7'};
  margin: 24px 8px;
  padding: 14px 20px;
  @media (min-width: ${({ theme }) => theme.breakPoints.medium}px) {
    margin: 24px 16px;
    padding: 16px 24px;
  }
  border-radius: 32px;
  border-bottom-right-radius: ${({ msg }) =>
    msg.sender === Actor.Vessel ? 0 : '32px'};
  border-bottom-left-radius: ${({ msg }) =>
    msg.sender === Actor.Shore ? 0 : '32px'};

  :after {
    position: absolute;
    bottom: -2px;
    right: ${({ msg }) => (msg.sender === Actor.Vessel ? '42px' : 'auto')};
    left: ${({ msg }) => (msg.sender === Actor.Vessel ? 'auto' : '42px')};
    font-size: 12px;
    @media (min-width: ${({ theme }) => theme.breakPoints.medium}px) {
      bottom: -6px;
      right: ${({ msg }) => (msg.sender === Actor.Vessel ? '56px' : 'auto')};
      left: ${({ msg }) => (msg.sender === Actor.Vessel ? 'auto' : '56px')};
      font-size: 13px;
    }
    opacity: 0.6;
    content: '${({ msg }) => {
      let str = moment.utc(msg.timestamp).format(DATE_TIME_FORMAT)
      if (msg.sender === Actor.Shore) {
        str += ` - ${msg.user?.email ?? 'Vessel Performance Team'}`
      }
      return str
    }}';
  }
`
