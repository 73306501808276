import { useContext } from 'react'
import moment from 'moment'

import { OuterWrapper } from './PerformanceOverview.styles'

import AdoptionCard from './RankingCardFeatures/AdoptionCard'
import PerformanceCard from './RankingCardFeatures/PerformanceCard'
import EnginePerformance from './EnginePerformance/EnginePerformance'
import {
  useGetAdoptionData,
  useGetEnergyMetrics,
  useGetWasteData,
} from '../../../queries/BalrogApi/BalrogApi'
import {
  getInitialDateRange,
  getSisterVesselImoList,
  getVesselName,
} from '../ClassComparisonPage.utils'
import { ContentCard } from '../../../commons'
import { PadContent } from '../../../layout/styles'
import { VesselPageContext } from '../../../contexts'

type Props = {
  sisterVesselsData: MasterDataApi.SisterVessel.SisterVesselResponse
}

const PerformanceOverview = ({ sisterVesselsData }: Props) => {
  const imoNo = useContext(VesselPageContext).imoNo!
  let vesselName = getVesselName(+imoNo, sisterVesselsData)
  const sisterVesselImoList = getSisterVesselImoList(sisterVesselsData)

  const getAdoptionData = useGetAdoptionData(sisterVesselImoList)

  const dateRange = getInitialDateRange()

  const getEnergyMetrics = useGetEnergyMetrics(sisterVesselImoList, dateRange)
  const getWasteData = useGetWasteData(sisterVesselImoList, dateRange)

  const isSameYear = moment.utc(dateRange.from).isSame(moment.utc(), 'year')
  const from = moment.utc(dateRange.from)
  const _from = isSameYear ? from.format('D MMMM') : from.format('D MMMM YYYY')
  const _to = moment.utc().format('D MMMM YYYY')

  if (
    !getAdoptionData.isSuccess ||
    !getEnergyMetrics.isSuccess ||
    !getWasteData.isSuccess
  ) {
    return null
  }

  return (
    <ContentCard id='svc__overview' title='' hideHeader>
      <PadContent>
        <h3>Performance Overview</h3>
        <p>
          Below you can see the current class performance for the period{' '}
          <strong>
            {_from} - {_to}
          </strong>
          .
        </p>
      </PadContent>
      <OuterWrapper>
        <PerformanceCard
          energyMetrics={getEnergyMetrics.data}
          wasteData={getWasteData.data}
          vesselName={vesselName}
        />
        <AdoptionCard data={getAdoptionData.data} vesselName={vesselName} />
      </OuterWrapper>
      <OuterWrapper>
        <EnginePerformance
          isMainEngine
          data={getWasteData.data}
          vesselName={vesselName}
        />
        <EnginePerformance data={getWasteData.data} vesselName={vesselName} />
      </OuterWrapper>
    </ContentCard>
  )
}

export default PerformanceOverview
