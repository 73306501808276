import { useContext } from 'react'
import { useField, useFormikContext } from 'formik'
import { McTooltip } from '@maersk-global/mds-react-wrapper/components-core/mc-tooltip'

import { StyledMcInput } from './MetcInputField.styles'
import { WindowContext } from '../../../../../contexts'

type Props = {
  name: string
  unit?: string
  isIgnored?: boolean
  onBlur?: () => void
}

const MetcInputField = ({ name, unit, isIgnored, onBlur }: Props) => {
  const { windowSize } = useContext(WindowContext)
  const { initialValues } = useFormikContext<GandalfApi.MetcReport>()
  const [field, meta, helpers] =
    useField<GandalfApi.Metc.DataPoint<number>>(name)

  const threshold = initialValues.data.meta.dataAvailabilityThreshold
  const dataPoint = field.value
  const { externalSource: extSrc } = dataPoint

  /**
   * Prevents changing the value of the input field by scrolling while the
   * cursor is over it.
   */
  const handleOnWheel = (event: WheelEvent) => {
    if (document.activeElement === event.target) {
      event.preventDefault()
    }
  }

  const handleChange = async (event: FocusEvent) => {
    const target = event.target as HTMLInputElement
    const stringValue = target.value as string | null
    let newValue: number | null = null
    if (stringValue !== null && stringValue !== '') {
      newValue = Number(stringValue)
    }

    if (dataPoint.value === newValue) {
      // No change. Do nothing.
      return
    }

    // Update the data point value.
    dataPoint.value = newValue

    if (
      extSrc !== null &&
      extSrc.value !== null &&
      extSrc.dataAvailability >= threshold
    ) {
      // Ext. source is present, it has a value and data is available.
      dataPoint.isOverridden = newValue !== extSrc.value
    }

    // Update the formik field value.
    await helpers.setValue(dataPoint)

    // Call the onBlur callback if it is present.
    onBlur?.()
  }

  const Input = (
    <StyledMcInput
      slot='trigger'
      type='number'
      fit={windowSize}
      name={name}
      value={dataPoint.value}
      suffix={unit}
      hiddenlabel
      icon={dataPoint.isOverridden ? 'info-circle' : undefined}
      isManualInput={
        extSrc === null ||
        extSrc.value === null ||
        extSrc.dataAvailability < threshold
      }
      isIgnored={isIgnored}
      invalid={Boolean(meta.error)}
      errormessage={meta.error}
      // @ts-ignore
      onwheel={handleOnWheel}
      blur={handleChange}
      data-e2e={name}
    />
  )

  return extSrc !== null && dataPoint.isOverridden ? (
    <McTooltip position='top-center' appearance='neutral-inverse'>
      {Input}
      <span>
        Original value was: {extSrc.value} {unit}
      </span>
    </McTooltip>
  ) : (
    Input
  )
}

export default MetcInputField
