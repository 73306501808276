export interface colorScheme {
  [key: number]: string
}

export const maerskBlue: colorScheme = {
  50: '#f4f9fc',
  100: '#e0f0f6',
  200: '#c9e4f0',
  300: '#b1d8e9',
  400: '#8bc6df',
  500: '#64b2d4',
  600: '#4b9cc5',
  700: '#3486b5',
  800: '#236f9e',
  900: '#1d5c83',
}

export const maritimeBlue: colorScheme = {
  50: '#f2f5f7',
  100: '#ccd8df',
  200: '#a5bcc8',
  300: '#7e9eaf',
  400: '#3e6f88',
  500: '#003e5e',
  600: '#002a47',
  700: '#002038',
  800: '#00182f',
  900: '#000f23',
}

export const grey: colorScheme = {
  50: '#f9f9fa',
  100: '#f1f2f3',
  200: '#e0e2e4',
  300: '#d1d3d5',
  400: '#c2c5c8',
  500: '#a3a8ad',
  600: '#858b91',
  700: '#666e79',
  800: '#474e56',
  900: '#282f36',
}

export const gold: colorScheme = {
  50: '#fbf9f5',
  100: '#f6f2e8',
  200: '#f0e9d7',
  300: '#e9dec5',
  400: '#dfcfa9',
  500: '#d4be8c',
  600: '#c5aa72',
  700: '#b5965a',
  800: '#957740',
  900: '#725b31',
}

export const red: colorScheme = {
  50: '#fdf1f1',
  100: '#f9d9d7',
  200: '#f5bcba',
  300: '#f09f9b',
  400: '#e96f6a',
  500: '#e13f38',
  600: '#d62c26',
  700: '#c81a16',
  800: '#b10d0a',
  900: '#940b08',
}

export const orange: colorScheme = {
  50: '#fff8ef',
  100: '#ffebd2',
  200: '#ffdcb1',
  300: '#ffcd8e',
  400: '#ffb457',
  500: '#ff9b1e',
  600: '#ff8213',
  700: '#fa6a09',
  800: '#d94f02',
  900: '#b34102',
}

export const yellow: colorScheme = {
  50: '#fffbeb',
  100: '#fff6d2',
  200: '#fff0b1',
  300: '#ffe88e',
  400: '#ffde57',
  500: '#ffd21e',
  600: '#ffc313',
  700: '#fab209',
  800: '#d99202',
  900: '#a66f01',
}

export const green: colorScheme = {
  50: '#f2fbf3',
  100: '#d9f3de',
  200: '#bdeac6',
  300: '#a0e1ad',
  400: '#71d285',
  500: '#41c35c',
  600: '#2db044',
  700: '#1b9c2e',
  800: '#0e851d',
  900: '#0b6f18',
}

export const seaGreen: colorScheme = {
  50: '#f2fafa',
  100: '#daf2f0',
  200: '#bfe9e5',
  300: '#a2dfda',
  400: '#75cfc8',
  500: '#46bfb5',
  600: '#31ab9f',
  700: '#1e978a',
  800: '#118072',
  900: '#0e6b5f',
}

export const purple: colorScheme = {
  50: '#f5f4fa',
  100: '#e3dff0',
  200: '#cec8e5',
  300: '#b9b0da',
  400: '#9689c8',
  500: '#7361b5',
  600: '#6352a9',
  700: '#504098',
  800: '#43338d',
  900: '#36287a',
}
