import { useContext, useEffect, useState } from 'react'
import { Formik } from 'formik'
import { Moment } from 'moment'
import * as yup from 'yup'

import { Performance } from '../../../../api-models'
import { Loading, Modal } from '../../../../commons'
import { VesselPageContext } from '../../../../contexts'
import * as PerformanceApi from '../../../../services/performance'
import { displayErrorModal, isShoreContext } from '../../../../utils'
import { DebunkeringForm } from './DebunkeringForm'
import { DebunkeringInfo } from './DebunkeringInfo'

export interface DebunkeringFormValues {
  timestamp?: Moment
  batchId: string
  quantity: string
  portCode: string
  reasonCode: string
  notes: string
}

let defaultInitialValues: DebunkeringFormValues = {
  batchId: '',
  quantity: '',
  portCode: '',
  reasonCode: '',
  notes: '',
}

const validationSchema = yup.object().shape({
  batchId: yup.string().required('Debunkered from is a required field'),
  quantity: yup.number().required('Debunkered quantity is a required field'),
  notes: yup
    .string()
    .notRequired()
    .max(1000, 'Notes cannot be more than 1000 characters long'),
  timestamp: yup.date().required('Time of debunkering is a required field'),
  reasonCode: yup
    .string()
    .required('Reason for debunkering is a required field'),
  portCode: yup.string().required('Port code is a required field'),
})

type Props = {
  entryId?: string
  closeHandler: (refreshAdjustments?: boolean) => void
}

export const DebunkeringModal = ({ entryId, closeHandler }: Props) => {
  const imoNo = useContext(VesselPageContext).imoNo!
  const [debunkering, setDebunkering] =
    useState<Performance.FuelOilStock.DebunkeringEntry>()
  const disabled = isShoreContext() || (!!debunkering && debunkering.readonly)
  const [openWindow, setOpenWindow] =
    useState<Performance.FuelOilStock.OpenWindow>()
  const [batches, setBatches] =
    useState<Performance.FuelOilStock.BatchResponse[]>()
  const shouldShowCreateNewEntry = !entryId

  const submitDebunkering = (
    values: DebunkeringFormValues,
    setSubmitting: (isSubmitting: boolean) => void,
  ) => {
    const debunkeringRequestBody: Performance.FuelOilStock.Debunkering = {
      ...values,
      timestamp: values.timestamp ? values.timestamp.toISOString() : '',
    }
    const request = debunkering
      ? PerformanceApi.putDebunkering(
          imoNo,
          debunkeringRequestBody,
          debunkering.id,
        )
      : PerformanceApi.postDebunkering(imoNo, debunkeringRequestBody)

    request
      .then(() => closeHandler(true))
      .catch((e) => {
        setSubmitting(false)
        void displayErrorModal(e)
      })
  }

  const deleteDebunkering = (
    debunkeringId: string,
    setSubmitting?: (isSubmitting: boolean) => void,
  ) => {
    if (setSubmitting) setSubmitting(true)
    PerformanceApi.deleteDebunkering(imoNo, debunkeringId)
      .then(() => closeHandler(true))
      .catch((e) => {
        if (setSubmitting) setSubmitting(false)
        void displayErrorModal({
          statusText: 'Could not delete debunkering',
          message: e.message,
        })
      })
  }

  useEffect(() => {
    if (entryId) {
      PerformanceApi.getDebunkering(imoNo, entryId)
        .then((data: Performance.FuelOilStock.DebunkeringEntry) =>
          setDebunkering(data),
        )
        .catch((e) =>
          displayErrorModal({
            statusText: 'Could not get debunkering',
            message: e.message,
          }),
        )
    }
  }, [entryId, imoNo])

  useEffect(() => {
    if (debunkering) {
      const { batchId } = debunkering
      const batchIds = [batchId]
      PerformanceApi.getBatchesById(imoNo, batchIds)
        .then(setBatches)
        .catch((e) =>
          displayErrorModal({
            statusText: 'Could not get batches',
            message: e.message,
          }),
        )
    }
  }, [debunkering, imoNo])

  useEffect(() => {
    if (imoNo) {
      PerformanceApi.getStockOpenWindow(imoNo)
        .then(setOpenWindow)
        .catch((e) =>
          displayErrorModal({
            statusText: 'Could not get allowed time of loss interval',
            message: e.message,
          }),
        )
    }
  }, [imoNo])

  return (
    <Modal
      title={shouldShowCreateNewEntry ? 'Add debunkering' : 'View debunkering'}
      visible
      closeHandler={closeHandler}
      helpTextKey='stock/debunkering'
    >
      {(entryId && !debunkering) || (debunkering && !batches) || !openWindow ? (
        <Loading />
      ) : shouldShowCreateNewEntry ? (
        <Formik
          initialValues={defaultInitialValues}
          onSubmit={(values, { setSubmitting }) =>
            submitDebunkering(values, setSubmitting)
          }
          validationSchema={validationSchema}
        >
          <DebunkeringForm
            disabled={disabled}
            debunkering={debunkering}
            closeHandler={closeHandler}
            deleteHandler={deleteDebunkering}
            openWindow={openWindow}
          />
        </Formik>
      ) : (
        <DebunkeringInfo
          batches={batches}
          entry={debunkering}
          closeHandler={closeHandler}
          deleteHandler={() => {
            if (!!entryId) {
              deleteDebunkering(entryId, undefined)
            }
          }}
        />
      )}
    </Modal>
  )
}
