import { getTechnicalIssuesResolvedCounts } from './Diagnostics.utils'
import moment from 'moment'

/**
 * Validates the operational issues form step
 */
export const validateOperationalIssues = (
  issues: Diagnostics.OperationalIssues | null,
): object => {
  if (!issues) {
    return {}
  }
  if (Object.values(issues.items).some((v) => v)) {
    // At least one issue checked. DateTime is required.
    let errors = {}
    if (!issues.resolvedBy) {
      errors = {
        operationalIssues: {
          resolvedBy: 'Required',
        },
      }
    } else if (moment.utc(issues.resolvedBy).isBefore(moment.utc())) {
      errors = {
        operationalIssues: {
          resolvedBy: 'Cannot be in the past',
        },
      }
    }
    return errors
  } else {
    // No issues are checked. Add an error for each issue.
    const errors = {
      operationalIssues: {
        items: {},
      },
    }

    for (const key in issues.items) {
      if (!issues.items[key]) {
        errors.operationalIssues.items[key] = 'Required'
      }
    }
    return errors
  }
}

/**
 * Validates the technical issues form step. This form step comprises technical
 * issues and data quality concerns.
 */
export const validateTechnicalIssues = (
  issues: Diagnostics.TechnicalIssues | null,
): object => {
  const { resolved, total } = getTechnicalIssuesResolvedCounts(issues)

  if (resolved === 0) {
    // No issues are checked
    return {
      technicalIssues: {
        itemsError:
          'If you do not intent to resolve any warnings, please dismiss the alert',
        dataQualityError:
          'If you do not intent to address any data quality concerns, please dismiss the alert',
      },
    }
  }

  if (resolved < total) {
    // Some issues are checked. DateTime is required.
    let errors = {}
    if (!issues?.resolvedBy) {
      errors = {
        technicalIssues: {
          resolvedBy: 'Required',
        },
      }
    } else if (moment.utc(issues.resolvedBy).isBefore(moment.utc())) {
      errors = {
        technicalIssues: {
          resolvedBy: 'Cannot be in the past',
        },
      }
    }
    return errors
  }

  // resolved === total
  return {}
}

/**
 * Validates the schedule main engine performance test (METC) form step
 */
export const validateScheduleMetc = (
  data: Diagnostics.ScheduleMetc | null,
): object => {
  let errors = {}
  if (!data?.scheduledAt) {
    errors = {
      scheduleMetc: {
        scheduledAt: 'Required',
      },
    }
  } else if (moment.utc(data.scheduledAt).isBefore(moment.utc())) {
    errors = {
      scheduleMetc: {
        scheduledAt: 'Cannot be in the past',
      },
    }
  }
  return errors
}

/**
 * Validates that the `comment` field is not empty when dismissing a step
 */
export const validateDismiss = (
  stepId: keyof Diagnostics.FormData,
  data: { comment?: string | null } | null,
): object => {
  return data?.comment ? {} : { [stepId]: { comment: 'Required' } }
}
