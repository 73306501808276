import styled from 'styled-components'

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  .shore-power-fields {
    display: grid;
    grid-template-rows: 1fr 1fr;
  }
`
