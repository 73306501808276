import { roundNumber } from '../../utils/math-utils'

const k0 = 186.9696
const k1 = 0.4862

export const volumeToMassCalculation: (
  volume: number,
  temperature: number,
  density: number,
) => number = (volume, temperature, density) => {
  const volumeInLiters = volume * 1000
  const alpha15 = k0 / Math.pow(density, 2) + k1 / density
  const deltaT = temperature - 15
  const volumeCorrectionFactor = roundNumber(
    Math.exp(-alpha15 * deltaT * (1 + 0.8 * alpha15 * deltaT)),
    4,
  )
  const standardVolume = roundNumber(volumeInLiters * volumeCorrectionFactor, 0)
  const mass = (standardVolume * (density - 1.1)) / (1000 * 1000)
  return mass
}
