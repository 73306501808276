import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import { hubConnection } from 'signalr-no-jquery'

import { routerParams } from '../../routes'
import { IVesselPageContext } from '../../contexts'
import { withVesselPageContext } from '../../higher-order-components'
import { displayErrorModal, getSensorApiSocketAddress } from '../../utils'
import { DataPointsTable } from '../../features/data-points'
import { ContentCard, Loading, Tabs } from '../../commons'
import { ContentLayout } from '../../layout'
import { getSystemDataPointGroups } from '../../services/sensors'
import TemNotifications from '../../components/TemNotifications/TemNotifications'
import PerformanceAlerts from '../../components/PerformanceAlerts/PerformanceAlerts'

interface Props extends RouteComponentProps<routerParams>, IVesselPageContext {}

interface State {
  hubConnection: null | any
  initializingConnection: boolean
  hubProxy: null | any
  activeFeed: 'CAMS' | 'Consumption' | 'Bunkering'
  dataPointGroups?: Array<SensorStatusApi.System.DataPointGroup>
  dataPoints?: Array<SensorStatusApi.SignalR.DataPoint>
  elapsedSeconds?: number
  elapsedPercentage?: number
  remainingSeconds?: number
}

class DataPointsPageLegacy extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      hubConnection: null,
      hubProxy: null,
      initializingConnection: true,
      activeFeed: 'CAMS',
    }
  }

  startStream = () => {
    const connection = hubConnection(`${getSensorApiSocketAddress()}/api`, {
      useDefaultPath: false,
    })

    const hubProxy = connection.createHubProxy('dataPointHub')
    hubProxy.on(
      'update',
      (
        type: string,
        connected: boolean,
        log: SensorStatusApi.SignalR.DataPointLog,
      ) => {
        this.setState({
          dataPoints: log.dataPoints,
          elapsedPercentage: log.elapsedPercentage,
          elapsedSeconds: log.elapsedSeconds,
          remainingSeconds: log.remainingSeconds,
          initializingConnection: !connected,
        })
      },
    )

    this.setState({ hubConnection: connection, hubProxy }, () => {
      this.state.hubConnection
        .start()
        .done(() => {
          this.state.hubProxy.invoke('subscribe', this.state.activeFeed)
          this.setState({ initializingConnection: false })
        })
        .fail((e: any) => displayErrorModal(e))
    })
  }

  getDataPointGroups = () => {
    const { imoNo } = this.props
    if (imoNo) {
      getSystemDataPointGroups(imoNo)
        .then((json) => {
          this.setState({ dataPointGroups: json })
        })
        .catch((e) => displayErrorModal(e))
    }
  }

  componentDidMount() {
    this.startStream()
    this.getDataPointGroups()
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    switch (true) {
      case prevState.activeFeed !== this.state.activeFeed:
        this.state.hubProxy.invoke('unsubscribe', prevState.activeFeed)
        this.state.hubProxy.invoke('subscribe', this.state.activeFeed)
    }
  }

  componentWillUnmount() {
    this.state.hubProxy?.invoke('unsubscribe', this.state.activeFeed)
  }

  public render() {
    const {
      dataPoints,
      elapsedPercentage,
      elapsedSeconds,
      dataPointGroups,
      activeFeed,
      initializingConnection,
      remainingSeconds,
    } = this.state

    return (
      <ContentLayout header='Data points'>
        <TemNotifications />
        <PerformanceAlerts />
        <ContentCard id='data-points' title='Data points'>
          <Tabs
            tabButtons={[
              {
                name: 'CAMS',
                onClick: () => {
                  this.setState({
                    activeFeed: 'CAMS',
                    initializingConnection: true,
                  })
                },
              },
              {
                name: 'Consumption',
                onClick: () => {
                  this.setState({
                    activeFeed: 'Consumption',
                    initializingConnection: true,
                  })
                },
              },
            ]}
          >
            <div className='table'>
              {!!initializingConnection && <Loading />}
              {!initializingConnection &&
                dataPointGroups &&
                dataPoints &&
                elapsedPercentage &&
                remainingSeconds &&
                elapsedSeconds && (
                  <DataPointsTable
                    dataPointGroups={dataPointGroups}
                    dataPoints={dataPoints}
                    elapsedPercentage={elapsedPercentage}
                    elapsedSeconds={elapsedSeconds}
                    remainingSeconds={remainingSeconds}
                    activeFeed={activeFeed}
                  />
                )}
            </div>
            <div className='table'>
              {!!initializingConnection && <Loading />}
              {!initializingConnection &&
                dataPointGroups &&
                dataPoints &&
                elapsedPercentage &&
                remainingSeconds &&
                elapsedSeconds && (
                  <DataPointsTable
                    dataPointGroups={dataPointGroups}
                    dataPoints={dataPoints}
                    elapsedPercentage={elapsedPercentage}
                    elapsedSeconds={elapsedSeconds}
                    remainingSeconds={remainingSeconds}
                    activeFeed={activeFeed}
                  />
                )}
            </div>
          </Tabs>
        </ContentCard>
      </ContentLayout>
    )
  }
}

export default withRouter(withVesselPageContext(DataPointsPageLegacy))
