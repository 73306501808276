import * as React from 'react'
import styled from 'styled-components'

export interface FilterPaneProps {
  title: string
  children?: React.ReactNode
}

const Wrapper = styled.div`
  font-weight: normal;
  padding: 16px;
  &:first-of-type {
    padding-bottom: bottom;
  }
`

const FilterPane = (props: FilterPaneProps) => {
  const { title, children } = props
  return (
    <Wrapper>
      <h4>{title}</h4>
      <hr />
      <div>{children}</div>
    </Wrapper>
  )
}

export default FilterPane
