import { useMemo } from 'react'
import * as moment from 'moment'
import { McButton } from '@maersk-global/mds-react-wrapper'

import { Performance } from '../../../../api-models'
import styled from '../../../../theme'
import { FormElements, ModalControls } from '../../../../commons'
import { isShoreContext } from '../../../../utils'
import { STOCK_DEBUNKERING_REASON_OPTIONS } from '../../../../utils/constants'
import { useTerminals } from '../../../../queries/MasterDataApi/MasterDataApi'
import { getTerminalOption } from '../../../hybrid-data-collector/reports/reports.utils'

const Wrapper = styled.div`
  table {
    padding: 16px;
  }
`

const Notes = styled.div`
  display: flex;
  overflow: scroll;
  margin-right: 5px;
  min-height: 32px;
  max-height: 200px;
  max-width: 450px;
  font-size: 14px;
  white-space: pre-line;
`

type Props = {
  closeHandler: () => void
  entry?: Performance.FuelOilStock.DebunkeringEntry
  batches?: Performance.FuelOilStock.BatchResponse[]
  deleteHandler: () => void
}

export const DebunkeringInfo = (props: Props) => {
  const { data: terminals, isSuccess: isTerminalsSuccess } = useTerminals()

  const activeTerminalOptions = useMemo(() => {
    if (!isTerminalsSuccess) return []

    const result = terminals
      .filter((t) => t.data.isActive)
      .map(getTerminalOption)

    if (
      props.entry?.portCode &&
      !result.find((t) => t.value === props.entry?.portCode)
    ) {
      // Workaround: Add the port code from the initial values if it is not in the list
      result.push({
        label: props.entry.portCode,
        value: props.entry.portCode,
      })
    }

    return result
  }, [terminals, isTerminalsSuccess, props.entry])

  if (!props.entry || !props.batches) {
    return null
  }

  const {
    timestamp,
    batchId,
    quantity,
    notes,
    readonly,
    portCode,
    reasonCode,
  } = props.entry
  const batchDebunkeredName = props.batches.find(
    ({ id }) => id === batchId,
  )?.displayName

  return (
    <Wrapper>
      <FormElements.ReadonlyTable>
        <tbody>
          <tr>
            <td>
              <FormElements.Label>Time of debunkering UTC</FormElements.Label>
            </td>
            <td>
              <FormElements.Value>
                {timestamp
                  ? moment.utc(timestamp).format('DD MMM YYYY - HH:mm')
                  : ''}
              </FormElements.Value>
            </td>
          </tr>
          <tr>
            <td>
              <FormElements.Label>Batch debunkered</FormElements.Label>
            </td>
            <td>
              <FormElements.Value>{batchDebunkeredName}</FormElements.Value>
            </td>
          </tr>
          <tr>
            <td>
              <FormElements.Label>Debunkered quantity</FormElements.Label>
            </td>
            <td>
              <FormElements.Value>{quantity} MT</FormElements.Value>
            </td>
          </tr>
          <tr>
            <td>
              <FormElements.Label>Port code</FormElements.Label>
            </td>
            <td>
              <FormElements.Value>
                {
                  activeTerminalOptions.find(
                    (portCodeOption) => portCodeOption.value === portCode,
                  )?.label
                }
              </FormElements.Value>
            </td>
          </tr>
          <tr>
            <td>
              <FormElements.Label>Reason for debunkering</FormElements.Label>
            </td>
            <td>
              <FormElements.Value>
                {
                  STOCK_DEBUNKERING_REASON_OPTIONS.find(
                    (reasonCodeOption) => reasonCodeOption.value === reasonCode,
                  )?.label
                }
              </FormElements.Value>
            </td>
          </tr>
          <tr>
            <td>
              <FormElements.Label>Notes</FormElements.Label>
            </td>
            <td>
              <Notes>{notes}</Notes>
            </td>
          </tr>
        </tbody>
      </FormElements.ReadonlyTable>
      <ModalControls>
        <McButton
          label='Close'
          appearance='neutral'
          click={props.closeHandler}
          type='button'
        />
        {!readonly && (
          <McButton
            label='Delete'
            appearance='error'
            className='left'
            disabled={isShoreContext()}
            click={props.deleteHandler}
            type='button'
          />
        )}
      </ModalControls>
    </Wrapper>
  )
}
