import { Event } from './events'

export enum ApiReportType {
  SEA = 'sea',
  ARRIVAL = 'arrival',
  ALONGSIDE = 'alongside',
  DEPARTURE = 'departure',
  CANAL = 'canal',
}

export enum ApiReportState {
  DRAFT = 'draft',
  SUBMITTED = 'submitted',
  EDIT = 'edit',
  INVALID = 'invalid',
}

export type ExternalSource<T> = {
  name: string
  value: T
  dataAvailability: number
}

export interface DataPoint<T> {
  value: T | null
  isOverridden: boolean
  externalSource: ExternalSource<T> | null
}

export type MainEngine = {
  number: number
  averagePower: DataPoint<number>
  runTime: DataPoint<number>
  averageShaftRpm: DataPoint<number>
  tcco: DataPoint<boolean> | null
}

export type UpdateMainEngine = {
  number: number
  averagePower: number | null
  runTime: number | null
  averageShaftRpm: number | null
  tcco: boolean | null
}

export interface Equipment {
  number?: number
  runTime: DataPoint<number>
  energy: DataPoint<number>
}

export interface UpdateEquipment {
  number?: number
  runTime: number | null
  energy: number | null
}

export interface UpdateShorePowerPeriods {
  periods: Period[] | null
  numberOfPeriods: number
  grandTotalEnergy: number
  grandTotalDays: number
  grandTotalHoursMinutes: string
  shorePowerUsed: string | null
  reason: string
  comment: string
}

export type FuelLine = {
  fuelLine: number //Lars should make this an enum from PAPI
  consumedMass: number
  dataAvailability: number | null
}

export type Batch = {
  batchId: string
  rob: number
  fuelLines: FuelLine[]
}

export type PeriodDetails = {
  date: string
  energy: number
}
export type Period = {
  periodStart: PeriodDetails
  periodEnd: PeriodDetails
  totalEnergy: number
  totalDays: number
  totalHoursMinutes: string
}

export enum OilCode {
  NORMAL = 'Normal',
  RUNNING_IN_LINERS_AND_RINGS = 'Running in liners and rings',
  SPECIAL_INSTRUCTION = 'Special instruction',
}

export type CylinderLubeOil = {
  number: number
  consumptionLiters: DataPoint<number>
  density: DataPoint<number>
  avgTemperature: DataPoint<number>
  oilCode: DataPoint<OilCode>
  consumptionKg: DataPoint<number>
  totalBaseNumber: DataPoint<number>
}

export type UpdateCylinderLubeOil = {
  number: number | null
  consumptionLiters: number | null
  density: number | null
  avgTemperature: number | null
  oilCode: OilCode | null
  totalBaseNumber: number | null
}

export interface TimeDataPoint<T> extends DataPoint<T> {}

export type TWarning = {
  message: string
  paths: string[]
}

export interface IReport {
  id: string
  seqNo: number //for easy communication between vessel and shore teams(business req.)
  imo: string
  periodStart: string //2020-07-02T10:00:00Z
  periodEnd: string
  reportType: ApiReportType
  spec: string //semver
  eventsCount: number
  createdAt: string
  modifiedAt: string
  submittedAt: string | null
  receivedOnShoreAt: string | null
  transferredToDatalakeAt: string | null
  bridge: {
    departureTerminal: {
      code: DataPoint<string>
      name: DataPoint<string>
    } | null
    alternativeDepartureTerminal: {
      name: string
      code: string
    } | null
    arrivalTerminal: {
      code: DataPoint<string>
      name: DataPoint<string>
    } | null
    arrivalTerminalOriginal: {
      code: DataPoint<string>
      name: DataPoint<string>
    } | null
    alternativeArrivalTerminal: {
      name: string
      code: string
    } | null
    alternativeArrivalTerminalOriginal: {
      name: string
      code: string
    } | null
    eta: TimeDataPoint<string>
    etaOriginal: TimeDataPoint<string>
    draughtFore: DataPoint<number>
    draughtAft: DataPoint<number>
    ballastWater: DataPoint<number>
    distanceLogged: DataPoint<number>
    distanceObserved: DataPoint<number>
    remainingDistanceToPilotStation: DataPoint<number>
    remainingDistanceToPilotStationOriginal: DataPoint<number>
    remainingTimeToPilotStation: DataPoint<number>
    waterTemperature: DataPoint<number>
    waterDepth: DataPoint<number>
    state: ApiReportState
    comment: string
    events: Event[] | null
  }
  engineRoom: {
    mainEngines: MainEngine[]
    auxEngines: Equipment[]
    shorePowerPeriods: {
      periods: Period[] | null
      numberOfPeriods: number
      grandTotalEnergy: number
      grandTotalDays: number
      grandTotalHoursMinutes: string
      shorePowerUsed: string | null
      reason: string
      comment: string
    } | null
    wasteHeatRecovery: Equipment | null
    shaftGenerators: Equipment[]
    shaftMotors: Equipment[]
    auxBlowers: Equipment[]
    scrubberSystem: Equipment | null
    reeferEnergy: DataPoint<number>
    numberOfReefers: DataPoint<number>
    fuelConsumption: {
      batches: Batch[]
      sludge: {
        total: number
      }
      leakageMass: number
    }
    cylindersLubeOil: CylinderLubeOil[]
    comment: string //this is a random comment that can be provided if relevant
    state: ApiReportState
    events: Event[] | null
  }
  warnings: TWarning[]
}
export interface HdcReportResponse {
  report: IReport
}

export interface HdcReportsResponse {
  reports: IReport[]
}

export type GandalfReportValidationError = {
  paths: string[]
  message: string
}

export interface UpdateEngineRoomReportRequest {
  engineRoom: Partial<{
    shorePowerPeriods: UpdateShorePowerPeriods | null
    mainEngines: UpdateMainEngine[]
    auxEngines: UpdateEquipment[]
    wasteHeatRecovery: UpdateEquipment | null
    shaftGenerators: UpdateEquipment[]
    shaftMotors: UpdateEquipment[]
    auxBlowers: UpdateEquipment[]
    scrubberSystem: UpdateEquipment | null
    cylindersLubeOil: UpdateCylinderLubeOil[]
    reeferEnergy: number | null
    numberOfReefers: number | null
    comment: string
  }>
}

export interface UpdateBridgeReportRequest {
  bridge: Partial<{
    departureTerminal: {
      code: string
      name: string
    } | null
    alternativeDepartureTerminal: {
      code: string
      name: string
    } | null
    arrivalTerminal: {
      code: string
      name: string
    } | null
    alternativeArrivalTerminal: {
      code: string
      name: string
    } | null
    eta: string | null
    draughtFore: number | null
    draughtAft: number | null
    ballastWater: number | null
    distanceLogged: number | null
    distanceObserved: number | null
    remainingDistanceToPilotStation: number | null
    waterTemperature: number | null
    waterDepth: number | null
    comment: string | null
  }>
}
