import * as React from 'react'
import * as ECharts from 'echarts'
import { Performance } from '../../../api-models'
import {
  areaGraphPadding,
  areaYMinDomain,
  calculateYDomainAverages,
  formatValue,
  lineGraphPadding,
  lineYMinDomain,
} from '../../../utils'
import { grey, maerskBlueTheme, orangeTheme } from '../../../theme'
import { Chart } from '../../../utils/models'
import {
  ACTIVATE_ZOOM_ACTION,
  AXIS_LABEL,
  AXIS_LINE,
  AXIS_SPLIT_LINE,
  AXIS_TICK,
  DATA_ZOOM_TIMELINE,
  GRID,
  TOOLBOX_TIMELINE,
  X_AXIS_TIMELINE,
} from '../../../utils/constants'

export interface TrimConsumptionChartProps {
  data: Performance.Trim.Monitor
  tooltipFormatter: ECharts.EChartOption.Tooltip.Formatter
  showConsumption: boolean
  showTrim: boolean
}

export interface TrimConsumptionChartState {}

export class TrimConsumptionChart extends React.Component<
  TrimConsumptionChartProps,
  TrimConsumptionChartState
> {
  private chartNode: React.RefObject<HTMLDivElement>
  private chart?: Chart
  constructor(props: TrimConsumptionChartProps) {
    super(props)

    this.state = {}
    this.chartNode = React.createRef()
  }

  getOptions = (data: Performance.Trim.Monitor): ECharts.EChartOption => {
    const { showConsumption, showTrim } = this.props
    return {
      xAxis: {
        ...X_AXIS_TIMELINE,
        min: data.queryPeriod.from,
        max: data.queryPeriod.to,
      },
      yAxis: [
        {
          id: 'consumption',
          type: 'value',
          axisTick: AXIS_TICK,
          axisLabel: {
            formatter: (value: any) => formatValue(value, 1),
            ...AXIS_LABEL,
          },
          axisLine: AXIS_LINE,
          splitLine: {
            show: false,
          },
        },
        {
          id: 'trim',
          type: 'value',
          axisTick: AXIS_TICK,
          axisLabel: {
            formatter: (value: any) => formatValue(value, 1),
            ...AXIS_LABEL,
          },
          axisLine: AXIS_LINE,
          splitLine: AXIS_SPLIT_LINE,
        },
      ],
      grid: GRID,
      toolbox: TOOLBOX_TIMELINE,
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'line',
        },
        textStyle: {
          color: grey[50],
        },
        backgroundColor: 'rgba(50,50,50,0.9)',
        formatter: this.props.tooltipFormatter,
      },
      dataZoom: [
        DATA_ZOOM_TIMELINE,
        {
          id: 'consumption',
          type: 'select',
          yAxisIndex: 0,
        },
        {
          id: 'trim',
          type: 'select',
          yAxisIndex: 1,
        },
      ],
      series: [
        {
          animation: false,
          name: 'trim',
          type: 'line',
          symbol: 'none',
          lineStyle: {
            color: orangeTheme.bg,
            opacity: showTrim ? 1 : 0,
          },
          yAxisIndex: 1,
          data: data.timestamps
            ? data.timestamps.map((timestamp, index) => {
                return [timestamp, data.trim[index]]
              })
            : [],
        },
        {
          animation: false,
          name: 'consumption',
          type: 'line',
          symbol: 'none',
          lineStyle: {
            color: maerskBlueTheme.bg,
            opacity: showConsumption ? 1 : 0,
          },
          areaStyle: {
            color: maerskBlueTheme.bg,
            opacity: showConsumption ? 0.2 : 0,
          },
          yAxisIndex: 0,
          data: data.timestamps
            ? data.timestamps.map((timestamp, index) => {
                return [timestamp, data.consumption[index]]
              })
            : [],
        },
      ],
    }
  }

  zoomOut = (silent?: boolean) => {
    if (this.chart) {
      this.chart.dispatchAction(
        {
          type: 'dataZoom',
          start: 0,
          end: 100,
        },
        !!silent,
      )
    }
  }

  zoomIn = (startValue: number, endValue: number) => {
    const { data } = this.props
    if (this.chart) {
      const averageDomains = calculateYDomainAverages(startValue, endValue, {
        timestamps: data.timestamps,
        trim: data.trim,
        consumption: data.consumption,
      })
      const trimDomain = lineGraphPadding(
        lineYMinDomain(averageDomains['trim'], 0),
      )
      const consumptionDomain = areaGraphPadding(
        areaYMinDomain(averageDomains['consumption'], 0),
      )
      const xBatch = { id: 'timestamp', startValue, endValue }
      this.chart.dispatchAction(
        {
          type: 'dataZoom',
          batch: [
            xBatch,
            {
              id: 'trim',
              dataZoomIndex: 2,
              startValue: trimDomain.min,
              endValue: trimDomain.max,
            },
            {
              id: 'consumption',
              dataZoomIndex: 1,
              startValue: consumptionDomain.min,
              endValue: consumptionDomain.max,
            },
          ],
        },
        true,
      )
    }
  }

  componentDidMount() {
    const { data } = this.props
    if (this.chartNode.current) {
      const chart = ECharts.init(this.chartNode.current) as Chart
      chart.setOption(this.getOptions(data))
      chart.dispatchAction(ACTIVATE_ZOOM_ACTION)
      this.chart = chart
    }
  }

  componentDidUpdate(prevProps: TrimConsumptionChartProps) {
    if (
      this.chart &&
      (prevProps.showConsumption !== this.props.showConsumption ||
        prevProps.showTrim !== this.props.showTrim)
    ) {
      this.chart.setOption(this.getOptions(this.props.data))
    }
  }

  public render() {
    return (
      <div
        onDoubleClick={() => {
          this.zoomOut(false)
        }}
        id='trim-consumption'
        ref={this.chartNode}
      />
    )
  }
}
