import styled, { maerskBlue } from '../../../theme'

export const DefaultChartWrapper = styled.div<{ rotation: number }>`
  height: 168px;
  display: flex;
  padding: 16px;
  text-align: center;
  justify-content: space-between;
  position: relative;

  .valueContainer {
    position: absolute;
  }

  .value-title {
    display: block;
    font-size: 12px;
    color: #a4a1ad;
  }

  .top {
    top: 8px;
  }

  .bottom {
    bottom: 8px;
  }

  .left {
    left: 16px;
    text-align: left;
  }

  .middle {
    left: 0;
    right: 0;
    text-align: center;
  }

  .right {
    right: 16px;
    text-align: right;
  }

  .values {
    display: block;
    font-size: 16px;
    color: #000;
  }

  .line {
    position: absolute;
    top: 57%;
    background-color: ${maerskBlue[500]};
    height: 2px;
    width: calc(100% - 32px);
    border: none;
    z-index: 1001;
    margin: 8px 0;
  }

  #draughtLine {
    z-index: 1000;
    transform: rotate(${(props) => props.rotation}deg);
    background-color: ${maerskBlue[500]};
  }

  .vesselIcon {
    position: absolute;
    top: 34%;
    transform: rotate(${(props) => props.rotation}deg);
    left: 0;
    right: 0;
    width: 250px;
    height: 60px;
    margin: auto;
    transition: transform 1s ease-in-out;
  }
`
