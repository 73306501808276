import { FuelConsumptionSeriesModel as FuelConsumptionSeriesModelType } from './models/fuel-consumption'
export type FuelConsumptionSeriesModel = FuelConsumptionSeriesModelType

export { FuelConsumption } from './components/FuelConsumption'
export { FuelConsumptionChart } from './charts/FuelConsumptionChart'
export { FuelConsumptionBlock } from './components/FuelConsumptionBlock'
export { useConsumerConsumption } from './hooks/useConsumerConsumption'
export { getConsumptionSeries } from './mappers'
export {
  mapSeriesZero,
  convertToFuelLineUnit,
  getDecimalsFromUnit,
} from './utils/fuel-consumption'
