import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: -12px;
`

export const Txt = styled.span`
  margin-left: 8px;
  font-size: 12px;
`

export const Circle = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid var(--mds_brand_appearance_neutral_default_border-color);
  background-color: var(--mds_brand_appearance_primary_subtle_background-color);
  margin-left: 16px;
`
