import { type FormikContextType } from 'formik'

import {
  TabIndex,
  MetcReportTabSearchParam,
  TabIndexToSection,
  type TabsStatus,
  TabStatus,
} from './EnginePerformanceTestReportPage.consts'
import { displayToast, doPost, getGandalfV2Url } from '../../../utils'
import { ReportState } from '../../../queries/GandalfApi/GandalfApi.consts'

/**
 * Resolves the initial tab index based on the URL search params. If the search
 * params do not contain a valid tab index, we default to the
 * <strong>General</strong> tab.
 */
export const resolveInitialTabIndex = (searchParams: URLSearchParams) => {
  const defaultTabIdx = TabIndex.GENERAL

  const initialTabIdx = parseInt(
    searchParams.get(MetcReportTabSearchParam) ?? defaultTabIdx.toString(),
  )

  const isValidTabIdx = Object.values(TabIndex).includes(initialTabIdx)

  return isValidTabIdx ? initialTabIdx : defaultTabIdx
}

/**
 * Resolves the initial status of the report tabs/sections based on the report
 * state. If the report state is `submitted`, all tabs are marked as done.
 */
export const resolveInitialTabsStatus = (
  state: GandalfApi.ReportState,
): TabsStatus => {
  return state === ReportState.Submitted
    ? getDoneTabsStatus()
    : getIndeterminateTabsStatus()
}

/**
 * Resolves the status of the report tabs/sections based on the errors returned
 * from Gandalf.
 */
export const resolveTabsStatus = (err: GandalfApi.ValidationError) => {
  const status = getDoneTabsStatus()
  err.body.errors.forEach((error) => {
    error.paths.forEach((path) => {
      if (path.startsWith(TabIndexToSection[TabIndex.GENERAL])) {
        status[TabIndex.GENERAL] = TabStatus.ERROR
      } else if (path.startsWith(TabIndexToSection[TabIndex.CYLINDERS])) {
        status[TabIndex.CYLINDERS] = TabStatus.ERROR
      } else if (path.startsWith(TabIndexToSection[TabIndex.TURBOCHARGERS])) {
        status[TabIndex.TURBOCHARGERS] = TabStatus.ERROR
      }
    })
  })

  if (err.body.errors.length > 0) {
    status[TabIndex.RESULTS] = TabStatus.ERROR
  }

  return status
}

/**
 * Yields a {@link TabsStatus} object with all tabs set to
 * {@link TabsStatus.INDETERMINATE}.
 */
export const getIndeterminateTabsStatus = (): TabsStatus => ({
  [TabIndex.GENERAL]: TabStatus.INDETERMINATE,
  [TabIndex.CYLINDERS]: TabStatus.INDETERMINATE,
  [TabIndex.TURBOCHARGERS]: TabStatus.INDETERMINATE,
  [TabIndex.RESULTS]: TabStatus.INDETERMINATE,
})

/**
 * Yields a {@link TabsStatus} object with all tabs set to
 * {@link TabsStatus.SUCCESS}.
 */
const getDoneTabsStatus = (): TabsStatus => ({
  [TabIndex.GENERAL]: TabStatus.SUCCESS,
  [TabIndex.CYLINDERS]: TabStatus.SUCCESS,
  [TabIndex.TURBOCHARGERS]: TabStatus.SUCCESS,
  [TabIndex.RESULTS]: TabStatus.SUCCESS,
})

/**
 * Sets the field errors on a Formik form based on errors returned from Gandalf.
 */
export const setFieldErrors = (
  err: GandalfApi.ValidationError,
  setFieldError: (field: string, message: string | undefined) => void,
) => {
  err.body.errors.forEach((error) => {
    error.paths.forEach((path) => {
      setFieldError(`data.${path.replace(/\.\./g, '.')}`, error.message)
    })
  })
}

/**
 * Fires a request to the `recalculate` Gandalf endpoint which takes the form
 * values as input and returns a report object with recalculated values. Then it
 * sets the form values to the new report object.
 */
export const recalculateResults = async (
  ctx: FormikContextType<GandalfApi.MetcReport>,
) => {
  const { values, setValues } = ctx
  const { imoNo, reportId } = values

  try {
    const newValues = await doPost(
      `${getGandalfV2Url()}/vessels/${imoNo}/reports/${reportId}/recalculate`,
      values,
    )
    setValues(newValues)
  } catch (e) {
    void displayToast('error', 'Failed to update calculated values', '')
  }
}

export const displaySuccessToast = () => {
  void displayToast(
    'success',
    'Report submitted',
    'You have successfully submitted the Engine performance test report.',
  )
}
