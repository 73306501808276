import { getDifference } from './EnginePerformanceTestReportsComparison.utils'
import { formatValue } from '../../../utils'

type Props = {
  reportsCompared: Array<GandalfApi.MetcReport>
}

const LoadCondition = ({ reportsCompared }: Props) => {
  const a = reportsCompared[0].data
  const b = reportsCompared[1].data

  return (
    <>
      <tr>
        <th>Load condition</th>
        <th />
        <th />
        <th />
      </tr>
      <tr>
        <td>Ambient pressure [mbar]</td>
        {reportsCompared.map((rc) => (
          <td key={rc.reportId} className='mds-table__cell--number'>
            {rc.data.general.erAmbientPres.value}
          </td>
        ))}
        <td className='mds-table__cell--number'>
          {getDifference(
            a.general.erAmbientPres.value!,
            b.general.erAmbientPres.value!,
          )}
        </td>
      </tr>
      <tr>
        <td>Scavenging air temperature [°C]</td>
        {reportsCompared.map((rc) => (
          <td key={rc.reportId} className='mds-table__cell--number'>
            {rc.data.general.scavAirTemp.value}
          </td>
        ))}
        <td className='mds-table__cell--number'>
          {getDifference(
            a.general.scavAirTemp.value!,
            b.general.scavAirTemp.value!,
          )}
        </td>
      </tr>
      <tr>
        <td>Scavenging air pressure [bar]</td>
        {reportsCompared.map((rc) => (
          <td key={rc.reportId} className='mds-table__cell--number'>
            {rc.data.general.scavAirPres.value}
          </td>
        ))}
        <td className='mds-table__cell--number'>
          {getDifference(
            a.general.scavAirPres.value!,
            b.general.scavAirPres.value!,
          )}
        </td>
      </tr>
      <tr>
        <td>Exhaust gas receiver pressure [bar]</td>
        {reportsCompared.map((rc) => (
          <td key={rc.reportId} className='mds-table__cell--number'>
            {rc.data.general.exhaustGasReceiverPres.value}
          </td>
        ))}
        <td className='mds-table__cell--number'>
          {getDifference(
            a.general.exhaustGasReceiverPres.value!,
            b.general.exhaustGasReceiverPres.value!,
          )}
        </td>
      </tr>
      <tr>
        <td>Blower inlet temperature [°C]</td>
        {reportsCompared.map((rc) => (
          <td key={rc.reportId} className='mds-table__cell--number'>
            {formatValue(rc.data.general.blowerInletTemp.value, 1)}
          </td>
        ))}
        <td className='mds-table__cell--number'>
          {getDifference(
            a.general.blowerInletTemp.value!,
            b.general.blowerInletTemp.value!,
          )}
        </td>
      </tr>
      <tr>
        <td>Turbine back pressure [mmH2O]</td>
        {reportsCompared.map((rc) => (
          <td key={rc.reportId} className='mds-table__cell--number'>
            {rc.data.general.turbineBackPres.value}
          </td>
        ))}
        <td className='mds-table__cell--number'>
          {getDifference(
            a.general.turbineBackPres.value!,
            b.general.turbineBackPres.value!,
          )}
        </td>
      </tr>
    </>
  )
}

export default LoadCondition
