import React from 'react'
import { FieldArray, getIn, useFormikContext } from 'formik'
import { McButton } from '@maersk-global/mds-react-wrapper'
import moment from 'moment'

import { Table, Tbody, Tr } from '../../../../../commons/tables/styles'
import {
  DateTh,
  EnergyTh,
  PeriodTh,
  RuntimeTd,
  TimeTh,
  TotalTd,
} from '../../styles'
import { UNITS } from '../../../../../utils/constants'
import { HOURS_REGEX, MINUTES_REGEX } from '../../../../../utils'
import { HDCReportFormValues, Period } from '../../../types'
import HDCDateField from '../../hdc-date-field'
import ReadonlyField from '../../readonly-field'
import HDCInputField from '../../hdc-input-field'

type Props = {
  totalPeriodCount: number
  key: number
  keyIndex: number
  onDeletePeriodRow: () => void
}

const ShorePowerPeriodTable = (props: Props) => {
  const { values, errors } = useFormikContext<HDCReportFormValues>()
  const { shorePowerPeriods } = values.engineRoom
  const headingArray = ['Start', 'End']

  const datetimeCalculator = (period: Period) => {
    let days: number, hours: number, minutes: number, energy: number

    // Make date from dateformat - date, hours and minute
    const endDateMoment = moment(
      period.periodEnd.dateFormat.dateValue,
      'DD/MM/YYYY',
    )
    const startDateMoment = moment(
      period.periodStart.dateFormat.dateValue,
      'DD/MM/YYYY',
    )

    let endDateStr = '',
      startDateStr = ''
    if (endDateMoment.isValid()) {
      // Handle invalid end date string
      endDateStr =
        moment
          .utc(
            period.periodEnd.dateFormat.dateValue +
              ' ' +
              (period.periodEnd.dateFormat.hoursValue
                ? period.periodEnd.dateFormat.hoursValue
                : 0) +
              ':' +
              (period.periodEnd.dateFormat.minutesValue
                ? period.periodEnd.dateFormat.minutesValue
                : 0) +
              ':00',
            'DD/MM/YYYY HH:mm:ss',
          )
          .utc()
          .format('YYYY-MM-DDTHH:mm:ss') + 'Z'
    }

    if (startDateMoment.isValid()) {
      startDateStr =
        moment
          .utc(
            period.periodStart.dateFormat.dateValue +
              ' ' +
              (period.periodStart.dateFormat.hoursValue
                ? period.periodStart.dateFormat.hoursValue
                : 0) +
              ':' +
              (period.periodStart.dateFormat.minutesValue
                ? period.periodStart.dateFormat.minutesValue
                : 0) +
              ':00',
            'DD/MM/YYYY HH:mm:ss',
          )
          .utc()
          .format('YYYY-MM-DDTHH:mm:ss') + 'Z'
    }
    if (
      endDateStr !== '' &&
      startDateStr !== '' &&
      moment.utc(endDateStr).diff(moment.utc(startDateStr)) > 0
    ) {
      const diff = moment.utc(endDateStr).diff(moment.utc(startDateStr))
      const duration = moment.duration(diff)
      days = duration.days()
      hours = duration.hours()
      minutes = duration.minutes()
    } else {
      days = 0
      hours = 0
      minutes = 0
    }
    // Energy calculation
    energy = period.periodEnd.energy - period.periodStart.energy
    period.periodStart.date = startDateStr
    period.periodEnd.date = endDateStr
    period.totalDays = days
    period.totalHoursMinutes = hours + ':' + minutes
    period.totalEnergy = energy > 0 ? energy : 0
  }

  const handleDelete = () => {
    props.onDeletePeriodRow()
  }

  const getPeriodSection = (producer: string, title: string) => (
    <Tr key={producer}>
      <td>{title}</td>
      <td>
        <HDCInputField
          addon={UNITS.KILO_WATT_HOUR}
          decimals={0}
          error={getIn(errors, `engineRoom.${producer}.energy`)}
          name={`engineRoom.${producer}.energy`}
          placeholder='Energy'
          type='number'
          isNoteVisible={false}
        />
      </td>
      <td>
        <HDCDateField
          error={getIn(errors, `engineRoom.${producer}.dateFormat.dateValue`)}
          name={`engineRoom.${producer}.dateFormat.dateValue`}
          placeholder='dd/mm/yyyy'
          type='date'
          dateStart={moment(values.periodStart)
            .subtract(1, 'day')
            .format('YYYY-MM-DD')}
          dateEnd={moment(values.periodEnd).add(1, 'day').format('YYYY-MM-DD')}
          openToDate={values.periodStart}
        />
      </td>
      <RuntimeTd>
        <HDCInputField
          decimals={0}
          addon='hr'
          error={getIn(errors, `engineRoom.${producer}.dateFormat.hoursValue`)}
          name={`engineRoom.${producer}.dateFormat.hoursValue`}
          placeholder='Hours'
          type='number'
          isNoteVisible={false}
          regex={HOURS_REGEX}
        />
        <HDCInputField
          decimals={0}
          addon='mins'
          error={getIn(
            errors,
            `engineRoom.${producer}.dateFormat.minutesValue`,
          )}
          name={`engineRoom.${producer}.dateFormat.minutesValue`}
          placeholder='Minutes'
          type='number'
          isNoteVisible={false}
          regex={MINUTES_REGEX}
        />
      </RuntimeTd>
    </Tr>
  )

  const getTotalSection = (producer: string, title: string, index: string) => {
    datetimeCalculator(shorePowerPeriods?.periods[index])
    return (
      <Tr key={`${title}-${index}`}>
        <TotalTd>{title}</TotalTd>
        <TotalTd>
          <ReadonlyField
            name={`engineRoom.${producer}.totalEnergy`}
            value={shorePowerPeriods?.periods[index].totalEnergy ?? '-'}
          />
        </TotalTd>
        <TotalTd>
          <ReadonlyField
            name={`engineRoom.${producer}.totalDays`}
            value={shorePowerPeriods?.periods[index].totalDays ?? '-'}
          />
        </TotalTd>
        <TotalTd>
          <ReadonlyField
            name={`engineRoom.${producer}.totalHoursMinutes`}
            value={shorePowerPeriods?.periods[index].totalHoursMinutes ?? '-:-'}
          />
        </TotalTd>
      </Tr>
    )
  }

  const getHeader = (producer: string) => (
    <Tr key={producer}>
      <PeriodTh>
        Period {props.keyIndex + 1}
        {props.totalPeriodCount === props.keyIndex + 1 && (
          <McButton
            label=''
            hiddenlabel
            variant='plain'
            fit='small'
            icon='trash'
            click={handleDelete}
            disabled={props.keyIndex === 0}
          />
        )}
      </PeriodTh>
      <EnergyTh>Energy counter [kWh] </EnergyTh>
      <DateTh>Date [dd/mm/yyyy]</DateTh>
      <TimeTh>Time [hh:mm] UTC</TimeTh>
    </Tr>
  )

  if (!shorePowerPeriods) return null

  return (
    <div>
      <Table>
        <Tbody striped={true}>
          <>
            <FieldArray
              name='engineRoom.shorePowerPeriods'
              render={() => getHeader('')}
            />
            <FieldArray
              name='engineRoom.shorePowerPeriods'
              render={() =>
                getPeriodSection(
                  `shorePowerPeriods.periods.${props.keyIndex}.periodStart`,
                  `${headingArray[0]}`,
                )
              }
            />
            <FieldArray
              name='engineRoom.shorePowerPeriods'
              render={() =>
                getPeriodSection(
                  `shorePowerPeriods.periods.${props.keyIndex}.periodEnd`,
                  `${headingArray[1]}`,
                )
              }
            />
            <FieldArray
              name='engineRoom.shorePowerPeriods'
              render={() =>
                getTotalSection(
                  `shorePowerPeriods.periods.${props.keyIndex}`,
                  'Period Total',
                  `${props.keyIndex}`,
                )
              }
            />
          </>
        </Tbody>
      </Table>
    </div>
  )
}

export default ShorePowerPeriodTable
