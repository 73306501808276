import { Performance } from '../../../../api-models'
import { Modal } from '../../../../commons'
import {
  FuelLineType,
  FuelType,
  FUEL_LINE_TYPE_TO_HEADER_TEXT_MAPPING,
} from '../../../../utils'
import { FormFuelLineChange } from './FormFuelLineChange'

type Props = {
  closeHandler: () => void
  currentlySelectedFuelType?: Performance.FuelOilStock.FuelTypeSelection
  fuelLineType: FuelLineType
  onSave: (fuelType: FuelType) => void
  uniqueFuelTypesOnboard: FuelType[]
}

export const FormFuelLineChangeModal = ({
  closeHandler,
  fuelLineType,
  uniqueFuelTypesOnboard,
  ...restProps
}: Props) => {
  return (
    <Modal
      visible
      helpTextKey='consumption-selection/fuel-type'
      title={`Change of ${
        FUEL_LINE_TYPE_TO_HEADER_TEXT_MAPPING[fuelLineType] || ''
      } fuel type`}
      closeHandler={closeHandler}
    >
      <FormFuelLineChange
        closeHandler={closeHandler}
        fuelLineType={fuelLineType}
        uniqueFuelTypesOnboard={uniqueFuelTypesOnboard}
        {...restProps}
      />
    </Modal>
  )
}
