import * as React from 'react'
import styled from '../../../theme'
import { Sensors } from '../../../api-models/sensors'
import { ChartStencil, ChartContainer, NotFound } from '../../../commons'
import { colorScheme } from '../theme'
import { SensorDataChart } from './SensorDataChart'

const Wrapper = styled.div`
  width: 600px;
  padding: 10px;
`

interface SensorDataChartContainerProps {
  sensorData?: Sensors.SensorDataResponse
}

export class SensorDataChartContainer extends React.PureComponent<
  SensorDataChartContainerProps,
  any
> {
  hasNoValues(sensorDataLogs: Sensors.SensorDataLog[]): boolean {
    return !sensorDataLogs.find((log) => log.value !== null)
  }

  render() {
    const { sensorData } = this.props
    const graphMinHeight = 263
    let graph
    if (!sensorData) {
      graph = (
        <ChartContainer minHeight={graphMinHeight} hideSideContent={true}>
          <ChartStencil chartType='area' minHeight={graphMinHeight} />
        </ChartContainer>
      )
    } else if (
      sensorData.logs.length === 0 ||
      this.hasNoValues(sensorData.logs)
    ) {
      graph = <NotFound text='No data for the selected period found.' />
    } else {
      const y1Label = sensorData.unit
        ? { name: sensorData.unit, colorScheme: colorScheme }
        : undefined
      graph = (
        <ChartContainer
          y1Label={y1Label}
          minHeight={graphMinHeight}
          hideSideContent={true}
        >
          <SensorDataChart data={sensorData} />
        </ChartContainer>
      )
    }
    return <Wrapper>{graph}</Wrapper>
  }
}
