import { useHistory, useParams } from 'react-router-dom'
import { Form, Formik, type FormikHelpers } from 'formik'

import {
  displaySuccessToast,
  setFieldErrors,
} from './MalfunctioningEquipmentReportPage.utils'
import General from './General/General'
import ImpactSpeed from './ImpactSpeed/ImpactSpeed'
import ImpactCargoCapacity from './ImpactCargoCapacity/ImpactCargoCapacity'
import ImpactFuelConsumption from './ImpactFuelConsumption/ImpactFuelConsumption'
import Solution from './Solution/Solution'
import Comment from './Comment/Comment'
import ActionButtons from './ActionButtons/ActionButtons'
import {
  useGetReport,
  usePutReport,
} from '../../../queries/GandalfApi/GandalfApi'
import { ReportType } from '../../../queries/GandalfApi/GandalfApi.consts'
import { routerParams } from '../../../routes'
import { ContentCard, Loading } from '../../../commons'
import { ContentLayout } from '../../../layout'
import { PadContent } from '../../../layout/styles'
import TemNotifications from '../../../components/TemNotifications/TemNotifications'
import PerformanceAlerts from '../../../components/PerformanceAlerts/PerformanceAlerts'
import Footer from '../../../components/Reporting/Footer/Footer'

type Params = routerParams & {
  reportId: string
}

const MalfunctioningEquipmentReportPage = () => {
  const { vesselId, reportId } = useParams<Params>()
  const history = useHistory()

  const getReport = useGetReport<GandalfApi.Mfe.ReportData>(
    vesselId,
    reportId,
    ReportType.Mfe,
  )
  const putReport = usePutReport(vesselId, reportId, ReportType.Mfe)

  const handleSubmit = async (
    report: GandalfApi.MfeReport,
    helpers: FormikHelpers<GandalfApi.MfeReport>,
  ) => {
    try {
      await putReport.mutateAsync(report)
      displaySuccessToast()
      history.push(`/MaerskStarConnect/vessel/${vesselId}/mfe`)
    } catch (error) {
      setFieldErrors(error, helpers.setFieldError)
    } finally {
      helpers.setSubmitting(false)
    }
  }

  if (!getReport.isSuccess) {
    return <Loading />
  }

  const { report } = getReport.data

  return (
    <Formik initialValues={report} onSubmit={handleSubmit} enableReinitialize>
      <Form>
        <ContentLayout header={`Malfunction report – No. ${report.reportNo}`}>
          <TemNotifications />
          <PerformanceAlerts />
          <ContentCard id='mfe__report' title='' hideHeader>
            <General />
            <ImpactSpeed />
            <ImpactCargoCapacity />
            <ImpactFuelConsumption />
            <Solution />
            <PadContent>
              <Comment />
            </PadContent>
            <ActionButtons />
          </ContentCard>
          <Footer dataFormat={report.dataFormat} />
        </ContentLayout>
      </Form>
    </Formik>
  )
}

export default MalfunctioningEquipmentReportPage
