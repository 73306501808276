import { getNotificationsApiUrl } from '../../utils'

export const getUrl = (filter: NotificationsAPI.NotificationsQueryFilter) => {
  const { state, category, type, started_at, ended_at, imo_no } = filter

  let url = `${getNotificationsApiUrl()}/v1/VesselNotifications?`

  if (state) url += `&state=${state}`
  if (category) url += `&category=${category}`
  if (type) url += `&type=${type}`
  if (started_at) url += `&started_at=${started_at}`
  if (ended_at) url += `&ended_at=${ended_at}`
  if (imo_no) url += `&imo_no=${imo_no}`

  return encodeURI(url)
}

export const parseNotification = <
  T extends NotificationsAPI.Notification<any, any>,
>(
  notification: T,
): T => {
  const notificationResponses = notification.notification_responses.map(
    (x: any) => ({
      ...x,
      data: JSON.parse(x.data),
    }),
  )

  return {
    ...notification,
    data: JSON.parse(notification.data),
    notification_responses: notificationResponses,
  }
}

export const parseNotifications = <
  T extends NotificationsAPI.Notification<any, any>,
>(
  notifications: Array<T>,
): Array<T> => {
  return notifications.map((notification) => parseNotification(notification))
}
