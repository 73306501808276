import {
  forwardRef,
  type MouseEvent,
  useContext,
  useImperativeHandle,
  useState,
} from 'react'
import {
  flexRender,
  type Row,
  type Table as ReactTable,
} from '@tanstack/react-table'
import { McIcon, McPagination } from '@maersk-global/mds-react-wrapper'

import { PaginationWrapper, SortableHeader, TableWrapper } from './Table.styles'
import { type TWindowSize } from '../../theme_new/styled'
import { WindowContext } from '../../contexts'

export interface ITableRef {
  deselectRow: () => void
}

type Props = {
  table: ReactTable<any>
  onRowSelect?: (row: Row<any>, event: MouseEvent) => void
  hidePagination?: boolean
  showFooter?: boolean
  maxFit?: TWindowSize
  disableRowHighlightOnHover?: boolean
  inert?: boolean
}

const Table = forwardRef<ITableRef, Props>(
  (
    {
      table,
      onRowSelect,
      hidePagination,
      showFooter,
      maxFit,
      disableRowHighlightOnHover,
      inert,
    },
    ref,
  ) => {
    const { windowSize } = useContext(WindowContext)
    const [selectedRowIdx, setSelectedRowIdx] = useState<number>()

    useImperativeHandle(ref, () => ({
      deselectRow() {
        setSelectedRowIdx(undefined)
      },
    }))

    const handleRowSelect = (row: Row<any>) => (event: MouseEvent) => {
      if (onRowSelect) {
        setSelectedRowIdx(row.index)
        onRowSelect(row, event)
      }
    }

    const handlePageChange = ({ detail: newPage }: CustomEvent<number>) => {
      const newPageIndex = newPage - 1
      const currentPageIndex = table.getState().pagination.pageIndex

      if (newPageIndex !== currentPageIndex) {
        table.setPageIndex(newPageIndex)
      }
    }

    const tableFit = maxFit || windowSize

    return (
      <>
        <TableWrapper
          className={`mds-table ${
            disableRowHighlightOnHover
              ? 'mds-table--disable-row-highlight-on-hover'
              : ''
          } mds-table--${tableFit}`}
        >
          <table>
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th
                      key={header.id}
                      {...{
                        className: header.column.getCanSort() ? 'sortable' : '',
                      }}
                    >
                      {(header.column.getCanSort() && (
                        <SortableHeader
                          {...{
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                          {{
                            asc: <McIcon icon='arrow-up' />,
                            desc: <McIcon icon='arrow-down' />,
                          }[header.column.getIsSorted() as string] ?? (
                            <McIcon icon='arrows-down-up' />
                          )}
                        </SortableHeader>
                      )) ||
                        flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody className={inert ? 'inert' : ''}>
              {table.getRowModel().rows.map((row) => (
                <tr
                  key={row.id}
                  {...{
                    onClick: handleRowSelect(row),
                    className: `${
                      row.original.feedback
                        ? `feedback feedback--${row.original.feedback}`
                        : row.index === selectedRowIdx
                        ? 'feedback feedback--info'
                        : ''
                    } ${
                      row.original.fuelTypeColor
                        ? `fuel-type fuel-type--${row.original.fuelTypeColor.toLowerCase()}`
                        : ''
                    }`,
                  }}
                >
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      {...{
                        className: row.original.cellFeedback?.[cell.column.id]
                          ? `feedback feedback--${
                              row.original.cellFeedback[cell.column.id]
                            }`
                          : '',
                      }}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
            {showFooter && table.getCoreRowModel().rows.length > 1 && (
              <tfoot>
                {table.getFooterGroups().map((footerGroup) => (
                  <tr key={footerGroup.id}>
                    {footerGroup.headers.map((header) => (
                      <th key={header.id}>
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.footer,
                              header.getContext(),
                            )}
                      </th>
                    ))}
                  </tr>
                ))}
              </tfoot>
            )}
          </table>
        </TableWrapper>
        {!hidePagination && (
          <PaginationWrapper>
            <McPagination
              fit={windowSize}
              totalpages={table.getPageCount()}
              currentpage={table.getState().pagination.pageIndex + 1}
              visiblepages={5}
              pagechange={handlePageChange}
            />
          </PaginationWrapper>
        )}
      </>
    )
  },
)

export default Table
