import { css } from '.'
import { InterpolationValue } from 'styled-components'

export const sizes = {
  full: 1072,
  half: 528,
  quarter: 256,
  spacing: 16,
}

export const layoutSizes = {
  full: '100%',
  half: 'calc(50% - 16px)',
  quarter: 'calc(25% - 16px)',
}

export const SCREEN_SIZES: { [key: string]: number } = {
  sm: 0,
  md: 1366,
  lg: 1440,
}

export type ElementSizes = {
  [key: string]: string
}

export const sm: ElementSizes = {
  NAVBAR_WIDTH: '264px',
  BIG_BAR_HEIGHT: '56px',
  SMALL_BAR_HEIGHT: '32px',
  H1_SIZE: '24px',
  H3_SIZE: '16px',
  FILTER_PANEL_WIDTH: '320px',
  FILTER_PANEL_WIDTH_COLLAPSED: '36px',
  FILTER_PADDING: '12px',
  VESSEL_SEARCH_FONT_SIZE: '10px',
  CONTENT_CARD_HEADER_PADDING: '8px',
  CONTENT_CARD_ADDITIONAL_INFO_FONT_SIZE: '10px',
  NOTIFICATION_BAR_FONT_SIZE: '12px',
  NOTIFICATION_BAR_HEIGHT: '32px',
  TABLE_MIN_HEIGHT: '200px',
  TABLE_MAX_HEIGHT: '400px',
}

export const md: ElementSizes = {
  NAVBAR_WIDTH: '264px',
  BIG_BAR_HEIGHT: '56px',
  SMALL_BAR_HEIGHT: '32px',
  H1_SIZE: '24px',
  H3_SIZE: '1.17em',
  FILTER_PADDING: '16px',
  FILTER_PANEL_WIDTH_COLLAPSED: '40px',
  VESSEL_SEARCH_FONT_SIZE: '14px',
  CONTENT_CARD_HEADER_PADDING: '16px',
  CONTENT_CARD_ADDITIONAL_INFO_FONT_SIZE: '10px',
  NOTIFICATION_BAR_FONT_SIZE: '12px',
  NOTIFICATION_BAR_HEIGHT: '32px',
  TABLE_MIN_HEIGHT: '200px',
  TABLE_MAX_HEIGHT: '55vh',
}

export const lg: ElementSizes = {
  NAVBAR_WIDTH: '264px',
  BIG_BAR_HEIGHT: '56px',
  SMALL_BAR_HEIGHT: '32px',
  H1_SIZE: '32px',
  H3_SIZE: '1.17em',
  FILTER_PADDING: '16px',
  FILTER_PANEL_WIDTH_COLLAPSED: '48px',
  VESSEL_SEARCH_FONT_SIZE: '14px',
  CONTENT_CARD_HEADER_PADDING: '16px',
  CONTENT_CARD_ADDITIONAL_INFO_FONT_SIZE: '12px',
  NOTIFICATION_BAR_FONT_SIZE: '14px',
  NOTIFICATION_BAR_HEIGHT: '32px',
  TABLE_MIN_HEIGHT: '200px',
  TABLE_MAX_HEIGHT: '55vh',
}

export const media = Object.keys(SCREEN_SIZES).reduce((acc, size) => {
  // @ts-ignore
  acc[size] = (literals: TemplateStringsArray, ...args: any[]) => {
    return css`
      @media (min-width: ${SCREEN_SIZES[size]}px) {
        ${css(literals, ...args)};
      }
    `
  }
  return acc
}, {} as Record<keyof typeof SCREEN_SIZES, (l: TemplateStringsArray, ...args: any[]) => InterpolationValue[]>)

export const formSizes = {
  wideInput: 288,
}
