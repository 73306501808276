import styled from 'styled-components'

export const TableContainer = styled.div`
  margin-top: 24px;
  display: flex;

  td {
    font-variant: tabular-nums;

    &.strong {
      border-top: 1px solid black;
    }
  }
`

export const TableHeadingCol = styled.col`
  width: 70%;
`

export const TableValueCol = styled.col`
  width: 30%;
`
