import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import { withVesselPageContext } from '../higher-order-components'
import { IVesselPageContext } from '../contexts'
import styled, {
  maritimeBlue,
  maerskBlue,
  orange,
  sizes,
  layoutSizes,
  media,
} from '../theme'
import { getTimestampFromVesselStatus } from '../utils'
import { routerParams } from '../routes'
import FilterPanel, {
  Filters,
  FilterConfig,
  getFiltersFromQueryParams,
  getFilterNotification,
} from '../features/filter'
import { WasteHeatRecoveryChartContainer } from '../features/waste-heat-recovery'
import { MainEnginePowerDial } from '../features/vessel-status'
import { ElectricalProductionBars } from '../features/electrical-production'
import { ContentCard, Loading, NotFound } from '../commons'
import { ContentLayout } from '../layout'
import TemNotifications from '../components/TemNotifications/TemNotifications'
import PerformanceAlerts from '../components/PerformanceAlerts/PerformanceAlerts'

const Wrapper = styled.div`
  .temp-gauge {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    svg {
      margin-top: 8px;
      ${media.sm`height: 124px;`};
      ${media.md`height: 148px;`};
      ${media.lg`height: 148px;`};
    }

    h1 {
      font-size: 48px;
      font-weight: bold;

      &.water-temperature {
        color: ${maritimeBlue[500]};
      }

      &.air-temperature {
        color: ${maerskBlue[500]};
      }
    }
  }

  .legend-wrapper {
    font-size: 12px;
    display: flex;
    align-items: center;

    svg.blue {
      color: ${maerskBlue[500]};
      height: 8px;
      width: 8px;
      margin-right: 4px;
    }

    svg.yellow {
      color: ${orange[500]};
      height: 8px;
      width: 8px;
      margin-right: 4px;
    }
  }
`

interface WasteHeatRecoveryPageProps
  extends IVesselPageContext,
    RouteComponentProps<routerParams> {}

interface WasteHeatRecoveryPageState {
  filters?: Filters
}

const filtersConfig: FilterConfig = {
  dateRange: {
    show: true,
    restrictNumberOfDays: 60,
    selections: [
      { value: 24, timeUnit: 'hours' },
      { value: 48, timeUnit: 'hours' },
      { value: 7, timeUnit: 'days' },
      { value: 14, timeUnit: 'days' },
      { value: 30, timeUnit: 'days' },
    ],
    canSelectMinuteSpecific: false,
  },
  numberOfDigits: {
    show: false,
    selections: [],
  },
  chartSmoothing: {
    show: false,
  },
}

class WasteHeatRecoveryPage extends React.Component<
  WasteHeatRecoveryPageProps,
  WasteHeatRecoveryPageState
> {
  constructor(props: WasteHeatRecoveryPageProps) {
    super(props)
    this.state = {
      filters: getFiltersFromQueryParams(),
    }
  }

  componentDidUpdate() {
    const { history, imoNo, configuration } = this.props
    if (configuration && !configuration.whr.isInstalled) {
      history.push(`/MaerskStarConnect/vessel/${imoNo}/electrical-production`)
    }
  }

  componentDidMount() {
    const { history, imoNo, configuration } = this.props
    if (configuration && !configuration.whr.isInstalled) {
      history.push(`/MaerskStarConnect/vessel/${imoNo}/electrical-production`)
    }
  }

  onFiltersReset = () => {
    this.setState({ filters: undefined })
  }

  public render() {
    const { vesselStatus, configuration } = this.props
    const { filters } = this.state
    const notifications: any[] = []
    if (filters) {
      notifications.push(getFilterNotification(filters, this.onFiltersReset))
    }

    return (
      <ContentLayout
        header='Waste heat recovery'
        featureId='waste-heat-recovery'
        notifications={notifications}
        hasFilter
      >
        <TemNotifications />
        <PerformanceAlerts />
        <FilterPanel
          onChange={(filters) =>
            this.setState({
              filters,
            })
          }
          config={filtersConfig}
          value={filters}
          onReset={this.onFiltersReset}
        />
        {configuration ? (
          <Wrapper>
            <div className='full-component'>
              <ContentCard
                id='electrical-production-bars'
                title='Electrical production'
                additionalInfo={
                  <span>{getTimestampFromVesselStatus(vesselStatus)}</span>
                }
                helpTextKey='electrical-balance/bars'
              >
                {!!vesselStatus && (
                  <ElectricalProductionBars
                    hasData={vesselStatus.hasData}
                    vesselConfiguration={configuration}
                    auxEngs={vesselStatus.auxEngs}
                    whr={vesselStatus.whr}
                    isDualEngine={configuration.hasTwoMainEngines}
                    shaftGenerator1={vesselStatus.shaftGenerator1}
                    shaftGenerator2={vesselStatus.shaftGenerator2}
                    timestamp={vesselStatus.timestamp}
                  />
                )}
                {(!vesselStatus || !configuration) && <Loading />}
              </ContentCard>
            </div>
            <WasteHeatRecoveryChartContainer filters={filters} />
            <div className='wrapped-components'>
              <ContentCard
                title='ME Power'
                helpTextKey='propulsion/maineng-power'
                id='mep'
                width={
                  configuration.hasTwoMainEngines
                    ? layoutSizes.half
                    : layoutSizes.quarter
                }
                additionalInfo={
                  <span>{getTimestampFromVesselStatus(vesselStatus)}</span>
                }
              >
                {!vesselStatus ? (
                  <Loading />
                ) : (
                  <MainEnginePowerDial
                    vesselStatus={vesselStatus}
                    vesselConfiguration={configuration}
                    minHeight={
                      configuration.hasTwoMainEngines ? 200 : undefined
                    }
                  />
                )}
              </ContentCard>
              <ContentCard
                id='water-temp'
                title='Water temp.'
                helpTextKey='waste-heat-recovery/water-temp'
                width={layoutSizes.quarter}
                contentHeight={`${
                  configuration.hasTwoMainEngines
                    ? sizes.quarter - 56
                    : sizes.quarter - 56 - 40
                }px`}
                additionalInfo={
                  <span>{getTimestampFromVesselStatus(vesselStatus)}</span>
                }
              >
                <div className='temp-gauge'>
                  {!!vesselStatus &&
                    !!vesselStatus.voyage &&
                    !!vesselStatus.voyage.waterTemperature && (
                      <h1 className='water-temperature'>
                        {vesselStatus.voyage.waterTemperature}°C
                      </h1>
                    )}
                  {!vesselStatus && <Loading />}
                  {!!vesselStatus &&
                    (!vesselStatus.voyage ||
                      !vesselStatus.voyage.waterTemperature) && (
                      <NotFound text='No water temperature detected' />
                    )}
                </div>
              </ContentCard>
              <ContentCard
                id='air-temp'
                title='Air temp.'
                width={layoutSizes.quarter}
                helpTextKey='waste-heat-recovery/air-temp'
                contentHeight={`${
                  configuration.hasTwoMainEngines
                    ? sizes.quarter - 56
                    : sizes.quarter - 56 - 40
                }px`}
                additionalInfo={
                  <span>{getTimestampFromVesselStatus(vesselStatus)}</span>
                }
              >
                <div className='temp-gauge'>
                  {!!vesselStatus &&
                    !!vesselStatus.voyage &&
                    !!vesselStatus.voyage.airTemperature && (
                      <h1 className='air-temperature'>
                        {vesselStatus.voyage.airTemperature}°C
                      </h1>
                    )}
                  {!vesselStatus && <Loading />}
                  {!!vesselStatus &&
                    (!vesselStatus.voyage ||
                      !vesselStatus.voyage.airTemperature) && (
                      <NotFound text='No air temperature detected' />
                    )}
                </div>
              </ContentCard>
            </div>
          </Wrapper>
        ) : (
          <Loading />
        )}
      </ContentLayout>
    )
  }
}

export default withRouter(withVesselPageContext(WasteHeatRecoveryPage))
