import { Topic } from '../topic'

export const LubeManagement = () => {
  const about = (
    <>
      <p>
        Lube management allows you to keep track of lube oil transaction onboard
        the vessel and structure how you keep track of your lube oil inventory.
        You can follow up on daily/weekly/monthly basis.
      </p>
      <p>
        In this section you get an introduction to the various functions in Lube
        Management and guides for steps required when adding Bunkering,
        Transfer, Loss and Sounding events. You can also read more about the
        various types of lube and how we structure them in StarConnect.
      </p>
    </>
  )

  return <Topic group='fuel' topic='lube' about={about} />
}
