import { McIcon, McTooltip } from '@maersk-global/mds-react-wrapper'

import { Wrapper } from './ShopTestLegends.styles'
import { FancyLabel } from '../../../commons'
import { blackColorScheme } from '../../../theme'

type Props = {
  staticModels: Array<AuxEngPage.StaticModel>
  selectedInstance?: number
  onClick: (idx: number | undefined) => void
}

const ShopTestLegends = (props: Props) => {
  const handleButtonClick = (idx: number) => () => {
    if (props.selectedInstance === idx) {
      props.onClick(undefined)
      return
    }

    props.onClick(idx)
  }

  return (
    <Wrapper>
      {props.staticModels.map((model, idx) => (
        <McTooltip key={model.id} position='top-center' appearance='inverse'>
          <span slot='trigger'>
            <FancyLabel
              colorScheme={blackColorScheme}
              value={props.selectedInstance === idx}
              onChange={handleButtonClick(idx)}
            >
              {model.instanceNumbers
                .map((instanceNumber) => `AE ${instanceNumber}`)
                .join(', ')}{' '}
              model
            </FancyLabel>
          </span>
          <span>
            <McIcon icon='engine' color='#ffffff' />
            {model.data.name}
          </span>
        </McTooltip>
      ))}
    </Wrapper>
  )
}

export default ShopTestLegends
