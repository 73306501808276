import { type EquipmentOption } from './CreateMetcReportModal.types'
import {
  MainEngInstanceName,
  StaticModelType,
} from '../../../queries/MasterDataApi/MasterDataApi.consts'

export const getEquipmentOptions = (
  staticModels: Array<
    MasterDataApi.StaticModels.StaticModelWithInstanceNumbers<MasterDataApi.StaticModels.StaticModelData>
  >,
): Array<EquipmentOption> => {
  return staticModels.flatMap((staticModel) => {
    if (staticModel.instanceNos) {
      return staticModel.instanceNos.map((instanceNo) => {
        const option: EquipmentOption = {
          value: {
            staticModelId: staticModel.id,
            instanceNo: instanceNo,
            type: staticModel.type,
          },
          label: '',
        }

        if (staticModel.type === StaticModelType.MainEng) {
          if (staticModel.instanceNos!.length === 1) {
            option.label = staticModel.data.name
          } else {
            option.label =
              MainEngInstanceName[instanceNo] + '  —  ' + staticModel.data.name
          }
        } else {
          // This assumes StaticModelType.AuxEng
          option.label = instanceNo + '  —  ' + staticModel.data.name
        }

        return option
      })
    }

    return []
  })
}
