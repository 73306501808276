import styled from 'styled-components'
const SummaryRow = styled.div`
  position: relative;
  display: flex;
  margin-bottom: 16px;
  flex-direction: row;
  flex-wrap: wrap;

  > div {
    flex: 0 0 25%;
  }
`

export default SummaryRow
