import { useContext } from 'react'
import { useFormikContext } from 'formik'
import { McButton } from '@maersk-global/mds-react-wrapper'

import { Wrapper } from './ShorePower.styles'
import { defaultPeriod, SHORE_POWER } from './ShorePower.utils'

import ShorePowerUsedComponent from './ShorePowerUsedComponent/ShorePowerUsedComponent'
import ShorePowerPeriodTable from './ShorePowerPeriodTable/ShorePowerPeriodTable'
import ShorePowerGrandTotalTable from './ShorePowerGrandTotalTable/ShorePowerGrandTotalTable'
import { HDCReportFormValues } from '../../types'
import { WindowContext } from '../../../../contexts'

const ShorePower = () => {
  const { windowSize } = useContext(WindowContext)
  const { values, setFieldValue } = useFormikContext<HDCReportFormValues>()
  const { shorePowerPeriods } = values.engineRoom

  const setPeriodsData = (callingFn: string) => {
    if (callingFn === 'addNewPeriod' || callingFn === 'defaultPeriod') {
      const shorePeriodsData = shorePowerPeriods
        ? [...shorePowerPeriods.periods]
        : []
      shorePeriodsData.push(defaultPeriod)
      setFieldValue(
        'engineRoom.shorePowerPeriods.numberOfPeriods',
        shorePowerPeriods ? shorePowerPeriods.numberOfPeriods + 1 : 0,
      )
      setFieldValue('engineRoom.shorePowerPeriods.periods', shorePeriodsData)
    }
    if (callingFn === 'deletePeriod') {
      setFieldValue(
        'engineRoom.shorePowerPeriods.numberOfPeriods',
        shorePowerPeriods ? shorePowerPeriods.numberOfPeriods - 1 : 0,
      )
      const shorePeriodsData = shorePowerPeriods
        ? [...shorePowerPeriods.periods]
        : []
      shorePeriodsData.pop()
      setFieldValue('engineRoom.shorePowerPeriods.periods', shorePeriodsData)
    }
  }
  const handleAddPeriod = () => {
    setPeriodsData('addNewPeriod')
  }

  const handleDeletePeriodRow = () => {
    setPeriodsData('deletePeriod')
  }

  const handleShorePowerReasonChange = (event) => {
    setFieldValue(
      'engineRoom.shorePowerPeriods.reason',
      event.detail.selectedOptions[0],
    )
  }

  const handleShorePowerUsedChange = (v: string) => (_: Event) => {
    setFieldValue('engineRoom.shorePowerPeriods.shorePowerUsed', v)
    // Below code is to reset fields on basis of shore Power used selection
    if (v === 'yes') {
      setFieldValue('engineRoom.shorePowerPeriods.reason', '')

      // show default period to user if he selected Yes
      setPeriodsData('defaultPeriod')
    }

    if (v === 'no') {
      setFieldValue('engineRoom.shorePowerPeriods.numberOfPeriods', 0)
      setFieldValue('engineRoom.shorePowerPeriods.periods', [])
    }
  }

  if (!shorePowerPeriods) return null

  return (
    <Wrapper>
      <ShorePowerUsedComponent
        onShorePowerUsedChange={handleShorePowerUsedChange}
        onShorePowerReasonChange={handleShorePowerReasonChange}
      />
      {shorePowerPeriods.shorePowerUsed === 'yes' &&
        shorePowerPeriods.numberOfPeriods > 0 && (
          <>
            {Array.from(
              { length: shorePowerPeriods.numberOfPeriods },
              (_, index) => (
                <ShorePowerPeriodTable
                  totalPeriodCount={shorePowerPeriods.numberOfPeriods}
                  key={index}
                  keyIndex={index}
                  onDeletePeriodRow={handleDeletePeriodRow}
                />
              ),
            )}
            <ShorePowerGrandTotalTable
              shorePowerPeriodsFormikContext={
                values.engineRoom.shorePowerPeriods
              }
            />
          </>
        )}
      {shorePowerPeriods.shorePowerUsed === 'yes' && (
        <McButton
          fit={windowSize}
          label='Add Period'
          icon='plus'
          click={handleAddPeriod}
          disabled={
            shorePowerPeriods.numberOfPeriods === SHORE_POWER.MAX_PERIOD_COUNT
          }
        />
      )}
    </Wrapper>
  )
}

export default ShorePower
