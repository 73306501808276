import { useContext, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { McTab, McTabBar } from '@maersk-global/mds-react-wrapper'

import { resolveInitialTabIndex } from './ActivityLogPage.utils'
import {
  ActivityLogTab,
  ActivityLogTabSearchParam,
} from './ActivityLogPage.consts'

import { useNotificationsApiHealthcheck } from '../../queries/NotificationsApi/NotificationsApi'
import { VesselPageContext, WindowContext } from '../../contexts'
import useSearchParams from '../../hooks/useSearchParams'
import { ContentLayout } from '../../layout'
import { PadContent } from '../../layout/styles'
import { ContentCard } from '../../commons'
import FuelAndStock from './FuelAndStock/FuelAndStock'
import Lube from './Lube/Lube'
import TemAdviceLog from './TemAdviceLog/TemAdviceLog'
import TemNotifications from '../../components/TemNotifications/TemNotifications'
import PerformanceAlerts from '../../components/PerformanceAlerts/PerformanceAlerts'
import PerformanceAlertsLog from './PerformanceAlerts/PerformanceAlertsLog'

const ActivityLogPage = () => {
  const imoNo = useContext(VesselPageContext).imoNo!
  const history = useHistory()
  const location = useLocation()
  const searchParams = useSearchParams()
  const { windowSize } = useContext(WindowContext)

  const {
    data: notificationsApiAvailable,
    isSuccess: notificationsApiAvailableIsSuccess,
    isLoading: notificationsApiAvailableIsLoading,
  } = useNotificationsApiHealthcheck(+imoNo)

  const [tabIndex, setTabIndex] = useState<ActivityLogTab>(() =>
    resolveInitialTabIndex(searchParams),
  )

  const updateTabIndex = (index: number) => {
    setTabIndex(index)
    searchParams.set(ActivityLogTabSearchParam, index.toString())
    history.replace({ ...location, search: searchParams.toString() })
  }

  const handleTabChange = ({ detail }: CustomEvent<number>) => {
    updateTabIndex(detail)
  }

  const showNotificationsTab =
    notificationsApiAvailableIsSuccess && notificationsApiAvailable

  if (notificationsApiAvailableIsLoading) return null

  return (
    <ContentLayout header='Activity log'>
      <TemNotifications />
      <PerformanceAlerts />
      <ContentCard id='activity-log' title='Events'>
        <McTabBar
          currentindex={tabIndex}
          fit={windowSize}
          tabchange={handleTabChange}
        >
          <McTab slot='tab' label='Fuel & stock' icon='fuel-pump' />
          <PadContent slot='panel'>
            <FuelAndStock />
          </PadContent>
          <McTab slot='tab' label='Lube' icon='droplet' data-e2e='LubeTab' />
          <PadContent slot='panel'>
            <Lube />
          </PadContent>
          {showNotificationsTab && (
            <>
              <McTab slot='tab' label='Energy advice' icon='light-bulb' />
              <PadContent slot='panel'>
                <TemAdviceLog />
              </PadContent>
              <McTab
                slot='tab'
                label='Performance advice'
                icon='exclamation-triangle'
              />
              <PadContent slot='panel'>
                <PerformanceAlertsLog />
              </PadContent>
            </>
          )}
        </McTabBar>
      </ContentCard>
    </ContentLayout>
  )
}

export default ActivityLogPage
