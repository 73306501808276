import React from 'react'
import { IReducerState } from './stock-entry-reducer'
import {
  EntryType,
  UserEntryTypes,
} from '../../features/stock-management/models'

const StockEntryContext = React.createContext<IContextProps>(
  {} as IContextProps,
)

interface IContextProps {
  imoNo: string
  state: IReducerState
  deleteEntry: (userEntryType: UserEntryTypes, entryId: string) => Promise<void>
  setCurrentEntry: (entryType?: EntryType, entryId?: string) => void
  getFuelOilUserEntries: () => Promise<void>
}

export default StockEntryContext
