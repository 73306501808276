import { useField } from 'formik'
import { McTag } from '@maersk-global/mds-react-wrapper'
import styled, { IColorScheme, IThemeInterface } from './../../../theme'

export interface IMcTagOption {
  label: string
  value: number
  scheme: IColorScheme
}

type Props = {
  options: Array<IMcTagOption>
  name: string
}

const transformSchemeToTheme = (s: IColorScheme): IThemeInterface => ({
  fg: s.font,
  bg: s.fill,
  border: s.stroke,
})

const McTagGroupWrapper = styled.div`
  display: flex;

  mc-tag::part(tag) {
    min-width: auto;
    margin-right: 8px;

    background-color: ${(props) => props.theme.bg};
    color: ${(props) => props.theme.fg};
    border: 1px solid ${(props) => props.theme.border || 'transparent'};
    opacity: ${(props) => (props.theme === 'white' ? '0.6' : '1')};
  }
`

export const McTagGroup = ({ options, name }: Props) => {
  /* field is unused here but if removed, the variables in the array destructuring will get the wrong values */
  // eslint-disable-next-line
  const [field, meta, helpers] = useField(name)
  const { value } = meta

  return (
    <>
      {options.map((option) => (
        <McTagGroupWrapper
          key={option.value}
          theme={
            option.value === value
              ? transformSchemeToTheme(option.scheme)
              : 'white'
          }
        >
          <McTag label={option.label} />
        </McTagGroupWrapper>
      ))}
    </>
  )
}
