import React from 'react'
import styled from 'styled-components'

interface Props extends React.HTMLAttributes<HTMLElement> {
  activeKey: number
  isActive: boolean
}

const Component = styled.div<Props>`
  display: ${({ isActive }) => (isActive ? 'block' : 'none')};
`

/**
 * Collapse
 * @description Collapse component is supposed to be used with an accordion,
 * this is the bar a user will see and is able to click to expand. The component inherits the
 * default React HTMLAttributes like click and styles etc.
 *
 * @param {number} activeKey The related index number it is located at in the accordion.
 * @param {boolean} isActive Determines whether it should show or not show the content of the collapsible content.
 */
const Collapse = ({ children, ...props }: Props) => {
  return <Component {...props}>{children}</Component>
}

export default Collapse
