import destinationValidationSchema from './destination'
import etaValidationSchema from './eta'
import distanceAndSpeedValidationSchema from './distanceAndSpeed'
import draughtAndSeawaterValidationSchema from './draughtAndSeawater'

const voyageValidationSchema = destinationValidationSchema
  .concat(etaValidationSchema)
  .concat(distanceAndSpeedValidationSchema)
  .concat(draughtAndSeawaterValidationSchema)

export default voyageValidationSchema
