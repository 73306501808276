import { ApiReportState, ApiReportType } from '../../api-models/hdc/report'
import { StatusTypeEnum } from './components/reports-table/ReportStatus.consts'
import { BridgeFormStep, HDCFormType, EngineRoomFormStep } from './types'

export const ReportTypeNames = {
  [ApiReportType.SEA]: 'Sea report',
  [ApiReportType.ARRIVAL]: 'Arrival report',
  [ApiReportType.ALONGSIDE]: 'Along side report',
  [ApiReportType.DEPARTURE]: 'Departure report',
  [ApiReportType.CANAL]: 'Canal passage report',
}

// Note: needs to be updated when adding new fields to any report step
export const FORM_STEP_TO_FIELDS = {
  [HDCFormType.BRIDGE_FORM]: {
    [BridgeFormStep.VOYAGE]: [
      'alternativeArrivalTerminal',
      'alternativeDepartureTerminal',
      'arrivalTerminal',
      'ballastWater',
      'comment',
      'departureTerminal',
      'distanceLogged',
      'distanceObserved',
      'draughtAft',
      'draughtFore',
      'eta',
      'remainingDistanceToPilotStation',
      'speedLogged',
      'speedObserved',
      'waterDepth',
      'waterTemperature',
    ],
    [BridgeFormStep.EVENTS]: ['comment'],
  },
  [HDCFormType.ENGINE_FORM]: {
    [EngineRoomFormStep.PROPULSION]: ['comment', 'mainEngines'],
    [EngineRoomFormStep.ELECTRICAL]: [
      'auxBlowers',
      'auxEngines',
      'comment',
      'energySum',
      'numberOfReefers',
      'reeferEnergy',
      'scrubberSystem',
      'shaftGenerators',
      'shaftMotors',
      'wasteHeatRecovery',
      'shorePowerPeriods',
    ],
    [EngineRoomFormStep.CONSUMPTION]: ['comment', 'cylindersLubeOil'],
    [EngineRoomFormStep.EVENTS]: ['comment'],
  },
}

export const GSIS_CANAL_CODES = [
  'TRBSUTM',
  'TRCANDT',
  'TRCANDT',
  'DEKECTM',
  'PAPTYTM',
  'EGSUCCN',
  'AUTORTM',
]

export const EDIT_WINDOW_PERIOD_MINUTES = 30 * 24 * 60

// Maps <bridge state + engine room state> to report state
export const REPORT_STATUS_MAP = {
  [ApiReportState.DRAFT + ApiReportState.DRAFT]: StatusTypeEnum.DRAFT,
  [ApiReportState.DRAFT + ApiReportState.SUBMITTED]:
    StatusTypeEnum.BRIDGE_INCOMPLETE,
  [ApiReportState.SUBMITTED + ApiReportState.DRAFT]:
    StatusTypeEnum.ENGINE_ROOM_INCOMPLETE,
  [ApiReportState.SUBMITTED + ApiReportState.SUBMITTED]: StatusTypeEnum.DONE, // note: report can still be editable, requires additional check
  [ApiReportState.SUBMITTED + ApiReportState.EDIT]:
    StatusTypeEnum.ENGINE_ROOM_INCOMPLETE,
  [ApiReportState.EDIT + ApiReportState.SUBMITTED]:
    StatusTypeEnum.BRIDGE_INCOMPLETE,
  [ApiReportState.EDIT + ApiReportState.EDIT]: StatusTypeEnum.EDIT,
  [ApiReportState.INVALID + ApiReportState.SUBMITTED]: StatusTypeEnum.INVALID,
  [ApiReportState.INVALID + ApiReportState.EDIT]: StatusTypeEnum.INVALID,
  [ApiReportState.SUBMITTED + ApiReportState.INVALID]: StatusTypeEnum.INVALID,
  [ApiReportState.EDIT + ApiReportState.INVALID]: StatusTypeEnum.INVALID,
  [ApiReportState.INVALID + ApiReportState.INVALID]: StatusTypeEnum.INVALID,
}
