import ReactTooltip from 'react-tooltip'
import styled from 'styled-components'
import { McIcon } from '@maersk-global/mds-react-wrapper'

const Wrapper = styled.div`
  font-size: 11px;
  color: #3586b5;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  right: 5px;
  top: 0;
  cursor: pointer;
`

const CommentsBackground = styled.div`
  background-color: #3586b5;
  border-radius: 100%;
  padding-top: 8px;
  padding-left: 8px;
  height: 40px;
  width: 40px;

  mc-icon::part(icon) {
    fill: #ffffff;
  }
`

type Props = {
  tooltipId: string
}

const UnknownPortIcon = ({ tooltipId }: Props) => {
  return (
    <Wrapper
      data-event='click'
      data-tip='Unknown port code and port name'
      data-for={tooltipId}
    >
      <CommentsBackground>
        <McIcon icon='anchor' size='24' />
      </CommentsBackground>
      Unknown
      <ReactTooltip place='top' className='input-note' id={tooltipId} />
    </Wrapper>
  )
}

export default UnknownPortIcon
