import { type IBunkeringFormValues } from '../types'
import { LUBE_OIL_TYPE_NAME } from '../constants'

export const getInitialValues = (): IBunkeringFormValues => ({
  deliveryDetails: {
    orderId: '',
    portCode: '',
    quantityAgreed: null,
    quantityOrdered: null,
  },
  notes: '',
  oilType: null,
  quantityPerChiefEngineer: null,
  tbn: null,
  timestamp: null,
})

export const oilTypeOptions = Object.entries(LUBE_OIL_TYPE_NAME).map(
  ([value, label]) => ({
    label,
    value: parseInt(value, 10),
  }),
)
