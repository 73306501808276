import { McIcon } from '@maersk-global/mds-react-wrapper'

import { MessageBubble, Wrapper } from './Message.styles'
import { useContext } from 'react'
import { WindowContext } from '../../../../../contexts'

type Props = {
  msg: Diagnostics.ChatMessage
}

const Message = ({ msg }: Props) => {
  const { windowSize } = useContext(WindowContext)

  return (
    <Wrapper sender={msg.sender}>
      <McIcon
        icon={msg.sender === 'Vessel' ? 'vessel-front' : 'office-2'}
        size={windowSize === 'small' ? '20' : '24'}
      />
      <MessageBubble msg={msg}>{msg.message}</MessageBubble>
    </Wrapper>
  )
}

export default Message
