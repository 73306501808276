import React, { useCallback, useEffect, useState } from 'react'
import { type TWindowSize } from '../theme_new/styled'
import { BREAK_POINTS } from '../theme_new/dimensions'

interface WindowContextProps {
  windowSize: TWindowSize
}

export const WindowContext = React.createContext<WindowContextProps>({
  windowSize: 'medium',
})

export const WindowContextProvider = ({ children }) => {
  const getWindowSize = useCallback((): TWindowSize => {
    const windowWidth = Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0,
    )

    return windowWidth > BREAK_POINTS.large
      ? 'large'
      : windowWidth > BREAK_POINTS.medium
      ? 'medium'
      : 'small'
  }, [])

  const [windowSize, setWindowSize] = useState<TWindowSize>(getWindowSize())

  useEffect(() => {
    const handleResize = () => setWindowSize(getWindowSize())

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [getWindowSize])

  return (
    <WindowContext.Provider value={{ windowSize }}>
      {children}
    </WindowContext.Provider>
  )
}
