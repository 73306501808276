import * as React from 'react'
import styled, { seaGreen, grey, sizes } from '../../../theme'
import { SimpleDial } from './SimpleDial'
import { Performance } from '../../../api-models'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  .dials {
    width: ${sizes.quarter}px;
    &.border {
      border-right: 1px solid ${grey[200]};
    }
  }
`

interface SpeedThroughWaterStatus {
  stw?: number
  maxSpeed?: number
}

export interface SpeedThroughWaterDialProps {
  vesselStatus: Performance.Status.Status
  vesselConfiguration: Performance.VesselConfiguration.Configuration
}

export class SpeedThroughWaterDial extends React.PureComponent<
  SpeedThroughWaterDialProps,
  any
> {
  getSpeedThroughWaterStatus(): SpeedThroughWaterStatus {
    const { vesselConfiguration, vesselStatus } = this.props
    const { voyage } = vesselStatus
    return {
      stw: voyage ? voyage.stw : undefined,
      maxSpeed: vesselConfiguration.maxSpeed,
    }
  }
  public render() {
    const { stw, maxSpeed } = this.getSpeedThroughWaterStatus() as any
    return (
      <React.Fragment>
        <Wrapper>
          <div className='dials'>
            <SimpleDial
              id='dial-stw'
              value={stw}
              color={seaGreen[500]}
              minValue={0}
              maxValue={maxSpeed}
              unit='knots'
              dialHeader={''}
            />
          </div>
        </Wrapper>
      </React.Fragment>
    )
  }
}
