import { useContext, useEffect, useMemo } from 'react'
import { useParams } from 'react-router'
import { useField } from 'formik'
import { McOption, McSelect } from '@maersk-global/mds-react-wrapper'

import {
  getParametersOptions,
  getStaticModelsOptions,
  type ModelOption,
  type ModelOptionValue,
} from './MfeEngineModelField.utils'
import { MfeEquipmentType as Equipment } from '../../MalfunctioningEquipmentPage.consts'
import {
  useVesselAuxEngines,
  useVesselMainEngines,
  useVesselStaticModels,
} from '../../../../queries/MasterDataApi/MasterDataApi'
import { StaticModelType } from '../../../../queries/MasterDataApi/MasterDataApi.consts'
import { type routerParams } from '../../../../routes'
import { WindowContext } from '../../../../contexts'

const fieldName = 'data.staticModel'

type Props = {
  equipment: Equipment.MainEngine | Equipment.AuxEngines
}

const MfeEngineModelField = ({ equipment }: Props) => {
  const { vesselId: imoNo } = useParams<routerParams>()
  const { windowSize } = useContext(WindowContext)
  const [field, meta, helpers] = useField<ModelOptionValue>(fieldName)

  const mainEnginesQuery = useVesselMainEngines(imoNo)
  const auxEnginesQuery = useVesselAuxEngines(imoNo)
  const staticModelsQuery =
    useVesselStaticModels<MasterDataApi.StaticModels.MainEngStaticModelData>(
      +imoNo,
      [StaticModelType.MainEng, StaticModelType.AuxEng],
    )

  const handleStaticModelChange = async ({ detail }: CustomEvent) => {
    void helpers.setValue(detail.value)
  }

  const isMe = equipment === Equipment.MainEngine
  const isAe = equipment === Equipment.AuxEngines
  const hasStaticModelData =
    staticModelsQuery.isSuccess &&
    staticModelsQuery.data.staticModels.length > 0

  const options = useMemo(() => {
    let _options: Array<ModelOption> = []
    if (hasStaticModelData) {
      _options = getStaticModelsOptions(
        equipment,
        staticModelsQuery.data.staticModels,
      )
    } else {
      if (isMe && mainEnginesQuery.isSuccess) {
        _options = getParametersOptions(mainEnginesQuery.data.engines)
      } else if (isAe && auxEnginesQuery.isSuccess) {
        _options = getParametersOptions(auxEnginesQuery.data.engines)
      }
    }
    return _options
  }, [
    equipment,
    staticModelsQuery.isSuccess,
    mainEnginesQuery.isSuccess,
    auxEnginesQuery.isSuccess,
  ])

  const isLoading =
    staticModelsQuery.isLoading ||
    (!hasStaticModelData &&
      ((isMe && mainEnginesQuery.isLoading) ||
        (isAe && auxEnginesQuery.isLoading)))

  useEffect(() => {
    // For Main Engine, if there is only one option, we will select it by default.
    if (options.length === 1 && equipment === Equipment.MainEngine) {
      // We have to wrap setValue in a setTimeout to make Formik behave.
      setTimeout(() => {
        void helpers.setValue(options[0].value)
      })
    }
  }, [options])

  // We have to find the field value in the options array. Otherwise, the
  // McSelect will not show the selected value in cases where the value is
  // created from machinery parameters and not from static models (on initial
  // renders only).
  const _value: ModelOptionValue = options.find(
    (option) => option.value.instanceNo === field.value.instanceNo,
  )?.value ?? {
    model: null,
    instanceNo: null,
    id: null,
  }

  return (
    <McSelect
      fit={windowSize}
      name={fieldName}
      label='Model'
      optionswidth='auto'
      invalid={Boolean(meta.error)}
      errormessage={meta.error}
      value={_value}
      optionselected={handleStaticModelChange}
      loading={isLoading}
    >
      <small>{equipment}</small>
      {options.map(({ label, value }) => (
        <McOption key={value.instanceNo} value={value} label={label} />
      ))}
    </McSelect>
  )
}

export default MfeEngineModelField
