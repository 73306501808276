import { useContext, useMemo } from 'react'
import { type Table } from '@tanstack/react-table'
import { McOption, McSelect } from '@maersk-global/mds-react-wrapper'

import { getEquipmentOptions } from './PerformanceAlertsLogFilters.utils'
import DateFilter from '../ActivityLogFilters/DateFilter/DateFilter'
import {
  FilterWrapper,
  ResultsCountTag,
} from '../../../components/Table/Table.styles'
import {
  Status,
  StatusDisplay,
} from '../../../components/PerformanceAlerts/Diagnostics/Diagnostics.consts'
import { WindowContext } from '../../../contexts'

type Props = {
  table: Table<NotificationsAPI.PerformanceAlert>
  isTwinEngine: boolean
}

const PerformanceAlertsLogFilters = ({ table, isTwinEngine }: Props) => {
  const { windowSize } = useContext(WindowContext)

  const equipmentOptions = useMemo(
    () => getEquipmentOptions(table, isTwinEngine),
    [table, isTwinEngine],
  )

  const handleStatusFilterChange = ({ detail }: CustomEvent) => {
    table.getColumn('status')?.setFilterValue(detail.value)
  }

  const handleEquipmentFilterChange = ({ detail }: CustomEvent) => {
    table.getColumn('equipment')?.setFilterValue(detail.value)
  }

  return (
    <FilterWrapper>
      <DateFilter table={table} columnId='created_at' />
      <McSelect
        fit={windowSize}
        label='Status'
        placeholder='All'
        optionselected={handleStatusFilterChange}
      >
        {Object.keys(Status).map((key) => (
          <McOption key={key} value={key} label={StatusDisplay[key]} />
        ))}
      </McSelect>
      <McSelect
        fit={windowSize}
        label='Equipment'
        placeholder='All'
        optionselected={handleEquipmentFilterChange}
      >
        {equipmentOptions.map((option) => (
          <McOption
            key={option.value}
            value={option.value}
            label={option.label}
          />
        ))}
      </McSelect>
      <ResultsCountTag
        appearance='info'
        label={`${table.getFilteredRowModel().rows.length} results`}
        fit={windowSize}
      />
    </FilterWrapper>
  )
}

export default PerformanceAlertsLogFilters
