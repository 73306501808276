import styled from 'styled-components'

export const Wrapper = styled('div')`
  display: flex;
  align-items: flex-end;
  gap: ${12 / 2}px;
  @media (min-width: 20px) {
    gap: ${20 / 2}px;
  }
  @media (min-width: 30px) {
    gap: ${30 / 2}px;
  }

  > mc-input-date {
    width: 50%;

    > span {
      display: inline-flex;
      white-space: nowrap;
    }
  }

  > mc-input-time {
    width: 50%;
  }
`
