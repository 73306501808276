import { createColumnHelper, type Row } from '@tanstack/react-table'
import { McTooltip } from '@maersk-global/mds-react-wrapper'

import { type Configuration } from '../../../../api-models/performance/vessel-configuration'
import { type Consumer } from '../../../../api-models/performance/counter'
import { ELECTRICAL_PRODUCER_NAMES } from '../../../../utils/constants'
import {
  NumericHeader,
  NumericTooltipCell,
} from '../../../../components/Table/Table.styles'
import { formatValue, numberOfMinutesToTime } from '../../../../utils'

const columnHelper = createColumnHelper<Consumer>()

export const getColumns = (vesselConfig: Configuration) => [
  columnHelper.accessor('type', {
    header: 'Electrical generator',
    cell: (props) => mapProducerName(props.row.original.type, vesselConfig),
    enableSorting: false,
  }),
  columnHelper.accessor('energy', {
    header: () => <NumericHeader>Energy production (kWh)</NumericHeader>,
    cell: (props) => (
      <McTooltip position='top-center' appearance='inverse'>
        <NumericTooltipCell slot='trigger'>
          {formatValue(props.getValue(), 0)}
        </NumericTooltipCell>
        <span>
          Data was available for <b>{props.row.original.dataAvailability}%</b>{' '}
          of the period
        </span>
      </McTooltip>
    ),
    footer: (props) => {
      const rows = props.table.getCoreRowModel().rows

      if (rows.length <= 1) return null

      const availability = Math.min(
        ...rows.map((row) => row.original.dataAvailability),
      )

      return (
        <McTooltip position='top-center' appearance='inverse'>
          <NumericTooltipCell boldFont slot='trigger'>
            {props.table.getCoreRowModel().rows.reduce(
              // @ts-ignore
              (acc, curr: Row<Consumer>) => acc + curr.getValue('energy'),
              0,
            )}
          </NumericTooltipCell>
          <span>
            Data was available for <b>{availability}%</b> of the period
          </span>
        </McTooltip>
      )
    },
    enableSorting: false,
  }),
  columnHelper.accessor('runningMinutes', {
    header: () => <NumericHeader>Running hours (hh:mm)</NumericHeader>,
    cell: (props) => (
      <McTooltip position='top-center' appearance='inverse'>
        <NumericTooltipCell slot='trigger'>
          {numberOfMinutesToTime(props.getValue())}
        </NumericTooltipCell>
        <span>
          Data was available for <b>{props.row.original.dataAvailability}%</b>{' '}
          of the period
        </span>
      </McTooltip>
    ),
    enableSorting: false,
  }),
]

const mapProducerName = (
  producerNumber: number,
  vesselConfig: Configuration,
): string => {
  if (!vesselConfig.hasTwoMainEngines && producerNumber === 6) {
    return ELECTRICAL_PRODUCER_NAMES[producerNumber].replace('port', '')
  }
  return ELECTRICAL_PRODUCER_NAMES[producerNumber]
}
