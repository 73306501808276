import React, { FC, useCallback, useEffect, useState } from 'react'
import { FieldProps, useFormikContext } from 'formik'
import { FuelType } from '../../../../utils/models'
import styled from '../../../../theme'
import { FormBatchSelectionModal } from './FormBatchSelectionModal'
import { BatchSelectionCommons } from './FormConsumptionSelection'
import { hasLabReport } from '../../utils'
import { CurrentBatchSelectionResponse } from '../../../../api-models/performance/fuel-oil-stock'
import { BatchSelections } from '../../../fdl-4/components/batch-selection/new/BatchSelections'
import { displayErrorModal } from '../../../../utils'

export type BatchOption = {
  label: string
  value: string
  isDisabled: boolean
  hasReport: boolean
  fuelType: FuelType
}

const SelectionTitle = styled.div`
  font-size: 14px;
  margin-bottom: 16px;
  display: flex;
  justify-content: left;
`

export type RecoveryAndStartupCommonRobType = {
  manualrob: {
    rob: {
      [batchId: string]: number
    }
  }
}

interface BatchSelectProps extends FieldProps {
  batches: BatchSelectionCommons[]
}

export const FormBatchSelection: FC<BatchSelectProps> = ({
  field,
  form,
  batches,
}) => {
  const { values } = useFormikContext<RecoveryAndStartupCommonRobType>()
  const [batchTransferFuelType, setBatchTransferFuelType] = useState<FuelType>()

  const [normalizedBatches, setNormalizedBatches] = useState<BatchOption[]>()
  const [batchOptions, setBatchOptions] = useState<BatchOption[] | null>()

  useEffect(
    () =>
      setNormalizedBatches(
        batches.map((batch) => {
          const hasReport = hasLabReport(batch)
          return {
            label: batch.displayName,
            value: batch.id,
            isDisabled: !hasReport || !values?.manualrob?.rob[batch.id],
            hasReport,
            fuelType: batch.fuel.type,
          }
        }),
      ),
    [values, batches],
  )

  const openBatchTransferModal = useCallback(
    (selectedFuelType: FuelType) => {
      setBatchTransferFuelType(selectedFuelType)
      if (normalizedBatches) {
        setBatchOptions(
          normalizedBatches.filter(
            ({ fuelType }) => fuelType === selectedFuelType,
          ),
        )
      }
    },
    [normalizedBatches],
  )

  const addBatchSelection = useCallback(
    (selectedBatchId: string) => {
      if (batches) {
        const newlySelectedBatch = batches.find(
          ({ id }) => id === selectedBatchId,
        )
        if (newlySelectedBatch) {
          const newBatchSelection: CurrentBatchSelectionResponse = {
            id: selectedBatchId,
            timestamp: newlySelectedBatch.timestamp!,
            fuelType: newlySelectedBatch.fuel.type!,
            batchId: newlySelectedBatch.id!,
            displayName: newlySelectedBatch?.displayName!,
            previousBatchDepleted: true,
            remainingOfPreviousBatch: 0,
          }
          const newBatchSelections = field.value
            .filter((batch) => batch.fuelType !== batchTransferFuelType)
            .concat(newBatchSelection)
          if (batchTransferFuelType === FuelType.MDO) {
            form.setFieldTouched(field.name)
          }
          form.setFieldValue(field.name, newBatchSelections)
          setBatchOptions(null)
        } else {
          displayErrorModal({
            statusText: 'Batch selection not possible',
            message:
              'Could not find the selected batch. Please try again later!',
          })
        }
      }
    },
    [batchTransferFuelType, batches, field.name, field.value, form],
  )
  const closeBatchTransferModal = useCallback(() => {
    setBatchOptions(null)
    if (batchTransferFuelType === FuelType.MDO) {
      form.setFieldTouched(field.name)
    }
  }, [batchTransferFuelType, form, field.name])

  return (
    <>
      <SelectionTitle>Transfer from storage</SelectionTitle>
      <BatchSelections
        onClick={openBatchTransferModal}
        batchSelections={field.value}
      />
      {batchOptions && (
        <FormBatchSelectionModal
          batchOptions={batchOptions}
          closeHandler={closeBatchTransferModal}
          onSave={addBatchSelection}
          fuelType={batchTransferFuelType}
        />
      )}
    </>
  )
}
