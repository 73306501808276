import { useState } from 'react'
import { McButton, McModal } from '@maersk-global/mds-react-wrapper'

import FuelAndStock from './FuelAndStock'

const FuelAndStockModal = () => {
  const [showLogModal, setShowLogModal] = useState(false)

  return (
    <>
      <McButton
        label='Show log'
        fit='small'
        appearance='neutral'
        click={() => setShowLogModal(true)}
      />
      {showLogModal && (
        <McModal
          open
          closed={() => setShowLogModal(false)}
          width='80vw'
          height='70%'
        >
          <div style={{ marginBottom: 24 }}>
            <FuelAndStock />
          </div>
          <McButton slot='primaryAction' click={() => setShowLogModal(false)}>
            Close
          </McButton>
        </McModal>
      )}
    </>
  )
}

export default FuelAndStockModal
