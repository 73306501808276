import { useContext, useMemo, useState } from 'react'
import { useTheme } from 'styled-components'
import { McIcon } from '@maersk-global/mds-react-wrapper'

import { type IReport } from '../../../../api-models/hdc/report'
import { Dropdown } from '../../../../commons'
import { deleteReport } from '../../../../services/hdc'
import styled from '../../../../theme'
import { displayConfirmModal, displayErrorModal } from '../../../../utils'
import { HDCContext } from '../../context'
import SummaryModal from '../../reports/summary-modal'
import { getLatestReport } from '../../utils'
import ModalContent from '../modal-content'

const MenuItem = styled.li`
  text-align: left;
  color: #277baf;
  cursor: pointer;

  &:not(:last-child) {
    border-bottom: 1px solid #ededed;
  }
`

type Props = {
  report: IReport
}

export const ReportsTableActionMenu = ({ report }: Props) => {
  const theme = useTheme()
  const {
    getReports,
    imoNo,
    resetState,
    setReport,
    state: HDCState,
  } = useContext(HDCContext)

  const [isSummaryModalVisible, setIsSummaryModalVisible] =
    useState<boolean>(false)

  const isLatestReport = useMemo(
    () => report.id === getLatestReport(HDCState.reports)?.id,
    [HDCState.reports, report.id],
  )

  const onDelete = (id: string) => {
    const message = (
      <ModalContent
        icon='exclamation-triangle-solid'
        iconColor={theme.colors.feedback.warning.main}
        message='You are about to delete a report. You will not be able to undo your
    action.'
        title='Be aware!'
      />
    )
    displayConfirmModal(
      {
        message,
        title: 'Delete report',
      },
      'Delete',
      'Cancel',
    )
      .then(() => {
        deleteReport(imoNo, id)
          .then(() => {
            resetState()
            void getReports()
          })
          .catch((error) => {
            void displayErrorModal({
              statusText: 'Failed to delete report',
              message: error.body.error,
            })
          })
      })
      .catch(() => {})
  }

  return (
    <>
      <Dropdown
        id='action'
        triggerId='action-trigger'
        triggerChildren={<McIcon icon='ellipsis-vertical' size='24' />}
        menuPosition={{ right: '0px' }}
        liPadding='14px 23px'
      >
        <MenuItem
          onClick={() => {
            setReport(report)
            setIsSummaryModalVisible(true)
          }}
        >
          View summary
        </MenuItem>
        {isLatestReport && (
          <MenuItem onClick={() => onDelete(report.id)}>Delete</MenuItem>
        )}
      </Dropdown>
      <SummaryModal
        handleClose={() => setIsSummaryModalVisible(false)}
        isVisible={isSummaryModalVisible}
      />
    </>
  )
}
