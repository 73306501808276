import { LUBE_OIL_LOSS_REASON_NAME, LUBE_OIL_TYPE_NAME } from '../constants'
import { ILossFormValues } from '../types'

export const getInitialValues = (): ILossFormValues => ({
  notes: '',
  oilType: null,
  quantity: null,
  reasonCode: null,
  timestamp: null,
})

export const oilTypeOptions = Object.entries(LUBE_OIL_TYPE_NAME).map(
  ([value, label]) => ({
    label,
    value: parseInt(value, 10),
  }),
)

export const reasonOptions = Object.entries(LUBE_OIL_LOSS_REASON_NAME).map(
  ([value, label]) => ({ value, label }),
)
