import { useContext } from 'react'
import { McButton, McIcon } from '@maersk-global/mds-react-wrapper'

import styled from '../../../theme'
import { ModalControls } from '../../../commons'
import { WindowContext } from '../../../contexts'

const Wrapper = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;

  .expand-button {
    display: block;
    text-align: center;
  }

  #expand-filters {
    padding: 12px 0;
    background-color: transparent;
  }
`

type Props = {
  expanded: boolean
  disabled: boolean
  onReset: () => void
  onSubmit: () => void
  onToggle: () => void
}

const FilterPanelFooter = (props: Props) => {
  const { windowSize } = useContext(WindowContext)
  const { expanded, disabled, onToggle, onSubmit, onReset } = props

  return (
    <Wrapper>
      {expanded && (
        <ModalControls>
          <McButton
            label='Apply filters'
            appearance='primary'
            id='apply-filters'
            disabled={disabled}
            click={onSubmit}
          />
          <McButton
            label='Clear filters'
            appearance='neutral'
            id='clear-filters'
            click={onReset}
            type='button'
          />
        </ModalControls>
      )}
      {!expanded && (
        <span className='expand-button'>
          <button id='expand-filters' onClick={onToggle}>
            <McIcon
              icon='arrow-to-left'
              size={windowSize === 'small' ? '20' : '24'}
            />
          </button>
        </span>
      )}
    </Wrapper>
  )
}

export default FilterPanelFooter
