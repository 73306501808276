import * as React from 'react'
import moment from 'moment'
import { Filters, clearQueryParams, sortDateRange } from '..'
import styled, { media, sm, md, lg, info } from '../../../theme'
import { RouteComponentProps, withRouter } from 'react-router'
import { routerParams } from '../../../routes'
import { Icon } from '../../../commons'
import { McButton } from '@maersk-global/mds-react-wrapper'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  ${media.sm`font-size: ${sm.NOTIFICATION_BAR_FONT_SIZE};`};
  ${media.md`font-size: ${md.NOTIFICATION_BAR_FONT_SIZE};`};
  ${media.lg`font-size: ${lg.NOTIFICATION_BAR_FONT_SIZE};`};
  ${media.sm`height: ${sm.NOTIFICATION_BAR_HEIGHT};`};
  ${media.md`height: ${md.NOTIFICATION_BAR_HEIGHT};`};
  ${media.lg`height: ${lg.NOTIFICATION_BAR_HEIGHT};`};
  button {
    padding: 4px 8px;
    margin-left: 8px;
    font-size: 13px;
  }
  span {
    margin-left: 8px;
    vertical-align: middle;
    display: inline-block;
  }

  svg {
    width: 20px !important;
    height: 20px !important;
  }
`

export interface FiltersNotificationProps
  extends RouteComponentProps<routerParams> {
  onReset?: () => void
  filters?: Filters
}

const FilterNotication = withRouter(
  class FiltersNotification extends React.Component<FiltersNotificationProps> {
    constructor(props: FiltersNotificationProps) {
      super(props)

      this.state = {}
    }

    public render() {
      const { filters, onReset, history } = this.props

      if (!filters || !filters.dateRange || !onReset) {
        return null
      }

      const sortedFilters = sortDateRange(
        moment.utc(filters.dateRange.from),
        moment.utc(filters.dateRange.to),
      )

      return (
        <Wrapper>
          <Icon
            icon='fal fa-info-circle'
            style={{ width: '20px', height: '20px' }}
          />
          <span>
            You have applied filter for period&nbsp;
            <b>{moment.utc(sortedFilters.from).format('DD MMM HH:mm UTC')}</b>
            &nbsp;to&nbsp;
            <b>{moment.utc(sortedFilters.to).format('DD MMM HH:mm UTC')}</b>.
            Live data will not be updated in the graphs until filters has been
            cleared.
          </span>
          <McButton
            label='Clear filters'
            appearance='neutral'
            click={() => {
              history.replace(clearQueryParams())
              onReset()
            }}
            type='button'
          />
        </Wrapper>
      )
    }
  },
)

const getFilterNotification = (filters: Filters, onReset: () => void) => ({
  id: 'filter-notification',
  theme: info,
  content: <FilterNotication onReset={onReset} filters={filters} />,
})

export default getFilterNotification
