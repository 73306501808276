import styled, { red } from '../../theme'

export const Wrapper = styled.div`
  .shallow-water {
    padding-left: 8px;
    font-size: 12px;
    display: flex;
    align-items: center;
    svg {
      color: ${red[300]};
      height: 8px;
      width: 8px;
      margin-right: 4px;
    }
  }
`
