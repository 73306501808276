import { EventType } from '../../../../api-models/hdc/events'

type EventTypeOption = {
  value: number
  label: string
}

export const getEventTypeOptions = (
  eventTypes: Array<EventType>,
  path: Array<string> = [],
): Array<EventTypeOption> => {
  return eventTypes.flatMap((curr) => {
    const name = [...path, curr.name]
    if (curr.eventTypes) {
      return getEventTypeOptions(curr.eventTypes, name)
    } else if (curr.id) {
      return {
        value: curr.id,
        label: name.join(' / '),
      }
    }
    return []
  })
}
