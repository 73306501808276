import { Performance } from '../../api-models'

export const VESSEL_SPECIFIC_TYPES = {
  ME1: 'me1',
  ME2: 'me2',
  MIXED: 'MixedEng',
}
export interface EngineChartModel {
  name: string
  data: Performance.Sfoc.EngineSfocData
}

export enum MeSfocChartActionType {
  TOGGLE_CLASS_AVERAGE,
  TOGGLE_VESSEL_SPECIFIC,
  TOGGLE_SHOP_TEST,
  TOGGLE_ME1,
  TOGGLE_ME2,
  TOGGLE_MIX,
  TOGGLE_NON_STABLE_POINTS,
  TOGGLE_STABLE_POINTS,
  TOGGLE_HIGHLIGHT_PERIOD,
}

export interface ToggleInMeSfocChart {
  ME1: boolean
  ME2: boolean
  Mix: boolean
  showShopTestCurve: boolean
  showVesselSpecific: boolean
  showStableDataPointsOnly: boolean
}

export interface SfocSeries {
  mainEng1StableSfocSeries: Array<number | null>[]
  mainEng2StableSfocSeries: Array<number | null>[]
  mainEngMixStableSfocSeries: Array<number | null>[]
  mainEng1NonstableSfocSeries: Array<number | null>[]
  mainEng2NonstableSfocSeries: Array<number | null>[]
  mainEngMixNonstableSfocSeries: Array<number | null>[]
}
