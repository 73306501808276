import { McIcon } from '@maersk-global/mds-react-wrapper'

import { PerformanceBar, PodiumContainer } from './RankingCardPodium.styles'

const performanceBarItems = [
  {
    height: '80px',
    color: '#ffd340',
  },
  {
    height: '96px',
    color: '#ffd340',
  },
  {
    height: '80px',
    color: '#ffd340',
  },
]

const Podium = () => (
  <PodiumContainer>
    {performanceBarItems.map((bar, index) => (
      <div key={index}>
        <McIcon icon='star-solid' size='24' color={bar.color} />
        <PerformanceBar height={bar.height} />
      </div>
    ))}
  </PodiumContainer>
)

export default Podium
