import React, { useCallback, useContext, useState, useEffect, FC } from 'react'
import { Field } from 'formik'
import styled from '../../../../theme'
import { FormBatchSelection } from './FormBatchSelection'
import { FormFuelTypeSelection } from './FormFuelTypeSelection'
import { FuelType, FuelLineType } from '../../../../utils'
import { VesselPageContext } from '../../../../contexts/vessel-page-context'
import { ErrorMessage } from '../ErrorMessage'
import {
  BunkeredBatchLabReport,
  BatchCommons,
} from '../../../../api-models/performance/fuel-oil-stock'

export interface BatchSelectionCommons extends BatchCommons {
  id: string
  hasLabReport?: boolean
  labReport?: null | BunkeredBatchLabReport
}

const ConsumptionSelectionContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const ErrorMessagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

const Separator = styled.hr`
  margin: 32px 0px;
  width: 100%;
`

const validateBatchSelections = (batchSelections) => {
  let error
  const mdoSelection = batchSelections.find(
    ({ fuelType }) => fuelType === FuelType.MDO,
  )
  if (!mdoSelection) {
    error = 'An MDO batch must be selected.'
  }
  return error
}

interface ConsumptionSelectionProps {
  batches: BatchSelectionCommons[]
}

export const FormConsumptionSelection: FC<ConsumptionSelectionProps> = ({
  batches,
}) => {
  const { configuration } = useContext(VesselPageContext)
  const [uniqueFuelTypesOnboard, setUniqueFuelTypesOnboard] =
    useState<FuelType[]>()

  useEffect(() => {
    const fuelTypesFromBatches = batches.map(({ fuel }) => fuel.type)
    const uniqueFuelTypes: FuelType[] = Array.from(
      new Set(fuelTypesFromBatches),
    )
    setUniqueFuelTypesOnboard(uniqueFuelTypes)
  }, [batches])

  const validateFuelTypeSelections = useCallback(
    (fuelTypeSelections) => {
      let error
      const currentFuelLines = fuelTypeSelections.map(
        ({ fuelLineType }) => fuelLineType,
      )
      const allRequiredFuelLines = configuration?.fuelLineTypes.filter(
        (fuelLineType) =>
          ![FuelLineType.AEMDO, FuelLineType.BoilerMDO].includes(fuelLineType),
      )
      const hasAllRequiredFuelLines = allRequiredFuelLines?.every(
        (fuelLineType) => currentFuelLines.includes(fuelLineType),
      )
      if (!hasAllRequiredFuelLines) {
        error = 'All fuel type selections must be made'
      }
      return error
    },
    [configuration],
  )
  return (
    <>
      <ConsumptionSelectionContainer className='joyride-rob-consumption'>
        <ErrorMessagesContainer>
          <ErrorMessage
            name='consumption.batchSelections'
            width='calc(100% - 16px)'
          />
          <ErrorMessage
            name='consumption.fuelTypeSelections'
            width='calc(100% - 16px)'
          />
        </ErrorMessagesContainer>
        <div className='joyride-batch-selections'>
          <Field
            name='consumption.batchSelections'
            validate={validateBatchSelections}
            component={(props) => (
              <FormBatchSelection {...props} batches={batches} />
            )}
          />
        </div>
        <Separator />
        <div className='joyride-fuel-type-selection'>
          <Field
            name='consumption.fuelTypeSelections'
            validate={validateFuelTypeSelections}
            component={(props) => (
              <FormFuelTypeSelection
                {...props}
                uniqueFuelTypesOnboard={uniqueFuelTypesOnboard}
              />
            )}
          />
        </div>
      </ConsumptionSelectionContainer>
    </>
  )
}
