import { useEffect, useCallback, useContext, useState } from 'react'

import { Performance } from '../api-models'
import { EStockNotificationType } from '../api-models/performance/common'
import { EFuelConsumptionState } from '../api-models/performance/fuel-consumption'
import { Loading } from '../commons'
import Notifications from '../components/Notifications/Notifications'
import {
  FuelConsumptionContext,
  FuelConsumptionProvider,
  StockEntryContextProvider,
  VesselPageContext,
} from '../contexts'
import { ConsumerSelection } from '../features/fdl-4'
import FilterPanel, {
  FilterConfig,
  getFiltersFromQueryParams,
  getFilterNotification,
} from '../features/filter'
import { FuelConsumption } from '../features/fuel-consumption'
import {
  getStartUpNotification,
  getRecoveryNotification,
} from '../features/fuel-consumption/components/ConsumptionStateNotifications'
import { INotification } from '../features/notifications/models'
import { getFilteredNotifications } from '../features/stock-management/utils'
import { ContentLayout } from '../layout'
import * as PerformanceApi from '../services/performance'
import {
  displayErrorModal,
  FUEL_LINE_TO_CONSUMER,
  TEN_MINUTES_IN_MS,
} from '../utils'
import TemNotifications from '../components/TemNotifications/TemNotifications'
import PerformanceAlerts from '../components/PerformanceAlerts/PerformanceAlerts'

const filtersConfig: FilterConfig = {
  dateRange: {
    show: true,
    restrictNumberOfDays: 60,
    selections: [
      { value: 24, timeUnit: 'hours' },
      { value: 48, timeUnit: 'hours' },
      { value: 7, timeUnit: 'days' },
      { value: 14, timeUnit: 'days' },
      { value: 30, timeUnit: 'days' },
    ],
    canSelectMinuteSpecific: false,
  },
  numberOfDigits: {
    show: false,
    selections: [],
  },
  chartSmoothing: {
    show: false,
  },
}
const Page = () => {
  // Contexts
  const { imoNo, configuration, updateVesselStatus } =
    useContext(VesselPageContext)
  const { consumption, refreshFuelConsumption, resetConsumption } = useContext(
    FuelConsumptionContext,
  )

  // Local state
  const [advices, setAdvices] = useState<string[]>([])
  const [alerts, setAlerts] = useState<string[]>([])
  const [batches, setBatches] = useState<
    Performance.FuelOilStock.BatchResponse[]
  >([])
  const [filters, setFilters] = useState(getFiltersFromQueryParams())
  const [warnings, setWarnings] = useState<string[]>([])

  // Local vars
  const onFiltersReset = () => setFilters(undefined)
  const pageNotifications: INotification[] = []

  if (filters) {
    pageNotifications.push(getFilterNotification(filters, onFiltersReset))
  }

  if (consumption.consumptionState === EFuelConsumptionState.StartUp) {
    pageNotifications.push(getStartUpNotification())
  } else if (
    consumption.consumptionState === EFuelConsumptionState.Recoverable
  ) {
    pageNotifications.push(getRecoveryNotification())
  }

  const generateNotifications = useCallback(() => {
    let dataLossAlerts: string[] = []
    if (consumption.losses?.length) {
      dataLossAlerts = Object.entries(
        consumption.losses.reduce((result, { fuelLineType }) => {
          result[FUEL_LINE_TO_CONSUMER[fuelLineType]] =
            (result[FUEL_LINE_TO_CONSUMER[fuelLineType]] || 0) + 1
          return result
        }, {}),
      ).map(
        ([consumer, count]) =>
          `Please repair ${count} data ${
            (count === 1 && 'loss') || 'losses'
          } beneath the section of ${consumer} chart`,
      )
    }

    setAlerts([
      ...getFilteredNotifications(
        consumption.notifications,
        EStockNotificationType.Error,
      ),
      ...dataLossAlerts,
    ])
    setAdvices(
      getFilteredNotifications(
        consumption.notifications,
        EStockNotificationType.Info,
      ),
    )
    setWarnings(
      getFilteredNotifications(
        consumption.notifications,
        EStockNotificationType.Warning,
      ),
    )
  }, [consumption.losses, consumption.notifications])

  const getBatches = useCallback(() => {
    if (imoNo) {
      PerformanceApi.getBatches(imoNo, '', true)
        .then(setBatches)
        .catch((e) => {
          void displayErrorModal({
            statusText: 'Error when getting fuel consumption',
            message: e.message,
          })
        })
    }
  }, [imoNo])

  /**
   * refetchFuelConsumptionData
   *
   * @description Fuction that can re-fresh all the data needed regarding fuel consumptio.
   * function called `refreshFuelConsumption` stems from `FuelConsumptionContext`.
   */
  const refetchFuelConsumptionData = useCallback(() => {
    void refreshFuelConsumption()

    if (updateVesselStatus) {
      updateVesselStatus()
    }
  }, [updateVesselStatus, refreshFuelConsumption])

  /**
   * reset effect
   * @description triggers every time the imoNo changes (on shore) to reset the state of the
   * context. This is done so we start with a clean slate every time we switch vessels.
   */
  useEffect(() => {
    resetConsumption()
  }, [imoNo, resetConsumption])

  /**
   * re-fresh effect
   * @description this filter mounts a interval that every 10 minutes will refetch data.
   *
   * We do this so that if a user leaves the screen and comeback later he gets the latest view.
   */
  useEffect(() => {
    const id = setInterval(refetchFuelConsumptionData, TEN_MINUTES_IN_MS)

    return () => clearInterval(id)
  }, [refetchFuelConsumptionData])

  /**
   * filter effect
   * @description triggers once a user changes the filters to the side of the page,
   * a complete refetch of consumption data will be fetched.
   *
   * A optimization for the future could be just to filter this data, based on the epoc time stamps,
   * as this page automatically refetches data every 10 minutes already.
   */
  useEffect(refetchFuelConsumptionData, [refetchFuelConsumptionData, filters])

  useEffect(() => {
    getBatches()
  }, [imoNo, getBatches])

  useEffect(() => {
    generateNotifications()
  }, [generateNotifications])

  return (
    <>
      <ContentLayout
        header='Fuel consumption'
        notifications={pageNotifications}
        hasFilter
      >
        <FilterPanel
          onChange={setFilters}
          config={filtersConfig}
          value={filters}
          onReset={onFiltersReset}
        />
        <Notifications advices={advices} alerts={alerts} warnings={warnings} />
        <TemNotifications />
        <PerformanceAlerts />
        <div className='full-components'>
          <ConsumerSelection
            batches={batches}
            onSelectionChange={refetchFuelConsumptionData}
          />
        </div>
        <div className='full-components'>
          {!!configuration ? (
            <FuelConsumption configuration={configuration} />
          ) : (
            <Loading />
          )}
        </div>
      </ContentLayout>
    </>
  )
}

/**
 * FuelConsumptionPage
 * @description Thin wrapper around the `Page` component found in this file, this enables the page to have access
 * to the FuelConsumption & StockEntryContext at render time, rather than at a nested level, this makes the
 * re-fresh data flow a lot simpler.
 */
const FuelConsumptionPage = () => {
  const { imoNo } = useContext(VesselPageContext)
  return (
    <FuelConsumptionProvider imoNo={imoNo}>
      <StockEntryContextProvider imoNo={imoNo}>
        <Page />
      </StockEntryContextProvider>
    </FuelConsumptionProvider>
  )
}

export default FuelConsumptionPage
