import { FC, useContext, useEffect, useMemo, useState } from 'react'
import { Field, Form, Formik } from 'formik'
import * as moment from 'moment'
import Select from 'react-select'
import { McButton } from '@maersk-global/mds-react-wrapper'

import styled, { grey } from '../../../../theme'
import {
  FormContainer,
  FormInputWithUnit,
  FormTextArea,
  Loading,
  ModalControls as FormControls,
} from '../../../../commons'
import { VesselPageContext } from '../../../../contexts'
import { Performance } from '../../../../api-models'
import * as PerformanceApi from '../../../../services/performance'
import { MixedBatchTable } from './MixedBatchTable'
import {
  BatchType,
  displayErrorModal,
  formatValue,
  FuelType,
  isShoreContext,
} from '../../../../utils'
import { ErrorMessage } from '../ErrorMessage'
import { McTagGroup } from '../McTagGroup'
import { editMixedBatchValidationSchema } from './validation-schemas'
import {
  mapBatchResponseToMergedBatch,
  mapMixedBatchToFormValues,
  mapValuesToMixedBatchUpdateRequest,
} from './mappers'
import { fuelTypeOptions, fuelTypeSubOptions } from '../../models'
import { useFuelGrades } from '../../../../queries/MasterDataApi/MasterDataApi'
import { getFuelGradeOption } from '../../utils'

const ContentWrapper = styled.div`
  max-height: 80vh;
  overflow-y: auto;

  .notes-label {
    text-align: left;
    font-size: 14px;
    min-width: 144px;
    margin-right: 12px;
    white-space: nowrap;
    color: #666e79;
  }
`

const ErrorContainer = styled.div`
  width: 100%;
`

const SelectWrapper = styled.div`
  width: 259px;
  font-size: 14px;
`

const LabReportTitle = styled.div`
  border-bottom: 1px solid ${grey[500]};
  padding: 8px;
  margin: 0 16px;

  .span {
    font-size: 16px;
  }
`

interface MixedBatchDetailsProps {
  mixedBatch: Performance.FuelOilStock.MixedBatchEntryResponse
  closeHandler: (refreshAdjustments?: boolean) => void
}

export interface LabReport {
  density15: number | null
  lcv: number | null
  water: number | null
  ash: number | null
  sulphur: number | null
  marpolSeal?: string | null
}

export interface MergedBatch {
  id: string
  quantity?: number
  displayName: string
  fuelType: number
  labReport?: LabReport
}

export interface FormValues {
  timestamp?: moment.Moment
  mixedBatches?: MergedBatch[]
  notes: string
  fuelType: number
  fuelGrade: string
  displayName: string
  isDistillate: boolean
  quantityPerChiefEngineer?: number
  bioPercentage: string
  density15: string
  lcv: string
  water: string
  ash: string
  sulphur: string
}

export const defaultInitialValues: FormValues = {
  timestamp: undefined,
  mixedBatches: undefined,
  notes: '',
  fuelType: 0,
  fuelGrade: '',
  displayName: '',
  isDistillate: false,
  quantityPerChiefEngineer: undefined,
  bioPercentage: '',
  density15: '',
  lcv: '',
  water: '',
  ash: '',
  sulphur: '',
}

const MixedBatchDetails: FC<MixedBatchDetailsProps> = ({
  mixedBatch,
  closeHandler,
}) => {
  const { data: fuelGrades, isSuccess: isFuelGradesSuccess } = useFuelGrades()
  const imoNo = useContext(VesselPageContext).imoNo!
  const isLabReportReadonly = mixedBatch?.labReportReadonly
  const isReadOnly = mixedBatch?.readonly
  const [formInitialValues, setFormInitialValues] = useState<FormValues>()

  const updateMixedBatch = (
    values: FormValues,
    setSubmitting: (isSubmitting: boolean) => void,
  ) => {
    if (setSubmitting) setSubmitting(true)
    PerformanceApi.putMixedBatch(
      imoNo,
      mixedBatch.id,
      mapValuesToMixedBatchUpdateRequest(values, mixedBatch),
    )
      .then(() => closeHandler(true))
      .catch((e) => {
        displayErrorModal({
          statusText: 'Could not update mixed batch',
          message: e.message,
        })
      })
    setSubmitting(false)
  }

  const deleteMixedBatch = (batchId, setSubmitting) => {
    setSubmitting(true)
    PerformanceApi.deleteMixedBatch(imoNo, batchId)
      .then(() => closeHandler(true))
      .catch((e) => {
        setSubmitting(false)
        displayErrorModal({
          statusText: 'Could not delete mixed batch',
          message: e.message,
        })
      })
  }

  const displayApiErrorAndReset = (title, message) => {
    displayErrorModal({
      statusText: title,
      message: message,
    })
    setFormInitialValues(defaultInitialValues)
  }

  useEffect(() => {
    const batchIds = mixedBatch.batches.map((batch) => batch.id)
    const mergedBatches: MergedBatch[] = []
    const batchRequests: Promise<
      | Performance.FuelOilStock.BunkeredBatchResponse
      | Performance.FuelOilStock.MixedBatchEntryResponse
    >[] = []
    PerformanceApi.getBatchesById(imoNo, batchIds)
      .then((batchResponse: Performance.FuelOilStock.BatchResponse[]) => {
        for (const batch of batchResponse) {
          if (batch.type === BatchType.BunkeredBatch) {
            batchRequests.push(PerformanceApi.getBunkeredBatch(imoNo, batch.id))
          } else if (batch.type === BatchType.MixedBatch) {
            batchRequests.push(PerformanceApi.getMixedBatch(imoNo, batch.id))
          }
        }
        Promise.all(batchRequests)
          .then((batchRequestsRes) => {
            for (const batchReqRes of batchRequestsRes) {
              const mappedBatchRes: MergedBatch | null =
                mapBatchResponseToMergedBatch(mixedBatch, batchReqRes)
              if (mappedBatchRes) mergedBatches.push(mappedBatchRes)
            }
            setFormInitialValues(
              mapMixedBatchToFormValues(mixedBatch, mergedBatches),
            )
          })
          .catch((e) =>
            displayApiErrorAndReset(
              'Error fetching related batches',
              e.message,
            ),
          )
      })
      .catch((e) =>
        displayApiErrorAndReset('Could not fetch batches', e.message),
      )
  }, [mixedBatch, imoNo])

  const activeFuelGradeOptions = useMemo(() => {
    if (!isFuelGradesSuccess) return []
    return fuelGrades.filter((fg) => fg.data.isActive).map(getFuelGradeOption)
  }, [fuelGrades, isFuelGradesSuccess])

  return formInitialValues ? (
    <Formik
      initialValues={formInitialValues}
      onSubmit={(values, { setSubmitting }) =>
        updateMixedBatch(values, setSubmitting)
      }
      validationSchema={editMixedBatchValidationSchema}
      validateOnBlur={false}
    >
      {({ values, isSubmitting, setSubmitting, isValidating, isValid }) => {
        const isSubmitDisabled =
          isShoreContext() ||
          (isLabReportReadonly && isReadOnly) ||
          isSubmitting ||
          isValidating ||
          !isValid
        const isDeleteDisabled = isShoreContext() || isReadOnly || isSubmitting
        return (
          <Form>
            <ContentWrapper>
              <FormContainer
                width='900px'
                labelWidth='132px'
                flexDirection='row'
              >
                <div style={{ width: '100%' }}>
                  <p
                    style={{
                      fontSize: '14px',
                      padding: '8px 0',
                      color: '#666E79',
                    }}
                  >
                    <span style={{ marginRight: '4px' }}>
                      Time of mixing UTC
                    </span>
                    <span style={{ color: '#000' }}>
                      {values?.timestamp
                        ? moment
                            .utc(values.timestamp)
                            .format('DD MMM YYYY HH:mm')
                        : ''}
                    </span>
                  </p>
                </div>
                <div style={{ flex: 1 }}>
                  <MixedBatchTable
                    mixedBatches={values.mixedBatches}
                    quantityCE={values.quantityPerChiefEngineer}
                  />
                </div>
                <div style={{ flex: 1 }}>
                  <div className='field-container'>
                    <span className='field-label'>Fuel grade</span>
                    <Field
                      component={(props) => (
                        <SelectWrapper>
                          <Select
                            placeholder='Type to search...'
                            onBlur={() =>
                              props.form.setFieldTouched(props.field.name)
                            }
                            onChange={(option: any) => {
                              if (option?.value) {
                                props.form.setFieldValue(
                                  props.field.name,
                                  option.value,
                                )
                                const fuelGrade = fuelGrades?.find(
                                  (fg) => fg.data.code === option.value,
                                )
                                if (fuelGrade) {
                                  props.form.setFieldValue(
                                    'fuelType',
                                    fuelGrade.data.fuelType,
                                  )
                                  props.form.setFieldValue(
                                    'isDistillate',
                                    fuelGrade.data.refinementType === 1,
                                  )
                                }
                              }
                            }}
                            value={
                              props.field?.value &&
                              props.options?.find(
                                ({ value }) => value === props.field.value,
                              )
                            }
                            options={props.options}
                            isOptionDisabled={({ isDisabled }) =>
                              isDisabled ?? false
                            }
                            isSearchable={true}
                            isDisabled={isReadOnly}
                            styles={{
                              placeholder: (provided) => ({
                                ...provided,
                                fontSize: '14px',
                              }),
                              option: (provided) => ({
                                ...provided,
                                fontSize: '14px',
                              }),
                              valueContainer: (provided) => ({
                                ...provided,
                                fontSize: '14px',
                              }),
                            }}
                          />
                        </SelectWrapper>
                      )}
                      options={activeFuelGradeOptions}
                      name='fuelGrade'
                      disabled={isReadOnly}
                    />
                  </div>

                  <div className='field-container'>
                    <span className='field-label'>Fuel type</span>
                    <McTagGroup name='fuelType' options={fuelTypeOptions} />
                  </div>

                  {values.fuelType !== 0 && values.fuelType !== FuelType.MM && (
                    <div className='field-container'>
                      <span className='field-label'>
                        Type of {FuelType[values.fuelType]}
                      </span>
                      <McTagGroup
                        name='isDistillate'
                        options={fuelTypeSubOptions[values.fuelType]}
                      />
                    </div>
                  )}

                  <div className='field-container'>
                    <span className='field-label'>Percentage bio</span>
                    <Field
                      component={FormInputWithUnit}
                      name='bioPercentage'
                      type='text'
                      unit='%mass'
                      placeholder='0'
                      width='120px'
                      disabled={isReadOnly}
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop: '8px',
                  }}
                >
                  <div className='field-component'>
                    <div className='notes-label'>Notes</div>
                    <Field
                      component={FormTextArea}
                      name='notes'
                      width='800px'
                      disabled={isReadOnly}
                    />
                  </div>
                </div>
                <ErrorContainer>
                  <ErrorMessage name='notes' />
                </ErrorContainer>
              </FormContainer>
              <>
                <LabReportTitle>
                  <span>Lab report</span>
                </LabReportTitle>
                <FormContainer width='500px' height='150px' labelWidth='100px'>
                  <div style={{ flex: 1 }}>
                    <div className='field-container'>
                      <span className='field-label'>Density 15</span>
                      <Field
                        component={FormInputWithUnit}
                        formatInputValue={(value) => formatValue(value, 2, '')}
                        name='density15'
                        type='number'
                        unit='kg/m3'
                        width='120px'
                        disabled={isLabReportReadonly}
                      />
                    </div>
                    <ErrorContainer>
                      <ErrorMessage name='density15'></ErrorMessage>
                    </ErrorContainer>
                    <div className='field-container'>
                      <span className='field-label'>LCV</span>
                      <Field
                        component={FormInputWithUnit}
                        formatInputValue={(value) => formatValue(value, 0, '')}
                        name='lcv'
                        type='number'
                        unit='KJ/kg'
                        width='120px'
                        disabled={isLabReportReadonly}
                      />
                    </div>
                    <ErrorContainer>
                      <ErrorMessage name='lcv'></ErrorMessage>
                    </ErrorContainer>
                  </div>
                  <div style={{ flex: 1 }}>
                    <div className='field-container'>
                      <span className='field-label'>Water</span>
                      <Field
                        component={FormInputWithUnit}
                        formatInputValue={(value) => formatValue(value, 2, '')}
                        name='water'
                        type='number'
                        unit='%volume'
                        width='120px'
                        disabled={isLabReportReadonly}
                      />
                    </div>
                    <ErrorContainer>
                      <ErrorMessage name='water'></ErrorMessage>
                    </ErrorContainer>
                    <div className='field-container'>
                      <span className='field-label'>Ash</span>
                      <Field
                        component={FormInputWithUnit}
                        formatInputValue={(value) => formatValue(value, 2, '')}
                        name='ash'
                        type='number'
                        unit='%mass'
                        width='120px'
                        disabled={isLabReportReadonly}
                      />
                    </div>
                    <ErrorContainer>
                      <ErrorMessage name='ash'></ErrorMessage>
                    </ErrorContainer>
                    <div className='field-container'>
                      <span className='field-label'>Sulphur</span>
                      <Field
                        component={FormInputWithUnit}
                        formatInputValue={(value) => formatValue(value, 2, '')}
                        name='sulphur'
                        type='number'
                        unit='%mass'
                        width='120px'
                        disabled={isLabReportReadonly}
                      />
                    </div>
                    <ErrorContainer>
                      <ErrorMessage name='sulphur'></ErrorMessage>
                    </ErrorContainer>
                  </div>
                </FormContainer>
              </>
            </ContentWrapper>
            <FormControls>
              <McButton
                label='Delete'
                appearance='error'
                className='left'
                disabled={isDeleteDisabled}
                click={() => deleteMixedBatch(mixedBatch.id, setSubmitting)}
                type='button'
              />
              <McButton
                label='Cancel'
                appearance='neutral'
                click={() => closeHandler(false)}
                type='button'
              />
              <McButton
                label='Save changes'
                appearance='primary'
                disabled={isSubmitDisabled}
                type='submit'
              />
            </FormControls>
          </Form>
        )
      }}
    </Formik>
  ) : (
    <Loading />
  )
}

export default MixedBatchDetails
