import * as React from 'react'
import styled from '../theme'

const SortableTableHeaderWrapper = styled.div<{ sortable?: boolean }>`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  max-height: 34px;
  min-height: 20px;

  .table-header {
    height: initial;
    width: ${(props) => (props.sortable ? 'calc(100% - 16px)' : '100%')};
    white-space: pre-wrap;
    padding: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 12px;
    display: flex;
    background-color: transparent;
    border: none;
    justify-content: space-between;
    align-items: center;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    margin-bottom: 0.6rem;

    &.bold {
      font-weight: bold;
    }
  }

  .unit {
    text-align: left;
    font-style: italic;
    font-weight: 500;
    width: 100%;
    margin-top: -4px;
  }

  svg {
    height: 16px;
    width: 12px !important;
  }

  span.sortable {
    position: absolute;
    right: 0px;
    display: flex;
    align-items: center;
  }
  span.sort-up,
  span.sort-down {
    color: #a3a3a3;
    display: none;
  }
  span.sort-neutral {
    color: #d3d3d3;
  }
`

interface SortableTableHeaderProps {
  headerText: any
  unit?: string
  disableSort?: boolean
  title?: string
}

export class SortableTableHeader extends React.Component<
  SortableTableHeaderProps,
  any
> {
  render() {
    const { headerText, unit, disableSort, title } = this.props
    return (
      <SortableTableHeaderWrapper
        title={typeof title === 'string' ? title : ''}
        sortable={!disableSort}
      >
        <div className={`table-header ${unit ? 'bold' : ''}`}>{headerText}</div>
        {unit && <span className='unit'>{unit}</span>}
        {!disableSort && (
          <>
            <span className='sortable sort-neutral'>
              <i className='fas fa-sort' />
            </span>
            <span className='sortable sort-up'>
              <i className='fas fa-sort-up' />
            </span>
            <span className='sortable sort-down'>
              <i className='fas fa-sort-down' />
            </span>
          </>
        )}
      </SortableTableHeaderWrapper>
    )
  }
}
