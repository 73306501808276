import { useEffect, useRef, useState } from 'react'
import { type EChartsType } from 'echarts/types/dist/shared'

import { ChartWrapper, Wrapper } from '../../Common/ChartStyles'
import { ModelData, initChart, updateChart, TestData } from './Charts.utils'

const TC_PSCAV_CHART_ID = 'tc-pscav-chart'
const TC_SPEED_CHART_ID = 'tc-speed-chart'
const TC_INLET_TEMP_CHART_ID = 'tc-inlet-temp-chart'

type Props = {
  isActive: boolean
  general: GandalfApi.Metc.General
  equipment: GandalfApi.Metc.Equipment
  result: GandalfApi.Metc.Result
}

const TcEfficiencyCharts = ({
  isActive,
  general,
  equipment,
  result,
}: Props) => {
  const [isInitialized, setIsInitialized] = useState(false)

  const pscavChartRef = useRef<EChartsType>()
  const speedChartRef = useRef<EChartsType>()
  const inletTempChartRef = useRef<EChartsType>()

  const operatingCondition = general.tccoEngaged.value
    ? equipment.shopTest.tccoOperatingCondition!
    : equipment.shopTest.defaultOperatingCondition

  const modelPscavData: Array<ModelData> = [
    {
      x: operatingCondition.referenceCondition.load,
      y: operatingCondition.isoCondition.scavAirPres,
      name: 'Pscav shop test',
    },
  ]

  const testPscavData: Array<TestData> = [
    {
      x: result.general.engineLoad,
      y: result.tcEfficiency.scavAirPres.isoConditionAdjustedValue,
      name: 'Pscav test (ISO)',
    },
  ]

  const modelTcSpeedData: Array<ModelData> = [
    {
      x: operatingCondition.referenceCondition.load,
      y: operatingCondition.isoCondition.tcSpeed,
      name: 'TC speed shop test',
    },
  ]

  const testTcSpeedData: Array<TestData> = [
    {
      x: result.general.engineLoad,
      y: result.tcEfficiency.tcSpeed.isoConditionAdjustedValue,
      name: 'TC speed test (ISO)',
    },
  ]

  const modelInletTempData: Array<ModelData> = [
    {
      x: operatingCondition.referenceCondition.load,
      y: operatingCondition.isoCondition.turbineInletTemp,
      name: 'TC inlet temp shop test',
    },
  ]

  const testInletTempData: Array<TestData> = [
    {
      x: result.general.engineLoad,
      y: result.tcEfficiency.tempBeforeTc.isoConditionAdjustedValue,
      name: 'TC inlet temp test (ISO)',
    },
  ]

  useEffect(() => {
    if (!isActive || isInitialized) return

    setTimeout(() => {
      pscavChartRef.current = initChart({
        domId: TC_PSCAV_CHART_ID,
        xLabel: 'Engine load',
        xUnit: '%MCR',
        yLabel: 'Pscav',
        yUnit: 'bar',
      })
      updateChart(modelPscavData, testPscavData, pscavChartRef.current)

      speedChartRef.current = initChart({
        domId: TC_SPEED_CHART_ID,
        xLabel: 'Engine load',
        xUnit: '%MCR',
        yLabel: 'Turbocharger speed',
        yUnit: 'RPM',
      })
      updateChart(modelTcSpeedData, testTcSpeedData, speedChartRef.current)

      inletTempChartRef.current = initChart({
        domId: TC_INLET_TEMP_CHART_ID,
        xLabel: 'Engine load',
        xUnit: '%MCR',
        yLabel: 'Turbocharger inlet temp',
        yUnit: '°C',
      })
      updateChart(
        modelInletTempData,
        testInletTempData,
        inletTempChartRef.current,
      )
    }, 0)

    setIsInitialized(true)
  }, [isActive])

  useEffect(() => {
    if (!isInitialized) return
    updateChart(modelPscavData, testPscavData, pscavChartRef.current)
    updateChart(modelTcSpeedData, testTcSpeedData, speedChartRef.current)
    updateChart(
      modelInletTempData,
      testInletTempData,
      inletTempChartRef.current,
    )
  }, [result.adjustedIsoConditions])

  return (
    <Wrapper>
      <ChartWrapper id={TC_PSCAV_CHART_ID} />
      <ChartWrapper id={TC_SPEED_CHART_ID} />
      <ChartWrapper id={TC_INLET_TEMP_CHART_ID} />
    </Wrapper>
  )
}

export default TcEfficiencyCharts
