import moment, { Moment, unitOfTime } from 'moment'
import { roundToClosestTen, isNumber } from './math-utils'
import { Performance } from '../api-models'
import { QueryPeriod } from '../features/filter'

export const TEN_MINUTES_IN_MS = 600000

export const HOURS_REGEX = /^(0?[0-9]|1[0-9]|2[0-3])$/
export const MINUTES_REGEX = /^([0-5]?[0-9]|59)$/

export const getTodayOrTimestamp: (
  timestamp: string | number,
  format?: string,
) => string = (timestamp, format) => {
  if (
    moment.utc(timestamp).date() === moment.utc().date() &&
    moment.utc(timestamp).month() === moment.utc().month() &&
    moment.utc(timestamp).year() === moment.utc().year()
  ) {
    return 'Today'
  }
  if (format) return moment.utc(timestamp).format(format)
  return moment.utc(timestamp).format('DD MMM')
}

export const getClosestTenMinute: (timestamp: Moment) => Moment = (
  timestamp,
) => {
  const startOfHour = timestamp.clone().startOf('hour')
  const diff = timestamp.diff(startOfHour, 'minutes')
  return startOfHour.add(roundToClosestTen(diff), 'minutes').startOf('minute')
}

export const getClosestMinuteFromTimestamp = (
  timestamp: number,
  interval: number,
): number => {
  const diff = timestamp % (interval * 60 * 1000)
  if (diff < (interval * 60 * 1000) / 2) {
    // round down
    return timestamp - diff
  } else {
    // round up
    return timestamp - diff + interval * 60 * 1000
  }
}

export const formatTimeAgo: (seconds: number | null) => string = (seconds) => {
  if (!seconds) return '-'
  switch (true) {
    case seconds < 60:
      return `${seconds}s`
    case seconds < 3600:
      return `${Math.floor(seconds / 60)}m`
    case seconds < 86400:
      return `${Math.floor(seconds / 3600)}h`
    default:
      return `${Math.floor(seconds / 86400)}d`
  }
}

export const getTimestampFromVesselStatus: (
  vesselStatus?: Performance.Status.Status | null,
) => string | null = (vesselStatus) => {
  if (!!vesselStatus && vesselStatus.timestamp) {
    const { timestamp } = vesselStatus
    const dataTimestamp = moment.utc(timestamp)
    if (
      dataTimestamp.date() === moment.utc().date() &&
      dataTimestamp.month() === moment.utc().month() &&
      dataTimestamp.year() === moment.utc().year()
    ) {
      return `${getTodayOrTimestamp(timestamp)} ${moment
        .utc(timestamp)
        .format('HH:mm UTC')}`
    } else {
      return moment.utc(timestamp).format('dd/MM HH:mm UTC')
    }
  } else {
    return `-`
  }
}

export const setMaxDateAsToday: (maxDate?: Moment) => Moment = (maxDate) => {
  const now = moment.utc()
  if (!maxDate) return now
  return now.isBefore(maxDate) ? now : maxDate
}

export function numberOfMinutesToTime(numberOfMinutes: number | null): string {
  if (!isNumber(numberOfMinutes)) return '-'
  const hours = Math.floor(numberOfMinutes / 60)
  let minutes = numberOfMinutes - hours * 60
  if (minutes < 10) return `${hours}:0${minutes}`
  return `${hours}:${minutes}`
}

export function getDateRangeFilterDifference(
  queryPeriod: QueryPeriod,
  timeUnit: unitOfTime.Base,
): number {
  if (queryPeriod) {
    return moment
      .utc(queryPeriod.to)
      .diff(moment.utc(queryPeriod.from), timeUnit)
  }
  return 0
}

export const formatTimeDifference = (diffInMinutes: number) => {
  if (diffInMinutes === 0 || Number.isNaN(diffInMinutes)) {
    return '-'
  }

  if (diffInMinutes < 60) {
    return `${diffInMinutes} minute${diffInMinutes !== 1 ? 's' : ''}`
  }

  if (diffInMinutes === 60) {
    return '1 hour'
  }

  const hours = Math.floor(diffInMinutes / 60)
  const minutes = diffInMinutes % 60

  return `${hours} hour${hours !== 1 ? 's' : ''}, ${minutes} minute${
    minutes !== 1 ? 's' : ''
  }`
}
