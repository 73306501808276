import {
  ELubeOilContainer,
  ELubeOilType,
  type ITransferFormValues,
} from '../types'
import { LUBE_OIL_TYPE_NAME } from '../constants'
import { getLubeOilContainerName } from '../utils'

export const getInitialValues = (): ITransferFormValues => ({
  fromOilContainer: null,
  notes: '',
  oilType: null,
  quantity: null,
  timestamp: null,
  toOilContainer: null,
})

export const oilTypeOptions = Object.entries(LUBE_OIL_TYPE_NAME)
  .filter(([value, _]) =>
    [ELubeOilType.AE, ELubeOilType.ME].includes(parseInt(value, 10)),
  )
  .map(([value, label]) => ({
    label,
    value: parseInt(value, 10),
  }))

export const getFromOilContainerOptions = (hasTwoMainEngines: boolean) =>
  Object.values(ELubeOilContainer)
    .filter(
      (oilContainer) =>
        !isNaN(Number(oilContainer)) &&
        [
          ELubeOilContainer.SpareClean,
          ELubeOilContainer.SpareDirty,
          ELubeOilContainer.Working,
          ...(hasTwoMainEngines ? [ELubeOilContainer.WorkingStbd] : []),
        ].includes(oilContainer as ELubeOilContainer),
    )
    .map((oilContainer) => ({
      label: getLubeOilContainerName(
        oilContainer as ELubeOilContainer,
        hasTwoMainEngines,
      ),
      value: oilContainer,
    }))

export const getToOilContainerOptions = (hasTwoMainEngines: boolean) =>
  Object.values(ELubeOilContainer)
    .filter(
      (oilContainer) =>
        !isNaN(Number(oilContainer)) &&
        ![
          ELubeOilContainer.DayTank,
          ELubeOilContainer.DayTankStbd,
          ELubeOilContainer.SpareClean,
          ...(!hasTwoMainEngines ? [ELubeOilContainer.WorkingStbd] : []),
        ].includes(oilContainer as ELubeOilContainer),
    )
    .map((oilContainer) => ({
      label: getLubeOilContainerName(
        oilContainer as ELubeOilContainer,
        hasTwoMainEngines,
      ),
      value: oilContainer,
    }))
