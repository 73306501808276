import React, { FC, useState, useContext } from 'react'
import { Performance } from '../../../../../api-models'
import * as PerformanceAPI from '../../../../../services/performance'
import { VesselPageContext } from '../../../../../contexts'
import { displayErrorModal } from '../../../../../utils'
import { BatchTransferModal } from '../BatchTransferModal'
import { FuelType } from '../../../../../utils/models'
import { BatchSelections } from './BatchSelections'

interface BatchSelectionsContainerProps {
  onSelectionsUpdate?: () => void
  batchSelections:
    | Performance.FuelOilStock.CurrentBatchSelectionResponse[]
    | null
}

export const BatchSelectionsContainer: FC<BatchSelectionsContainerProps> = ({
  onSelectionsUpdate,
  batchSelections,
}) => {
  const vesselPageContext = useContext(VesselPageContext)
  const imoNo = vesselPageContext.imoNo!

  //Batch selection state
  const [fuelTypeForBatchSelection, setFuelTypeForBatchSelection] =
    useState<FuelType>()

  const showErrorWithTitle = (statusText) => (error) =>
    displayErrorModal({
      statusText,
      message: error.message,
    })

  const submitBatchTransfer = (
    batchSelection: Performance.FuelOilStock.BatchSelection,
  ) => {
    return PerformanceAPI.postBatchSelection(imoNo, batchSelection)
      .then(() => {
        onEntryUpdates()
        setFuelTypeForBatchSelection(undefined)
      })
      .catch(showErrorWithTitle('Could not create batch selection'))
  }

  const onEntryUpdates = () => {
    if (onSelectionsUpdate) onSelectionsUpdate()
  }

  return (
    <>
      <BatchSelections
        onClick={(fuelType) => setFuelTypeForBatchSelection(fuelType)}
        batchSelections={batchSelections}
      />
      {batchSelections && fuelTypeForBatchSelection && (
        <BatchTransferModal
          fuelTypeForBatchSelection={fuelTypeForBatchSelection}
          currentlySelectedBatch={batchSelections.find(
            (selection) => selection.fuelType === fuelTypeForBatchSelection,
          )}
          closeHandler={() => setFuelTypeForBatchSelection(undefined)}
          submit={(batchSelection) => {
            return submitBatchTransfer(batchSelection)
          }}
        />
      )}
    </>
  )
}
