import { Topic } from '../topic'

export const Reporting = () => {
  const about = (
    <>
      <p>
        Here you find help on the subject of reporting in StarConnect. In this
        section you get an introduction to the various functions in manual
        Reporting and guides for steps required. You can also read more about
        the various report types in StarConnect - when to do it and how.
      </p>
    </>
  )

  return <Topic group='fuel' topic='reporting' about={about} />
}
