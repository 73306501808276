import {
  ActivityLogTab,
  ActivityLogTabSearchParam,
} from './ActivityLogPage.consts'

/**
 * Resolves the initial tab index based on the URL search params. If the search
 * params do not contain a valid tab index, we default to the
 * <strong>Fuel & stock</strong> tab.
 */
export const resolveInitialTabIndex = (
  searchParams: URLSearchParams,
): ActivityLogTab => {
  const defaultTabIdx = ActivityLogTab.FuelAndStock

  const initialTabIdx = parseInt(
    searchParams.get(ActivityLogTabSearchParam) ?? defaultTabIdx.toString(),
  )

  const isValidTabIdx = Object.values(ActivityLogTab).includes(initialTabIdx)

  return isValidTabIdx ? initialTabIdx : defaultTabIdx
}
