import React, { FC } from 'react'
import styled, { grey } from '../../../theme'

const Wrapper = styled.div`
  position: relative;
`

const Tooltip = styled.div`
  display: flex;
  align-items: center;
  width: 380px;
  position: absolute;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.35);
  border-radius: 4px;
  background-color: #fff;
  right: 63px;
  bottom: 32px;
  padding: 4px 16px 4px 4px;

  p {
    margin-left: 4px;
  }

  button {
    right: 4px;
    top: 4px;
    position: absolute;
    color: ${grey[700]};
    background-color: transparent;
  }

  i {
    position: absolute;
    top: 100%;
    right: 1px;
    margin-left: -16px;
    width: 32px;
    height: 16px;
    overflow: hidden;
  }

  i::after {
    content: '';
    position: absolute;
    width: 16px;
    height: 16px;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    background-color: #fff;
    box-shadow: 3px 2px 5px rgba(0, 0, 0, 0.349019607843137);
  }
`

interface CalculatedTooltipProps {
  closeHandler: () => void
}

export const CalculatedTooltip: FC<CalculatedTooltipProps> = ({
  closeHandler,
}) => {
  return (
    <Wrapper>
      <Tooltip>
        <i className='fal fa-info-circle fa-2x' />
        <p>
          Data is not available for the full period you have selected.
          <br />
          We have therefore adjusted it to match availability.
        </p>
        <button
          onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
            closeHandler()
          }}
        >
          <i className='fal fa-times' />
        </button>
        <i></i>
      </Tooltip>
    </Wrapper>
  )
}
