import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { HubConnectionState } from '@microsoft/signalr'

import { VesselPageContext } from '../../contexts'
import { displayErrorModal } from '../../utils'
import { getSystemDataPointGroups } from '../../services/sensors'
import {
  EDataPointGroupName,
  hubMethods,
} from '../../api-models/sensor-status/consts'
import { ContentLayout } from '../../layout'
import { ContentCard, Loading, Tabs } from '../../commons'
import { DataPointsTable } from '../../features/data-points'
import { connection } from './helpers'
import TemNotifications from '../../components/TemNotifications/TemNotifications'
import PerformanceAlerts from '../../components/PerformanceAlerts/PerformanceAlerts'

const DataPointsPage = () => {
  const imoNo = useContext(VesselPageContext).imoNo!

  const dataPointGroupsQuery = useQuery(
    ['sensor_status__system__data_point_groups', imoNo],
    () => getSystemDataPointGroups(imoNo),
    { staleTime: Infinity },
  )

  const [state, setState] = useState<DataPointsPage.State>({
    activeFeed: EDataPointGroupName.CAMS,
  })

  const startConnection = useCallback(async () => {
    if (connection.state !== HubConnectionState.Disconnected) return

    connection.on(
      hubMethods.update,
      (
        type: string,
        connected: boolean,
        log: SensorStatusApi.SignalR.DataPointLog,
      ) => {
        setState((prevState) => ({ ...prevState, dataPointLog: { ...log } }))
      },
    )

    try {
      await connection.start()
      await connection.invoke(hubMethods.subscribe, EDataPointGroupName.CAMS)
    } catch (err) {
      await displayErrorModal(err)
    }
  }, [])

  useEffect(() => {
    void startConnection()
    return () => {
      if (
        [
          HubConnectionState.Connected,
          HubConnectionState.Disconnecting,
        ].includes(connection.state)
      ) {
        void connection.stop()
      }
    }
  }, [startConnection])

  useEffect(() => {
    if (connection.state !== HubConnectionState.Connected) return
    void connection.invoke(hubMethods.subscribe, state.activeFeed)
  }, [state.activeFeed])

  const handleFeedChange = async (
    feed: SensorStatusApi.System.DataPointGroupName,
  ) => {
    await connection.invoke(hubMethods.unsubscribe, state.activeFeed)
    setState({ ...state, activeFeed: feed })
  }

  if (dataPointGroupsQuery.isError) {
    void displayErrorModal(dataPointGroupsQuery.error as ResponseError)
    return null
  }

  if (
    !dataPointGroupsQuery.isSuccess ||
    connection.state !== HubConnectionState.Connected
  ) {
    return null
  }

  return (
    <ContentLayout header='Data points'>
      <TemNotifications />
      <PerformanceAlerts />
      <ContentCard id='data-points' title='Data points'>
        <Tabs
          tabButtons={[
            {
              name: 'CAMS',
              onClick: () => handleFeedChange(EDataPointGroupName.CAMS),
            },
            {
              name: 'Consumption',
              onClick: () => handleFeedChange(EDataPointGroupName.CONSUMPTION),
            },
          ]}
        >
          <div className='table'>
            {(state.dataPointLog?.dataPoints?.length && (
              <DataPointsTable
                dataPointGroups={dataPointGroupsQuery.data}
                activeFeed={state.activeFeed}
                {...state.dataPointLog!}
              />
            )) || <Loading />}
          </div>
          <div className='table'>
            {(state.dataPointLog?.dataPoints?.length && (
              <DataPointsTable
                dataPointGroups={dataPointGroupsQuery.data}
                activeFeed={state.activeFeed}
                {...state.dataPointLog!}
              />
            )) || <Loading />}
          </div>
        </Tabs>
      </ContentCard>
    </ContentLayout>
  )
}

export default DataPointsPage
