import { useHistory } from 'react-router'
import {
  McButton,
  McCAccordion,
  McCAccordionItem,
} from '@maersk-global/mds-react-wrapper'

import { HelpSection } from '../help-section'
import { HELP_SECTION_TOPICS } from './constants'
import { Subtopic } from './subtopic'

interface TopicProps {
  group: string
  topic: string
  about: JSX.Element
}

export const Topic = ({ group, topic, about }: TopicProps) => {
  const { goBack } = useHistory()

  const topics = (
    <>
      <McButton click={goBack} fit='small' icon='arrow-left'>
        Back
      </McButton>
      <h3>{HELP_SECTION_TOPICS[group].topics[topic].name}</h3>
      <p className='title'>
        Last updated {HELP_SECTION_TOPICS[group].topics[topic].updatedAt}
      </p>
      <h4>About the feature</h4>
      {about}
      <h4>Guides on {HELP_SECTION_TOPICS[group].topics[topic].name}</h4>
      <McCAccordion>
        {HELP_SECTION_TOPICS[group].topics[topic].subtopics.map(
          (subtopic, idx) => (
            <McCAccordionItem
              key={idx}
              title={`${idx + 1}. ${subtopic.name}`}
              id={idx + 1}
            >
              <Subtopic component={subtopic.component} name={subtopic.name} />
            </McCAccordionItem>
          ),
        )}
      </McCAccordion>
    </>
  )

  return <HelpSection topics={topics} />
}
