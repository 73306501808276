import { useContext, useEffect, useState } from 'react'
import { McButton } from '@maersk-global/mds-react-wrapper'

import { Loading, Modal, ModalControls } from '../../../../commons'
import { Performance } from '../../../../api-models'
import { VesselPageContext } from '../../../../contexts'
import * as PerformanceApi from '../../../../services/performance'
import { displayErrorModal, isShoreContext } from '../../../../utils'
import { RobMarkInfo } from './RobMarkInfo'
import { getBatchDictionary, getMergedBatchRobMap } from '../../utils'
import { FullRobMarkEntry, MergedBatchRob } from '../../models'
import styled from './../../../../theme'

const Wrapper = styled.div`
  max-height: 80vh;
  overflow-y: auto;
`

type Props = {
  entryId?: string
  closeHandler: (refreshAdjustments?: boolean) => void
}

export const RobMarkInfoModal = ({ entryId, closeHandler }: Props) => {
  const imoNo = useContext(VesselPageContext).imoNo!
  const [robMark, setRobMark] = useState<FullRobMarkEntry>()
  const isChangeAllowed = robMark && !robMark.readonly

  const deleteRobMark = (
    entryId: string,
    setSubmitting?: (isSubmitting: boolean) => void,
  ) => {
    if (setSubmitting) setSubmitting(true)
    PerformanceApi.deleteRobMark(imoNo, entryId)
      .then(() => closeHandler(true))
      .catch((e) => {
        void displayErrorModal(e)
      })
      .finally(() => {
        if (setSubmitting) setSubmitting(false)
      })
  }

  useEffect(() => {
    if (entryId) {
      PerformanceApi.getRobMark(imoNo, entryId)
        .then((data: Performance.FuelOilStock.RobMarkEntry) => {
          const batchIds = data.batches.map((batch) => batch.id)
          PerformanceApi.getBatchesById(imoNo, batchIds).then(
            (res: Performance.FuelOilStock.BatchResponse[]) => {
              const batchesById = getBatchDictionary(res)
              const mergedValues: MergedBatchRob[] = data.batches.map(
                (batch) => ({
                  ...batch,
                  displayName: batchesById[batch.id].displayName,
                  fuelType: batchesById[batch.id].fuel.type,
                  timestamp: batchesById[batch.id].timestamp,
                }),
              )
              const newBatchQuantities = getMergedBatchRobMap(mergedValues)
              const fullRobMark: FullRobMarkEntry = {
                batchQuantities: newBatchQuantities,
                timestamp: data.timestamp,
                id: data.id,
                notes: data.notes,
                readonly: data.readonly,
              }
              setRobMark(fullRobMark)
            },
          )
        })
        .catch((e) => displayErrorModal(e))
    }
  }, [entryId, imoNo])

  return (
    <Modal
      title='Remaining on-board'
      visible
      closeHandler={closeHandler}
      helpTextKey='stock/rob-mark'
    >
      <Wrapper>
        {entryId && !robMark ? <Loading /> : <RobMarkInfo entry={robMark} />}
      </Wrapper>
      <ModalControls>
        <McButton
          label='Close'
          appearance='primary'
          click={() => closeHandler()}
          type='button'
        />
        {isChangeAllowed && (
          <McButton
            label='Delete'
            appearance='error'
            disabled={isShoreContext()}
            click={() => {
              if (entryId) {
                deleteRobMark(entryId)
              }
            }}
            type='button'
          />
        )}
      </ModalControls>
    </Modal>
  )
}
