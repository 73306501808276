import React, { useContext } from 'react'
import { McIcon, McInput } from '@maersk-global/mds-react-wrapper'

import { WindowContext } from '../../contexts'
import { TWindowSize } from '../../theme_new/styled'

export interface InputFieldProps extends React.InputHTMLAttributes<any> {
  addon?: string
  decimals?: number
  hasWarning?: boolean
  isInvalid?: boolean
  error?: string
  label?: string
  icon?: string
  note?: string
  fit?: TWindowSize
  isNoteVisible?: boolean
}

const InputField = ({
  addon,
  decimals = 0,
  hasWarning = false,
  isInvalid,
  error,
  label,
  icon,
  note,
  fit,
  isNoteVisible,
  ...inputProps
}: InputFieldProps) => {
  const { windowSize } = useContext(WindowContext)

  if (decimals && inputProps.type !== 'number') {
    console.warn(
      'You are using decimals with a field that is not of type number. Please reconsider!',
    )
  }

  /**
   * Prevents changing the value of the input field by scrolling while the
   * cursor is over it.
   */
  const handleOnWheel = (event: WheelEvent) => {
    if (document.activeElement === event.target) {
      event.preventDefault()
    }
  }

  const resolveHint = () => {
    if (note === ' ') {
      return <>&nbsp;</>
    }

    return note
  }

  return (
    <McInput
      fit={fit || windowSize}
      {...(addon ? { suffix: addon } : {})}
      {...(icon ? { icon: icon, iconposition: 'right' } : {})}
      {...(!label ? { hiddenlabel: true } : {})}
      name={inputProps.id}
      type={inputProps.type as 'text' | 'number'}
      value={inputProps.value?.toString()}
      placeholder={inputProps.placeholder}
      // @ts-ignore
      blur={inputProps.onBlur}
      // @ts-ignore
      input={inputProps.onChange}
      invalid={isInvalid}
      disabled={inputProps.disabled}
      errormessage={error}
      onwheel={handleOnWheel}
      data-e2e={inputProps.id}
    >
      {label && (
        <span slot='label' style={{ display: 'inline-flex' }}>
          {label}&nbsp;
          {hasWarning && (
            <McIcon
              className='mc-icon--warning'
              icon='exclamation-triangle-solid'
              size={windowSize === 'small' ? '20' : '24'}
            />
          )}
        </span>
      )}
      <span slot='hint'>
        {isNoteVisible === false ? <>&nbsp;</> : resolveHint() || <>&nbsp;</>}
      </span>
    </McInput>
  )
}

export default InputField
