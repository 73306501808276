import EfficiencyResultCell from './EfficiencyResultCell'
import { getDifference } from './EnginePerformanceTestReportsComparison.utils'

type Props = {
  reportsCompared: Array<GandalfApi.MetcReport>
}

const MainEngineEfficiency = ({ reportsCompared }: Props) => {
  const a = reportsCompared[0].data
  const b = reportsCompared[1].data

  return (
    <>
      <tr>
        <th>Main Engine Efficiency</th>
        <th />
        <th />
        <th />
      </tr>
      <tr>
        <td>Indicated power [kW]</td>
        {reportsCompared.map((rc) => (
          <td key={rc.reportId} className='mds-table__cell--number'>
            <EfficiencyResultCell
              efficiencyResult={rc.data.result.meEfficiency.indicatedPower}
              precision={0}
            />
          </td>
        ))}
        <td className='mds-table__cell--number'>
          {getDifference(
            a.result.meEfficiency.indicatedPower.isoConditionAdjustedValue!,
            b.result.meEfficiency.indicatedPower.isoConditionAdjustedValue!,
          )}
        </td>
      </tr>
      <tr>
        <td>Mechanical efficiency [%]</td>
        {reportsCompared.map((rc) => (
          <td key={rc.reportId} className='mds-table__cell--number'>
            <EfficiencyResultCell
              efficiencyResult={
                rc.data.result.meEfficiency.mechanicalEfficiency
              }
              precision={1}
            />
          </td>
        ))}
        <td className='mds-table__cell--number'>
          {getDifference(
            a.result.meEfficiency.mechanicalEfficiency
              .isoConditionAdjustedValue!,
            b.result.meEfficiency.mechanicalEfficiency
              .isoConditionAdjustedValue!,
          )}
        </td>
      </tr>
      <tr>
        <td>SFOC [g/kWh]</td>
        {reportsCompared.map((rc) => (
          <td key={rc.reportId} className='mds-table__cell--number'>
            <EfficiencyResultCell
              efficiencyResult={rc.data.result.meEfficiency.sfoc}
              precision={2}
            />
          </td>
        ))}
        <td className='mds-table__cell--number'>
          {getDifference(
            a.result.meEfficiency.sfoc.isoConditionAdjustedValue!,
            b.result.meEfficiency.sfoc.isoConditionAdjustedValue!,
          )}
        </td>
      </tr>
      <tr>
        <td>Pcomp [bar]</td>
        {reportsCompared.map((rc) => (
          <td key={rc.reportId} className='mds-table__cell--number'>
            <EfficiencyResultCell
              efficiencyResult={rc.data.result.meEfficiency.pcomp}
              precision={2}
            />
          </td>
        ))}
        <td className='mds-table__cell--number'>
          {getDifference(
            a.result.meEfficiency.pcomp.isoConditionAdjustedValue!,
            b.result.meEfficiency.pcomp.isoConditionAdjustedValue!,
          )}
        </td>
      </tr>
      <tr>
        <td>Pmax [bar]</td>
        {reportsCompared.map((rc) => (
          <td key={rc.reportId} className='mds-table__cell--number'>
            <EfficiencyResultCell
              efficiencyResult={rc.data.result.meEfficiency.pmax}
              precision={2}
            />
          </td>
        ))}
        <td className='mds-table__cell--number'>
          {getDifference(
            a.result.meEfficiency.pmax.isoConditionAdjustedValue!,
            b.result.meEfficiency.pmax.isoConditionAdjustedValue!,
          )}
        </td>
      </tr>
      <tr>
        <td>Pressure rise (Pmax - Pcomp) [bar]</td>
        {reportsCompared.map((rc) => (
          <td key={rc.reportId} className='mds-table__cell--number'>
            <EfficiencyResultCell
              efficiencyResult={rc.data.result.meEfficiency.pressureRise}
              precision={2}
            />
          </td>
        ))}
        <td className='mds-table__cell--number'>
          {getDifference(
            a.result.meEfficiency.pressureRise.isoConditionAdjustedValue!,
            b.result.meEfficiency.pressureRise.isoConditionAdjustedValue!,
          )}
        </td>
      </tr>
      <tr>
        <td>SFOC due to Pmax deviation [g/kWh]</td>
        {reportsCompared.map((rc) => (
          <td key={rc.reportId} className='mds-table__cell--number'>
            <EfficiencyResultCell
              efficiencyResult={rc.data.result.meEfficiency.sfocDuePmax}
              precision={2}
            />
          </td>
        ))}
        <td className='mds-table__cell--number'>
          {getDifference(
            a.result.meEfficiency.sfocDuePmax.isoConditionAdjustedValue!,
            b.result.meEfficiency.sfocDuePmax.isoConditionAdjustedValue!,
          )}
        </td>
      </tr>
    </>
  )
}

export default MainEngineEfficiency
