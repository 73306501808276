import styled from 'styled-components'

type FormRowProps = {
  direction?: 'column' | 'row'
  hidden?: boolean
  justify?: 'flex-start' | 'flex-end'
}
const FormRow = styled.div<FormRowProps>`
  display: ${({ hidden }) => (hidden ? 'none' : 'flex')};
  margin-bottom: 16px;
  flex-direction: ${({ direction = 'row' }) => direction};
  justify-content: ${({ justify = 'flex-start' }) => justify};

  h3 {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 16px;
    border-bottom: 1px solid #e7e8e9;
    padding-bottom: 8px;
  }

  > div {
    flex: 1 1 auto;
    margin: 8px;

    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }

    > div {
      max-width: 225px;
    }
    > textarea {
      max-width: 335px;
    }
  }
`

export default FormRow
