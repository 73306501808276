import { useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  getCoreRowModel,
  getFacetedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  type Row,
  type SortingState,
  useReactTable,
} from '@tanstack/react-table'

import * as FrontendApi from '../../api-models/frontend'
import { PadContent } from '../../layout/styles'
import Table, { type ITableRef } from '../../components/Table/Table'
import { columns } from './helpers'

type Props = {
  data: Array<FrontendApi.VesselsOverviewData>
}

const VesselsOverviewTable = ({ data }: Props) => {
  const history = useHistory()

  const [sorting, setSorting] = useState<SortingState>([
    { desc: false, id: 'vesselName' },
  ])

  const tableRef = useRef<ITableRef>(null)

  const table = useReactTable({
    data,
    columns,
    initialState: {
      pagination: { pageSize: 25 },
    },
    state: { sorting },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
  })

  const handleRowClick = (row: Row<FrontendApi.VesselsOverviewData>) => {
    history.push(`/MaerskStarConnect/vessel/${row.original.imoNo}/overview`)
  }

  return (
    <PadContent>
      <Table
        table={table}
        onRowSelect={handleRowClick}
        maxFit='small'
        ref={tableRef}
      />
    </PadContent>
  )
}

export default VesselsOverviewTable
