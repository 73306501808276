import styled from 'styled-components'

import { PadContent } from '../../../layout/styles'

export const Content = styled(PadContent)`
  position: relative;

  > mc-popover {
    position: absolute;
    right: 0;
  }
`

export const ChartContainer = styled.div`
  margin: 40px 0 48px;
`

export const CheckboxWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`
