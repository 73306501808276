import { useContext, useMemo } from 'react'
import { useFormikContext } from 'formik'

import FormSection from '../../../../components/form/section'
import { UNITS } from '../../../../utils/constants'
import ElectricalConsumersTable from '../../components/electrical-consumers-table'
import ElectricalProducersTable from '../../components/electrical-producers-table'
import FormRow, { SimpleFormRow } from '../../components/form-row'
import HDCInputField from '../../components/hdc-input-field'
import HDCTextareaField from '../../components/hdc-textarea-field'
import OverlayLoader from '../../components/overlay-loader'
import ShorePower from '../../components/ShorePower/ShorePower'
import { HDCContext } from '../../context'
import { HDCReportFormValues } from '../../types'

import {
  flattenFormikErrors,
  getFilteredByStepErrors,
  getFilteredByStepWarnings,
} from '../../utils'
import Notifications from '../../../../components/Notifications/Notifications'
import * as S from '../style'
import { ApiReportType } from '../../../../api-models/hdc/report'

const ElectricalStep = () => {
  const { errors, isSubmitting } = useFormikContext<HDCReportFormValues>()
  const { state: HDCState } = useContext(HDCContext)
  const {
    form: { currentStep, steps, type },
    report,
  } = HDCState

  const warnings = useMemo(
    () =>
      getFilteredByStepWarnings(report!.warnings, type!, steps[currentStep]),
    [currentStep, report, steps, type],
  )

  return (
    <S.Wrapper>
      <Notifications
        alerts={flattenFormikErrors(
          getFilteredByStepErrors(errors, type!, steps[currentStep]),
        )}
        warnings={[...new Set(Object.values(warnings))]}
      />
      <FormSection
        header='Electrical production'
        description='Please confirm/enter the electrical energy production onboard for the report period.'
      >
        <FormRow>
          <ElectricalProducersTable warnings={warnings} />
        </FormRow>
      </FormSection>
      {report?.reportType === ApiReportType.ALONGSIDE &&
        report?.engineRoom.shorePowerPeriods && (
          <FormSection header='Shore power' description=''>
            <ShorePower />
          </FormSection>
        )}
      <FormSection
        header='Electrical consumption'
        description='Please confirm/enter the electrical energy consumption onboard for the report period.'
      >
        <FormRow>
          <ElectricalConsumersTable warnings={warnings} />
        </FormRow>
        <FormRow>
          <HDCInputField
            addon={UNITS.KILO_WATT_HOUR}
            decimals={0}
            hasWarning={!!warnings['engineRoom.reeferEnergy']}
            label='Reefer energy'
            name='engineRoom.reeferEnergy.value'
            placeholder='Reefer energy'
            type='number'
          />
          <HDCInputField
            addon='#'
            decimals={0}
            hasWarning={!!warnings['engineRoom.numberOfReefers']}
            label='Number of reefers'
            name='engineRoom.numberOfReefers.value'
            placeholder='Number of reefers'
            type='number'
          />
        </FormRow>
      </FormSection>
      <FormSection header='Report comments'>
        <SimpleFormRow>
          <HDCTextareaField
            name='engineRoom.comment'
            label='Additional reporting comments'
          />
        </SimpleFormRow>
      </FormSection>
      {isSubmitting && <OverlayLoader padding='0px' />}
    </S.Wrapper>
  )
}

export default ElectricalStep
