import { useContext, useState } from 'react'
import {
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  type SortingState,
  useReactTable,
} from '@tanstack/react-table'
import styled from 'styled-components'
import { McButton } from '@maersk-global/mds-react-wrapper'

import {
  getColumns,
  getInitialDateRange,
} from './EnginePerformanceTestReportsCard.utils'

import EnginePerformanceTestReportsComparison from '../EnginePerformanceTestReportsComparison/EnginePerformanceTestReportsComparison'
import EnginePerformanceTestReportsFilters from './EnginePerformanceTestReportsFilters/EnginePerformanceTestReportsFilters'
import { VesselPageContext, WindowContext } from '../../../contexts'
import Table from '../../../components/Table/Table'
import { ContentCard } from '../../../commons'
import { PadContent } from '../../../layout/styles'
import { useGetVesselClassMetcReportSummaries } from '../../../queries/GandalfApi/GandalfApi'
import { getSisterVesselImoList } from '../ClassComparisonPage.utils'
import { useVesselConfiguration } from '../../../hooks'

type Props = {
  sisterVesselsData: MasterDataApi.SisterVessel.SisterVesselResponse
}

const EnginePerformanceTestReportsCard = ({ sisterVesselsData }: Props) => {
  const { windowSize } = useContext(WindowContext)
  const imoNo = useContext(VesselPageContext).imoNo!
  const vesselConfig = useVesselConfiguration(imoNo)

  const sisterVesselImoList = getSisterVesselImoList(sisterVesselsData)

  const getVesselClassMetcReportSummaries =
    useGetVesselClassMetcReportSummaries(
      sisterVesselImoList,
      getInitialDateRange(),
    )

  const [sorting, setSorting] = useState<SortingState>([
    { desc: true, id: 'start' },
  ])
  const [selectedReports, setSelectedReports] = useState<Set<string>>(new Set())
  const [isComparing, setIsComparing] = useState(false)

  const handleReportSelected = (reportId: string, isChecked: boolean) => {
    const newSelectedReports = new Set(selectedReports)
    if (isChecked) {
      if (newSelectedReports.size === 2) {
        newSelectedReports.delete([...newSelectedReports][0])
      }
      newSelectedReports.add(reportId)
    } else {
      newSelectedReports.delete(reportId)
    }
    setSelectedReports(newSelectedReports)
  }

  const handleCompareEnd = () => {
    setIsComparing(false)
  }

  const table = useReactTable({
    data: getVesselClassMetcReportSummaries.data || [],
    columns: getColumns(
      windowSize,
      handleReportSelected,
      selectedReports,
      sisterVesselsData,
      vesselConfig,
    ),
    initialState: {
      pagination: { pageSize: 10 },
    },
    state: { sorting },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  })

  const reportsIdsForComparison = getVesselClassMetcReportSummaries.data
    ?.filter((r) => selectedReports.has(r.id))
    .map((r) => r.id)

  return (
    <>
      <ContentCard id='svc__engine-test-reports' title='' hideHeader>
        <PadContent>
          <h3>Main Engine Test Reports</h3>
          <p>
            Below you can see and compare Main Engine Test Reports created by
            this class for the last 12 months.
          </p>
        </PadContent>
        <PadContent>
          <EnginePerformanceTestReportsFilters table={table} />
          {getVesselClassMetcReportSummaries.isSuccess && (
            <Table table={table} />
          )}
          <CompareBtnWrapper>
            <McButton
              fit={windowSize}
              label='Compare'
              disabled={selectedReports.size < 2}
              click={() => setIsComparing(true)}
            />
          </CompareBtnWrapper>
        </PadContent>
      </ContentCard>
      {reportsIdsForComparison && isComparing && (
        <EnginePerformanceTestReportsComparison
          reportIds={reportsIdsForComparison}
          onCompareEnd={handleCompareEnd}
          sisterVesselsData={sisterVesselsData}
        />
      )}
    </>
  )
}

const CompareBtnWrapper = styled.div`
  padding: 0 8px;
  margin-top: -48px;
`

export default EnginePerformanceTestReportsCard
