import { InitialRobBatch } from '../../models'

const valueExist = (value) =>
  typeof value !== 'undefined' && value !== null && value !== ''
export const hasAllRequiredBatchParams = (batch: InitialRobBatch): boolean => {
  const topLevelProps = ['timestamp', 'quantityPerChiefEngineer']
  const fuelProps = ['grade', 'type', 'isDistillate', 'bioPercentage']
  const deliveryDetailsProps = [
    'orderId',
    'portCode',
    'quantityOrdered',
    'quantityAgreed',
  ]
  return (
    topLevelProps.every((prop) => valueExist(batch[prop])) &&
    fuelProps.every((prop) => valueExist(batch.fuel[prop])) &&
    deliveryDetailsProps.every((prop) =>
      valueExist(batch.deliveryDetails[prop]),
    )
  )
}
