import moment from 'moment'

import { DATE_TIME_FORMAT } from '../formik/InputDateTime/InputDateTime.utils'
import { displayTemSubmitModal } from '../../utils'
import { NotificationState } from '../../queries/NotificationsApi/NotificationsApi.consts'

export const handleDismissAdvice = async (
  notification: NotificationsAPI.TemAdvice,
): Promise<NotificationsAPI.NotificationResponsePayload | null> => {
  const submitResponse = await displayTemSubmitModal(notification)

  if (!submitResponse) return null

  const formattedDateTime = moment
    .utc(`${submitResponse.date} ${submitResponse.time}`, DATE_TIME_FORMAT)
    .toISOString()

  const notificationResponseData: NotificationsAPI.Tem.Response = {
    reason: submitResponse.reason as NotificationsAPI.Tem.DismissalReason,
    comment: submitResponse.comment,
    noSparePartsOnBoard: submitResponse.noSparePartsOnBoard,
  }

  return {
    notificationId: notification.id,
    state: NotificationState.Dismissed,
    data: JSON.stringify(notificationResponseData),
    ends_at: formattedDateTime,
  }
}

export const getVoyageLegDisplayString = (
  notifications: Array<NotificationsAPI.TemAdvice> | undefined,
  terminals: Array<MasterDataApi.Common.Terminal> | undefined,
): string => {
  if (!notifications || notifications.length === 0) return ''

  const { portCall } = notifications[0].data
  if (!portCall) return ''

  const departureTerminalCode = portCall.departureTerminalCode
  const arrivalTerminalCode = portCall.arrivalTerminalCode

  if (!departureTerminalCode || !arrivalTerminalCode) return ''

  const departureTerminalName =
    terminals?.find((terminal) => terminal.data.code === departureTerminalCode)
      ?.data.name ?? ''

  const arrivalTerminalName =
    terminals?.find((terminal) => terminal.data.code === arrivalTerminalCode)
      ?.data.name ?? ''

  const arrivalDate = moment
    .utc(portCall.arrivalAt)
    .format('HH:mm MMM DD YYYY [UTC]')

  return `${departureTerminalName} (${departureTerminalCode}) → ${arrivalTerminalName} (${arrivalTerminalCode}) ${arrivalDate}`
}
