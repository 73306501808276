import type { IStockNotification, Period } from './common'
import type { UserEntryTypes } from '../../features/stock-management'
import type { FuelType } from '../../utils'

export interface UserEntry {
  type: UserEntryTypes
  id: string
  timestamp: string
  fuelType: FuelType
  batchName: string | null
  added: number | null
  subtracted: number | null
  hasNotes: boolean
  readonly: boolean
}

export interface Startup {
  robTimestamp: string
  entries: StartupEntry[]
  fuelTypeSelections: FuelTypeSelection[]
}

export interface FuelTypeSelection {
  fuelType: number
  fuelLineType: number
}

export interface StartupEntry {
  batch: BunkeredBatch
  rob: number
  selected: boolean
}

export interface RecoveryRequest {
  timestamp: string
  batches: RecoveryBatch[]
  fuelTypeSelections: FuelTypeSelection[]
}

export interface RecoveryBatch {
  id: string
  rob: number
  selected: boolean
}

export interface StockEntry {
  id: string
  readonly: boolean
  created: string
  updated: string
}

export interface BatchCommons {
  timestamp: string
  displayName: string
  fuel: Fuel
}

export interface Fuel {
  grade: string
  type: number
  isDistillate: boolean
  bioPercentage: number
}

export interface BatchResponse extends BatchCommons {
  id: string
  type: number
  hasLabReport: boolean
  quantityPerChiefEngineer: number
  hasNotes: boolean
  isSelected: boolean
}

export interface BunkeredBatch extends BatchCommons {
  quantityPerChiefEngineer: number
  deliveryDetails: DeliveryDetails
  labReport: BunkeredBatchLabReport
  notes: string
}

export interface BunkeredBatchResponse extends BunkeredBatch {
  id: string
  readonly: boolean
  labReportReadonly: boolean
  isOnBoard: boolean
  created: string
  updated: string
}

export interface DeliveryDetails {
  orderId: string
  portCode: string
  quantityOrdered: number
  quantityAgreed: number
}

interface LabReportCommons {
  density15: number | null
  lcv: number | null
  water: number | null
  ash: number | null
  sulphur: number | null
}

export interface BunkeredBatchLabReport extends LabReportCommons {
  marpolSeal: string | null
}

export interface MixedBatch {
  id: string
  quantity: number
}

export interface MixedBatchEntryRequest {
  timestamp: string
  batches: Array<MixedBatch>
  notes: string
}

export interface MixedBatchEntryUpdateRequest {
  timestamp: string
  batches: Array<MixedBatch>
  fuel: Fuel
  labReport?: MixedBatchLabReport
  notes: string
}

export interface MixedBatchEntryResponse extends BatchCommons {
  id: string
  readonly: boolean
  labReportReadonly: boolean
  quantityPerChiefEngineer: number
  labReport?: MixedBatchLabReport
  notes: string
  batches: Array<MixedBatch>
  isOnBoard: boolean
  created: string
  updated: string
}

export interface MixedBatchLabReport extends LabReportCommons {}

export interface BatchDetails {
  orderId: string
  portCode: string
  quantityOrdered: number
  quantityAgreed: number
}

export interface Sounding {
  timestamp: string
  batches: BatchSounding[]
  notes: string
}

export interface SoundingEntry extends Sounding, StockEntry {}

export interface BatchSounding {
  id: string
  rob: number
  adjustment: number
}

export interface Loss {
  timestamp: string
  batchId: string
  destinationBatchId: string | null
  quantity: string
  reasonCode: string
  notes: string
}

export interface LossEntry extends Loss, StockEntry {}

export interface Debunkering {
  timestamp: string
  batchId: string
  quantity: string
  portCode: string
  reasonCode: string
  notes: string
}

export interface DebunkeringEntry extends Debunkering, StockEntry {}

export interface AdjustmentEntry {
  id: string
  type: number
  timestamp: string
  fuelType: number
  text: string
  adjustment: number
  additionalProperties: {
    isOnBoard: boolean
    hasLabReport: boolean
  }
}

export interface RobMark {
  timestamp: string
  batches: BatchRob[]
  notes: string
}

export interface RobMarkEntry extends RobMark, StockEntry {}

export interface BatchRob {
  id: string
  rob: number
}

export interface StatusResponse {
  notifications: IStockNotification[]
  rob: {
    queryTimestamp: string
    notifications: IStockNotification[]
    hasData: boolean
    timestamp: string
    robMark: {
      type: number
      timestamp: string
    }
    fuelTypeQuantities: FuelTypeQuantity[]
    batchQuantities: BatchQuantity[]
  }
  burndown?: BurndownModel
  state: number
  recoverableFrom: string
}

export interface BurndownModel {
  openWindow: OpenWindow
  userEntries: Array<UserEntry>
  batches: Array<BatchBurndownModel>
  period: Period
}

export interface BatchBurndownModel {
  batch: BatchResponse
  logs: Array<BatchBurndownLogModel>
}

export enum BatchBurndownEvent {
  /**
   * New batch; bunkered or mixed.
   */
  NewBatch = 10,
  /**
   * Startup / initial ROB.
   */
  Startup = 20,
  /**
   * Recovery.
   */
  Recovery = 21,
  /**
   * Batch has been sounded.
   */
  Sounding = 25,
  /**
   * Batch has been mixed; fully or partly.
   */
  Mixed = 30,
  /**
   * Loss to/from batch.
   */
  Loss = 31,
  /**
   * Batch has been debunkered; fully or partly.
   */
  Debunkering = 32,
  /**
   * Change over start.
   */
  Start = 50,
  /**
   * Change over stop.
   */
  Stop = 51,
  /**
   * Error, e.g. batch depleted
   */
  Error = 100,
}

export interface BatchBurndownLogModel {
  timestamp: string

  /**
   * ROB in [MT]
   */
  rob: number

  /**
   * ROB from which batch was adjusted, e.g. a loss, in [MT], `null` if batch was not adjusted.
   */
  fromRob: number | null

  /**
   * Events associated with the log, empty array if no events exists.
   * @see BatchBurndownEvent
   */
  events: BatchBurndownEvent[]
}

export interface RobResponse {
  hasData: boolean
  queryTimestamp: string
  notifications: IStockNotification[]
  timestamp: string
  fuelTypeQuantities: FuelTypeQuantity[]
  batchQuantities: BatchQuantity[]
}

export interface FuelTypeQuantity {
  fuelType: number
  quantity: number
}

export interface BatchQuantity {
  batch: BatchResponse
  quantity: number
}

export interface LatestRobMark {
  id: string
  timestamp: string
}

export interface OpenWindow {
  period: Period
}

export interface RobAlerts {
  timestamp: string
  fuelType: number
  message: string
}

export interface BatchSelection {
  timestamp: string
  batchId: string
  previousBatchDepleted?: boolean
  remainingOfPreviousBatch?: number
}

export interface BatchSelectionResponse extends StockEntry {
  timestamp: string
  batchId: string
  previousBatchDepleted: boolean
  remainingOfPreviousBatch: number
  notes: string
}

export interface CurrentBatchSelectionResponse {
  id: string
  timestamp: string
  fuelType: number
  batchId: string
  displayName: string
  previousBatchDepleted: boolean
  remainingOfPreviousBatch: number
}
