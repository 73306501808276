import * as yup from 'yup'
import { BridgeFormStep, EngineRoomFormStep, HDCFormType } from '../types'
import voyageValidationSchema from './voyage/voyage'
import propulsionValidationSchema from './engineRoom/propulsion'
import electricalValidationSchema from './engineRoom/electrical'
import consumptionValidationSchema from './engineRoom/consumption'
import { ApiReportType } from '../../../api-models/hdc/report'

const getPartialValidationSchema = (
  form: HDCFormType | null,
  step: BridgeFormStep | EngineRoomFormStep,
  reportType?: ApiReportType,
): yup.ObjectSchema<any> | null => {
  if (HDCFormType.BRIDGE_FORM === form) {
    if (step === BridgeFormStep.VOYAGE || step === BridgeFormStep.SUMMARY) {
      return voyageValidationSchema
    }
  }
  if (HDCFormType.ENGINE_FORM === form) {
    if (step === EngineRoomFormStep.PROPULSION) {
      return propulsionValidationSchema
    } else if (step === EngineRoomFormStep.ELECTRICAL) {
      return electricalValidationSchema(reportType)
    } else if (step === EngineRoomFormStep.CONSUMPTION) {
      return consumptionValidationSchema
    } else if (step === EngineRoomFormStep.SUMMARY) {
      return propulsionValidationSchema
        .concat(electricalValidationSchema(reportType))
        .concat(consumptionValidationSchema)
    }
  }
  return yup.object().shape({})
}

export default getPartialValidationSchema
