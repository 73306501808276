export const NotificationState: Record<
  NotificationsAPI.NotificationState,
  NotificationsAPI.NotificationState
> = {
  Active: 'Active',
  Followed: 'Followed',
  Dismissed: 'Dismissed',
  Inactive: 'Inactive',
  TimedOut: 'TimedOut',
  Closed: 'Closed',
} as const

export const NotificationStateDisplay: Record<
  NotificationsAPI.NotificationState,
  string
> = {
  Active: 'Active',
  Followed: 'Followed',
  Dismissed: 'Dismissed',
  Inactive: 'Inactive',
  TimedOut: 'Timed out',
  Closed: 'Closed',
} as const

export const NotificationCategories = {
  Voyage: 'Voyage',
  Energy: 'Energy',
} as const

export const NotificationTypes: {
  [K in NotificationsAPI.NotificationType]: K
} = {
  EPA: 'EPA',
  NPS: 'NPS',
  MESFOC: 'MESFOC',
} as const

export const MeSfocDataVersion = {
  current: 1,
} as const

export const meSfocAlertsQueryFilter: NotificationsAPI.NotificationsQueryFilter =
  {
    category: NotificationCategories.Energy,
    type: NotificationTypes.MESFOC,
  }

export const activeMeSfocAlertsQueryFilter: NotificationsAPI.NotificationsQueryFilter =
  {
    ...meSfocAlertsQueryFilter,
    state: NotificationState.Active,
  }
