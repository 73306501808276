import { useContext } from 'react'

import { ApiReportType } from '../../../api-models/hdc/report'
import { Loading, Modal, Tabs } from '../../../commons'
import Notifications from '../../../components/Notifications/Notifications'
import BridgeSummary from '../components/bridge-summary'
import EngineRoomSummary from '../components/engine-room-summary'
import { ReportTypeNames } from '../constants'
import { HDCContext } from '../context'
import { BridgeFormStep, EngineRoomFormStep, HDCFormType } from '../types'
import { getFilteredByStepWarnings, prettyDuration } from '../utils'
import * as S from './style'

type Props = {
  handleClose: Function
  isVisible: boolean
}

const SummaryModal = ({ handleClose, isVisible }: Props) => {
  const {
    state: { report, reports, reportType },
  } = useContext(HDCContext)

  const title = report
    ? `${ReportTypeNames[report.reportType]} - No. ${
        report.seqNo
      } - Duration ${prettyDuration(
        report.periodStart,
        report.periodEnd,
      )} - From ${report.periodStart
        .utc()
        .format('DD MMM YYYY HH:mm')} UTC - To ${report.periodEnd
        .utc()
        .format('DD MMM YYYY HH:mm')} UTC`
    : '-'

  const warnings = report
    ? {
        ...getFilteredByStepWarnings(
          report.warnings,
          HDCFormType.BRIDGE_FORM,
          BridgeFormStep.SUMMARY,
        ),
        ...getFilteredByStepWarnings(
          report.warnings,
          HDCFormType.ENGINE_FORM,
          EngineRoomFormStep.SUMMARY,
        ),
      }
    : {}

  return (
    <Modal closeHandler={handleClose} title={title} visible={isVisible}>
      {!report || !reports ? (
        <Loading />
      ) : (
        <>
          <S.FormNotificationsWrapper>
            <Notifications warnings={[...new Set(Object.values(warnings))]} />
          </S.FormNotificationsWrapper>
          <S.SummaryModalContentWrapper>
            <Tabs
              startIndex={reportType === ApiReportType.ALONGSIDE ? 1 : 0}
              tabButtons={[
                {
                  hide: reportType === ApiReportType.ALONGSIDE,
                  name: 'Bridge report',
                },
                {
                  name: 'Engine room report',
                },
              ]}
            >
              <S.TabWrapper>
                <BridgeSummary
                  bridgeEvents={report.bridge.events || []}
                  engineRoomEvents={report.engineRoom.events || []}
                  report={report}
                  reports={reports}
                />
              </S.TabWrapper>
              <S.TabWrapper>
                <EngineRoomSummary
                  bridgeEvents={report.bridge.events || []}
                  engineRoomEvents={report.engineRoom.events || []}
                  report={report}
                />
              </S.TabWrapper>
            </Tabs>
          </S.SummaryModalContentWrapper>
        </>
      )}
    </Modal>
  )
}

export default SummaryModal
