import React, { useContext } from 'react'
import { useFormikContext } from 'formik'

import {
  TableContainer,
  TableHeadingCol,
  TableValueCol,
} from './GenaralTab.styles'

import { recalculateResults } from '../EnginePerformanceTestReportPage.utils'
import Legend from '../Common/Legend/Legend'
import MetcInputField from '../Common/MetcInputField/MetcInputField'
import MetcTextareaField from '../Common/MetcTextArea'
import MetcCheckbox from '../Common/MetcCheckbox/MetcCheckbox'
import MetcReadonlyInputField from '../Common/MetcReadonlyInputField/MetcReadonlyInputField'
import { WindowContext } from '../../../../contexts'
import { formatValue, isShoreContext } from '../../../../utils'

const GeneralTab = () => {
  const { windowSize } = useContext(WindowContext)

  const ctx = useFormikContext<GandalfApi.MetcReport>()
  const { data } = ctx.values

  const _recalculateResults = () => {
    if (isShoreContext()) return
    return void recalculateResults(ctx)
  }

  return (
    <div slot='panel'>
      <Legend />
      <TableContainer
        className={`mds-table mds-table--${windowSize} mds-table--disable-row-highlight-on-hover`}
      >
        <table>
          <colgroup>
            <TableHeadingCol />
            <TableValueCol />
          </colgroup>
          <thead>
            <tr>
              <th>Operating conditions</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Engine shaft power</td>
              <td>
                <MetcInputField
                  name={'data.general.enginePower'}
                  unit='kW'
                  onBlur={_recalculateResults}
                />
              </td>
            </tr>
            <tr>
              <td>Engine load</td>
              <td>
                <MetcReadonlyInputField
                  value={formatValue(data.result.general.engineLoad, 2)}
                  unit='%MCR'
                />
              </td>
            </tr>
            <tr>
              <td>Engine RPM</td>
              <td>
                <MetcInputField
                  name={'data.general.shaftRpm'}
                  unit='RPM'
                  onBlur={_recalculateResults}
                />
              </td>
            </tr>
            <tr>
              <td>Engine running hours</td>
              <td>
                <MetcInputField
                  name={'data.general.engineRunningHours'}
                  unit='hours'
                />
              </td>
            </tr>
            {data.equipment.hasTcco && (
              <tr>
                <td>TCCO operation</td>
                <td>
                  <MetcCheckbox
                    name='data.general.tccoEngaged'
                    label='Engaged'
                    onChange={_recalculateResults}
                  />
                </td>
              </tr>
            )}
            <tr>
              <td>Auxiliary blowers</td>
              <td>
                <MetcCheckbox
                  name='data.general.auxBlowersOn'
                  label='Engaged'
                />
              </td>
            </tr>
            {data.equipment.hasWhr && (
              <tr>
                <td>Exhaust gas bypass valve position</td>
                <td>
                  <MetcInputField
                    name={'data.general.exhaustBypassPosition'}
                    unit='%'
                  />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </TableContainer>
      <TableContainer
        className={`mds-table mds-table--${windowSize} mds-table--disable-row-highlight-on-hover`}
      >
        <table>
          <colgroup>
            <TableHeadingCol />
            <TableValueCol />
          </colgroup>
          <thead>
            <tr>
              <th>Fuel</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Fuel batch</td>
              <td
                style={{
                  textAlign: 'end',
                  lineHeight: 2.2,
                }}
              >
                {data.general.batchName}
              </td>
            </tr>
            <tr>
              <td>Fuel LCV</td>
              <td>
                <MetcReadonlyInputField
                  value={formatValue(data.general.batchLcv, 0)}
                  unit='kJ/kg'
                />
              </td>
            </tr>
            <tr>
              <td>Fuel CCAI</td>
              <td>
                <MetcInputField name={'data.general.batchCcai'} />
              </td>
            </tr>
            <tr>
              <td>Fuel consumption</td>
              <td>
                <MetcReadonlyInputField
                  value={formatValue(data.general.consumedMass.value, 0)}
                  unit='kg'
                />
              </td>
            </tr>
            <tr>
              <td>SFOC measured</td>
              <td>
                <MetcReadonlyInputField
                  value={formatValue(data.result.general.sfoc, 2)}
                  unit='g/kWh'
                />
              </td>
            </tr>
            <tr>
              <td>SFOC LCV Corrected to 42700 kg/kJ</td>
              <td>
                <MetcReadonlyInputField
                  value={formatValue(data.result.general.sfocLcvCorrected, 2)}
                  unit='g/kWh'
                />
              </td>
            </tr>
          </tbody>
        </table>
      </TableContainer>
      <TableContainer
        className={`mds-table mds-table--${windowSize} mds-table--disable-row-highlight-on-hover`}
      >
        <table>
          <colgroup>
            <TableHeadingCol />
            <TableValueCol />
          </colgroup>
          <thead>
            <tr>
              <th>Load condition</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Ambient pressure</td>
              <td>
                <MetcInputField
                  name={'data.general.erAmbientPres'}
                  unit='mbar'
                  onBlur={_recalculateResults}
                />
              </td>
            </tr>
            <tr>
              <td>Scavenging air temperature</td>
              <td>
                <MetcInputField
                  name={'data.general.scavAirTemp'}
                  unit='°C'
                  onBlur={_recalculateResults}
                />
              </td>
            </tr>
            <tr>
              <td>Scavenging air pressure</td>
              <td>
                <MetcInputField
                  name={'data.general.scavAirPres'}
                  unit='bar'
                  onBlur={_recalculateResults}
                />
              </td>
            </tr>
            <tr>
              <td>Exhaust gas receiver pressure</td>
              <td>
                <MetcInputField
                  name={'data.general.exhaustGasReceiverPres'}
                  unit='bar'
                  onBlur={_recalculateResults}
                />
              </td>
            </tr>
            <tr>
              <td>Blower inlet temperature</td>
              <td>
                <MetcInputField
                  name={'data.general.blowerInletTemp'}
                  unit='°C'
                  onBlur={_recalculateResults}
                />
              </td>
            </tr>
            <tr>
              <td>Turbine back pressure</td>
              <td>
                <MetcInputField
                  name={'data.general.turbineBackPres'}
                  unit='mmH2O'
                  onBlur={_recalculateResults}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </TableContainer>
      <MetcTextareaField label='Comment' name='data.comment' />
    </div>
  )
}

export default GeneralTab
