import { useContext, useMemo } from 'react'
import { AppContext } from '../contexts'
import { isShoreContext } from '../utils'
import { Sensors } from '../api-models/sensors'

export function useSensorStatus(
  imoNo,
): Sensors.VesselSensorsStatus | undefined {
  const appContext = useContext(AppContext)
  return useMemo(() => {
    if (isShoreContext()) {
      return appContext.sensorsStatuses
        ? appContext.sensorsStatuses.find(
            (status: Sensors.VesselSensorsStatus) =>
              status.imoNo.toString() === imoNo,
          )
        : undefined
    }
    return appContext.sensorsStatus //vessel context
  }, [imoNo, appContext.sensorsStatuses, appContext.sensorsStatus])
}
