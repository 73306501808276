import { type FunctionComponent } from 'react'
import { createColumnHelper } from '@tanstack/react-table'
import moment from 'moment'
import { McIcon } from '@maersk-global/mds-react-wrapper'

import {
  Header,
  IconCell,
  NumericCell,
  NumericHeader,
} from '../../../components/Table/Table.styles'
import { FUEL_OIL_USER_ENTRY_TYPE_NAMES } from '../../../features/stock-management/models'
import { FUEL_TYPE_NAMES } from '../../../utils'
import type { UserEntry } from '../../../api-models/performance/fuel-oil-stock'
import { dateFilter } from '../ActivityLogFilters/DateFilter/helpers'
import { entryTypeFilter } from '../ActivityLogFilters/EntryTypeFilter/helpers'
import { fuelTypeFilter } from '../ActivityLogFilters/FuelTypeFilter/helpers'
import {
  BunkeringModal,
  DebunkeringModal,
  FuelLineChangeModal,
  LossModal,
  MixedBatchModal,
  SoundingModal,
  UserEntryTypes,
} from '../../../features/stock-management'
import { RobMarkInfoModal } from '../../../features/stock-management/components/rob/RobMarkInfoModal'
import { EditBatchSelectionModal } from '../../../features/fdl-4'

const columnHelper = createColumnHelper<UserEntry>()

export const columns = [
  columnHelper.accessor('timestamp', {
    cell: (info) => moment.utc(info.getValue()).format('DD MMM YYYY HH:mm'),
    header: 'Entered',
    filterFn: dateFilter,
  }),
  columnHelper.accessor((row) => FUEL_OIL_USER_ENTRY_TYPE_NAMES[row.type], {
    id: 'type',
    header: 'Entry type',
    filterFn: entryTypeFilter,
  }),
  columnHelper.accessor((row) => FUEL_TYPE_NAMES[row.fuelType], {
    id: 'fuelType',
    header: 'Fuel type',
    filterFn: fuelTypeFilter,
  }),
  columnHelper.accessor('batchName', {
    cell: (info) => info.getValue(),
    header: 'Batch name',
  }),
  {
    id: 'quantity',
    accessorFn: (row) => row.added || (row.subtracted && -row.subtracted),
    header: () => <NumericHeader>+/– (MT)</NumericHeader>,
    cell: (props) => {
      let str: string | number = '-'
      if (typeof props.getValue() === 'number') {
        str = props.getValue() > 0 ? `+${props.getValue()}` : props.getValue()
      }
      return <NumericCell>{str}</NumericCell>
    },
  },
  columnHelper.display({
    id: 'hasNotes',
    header: () => <Header icon>Notes</Header>,
    cell: (props) =>
      props.cell.row.original['hasNotes'] ? (
        <IconCell>
          <McIcon icon='comments' />
        </IconCell>
      ) : null,
  }),
  columnHelper.display({
    id: 'action',
    header: () => <Header icon>Action</Header>,
    cell: (props) => (
      <IconCell>
        {props.cell.row.original['readonly'] ? 'View' : 'Edit'}
      </IconCell>
    ),
  }),
]

type TEntryModal = FunctionComponent<{
  entryId: string
  closeHandler: () => void
  submit: () => void
  userEntryType?: UserEntryTypes
  readonly: boolean
}>

export const entryModals: Record<UserEntryTypes, TEntryModal> = {
  [UserEntryTypes.BunkeredBatch]: BunkeringModal,
  [UserEntryTypes.MixedBatch]: MixedBatchModal,
  [UserEntryTypes.StartupRobMark]: RobMarkInfoModal,
  [UserEntryTypes.Sounding]: SoundingModal,
  [UserEntryTypes.Loss]: LossModal,
  [UserEntryTypes.Debunkering]: DebunkeringModal,
  [UserEntryTypes.RecoveryRobMark]: RobMarkInfoModal,
  [UserEntryTypes.BatchSelection]: EditBatchSelectionModal,
  [UserEntryTypes.MEFuelLineSelection]: FuelLineChangeModal,
  [UserEntryTypes.AEMainFuelLineSelection]: FuelLineChangeModal,
  [UserEntryTypes.AESecondaryFuelLineSelection]: FuelLineChangeModal,
  [UserEntryTypes.AEMDOFuelLineSelection]: FuelLineChangeModal,
  [UserEntryTypes.BoilerMainFuelLineSelection]: FuelLineChangeModal,
  [UserEntryTypes.BoilerMDOFuelLineSelection]: FuelLineChangeModal,
}
