import styled from 'styled-components'

export const ContentWrapper = styled.div`
  margin-top: 32px;

  > mc-select {
    display: inline-block;
    width: 100%;
    margin-bottom: 16px;
  }
`

export const DateTimeWrapper = styled.div`
  position: relative;
  display: flex;
  width: 50%;
  gap: 16px;
  margin-top: 16px;

  > mc-input-date {
    width: 75%;
  }
`

export const Error = styled.div`
  display: inline-flex;
  align-items: center;
  margin-top: 16px;
  color: ${(props) => props.theme.colors.feedback.danger.main};

  mc-icon {
    margin-right: 4px;

    &::part(icon) {
      fill: ${(props) => props.theme.colors.feedback.danger.main};
    }
  }
`

export const Duration = styled.span`
  position: absolute;
  bottom: 8px;
  left: calc(100% + 12px);
  white-space: nowrap;
  color: ${(props) => props.theme.colors.grey[700]};
`
