import { Vessel, Period } from './common'

export interface CounterResponse {
  vessel: Vessel
  hasData: boolean
  queryPeriod: Period
  resultPeriod: Period
  fuelConsumption: FuelConsumption
  mainEngDetails: MainEngDetails
  electricalConsumption: ElectricalConsumption
  electricalProduction: ElectricalProduction
  operationalCondition: OperationalCondition
}

export interface ElectricalConsumption {
  consumers: Consumer[]
  totalEnergy: number
}

export interface Consumer {
  type: number
  energy: number
  runningMinutes: number
  dataAvailability: number
}

export interface ElectricalProduction {
  producers: Consumer[]
  totalEnergy: number
}

export interface FuelConsumption {
  fuelLineDataAvailabilities: FuelLineDataAvailability[]
  fuelTypes: FuelConsumptionFuelType[]
  sludge: Sludge
  leakageMass: number
}

export interface FuelLineDataAvailability {
  fuelLineType: number
  dataAvailability: number
}

export interface FuelConsumptionFuelType {
  fuelType: number
  fuelLines: ConsumedMass[]
  batches: Batch[]
  unknownBatch: Batch
  total: number
  dataAvailability: number
}

export interface Batch {
  batchId?: string
  fuelLines: ConsumedMass[]
  total: number
  dataAvailability: number
}

export interface ConsumedMass {
  fuelLineType: number
  consumedMass: number
  dataAvailability: number
}

export interface Sludge {
  fuelTypes: SludgeFuelType[]
  total: number
}

export interface SludgeFuelType {
  fuelType: number
  value: number
}

export interface MainEngDetails {
  engines: Engine[]
  totalPower: number
}

export interface Engine {
  number: number
  power: DistanceLogged
  shaftRpm: DistanceLogged
  runningMinutes: DistanceLogged
  tccoPercentage: DistanceLogged
  auxBlowerPower?: DistanceLogged
}

export interface DistanceLogged {
  value: number | null
  dataAvailability: number
}

export interface OperationalCondition {
  draughtFore: DistanceLogged
  meanDraught: DistanceLogged
  draughtAft: DistanceLogged
  distanceLogged: DistanceLogged
}
