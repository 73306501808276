import { useContext, useEffect, useMemo, useRef } from 'react'
import { useFormikContext } from 'formik'

import { FormProgressBar, Icon } from '../../../../commons'
import { orange, red } from '../../../../theme'
import { ReportWrapper } from '../../components/styles'
import { HDCContext } from '../../context'
import {
  BridgeFormStep,
  HDCFormType,
  type HDCReportFormValues,
} from '../../types'
import SummaryStep from '../summary-step'
import EventsStep from './events-step'
import VoyageStep from './voyage-step'

const HDCBridgeReport = () => {
  const { setErrors, submitForm } = useFormikContext<HDCReportFormValues>()
  const {
    state: HDCState,
    setFormType,
    setFormCurrentStep,
  } = useContext(HDCContext)
  const {
    currentStep,
    hasUnsavedEvent,
    hasValidationError,
    hasValidationWarning,
    steps,
  } = HDCState.form
  const refHasUnsavedEvent = useRef(hasUnsavedEvent)

  useEffect(() => {
    refHasUnsavedEvent.current = hasUnsavedEvent
  }, [hasUnsavedEvent])

  const handleProgressBarClick = async (stepIndex: number): Promise<void> => {
    // Try to save the progress, when user navigates away from the report step
    if (steps[currentStep] !== BridgeFormStep.SUMMARY) {
      await submitForm()
      setErrors({})
    }
    if (
      steps[currentStep] === BridgeFormStep.EVENTS &&
      refHasUnsavedEvent.current
    ) {
      return
    }
    if (steps[stepIndex] === BridgeFormStep.START) {
      setFormType(null)
      return
    }
    setFormCurrentStep(stepIndex)

    // TODO: We need to allow some time for the step to switch over fully.
    //  Otherwise the subsequent form submission (if any errors exist on the
    //  next step) will run with data from the current step.
    //  NOTE: This is obviously a hack and very bad practice! However, as long
    //  as we're using Formik there's no proper way to fix this.
    await new Promise((resolve) => setTimeout(resolve, 100))

    // If step has already been visited and had validation errors,
    // run the submission flow to highlight the invalid fields
    if (
      steps[stepIndex] !== BridgeFormStep.SUMMARY &&
      hasValidationError[HDCFormType.BRIDGE_FORM][steps[stepIndex]]
    ) {
      submitForm()
    }
  }

  const validationIcons = useMemo(
    (): JSX.Element[] =>
      (steps as BridgeFormStep[]).map((step) => (
        <>
          {(hasValidationError[HDCFormType.BRIDGE_FORM][step] && (
            <Icon
              icon='fal fa-exclamation-circle'
              style={{
                color: red[700],
                marginLeft: '8px',
              }}
            />
          )) ||
            null}
          {(hasValidationWarning[HDCFormType.BRIDGE_FORM][step] && (
            <Icon
              icon='fal fa-exclamation-circle'
              style={{
                color: orange[400],
                marginLeft: '8px',
              }}
            />
          )) ||
            null}
        </>
      )),
    [hasValidationError, hasValidationWarning, steps],
  )

  return (
    <ReportWrapper>
      <FormProgressBar
        icons={validationIcons}
        steps={steps}
        currentStep={currentStep}
        padding='0px 0px 32px'
        onClick={(stepIndex) => handleProgressBarClick(stepIndex)}
      />
      {steps[currentStep] === BridgeFormStep.VOYAGE && <VoyageStep />}
      {steps[currentStep] === BridgeFormStep.EVENTS && <EventsStep />}
      {steps[currentStep] === BridgeFormStep.SUMMARY && <SummaryStep />}
    </ReportWrapper>
  )
}

export default HDCBridgeReport
