export interface Settings {
  estimatedLeakage: number | null
  logs: SettingsLog[]
}
export interface SettingsLog {
  type: number
  timestamp: string
  value: number | string | boolean | null
  previousValue: number | string | boolean | null
}
