import moment from 'moment'
import { Filters, getDateRange, sortDateRange } from '../../features/filter'
import { getDefaultFilters } from '../../utils'

export const filterDataByMaxSetpoint = (
  baselineModel: HydraApi.SfocBaselineModel.BaselineModel,
  maxSetpoint?: number,
) => {
  if (
    !baselineModel.load ||
    !baselineModel.predictedSfoc10Percentile ||
    !maxSetpoint
  ) {
    return baselineModel
  }
  const { load, predictedSfoc10Percentile } = baselineModel
  const index = load.findIndex((value) => value > maxSetpoint)
  if (index === -1) return baselineModel
  const truncatedLoad = load.slice(0, index)
  const truncatedPredictedSfoc10Percentile = predictedSfoc10Percentile.slice(
    0,
    index,
  )

  return {
    load: truncatedLoad,
    predictedSfoc10Percentile: truncatedPredictedSfoc10Percentile,
  }
}

export const getQueryPeriod = (filters: Filters | undefined) => {
  const samplePeriod = 90
  const { to, from } = getDateRange(
    getDefaultFilters(14, 'd').dateRange,
    filters,
  )
  const sortedFilters = sortDateRange(moment.utc(from), moment.utc(to))
  // This is because we always fetch 90 days of data for the scatter chart, but we only show data according to filter in the timeline chart
  const fromDate = moment
    .utc(sortedFilters.to)
    .subtract(moment.duration(samplePeriod, 'days'))
  return {
    to: sortedFilters.to.toISOString(),
    from: fromDate.toISOString(),
  }
}
