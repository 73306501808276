import * as yup from 'yup'

export const batchTransferValidationSchema = yup.object().shape({
  batchId: yup
    .string()
    .required('Change over batch is a required field')
    .test({
      name: 'validBatch',
      test: function (value) {
        const context = this.options?.context as any
        const batchOptions = context.batchOptions as Array<{ value: string }>
        if (batchOptions === undefined) {
          return true
        }
        if (batchOptions.filter((val) => val.value === value).length < 1) {
          return this.createError({
            message: 'Selected batch was not onboard at the time selected',
            path: this.path,
          })
        }
        return true
      },
    }),
  previousBatchDepleted: yup
    .boolean()
    .required('Previous batch depleted is a required field'),
  remainingOfPreviousBatch: yup
    .number()
    .when('previousBatchDepleted', {
      is: false,
      then: yup
        .number()
        .required(
          'Remaining in service/settling tank of previous batch must be entered if batch is not depleted',
        ),
    })
    .when('previousBatchDepleted', {
      is: true,
      then: yup.boolean(),
    }),
  timestamp: yup.date().required('Time of batch changeover is required field'),
})
