import React, { useReducer, useCallback, useEffect, useContext } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { Event } from '../../../api-models/hdc/events'
import {
  ApiReportType,
  HdcReportResponse,
  IReport,
} from '../../../api-models/hdc/report'
import { FuelLineDataLoss } from '../../../api-models/performance/fuel-consumption'
import { Loading } from '../../../commons'
import { VesselPageContext } from '../../../contexts'
import { routerParams } from '../../../routes'
import {
  CreateInitialReportPayload,
  getAllReports,
  getEventTypes,
  getReportById,
  initReport,
} from '../../../services/hdc'
import {
  BridgeFormStep,
  EngineRoomFormStep,
  ESubReport,
  HDCFormType,
} from '../types'
import {
  updateFormCustomDestination,
  updateEvents,
  updateEventTypes,
  updateFuelDataLosses,
} from './actions'
import {
  HDCContext,
  HDCContextReducer,
  resetState,
  setReports,
  updateFormCurrentStep,
  updateFormSteps,
  updateFormType,
  updateHasUnsavedEvent,
  updateHasValidationError,
  updateHasValidationWarning,
  updateReport,
  updateReportTimestamp,
  updateReportType,
  updateInvalidReports,
} from '.'
import { HDCReducerState, ReportTimestamp } from './reducer'

const initialValidationValues = {
  [HDCFormType.BRIDGE_FORM]: Object.values(BridgeFormStep).reduce(
    (result, step) => {
      result[step] = false
      return result
    },
    {} as {
      [step in BridgeFormStep]: boolean
    },
  ),
  [HDCFormType.ENGINE_FORM]: Object.values(EngineRoomFormStep).reduce(
    (result, step) => {
      result[step] = false
      return result
    },
    {} as {
      [step in EngineRoomFormStep]: boolean
    },
  ),
}

export const initialValues: HDCReducerState = {
  reports: undefined,
  report: null,
  reportTimestamp: null,
  reportType: null,
  eventTypes: undefined,
  form: {
    type: null,
    currentStep: 1,
    steps: [],
    hasUnsavedEvent: false,
    hasValidationError: initialValidationValues,
    hasValidationWarning: initialValidationValues,
    useCustomArrival: false,
    useCustomDeparture: false,
  },
  fuelDataLosses: undefined,
  invalidReports: [],
}

export interface HDCContextProviderProps
  extends RouteComponentProps<routerParams> {
  imoNo: string
  children?: React.ReactNode
}

const HDCContextProvider = ({ imoNo, children }: HDCContextProviderProps) => {
  const [state, dispatch] = useReducer(HDCContextReducer, initialValues)

  const initiateReport = useCallback(
    (initialReport: CreateInitialReportPayload) =>
      initReport(imoNo, initialReport),
    [imoNo],
  )

  const getReport = useCallback(
    async (id) => {
      try {
        const response: HdcReportResponse = await getReportById(imoNo, id)
        const report = response.report
        dispatch(updateReport(report))
        return report
      } catch (err) {
        return Promise.reject(err)
      }
    },
    [imoNo],
  )

  const getReports = useCallback(async () => {
    try {
      const [reportsResponse, eventTypesResponse] = await Promise.all([
        getAllReports(imoNo),
        getEventTypes(),
      ])
      const reports: IReport[] = Array.isArray(reportsResponse?.reports)
        ? reportsResponse.reports
        : []
      dispatch(setReports(reports))
      dispatch(
        updateEventTypes(
          Array.isArray(eventTypesResponse?.eventTypes)
            ? eventTypesResponse.eventTypes
            : [],
        ),
      )
      return reports
    } catch (err) {
      dispatch(setReports([]))
      return Promise.reject(err)
    }
  }, [imoNo])

  const setReportType = useCallback((type: ApiReportType | null) => {
    return dispatch(updateReportType(type))
  }, [])

  const setReportTimestamp = useCallback((timestamp: ReportTimestamp) => {
    return dispatch(updateReportTimestamp(timestamp))
  }, [])

  const setReport = useCallback((report: IReport) => {
    return dispatch(updateReport(report))
  }, [])

  const setEvents = useCallback(
    (events: Event[], subReport: ESubReport) =>
      dispatch(updateEvents(events, subReport)),
    [],
  )

  const setFormSteps = useCallback((steps: string[] | null) => {
    return dispatch(updateFormSteps(steps))
  }, [])

  const setFormType = useCallback((formType: HDCFormType | null) => {
    return dispatch(updateFormType(formType))
  }, [])

  const setFormCurrentStep = useCallback((stepCount: number) => {
    return dispatch(updateFormCurrentStep(stepCount))
  }, [])

  const setFormUseCustomArrival = useCallback((customArrival: boolean) => {
    return dispatch(
      updateFormCustomDestination('useCustomArrival', customArrival),
    )
  }, [])

  const setFormUseCustomDeparture = useCallback((customDeparture: boolean) => {
    return dispatch(
      updateFormCustomDestination('useCustomDeparture', customDeparture),
    )
  }, [])

  const setHasUnsavedEvent = useCallback(
    (hasEvent: boolean) => dispatch(updateHasUnsavedEvent(hasEvent)),
    [],
  )

  const setHasValidationError = useCallback((formType, step, isInvalid) => {
    return dispatch(updateHasValidationError(formType, step, isInvalid))
  }, [])

  const setHasValidationWarning = useCallback(
    (formType, step, hasWarning) =>
      dispatch(updateHasValidationWarning(formType, step, hasWarning)),
    [],
  )

  const setFuelDataLosses = useCallback(
    (losses: FuelLineDataLoss[]) => dispatch(updateFuelDataLosses(losses)),
    [],
  )

  const setInvalidReports = useCallback(
    (reportIds: string[]) => dispatch(updateInvalidReports(reportIds)),
    [],
  )

  const reset = useCallback(() => {
    return dispatch(resetState())
  }, [])

  // Make sure we go into loading state when imoNo changes
  useEffect(() => dispatch(resetState()), [imoNo])

  return (
    <HDCContext.Provider
      value={{
        imoNo,
        state,
        initiateReport,
        getReport,
        getReports,
        setReportType,
        setReportTimestamp,
        setReport,
        setEvents,
        setFormType,
        setFormCurrentStep,
        setFormSteps,
        setFormUseCustomArrival,
        setFormUseCustomDeparture,
        setHasUnsavedEvent,
        setHasValidationError,
        setHasValidationWarning,
        setFuelDataLosses,
        setInvalidReports,
        resetState: reset,
      }}
    >
      {children}
    </HDCContext.Provider>
  )
}

export default withRouter((props: RouteComponentProps<routerParams>) => {
  const { imoNo } = useContext(VesselPageContext)
  return imoNo ? (
    <HDCContextProvider imoNo={`${imoNo}`} {...props} />
  ) : (
    <Loading />
  )
})
