import Select, { components } from 'react-select'
import { type FieldProps } from 'formik'
import styled from 'styled-components'

import { grey } from '../theme'
import { Icon } from '.'

const SelectWrapper = styled.div<{ width?: string }>`
  width: ${(props) => props.width || '200px'};
`

const OptionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export interface IOption {
  label: string
  value: any
  isDisabled: boolean
  [key: string]: any
}

interface Props extends FieldProps {
  disabled?: boolean
  onChange?: (value) => void
  options: IOption[]
  width?: string
  menuWidth?: string
}

const Option = (props) => {
  return (
    <components.Option {...props}>
      <OptionContainer>
        <div>{props.label}</div>{' '}
        {props.isDisabled && (
          <Icon icon='fal fa-lock-alt fa-lg' style={{ color: grey[500] }} />
        )}
      </OptionContainer>
    </components.Option>
  )
}

export const FormSelect = ({
  disabled,
  field,
  form,
  options,
  width,
  menuWidth,
  onChange,
}: Props) => (
  <SelectWrapper width={width}>
    <Select
      components={{ Option }}
      options={options}
      isClearable={false}
      isSearchable={false}
      value={
        (typeof field?.value === 'boolean' || field?.value) &&
        options.find((option) => option.value === field.value)
      }
      isDisabled={disabled}
      isOptionDisabled={(option) => option.isDisabled}
      styles={{
        menu: (provided) => ({
          ...provided,
          width: menuWidth || '300px',
        }),
        option: (provided) => ({
          ...provided,
          fontSize: '14px',
        }),
        valueContainer: (provided) => ({
          ...provided,
          fontSize: '14px',
        }),
      }}
      onBlur={field.onBlur}
      onChange={(option: any) => {
        form.setFieldValue(field.name, option.value)
        if (onChange) {
          onChange(option)
        }
      }}
    />
  </SelectWrapper>
)
