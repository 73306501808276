import styled from 'styled-components'
import { McTag } from '@maersk-global/mds-react-wrapper/components-core/mc-tag'
import type { TagAppearance } from '@maersk-global/mds-components-core/mc-tag/types'

type Props = {
  rangeValidation: GandalfApi.Metc.RangeValidation
}

const RangeValidationTag = ({ rangeValidation }: Props) => {
  if (rangeValidation === null) return '-'

  const { appearance, icon } = resolveAppearance(rangeValidation)

  return (
    <Wrapper>
      <McTag appearance={appearance} hiddenlabel icon={icon} />
    </Wrapper>
  )
}

export default RangeValidationTag

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`

export const resolveAppearance = (
  rangeValidation: number,
): {
  appearance: TagAppearance
  icon: string
} => {
  const appearance = rangeValidation === 0 ? 'success' : 'error'

  let icon = 'check'
  if (rangeValidation === -10) {
    icon = 'arrow-down'
  } else if (rangeValidation === 10) {
    icon = 'arrow-up'
  }

  return { appearance, icon }
}
