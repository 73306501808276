import { McIcon } from '@maersk-global/mds-react-wrapper'

import styled, { green, grey } from '../../../theme'

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  border-bottom: 1px solid ${grey[200]};

  .name {
    display: flex;
    align-items: center;

    > mc-icon {
      margin-right: 12px;
    }

    > span {
      margin-left: 12px;
      color: ${grey[500]};
      font-feature-settings: 'tnum' 1;
    }
  }

  &.selected {
    background-color: ${green[200]};
  }
`

type Props = {
  imoNos: Set<string>
  name: string
  imoNo: string
  isSelected: boolean
  isDeselect: boolean
  onClick: () => void
}

const VesselItem = ({
  name,
  imoNo,
  isSelected,
  isDeselect,
  onClick,
  imoNos,
}: Props) => {
  const isInactive = !imoNos.has(imoNo)

  return (
    <Wrapper
      className={`${isSelected ? 'selected' : ''}`}
      onClick={onClick}
      title={isInactive ? 'This vessel is not installed yet.' : ''}
    >
      <div className='name'>
        <McIcon icon={isInactive ? 'wifi-offline' : 'vessel-front'} />
        {name}
        <span>{imoNo}</span>
      </div>
      <McIcon icon={isSelected ? 'check' : isDeselect ? 'minus' : 'plus'} />
    </Wrapper>
  )
}

export default VesselItem
