import { useContext, useEffect, useState } from 'react'

import { Performance } from '../../../../api-models'
import { Loading, Modal } from '../../../../commons'
import { VesselPageContext } from '../../../../contexts'
import * as PerformanceApi from '../../../../services/performance'
import MixedBatchDetails from './MixedBatchDetails'
import MixedBatchForm from './MixedBatchForm'
import { displayErrorModal } from '../../../../utils'

type Props = {
  entryId?: string
  closeHandler: (refresh?: boolean) => void
}

export const MixedBatchModal = ({ closeHandler, entryId }: Props) => {
  const imoNo = useContext(VesselPageContext).imoNo!
  const [mixedBatch, setMixedBatch] =
    useState<Performance.FuelOilStock.MixedBatchEntryResponse>()

  useEffect(() => {
    if (entryId) {
      PerformanceApi.getMixedBatch(imoNo, entryId)
        .then(setMixedBatch)
        .catch(() =>
          displayErrorModal({
            statusText: 'Could not get mixed batch',
            message: 'Could not find the requested mixed batch',
          }),
        )
    }
  }, [entryId, imoNo])

  if (entryId && !mixedBatch) {
    return (
      <Modal title={'Mixed batch'} visible closeHandler={closeHandler}>
        <Loading />
      </Modal>
    )
  }

  return (
    <Modal
      title={mixedBatch ? 'Mixed batch details' : 'Create mixed batch'}
      visible
      closeHandler={closeHandler}
      helpTextKey={mixedBatch ? '' : 'stock/add-mixed-batch'}
    >
      {mixedBatch && (
        <MixedBatchDetails
          mixedBatch={mixedBatch}
          closeHandler={closeHandler}
        />
      )}
      {!mixedBatch && <MixedBatchForm closeHandler={closeHandler} />}
    </Modal>
  )
}
