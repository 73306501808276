export interface QueryResponse extends Response {
  queryPeriod: Period
}

export interface Response {
  hasData: boolean
  vessel: Vessel
}

export interface Period {
  from: string
  to: string
  totalMinutes: number
}

export interface QueryPeriod {
  from: string
  to: string
}

export interface Vessel {
  imoNo: number
  papiVersion?: string
}

export enum EStockNotificationType {
  Info = 1,
  Warning,
  Error,
}

export interface IStockNotification {
  type: EStockNotificationType
  timestamp: string
  message: string
}
