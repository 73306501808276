import { useParams } from 'react-router-dom'
import moment from 'moment/moment'

import { Wrapper } from './MetcReportMetaDisplay.styles'
import { PadContent } from '../../../../../layout/styles'
import { useVesselMainEngines } from '../../../../../queries/MasterDataApi/MasterDataApi'
import { MainEngInstanceName } from '../../../../../queries/MasterDataApi/MasterDataApi.consts'
import { routerParams } from '../../../../../routes'

const FORMAT = 'DD MMM YYYY, HH:mm'

type Props = {
  report: GandalfApi.MetcReport
}

const MetcReportMetaDisplay = ({ report }: Props) => {
  const { vesselId } = useParams<routerParams>()
  const vesselMainEngines = useVesselMainEngines(vesselId)

  const isTwinEngineVessel =
    vesselMainEngines.isSuccess && vesselMainEngines.data.engines.length === 2

  let instance = ''
  if (isTwinEngineVessel) {
    instance = MainEngInstanceName[report.data.equipment.instanceNo]
  }

  const end = moment.utc(report.endTimestamp)
  const start = moment.utc(report.startTimestamp)
  const duration = moment.duration(end.diff(start))
  const durationString = `${duration.hours()}h ${duration.minutes()}m`

  return (
    <PadContent>
      <Wrapper>
        <div>
          <div>Equipment</div>
          <span>Main engine {instance}</span>
        </div>
        <div>
          <div>Start time</div>
          <span>{start.format(FORMAT)}</span>
        </div>
        <div>
          <div>End time</div>
          <span>{end.format(FORMAT)}</span>
        </div>
        <div>
          <div>Duration</div>
          <span>{durationString}</span>
        </div>
      </Wrapper>
    </PadContent>
  )
}

export default MetcReportMetaDisplay
