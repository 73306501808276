import {
  BrowserAuthError,
  BrowserCacheLocation,
  Configuration,
  EventMessage,
  EventMessageUtils,
  InteractionRequiredAuthError,
  InteractionStatus,
  PublicClientApplication,
  SilentRequest,
} from '@azure/msal-browser'

export const ROLES = {
  USER: 'User',
  ADMIN: 'Admin',
  SUPER_USER: 'SuperUser',
  INTERNAL_USER: 'InternalUser',
  TEST_USER: 'TestUser',
}

export const msalConfig: Configuration = {
  auth: {
    authority: (window as any).config.azureAd.authority,
    clientId: (window as any).config.azureAd.clientId,
    postLogoutRedirectUri: (window as any).config.azureAd.redirectUri,
    redirectUri: (window as any).config.azureAd.redirectUri,
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
  },
  system: {
    iframeHashTimeout: 10000,
  },
}

export const msalInstance = new PublicClientApplication(msalConfig)

msalInstance.addEventCallback((message: EventMessage) => {
  const status = EventMessageUtils.getInteractionStatusFromEvent(message)
  if (status) {
    localStorage.setItem('msalStatus', status)
  }
})

export const signOut = async () => {
  await msalInstance.logoutRedirect()
}

export const getAccessToken = async () => {
  const accessTokenRequest: SilentRequest = {
    account: msalInstance.getAllAccounts()[0],
    scopes: [(window as any).config.azureAd.scope],
  }
  try {
    const accessTokenResponse = await msalInstance.acquireTokenSilent(
      accessTokenRequest,
    )
    return accessTokenResponse.accessToken
  } catch (err) {
    console.log('Failed to silently acquire token', err)
    if (
      err instanceof InteractionRequiredAuthError ||
      err instanceof BrowserAuthError
    ) {
      const status = localStorage.getItem('msalStatus') as InteractionStatus
      if (status !== InteractionStatus.None) {
        return
      }

      await signOut()
    }
  }
}
