import { useContext } from 'react'
import { type Table } from '@tanstack/react-table'

import type { TLubeOilEntry } from '../../lube-oil/types'
import { WindowContext } from '../../../contexts'
import {
  FilterWrapper,
  ResultsCountTag,
} from '../../../components/Table/Table.styles'
import DateFilter from '../ActivityLogFilters/DateFilter/DateFilter'
import EntryTypeFilter from '../ActivityLogFilters/EntryTypeFilter/EntryTypeFilter'
import OilTypeFilter from '../ActivityLogFilters/OilTypeFilter/OilTypeFilter'
import { LUBE_OIL_ENTRY_TYPE_NAME } from '../../lube-oil/constants'

type Props = {
  table: Table<TLubeOilEntry>
}

const LubeFilters = ({ table }: Props) => {
  const { windowSize } = useContext(WindowContext)

  return (
    <FilterWrapper>
      <DateFilter table={table} columnId='timestamp' />
      <div className='lube-entry-type-filter'>
        <EntryTypeFilter
          table={table}
          label='Entry type'
          availableEntryTypes={LUBE_OIL_ENTRY_TYPE_NAME}
          columnId='type'
          data-e2e='EntryType'
        />
      </div>
      <div className='oil-type-filter'>
        <OilTypeFilter table={table} />
      </div>
      <ResultsCountTag
        appearance='info'
        label={`${table.getFilteredRowModel().rows.length} results`}
        fit={windowSize}
      />
    </FilterWrapper>
  )
}

export default LubeFilters
