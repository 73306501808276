import styled from 'styled-components'

export const RecoveryWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 4px;

  span {
    margin-left: 8px;
  }

  button {
    padding: 4px;
  }
`

export const ConfirmRecovery = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 0;
  text-align: center;

  mc-icon {
    margin-bottom: 40px;

    &::part(icon) {
      transform: scale(3);
      fill: ${(props) => props.theme.colors.feedback.danger.main};
    }
  }
`
