import styled from 'styled-components'

import { lg, maerskBlue, md, media, navMainControl, sm } from '../../../theme'

export const Wrapper = styled.div`
  overflow: hidden;

  .vessel-title {
    background-color: ${navMainControl.bg};
    color: ${navMainControl.fg};
    display: flex;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    height: 32px;
    font-size: 16px;
  }

  .link {
    color: #282f36;
    display: flex;
    justify-content: space-between;
    align-items: center;

    button,
    a {
      background-color: transparent;
      text-decoration: none;
      color: #282f36;
      font-size: 14px;
      width: 100%;
      height: 100%;
      text-align: left;
    }

    button:focus {
      outline: none;
    }
  }

  .search {
    ${media.sm`padding: 8px 16px;`}
    ${media.md`padding: 16px;`}
    ${media.lg`padding: 16px;`}
    input {
      ${media.sm`font-size: ${sm.VESSEL_SEARCH_FONT_SIZE};`};
      ${media.md`font-size: ${md.VESSEL_SEARCH_FONT_SIZE};`};
      ${media.lg`font-size: ${lg.VESSEL_SEARCH_FONT_SIZE};`};
    }

    span {
      border: 1px solid ${maerskBlue[500]};
    }
  }

  .vessels {
    overflow-y: hidden;
    list-style-type: none;

    &:hover {
      overflow-y: auto;
    }
  }
`
