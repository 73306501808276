import * as React from 'react'
import { Pie } from 'recharts'
import { findDialAngle } from '../../../utils'
import { orange, grey } from '../../../theme'

export interface DialReferenceLine {
  value: number | null
  maxValue?: number
  color?: string
  label?: string
  tooltip?: string
  hasDialHeader?: boolean
  className?: string
}

const renderLabel = (
  { cx, cy, midAngle, innerRadius, outerRadius, percent, index }: any,
  data: any,
  tooltip: any,
) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 1.15
  const RADIAN = Math.PI / 180
  const x = cx + radius * Math.cos(-midAngle * RADIAN)
  const y = cy + radius * Math.sin(-midAngle * RADIAN)

  const item = data[index]
  let anchor = 'middle'
  if (x > cx + 20) {
    anchor = 'start'
  } else if (x < cx - 20) {
    anchor = 'end'
  }

  let label = item.label || ''
  if ((x < cx - 70 || x > cx + 70) && label.length > 5) {
    label = `${(item.label || '').slice(0, 2)}...`
  }

  const labels = label.split(',')

  return (
    <text
      x={x}
      y={y}
      fontSize='12'
      textAnchor={anchor}
      dominantBaseline='central'
      fill={grey[700]}
      key={`label-${item.label}`}
    >
      <title>{tooltip}</title>
      {labels.map((label: string, index: number) => {
        if (labels.length > 1) {
          return (
            <tspan key={label} dy={index === 0 ? -12 : 12} x={x} dx={0}>
              {label}
            </tspan>
          )
        }
        return <tspan key={label}>{label}</tspan>
      })}
    </text>
  )
}

export const mapToRefLine = (refLine: DialReferenceLine): JSX.Element => {
  const { value, maxValue, color, label, hasDialHeader, tooltip, className } =
    refLine
  const data = [{ value: value, label: label }]
  const padding = hasDialHeader ? 48 : 30

  return (
    <Pie
      key={`${label}-${value}`}
      data={data}
      dataKey='value'
      startAngle={findDialAngle(
        value ? value - 1 : 0,
        maxValue ? maxValue : 100,
      )}
      endAngle={findDialAngle(value ? value : 0, maxValue ? maxValue : 100)}
      innerRadius={56}
      outerRadius={101}
      fill={color ? color : orange[500]}
      stroke={orange[500]}
      cy={padding + 96}
      label={(pieData: any) => renderLabel(pieData, data, tooltip)}
      labelLine={false}
      isAnimationActive={false}
      className={className}
    />
  )
}

export default mapToRefLine
