import * as React from 'react'
import styled, { grey } from '../../../theme'
import moment from 'moment'
import { Sensors } from '../../../api-models/sensors'
import { FormElements } from '../../../commons'

const FormWrapper = styled.div`
  font-size: 14px;
  .error-details-table {
    width: 100%;
    background: ${grey[100]};
    border: 1px solid ${grey[300]};
    border-radius: 2px;
    p:first-letter {
      text-transform: capitalize;
    }
    td {
      padding: 4px;
    }
    label {
      font-size: 14px;
      white-space: nowrap;
      display: block;
      width: 100%;
    }
    td.value {
      min-width: 288px;
    }
  }

  .align-right {
    text-align: right;
  }
`

interface SensorDetailsFormProps {
  sensor: Sensors.SensorResponse
  sensorError?: Sensors.SensorResponse
}

export default class SensorDetailsForm extends React.PureComponent<
  SensorDetailsFormProps,
  any
> {
  renderErrorDetailsTable(sensorError: Sensors.SensorResponse) {
    const registered = moment.utc(sensorError.error!.timestamp).utc()
    return (
      <table className='error-details-table'>
        <tr style={{ verticalAlign: 'text-top' }}>
          <td>
            <p>{sensorError.error!.message}</p>
            <p style={{ paddingRight: '24px' }}>{sensorError.error!.details}</p>
            <p style={{ color: grey[700], fontSize: '12px' }}>
              {sensorError.error!.code}
            </p>
          </td>
          <td style={{ textAlign: 'right' }}>
            <p style={{ fontSize: '10px' }}>{registered.fromNow()}</p>
            <p style={{ color: grey[700], fontSize: '10px' }}>
              ({registered.format('Mo MMM HH:mm')})
            </p>
          </td>
        </tr>
      </table>
    )
  }

  render() {
    const { sensor, sensorError } = this.props
    return (
      <FormWrapper>
        <FormElements.ReadonlyTable>
          <tbody>
            <tr>
              <td className='align-right'>
                <label htmlFor='name'>Name</label>
              </td>
              <td>
                ({sensor.id}) {sensor.name}
              </td>
            </tr>
            <tr>
              <td className='align-right'>
                <label htmlFor='connector'>Connector</label>
              </td>
              <td>{sensor.connector.name}</td>
            </tr>
            <tr>
              <td className='align-right'>
                <label htmlFor='group'>Group</label>
              </td>
              <td>{sensor.group}</td>
            </tr>
            {sensor.hasError && sensorError && (
              <tr>
                <td>
                  <label htmlFor='error-details'>Error details</label>
                </td>
                <td>{this.renderErrorDetailsTable(sensorError)}</td>
              </tr>
            )}
          </tbody>
        </FormElements.ReadonlyTable>
      </FormWrapper>
    )
  }
}
