import { useState } from 'react'
import { layoutSizes } from '../../theme'
import { type IVesselPageContext } from '../../contexts'

import { withVesselPageContext } from '../../higher-order-components'
import { getTimestampFromVesselStatus, isFeatureEnabled } from '../../utils'
import FilterPanel, {
  type Filters,
  filtersChanged,
  getFilterNotification,
  getFiltersFromQueryParams,
} from '../../features/filter'
import { MeSfocChartContainer } from '../../features/main-engine-sfoc'
import { TorqueOffsetChartContainer } from '../../features/torque-offset'
import { RunningModeChartContainer } from '../../features/running-mode'
import {
  MainEnginePowerDial,
  MainEngineRPMDial,
  ShaftMotorDial,
} from '../../features/vessel-status'
import { ContentCard, Loading } from '../../commons'
import { ContentLayout } from '../../layout'
import TemNotifications from '../../components/TemNotifications/TemNotifications'
import { INotification } from '../../features/notifications/models'
import {
  useFilteredSFOC,
  useVesselSpecificBaseline,
} from '../../queries/HydraApi/HydraApi'
import { filtersConfig } from './PropulsionPage.const'
import { getQueryPeriod } from './PropulsionPage.utils'
import { useVesselStaticModels } from '../../queries/MasterDataApi/MasterDataApi'
import { StaticModelType } from '../../queries/MasterDataApi/MasterDataApi.consts'
import PerformanceAlerts from '../../components/PerformanceAlerts/PerformanceAlerts'

type Props = IVesselPageContext

const PropulsionPage = ({ imoNo, vesselStatus, configuration }: Props) => {
  const [filters, setFilters] = useState<Filters | undefined>(
    getFiltersFromQueryParams(),
  )

  const dataShopTestModels =
    useVesselStaticModels<MasterDataApi.StaticModels.MainEngStaticModelData>(
      +imoNo!,
      [StaticModelType.MainEng],
    )
  const { data: dataFilteredSFOC } = useFilteredSFOC(
    imoNo!,
    getQueryPeriod(filters),
  )
  const { data: dataBaselineModel } = useVesselSpecificBaseline(
    imoNo!,
    getQueryPeriod(filters),
  )

  const onFiltersReset = () => {
    setFilters(undefined)
  }

  const notifications: INotification[] = []
  if (filters) {
    notifications.push(getFilterNotification(filters, onFiltersReset))
  }

  const handleFiltersChange = (newFilters: Filters) => {
    if (filtersChanged(filters, newFilters)) {
      setFilters(newFilters)
    }
  }

  return (
    <ContentLayout
      header='Propulsion'
      featureId='propulsion'
      notifications={notifications}
      hasFilter
    >
      <TemNotifications />
      <PerformanceAlerts />
      <FilterPanel
        onChange={handleFiltersChange}
        config={filtersConfig}
        value={filters}
        onReset={onFiltersReset}
      />
      {configuration ? (
        <>
          <div className='wrapped-components'>
            <ContentCard
              title='ME RPM'
              helpTextKey='propulsion/maineng-rpm'
              width={
                configuration.hasTwoMainEngines
                  ? layoutSizes.half
                  : layoutSizes.quarter
              }
              id='rpm'
              additionalInfo={
                <span>{getTimestampFromVesselStatus(vesselStatus)}</span>
              }
            >
              {!vesselStatus ? (
                <Loading />
              ) : (
                <MainEngineRPMDial
                  vesselStatus={vesselStatus}
                  vesselConfiguration={configuration}
                />
              )}
            </ContentCard>
            <ContentCard
              title='Shaft motor'
              id='shaft-motor'
              helpTextKey='propulsion/shaft-motor'
              width={
                configuration.hasTwoMainEngines
                  ? layoutSizes.half
                  : layoutSizes.quarter
              }
              additionalInfo={
                <span>{getTimestampFromVesselStatus(vesselStatus)}</span>
              }
              hide={
                !configuration.shaftMotor1.isInstalled &&
                !configuration.shaftMotor2.isInstalled
              }
            >
              {!vesselStatus ? (
                <Loading />
              ) : (
                <ShaftMotorDial
                  vesselStatus={vesselStatus}
                  vesselConfiguration={configuration}
                />
              )}
            </ContentCard>
            <ContentCard
              title='ME Power'
              helpTextKey='propulsion/maineng-power'
              id='mep'
              width={
                configuration.hasTwoMainEngines
                  ? layoutSizes.half
                  : layoutSizes.quarter
              }
              additionalInfo={
                <span>{getTimestampFromVesselStatus(vesselStatus)}</span>
              }
            >
              {!vesselStatus ? (
                <Loading />
              ) : (
                <MainEnginePowerDial
                  vesselStatus={vesselStatus}
                  vesselConfiguration={configuration}
                  minHeight={configuration.hasTwoMainEngines ? 300 : undefined}
                />
              )}
            </ContentCard>
            {configuration.hasTwoMainEngines && (
              <RunningModeChartContainer filters={filters} />
            )}
          </div>
          {imoNo && (
            <MeSfocChartContainer
              vesselStatus={vesselStatus}
              imoNo={imoNo}
              configuration={configuration}
              filters={filters}
              data={dataFilteredSFOC}
              dataShopTestModels={dataShopTestModels.data}
              dataVesselSpecific={dataBaselineModel}
            />
          )}

          <div className='wrapped-components'>
            {isFeatureEnabled('propulsion/torque-offset') && (
              <>
                <ContentCard
                  id='propulsion_to_port'
                  title={`${
                    configuration.hasTwoMainEngines
                      ? 'Port Torque offset'
                      : 'Torque offset'
                  }`}
                  helpTextKey='propulsion/torque-offset'
                  width={layoutSizes.half}
                >
                  <TorqueOffsetChartContainer
                    filters={filters}
                    isMainEngine2={false}
                    vesselStatus={vesselStatus}
                    vesselConfiguration={configuration}
                  />
                </ContentCard>

                {configuration.hasTwoMainEngines && (
                  <ContentCard
                    id='propulsion_to_stbd'
                    title='Stbd Torque offset'
                    helpTextKey='propulsion/torque-offset'
                    width={layoutSizes.half}
                  >
                    <TorqueOffsetChartContainer
                      filters={filters}
                      isMainEngine2={true}
                      vesselStatus={vesselStatus}
                      vesselConfiguration={configuration}
                    />
                  </ContentCard>
                )}
              </>
            )}
            {!configuration.hasTwoMainEngines && (
              <RunningModeChartContainer filters={filters} />
            )}
          </div>
        </>
      ) : (
        <Loading />
      )}
    </ContentLayout>
  )
}

export default withVesselPageContext(PropulsionPage)
