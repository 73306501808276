import { type MouseEvent, useContext, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router'
import {
  getCoreRowModel,
  getPaginationRowModel,
  type Row,
  useReactTable,
} from '@tanstack/react-table'

import { Loading, NotFound } from '../../../../commons'
import { displayErrorModal, isShoreContext } from '../../../../utils'
import { HDCContext } from '../../context'
import { isReportEditable } from '../../utils'
import { ApiReportState, type IReport } from '../../../../api-models/hdc/report'
import { PadContent } from '../../../../layout/styles'
import { columns as _columns } from './helpers'
import Table, { type ITableRef } from '../../../../components/Table/Table'
import SummaryModal from '../../reports/summary-modal'

export const ReportsOverviewComponent = () => {
  const { state, getReports, imoNo, setInvalidReports, setReport } =
    useContext(HDCContext)
  const { reports, invalidReports } = state

  const [isSummaryModalVisible, setIsSummaryModalVisible] =
    useState<boolean>(false)

  const { push } = useHistory()

  useEffect(() => {
    const fetchReports = async () => {
      try {
        const reports = await getReports()
        const invalidReports: string[] = []
        reports.forEach((report) => {
          if (
            report.bridge.state === ApiReportState.INVALID ||
            report.engineRoom.state === ApiReportState.INVALID
          ) {
            invalidReports.push(report.id)
          }
        })
        setInvalidReports(invalidReports)
      } catch (err) {
        displayErrorModal({
          statusText: 'Could not find previous reports',
          message: err.message,
        })
      }
    }

    void fetchReports()
  }, [getReports, setInvalidReports])

  const tableRef = useRef<ITableRef>(null)

  const table = useReactTable({
    data: reports || [],
    columns: _columns,
    initialState: {
      pagination: { pageSize: 25 },
    },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  })

  const handleRowClick = (row: Row<IReport>, event: MouseEvent) => {
    const target = event.target as HTMLElement
    if (target.closest('button, li')) {
      /* Return if clicking on a menu button or menu item. */
      tableRef.current?.deselectRow()
      return
    }

    setReport(row.original)
    if (
      isShoreContext() ||
      !isReportEditable(row.original, reports!) ||
      (invalidReports.length && !invalidReports.includes(row.original.id))
    ) {
      setIsSummaryModalVisible(true)
    } else {
      push(`/MaerskStarConnect/vessel/${imoNo}/hdc/report`)
    }
  }

  const handleModalClosed = () => {
    setIsSummaryModalVisible(false)
    tableRef.current?.deselectRow()
  }

  return (
    <>
      <PadContent>
        <p style={{ marginBottom: 16 }}>
          Here you can see status of earlier reports. Either click on a report
          to edit or click the Actions to View summary/Delete.
        </p>
        {reports && reports.length > 0 && (
          <Table
            table={table}
            ref={tableRef}
            maxFit='small'
            onRowSelect={handleRowClick}
          />
        )}
        {reports && reports.length === 0 && (
          <NotFound text='No reports available yet in StarConnect!' />
        )}
        {!reports && <Loading />}
      </PadContent>
      <SummaryModal
        handleClose={handleModalClosed}
        isVisible={isSummaryModalVisible}
      />
    </>
  )
}
