import styled from 'styled-components'

export const IconCell = styled.div`
  display: flex;
  justify-content: center;
  padding: 4px 0;
`

export const Action = styled(IconCell)<{ isDisabled: boolean }>`
  cursor: pointer;
  opacity: ${(props) => props.isDisabled && 0.5};
  pointer-events: ${(props) => props.isDisabled && 'none'};
`

export const AddRowButton = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 8px 8px 0;
`

export const NewEntryOptions = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  padding: 24px 24px 16px;
  border-bottom: 1px solid ${(props) => props.theme.colors.grey[200]};

  > p {
    width: 100%;
  }
`

export const Description = styled.div`
  margin: 1rem 0 1rem 0;
`

export const Filters = styled.div`
  margin-bottom: 1rem;

  > div {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    gap: 1.2rem;
    margin-bottom: 2rem;
    padding-top: 1rem;

    > div {
      display: flex;
      gap: 1rem;

      > * {
        width: 170px;
      }
    }
  }
`

export const Input = styled.div<{ width: string }>`
  width: ${(props) => props.width};
`

export const InputRow = styled.div`
  display: flex;
  gap: 2rem;
  margin: 2rem 0;
`

export const DeletionRow = styled.div`
  display: flex;
  align-items: center;
  margin-top: 2rem;

  > p {
    margin-left: 16px;
    color: ${(props) => props.theme.colors.feedback.danger.main};
  }
`

export const SoundingTable = styled.div`
  margin-bottom: 4rem;

  > table {
    table-layout: fixed;

    > tbody {
      td {
        vertical-align: top;

        > div {
          width: 100%;
        }
      }

      td.oil-container {
        vertical-align: middle;
      }
    }
  }

  td.total {
    font-weight: bold;
  }

  th.actions {
    width: 5%;
  }

  th.oil-container {
    width: 15%;
  }
`
