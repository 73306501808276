import * as React from 'react'
import { McButton } from '@maersk-global/mds-react-wrapper'

import { Modal } from '../commons'
import styled, { red } from '../theme'

const Wrapper = styled.div`
  .content {
    padding: 16px;
    display: flex;
    align-items: center;
    svg {
      color: ${red[500]};
      margin-right: 16px;
    }
    white-space: pre-wrap;
  }
  .modal-bottom {
    display: flex;
    justify-content: flex-end;
    padding: 16px;
  }
`

export interface ErrorModalProps {
  error: {
    statusText?: string
    message?: string
  }
  closeHandler: () => void
}

export interface ErrorModalState {}

export class ErrorModal extends React.Component<
  ErrorModalProps,
  ErrorModalState
> {
  render() {
    const { error, closeHandler } = this.props
    return (
      <React.Fragment>
        {error && (
          <Modal
            title={
              error.statusText ? error.statusText : 'Something went wrong...'
            }
            visible={!!error}
            closeHandler={closeHandler}
          >
            <Wrapper>
              <div className='content' data-e2e='error-modal'>
                <span className='fal fa-frown fa-3x' />
                {error.message ? error.message : 'No error message available.'}
              </div>
              <hr />
              <div className='modal-bottom'>
                <McButton
                  label='Ok'
                  appearance='primary'
                  click={closeHandler}
                  type='button'
                />
              </div>
            </Wrapper>
          </Modal>
        )}
      </React.Fragment>
    )
  }
}
