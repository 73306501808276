import styled from 'styled-components'

import { grey, maerskBlue } from '../../theme'

type Props = {
  isDisabled?: boolean
  depth: number
  isOpen?: boolean
}

const colorDepthMap = {
  0: '#ffffff',
  1: maerskBlue[500],
  2: maerskBlue[400],
  3: maerskBlue[300],
}

export const Header = styled.div<Props>`
  align-items: center;
  background-color: ${(props) => {
    if (props.isDisabled) return grey[300]
    return props.isOpen ? '#E8F5FA' : colorDepthMap[props.depth]
  }};
  color: ${(props) => {
    if (props.isDisabled || props.isOpen) return grey[900]
    return props.depth === 0 ? grey[900] : grey[50]
  }};
  border-top: 1px solid ${grey[100]};
  border-bottom: 1px solid ${grey[100]};
  cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'pointer')};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 14px;
  height: 50px;
  line-height: 50px;
  margin-top: 10px;
  border-radius: ${(props) => (props.depth && '3px') || '0'};
  box-shadow: ${(props) =>
    props.depth > 0 ? '0 4px 4px rgba(0,0,0, .05)' : 'none'};

  + div {
    border-top: none;
  }
`

export const Title = styled.div`
  font-size: 16px;
  font-weight: 400;
`

export const Content = styled.section<{ isOpen: boolean }>`
  padding: 14px 0;
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
`
