import moment from 'moment/moment'

import { DateTimeWrapper } from '../Diagnostics.styles'
import { FormInputDateTime } from '../../../formik'

type Props = Diagnostics.StepComponentProps

const ScheduleEnginePerformanceTest = ({ isReadonly }: Props) => {
  return (
    <>
      <p>
        There are no apparent issues. Please schedule a main engine performance
        test.
      </p>
      <hr />
      <p>When do you expect to carry out the main engine performance test?</p>
      <DateTimeWrapper>
        <FormInputDateTime
          name='scheduleMetc.scheduledAt'
          label='Date and time'
          min={moment.utc()}
          disabled={isReadonly}
        />
      </DateTimeWrapper>
    </>
  )
}

export default ScheduleEnginePerformanceTest
