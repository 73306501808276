import React from 'react'
import { McButton } from '@maersk-global/mds-react-wrapper'

import { SensorDataChartContainer } from './SensorDataChartContainer'
import { Sensors } from '../../../api-models/sensors'
import { IVesselPageContext } from '../../../contexts'
import SensorDetailsForm from './SensorDetailsForm'
import { Modal, ModalControls, Tabs } from '../../../commons'
import { withVesselPageContext } from '../../../higher-order-components'
import { displayErrorModal } from '../../../utils'
import { getSensorsData } from '../../../services/sensors'

interface SensorDetailsProps extends IVesselPageContext {
  sensor: Sensors.SensorResponse
  sensorError?: Sensors.SensorResponse
  onCancel: () => void
}

interface SensorDetailsState {
  sensorData?: Sensors.SensorDataResponse
}

class SensorDetails extends React.Component<
  SensorDetailsProps,
  SensorDetailsState
> {
  constructor(props: SensorDetailsProps) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    this.loadSensorData()
  }

  loadSensorData() {
    const { imoNo, sensor } = this.props
    if (imoNo) {
      getSensorsData(imoNo, sensor.id)
        .then((sensorData: Sensors.SensorDataResponse) => {
          this.setState({
            sensorData,
          })
        })
        .catch((e) => displayErrorModal(e))
    }
  }

  render() {
    const { sensor, sensorError, onCancel } = this.props
    const { sensorData } = this.state
    return (
      <div>
        <Modal closeHandler={onCancel} visible={true} title={sensor.name}>
          <Tabs tabButtons={[{ name: 'Details' }, { name: 'Data' }]}>
            <SensorDetailsForm sensor={sensor} sensorError={sensorError} />
            <SensorDataChartContainer sensorData={sensorData} />
          </Tabs>
          <ModalControls>
            <McButton
              label='Close'
              appearance='primary'
              id='cancel'
              click={onCancel}
              type='button'
            />
          </ModalControls>
        </Modal>
      </div>
    )
  }
}

export default withVesselPageContext(SensorDetails)
