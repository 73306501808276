import { useEffect, useState, useContext } from 'react'
import moment, { type Moment } from 'moment'
import { McButton } from '@maersk-global/mds-react-wrapper'

import { ModalControls, DateTimeInput, Loading } from '../../../../commons'
import { Modal } from '../../../../commons'
import { StockOnBoard } from '../../models'
import * as PerformanceApi from '../../../../services/performance'
import { Performance } from '../../../../api-models'
import { displayErrorModal, getClosestTenMinute } from '../../../../utils'
import { FuelTypeBatches } from '../stock-on-board/FuelTypeBatches'
import { VesselPageContext } from '../../../../contexts'
import { mapStockOnBoard } from '../../utils'
import styled from '../../../../theme'
import { StockRobNotifications } from '../StockRobNotifications'
import { FuelTypeTotals } from '../stock-on-board/FuelTypeTotals'

const Wrapper = styled.div`
  padding: 16px;
  max-width: 950px;
  max-height: 80vh;
  min-width: 950px;
  min-height: 300px;
  overflow-x: auto;
  overflow-y: auto;
`

const DateWrapper = styled.div`
  margin-bottom: 16px;
  display: flex;
  align-items: center;
`

const FieldLabel = styled.span`
  width: 168px;
  font-size: 14px;
  text-align: right;
  margin-right: 10px;
`

type Props = {
  closeHandler: () => void
}

export const RobModal = ({ closeHandler }: Props) => {
  const imoNo = useContext(VesselPageContext).imoNo!
  const [stockOnBoard, setStockOnBoard] = useState<StockOnBoard>()
  const [timestamp, setTimestamp] = useState<Moment>()
  const [rob, setRob] = useState<Performance.FuelOilStock.RobResponse>()

  useEffect(() => {
    if (timestamp) {
      setStockOnBoard(undefined)
      PerformanceApi.getRob(imoNo, timestamp.toISOString())
        .then((data) => {
          if (data.hasData) {
            setStockOnBoard(
              mapStockOnBoard(data.batchQuantities, data.fuelTypeQuantities),
            )
          } else {
            setStockOnBoard(mapStockOnBoard([], []))
          }
          setRob(data)
        })
        .catch((_) =>
          displayErrorModal({
            statusText: 'Could not receive ROB',
            message: 'Could not receive remaining on board at time of loss',
          }),
        )
    }
  }, [timestamp, imoNo])

  useEffect(() => setTimestamp(getClosestTenMinute(moment().utc())), [imoNo])

  return (
    <Modal
      title={'Get ROB at a specific time'}
      visible
      closeHandler={closeHandler}
      helpTextKey='stock/rob-at-specific-time'
    >
      <Wrapper>
        {rob?.notifications && <StockRobNotifications rob={rob} />}
        <DateWrapper>
          <FieldLabel>Select date and time UTC</FieldLabel>
          <DateTimeInput
            selected={moment.utc(timestamp)}
            minuteSpecific={false}
            onChange={(timestamp: Moment) => {
              setTimestamp(timestamp)
            }}
          />
        </DateWrapper>
        {!stockOnBoard && <Loading />}
        {stockOnBoard && (
          <>
            <FuelTypeTotals stockOnBoard={stockOnBoard} />
            <FuelTypeBatches stockOnBoard={stockOnBoard} width='950px' />
          </>
        )}
      </Wrapper>
      <ModalControls>
        <McButton
          label='Close'
          appearance='primary'
          click={closeHandler}
          type='button'
        />
      </ModalControls>
    </Modal>
  )
}
