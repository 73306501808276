import React from 'react'
import { type Moment } from 'moment'
import styled from 'styled-components'
import { McButton, McSelectNative } from '@maersk-global/mds-react-wrapper'

import {
  displayErrorModal,
  doDownload,
  getPerformanceApiUrl,
  isShoreContext,
  setMaxDateAsToday,
} from '../../../utils'
import { Performance } from '../../../api-models'
import {
  DateTimeInput,
  FormElements,
  Loading,
  Modal,
  ModalContent,
  ModalControls,
} from '../../../commons'
import { getExport } from '../../../services/performance'

const selectOptions = () => {
  let options = [
    {
      label: 'CAMS/ICS',
      value: '2',
    },
    {
      label: 'Flowmeters',
      value: '1',
    },
  ]
  if (isShoreContext()) {
    options.splice(1, 0, {
      label: 'Detailed CAMS',
      value: '3',
    })
  }
  return options
}

const Wrapper = styled.div`
  .content {
    padding: 16px;
  }

  .loading {
    text-align: center;
    padding: 16px;
    width: 100%;
    box-sizing: border-box;
  }

  .modal-content {
    /* we don't want the export form overflow just because we click the time picker. Time field would be hidden behind modal controls */
    overflow-y: visible !important;
  }
`

interface ExportDataProps {
  closeHandler: Function
  visible: boolean
  imoNo: string
  vesselMetadata: MasterDataApi.VesselParameters.General.BasicInfo.Data
}

interface ExportDataState {
  upperDateTime: Moment | null
  lowerDateTime: Moment | null
  selectedExport: number
  exportResponse?: Performance.Export.Report
  loading?: boolean
  userHasClicked?: boolean
  dateError?: boolean
}

const defaultState = {
  upperDateTime: null,
  lowerDateTime: null,
  selectedExport: -1,
  loading: false,
  userHasClicked: false,
  dateError: false,
  exportResponse: undefined,
}

export default class ExportData extends React.Component<
  ExportDataProps,
  ExportDataState
> {
  constructor(props: ExportDataProps) {
    super(props)

    this.state = defaultState
  }

  getDataExport() {
    const { selectedExport, lowerDateTime, upperDateTime } = this.state
    const { imoNo, vesselMetadata } = this.props
    const reportType = selectOptions().find(
      (option) => +option.value === this.state.selectedExport,
    )!
    if (upperDateTime && lowerDateTime) {
      const to = upperDateTime.isAfter(lowerDateTime)
        ? upperDateTime
        : lowerDateTime
      const from = upperDateTime.isBefore(lowerDateTime)
        ? upperDateTime
        : lowerDateTime
      const period = {
        to: to.utc().endOf('minute').toISOString(),
        from: from.utc().endOf('minute').toISOString(),
      }

      getExport(imoNo, selectedExport, period)
        .then((json: Performance.Export.Report) => {
          return doDownload(
            `${getPerformanceApiUrl()}/FileDownload/${json.id}`,
            `${imoNo} ${vesselMetadata.name} ${
              reportType ? reportType.label : 'Data export'
            } ${upperDateTime.startOf('minute').toISOString()} ${lowerDateTime
              .startOf('minute')
              .toISOString()}.xlsx`,
          )
            .then((response) => {
              this.setState({ loading: false })
              this.props.closeHandler()
            })
            .catch((e) => {
              this.setState({ loading: false })
              displayErrorModal(e)
            })
        })
        .catch((e) => {
          this.setState({ loading: false })
          displayErrorModal(e)
        })
    } else {
      this.setState({
        dateError: true,
        loading: false,
      })
    }
  }

  componentDidUpdate() {}

  render() {
    const {
      selectedExport,
      lowerDateTime,
      upperDateTime,
      loading,
      userHasClicked,
      dateError,
    } = this.state
    const invalidRequest = selectedExport === -1
    const lowerDateTimeMin = upperDateTime
      ? upperDateTime.clone().subtract(selectedExport === 3 ? 7 : 59, 'days')
      : undefined
    const lowerDateTimeMax = upperDateTime
      ? upperDateTime.clone().add(selectedExport === 3 ? 7 : 59, 'days')
      : undefined
    const upperDateTimeMin = lowerDateTime
      ? lowerDateTime.clone().subtract(selectedExport === 3 ? 7 : 59, 'days')
      : undefined
    const upperDateTimeMax = lowerDateTime
      ? lowerDateTime.clone().add(selectedExport === 3 ? 7 : 59, 'days')
      : undefined
    return (
      <Modal
        closeHandler={this.props.closeHandler}
        visible={this.props.visible}
        title='Export data'
      >
        <Wrapper id={'dataExport'}>
          {loading && (
            <div className='loading'>
              <Loading />
              Hang on! We are fishing for your data as fast as we can...
            </div>
          )}
          {!loading && (
            <FormElements.Form
              onSubmit={(event) => {
                event.preventDefault()
                if (!invalidRequest) {
                  this.setState({ loading: true })
                  this.getDataExport()
                }
                this.setState({ userHasClicked: true })
              }}
            >
              <ModalContent>
                <div className='content'>
                  <FormElements.Table>
                    <tbody>
                      <tr>
                        <td>
                          <FormElements.Label>
                            Select what data to export
                          </FormElements.Label>
                        </td>
                        <td>
                          <FormElements.Value>
                            <McSelectNative
                              hiddenlabel
                              placeholder='Select...'
                              options={selectOptions()}
                              change={({ detail }: CustomEvent) => {
                                const value = detail.selectedOptions[0]?.value
                                this.setState({
                                  selectedExport: value ? +value : -1,
                                })
                              }}
                            />
                          </FormElements.Value>
                        </td>
                      </tr>
                      {invalidRequest && userHasClicked && (
                        <tr>
                          <td />
                          <td>
                            <FormElements.Error>
                              Please select the type of data you want to export
                            </FormElements.Error>
                          </td>
                        </tr>
                      )}
                      <tr>
                        <td>
                          <FormElements.Label>
                            Data export period UTC
                          </FormElements.Label>
                        </td>
                        <td>
                          <DateTimeInput
                            onChange={(moment) => {
                              this.setState({
                                upperDateTime: moment,
                              })
                            }}
                            selected={upperDateTime}
                            minDate={upperDateTimeMin}
                            maxDate={setMaxDateAsToday(upperDateTimeMax)}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td />
                        <td>
                          <DateTimeInput
                            onChange={(moment) => {
                              this.setState({ lowerDateTime: moment })
                            }}
                            selected={lowerDateTime}
                            minDate={lowerDateTimeMin}
                            maxDate={setMaxDateAsToday(lowerDateTimeMax)}
                          />
                        </td>
                      </tr>
                      {dateError && (
                        <tr>
                          <td />
                          <td>
                            <FormElements.Error>
                              Please select a date range
                            </FormElements.Error>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </FormElements.Table>
                </div>
              </ModalContent>
              <ModalControls>
                <McButton
                  type='button'
                  appearance='neutral'
                  id='cancel'
                  click={() => {
                    this.setState(defaultState)
                    this.props.closeHandler()
                  }}
                >
                  Cancel
                </McButton>
                <McButton id='submit' type='submit'>
                  Export
                </McButton>
              </ModalControls>
            </FormElements.Form>
          )}
        </Wrapper>
      </Modal>
    )
  }
}
