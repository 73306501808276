export const getDataPointFeedtype: (activeFeed: string) => number | null = (
  activeFeed,
) => {
  switch (activeFeed) {
    case 'CAMS':
      return 1
    case 'Consumption':
      return 2
    case 'Bunkering':
      return 3
    default:
      return null
  }
}

export const getStockEntryName = (type: number) => {
  switch (type) {
    case 1:
      return 'Bunkering'
    case 5:
      return 'ROB Mark'
    case 10:
      return 'Sounding'
    case 20:
      return 'Loss'
    case 21:
      return 'Debunkering'
    default:
      return ''
  }
}
