import styled from 'styled-components'

export const Wrapper = styled.div`
  padding-bottom: 16px;

  .legend {
    padding: 8px 0 0 24px;
    display: flex;
    .toggles {
      display: flex;
      * {
        margin: 0 8px 0 0;
      }
    }
  }
`

export const BatchAndCorrectionsWrapper = styled.section`
  border: 1px solid #e0e2e4;
  border-radius: 2px;
  margin: 20px;
  padding: 0 10px;
`

export const BatchWrapper = styled.div`
  padding: 20px 0;
  position: relative;
`

export const CorrectionsTrigger = styled.button<{
  isCorrectionsShown: boolean
}>`
  background: transparent;
  border: 0 none;
  bottom: 2px;
  font-size: 11px;
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;

  p {
    display: inline;
    position: relative;
    margin-right: 10px;
    padding-right: 10px;

    &::after {
      border-top: 1px solid #141414;
      border-left: 1px solid #141414;
      bottom: ${(props) => (props.isCorrectionsShown ? '1px' : '5px')};
      content: ' ';
      display: inline-block;
      height: 5px;
      margin-left: 5px;
      position: absolute;
      right: 0;
      transform: rotate(
        ${(props) => (props.isCorrectionsShown ? '45' : '225')}deg
      );
      width: 5px;
    }
  }

  &:focus {
    outline: 0 none;
  }
`

export const AccordionsWrapper = styled.section`
  padding: 0 14px;
`
