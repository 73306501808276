import { useContext, useState } from 'react'
import {
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  type SortingState,
  useReactTable,
} from '@tanstack/react-table'

import { getColumns } from './TemAdviceLog.utils'
import { Wrapper } from './TemAdviceLog.styles'
import TemAdviceLogFilters from './TemAdviceLogFilters'
import {
  useDismissNotification,
  useNotifications,
} from '../../../queries/NotificationsApi/NotificationsApi'
import {
  NotificationCategories,
  NotificationTypes,
} from '../../../queries/NotificationsApi/NotificationsApi.consts'
import { VesselPageContext, WindowContext } from '../../../contexts'
import Table from '../../../components/Table/Table'
import { handleDismissAdvice } from '../../../components/TemNotifications/TemNotifications.utils'
import { Loading } from '../../../commons'

const TemAdviceLog = () => {
  const { windowSize } = useContext(WindowContext)
  const imoNo = useContext(VesselPageContext).imoNo!

  const [sorting, setSorting] = useState<SortingState>([
    { desc: true, id: 'created_at' },
  ])

  const filter: NotificationsAPI.NotificationsQueryFilter = {
    category: NotificationCategories.Energy,
    type: NotificationTypes.EPA,
    imo_no: +imoNo,
  }

  const dismissNotification = useDismissNotification(filter)

  const { data: notifications, isSuccess: isNotificationsSuccess } =
    useNotifications(filter)

  const handleDismissAdviceClick = async (
    notification: NotificationsAPI.TemAdvice,
  ) => {
    const notificationResponse = await handleDismissAdvice(notification)
    if (!notificationResponse) return
    dismissNotification.mutate(notificationResponse)
  }

  const table = useReactTable({
    data: notifications || [],
    columns: getColumns(handleDismissAdviceClick, windowSize),
    initialState: {
      pagination: { pageSize: 25 },
    },
    state: { sorting },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  })

  if (!isNotificationsSuccess) {
    return <Loading />
  }

  return (
    <Wrapper>
      <TemAdviceLogFilters table={table} />
      <Table table={table} />
    </Wrapper>
  )
}

export default TemAdviceLog
