import * as colors from './colors'
import { IThemeInterface } from './'

export const primary: IThemeInterface = {
  bg: colors.maritimeBlue[500],
  fg: '#fff',
  border: colors.maritimeBlue[500],
  hover: colors.maritimeBlue[600],
  active: '#3e6f88',
}

export const danger: IThemeInterface = {
  bg: '#EDC0CC',
  fg: '#920030',
  border: '#920030',
}

export const warningTheme: IThemeInterface = {
  bg: colors.orange[100],
  fg: colors.orange[800],
  border: colors.orange[900],
  icon: 'exclamation-triangle',
  font: colors.orange[800],
}

export const errorTheme: IThemeInterface = {
  bg: colors.red[100],
  fg: colors.red[800],
  border: colors.red[800],
  icon: 'hand-paper',
  font: colors.red[800],
}

export const info: IThemeInterface = {
  bg: '#e0f0f6',
  fg: '#1d5c83',
  border: colors.maerskBlue[500],
  icon: 'info-circle',
  font: colors.maerskBlue[900],
}

export const inputDefault: IThemeInterface = {
  bg: colors.grey[100],
  fg: '#000',
  border: colors.grey[300],
}

export const normal: IThemeInterface = {
  bg: '#fff',
  fg: '#000000',
  hover: colors.grey[50],
  border: colors.maritimeBlue[500],
}

export const navMainControl: IThemeInterface = {
  bg: '#3486B5',
  fg: '#fff',
  border: 'transparent',
}

export const orangeTheme: IThemeInterface = {
  bg: colors.orange[500],
  border: colors.orange[500],
  fg: '#000',
} as IThemeInterface

export const purpleTheme: IThemeInterface = {
  bg: colors.purple[500],
  border: colors.purple[500],
  fg: '#000',
} as IThemeInterface

export const maerskBlueTheme: IThemeInterface = {
  bg: colors.maerskBlue[500],
  border: colors.maerskBlue[500],
  fg: '#000',
} as IThemeInterface

export const seaGreenTheme: IThemeInterface = {
  bg: colors.seaGreen[500],
  border: colors.seaGreen[500],
  fg: '#000',
} as IThemeInterface

export const maritimeBlueTheme: IThemeInterface = {
  bg: colors.maritimeBlue[500],
  border: colors.maritimeBlue[500],
  fg: '#fff',
} as IThemeInterface

export const fillOpacity: (
  theme: IThemeInterface,
  opacity: number,
) => IThemeInterface = (theme, opacity) => {
  const rgb = hexToRgb(theme.bg)
  return rgb
    ? { ...theme, bg: `rgba(${rgb.r},${rgb.g},${rgb.b},${opacity})` }
    : theme
}

// Found at https://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb
export const hexToRgb: (
  hex: string,
) => { r: number; g: number; b: number } | null = (hex) => {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
  hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b)

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null
}

export const fillOpacityColor = (color: string, opacity: number): string => {
  const rgb = hexToRgb(color)
  return rgb ? `rgba(${rgb.r},${rgb.g},${rgb.b},${opacity})` : color
}
