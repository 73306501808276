import { useContext } from 'react'
import { useField } from 'formik'
import { McOption, McSelect } from '@maersk-global/mds-react-wrapper'

import { WindowContext } from '../../../../contexts'

type Props = {
  name: string
  label: string
  options: Array<{
    label: string
    value: string
  }>
  onChange?: (value: string) => void
}

const MfeInputSelect = ({ name, label, options, onChange }: Props) => {
  const { windowSize } = useContext(WindowContext)
  const [field, meta, helpers] = useField<string | null>(name)

  const handleChange = async ({ detail }: CustomEvent) => {
    await helpers.setValue(detail.value)
    onChange?.(detail.value)
  }

  let _options = options
  let disabled = false
  if (field.value !== null) {
    // If the field has a value, we expect to find that value amongst the
    // given options. It might not be the case, however, if the underlying
    // technical off-service events have changed.
    const optionExistsForFieldValue = !!options.find(
      (o) => o.value === field.value,
    )

    // If we do NOT find the field value amongst the given options, we'll
    // provide a single option matching the field value and disable the field.
    if (!optionExistsForFieldValue) {
      _options = [{ label: field.value, value: field.value }]
      disabled = true
    }
  }

  return (
    <McSelect
      fit={windowSize}
      key={name + '_' + (field.value || 'empty')}
      label={label}
      value={field.value}
      invalid={Boolean(meta.error)}
      errormessage={meta.error}
      optionselected={handleChange}
      disabled={disabled}
    >
      {_options.map((o) => (
        <McOption key={o.value} value={o.value} label={o.label} />
      ))}
    </McSelect>
  )
}

export default MfeInputSelect
