import React, { FunctionComponent } from 'react'
import { SimpleTable } from '../../../../commons'
import styled, { maritimeBlue } from '../../../../theme'
import { RobMarkTableRow } from './RobMarkTableRow'
import { MergedBatchRob } from '../../models'
import { fuelTypeNumberByDisplayOrder } from '../../utils'

const Spacer = styled.tr`
  height: 6px;
`

const Wrapper = styled.div`
  max-width: 600px;
  table {
    min-width: 500px;
    overflow-x: auto;
    table-layout: fixed;
    white-space: nowrap;

    width: 100%;
    font-size: 14px;
    border-collapse: separate;
    border-spacing: 0px;
    tbody tr:hover {
      background-color: rgba(100, 178, 212, 0.1);
    }
    th {
      text-align: left;

      div {
        &:nth-child(2) {
          text-align: left;
          font-style: italic;
          font-weight: 500;
          width: 100%;
        }
      }

      &.fuel-type {
        width: 40%;
      }
      &.sounding {
        width: 30%;
      }
      &.calculated,
      &.adjustment {
        width: 15%;
      }
    }
    td {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      position: relative;
      padding: 8px;
      border-top: 0;
      border-left: none;
      &:last-child {
        border-right: none;
      }

      &:first-child {
        text-align: left;
      }
      span.calculator {
        padding-left: 16px;
        display: flex;
        align-items: center;
        cursor: pointer;
        > i {
          font-size: 16px;
          line-height: 16px;
          > svg {
            color: ${maritimeBlue[500]};
            height: 24px;
            width: 24px;
          }
        }
      }
      text-align: right;
    }
  }
`

export const RobMarkTable: FunctionComponent<{
  batchQuantities: Map<number, MergedBatchRob[]>
}> = ({ batchQuantities }) => {
  return (
    <Wrapper>
      <SimpleTable>
        <thead>
          <tr>
            <th className='fuel-type'>
              <div>Fuel</div>
              <div>&nbsp;</div>
            </th>
            <th className='calculated'>
              <div>Remaining</div>
              <div>MT</div>
            </th>
          </tr>
        </thead>
        <tbody>
          {[Array.from(batchQuantities.keys())].map((fuelTypes) => {
            return fuelTypes
              .sort(fuelTypeNumberByDisplayOrder)
              .map((fuelType, index) => {
                const batches = batchQuantities.get(fuelType)
                if (batches) {
                  return (
                    <RobMarkTableRow
                      key={fuelType + '-' + index}
                      fuelType={fuelType}
                      batches={Array.from(batches.values())}
                    />
                  )
                }
                return ''
              })
          })}
          <Spacer />
        </tbody>
      </SimpleTable>
    </Wrapper>
  )
}
