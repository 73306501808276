import { useContext, useEffect, useState } from 'react'

import { Performance } from '../../../../api-models'
import { Modal } from '../../../../commons'
import {
  displayErrorModal,
  FUEL_LINE_TYPE_TO_HEADER_TEXT_MAPPING,
  FuelLineType,
} from '../../../../utils'
import { FuelLineChangeForm } from './FuelLineChangeForm'
import {
  getFuelTypeSelectionsById,
  putFuelTypeSelection,
} from '../../../../services/performance'
import {
  StockEntryContextProvider,
  VesselPageContext,
} from '../../../../contexts'

type Props = {
  entryId?: string
  fuelLineType?: FuelLineType
  batches?: Performance.FuelOilStock.BatchResponse[]
  currentlySelectedFuelType?: Performance.FDL.FuelTypeSelectionResponse
  closeHandler: () => void
  submit: (fuelTypeSelection: Performance.FDL.FuelTypeSelection) => void
}

export const FuelLineChangeModal = ({
  closeHandler,
  batches,
  entryId,
  submit,
  ...restProps
}: Props) => {
  const [currentlySelectedFuelType, setCurrentlySelectedFuelType] = useState<
    Performance.FDL.FuelTypeSelectionResponse | undefined
  >(restProps.currentlySelectedFuelType)
  const [fuelLineType, setFuelLineType] = useState<FuelLineType | undefined>(
    restProps.fuelLineType,
  )
  const imoNo = useContext(VesselPageContext).imoNo!

  /**
   * Fetching the current fuel type selection if a entry ID
   * is provided, as is the case for edit fuel type selection from
   * the logs table
   */
  useEffect(() => {
    if (!entryId) {
      return
    }

    getFuelTypeSelectionsById(imoNo, entryId).then((fuelTypeSelection) => {
      setCurrentlySelectedFuelType(fuelTypeSelection)

      if (!fuelLineType) {
        setFuelLineType(fuelTypeSelection.fuelLineType)
      }
    })
  }, [entryId, imoNo, fuelLineType])

  const updateFuelLineSelection = (fuelTypeSelection) => {
    if (entryId) {
      return putFuelTypeSelection(imoNo, entryId!, fuelTypeSelection)
        .then(() => submit(fuelTypeSelection))
        .catch((error) => {
          void displayErrorModal({
            statusText: 'Updating fuel type selection error',
            message: error.message,
          })
        })
    }

    submit(fuelTypeSelection)
  }

  return (
    <Modal
      visible
      helpTextKey='consumption-selection/fuel-type'
      title={`Change of ${
        FUEL_LINE_TYPE_TO_HEADER_TEXT_MAPPING[fuelLineType!] || ''
      } fuel type`}
      closeHandler={closeHandler}
    >
      <StockEntryContextProvider imoNo={imoNo}>
        <FuelLineChangeForm
          batches={batches || []}
          closeHandler={closeHandler}
          fuelLineType={fuelLineType!}
          currentlySelectedFuelType={currentlySelectedFuelType}
          entryId={entryId}
          submit={updateFuelLineSelection}
          {...restProps}
        />
      </StockEntryContextProvider>
    </Modal>
  )
}
