import { useContext } from 'react'
import { type Table } from '@tanstack/react-table'

import { WindowContext } from '../../../contexts'
import {
  FilterWrapper,
  ResultsCountTag,
} from '../../../components/Table/Table.styles'
import DateFilter from '../ActivityLogFilters/DateFilter/DateFilter'
import EntryTypeFilter from '../ActivityLogFilters/EntryTypeFilter/EntryTypeFilter'
import { NotificationStateDisplay } from '../../../queries/NotificationsApi/NotificationsApi.consts'
import { DismissalReason } from '../../../components/TemNotifications/TemSubmitModal/TemSubmitModal.consts'

type Props = {
  table: Table<NotificationsAPI.TemAdvice>
}

const TemAdviceLogFilters = ({ table }: Props) => {
  const { windowSize } = useContext(WindowContext)

  const dismissalOptions = {}
  let key: keyof typeof DismissalReason
  for (key in DismissalReason) {
    dismissalOptions[key] = DismissalReason[key]
  }

  return (
    <FilterWrapper>
      <DateFilter table={table} columnId='created_at' />
      <div className='tem-advice-status-filter'>
        <EntryTypeFilter
          table={table}
          label={'Status'}
          availableEntryTypes={NotificationStateDisplay}
          columnId={'state'}
          isStringValues
        />
      </div>
      <div className='tem-advice-reason-filter'>
        <EntryTypeFilter
          table={table}
          label={'Reason for dismissal'}
          availableEntryTypes={dismissalOptions}
          columnId={'dismissalReason'}
          isStringValues
        />
      </div>
      <ResultsCountTag
        appearance='info'
        label={`${table.getFilteredRowModel().rows.length} results`}
        fit={windowSize}
      />
    </FilterWrapper>
  )
}

export default TemAdviceLogFilters
