import { useContext, useState } from 'react'
import styled from 'styled-components'
import { McButton } from '@maersk-global/mds-react-wrapper'

import { HDCContext } from '../context'
import { WindowContext } from '../../../contexts'
import { ApiReportType } from '../../../api-models/hdc/report'
import { ReportTypeNames } from '../constants'
import { type HDCContextProps } from '../context/context'
import HDCNewReportModal from './new-report-modal'
import { ContentCard, ContentCardBody } from './styles'

const ButtonRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`

const HDCReportingOptions = () => {
  const { windowSize } = useContext(WindowContext)
  const { state, setReportType } = useContext<HDCContextProps>(HDCContext)
  const [showModal, toggleModal] = useState(false)

  const { invalidReports, reportType } = state

  const startModal = (type: ApiReportType) => () => {
    setReportType(type)
    toggleModal(true)
  }

  const closeModal = (): void => {
    toggleModal(false)
  }

  return (
    <ContentCard id='reporting-header' title='Add new report'>
      <ContentCardBody flexDirection='column' padded>
        <p style={{ marginBottom: '16px' }}>
          Choose the kind of report you need to make. A new window will open to
          guide you through the reporting process.
        </p>
        <ButtonRow>
          {Object.values(ApiReportType).map((type) => (
            <McButton
              fit={windowSize}
              key={type}
              label={ReportTypeNames[type]}
              appearance={reportType === type ? 'secondary' : 'primary'}
              disabled={!!invalidReports.length}
              trailingicon='plus'
              click={startModal(type)}
              data-e2e={`${ReportTypeNames[type]}`}
            />
          ))}
        </ButtonRow>
      </ContentCardBody>
      {showModal && (
        <HDCNewReportModal
          visible
          onClose={closeModal}
          reportType={reportType!}
        />
      )}
    </ContentCard>
  )
}

export default HDCReportingOptions
