import { FilterConfig } from '../../features/filter'

export const FILTERS_CONFIG: FilterConfig = {
  dateRange: {
    show: true,
    restrictNumberOfDays: 60,
    selections: [
      { value: 23, timeUnit: 'hours' },
      { value: 24, timeUnit: 'hours' },
      { value: 25, timeUnit: 'hours' },
      { value: 48, timeUnit: 'hours' },
      { value: 7, timeUnit: 'days' },
      { value: 14, timeUnit: 'days' },
      { value: 30, timeUnit: 'days' },
    ],
    canSelectMinuteSpecific: true,
  },
  numberOfDigits: {
    show: true,
    selections: [2, 3],
  },
  chartSmoothing: {
    show: false,
  },
}

// This is used for determining batches which aren't in use
export const EMPTY_GUID = '00000000-0000-0000-0000-000000000000'
