import React from 'react'
import { ApiReportType } from '../../../../api-models/hdc/report'
import imgDeparture from '../../../../assets/images/help-section/reporting-port-call-departure.png'
import imgPortNotFound from '../../../../assets/images/help-section/reporting-port-call-port-not-found.png'
import imgFlow from '../../../../assets/images/help-section/reporting-port-call-flow.png'
import { capitalize } from '../../../../utils'

const REPORT_TYPES = [
  {
    name: ApiReportType.ARRIVAL,
    from: 'Pilot station',
    to: 'All fast',
    description:
      'No reporting of ETA and remaining distance tp PS - you are past that point',
  },
  {
    name: ApiReportType.ALONGSIDE,
    from: 'All fast',
    to: 'Last line',
    description: 'Only Engine section of the report has to be submitted',
  },
  {
    name: ApiReportType.DEPARTURE,
    from: 'Last line',
    to: 'Pilot station',
    description:
      'This is the start of a next voyage so Destination, ETA and distance have to be reported. All fields that was carried over from previous report will be cleared and you will have to enter e.g. # of Reefers',
  },
]

export const PortCall = React.forwardRef<HTMLDivElement>((props, ref) => (
  <div ref={ref} id='help-section-subtopic'>
    <h4>In this guide you will learn</h4>
    <ul>
      <li>How a port call should be reported in 3 steps</li>
      <li>To deal with Back-to-back port calls</li>
      <li>What to do if port code is not found</li>
    </ul>
    <h4>Purpose</h4>
    <p>
      The purpose of this guide is to describe the change in reporting process
      from having one Port report with 3 sections in MSPS to now have 3
      different report types that can be submitted individually.
    </p>
    <h4>Prerequisites</h4>
    <ul>
      <li>
        Timestamps should be aligned with the onboard logbooks and Port module
        timestamps
      </li>
      <li>The reported Port codes should be aligned with GSIS schedule</li>
    </ul>
    <h4>Reporting a port call</h4>
    <p>
      A port call will require 3 individual reports to be submitted to cover the
      period from Pilot station to Pilot station.
    </p>
    <img src={imgFlow} alt='Port call flow' />
    <p>
      To have an clear and aligned point in time for reporting we are shifting
      from reporting of Sea passage to Port calls at Pilot station. The 3
      reports covers following:
    </p>
    <table>
      <thead>
        <tr>
          <th>Report types</th>
          <th>From</th>
          <th>To</th>
          <th>What is special</th>
        </tr>
      </thead>
      <tbody>
        {REPORT_TYPES.map((reportType, idx) => (
          <tr key={idx}>
            <td>{capitalize(reportType.name)}</td>
            <td>{reportType.from}</td>
            <td>{reportType.to}</td>
            <td>{reportType.description}</td>
          </tr>
        ))}
      </tbody>
    </table>
    <p className='note'>
      Note: All timestamps in StarConnect are to be reported in UTC time and to
      be aligned with logbook and Pitstop-Port module.
    </p>
    <p>
      When you have back to back port calls with less than 36 hours in between
      you may extend your Departure report to cover until next arrival Pilot
      Station.
    </p>
    <p className='note'>
      Note: When creating the B2B departure report there will be 0 NM remaining
      to PS and your ETA PS should be equal to closing of Departure report (For
      current version 1 min past to bypass issue with date picker logic)
    </p>
    <img src={imgDeparture} alt='Departure' />
    <h4>Port code not found</h4>
    <p>
      We are trying hard to get updates to you but there could be rare cases
      where a specific port code can not be found in the list. In such cases
      please just tick the “Port not in menu” and you will be able to add code
      and name manually.
    </p>
    <img src={imgPortNotFound} alt='Port not found' />
  </div>
))
