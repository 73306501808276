export const SHORE_POWER_USED = {
  REASONS_LIST: [
    'Vessel not equipped to take shore power',
    'Vessel shore power equipment malfunctioning',
    'Port does not provide shore power',
    'Port shore power equipment malfunctioning',
  ],
  DEFAULT_WIDTH: 98,
  TEXT_AREA_ROWS: 5,
}
