import { useState } from 'react'
import { useIsFetching } from '@tanstack/react-query'
import moment from 'moment'
import type { IDateRangeValue } from '@maersk-global/mds-components-core/mc-date-range/types'
import type { MonthYearPickerValue } from '@maersk-global/mds-components-core/mc-month-year-picker/types'

import { Content } from './EquipmentWaste.styles'
import OverallWasteChart from './OverallWasteChart/OverallWasteChart'
import EquipmentWasteChart from './EquipmentWasteChart/EquipmentWasteChart'
import PerformanceMetricsCharts from './PerformanceMetricsCharts/PerformanceMetricsCharts'
import {
  getInitialDateRange,
  getSisterVesselImoList,
} from '../ClassComparisonPage.utils'
import { ContentCard } from '../../../commons'
import {
  ENERGY_METRICS,
  PERFORMANCE_METRICS,
  WASTE_DATA,
} from '../../../queries/BalrogApi/BalrogApi'
import MonthYearPicker from '../../../components/MonthYearPicker/MonthYearPicker'

type Props = {
  sisterVesselsData: MasterDataApi.SisterVessel.SisterVesselResponse
}

const EquipmentWaste = ({ sisterVesselsData }: Props) => {
  const sisterVesselImoList = getSisterVesselImoList(sisterVesselsData)
  const [dateRange, setDateRange] = useState<IDateRangeValue>(() =>
    getInitialDateRange(),
  )

  const isFetchingData =
    useIsFetching([ENERGY_METRICS]) +
    useIsFetching([WASTE_DATA]) +
    useIsFetching([PERFORMANCE_METRICS])

  const now = moment.utc()
  const dateRangeFromMin = moment.max(
    now.clone().subtract(1, 'year'),
    moment.utc('2024-01-01'),
  )
  const monthYearMin: MonthYearPickerValue = {
    month: dateRangeFromMin.month(),
    year: dateRangeFromMin.year(),
  }
  const monthYearMax: MonthYearPickerValue = {
    month: now.month(),
    year: now.year(),
  }

  return (
    <ContentCard id='svc__waste-metrics' title='' hideHeader>
      <Content>
        <MonthYearPicker
          dateRange={dateRange}
          setDateRange={setDateRange}
          min={monthYearMin}
          max={monthYearMax}
          isLoading={!!isFetchingData}
        />
        <h3>Overall Machinery Fuel Efficiency</h3>
        <p>
          The graph shows the monthly fuel excess in percentage for all
          machinery, <br /> which is calculated by the difference between the
          actual Performance and class baseline.
        </p>
        <OverallWasteChart
          dateRange={dateRange}
          sisterVesselImoList={sisterVesselImoList}
        />
        <h3>Main Engine Fuel Efficiency</h3>
        <p>
          The graph shows the monthly Fuel Excess in percentage for the Main
          Engine, which is calculated by the difference between the Actual
          Performance and Class Baseline.
        </p>
        <EquipmentWasteChart
          dateRange={dateRange}
          sisterVesselImoList={sisterVesselImoList}
        />
        <PerformanceMetricsCharts
          sisterVesselImoList={sisterVesselImoList}
          dateRange={dateRange}
        />
      </Content>
    </ContentCard>
  )
}

export default EquipmentWaste
