import { useContext } from 'react'
import { type Table } from '@tanstack/react-table'
import { McSelectNative } from '@maersk-global/mds-react-wrapper'

import { WindowContext } from '../../../../contexts'
import { fuelTypeFilterOptions } from './helpers'
import { type UserEntry } from '../../../../api-models/performance/fuel-oil-stock'

type Props = {
  table: Table<UserEntry>
}

const FuelTypeFilter = ({ table }: Props) => {
  const { windowSize } = useContext(WindowContext)

  const handleTypeChange = (event: CustomEvent) => {
    const name = (event.target as HTMLSelectElement).name
    const column = table.getColumn(name)
    if (event.detail.selectedOptions.length === 0) {
      column!.setFilterValue(undefined)
    } else {
      column!.setFilterValue(+event.detail.selectedOptions[0].value)
    }
  }

  return (
    <McSelectNative
      className='select-filter'
      fit={windowSize}
      name='fuelType'
      label='Fuel type'
      placeholder='All'
      options={fuelTypeFilterOptions}
      change={handleTypeChange}
      data-e2e='FuelType'
    />
  )
}

export default FuelTypeFilter
