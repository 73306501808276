import { useMemo, useState } from 'react'
import Select from 'react-select'
import styled from 'styled-components'
import { McButton } from '@maersk-global/mds-react-wrapper'

import { FuelLineType, FuelType, isShoreContext } from '../../../../utils'
import { ModalControls } from '../../../../commons'
import { Performance } from '../../../../api-models'

const InitialFuelTypeSelectionContent = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
`

const SelectContainer = styled.div`
  width: 200px;
`

const FieldText = styled.span`
  width: 280px;
  font-size: 14px;
  text-align: right;
  margin-right: 8px;
`

interface Option {
  label: string
  value: FuelType
}

const ALL_FUEL_TYPE_OPTIONS: Option[] = [
  { label: FuelType[FuelType.HS], value: FuelType.HS },
  { label: FuelType[FuelType.VLS], value: FuelType.VLS },
  { label: FuelType[FuelType.ULS], value: FuelType.ULS },
  { label: FuelType[FuelType.MDO], value: FuelType.MDO },
]

type Props = {
  fuelLineType: FuelLineType
  currentlySelectedFuelType?: Performance.FuelOilStock.FuelTypeSelection
  closeHandler: () => void
  onSave: (fuelType: FuelType) => void
  uniqueFuelTypesOnboard: FuelType[]
}

export const FormFuelLineChange = ({
  closeHandler,
  onSave,
  currentlySelectedFuelType,
  uniqueFuelTypesOnboard,
}: Props) => {
  const [selectedFuelTypeOption, setSelectedFuelTypeOption] = useState<Option>()
  const disabled = isShoreContext() || !selectedFuelTypeOption?.value

  const fuelTypeOptions = useMemo(
    () =>
      ALL_FUEL_TYPE_OPTIONS.filter(({ value }) =>
        uniqueFuelTypesOnboard.includes(value),
      ),
    [uniqueFuelTypesOnboard],
  )

  return (
    <>
      <InitialFuelTypeSelectionContent>
        <FieldText>What fuel type is currently consumed?</FieldText>
        <SelectContainer>
          <Select
            options={fuelTypeOptions}
            isClearable={false}
            isSearchable={false}
            value={selectedFuelTypeOption}
            styles={{
              menu: (provided) => ({
                ...provided,
                width: '200px',
              }),
              option: (provided) => ({
                ...provided,
                fontSize: '14px',
              }),
              valueContainer: (provided) => ({
                ...provided,
                fontSize: '14px',
              }),
            }}
            onChange={(option) => setSelectedFuelTypeOption(option as Option)}
          />
        </SelectContainer>
      </InitialFuelTypeSelectionContent>
      <ModalControls>
        <McButton label='Cancel' click={closeHandler} type='button' />
        <McButton
          label='Add change'
          appearance='primary'
          disabled={disabled}
          click={(event) => {
            event.preventDefault()
            event.stopPropagation()
            if (selectedFuelTypeOption?.value) {
              onSave(selectedFuelTypeOption.value)
            }
          }}
          type='button'
        />
      </ModalControls>
    </>
  )
}
