import { FilterFn } from '@tanstack/react-table'

import { UserEntryTypes } from '../../../../features/stock-management'
import { ELubeOilEntryType } from '../../../lube-oil/types'

export const getEntryTypeFilterOptions = (
  available: { [k: number]: string },
  allowed?: Array<UserEntryTypes | ELubeOilEntryType>,
) =>
  Object.entries(available)
    .map(([value, label]) => ({ value, label }))
    .filter((option) => allowed?.includes(+option.value) ?? true)

export const entryTypeFilter: FilterFn<any> = (
  row,
  columnId,
  entryType: UserEntryTypes,
) => row.original[columnId] === entryType
