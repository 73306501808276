import { FilterConfig } from '../../features/filter'

export const filtersConfig: FilterConfig = {
  dateRange: {
    show: true,
    restrictNumberOfDays: 60,
    selections: [
      { value: 24, timeUnit: 'hours' },
      { value: 48, timeUnit: 'hours' },
      { value: 7, timeUnit: 'days' },
      { value: 14, timeUnit: 'days' },
      { value: 30, timeUnit: 'days' },
    ],
    canSelectMinuteSpecific: false,
  },
  numberOfDigits: {
    show: false,
    selections: [],
  },
  chartSmoothing: {
    show: false,
  },
}
