import * as React from 'react'
import styled, { maerskBlue, grey, sizes } from '../../../theme'
import { Performance } from '../../../api-models'
import { withFeatureToggling } from '../../../higher-order-components'
import { SimpleDial } from './SimpleDial'

const Wrapper = styled.div<{ hasTwoMainEngines: boolean }>`
  display: flex;
  justify-content: center;
  width: ${(props) => (props.hasTwoMainEngines ? sizes.half : sizes.quarter)}px;
  .dials {
    width: ${sizes.quarter}px;
    &.border {
      border-right: 1px solid ${grey[200]};
    }
  }
`

interface MainEngStatus {
  mainEng1: {
    rpm?: number
    maxRpm?: number
  }
  mainEng2: {
    rpm?: number
    maxRpm?: number
  }
}

export interface MainEngineRPMDialProps {
  vesselStatus: Performance.Status.Status
  vesselConfiguration: Performance.VesselConfiguration.Configuration
}

export interface MainEngineRPMDialState {}

export class MainEngineRPMDial extends React.PureComponent<
  MainEngineRPMDialProps,
  MainEngineRPMDialState
> {
  getMainEngs(): MainEngStatus {
    const { vesselStatus, vesselConfiguration } = this.props
    const { mainEng1, mainEng2 } = vesselStatus
    return {
      mainEng1: {
        rpm: mainEng1 ? mainEng1.shaftRpm : undefined,
        maxRpm: vesselConfiguration.mainEng1.maxShaftRpm,
      },
      mainEng2: {
        rpm: mainEng2 ? mainEng2.shaftRpm : undefined,
        maxRpm: vesselConfiguration.mainEng2.maxShaftRpm,
      },
    }
  }

  public render() {
    const { vesselConfiguration } = this.props
    const { mainEng1, mainEng2 } = this.getMainEngs() as any
    const isDualEngine = vesselConfiguration.hasTwoMainEngines
    return (
      <Wrapper hasTwoMainEngines={isDualEngine}>
        <div className={`dials ${isDualEngine ? 'border' : ''}`}>
          <SimpleDial
            id='dial-me-rpm-1'
            value={mainEng1.rpm}
            color={maerskBlue[500]}
            minValue={0}
            maxValue={mainEng1.maxRpm}
            unit='RPM'
            dialHeader={isDualEngine ? 'Port' : ''}
          />
        </div>
        {isDualEngine && (
          <div className='dials'>
            <SimpleDial
              id='dial-me-rpm-2'
              value={mainEng2.rpm}
              color={maerskBlue[500]}
              minValue={0}
              maxValue={mainEng2.maxRpm}
              unit='RPM'
              dialHeader={'Stbd'}
            />
          </div>
        )}
      </Wrapper>
    )
  }
}

export default withFeatureToggling(MainEngineRPMDial)
