import * as React from 'react'
import { EChartOption, init } from 'echarts'
import {
  getChartData,
  getEngineData,
  getTooltipData,
  tooltipFormatter,
} from '../utils'
import { QueryPeriod } from '../../filter'
import { stripDecimals } from '../../../utils'
import { Chart } from '../../../utils/models'
import {
  GRID,
  AXIS_LINE,
  AXIS_TICK,
  AXIS_SPLIT_LINE,
  AXIS_LABEL,
  TOOLBOX_SCATTER,
  ACTIVATE_ZOOM_ACTION,
} from '../../../utils/constants'
import { orange, grey } from '../../../theme'
import {
  mapShopTestModelsData,
  mapSfocSeries,
  mapLastSixHours,
  checkIfTccoStaticModelExists,
} from '../mapper'
import { EngineChartModel, ToggleInMeSfocChart } from '../models'
import { meMix, meEng1, meEng2 } from '../themes'

interface MeSfocVsPowerChartProps {
  queryPeriod: QueryPeriod
  hasTwoMainEngines: boolean
  hasVesselSpecificEnabled: boolean
  data: HydraApi.Sfoc.MainEngine
  dataShopTestModels?: MasterDataApi.StaticModels.VesselStaticModels<MasterDataApi.StaticModels.StaticModelData>
  dataVesselSpecific?: HydraApi.SfocBaselineModel.BaselineModel
  toggleInChart: ToggleInMeSfocChart
}

interface MeSfocVsPowerChartState {}

export class MeSfocVsPowerChart extends React.Component<
  MeSfocVsPowerChartProps,
  MeSfocVsPowerChartState
> {
  private chartNode: React.RefObject<HTMLDivElement>
  private chart?: Chart

  constructor(props: MeSfocVsPowerChartProps) {
    super(props)
    this.state = {}
    this.chartNode = React.createRef()
  }

  getOptions = (): EChartOption | any => {
    const {
      hasTwoMainEngines,
      dataShopTestModels,
      data,
      toggleInChart,
      dataVesselSpecific,
    } = this.props
    const {
      showShopTestCurve,
      showVesselSpecific,
      showStableDataPointsOnly,
      ME1,
      ME2,
      Mix,
    } = toggleInChart
    const { staticModels } = mapShopTestModelsData(dataShopTestModels)
    const hasTccoStaticModel = checkIfTccoStaticModelExists(staticModels)
    const {
      mainEng1StableSfocSeries,
      mainEng2StableSfocSeries,
      mainEngMixStableSfocSeries,
      mainEng1NonstableSfocSeries,
      mainEng2NonstableSfocSeries,
      mainEngMixNonstableSfocSeries,
    } = mapSfocSeries(data, hasTwoMainEngines)
    const engines = getEngineData(data, hasTwoMainEngines)
    const { ME1LastSixHours, ME2LastSixHours, MixLastSixHours } =
      mapLastSixHours(data, hasTwoMainEngines)
    return {
      grid: GRID,
      xAxis: [
        {
          type: 'value',
          axisLabel: {
            formatter: (value: any) => stripDecimals(value, 1),
            ...AXIS_LABEL,
          },
          axisLine: AXIS_LINE,
          axisTick: AXIS_TICK,
          splitLine: AXIS_SPLIT_LINE,
          min: 0,
          max: 100,
        },
      ],
      yAxis: [
        {
          id: 'mesfoc',
          type: 'value',
          axisTick: AXIS_TICK,
          axisLine: AXIS_LINE,
          splitLine: AXIS_SPLIT_LINE,
          axisLabel: {
            formatter: (value: any) => stripDecimals(value, 1),
            ...AXIS_LABEL,
          },
          min: (value) => value.min,
          max: (value) => value.max,
        },
      ],
      tooltip: {
        trigger: 'item',
        axisPointer: {
          type: 'cross',
          label: {
            show: false,
          },
        },
        textStyle: {
          color: grey[50],
        },
        backgroundColor: 'rgba(50,50,50,0.9)',
        formatter: (params: any) => this.tooltipFormatter(engines, params),
      },
      toolbox: {
        ...TOOLBOX_SCATTER,
        feature: {
          dataZoom: {
            filterMode: 'none',
          },
        },
      },
      legend: {
        show: false,
        selected: {
          ME1: ME1,
          ME2: ME2,
          Mix: Mix,
          ME1NonStable: ME1 && !showStableDataPointsOnly,
          ME2NonStable: ME2 && !showStableDataPointsOnly,
          MixNonStable: Mix && !showStableDataPointsOnly,
          VesselSpecific: showVesselSpecific,
          ShopTestModel: showShopTestCurve,
          LastSixHoursME1: ME1,
          LastSixHoursME2: ME2,
          LastSixHoursMix: Mix,
        },
      },
      dataset: {
        source: {
          mainEng1LoadSeries: data.mainEng1.loadSeries,
          mainEng1StableSfocSeries,
          mainEng1NonstableSfocSeries,
          mainEng2LoadSeries: hasTwoMainEngines ? data.mainEng2.loadSeries : [],
          mainEng2StableSfocSeries,
          mainEng2NonstableSfocSeries,
          mainEngMixLoadSeries: hasTwoMainEngines
            ? data.mainEngMix.loadSeries
            : [],
          mainEngMixStableSfocSeries,
          mainEngMixNonstableSfocSeries,
        },
      },
      /* The order of the data in series matters to the chartConnector function that passes events
        between the scatter and the timeline chart, don't change the order without updating the indexes
        in this function. */
      series: [
        // Sfoc data
        {
          zlevel: 0,
          z: 1,
          xAxisIndex: 0,
          name: 'ME1',
          type: 'scatter',
          symbolSize: 4,
          dimensions: ['mainEng1LoadSeries', 'mainEng1StableSfocSeries'],
          itemStyle: {
            color: meEng1.stroke,
            opacity: 1,
          },
          emphasis: {
            itemStyle: {
              opacity: 1,
            },
            label: {
              show: true,
              position: 'inside',
              offset: [0, 0],
              formatter: () => '●',
              fontSize: 24,
              color: 'purple',
            },
          },
        },
        {
          zlevel: 0,
          z: 1,
          xAxisIndex: 0,
          name: 'ME2',
          type: 'scatter',
          symbolSize: 4,
          dimensions: ['mainEng2LoadSeries', 'mainEng2StableSfocSeries'],
          itemStyle: {
            color: meEng2.stroke,
            opacity: 1,
          },
          emphasis: {
            itemStyle: {
              opacity: 1,
            },
            label: {
              show: true,
              position: 'inside',
              offset: [0, 0],
              formatter: () => '●',
              fontSize: 24,
              color: 'purple',
            },
          },
        },
        {
          zlevel: 0,
          z: 1,
          xAxisIndex: 0,
          name: 'Mix',
          type: 'scatter',
          symbolSize: 4,
          dimensions: ['mainEngMixLoadSeries', 'mainEngMixStableSfocSeries'],
          itemStyle: {
            color: meMix.stroke,
            opacity: 1,
          },
          emphasis: {
            itemStyle: {
              opacity: 1,
            },
            label: {
              show: true,
              position: 'inside',
              offset: [0, 0],
              formatter: () => '●',
              fontSize: 24,
              color: 'purple',
            },
          },
        },
        {
          zlevel: 0,
          z: 0,
          xAxisIndex: 0,
          name: 'ME1NonStable',
          type: 'scatter',
          symbolSize: 4,
          dimensions: ['mainEng1LoadSeries', 'mainEng1NonstableSfocSeries'],
          itemStyle: {
            color: grey[600],
            opacity: 0.2,
          },
          emphasis: {
            itemStyle: {
              opacity: 0.5,
            },
            label: {
              show: true,
              position: 'inside',
              offset: [0, 0],
              formatter: () => '●',
              fontSize: 24,
              color: 'purple',
            },
          },
        },
        {
          zlevel: 0,
          z: 0,
          xAxisIndex: 0,
          name: 'ME2NonStable',
          type: 'scatter',
          symbolSize: 4,
          dimensions: ['mainEng2LoadSeries', 'mainEng2NonstableSfocSeries'],
          itemStyle: {
            color: grey[600],
            opacity: 1,
          },
          emphasis: {
            itemStyle: {
              opacity: 0.5,
            },
            label: {
              show: true,
              position: 'inside',
              offset: [0, 0],
              formatter: () => '●',
              fontSize: 24,
              color: 'purple',
            },
          },
        },
        {
          zlevel: 0,
          z: 0,
          xAxisIndex: 0,
          name: 'MixNonStable',
          type: 'scatter',
          symbolSize: 4,
          dimensions: ['mainEngMixLoadSeries', 'mainEngMixNonstableSfocSeries'],
          itemStyle: {
            color: grey[600],
            opacity: 1,
          },
          emphasis: {
            itemStyle: {
              opacity: 0.5,
            },
            label: {
              show: true,
              position: 'inside',
              offset: [0, 0],
              formatter: () => '●',
              fontSize: 24,
              color: 'purple',
            },
          },
        },
        // Last six hours
        {
          zlevel: 1,
          z: 2,
          xAxisIndex: 0,
          name: 'LastSixHoursME1',
          type: 'scatter',
          symbolSize: 4,
          data: ME1LastSixHours,
          itemStyle: {
            color: 'red',
            opacity: 1,
          },
          emphasis: {
            itemStyle: {
              opacity: 1,
            },
            label: {
              show: true,
              position: 'inside',
              offset: [0, 0],
              formatter: () => '●',
              fontSize: 24,
              color: 'purple',
            },
          },
        },
        {
          zlevel: 1,
          z: 2,
          xAxisIndex: 0,
          name: 'LastSixHoursME2',
          type: 'scatter',
          symbolSize: 4,
          data: ME2LastSixHours,
          itemStyle: {
            color: 'red',
            opacity: 1,
          },
          emphasis: {
            itemStyle: {
              opacity: 1,
            },
            label: {
              show: true,
              position: 'inside',
              offset: [0, 0],
              formatter: () => '●',
              fontSize: 24,
              color: 'purple',
            },
          },
        },
        {
          zlevel: 1,
          z: 2,
          xAxisIndex: 0,
          name: 'LastSixHoursMix',
          type: 'scatter',
          symbolSize: 4,
          data: MixLastSixHours,
          itemStyle: {
            color: 'red',
            opacity: 1,
          },
          emphasis: {
            itemStyle: {
              opacity: 1,
            },
            label: {
              show: true,
              position: 'inside',
              offset: [0, 0],
              formatter: () => '●',
              fontSize: 24,
              color: 'purple',
            },
          },
        },

        // shop test meSfocNormalRangeCurve
        {
          zlevel: 0,
          z: 3,
          xAxisIndex: 0,
          name: 'ShopTestModel',
          type: 'line',
          smooth: false,
          showSymbol: true,
          itemStyle: {
            color: 'black',
          },
          lineStyle: {
            width: 3,
          },
          data: getChartData(staticModels, 'DEFAULT'),
          tooltip: {
            formatter: (params) => getTooltipData(params, 'Shop Test'),
          },
        },
        // shop test meSfocTccoRangeCurve
        {
          zlevel: 0,
          z: 3,
          xAxisIndex: 0,
          name: 'ShopTestModel',
          type: 'line',
          smooth: false,
          showSymbol: true,
          itemStyle: {
            color: 'black',
          },
          lineStyle: {
            width: 3,
          },
          data: hasTccoStaticModel ? getChartData(staticModels, 'TCCO') : [],
          tooltip: {
            formatter: (params) => getTooltipData(params, 'Shop Test'),
          },
        },
        // vessel specific curves
        {
          zlevel: 1,
          z: 1,
          xAxisIndex: 0,
          name: 'VesselSpecific',
          type: 'line',
          smooth: true,
          showSymbol: true,
          symbolSize: 1,
          itemStyle: {
            color: orange[600],
          },
          tooltip: {
            formatter: (params) => getTooltipData(params, 'Vessel specific'),
          },
          lineStyle: {
            width: 3,
          },
          data:
            dataVesselSpecific &&
            dataVesselSpecific.load.map((value, index) => {
              return [
                value,
                dataVesselSpecific.predictedSfoc10Percentile[index],
              ]
            }),
        },
      ],
    }
  }

  tooltipFormatter = (engines: EngineChartModel[], params: any) => {
    const {
      data,
      dataShopTestModels,
      dataVesselSpecific,
      hasTwoMainEngines,
      hasVesselSpecificEnabled,
    } = this.props
    return tooltipFormatter(
      params.dataIndex,
      engines,
      hasTwoMainEngines,
      hasVesselSpecificEnabled,
      data,
      dataVesselSpecific,
      dataShopTestModels,
    )
  }

  zoomOut = () => {
    if (this.chart) {
      this.chart.dispatchAction(
        {
          type: 'dataZoom',
          start: 0,
          end: 100,
        },
        true,
      )
    }
    this.setState({ startValue: undefined, endValue: undefined })
  }

  componentDidMount() {
    if (this.chartNode.current) {
      const chart = init(this.chartNode.current) as Chart
      chart.setOption(this.getOptions())
      chart.dispatchAction(ACTIVATE_ZOOM_ACTION)
      chart.group = 'me-sfoc'
      this.chart = chart
    }
  }

  componentDidUpdate(prevProps: MeSfocVsPowerChartProps) {
    if (!this.chart) return
    const { data, toggleInChart, dataShopTestModels, dataVesselSpecific } =
      this.props
    const {
      showShopTestCurve,
      showVesselSpecific,
      showStableDataPointsOnly,
      ME1,
      ME2,
      Mix,
    } = toggleInChart
    if (
      prevProps.data !== data ||
      prevProps.dataShopTestModels !== dataShopTestModels ||
      prevProps.dataVesselSpecific !== dataVesselSpecific
    ) {
      this.chart.setOption(this.getOptions())
    }
    // Shop test
    if (showShopTestCurve !== prevProps.toggleInChart.showShopTestCurve) {
      this.chart.dispatchAction({
        type: 'legendToggleSelect',
        name: 'ShopTestModel',
      })
    }

    // vessel specific
    if (showVesselSpecific !== prevProps.toggleInChart.showVesselSpecific) {
      this.chart.dispatchAction({
        type: 'legendToggleSelect',
        name: 'VesselSpecific',
      })
    }
    // stable data points filtering
    if (
      (ME1 &&
        showStableDataPointsOnly !==
          prevProps.toggleInChart.showStableDataPointsOnly) ||
      ME1 !== prevProps.toggleInChart.ME1
    ) {
      this.chart.dispatchAction({
        type:
          ME1 && !showStableDataPointsOnly ? 'legendSelect' : 'legendUnSelect',
        name: 'ME1NonStable',
      })
    }
    if (
      (ME2 &&
        showStableDataPointsOnly !==
          prevProps.toggleInChart.showStableDataPointsOnly) ||
      ME2 !== prevProps.toggleInChart.ME2
    ) {
      this.chart.dispatchAction({
        type:
          ME2 && !showStableDataPointsOnly ? 'legendSelect' : 'legendUnSelect',
        name: 'ME2NonStable',
      })
    }
    if (
      (Mix &&
        showStableDataPointsOnly !==
          prevProps.toggleInChart.showStableDataPointsOnly) ||
      Mix !== prevProps.toggleInChart.Mix
    ) {
      this.chart.dispatchAction(
        {
          type:
            Mix && !showStableDataPointsOnly
              ? 'legendSelect'
              : 'legendUnSelect',
          name: 'MixNonStable',
        },
        true,
      )
    }
    // Selecting engines
    if (ME1 !== prevProps.toggleInChart.ME1) {
      this.chart.dispatchAction({
        type: ME1 ? 'legendSelect' : 'legendUnSelect',
        name: 'ME1',
      })
      this.chart.dispatchAction({
        type: ME1 ? 'legendSelect' : 'legendUnSelect',
        name: 'LastSixHoursME1',
      })
    }
    if (ME2 !== prevProps.toggleInChart.ME2) {
      this.chart.dispatchAction({
        type: ME2 ? 'legendSelect' : 'legendUnSelect',
        name: 'ME2',
      })
      this.chart.dispatchAction({
        type: ME2 ? 'legendSelect' : 'legendUnSelect',
        name: 'LastSixHoursME2',
      })
    }
    if (Mix !== prevProps.toggleInChart.Mix) {
      this.chart.dispatchAction({
        type: Mix ? 'legendSelect' : 'legendUnSelect',
        name: 'Mix',
      })
      this.chart.dispatchAction({
        type: Mix ? 'legendSelect' : 'legendUnSelect',
        name: 'LastSixHoursMix',
      })
    }
  }

  public render() {
    return (
      <div
        onDoubleClick={() => {
          this.zoomOut()
        }}
        id={`me-sfoc-vs-power-chart`}
        ref={this.chartNode}
      />
    )
  }
}
