// libs
import { useEffect, useMemo } from 'react'
import { InteractionStatus } from '@azure/msal-browser'
import { useIsAuthenticated, useMsal } from '@azure/msal-react'
import { ThemeProvider } from 'styled-components'
import * as moment from 'moment'

// internals
import { WindowContextProvider } from './contexts'
import Routes from './routes'
import { GlobalStyles, lightTheme } from './theme_new'
import { isLocalhost, isShoreContext } from './utils'

// TODO REMOVE THIS, PUT IT IN *.config.js
if (isLocalhost()) {
  if (typeof (window as any).config.APP_VERSION !== 'string') {
    ;(window as any).config.APP_BUILD_NUMBER = `${moment
      .utc()
      .format('YYYYMMDD')}.1`
  }
  if (typeof (window as any).config.APP_BUILD_NUMBER !== 'string') {
    ;(window as any).config.APP_BUILD_NUMBER = `${moment
      .utc()
      .format('YYYYMMDD')}.1`
  }
}

const ShoreApp = () => {
  const isAuthenticated = useIsAuthenticated()
  const { accounts, instance, inProgress } = useMsal()

  const loginRequest = useMemo(
    () => ({
      account: accounts[0],
      scopes: [(window as any).config.azureAd.scope],
      extraQueryParameters: {
        domain_hint: (window as any).config.azureAd.domain,
      },
    }),
    [accounts],
  )

  useEffect(() => {
    if (!isAuthenticated && inProgress === InteractionStatus.None) {
      void instance.loginRedirect(loginRequest)
    }
  }, [inProgress, instance, isAuthenticated, loginRequest])

  if (isAuthenticated) {
    return <Routes />
  }

  return <div>Please wait until we sign you in</div>
}

const App = () => (
  <WindowContextProvider>
    <ThemeProvider theme={lightTheme}>
      <GlobalStyles />
      {isShoreContext() ? <ShoreApp /> : <Routes />}
    </ThemeProvider>
  </WindowContextProvider>
)

export default App
