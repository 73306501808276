import { Batch, FuelLine } from '../../../../api-models/hdc/report'
import { BatchResponse } from '../../../../api-models/performance/fuel-oil-stock'
import { FuelType, FUEL_TYPES_FDL4, FUEL_TYPE_NAMES } from '../../../../utils'
import { fuelTypeNumberByDisplayOrder } from '../../../stock-management/utils'
import { FuelTypeConsumption, HDCBatch } from '../../types'
import { type BatchConsumption, type FuelTypeGroup } from './types'

const sumTotalConsumedMassPerFuelType = (fuelLines: FuelLine[]) => {
  let sum = 0
  fuelLines.forEach((fuelLine: FuelLine) => (sum = sum + fuelLine.consumedMass))
  return sum
}

const sumConsumedMassPerFuelType = (
  batchConsumptions: BatchConsumption[],
): number | null => {
  let sum = 0
  batchConsumptions.forEach(
    (batchConsumption: BatchConsumption) =>
      (sum = sum + (batchConsumption.totalConsumedMass || 0)),
  )
  return sum
}

const sumRobPerFuelType = (batchConsumptions: BatchConsumption[]) => {
  let sum = 0
  batchConsumptions.forEach(
    (batchConsumption: BatchConsumption) => (sum = sum + batchConsumption.rob),
  )
  return sum
}

const mapBatchToFuelTypeRow = (batch: HDCBatch): BatchConsumption => {
  return {
    batchName: batch.name,
    rob: batch.rob,
    fuelLines: batch.fuelLines,
    totalConsumedMass: sumTotalConsumedMassPerFuelType(batch.fuelLines),
  }
}

export const mapFuelConsumptionData = (
  batches: Batch[],
  papiBatches: BatchResponse[],
): FuelTypeGroup[] => {
  const fuelTypeConsumptions: FuelTypeConsumption[] = Object.values(
    FUEL_TYPES_FDL4,
  ).map((fuelType) => {
    const fuelTypeBatches = batches.filter((batch) => {
      const papiBatch = papiBatches.find(({ id }) => id === batch.batchId)
      return papiBatch?.fuel.type === fuelType
    })
    return {
      batches: fuelTypeBatches,
      fuelType,
    }
  })
  const fuelTypeGroups: FuelTypeGroup[] = []

  Object.values(FUEL_TYPES_FDL4)
    .sort(fuelTypeNumberByDisplayOrder)
    .forEach((type: FuelType | string) => {
      const fuelTypeConsumption = fuelTypeConsumptions.find(
        (fuelTypeCons: FuelTypeConsumption) => fuelTypeCons.fuelType === type,
      )
      if (fuelTypeConsumption) {
        const { fuelType, batches } = fuelTypeConsumption
        const batchRows = new Array<BatchConsumption>()
        batches
          .map((batch: Batch): HDCBatch => {
            const papiBatch = papiBatches.find((pb) => pb.id === batch.batchId)
            return {
              ...batch,
              name: papiBatch?.displayName || 'Unknown batch',
            }
          })
          .forEach((batch: HDCBatch) => {
            batchRows.push(mapBatchToFuelTypeRow(batch))
          })
        fuelTypeGroups.push({
          fuelType: FUEL_TYPE_NAMES[fuelType],
          fuelTypeTotal: sumConsumedMassPerFuelType(batchRows),
          fuelTypeRobTotal: sumRobPerFuelType(batchRows),
          batchConsumption: batchRows,
        })
      } else {
        fuelTypeGroups.push({
          fuelType: FUEL_TYPE_NAMES[type],
          fuelTypeTotal: null,
          fuelTypeRobTotal: null,
          batchConsumption: [],
        })
      }
    })

  return fuelTypeGroups
}
