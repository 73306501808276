import { useCallback, useContext, useEffect, useState } from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import moment from 'moment'
import { McButton, McIcon } from '@maersk-global/mds-react-wrapper'

import { ConfirmRecovery, RecoveryWrapper } from './StockManagementPage.styles'
import { Performance } from '../../api-models'
import Notifications from '../../components/Notifications/Notifications'
import TemNotifications from '../../components/TemNotifications/TemNotifications'
import PerformanceAlerts from '../../components/PerformanceAlerts/PerformanceAlerts'
import ConfirmModal from '../../components/ConfirmModal/ConfirmModal'
import { StockEntryContextProvider } from '../../contexts/stock-entry-context'
import { VesselPageContext } from '../../contexts'
import { INotification } from '../../features/notifications/models'
import {
  EntryModals,
  InitialRobModal,
  RecoveryModal,
  StockOnBoard,
} from '../../features/stock-management'
import { BatchBurndown } from '../../features/stock-management/components/batch-burndown/BatchBurndown'
import {
  StockOnBoard as IStockOnBoard,
  StockState,
} from '../../features/stock-management/models'
import {
  getFilteredNotifications,
  mapStockOnBoard,
} from '../../features/stock-management/utils'
import { ContentLayout } from '../../layout'
import { routerParams } from '../../routes'
import * as PerformanceApi from '../../services/performance'
import { info } from '../../theme'
import { displayErrorModal, TEN_MINUTES_IN_MS } from '../../utils'
import { EStockNotificationType } from '../../api-models/performance/common'

interface Props extends RouteComponentProps<routerParams> {}

const StockManagementPage = ({ history }: Props) => {
  const imoNo = useContext(VesselPageContext).imoNo!
  const [showRecoveryModal, setShowRecoveryModal] = useState(false)
  const [
    shouldRenderRecoveryConfirmModal,
    setShouldRenderRecoveryConfirmModal,
  ] = useState<boolean>(true)
  const [alerts, setAlerts] = useState<string[]>([])
  const [advices, setAdvices] = useState<string[]>([])
  const [stockOnBoard, setStockOnBoard] = useState<IStockOnBoard | null>()
  const [stateOfStock, setStateOfStock] = useState<StockState | null>()
  const [recoveryAvailableFromDate, setRecoveryAvailableFromDate] =
    useState<moment.Moment | null>()
  const [batchSelections, setBatchSelections] = useState<
    Performance.FuelOilStock.CurrentBatchSelectionResponse[] | null
  >()
  const [warnings, setWarnings] = useState<string[]>([])

  const getStockStatus = useCallback(
    () =>
      PerformanceApi.getStockStatus(imoNo, true)
        .then((data) => {
          setStockOnBoard(
            data.rob.hasData
              ? {
                  ...mapStockOnBoard(
                    data.rob.batchQuantities,
                    data.rob.fuelTypeQuantities,
                  ),
                  burndown: data.burndown,
                }
              : null,
          )
          setAlerts(
            getFilteredNotifications(
              [...data.notifications, ...data.rob.notifications],
              EStockNotificationType.Error,
            ),
          )
          setAdvices(
            getFilteredNotifications(
              [...data.notifications, ...data.rob.notifications],
              EStockNotificationType.Info,
            ),
          )
          setWarnings(
            getFilteredNotifications(
              [...data.notifications, ...data.rob.notifications],
              EStockNotificationType.Warning,
            ),
          )
          setStateOfStock(data.state)
          setRecoveryAvailableFromDate(moment.utc(data.recoverableFrom))
        })
        .catch((e) =>
          displayErrorModal({
            statusText: 'Could not get stock status',
            message: e.message,
          }).then(() => {
            setStockOnBoard(null)
            setStateOfStock(null)
            setRecoveryAvailableFromDate(null)
            setAlerts([])
            setAdvices([])
            setWarnings([])
          }),
        ),
    [imoNo],
  )

  useEffect(() => {
    void getStockStatus()
    const id = setInterval(getStockStatus, TEN_MINUTES_IN_MS)

    return () => clearInterval(id)
  }, [imoNo, getStockStatus])

  const getBatchSelectionData = useCallback(() => {
    PerformanceApi.getCurrentBatchSelections(imoNo)
      .then(setBatchSelections)
      .catch((e) =>
        displayErrorModal({
          statusText: 'Could not get current batch selections',
          message: e.message,
        }).then(() => setBatchSelections(null)),
      )
  }, [imoNo])

  useEffect(() => {
    if (imoNo) {
      getBatchSelectionData()
    }
  }, [imoNo, getBatchSelectionData])

  const renderPageNotifications = () => {
    let notifications: INotification[] = []
    if (stateOfStock === StockState.RECOVERY) {
      notifications.push({
        id: 'stock-page-notification',
        theme: info,
        content: (
          <RecoveryWrapper>
            <span className='icon'>
              <i className='fal fa-info-circle fa-2x' />
            </span>
            <span>
              ROB needs to be recalculated since no consumption data was found.
              Press "Recovery" to fix it!
            </span>
            <span>
              <McButton
                label='Recovery'
                appearance='neutral'
                fit='small'
                click={() => setShowRecoveryModal(true)}
              />
            </span>
          </RecoveryWrapper>
        ),
      })
    }
    return notifications
  }

  return (
    <StockEntryContextProvider imoNo={imoNo}>
      <ContentLayout
        header='Stock management'
        notifications={renderPageNotifications()}
      >
        <Notifications advices={advices} alerts={alerts} warnings={warnings} />
        <TemNotifications />
        <PerformanceAlerts />
        <div className='full-components'>
          <StockOnBoard
            stockOnBoard={stockOnBoard}
            stateOfStock={stateOfStock}
            batchSelections={batchSelections}
            setShowRecoveryModal={setShowRecoveryModal}
            onSelectionsUpdate={() => {
              void getStockStatus()
              getBatchSelectionData()
            }}
          />
          <BatchBurndown
            imoNo={imoNo}
            burndown={stockOnBoard === null ? null : stockOnBoard?.burndown}
          />
        </div>
        <EntryModals
          onUpdate={() => {
            void getStockStatus()
            getBatchSelectionData()
          }}
        />
        {stateOfStock === StockState.STARTUP && (
          <InitialRobModal
            closeHandler={(redirect: boolean) => {
              if (redirect) {
                history.push('/MaerskStarConnect/vessel/' + imoNo + '/overview')
              }
              void getStockStatus()
              getBatchSelectionData()
            }}
          />
        )}
        {stateOfStock === StockState.RECOVERY &&
          shouldRenderRecoveryConfirmModal && (
            <ConfirmModal
              content={{
                message: (
                  <ConfirmRecovery>
                    <McIcon icon='exclamation-circle' size='24' />
                    <p>
                      Stock module has stopped counting due to data loss. You
                      are now able to track consumption again.
                    </p>
                    <p>
                      Click <strong>Recovery</strong> to insert ROB and select
                      batches in use to start up the stock module again.
                    </p>
                  </ConfirmRecovery>
                ),
                title: 'Data recovery available',
              }}
              cancelButtonText='Not now'
              confirmButtonText='Recovery'
              cancelHandler={() => {
                /* this confirm modal should only be rendered once per page visit while in recovery mode */
                setShouldRenderRecoveryConfirmModal(false)
              }}
              confirmHandler={() => {
                setShowRecoveryModal(true)
                /* this confirm modal should only be rendered once per page visit while in recovery mode */
                setShouldRenderRecoveryConfirmModal(false)
              }}
            />
          )}
        {showRecoveryModal && (
          <RecoveryModal
            recoveryAvailableFromDate={recoveryAvailableFromDate}
            closeHandler={() => {
              setShowRecoveryModal(false)
              void getStockStatus()
              getBatchSelectionData()
            }}
          />
        )}
      </ContentLayout>
    </StockEntryContextProvider>
  )
}
export default withRouter(StockManagementPage)
