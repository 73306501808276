import { createGlobalStyle, type DefaultTheme } from 'styled-components'

import { blue, feedback, fuelType, grey, red } from './colors'
import { shadows } from './shadows'
import { BREAK_POINTS as breakPoints, GAPS as gaps } from './dimensions'

export const GlobalStyles = createGlobalStyle`
  body {
    background-color: ${({ theme }) => theme.colors.background.body};
  }
`

export const lightTheme: DefaultTheme = {
  colors: {
    background: {
      body: grey[100],
    },
    card: grey[50],
    feedback,
    fuelType,
    blue,
    grey,
    red,
  },
  shadows,
  borderRadius: '6px',
  breakPoints,
  gaps,
}

// export const darkTheme: DefaultTheme = {
//   colors: {
//     background: {
//       body: 'black',
//     },
//   },
// };
