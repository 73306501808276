import { useFormikContext } from 'formik'
import { VolumeToMassCalculator } from '../../../volume-to-mass-calculator'

type Props = {
  shouldShowCalculator: boolean
  mixedBatchName: string
  closeHandler: () => void
}

export const VolumeToMassComponent = ({
  shouldShowCalculator,
  mixedBatchName,
  closeHandler,
}: Props) => {
  const { setFieldValue } = useFormikContext<any>()
  return (
    <>
      {shouldShowCalculator && (
        <VolumeToMassCalculator
          onCancel={() => closeHandler()}
          onClose={() => closeHandler()}
          onSave={(totalSum: string) => {
            let quantity
            if (totalSum === '-' || isNaN(+totalSum)) {
              quantity = ''
            } else {
              quantity = parseFloat(totalSum)
            }
            setFieldValue(`${mixedBatchName}`, quantity)
            closeHandler()
          }}
        />
      )}
    </>
  )
}
