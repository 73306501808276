import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  width: 50%;
  gap: 16px;

  > mc-input-date {
    width: 75%;

    &::part(label) {
      white-space: nowrap;
    }
  }
`
