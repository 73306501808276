import { McButton, McModal } from '@maersk-global/mds-react-wrapper'
import moment from 'moment/moment'

import { booleanToYesNo, formatDate, withSuffix } from './SummaryModal.utils'
import { Comment, Item, Heading, Ruler } from './SummaryModal.styles'
import { Row } from '../MalfunctioningEquipmentReportPage/MalfunctioningEquipmentReportPage.styles'
import { MfeEquipmentType as Equipment } from '../MalfunctioningEquipmentPage.consts'
import { statusToDisplayStr } from '../MalfunctioningEquipmentPage.utils'
import { formatTimeDifference } from '../../../utils'
import { MfeStatus } from '../../../queries/GandalfApi/GandalfApi.consts'
import Footer from '../../../components/Reporting/Footer/Footer'

type Props = {
  open: boolean
  onClose: () => void
  report: GandalfApi.MfeReport
}

const SummaryModal = ({ open, onClose, report }: Props) => {
  return (
    <McModal
      dimension='medium'
      height='88vh'
      width='900px'
      open={open}
      closed={onClose}
    >
      <Heading slot='heading'>
        {`Malfunctioning equipment report - No. ${report.reportNo}`}
      </Heading>
      <h4>General info</h4>
      <Row>
        <Item>
          <label>Start date of malfunction</label>
          <p>
            {moment.utc(report.startTimestamp).format('DD MMM YYYY, HH:mm')}
          </p>
        </Item>
        <Item>
          <label>Status of malfunction</label>
          <p style={{ textTransform: 'capitalize' }}>
            {statusToDisplayStr(report.data.status)}
          </p>
        </Item>
        {(report.data.status !== null &&
          report.data.status !== MfeStatus.OutOfService && (
            <Item>
              <label>
                {report.data.status === MfeStatus.Ongoing
                  ? 'Expected due date for repairs'
                  : 'End date of malfunction'}
              </label>
              <p>
                {report.endTimestamp
                  ? moment.utc(report.endTimestamp).format('DD MMM YYYY, HH:mm')
                  : '-'}
              </p>
            </Item>
          )) || <div />}
      </Row>
      <Row>
        <Item>
          <label>Equipment</label>
          <p>{report.data.equipment ?? '-'}</p>
        </Item>
        <Item>
          <label>Component</label>
          <p>{report.data.component ?? '-'}</p>
        </Item>
        <Item>
          <label>Sub-component</label>
          <p>{report.data.subComponent ?? '-'}</p>
        </Item>
      </Row>
      {report.data.equipment === Equipment.MainEngine && (
        <Row>
          <Item>
            <label>ME status</label>
            <p>{report.data.meStatus ?? '-'}</p>
          </Item>
        </Row>
      )}
      <Ruler />
      <h4>Impact on vessel speed</h4>
      <Row>
        {(report.data.impact.stoppage.hasStoppage && (
          <>
            <Item>
              <label>Stoppage from</label>
              <p>{formatDate(report.data.impact.stoppage.startTimestamp)}</p>
            </Item>
            <Item>
              <label>Stoppage to</label>
              <p>{formatDate(report.data.impact.stoppage.endTimestamp)}</p>
            </Item>
            <Item>
              <label>Stoppage duration</label>
              <p>
                {formatTimeDifference(
                  moment
                    .utc(report.data.impact.stoppage.endTimestamp)
                    .diff(
                      moment.utc(report.data.impact.stoppage.startTimestamp),
                      'minutes',
                    ),
                )}
              </p>
            </Item>
          </>
        )) || (
          <Item>
            <label>Was there stoppage</label>
            <p>
              {report.data.impact.stoppage.hasStoppage === null ? '-' : 'No'}
            </p>
          </Item>
        )}
      </Row>
      <Row>
        <Item>
          <label>Was there any change in ETA?</label>
          <p>
            {report.data.impact.etaChange.hasEtaChange === null
              ? '-'
              : report.data.impact.etaChange.hasEtaChange
              ? 'Yes'
              : 'No'}
          </p>
        </Item>
      </Row>
      <Row>
        <Item>
          <label>Is there restriction of ME power?</label>
          <p>
            {(report.data.impact.mePowerRestriction.hasRestriction === null &&
              '-') ||
              (report.data.impact.mePowerRestriction.hasRestriction
                ? `Maximum ME Power: ${withSuffix(
                    report.data.impact.mePowerRestriction.maxPower,
                    'kW',
                  )}`
                : 'No')}
          </p>
        </Item>
        <div />
      </Row>
      <Ruler />
      <h4>Impact on cargo capacity</h4>
      <Row>
        <Item>
          <label>Reefer cargo capacity impacted?</label>
          <p>
            {report.data.impact.cargoCapacity.hasCapacityChange === null
              ? '-'
              : booleanToYesNo(
                  report.data.impact.cargoCapacity.hasCapacityChange,
                )}
          </p>
        </Item>
        {report.data.impact.cargoCapacity.hasCapacityChange && (
          <>
            <Item>
              <label>Reduced electrical production</label>
              <p>
                {withSuffix(
                  report.data.impact.cargoCapacity
                    .electricalProductionReduction,
                  'kWh',
                )}
              </p>
            </Item>
            <Item>
              <label>New maximum reefer capacity</label>
              <p>
                {withSuffix(
                  report.data.impact.cargoCapacity.maxReeferCapacity,
                  'TEU',
                )}
              </p>
            </Item>
          </>
        )}
      </Row>
      <Ruler />
      <h4>Impact on fuel consumption</h4>
      <Row>
        <Item>
          <label>Impact on fuel consumption</label>
          <p>{withSuffix(report.data.impact.fuelConsumption, 'MT')}</p>
        </Item>
        <div />
        <div />
      </Row>
      <Ruler />
      <h4>Solutions</h4>
      <Row>
        <Item>
          <label>Required spare parts?</label>
          <p>{booleanToYesNo(report.data.solution.requireSpareParts)}</p>
        </Item>
        {report.data.solution.requireSpareParts && (
          <Item>
            <label>Spare parts on board?</label>
            <p>{booleanToYesNo(report.data.solution.haveSpareParts)}</p>
          </Item>
        )}
        <div />
      </Row>
      <Row>
        <Comment>
          <label>Comment</label>
          <p>{report.data.comment ?? '-'}</p>
        </Comment>
      </Row>
      <Footer dataFormat={report.dataFormat} />
      <McButton
        label='Close'
        slot='primaryAction'
        appearance='neutral'
        variant='outlined'
        dialogaction='cancel'
      />
    </McModal>
  )
}

export default SummaryModal
