import { Table, Tbody, Th, Thead, Tr } from '../../../commons/tables/styles'
import { UNITS } from '../../../utils/constants'
import { FormEquipment, HDCReportFormValues } from '../types'
import { getOriginalValueNote } from '../utils'
import ReadonlyField from './readonly-field'
import { EquipmentTh, ReadonlyRuntimeTh } from './styles'

type Props = {
  report: HDCReportFormValues
}

const ReadonlyElectricalProducersTable = ({ report }: Props) => {
  const { engineRoom } = report
  const { auxEngines, shaftGenerators, wasteHeatRecovery } = engineRoom

  const getSection = (producer: FormEquipment, title: string, idx = 0) => (
    <Tr key={`${title}-${idx}`}>
      <td>{title}</td>
      <td>
        <ReadonlyField
          addon={UNITS.KILO_WATT_HOUR}
          name={`${title}-${idx}-energy`}
          note={getOriginalValueNote(producer.energy)}
          value={producer.energy.value ?? '-'}
        />
      </td>
      <td>
        <ReadonlyField
          name={`${title}-${idx}-runtime`}
          note={getOriginalValueNote(producer.runTime)}
          value={`${producer.runTime.hours.value ?? '-'}h ${
            producer.runTime.minutes.value ?? '-'
          }m`}
        />
      </td>
    </Tr>
  )

  return (
    <Table>
      <Thead>
        <Tr>
          <EquipmentTh>Producer</EquipmentTh>
          <Th>Energy</Th>
          <ReadonlyRuntimeTh>Runtime</ReadonlyRuntimeTh>
        </Tr>
      </Thead>
      <Tbody striped={true}>
        {auxEngines.map((engine, idx) =>
          getSection(engine, `AE ${engine.number}`, idx),
        )}
        {shaftGenerators.map((generator, idx) =>
          getSection(generator, `Shaft generator ${generator.number}`, idx),
        )}
        {wasteHeatRecovery &&
          getSection(wasteHeatRecovery, 'Waste heat recovery')}
      </Tbody>
    </Table>
  )
}

export default ReadonlyElectricalProducersTable
