import * as moment from 'moment'
import { Performance } from '../../../../api-models'
import { MergedBatch, FormValues } from './MixedBatchDetails'

export const mapMixedBatchToFormValues = (
  batch: Performance.FuelOilStock.MixedBatchEntryResponse,
  mergedBatches: MergedBatch[],
): FormValues => {
  return {
    timestamp: moment.utc(batch.timestamp),
    mixedBatches: mergedBatches,
    notes: batch.notes,
    fuelType: batch.fuel.type,
    fuelGrade: batch.fuel.grade,
    displayName: batch.displayName,
    isDistillate: batch.fuel.isDistillate,
    quantityPerChiefEngineer: batch.quantityPerChiefEngineer,
    bioPercentage: `${batch.fuel.bioPercentage ?? ''}`,
    density15: `${batch.labReport?.density15 ?? ''}`,
    lcv: `${batch.labReport?.lcv ?? ''}`,
    water: `${batch.labReport?.water ?? ''}`,
    ash: `${batch.labReport?.ash ?? ''}`,
    sulphur: `${batch.labReport?.sulphur ?? ''}`,
  }
}

export const mapValuesToMixedBatchUpdateRequest = (
  values: FormValues,
  mixedBatch: Performance.FuelOilStock.MixedBatchEntryResponse,
): Performance.FuelOilStock.MixedBatchEntryUpdateRequest => {
  const formatValue = (val: string | null): number | null =>
    typeof val === 'number' || !!val ? +val : null
  return {
    timestamp: mixedBatch.timestamp,
    batches: mixedBatch.batches,
    notes: values.notes,
    fuel: {
      type: values.fuelType,
      grade: values.fuelGrade,
      isDistillate: values.isDistillate,
      bioPercentage: formatValue(values.bioPercentage) || 0,
    },
    labReport: {
      density15: formatValue(values.density15),
      lcv: formatValue(values.lcv),
      water: formatValue(values.water),
      ash: formatValue(values.ash),
      sulphur: formatValue(values.sulphur),
    },
  }
}

const isMixedBatch = (
  batch:
    | Performance.FuelOilStock.MixedBatchEntryResponse
    | Performance.FuelOilStock.BunkeredBatchResponse,
): batch is Performance.FuelOilStock.MixedBatchEntryResponse => {
  return 'batches' in batch
}

export const mapBatchResponseToMergedBatch = (
  mixedBatch: Performance.FuelOilStock.MixedBatchEntryResponse,
  response:
    | Performance.FuelOilStock.MixedBatchEntryResponse
    | Performance.FuelOilStock.BunkeredBatchResponse,
): MergedBatch | null => {
  const mixed = mixedBatch.batches.find((b) => b.id === response.id)

  if (!mixed) return null

  const mappedBatch = {
    id: mixed.id,
    quantity: mixed.quantity,
    displayName: response.displayName,
    fuelType: response.fuel.type,
    labReport: {
      density15: response.labReport?.density15 ?? null,
      lcv: response.labReport?.lcv ?? null,
      water: response.labReport?.water ?? null,
      ash: response.labReport?.ash ?? null,
      sulphur: response.labReport?.sulphur ?? null,
    },
  }

  return isMixedBatch(response)
    ? mappedBatch
    : {
        ...mappedBatch,
        labReport: {
          ...mappedBatch.labReport,
          marpolSeal: response.labReport?.marpolSeal,
        },
      }
}
