import type {
  TColorVariant,
  TFeedbackLevel,
  TFuelTypeColor,
  THexColorCode,
  TShade,
} from './styled'

/**
 * Blue color palette as defined by
 * [Maersk Design System]{@link https://designsystem.maersk.com/foundations/colours/index.html#primary}
 */
export const blue: Record<TShade, THexColorCode> = {
  50: '#B5E0F5',
  100: '#91D8F2',
  200: '#77C6E0',
  300: '#42B0D5', // Maersk Blue
  400: '#0CA0CE',
  500: '#058EC6',
  600: '#0073AB',
  700: '#005487',
  800: '#003D6D',
  900: '#00243D',
}

/**
 * Grey color palette as defined by
 * [Maersk Design System]{@link https://designsystem.maersk.com/foundations/colours/index.html#functional}
 */
export const grey: Record<TShade, THexColorCode> = {
  50: '#FFFFFF',
  100: '#F7F7F7',
  200: '#EDEDED',
  300: '#DBDBDB',
  400: '#CFCFCF',
  500: '#B2B2B2',
  600: '#878787',
  700: '#545454',
  800: '#353535',
  900: '#141414',
}

/**
 * Red color palette as defined by
 * [Maersk Design System]{@link https://designsystem.maersk.com/foundations/colours/index.html#secondary}
 */
export const red: Record<TShade, THexColorCode> = {
  50: '#FFBAA6',
  100: '#FF9E87',
  200: '#FF8766',
  300: '#FA6A55',
  400: '#FA381C',
  500: '#E0280F',
  600: '#C6280F',
  700: '#A5110C',
  800: '#8C0705',
  900: '#6B0D05',
}

/**
 * Feedback color palette as defined by
 * [Maersk Design System]{@link https://designsystem.maersk.com/foundations/colours/index.html#feedback}
 */
export const feedback: Record<
  TFeedbackLevel,
  Record<TColorVariant, THexColorCode>
> = {
  danger: {
    main: '#B80012',
    light: '#E9B2B7',
  },
  warning: {
    main: '#FFD029',
    light: '#FFEBA8',
  },
  success: {
    main: '#40AB35',
    light: '#C5E5C2',
  },
  info: {
    main: '#42B0D5',
    light: '#B5E0F5',
  },
}

export const fuelType: Record<TFuelTypeColor, THexColorCode> = {
  hs: '#CCD8DFFF',
  vls: '#E3DFF0FF',
  uls: '#E0F0F6FF',
  mdo: '#FFEBD2FF',
}
