import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  margin-left: 24px;

  > mc-tooltip {
    > span {
      display: flex;
      gap: 8px;
      margin: 0 8px 0 0;
    }
  }
`
