import * as React from 'react'
import { Dropdown } from '../../commons'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { UserFavoriteList } from '../../features/manage-favorites'

const DropdownTrigger = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 4px 12px;
  color: #fff;
  box-sizing: border-box;
`

interface ListDropdownProps {
  userLists: UserFavoriteList[]
  currentUserList: number | null
  changeUserList: (index: number) => void
}

interface ListDropdownState {}

export class ListDropdown extends React.Component<
  ListDropdownProps,
  ListDropdownState
> {
  getSelectedUserListIndexFromStorage() {
    const { userLists } = this.props

    let selectedUserListIndex = parseInt(
      window.localStorage.getItem('selectedUserListIndex') || '-1',
    )

    if (
      selectedUserListIndex === -1 ||
      (userLists && userLists[selectedUserListIndex] === undefined)
    ) {
      return 0
    }

    return selectedUserListIndex
  }

  render() {
    const { userLists, currentUserList, changeUserList } = this.props
    if (currentUserList === null) return null
    return (
      <Dropdown
        triggerChildren={
          <DropdownTrigger id='sidebar-dropdown-div'>
            <span>
              {userLists.length > 0 && currentUserList !== null
                ? userLists[this.getSelectedUserListIndexFromStorage()].name
                : 'No lists'}
            </span>
            <span className='fa fa-caret-down' />
          </DropdownTrigger>
        }
        menuPosition={{ top: '40px', right: '8px' }}
      >
        <li id='vessel-lists-id' className='link'>
          <Link
            to={'/MaerskStarConnect/shore/manage-lists'}
            className='vessel-lists'
          >
            Manage lists
          </Link>
          <span className='fal fa-cog' />
        </li>
        <hr />
        <React.Fragment>
          {userLists.map((list, index) => {
            return (
              <li
                id={`favourite-lists-${index}`}
                key={list.name}
                className='link'
              >
                <button
                  onClick={() => {
                    changeUserList(index)
                  }}
                >
                  {list.name}
                </button>
              </li>
            )
          })}
        </React.Fragment>
      </Dropdown>
    )
  }
}
