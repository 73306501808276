export interface FuelTypeSelection {
  timestamp: string
  fuelType: number
  fuelLineType: number
}

export interface FuelTypeSelectionResponse {
  id: string
  readonly: boolean
  timestamp: string
  fuelType: number
  fuelLineType: number
  created: string
  updated: string
}
