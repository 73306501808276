import { Performance } from '../../api-models'
import { AuxEngParams, TEMConfiguration } from './models'

export function getMainEngMcr(
  vesselConfiguration: Performance.VesselConfiguration.Configuration,
) {
  let mcr = vesselConfiguration.mainEng1.mcr * 1000
  if (vesselConfiguration.hasTwoMainEngines) mcr *= 2
  return mcr
}

export function getMaxElectricalPowerProduction(
  vesselConfiguration: Performance.VesselConfiguration.Configuration,
  auxEngParams: AuxEngParams,
) {
  let maxElextricalPower = 0
  if (vesselConfiguration) {
    if (
      vesselConfiguration.whr.isInstalled &&
      typeof vesselConfiguration.whr.maxPower === 'number'
    )
      maxElextricalPower += vesselConfiguration.whr.maxPower
    if (
      vesselConfiguration.shaftGenerator1.isInstalled &&
      typeof vesselConfiguration.shaftGenerator1.maxPower === 'number'
    )
      maxElextricalPower += vesselConfiguration.shaftGenerator1.maxPower
    if (
      vesselConfiguration.shaftGenerator2.isInstalled &&
      typeof vesselConfiguration.shaftGenerator2.maxPower === 'number'
    )
      maxElextricalPower += vesselConfiguration.shaftGenerator2.maxPower
    if (
      auxEngParams.nbrBigAuxEngs > 0 &&
      typeof auxEngParams.maxPowerBigAuxEng === 'number'
    )
      maxElextricalPower +=
        auxEngParams.maxPowerBigAuxEng * auxEngParams.nbrBigAuxEngs
    if (
      auxEngParams.nbrSmallAuxEngs > 0 &&
      typeof auxEngParams.maxPowerSmallAuxEng === 'number'
    )
      maxElextricalPower +=
        auxEngParams.maxPowerSmallAuxEng * auxEngParams.nbrSmallAuxEngs
  }
  return maxElextricalPower
}

export function getAuxEngParams(
  auxEngs: Performance.VesselConfiguration.AuxEng[],
): AuxEngParams {
  let aeParams = auxEngs.reduce(
    (acc: any, auxEng: Performance.VesselConfiguration.AuxEng) => {
      if (!auxEng.isInstalled) return acc
      if (auxEng.isSmall)
        return {
          ...acc,
          nbrSmallAuxEngs: acc.nbrSmallAuxEngs + 1,
          maxPowerSmallAuxEng: auxEng.maxPower,
        }
      else
        return {
          ...acc,
          nbrBigAuxEngs: acc.nbrBigAuxEngs + 1,
          maxPowerBigAuxEng: auxEng.maxPower,
        }
    },
    {
      nbrBigAuxEngs: 0,
      nbrSmallAuxEngs: 0,
      maxPowerBigAuxEng: undefined,
      maxPowerSmallAuxEng: undefined,
    },
  )
  let installedEngines = new Set<number>()
  auxEngs
    .filter((auxEng) => auxEng.isInstalled)
    .forEach((auxEng) => installedEngines.add(auxEng.number))
  aeParams.installedEngines = installedEngines
  return aeParams
}

export function getTEMConfiguration(
  vesselConfiguration: Performance.VesselConfiguration.Configuration,
): TEMConfiguration {
  const { whr, shaftGenerator1, shaftMotor1 } = vesselConfiguration
  if (whr.isInstalled) {
    if (shaftGenerator1.isInstalled || shaftMotor1.isInstalled)
      return TEMConfiguration.BOTH
    return TEMConfiguration.FIXED_POWER_ONLY
  }
  return TEMConfiguration.VARIABLE_POWER_ONLY
}
