import ReactTooltip from 'react-tooltip'
import styled from 'styled-components'

import { Icon } from '../../../commons'
import { maerskBlue } from '../../../theme'

const Wrapper = styled.div<{ label?: string }>`
  display: inline-block;
  margin-bottom: 8px;

  p {
    display: ${({ label }) => (label ? 'block' : 'none')};
    margin: 2px 0 2px;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    color: #6c6c6c;
  }
`

const Value = styled.span<{ label?: string }>`
  font-size: 14px;
  font-weight: ${({ label }) => (label ? '600' : '400')};
`

const AddOn = styled.span`
  margin-left: 4px;
`

const Note = styled(Icon)`
  padding: 0 4px;
  color: ${maerskBlue[700]};
`

type Props = {
  name: string
  value: number | string
  label?: string
  addon?: string
  note?: string | null
}

const ReadonlyField = ({ name, value, label, addon, note }: Props) => {
  return (
    <Wrapper label={label}>
      <div>
        <p>{label}</p>
        <Value label={label}>
          {value}
          <AddOn>{addon}</AddOn>
          {note && (
            <span data-tip={note} data-for={'note-' + name}>
              <ReactTooltip className='input-note' id={'note-' + name} />
              <Note icon='fal fa-exclamation-circle' />
            </span>
          )}
        </Value>
      </div>
    </Wrapper>
  )
}

export default ReadonlyField
