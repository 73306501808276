import { formatValue } from '../../../utils'

export const getDifference = (a: number, b: number) => {
  if (a === 0) {
    return '-'
  }

  let diff = 0
  diff = ((a - b) / a) * 100

  return formatValue(Math.abs(diff), 1)
}
