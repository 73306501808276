export const lcvCalculation: (
  density15: number,
  sulphur: number,
  water: number,
  ash: number,
) => number = (density15, water, sulphur, ash) => {
  const lcv =
    ((46.704 -
      8.802 * density15 * density15 * 0.000001 +
      3.167 * density15 * 0.001) *
      (1 - 0.01 * (water + ash + sulphur)) +
      0.01 * (9.42 * sulphur - 2.449 * water)) *
    1000
  return Math.round(lcv)
}
