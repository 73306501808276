import * as React from 'react'
import styled, { grey, maritimeBlue, sizes } from '../../../theme'
import { SimpleDial } from './SimpleDial'
import { Performance } from '../../../api-models'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  .dials {
    width: ${sizes.quarter}px;
    &.border {
      border-right: 1px solid ${grey[200]};
    }
  }
`

interface ShaftMotorStatus {
  shaftMotor1: {
    power?: number
    maxPower?: number
  }
  shaftMotor2: {
    power?: number
    maxPower?: number
  }
}
export interface ShaftMotorDialProps {
  vesselStatus: Performance.Status.Status
  vesselConfiguration: Performance.VesselConfiguration.Configuration
}

export interface ShaftMotorDialState {}

export class ShaftMotorDial extends React.Component<
  ShaftMotorDialProps,
  ShaftMotorDialState
> {
  getShaftMotors(): ShaftMotorStatus {
    const { vesselStatus, vesselConfiguration } = this.props
    const { shaftMotor1, shaftMotor2 } = vesselStatus
    return {
      shaftMotor1: {
        power: shaftMotor1 ? shaftMotor1.power : undefined,
        maxPower: vesselConfiguration.shaftMotor1.maxPower,
      },
      shaftMotor2: {
        power: shaftMotor2 ? shaftMotor2.power : undefined,
        maxPower: vesselConfiguration.shaftMotor2.maxPower,
      },
    }
  }
  public render() {
    const { vesselConfiguration } = this.props
    const { shaftMotor1, shaftMotor2 } = this.getShaftMotors() as any
    const isDualEngine = vesselConfiguration.hasTwoMainEngines
    return (
      <React.Fragment>
        <Wrapper>
          <div className={`dials ${isDualEngine ? 'border' : ''}`}>
            <SimpleDial
              id='dial-shaft-motor-1'
              value={shaftMotor1.power}
              color={maritimeBlue[500]}
              minValue={0}
              maxValue={shaftMotor1.maxPower}
              unit='KW'
              dialHeader={isDualEngine ? 'Port' : ''}
            />
          </div>
          {isDualEngine && !!shaftMotor2 && (
            <div className='dials'>
              <SimpleDial
                id='dial-shaft-motor-2'
                value={shaftMotor2.power}
                color={maritimeBlue[500]}
                minValue={0}
                maxValue={shaftMotor2.maxPower}
                unit='KW'
                dialHeader={'Stbd'}
              />
            </div>
          )}
        </Wrapper>
      </React.Fragment>
    )
  }
}
