export const StaticModelType = {
  Hull: 1,
  Propeller: 2,
  Whr: 3,
  MainEng: 4,
  AuxEng: 5,
  Boiler: 6,
  ShaftMotorGenerator: 7,
} as const

export const StaticModelTypeNames = {
  [StaticModelType.Hull]: 'Hull',
  [StaticModelType.Propeller]: 'Propeller',
  [StaticModelType.Whr]: 'WHR',
  [StaticModelType.MainEng]: 'Main Engine',
  [StaticModelType.AuxEng]: 'Aux Engine',
  [StaticModelType.Boiler]: 'Boiler',
  [StaticModelType.ShaftMotorGenerator]: 'Shaft Motor Generator',
} as const

export const MainEngInstanceName = {
  1: 'Port',
  2: 'Stbd.',
}

export const VesselStatus = {
  Active: 'ACTIVE',
  Outfleeted: 'OUTFLEETED',
}
