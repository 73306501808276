import styled from 'styled-components'
import { grey } from '../../../../theme'

export const Wrapper = styled.div`
  .ae-label {
    display: flex;
    align-items: center;

    p {
      width: 100%;
    }
  }

  .engine-size-label {
    font-size: 10px;
    text-align: center;
    color: ${grey[700]};
    cursor: default;
  }
`

export const ContentWrapper = styled.div`
  display: flex;
  align-items: stretch;
`

export const ChartsWrapper = styled.div`
  flex: 1;
`

export const StatusWrapper = styled.div`
  width: 120px;
  margin-top: 16px;

  svg.green {
    width: 8px;
    height: 8px;
    line-height: 8px;
    font-size: 8px;
    color: #71d285;
    border: 1px solid white;
    border-radius: 50%;
  }
`

export const Economizer = styled.div`
  text-align: right;
  flex: 1;

  svg {
    width: 10px;
    height: 16px;
    vertical-align: -0.3em;
  }
`

export const ActiveEngineIcon = styled.span``
