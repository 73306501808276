import { useContext, useEffect, useState } from 'react'
import { Field, Form, useFormikContext } from 'formik'
import Select from 'react-select'
import moment from 'moment'
import styled from 'styled-components'
import { McButton } from '@maersk-global/mds-react-wrapper'

import { BatchSelect } from '../../../fdl-4/components/batch-selection/BatchSelect'
import {
  FormContainer,
  FormDateTimeInput,
  FormInputWithUnit,
  FormTextArea,
  Icon,
  InfoBox,
  Loading,
  ModalControls,
} from '../../../../commons'
import { displayErrorModal, formatValue } from '../../../../utils'
import { type LossFormValues } from './LossModal'
import { Performance } from '../../../../api-models'
import { info, maritimeBlue } from '../../../../theme'
import { VesselPageContext } from '../../../../contexts'
import { VolumeToMassCalculator } from '../../../volume-to-mass-calculator'
import * as PerformanceApi from '../../../../services/performance'
import { STOCK_LOSS_REASON_OPTIONS } from '../../../../utils/constants'
import { StockRobNotifications } from '../StockRobNotifications'
import { ErrorMessage } from '../ErrorMessage'
import { Option } from '../../hooks'

const Wrapper = styled.div`
  max-height: 80vh;
  overflow-y: auto;
`

const FieldContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`

const FieldLabel = styled.span`
  width: 120px;
  font-size: 14px;
  text-align: right;
  margin-right: 8px;
`

const SelectWrapper = styled.div`
  width: 320px;
`

const CalculatorContainer = styled.div`
  padding-left: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;

  > i {
    font-size: 16px;
    line-height: 16px;
  }

  svg {
    color: ${maritimeBlue[500]};
    height: 24px;
    width: 24px !important;
  }
`

const RobContainer = styled.div`
  text-align: right;
  font-size: 14px;
  margin: 0 10px;
`

const QuantityFieldContainer = styled.div``

const QuantityInputContainer = styled.div`
  display: flex;
`

type Props = {
  disabled: boolean
  openWindow?: Performance.FuelOilStock.OpenWindow
  batchOptions?: Option[]
  closeHandler: () => void
}

export const LossForm = (props: Props) => {
  const { batchOptions, closeHandler, disabled, openWindow } = props
  const formikProps = useFormikContext<LossFormValues>()
  const { dirty, isSubmitting, isValid, setFieldValue, values } = formikProps
  const imoNo = useContext(VesselPageContext).imoNo!
  const [rob, setRob] = useState<Performance.FuelOilStock.RobResponse>()
  const [currentBatchRob, setCurrentBatchRob] = useState<number | null>()
  const [lossToOptions, setLossToOptions] = useState<Option[]>()
  const [batchOptionsAtTimeOfLoss, setBatchOptionsAtTimeOfLoss] =
    useState<Option[]>()
  const [shouldShowCalculator, setShouldShowCalculator] = useState(false)
  /*
   * The state variable validateQuantity is supposed to be a function
   * used for validating against a dynamically changing value (rob for current batch).
   * To use a function as state we have to return a function which returns another function,
   * this is because the state will call the function and set the return value as
   * the state variable in the case when it is a function.
   */
  const [validateQuantity, setValidateQuantity] = useState(() => () => {})

  useEffect(() => {
    if (values.timestamp) {
      PerformanceApi.getRob(imoNo, moment.utc(values.timestamp).toISOString())
        .then(setRob)
        .catch((_) =>
          displayErrorModal({
            statusText: 'Could not receive ROB',
            message: 'Could not receive remaining on board at time of loss',
          }),
        )
      setFieldValue('batchId', '')
      setFieldValue('destinationBatchId', '')
      setFieldValue('quantity', '')
      setFieldValue('notes', '')
      setFieldValue('reasonCode', '')
    }
  }, [values.timestamp, imoNo, setFieldValue])

  useEffect(() => {
    if (batchOptionsAtTimeOfLoss) {
      setLossToOptions([
        ...batchOptionsAtTimeOfLoss,
        {
          label: 'Waste',
          value: 'waste',
          fuelType: '',
        },
      ])
    }
  }, [batchOptionsAtTimeOfLoss])

  useEffect(() => {
    /*
     * If Stock is not in an operational state, rob returns no data.
     * Last known batches on board must then be fetched from batch endpoint
     */
    if (rob?.hasData) {
      const batchIdsAtTimeOfLoss = rob?.batchQuantities
        .filter(({ quantity }) => quantity > 0)
        .map((x) => x.batch.id)
      setBatchOptionsAtTimeOfLoss(
        batchOptions?.filter(({ value }) =>
          batchIdsAtTimeOfLoss?.includes(value),
        ),
      )
    } else {
      setBatchOptionsAtTimeOfLoss(batchOptions)
    }
  }, [rob, batchOptions])

  useEffect(() => {
    if (rob?.hasData && values.batchId) {
      const { batchQuantities } = rob
      const newRob = batchQuantities.find(
        (x) => x.batch.id === values.batchId,
      )?.quantity
      if (typeof newRob === 'number') {
        setCurrentBatchRob(newRob)
      } else {
        setCurrentBatchRob(null)
      }
    } else {
      setCurrentBatchRob(null)
    }
  }, [values.batchId, rob])

  useEffect(() => {
    setValidateQuantity(
      () => (value) =>
        currentBatchRob &&
        value > currentBatchRob &&
        `Quantity must be lower than remaining quantity of batch at time of loss (maximum ${currentBatchRob} MT)`,
    )
  }, [currentBatchRob])

  if (!batchOptions || !openWindow) {
    return <Loading />
  }
  return (
    <Form>
      <Wrapper>
        <FormContainer>
          {rob?.notifications && <StockRobNotifications rob={rob} />}
          <InfoBox theme={info} boxMargin='0 0 16px 0' maxWidth='510px'>
            <small>
              Please note that sludge is automatically deducted from your
              consumption, so you do not need to, under normal circumstances,
              add it as a loss.
            </small>
          </InfoBox>
          <FieldContainer>
            <FieldLabel>Time of loss UTC</FieldLabel>
            <Field
              component={(props) => (
                <FormDateTimeInput
                  {...props}
                  minDate={moment.utc(openWindow.period.from)}
                  maxDate={moment.utc(openWindow.period.to)}
                  minuteSpecific={false}
                />
              )}
              name='timestamp'
              disabled={disabled}
            />
          </FieldContainer>
          <ErrorMessage name='timestamp'></ErrorMessage>

          <FieldContainer>
            <FieldLabel>Loss from</FieldLabel>
            <Field
              component={(props) => (
                <BatchSelect
                  {...props}
                  width='300px'
                  menuHeaderText='Select loss from'
                  noOptionsMessage={() =>
                    'Select a time of loss to see options'
                  }
                />
              )}
              name='batchId'
              options={batchOptionsAtTimeOfLoss}
              width='300px'
              disabled={disabled}
            />
            <RobContainer>
              <div>{`${
                typeof currentBatchRob === 'number'
                  ? formatValue(currentBatchRob, 3)
                  : '-'
              } MT`}</div>
              <div>at time of loss</div>
            </RobContainer>
          </FieldContainer>
          <ErrorMessage name='batchId'></ErrorMessage>

          <FieldContainer>
            <FieldLabel>Loss to</FieldLabel>
            <Field
              component={(props) => (
                <BatchSelect
                  {...props}
                  width='300px'
                  menuHeaderText='Select loss to'
                  noOptionsMessage={() =>
                    'Select a time of loss to see options'
                  }
                />
              )}
              name='destinationBatchId'
              options={lossToOptions}
              width='300px'
              disabled={disabled}
            />
          </FieldContainer>
          <ErrorMessage name='destinationBatchId'></ErrorMessage>

          <FieldContainer>
            <FieldLabel>Quantity</FieldLabel>
            <QuantityFieldContainer>
              <QuantityInputContainer>
                <Field
                  component={FormInputWithUnit}
                  name='quantity'
                  type='number'
                  unit='MT'
                  width='120px'
                  disabled={disabled}
                  validate={validateQuantity}
                  placeholder='0.000'
                />
                <CalculatorContainer
                  onClick={() => setShouldShowCalculator(true)}
                >
                  <Icon icon='fal fa-calculator' />
                </CalculatorContainer>
              </QuantityInputContainer>
            </QuantityFieldContainer>

            {shouldShowCalculator && (
              <VolumeToMassCalculator
                onCancel={() => setShouldShowCalculator(false)}
                onClose={() => setShouldShowCalculator(false)}
                onSave={(totalSum: string) => {
                  let quantity
                  if (totalSum === '-' || isNaN(+totalSum) || disabled) {
                    quantity = ''
                  } else {
                    quantity = parseFloat(totalSum)
                  }
                  setFieldValue('quantity', quantity)
                  setShouldShowCalculator(false)
                }}
              />
            )}
          </FieldContainer>
          <ErrorMessage name='quantity'></ErrorMessage>

          <FieldContainer>
            <FieldLabel>Reason for loss</FieldLabel>
            <Field
              component={(props) => (
                <SelectWrapper>
                  <Select
                    onBlur={() => props.form.setFieldTouched(props.field.name)}
                    onChange={(option: any) => {
                      if (option?.value)
                        props.form.setFieldValue(props.field.name, option.value)
                    }}
                    value={
                      props.field?.value &&
                      props.options?.find(
                        ({ value }) => value === props.field.value,
                      )
                    }
                    options={props.options}
                    isOptionDisabled={({ isDisabled }) => isDisabled ?? false}
                    isSearchable={true}
                    styles={{
                      placeholder: (provided) => ({
                        ...provided,
                        fontSize: '13px',
                      }),
                      option: (provided) => ({
                        ...provided,
                        fontSize: '14px',
                      }),
                      valueContainer: (provided) => ({
                        ...provided,
                        fontSize: '13px',
                      }),
                    }}
                    menuPosition='fixed'
                  />
                </SelectWrapper>
              )}
              options={STOCK_LOSS_REASON_OPTIONS}
              name='reasonCode'
              disabled={disabled}
            />
          </FieldContainer>
          <ErrorMessage name='reasonCode'></ErrorMessage>

          <FieldContainer>
            <FieldLabel>Notes</FieldLabel>
            <Field component={FormTextArea} name='notes' width='320px' />
          </FieldContainer>
          <ErrorMessage name='notes'></ErrorMessage>
        </FormContainer>
      </Wrapper>
      <ModalControls>
        <McButton
          label='Cancel'
          appearance='neutral'
          click={closeHandler}
          type='button'
        />
        <McButton
          label='Add new loss'
          appearance='primary'
          disabled={isSubmitting || disabled || !isValid || !dirty}
          type='submit'
        />
      </ModalControls>
    </Form>
  )
}
