import styled from 'styled-components'

export const Wrapper = styled.div`
  padding: 16px;

  > div {
    display: flex;
    gap: 48px;

    > div {
      > mc-label::part(label) {
        font-weight: bold;
        margin-left: 16px;
      }
    }

    &:last-child > mc-button {
      margin-top: 16px;
      margin-left: auto;
      justify-self: flex-end;
    }
  }
`
