/* eslint-disable */
/*!
 * Font Awesome Pro 5.4.1 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
(function() {
  var _WINDOW = {};
  try {
    if (typeof window !== "undefined") _WINDOW = window;
  } catch (e) {}

  var _ref = _WINDOW.navigator || {};
  var _ref$userAgent = _ref.userAgent;
  var userAgent = _ref$userAgent === undefined ? "" : _ref$userAgent;

  var WINDOW = _WINDOW;

  var IS_IE = ~userAgent.indexOf("MSIE") || ~userAgent.indexOf("Trident/");

  var NAMESPACE_IDENTIFIER = "___FONT_AWESOME___";

  var PRODUCTION = (function() {
    try {
      return "production" === "production";
    } catch (e) {
      return false;
    }
  })();

  var oneToTen = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  var oneToTwenty = oneToTen.concat([11, 12, 13, 14, 15, 16, 17, 18, 19, 20]);

  var RESERVED_CLASSES = [
    "xs",
    "sm",
    "lg",
    "fw",
    "ul",
    "li",
    "border",
    "pull-left",
    "pull-right",
    "spin",
    "pulse",
    "rotate-90",
    "rotate-180",
    "rotate-270",
    "flip-horizontal",
    "flip-vertical",
    "stack",
    "stack-1x",
    "stack-2x",
    "inverse",
    "layers",
    "layers-text",
    "layers-counter"
  ]
    .concat(
      oneToTen.map(function(n) {
        return n + "x";
      })
    )
    .concat(
      oneToTwenty.map(function(n) {
        return "w-" + n;
      })
    );

  function bunker(fn) {
    try {
      fn();
    } catch (e) {
      if (!PRODUCTION) {
        throw e;
      }
    }
  }

  var w = WINDOW || {};

  if (!w[NAMESPACE_IDENTIFIER]) w[NAMESPACE_IDENTIFIER] = {};
  if (!w[NAMESPACE_IDENTIFIER].styles) w[NAMESPACE_IDENTIFIER].styles = {};
  if (!w[NAMESPACE_IDENTIFIER].hooks) w[NAMESPACE_IDENTIFIER].hooks = {};
  if (!w[NAMESPACE_IDENTIFIER].shims) w[NAMESPACE_IDENTIFIER].shims = [];

  var namespace = w[NAMESPACE_IDENTIFIER];

  var _extends =
    Object.assign ||
    function(target) {
      for (var i = 1; i < arguments.length; i++) {
        var source = arguments[i];

        for (var key in source) {
          if (Object.prototype.hasOwnProperty.call(source, key)) {
            target[key] = source[key];
          }
        }
      }

      return target;
    };

  function define(prefix, icons) {
    var normalized = Object.keys(icons).reduce(function(acc, iconName) {
      var icon = icons[iconName];
      var expanded = !!icon.icon;

      if (expanded) {
        acc[icon.iconName] = icon.icon;
      } else {
        acc[iconName] = icon;
      }
      return acc;
    }, {});

    if (typeof namespace.hooks.addPack === "function") {
      namespace.hooks.addPack(prefix, normalized);
    } else {
      namespace.styles[prefix] = _extends(
        {},
        namespace.styles[prefix] || {},
        normalized
      );
    }

    /**
     * Font Awesome 4 used the prefix of `fa` for all icons. With the introduction
     * of new styles we needed to differentiate between them. Prefix `fa` is now an alias
     * for `fas` so we'll easy the upgrade process for our users by automatically defining
     * this as well.
     */
    if (prefix === "fas") {
      define("fa", icons);
    }
  }

  var icons = {
    ///////////////////////////////////////////KEEP BELOW/////////////////////////////////////////////////////////////
    "exclamation-triangle": [
      576,
      512,
      [],
      "f071",
      "M248.747 204.705l6.588 112c.373 6.343 5.626 11.295 11.979 11.295h41.37a12 12 0 0 0 11.979-11.295l6.588-112c.405-6.893-5.075-12.705-11.979-12.705h-54.547c-6.903 0-12.383 5.812-11.978 12.705zM330 384c0 23.196-18.804 42-42 42s-42-18.804-42-42 18.804-42 42-42 42 18.804 42 42zm-.423-360.015c-18.433-31.951-64.687-32.009-83.154 0L6.477 440.013C-11.945 471.946 11.118 512 48.054 512H527.94c36.865 0 60.035-39.993 41.577-71.987L329.577 23.985zM53.191 455.002L282.803 57.008c2.309-4.002 8.085-4.002 10.394 0l229.612 397.993c2.308 4-.579 8.998-5.197 8.998H58.388c-4.617.001-7.504-4.997-5.197-8.997z"
    ],
    exchange: [
      512,
      512,
      [],
      "f0ec",
      "M508.485 168.485l-100.375 100c-4.686 4.686-12.284 4.686-16.97 0l-19.626-19.626c-4.753-4.753-4.675-12.484.173-17.14L422.916 184H12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h410.916l-51.228-47.719c-4.849-4.656-4.927-12.387-.173-17.14l19.626-19.626c4.686-4.686 12.284-4.686 16.97 0l100.375 100c4.685 4.686 4.685 12.284-.001 16.97zm-504.97 192l100.375 100c4.686 4.686 12.284 4.686 16.97 0l19.626-19.626c4.753-4.753 4.675-12.484-.173-17.14L89.084 376H500c6.627 0 12-5.373 12-12v-24c0-6.627-5.373-12-12-12H89.084l51.228-47.719c4.849-4.656 4.927-12.387.173-17.14l-19.626-19.626c-4.686-4.686-12.284-4.686-16.97 0l-100.375 100c-4.686 4.686-4.686 12.284.001 16.97z"
    ],
    wifi: [
      640,
      512,
      [],
      "f1eb",
      "M320 368c17.64 0 32 14.36 32 32s-14.36 32-32 32-32-14.36-32-32 14.35-32 32-32m0-48c-44.18 0-80 35.82-80 80s35.82 80 80 80 80-35.82 80-80-35.82-80-80-80zm204.69-31.83c4.62-4.87 4.38-12.64-.59-17.15-115.74-105.32-292.41-105.38-408.22 0-4.96 4.51-5.2 12.28-.59 17.15l16.47 17.37c4.46 4.71 11.81 4.95 16.62.6 97.44-88.13 245.68-88.21 343.22 0 4.81 4.35 12.16 4.1 16.62-.6l16.47-17.37zm111.42-133.98C457.86-8.86 181.84-8.59 3.89 154.19c-4.94 4.52-5.22 12.14-.57 16.95l16.6 17.18c4.52 4.68 12.01 4.93 16.81.54 159.59-145.79 406.82-145.91 566.54 0 4.81 4.39 12.29 4.13 16.81-.54l16.6-17.18c4.65-4.81 4.37-12.44-.57-16.95z"
    ],
    "caret-down": [
      320,
      512,
      [],
      "f0d7",
      "M272 160H48.1c-42.6 0-64.2 51.7-33.9 81.9l111.9 112c18.7 18.7 49.1 18.7 67.9 0l112-112c30-30.1 8.7-81.9-34-81.9zM160 320L48 208h224L160 320z"
    ],
    comment: [
      512,
      512,
      [],
      "f075",
      "M256 64c123.5 0 224 79 224 176S379.5 416 256 416c-28.3 0-56.3-4.3-83.2-12.8l-15.2-4.8-13 9.2c-23 16.3-58.5 35.3-102.6 39.6 12-15.1 29.8-40.4 40.8-69.6l7.1-18.7-13.7-14.6C47.3 313.7 32 277.6 32 240c0-97 100.5-176 224-176m0-32C114.6 32 0 125.1 0 240c0 47.6 19.9 91.2 52.9 126.3C38 405.7 7 439.1 6.5 439.5c-6.6 7-8.4 17.2-4.6 26 3.8 8.8 12.4 14.5 22 14.5 61.5 0 110-25.7 139.1-46.3 29 9.1 60.2 14.3 93 14.3 141.4 0 256-93.1 256-208S397.4 32 256 32z"
    ]
  };

  bunker(function() {
    define("far", icons);
  });
})();
