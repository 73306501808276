export interface ApplicationConfig {
  azureAd: {
    authority: string
    clientId: string
    redirectUri: string
  }

  masterDataApiResource: {
    baseAddress: string
  }

  masterDataApiShoreResource: {
    baseAddress: string
  }

  managementApiResource: {
    baseAddress: string
  }

  performanceApiResource: {
    baseAddress: string
    socketAddress: string
  }

  sensorApiResource: {
    baseAddress: string
    socketAddress: string
  }

  hydraApiResource: {
    baseAddress: string
  }

  notificationsApiResource: {
    baseAddress: string
  }

  balrogApiResource: {
    baseAddress: string
  }

  voyageUrl: {
    baseAddress: string
  }

  gandalfResource: {
    baseAddress: string
  }

  gandalfShoreResource: {
    baseAddress: string
  }

  currentVesselImo: string
  currentVesselName: string
  APP_RUN_CONTEXT: string
  APP_VERSION: string
  APP_BUILD_NUMBER: string
}

export interface ErrorResponse {
  message: string
}
