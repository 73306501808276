export const pad = (num: number, size: number): string => {
  let s = num + ''
  while (s.length < size) s = '0' + s
  return s
}

export const stripPort: (a?: string) => string = (a) => {
  return a ? a.replace('port', '') : ''
}

export const commaAgnostic: (a?: string | number) => string = (a) => {
  return a ? `${a}`.replace(/[^0-9,.-]+/g, '').replace(',', '.') : ''
}

export const stripDecimals = (
  value: number | string,
  numberOfDigits: number,
): string => {
  let [number, decimals] = `${value}`.split('.')
  if (decimals) {
    decimals = decimals.slice(0, numberOfDigits)
    if (decimals.length > 0) {
      return [number, decimals].join('.')
    }
  }
  return number
}

export const padSeriesWithZero = (series: number[]): number[] => {
  const paddedSeries = [...series]
  for (let i = 0; i < paddedSeries.length - 1; i++) {
    if (
      typeof paddedSeries[i] === 'number' &&
      typeof paddedSeries[i + 1] !== 'number' &&
      paddedSeries[i] > 0
    ) {
      paddedSeries[i + 1] = 0
    } else if (
      typeof paddedSeries[i] !== 'number' &&
      typeof paddedSeries[i + 1] === 'number' &&
      paddedSeries[i + 1] > 0
    ) {
      paddedSeries[i] = 0
    }
  }
  return paddedSeries
}

export const flattenObject = (ob: any): any => {
  var toReturn = {}

  for (var i in ob) {
    if (!ob.hasOwnProperty(i)) continue

    if (typeof ob[i] == 'object' && ob[i] !== null) {
      var flatObject = flattenObject(ob[i])
      for (var x in flatObject) {
        if (!flatObject.hasOwnProperty(x)) continue

        toReturn[i + '.' + x] = flatObject[x]
      }
    } else {
      toReturn[i] = ob[i]
    }
  }
  return toReturn
}

export const capitalize = (word: string) =>
  word.charAt(0).toUpperCase() + word.slice(1)
