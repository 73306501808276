import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 16px;
  margin: 16px 0 20px;
  border: 1px solid #d8d8d8;
  border-radius: 3px;
  padding: 10px 18px;
`

export const Content = styled.div`
  flex: 0 0 65%;

  mc-notification {
    display: block;
    margin-bottom: 12px;
  }
`

export const Header = styled.h5`
  margin-bottom: 4px !important;
`

export const Duration = styled.p`
  font-size: 14px;
  line-height: 17px;
  color: #6d7278;
`

export const MetadataRow = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 12px;

  > div {
    flex: 0 0 50%;
  }
`
