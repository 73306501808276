import React, { useContext, useState } from 'react'
import {
  useMutation,
  useQuery,
  useQueryClient,
  type UseQueryResult,
} from '@tanstack/react-query'
import { FieldArray, Form, Formik } from 'formik'
import {
  McButton,
  McIcon,
  McModal,
  McTab,
  McTabBar,
} from '@maersk-global/mds-react-wrapper'

import {
  FormInputDateTime,
  FormRadioAlt,
  FormTextArea,
  FormTextInput,
} from '../../../components/formik'
import { VesselPageContext } from '../../../contexts'
import OverlayLoader from '../../../features/hybrid-data-collector/components/overlay-loader'
import {
  deleteLubeOilEntry,
  getLubeOilEntry,
  postLubeOilEntry,
  putLubeOilEntry,
} from '../../../services/performance'
import { displayErrorModal, isShoreContext } from '../../../utils'
import { LUBE_OIL_TYPE_NAME } from '../constants'
import * as S from '../style'
import {
  ELubeOilContainer,
  ELubeOilType,
  ESoundingType,
  type IModalProps,
  type ISoundingFormValues,
  type TLubeOilSounding,
  type TLubeOilSoundingItem,
} from '../types'
import { getLubeOilContainerName } from '../utils'
import soundingValidationSchema from '../validation/sounding'
import {
  getEmptyItem,
  getInitialValues,
  OIL_TYPE_TO_FORM_NAME,
} from './helpers'

const Sounding = (props: IModalProps) => {
  const { hasTwoMainEngines, imoNo } =
    useContext(VesselPageContext).configuration!

  const [tabIndex, setTabIndex] = useState(0)
  const [isConfirmingDeletion, setConfirmingDeletion] = useState(false)

  /**
   * @description `undefined` if adding new entry; `string` if editing existing entry
   */
  const entryId = props.entryId

  const queryClient = useQueryClient()

  const { data, isFetching }: UseQueryResult<TLubeOilSounding> = useQuery(
    ['lubeOilEntry', 'Sounding', entryId, imoNo],
    () => getLubeOilEntry('Sounding', entryId!, imoNo),
    { enabled: !!entryId },
  )

  const putMutation = useMutation({
    mutationFn: (entry: TLubeOilSounding) =>
      putLubeOilEntry('Sounding', imoNo, entry.id!, entry),
    onSuccess: handleMutationSuccess,
    onError: handleMutationError,
  })

  const postMutation = useMutation({
    mutationFn: (entry: TLubeOilSounding) =>
      postLubeOilEntry('Sounding', imoNo, entry),
    onSuccess: handleMutationSuccess,
    onError: handleMutationError,
  })

  const deleteMutation = useMutation({
    mutationFn: (entry: TLubeOilSounding) =>
      deleteLubeOilEntry('Sounding', entry.id!, imoNo),
    onSuccess: handleMutationSuccess,
    onError: (error: any) => {
      void displayErrorModal({
        statusText: 'Failed to delete entry',
        message: error.message || 'Could not delete entry. Please try again.',
      })
    },
  })

  function handleMutationSuccess() {
    void queryClient.invalidateQueries({
      queryKey: ['lubeOilEntries', String(imoNo)],
    })
    props.onClose()
  }

  function handleMutationError(error) {
    void displayErrorModal({
      statusText: 'Failed to save sounding',
      message: error.message || 'Could not save sounding. Please try again.',
    })
  }

  const getTable = (
    items: TLubeOilSoundingItem[],
    oilType: ELubeOilType,
    container: ELubeOilContainer,
    id: string,
    field: string,
    push?: (obj: any) => void,
    remove?: <T>(index: number) => T | undefined,
    twinContainer?: ELubeOilContainer,
  ) => {
    const getTotal = (items, container, twinContainer, field) =>
      items.reduce(
        (acc, item) =>
          [container, twinContainer].includes(item.oilContainer)
            ? acc + parseInt(item[field] || 0, 10)
            : acc,
        0,
      )

    const isRemovalEnabled = (idx: number) =>
      twinContainer === ELubeOilContainer.DayTankStbd
        ? idx > 2 && idx % 2 !== 0
        : idx !== 1

    let tableIdx = 0

    const isUnpumpableDisabled =
      [ELubeOilContainer.DayTank, ELubeOilContainer.Working].includes(
        container,
      ) ||
      (!!twinContainer &&
        [ELubeOilContainer.DayTankStbd, ELubeOilContainer.WorkingStbd].includes(
          twinContainer,
        )) ||
      oilType === ELubeOilType.Other

    return (
      <S.SoundingTable className='mds-table mds-table--small'>
        <table data-testid={`lube-sounding-table-${oilType}-${container}`}>
          <thead>
            <tr>
              <th className='oil-container'></th>
              <th>TBN</th>
              <th>ROB (kg)</th>
              <th>Unpumpable (kg)</th>
              <th>Description</th>
              <th className='actions'></th>
            </tr>
          </thead>
          <tbody>
            {items.map((item, idx) => {
              if (
                item.oilContainer !== container &&
                item.oilContainer !== twinContainer
              ) {
                return null
              }
              tableIdx += 1
              return (
                (item.oilContainer === container ||
                  item.oilContainer === twinContainer) && (
                  <tr key={`${id}-row-${idx}`}>
                    <td className='oil-container'>
                      {getLubeOilContainerName(
                        item.oilContainer,
                        !!twinContainer,
                      )}
                    </td>
                    <td>
                      <FormTextInput
                        disabled={oilType === ELubeOilType.Other}
                        name={`${field}.${idx}.tbn`}
                        id={`${id}-tbn-${idx}`}
                        data-e2e={`${id}-tbn-${idx}`}
                        {...(oilType === ELubeOilType.Other
                          ? { placeholder: 'Not applicable' }
                          : {})}
                      />
                    </td>
                    <td>
                      <FormTextInput
                        name={`${field}.${idx}.rob`}
                        id={`${id}-rob-${idx}`}
                        data-e2e={`${id}-rob-${idx}`}
                      />
                    </td>
                    <td>
                      <FormTextInput
                        disabled={isUnpumpableDisabled}
                        name={`${field}.${idx}.unpumpable`}
                        id={`${id}-unpumpable-${idx}`}
                        data-e2e={`${id}-unpumpable-${idx}`}
                        {...(isUnpumpableDisabled
                          ? { placeholder: 'Not applicable' }
                          : {})}
                      />
                    </td>
                    <td>
                      <FormTextInput
                        name={`${field}.${idx}.description`}
                        id={`${id}-description-${idx}`}
                        data-e2e={`${id}-description-${idx}`}
                        placeholder='Max 50 characters'
                      />
                    </td>
                    <td>
                      {remove && isRemovalEnabled(tableIdx) && (
                        <S.Action
                          data-testid={`lube-sounding-delete-row-${idx}-${oilType}-${container}`}
                          isDisabled={false}
                          onClick={() => {
                            if (
                              twinContainer === ELubeOilContainer.DayTankStbd
                            ) {
                              remove(idx + 1)
                            }
                            remove(idx)
                          }}
                        >
                          <McIcon icon='trash' />
                        </S.Action>
                      )}
                    </td>
                  </tr>
                )
              )
            })}
            <tr>
              <td className='total'>Total</td>
              <td></td>
              <td className='total'>
                {getTotal(items, container, twinContainer, 'rob')}
              </td>
              {!isUnpumpableDisabled && (
                <td className='total'>
                  {getTotal(items, container, twinContainer, 'unpumpable')}
                </td>
              )}
            </tr>
          </tbody>
        </table>
        {push && (
          <S.AddRowButton>
            <McButton
              label='Add row'
              appearance='neutral'
              fit='small'
              trailingicon='plus'
              click={() => {
                push(getEmptyItem(container, oilType))
                if (twinContainer === ELubeOilContainer.DayTankStbd) {
                  push(getEmptyItem(twinContainer, oilType))
                }
              }}
            />
          </S.AddRowButton>
        )}
      </S.SoundingTable>
    )
  }

  const handleSubmit = (values: ISoundingFormValues) => {
    let items = [
      ...values.ae,
      ...values.clo,
      ...values.cloAdditive,
      ...values.me,
      ...values.other,
    ]
    let isPartial = false
    if (values.type === ESoundingType.Partial) {
      items = items.filter((item) => !!item.rob)
      isPartial = true
    }
    const entry = {
      isPartial,
      items,
      notes: values.notes,
      timestamp: values.timestamp!,
    }
    if (entryId) {
      putMutation.mutate({
        ...entry,
        id: entryId,
      })
    } else {
      postMutation.mutate(entry)
    }
  }

  const handleDelete = () => {
    if (!isConfirmingDeletion) {
      setConfirmingDeletion(true)
      return
    }

    deleteMutation.mutate(data!)
  }

  const handleTabChange = ({ detail }: CustomEvent<number>) => {
    setTabIndex(detail)
  }

  if (entryId && isFetching) return <OverlayLoader />

  return (
    <Formik
      initialValues={getInitialValues(hasTwoMainEngines, data)}
      onSubmit={handleSubmit}
      validationSchema={soundingValidationSchema}
    >
      {({ errors, isSubmitting, submitForm, touched, values }) => (
        <McModal
          open
          closed={() => props.onClose()}
          heading='Sounding'
          width='1000px'
          height='90%'
        >
          <Form>
            <S.Description>
              Type of sounding must be chosen as "Full" when adding sounding
              event for Month End Lube Oil Report.
            </S.Description>
            <S.InputRow>
              <S.Input width='50%'>
                <FormInputDateTime
                  fit='medium'
                  name='timestamp'
                  label='Date and time of sounding, UTC'
                  min={props.openWindow?.from ?? undefined}
                  max={props.openWindow?.to ?? undefined}
                />
              </S.Input>
              <FormRadioAlt
                name='type'
                hint={
                  values.type === ESoundingType.Full
                    ? 'Full oil inventory must be reported'
                    : 'Minimum 1 oil type must be reported'
                }
                id='lube-sounding-type'
                legend='Type of sounding'
                options={Object.values(ESoundingType).map((type) => ({
                  label: type,
                  value: type,
                }))}
              />
            </S.InputRow>

            <McTabBar
              currentindex={tabIndex}
              tabchange={handleTabChange}
              data-e2e={'TabBar'}
            >
              {Object.values(LUBE_OIL_TYPE_NAME).map((type, idx) => (
                <React.Fragment key={`lube-sounding-oil-type-${idx}`}>
                  <McTab
                    slot='tab'
                    icon={
                      errors[OIL_TYPE_TO_FORM_NAME[type]] &&
                      touched[OIL_TYPE_TO_FORM_NAME[type]] && [
                        'exclamation-circle',
                      ]
                    }
                    label={type}
                  />
                  <div slot='panel' style={{ marginTop: '16px' }}>
                    {/* CLO */}
                    {type === LUBE_OIL_TYPE_NAME[ELubeOilType.CLO] && (
                      <>
                        <FieldArray name='clo'>
                          {({ remove, push }) =>
                            getTable(
                              values.clo,
                              ELubeOilType.CLO,
                              ELubeOilContainer.SpareClean,
                              'clo-spare-clean',
                              'clo',
                              push,
                              remove,
                            )
                          }
                        </FieldArray>
                        <FieldArray name='clo'>
                          {({ remove, push }) =>
                            getTable(
                              values.clo,
                              ELubeOilType.CLO,
                              ELubeOilContainer.DayTank,
                              'clo-day-tank',
                              'clo',
                              push,
                              remove,
                              hasTwoMainEngines
                                ? ELubeOilContainer.DayTankStbd
                                : undefined,
                            )
                          }
                        </FieldArray>
                      </>
                    )}

                    {/* CLO Additive */}
                    {type === LUBE_OIL_TYPE_NAME[ELubeOilType.CLOAdditive] && (
                      <FieldArray name='cloAdditive'>
                        {({ remove, push }) =>
                          getTable(
                            values.cloAdditive,
                            ELubeOilType.CLOAdditive,
                            ELubeOilContainer.SpareClean,
                            'clo-additive',
                            'cloAdditive',
                            push,
                            remove,
                          )
                        }
                      </FieldArray>
                    )}

                    {/* ME */}
                    {type === LUBE_OIL_TYPE_NAME[ELubeOilType.ME] && (
                      <>
                        <FieldArray name='me'>
                          {({ remove, push }) =>
                            getTable(
                              values.me,
                              ELubeOilType.ME,
                              ELubeOilContainer.SpareClean,
                              'me-spare-clean',
                              'me',
                              push,
                              remove,
                            )
                          }
                        </FieldArray>
                        {getTable(
                          values.me,
                          ELubeOilType.ME,
                          ELubeOilContainer.Working,
                          'me-working',
                          'me',
                          undefined,
                          undefined,
                          hasTwoMainEngines
                            ? ELubeOilContainer.WorkingStbd
                            : undefined,
                        )}
                        <FieldArray name='me'>
                          {({ remove, push }) =>
                            getTable(
                              values.me,
                              ELubeOilType.ME,
                              ELubeOilContainer.SpareDirty,
                              'me-spare-dirty',
                              'me',
                              push,
                              remove,
                            )
                          }
                        </FieldArray>
                      </>
                    )}

                    {/* AE */}
                    {type === LUBE_OIL_TYPE_NAME[ELubeOilType.AE] && (
                      <FieldArray name='ae'>
                        {({ remove, push }) =>
                          getTable(
                            values.ae,
                            ELubeOilType.AE,
                            ELubeOilContainer.SpareClean,
                            'ae-spare-clean',
                            'ae',
                            push,
                            remove,
                          )
                        }
                      </FieldArray>
                    )}

                    {/* Other */}
                    {type === LUBE_OIL_TYPE_NAME[ELubeOilType.Other] && (
                      <FieldArray name='other'>
                        {({ remove, push }) =>
                          getTable(
                            values.other,
                            ELubeOilType.Other,
                            ELubeOilContainer.SpareClean,
                            'other-spare-clean',
                            'other',
                            push,
                            remove,
                          )
                        }
                      </FieldArray>
                    )}

                    <FormTextArea
                      name='notes'
                      id='clo-comment'
                      data-e2e='Comment'
                      label='Comment'
                      placeholder='Write your comment here'
                      rows={3}
                    />
                  </div>
                </React.Fragment>
              ))}
            </McTabBar>
            {!isShoreContext() && entryId && !data?.readonly && (
              <S.DeletionRow>
                <McButton
                  type='button'
                  data-e2e={'Delete'}
                  appearance='neutral'
                  trailingicon='trash'
                  click={handleDelete}
                >
                  Delete
                </McButton>
                {isConfirmingDeletion && (
                  <p className='mds-text--medium-bold'>
                    Are you sure you want to delete this entry?
                  </p>
                )}
              </S.DeletionRow>
            )}
            {(isSubmitting || deleteMutation.isLoading) && <OverlayLoader />}
          </Form>
          {((isShoreContext() || data?.readonly) && (
            <McButton slot='primaryAction' click={() => props.onClose()}>
              Close
            </McButton>
          )) || (
            <>
              <McButton
                slot='primaryAction'
                data-e2e={'Save'}
                click={submitForm}
              >
                Save
              </McButton>
              <McButton
                slot='secondaryAction'
                appearance='neutral'
                click={() => props.onClose()}
              >
                Cancel
              </McButton>
            </>
          )}
        </McModal>
      )}
    </Formik>
  )
}

export default Sounding
