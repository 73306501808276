import { useContext, useRef, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import {
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  type Row,
  type SortingState,
  useReactTable,
} from '@tanstack/react-table'

import { type TLubeOilEntry } from '../../lube-oil/types'
import { VesselPageContext } from '../../../contexts'
import {
  getLubeOilEntries,
  getLubeOilOpenWindow,
} from '../../../services/performance'
import { columns, entryModals } from './helpers'
import { Loading } from '../../../commons'
import LubeFilters from './LubeFilters'
import Table, { type ITableRef } from '../../../components/Table/Table'

const Lube = () => {
  const imoNo = useContext(VesselPageContext).imoNo!

  const [sorting, setSorting] = useState<SortingState>([
    { desc: true, id: 'timestamp' },
  ])
  const [selectedEntry, setSelectedEntry] = useState<TLubeOilEntry>()

  const tableRef = useRef<ITableRef>(null)

  const entriesQuery = useQuery(
    ['lubeOilEntries', imoNo],
    () => getLubeOilEntries(imoNo),
    { staleTime: 600000 },
  )

  const openWindowQuery = useQuery(['lubeOilOpenWindow', imoNo], () =>
    getLubeOilOpenWindow(imoNo),
  )

  const table = useReactTable({
    data: entriesQuery.data || [],
    columns,
    initialState: {
      pagination: { pageSize: 25 },
    },
    state: { sorting },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  })

  const handleRowClick = (row: Row<TLubeOilEntry>) => {
    setSelectedEntry(row.original)
  }

  const handleModalClosed = () => {
    setSelectedEntry(undefined)
    tableRef.current?.deselectRow()
  }

  const EntryModal = selectedEntry && entryModals[selectedEntry.type]

  const isLoading = entriesQuery.isLoading && openWindowQuery.isLoading
  const isSuccess = entriesQuery.isSuccess && openWindowQuery.isSuccess

  if (isLoading) return <Loading />
  if (!isSuccess) return null

  return (
    <>
      <LubeFilters table={table} />
      <Table
        table={table}
        onRowSelect={handleRowClick}
        ref={tableRef}
        data-e2e={'Table'}
      />
      {EntryModal && (
        <EntryModal
          entryId={selectedEntry.id}
          openWindow={openWindowQuery.data.period}
          onClose={handleModalClosed}
        />
      )}
    </>
  )
}

export default Lube
