import { Performance } from '../../../api-models'
import {
  FuelConsumptionModel,
  FuelConsumptionStatusModel,
} from '../models/fuel-consumption'
import { useMemo, useCallback, useContext } from 'react'
import { VesselPageContext } from '../../../contexts'

export function useConsumerConsumption(
  fuelConsumptionData: Performance.FuelConsumption.Monitor | undefined | null,
): [FuelConsumptionModel, FuelConsumptionModel, FuelConsumptionModel] {
  const { vesselStatus } = useContext(VesselPageContext)
  const mapFuelLineConsumption = useCallback(
    (
      statusFuelLineProp,
      fuelConsumptionDataFuelLineProp,
    ): FuelConsumptionModel => {
      const status =
        vesselStatus &&
        ({
          timestamp: vesselStatus.timestamp,
          dataFuelLines: [
            vesselStatus.hasData &&
            vesselStatus.fuelConsumption &&
            vesselStatus.fuelConsumption[statusFuelLineProp]
              ? vesselStatus.fuelConsumption[statusFuelLineProp]
              : [],
          ].flat(),
        } as FuelConsumptionStatusModel)
      return {
        status: status,
        timestamps:
          fuelConsumptionData &&
          fuelConsumptionData.hasData &&
          fuelConsumptionData.timestamps,
        dataFuelLines:
          fuelConsumptionData &&
          fuelConsumptionData.hasData &&
          [fuelConsumptionData[fuelConsumptionDataFuelLineProp]].flat(),
      } as FuelConsumptionModel
    },
    [vesselStatus, fuelConsumptionData],
  )

  const mainEngFuelConsumption = useMemo(
    () => mapFuelLineConsumption('mainEngFuelLine', 'mainEngFuelLine'),
    [mapFuelLineConsumption],
  )

  const auxEngFuelConsumption = useMemo(
    () => mapFuelLineConsumption('auxEngFuelLines', 'auxEngFuelLines'),
    [mapFuelLineConsumption],
  )
  const boilerFuelConsumption = useMemo(
    () => mapFuelLineConsumption('boilerFuelLines', 'boilerLines'),
    [mapFuelLineConsumption],
  )

  return [mainEngFuelConsumption, auxEngFuelConsumption, boilerFuelConsumption]
}
