import { doGet, getHydraApiUrl, getApplicationConfig } from '../../utils'
import { Hydra } from '../../api-models/hydra'
import { QueryPeriod } from '../../features/filter'

export const getTrim = (imoNo: string, speed: string, meanDraught: string) =>
  doGet(
    `${
      getApplicationConfig().hydraApiResource.baseAddress
    }/Vessels/trim/${imoNo}?speed=${speed}&draughtMean=${meanDraught}`,
  )

export const getElectricalProductionCost = (
  imoNo: string,
  queryPeriod: Hydra.Period,
): Promise<Hydra.ElectricalProduction.ElectricalProductionCost> =>
  doGet(
    `${getHydraApiUrl()}/Vessels/ElectricalProductionCost/${imoNo}?startDate=${
      queryPeriod.from
    }&endDate=${queryPeriod.to}`,
  )

export const getElectricalProductionCostStatus = (
  imoNo: string,
  timestamp: string,
): Promise<Hydra.ElectricalProduction.ElectricalProductionCost> =>
  doGet(
    `${getHydraApiUrl()}/Vessels/ElectricalProductionCost/${imoNo}?timestamp=${timestamp}`,
  )

export const getRunningModeStatus = (imoNo: string, timestamp: string) =>
  doGet(
    `${getHydraApiUrl()}/Vessels/RunningModeDetection/${imoNo}?timestamp=${timestamp}`,
  )

export const getRunningMode = (imoNo: string, period: QueryPeriod) =>
  doGet(
    `${getHydraApiUrl()}/Vessels/RunningModeDetection/${imoNo}?startDate=${
      period.from
    }&endDate=${period.to}`,
  )

export const getTorqueOffsetOverTimeStatus = (
  imoNo: string,
  timestamp: string,
) =>
  doGet(
    `${getHydraApiUrl()}/Vessels/TorqueOffsetOverTime/${imoNo}?timestamp=${timestamp}`,
  )

export const getTorqueOffsetOverTime = (imoNo: string, period: QueryPeriod) =>
  doGet(
    `${getHydraApiUrl()}/Vessels/TorqueOffsetOverTime/${imoNo}?startDate=${
      period.from
    }&endDate=${period.to}`,
  )

// TEM

export const getTEM = (
  imoNo: string,
  reqPower: number,
  electricalDemand: number,
  meOperationalMode: string,
  waterTemperature: number | undefined,
  synchronousLoading: boolean,
): Promise<Hydra.TEM.EnergyManagement> => {
  return doGet(
    `${getHydraApiUrl()}/Vessels/TEM/${imoNo}?requiredPower=${reqPower}&otherPower=${electricalDemand}&me_operational_mode=${meOperationalMode}&sync_loading=${synchronousLoading}${
      waterTemperature || waterTemperature === 0
        ? `&seaWaterTemp=${waterTemperature}`
        : ''
    }`,
  )
}

// Test pages

export const getRunningModeDetectionPlot = (
  imoNo: string,
  queryPeriod: QueryPeriod,
) =>
  doGet(
    `${getHydraApiUrl()}/Vessels/RunningModeDetectionsPlots/${imoNo}?startDate=${
      queryPeriod.from
    }&endDate=${queryPeriod.to}`,
  )
export const getTorqueOffsetPlots = (imoNo, queryPeriod) =>
  doGet(
    `${getHydraApiUrl()}/Vessels/TorqueOffsetPlots/${imoNo}?startDate=${
      queryPeriod.from
    }&endDate=${queryPeriod.to}`,
  )
