import { sortBy } from 'lodash'

import { Event } from '../../../api-models/hdc/events'
import { ApiReportType, IReport } from '../../../api-models/hdc/report'
import FramedSection from '../../../components/form/framed-section'
import FormSection from '../../../components/form/section'
import { UNITS } from '../../../utils/constants'
import EventRow from './EventRow/EventRow'
import ReadonlyField from '../components/readonly-field'
import SummaryRow from '../components/summary-row'
import UnknownPortIcon from '../components/unknown-port-icon'
import { HDCReportFormValues } from '../types'
import {
  calculateMeanDraught,
  convertMinutes,
  distanceAndSpeedObservations,
  getCalculatedRDPS,
  getOriginalValueNote,
  shouldCalculateRDPS,
} from '../utils'

type Props = {
  bridgeEvents: Event[]
  engineRoomEvents: Event[]
  report: HDCReportFormValues
  reports: IReport[]
}

const BridgeSummary = ({
  bridgeEvents,
  engineRoomEvents,
  report,
  reports,
}: Props) => {
  const { bridge, reportType } = report
  const {
    alternativeArrivalTerminal,
    alternativeDepartureTerminal,
    arrivalTerminal,
    ballastWater,
    comment,
    departureTerminal,
    distanceLogged,
    distanceObserved,
    draughtAft,
    draughtFore,
    eta,
    remainingDistanceToPilotStation,
    waterDepth,
    waterTemperature,
  } = bridge

  const sortedBridgeEvents = sortBy(bridgeEvents, 'startTimestamp')

  const sortedEngineRoomEvents = sortBy(engineRoomEvents, 'startTimestamp')

  const getRemainingTime = () => {
    const { hours, minutes } = convertMinutes(
      report.bridge.remainingTimeToPilotStation.value,
    )
    return `${hours} ${hours === 1 ? 'hour' : 'hours'} ${minutes} ${
      minutes === 1 ? 'minute' : 'minutes'
    }`
  }

  return (
    <>
      <FormSection header='Voyage'>
        <FramedSection header='Arrival'>
          <SummaryRow>
            {alternativeArrivalTerminal ? (
              <>
                <ReadonlyField
                  label='New port code'
                  name='new-arrival-port-code'
                  value={alternativeArrivalTerminal?.code ?? '-'}
                />
                <ReadonlyField
                  label='New port name'
                  name='new-arrival-port-name'
                  value={alternativeArrivalTerminal?.name ?? '-'}
                />
                <UnknownPortIcon tooltipId='unknown-port-icon' />
              </>
            ) : (
              <>
                <ReadonlyField
                  label='Port code'
                  name='arrival-port-code'
                  note={getOriginalValueNote(arrivalTerminal?.code)}
                  value={arrivalTerminal?.code.value ?? '-'}
                />
                <ReadonlyField
                  label='Port name'
                  name='arrival-port-name'
                  note={getOriginalValueNote(arrivalTerminal?.name)}
                  value={arrivalTerminal?.name.value ?? '-'}
                />
              </>
            )}
          </SummaryRow>
        </FramedSection>
        {[ApiReportType.CANAL, ApiReportType.DEPARTURE].includes(
          reportType,
        ) && (
          <FramedSection
            header={reportType === ApiReportType.CANAL ? 'Canal' : 'Depature'}
          >
            <SummaryRow>
              {alternativeDepartureTerminal ? (
                <>
                  <ReadonlyField
                    label={`New ${
                      reportType === ApiReportType.CANAL ? 'canal' : 'port'
                    } code`}
                    name='new-departure-port-code'
                    value={alternativeDepartureTerminal.code ?? '-'}
                  />
                  <ReadonlyField
                    label={`New ${
                      reportType === ApiReportType.CANAL ? 'canal' : 'port'
                    } name`}
                    name='new-departure-port-name'
                    value={alternativeDepartureTerminal.name ?? '-'}
                  />
                  <UnknownPortIcon tooltipId='unknown-port-icon' />
                </>
              ) : (
                <>
                  <ReadonlyField
                    label={`${
                      reportType === ApiReportType.CANAL ? 'Canal' : 'Port'
                    } code`}
                    name='departure-port-code'
                    note={getOriginalValueNote(departureTerminal?.code)}
                    value={departureTerminal?.code.value ?? '-'}
                  />
                  <ReadonlyField
                    label={`${
                      reportType === ApiReportType.CANAL ? 'Canal' : 'Port'
                    } name`}
                    name='departure-port-name'
                    note={getOriginalValueNote(departureTerminal?.name)}
                    value={departureTerminal?.name.value ?? '-'}
                  />
                </>
              )}
            </SummaryRow>
          </FramedSection>
        )}
        <FramedSection header='ETA'>
          <SummaryRow>
            <ReadonlyField
              label='ETA to PS'
              name='eta'
              note={getOriginalValueNote(eta)}
              value={(eta.value?.format('DD MMM YYYY HH:mm') ?? '-') + ' UTC'}
            />
            {shouldCalculateRDPS(
              report,
              reports,
              remainingDistanceToPilotStation.isOverridden,
            ) ? (
              <ReadonlyField
                addon={UNITS.NAUTICAL_MILE}
                label='Remaining distance to PS'
                name='calculated-remaining-distance'
                value={getCalculatedRDPS(report, reports)}
              />
            ) : (
              <ReadonlyField
                addon={UNITS.NAUTICAL_MILE}
                label='Remaining distance to PS'
                name='remaining-distance'
                note={getOriginalValueNote(remainingDistanceToPilotStation)}
                value={remainingDistanceToPilotStation.value ?? '-'}
              />
            )}
            <ReadonlyField
              label='Remaining time to PS'
              name='remaining-time'
              value={getRemainingTime()}
            />
          </SummaryRow>
        </FramedSection>
        <FramedSection header='Distance'>
          <SummaryRow>
            <ReadonlyField
              addon={UNITS.NAUTICAL_MILE}
              label='Distance logged'
              name='distance-logged'
              note={getOriginalValueNote(distanceLogged)}
              value={distanceLogged.value ?? '-'}
            />
            <ReadonlyField
              addon={UNITS.NAUTICAL_MILE}
              label='Distance observed'
              name='distance-observed'
              note={getOriginalValueNote(distanceObserved)}
              value={distanceObserved.value ?? '-'}
            />
          </SummaryRow>
        </FramedSection>
        <FramedSection header='Speed'>
          <SummaryRow>
            <ReadonlyField
              addon={UNITS.KNOTS}
              label='Speed logged'
              name='speed-logged'
              value={distanceAndSpeedObservations(
                distanceLogged,
                report.engineRoom.mainEngines,
              )}
            />
            <ReadonlyField
              addon={UNITS.KNOTS}
              label='Speed observed'
              name='speed-observed'
              value={distanceAndSpeedObservations(
                distanceObserved,
                report.engineRoom.mainEngines,
              )}
            />
          </SummaryRow>
        </FramedSection>
        <FramedSection header='Draught'>
          <SummaryRow>
            <ReadonlyField
              addon={UNITS.METER}
              label={`${
                reportType === ApiReportType.DEPARTURE
                  ? 'Visual draught'
                  : 'Draught'
              } fore`}
              name='draught-fore'
              note={getOriginalValueNote(draughtFore)}
              value={draughtFore.value ?? '-'}
            />
            <ReadonlyField
              addon={UNITS.METER}
              label={`${
                reportType === ApiReportType.DEPARTURE
                  ? 'Visual draught'
                  : 'Draught'
              } aft`}
              name='draught-aft'
              note={getOriginalValueNote(draughtAft)}
              value={draughtAft.value ?? '-'}
            />
            <ReadonlyField
              addon={UNITS.METER}
              label='Mean draught'
              name='mean-draught'
              value={calculateMeanDraught(draughtAft, draughtFore)}
            />
            <ReadonlyField
              addon={UNITS.METRIC_TON}
              label='Ballast water'
              name='ballast-water'
              note={getOriginalValueNote(ballastWater)}
              value={ballastWater.value ?? '-'}
            />
          </SummaryRow>
        </FramedSection>
        <FramedSection header='Water'>
          <SummaryRow>
            <ReadonlyField
              addon={UNITS.TEMPERATURE}
              label='Water temperature'
              name='water-temperature'
              note={getOriginalValueNote(waterTemperature)}
              value={waterTemperature.value ?? '-'}
            />
            <ReadonlyField
              addon={UNITS.METER}
              label='Water depth'
              name='water-depth'
              note={getOriginalValueNote(waterDepth)}
              value={waterDepth.value ?? '-'}
            />
          </SummaryRow>
        </FramedSection>
      </FormSection>
      <FormSection header='Events - Added from Engine room'>
        {sortedEngineRoomEvents.length ? (
          sortedEngineRoomEvents.map((event) => (
            <EventRow
              data={event}
              key={`report-event-${event.id}`}
              report={report}
            />
          ))
        ) : (
          <p>There are no events reported from the Engine Room.</p>
        )}
      </FormSection>
      <FormSection header='Events - Added from Bridge'>
        {sortedBridgeEvents.length ? (
          sortedBridgeEvents.map((event) => (
            <EventRow
              data={event}
              key={`report-event-${event.id}`}
              report={report}
            />
          ))
        ) : (
          <p>There are no events reported from the Bridge.</p>
        )}
      </FormSection>
      <FormSection header='Report comments'>
        <FramedSection header='Comments from Bridge'>
          {(comment ?? '-').split('\n').map((string) => (
            <p key={string}>{string}</p>
          ))}
        </FramedSection>
      </FormSection>
    </>
  )
}

export default BridgeSummary
