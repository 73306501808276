import { useContext, useState } from 'react'
import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import Moment from 'moment'
import { v4 as uuidv4 } from 'uuid'
import { McButton } from '@maersk-global/mds-react-wrapper'

import { postManualCorrectionRepairs } from '../../../../services/performance'
import { FuelLineDataLoss } from '../../../../api-models/performance/fuel-consumption'
import { displayErrorModal, formatValue } from '../../../../utils'
import { VesselPageContext } from '../../../../contexts'
import {
  DateTimeInput,
  FormInputWithUnit,
  Loading,
  TextArea,
} from '../../../../commons'
import TotalizerModal from '../totalizer-modal'
import * as S from './style'
import { amountPerHour, zeroSecondsDate } from '../../utils/fuel-consumption'

type Props = {
  loss: FuelLineDataLoss
  refreshData: Function
  id: string
}

const FuelConsumptionDataLossForm = ({ loss, refreshData, id }: Props) => {
  const imoNo = useContext(VesselPageContext).imoNo!
  const initialValues = {
    startTimestamp: zeroSecondsDate(loss.startTimestamp),
    endTimestamp: zeroSecondsDate(loss.endTimestamp),
    consumedMass: loss.consumedMassEstimate?.calculationSucceded
      ? loss.consumedMassEstimate.diffValue
      : null,
    notes: '',
  }
  const [totalizerActive, setTotalizerActive] = useState(false)

  const handleSubmit = async (values, helpers) => {
    try {
      await postManualCorrectionRepairs(imoNo, {
        ...values,
        fuelLineType: loss.fuelLineType,
      })
      refreshData()
      helpers.setSubmitting(false)
    } catch (err) {
      void displayErrorModal({
        statusText: 'Failed to repair loss',
        message: err.message,
      })
    }
  }

  // Formik validation schema
  const validationSchema = Yup.object().shape({
    startTimestamp: Yup.date().required('Required'),
    endTimestamp: Yup.date().required('Required'),
    consumedMass: Yup.string()
      .nullable()
      .required('Please provide an estimated consumed mass.'),
    notes: Yup.string().max(1000, 'Limit of 1000 characters exceeded'),
  })

  return (
    <>
      <Formik
        key={id}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({
          values,
          touched,
          errors,
          handleChange,
          setFieldValue,
          isSubmitting,
        }) => (
          <Form>
            <S.Container>
              {isSubmitting && (
                <S.Loading>
                  <Loading />
                </S.Loading>
              )}
              <S.LossContainer>
                <S.Header>Data Loss period</S.Header>
                <Field
                  value={values.startTimestamp}
                  name='startTimestamp'
                  component={(props) => (
                    <DateTimeInput
                      id={uuidv4()}
                      {...props}
                      selected={Moment.utc(values.startTimestamp)}
                    />
                  )}
                  disabled
                />
                <S.Label>Data loss from</S.Label>

                <Field
                  value={values.endTimestamp}
                  name='endTimestamp'
                  component={(props) => (
                    <DateTimeInput
                      id={uuidv4()}
                      {...props}
                      selected={Moment.utc(props.field.value)}
                      minDate={Moment.utc(loss.startTimestamp)}
                      maxDate={Moment.utc(loss.endTimestamp)}
                      onChange={(val) =>
                        setFieldValue('endTimestamp', val.toISOString())
                      }
                    />
                  )}
                  onChange={handleChange}
                />
                <S.Label>Data loss to</S.Label>

                <TextArea
                  name='notes'
                  value={values.notes}
                  onChange={handleChange}
                />
                {touched.notes && errors.notes ? (
                  <S.ErrorLabel>{errors.notes}</S.ErrorLabel>
                ) : (
                  <S.Label>Insert comment</S.Label>
                )}
              </S.LossContainer>

              <S.ConsumptionContainer>
                <S.Header>Consumption</S.Header>
                <S.Button>
                  <McButton
                    type='button'
                    variant='outlined'
                    label='Totalizer at specific time'
                    icon='cog'
                    click={() => setTotalizerActive(true)}
                  />
                </S.Button>

                <S.ConsumptionField>
                  <Field
                    value={values.consumedMass}
                    name='consumedMass'
                    component={FormInputWithUnit}
                    formatInputValue={(value) => formatValue(value, 3, '')}
                    type='number'
                    unit='MT'
                    width='220px'
                  />
                  <S.ConsumptionPerHour>
                    {values.consumedMass && values.consumedMass > 0
                      ? `${amountPerHour(
                          values.startTimestamp,
                          values.endTimestamp,
                          values.consumedMass,
                        )} MT/h`
                      : ''}
                  </S.ConsumptionPerHour>
                </S.ConsumptionField>
                {touched.consumedMass && errors.consumedMass ? (
                  <S.ErrorLabel>{errors.consumedMass}</S.ErrorLabel>
                ) : (
                  <S.Label>Insert est. consumption</S.Label>
                )}
              </S.ConsumptionContainer>
            </S.Container>

            <S.Actions>
              <McButton appearance='primary' label='Save' type='submit' />
            </S.Actions>
          </Form>
        )}
      </Formik>

      <TotalizerModal
        handleClose={() => setTotalizerActive(false)}
        visible={totalizerActive}
        timestamp={loss.endTimestamp}
      />
    </>
  )
}

export default FuelConsumptionDataLossForm
