import { useContext } from 'react'
import { useField } from 'formik'
import { McInput } from '@maersk-global/mds-react-wrapper'

import { WindowContext } from '../../../../contexts'

type Props = {
  name: string
  label?: string
  unit?: string
}

const MfeInputNumber = ({ name, label, unit }: Props) => {
  const { windowSize } = useContext(WindowContext)
  const [field, meta, helpers] = useField<number | null>(name)

  /**
   * Prevents changing the value of the input field by scrolling while the
   * cursor is over it.
   */
  const handleOnWheel = (event: WheelEvent) => {
    if (document.activeElement === event.target) {
      event.preventDefault()
    }
  }

  const handleBlur = async (event: FocusEvent) => {
    const target = event.target as HTMLInputElement
    const stringValue = target.value as string | null
    let newValue: number | null = null
    if (stringValue !== null && stringValue !== '') {
      newValue = Number(stringValue)
    }

    if (field.value === newValue) {
      // No change. Do nothing.
      return
    }

    // Update the formik field value.
    await helpers.setValue(newValue)
  }

  return (
    <McInput
      fit={windowSize}
      width='100'
      label={label}
      type='number'
      value={field.value}
      suffix={unit}
      invalid={Boolean(meta.error)}
      errormessage={meta.error}
      // @ts-ignore
      onwheel={handleOnWheel}
      blur={handleBlur}
    />
  )
}

export default MfeInputNumber
