import * as React from 'react'
import styled, { IThemeInterface, fillOpacity, normal } from '../theme'

const LabelRow = styled.div`
  padding: 16px 8px 8px;
  margin-left: 8px;
  margin-right: -20px;
  display: flex;
  justify-content: space-between;
`

const Label = styled.div`
  border-radius: 4px;
  padding: 2px 4px;
  font-size: 10px;
  width: fit-content;
  min-width: 32px;
  text-align: center;
`

interface ChartLabelsProps {
  labelValue1: string
  labelTheme1?: IThemeInterface
  labelValue2?: string
  labelTheme2?: IThemeInterface
  margins?: { left: number; right: number }
}

export const ChartLabels = (props: ChartLabelsProps) => {
  const theme1 = props.labelTheme1
    ? fillOpacity(props.labelTheme1, 0.2)
    : normal
  const theme2 = props.labelTheme2
    ? fillOpacity(props.labelTheme2, 0.2)
    : normal
  const labelOneStyle = {
    backgroundColor: theme1.bg,
    color: !!theme1.font ? theme1.font : theme1.fg,
    border: `1px solid ${theme1.border}`,
  }

  const labelTwoStyle = {
    backgroundColor: theme2.bg,
    color: !theme1.font ? theme1.font : theme1.fg,
    border: `1px solid ${theme2.border}`,
  }

  return (
    <LabelRow
      style={
        props.margins && {
          marginLeft: `${props.margins.left}px`,
          marginRight: `${props.margins.right}px`,
        }
      }
    >
      <Label style={labelOneStyle}>{props.labelValue1}</Label>
      {props.labelValue2 && (
        <Label style={labelTwoStyle}>{props.labelValue2}</Label>
      )}
    </LabelRow>
  )
}
