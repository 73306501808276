import { type FilterFn } from '@tanstack/react-table'

import { FUEL_TYPE_NAMES, FuelType } from '../../../../utils'
import type { UserEntry } from '../../../../api-models/performance/fuel-oil-stock'

export const fuelTypeFilterOptions = Object.entries(FUEL_TYPE_NAMES).map(
  ([value, label]) => ({ value, label }),
)

export const fuelTypeFilter: FilterFn<UserEntry> = (
  row,
  columnId,
  fuelType: FuelType,
) => row.original.fuelType === fuelType
