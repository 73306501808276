import * as React from 'react'
import styled, { orange } from '../theme'

const NotificationBadgeSpan = styled.span<{ bg?: string; fg?: string }>`
  display: flex;
  min-height: 14px;
  border-radius: 8px;
  background-color: ${(props) => (props.bg ? `${props.bg}` : orange[500])};
  color: ${(props: NotificationBadgeProps) =>
    props.fg ? `${props.fg}` : '#000'};
  padding: 0 2px;
  font-size: 12px;
  min-width: 17px;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}
`

interface NotificationBadgeProps {
  notificationCount?: number
  bg?: string
  fg?: string
}

export class NotificationBadge extends React.Component<
  NotificationBadgeProps,
  {}
> {
  public render() {
    const { notificationCount } = this.props
    if (notificationCount !== undefined && notificationCount > 0) {
      const { bg, fg } = this.props
      return (
        <NotificationBadgeSpan bg={bg} fg={fg}>
          <span>{notificationCount}</span>
        </NotificationBadgeSpan>
      )
    } else {
      return <span />
    }
  }
}
