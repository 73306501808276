import * as React from 'react'
import { RunningModeChart } from './RunningModeChart'
import { Hydra } from '../../../api-models/hydra'
import { mapRunningModeToStatus } from '../mapper'
import { IVesselPageContext } from '../../../contexts'
import { Filters, getDateRange, filtersChanged } from '../../../features/filter'
import { formatValue, getDefaultFilters } from '../../../utils'
import moment from 'moment'
import {
  layoutSizes,
  lightBlueColorScheme,
  tealColorScheme,
  purpleColorScheme,
} from '../../../theme'
import {
  ChartStencil,
  ContentCard,
  ChartContainer,
  FancyLabel,
  ValueLabel,
  VesselStatusBlock,
  Block,
} from '../../../commons'
import { withVesselPageContext } from '../../../higher-order-components'
import { RunningModeStatus } from '../types'
import { displayErrorModal } from '../../../utils/modal-utils'
import { getRunningModeStatus, getRunningMode } from '../../../services/hydra'
import { normal } from '../theme'

interface RunningModeChartContainerState {
  loading: boolean
  runningModeOverTime?: Hydra.Propulsion.RunningModeOverTime
  runningModeStatus?: RunningModeStatus
}

interface RunningModeChartContainerProps extends IVesselPageContext {
  filters?: Filters
}

class RunningModeChartContainer extends React.Component<
  RunningModeChartContainerProps,
  RunningModeChartContainerState
> {
  constructor(props: any) {
    super(props)
    this.state = {
      loading: false,
      runningModeOverTime: undefined,
      runningModeStatus: undefined,
    }
  }

  loadRunningModeData() {
    const { imoNo, vesselStatus } = this.props
    if (imoNo && vesselStatus && vesselStatus.hasData) {
      getRunningModeStatus(
        imoNo,
        moment.utc(vesselStatus.timestamp).toISOString(),
      )
        .then((json: Hydra.Propulsion.RunningModeOverTime) => {
          this.setState({
            runningModeStatus: mapRunningModeToStatus(json),
          })
        })
        .catch((e) => displayErrorModal(e))
    }
  }

  loadRunningModeOverTimeData() {
    const { imoNo, filters } = this.props
    if (imoNo) {
      this.setState({ loading: true })
      const period = getDateRange(
        getDefaultFilters(3, 'days').dateRange,
        filters,
      )
      getRunningMode(imoNo, period)
        .then((json: Hydra.Propulsion.RunningModeOverTime) => {
          this.setState({
            runningModeOverTime: json,
            loading: false,
          })
        })
        .catch((e) => {
          displayErrorModal(e)
        })
    }
  }

  componentDidUpdate(prevProps: any) {
    const { filters, imoNo, vesselStatus } = this.props
    if (
      filtersChanged(prevProps.filters, filters) ||
      prevProps.vesselStatus?.timestamp !== vesselStatus?.timestamp ||
      prevProps.imoNo !== imoNo
    ) {
      this.loadRunningModeOverTimeData()
      this.loadRunningModeData()
    }
  }

  componentDidMount() {
    this.loadRunningModeOverTimeData()
    this.loadRunningModeData()
  }

  formatUnitTypeRunningMode(value: number | null): string {
    if (value === null) return '%'
    if (value > 0) return '% heavy'
    if (value < 0) return '% light'
    return '%'
  }

  public render() {
    const { configuration } = this.props as any
    const { runningModeOverTime, runningModeStatus, loading } = this.state
    return (
      <ContentCard
        id='running_mode'
        title='Running mode'
        width={layoutSizes.half}
        helpTextKey='propulsion/running-mode'
      >
        <ChartContainer
          y1Label={{ name: '%', colorScheme: normal }}
          minHeight={263}
        >
          {!!loading && <ChartStencil chartType='area' minHeight={263} />}
          {!!runningModeOverTime && (
            <RunningModeChart
              data={runningModeOverTime}
              hasTwoMainEngines={configuration.hasTwoMainEngines}
            />
          )}
          {!!runningModeStatus && (
            <VesselStatusBlock timestamp={runningModeStatus.queryPeriod.to}>
              <Block>
                <FancyLabel
                  colorScheme={
                    configuration.hasTwoMainEngines
                      ? tealColorScheme
                      : lightBlueColorScheme
                  }
                >
                  {configuration.hasTwoMainEngines ? 'Port RM' : 'RM'}{' '}
                </FancyLabel>
                <ValueLabel
                  unit={this.formatUnitTypeRunningMode(
                    runningModeStatus.calculatedResults
                      .deviationFromPredictedMe1,
                  )}
                >
                  {formatValue(
                    runningModeStatus.calculatedResults
                      ? runningModeStatus.calculatedResults
                          .deviationFromPredictedMe1
                      : null,
                    2,
                  )}
                </ValueLabel>
              </Block>
              {configuration.hasTwoMainEngines && (
                <Block>
                  <FancyLabel colorScheme={purpleColorScheme}>
                    Stbd RM
                  </FancyLabel>
                  <ValueLabel
                    unit={this.formatUnitTypeRunningMode(
                      runningModeStatus.calculatedResults
                        .deviationFromPredictedMe2,
                    )}
                  >
                    {formatValue(
                      runningModeStatus.calculatedResults
                        ? runningModeStatus.calculatedResults
                            .deviationFromPredictedMe2
                        : null,
                      2,
                    )}
                  </ValueLabel>
                </Block>
              )}
            </VesselStatusBlock>
          )}
        </ChartContainer>
      </ContentCard>
    )
  }
}

export default withVesselPageContext(RunningModeChartContainer)
