import styled, { grey } from '../../../theme'

export const Wrapper = styled.div`
  padding: 16px 24px 16px 16px;

  .table-container {
    display: flex;
    flex: 1 1 auto;
    min-height: 450px;

    .table-container {
      display: flex;
      flex: 1 1 auto;
    }
  }

  .draught-label-row {
    grid-column: 1 / -1;
    background: white;
    z-index: 2;
  }

  .trim-label-row {
    display: flex;
    justify-content: space-between;
    grid-column: 2 / -1;
    background: white;
    z-index: 2;
  }
`

export const Table = styled.div<{
  nbrOfRows: number
  nbrOfCols: number
}>`
  display: grid;
  overflow: hidden;
  grid-template-columns: repeat(${(props) => props.nbrOfCols}, 1fr);
  grid-template-rows: repeat(${(props) => props.nbrOfRows}, 1fr);
  color: ${grey[900]};
  margin: 4px 0;

  .header-left {
    pointer-events: none;
  }

  .header-bottom {
    pointer-events: none;
  }

  .empty {
    pointer-events: none;
    cursor: unset;
    border-right: 1px solid ${grey[300]};
    border-bottom: 1px solid ${grey[300]};
  }

  .reference-point {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid black;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    cursor: pointer;
  }

  .cell {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    cursor: pointer;
  }

  .cell:hover,
  .reference-point:hover {
    filter: brightness(115%);
    z-index: 1;
    border: unset;
  }

  .cell:hover::before,
  .reference-point:hover::before {
    content: '';
    position: absolute;
    border: 2px solid black;
    left: 0;
    top: -50vh;
    height: 200vh;
    width: 100%;
    pointer-events: none;
    box-sizing: border-box;
  }

  .cell:hover::after,
  .reference-point:hover::after {
    content: '';
    pointer-events: none;
    position: absolute;
    border: 2px solid black;
    left: -100vw;
    top: 0;
    width: 200vw;
    height: 100%;
    box-sizing: border-box;
  }
`
