import styled from 'styled-components'

/**
 * As MDS does not provide a simple circle icon, we have to create one ourselves.
 * This circle is modelled after the MDS `check-circle` icon.
 */
export const Circle = styled.div`
  border: 1px solid rgb(20, 20, 20);
  border-radius: 50%;
  margin: 2px 2px 2px 2px;
  width: 16px;
  height: 16px;
  @media (min-width: ${(props) => props.theme.breakPoints.medium}px) {
    margin: 2px 2px 4px 2px;
  }
  @media (min-width: ${(props) => props.theme.breakPoints.large}px) {
    width: 20px;
    height: 20px;
    margin: 2px 2px 2px 2px;
  }
`
