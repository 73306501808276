import { HDCFormType } from './../../features/hybrid-data-collector/types'
import { EventType, Event, SubmitEvent } from '../../api-models/hdc/events'
import {
  ApiReportType,
  HdcReportResponse,
  HdcReportsResponse,
  IReport,
  UpdateBridgeReportRequest,
  UpdateEngineRoomReportRequest,
} from '../../api-models/hdc/report'
import { getGandalfUrl } from '../../utils/application-utils'
import {
  doDelete,
  doGet,
  doPatch,
  doPost,
  doPut,
} from '../../utils/request-utils'

export type CreateInitialReportPayload = {
  periodStart: string
  periodEnd: string
  reportType: ApiReportType
}

export const initReport = (
  imoNo: string,
  initialReport: CreateInitialReportPayload,
): Promise<HdcReportResponse> =>
  doPost(`${getGandalfUrl()}/vessels/${imoNo}/reports/draft`, {
    report: initialReport,
  })

export const getReportById = (
  imoNo: string,
  id: string,
): Promise<HdcReportResponse> =>
  doGet(`${getGandalfUrl()}/vessels/${imoNo}/reports/${id}`)

export const getAllReports = (imoNo: string): Promise<HdcReportsResponse> =>
  doGet(`${getGandalfUrl()}/vessels/${imoNo}/reports`)

export const deleteReport = (imoNo: string, id: string): Promise<void> =>
  doDelete(`${getGandalfUrl()}/vessels/${imoNo}/reports/${id}`)

export const updateBridgeReport = (
  imoNo: string,
  reportId: string,
  bridge: UpdateBridgeReportRequest,
): Promise<void> =>
  doPatch(`${getGandalfUrl()}/vessels/${imoNo}/reports/${reportId}/bridge`, {
    ...bridge,
  })

export const updateEngineRoomReport = (
  imoNo: string,
  reportId: string,
  engineRoom: UpdateEngineRoomReportRequest,
): Promise<void> =>
  doPatch(
    `${getGandalfUrl()}/vessels/${imoNo}/reports/${reportId}/engineroom`,
    {
      ...engineRoom,
    },
  )

export const submitBridgeReport = (
  imoNo: string,
  id: string,
): Promise<IReport> =>
  doPut(`${getGandalfUrl()}/vessels/${imoNo}/reports/${id}/bridge/submit`)

export const submitEngineRoomReport = (
  imoNo: string,
  id: string,
): Promise<IReport> =>
  doPut(`${getGandalfUrl()}/vessels/${imoNo}/reports/${id}/engineroom/submit`)

export const updateReportStateToEdit = (
  imo: string,
  id: string,
  section: HDCFormType,
): Promise<IReport> =>
  doPut(`${getGandalfUrl()}/vessels/${imo}/reports/${id}/${section}/edit`)

export const getAllEvents = (imoNo: string): Promise<{ events: Event[] }> =>
  doGet(`${getGandalfUrl()}/vessels/${imoNo}/events`)

export const getEventTypes = (): Promise<{ eventTypes: EventType[] }> =>
  doGet(`${getGandalfUrl()}/eventtypes`)

export const postEvent = (
  event: SubmitEvent,
  imo: string,
  reportId: string,
  section: HDCFormType,
): Promise<{ event: Event }> =>
  doPost(
    `${getGandalfUrl()}/vessels/${imo}/reports/${reportId}/${section}/events`,
    { event },
  )

export const editEvent = (
  event: Event,
  imo: string,
  reportId: string,
  section: HDCFormType,
): Promise<{ event: Event }> =>
  doPut(
    `${getGandalfUrl()}/vessels/${imo}/reports/${reportId}/${section}/events`,
    { event },
  )

export const deleteEvent = (
  imo: string,
  id: string,
  reportId: string,
  section: HDCFormType,
): Promise<void> =>
  doDelete(
    `${getGandalfUrl()}/vessels/${imo}/reports/${reportId}/${section}/events/${id}`,
  )

export const discardReportChanges = (
  imo: string,
  id: string,
  section: HDCFormType,
): Promise<{ report: IReport }> =>
  doPut(
    `${getGandalfUrl()}/vessels/${imo}/reports/${id}/${section}/edit/discard`,
  )
