import * as React from 'react'
import styled, { IColorScheme } from '../theme'
import { FancyLabel } from '../commons'
import { isNumber } from '../utils'

const Wrapper = styled.div<{
  expandHeight?: boolean
  minHeight?: number
  hideSideContent?: boolean
  sideContentWidth?: number
}>`
  .y-labels {
    width: ${(props) =>
      isNumber(props.sideContentWidth)
        ? `calc(100% - ${props.sideContentWidth}px)`
        : props.hideSideContent
        ? '95%'
        : 'calc(100% - 120px)'};
    padding: 16px 24px 0px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    height: 37px;
    .y-label {
      margin-bottom: 0;
    }
  }

  .chart-layout {
    display: flex;
    user-select: none;
    .chart-main-content {
      width: ${(props) =>
        isNumber(props.sideContentWidth)
          ? `calc(100% - ${props.sideContentWidth}px)`
          : props.hideSideContent
          ? '95%'
          : 'calc(100% - 120px)'};
      > div {
        min-height: ${(props) =>
          isNumber(props.minHeight) ? `${props.minHeight}` : '363'}px;
        ${(props) => (props.expandHeight ? 'height: calc(100%);' : '')}
      }
    }
    .chart-side-content {
      margin-top: -21px;
      width: ${(props) =>
        isNumber(props.sideContentWidth)
          ? `${props.sideContentWidth}px`
          : '120px'};
      display: flex;
      align-items: stretch;
      min-height: 100%;
      position: relative;
      flex-direction: column;

      .x-label {
        position: absolute;
        bottom: 24px;
      }
    }

    .tooltip {
      border-spacing: 4px;
      border-collapse: collapse;
      thead {
        tr:first-of-type {
          th {
            text-align: center;
            padding-bottom: 4px;
          }
        }
        tr:last-of-type {
          border-bottom: 1px solid #fff;
        }
      }
      tr:first-of-type {
        td {
          padding-top: 4px;
        }
      }
      td,
      th {
        padding-left: 12px;
        text-align: right;
        &:first-child {
          padding-left: 0;
        }
        &:first-child,
        &:last-child {
          text-align: left;
        }
      }
      .right-aligned {
        width: 100%;
        display: block;
        text-align: right;
      }
    }
  }
`

interface Label {
  name: string
  colorScheme: IColorScheme
}

export interface ChartContainerProps {
  y1Label?: Label
  y2Label?: Label
  xLabel?: Label
  minHeight?: number
  sideContentWidth?: number
  hideSideContent?: boolean
  expandHeight?: boolean
  children?: React.ReactNode
}

export const ChartContainer = (props: ChartContainerProps) => {
  const {
    y1Label,
    y2Label,
    xLabel,
    minHeight,
    sideContentWidth,
    hideSideContent,
    children,
    expandHeight,
  } = props
  const [chart, ...sideContent] = React.Children.toArray(children).map(
    (child) => child,
  )

  return (
    <Wrapper
      expandHeight={expandHeight}
      minHeight={minHeight}
      sideContentWidth={sideContentWidth}
      hideSideContent={!!hideSideContent}
    >
      {(y1Label || y2Label) && (
        <div className='y-labels'>
          {y1Label && (
            <FancyLabel className='y-label' colorScheme={y1Label.colorScheme}>
              {y1Label.name}
            </FancyLabel>
          )}
          {y2Label && (
            <FancyLabel className='y-label' colorScheme={y2Label.colorScheme}>
              {y2Label.name}
            </FancyLabel>
          )}
        </div>
      )}
      <div className='chart-layout'>
        <div className='chart-main-content'>{chart}</div>
        {!hideSideContent && (
          <aside className='chart-side-content'>
            {sideContent}
            {xLabel && (
              <FancyLabel className='x-label' colorScheme={xLabel.colorScheme}>
                {xLabel.name}
              </FancyLabel>
            )}
          </aside>
        )}
      </div>
    </Wrapper>
  )
}
