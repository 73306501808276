import moment, { Moment } from 'moment'
import { EventType } from '../../../api-models/hdc/events'
import { ApiReportType, IReport } from '../../../api-models/hdc/report'
import { FuelLineDataLoss } from '../../../api-models/performance/fuel-consumption'
import {
  BridgeFormStep,
  EngineRoomFormStep,
  HDCFormType,
  HDCReportFormValues,
} from '../types'
import { transformHdcReportToFormikReport } from '../utils'
import { initialValues } from './provider'

export enum ActionType {
  RESET = 'RESET_STATE',
  SET_REPORT_TYPE = 'SET_REPORT_TYPE',
  SET_REPORT_TIMESTAMP = 'SET_REPORT_TIMESTAMP',
  SET_REPORTS = 'SET_REPORTS',
  SET_REPORT = 'SET_REPORT',
  SET_TERMINALS = 'SET_TERMINALS',
  SET_EVENTS = 'SET_EVENTS',
  SET_EVENT_TYPES = 'SET_EVENT_TYPES',
  SET_FORM_TYPE = 'SET_FORM_TYPE',
  SET_FORM_CURRENT_STEP = 'SET_FORM_CURRENT_STEP',
  SET_FORM_STEPS = 'SET_FORM_STEPS',
  SET_FORM_USE_CUSTOM_PORT = 'SET_FORM_USE_CUSTOM_PORT',
  SET_HAS_UNSAVED_EVENT = 'SET_HAS_UNSAVED_EVENT',
  SET_HAS_VALIDATION_ERROR = 'SET_HAS_VALIDATION_ERROR',
  SET_HAS_VALIDATION_WARNING = 'SET_HAS_VALIDATION_WARNING',
  SET_FUEL_DATA_LOSSES = 'SET_FUEL_DATA_LOSSES',
  SET_INVALID_REPORTS = 'SET_INVALID_REPORTS',
}

export interface ReducerAction {
  type: ActionType
  data?: any
}

export type THDCStepValidation = {
  [HDCFormType.BRIDGE_FORM]: {
    [step in BridgeFormStep]: boolean
  }
  [HDCFormType.ENGINE_FORM]: {
    [step in EngineRoomFormStep]: boolean
  }
}

export interface HDCReducerState {
  reportType: ApiReportType | null
  reports: IReport[] | null | undefined
  reportTimestamp: ReportTimestamp | null
  report: HDCReportFormValues | null
  eventTypes: EventType[] | undefined
  form: {
    type: HDCFormType | null
    currentStep: number
    steps: BridgeFormStep[] | EngineRoomFormStep[]
    hasUnsavedEvent: boolean
    hasValidationError: THDCStepValidation
    hasValidationWarning: THDCStepValidation
    useCustomArrival: boolean
    useCustomDeparture: boolean
  }
  fuelDataLosses: FuelLineDataLoss[] | undefined
  invalidReports: string[]
}

export interface ReportTimestamp {
  from: Moment
  to: Moment
}

const reducer: React.Reducer<HDCReducerState, ReducerAction> = (
  state = initialValues,
  action,
): HDCReducerState => {
  const { data, type } = action
  switch (type) {
    case ActionType.SET_REPORT:
      const report: IReport = data
      return {
        ...state,
        reportTimestamp: {
          from: moment(report.periodStart).utc(),
          to: moment(report.periodEnd).utc(),
        },
        reportType: report.reportType,
        report: transformHdcReportToFormikReport(report),
      }
    case ActionType.SET_REPORTS:
      return {
        ...state,
        reports: data.sort((a: IReport, b: IReport) =>
          a.seqNo > b.seqNo ? -1 : 1,
        ),
      }
    case ActionType.SET_REPORT_TIMESTAMP:
      return { ...state, reportTimestamp: data }
    case ActionType.SET_REPORT_TYPE:
      return { ...state, reportType: data }
    case ActionType.SET_EVENTS:
      return {
        ...state,
        report: state.report
          ? {
              ...state.report,
              [data.subReport]: {
                ...state.report[data.subReport],
                events: data.events,
              },
            }
          : null,
      }
    case ActionType.SET_EVENT_TYPES:
      return {
        ...state,
        eventTypes: data,
      }
    case ActionType.SET_FORM_STEPS:
      return {
        ...state,
        form: {
          ...state.form,
          currentStep: 1,
          steps: data,
        },
      }
    case ActionType.SET_FORM_TYPE:
      return {
        ...state,
        form: {
          ...state.form,
          type: data,
        },
      }
    case ActionType.SET_FORM_CURRENT_STEP:
      return {
        ...state,
        form: {
          ...state.form,
          currentStep: data,
        },
      }
    case ActionType.SET_FORM_USE_CUSTOM_PORT:
      return {
        ...state,
        form: {
          ...state.form,
          [data.portType]: data.value,
        },
      }
    case ActionType.SET_HAS_UNSAVED_EVENT:
      return {
        ...state,
        form: {
          ...state.form,
          hasUnsavedEvent: data,
        },
      }
    case ActionType.SET_HAS_VALIDATION_ERROR:
      return {
        ...state,
        form: {
          ...state.form,
          hasValidationError: {
            ...state.form.hasValidationError,
            [data.formType]: {
              ...state.form.hasValidationError[data.formType],
              [data.step]: data.isInvalid,
            },
          },
        },
      }
    case ActionType.SET_HAS_VALIDATION_WARNING:
      return {
        ...state,
        form: {
          ...state.form,
          hasValidationWarning: {
            ...state.form.hasValidationWarning,
            [data.formType]: {
              ...state.form.hasValidationWarning[data.formType],
              [data.step]: data.hasWarning,
            },
          },
        },
      }
    case ActionType.SET_FUEL_DATA_LOSSES:
      return {
        ...state,
        fuelDataLosses: data,
      }
    case ActionType.SET_INVALID_REPORTS:
      return {
        ...state,
        invalidReports: data,
      }
    case ActionType.RESET:
      return initialValues
    default:
      return state
  }
}

export default reducer
