import styled from 'styled-components'

import Accordion from '../../../components/accordion'
import OverwriteForm from '../components/overwrite-form'
import type {
  ManualCorrectionOverwrite,
  ManualCorrectionRepair,
} from '../../../api-models/performance/manual-corrections'
import { FUEL_LINE_TYPE_SELECT_OPTIONS, FuelLineType } from '../../../utils'
import { HelpText } from '../../help-texts'

const AccItem = styled.div`
  display: flex;
  align-items: center;
  line-height: 0;
`

type Props = {
  imoNo: string
  fuelLineTypes: FuelLineType[]
  repairs: ManualCorrectionRepair[]
  overwrites: ManualCorrectionOverwrite[]
  refreshFuelConsumption: () => void
}

/**
 * FuelConsumptionCorrections
 * @description component used to display corrections, this covers both repairs and manual overwrites.
 *
 * @param {string} imoNo A string representing the unique vessel ID.
 * @param {FuelLineType[]} fuelLineTypes An array of graph associated fuel line types.
 * @param {ManualCorrections[]} repairs An array of consumption repairs filtered by fuel line type.
 * @param {ManualCorrections[]} overwrites An array of consumption overwrites filtered by fuel line type.
 * @param {function} refreshFuelConsumption A callback that refreshes all of fuel consumption data set
 */
const FuelConsumptionCorrections = ({
  imoNo,
  fuelLineTypes,
  overwrites,
  repairs,
  refreshFuelConsumption,
}: Props) => {
  const fuelLineOptions = FUEL_LINE_TYPE_SELECT_OPTIONS.filter((op) =>
    fuelLineTypes.some((fl) => fl === op.value),
  )

  return (
    <Accordion
      depth={0}
      itemRenderer={() => (
        <AccItem>
          Manual Overwrite {<HelpText textKey='fuel-consumption/overwrite' />}
        </AccItem>
      )}
      toggle={''}
    >
      <OverwriteForm
        id='lorem'
        refreshData={refreshFuelConsumption}
        fuelLineOptions={fuelLineOptions}
      />
    </Accordion>
  )
}

export default FuelConsumptionCorrections
