export const GRID = {
  show: true,
  borderColor: '#E0E0E0',
  borderWidth: 0.5,
  containLabel: true,
}

export const NAME_LOCATION = 'middle'

export const X_AXIS_NAME_GAP = 28

export const FONT_SIZE = 14

export const TEXT_STYLE = {
  fontFamily: 'Maersk Text',
  fontSize: FONT_SIZE,
}

export const AXIS_LABEL = {
  show: true,
  color: '#000000',
  ...TEXT_STYLE,
}

export const AXIS_LINE = {
  show: true,
}

export const AXIS_TICK = {
  show: false,
}

export const MARK_LINE_STYLE = {
  type: 'dashed',
  width: 2,
}

export const TOOLTIP = {
  trigger: 'axis',
  backgroundColor: '#767676',
  borderWidth: 0,
  textStyle: {
    ...TEXT_STYLE,
    color: 'white',
  },
}

export const COLORS = ['#00243D', '#42B0D5']
export const COLOR = { primary: '#42B0D5', secondary: '#0073AB' }
