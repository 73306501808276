import React from 'react'
import imgDelete from '../../../../assets/images/help-section/reporting-navigation-delete.png'
import imgDraftReport from '../../../../assets/images/help-section/reporting-navigation-draft.png'
import imgNewReport from '../../../../assets/images/help-section/reporting-navigation-new-report.png'
import imgOverview from '../../../../assets/images/help-section/reporting-navigation-overview.png'
import imgSubmittedReport from '../../../../assets/images/help-section/reporting-navigation-submitted.png'
import imgSummary from '../../../../assets/images/help-section/reporting-navigation-summary.png'

const REPORT_STATUS = [
  {
    name: 'Draft',
    description:
      'The report has been created, but neither Bridge and Engine section have been completed',
    action: 'Complete and submit both Bridge and Engine sections',
  },
  {
    name: 'Engine',
    description: 'The Engine sections has not been completed',
    action: 'Complete and submit Engine section',
  },
  {
    name: 'Bridge',
    description: 'The Bridge section has not been completed',
    action: 'Complete and submit Bridge section',
  },
  {
    name: 'Edit',
    description: 'Both Bridge and Engine sections are in edit mode',
    action: 'Complete and submit Bridge abd Engine sections',
  },
  {
    name: 'Still editable',
    description:
      'All OK / the report has been submitted to shore. but changes can still be made if required',
    action: 'No action required',
  },
  {
    name: 'Done',
    description: 'All done and dusted',
    action: 'No further changes can be made to the report',
  },
]

export const ReportNavigation = React.forwardRef<HTMLDivElement>(
  (props, ref) => (
    <div ref={ref} id='help-section-subtopic'>
      <h4>In this guide you will learn</h4>
      <ul>
        <li>How to create a new report</li>
        <li>Where & how to edit and delete a report</li>
        <li>How to view and switch between Bridge and Engine section</li>
        <li>How to follow the change in Report status</li>
      </ul>
      <h4>Purpose</h4>
      <p>
        The purpose of this guide is to introduce you to the basic navigation in
        the StarConnect Reporting section and become aware of the reporting
        statuses that require action from you.
      </p>
      <h4>Prerequisites</h4>
      <ul>
        <li>
          All data losses within the report period (if any) should be repaired
          on the Consumption page for the report period. You will see Alert in
          StarConnect if there is data loss
        </li>
        <li>
          Your Stock module should be up to date - make sure all bunkerings are
          reported and soundings are up to date
        </li>
      </ul>
      <h4>Report overview</h4>
      <p>
        Then SC reporting can be found in the left side menu bar and will bring
        you to the Reporting Overview page where you have an overview of all
        your submitted reports. From this page you can Create, Edit, View and
        Delete reports and see report status.
      </p>
      <div className='image-container'>
        <img src={imgOverview} alt='Overview' />
        <img src={imgNewReport} alt='New report' />
      </div>
      <p className='title'>Create a report</p>
      <p>
        To create a new report simply click the report type you need. If it is
        the first report ever created in StarConnect you will be prompted to
        enter both Start and End date/time for the report period but from then
        on you will only have to enter closing time UTC for the report period.
      </p>
      <p>Please note:</p>
      <ul>
        <li>Max period for a Sea report is 36 hours</li>
        <li>
          If you by accident create and save a report with a wrong timestamp you
          need to delete it and create it again with correct timestamp
        </li>
      </ul>
      <p>
        When the report has been created you can work with both the Bridge and
        the Engine room report from multiple workstations at the same time. In
        both sections you will be guided through the reporting steps and you
        will need to confirm the data that has been auto logged for the period
        or add your manual readings to the report.
      </p>
      <p className='note'>
        Note: In order to send a report to shore both Bridge and Engine reports
        have to be submitted.
      </p>
      <div className='image-container'>
        <img src={imgDraftReport} alt='Draft report' />
        <img src={imgSubmittedReport} alt='Submitted report' />
      </div>
      <p className='title'>Edit a report</p>
      <p>
        If you find that there is an error in the reported data you can edit the
        report.
      </p>
      <p>
        The reports can only be edited for a limited period - max period is 15
        days back from latest report date.
      </p>
      <p>
        Please keep in mind that all fuel data should be corrected from
        Consumption and Stock pages. If any changes are made to fuel data, the
        consumption and ROB for the report period will automatically be
        recalculated and the report will be submitted to shore again.
      </p>
      <p>
        In the report overview page you can see if the report status is “Still
        editable” - if status is “Done” you can no longer edit the report.
      </p>
      <p>
        To edit the report, click on the report in the overview page and you can
        choose which section to edit and submit again
      </p>
      <p className='title'>Delete a report</p>
      <p>
        Only the latest report can be deleted - but if needed you can delete
        several reports one by one.
      </p>
      <p>
        Reports can only be deleted from the Reporting overview - Click the “. .
        .” in the Actions column.
      </p>
      <img src={imgDelete} alt='Delete report' />
      <p className='title'>Navigating between Bridge and Engine</p>
      <p>
        Both in Edit and View mode you can swap between Bridge and Engine
        section if you need to cross check your data.
      </p>
      <p>
        When editing - you can go to Start page and switch to editing of the
        other report section.
      </p>
      <p>
        When editing - you can swap between report summary for both Bridge and
        Engine.
      </p>
      <img src={imgSummary} alt='Summary' />
      <p className='title'>Report status</p>
      <p>
        Report status will change as you progress with your report and can be
        seen in the report overview:
      </p>
      <table>
        <thead>
          <tr>
            <th>Report status</th>
            <th>What does it mean</th>
            <th>What should you do</th>
          </tr>
        </thead>
        <tbody>
          {REPORT_STATUS.map((status, idx) => (
            <tr key={idx}>
              <td>{status.name}</td>
              <td>{status.description}</td>
              <td>{status.action}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  ),
)
