import * as yup from 'yup'

const draughtAndSeawaterValidationSchema = yup.object().shape({
  bridge: yup.object().shape({
    draughtFore: yup.object().shape({
      value: yup
        .number()
        .nullable()
        .required('Please enter static draught fore')
        .moreThan(
          0,
          'Please enter static draught fore - expected range 0 to 30.',
        )
        .lessThan(
          30,
          'The reported draught is outside expected range - expected range 0 to 30.',
        ),
    }),
    draughtAft: yup.object().shape({
      value: yup
        .number()
        .nullable()
        .required('Draught aft is required')
        .moreThan(
          0,
          'Please enter static draught fore - expected range 0 to 30.',
        )
        .lessThan(
          30,
          'The reported draught is outside expected range - expected range 0 to 30.',
        ),
    }),
    ballastWater: yup.object().shape({
      value: yup
        .number()
        .nullable()
        .required('Please enter ballast water [mt]')
        .min(0, 'Please enter ballast water [mt]'),
    }),
    waterTemperature: yup.object().shape({
      value: yup
        .number()
        .nullable()
        .required(
          'Please enter avg sea water temperature [deg C] for the report period',
        )
        .max(
          36,
          'The reported sea water temperature is outside expected range - expected range 36 to -9.',
        )
        .min(
          -9,
          'The reported sea water temperature is outside expected range - expected range 36 to -9.',
        ),
    }),
    waterDepth: yup.object().shape({
      value: yup
        .number()
        .nullable()
        .required(
          'Please estimate an average water depth [m] representative for the report period.',
        )
        .moreThan(
          0,
          'Please estimate an average water depth [m] representative for the report period. ',
        ),
    }),
  }),
})

export default draughtAndSeawaterValidationSchema
