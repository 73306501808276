import { useContext, useEffect, useRef } from 'react'
import styled from 'styled-components'
import * as echarts from 'echarts'

import { type GaugeData } from '../PerformanceOverview.types'
import {
  TEXT_STYLE,
  TOOLTIP,
} from '../../../EnginePerformanceTestPage/EnginePerformanceTestReportPage/Common/ChartConfig'
import { VesselPageContext } from '../../../../contexts'

const CHART_SERIES_CONFIG = {
  type: 'gauge',
  radius: 136,
  center: ['50%', '78%'],
  startAngle: 180,
  endAngle: 0,
  title: { show: false },
  splitLine: { show: false },
  axisTick: { show: false },
  axisLabel: { show: false },
}

const GaugeWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 16px;

  > div {
    width: 352px;
    height: 184px;
  }
`

type Props = {
  vesselName: string
  data: GaugeData
}

const PerformanceGauge = ({ vesselName, data }: Props) => {
  const chartRef = useRef(null)
  let contextVesselName =
    useContext(VesselPageContext).configuration!.vesselName

  useEffect(() => {
    if (!chartRef.current || data.length === 0) {
      return
    }

    const max = Math.ceil(Math.max(...data.map(({ value }) => Math.abs(value))))
    const ownVesselIdx = data.findIndex(({ name }) => name === vesselName)

    const chart = echarts.init(chartRef.current)
    const option = {
      title: [
        {
          show: true,
          text: 'Most Efficient',
          bottom: 12,
          right: 0,
          textStyle: {
            ...TEXT_STYLE,
          },
        },
        {
          show: true,
          text: 'Least Efficient',
          bottom: 12,
          left: 0,
          textStyle: {
            ...TEXT_STYLE,
          },
        },
      ],
      tooltip: {
        ...TOOLTIP,
        trigger: 'item',
      },
      series: [
        {
          name: 'my_vessel',
          detail: {
            show: true,
            offsetCenter: [0, '24%'],
            formatter: 'My vessel',
            ...TEXT_STYLE,
          },
          tooltip: { show: false },
          axisLine: {
            lineStyle: {
              width: 24,
              color: [
                [0.33, '#E9B2B7'],
                [0.66, '#FFEBA8'],
                [1, '#C5E5C2'],
              ],
            },
          },
          pointer: {
            length: '72%',
            width: 8,
            itemStyle: { color: '#00243D' },
          },
          data:
            ownVesselIdx !== -1
              ? [{ value: -data[ownVesselIdx].value }]
              : [{ value: contextVesselName }],
          min: -max,
          max: max,
          ...CHART_SERIES_CONFIG,
        },
        {
          name: 'sister_vessels',
          detail: { show: false },
          axisLine: { show: false },
          pointer: {
            length: '17%',
            offsetCenter: [0, '-83%'],
            icon: 'roundRect',
            width: data.length > 10 ? 4 : 8,
            itemStyle: { color: '#00243D' },
          },
          data: data.map(({ name, value }) => ({
            name,
            value: -value,
          })),
          tooltip: {
            formatter: (params: any) => {
              let txt = params.name
              if (params.name === vesselName) {
                txt += ' (My vessel)'
              }
              return txt
            },
            position: 'right',
          },
          min: -max,
          max: max,
          ...CHART_SERIES_CONFIG,
        },
      ],
    }
    // @ts-ignore
    chart.setOption(option)
  }, [data])

  return (
    <GaugeWrapper>
      <div ref={chartRef} />
    </GaugeWrapper>
  )
}

export default PerformanceGauge
