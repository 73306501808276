import { displayToast } from '../../../utils'

export const booleanOptions = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
]

export const displaySuccessToast = () => {
  void displayToast(
    'success',
    'Report submitted',
    'You have successfully reported the malfunctioning equipment.',
  )
}

/**
 * Sets the field errors on a Formik form based on errors returned from Gandalf.
 */
export const setFieldErrors = (
  err: GandalfApi.ValidationError,
  setFieldError: (field: string, message: string | undefined) => void,
) => {
  err.body.errors.forEach((error) => {
    error.paths.forEach((path) => {
      setFieldError(`${path.replace(/\.\./g, '.')}`, error.message)
    })
  })
}
