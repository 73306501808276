import React, { FunctionComponent } from 'react'
import { warningTheme } from '../../../../theme'
import { useFormikContext, getIn } from 'formik'
import { InfoBox } from '../../../../commons'
import { MixedBatch } from './MixedBatchForm'

interface MixWarningProps {
  name: string
}

const isMixingDifferentFuelTypes = (batches: MixedBatch[]) => {
  const fuelTypes = batches.reduce((fuelTypes: string[], batch: MixedBatch) => {
    if (batch.fuel?.type) {
      fuelTypes.push(`${batch.fuel.type + ' ' + batch.fuel.isDistillate}`)
    }
    return fuelTypes
  }, [])

  const uniqueFuelTypes = Array.from(new Set(fuelTypes))
  return uniqueFuelTypes.length > 1
}

export const MixWarning: FunctionComponent<MixWarningProps> = ({ name }) => {
  const { values, touched } = useFormikContext<any>()
  const batches = getIn(values, name)
  const isTouched = getIn(touched, name)
  const showWarning = isMixingDifferentFuelTypes(batches)

  return isTouched && showWarning ? (
    <InfoBox theme={warningTheme}>
      It appears you are mixing batches of different fuel types or distillates.
      Are you sure that is correct?
    </InfoBox>
  ) : null
}
