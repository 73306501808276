import { getDefaultFilters } from '../../../../utils'

export const initialState = {
  data: undefined,
  showEngineInChart: [true, true, true, true, true, true],
  chartsMounted: false,
  sfocShopTestCurves: [],
  dimensions: [
    'powerMix',
    'fpowerAE1',
    'fpowerAE2',
    'fpowerAE3',
    'fpowerAE4',
    'fpowerAE5',
    'powerAE1',
    'powerAE2',
    'powerAE3',
    'powerAE4',
    'powerAE5',
    'sfoc',
    'timestamp',
    'totalpower',
  ],
  defaultFilter: getDefaultFilters(14, 'd'),
  selectedShopTestCurve: 0,
}

export const SAMPLE_PERIOD = 90
