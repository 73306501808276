import { MfeEquipmentType as Equipment } from '../../MalfunctioningEquipmentPage.consts'
import {
  MainEngInstanceName,
  StaticModelType,
} from '../../../../queries/MasterDataApi/MasterDataApi.consts'

export type ModelOption = {
  value: ModelOptionValue
  label: string
}

export type ModelOptionValue = {
  model: string | null
  instanceNo: number | null
  id: string | null
}

export const getStaticModelsOptions = (
  equipment: Equipment.MainEngine | Equipment.AuxEngines,
  staticModels: Array<
    MasterDataApi.StaticModels.StaticModelWithInstanceNumbers<MasterDataApi.StaticModels.StaticModelData>
  >,
): Array<ModelOption> => {
  return staticModels
    .filter((sm) => {
      if (equipment === Equipment.MainEngine) {
        return sm.type === StaticModelType.MainEng
      }
      if (equipment === Equipment.AuxEngines) {
        return sm.type === StaticModelType.AuxEng
      }
      return false
    })
    .flatMap((staticModel) => {
      if (staticModel.instanceNos) {
        return staticModel.instanceNos
          .sort((a, b) => a - b)
          .map((instanceNo) => {
            const option: ModelOption = {
              value: {
                id: staticModel.id,
                instanceNo: instanceNo,
                model: staticModel.data.name,
              },
              label: '',
            }

            if (staticModel.type === StaticModelType.MainEng) {
              if (staticModel.instanceNos!.length === 1) {
                option.label = staticModel.data.name
              } else {
                option.label =
                  MainEngInstanceName[instanceNo] +
                  '  —  ' +
                  staticModel.data.name
              }
            } else {
              // This assumes StaticModelType.AuxEng
              option.label = '#' + instanceNo + '  —  ' + staticModel.data.name
            }

            return option
          })
      }

      return []
    })
}

export const getParametersOptions = (
  engines: Array<MasterDataApi.VesselParameters.Machinery.Engine>,
): Array<ModelOption> => {
  return engines.map(({ number }) => ({
    value: { instanceNo: number, model: null, id: null },
    label: '#' + number,
  }))
}
