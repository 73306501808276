import { type Period } from '../../api-models/performance/common'

export enum ESoundingType {
  Full = 'Full',
  Partial = 'Partial',
}

export interface IModalProps {
  entryId?: string
  openWindow?: Period
  onClose: () => void
}

export interface IBunkeringFormValues {
  deliveryDetails: {
    orderId: string
    portCode: string
    quantityAgreed: number | null
    quantityOrdered: number | null
  }
  notes: string
  oilType: ELubeOilType | null
  quantityPerChiefEngineer: number | null
  tbn: number | null
  timestamp: string | null // ISO date string;
}

export interface ILossFormValues {
  notes: string
  oilType: ELubeOilType | null
  quantity: number | null
  reasonCode: ELubeOilLossReasonCode | null
  timestamp: string | null // ISO date string;
}

export interface ISoundingFormValues {
  ae: TLubeOilSoundingItem[]
  clo: TLubeOilSoundingItem[]
  cloAdditive: TLubeOilSoundingItem[]
  type: ESoundingType
  me: TLubeOilSoundingItem[]
  notes: string
  other: TLubeOilSoundingItem[]
  timestamp: string | null // ISO date string;
}

export interface ITransferFormValues {
  fromOilContainer: ELubeOilContainer | null
  notes: string
  oilType: ELubeOilType | null
  quantity: number | null
  timestamp: string | null // ISO date string
  toOilContainer: ELubeOilContainer | null
}

export enum ELubeOilEntryType {
  Bunkering = 10,
  Sounding = 21,
  Loss = 30,
  Transfer = 40,
}

export type TLubeOilBunkering = {
  deliveryDetails: {
    orderId: string
    portCode: string
    quantityAgreed: number | null
    quantityOrdered: number | null
  }
  id?: string
  notes: string
  oilType: ELubeOilType
  quantityPerChiefEngineer: number
  readonly?: boolean
  tbn: number | null
  timestamp: string
}

export enum ELubeOilContainer {
  SpareClean = 10,
  Working = 21,
  WorkingStbd = 22,
  DayTank = 31,
  DayTankStbd = 32,
  SpareDirty = 40,
  BOB = 50,
  AE1 = 61,
  AE2 = 62,
  AE3 = 63,
  AE4 = 64,
  AE5 = 65,
}

export enum ELubeOilLossReasonCode {
  Leakage = 'LEAKAGE',
  Contamination = 'CONTAMINATION',
  Other = 'OTHER',
}

export enum ELubeOilType {
  CLO = 10,
  CLOAdditive = 15,
  ME = 20,
  AE = 30,
  Other = 40,
}

export type TLubeOilSoundingItem = {
  oilType: ELubeOilType
  oilContainer: ELubeOilContainer
  tbn: number | null
  rob: number | null
  unpumpable: number | null
  description: string
}

export type TLubeOilLoss = {
  id?: string
  notes: string
  oilType: ELubeOilType
  quantity: number
  readonly?: boolean
  reasonCode: ELubeOilLossReasonCode
  timestamp: string
}

export type TLubeOilSounding = {
  timestamp: string
  isPartial: boolean
  items: TLubeOilSoundingItem[]
  notes: string
  id?: string
  readonly?: boolean
}

export type TLubeOilTransfer = {
  fromOilContainer: ELubeOilContainer
  id?: string
  notes: string
  oilType: ELubeOilType
  quantity: number
  readonly?: boolean
  timestamp: string
  toOilContainer: ELubeOilContainer
}

export type TLubeOilEntry = {
  id: string
  type: ELubeOilEntryType
  timestamp: string
  oilType: ELubeOilType
  quantity: number
  hasNotes: boolean
  details: TLubeOilEntryDetails
  readonly: boolean
}

export type TLubeOilEntryDetails =
  | TLubeOilBunkeringDetails
  | TLubeOilSoundingDetails
  | TLubeOilLossDetails
  | TLubeOilTransferDetails

export type TLubeOilBunkeringDetails = { tbn?: number; portCode: string }

export type TLubeOilSoundingDetails = { isPartial: boolean }

export type TLubeOilLossDetails = { reasonCode: string }

export type TLubeOilTransferDetails = {
  fromOilContainer: ELubeOilContainer
  toOilContainer: ELubeOilContainer
}
