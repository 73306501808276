import { McButton } from '@maersk-global/mds-react-wrapper'

import { Performance } from '../../../../api-models'
import {
  FUEL_LINE_TYPE_TO_HEADER_TEXT_MAPPING,
  FuelType,
} from '../../../../utils'
import styled, {
  FuelColors,
  type IColorScheme,
  lg,
  md,
  media,
  sm,
  whiteColorScheme,
} from '../../../../theme'

const ConsumerCard = styled.div<{ colorScheme: IColorScheme }>`
  border: 1px solid ${({ colorScheme }) => colorScheme.stroke};
  border-radius: 4px;
  background: ${({ colorScheme }) => colorScheme.fill};
  box-sizing: border-box;
  ${media.sm`padding: ${sm.CONTENT_CARD_HEADER_PADDING}`}
  ${media.lg`padding: ${lg.CONTENT_CARD_HEADER_PADDING}`}
  ${media.md`padding: ${md.CONTENT_CARD_HEADER_PADDING}`}
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`
const ConsumerCardEngineName = styled.p`
  color: #000;
  text-align: left;
  width: 100%;
  margin-bottom: 12px;
`

const FuelTypeAndConsumptionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  align-items: center;
  margin-bottom: 12px;
`

const FuelTypeIndicator = styled.span<{ colorScheme: IColorScheme }>`
  font-size: 2em;
  color: ${({ colorScheme }) => colorScheme.stroke};
`

type Props = {
  fuelLine: Performance.Status.MainEngFuelLine | Performance.Status.FuelLine
  onClick: (
    fuelLine: Performance.Status.MainEngFuelLine | Performance.Status.FuelLine,
  ) => void
}

export const FuelConsumerCard = ({ fuelLine, onClick }: Props) => {
  const { fuelType, fuelLineType } = fuelLine

  const colorScheme =
    fuelType && fuelType > 0 ? FuelColors[FuelType[fuelType]] : whiteColorScheme

  return (
    <ConsumerCard colorScheme={colorScheme}>
      <ConsumerCardEngineName>
        {FUEL_LINE_TYPE_TO_HEADER_TEXT_MAPPING[fuelLineType]}
      </ConsumerCardEngineName>
      <FuelTypeAndConsumptionWrapper>
        <FuelTypeIndicator colorScheme={colorScheme}>
          {FuelType[fuelType] ? FuelType[fuelType] : 'N/A'}
        </FuelTypeIndicator>
      </FuelTypeAndConsumptionWrapper>
      <McButton
        fit='small'
        appearance='neutral'
        width='full-width'
        trailingicon={fuelType ? 'arrows-left-right' : undefined}
        click={(event) => {
          event.preventDefault()
          event.stopPropagation()
          onClick(fuelLine)
        }}
      >
        {fuelType ? 'Change fuel type' : 'Select fuel type'}
      </McButton>
    </ConsumerCard>
  )
}
