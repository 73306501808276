import { useState } from 'react'
import styled from 'styled-components'
import { McButton } from '@maersk-global/mds-react-wrapper'

import {
  FormElements,
  InputFieldWithUnit,
  ModalControls,
} from '../../../commons'
import { grey } from '../../../theme'
import { VESSEL_SETTINGS } from '../../../utils/constants'
import { postVesselSettings } from '../../../services/performance'
import { calculateEstimatedLeakagePerHour } from '../utils'

const Wrapper = styled.div`
  td.fuel-types-on-board {
    display: flex;
    width: unset !important;

    * {
      margin-right: 4px;
    }
  }

  .form-error {
    width: 100%;
  }

  button {
    height: 37px;
  }

  button div {
    padding: 0;
  }
`

const formAllowedValues = {
  estimatedLeakage: {
    min: 0.0,
    max: 2.0,
  },
}

type Props = {
  imoNo: string
  displayLeakage: boolean
  initialLeakage: number
  closeHandler: () => void
}

export function VesselSettingsForm(props: Props) {
  const { initialLeakage, displayLeakage, closeHandler, imoNo } = props

  const [estimatedLeakage, setEstimateLeakage] = useState(initialLeakage)
  const [estimatedLeakageError, setEstimatedLeakageError] = useState(false)
  const [submitError, setSubmitError] = useState('')
  const [requestInProgress, setRequestInProgress] = useState(false)

  const handleSubmit = (event) => {
    event.stopPropagation()
    event.preventDefault()
    const estimatedLeakageErrorOnSubmit =
      typeof estimatedLeakage !== 'number' || isNaN(+estimatedLeakage)
    setEstimatedLeakageError(estimatedLeakageErrorOnSubmit)
    if (!estimatedLeakageError) {
      setRequestInProgress(true)
      postVesselSettings(imoNo, [
        {
          type: VESSEL_SETTINGS.Leakage,
          value: estimatedLeakage,
        },
      ])
        .then((_) => {
          setRequestInProgress(false)
          closeHandler()
        })
        .catch((e) => {
          setRequestInProgress(false)
          setSubmitError(e.message)
        })
    }
  }

  const estimatedLeakagePerHour = calculateEstimatedLeakagePerHour(
    estimatedLeakage,
  ).replace('.', ',')
  return (
    <Wrapper>
      <FormElements.Form id='vessel-settings-form'>
        <FormElements.Table>
          <tbody>
            {displayLeakage && (
              <tr>
                <td>
                  <FormElements.Label>ME leakage</FormElements.Label>
                </td>
                <td style={{ display: 'flex', width: 'unset' }}>
                  <FormElements.Value style={{ minWidth: '130px' }}>
                    <InputFieldWithUnit
                      type='number'
                      id='estimatedLeakage-input'
                      value={
                        typeof estimatedLeakage === 'number'
                          ? estimatedLeakage
                          : ''
                      }
                      unit='MT/24 h'
                      width='130px'
                      error={estimatedLeakageError}
                      onChange={(event: any) => {
                        setEstimateLeakage(+event.target.value)
                      }}
                      step='0.1'
                      min={formAllowedValues.estimatedLeakage.min.toString()}
                      max={formAllowedValues.estimatedLeakage.max.toString()}
                      onBlur={() => {
                        setEstimatedLeakageError(
                          typeof estimatedLeakage !== 'number' ||
                            isNaN(+estimatedLeakage),
                        )
                      }}
                    />
                  </FormElements.Value>
                  &nbsp;
                  <span style={{ color: grey[700], alignSelf: 'center' }}>
                    ({estimatedLeakagePerHour} kg/h)
                  </span>
                </td>
              </tr>
            )}
            {!!estimatedLeakageError && (
              <tr>
                <td colSpan={2}>
                  <FormElements.Error>
                    Please enter estimated leakage
                  </FormElements.Error>
                </td>
              </tr>
            )}
          </tbody>
        </FormElements.Table>
      </FormElements.Form>
      {displayLeakage ? (
        <ModalControls errorMessage={submitError}>
          <McButton
            label='Cancel'
            appearance='neutral'
            click={closeHandler}
            type='button'
          />
          <McButton
            label='Save changes'
            appearance='primary'
            disabled={requestInProgress}
            click={handleSubmit}
            type='submit'
          />
        </ModalControls>
      ) : (
        <ModalControls>
          <McButton
            label='Close'
            appearance='primary'
            click={closeHandler}
            type='button'
          />
        </ModalControls>
      )}
    </Wrapper>
  )
}
