import React from 'react'
import * as moment from 'moment'
import { type EChartOption, init } from 'echarts'

import { Performance } from '../../../../api-models'
import { formatFuelTypes, getEngineTheme } from '../../auxiliary-util'
import { type QueryPeriod } from '../../../filter'
import { grey } from '../../../../theme'
import {
  formatValue,
  getClosestTenMinute,
  stripDecimals,
  tooltip,
} from '../../../../utils'
import { Chart } from '../../../../utils/models'
import {
  ACTIVATE_ZOOM_ACTION,
  AXIS_LABEL,
  AXIS_LINE,
  AXIS_POINTER_LABEL,
  AXIS_SPLIT_LINE,
  AXIS_TICK,
  GRID,
  TOOLBOX_SCATTER,
  UNITS,
} from '../../../../utils/constants'

type Props = {
  data: Performance.Sfoc.AeSfocData
  showEngine: boolean[]
  chartData: Array<Array<number | undefined>>
  queryPeriod: QueryPeriod
  dimensions: Array<string>
  sfocShopTestCurves: Array<SfocShopTestCurve>
  selectedShopTestCurve?: number
}

type State = {}

export class AeSfocVsPowerChart extends React.Component<Props, State> {
  private readonly chartNode: React.RefObject<HTMLDivElement>
  private chart?: Chart

  constructor(props: Props) {
    super(props)
    this.state = {}
    this.chartNode = React.createRef()
  }

  getOptions = (data: any): EChartOption | any => {
    const {
      showEngine,
      chartData,
      dimensions,
      sfocShopTestCurves,
      selectedShopTestCurve,
    } = this.props

    // Shall always be the last 6 hours
    const sinceLastSixHours = getClosestTenMinute(
      moment.utc().subtract(6, 'h'),
    ).valueOf()

    return {
      grid: GRID,
      xAxis: [
        {
          type: 'value',
          axisLine: AXIS_LINE,
          axisTick: AXIS_TICK,
          splitLine: AXIS_SPLIT_LINE,
          axisLabel: {
            formatter: (value: any) => stripDecimals(value, 1),
            ...AXIS_LABEL,
          },
        },
      ],
      yAxis: [
        {
          id: 'aesfoc',
          type: 'value',
          axisTick: AXIS_TICK,
          axisLine: AXIS_LINE,
          splitLine: AXIS_SPLIT_LINE,
          axisLabel: {
            formatter: (value: any) => stripDecimals(value, 1),
            ...AXIS_LABEL,
          },
          min: 150,
        },
      ],
      tooltip: {
        trigger: 'item',
        axisPointer: {
          type: 'cross',
          label: AXIS_POINTER_LABEL,
        },
        textStyle: {
          color: grey[50],
        },
        backgroundColor: 'rgba(50,50,50,0.9)',
        formatter: (params: any) => {
          // dataIndex relates to the actual index in the original arrays
          let dataIndex = params.dataIndex
          let labels: Array<{
            [key: string]: string | number
          }> = []

          if (params.seriesName === 'Mix') {
            data.runningEnginesSeries[dataIndex].forEach(
              (entry: Performance.Sfoc.RunningEnginesSerie) => {
                labels.push({
                  label: `AE ${entry.number} Load`,
                  value: formatValue(entry.load, 1),
                  unit: '%',
                })
              },
            )

            data.runningEnginesSeries[dataIndex].forEach(
              (entry: Performance.Sfoc.RunningEnginesSerie) => {
                labels.push({
                  label: `AE ${entry.number} Power`,
                  value: formatValue(entry.power, 1),
                  unit: UNITS.KILO_WATT,
                })
              },
            )
          } else {
            data.runningEnginesSeries[dataIndex].forEach(
              (entry: Performance.Sfoc.RunningEnginesSerie) => {
                labels.push({
                  label: `AE ${entry.number} Load`,
                  value: formatValue(entry.load, 1),
                  unit: '%',
                })
              },
            )
          }

          const fuelTypes = formatFuelTypes(data.fuelTypesSeries[dataIndex])
          labels.push(
            {
              label: `Power`,
              value: formatValue(data.powerSeries[dataIndex], 1),
              unit: UNITS.KILO_WATT,
            },
            {
              label: 'Consumption',
              value: formatValue(data.consumptionSeries[dataIndex] * 1000, 0),
              unit: UNITS.KG_PER_HOUR,
            },
            {
              label: 'SFOC',
              value: formatValue(data.sfocSeries[dataIndex], 1),
              unit: UNITS.SFOC,
            },
            {
              label: 'Fuel Type',
              value: fuelTypes,
              unit: '',
            },
          )
          return tooltip(data.timestamps[dataIndex], labels)
        },
      },
      toolbox: TOOLBOX_SCATTER,
      legend: {
        show: false,
        selected: {
          Mix: showEngine[0],
          AE1: showEngine[1],
          AE2: showEngine[2],
          AE3: showEngine[3],
          AE4: showEngine[4],
          AE5: showEngine[5],
        },
      },
      dataset: [
        {
          dimensions: dimensions,
          source: chartData,
        },
        ...sfocShopTestCurves.map(({ curve, staticModelId }) => ({
          id: staticModelId,
          dimensions: ['power', 'sfoc'],
          source: curve,
        })),
      ],
      series: ['Mix', 'AE1', 'AE2', 'AE3', 'AE4', 'AE5']
        .map((engineId) => {
          const seriesColor = getEngineTheme(engineId)
          return {
            name: engineId,
            type: 'scatter',
            symbolSize: 4,
            dimensions: dimensions,
            itemStyle: {
              color: (params: any) => {
                return params.data[dimensions.indexOf('timestamp')] >
                  sinceLastSixHours
                  ? 'red'
                  : seriesColor.stroke
              },
              opacity: 1,
            },
            datasetIndex: 0,
            encode: {
              x: engineId === 'Mix' ? 'powerMix' : `fpower${engineId}`,
              y: 'sfoc',
            },
            emphasis: {
              itemStyle: {
                opacity: 1,
              },
              label: {
                show: true,
                position: 'inside',
                offset: [0, 0],
                formatter: () => '●',
                fontSize: 24,
                color: 'purple',
              },
            },
          }
        })
        .concat(
          // @ts-ignore
          sfocShopTestCurves
            .map((curve, idx) => {
              if (idx !== selectedShopTestCurve) return null

              return {
                name: curve.staticModelName,
                type: 'line',
                z: 3,
                smooth: true,
                datasetIndex: idx + 1,
                symbol: 'none',
                itemStyle: {
                  color: 'black',
                },
                lineStyle: {
                  width: 3,
                },
              }
            })
            .filter(Boolean),
        ),
    }
  }

  zoomOut = (silent?: boolean) => {
    if (this.chart) {
      this.chart.dispatchAction(
        {
          type: 'dataZoom',
          start: 0,
          end: 100,
        },
        !!silent,
      )
    }
    this.setState({ startValue: undefined, endValue: undefined })
  }

  componentDidMount() {
    const { data } = this.props
    if (this.chartNode.current) {
      const chart = init(this.chartNode.current) as Chart
      chart.setOption(this.getOptions(data))
      chart.dispatchAction(ACTIVATE_ZOOM_ACTION)
      chart.group = 'ae-sfoc'
      this.chart = chart
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { data, showEngine, selectedShopTestCurve } = this.props
    if (
      this.chart &&
      (prevProps.data !== data ||
        prevProps.selectedShopTestCurve !== selectedShopTestCurve)
    ) {
      this.chart.setOption(this.getOptions(data), true)
    } else if (this.chart && showEngine !== prevProps.showEngine) {
      showEngine.forEach((engine, index) => {
        if (this.chart && engine !== prevProps.showEngine[index]) {
          this.chart.dispatchAction({
            type: 'legendToggleSelect',
            name: index === 0 ? 'Mix' : `AE${index}`,
          })
        }
      })
    }
  }

  public render() {
    return (
      <div
        onDoubleClick={() => {
          this.zoomOut(false)
        }}
        id='aesfoc-vs-power-chart'
        ref={this.chartNode}
      />
    )
  }
}
