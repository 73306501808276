import { useContext, useState } from 'react'
import { McRadio, McRadioGroup } from '@maersk-global/mds-react-wrapper'
import type { IDateRangeValue } from '@maersk-global/mds-components-core/mc-date-range/types'

import {
  ChartType,
  performanceMetricsOptions,
} from './PerformanceMetricsCharts.consts'
import CommonMetricsChart from './CommonMetricsChart/CommonMetricsChart'
import LoadProfileChart from './LoadProfileChart/LoadProfileChart'
import { WindowContext } from '../../../../contexts'

type Props = {
  dateRange?: IDateRangeValue
  sisterVesselImoList: string
}

const PerformanceMetricsCharts = ({
  dateRange,
  sisterVesselImoList,
}: Props) => {
  const { windowSize } = useContext(WindowContext)
  const [selectedChartType, setSelectedChartType] = useState(ChartType.Sfoc)

  const renderChartComponent = () => {
    switch (selectedChartType) {
      case ChartType.Sfoc:
        return (
          <CommonMetricsChart
            sisterVesselImoList={sisterVesselImoList}
            dateRange={dateRange}
            chartType={ChartType.Sfoc}
          />
        )
      case ChartType.LoadProfile:
        return (
          <LoadProfileChart
            sisterVesselImoList={sisterVesselImoList}
            dateRange={dateRange}
          />
        )
      case ChartType.Pmax:
        return (
          <CommonMetricsChart
            sisterVesselImoList={sisterVesselImoList}
            dateRange={dateRange}
            chartType={ChartType.Pmax}
          />
        )
      case ChartType.Pcomp:
        return (
          <CommonMetricsChart
            sisterVesselImoList={sisterVesselImoList}
            dateRange={dateRange}
            chartType={ChartType.Pcomp}
          />
        )
      default:
        return null
    }
  }

  return (
    <>
      <div>
        <h3>Main Engine Performance Metrics</h3>
        <p>
          The graph shows the performance parameters for Main Engine in the
          selected date range.
        </p>
      </div>
      <McRadioGroup fit={windowSize} orientation='horizontal' legend=''>
        {performanceMetricsOptions.map((option) => (
          <McRadio
            key={option.value.toString()}
            fit={windowSize}
            label={option.label}
            value={option.value.toString()}
            checked={selectedChartType === option.value}
            change={() => setSelectedChartType(option.value)}
          />
        ))}
      </McRadioGroup>
      {renderChartComponent()}
    </>
  )
}

export default PerformanceMetricsCharts
