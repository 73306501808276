import { type IMcNotification } from '@maersk-global/mds-components-core/mc-notification/types'

import { type THDCStepValidation } from '../../features/hybrid-data-collector/context/reducer'
import { HDCFormType } from '../../features/hybrid-data-collector/types'
import { FuelLineDataLoss } from '../../api-models/performance/fuel-consumption'

export const getErrorPersistingTabs = (
  hasValidationError: THDCStepValidation,
  hdcFormType: HDCFormType,
) => {
  return Object.entries(hasValidationError[hdcFormType])
    .filter(([_, value]) => value)
    .map(([key, _]) => key)
}

export const generateErrorMessage = (errorPersistingTabs: Array<string>) => {
  let errMsg = 'There '
  if (errorPersistingTabs.length === 1) {
    errMsg += `is an error on: ${errorPersistingTabs[0]}`
  } else {
    errMsg += `are errors on: ${errorPersistingTabs.join(', ')}`
  }
  return errMsg + '. Kindly revalidate before submitting report.'
}

export const getMfeEtaWarnings = (
  rr: Array<GandalfApi.MfeReport>,
  imoNo: string,
): Array<IMcNotification> => {
  return rr.map((r) => ({
    heading: 'ETA affected by malfunctioning equipment',
    body: 'Please note that an equipment malfunction has been reported and ETA is affected.',
    appearance: 'warning',
    actions: [
      {
        url: `/MaerskStarConnect/vessel/${imoNo}/mfe/report/${r.reportId}`,
        label: `View report #${r.reportNo}`,
        target: '_blank',
        rel: 'noreferrer',
      },
    ],
  }))
}

export const getFuelDataLossAlert = (
  ff: Array<FuelLineDataLoss> | undefined,
  imoNo: string,
): Array<IMcNotification> => {
  if (!ff || ff.length === 0) {
    return []
  }
  return [getFuelDataLossAlertIMcNotification(imoNo)]
}

export const getFuelDataLossAlertIMcNotification = (
  imoNo: string,
): IMcNotification => {
  return {
    heading: 'Data loss detected',
    body: 'If you are about to create a report, please repair data loss on the Fuel consumption page.',
    appearance: 'error',
    actions: [
      {
        url: `/MaerskStarConnect/vessel/${imoNo}/fuel-consumption`,
        label: 'Go to Fuel consumption page',
      },
    ],
  }
}
