import { dataQualityChecks } from './Technical.consts'

/**
 * Yields data quality checks, excluding the leakage factor check if there is no manual overflow entry
 */
export const getDataQualityChecks = (
  hasManualOverflowEntry: boolean | undefined,
) =>
  dataQualityChecks.filter(
    (check) => check.id !== 'leakageFactorEntered' || hasManualOverflowEntry,
  )
