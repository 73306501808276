import { LUBE_OIL_CONTAINER_NAME } from './constants'
import { ELubeOilContainer } from './types'

export const getLubeOilContainerName = (
  container: ELubeOilContainer,
  hasTwoMainEngines: boolean = false,
) => {
  if (
    hasTwoMainEngines &&
    [ELubeOilContainer.DayTank, ELubeOilContainer.Working].includes(container)
  ) {
    return LUBE_OIL_CONTAINER_NAME[container] + ' Port'
  }
  return LUBE_OIL_CONTAINER_NAME[container]
}
