import React from 'react'
import { McIcon } from '@maersk-global/mds-react-wrapper'

import { Wrapper } from './EmptyState.styles'
import { NotFound } from '../../../commons'

type Props = {
  children: React.ReactNode
}

const EmptyState = ({ children }: Props) => {
  return (
    <Wrapper
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '32px',
        marginBottom: '72px',
      }}
    >
      <NotFound text='No engine test reports created yet' />
      <McIcon icon='engine' />
      {children}
    </Wrapper>
  )
}

export default EmptyState
