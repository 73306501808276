import { isShoreContext, DataFetchError, getAccessToken } from '.'

export const createQueryParams = (params: { [key: string]: any }) => {
  const entries = Object.entries(params)
  if (!entries.length) {
    return ''
  }
  return `?${entries.map(([key, value]) => `${key}=${value}`).join('&')}`
}

export const doDelete: (url: string, body?: any) => Promise<any> = (
  url,
  body?,
) => {
  const options = {
    method: 'DELETE',
  }
  if (body) {
    options['body'] = JSON.stringify(body)
  }
  return doRequest(url, options)
}

export const doGet: (url: string) => Promise<any> = (url) => {
  const options = {
    method: 'GET',
  }
  return doRequest(url, options)
}

export const doPost: (url: string, body: any) => Promise<any> = (url, body) => {
  const options = {
    method: 'POST',
    body: JSON.stringify(body),
  }
  return doRequest(url, options)
}

export const doPatch: (url: string, body: any) => Promise<any> = (
  url,
  body,
) => {
  const options = {
    method: 'PATCH',
    body: JSON.stringify(body),
  }
  return doRequest(url, options)
}

export const doPut: (url: string, body?: any) => Promise<any> = (url, body) => {
  const options = {
    method: 'PUT',
    body: body ? JSON.stringify(body) : null,
  }
  return doRequest(url, options)
}

const doRequest = async (url: string, options: any) => {
  const requestHeaders = options.headers || new Headers()
  if (!requestHeaders.has('Content-Type')) {
    requestHeaders.append('Content-Type', 'application/json')
    requestHeaders.append('cache', 'no-cache')
  }

  if (!isShoreContext() && process.env.NODE_ENV.match(/development|test/)) {
    requestHeaders.append('Authorization', process.env.REACT_APP_POMERIUM)
  }

  if (!isShoreContext()) {
    requestHeaders.append('X-Vessel-Application', 'scv-frontend')
  }

  if (isShoreContext()) {
    const token = await getAccessToken()
    requestHeaders.append('Authorization', `Bearer ${token}`)
  }

  const updatedOptions = { ...options, headers: requestHeaders }

  return new Promise((resolve, reject) => {
    fetch(url, updatedOptions)
      .then((response: Response) => {
        if (!response.ok) {
          response
            .json()
            .then((json) => {
              reject({
                statusText: `${response.status}: ${response.statusText}`,
                message: json.message,
                statusCode: response.status,
                body: json,
              })
            })
            .catch(() => {
              /* If JSON cannot be parsed, just convey the HTTP status code */
              reject(`Request failed with status code ${response.status}`)
            })
        } else if (
          response.headers.has('Content-Type') &&
          (response.headers.get('Content-Type') || '').includes(
            'application/json',
          )
        ) {
          resolve(response.json())
        } else if (
          response.headers.has('Content-Type') &&
          (response.headers.get('Content-Type') || '').includes('image/png')
        ) {
          resolve(response.blob())
        } else {
          resolve(response)
        }
      })
      .catch((e) => {
        reject(e)
      })
  })
}

export const doDownload = async (url: string, fileName?: string) => {
  const requestHeaders = new Headers()

  if (isShoreContext()) {
    const token = await getAccessToken()
    requestHeaders.append('Authorization', `Bearer ${token}`)
  }

  const updatedOptions = { headers: requestHeaders }

  return new Promise((resolve, reject) => {
    fetch(url, updatedOptions)
      .then((response: Response) => {
        if (!response.ok) {
          reject(new DataFetchError(response))
        } else {
          let a = document.createElement('a') as any
          document.body.appendChild(a)
          a.style = 'display: none'
          response.blob().then((blob) => {
            const url = window.URL.createObjectURL(blob)
            a.href = url
            a.download = fileName ? fileName : 'NAME_UNAVAILABLE'
            a.click()
            setTimeout(() => {
              // timeout needed for FF Portable version 1.0
              window.URL.revokeObjectURL(url)
            }, 5000)
          })
        }
        resolve(response)
      })
      .catch((e) => reject(e))
  })
}
