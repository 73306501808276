import React from 'react'
import { ApiReportType } from '../../../../api-models/hdc/report'
import imgHappyFlow from '../../../../assets/images/help-section/reporting-types-happy-flow.png'
import { capitalize } from '../../../../utils'

const REPORT_TYPES = [
  {
    name: ApiReportType.DEPARTURE,
    from: 'Last line',
    to: 'Pilot off',
    maxHours: 'None',
    sequenceFrom: [ApiReportType.ALONGSIDE],
    sequenceAfter: [],
    sequenceAfterNotAllowed: [
      ApiReportType.DEPARTURE,
      ApiReportType.SEA,
      ApiReportType.CANAL,
    ],
  },
  {
    name: ApiReportType.SEA,
    from: 'Close prevous report',
    to: 'Close of this report',
    maxHours: '36 hours',
    sequenceFrom: [],
    sequenceAfter: [
      ApiReportType.DEPARTURE,
      ApiReportType.SEA,
      ApiReportType.CANAL,
    ],
    sequenceAfterNotAllowed: [ApiReportType.ARRIVAL, ApiReportType.ALONGSIDE],
  },
  {
    name: ApiReportType.ARRIVAL,
    from: 'Arrival to pilot station',
    to: 'All fast',
    maxHours: 'None',
    sequenceFrom: [],
    sequenceAfter: [
      ApiReportType.DEPARTURE,
      ApiReportType.SEA,
      ApiReportType.CANAL,
    ],
    sequenceAfterNotAllowed: [ApiReportType.ARRIVAL, ApiReportType.ALONGSIDE],
  },
  {
    name: ApiReportType.ALONGSIDE,
    from: 'All fast',
    to: 'Last line',
    maxHours: 'None',
    sequenceFrom: [],
    sequenceAfter: [
      ApiReportType.ALONGSIDE,
      ApiReportType.SEA,
      ApiReportType.CANAL,
    ],
    sequenceAfterNotAllowed: [ApiReportType.SEA, ApiReportType.DEPARTURE],
  },
  {
    name: ApiReportType.ALONGSIDE,
    from: 'Arrival to pilot station',
    to: 'Drop of canal pilot',
    maxHours: 'None',
    sequenceFrom: [],
    sequenceAfter: [
      ApiReportType.DEPARTURE,
      ApiReportType.SEA,
      ApiReportType.ALONGSIDE,
      ApiReportType.CANAL,
    ],
    sequenceAfterNotAllowed: [ApiReportType.ARRIVAL],
  },
]

export const ReportTypes = React.forwardRef<HTMLDivElement>((props, ref) => (
  <div ref={ref} id='help-section-subtopic'>
    <h4>In this guide you will learn</h4>
    <ul>
      <li>When to use the different report types</li>
      <li>Which timestamps to capture for a report period</li>
      <li>Which dependencies there are in the reporting sequence</li>
    </ul>
    <h4>Purpose</h4>
    <p>
      This guide will give you an introduction to the different report types and
      how to align your StarConnect reporting timestamps with logbooks and other
      SC products.
    </p>
    <h4>Prerequisites</h4>
    <ul>
      <li>
        All data losses within the report period (if any) should be repaired on
        the Consumption page for the report period. You will see Alert in
        StarConnect if there is data loss.
      </li>
      <li>
        Your Stock module should be up to date - make sure all bunkerings are
        reported and soundings are up to date
      </li>
    </ul>
    <h4>Report types</h4>
    <p>
      The main difference between report types in MSPS and StarConnect (SC) is:
    </p>
    <ul>
      <li>
        The MSPS port report in 3 sections have been split into 3 reports
        (Arrival, Alongside and Departure) in StarConnect that can be submitted
        individually
      </li>
      <li>
        The split between sea reports and departure/arrival has been shifted
        from Start/End of Sea passage till Pilot station
      </li>
      <li>
        A new Canal report has been introduced in SC to replace the old MSPS
        process to report canal passages in a port report
      </li>
    </ul>
    <p>The 5 report types available in SC and which period they cover:</p>
    <table>
      <thead>
        <tr>
          <th>Report types</th>
          <th>Period from</th>
          <th>Period to</th>
          <th>Max hours</th>
        </tr>
      </thead>
      <tbody>
        {REPORT_TYPES.map((reportType, idx) => (
          <tr key={idx}>
            <td>{capitalize(reportType.name)}</td>
            <td>{reportType.from}</td>
            <td>{reportType.to}</td>
            <td>{reportType.maxHours}</td>
          </tr>
        ))}
      </tbody>
    </table>
    <h4>Report periods</h4>
    <p>
      The reporting period timestamp should be aligned with the logbook records
      / PitStop-Port module for following timestamps:
    </p>
    <ul>
      <li>Arrival Pilot station</li>
      <li>All fast</li>
      <li>Last line</li>
      <li>Pilot off</li>
    </ul>
    <div>(Port module = Pilot boarding place)</div>
    <p>The happy flow of reports for a new voyage will look like this:</p>
    <img src={imgHappyFlow} alt='Happy flow' />
    <p className='note'>
      Please note that last sea report before arrival should have closing time =
      Arrival Pilot boarding place in order to align reporting.
    </p>
    <h4>Report sequence</h4>
    <p>
      In order to capture the voyage data correctly there are some constraints
      to the sequence that the reports can appear in - in short:
    </p>
    <table>
      <thead>
        <tr>
          <th>Report types</th>
          <th>Period from</th>
          <th>Can be after report type</th>
          <th>Not allowed after report type</th>
        </tr>
      </thead>
      <tbody>
        {REPORT_TYPES.map((reportType, idx) => (
          <tr key={idx}>
            <td>{capitalize(reportType.name)}</td>
            <td>
              {reportType.sequenceFrom
                .map((type) => capitalize(type))
                .join(', ')}
            </td>
            <td>
              {reportType.sequenceAfter
                .map((type) => capitalize(type))
                .join(', ')}
            </td>
            <td>
              {reportType.sequenceAfterNotAllowed
                .map((type) => capitalize(type))
                .join(', ')}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
    <p>
      You will be notified and stopped if you try to create a report type that
      is not allowed for the current sequence.
    </p>
  </div>
))
