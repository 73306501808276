import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { McButton } from '@maersk-global/mds-react-wrapper'

import {
  DialContainer,
  FooterContainer,
  Wrapper,
} from './VesselOverviewPage.styles'
import { layoutSizes } from '../../theme'
import { ContentCard, Loading } from '../../commons'
import Notifications from '../../components/Notifications/Notifications'
import { VesselPageContext } from '../../contexts'
import { FuelConsumptionOverview } from '../../features/fuel-consumption-overview'
import { StockOnBoardLight } from '../../features/stock-management/components/stock-on-board/StockOnBoardLight'
import {
  type StockOnBoard as IStockOnBoard,
  StockState,
} from '../../features/stock-management/models'
import {
  getFilteredNotifications,
  mapStockOnBoard,
} from '../../features/stock-management/utils'
import { mapVesselStatusToTrimChartData } from '../TrimPage/TrimPage.utils'
import { TrimChart } from '../TrimPage'
import {
  MainEnginePowerDial,
  MainEngineRPMDial,
  SpeedOverGroundDial,
  SpeedThroughWaterDial,
} from '../../features/vessel-status'
import { ContentLayout } from '../../layout'
import * as PerformanceApi from '../../services/performance'
import { displayErrorModal, getTimestampFromVesselStatus } from '../../utils'
import { EStockNotificationType } from '../../api-models/performance/common'
import TemNotificationsSwitch from '../../components/TemNotificationsSwitch/TemNotificationsSwitch'
import PerformanceAlerts from '../../components/PerformanceAlerts/PerformanceAlerts'
import EnginePerformanceTestNotifications from '../../components/EnginePerformanceTestNotifications/EnginePerformanceTestNotifications'

const VesselOverviewPage = () => {
  const { vesselStatus, configuration, imoNo } = useContext(VesselPageContext)

  const [advices, setAdvices] = useState<string[]>([])
  const [alerts, setAlerts] = useState<(string | JSX.Element)[]>([])
  const [stockOnBoard, setStockOnBoard] = useState<IStockOnBoard | null>()
  const [stateOfStock, setStateOfStock] = useState<StockState | null>()
  const [warnings, setWarnings] = useState<string[]>([])

  const dataLossNotification: JSX.Element = useMemo(
    () => (
      <span>
        Data loss is detected. Please repair it on{' '}
        <Link to={`/MaerskStarConnect/vessel/${imoNo}/fuel-consumption`}>
          Fuel consumption
        </Link>{' '}
        page
      </span>
    ),
    [imoNo],
  )

  const loadData = useCallback(
    async (imoNo: string) => {
      try {
        const [stockStatus, lubeOilStockStatus, consumptionStatus] =
          await Promise.all([
            PerformanceApi.getStockStatus(imoNo, false),
            PerformanceApi.getLubeOilStatus(parseInt(imoNo, 10)),
            PerformanceApi.getFuelConsumptionStatus(imoNo),
          ])
        setStockOnBoard(
          stockStatus.rob.hasData
            ? {
                ...mapStockOnBoard(
                  stockStatus.rob.batchQuantities,
                  stockStatus.rob.fuelTypeQuantities,
                ),
                timestamp: moment.utc(stockStatus.rob.timestamp),
              }
            : null,
        )
        setAlerts([
          ...getFilteredNotifications(
            [
              ...stockStatus.notifications,
              ...stockStatus.rob.notifications,
              ...lubeOilStockStatus.notifications,
              ...consumptionStatus.notifications,
            ],
            EStockNotificationType.Error,
          ),
          ...(consumptionStatus.hasDataLoss ? [dataLossNotification] : []),
        ])
        setAdvices([
          ...getFilteredNotifications(
            [
              ...stockStatus.notifications,
              ...stockStatus.rob.notifications,
              ...lubeOilStockStatus.notifications,
              ...consumptionStatus.notifications,
            ],
            EStockNotificationType.Info,
          ),
        ])
        setWarnings(
          getFilteredNotifications(
            [
              ...stockStatus.notifications,
              ...stockStatus.rob.notifications,
              ...lubeOilStockStatus.notifications,
              ...consumptionStatus.notifications,
            ],
            EStockNotificationType.Warning,
          ),
        )
        setStateOfStock(stockStatus.state)
      } catch (err) {
        void displayErrorModal({
          statusText: 'Could not fetch stock and fuel status',
          message: err.message || 'Please try to refresh the page',
        })
        setStockOnBoard(null)
        setStateOfStock(null)
        setAlerts([])
        setAdvices([])
        setWarnings([])
      }
    },
    [dataLossNotification],
  )

  useEffect(() => {
    if (!imoNo) return
    void loadData(imoNo)
  }, [imoNo, loadData])

  if (!configuration || !imoNo) return <Loading />

  return (
    <Wrapper>
      <ContentLayout header='Overview'>
        <Notifications advices={advices} alerts={alerts} warnings={warnings} />
        <EnginePerformanceTestNotifications />
        <TemNotificationsSwitch
          imoNo={imoNo}
          timestamp={vesselStatus?.timestamp}
        />
        <PerformanceAlerts />
        <div className='cards'>
          <div className='row'>
            <div className='column left three-quarters'>
              <ContentCard
                id='fuelConsumption'
                helpTextKey='vessel/fuel-consumption'
                title='Total fuel consumption'
                className='fuel-consumption-chart'
                footerContent={
                  <FooterContainer>
                    <Link
                      to={`/MaerskStarConnect/vessel/${imoNo}/fuel-consumption`}
                      component={React.forwardRef(
                        ({ navigate, ...props }, ref) => (
                          <McButton
                            ref={ref}
                            label='Go to Consumption'
                            appearance='neutral'
                            trailingicon='square-arrow-up-right'
                            fit='small'
                            {...props}
                            data-e2e='Go to Consumption'
                          />
                        ),
                      )}
                    />
                  </FooterContainer>
                }
                contentPadding='8px'
              >
                <FuelConsumptionOverview />
              </ContentCard>
            </div>

            <div className='column right quarter'>
              <ContentCard
                id='stock-on-board-light'
                title='Stock on board'
                helpTextKey='stock/stock-on-board-light'
                height='100%'
                className='stock-on-board-light'
                contentHeight='100%'
                footerContent={
                  <FooterContainer>
                    <Link
                      to={`/MaerskStarConnect/vessel/${imoNo}/stock-management`}
                      component={React.forwardRef(
                        ({ navigate, ...props }, ref) => (
                          <McButton
                            ref={ref}
                            label='Go to Stock'
                            appearance='neutral'
                            trailingicon='square-arrow-up-right'
                            fit='small'
                            {...props}
                            data-e2e='Go to Stock'
                          />
                        ),
                      )}
                    />
                  </FooterContainer>
                }
              >
                <StockOnBoardLight
                  stockOnBoard={stockOnBoard}
                  stateOfStock={stateOfStock}
                />
              </ContentCard>
            </div>
          </div>
        </div>
        <div className='wrapped-components'>
          <ContentCard
            title='ME Power'
            helpTextKey='propulsion/maineng-power'
            id='mep'
            width={
              configuration.hasTwoMainEngines
                ? layoutSizes.half
                : layoutSizes.quarter
            }
            additionalInfo={
              <span>{getTimestampFromVesselStatus(vesselStatus)}</span>
            }
            className={
              configuration.hasTwoMainEngines
                ? 'me-power-dial top'
                : 'me-power-dial left'
            }
          >
            {!vesselStatus ? (
              <Loading />
            ) : (
              <MainEnginePowerDial
                vesselStatus={vesselStatus}
                vesselConfiguration={configuration}
              />
            )}
          </ContentCard>
          <ContentCard
            title='ME RPM'
            helpTextKey='propulsion/maineng-rpm'
            width={
              configuration.hasTwoMainEngines
                ? layoutSizes.half
                : layoutSizes.quarter
            }
            id='rpm'
            additionalInfo={
              <span>{getTimestampFromVesselStatus(vesselStatus)}</span>
            }
          >
            {!vesselStatus ? (
              <Loading />
            ) : (
              <MainEngineRPMDial
                vesselStatus={vesselStatus}
                vesselConfiguration={configuration}
              />
            )}
          </ContentCard>
          <ContentCard
            helpTextKey='vessel/sog'
            title='SOG'
            id='sog'
            width={`${layoutSizes.quarter}`}
            additionalInfo={
              <span>{getTimestampFromVesselStatus(vesselStatus)}</span>
            }
            className='sog left'
          >
            {!vesselStatus ? (
              <Loading />
            ) : (
              <DialContainer
                hasTwoMainEngines={configuration.hasTwoMainEngines}
              >
                <SpeedOverGroundDial
                  vesselStatus={vesselStatus}
                  vesselConfiguration={configuration}
                />
              </DialContainer>
            )}
          </ContentCard>
          <ContentCard
            title='STW'
            id='stw'
            helpTextKey='vessel/stw'
            width={`${layoutSizes.quarter}`}
            additionalInfo={
              <span>{getTimestampFromVesselStatus(vesselStatus)}</span>
            }
          >
            {!vesselStatus ? (
              <Loading />
            ) : (
              <DialContainer
                hasTwoMainEngines={configuration.hasTwoMainEngines}
              >
                <SpeedThroughWaterDial
                  vesselStatus={vesselStatus}
                  vesselConfiguration={configuration}
                />
              </DialContainer>
            )}
          </ContentCard>
          <ContentCard
            id='trim'
            title='Trim'
            additionalInfo={
              <span>{getTimestampFromVesselStatus(vesselStatus)}</span>
            }
            helpTextKey='live-trim'
            width={`${layoutSizes.half}`}
            footerContent={
              <FooterContainer>
                <Link
                  to={`/MaerskStarConnect/vessel/${imoNo}/trim`}
                  component={React.forwardRef(({ navigate, ...props }, ref) => (
                    <McButton
                      ref={ref}
                      label='Go to Trim'
                      appearance='neutral'
                      trailingicon='square-arrow-up-right'
                      fit='small'
                      {...props}
                      data-e2e='Go to Trim'
                    />
                  ))}
                />
              </FooterContainer>
            }
          >
            {vesselStatus ? (
              <TrimChart data={mapVesselStatusToTrimChartData(vesselStatus)} />
            ) : (
              <Loading />
            )}
          </ContentCard>
        </div>
      </ContentLayout>
    </Wrapper>
  )
}

export default VesselOverviewPage
