import { useState } from 'react'
import {
  getCoreRowModel,
  getSortedRowModel,
  type SortingState,
  useReactTable,
} from '@tanstack/react-table'

import { type Performance } from '../../../../api-models'
import { columns } from './helpers'
import Table from '../../../../components/Table/Table'

type Props = {
  logs: Array<Performance.VesselSettings.SettingsLog>
}

const VersionHistoryTable = ({ logs }: Props) => {
  const [sorting, setSorting] = useState<SortingState>([
    { desc: true, id: 'timestamp' },
  ])

  const table = useReactTable({
    data: logs,
    columns: columns,
    state: { sorting },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  })

  return (
    <div style={{ maxHeight: '50vh', overflow: 'scroll' }}>
      <Table table={table} maxFit='small' hidePagination />
    </div>
  )
}

export default VersionHistoryTable
