import { McButton, McModal } from '@maersk-global/mds-react-wrapper'

type Props = {
  open: boolean
  onClose: () => void
}

const InfoModal = ({ open, onClose }: Props) => {
  return (
    <McModal
      heading='Main engine Run hours are not matching with the Report period.'
      dimension='small'
      width='620px'
      height='270px'
      hiddenclose
      open={open}
      closed={onClose}
    >
      <p>
        If there has been a stoppage at sea due to malfunction, please create a
        Malfunctioning equipment report and the matching technical off service
        in this report.
        <br /> If the stoppage is due to an operational off-service, then please
        register the event in this report.
      </p>
      <>
        <McButton slot='primaryAction' label='Ok' dialogaction='ok' />
      </>
    </McModal>
  )
}

export default InfoModal
