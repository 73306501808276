import styled, { grey } from '../theme'

export const SimpleTable = styled.table`
  border-collapse: collapse;
  thead {
    background-color: ${grey[50]};
    box-shadow: none !important;
    border-top: 1px solid ${grey[200]};
    border-bottom: 1px solid ${grey[200]};
    font-weight: 500;
    font-size: 12px;
    color: #474e56;

    th {
      min-height: 36px;
      padding: 8px;
      border-color: ${grey[200]};
    }
  }
  th,
  td {
    border: 1px solid ${grey[200]};
    padding: 8px;
    &:first-of-type {
      border-left: none;
    }
    &:last-of-type {
      border-right: none;
    }
  }
  tfoot {
  }
`
