import React, { useState, FC } from 'react'
import {
  useFormikContext,
  getIn,
  FieldArray,
  FieldArrayRenderProps,
} from 'formik'
import { RecoverySoundingTableSection } from './RecoverySoundingTableSection'
import { VolumeToMassCalculator } from '../../../volume-to-mass-calculator'
import { VolumeToMassCalculatorState } from '../../../volume-to-mass-calculator/components/VolumeToMassCalculator'
import { SimpleTable } from '../../../../commons'

import styled, {
  lightBlueColorScheme,
  mariTimeColorScheme,
  purpleColorScheme,
  lightOrangeColorScheme,
  maritimeBlue,
} from '../../../../theme'

const Spacer = styled.tr`
  height: 6px;
`

const Wrapper = styled.div`
  max-width: 600px;
  padding: 16px;
  table {
    min-width: 500px;
    overflow-x: auto;
    table-layout: fixed;
    white-space: nowrap;

    width: 100%;
    font-size: 14px;
    border-collapse: separate;
    border-spacing: 0px;
    tbody tr:hover {
      background-color: rgba(100, 178, 212, 0.1);
    }
    th {
      text-align: left;

      div {
        &:nth-child(2) {
          text-align: left;
          font-style: italic;
          font-weight: 500;
          width: 100%;
        }
      }

      &.fuel-type {
        width: 40%;
      }
      &.sounding {
        width: 30%;
      }
      &.adjustment {
        width: 15%;
      }
    }
    td {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      position: relative;
      padding: 8px;
      border-top: 0;
      border-left: none;
      &:last-child {
        border-right: none;
      }

      &:first-child {
        text-align: left;
      }
      span.calculator {
        padding-left: 16px;
        display: flex;
        align-items: center;
        cursor: pointer;
        > i {
          font-size: 16px;
          line-height: 16px;
          > svg {
            color: ${maritimeBlue[500]};
            height: 24px;
            width: 24px;
          }
        }
      }
      text-align: right;
    }
  }
`

interface RecoverySoundingTableProps {
  isShoreMode: boolean
}

const BATCH_DATA_BY_FUEL_TYPE = [
  { fuelTypeArrayName: 'hsValues', colorScheme: mariTimeColorScheme },
  { fuelTypeArrayName: 'vlsValues', colorScheme: purpleColorScheme },
  { fuelTypeArrayName: 'ulsValues', colorScheme: lightBlueColorScheme },
  { fuelTypeArrayName: 'mdoValues', colorScheme: lightOrangeColorScheme },
]

export const RecoverySoundingTable: FC<RecoverySoundingTableProps> = ({
  isShoreMode,
}) => {
  const [calculatorStore, setCalculatorStore] = useState({})
  const [batchAccessor, setBatchAccessor] = useState<string>()
  const { values, setFieldValue, setFieldTouched } = useFormikContext<any>()
  return (
    <Wrapper>
      <SimpleTable>
        <thead>
          <tr>
            <th className='fuel-type'>
              <div>Fuel</div>
              <div>&nbsp;</div>
            </th>
            <th className='sounding'>
              <div>Remaining on board</div>
              <div>MT</div>
            </th>
          </tr>
        </thead>
        <tbody>
          {BATCH_DATA_BY_FUEL_TYPE.map(({ fuelTypeArrayName, colorScheme }) => (
            <React.Fragment key={fuelTypeArrayName}>
              <Spacer />
              <FieldArray name={fuelTypeArrayName}>
                {(fieldArray: FieldArrayRenderProps) => (
                  <RecoverySoundingTableSection
                    {...fieldArray}
                    disabled={isShoreMode}
                    onCalcClick={setBatchAccessor}
                    scheme={colorScheme}
                  />
                )}
              </FieldArray>
            </React.Fragment>
          ))}
        </tbody>
      </SimpleTable>
      {!!batchAccessor && (
        <VolumeToMassCalculator
          labelText={getIn(values, `${batchAccessor}.displayName`)}
          onCancel={() => setBatchAccessor(undefined)}
          onClose={() => setBatchAccessor(undefined)}
          storedState={
            !!batchAccessor ? calculatorStore[batchAccessor] : undefined
          }
          onSave={(totalSum: string, state: VolumeToMassCalculatorState) => {
            if (totalSum === '-' || isNaN(+totalSum) || isShoreMode) {
              setBatchAccessor(undefined)
              return
            }
            const sum = parseFloat(totalSum)
            const fieldName = `manualrob.rob.${getIn(
              values,
              `${batchAccessor}.id`,
            )}`

            setFieldValue(fieldName, sum)
            setFieldTouched(fieldName, true, false)
            setBatchAccessor(undefined)
            setCalculatorStore({ ...calculatorStore, [batchAccessor]: state })
          }}
        />
      )}
    </Wrapper>
  )
}
