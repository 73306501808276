import { useContext, useMemo } from 'react'
import { useFormikContext } from 'formik'
import { McTooltip } from '@maersk-global/mds-react-wrapper'

import { CutOutTcLabel, InputCol, ValueCol } from './TurbochargerTab.styles'

import { AverageCol } from '../EnginePerformanceTestReportPage.styles'
import { recalculateResults } from '../EnginePerformanceTestReportPage.utils'
import MetcInputField from '../Common/MetcInputField/MetcInputField'
import MetcTextareaField from '../Common/MetcTextArea'
import { TableContainer } from '../GeneralTab/GenaralTab.styles'
import Legend from '../Common/Legend/Legend'
import { formatValue, isShoreContext } from '../../../../utils'
import { WindowContext } from '../../../../contexts'

const TCCO_TOOLTIP =
  'Turbocharger speed is below 200 RPM and is considered cut out. Values are ignored.'

const TurbochargerTab = () => {
  const { windowSize } = useContext(WindowContext)
  const ctx = useFormikContext<GandalfApi.MetcReport>()
  const { data } = ctx.values

  const tcRange = useMemo(
    () => Array.from(Array(data.equipment.noOfTurbochargers).keys()),
    [data.equipment.noOfTurbochargers],
  )

  const _recalculateResults = () => {
    if (isShoreContext()) return
    return void recalculateResults(ctx)
  }

  return (
    <div slot='panel'>
      <Legend />
      <TableContainer className={`mds-table mds-table--${windowSize}`}>
        <table style={{ display: 'block', overflowX: 'auto' }}>
          <colgroup>
            <ValueCol />
            <AverageCol />
            {tcRange.map((i) => (
              <InputCol key={i} n={data.equipment.noOfTurbochargers} />
            ))}
          </colgroup>
          <thead>
            <tr>
              <th>Value</th>
              <th>Average</th>
              {tcRange.map((i) => {
                const label = `Turbocharger ${i + 1}`
                if (data.turbochargers[i].isCutOut) {
                  return (
                    <th key={i}>
                      <McTooltip appearance='inverse' position='top-center'>
                        <CutOutTcLabel slot='trigger'>{label}</CutOutTcLabel>
                        <span>{TCCO_TOOLTIP}</span>
                      </McTooltip>
                    </th>
                  )
                } else {
                  return <th key={i}>{label}</th>
                }
              })}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Speed [RPM]</td>
              <td>{formatValue(data.result.turbochargers.speedAvg, 2)}</td>
              {tcRange.map((i) => (
                <td key={i}>
                  <MetcInputField
                    name={`data.turbochargers.${i}.speed`}
                    isIgnored={data.turbochargers[i].isCutOut}
                    onBlur={_recalculateResults}
                  />
                </td>
              ))}
            </tr>
            <tr>
              <td>Press Drop Air Filter [mmH20]</td>
              <td>
                {formatValue(data.result.turbochargers.presDropAirFilterAvg, 2)}
              </td>
              {tcRange.map((i) => (
                <td key={i}>
                  <MetcInputField
                    name={`data.turbochargers.${i}.presDropAirFilter`}
                    isIgnored={data.turbochargers[i].isCutOut}
                    onBlur={_recalculateResults}
                  />
                </td>
              ))}
            </tr>
            <tr>
              <td>Press Drop Air Cooler [mmH20]</td>
              <td>
                {formatValue(data.result.turbochargers.presDropAirCoolerAvg, 2)}
              </td>
              {tcRange.map((i) => (
                <td key={i}>
                  <MetcInputField
                    name={`data.turbochargers.${i}.presDropAirCooler`}
                    isIgnored={data.turbochargers[i].isCutOut}
                    onBlur={_recalculateResults}
                  />
                </td>
              ))}
            </tr>
            <tr>
              <td>Exh Temp before TC [°C]</td>
              <td>
                {formatValue(
                  data.result.turbochargers.exhaustGasTempBeforeTcAvg,
                  2,
                )}
              </td>
              {tcRange.map((i) => (
                <td key={i}>
                  <MetcInputField
                    name={`data.turbochargers.${i}.exhaustGasTempBeforeTc`}
                    isIgnored={data.turbochargers[i].isCutOut}
                    onBlur={_recalculateResults}
                  />
                </td>
              ))}
            </tr>
            <tr>
              <td>Exh Temp after TC [°C]</td>
              <td>
                {formatValue(
                  data.result.turbochargers.exhaustGasTempAfterTcAvg,
                  2,
                )}
              </td>
              {tcRange.map((i) => (
                <td key={i}>
                  <MetcInputField
                    name={`data.turbochargers.${i}.exhaustGasTempAfterTc`}
                    isIgnored={data.turbochargers[i].isCutOut}
                    onBlur={_recalculateResults}
                  />
                </td>
              ))}
            </tr>
            <tr>
              <td>Air temp. before cooler [°C]</td>
              <td>
                {formatValue(
                  data.result.turbochargers.airTempBeforeCoolerAvg,
                  2,
                )}
              </td>
              {tcRange.map((i) => (
                <td key={i}>
                  <MetcInputField
                    name={`data.turbochargers.${i}.airTempBeforeCooler`}
                    isIgnored={data.turbochargers[i].isCutOut}
                    onBlur={_recalculateResults}
                  />
                </td>
              ))}
            </tr>
            <tr>
              <td>Air temp. after cooler [°C]</td>
              <td>
                {formatValue(
                  data.result.turbochargers.airTempAfterCoolerAvg,
                  2,
                )}
              </td>
              {tcRange.map((i) => (
                <td key={i}>
                  <MetcInputField
                    name={`data.turbochargers.${i}.airTempAfterCooler`}
                    isIgnored={data.turbochargers[i].isCutOut}
                    onBlur={_recalculateResults}
                  />
                </td>
              ))}
            </tr>
            <tr>
              <td>CW temp. before cooler [°C]</td>
              <td>
                {formatValue(
                  data.result.turbochargers.cwTempBeforeCoolerAvg,
                  2,
                )}
              </td>
              {tcRange.map((i) => (
                <td key={i}>
                  <MetcInputField
                    name={`data.turbochargers.${i}.cwTempBeforeCooler`}
                    isIgnored={data.turbochargers[i].isCutOut}
                    onBlur={_recalculateResults}
                  />
                </td>
              ))}
            </tr>
            <tr>
              <td>CW temp. after cooler [°C]</td>
              <td>
                {formatValue(data.result.turbochargers.cwTempAfterCoolerAvg, 2)}
              </td>
              {tcRange.map((i) => (
                <td key={i}>
                  <MetcInputField
                    name={`data.turbochargers.${i}.cwTempAfterCooler`}
                    isIgnored={data.turbochargers[i].isCutOut}
                    onBlur={_recalculateResults}
                  />
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </TableContainer>
      <MetcTextareaField label='Comment' name='data.comment' />
    </div>
  )
}

export default TurbochargerTab
