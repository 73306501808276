import * as yup from 'yup'
import {
  getQuantityValidationSchema,
  REQUIRED_MESSAGE,
  timestampValidationSchema,
} from './utils'

const MAX_QUANTITY = 150000
const MIN_QUANTITY = 1

const lossValidationSchema = yup.object().shape({
  oilType: yup.number().nullable().required(REQUIRED_MESSAGE),
  quantity: getQuantityValidationSchema(MIN_QUANTITY, MAX_QUANTITY).required(
    REQUIRED_MESSAGE,
  ),
  reasonCode: yup.string().nullable().required(REQUIRED_MESSAGE),
  timestamp: timestampValidationSchema,
})

export default lossValidationSchema
