import * as React from 'react'
import * as ECharts from 'echarts'
import { IVesselPageContext } from '../../../contexts'
import { Performance } from '../../../api-models'
import { Filters, filtersChanged, getDateRange } from '../../filter'
import {
  Chart,
  connectChartsX,
  displayErrorModal,
  formatValue,
  getDataIndex,
  getDefaultFilters,
  tooltip,
} from '../../../utils'
import { TrimConsumptionChart } from './TrimConsumptionChart'
import { Mode, SpeedThroughWaterChart } from './SpeedThroughWaterChart'
import { withVesselPageContext } from '../../../higher-order-components'
import * as colorSchemes from '../themes'
import {
  Block,
  ChartContainer,
  ChartStencil,
  ContentCard,
  FancyLabel,
  ValueLabel,
  VesselStatusBlock,
} from '../../../commons'
import { ChartContainerProps } from '../../../commons/ChartContainer'
import { Toggle } from '../components/Toggle'
import { UNITS } from '../../../utils/constants'
import { getTrimMonitor } from '../../../services/performance'

interface TrimChartsContainerProps extends IVesselPageContext {
  filters?: Filters
}

interface TrimChartsContainerState {
  loading?: boolean
  data?: Performance.Trim.Monitor
  chartsMounted?: boolean
  chartMode: string
  showConsumption: boolean
  showTrim: boolean
  showStw: boolean
  showShaftRpm: boolean
  showPower: boolean
}

const states = {
  loading: {
    loading: true,
    data: undefined,
    chartsMounted: false,
  },
  loaded: {
    loading: false,
  },
  chartsMounted: {
    chartsMounted: true,
  },
}

const chartModeStates = {
  shaftRpm: {
    chartMode: 'constantRpm',
  },
  powerChart: {
    chartMode: 'constantPower',
  },
}

const chartModes: {
  [key: string]: { title: string; labels: ChartContainerProps }
} = {
  constantRpm: {
    title: 'STW vs Shaft power',
    labels: {
      y1Label: { name: UNITS.KNOTS, colorScheme: colorSchemes.stw },
      y2Label: {
        name: UNITS.MEGA_WATT,
        colorScheme: colorSchemes.power,
      },
    },
  },
  constantPower: {
    title: 'STW vs Shaft RPM',
    labels: {
      y1Label: { name: UNITS.KNOTS, colorScheme: colorSchemes.stw },
      y2Label: {
        name: UNITS.RPM,
        colorScheme: colorSchemes.shaftRpm,
      },
    },
  },
}

class TrimChartsContainer extends React.Component<
  TrimChartsContainerProps,
  TrimChartsContainerState
> {
  private trimConsumptionChart: React.RefObject<TrimConsumptionChart>
  private voyageChart: React.RefObject<SpeedThroughWaterChart>

  constructor(props: TrimChartsContainerProps) {
    super(props)

    this.state = {
      chartMode: 'constantPower',
      showConsumption: true,
      showTrim: true,
      showStw: true,
      showShaftRpm: true,
      showPower: true,
    }
    this.trimConsumptionChart = React.createRef()
    this.voyageChart = React.createRef()
  }

  loadData() {
    const { imoNo, filters } = this.props
    const period = getDateRange(getDefaultFilters(7, 'd').dateRange, filters)
    if (imoNo && !this.state.loading) {
      this.setState(states.loading)
      getTrimMonitor(imoNo, period)
        .then((json: Performance.Trim.Monitor) => {
          this.setState({
            data: json,
            ...states.loaded,
          })
        })
        .catch((e) => {
          this.setState({
            ...states.loaded,
          })
          displayErrorModal(e)
        })
    }
  }

  zoomSync = (params: any) => {
    if (params.batch) {
      const [startValue, endValue] = [
        Math.floor(params.batch[0].startValue),
        Math.ceil(params.batch[0].endValue),
      ]
      if (
        this.trimConsumptionChart &&
        this.trimConsumptionChart.current &&
        this.voyageChart &&
        this.voyageChart.current
      ) {
        this.trimConsumptionChart.current.zoomIn(startValue, endValue)
        this.voyageChart.current.zoomIn(startValue, endValue)
      }
    } else {
      if (
        this.trimConsumptionChart &&
        this.trimConsumptionChart.current &&
        this.voyageChart &&
        this.voyageChart.current
      ) {
        this.trimConsumptionChart.current.zoomOut(true)
        this.voyageChart.current.zoomOut(true)
      }
    }
  }

  tooltipFormatter = (
    params:
      | ECharts.EChartOption.Tooltip.Format
      | ECharts.EChartOption.Tooltip.Format[],
  ) => {
    const data = this.state.data
    const hasTwoMainEngines = this.props.configuration
      ? this.props.configuration.hasTwoMainEngines
      : false
    if (!data) return ''
    const dataIndex = getDataIndex(params)
    if (data.hasData && typeof dataIndex === 'number') {
      const commonData = [
        {
          label: 'ME Cons.',
          value: formatValue(data.consumption[dataIndex], 1),
          unit: UNITS.MT_PER_HOUR,
        },
        {
          label: 'Trim',
          value: formatValue(data.trim[dataIndex], 1),
          unit: UNITS.METER,
        },
        {
          label: 'STW',
          value: formatValue(data.stw[dataIndex], 1),
          unit: UNITS.KNOTS,
        },
      ]

      return tooltip(data.timestamps[dataIndex], [
        ...commonData,
        {
          label: hasTwoMainEngines ? 'Avg. RPM' : 'RPM',
          value: formatValue(data.shaftRpm[dataIndex], 1),
          unit: UNITS.RPM,
        },
        {
          label: hasTwoMainEngines ? 'Total shaft power' : 'Shaft power',
          value: formatValue(data.shaftPower[dataIndex], 1),
          unit: UNITS.MEGA_WATT,
        },
      ])
    }
    return ''
  }

  componentDidMount() {
    this.loadData()
  }

  componentDidUpdate(
    prevProps: TrimChartsContainerProps,
    prevState: TrimChartsContainerState,
  ) {
    const { data, chartsMounted } = this.state
    if (
      this.props.imoNo !== prevProps.imoNo ||
      filtersChanged(prevProps.filters, this.props.filters) ||
      this.props.vesselStatus?.timestamp !== prevProps.vesselStatus?.timestamp
    ) {
      this.loadData()
    }
    if (!chartsMounted && document.getElementById('trim-consumption')) {
      this.setState(states.chartsMounted)
    }
    if (!!data && !!chartsMounted && !prevState.chartsMounted) {
      const [trim, voyage] = [
        document.getElementById('trim-consumption'),
        document.getElementById('stw-voyage'),
      ] as Array<HTMLDivElement | null>
      if (trim && voyage) {
        const trimChart = ECharts.getInstanceByDom(trim) as Chart
        const voyageChart = ECharts.getInstanceByDom(voyage) as Chart
        trimChart.on('dataZoom', this.zoomSync)
        voyageChart.on('dataZoom', this.zoomSync)
        connectChartsX([trimChart, voyageChart])
      }
    }
  }

  findInstantValues = (
    vesselStatus: Performance.Status.Status | null,
  ): {
    timestamp: string | null
    shaftRpm: number | null
    stw: number | null
    shaftPower: number | null
    trim: number | null
    consumption: number | null
  } => {
    const output = {
      timestamp: null,
      shaftRpm: null,
      shaftPower: null,
      stw: null,
    } as {
      timestamp: string | null
      shaftRpm: number | null
      stw: number | null
      shaftPower: number | null
      trim: number | null
      consumption: number | null
    }
    if (!vesselStatus) return output

    output.shaftPower = vesselStatus.propulsion
      ? vesselStatus.propulsion.shaftPower
      : null
    output.shaftRpm = vesselStatus.mainEng
      ? vesselStatus.mainEng.shaftRpm
      : null
    output.consumption = vesselStatus.fuelConsumption
      ? vesselStatus.fuelConsumption.consolidated.mainEng
      : null
    output.stw = vesselStatus.voyage ? vesselStatus.voyage.stw : null
    output.trim = vesselStatus.draught ? vesselStatus.draught.trim : null
    output.timestamp = vesselStatus.timestamp
    return output
  }

  public render() {
    const { configuration, vesselStatus } = this.props as any
    const {
      data,
      chartMode,
      showConsumption,
      showPower,
      showShaftRpm,
      showStw,
      showTrim,
    } = this.state
    const { timestamp, shaftRpm, shaftPower, stw, trim, consumption } =
      this.findInstantValues(vesselStatus)
    return (
      <div className='full-components'>
        <ContentCard
          id='trim-main-eng-consumption'
          helpTextKey='trim/trim-vs-maineng-consumption'
          title='Trim vs ME Consumption'
        >
          <ChartContainer
            y1Label={{
              name: UNITS.MT_PER_HOUR,
              colorScheme: colorSchemes.consumption,
            }}
            y2Label={{ name: UNITS.METER, colorScheme: colorSchemes.trim }}
          >
            {!data && <ChartStencil chartType='area' />}
            {!!data && (
              <TrimConsumptionChart
                showConsumption={showConsumption}
                showTrim={showTrim}
                ref={this.trimConsumptionChart}
                tooltipFormatter={this.tooltipFormatter}
                data={data}
              />
            )}
            <VesselStatusBlock timestamp={timestamp}>
              <Block>
                <FancyLabel
                  colorScheme={colorSchemes.consumption}
                  value={showConsumption}
                  onChange={() => {
                    this.setState({ showConsumption: !showConsumption })
                  }}
                >
                  ME Cons
                </FancyLabel>
                <ValueLabel unit={UNITS.MT_PER_HOUR}>
                  {formatValue(consumption, 1)}
                </ValueLabel>
              </Block>
              <Block>
                <FancyLabel
                  colorScheme={colorSchemes.trim}
                  value={showTrim}
                  onChange={() => {
                    this.setState({ showTrim: !showTrim })
                  }}
                >
                  Trim
                </FancyLabel>
                <ValueLabel unit={UNITS.METER}>
                  {formatValue(trim, 1)}
                </ValueLabel>
              </Block>
            </VesselStatusBlock>
          </ChartContainer>
        </ContentCard>
        <ContentCard
          id='trim-voyage'
          title={chartModes[chartMode].title}
          helpTextKey='trim/trim-vs-speed'
          additionalInfo={
            <Toggle
              id='voyage-mode'
              value={this.state.chartMode}
              toggleLabel={'Governor on:'}
              onChange={(option: any) => {
                this.setState({ chartMode: `${option}` })
              }}
              options={[
                {
                  value: chartModeStates.shaftRpm.chartMode,
                  label: 'RPM mode',
                },
                {
                  value: chartModeStates.powerChart.chartMode,
                  label: 'Power mode',
                },
              ]}
            />
          }
        >
          <ChartContainer {...chartModes[chartMode].labels}>
            {!data && <ChartStencil chartType='area' />}
            {!!data && (
              <SpeedThroughWaterChart
                showPower={showPower}
                showShaftRpm={showShaftRpm}
                showStw={showStw}
                ref={this.voyageChart}
                currentMode={chartMode as Mode}
                tooltipFormatter={this.tooltipFormatter}
                data={data}
                hasTwoMainEngines={configuration.hasTwoMainEngines}
              />
            )}
            <VesselStatusBlock timestamp={timestamp}>
              <Block>
                <FancyLabel
                  colorScheme={colorSchemes.stw}
                  value={showStw}
                  onChange={() => {
                    this.setState({ showStw: !showStw })
                  }}
                >
                  STW
                </FancyLabel>
                <ValueLabel unit={UNITS.KNOTS}>
                  {formatValue(stw, 1)}
                </ValueLabel>
              </Block>
              <Block>
                <FancyLabel
                  colorScheme={colorSchemes.shaftRpm}
                  value={showShaftRpm}
                  onChange={() => {
                    this.setState({ showShaftRpm: !showShaftRpm })
                  }}
                >
                  {configuration.hasTwoMainEngines ? 'Avg. RPM' : 'RPM'}
                </FancyLabel>
                <ValueLabel unit={UNITS.RPM}>
                  {formatValue(shaftRpm, 1)}
                </ValueLabel>
              </Block>
              <Block>
                <FancyLabel
                  colorScheme={colorSchemes.power}
                  value={showPower}
                  onChange={() => {
                    this.setState({ showPower: !showPower })
                  }}
                >
                  {configuration.hasTwoMainEngines
                    ? 'Total shaft power'
                    : 'Shaft power'}
                </FancyLabel>
                <ValueLabel unit={UNITS.MEGA_WATT}>
                  {formatValue(shaftPower, 1)}
                </ValueLabel>
              </Block>
            </VesselStatusBlock>
          </ChartContainer>
        </ContentCard>
      </div>
    )
  }
}

export default withVesselPageContext(TrimChartsContainer)
