import React, { FunctionComponent } from 'react'
import { warningTheme } from '../../../../theme'
import { useFormikContext, getIn } from 'formik'
import { InfoBox } from '../../../../commons'

interface SoundingWarningProps {
  name: string
}

const soundingValueOutsideLimits = (
  calculatedValue: number | undefined,
  soundingString: string,
) => {
  if (!calculatedValue || soundingString === '')
    // Error and no warning when empty sounding value
    return false
  const soundingValue = parseFloat(soundingString)
  if (calculatedValue > 100)
    return (
      calculatedValue * 0.8 > soundingValue ||
      calculatedValue * 1.2 < soundingValue
    )
  return Math.abs(calculatedValue - soundingValue) > 20
}

export const SoundingWarning: FunctionComponent<SoundingWarningProps> = ({
  name,
}) => {
  const { values, touched } = useFormikContext<any>()
  const batch = getIn(values, name)
  const isTouched = getIn(touched, name)
  const { quantity, rob } = batch
  const showWarning = soundingValueOutsideLimits(quantity, rob)

  return isTouched && showWarning ? (
    <tr>
      <td colSpan={4} style={{ whiteSpace: 'normal' }}>
        <div className='warning-adjusted'>
          <InfoBox theme={warningTheme}>
            That sounds like a big difference. Please double check. If it is
            correct, no problem, you can still add it.
          </InfoBox>
        </div>
      </td>
    </tr>
  ) : null
}
