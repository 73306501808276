import styled from 'styled-components'
import { ContentCard as CC } from '../../../commons'
import { Th } from '../../../commons/tables/styles'

export const ContentCard = styled(CC)`
  margin-bottom: 16px;
`

type ContentCardBodyProps = {
  padded?: boolean
  flexDirection?: 'row' | 'column'
}
export const ContentCardBody = styled.div<ContentCardBodyProps>`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
  padding: ${({ padded }) => (padded ? '18px 20px' : '0px')};
`

export const ContentCardTitleChild = styled.div<{ bold?: boolean }>`
  font-size: 14px;
  font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
  margin: 0 12px;
  position: relative;

  &:after {
    position: absolute;
    content: '•';
    font-size: 17px;
    font-weight: bold;
    top: -2px;
    right: -16px;
  }

  &:last-child {
    margin-right: 0;

    &:after {
      display: none;
    }
  }
`

export const ContentCardFooter = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
`

export const EquipmentTh = styled(Th)`
  width: 50%;
`

export const ReadonlyRuntimeTh = styled(Th)`
  width: 25%;
`

export const ReportWrapper = styled.div`
  padding: 16px;
  width: 100%;
  box-sizing: border-box;
`

export const RuntimeTh = styled(Th)`
  width: 30%;
`

export const RuntimeTd = styled.td`
  display: flex;
  gap: 8px;
`
export const PeriodTh = styled(Th)`
  position: relative;
  width: 25%;

  mc-button {
    position: absolute;
    bottom: 4px;
    margin-left: 4px;
  }
`
export const EnergyTh = styled(Th)`
  width: 25%;
`
export const DateTh = styled(Th)`
  width: 25%;
`
export const TimeTh = styled(Th)`
  width: 25%;
`

export const TotalTd = styled.td`
  gap: 8px;
  width: 25%;
`
