import { useContext, useMemo } from 'react'
import { type Table } from '@tanstack/react-table'
import { McOption, McSelect } from '@maersk-global/mds-react-wrapper'

import { FilterWrapper } from '../../../components/Table/Table.styles'
import DateFilter from '../../ActivityLogPage/ActivityLogFilters/DateFilter/DateFilter'
import { WindowContext } from '../../../contexts'
import {
  getEquipmentOptions,
  getMeStatusOptions,
} from './MalfunctioningEquipmentFilters.utils'

type Props = {
  table: Table<GandalfApi.MfeReport>
}

const MalfunctioningEquipmentFilters = ({ table }: Props) => {
  const { windowSize } = useContext(WindowContext)

  const equipmentOptions = useMemo(() => getEquipmentOptions(table), [table])
  const meStatusOptions = useMemo(() => getMeStatusOptions(table), [table])

  const handleEquipmentFilterChange = ({ detail }: CustomEvent) => {
    table.getColumn('data_equipment')?.setFilterValue(detail.value)
  }

  const handleMeStatusFilterChange = ({ detail }: CustomEvent) => {
    table.getColumn('data_meStatus')?.setFilterValue(detail.value)
  }

  return (
    <FilterWrapper>
      <DateFilter table={table} columnId='startTimestamp' />
      <McSelect
        fit={windowSize}
        label='Equipment'
        placeholder='All'
        optionselected={handleEquipmentFilterChange}
      >
        {equipmentOptions.map((option) => (
          <McOption
            key={option.value}
            value={option.value}
            label={option.label}
          />
        ))}
      </McSelect>
      <McSelect
        fit={windowSize}
        label='ME status'
        placeholder='All'
        optionselected={handleMeStatusFilterChange}
      >
        {meStatusOptions.map((option) => (
          <McOption
            key={option.value}
            value={option.value}
            label={option.label}
          />
        ))}
      </McSelect>
    </FilterWrapper>
  )
}

export default MalfunctioningEquipmentFilters
