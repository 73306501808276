import { useCallback, useState } from 'react'
import { type EChartOption, getInstanceByDom, graphic, init } from 'echarts'
import moment from 'moment'

import { tooltip } from '../../../utils'
import {
  ACTIVATE_ZOOM_ACTION,
  DATA_ZOOM_TIMELINE,
  GRID,
  TOOLBOX_TIMELINE,
  X_AXIS_TIMELINE,
} from '../../../utils/constants'
import { mapFuelLineTypeToName } from '../../../utils/mappers/fdl4-mappers'
import { Chart } from '../../../utils/models'
import {
  ManualCorrectionOverwrite,
  ManualCorrectionRepair,
} from '../../../api-models/performance/manual-corrections'
import { grey } from '../../../theme'
import EditRepairModal from '../components/edit-repair-modal'
import EditOverwriteModal from '../components/edit-overwrite-modal'
import * as S from './CorrectionsChart.style'
import {
  type CorrectionsChartProps,
  EEntryType,
  ENTRY_COLOR_MAP,
} from './CorrectionsChart.types'

const BAR_HEIGHT = 25
const HEIGHT_RATIO = 0.8

/**
 * Returns an eCharts graphic object based on incoming parameters
 *
 * @param params
 * @param rect
 * @returns
 */
const clipRectByRect = (params, rect) =>
  graphic.clipRectByRect(rect, {
    x: params.coordSys.x,
    y: params.coordSys.y,
    width: params.coordSys.width,
    height: params.coordSys.height,
  })

export const CorrectionsChart = ({
  batchSeries,
  id,
  isShown,
  losses,
  overwrites,
  queryPeriod,
  repairs,
  setChartRef,
  setIsDataLossAccordionOpen,
  refreshFuelConsumption,
}: CorrectionsChartProps) => {
  // Data loss modal
  const [chart, setChart]: [Chart | undefined, Function] = useState()
  const [editRepairActive, setEditRepairActive] = useState<boolean>(false)
  const [selectedRepair, setSelectedRepair] = useState<
    ManualCorrectionRepair | undefined
  >()

  // Overwrite Modal
  const [editOverwriteActive, setEditOverwriteActive] = useState<boolean>(false)
  const [selectedOverwrite, setSelectedOverwrite] = useState<
    ManualCorrectionOverwrite | undefined
  >()

  /**
   * Returns an options object for consumption by the chart
   */
  const getOptions = useCallback((): EChartOption | any => {
    const queryFrom = moment(queryPeriod.from).valueOf()
    const queryTo = moment(queryPeriod.to).valueOf()

    const getRenderItems =
      (color: string, interactable: boolean, zIndex: number) =>
      (params, api) => {
        const fuelLineName = api.value(0)
        const startTS = api.value(1)
        const endTS = api.value(2)

        const start = api.coord([startTS, fuelLineName])
        const end = api.coord([endTS, fuelLineName])
        const barLength = end[0] - start[0]
        const barHeight = api.size([0, 1])[1] * HEIGHT_RATIO

        return {
          type: 'rect',
          shape: clipRectByRect(params, {
            x: start[0],
            y: start[1] - barHeight / 2,
            width: barLength,
            height: barHeight,
          }),
          silent: !interactable,
          style: {
            fill: color,
          },
          z2: zIndex,
        }
      }

    const getTooltip = (header: string, content: string) => ({
      formatter: (params) => {
        const [, startTimestamp, endTimestamp] = params.data

        const columnHeaders = [
          {
            label: header,
          },
        ]

        const tooltips = [
          {
            label: `${content} from ${moment
              .utc(startTimestamp)
              .format('DD MMM HH:mm UTC')} to ${moment
              .utc(endTimestamp)
              .format('DD MMM HH:mm UTC')}`,
          },
        ]

        return tooltip(startTimestamp, tooltips, columnHeaders)
      },
    })

    return {
      dataZoom: [{ ...DATA_ZOOM_TIMELINE, filterMode: 'weakFilter' }],
      grid: {
        ...GRID,
        containLabel: false,
        show: true,
        top: 0,
        bottom: 0,
        right: 70,
        height: BAR_HEIGHT * batchSeries.fuelLines.length,
        backgroundColor: '#fff',
        borderWidth: 0,
        left: 20,
      },
      series: [
        {
          data: batchSeries.fuelLines.map((fuelLineType) => [
            mapFuelLineTypeToName(fuelLineType),
            queryFrom,
            queryTo,
            EEntryType.Regular,
          ]),
          encode: {
            x: [1, 2],
            y: 0,
          },
          name: 'Timeline',
          renderItem: getRenderItems(
            ENTRY_COLOR_MAP[EEntryType.Regular],
            false,
            0,
          ),
          type: 'custom',
          xAxisIndex: 0,
          yAxisIndex: 0,
        },
        {
          data: losses.map((entry) => [
            mapFuelLineTypeToName(entry.fuelLineType),
            moment(entry.startTimestamp).valueOf(),
            moment(entry.endTimestamp).valueOf(),
            EEntryType.Loss,
            false,
            entry,
          ]),
          encode: {
            x: [1, 2],
            y: 0,
          },
          name: 'Loss',
          renderItem: getRenderItems(ENTRY_COLOR_MAP[EEntryType.Loss], true, 1),
          tooltip: getTooltip('Loss Info', 'DATA LOSS'),
          type: 'custom',
          xAxisIndex: 0,
          yAxisIndex: 0,
        },
        {
          data: repairs.map((entry) => [
            mapFuelLineTypeToName(entry.fuelLineType),
            moment(entry.startTimestamp).valueOf(),
            moment(entry.endTimestamp).valueOf(),
            EEntryType.Repair,
            !!entry.readonly,
            entry,
          ]),
          encode: {
            x: [1, 2],
            y: 0,
          },
          name: 'Repaired',
          renderItem: getRenderItems(
            ENTRY_COLOR_MAP[EEntryType.Repair],
            true,
            2,
          ),
          tooltip: getTooltip('Repaired Info', 'REPAIR'),
          type: 'custom',
          xAxisIndex: 0,
          yAxisIndex: 0,
        },
        {
          data: overwrites.map((entry) => [
            mapFuelLineTypeToName(entry.fuelLineType),
            moment(entry.startTimestamp).valueOf(),
            moment(entry.endTimestamp).valueOf(),
            EEntryType.Overwrite,
            !!entry.readonly,
            entry,
          ]),
          encode: {
            x: [1, 2],
            y: 0,
          },
          name: 'Overwrite',
          renderItem: getRenderItems(
            ENTRY_COLOR_MAP[EEntryType.Overwrite],
            true,
            3,
          ),
          tooltip: getTooltip('Overwrite Info', 'OVERWRITE'),
          type: 'custom',
          xAxisIndex: 0,
          yAxisIndex: 0,
        },
      ],
      toolbox: {
        ...TOOLBOX_TIMELINE,
      },
      tooltip: {
        axisPointer: {
          show: false,
        },
        textStyle: {
          color: grey[50],
        },
        backgroundColor: 'rgba(50,50,50,0.9)',
      },
      xAxis: {
        ...X_AXIS_TIMELINE,
        min: moment.utc(queryPeriod.from).valueOf(),
        max: moment.utc(queryPeriod.to).valueOf(),
        show: false,
      },
      yAxis: {
        axisLine: { show: false },
        axisTick: { show: false },
        data: batchSeries.fuelLines
          .sort()
          .map((line) => mapFuelLineTypeToName(line))
          .reverse(),
        position: 'right',
        splitLine: { show: false },
        axisPointer: {
          show: false,
        },
      },
    }
  }, [batchSeries.fuelLines, losses, overwrites, queryPeriod, repairs])

  const refCallback = useCallback(
    (chartNode) => {
      if (chartNode === null) {
        return
      }

      const chart = init(chartNode) as Chart
      chart.setOption(getOptions())
      chart.dispatchAction(ACTIVATE_ZOOM_ACTION)
      chart.group = 'fuel-consumption-group'
      chart.on('click', (params) => {
        const [, , , type, , entry] = params.data

        if (type === EEntryType.Repair) {
          setSelectedRepair(entry)
          setEditRepairActive(true)
        } else if (type === EEntryType.Overwrite) {
          setSelectedOverwrite(entry)
          setEditOverwriteActive(true)
        } else if (type === EEntryType.Loss) {
          setIsDataLossAccordionOpen(true)
        }
      })
      setChart(chart)
      const chartRef = getInstanceByDom(chartNode)
      setChartRef(chartRef)
    },
    [getOptions, setChartRef, setIsDataLossAccordionOpen],
  )

  const zoomOut = () => {
    const { from, to } = queryPeriod
    chart?.dispatchAction({
      type: 'dataZoom',
      batch: [
        {
          startValue: moment.utc(from).valueOf(),
          endValue: moment.utc(to).valueOf(),
        },
      ],
    })
  }

  return (
    <>
      <S.ChartSection isShown={isShown}>
        <S.ChartLegend>
          {['Loss', 'Repaired', 'Overwrite'].map((type) => (
            <S.ChartLegendItem key={type} type={type}>
              {type}
            </S.ChartLegendItem>
          ))}
        </S.ChartLegend>
        <S.ChartNode
          id={id}
          minHeight={BAR_HEIGHT * batchSeries.fuelLines.length}
          onDoubleClick={() => zoomOut()}
          ref={refCallback}
        ></S.ChartNode>
      </S.ChartSection>

      {selectedRepair && Object.keys(selectedRepair).length > 0 && (
        <EditRepairModal
          visible={editRepairActive}
          data={selectedRepair}
          handleClose={() => {
            setEditRepairActive(false)
          }}
          refreshData={refreshFuelConsumption}
        />
      )}

      {selectedOverwrite && Object.keys(selectedOverwrite).length > 0 && (
        <EditOverwriteModal
          visible={editOverwriteActive}
          data={selectedOverwrite}
          handleClose={() => {
            setEditOverwriteActive(false)
          }}
          refreshData={refreshFuelConsumption}
        />
      )}
    </>
  )
}
