import { createColumnHelper } from '@tanstack/react-table'

import { type EnergyManagementSimulatorData } from '../models'
import {
  NumericCell,
  NumericHeader,
} from '../../../components/Table/Table.styles'

const columnHelper = createColumnHelper<EnergyManagementSimulatorData>()

export const columns = [
  columnHelper.accessor('name', {
    header: 'Equipment',
    enableSorting: false,
  }),
  columnHelper.accessor('value', {
    header: () => <NumericHeader>Simulated power (kWh)</NumericHeader>,
    enableSorting: false,
    cell: (props) => {
      return <NumericCell>{props.getValue()}</NumericCell>
    },
  }),
]
