import { ActionType, IReducerAction } from './stock-entry-reducer'
import { EntryType } from '../../features/stock-management/models'
import { Performance } from '../../api-models'

export const deleteFuelOilUserEntry = ({ type, id }) => {}

export const setFuelOilUserEntries = (
  fuelOilUserEntries?: Performance.FuelOilStock.UserEntry[],
): IReducerAction => {
  return {
    type: ActionType.SET_FUEL_OIL_USER_ENTRIES,
    data: fuelOilUserEntries,
  }
}

export const setCurrentEntry = (
  entryType?: EntryType,
  entryId?: string,
): IReducerAction => {
  return {
    type: ActionType.SET_CURRENT_ENTRY_TYPE,
    data: {
      entryType,
      entryId,
    },
  }
}
