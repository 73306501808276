import { useCallback, useContext, useEffect, useState } from 'react'

import { EMPTY_GUID, FILTERS_CONFIG } from './CounterPage.utils'
import * as S from './CounterPage.styles'
import { VesselPageContext } from '../../contexts'
import { Performance } from '../../api-models'
import {
  EnergyConsumptionTable,
  EnergyProductionTable,
  FuelConsumptionOverview,
  MainEngineTable,
  SelectedPeriod,
} from '../../features/counter'
import {
  BatchDetailsModal,
  MixedBatchModal,
} from '../../features/stock-management'
import {
  BatchType,
  displayErrorModal,
  FuelType as FuelTypeEnum,
  getDefaultFilters,
  isFeatureEnabled,
} from '../../utils'
import { FUEL_TYPES } from '../../utils/constants'
import FilterPanel, {
  Filters,
  getDateRange,
  getFilterNotification,
  getFiltersFromQueryParams,
} from '../../features/filter'
import * as PerformanceApi from '../../services/performance'
import { ContentLayout } from '../../layout'
import { ContentCard, Loading, NotFound } from '../../commons'
import TemNotifications from '../../components/TemNotifications/TemNotifications'
import PerformanceAlerts from '../../components/PerformanceAlerts/PerformanceAlerts'

const CounterPage = () => {
  const { imoNo } = useContext(VesselPageContext)
  const configuration = useContext(VesselPageContext).configuration!
  const [counterData, setCounterData] =
    useState<Performance.Counter.CounterResponse>()
  const [filters, setFilters] = useState<Filters | undefined>(
    getFiltersFromQueryParams(),
  )
  const [batchEntries, setBatchEntries] = useState<
    Performance.FuelOilStock.BatchResponse[]
  >([])
  const [shouldShowBatchDetails, setShouldShowBatchDetails] = useState(false)
  const [shouldShowMixedBatchDetails, setShouldShowMixedBatchDetails] =
    useState(false)
  const [batchDetailId, setBatchDetailId] = useState('')

  const loadData = useCallback(() => {
    if (imoNo) {
      setCounterData(undefined)
      const period = getDateRange(getDefaultFilters(1, 'd').dateRange, filters)
      PerformanceApi.getCounter(imoNo, period)
        .then((counterResponse: Performance.Counter.CounterResponse) => {
          setCounterData(counterResponse)
          if (counterResponse?.fuelConsumption) {
            const fuelTypes = counterResponse.fuelConsumption.fuelTypes
            const fuelConsumptionByFuelType = fuelTypes.reduce(
              (fuelTypeObj, { fuelType, ...rest }) => ({
                ...fuelTypeObj,
                [FuelTypeEnum[fuelType]]: rest,
              }),
              {},
            )
            const batchIds = FUEL_TYPES.reduce(
              (ids: string[], fuelType: string) => {
                const currentFuelType = fuelConsumptionByFuelType[fuelType]
                if (currentFuelType) {
                  ids.push(
                    ...currentFuelType.batches
                      .filter(({ batchId }) => batchId !== EMPTY_GUID)
                      .map(({ batchId }) => batchId),
                  )
                }
                return ids
              },
              [],
            )
            if (batchIds.length) {
              PerformanceApi.getBatches(imoNo, batchIds)
                .then(setBatchEntries)
                .catch((e) => setBatchEntries([]))
            }
          }
        })
        .catch((e) =>
          displayErrorModal({
            message: 'Cannot get data for counter for the given time period',
            statusText: 'Unable to get data',
          }),
        )
    }
  }, [imoNo, filters])

  useEffect(() => {
    loadData()
  }, [loadData])

  const onFiltersChange = (filters) => {
    setCounterData(undefined)
    setFilters(filters)
  }

  const notifications = filters && [
    getFilterNotification(filters, () => onFiltersChange(undefined)),
  ]

  const showBatchDetails = (batchId) => {
    const batch = batchEntries.find(({ id }) => id === batchId)
    if (batch) {
      setBatchDetailId(batchId)
      // eslint-disable-next-line eqeqeq
      if (batch.type == BatchType.BunkeredBatch) {
        setShouldShowBatchDetails(true)
      } else {
        setShouldShowMixedBatchDetails(true)
      }
    } else {
      setShouldShowBatchDetails(false)
      setShouldShowMixedBatchDetails(false)
    }
  }

  return (
    <ContentLayout header='Counter' notifications={notifications} hasFilter>
      <TemNotifications />
      <PerformanceAlerts />
      <FilterPanel
        onChange={onFiltersChange}
        config={FILTERS_CONFIG}
        value={filters}
        onReset={() => onFiltersChange(undefined)}
      />
      {shouldShowBatchDetails && (
        <BatchDetailsModal
          visible={shouldShowBatchDetails}
          closeHandler={() => setShouldShowBatchDetails(false)}
          batchId={batchDetailId}
        />
      )}
      {shouldShowMixedBatchDetails && (
        <MixedBatchModal
          closeHandler={() => setShouldShowMixedBatchDetails(false)}
          entryId={batchDetailId}
        />
      )}
      <>
        <S.SelectedPeriodWrapper>
          {counterData?.queryPeriod && counterData?.resultPeriod && (
            <SelectedPeriod
              queryPeriod={counterData.queryPeriod}
              resultPeriod={counterData.resultPeriod}
            />
          )}
        </S.SelectedPeriodWrapper>
        {
          <S.FuelConsumptionWrapper>
            <FuelConsumptionOverview
              batchEntries={batchEntries}
              fuelConsumption={counterData?.fuelConsumption}
              hasData={counterData?.hasData ?? false}
              isLoading={!counterData}
              showBatchDetails={showBatchDetails}
              numberOfDigits={filters?.numberOfDigits || 3}
            />
          </S.FuelConsumptionWrapper>
        }
        <S.TableWrapper>
          <S.VerticalTableWrapper>
            {isFeatureEnabled('counter/energy-production') && (
              <ContentCard
                id='counter-pp'
                title='Energy production'
                helpTextKey='counter/totalpowerproductiontable'
                contentPadding='16px'
              >
                {counterData?.electricalProduction && (
                  <EnergyProductionTable
                    data={counterData.electricalProduction}
                  />
                )}
                {!counterData && <Loading />}
                {counterData && !counterData?.hasData && (
                  <NotFound text='No data found for selected period' />
                )}
              </ContentCard>
            )}
          </S.VerticalTableWrapper>
          <S.VerticalTableWrapper>
            {isFeatureEnabled('counter/energy-consumption') && (
              <ContentCard
                id='counter-pc'
                title='Energy consumption'
                helpTextKey='counter/totalpowerconsumptiontable'
                contentPadding='16px'
                hide={
                  counterData?.electricalConsumption?.consumers &&
                  counterData.electricalConsumption.consumers.length === 0
                }
              >
                {counterData?.electricalConsumption && (
                  <EnergyConsumptionTable
                    data={counterData.electricalConsumption}
                  />
                )}
                {!counterData && <Loading />}
                {counterData && !counterData.hasData && (
                  <NotFound text='No data found for selected period' />
                )}
              </ContentCard>
            )}
            {isFeatureEnabled('counter/main-engines') && (
              <ContentCard
                id='counter-me'
                title={`Main engine${
                  configuration.hasTwoMainEngines ? 's' : ''
                }`}
                helpTextKey='counter/mainengpowertable'
                contentPadding='16px'
              >
                {counterData && counterData.mainEngDetails && (
                  <MainEngineTable
                    mainEngDetails={counterData.mainEngDetails}
                    configuration={configuration}
                  />
                )}
                {!counterData && <Loading />}
                {counterData && !counterData.hasData && (
                  <NotFound text='No data found for selected period' />
                )}
              </ContentCard>
            )}
          </S.VerticalTableWrapper>
        </S.TableWrapper>
      </>
    </ContentLayout>
  )
}

export default CounterPage
