import React from 'react'
import styled from 'styled-components'

const FieldSet = styled.fieldset<{ hidden: boolean }>`
  margin-bottom: 32px !important;
  display: ${({ hidden }) => (hidden ? 'none' : 'block')};

  &:disabled {
    input,
    textarea {
      cursor: not-allowed;
    }
  }
`

const Header = styled.h3`
  margin-bottom: 8px !important;
  border-bottom: 3px solid ${(props) => props.theme.colors.blue[50]};
`
const Description = styled.p`
  margin-bottom: 16px !important;
  margin-left: 2px !important;
`

type Props = {
  header: string
  description?: string
  hidden?: boolean
  disabled?: boolean
  children?: React.ReactNode
}

const FormSection = ({
  header,
  description,
  children,
  hidden,
  disabled,
}: Props) => {
  return (
    <FieldSet hidden={!!hidden} disabled={disabled}>
      <Header className='mds-headline--small'>{header}</Header>
      {description && <Description>{description}</Description>}
      {children}
    </FieldSet>
  )
}

export default FormSection
