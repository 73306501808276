export interface Chart extends echarts.ECharts {
  dispatchAction: (payload: object, silent?: boolean) => void
  getZr: () => any
  makeActionFromEvent: (event) => EChartsAction
}

export interface EChartsAction {
  type: string
  batch: object[]
}

export interface YDomain {
  min: number
  max: number
}

export interface YDomains {
  [key: string]: YDomain
}
