import * as yup from 'yup'
import { ELubeOilContainer, ELubeOilType, ESoundingType } from './../types'
import {
  integerValidationSchema,
  OIL_TYPE_TO_TBN_RANGE,
  REQUIRED_MESSAGE,
  timestampValidationSchema,
} from './utils'

const MAX_UNPUMPABLE = 5000
const MIN_UNPUMPABLE = 0

const OIL_TYPE_TO_ROB_RANGE = {
  [ELubeOilType.AE]: [0, 40000],
  [ELubeOilType.CLO]: [0, 200000],
  [ELubeOilType.CLO + ELubeOilContainer.DayTank]: [0, 10000],
  [ELubeOilType.CLO + ELubeOilContainer.DayTankStbd]: [0, 10000],
  [ELubeOilType.CLOAdditive]: [0, 50000],
  [ELubeOilType.ME]: [0, 150000],
  [ELubeOilType.Other]: [0, 40000],
}

const unpumpableSchema = integerValidationSchema
  .min(
    MIN_UNPUMPABLE,
    `Should be in range ${MIN_UNPUMPABLE} to ${MAX_UNPUMPABLE}`,
  )
  .max(
    MAX_UNPUMPABLE,
    `Should be in range ${MIN_UNPUMPABLE} to ${MAX_UNPUMPABLE}`,
  )

const getItemSchema = (oilType: ELubeOilType) =>
  yup.array().when('type', {
    is: ESoundingType.Full,
    then: yup.array().of(
      yup.object({
        rob: getROBSchema(oilType),
        ...(oilType !== ELubeOilType.Other && { tbn: getTBNSchema(oilType) }),
        unpumpable: unpumpableSchema,
      }),
    ),
    otherwise: yup.array().of(
      yup.object().shape(
        {
          rob: getPartialSoundingFieldSchema('rob', oilType),
          ...(oilType !== ELubeOilType.Other && {
            tbn: getPartialSoundingFieldSchema('tbn', oilType),
          }),
          unpumpable: getPartialSoundingFieldSchema('unpumpable', oilType),
        },
        [
          ['rob', 'rob'],
          ['tbn', 'tbn'],
          ['unpumpable', 'unpumpable'],
          ['rob', 'unpumpable'],
          ['rob', 'tbn'],
          ['tbn', 'unpumpable'],
        ],
      ),
    ),
  })

const getPartialSoundingFieldSchema = (
  field: 'rob' | 'tbn' | 'unpumpable',
  oilType: ELubeOilType,
) =>
  yup
    .number()
    .nullable()
    .when(['description', 'rob', 'tbn', 'unpumpable'], {
      is: (description, rob, tbn, unpumpable) =>
        !!description || rob != null || tbn != null || unpumpable != null,
      then:
        field === 'rob'
          ? getROBSchema(oilType)
          : field === 'tbn'
          ? getTBNSchema(oilType)
          : unpumpableSchema,
    })

const getROBSchema = (oilType: ELubeOilType) =>
  integerValidationSchema
    .required(REQUIRED_MESSAGE)
    .test('is-in-range', '', function (value) {
      const { oilContainer } = this.parent

      const [min, max] = [
        ELubeOilContainer.DayTank,
        ELubeOilContainer.DayTankStbd,
      ].includes(oilContainer)
        ? OIL_TYPE_TO_ROB_RANGE[oilType + oilContainer]
        : OIL_TYPE_TO_ROB_RANGE[oilType]

      if (value < min || value > max) {
        return this.createError({
          path: this.path,
          message: `Should be in range ${min} to ${max}`,
        })
      }

      return true
    })

const getTBNSchema = (oilType: ELubeOilType) => {
  const [min, max] = OIL_TYPE_TO_TBN_RANGE[oilType]
  return yup
    .number()
    .nullable()
    .when('rob', {
      is: 0,
      then: yup.number().oneOf([null], 'Not applicable when ROB is 0'),
      otherwise: integerValidationSchema
        .min(min, `Should be in range ${min} to ${max}`)
        .max(max, `Should be in range ${min} to ${max}`)
        .required(REQUIRED_MESSAGE),
    })
}

const soundingValidationSchema = yup.object().shape({
  ae: getItemSchema(ELubeOilType.AE),
  clo: getItemSchema(ELubeOilType.CLO),
  cloAdditive: getItemSchema(ELubeOilType.CLOAdditive),
  me: getItemSchema(ELubeOilType.ME),
  other: getItemSchema(ELubeOilType.Other),
  timestamp: timestampValidationSchema,
})

export default soundingValidationSchema
