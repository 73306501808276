import {
  Field,
  type FieldInputProps,
  getIn,
  useField,
  useFormikContext,
} from 'formik'

import { type ExternalSource } from '../../../api-models/hdc/report'
import InputField, {
  type InputFieldProps,
} from '../../../components/form/input-field'
import { formatValue } from '../../../utils'
import { HDC_DATA_AVAILABILITY } from '../../../utils/constants'
import { type HDCReportFormValues } from '../types'
import { getOriginalValue } from '../utils'

type Props = InputFieldProps & {
  error?: string
  hasWarning?: boolean
  maxWidth?: string
  name: string
  icon?: string
  showSensorValue?: boolean
  isNoteVisible?: boolean
  regex?: RegExp
}

const HDCInputField = ({
  decimals = 0,
  error,
  hasWarning,
  maxWidth,
  name,
  icon,
  note,
  isNoteVisible,
  showSensorValue = true,
  regex,
  ...inputFieldProps
}: Props) => {
  const { values, initialValues } = useFormikContext()
  const [field, meta] = useField<FieldInputProps<string>>(name)
  const { setFieldValue } = useFormikContext<HDCReportFormValues>()

  const shouldShowSensorValue = (): boolean => {
    if (!showSensorValue) {
      return false
    }
    // Get the entire datapoint rather than just the form value,
    // this is needed to get the external datasource (sensor) value.
    // As all form values are modelled after the API model externalSource should,
    // exist in the Formik context.
    const externalSource: ExternalSource<string | number> = getIn(
      values,
      name.replace('.value', '.externalSource'),
    )

    if (
      !externalSource ||
      (externalSource.value &&
        externalSource.dataAvailability < HDC_DATA_AVAILABILITY)
    ) {
      return false
    }

    return (
      getIn(initialValues, name) !== externalSource.value ||
      getIn(values, name) !== externalSource.value
    )
  }

  const handleBlur = (event) => {
    const target = event.target as HTMLInputElement
    let value: string | number = target.value

    // If this is a number type input field, and it loses focus with e.g. `50.`,
    // set the field value to `50`
    if (inputFieldProps.type === 'number') {
      if (value.charAt(value.length - 1) === '.') {
        value = parseFloat(formatValue(value, 0, ''))
      } else {
        value = parseFloat(formatValue(value, decimals, ''))
      }

      if (Number.isNaN(value)) {
        value = ''
      }
    }

    const shouldValidate = !!error || !!meta.error
    void setFieldValue(name, value, shouldValidate)
  }

  const handleChange = (event) => {
    if (!regex) return
    const target = event.target as HTMLInputElement
    let value: string | number = target.value

    if (value === '') {
      return
    }

    if (!regex.test(value)) {
      void setFieldValue(name, '')
    }
  }

  return (
    <Field
      {...inputFieldProps}
      component={InputField}
      decimals={decimals}
      hasWarning={hasWarning}
      id={name}
      isInvalid={!!error || !!meta.error}
      maxWidth={maxWidth}
      icon={icon}
      note={
        isNoteVisible === false
          ? null
          : note ||
            (shouldShowSensorValue()
              ? `Was ${getOriginalValue(values, name)}`
              : null)
      }
      onChange={handleChange}
      onBlur={handleBlur}
      value={field.value ?? ''}
      data-e2e={inputFieldProps.id}
    />
  )
}

export default HDCInputField
