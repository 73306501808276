import { useContext, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useFormikContext } from 'formik'
import { McButton } from '@maersk-global/mds-react-wrapper'

import { Wrapper } from './ActionsButtons.styles'
import { PadContent } from '../../../../layout/styles'
import { WindowContext } from '../../../../contexts'
import { routerParams } from '../../../../routes'
import { displayToast, isShoreContext } from '../../../../utils'
import { usePatchReport } from '../../../../queries/GandalfApi/GandalfApi'
import { ReportType } from '../../../../queries/GandalfApi/GandalfApi.consts'

type Params = routerParams & {
  reportId: string
}

const ActionButtons = () => {
  const { windowSize } = useContext(WindowContext)
  const history = useHistory()
  const { vesselId, reportId } = useParams<Params>()
  const { values: report, isSubmitting } =
    useFormikContext<GandalfApi.MfeReport>()

  const patchReport = usePatchReport(vesselId, reportId, ReportType.Mfe)

  const [isSaving, setIsSaving] = useState(false)

  const handleDiscard = () => {
    history.push(`/MaerskStarConnect/vessel/${vesselId}/mfe`)
  }

  const handleSave = async () => {
    setIsSaving(true)
    try {
      await patchReport.mutateAsync({ section: 'partial', payload: report })
      void displayToast('success', 'Report saved successfully', '')
    } catch (error) {
      console.log(error)
    } finally {
      setIsSaving(false)
    }
  }

  return (
    <PadContent>
      <Wrapper>
        <McButton
          fit={windowSize}
          variant='outlined'
          label='Close and discard changes'
          click={handleDiscard}
        />
        <McButton
          fit={windowSize}
          variant='outlined'
          label='Save'
          click={handleSave}
          loading={isSaving}
          disabled={isShoreContext()}
        />
        <McButton
          type='submit'
          fit={windowSize}
          label='Submit'
          trailingicon='check'
          loading={isSubmitting}
          disabled={isSaving || isShoreContext()}
        />
      </Wrapper>
    </PadContent>
  )
}

export default ActionButtons
