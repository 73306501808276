import { McButton, McModal } from '@maersk-global/mds-react-wrapper'

type Props = {
  content?: {
    title?: string
    message?: string | JSX.Element
  }
  confirmHandler: () => void
  cancelHandler: () => void
  confirmButtonText?: string
  cancelButtonText?: string
  hideCancelButton?: boolean
}

const ConfirmModal = ({
  content,
  confirmHandler,
  cancelHandler,
  confirmButtonText,
  cancelButtonText,
  hideCancelButton,
}: Props) => {
  if (!content) return null

  const { title, message } = content

  return (
    <McModal
      {...(title ? { heading: title } : {})}
      open
      closed={cancelHandler}
      height='auto'
      hiddenclose
      zindex={Number.MAX_SAFE_INTEGER}
    >
      {message ? message : 'Are you sure?'}
      <McButton
        slot='primaryAction'
        appearance='primary'
        click={confirmHandler}
        data-e2e='modal-primary-button'
      >
        {confirmButtonText || 'Confirm'}
      </McButton>
      {!hideCancelButton && (
        <McButton
          slot='secondaryAction'
          appearance='neutral'
          click={cancelHandler}
        >
          {cancelButtonText || 'Cancel'}
        </McButton>
      )}
    </McModal>
  )
}

export default ConfirmModal
