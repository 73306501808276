import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  display: grid;
  grid-gap: 20px;
  grid-template-areas: 'loss consumption';
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
`

export const LossContainer = styled.section`
  grid-area: loss;
`

export const ConsumptionContainer = styled.section`
  grid-area: consumption;
`

export const ConsumptionField = styled.div`
  display: flex;
  align-items: center;
  margin-top: 24px;
`

export const ConsumptionPerHour = styled.div`
  margin: 4px 0 0 16px;
`

export const Header = styled.h5`
  border-bottom: 2px solid #63b2d4;
  margin: 4px 0 16px !important;
`

export const Label = styled.p`
  font-size: 16px !important;
  margin: 24px 0 1px 0 !important;
`

export const ErrorLabel = styled(Label)`
  color: red;
`

export const Button = styled.div`
  margin: 32px 0;
`

export const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 10px 0;
`

export const Loading = styled.div`
  background-color: rgba(255, 255, 255, 0.95);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1;
`

export const Paragraph = styled.p`
  margin: 10px 0 20px !important;
`
