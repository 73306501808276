import * as React from 'react'
import * as ECharts from 'echarts'
import * as moment from 'moment'
import { Sensors } from '../../../api-models/sensors'
import { formatValue, tooltip } from '../../../utils'
import { grey, maerskBlue, red } from '../../../theme'
import { Chart } from '../../../utils/models'
import {
  ACTIVATE_ZOOM_ACTION,
  X_AXIS_TIMELINE,
  AXIS_LABEL,
  AXIS_LINE,
  AXIS_TICK,
  AXIS_SPLIT_LINE,
  GRID,
  TOOLBOX_TIMELINE,
} from '../../../utils/constants'

interface SensorDataChartProps {
  data: Sensors.SensorDataResponse
}

export class SensorDataChart extends React.Component<
  SensorDataChartProps,
  any
> {
  private chartNode: React.RefObject<HTMLDivElement>
  private chart?: Chart

  constructor(props: SensorDataChartProps) {
    super(props)

    this.chartNode = React.createRef()
  }

  componentDidMount() {
    if (this.chartNode.current) {
      const chart = ECharts.init(this.chartNode.current) as Chart
      chart.setOption(this.getSensorDataOptions())
      chart.dispatchAction(ACTIVATE_ZOOM_ACTION)
      this.chart = chart
    }
  }

  componentDidUpdate() {
    if (this.chart) {
      this.chart.setOption(this.getSensorDataOptions())
    }
  }

  calculateYMinMax() {
    const { logs } = this.props.data
    const yPadding = 0.15
    const values = logs
      .map((s1) => s1.value)
      .filter((s2) => s2 !== null) as number[]
    let max = Math.max(...values)
    let min = Math.min(...values)
    const diff = max - min
    if (diff > 0) {
      min = min - diff * yPadding
      max = max + diff * yPadding
    } else {
      min = min - min * yPadding
      max = max + max * yPadding
    }
    return { min, max }
  }

  getSensorDataOptions(): ECharts.EChartOption | any {
    const { data } = this.props
    const yMinMax = this.calculateYMinMax()

    // Always show 1 day as initial zoom, if not enough data for 1 day, show all data
    const startIndexDataZoom =
      data.logs.length >= 144 ? data.logs.length - 144 : 0

    return {
      xAxis: {
        ...X_AXIS_TIMELINE,
        axisLabel: {
          ...X_AXIS_TIMELINE.axisLabel,
          formatter: (value: any) =>
            `{time|${moment.utc(value).format('HH mm')}}\n{date|${moment
              .utc(value)
              .format('DD MMM')}}\n\n\n`,
        },
        min: data.logs[0].timestamp,
        max: data.logs[data.logs.length - 1].timestamp,
      },
      yAxis: [
        {
          type: 'value',
          min: yMinMax.min,
          max: yMinMax.max,
          axisLabel: {
            formatter: (value: any) =>
              formatValue(value, yMinMax.max > 0 ? data.decimals : 1),
            ...AXIS_LABEL,
          },
          axisLine: AXIS_LINE,
          axisTick: AXIS_TICK,
          splitLine: AXIS_SPLIT_LINE,
        },
      ],
      grid: GRID,
      toolbox: TOOLBOX_TIMELINE,
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'line',
        },
        textStyle: {
          color: grey[50],
        },
        backgroundColor: 'rgba(50,50,50,0.9)',
        formatter: (params: any) => this.formatTooltip(params),
      },
      dataZoom: [
        {
          type: 'slider',
          startValue: data.logs[startIndexDataZoom].timestamp,
          showDetail: false,
          backgroundColor: 'rgba(47,69,84,0)',
          dataBackground: {
            lineStyle: {
              color: maerskBlue[500],
              opacity: 1,
            },
            areaStyle: {
              opacity: 0,
            },
          },
        },
      ],
      series: [
        {
          name: 'datapoints',
          type: 'line',
          lineStyle: {
            color: maerskBlue[500],
          },
          symbol: 'none',
          xAxisIndex: 0,
          yAxisIndex: 0,
          data: data.logs.map((s) => [s.timestamp, s.value]),
        },
        {
          name: 'invalid-datapoints',
          type: 'line',
          lineStyle: {
            color: red[400],
          },
          itemStyle: {
            color: red[400],
          },
          symbol: 'circle',
          symbolSize: 4,
          xAxisIndex: 0,
          yAxisIndex: 0,
          data: data.logs.map((s) => [
            s.timestamp,
            !s.isValid ? s.value : null,
          ]),
        },
        {
          name: 'invalid-datapoints-marker',
          type: 'line',
          lineStyle: {
            color: red[400],
            opacity: 0.5,
            width: 3,
          },
          symbol: 'square',
          itemStyle: {
            color: red[400],
            opacity: 0.5,
          },
          symbolSize: 4,
          xAxisIndex: 0,
          yAxisIndex: 0,
          data: data.logs.map((s) => [
            s.timestamp,
            !s.isValid ? yMinMax.min : null,
          ]),
        },
      ],
    }
  }

  formatTooltip(params: any) {
    const { data } = this.props
    const sensor = data.logs[params[0].dataIndex]
    const tooltipData = [
      {
        label: 'Value',
        value: formatValue(sensor.value, 2),
        unit: data.unit,
      },
    ]
    return tooltip(sensor.timestamp, tooltipData)
  }

  render() {
    return (
      <div
        onDoubleClick={() => {
          if (this.chart) {
            this.chart.dispatchAction({
              type: 'dataZoom',
              start: 0,
              end: 100,
            })
          }
        }}
        ref={this.chartNode}
      />
    )
  }
}
