import * as React from 'react'
import { NavLink, RouteComponentProps, withRouter } from 'react-router-dom'
import styled from 'styled-components'
import { McIcon } from '@maersk-global/mds-react-wrapper'

import { routerParams } from '../../routes'
import { pages as shorePages } from '../../routing/ShoreRoutes'
import { isFeatureEnabled, getPagePath } from '../../utils'

const Wrapper = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.colors.grey[200]};
  position: relative;

  .pages {
    li {
      &:hover {
        background-color: ${(props) => props.theme.colors.grey[100]};
      }

      a {
        display: flex;
        gap: 8px;
        align-items: center;
        padding: 12px;
        border-left: transparent 0.4rem solid;
        width: 100%;
        color: ${(props) => props.theme.colors.grey[700]};
        text-decoration: none;

        span {
          margin-bottom: -4px;
        }

        mc-icon {
          &::part(icon) {
            fill: ${(props) => props.theme.colors.grey[700]};
          }
        }
      }

      a.active {
        background-color: ${(props) => props.theme.colors.grey[100]};
        color: ${(props) => props.theme.colors.grey[900]};
        border-left-color: ${(props) => props.theme.colors.blue[400]};

        mc-icon {
          &::part(icon) {
            fill: ${(props) => props.theme.colors.grey[900]};
          }
        }
      }
    }
  }
`

export interface BarContentShoreOverviewProps
  extends RouteComponentProps<routerParams> {}

export interface BarContentShoreOverviewState {}

class BarContentShoreOverview extends React.Component<
  BarContentShoreOverviewProps,
  BarContentShoreOverviewState
> {
  render() {
    const { match } = this.props
    return (
      <Wrapper>
        <ul className='pages'>
          {shorePages.map((page) => {
            if (
              page.hideInNav ||
              !isFeatureEnabled(getPagePath(`${match.path}/${page.pagePath}`))
            ) {
              return null
            }
            return page.displayInOverviewMenu ? (
              <li key={`link-${page.pagePath}`}>
                <NavLink exact to={`/MaerskStarConnect/shore${page.pagePath}`}>
                  {page.icon && <McIcon icon={page.icon} size='24' />}
                  <span>{page.name}</span>
                </NavLink>
              </li>
            ) : (
              ''
            )
          })}
        </ul>
      </Wrapper>
    )
  }
}

export default withRouter(BarContentShoreOverview)
