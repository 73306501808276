export interface EnergyManagementSimulatorData {
  name: string
  value: string
  visible: boolean
}

export interface AuxEngParams {
  nbrBigAuxEngs: number
  nbrSmallAuxEngs: number
  maxPowerBigAuxEng?: number
  maxPowerSmallAuxEng?: number
  installedEngines: Set<number>
}

export enum MeOperationalMode {
  FIXED_POWER,
  VARIABLE_POWER,
}

export enum TEMConfiguration {
  FIXED_POWER_ONLY,
  VARIABLE_POWER_ONLY,
  BOTH,
}
