import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 32px 0 24px;
`

export const TextAreaWrapper = styled.div`
  position: sticky;
  bottom: 0;
  margin-top: auto;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    #ffffff 45%
  );

  mc-textarea {
    display: block;
    margin-top: 64px;
    padding-bottom: 4px;
  }
`
