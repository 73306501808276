import React from 'react'

export const VesselFilterContext =
  React.createContext<VesselFilterContext.Interface>({
    vesselFilters: {
      query: '',
    },
    applyVesselFilters: () => {},
  })

type Props = {
  children?: React.ReactNode
}

type State = {
  vesselFilters: VesselFilterContext.Model
}

export class FilterContextProvider extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      vesselFilters: {
        query: '',
      },
    }
  }

  applyVesselFilters = (vesselFilters: VesselFilterContext.Model) => {
    this.setState({ vesselFilters })
  }

  render() {
    const context = {
      vesselFilters: this.state.vesselFilters,
      applyVesselFilters: this.applyVesselFilters,
    }

    return (
      <VesselFilterContext.Provider value={context}>
        {this.props.children}
      </VesselFilterContext.Provider>
    )
  }
}

export const FilterContextConsumer = VesselFilterContext.Consumer
