import moment from 'moment'
import * as yup from 'yup'
import { ApiReportType } from '../../../../api-models/hdc/report'

const newReportModalValidationSchema = yup.object().shape({
  reportType: yup.string(),
  from: yup.date().required('Report start timestamp is required'),
  to: yup
    .date()
    .test({
      name: 'from',
      test: function (to) {
        const { from, reportType } = this.parent
        const MIN_LAPSED_REPORT_TIME = 10

        const toUtc = moment(to).utc()
        const fromUtc = moment(from).utc()

        if (
          reportType === ApiReportType.SEA &&
          toUtc.diff(fromUtc, 'hours', true) > 36
        ) {
          return this.createError({
            path: this.path,
            message: 'Sea report cannot not be older than 36 hours.',
          })
        }

        if (toUtc.diff(fromUtc, 'second') < 0) {
          return this.createError({
            path: this.path,
            message:
              'You cannot select a timestamp that is before latest report timestamp',
          })
        }

        if (toUtc.diff(fromUtc, 'minutes') < MIN_LAPSED_REPORT_TIME) {
          return this.createError({
            path: this.path,
            message: `You cannot create a report earlier than ${MIN_LAPSED_REPORT_TIME} minutes after previous report.`,
          })
        }

        return true
      },
    })
    .required('Report end timestamp is required'),
})

export default newReportModalValidationSchema
