import styled from 'styled-components'

export const Row = styled.div`
  display: flex;
  gap: 32px;
  margin-top: 24px;

  height: 56px;
  @media (min-width: ${(props) => props.theme.breakPoints.medium}px) {
    height: 68px;
  }
  @media (min-width: ${(props) => props.theme.breakPoints.large}px) {
    height: 76px;
  }

  > * {
    flex-basis: 33.33%;
  }
`

export const Ruler = styled.hr`
  margin-top: 42px !important;
`
