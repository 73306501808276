import { FuelConsumptionAction, FuelConsumptionActionType } from './actions'
import {
  BatchMonitor,
  EFuelConsumptionState,
  FuelLineDataLoss,
  Monitor,
} from '../../api-models/performance/fuel-consumption'
import {
  ManualCorrectionOverwrite,
  ManualCorrectionRepair,
} from '../../api-models/performance/manual-corrections'
import { IStockNotification } from '../../api-models/performance/common'

export type FuelConsumptionState = {
  consumptionState: EFuelConsumptionState | undefined | null
  losses: FuelLineDataLoss[] | undefined
  notifications: IStockNotification[]
  overwrites: ManualCorrectionOverwrite[] | undefined
  repairs: ManualCorrectionRepair[] | undefined
  monitor: {
    batch: BatchMonitor | undefined | null
    oil: Monitor | undefined | null
  }
}

export const initialState: FuelConsumptionState = {
  consumptionState: undefined,
  losses: undefined,
  notifications: [],
  overwrites: undefined,
  repairs: undefined,
  monitor: {
    batch: undefined,
    oil: undefined,
  },
}

export const FuelConsumptionReducer: React.Reducer<
  FuelConsumptionState,
  FuelConsumptionAction
> = (state = initialState, action) => {
  const { payload, type } = action

  switch (type) {
    case FuelConsumptionActionType.SET_CONSUMPTION_STATE:
      return {
        ...state,
        consumptionState: isNaN(payload) ? null : payload,
      }
    case FuelConsumptionActionType.SET_NOTIFICATIONS:
      return {
        ...state,
        notifications: payload,
      }
    case FuelConsumptionActionType.SET_CORRECTIONS:
      return {
        ...state,
        overwrites: payload.overwrites,
        repairs: payload.repairs,
      }
    case FuelConsumptionActionType.SET_LOSSES:
      return {
        ...state,
        losses: payload || [],
      }
    case FuelConsumptionActionType.SET_MONITOR_BATCH:
      return {
        ...state,
        monitor: {
          ...state.monitor,
          batch: payload || null,
        },
      }
    case FuelConsumptionActionType.SET_MONITOR_OIL:
      return {
        ...state,
        monitor: {
          ...state.monitor,
          oil: payload || null,
        },
      }
    case FuelConsumptionActionType.RESET_FUEL_CONSUMPTION_STATE:
      return initialState
    default:
      return state
  }
}
