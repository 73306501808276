import { McButton } from '@maersk-global/mds-react-wrapper'

import styled, { FuelColors, IColorScheme } from '../../../../../theme'
import { FuelType } from '../../../../../utils'

const Wrapper = styled.div`
  width: 100%;
  font-size: 14px;
`

const Batch = styled.div<{ colorScheme: IColorScheme }>`
  padding: 14px;
  background-color: ${(props) => props.colorScheme.fill};
  border: 1px solid ${(props) => props.colorScheme.stroke};
  border-radius: 3px;
  margin-bottom: 8px;

  .fuel-title {
    font-size: 32px;
    line-height: 39px;
    color: ${(props) => props.colorScheme.stroke};
  }
`

const Sulphur = styled.div<{ colorScheme: IColorScheme }>`
  color: ${(props) => props.colorScheme.stroke};
  text-align: center;
`

const BatchName = styled.div`
  padding: 8px 0;
  line-height: 17px;
`

const SulphurContent: { [id: string]: string } = {
  HS: '0.5% < Sulphur% < 3.5%',
  VLS: '0.1% < Sulphur% < 0.5%',
  ULS: 'Sulphur% < 0.1%',
  MDO: 'Sulphur% < 0.1%',
}

type Props = {
  fuelType: FuelType
  batchName?: string
  onClick: (fuelType: FuelType) => void
}

export const BatchSelectionDisplay = ({
  fuelType,
  batchName,
  onClick,
}: Props) => (
  <Wrapper>
    <Batch className='test' colorScheme={FuelColors[FuelType[fuelType]]}>
      <span className='fuel-title'>{FuelType[fuelType]}</span>
      <BatchName>{batchName ? batchName : '-'}</BatchName>
      <McButton
        fit='small'
        appearance='neutral'
        width='full-width'
        trailingicon={batchName ? 'arrows-left-right' : undefined}
        click={(event) => {
          event.preventDefault()
          event.stopPropagation()
          onClick(fuelType)
        }}
      >
        {batchName ? 'Change batch' : 'Select batch'}
      </McButton>
    </Batch>
    <Sulphur colorScheme={FuelColors[FuelType[fuelType]]}>
      {SulphurContent[FuelType[fuelType]]}
    </Sulphur>
  </Wrapper>
)
