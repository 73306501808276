import styled from 'styled-components'
import { McIcon } from '@maersk-global/mds-react-wrapper'

const Wrapper = styled.div<{ iconColor?: string }>`
  padding: 24px 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  > mc-icon::part(icon) {
    transform: scale(3.5);
    fill: ${(props) => props.iconColor};
  }
`

const Title = styled.h4`
  margin-bottom: 48px !important;
`

const Message = styled.div`
  margin: 40px 0 16px 0;
`

type Props = {
  icon: string
  iconColor?: string
  message?: JSX.Element | string
  title: string
}

const ModalContent = ({ icon, iconColor, message, title }: Props) => {
  return (
    <Wrapper iconColor={iconColor}>
      <Title>{title}</Title>
      <McIcon icon={icon} size='24' />
      {message && <Message>{message}</Message>}
    </Wrapper>
  )
}

export default ModalContent
