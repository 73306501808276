import { useField } from 'formik'
import { McInput } from '@maersk-global/mds-react-wrapper'

type Props = {
  id: string
  name: string
  label?: string
  hiddenlabel?: boolean
  placeholder?: string
  required?: boolean
  disabled?: boolean
  suffix?: string
}

/**
 * @description A thin wrapper for TextInput that provides two-way data binding
 * for Formik form values based on `name` prop
 */
export const FormTextInput = (props: Props) => {
  const [field, meta, helpers] = useField(props.name)

  const handleInputChange = ({ target }: InputEvent) => {
    const value = (target as HTMLInputElement).value
    helpers.setValue(value)
  }

  return (
    <McInput
      {...props}
      hiddenlabel={!Boolean(props.label)}
      invalid={meta.touched && !!meta.error}
      errormessage={meta.touched ? meta.error : undefined}
      // @ts-ignore
      input={handleInputChange}
      value={field.value}
    />
  )
}
