import * as React from 'react'
import Select from 'react-select'
import { pad } from '../utils'

interface TimePickerProps {
  id: string
  isHour?: boolean
  specificSelectable?: boolean
  disabled?: boolean
  maxValue?: number
  minValue?: number
  onChange: (selectedOption: any) => void
  onFocus: () => void
  value: { value: number; label: string }
}

export const TimePicker: React.FunctionComponent<TimePickerProps> = ({
  id,
  isHour,
  maxValue,
  minValue,
  specificSelectable,
  disabled,
  value,
  onFocus,
  onChange,
}) => {
  let options: any[] = []
  switch (true) {
    case isHour:
      options = Array.from(new Array(24).keys()).map((value) => ({
        value,
        label: `${pad(value, 2)}`,
      }))
      break
    case specificSelectable:
      options = Array.from(new Array(60).keys()).map((value) => ({
        value: value,
        label: `${pad(value, 2)}`,
      }))
      break
    default:
      options = Array.from(new Array(6).keys()).map((value) => ({
        value: value * 10,
        label: `${pad(value * 10, 2)}`,
      }))
      break
  }
  if (maxValue) {
    options = options.filter((option) => option.value <= maxValue)
  }
  if (minValue) {
    options = options.filter((option) => option.value >= minValue)
  }
  return (
    <div id={id}>
      <Select
        backspaceRemovesValue={false}
        isClearable={false}
        isMulti={false}
        isDisabled={disabled}
        options={options}
        components={{ IndicatorSeparator: () => null }}
        styles={{
          container: (provided) => ({
            ...provided,
            width: '56px',
          }),
          indicatorsContainer: (provided) => ({
            ...provided,
            padding: '0 4px',
          }),
          dropdownIndicator: (provided) => ({
            ...provided,
            width: '20px',
            padding: 'unset',
          }),
          option: (provided) => ({
            ...provided,
            fontSize: '13px',
          }),
          valueContainer: (provided) => ({
            ...provided,
            fontSize: '13px',
          }),
          menuList: (provided) => ({
            ...provided,
            fontSize: '13px',
            maxHeight: '200px',
          }),
        }}
        onChange={onChange}
        onFocus={onFocus}
        value={value}
        menuPosition='fixed'
      />
    </div>
  )
}
