import * as yup from 'yup'

const distanceAndSpeedValidationSchema = yup.object().shape({
  bridge: yup.object().shape({
    distanceLogged: yup.object().shape({
      value: yup
        .number()
        .nullable()
        .required('Please enter logged distance for the report period'),
    }),
    distanceObserved: yup.object().shape({
      value: yup
        .number()
        .nullable()
        .required('Please enter observed distance for the report period'),
    }),
  }),
})

export default distanceAndSpeedValidationSchema
