import { useContext, useMemo, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { Form, Formik } from 'formik'
import {
  McButton,
  McModal,
  McStepIndicator,
  McStepIndicatorItem,
} from '@maersk-global/mds-react-wrapper'

import {
  Actor,
  CloseAction,
  getDefaultStepsState,
  StepId,
  steps,
  StepState,
} from './Diagnostics.consts'
import {
  ContentWrapper,
  ModalHeight,
  ModalWidth,
  SplitView,
  Wrapper,
} from './Diagnostics.styles'
import {
  getInitialStep,
  getSubheading,
  hasUnresolvedTechnicalIssues,
  resolveInitialFormValues,
  resolveInitialStepsState,
} from './Diagnostics.utils'
import History from './History/History'
import { displayToast, isShoreContext } from '../../../utils'
import {
  validateDismiss,
  validateOperationalIssues,
  validateScheduleMetc,
  validateTechnicalIssues,
} from './Diagnostics.validation'
import { getNotificationHeading } from '../PerformanceAlerts.utils'
import { useTerminals } from '../../../queries/MasterDataApi/MasterDataApi'
import {
  keys as NotificationsApiQueryKeys,
  useGetNotification,
  usePostPerformanceAlertResponse,
} from '../../../queries/NotificationsApi/NotificationsApi'
import {
  MeSfocDataVersion,
  NotificationState,
} from '../../../queries/NotificationsApi/NotificationsApi.consts'
import { VesselPageContext, WindowContext } from '../../../contexts'
import { useVesselConfiguration } from '../../../hooks'

type Props = {
  alertId: string
  onClosed: () => void
  onOpened?: () => void
}

const Diagnostics = ({ alertId, onClosed, onOpened }: Props) => {
  const imoNo = useContext(VesselPageContext).imoNo!
  const configuration = useVesselConfiguration(imoNo)
  const { windowSize } = useContext(WindowContext)
  const queryClient = useQueryClient()
  const getTerminals = useTerminals()
  const getNotification =
    useGetNotification<NotificationsAPI.PerformanceAlert>(alertId)
  const postPerformanceAlertResponse = usePostPerformanceAlertResponse(alertId)

  const [peekStepIdx, setPeekStepIdx] = useState<number>()

  const { initialStepsState, currentStep } = useMemo(() => {
    if (!getNotification.isSuccess) {
      return {
        initialStepsState: getDefaultStepsState(),
        currentStep: steps[0],
      }
    }
    const initialStepsState = resolveInitialStepsState(getNotification.data)
    const initialStep =
      peekStepIdx !== undefined
        ? steps[peekStepIdx]
        : getInitialStep(steps, initialStepsState)

    return { initialStepsState, currentStep: initialStep }
  }, [getNotification.data, peekStepIdx])

  if (!getNotification.isSuccess) {
    return null
  }

  const validate = (values: Diagnostics.FormData): object => {
    const closeAction = resolveCloseAction()
    if (closeAction === CloseAction.Dismissed) {
      return validateDismiss(currentStep.id, values[currentStep.id])
    } else if (closeAction === CloseAction.Submitted) {
      switch (currentStep.id) {
        case StepId.OperationalIssues:
          return validateOperationalIssues(values.operationalIssues)
        case StepId.TechnicalIssues:
          return validateTechnicalIssues(values.technicalIssues)
        case StepId.ScheduleMetc:
          return validateScheduleMetc(values.scheduleMetc)
      }
    }
    return {}
  }

  const resolveCloseAction = (): Diagnostics.CloseAction | undefined => {
    const action = document.activeElement?.getAttribute('slot')
    if (action === 'primaryAction') {
      return CloseAction.Submitted
    } else if (action === 'secondaryAction') {
      return CloseAction.Dismissed
    } else {
      return undefined
    }
  }

  const isReadonly =
    isShoreContext() ||
    initialStepsState[currentStep.id] === StepState.Completed ||
    getNotification.data.state === NotificationState.Closed

  const handleSubmit = async (values: Diagnostics.FormData) => {
    if (isReadonly) return

    const action = resolveCloseAction()
    if (!action) {
      throw new Error('Unknown submit/dismiss notification response action')
    }

    // Move the comment from the form data to the response message
    const msg = values[currentStep.id]?.comment
    delete values[currentStep.id]?.comment

    // Clear form data for subsequent steps (set to null). If dismissing, also
    // clear the current step.
    switch (currentStep.id) {
      case StepId.OperationalIssues:
        if (action === CloseAction.Dismissed) {
          values.operationalIssues = null
        }
        values.technicalIssues = null
        values.scheduleMetc = null
        break
      case StepId.TechnicalIssues:
        if (action === CloseAction.Dismissed) {
          values.technicalIssues = null
        } else if (!hasUnresolvedTechnicalIssues(values.technicalIssues)) {
          values.technicalIssues!.resolvedBy = null
        }
        values.scheduleMetc = null
        break
      case StepId.ScheduleMetc:
        if (action === CloseAction.Dismissed) {
          values.scheduleMetc = null
        }
        break
    }

    const responseData: NotificationsAPI.Performance.Response = {
      dataVersion: MeSfocDataVersion.current,
      actor: Actor.Vessel,
      user: null,
      reason: action,
      message: msg ?? null,
      step: currentStep.id,
      formData: values,
    }

    let endsAt = getNotification.data.ends_at
    let state: NotificationsAPI.NotificationState = NotificationState.Inactive
    if (action === CloseAction.Submitted) {
      switch (currentStep.id) {
        case StepId.OperationalIssues:
          endsAt = values[currentStep.id]?.resolvedBy ?? endsAt
          break
        case StepId.TechnicalIssues:
          endsAt = values[currentStep.id]?.resolvedBy ?? endsAt
          if (!hasUnresolvedTechnicalIssues(values.technicalIssues)) {
            state = NotificationState.Active
          }
          break
        case StepId.ScheduleMetc:
          endsAt = values[currentStep.id]?.scheduledAt ?? endsAt
          break
        default:
          console.error('Unknown form step', currentStep.id)
      }
    }

    const payload: NotificationsAPI.Performance.ResponsePayload = {
      data: JSON.stringify(responseData),
      state: state,
      ends_at: endsAt,
    }

    try {
      await postPerformanceAlertResponse.mutateAsync(payload)
      void displayToast(
        'success',
        'Response submitted',
        'Your response has been submitted successfully.',
      )
      if (state === NotificationState.Active) {
        void queryClient.invalidateQueries({
          queryKey: NotificationsApiQueryKeys.notification(alertId),
        })
        setPeekStepIdx(undefined)
      } else if (state === NotificationState.Inactive) {
        onClosed()
      }
    } catch (error) {
      void displayToast(
        'error',
        'Error submitting response',
        'An error occurred while submitting your response. Please try again.',
      )
    }
  }

  const handlePeekStep = (idx: number) => {
    if (
      idx === currentStep.idx || // Ignore click on the current step
      initialStepsState[steps[idx].id] === StepState.Pending // Ignore click on pending/future steps
    ) {
      return
    }

    setPeekStepIdx(idx)
  }

  onOpened?.()

  const isTwinEngine = configuration?.hasTwoMainEngines ?? false

  // Cap fit to medium
  const fit = windowSize === 'large' ? 'medium' : windowSize

  return (
    <Formik
      initialValues={resolveInitialFormValues(
        getNotification.data,
        configuration?.hasManualOverflowEntry,
      )}
      onSubmit={handleSubmit}
      validate={validate}
      enableReinitialize
    >
      <Form>
        <McModal
          heading={getNotificationHeading(getNotification.data, isTwinEngine)}
          height={ModalHeight[currentStep.id]}
          width={ModalWidth[windowSize]}
          fit={fit}
          zindex={1002}
          open
          entercloseactiondisabled
          closed={onClosed}
        >
          <Wrapper>
            <McStepIndicator currentindex={currentStep.idx} fit={windowSize}>
              {steps.map((s) => (
                <McStepIndicatorItem
                  key={s.id}
                  state={initialStepsState[s.id]}
                  onClick={() => handlePeekStep(s.idx)}
                >
                  <span
                    style={{
                      fontWeight: currentStep.idx === s.idx ? 'bold' : 'normal',
                      textDecoration:
                        currentStep.idx === s.idx ? 'underline' : '',
                    }}
                  >
                    {s.heading}
                  </span>
                </McStepIndicatorItem>
              ))}
            </McStepIndicator>
            <SplitView>
              <div>
                <p>
                  {getSubheading(
                    getNotification.data,
                    isTwinEngine,
                    getTerminals.data,
                  )}
                </p>
                <ContentWrapper>
                  <currentStep.component
                    alert={getNotification.data.data}
                    isReadonly={isReadonly}
                  />
                </ContentWrapper>
              </div>
              <History
                isReadonly={isReadonly}
                alert={getNotification.data}
                stepId={currentStep.id}
              />
            </SplitView>
          </Wrapper>
          <McButton
            fit={fit}
            slot='primaryAction'
            type='submit'
            disabled={isReadonly}
            loading={postPerformanceAlertResponse.isLoading}
          >
            Submit
          </McButton>
          <McButton
            fit={fit}
            slot='secondaryAction'
            appearance='neutral'
            type='submit'
            disabled={postPerformanceAlertResponse.isLoading || isReadonly}
          >
            Dismiss
          </McButton>
        </McModal>
      </Form>
    </Formik>
  )
}

export default Diagnostics
