import * as yup from 'yup'

const etaSchema = yup.object().shape({
  bridge: yup.object().shape({
    eta: yup.object().shape({
      value: yup
        .date()
        .nullable()
        .required('Please enter Estimated Time for Arrival to pilot station'),
    }),
    remainingDistanceToPilotStation: yup.object().shape({
      value: yup
        .number()
        .nullable()
        .required(
          'Please enter remaining distance [NM] from closing of this report till arrival to pilot station',
        )
        .min(
          0,
          'Remaining distance to pilot station is negative. Please insert time for route change and update the remaining distance to PS',
        ),
    }),
  }),
})

export default etaSchema
