import * as React from 'react'
import styled, { seaGreen, grey, sizes } from '../../../theme'
import { SimpleDial } from './SimpleDial'
import { Performance } from '../../../api-models'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  .dials {
    width: ${sizes.quarter}px;
    &.border {
      border-right: 1px solid ${grey[200]};
    }
  }
`

interface SpeedOverGroundStatus {
  sog?: number
  maxSpeed?: number
}

export interface SpeedOverGroundDialProps {
  vesselStatus: Performance.Status.Status
  vesselConfiguration: Performance.VesselConfiguration.Configuration
}
export interface SpeedOverGroundDialState {}

export class SpeedOverGroundDial extends React.PureComponent<
  SpeedOverGroundDialProps,
  SpeedOverGroundDialState
> {
  getSpeedOverGroundStatus(): SpeedOverGroundStatus {
    const { vesselConfiguration, vesselStatus } = this.props
    const { voyage } = vesselStatus
    return {
      sog: voyage ? voyage.sog : undefined,
      maxSpeed: vesselConfiguration.maxSpeed,
    }
  }
  public render() {
    const { sog, maxSpeed } = this.getSpeedOverGroundStatus() as any
    return (
      <React.Fragment>
        <Wrapper>
          <div className='dials'>
            <SimpleDial
              id='dial-sog'
              value={sog}
              color={seaGreen[500]}
              minValue={0}
              maxValue={maxSpeed}
              unit='knots'
              dialHeader={''}
            />
          </div>
        </Wrapper>
      </React.Fragment>
    )
  }
}
