import { Management } from '../../api-models/management'

export const mapVesselMasterDataToVesselRegistry = (
  data: MasterDataApi.VesselParameters.General.BasicInfo.VesselsResponse,
): Array<Management.Vessel.VesselRegistry> => {
  return data.map(({ imoNo, parameters }) => {
    const data = parameters['General.BasicInfo'].data
    return {
      imoNo,
      techManager: data.fleetGroup ?? 'UNSORTED',
      vesselClass: data.vesselClass ?? 'Uncategorized',
      vesselType: getFleetGroup(data.ownershipType),
      vesselName: data.name,
      status: data.status,
    }
  })
}

const getFleetGroup = (vesselType: string): string =>
  vesselType === 'CHARTER' ? 'TC Owners' : 'Maersk Line Fleet Group'
