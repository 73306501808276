import styled from 'styled-components'

export const Wrapper = styled.div`
  display: grid;
  grid-template-areas:
    'a b'
    'c d';
  grid-gap: 24px;
  grid-template-columns: auto auto;
  max-width: 1080px;

  #list-selector {
    grid-area: b;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    mc-select {
      width: 180px;
    }
  }

  .card-content {
    padding: 16px;
  }

  #vessels {
    grid-area: c;
  }

  #list {
    grid-area: d;
  }

  .controls {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    min-height: 32px;

    > mc-select {
      width: 176px;
    }
  }
`
