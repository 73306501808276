import styled from 'styled-components'
import { ENTRY_COLOR_MAP, EEntryType } from './CorrectionsChart.types'

export const ChartSection = styled.section<{ isShown: boolean }>`
  border-top: 1px solid #e0e2e4;
  height: ${(props) => (props.isShown ? 'auto' : '0')};
  padding: ${(props) => (props.isShown ? '40px 0 20px' : '0')};
  position: relative;
  visibility: ${(props) => (props.isShown ? 'visible' : 'hidden')};
`

export const ChartLegend = styled.ul`
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 10px;
  top: 10px;
`

export const ChartLegendItem = styled.li<{ type: string }>`
  align-items: center;
  color: #6c6c6c;
  display: flex;
  font-size: 10px;
  line-height: 12px;

  &:not(:last-child) {
    padding-right: 5px;
  }

  &::before {
    background-color: ${(props) => {
      if (props.type === 'Loss') {
        return ENTRY_COLOR_MAP[EEntryType.Loss]
      } else if (props.type === 'Repaired') {
        return ENTRY_COLOR_MAP[EEntryType.Repair]
      }
      return ENTRY_COLOR_MAP[EEntryType.Overwrite]
    }};
    border-radius: 50%;
    content: ' ';
    display: inline-block;
    height: 10px;
    margin-right: 2.5px;
    width: 10px;
  }
`

export const ChartNode = styled.div<{ minHeight: number }>`
  min-height: ${(props) => props.minHeight}px;
  padding-right: 66px;

  .tooltip {
    border-spacing: 4px;
    border-collapse: collapse;

    td,
    th {
      padding-left: 12px;
      text-align: right;

      &:first-child {
        padding-left: 0;
      }

      &:first-child,
      &:last-child {
        text-align: left;
      }
    }

    .right-aligned {
      width: 100%;
      display: block;
      text-align: right;
    }

    tr:first-of-type {
      td {
        padding-top: 4px;
      }
    }

    thead {
      tr:first-of-type {
        th {
          text-align: center;
          padding-bottom: 4px;
        }
      }

      tr:last-of-type {
        border-bottom: 1px solid #fff;
      }
    }
  }
`
