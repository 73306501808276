import styled from '../../../../theme'
import { FuelTypeQuantity } from './FuelTypeQuantity'
import { StockOnBoard } from '../../models'
import {
  batchQuantitiesByDisplayOrder,
  fuelTypeByDisplayOrder,
  getBatchesFromFuelType,
} from '../../utils'
import { FuelType } from '../../../../utils'

const Wrapper = styled.div<{ width?: string }>`
  display: flex;
  justify-content: space-between;
  width: ${(props) => props.width || '100%'};

  & > div:not(:last-child) {
    margin-right: 16px;
  }
`

type Props = {
  stockOnBoard?: StockOnBoard
  width?: string
}

export const FuelTypeBatches = ({ stockOnBoard, width }: Props) => {
  return (
    <Wrapper width={width} data-e2e='batches'>
      {stockOnBoard?.fuelTypeQuantities
        .filter((fuelTypeQuantity) => fuelTypeQuantity.fuelType !== FuelType.MM)
        .sort(fuelTypeByDisplayOrder)
        .map((fuelTypeQuantity, idx) => (
          <FuelTypeQuantity
            key={`fuel-type-quantity-${idx}`}
            fuelType={fuelTypeQuantity.fuelType}
            fuelTypeBatches={getBatchesFromFuelType(
              stockOnBoard.batchQuantities.sort(batchQuantitiesByDisplayOrder),
              fuelTypeQuantity.fuelType,
            )}
          />
        ))}
    </Wrapper>
  )
}
