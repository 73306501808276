import React from 'react'
import { useLocation } from 'react-router-dom'

import { Main } from './AppLayout.styles'
import { isVesselPage } from '../../utils'
import TopNav from '../TopNav/TopNav'
import { SidebarNav } from '../SidebarNav/SidebarNav'
import { VesselPageContextProvider } from '../../contexts'
import { FilterContextProvider } from '../SidebarNav/VesselFilterContext/VesselFilterContext'
import { HDCContextProvider } from '../../features/hybrid-data-collector/context'

type Props = {
  children?: React.ReactNode
}

const AppLayout = (props: Props) => {
  const location = useLocation()

  return (
    <>
      <TopNav />
      <FilterContextProvider>
        <SidebarNav />
      </FilterContextProvider>
      <Main>
        {isVesselPage(location.pathname) ? (
          <VesselPageContextProvider>
            <HDCContextProvider>{props.children}</HDCContextProvider>
          </VesselPageContextProvider>
        ) : (
          props.children
        )}
      </Main>
    </>
  )
}

export default AppLayout
