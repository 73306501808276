export interface ConfigurationResponse {
  imoNo: number
  hasTwoMainEngines: boolean
  flowMeterScenario: string
  fuelLineTypes: number[]
  hasOverflow: boolean
  hasManualOverflowEntry: boolean
  itPlatform: string
  isOutOfOperation: boolean
  outOfOperationReason: string
  maxSpeed: number
  mainEng1: MainEng
  mainEng2: MainEng
  whr: Whr
  shaftMotor1: Shaft
  shaftMotor2: Shaft
  shaftGenerator1: Shaft
  shaftGenerator2: Shaft
  auxEngs: AuxEng[]
  vesselClass: string
  vesselName: string
}

interface AuxEng {
  number: number
  isInstalled: boolean
  isSmall: boolean
  mcr: number
  maxPower: number
  minLoad: number
  hasEconomizer: boolean
}

interface MainEng {
  number: number
  isInstalled: boolean
  mcr: number
  maxSetpoint: number
  maxShaftRpm: number
  hasTcco: boolean
  tccoLimit: number
  hasAuxBlowers: boolean
  auxBlowersLimit: number
  auxBlowersPower: number
}

interface Shaft {
  number: number
  isInstalled: boolean
  maxPower: number
  efficiency: number
}

interface Whr {
  isInstalled: boolean
  maxPower: number
  steamTurbineLimit: number
  powerTurbineLimit: number
}

export interface ConnectionStatusResponse {
  vessel: Vessel
  lastLogSeconds: number
  lastLogText: string
  lastLogStatus: number
  lastContactSeconds: number
  lastContactText: string
  lastContactStatus: number
}

interface Vessel {
  imoNo: number
  papiVersion: string
}
