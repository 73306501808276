import * as React from 'react'
import ReactTooltip from 'react-tooltip'
import styled from '../../../../theme'
import { LabReport } from './MixedBatchDetails'
import { UNITS } from '../../../../utils/constants'

interface LabReportTooltipProps {
  toolTipId: string
  labReport?: LabReport
  batchDisplayName: string
}

const TooltipWrapper = styled.span`
  font-weight: 400;
`

const TooltipLabel = styled.span`
  display: inline-block;
  min-width: 90px;
`

export class LabReportTooltip extends React.Component<
  LabReportTooltipProps,
  any
> {
  render() {
    const { toolTipId, labReport, batchDisplayName } = this.props
    return (
      <ReactTooltip id={toolTipId}>
        <TooltipWrapper>
          {batchDisplayName}
          <hr></hr>
          {labReport?.marpolSeal && (
            <div>
              <TooltipLabel>Marpol seal:</TooltipLabel>
              <span>{labReport?.marpolSeal}</span>
            </div>
          )}
          <div>
            <TooltipLabel>Density 15:</TooltipLabel>
            <span>
              {labReport?.density15} {UNITS.DENSITY_FIFTEEN}
            </span>
          </div>
          <div>
            <TooltipLabel>LCV:</TooltipLabel>
            <span>
              {labReport?.lcv} {UNITS.KJ_PER_KILO}
            </span>
          </div>
          <div>
            <TooltipLabel>Water:</TooltipLabel>
            <span>
              {labReport?.water} {UNITS.PERCENTAGE_VOLUME}
            </span>
          </div>
          <div>
            <TooltipLabel>Ash:</TooltipLabel>
            <span>
              {labReport?.ash} {UNITS.MASS}
            </span>
          </div>
          <div>
            <TooltipLabel>Sulphur:</TooltipLabel>
            <span>
              {labReport?.sulphur} {UNITS.MASS}
            </span>
          </div>
        </TooltipWrapper>
      </ReactTooltip>
    )
  }
}
