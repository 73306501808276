import * as React from 'react'
import { power, shaftRpm, stw } from '../themes'
import { Performance } from '../../../api-models'
import { EChartOption, init } from 'echarts'
import { grey } from '../../../theme'
import {
  areaGraphPadding,
  areaYMinDomain,
  calculateYDomainAverages,
  lineGraphPadding,
  lineYMinDomain,
  stripDecimals,
} from '../../../utils'
import { Chart } from '../../../utils/models'
import {
  ACTIVATE_ZOOM_ACTION,
  AXIS_LABEL,
  AXIS_LINE,
  AXIS_SPLIT_LINE,
  AXIS_TICK,
  DATA_ZOOM_TIMELINE,
  GRID,
  TOOLBOX_TIMELINE,
  X_AXIS_TIMELINE,
} from '../../../utils/constants'

enum SeriesName {
  'shaftPower',
  'shaftRpm',
}

/**
 *  Counter intuitive, when we are choosing the constant series,
 *  it is actually the opposite series we want to highlight.
 *  To make this easier to think about,
 *  I added this mode object to defined the different mode states
 *  */
const MODES = {
  constantRpm: {
    shaftRpmLineOpacity: 0.2,
    powerLineOpacity: 1,
    shaftRpmYAxis: 2, //inactive axis
    powerYAxis: 1, //active axis
    activeSeries: SeriesName.shaftPower,
    inactiveSeries: SeriesName.shaftRpm,
  },
  constantPower: {
    shaftRpmLineOpacity: 1,
    powerLineOpacity: 0.2,
    shaftRpmYAxis: 1, //active axis
    powerYAxis: 2, //inactive axis
    activeSeries: SeriesName.shaftRpm,
    inactiveSeries: SeriesName.shaftPower,
  },
}

export type Mode = 'constantRpm' | 'constantPower'

export interface SpeedThroughWaterChartProps {
  data: Performance.Trim.Monitor
  currentMode: Mode
  showStw: boolean
  showPower: boolean
  showShaftRpm: boolean
  tooltipFormatter: EChartOption.Tooltip.Formatter
  hasTwoMainEngines?: boolean
}

export interface SpeedThroughWaterChartState {
  startValue?: number
  endValue?: number
}

export class SpeedThroughWaterChart extends React.Component<
  SpeedThroughWaterChartProps,
  SpeedThroughWaterChartState
> {
  private chartNode: React.RefObject<HTMLDivElement>
  private chart?: Chart

  constructor(props: SpeedThroughWaterChartProps) {
    super(props)

    this.state = {}
    this.chartNode = React.createRef()
  }

  getYSeries = (data: Performance.Trim.Monitor, currentMode: SeriesName) => {
    const ySeriesData =
      currentMode === SeriesName.shaftPower ? data.shaftPower : data.shaftRpm
    return data.timestamps
      ? data.timestamps.map((timestamp, index) => {
          return [timestamp, ySeriesData[index]]
        })
      : []
  }

  getSeries = (
    data: Performance.Trim.Monitor,
    currentMode: SeriesName,
  ): (number | null)[] => {
    const seriesData =
      currentMode === SeriesName.shaftPower ? data.shaftPower : data.shaftRpm
    return data.timestamps ? seriesData : []
  }

  getOptions = (
    data: Performance.Trim.Monitor,
    currentMode: Mode,
  ): EChartOption | any => {
    const { showPower, showShaftRpm, showStw } = this.props
    const powerSeries = this.getYSeries(data, SeriesName.shaftPower)
    const rpmSeries = this.getYSeries(data, SeriesName.shaftRpm)
    return {
      xAxis: {
        ...X_AXIS_TIMELINE,
        min: data.queryPeriod.from,
        max: data.queryPeriod.to,
      },
      yAxis: [
        {
          id: 'stw',
          type: 'value',
          axisTick: AXIS_TICK,
          axisLabel: {
            formatter: (value: any) => stripDecimals(value, 1),
            ...AXIS_LABEL,
          },
          axisLine: { ...AXIS_LINE, onZero: true },
          splitLine: AXIS_SPLIT_LINE,
        },
        {
          id: 'activeSeries',
          type: 'value',
          axisTick: AXIS_TICK,
          axisLabel: {
            formatter: (value: any) => stripDecimals(value, 1),
            ...AXIS_LABEL,
          },
          axisLine: AXIS_LINE,
          splitLine: {
            show: false,
          },
        },
        {
          id: 'inactiveScale',
          show: false,
          type: 'value',
          axisTick: { show: false },
          axisLabel: {
            show: false,
          },
          axisLine: AXIS_LINE,
          splitLine: {
            show: false,
          },
        },
      ],
      grid: GRID,
      toolbox: TOOLBOX_TIMELINE,
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'line',
        },
        textStyle: {
          color: grey[50],
        },
        backgroundColor: 'rgba(50,50,50,0.9)',
        formatter: this.props.tooltipFormatter,
      },
      dataZoom: [
        DATA_ZOOM_TIMELINE,
        {
          type: 'select',
          yAxisIndex: 0,
        },
        {
          type: 'select',
          yAxisIndex: 1,
        },
        {
          type: 'select',
          yAxisIndex: 2,
        },
      ],
      series: [
        {
          animation: false,
          name: 'stw',
          type: 'line',
          symbol: 'none',
          lineStyle: {
            color: stw.stroke,
            opacity: showStw ? 1 : 0,
          },
          areaStyle: {
            normal: {
              opacity: showStw ? 1 : 0,
            },
          },
          yAxisIndex: 0,
          data: data.timestamps
            ? data.timestamps.map((timestamp, index) => {
                return [timestamp, data.stw[index]]
              })
            : [],
        },
        {
          animation: false,
          name: 'shaftRpm',
          type: 'line',
          symbol: 'none',
          showSymbol: false,
          lineStyle: {
            color: shaftRpm.stroke,
            opacity: showShaftRpm ? MODES[currentMode].shaftRpmLineOpacity : 0,
          },
          yAxisIndex: MODES[currentMode].shaftRpmYAxis,
          data: rpmSeries,
        },
        {
          animation: false,
          name: 'power',
          type: 'line',
          symbol: 'none',
          showSymbol: false,
          lineStyle: {
            color: power.stroke,
            opacity: showPower ? MODES[currentMode].powerLineOpacity : 0,
          },
          yAxisIndex: MODES[currentMode].powerYAxis,
          data: powerSeries,
        },
      ],
      visualMap: [
        {
          show: false,
          calculable: false,
          type: 'piecewise',
          pieces: [
            {
              min: 0,
              max: 1000,
              color: stw.fill,
              colorAlpha: 0.2,
            },
          ],
        },
      ],
    }
  }

  zoomIn = (startValue: number, endValue: number) => {
    const { data, currentMode } = this.props
    if (this.chart) {
      const averageDomains = calculateYDomainAverages(startValue, endValue, {
        timestamps: data.timestamps,
        stw: data.stw,
        activeSeries: this.getSeries(data, MODES[currentMode].activeSeries),
        inactiveSeries: this.getSeries(data, MODES[currentMode].inactiveSeries),
      })
      const stwDomain = areaGraphPadding(
        areaYMinDomain(averageDomains['stw'], 1),
      )
      const activeSeriesDomain = lineGraphPadding(
        lineYMinDomain(averageDomains['activeSeries'], 1),
      )
      const inactiveSeriesDomain = lineGraphPadding(
        lineYMinDomain(averageDomains['inactiveSeries'], 1),
      )
      const xBatch = { id: 'timestamp', startValue, endValue }
      this.setState({ startValue, endValue })
      this.chart.dispatchAction(
        {
          type: 'dataZoom',
          batch: [
            xBatch,
            {
              id: 'stw',
              dataZoomIndex: 1,
              startValue: stwDomain.min,
              endValue: stwDomain.max,
            },
            {
              id: 'activeSeries',
              dataZoomIndex: 2,
              startValue: activeSeriesDomain.min,
              endValue: activeSeriesDomain.max,
            },
            {
              id: 'inactiveSeries',
              dataZoomIndex: 3,
              startValue: inactiveSeriesDomain.min,
              endValue: inactiveSeriesDomain.max,
            },
          ],
        },
        true,
      )
    }
  }

  zoomOut = (silent?: boolean) => {
    if (this.chart) {
      this.chart.dispatchAction(
        {
          type: 'dataZoom',
          start: 0,
          end: 100,
        },
        !!silent,
      )
    }
    this.setState({ startValue: undefined, endValue: undefined })
  }

  componentDidMount() {
    const { data, currentMode } = this.props
    if (this.chartNode.current) {
      const chart = init(this.chartNode.current) as Chart
      chart.setOption(this.getOptions(data, currentMode))
      chart.dispatchAction(ACTIVATE_ZOOM_ACTION)
      this.chart = chart
    }
  }

  componentDidUpdate(prevProps: SpeedThroughWaterChartProps) {
    const { currentMode, data } = this.props
    const { startValue, endValue } = this.state
    if (prevProps.currentMode !== currentMode && this.chart) {
      this.chart.setOption(this.getOptions(data, currentMode))
      if (startValue && endValue) {
        this.zoomIn(startValue, endValue)
      }
    }
    if (
      this.chart &&
      (prevProps.showPower !== this.props.showPower ||
        prevProps.showShaftRpm !== this.props.showShaftRpm ||
        prevProps.showStw !== this.props.showStw)
    ) {
      this.chart.setOption(this.getOptions(data, currentMode))
    }
  }

  public render() {
    return (
      <div
        onDoubleClick={() => {
          this.zoomOut(false)
        }}
        id='stw-voyage'
        ref={this.chartNode}
      />
    )
  }
}
