import { useCallback, useContext, useEffect, useState } from 'react'
import { Field, Form, Formik } from 'formik'
import Moment from 'moment'
import { v4 as uuidv4 } from 'uuid'
import * as Yup from 'yup'
import { McButton } from '@maersk-global/mds-react-wrapper'

import {
  delManualCorrectionOverwrite,
  getConsumedMassInPeriod,
  putManualCorrectionOverwrite,
} from '../../../../services/performance'
import { ManualCorrectionOverwrite } from '../../../../api-models/performance/manual-corrections'
import { displayErrorModal } from '../../../../utils'
import { VesselPageContext } from '../../../../contexts'
import {
  DateTimeInput,
  FormSelect,
  Loading,
  Modal,
  TextArea,
} from '../../../../commons'
import * as S from './style'
import { ConsumptionField, ConsumptionPerHour } from '../overwrite-form/style'
import TotalizerModal from '../totalizer-modal'
import { amountPerHour, zeroSecondsDate } from '../../utils/fuel-consumption'
import HDCInputField from '../../../hybrid-data-collector/components/hdc-input-field'
import { UNITS } from '../../../../utils/constants'

type TReason = { value: number; label: string }

type Props = {
  visible: boolean
  handleClose: () => void
  refreshData: Function
  data: ManualCorrectionOverwrite
}

export const EditOverwriteModal = ({
  visible,
  handleClose,
  refreshData,
  data,
}: Props) => {
  const imoNo = useContext(VesselPageContext).imoNo!
  const [totalizerActive, setTotalizerActive] = useState(false)
  const [calculatedConsumption, setCalculatedConsumption] = useState<
    number | null
  >(null)

  const initialValues = {
    startTimestamp: zeroSecondsDate(data.startTimestamp),
    endTimestamp: zeroSecondsDate(data.endTimestamp),
    fuelLineType: data.fuelLineType,
    consumedMass: data.consumedMass,
    reason: data.reason,
    notes: data.notes,
  }

  const getCalculatedConsumption = useCallback(
    async (startDate: string, endDate: string, fuelLineType: number | null) => {
      if (!startDate || !endDate || fuelLineType === null) {
        return null
      }
      const response = await getConsumedMassInPeriod(
        imoNo,
        { from: startDate, to: endDate },
        fuelLineType,
      )
      return response.hasData ? response.consumedMass : null
    },
    [imoNo],
  )

  useEffect(() => {
    getCalculatedConsumption(
      initialValues.startTimestamp,
      initialValues.endTimestamp,
      initialValues.fuelLineType,
    ).then((calculatedConsumedMass) =>
      setCalculatedConsumption(calculatedConsumedMass),
    )
  }, [
    getCalculatedConsumption,
    initialValues.endTimestamp,
    initialValues.fuelLineType,
    initialValues.startTimestamp,
  ])

  const handleSubmit = async (values, helpers) => {
    try {
      await putManualCorrectionOverwrite(imoNo, data.id, {
        ...values,
        fuelLineType: data.fuelLineType,
      })
      refreshData()
    } catch (err) {
      void displayErrorModal({
        statusText: 'Failed to update overwrite',
        message: err.message,
      })
    } finally {
      helpers.setSubmitting(false)
    }
  }

  const handleDelete = async () => {
    await delManualCorrectionOverwrite(imoNo, data.id)
    handleClose()
    refreshData()
  }

  // Data loss reasons
  const reasons: TReason[] = [
    { value: 10, label: 'Calibrating flowmeters to zero (Constant)' },
    { value: 11, label: 'Unexpected leaks (Factor)' },
    { value: 15, label: 'Flushing (Constant)' },
    { value: 20, label: 'Inaccurate measurement (Factor)' },
    { value: 25, label: 'Defect flowmeter (Constant)' },
  ]

  // Formik validation schema
  const validationSchema = Yup.object().shape({
    startTimestamp: Yup.date().required('Please provide a start time'),
    endTimestamp: Yup.date().required('Please provide an end time'),
    consumedMass: Yup.number()
      .typeError('Please provide consumed mass')
      .required('Please provide consumed mass'),
    reason: Yup.mixed().required('Please provide a reason'),
    fuelLineType: Yup.mixed().required('Please select fuel line'),
    notes: Yup.string().max(1000, 'Limit of 1000 characters exceeded'),
  })

  return (
    <Modal
      title={data.readonly ? 'View overwrite' : 'Edit overwrite'}
      visible={visible}
      closeHandler={handleClose}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({
          values,
          touched,
          errors,
          handleChange,
          setFieldValue,
          isSubmitting,
        }) => (
          <>
            <Form>
              <S.Container>
                {isSubmitting && (
                  <S.Loading>
                    <Loading />
                  </S.Loading>
                )}
                <S.Wrapper>
                  <S.Header>Repaired period</S.Header>
                  <S.Label>Overwrite from</S.Label>
                  <Field
                    value={values.startTimestamp}
                    name='startTimestamp'
                    component={(props) => (
                      <DateTimeInput
                        id={uuidv4()}
                        {...props}
                        selected={Moment.utc(values.startTimestamp)}
                      />
                    )}
                    disabled
                  />

                  <S.Label>Overwrite to</S.Label>
                  <Field
                    value={values.endTimestamp}
                    name='endTimestamp'
                    component={(props) => (
                      <DateTimeInput
                        id={uuidv4()}
                        {...props}
                        selected={Moment.utc(values.endTimestamp)}
                        minDate={Moment.utc(values.startTimestamp).add(10, 'm')}
                        onChange={async (val) => {
                          const newDate = val.toISOString()
                          setFieldValue('endTimestamp', newDate)
                          const calculatedConsumedMass =
                            await getCalculatedConsumption(
                              values.startTimestamp,
                              newDate,
                              values.fuelLineType,
                            )
                          if (calculatedConsumedMass !== null) {
                            setCalculatedConsumption(calculatedConsumedMass)
                            setFieldValue(
                              'consumedMass',
                              calculatedConsumedMass,
                            )
                          }
                        }}
                      />
                    )}
                    disabled={data.readonly}
                  />

                  {!data.readonly && (
                    <S.Button>
                      <McButton
                        type='button'
                        variant='outlined'
                        label='Totalizer at specific time'
                        fit='medium'
                        icon='cog'
                        click={() => setTotalizerActive(true)}
                      />
                    </S.Button>
                  )}

                  <S.Label>Insert comment</S.Label>
                  <TextArea
                    name='notes'
                    value={values.notes}
                    onChange={handleChange}
                    disabled={data.readonly}
                  />
                  {touched.notes && errors.notes && (
                    <S.ErrorLabel>{errors.notes}</S.ErrorLabel>
                  )}

                  <S.Header>Consumption & Reason</S.Header>
                  <ConsumptionField>
                    <HDCInputField
                      fit='medium'
                      name='consumedMass'
                      type='number'
                      label='Insert est. consumption'
                      decimals={3}
                      addon={UNITS.METRIC_TON}
                      error={errors.consumedMass}
                      note={
                        calculatedConsumption !== null &&
                        values.consumedMass !== calculatedConsumption
                          ? `Original value was ${calculatedConsumption}`
                          : ' '
                      }
                    />
                    {!!values.consumedMass && (
                      <ConsumptionPerHour>
                        {`${amountPerHour(
                          values.startTimestamp,
                          values.endTimestamp,
                          values.consumedMass,
                        )} MT/h`}
                      </ConsumptionPerHour>
                    )}
                  </ConsumptionField>

                  <S.Label>Choose a reason</S.Label>
                  <Field
                    component={FormSelect}
                    name='reason'
                    options={reasons}
                  />
                  {touched.reason && errors.reason && (
                    <S.ErrorLabel>{errors.reason}</S.ErrorLabel>
                  )}
                </S.Wrapper>
                <S.Actions>
                  {data.readonly && (
                    <McButton
                      label='Ok'
                      appearance='primary'
                      click={handleClose}
                    />
                  )}

                  {!data.readonly && (
                    <>
                      <McButton
                        label='Save'
                        appearance='primary'
                        type='submit'
                      />
                      <McButton
                        label='Delete'
                        appearance='error'
                        click={handleDelete}
                        type='button'
                      />
                    </>
                  )}
                </S.Actions>
              </S.Container>
            </Form>

            <TotalizerModal
              handleClose={() => setTotalizerActive(false)}
              visible={totalizerActive}
              timestamp={values.endTimestamp}
            />
          </>
        )}
      </Formik>
    </Modal>
  )
}

export default EditOverwriteModal
