import moment from 'moment'

import { CheckboxWrapper, DateTimeWrapper } from '../Diagnostics.styles'
import Checkbox from '../../../formik/Checkbox/Checkbox'
import { getOperationalCheckboxLabel } from './Operational.consts'
import { FormInputDateTime } from '../../../formik'

type Props = Diagnostics.StepComponentProps

const Operational = ({ alert, isReadonly }: Props) => {
  const issues = alert.operationalIssues.filter((issue) => issue.hasIssue)

  if (issues.length === 0) {
    return <p>There are no operational issues to resolve.</p>
  }

  return (
    <>
      <p>
        In order to mitigate the excess fuel consumption, please take following
        actions:
      </p>
      {issues.map((issue, idx) => (
        <CheckboxWrapper key={issue.name}>
          <label htmlFor={`operationalIssues.items.${issue.name}`}>
            <span>{idx + 1}</span>
            <div>{issue.message}</div>
          </label>
          <Checkbox
            name={`operationalIssues.items.${issue.name}`}
            label={getOperationalCheckboxLabel(issue.name)}
            disabled={isReadonly}
          />
        </CheckboxWrapper>
      ))}
      <hr />
      <p>When do you expect to carry out the actions?</p>
      <DateTimeWrapper>
        <FormInputDateTime
          name='operationalIssues.resolvedBy'
          label='Date and time'
          min={moment.utc()}
          disabled={isReadonly}
        />
      </DateTimeWrapper>
    </>
  )
}

export default Operational
