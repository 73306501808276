import React from 'react'
import { VesselPageContext } from '../contexts'

const getWithVesselPageContext = (
  WrappedComponent: React.ComponentClass<any> | React.FC<any>,
) => {
  return class extends React.Component<any, {}> {
    render() {
      return (
        <VesselPageContext.Consumer>
          {(context) => <WrappedComponent {...this.props} {...context} />}
        </VesselPageContext.Consumer>
      )
    }
  }
}

export default getWithVesselPageContext
