import React, { useContext } from 'react'
import { McIcon } from '@maersk-global/mds-react-wrapper'
import { McInputDate } from '@maersk-global/mds-react-wrapper'
import moment from 'moment'

import { WindowContext } from '../../contexts'
import { TWindowSize } from '../../theme_new/styled'

export type DateFieldProps = React.InputHTMLAttributes<any> & {
  hasWarning?: boolean
  isInvalid?: boolean
  error?: string
  label?: string
  icon?: string
  note?: string
  fit?: TWindowSize
  dateStart: string
  dateEnd: string
}

const DateField = ({
  hasWarning = false,
  isInvalid,
  error,
  label,
  icon,
  note,
  fit,
  ...inputProps
}: DateFieldProps) => {
  const { windowSize } = useContext(WindowContext)

  const resolveHint = () => {
    if (note === ' ') {
      return <>&nbsp;</>
    }
    return note
  }

  const date = inputProps.value
    ? moment(inputProps.value.toString(), ['DD/MM/YYYY', 'YYYY-MM-DD']).format(
        'DD/MM/YYYY',
      )
    : ''

  return (
    <McInputDate
      fit={fit || windowSize}
      name={inputProps.id}
      {...(icon ? { icon: icon, iconposition: 'right' } : {})}
      {...(!label ? { hiddenlabel: true } : {})}
      value={date}
      format='DD/MM/YYYY'
      customize={[
        {
          date: {
            from: moment('1400-01-01', 'YYYY-MM-DD').calendar(),
            to: inputProps.dateStart,
          },
          disabled: true,
        },
        {
          date: {
            from: inputProps.dateEnd,
            to: moment('5000-01-01', 'YYYY-MM-DD').calendar(),
          },
          disabled: true,
        },
      ]}
      nextlabel='Next month'
      previouslabel='Previous month'
      placeholder={inputProps.placeholder}
      // @ts-ignore
      blur={inputProps.onBlur}
      // @ts-ignore
      focus={inputProps.onFocus}
      invalid={isInvalid}
      disabled={inputProps.disabled}
      errormessage={error}
    >
      {label && (
        <span slot='label'>
          {label}&nbsp;
          {hasWarning && (
            <McIcon
              className='mc-icon--warning'
              icon='exclamation-triangle-solid'
              size={windowSize === 'small' ? '20' : '24'}
            />
          )}
        </span>
      )}
      <span slot='hint'>{resolveHint() || <>&nbsp;</>}</span>
    </McInputDate>
  )
}

export default DateField
