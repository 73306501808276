import { Performance } from '../../../../../api-models'
import { Loading } from '../../../../../commons'
import styled from '../../../../../theme'
import { FuelType } from '../../../../../utils'
import { BatchSelectionDisplay } from './BatchSelectionDisplay'

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  & > div:not(:last-child) {
    margin-right: 16px;
  }
`

const getDisplayNameForSelectedBatch = (
  batchSelections: Performance.FuelOilStock.CurrentBatchSelectionResponse[],
  fuelType: FuelType,
): string | undefined =>
  batchSelections.find((selection) => selection.fuelType === fuelType)
    ?.displayName

type Props = {
  onClick: (fuelType: FuelType) => void
  batchSelections:
    | Performance.FuelOilStock.CurrentBatchSelectionResponse[]
    | null
}

export const BatchSelections = ({ onClick, batchSelections }: Props) => {
  if (!batchSelections) return <Loading width='unset' />
  return (
    <Wrapper>
      <BatchSelectionDisplay
        fuelType={FuelType.HS}
        batchName={getDisplayNameForSelectedBatch(batchSelections, FuelType.HS)}
        onClick={onClick}
      />
      <BatchSelectionDisplay
        fuelType={FuelType.VLS}
        batchName={getDisplayNameForSelectedBatch(
          batchSelections,
          FuelType.VLS,
        )}
        onClick={onClick}
      />
      <BatchSelectionDisplay
        fuelType={FuelType.ULS}
        batchName={getDisplayNameForSelectedBatch(
          batchSelections,
          FuelType.ULS,
        )}
        onClick={onClick}
      />
      <BatchSelectionDisplay
        fuelType={FuelType.MDO}
        batchName={getDisplayNameForSelectedBatch(
          batchSelections,
          FuelType.MDO,
        )}
        onClick={onClick}
      />
    </Wrapper>
  )
}
