import moment from 'moment'
import { useContext } from 'react'

import { getDataQualityChecks } from './Technical.utils'

import {
  CheckboxWrapper,
  ErrorWrapper,
  DateTimeWrapper,
} from '../Diagnostics.styles'
import Checkbox from '../../../formik/Checkbox/Checkbox'
import { Error, FormInputDateTime } from '../../../formik'
import { VesselPageContext } from '../../../../contexts'
import { useVesselConfiguration } from '../../../../hooks'

type Props = Diagnostics.StepComponentProps

const Technical = ({ alert, isReadonly }: Props) => {
  const imoNo = useContext(VesselPageContext).imoNo!
  const configuration = useVesselConfiguration(imoNo)

  const filteredDataQualityChecks = getDataQualityChecks(
    configuration?.hasManualOverflowEntry,
  )

  const renderTechnicalIssues = () => {
    if (alert.technicalIssues.length < 1) {
      return null
    }

    return (
      <>
        <p>
          To mitigate the excess fuel consumption, please troubleshoot the
          following warnings from your latest engine test report:
        </p>
        {alert.technicalIssues.map((issue, idx) => (
          <CheckboxWrapper key={issue.name}>
            <label htmlFor={`technicalIssues.items.${issue.name}`}>
              <span>{idx + 1}</span>
              <div>{issue.message}</div>
            </label>
            <Checkbox
              name={`technicalIssues.items.${issue.name}`}
              label='Resolved'
              disabled={isReadonly}
            />
          </CheckboxWrapper>
        ))}
        <ErrorWrapper>
          <Error name='technicalIssues.itemsError' />
        </ErrorWrapper>
        <hr />
      </>
    )
  }

  return (
    <>
      {renderTechnicalIssues()}
      <p>
        Please confirm the following data quality concerns have been addressed:
      </p>
      {filteredDataQualityChecks.map((item, idx) => (
        <CheckboxWrapper key={item.id}>
          <label htmlFor={`technicalIssues.dataQuality.${item.id}`}>
            <span>{idx + 1}</span>
            <div>{item.label}</div>
          </label>
          <Checkbox
            name={`technicalIssues.dataQuality.${item.id}`}
            label='Yes'
            disabled={isReadonly}
          />
        </CheckboxWrapper>
      ))}
      <ErrorWrapper>
        <Error name='technicalIssues.dataQualityError' />
      </ErrorWrapper>
      <hr />
      <p>
        When do you expect to have resolved the warnings and addressed the data
        quality concerns?
      </p>
      <DateTimeWrapper>
        <FormInputDateTime
          name='technicalIssues.resolvedBy'
          label='Date and time'
          min={moment.utc()}
          disabled={isReadonly}
        />
      </DateTimeWrapper>
    </>
  )
}

export default Technical
