import { Performance } from '../../api-models'
import { formatValue } from '../../utils'
import { FilterConfig } from '../../features/filter'

export function mapVesselStatusToTrimChartData(
  vesselStatus: Performance.Status.Status,
): TrimChartData {
  return {
    trimDegrees: vesselStatus.draught?.trimDegrees ?? null,
    aftDraught: vesselStatus.draught?.aft ?? null,
    meanDraught: vesselStatus.draught?.mean ?? null,
    foreDraught: vesselStatus.draught?.fore ?? null,
    trim: vesselStatus.draught?.trim ?? null,
    isHogging: vesselStatus.draught?.isHogging ?? null,
    hullDeflection: vesselStatus.draught?.hullDeflection ?? null,
    waterDepth: vesselStatus.voyage?.waterDepth ?? null,
  }
}

export const getUnderKeelValue = (waterDepth: number | null): string => {
  if (waterDepth === null) {
    return '-'
  }

  if (waterDepth >= 200) return '> 200 m'
  if (waterDepth < 0) return '-'
  return `${formatValue(waterDepth, 1)} m`
}

export const filtersConfig: FilterConfig = {
  dateRange: {
    show: true,
    restrictNumberOfDays: 60,
    selections: [
      { value: 24, timeUnit: 'hours' },
      { value: 48, timeUnit: 'hours' },
      { value: 7, timeUnit: 'days' },
      { value: 14, timeUnit: 'days' },
      { value: 30, timeUnit: 'days' },
    ],
    canSelectMinuteSpecific: false,
  },
  numberOfDigits: {
    show: false,
    selections: [],
  },
  chartSmoothing: {
    show: false,
  },
}
