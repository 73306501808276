import { green, yellow, red, grey } from '../../theme'

interface TextDescription {
  [key: string]: string
}

export const getHelpText: (key: string) => string = (key) =>
  HELP_TEXT[key] || ''

// * = &#8226;
// \n * =</li><li>;

export const HELP_TEXT = {
  'filter-panel': `
    <li>This is the filter panel where you can customize the amount of data shown in the content of the page.</li>
    <li>The quick selects are always choosing up to current date and time, I.e. 14 days will show the last 14 days from the current time.</li>
    <li>There is also the opportunity to select a to and from date and time, please remember that the values are being calculated on a 10-minute basis, thus please select 10-minute intervals whenever possible.</li>
    <li>Clearing filters will reset to the default for the page.</li>`,
  'vessels/overview-table': `
    <li>This page is only visible for shore users of SC.</li>
    <li>In this table you see relevant instantaneous values for the vessels you've selected in your list. </li>
    <li>Instantaneous values are always from the last data package received, the time icon beside the vessel's name displays the time from "the last log received" for each vessel.</li>
    <li>Data sources are the mass flow meters (for fuel consumption data) and CAMS/ICS (for all other parameters), collected every 10min.</li>
    <li>The parameters shown are considered the most relevant for SC version 1, but as new vessel features are built within SC we will continually improve the page and add more parameters.</li>`,
  'vessel/fuel-consumption': `
    <li>On this graph you see the total consumption of the ME, AEs and BLR by fuel type (HS, ULS, VLS & MDO), plotted over time. Color coding is by fuel type.</li>
    <li>To see specifics about consumption per consumer, please check the fuel consumption page.</li>
    <li>Instantaneous values shown to the right of the graph are always the latest data points received.</li>
    <li>Data source are the mass flow meters, collected every 10 min.</li>`,
  'live-trim': `
    <li>This item provides and overview of the  last data points received on trim status, sagging/hogging, draught & under keel clearance.</li>
    <li>Data sources are the draught sensors & echo sounder, collected every 10min.</li>
    <li>We know that some vessels are experiencing problems with the draught sensors. We are currently working on options to improve trim estimate accuracy.</li>`,
  'consumption-selection': `
    <li>In this window, you can select which fuel is being used.</li>
    <li>On the right side, you can select which fuel type each consumer is burning.</li>
    <li>On the left side, you can select which batch of each fuel type is being consumed. That should ideally correspond to what you have in your settling tanks.</li>
    <li>In the top right corner, there is the button “Show log”. It will open a list of all events related to stock and consumption.</li>`,
  'consumption-selection/batch': `
    <li>Here you can change which fuel batch you are transferring from storage to service/settling tank(s).</li>
    <li>Time for batch change is when you open the valve to transfer the new batch.</li>
    <li>If the current batch has not been depleted, you need to fill in how much is remaining in the service/settling tank of the batch.</li>
    <li>Note – you can only select batches that have a lab report.</li>`,
  'consumption-selection/fuel-type': `
    <li>This is where selections are made for what fuel type that is currently consumed by each fuel line. Please fill in the timestamp for the change of fuel type.</li>
    <li>Be aware that when selecting change over e.g. at 10:30 it covers the period from 10:20 to 10:30.</li>
  `,
  'counter/fuelconsumptiontable': `
    <li>This table provides an overview of the fuel consumption for a specified period and can be used for daily reporting of fuel consumption into MSPS.</li>
    <li>The consumed fuel is split into consumers (ME, AE and BLR) and fuel types (HS, ULS, VLS and MDO).</li>
    <li>The predefined interval reflects the data points received over the last 24 hours, if data availability allowed.</li>
    <li>This interval can be adjusted by selecting a new period (date and time) and reapplying the filter.</li>
    <li>All the data displayed in the table is calculated based on totalizer measurements from the flow meters, cut off values and applying the consumption logic calculations on top.</li>
    <li>The number of error-free data in relation to the total number of observations in a selected period, is displayed as data availability percentage in the last column. Be mindful of counter readings where the data availability is below 100% as the calculated consumption may be associated with a higher degree of uncertainty.</li>
    <li>The estimated "ME leakage" that is deducted from the consumption is shown at the top right of the table.</li><li>The sludge that is deducted from the stock, together with the consumption, is shown at the top right of the table.</li>`,
  'counter/mainengpowertable': `
    <li>Average ME power in selected period.</li>
    <li>ME running hours in selected period.</li><li>Average RPM in selected period.</li>
    <li>If TCCO is on more than 80% in selected period.</li>`,
  'counter/totalpowerconsumptiontable': `
    <li>On this table you will find electrical power consumption in kWh and Running hours for the selected period. (For consumers that is tracked in MSPS)</li>
    <li>Values are calculated from CAMS. Please evaluate the values against local instruments. If discrepancy is noticed,  values from local instruments is the one to report in MSPS. (see manual for details)</li>`,
  'counter/totalpowerproductiontable': `
    <li>On this table you will find all electrical generators, their electrical production in kWh, and their running hours, in selected period.</li>
    <li>Values are calculated from CAMS data. Please evaluate the values against local instruments. If discrepancy is noticed, values from local instruments is the one to report in MSPS. (see manual for details)</li>`,
  'fuel-consumption/main-engines': `
    <li>On this graph you can see the consumption of your main engine.</li>
    <li>The graph is colored based on which fuel type is being consumed.</li>
    <li>When you press the fuel buttons in the top left corner (HS, VLS, ULS, MDO), you can toggle on/off based on fuel type. </li>
    <li>On the top left, you will also find a button called “Density 15”. Pressing this will give you a line on the graph with the density measured in the fuel line, corrected to 15 degrees Celsius. This is meant to assist you in fuel changeovers.</li>
    <li>At the bottom, you will find a bar (no! not that kind of bar!) that indicates the batch being burned. When selecting a new batch, the bar will indicate “blending”. This shows the period from when you select the new batch until we start consuming it. This should correspond to the amount in the service/settling tank. It is a theoretical calculation and is only used for stock purposes. </li>
    <li>When you select a new fuel type but not a batch, “error” will be shown in the bar. You need to go to stock management and select “change batch”.</li>
    <li>Under the graph, you can open a “correction bar”. That will show if any data losses have occurred and if they are already fixed.</li>
    <li>You can also see if manual overwrites have been entered. That will be marked with  blue color.</li>
    <li>The consumption graph shows the edited version of the data.</li>`,
  'fuel-consumption/auxiliary-engines': `
    <li>On this graph, you can see the total consumption of your auxiliary engines.</li>
    <li>The graph is colored based on which fuel type is being consumed.</li>
    <li>When you press the fuel buttons in the top left corner (HS, VLS, ULS, MDO), you can toggle on/off based on fuel type. </li>
    <li>On the top left, you will also find a button called “Density 15”. Pressing this will give you a line on the graph with the density measured in the fuel line, corrected to 15 degrees Celsius. This is meant to assist you in fuel changeovers.</li>
    <li>At the bottom, you will find some bars that indicates the batch being burned. When selecting a new batch, the bars will indicate “mixing period”. This shows the period from selecting new batch until we start consuming it. This should correspond to the amount in service/settling tank. It is a theoretical calculation and is only used for stock purpose.</li>
    <li>When you select a new fuel type but not a batch, “error” will be shown in the bar. You need to go to stock management and select “change batch”.</li>
    <li>There is a batch indicator bar for each fuel line.</li>
    <li>Under the graph, you can open a “correction bar”. That will show if any data losses have occurred and if they are already fixed.</li>
    <li>You can also see if manual overwrites have been entered. That will be marked with  blue color.</li>
    <li>The consumption graph shows the edited version of the data.</li>`,
  'fuel-consumption/boiler': `
    <li>On this graph you see the consumption of your boiler.</li>
    <li>The graph is colored based on which fuel type is being consumed.</li>
    <li>When you press the fuel buttons in the top left corner (HS, VLS, ULS, MDO), you can toggle on/off based on fuel type. </li>
    <li>On the top left, you will also find a button called “Density 15”. Pressing this will give you a line on the graph with the density measured in the fuel line, corrected to 15 degrees Celsius. This is meant to assist you in fuel changeovers.</li>
    <li>At the bottom, you will find a bar that indicates the batch being burned. When selecting a new batch, the bar will indicate “mixing period”. This shows the period from selecting new batch until we start consuming it. This should correspond to the amount in service/settling tank. It is a theoretical calculation and is only used for stock purpose.</li>
    <li>When you select a new fuel type but not a batch, “error” will be shown in the bar. You need to go to stock management and select “change batch”.</li>
    <li>There is a batch indicator bar for each fuel line.</li>
    <li>Under the graph, you can open a “correction bar”. That will show if any data losses have occurred and if they are already fixed.</li>
    <li>You can also see if manual overwrites have been entered. That will be marked with  blue color.</li>
    <li>The consumption graph shows the edited version of the data.</li>`,
  'fuel-consumption/overwrite': `
    <li>
      There are 2 types of overwrite: “constant” and “factor”.<br/>
      A constant overwrite will give you an average consumption over full period selected.<br/>
      A factor overwrite will offset measured consumption over the full period selected.<br/>
      The reason code determines which overwrite is used.
    </li>
    <li>Zero calibration = Constant<br/>
      Temporarily leakage = Factor<br/>
      Flushing = Constant<br/>
      Inaccurate measurement = Factor<br/>
      Defect flowmeter = Constant
    </li>
  `,
  'propulsion/maineng-rpm': `
    <li>This Gauge is showing the ME RPM at the timestamp shown in the upper right corner.</li>
    <li>This data is picked up from the torque meter through CAMS/ISC.</li>`,
  'propulsion/shaft-motor': `
    <li>This Gauge is showing the amount of MW being consumed by the Shaft Motor at the timestamp shown in the upper right corner.</li>
    <li>This data is picked up from the Shaft motor through CAMS/ISC.</li>`,
  'propulsion/maineng-power': `
    <li>This Gauge is showing the amount of MW being produced by the Main Engine and the current %MCR at the timestamp shown in the upper right corner. </li>
    <li>This data is picked up from the Torque meter through CAMS/ISC. The data used for calculating the %MCR is from FMOT data.</li>`,
  'propulsion/sfoc': `
    <h5>…this graph has the following purpose?</h5>
    <li>This graph is supposed to aid you with evaluating the performance of your main engine over the past 90 days. All SFOC data points are LCV corrected to 42700 kJ/kg</li>
    <h5>…you can click and toggle in this plot?</h5>
    <li>Following tips apply to all vessels. For 2-ME vessels, there are additional notes a little further down.</li>
    <h5>…above the scatter plot, you have the following options?</h5>
    <li>Button <em>Nonstable</em>: We filter out points when you are maneuvering. Click this button, to see them in light grey.</li>
    <li>Button <em>Vessel specific</em>: The vessel specific SFOC baseline is toggled on by default and shown as a solid orange line. The baseline is LCV corrected to 42700 kJ/kg</li>
    <li>Button <em>Shop test</em>: The shop test is shown as a solid black line by clicking this button. You can show it together with the vessel specific curve. The shop test is LCV corrected to 42700 kJ/kg.</li>
    <h5>…below the SFOC over time plot</h5>
    <li>There is a grey miniature version of the time plot showing two weeks of data.
        Right there, you also find a slider to change the time window</li>
    <li>Click in the middle of this time slider to move the two-weeks window.</li>
    <li>You can click on the ends of the slider and move it to extend or shorten the period shown in the time plot. The scatter plot will always show 90 days.</li>
    <h5>…ships with 2 main engines:</h5>
    <li>See the mixed ME SFOC by default. You have the following additional options:</li>
    <li>Button <em>Port ME</em> and <em>Stbd ME</em>: These buttons at right side of the graph are grey. If you click on them, they become blue and
          green and the SFOC values of the two individual engines appear. Click again to remove these points again.</li>
    <li>Button <em>Mix MEs</em>: This button at right side of the graph is purple. If you click on it, it turns grey and the mixed ME SFOC points disappear. Click again to get the mixed SFOC back.</li>
    <h5>…you can get more information about the data and the logic behind the plot?</h5>
    <li>In the top right corner of StarConnect, next to the tool box, you can click on a larger question mark for more info. And of course, there is always the manual.</li>`,
  'propulsion/running-mode':
    '<li>This feature is still under development and is released to gather feedback and ideas so that we can improve this further!</li><li>The graph is showing the heavy (+) / light (-) running as a plot over time. Max period visible is 60 days.</li><li>The running mode is calculated using the RPM and Power of the main engine, plotted on top of ships propeller curve.</li><li>The running mode graph shows data for ME load &gt; 10&#37; MCR.</li>',
  'propulsion/torque-offset':
    "<li>This feature is still under development and is released to gather feedback and ideas so that we can improve this further!</li><li>This graph is showing both a dynamic and a static offset of the torque meter comparing these to the manufacturer's limits.</li><li>The limits are calculated based on the ME MCR and Max RPM and are shown with red dotted lines.</li><li>This is recalculated once a day, using the last month of data.</li><li>Please feel free to reach out to your Performance manager if you see drifting values or sudden deviations in the graphs. In all circumstances consult your performance manager before deciding on TM calibration based on the information from this graph.</li>",
  'trim/trim-visualizer': `<p>
  <li>This feature is the Calm Water Trim Tool. It indicates the fuel efficiency for different trims and mean draughts
      relative to even keel at the mean draught you enter as input.
      Both inputs (mean draught and speed through water) are entered manually.</li>
  <li>
    Trim convention used
    <ul>
      <li>Positive trim values indicate stern down trim</li>
      <li>Negative trim values indicate bow down trim</li>
    </ul>
  </li>
  <li>Colors and values
  <ul>
    <li><span style="color: ${red[500]}">Red:</span> and positive numbers indicate an increased fuel consumption compared to even keel</li>
    <li><span style="color: ${green[500]}">Green:</span> and negative numbers indicate a reduced fuel consumption compared to even keel</li>
    <li><span style="color: ${grey[500]}">Grey</span> colour and no number means that either there is no data OR that the draught/trim condition violates practical or operational constraints, for example sufficient propeller submergence.</li>
  </ul>
  </li>
  <li>The results come from towing tank experiments and computer simulations. Therefore, the influence from real
      world phenomena such as weather conditions and vessel sagging/hogging is not taken into account. The closer the operating
      condition is to ideal conditions, the better accuracy of the results. </li>
  <li>Kindly use this tool as the starting point to get guidance on the optimum trim condition for your vessel.
      If conditions allow, you are welcome to then use Speed and Power/Fuel trend in StarConnect to verify the savings and possibly finding additional potential.  </li>
  <li>For more information visit the Help Section by clicking on the ?-icon in the top right corner of the SC app. </li>
  <li>We’re currently working on improving this tool and would love your feedback. You can reach us at  VITServicedesk@maersk.com. </li>
  </p>`,
  'trim/trim-vs-maineng-consumption': `
    <li>On this graph you see trim and main engine fuel consumption plotted over time to identify the effect of trimming on the ME consumption.</li>
    <li>Instantaneous values shown to the right of the graph are always the latest data points received.</li>
    <li>Data sources are the draught sensors and the ME consumption flow meters, collected every 10min.</li>
    <li>We know that some vessels are experiencing problems with the draught sensors. We are currently working on options to improve trim estimate accuracy.</li>`,
  'trim/trim-vs-speed': `
    <li>On this graph you see the speed through water plotted against either the shaft RPM or shaft power, you can toggle between the two options at the top right of the graph.</li>
    <li>By selecting “Governor on RPM mode” you see STW vs shaft power and by selecting “Governor on power mode” you see STW vs RPM.</li>
    <li>The idea here is to “hide” the parameter that should remain relatively stable to improve visibility of the other more interesting parameter, that may change with trim changes.</li>
    <li>Shaft power = ME Power + (Shaft motor power*efficiency) - (shaft generator power /efficiency).</li>
    <li>Instantaneous values shown to the right of the graph are always the latest data points received.</li>
    <li>Data sources are the speed log, ME RPM counter and power meters on SG, SM and ME.  All sourced from CAMS/ICS, collected every 10min.</li>`,
  'system-status/table': `
    <li>This table is showing an overview of the connection to the different vessels.</li>
    <li>The green/yellow/red is showing the status of the two connectors (Green= all good, Yellow=some data missing, Red=all data missing)</li>
    <li>The last connection is showing when we last had a response from the vessel and last log received is showing the last payload received (CAMS or FM).</li>
    <li>The vessel class and IMO number is also shown</li><li>Filters/Search is possible on all columns.</li>`,
  'vessel-config/table': `
    <li>This table is showing the configuration of the vessel.</li>
    <li>It's showing what version of the performance system is currently installed on the vessel and what version is available for installation.</li>
    <li>The flow meter scenario is also shown to the user, in the future we will work on embedding drawings of this into the application.</li>`,
  'cut-off-values/table': `
    <li>This table is showing the cut off values for the different consumers on the vessel. These are applied to remove false consumption when the consumer is not running as measuring exactly 0 is quite a challenge.</li>
    <li>These are based on the size of the consumer to ensure that no actual consumption is filtered out.</li>
    <li>These cut off values are used in the consumption logic, thus a change in a value will affect the calculated fuel consumption.</li>`,
  'manage-lists/available': `
    <li>This page is used to create a user defined list of vessels.</li>
    <li>Simply find the vessels that you want to add to your list and click the + button, you can search on both vessel name, vessel class, fleet group and IMO number.</li>
    <li>You can select to see the list sorted by Fleet group or Vessel class, more will be added in the future.</li>`,
  'manage-lists/my-list': `
    <li>When you've chosen vessels for your list, simply give the list a name and click save.</li>
    <li>As a user you can add multiple lists to easy navigate between these directly from the side menu.</li>
    <li>Lists can be modified by choosing the given list in the upper right hand corner of the page and then performing the wanted action (add more vessels or delete vessels)</li>
    <li>The Fleet group, vessel class data etc. Is sourced from FMOT.</li>`,
  'electrical-balance/bars': `
    <li>This bar chart shows the electrical production at the latest received timestamp (upper right corner)</li>
    <li>On the AE bar charts you'll see a dotted line showing the recommended minimum load of the given engine. This should be adhered to whenever possible.</li>`,
  'electrical-balance/fuel-cost': `
    <li>This graph shows the fuel cost for each kWh produced and an advice for an optimized energy production and cost.</li>
    <li>The orange graph is calculated using flowmeters + kWh counters.</li><li>The green curve represents the result of our advice calculation.</li>
    <li>We have applied a filter: The advice will only show up when our system determines that the ship is in a stable condition (e.g. not maneuvering).</li>
    <li>The green band around the advice is the uncertainty band. We know about the uncertainty in our models and that it is not possible to perfectly hit a curve.</li>
    <li>The band is currently fixed to +/- 50 g/kWh – this will be updated as we get better to simulate the operational scenarios.</li>
    <li>The calculation behind the advice is the same as the one behind the energy management simulator.</li>
    <li>The shaft generator contribution is calculated as ME SFOC multiplied with the efficiency of shaft generator (95%).</li>
    <li>WHR is calculated as free energy. In some cases that is not true. We are working on a more precise calculation of the fuel penalty for utilizing WHR.</li>
    <li>The total power in the hover box is usually slightly higher than the measured total power. To make our calculation faster, we have rounded electrical power to the next ten.</li>`,
  'electrical-balance/consumption-dial': `
    <li>This pie chart is showing the electrical consumption on the vessel at latest received timestamp (upper right corner).</li>
    <li>This is showing the electrical consumption of the equipment where we have values from CAMS, the base load is calculated as "the rest". By simply subtracting the known consumption from the production.</li>`,
  'waste-heat-recovery/whr-maineng-mcr': `
    <li>This graph is showing the Waste Heat Recovery production values per % MCR.  On the right hand side you'll see an instantaneous value showing the WHR production at the latest received timestamp. The data points within the selected filter period (defaults to last 6 hours) are color coded in orange to highlight current Waste Heat Recovery.</li>
    <li>In the future an overlay of the temperature corrected WHR model will be integrated.</li>`,
  'waste-heat-recovery/mcr-dial': `
    <li>This Gauge is showing the ME RPM at the timestamp shown in the upper right corner.</li>
    <li>The cut in points for the WHR Steam Turbine and Power Turbine are shown in order to attract some attention when the %MCR is in the range of these as it should then be considered if it's feasible to speed up slightly to engage this equipment.</li>
    <li>This data is picked up from the torque meter through CAMS/ISC.</li>`,
  'waste-heat-recovery/water-temp': `<li>This is showing the temperature of the sea water.</li>`,
  'waste-heat-recovery/air-temp': `<li>This is showing the ambient (outside) air temperature.</li>`,
  'stock/rob-mark': `
    <li>This form is shown whenever We do not have enough data to determine a ROB.</li>
    <li>Reasons could be: "First start up" , "malfunction of flow meters" , "connection issues between equipment onboard"</li>`,
  'stock/rob-at-specific-time': `<li>Here you can look back and have the system calculate your ROB at a desired time.</li>`,
  'stock/stock-on-board': `
    <li>In this window, you have an overview of your current stock separated by fuel type.</li>
    <li>When you change-over Bunker/ Storage tank suction to the Transfer pump supplying the Settling Tank, you must register that change here in “Change batch”.</li>
    <li>Under each fuel type, you can see your ROB (Remaining On Board) for bunkered and mixed batches in that category. </li>
    <li>When a batch is marked as “selected”, it means that the batch is registered as selected by the system. This should correspond to what is in your service/settling tanks.</li>
    <li>Have you registered your fuel as a biofuel? This will also be visible with a small green leaf icon on the batch. </li>
    <li>On the top right corner, there is a “Show log” button, which gives you a history of all the stock entries you have made.</li>`,
  'stock/stock-on-board-light': `<li>In this window you have an overview of your current stock, separated by fuel type.</li>`,
  'stock/batch-burndown': `
    <li>In this graph, you can track the burndown for each batch.</li>
    <li>There is a separate tab for each fuel type.</li>
    <li>You can click on the “Events” tab to toggle the vertical lines on the graph on and off.</li>
    <li>Each horizontal line represents a batch that you have bunkered or mixed.</li>
    <li>Every dot on the line marks ROB for a batch which is set at midnight every day or when an event occurs.</li>
    <li>The graph has a grey/white color. The grey part of the graph shows the stock entries that are outside the 5-day open window. The white part of the graph shows the stock entries that are within the 5-day open window, which are editable.</li>
    <li>Vertical lines indicate your stock entries. If the head of the line is a circle, it is possible to click it and get a new window corresponding to that entry. </li>
    <li>If the vertical line has a square on the top, it shows that you have made a fuel type selection in the fuel consumption page.</li>
    <li>At the bottom of the graph, there is a grey bar, which allows you to select the date range of the batch burndown graph. </li>
    <li>At the top right corner, there is a button called “Show log”. That will open a window with the full list of entries. </li>`,
  'stock/show-logs': `
    <li>This shows a history of all the fuel and batch entries that you have made in the Fuel Consumption and Stock Management pages.</li>
    <li>On the right, you will either see a trashcan or a padlock icon. If the trashcan icon is present, you can delete the entry. If a padlock is displayed, it means it is locked.</li>
    <li>At the top, you can sort all columns</li>`,
  'stock/add-mixed-batch': `
    <li>In this form, it is possible to register batches mixed in the storage tanks on board. </li>
    <li>Mixed batches will have the word “MIX” in the name instead of a port code.</li>
    <li>Batch properties (lab report) are calculated based on linear relations of lab reports from all batches in the mix and MT mixed from each batch. We know that batches may not be perfectly mixed, so it might not be 100% accurate. Therefore it is possible to edit them.</li>
    <li>By clicking the calculator icon, you will get an assistant Volume to MT calculator that uses the MOT standard way of calculating.</li>`,
  'stock/bunkering': `
    <li>You must register fuel batches that you have bunkered here.</li>
    <li>“Quantity received according to C/E” is the quantity that counts in the stock module.</li>
    <li>If VLS or ULS is bunkered, you need to specify if it is a distillate (diesel oil) or a residual (fuel oil).</li>
    <li>If biofuel is bunkered, you must add the %mass of bio in the form. If %mass is above 0% we register it as a bio fuel.</li>
    <li>Lab reports should be entered as soon as available. You cannot register a “Change batch” until you have entered a lab report.</li>`,
  'stock/bunkering/view': `
    <li>Quantity ordered: is the amount of fuel stated in the bunker order confirmation.</li>
    <li>Quantity agreed with supplier: is the amount stated on the BDN.</li>
    <li>Quantity received according to C/E: Is the amount that will be added to the existing ROB</li>
    <li>Should the "Quantity agreed with supplier" and "Quantity received according to C/ E" differ, a letter of protest (LOP) should be raised, as provided in the GLSM.</li>`,
  'stock/sounding': `
    <li>A sounding sets a new ROB mark. This means that you cannot edit the stock entries that were made prior to making the sounding.</li>
    <li>Under each fuel type you will find all batches bunkered under that specific fuel type.</li>
    <li>Our ROB guess is prefilled, and you can save without changing.</li>
    <li>By clicking the calculator icon, you will get an assistant Volume to MT calculator that uses the MOT standard way of calculating. If a batch is bunkered in several tanks, you can use a new line for each batch. When pressing save, the total amount will be registered as ROB for that batch. </li>`,
  'stock/loss': `
    <li>Should you encounter an unintentional loss, this is the place to register it.</li>
    <li>For larger amounts of fuel leaked into other fuel types, please use the Mix batches form instead of this one. Large amounts in this case is when one of the 2 fuels changes properties e.g. changes fuel type (Sulphur contamination) or you as a CE judge that LCV change is so much that the SFOC will be inaccurate. The LCV calculator in the toolbox can assist you in determining this.</li>`,
  'stock/debunkering': `
    <li>This functionality is used whenever a de-bunkering is performed. </li>
    <li>The amount is deducted from the ROB</li>`,
  'vessel/sog': `<li>This gauge shows the Speed Over Ground of the vessel at the timestamp shown in the upper right hand corner.</li>`,
  'vessel/stw': `<li>This gauge shows the Speed Through Water of the vessel at the timestamp shown in the upper right hand corner.</li>`,
  'vessel-settings-form': `
    <li>This dialogue box allows you to manually enter the leakage from ME.</li>
    <li>The leakage entered should only be the amount that comes to your drain tank or overflow tank, from ME - only what is possible to transfer back to settling/service tank.</li>
    <li>Do NOT include drain from auto filters.</li><li>If you experience higher amount of leak from ME, please investigate the reason and rectify it.</li>`,
  'sensors/sensors': `
    <li>This is the very first part of the sensor module, a module that will expand in the future.</li>
    <li>For now, you see all the sensors installed on the vessel that are required for Maersk StarConnect at the moment.</li>
    <li>The current errors list is showing sensors that are having an internal error or are considered out of range. A short explanation of the error is available on the overview and by clicking the sensor you can get more information.</li>
    <li>This module targets both vessels and shore organization because we believe in the benefit of understanding when and why sensor/equipment fail.</li>
    <li>The page will grow along with the application, sharing further insights to the sensors and extending the scope of data validation. The vision for this page is to provide one place to communicate data quality and enable all relevant actors to get the information they need to find solutions to sensor and equipment failure.</li>`,
  'auxiliary-engines/sfoc-vs-power': `
    <h5>AE SFOC graph</h5>
    <li>This plot is showing the Specific Fuel Oil Consumption which is LCV (Lower Calorific Value) corrected to 42,700 kJ/kg plotted over Power. On the right-hand side, you will see an instantaneous value showing the SFOC at the timestamp shown. Latest 6 hours is shown as red dots.</li>
    <h5>Toggle buttons</h5>
    <li>AE model button(s) – you can toggle AE SFOC shop test model(s) one at a time.</li>
    <li>On these buttons it is possible to toggle each AE and the "Mixed", on/off. It will affect both the SFOC graph and the timeline.</li>
    <li>It is also possible to see if the AE has an economizer installed, This is indicated by a small icon on the button.</li>
    <h5>Power over time graph</h5>
    <li>On this graph you can see the load of each AE plotted over time.</li>
    <li>The grey window below will let you quickly toggle through the past 90 days from the selected period. It can also be used for zooming.</li>`,
  'energy-management-simulator': `
    <li>This calculator gives advice on which equipment to run to produce power in the most fuel-efficient way.</li>
    <li>The calculator does not use sensor data and is purely based on your manual input.</li>
    <li>The calculation is based on your demands for ME or propulsion power and electricity. When to use the calculator and what numbers to enter is further described in the detailed help section.</li>
    <li>If no ambient temperature is entered, 15°C is used by default to identify the appropriate WHR model curve. Vessels without WHR system don’t see a temperature input field.</li>
    <li>This version will not be able to cater for special operational conditions or malfunctioning equipment – assumption is that all equipment is functional.</li>
    <li>The logic assumes that you have to turn on a second AE, as soon as the first one reaches 80% of its max power. We know this is a bold assumption, but it ensures that we do not ask you to do something that is impossible.</li>
    <li>The logic uses MSPS models to evaluate the best combination of equipment.</li>
    <li>Feedback on the energy management calculator or special operational scenarios as well as suggestions for improvements are very much appreciated.</li>
    <li>For more details on the calculation logic or use cases of this calculator, checkout the help section in the top right corner of StarConnect.</li>`,
  'trim-simulator': `
    <li>This feature is the Calm Water Trim Simulator. It shows the fuel efficiency at a range of trims, given the mean draught and speed through water (entered manually).</li>
    <li>The vessel animation and values below it show the optimal trim configuration.</li>
        <p><span style="color: ${green[500]}">Green:</span> 0-2% more fuel use than optimal.</p>
        <p><span style="color: ${yellow[500]}">Yellow:</span> 2-7% more fuel use than optimal.</p>
        <p><span style="color: ${red[500]}">Red:</span> +7% fuel use than optimal.</p>
        <p><span style="color: ${grey[500]}">Grey</span>: Infeasible trim (due to propeller/bow thruster out of water or untested configuration).</p>
    <li>The results come from towing tank experiments done in a laboratory. Therefore, real world phenomena such as weather conditions and vessel sagging/hogging are not taken into account. The closer the operating condition is to ideal conditions, the better accuracy of the results.</li>
    <li>Multiple green sections can show up. They have similar fuel efficiency, so please choose the one that you believe is preferable in your situation.</li>
    <li>We’re currently working on improving this tool and would love your feedback. You can reach us at <a href = "mailto: VITServicedesk@maersk.com" style="color: #000; cursor: pointer;">VITServicedesk@maersk.com</a></li>`,
  'advice/tem': `
    <li>Alerts give you notifications about stock.</li>
    <li>The messages in red require urgent action and you need to correct stock.</li>
    <li>The messages in orange and blue provide important information.</li>
    <li>Our energy advice calculation is now shown as advice text. The logic has been around since the energy simulator. You can find explanations in the help section and see the calculation in the fuel cost for electrical production graph.</li>`,
  'fuel-consumption/consumers': `
    <li>You must register the fuel type being consumed by each fuel line here.</li>
    <li>You must also register the time at which the fuel line started consuming the fuel, that is when you change Service Tank suction valves between consumers ME, AE or OFB. </li>`,
  'tools/lcv-calculator': `
    <li>This tool calculate the LCV based on: Density 15, Sulphur, Water and Ash.</li>
    <li>It should be used when you must use a fuel with no lab report.</li>
    <li>Use pretest or BDN to get Density 15 and Sulphur. If Water and Ash is not available use 0.1% for both.</li>`,
  'hdc/reports-table': `
    <li>Report status will change as you progress with your report. Following indicators can be shown:
    <ul>
      <li><em>Done</em> – all done and dusted – no further changes can be made to the report</li>
      <li><em>Still editable</em> – all OK – the report has been submitted to shore, but changes can still be made if required</li>
      <li><em>Draft</em> – the report has been created but neither Bridge nor Engine section has been completed</li>
      <li><em>Engine</em> – the Engine section has not been completed</li>
      <li><em>Bridge</em> – the Bridge section has not been completed</li>
      <li><em>Edit</em> – both Bridge and Engine sections are in edit mode</li>
    </ul>
    </li>
    <li>Max period for editing a report is 15 days back from latest report date – after this day status will switch from "Still editable" to "Done"</li>`,
} as TextDescription
