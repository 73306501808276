import { type MouseEvent, useContext, useRef, useState } from 'react'
import { useHistory, useParams, useRouteMatch } from 'react-router-dom'
import { McButton } from '@maersk-global/mds-react-wrapper'
import { type McListItem } from '@maersk-global/mds-components-core'
import {
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  type Row,
  type SortingState,
  useReactTable,
} from '@tanstack/react-table'

import { getColumns, isEditDisabled } from './MalfunctioningEquipmentPage.utils'
import { MfeReportAction as Action } from './MalfunctioningEquipmentPage.consts'
import EmptyState from './EmptyState/EmptyState'
import SummaryModal from './SummaryModal/SummaryModal'
import { ContentLayout } from '../../layout'
import { ContentCard, Loading } from '../../commons'
import { PadContent } from '../../layout/styles'
import { WindowContext } from '../../contexts'
import {
  useGetReports,
  usePostReport,
} from '../../queries/GandalfApi/GandalfApi'
import { ReportType } from '../../queries/GandalfApi/GandalfApi.consts'
import { routerParams } from '../../routes'
import Table, { type ITableRef } from '../../components/Table/Table'
import DeleteReportModal from '../../components/Reporting/DeleteReportModal'
import MalfunctioningEquipmentFilters from './MalfunctioningEquipmentFilters/MalfunctioningEquipmentFilters'
import TemNotifications from '../../components/TemNotifications/TemNotifications'
import PerformanceAlerts from '../../components/PerformanceAlerts/PerformanceAlerts'

const MalfunctioningEquipmentPage = () => {
  const { windowSize } = useContext(WindowContext)
  const history = useHistory()
  const { url } = useRouteMatch()
  const { vesselId: imoNo } = useParams<routerParams>()

  const [isCreating, setIsCreating] = useState(false)
  const [selectedReportId, setSelectedReportId] = useState<string>()
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [summaryModalOpen, setSummaryModalOpen] = useState(false)
  const [sorting, setSorting] = useState<SortingState>([
    { desc: true, id: 'startTimestamp' },
  ])

  const tableRef = useRef<ITableRef>(null)

  const getReports = useGetReports<GandalfApi.Mfe.ReportData>(
    imoNo,
    ReportType.Mfe,
  )
  const postReport = usePostReport(imoNo, ReportType.Mfe)

  const handleCreateReportClick = async () => {
    setIsCreating(true)
    try {
      const payload: GandalfApi.Mfe.CreateReportRequest = {
        report: {
          reportType: ReportType.Mfe,
        },
      }
      const { reportId } = await postReport.mutateAsync(payload)
      history.push(`${url}/report/${reportId}`)
    } finally {
      setIsCreating(false)
    }
  }

  const handleRowClick = (
    row: Row<GandalfApi.MfeReport>,
    event: MouseEvent,
  ) => {
    tableRef.current?.deselectRow()

    const target = event.target as HTMLElement
    if (target.closest('mc-button, mc-list-item')) {
      /* Return if clicking on a menu button or menu item or report older than 60 days */
      return
    }

    if (isEditDisabled(row.original)) {
      setSelectedReportId(row.original.reportId)
      setSummaryModalOpen(true)
      return
    }

    history.push(`${url}/report/${row.original.reportId}`)
  }

  const handleListClick =
    (reportId: string) =>
    ({ detail }: CustomEvent) => {
      setSelectedReportId(reportId)
      const item: McListItem = detail.item

      switch (item.label) {
        case Action.View:
          setSummaryModalOpen(true)
          break
        case Action.Edit:
          history.push(`${url}/report/${reportId}`)
          break
        case Action.Delete:
          setDeleteModalOpen(true)
          break
      }
    }

  const handleDeleteModalClose = () => {
    setSelectedReportId(undefined)
    setDeleteModalOpen(false)
  }

  const handleSummaryModalClose = () => {
    setSelectedReportId(undefined)
    setSummaryModalOpen(false)
  }

  const reports = getReports.isSuccess ? getReports.data.reports : []

  const table = useReactTable<GandalfApi.MfeReport>({
    data: reports,
    columns: getColumns(windowSize, handleListClick),
    initialState: {
      pagination: { pageSize: 25 },
    },
    state: { sorting },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  })

  if (!getReports.isSuccess) {
    return <Loading />
  }

  const renderButton = (
    <McButton
      fit={windowSize}
      loading={isCreating}
      label='Report malfunction'
      click={handleCreateReportClick}
      data-e2e='add-mfe-report-button'
    />
  )

  const hasReports = reports.length !== 0
  const selectedReport = reports.find((r) => r.reportId === selectedReportId)

  return (
    <>
      <ContentLayout header='Malfunctioning equipment'>
        <TemNotifications />
        <PerformanceAlerts />
        <ContentCard
          id='mfe__reports-overview'
          title={hasReports ? 'Reports overview' : ''}
          additionalInfo={hasReports ? renderButton : null}
        >
          <PadContent>
            {hasReports ? (
              <>
                <MalfunctioningEquipmentFilters table={table} />
                <Table
                  table={table}
                  onRowSelect={handleRowClick}
                  ref={tableRef}
                />
              </>
            ) : (
              <EmptyState>{renderButton}</EmptyState>
            )}
          </PadContent>
        </ContentCard>
      </ContentLayout>
      {selectedReport && (
        <>
          <DeleteReportModal
            open={deleteModalOpen}
            onClose={handleDeleteModalClose}
            imoNo={imoNo}
            report={selectedReport}
          />
          <SummaryModal
            open={summaryModalOpen}
            onClose={handleSummaryModalClose}
            report={selectedReport}
          />
        </>
      )}
    </>
  )
}

export default MalfunctioningEquipmentPage
