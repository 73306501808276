import React from 'react'
import { FieldArray } from 'formik'

import { totalCalculator } from './ShorePowerGrandTotalTable.utils'

import { Table, Tbody, Thead, Tr } from '../../../../../commons/tables/styles'
import { DateTh, EnergyTh, PeriodTh, TimeTh, TotalTd } from '../../styles'

const ShorePowerGrandTotalTable = ({ shorePowerPeriodsFormikContext }) => {
  const getTotalSection = () => {
    totalCalculator(shorePowerPeriodsFormikContext)
    return (
      <Tr>
        <TotalTd></TotalTd>
        <TotalTd>{shorePowerPeriodsFormikContext.grandTotalEnergy}</TotalTd>
        <TotalTd>{shorePowerPeriodsFormikContext.grandTotalDays}</TotalTd>
        <TotalTd>
          {shorePowerPeriodsFormikContext.grandTotalHoursMinutes}
        </TotalTd>
      </Tr>
    )
  }

  return (
    <div>
      <Table>
        <Thead>
          <Tr>
            <PeriodTh>Shore power Total</PeriodTh>
            <EnergyTh>Energy counter [kWh]</EnergyTh>
            <DateTh>Days</DateTh>
            <TimeTh>Hours and minutes</TimeTh>
          </Tr>
        </Thead>
        <Tbody>
          <FieldArray
            name='engineRoom.shorePowerPeriods.grandTotal'
            render={() => getTotalSection()}
          />
        </Tbody>
      </Table>
    </div>
  )
}

export default ShorePowerGrandTotalTable
