import moment from 'moment'

import { getClosestTenMinute } from './time-utils'
import { type DefaultFilters } from '../features/filter'

export enum Selection {
  NONE = 0,
  PARTIAL = 1,
  ALL = 2,
}

export const getDefaultFilters = (
  amount: number,
  unit: moment.unitOfTime.DurationConstructor,
): DefaultFilters => {
  return {
    dateRange: {
      to: getClosestTenMinute(moment().utc()),
      from: getClosestTenMinute(moment().utc().subtract(amount, unit)),
    },
    numberOfDigits: 3,
  }
}

export const debounce = <T extends any[]>(
  func: (...args: T) => void,
  time: number,
) => {
  let debounceId: NodeJS.Timeout

  return function exeFunc(...args: T) {
    clearTimeout(debounceId)
    debounceId = setTimeout(() => func(...args), time)
  }
}
