import { useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import moment from 'moment'
import {
  McButton,
  McCheckbox,
  McInputDate,
  McInputTime,
  McModal,
  McOption,
  McSelect,
  McTextarea,
} from '@maersk-global/mds-react-wrapper'

import {
  DateTimeWrapper,
  Row,
  SparePartsWrapper,
} from './TemSubmitModal.styles'
import { DismissalReason, reasonConfig } from './TemSubmitModal.consts'
import {
  getDefaultValues,
  getExpectedTimeSetting,
  TEM_SUBMIT_MODAL,
} from './TemSubmitModal.utils'
import {
  DATE_FORMAT,
  DATE_TIME_FORMAT,
} from '../../formik/InputDateTime/InputDateTime.utils'
import { isShoreContext } from '../../../utils'

type Props = {
  notification: NotificationsAPI.TemAdvice
  closeHandler: () => void
  submitHandler: (data: TemSubmitModalTypes.SubmitModalInputs) => void
}

const TemSubmitModal = ({
  notification,
  closeHandler,
  submitHandler,
}: Props) => {
  const defaultValues = useRef(getDefaultValues(notification))

  const [currentReasonConfig, setCurrentReasonConfig] = useState<
    TemSubmitModalTypes.ReasonConfigType | undefined
  >(reasonConfig[defaultValues.current.reason])

  const [isSparePartsVisible, setIsSparePartsVisible] = useState<
    boolean | undefined
  >(reasonConfig[defaultValues.current.reason]?.increaseTimeFrame)

  const dismissalOptions: Array<NotificationsAPI.Tem.DismissalReason> =
    Array.from(Object.values(DismissalReason))

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    watch,
    getValues,
  } = useForm<TemSubmitModalTypes.SubmitModalInputs>({
    defaultValues: defaultValues.current,
  })

  const handleReasonChange = ({ detail }: CustomEvent) => {
    const reasonConfigObj = reasonConfig[detail.value]
    setCurrentReasonConfig(reasonConfigObj)

    if (reasonConfigObj) {
      setValue('date', '')
      setValue('time', '')
      setValue('comment', '')
      setValue('noSparePartsOnBoard', false)
      setIsSparePartsVisible(reasonConfigObj.increaseTimeFrame)
    } else {
      reset()
    }
  }

  const handleSparePartsCheckedChange = () => {
    setValue('noSparePartsOnBoard', !getValues('noSparePartsOnBoard'))
  }

  const validateDateTime = (
    _: any,
    values: TemSubmitModalTypes.SubmitModalInputs,
  ): boolean | string => {
    if (!values.reason) {
      /* We cannot validate the date/time unless we have dismissal reason first. */
      return 'Please select a reason for dismissal'
    }

    if (values.date === '' || values.time === '') {
      return false
    }

    const inputDateTime = moment.utc(
      `${values.date} ${values.time}`,
      DATE_TIME_FORMAT,
    )

    if (!inputDateTime.isValid()) {
      return `Please enter a valid date using the format ${DATE_FORMAT}`
    }

    const currentDateTime = moment.utc()
    const maxDateTime = currentDateTime.clone()

    const reasonConfigObj: TemSubmitModalTypes.ReasonConfigType =
      reasonConfig[values.reason]

    if (reasonConfigObj.maxDays !== undefined) {
      if (values.noSparePartsOnBoard) {
        maxDateTime.add(reasonConfigObj.increasedMaxDays, 'days')
      } else {
        maxDateTime.add(reasonConfigObj.maxDays, 'days')
      }
    } else {
      /* reasonConfigObj.maxHours !== undefined */
      maxDateTime.add(reasonConfigObj.maxHours, 'hours')
    }

    if (!inputDateTime.isSameOrBefore(maxDateTime)) {
      return `Date/time must not exceed ${maxDateTime.format(DATE_TIME_FORMAT)}`
    } else if (inputDateTime.isBefore(currentDateTime)) {
      return `Date/time must be in the future. Current UTC time is ${currentDateTime.format(
        DATE_TIME_FORMAT,
      )}`
    }

    return true
  }

  const { maxExpectedDate, minExpectedDate, dateLimitHint } =
    getExpectedTimeSetting(
      getValues('noSparePartsOnBoard'),
      currentReasonConfig,
    )

  return (
    <form onSubmit={handleSubmit(submitHandler)}>
      <McModal
        heading='Energy Advice'
        open
        closed={closeHandler}
        height='auto'
        width='632px'
        zindex={Number.MAX_SAFE_INTEGER}
      >
        <p>
          {notification.data.description}
          <br />
          <br />
          <em>{TEM_SUBMIT_MODAL.ADVICE_STATIC_TEXT}</em>
        </p>
        <Row>
          <McSelect
            label='Reason for dismissal'
            placeholder='Select a reason'
            {...register('reason', { required: true })}
            optionselected={handleReasonChange}
            invalid={errors?.reason?.type === 'required'}
            errormessage='Please select a reason for dismissal'
          >
            {dismissalOptions.map((option) => (
              <McOption key={option} value={option} label={option} />
            ))}
          </McSelect>
        </Row>
        {isSparePartsVisible && (
          <SparePartsWrapper>
            <McCheckbox
              {...register('noSparePartsOnBoard')}
              label='No spare parts on board'
              change={handleSparePartsCheckedChange}
              checked={watch('noSparePartsOnBoard')}
            />
          </SparePartsWrapper>
        )}
        <Row>Expected time to be back in operation</Row>
        <DateTimeWrapper>
          <McInputDate
            className='mc-input-date'
            label=''
            format={DATE_FORMAT}
            nextlabel='Next month'
            previouslabel='Previous month'
            placeholder={DATE_FORMAT}
            min={minExpectedDate}
            max={maxExpectedDate}
            hint={dateLimitHint}
            invalid={errors.date !== undefined}
            {...register('date', {
              required: true,
              validate: validateDateTime,
            })}
          >
            {errors.date && (
              <div slot='errormessage' style={{ whiteSpace: 'nowrap' }}>
                {errors.date.message || 'Please enter a valid time frame'}
              </div>
            )}
          </McInputDate>
          <McInputTime
            {...register('time', { required: true })}
            label=''
            invalid={errors.date !== undefined}
          />
        </DateTimeWrapper>
        <Row>
          <McTextarea
            label='Comment'
            placeholder='e.g. Leaking fuel injector.'
            rows={TEM_SUBMIT_MODAL.ROWS}
            maxlength={TEM_SUBMIT_MODAL.MAX_LENGTH}
            invalid={errors?.comment?.type === 'required'}
            errormessage='Please provide details'
            {...register('comment', { required: true })}
          />
        </Row>
        <McButton
          slot='primaryAction'
          dialogaction='ok'
          type='submit'
          disabled={isShoreContext()}
        >
          Submit
        </McButton>
        <McButton
          slot='secondaryAction'
          appearance='neutral'
          click={closeHandler}
          dialogaction='cancel'
        >
          Cancel
        </McButton>
      </McModal>
    </form>
  )
}

export default TemSubmitModal
