import React from 'react'

import { Icons } from '../theme'
import { isNumber } from '../utils'

interface Props
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLElement>,
    HTMLElement
  > {
  icon: string
  verticalAlign?: number
}

export const Icon = ({ icon, verticalAlign, ...restProps }: Props) => {
  if (/cv/.test(icon)) {
    const Component = Icons[icon]
    if (!!Component) {
      return (
        <Component
          style={{
            verticalAlign: isNumber(verticalAlign)
              ? `${verticalAlign}em`
              : '-.125em',
          }}
          {...restProps}
        />
      )
    }

    return (
      <i key={icon} {...restProps}>
        <span className='fal fa-not-existing' />
      </i>
    )
  }

  return (
    <i key={icon} {...restProps}>
      <span className={icon} />
    </i>
  )
}
