import { useContext, useMemo } from 'react'
import { type Table } from '@tanstack/react-table'
import { McInput, McSelectNative } from '@maersk-global/mds-react-wrapper'

import { WindowContext } from '../../../../contexts'
import { type Sensors } from '../../../../api-models/sensors'
import {
  FilterWrapper,
  ResultsCountTag,
} from '../../../../components/Table/Table.styles'
import { getFilterOptions } from '../../../../components/Table/Table.utils'
import { getConnectorOptions } from './helpers'

type Props = {
  table: Table<Sensors.SensorResponse>
}

const AllSensorsFilters = ({ table }: Props) => {
  const { windowSize } = useContext(WindowContext)

  const handleChange = (event: CustomEvent) => {
    const name = (event.target as HTMLSelectElement).name
    const column = table.getColumn(name)
    if (event.detail.selectedOptions.length === 0) {
      column!.setFilterValue(undefined)
    } else {
      column!.setFilterValue(event.detail.selectedOptions[0].value)
    }
  }

  const handleSensorNameChange = ({ target }: Event) => {
    const { value } = target as HTMLInputElement
    table.getColumn('name')!.setFilterValue(value)
  }

  const connectorOptions = useMemo(
    () => getConnectorOptions(table.getCoreRowModel().rows),
    [table],
  )

  const groupOptions = useMemo(
    () => getFilterOptions(table.getCoreRowModel().rows, 'group'),
    [table],
  )

  return (
    <FilterWrapper>
      <McInput
        className='sensor-name-filter'
        fit={windowSize}
        label='Sensor name'
        placeholder='Sensor name'
        input={handleSensorNameChange}
        clearbutton
        keepclearbuttonvisible
        data-e2e='SensorNameFilter'
      />
      <McSelectNative
        className='sensor-connector-filter'
        fit={windowSize}
        name='connector'
        label='Connector'
        placeholder='All'
        options={connectorOptions}
        change={handleChange}
        data-e2e='SensorConnectorFilter'
      />
      <McSelectNative
        className='sensor-group-filter'
        fit={windowSize}
        name='group'
        label='Group'
        placeholder='All'
        options={groupOptions}
        change={handleChange}
        data-e2e='SensorGroupFilter'
      />
      <ResultsCountTag
        label={`${table.getFilteredRowModel().rows.length} results`}
        appearance='info'
        fit={windowSize}
      />
    </FilterWrapper>
  )
}

export default AllSensorsFilters
