export const dataQualityChecks = [
  {
    id: 'shaftPowerMeterCalibrated',
    label: 'Shaft power meter calibrated within 30 days?',
  },
  {
    id: 'massFlowMeterCalibrated',
    label:
      'Mass flow meter calibrated within 30 days, or since last fuel change-over?',
  },
  {
    id: 'leakageFactorEntered',
    label: 'Correct leakage factor entered in StarConnect?',
  },
]
