import { useState, useEffect } from 'react'
import Moment from 'moment'
import { getBatches } from '../../services/performance'
import {
  mapBatchestoSelectOptions,
  fuelTypeNumberByDisplayOrder,
} from './utils'
import { BatchResponse } from '../../api-models/performance/fuel-oil-stock'

const batchByFuelTypeAndTimeCompFn = (
  batchA: BatchResponse,
  batchB: BatchResponse,
) => {
  const fuelTypeDiff = batchA.fuel.type - batchB.fuel.type
  if (fuelTypeDiff === 0) {
    const batchAUnixTimestamp = Moment.utc(batchA.timestamp).valueOf()
    const batchBUnixTimestamp = Moment.utc(batchB.timestamp).valueOf()
    /* will sort so that most recently bunkered batch comes first */
    return batchBUnixTimestamp - batchAUnixTimestamp
  } else {
    return fuelTypeNumberByDisplayOrder(batchA.fuel.type, batchB.fuel.type)
  }
}

export interface Option {
  label: string
  value: string
  fuelType: string
  isDisabled?: boolean
}

export const useBatchOptions = (
  imoNo: string,
  disableIfLabReportMissing?: boolean,
) => {
  const [batchOptions, setBatchOptions] = useState<Array<Option>>()

  useEffect(() => {
    getBatches(imoNo, undefined, true)
      .then((batches) => {
        batches.sort(batchByFuelTypeAndTimeCompFn)
        setBatchOptions(
          mapBatchestoSelectOptions(batches, disableIfLabReportMissing),
        )
      })
      .catch((e) => e.displayErrorModal(e))
  }, [imoNo, disableIfLabReportMissing])

  return batchOptions
}
