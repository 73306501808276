import { EntryType } from '../../features/stock-management/models'
import { Performance } from '../../api-models'

export enum ActionType {
  SET_CURRENT_ENTRY_TYPE,
  CLOSE_ENTRY,
  SET_FUEL_OIL_USER_ENTRIES,
}

export interface IReducerState {
  entryType?: EntryType
  entryId?: string
  fuelOilUserEntries?: Performance.FuelOilStock.UserEntry[]
}

export interface IReducerAction {
  type: ActionType
  data?: any
}

const reducer: React.Reducer<IReducerState, IReducerAction> = (
  state,
  action,
) => {
  const { data, type } = action
  switch (type) {
    case ActionType.SET_FUEL_OIL_USER_ENTRIES:
      return { ...state, fuelOilUserEntries: data }
    case ActionType.SET_CURRENT_ENTRY_TYPE:
      const { entryType, entryId } = data
      return { ...state, entryType, entryId }
    case ActionType.CLOSE_ENTRY:
      return { ...state }
    default:
      return state
  }
}

export default reducer
