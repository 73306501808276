import * as React from 'react'
import * as moment from 'moment'
import { SimpleBar, SimpleBarRefLine, NotFound, Icon } from '../../../commons'
import styled from 'styled-components'
import { red, maerskBlue, grey, sizes } from '../../../theme'
import { Performance } from '../../../api-models'
import { formatValue } from '../../../utils'
import { IVesselPageContext } from '../../../contexts'
import { withVesselPageContext } from '../../../higher-order-components'

const Wrapper = styled.div`
  display: flex;
  padding: 8px 16px 16px;
  max-width: ${sizes.full}px;
  flex-wrap: nowrap;
  justify-content: space-between;
  div {
    align-self: baseline;
  }
  svg {
    margin-top: 8px;
  }
  hr {
    border-right: 1px solid ${grey[200]};
    height: unset;
    margin: 0;
  }
  .tooltip-content {
    font-size: 12px;
    padding: 4px;
  }
`

const TooltipWrapper = styled.div`
  text-align: center;
  .header {
    padding: 4px;
    font-size: 13px;
  }
  background-color: ${grey[800]};
  color: ${grey[100]};
  z-index: 10;
`

const Economizer = styled.div`
  position: relative;
  svg {
    position: absolute;
    bottom: 2px;
    right: 10px;
    width: 10px;
    height: 18px;
  }
`

interface ElectricalProductionBarsProps extends IVesselPageContext {
  vesselConfiguration: Performance.VesselConfiguration.Configuration
  auxEngs: Array<Performance.Status.AuxEngineStatus> | null
  whr: Performance.Status.WHRStatus | null
  hasData: boolean
  isDualEngine: boolean
  shaftGenerator1: Performance.Status.ShaftStatus | null
  shaftGenerator2: Performance.Status.ShaftStatus | null
  timestamp: string
}

interface ElectricalProductionBarsState {}

class ElectricalProductionBars extends React.Component<
  ElectricalProductionBarsProps,
  ElectricalProductionBarsState
> {
  constructor(props: ElectricalProductionBarsProps) {
    super(props)

    this.state = {}
  }

  isSmallAuxEngine = (auxEng: Performance.Status.AuxEngineStatus): boolean => {
    return this.props.vesselConfiguration.auxEngs.some((configAux) => {
      return configAux.isSmall && auxEng.number === configAux.number
    })
  }

  engineHasEconomizer = (engineNbr: number): boolean => {
    const { auxEngs } = this.props.vesselConfiguration
    const engineConfig = auxEngs.find((eng) => eng.number === engineNbr)
    return engineConfig ? !!engineConfig.hasEconomizer : false
  }

  public render() {
    const {
      auxEngs,
      whr,
      hasData,
      shaftGenerator1,
      shaftGenerator2,
      isDualEngine,
      timestamp,
      vesselConfiguration,
    } = this.props
    const auxEngsConfiguration = vesselConfiguration.auxEngs
    if (!hasData || (!auxEngs && !whr))
      return <NotFound text='Could not detect any electrical production.' />
    return (
      <Wrapper>
        {!!auxEngs &&
          auxEngs.map((auxEng, index) => {
            const minLoad = auxEngsConfiguration[auxEng.number - 1]
              .minLoad as number
            const isBelowMinLoad = auxEng.load < minLoad
            return (
              <div key={index}>
                <SimpleBar
                  id={`aux-eng-bar-${auxEng.number}`}
                  key={`auxEng${auxEng.number}`}
                  percentage={auxEng.load}
                  value={formatValue(auxEng.power, 0)}
                  unit='kW'
                  barWidth={80}
                  height={this.isSmallAuxEngine(auxEng) ? '136px' : '168px'}
                  title={`AE${auxEng.number}`}
                  fill={isBelowMinLoad ? red[300] : maerskBlue[300]}
                  stroke={isBelowMinLoad ? red[500] : maerskBlue[500]}
                  label={{ fill: isBelowMinLoad ? red[500] : '#000' }}
                  tooltip={() => (
                    <TooltipWrapper>
                      <div className='header'>
                        {moment.utc(timestamp).format('DD MMM HH:mm')}
                      </div>
                      <hr />
                      <div className='tooltip-content'>
                        {formatValue(auxEng.power, 0)} kw <br />
                        {formatValue(auxEng.load, 0)} %
                      </div>
                    </TooltipWrapper>
                  )}
                >
                  <SimpleBarRefLine
                    percentage={minLoad}
                    barWidth={80}
                    dashed
                    stroke={red[500]}
                  />
                </SimpleBar>
                {this.engineHasEconomizer(auxEng.number) && (
                  <Economizer>
                    <Icon
                      icon='cv-economizer'
                      title='This AE has an economizer'
                    />
                  </Economizer>
                )}
              </div>
            )
          })}
        {(!!whr || !!shaftGenerator1) && <hr />}
        {!!whr && (
          <SimpleBar
            id='whr-bar'
            percentage={whr.load}
            value={formatValue(whr.power, 0)}
            unit={'kW'}
            title='WHR'
            height='168px'
            tooltip={() => (
              <TooltipWrapper>
                <div className='header'>
                  {moment.utc(timestamp).format('DD MMM HH:mm')}
                </div>
                <hr />
                <div className='tooltip-content'>
                  {formatValue(whr.power, 0)} kw <br />
                  {formatValue(whr.load, 0)} %
                </div>
              </TooltipWrapper>
            )}
          >
            <g />
          </SimpleBar>
        )}
        {!!shaftGenerator1 && (
          <SimpleBar
            id='sg1-bar'
            percentage={shaftGenerator1.load}
            height='168px'
            value={formatValue(shaftGenerator1.power, 0)}
            unit={'kW'}
            title={`SG ${isDualEngine ? 'Port' : ''}`}
            tooltip={() => (
              <TooltipWrapper>
                <div className='header'>
                  {moment.utc(timestamp).format('DD MMM HH:mm')}
                </div>
                <hr />
                <div className='tooltip-content'>
                  {formatValue(shaftGenerator1.power, 0)} kw <br />
                  {formatValue(shaftGenerator1.load, 0)} %
                </div>
              </TooltipWrapper>
            )}
          >
            <g />
          </SimpleBar>
        )}
        {!!shaftGenerator2 && isDualEngine && (
          <SimpleBar
            id='sg2-bar'
            percentage={shaftGenerator2.load}
            value={formatValue(shaftGenerator2.power, 0)}
            unit={'kW'}
            title='SG Stbd'
            height='168px'
            tooltip={() => (
              <TooltipWrapper>
                <div className='header'>
                  {moment.utc(timestamp).format('DD MMM HH:mm')}
                </div>
                <hr />
                <div className='tooltip-content'>
                  {formatValue(shaftGenerator2.power, 0)} kw <br />
                  {formatValue(shaftGenerator2.load, 0)} %
                </div>
              </TooltipWrapper>
            )}
          >
            <g />
          </SimpleBar>
        )}
      </Wrapper>
    )
  }
}

export default withVesselPageContext(ElectricalProductionBars)
