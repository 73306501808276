import styled from 'styled-components'

import { Loading } from '../../../commons'

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 10000000;
`

type Props = {
  spinnerSize?: number
  padding?: string
}

const OverlayLoader = ({ spinnerSize, padding }: Props) => (
  <Wrapper>
    <Loading spinnerSize={spinnerSize} padding={padding} />
  </Wrapper>
)

export default OverlayLoader
