import { useCallback, useContext, useEffect, useMemo, useState } from 'react'

import { AppContext } from '../../contexts'
import VesselsOverviewTable from '../../features/vessels-overview/VesselsOverviewTable'
import * as FrontendApi from '../../api-models/frontend'
import { getVesselsOverview } from '../../services/frontend'
import {
  defaultSortMethod,
  displayErrorModal,
  getConnectedVessels,
} from '../../utils'
import { Performance } from '../../api-models'
import { type Management } from '../../api-models/management'
import { ContentLayout } from '../../layout'
import { ContentCard, Loading } from '../../commons'
import { getInitialState } from './VesselsOverviewPage.utils'
import { useVesselsBasicInfo } from '../../queries/MasterDataApi/MasterDataApi'
import { useFavourites } from '../../queries/ManagementApi/ManagementApi'
import { getFavouriteLists } from '../../queries/ManagementApi/ManagementApi.utils'
import { mapVesselMasterDataToVesselRegistry } from '../../queries/MasterDataApi/MasterDataApi.utils'

type State = {
  imoNos?: Array<number>
  data?: Array<FrontendApi.VesselsOverviewData>
  vesselStatuses?: Array<Performance.Status.Status>
}

const VesselsOverviewPage = () => {
  const vesselsBasicInfo = useVesselsBasicInfo()
  const favourites = useFavourites()

  const favouriteLists = useMemo(() => {
    const lists: Array<Management.Favourites.FavouriteList> = []

    if (vesselsBasicInfo.isSuccess) {
      lists.push({
        name: 'All Vessels',
        vessels: mapVesselMasterDataToVesselRegistry(vesselsBasicInfo.data),
      })

      if (favourites.isSuccess) {
        lists.push(...getFavouriteLists(favourites.data, vesselsBasicInfo.data))
      }
    }

    return lists
  }, [favourites.isSuccess, vesselsBasicInfo.isSuccess])

  const { imoNos, selectedFavouriteList } = useContext(AppContext)

  const [state, setState] = useState<State>(getInitialState)

  const loadAllVesselStatuses = useCallback(
    (vessels: Management.Vessel.VesselRegistry[]) => {
      getVesselsOverview(vessels)
        .then((json: Array<FrontendApi.VesselsOverviewData>) => {
          setState((prevState) => ({
            ...prevState,
            data: json,
          }))
        })
        .catch((e) => {
          void displayErrorModal(e)
        })
    },
    [],
  )

  const loadVesselStatuses = useCallback(() => {
    if (selectedFavouriteList === undefined || !imoNos) return

    const vesselImoNos =
      favouriteLists.length > 0
        ? favouriteLists[selectedFavouriteList].vessels.map(
            (vessel) => vessel.imoNo,
          )
        : []

    if (vesselImoNos.length > 0) {
      const connectedVessels = getConnectedVessels(
        favouriteLists[selectedFavouriteList].vessels,
        imoNos,
      )
      connectedVessels.sort((a, b) =>
        defaultSortMethod(a.vesselName, b.vesselName, false),
      )
      const vesselsToFetch = connectedVessels.slice(
        0,
        Math.min(18, connectedVessels.length),
      )

      getVesselsOverview(vesselsToFetch)
        .then((json: Array<FrontendApi.VesselsOverviewData>) => {
          setState((prevState) => ({
            ...prevState,
            data: populateData(json, connectedVessels),
          }))
        })
        .then(() => {
          if (connectedVessels.length > vesselsToFetch.length) {
            loadAllVesselStatuses(connectedVessels)
          }
        })
        .catch((e) => {
          void displayErrorModal(e)
        })
    }
  }, [loadAllVesselStatuses, favouriteLists, imoNos, selectedFavouriteList])

  useEffect(() => loadVesselStatuses(), [loadVesselStatuses])

  const populateData = (
    json: Array<FrontendApi.VesselsOverviewData>,
    allVessels: Management.Vessel.VesselRegistry[],
  ) => {
    const data = [...json]
    return data.concat(
      allVessels
        .filter((x) => !data.find((y) => y.imoNo === x.imoNo))
        .map((x) => ({
          imoNo: x.imoNo,
          vesselName: x.vesselName,
          sog: 0,
          stw: 0,
          auxEngConsumption: 0,
          boilerConsumption: 0,
          mainEng1Consumption: null,
          mainEng1Power: null,
          mainEng1ShaftRpm: null,
          mainEng2Consumption: null,
          mainEng2Power: null,
          mainEng2ShaftRpm: null,
          trim: 0,
          meanDraught: 0,
        })),
    )
  }

  return (
    <ContentLayout header='Vessel overview'>
      <ContentCard
        id='vessels-overview'
        title='Vessel overview'
        helpTextKey='vessels/overview-table'
      >
        {!state.data && <Loading />}
        {!!state.data && <VesselsOverviewTable data={state.data} />}
      </ContentCard>
    </ContentLayout>
  )
}

export default VesselsOverviewPage
