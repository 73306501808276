import { FilterConfig } from '../../features/filter'

export const filtersConfig: FilterConfig = {
  dateRange: {
    show: true,
    restrictNumberOfDays: 90,
    selections: [
      { value: 24, timeUnit: 'hours' },
      { value: 48, timeUnit: 'hours' },
      { value: 7, timeUnit: 'days' },
      { value: 14, timeUnit: 'days' },
      { value: 30, timeUnit: 'days' },
    ],
    canSelectMinuteSpecific: false,
  },
  numberOfDigits: {
    show: false,
    selections: [],
  },
  chartSmoothing: {
    show: false,
  },
}

export const resolveInstanceNumbers = (
  staticModel: MasterDataApi.StaticModels.StaticModel<MasterDataApi.StaticModels.AuxEngStaticModelData>,
  assignments: Array<
    MasterDataApi.StaticModels.Assignment<MasterDataApi.StaticModels.AuxEngInstance>
  >,
) => {
  return assignments
    .filter((assignment) => assignment.staticModelId === staticModel.id)
    .map((assignment) => assignment.instanceNo)
    .sort((a, b) => a - b) // Enforce invariant: Instance numbers are sorted low to high
}

export const sortModelsByInstanceNumbers = (
  modelA: AuxEngPage.StaticModel,
  modelB: AuxEngPage.StaticModel,
) => {
  const instanceA = modelA.instanceNumbers[0]
  const instanceB = modelB.instanceNumbers[0]

  if (instanceA < instanceB) return -1
  if (instanceA > instanceB) return 1

  return 0
}
