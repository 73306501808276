import { type Table } from '@tanstack/react-table'

import { getEquipmentName } from '../../../components/PerformanceAlerts/PerformanceAlerts.utils'

export const getEquipmentOptions = (
  table: Table<NotificationsAPI.PerformanceAlert>,
  isTwinEngine: boolean,
) => {
  return Array.from(
    new Set(
      table.getCoreRowModel().rows.map((row) => {
        const { type, data } = row.original

        return getEquipmentName(type, isTwinEngine ? data.instance : undefined)
      }),
    ),
  ).map((equipment) => ({
    label: equipment,
    value: equipment,
  }))
}
