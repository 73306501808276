import * as React from 'react'
import styled, { IColorScheme, grey } from '../theme'

const FancyLabelWrapper = styled.div<IColorScheme>`
  margin-bottom: 8px;
  border-radius: 4px;
  padding: 2px 4px;
  line-height: 1.6;
  font-size: 10px;
  width: fit-content;
  min-width: 32px;
  text-align: center;
  min-height: 10px;
  border: 1px solid ${(scheme) => scheme.stroke};
  background-color: ${(scheme) => scheme.fill};
  color: ${(scheme) => scheme.font};

  &[role='checkbox'] {
    cursor: pointer;
  }

  &.inactive {
    border: 1px solid ${grey[500]};
    background-color: ${grey[200]};
    color: ${grey[700]};

    &.fill {
      background: -moz-linear-gradient(
        left,
        ${grey[200]} 20%,
        ${grey[200]} 20%
      );
      background: -webkit-gradient(
        left top,
        right top,
        color-stop(20%, ${grey[200]}),
        color-stop(20%, ${grey[200]})
      );
      background: -webkit-linear-gradient(
        left,
        ${grey[200]} 20%,
        ${grey[200]} 20%
      );
      background: -o-linear-gradient(left, ${grey[200]} 20%, ${grey[200]} 20%);
      background: -ms-linear-gradient(left, ${grey[200]} 20%, ${grey[200]} 20%);
      background: linear-gradient(to right, ${grey[200]} 20%, ${grey[200]} 20%);
      border: 1px solid ${grey[400]};
      color: ${grey[600]};
    }
  }
  &.fill {
    background: -moz-linear-gradient(
      left,
      ${(scheme) => scheme.stroke} 20%,
      ${(scheme) => scheme.fill} 20%
    );
    background: -webkit-gradient(
      left top,
      right top,
      color-stop(20%, ${grey[500]}),
      color-stop(20%, ${grey[200]})
    );
    background: -webkit-linear-gradient(
      left,
      ${(scheme) => scheme.stroke} 20%,
      ${(scheme) => scheme.fill} 20%
    );
    background: -o-linear-gradient(
      left,
      ${(scheme) => scheme.stroke} 20%,
      ${(scheme) => scheme.fill} 20%
    );
    background: -ms-linear-gradient(
      left,
      ${(scheme) => scheme.stroke} 20%,
      ${(scheme) => scheme.fill} 20%
    );
    background: linear-gradient(
      to right,
      ${(scheme) => scheme.stroke} 20%,
      ${(scheme) => scheme.fill} 20%
    );
  }
`

interface FancyLabelProps {
  colorScheme: IColorScheme
  value?: boolean
  onChange?: (event: React.MouseEvent<HTMLDivElement>) => void
  className?: string
  hasFill?: boolean
  style?: { [key: string]: string }
  children?: React.ReactNode
}

export class FancyLabel extends React.PureComponent<FancyLabelProps, any> {
  public render() {
    const { onChange, value, children, hasFill, colorScheme, className } =
      this.props
    const isInActive = `${value ? '' : 'inactive'} ${hasFill ? 'fill' : ''}`
    if (onChange) {
      return (
        <FancyLabelWrapper
          {...colorScheme}
          onClick={onChange}
          role='checkbox'
          className={isInActive}
          style={{ ...this.props.style }}
        >
          {children}
        </FancyLabelWrapper>
      )
    }
    return (
      <FancyLabelWrapper role='label' {...colorScheme} className={className}>
        {children}
      </FancyLabelWrapper>
    )
  }
}
