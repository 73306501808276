import ReactTooltip from 'react-tooltip'
import { css } from 'styled-components'
import { McIcon } from '@maersk-global/mds-react-wrapper'

import { StatusTypeEnum } from './ReportStatus.consts'
import styled, { green, red } from '../../../../theme'

const Status = styled.div<{
  color?: string
  hasTooltip?: boolean
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${({ color }) => (color ? color : '#000000')};

  mc-icon::part(icon) {
    fill: ${({ color }) => (color ? color : '#000000')};
  }

  small {
    line-height: 13px;
  }

  ${(props) =>
    props.hasTooltip &&
    css`
      &:hover {
        color: #ff9b1e;

        svg {
          color: #ff9b1e;
        }

        mc-icon::part(icon) {
          fill: #ff9b1e;
        }
      }
    `}
`

type Props = {
  status: StatusTypeEnum
  id: number
}

export const ReportStatus = ({ status, id }: Props) => {
  return (
    <>
      <ReactTooltip
        className='star-connect'
        id={`report-status-tooltip-${id}`}
      />
      {StatusTypeEnum.DONE === status && (
        <Status
          color={green[500]}
          hasTooltip
          data-tip='Report is submitted'
          data-for={`report-status-tooltip-${id}`}
        >
          <McIcon icon='check-circle' />
          <small>Done</small>
        </Status>
      )}
      {StatusTypeEnum.EDITABLE === status && (
        <Status
          color={green[500]}
          hasTooltip
          data-tip='Report is submitted & editable'
          data-for={`report-status-tooltip-${id}`}
        >
          <McIcon icon='check-circle' />
          <small>Still editable</small>
        </Status>
      )}
      {StatusTypeEnum.BRIDGE_INCOMPLETE === status && (
        <Status
          color='#979797'
          hasTooltip
          data-tip='Bridge is still missing to complete report'
          data-for={`report-status-tooltip-${id}`}
        >
          <McIcon icon='binoculars' />
          <small>Bridge</small>
        </Status>
      )}
      {StatusTypeEnum.ENGINE_ROOM_INCOMPLETE === status && (
        <Status
          color='#979797'
          hasTooltip
          data-tip='Engine room is still missing to complete report'
          data-for={`report-status-tooltip-${id}`}
        >
          <McIcon icon='propellor' />
          <small>Engine</small>
        </Status>
      )}
      {StatusTypeEnum.DRAFT === status && (
        <Status
          color='#979797'
          hasTooltip
          data-tip='Report is in draft mode'
          data-for={`report-status-tooltip-${id}`}
        >
          <McIcon icon='check-circle' />
          <small>Draft</small>
        </Status>
      )}
      {StatusTypeEnum.EDIT === status && (
        <Status
          color='#979797'
          hasTooltip
          data-tip='Report is in edit mode'
          data-for={`report-status-tooltip-${id}`}
        >
          <McIcon icon='pencil' />
          <small>Edit</small>
        </Status>
      )}
      {StatusTypeEnum.INVALID === status && (
        <Status
          color={red[700]}
          hasTooltip
          data-tip='Report is invalid. Please fix it and submit before proceeding to other reports'
          data-for={`report-status-tooltip-${id}`}
        >
          <McIcon icon='exclamation-circle' />
          <small>Invalid</small>
        </Status>
      )}
      {StatusTypeEnum.UNKNOWN === status && (
        /* This shouldn't really happen, but if it does, it lets us know there is an unexpected state of a report that we need to look into */
        <Status color='#000000'>
          <small>Unknown</small>
        </Status>
      )}
    </>
  )
}
