import { useMemo } from 'react'
import { getCoreRowModel, useReactTable } from '@tanstack/react-table'

import { getColumns } from './MainEngine.utils'
import Table from '../../../../components/Table/Table'
import { Performance } from '../../../../api-models'
import { MAIN_ENGINE_NAMES } from '../../../../utils/constants'

type Props = {
  mainEngDetails: Performance.Counter.MainEngDetails
  configuration: Performance.VesselConfiguration.Configuration
}

const MainEngine = ({ mainEngDetails, configuration }: Props) => {
  const data: Array<CounterPage.MainEngine.TableModel> = useMemo(
    () =>
      mainEngDetails.engines.map((engine) => {
        const cellFeedback = {
          ...(engine.power.dataAvailability < 100 ? { power: 'danger' } : {}),
          // prettier-ignore
          ...(engine.runningMinutes.dataAvailability < 100 ? { runningMinutes: 'danger' } : {}),
          // prettier-ignore
          ...(engine.shaftRpm.dataAvailability < 100 ? { shaftRpm: 'danger' } : {}),
          // prettier-ignore
          ...(engine.tccoPercentage.dataAvailability < 100 ? { tccoPercentage: 'danger' } : {}),
        }

        return {
          engineName: configuration.hasTwoMainEngines
            ? engine.number === 1
              ? MAIN_ENGINE_NAMES.Port
              : MAIN_ENGINE_NAMES.Stbd
            : MAIN_ENGINE_NAMES.Main,
          power: engine.power,
          runningMinutes: engine.runningMinutes,
          shaftRpm: engine.shaftRpm,
          tccoPercentage: engine.tccoPercentage,
          cellFeedback,
        }
      }),
    [mainEngDetails, configuration],
  )

  const table = useReactTable({
    data,
    columns: getColumns(configuration),
    getCoreRowModel: getCoreRowModel(),
  })

  return <Table table={table} hidePagination showFooter />
}

export default MainEngine
