import { getDifference } from './EnginePerformanceTestReportsComparison.utils'
import { formatValue } from '../../../utils'
import React from 'react'

type Props = {
  reportsCompared: Array<GandalfApi.MetcReport>
}

const GeneralOperatingConditions = ({ reportsCompared }: Props) => {
  const a = reportsCompared[0].data
  const b = reportsCompared[1].data

  return (
    <>
      <tr>
        <th>Operating conditions</th>
        <th />
        <th />
        <th />
      </tr>
      <tr>
        <td>Engine shaft power [kW]</td>
        {reportsCompared.map((rc) => (
          <td key={rc.reportId} className='mds-table__cell--number'>
            {rc.data.general.enginePower.value}
          </td>
        ))}
        <td className='mds-table__cell--number'>
          {getDifference(
            a.general.enginePower.value!,
            b.general.enginePower.value!,
          )}
        </td>
      </tr>
      <tr>
        <td>Engine load [%MCR]</td>
        {reportsCompared.map((rc) => (
          <td key={rc.reportId} className='mds-table__cell--number'>
            {formatValue(rc.data.result.general.engineLoad, 2)}
          </td>
        ))}
        <td className='mds-table__cell--number'>
          {getDifference(
            a.result.general.engineLoad!,
            b.result.general.engineLoad!,
          )}
        </td>
      </tr>
      <tr>
        <td>Engine RPM</td>
        {reportsCompared.map((rc) => (
          <td key={rc.reportId} className='mds-table__cell--number'>
            {formatValue(rc.data.general.shaftRpm.value, 0)}
          </td>
        ))}
        <td className='mds-table__cell--number'>
          {getDifference(a.general.shaftRpm.value!, b.general.shaftRpm.value!)}
        </td>
      </tr>
      <tr>
        <td>Engine running hours</td>
        {reportsCompared.map((rc) => (
          <td key={rc.reportId} className='mds-table__cell--number'>
            {formatValue(rc.data.general.engineRunningHours.value, 0)}
          </td>
        ))}
        <td className='mds-table__cell--number'>
          {getDifference(
            a.general.engineRunningHours.value!,
            b.general.engineRunningHours.value!,
          )}
        </td>
      </tr>
      {reportsCompared[0].data.equipment.hasTcco && (
        <tr>
          <td>TCCO operation</td>
          {reportsCompared.map((rc) => (
            <td key={rc.reportId} className='mds-table__cell--number'>
              {rc.data.general.tccoEngaged.value ? 'Engaged' : 'Not engaged'}
            </td>
          ))}
          <td className='mds-table__cell--number'>-</td>
        </tr>
      )}
      <tr>
        <td>Auxiliary blowers</td>
        {reportsCompared.map((rc) => (
          <td key={rc.reportId} className='mds-table__cell--number'>
            {rc.data.general.auxBlowersOn.value ? 'Engaged' : 'Not engaged'}
          </td>
        ))}
        <td className='mds-table__cell--number'>-</td>
      </tr>
      {reportsCompared[0].data.equipment.hasWhr && (
        <tr>
          <td>Exhaust gas bypass valve position [%]</td>
          {reportsCompared.map((rc) => (
            <td key={rc.reportId} className='mds-table__cell--number'>
              {formatValue(rc.data.general.exhaustBypassPosition.value, 0)}
            </td>
          ))}
          <td className='mds-table__cell--number'>
            {getDifference(
              a.general.exhaustBypassPosition.value!,
              b.general.exhaustBypassPosition.value!,
            )}
          </td>
        </tr>
      )}
    </>
  )
}

export default GeneralOperatingConditions
