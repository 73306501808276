import * as colors from './colors'
import { IColorScheme, IThemeInterface } from './'
import { info, warningTheme, errorTheme } from './themes'

export const lightBlueColorScheme: IColorScheme = {
  fill: colors.maerskBlue[100],
  font: colors.maerskBlue[900],
  stroke: colors.maerskBlue[500],
}

export const lightOrangeColorScheme: IColorScheme = {
  fill: colors.orange[100],
  font: colors.orange[900],
  stroke: colors.orange[500],
}

export const tealColorScheme: IColorScheme = {
  fill: colors.seaGreen[100],
  font: colors.seaGreen[900],
  stroke: colors.seaGreen[500],
}

export const purpleColorScheme: IColorScheme = {
  fill: colors.purple[100],
  font: colors.purple[900],
  stroke: colors.purple[500],
}

export const mariTimeColorScheme: IColorScheme = {
  fill: colors.maritimeBlue[100],
  font: colors.maritimeBlue[900],
  stroke: colors.maritimeBlue[500],
}

export const whiteColorScheme: IColorScheme = {
  fill: '#fff',
  font: '#000',
  stroke: colors.grey[500],
}

export const greenColorScheme: IColorScheme = {
  fill: colors.green[100],
  font: colors.green[900],
  stroke: colors.green[500],
}

export const seaGreenColorScheme: IColorScheme = {
  fill: colors.seaGreen[100],
  font: colors.seaGreen[900],
  stroke: colors.seaGreen[500],
}

export const greyColorScheme: IColorScheme = {
  fill: colors.grey[100],
  font: colors.grey[900],
  stroke: colors.grey[500],
}

export const darkGreyColorScheme: IColorScheme = {
  fill: colors.grey[400],
  font: colors.grey[900],
  stroke: colors.grey[800],
}

export const redColorScheme: IColorScheme = {
  fill: colors.red[100],
  font: colors.red[900],
  stroke: colors.red[500],
}

export const blackColorScheme: IColorScheme = {
  fill: 'black',
  font: 'white',
  stroke: colors.grey[800],
}

export const FuelColors: { [id: string]: IColorScheme } = {
  HS: mariTimeColorScheme,
  VLS: purpleColorScheme,
  ULS: lightBlueColorScheme,
  MDO: lightOrangeColorScheme,
  MM: greenColorScheme,
}

export const StockNotificationColors: { [id: number]: IThemeInterface } = {
  1: info,
  2: warningTheme,
  3: errorTheme,
}
