import {
  grey,
  maerskBlue,
  fillOpacityColor,
  seaGreen,
  orange,
  maritimeBlue,
  purple,
} from '../../theme'

export const consumption = {
  stroke: grey[200],
  fill: '#fff',
  font: '#000',
}

export const normal = {
  stroke: '#e0e0e0',
  fill: '#fff',
  font: '#000',
}

export const unselected = {
  stroke: grey[500],
  fill: fillOpacityColor(grey[500], 0.2),
  font: '#000',
}

export const auxEng1 = {
  stroke: maerskBlue[500],
  fill: fillOpacityColor(maerskBlue[500], 0.2),
  font: '#000',
}
export const auxEng2 = {
  stroke: maritimeBlue[500],
  fill: fillOpacityColor(maritimeBlue[500], 0.2),
  font: '#000',
}
export const auxEng3 = {
  stroke: orange[500],
  fill: fillOpacityColor(orange[500], 0.2),
  font: '#000',
}

export const auxEng4 = {
  stroke: seaGreen[700],
  fill: fillOpacityColor(seaGreen[500], 0.2),
  font: '#000',
}

export const auxEng5 = {
  stroke: purple[500],
  fill: fillOpacityColor(purple[500], 0.2),
  font: '#000',
}
export const auxMix = {
  stroke: grey[500],
  fill: fillOpacityColor(grey[500], 0.2),
  font: '#000',
}

export const auxMixInvisible = {
  stroke: grey[500],
  fill: fillOpacityColor(grey[500], 0.1),
  font: '#000',
}
