import styled from 'styled-components'
import { McTag } from '@maersk-global/mds-react-wrapper/components-core/mc-tag'
import { McTooltip } from '@maersk-global/mds-react-wrapper'

import { resolveAppearance } from '../../EnginePerformanceTestPage/EnginePerformanceTestReportPage/ResultsTab/RangeValidationTag'
import { formatValue } from '../../../utils'

type Props = {
  efficiencyResult: GandalfApi.Metc.EfficiencyResult
  precision: number
}

const EfficiencyResultCell = ({ efficiencyResult, precision }: Props) => (
  <Wrapper>
    {renderTag(
      efficiencyResult.rangeValidation,
      efficiencyResult.modelDeviation,
    )}
    <span>
      {formatValue(efficiencyResult.isoConditionAdjustedValue, precision)}
    </span>
  </Wrapper>
)

export default EfficiencyResultCell

const renderTag = (
  rangeValidation: GandalfApi.Metc.RangeValidation,
  modelDeviation: GandalfApi.Metc.EfficiencyResult['modelDeviation'],
) => {
  if (rangeValidation === null) {
    return null
  }

  const { appearance, icon } = resolveAppearance(rangeValidation)

  return (
    <McTooltip appearance='neutral-inverse' position='top-center'>
      <McTag slot='trigger' appearance={appearance} hiddenlabel icon={icon} />
      <span> Deviation to shoptest: {formatValue(modelDeviation, 2)}%</span>
    </McTooltip>
  )
}

export const Wrapper = styled.div`
  display: flex;
  gap: 8px;
  justify-content: flex-end;

  > span {
    width: 24%;
  }
`
