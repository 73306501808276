import {
  type CellContext,
  createColumnHelper,
  type FilterFn,
} from '@tanstack/react-table'
import moment from 'moment/moment'
import {
  McButton,
  McIcon,
  McList,
  McListItem,
  McMenu,
  McTooltip,
  McTag,
} from '@maersk-global/mds-react-wrapper'

import { MfeReportAction as Action } from './MalfunctioningEquipmentPage.consts'
import { dateFilter } from '../ActivityLogPage/ActivityLogFilters/DateFilter/helpers'
import { type TWindowSize } from '../../theme_new/styled'
import { Header, IconCell } from '../../components/Table/Table.styles'
import { ReportSubmittedTableCell } from '../../components/Reporting/ReportStateTableCell'
import {
  MfeStatus,
  ReportState,
} from '../../queries/GandalfApi/GandalfApi.consts'

const columnHelper = createColumnHelper<GandalfApi.MfeReport>()

export const isEditDisabled = (report: GandalfApi.MfeReport): boolean => {
  const { startTimestamp, endTimestamp, reportState } = report
  const status = report.data.status

  if (
    reportState !== ReportState.Submitted ||
    status === MfeStatus.Ongoing ||
    status === MfeStatus.OutOfService
  ) {
    return false
  }

  const now = moment.utc()
  const then = status === MfeStatus.Completed ? endTimestamp : startTimestamp

  return moment(now).diff(then, 'days', true) > 60
}

export const getColumns = (
  windowSize: TWindowSize,
  onListItemClick: (reportId: string) => (event: CustomEvent) => void,
) => {
  return [
    columnHelper.accessor('reportNo', {
      header: 'No.',
      filterFn: reportNoFilterFn,
    }),
    columnHelper.accessor('startTimestamp', {
      header: 'Start date',
      cell: (info) => moment.utc(info.getValue()).format('DD MMM YYYY, HH:mm'),
      filterFn: dateFilter,
    }),
    columnHelper.accessor('endTimestamp', {
      header: 'End date',
      cell: (info) =>
        info.getValue()
          ? moment.utc(info.getValue()).format('DD MMM YYYY, HH:mm')
          : '-',
      filterFn: dateFilter,
    }),
    columnHelper.accessor('data.equipment', {
      header: 'Equipment',
      cell: (info) => info.getValue() ?? '-',
    }),
    columnHelper.accessor('data.component', {
      header: 'Component',
      cell: (info) => info.getValue() ?? '-',
    }),
    columnHelper.accessor('data.subComponent', {
      header: 'Sub-component',
      cell: (info) => info.getValue() ?? '-',
    }),
    columnHelper.accessor('data.meStatus', {
      header: 'ME status',
      cell: (info) => info.getValue() ?? '-',
    }),
    columnHelper.accessor('data.comment', {
      header: () => <Header icon>Comment</Header>,
      cell: ({ row }: CellContext<GandalfApi.MfeReport, any>) => {
        if (!row.original.data.comment) return <IconCell>-</IconCell>

        return (
          <IconCell>
            <McTooltip
              width={400}
              fit={windowSize}
              appearance='inverse'
              position='top-center'
            >
              <McIcon
                slot='trigger'
                icon='comment'
                size={windowSize === 'small' ? '20' : '24'}
              />
              <span>{row.original.data.comment}</span>
            </McTooltip>
          </IconCell>
        )
      },
      enableSorting: false,
    }),
    columnHelper.accessor('data.status', {
      header: 'Status',
      cell: (info) => {
        const status = info.getValue()
        const appearance = status === MfeStatus.Ongoing ? 'warning' : 'success'
        return status ? (
          <McTag
            style={{ textTransform: 'capitalize' }}
            appearance={appearance}
          >
            {statusToDisplayStr(status)}
          </McTag>
        ) : (
          '-'
        )
      },
    }),
    columnHelper.accessor('reportState', {
      header: 'Submitted',
      cell: (info) => <ReportSubmittedTableCell state={info.getValue()} />,
    }),
    columnHelper.display({
      id: 'actions',
      header: () => <Header icon>Actions</Header>,
      cell: ({ row }: CellContext<GandalfApi.MfeReport, any>) => {
        const isDisabled = isEditDisabled(row.original)
        return (
          <IconCell>
            <McMenu fit={windowSize} position='left-top'>
              <McButton
                slot='trigger'
                icon='ellipsis-vertical'
                hiddenlabel
                label='actions'
                variant='plain'
                fit={windowSize}
              />
              <McList
                fit={windowSize}
                listchange={onListItemClick(row.original.reportId)}
              >
                <McListItem fit={windowSize} icon='file' label={Action.View} />
                <McListItem
                  fit={windowSize}
                  icon='pencil'
                  label={Action.Edit}
                  disabled={isDisabled}
                />
                <McListItem
                  fit={windowSize}
                  icon='trash'
                  label={Action.Delete}
                  disabled={isDisabled}
                />
              </McList>
            </McMenu>
          </IconCell>
        )
      },
    }),
  ]
}

const reportNoFilterFn: FilterFn<GandalfApi.MfeReport> = (
  row,
  columnId,
  filterStr,
) => {
  return row.original.reportNo === +filterStr
}

export const statusToDisplayStr = (
  status: GandalfApi.Mfe.Status | null,
): string => {
  switch (status) {
    case MfeStatus.Ongoing:
      return 'Ongoing'
    case MfeStatus.Completed:
      return 'Completed'
    case MfeStatus.OutOfService:
      return 'Out of service'
    default:
      return '-'
  }
}
