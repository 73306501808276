import moment from 'moment'
import type { FilterFn, Table } from '@tanstack/react-table'

import { DATE_FORMAT } from '../../../../components/formik/InputDateTime/InputDateTime.utils'

export const getInitialFilterDates = (table: Table<any>, columnId: string) => {
  const now = moment.utc()
  const defaultMinMax = {
    from: now.format(DATE_FORMAT),
    to: now.format(DATE_FORMAT),
  }

  const column = table.getColumn(columnId)
  if (!column) {
    console.error(`column ${columnId} not found`)
    return defaultMinMax
  }

  const minMaxDates = column.getFacetedMinMaxValues()

  if (!minMaxDates) {
    return defaultMinMax
  }

  const maxDate = moment.max(now, moment.utc(String(minMaxDates[1] ?? '')))
  const minDate = moment.utc(String(minMaxDates[0] ?? ''))

  return {
    from: minDate.format(DATE_FORMAT),
    to: maxDate.format(DATE_FORMAT),
  }
}

export const dateFilter: FilterFn<any> = (
  row,
  columnId,
  timestamp: { from: number; to: number },
) => {
  const rowTimestamp = moment.utc(row.original[columnId]).valueOf()
  return rowTimestamp >= timestamp.from && rowTimestamp <= timestamp.to
}
