import { Row, Ruler } from '../MalfunctioningEquipmentReportPage.styles'
import MfeInputNumber from '../MfeInputNumber/MfeInputNumber'
import { PadContent } from '../../../../layout/styles'

const ImpactFuelConsumption = () => {
  return (
    <PadContent>
      <h3>Impact on fuel consumption</h3>
      <Row>
        <MfeInputNumber name='data.impact.fuelConsumption' unit='MT' />
        <div>
          Please use the Stoppage calculator when calculating the fuel impact.
          If needed Stoppage Calculator can be requested from Vessel Performance
          team.
        </div>
        <div />
      </Row>
      <Ruler />
    </PadContent>
  )
}

export default ImpactFuelConsumption
