import styled from 'styled-components'

export const Wrapper = styled.div`
  .trim-table {
    width: 100%;
  }

  form {
    display: flex;
    align-items: flex-start;
    gap: 24px;
    padding: 16px;
    border-bottom: 1px solid ${(props) => props.theme.colors.grey[200]};

    mc-input {
      width: 160px;
    }

    mc-button {
      margin-top: 28px;
    }
  }

  .null-state-message {
    display: flex;
    align-items: center;
    min-height: 450px;
  }
`
