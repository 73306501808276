import React from 'react'
import { McButton, McModal } from '@maersk-global/mds-react-wrapper'

import styled from '../../../theme'
import { Ul } from '../../../commons'
import { getHelpText } from '../help-texts'

const Wrapper = styled.span`
  margin-left: 4px;
`

export const HelpTextModalContent = styled.div`
  max-width: 50vw;
  padding: 16px 16px 16px 32px;
  max-height: 70vh;
  overflow-y: auto;
`

interface HelpTextProps {
  textKey: string
}

interface HelpTextState {
  text: string
  show: boolean
}

export class HelpText extends React.Component<HelpTextProps, HelpTextState> {
  constructor(props: HelpTextProps) {
    super(props)
    this.state = {
      text: getHelpText(props.textKey),
      show: false,
    }
  }

  public render() {
    const { text, show } = this.state

    const handleClick = (event) => {
      event.stopPropagation()
      event.preventDefault()
      this.setState({ show: true })
    }

    return (
      <Wrapper>
        <McButton
          hiddenlabel
          variant='plain'
          fit='small'
          icon='info-circle'
          style={{ opacity: 0.4 }}
          click={handleClick}
        />
        {show && (
          <McModal
            open={show}
            heading='Did you know that...'
            zindex={Number.MAX_SAFE_INTEGER}
            closed={(e: any) => {
              e.stopPropagation()
              e.preventDefault()
              this.setState({ show: false })
            }}
          >
            <HelpTextModalContent>
              <Ul dangerouslySetInnerHTML={{ __html: text }} />
            </HelpTextModalContent>
            <McButton
              slot='primaryAction'
              click={(e) => {
                e.stopPropagation()
                e.preventDefault()
                this.setState({ show: false })
              }}
            >
              Close
            </McButton>
          </McModal>
        )}
      </Wrapper>
    )
  }
}
