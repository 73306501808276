import {
  type CellContext,
  createColumnHelper,
  type FilterFn,
} from '@tanstack/react-table'
import moment from 'moment'
import {
  McButton,
  McIcon,
  McList,
  McListItem,
  McMenu,
  McTag,
  McTooltip,
} from '@maersk-global/mds-react-wrapper'

import { MetcReportAction as Action } from './EnginePerformanceTestPage.consts'
import {
  Header,
  IconCell,
  NumericCell,
  NumericHeader,
} from '../../components/Table/Table.styles'
import { ReportStateTableCell } from '../../components/Reporting/ReportStateTableCell'
import { type TWindowSize } from '../../theme_new/styled'
import { ReportState } from '../../queries/GandalfApi/GandalfApi.consts'
import { dateFilter } from '../ActivityLogPage/ActivityLogFilters/DateFilter/helpers'
import { formatValue } from '../../utils'
import {
  MainEngInstanceName,
  StaticModelType,
  StaticModelTypeNames,
} from '../../queries/MasterDataApi/MasterDataApi.consts'

const columnHelper = createColumnHelper<GandalfApi.MetcReport>()

export const getColumns = (
  windowSize: TWindowSize,
  onListItemClick: (reportId: string) => (event: CustomEvent) => void,
  mainEngines?: MasterDataApi.VesselParameters.Machinery.MainEngine.Data,
) => {
  return [
    columnHelper.accessor('reportNo', {
      header: 'No.',
      filterFn: reportNoFilterFn,
    }),
    columnHelper.accessor('startTimestamp', {
      header: 'Report date (UTC)',
      cell: (info) => moment.utc(info.getValue()).format('DD MMM YYYY, HH:mm'),
      filterFn: dateFilter,
    }),
    columnHelper.display({
      id: 'duration',
      header: 'Report duration',
      cell: ({ row }: CellContext<GandalfApi.MetcReport, any>) => {
        const start = moment.utc(row.original.startTimestamp)
        const end = moment.utc(row.original.endTimestamp)
        const duration = moment.duration(end.diff(start))
        return `${duration.hours()}h ${duration.minutes()}m`
      },
    }),
    columnHelper.display({
      id: 'equipment',
      header: 'Equipment',
      cell: ({ row }: CellContext<GandalfApi.MetcReport, any>) => {
        const equipment = row.original.data.equipment

        let instance = ''
        if (
          equipment.staticModelType === StaticModelType.MainEng &&
          mainEngines?.engines.length === 2
        ) {
          instance = MainEngInstanceName[equipment.instanceNo]
        }

        return `${StaticModelTypeNames[equipment.staticModelType]} ${instance}`
      },
      filterFn: equipmentFilterFn(mainEngines),
    }),
    columnHelper.accessor('data.result.general.engineLoad', {
      header: () => <NumericHeader>Engine load</NumericHeader>,
      cell: (info) => {
        return (
          <NumericCell>{formatValue(info.getValue(), 1) + ' %MCR'}</NumericCell>
        )
      },
    }),
    columnHelper.accessor('data.result.meEfficiency.sfoc.modelDeviation', {
      header: () => <NumericHeader>SFOC deviation</NumericHeader>,
      cell: (info) => {
        if (info.getValue() === null) {
          return <NumericCell>-</NumericCell>
        }
        return (
          <NumericCell>{formatValue(info.getValue(), 1) + ' %'}</NumericCell>
        )
      },
    }),
    columnHelper.display({
      id: 'warnings',
      header: 'Warnings',
      cell: ({ row }: CellContext<GandalfApi.MetcReport, any>) => {
        const wc = getWarningsCount(row.original.data.result)

        if (wc) {
          return (
            <IconCell>
              <McTag fit={windowSize} appearance='error'>
                &nbsp;{wc}&nbsp;
              </McTag>
            </IconCell>
          )
        }

        if (row.original.reportState === ReportState.Submitted) {
          return (
            <IconCell>
              <McTag fit={windowSize} appearance='success'>
                &nbsp;OK&nbsp;
              </McTag>
            </IconCell>
          )
        }

        return <IconCell>-</IconCell>
      },
    }),
    columnHelper.accessor('data.comment', {
      header: () => <Header icon>Comment</Header>,
      cell: ({ row }: CellContext<GandalfApi.MetcReport, any>) => {
        if (!row.original.data.comment) return <IconCell>-</IconCell>

        return (
          <IconCell>
            <McTooltip
              width={400}
              fit={windowSize}
              appearance='inverse'
              position='top-center'
            >
              <McIcon
                slot='trigger'
                icon='comment'
                size={windowSize === 'small' ? '20' : '24'}
              />
              <span>{row.original.data.comment}</span>
            </McTooltip>
          </IconCell>
        )
      },
      enableSorting: false,
    }),
    columnHelper.accessor('reportState', {
      header: 'Status',
      cell: (info) => <ReportStateTableCell state={info.getValue()} />,
    }),
    columnHelper.display({
      id: 'actions',
      header: () => <Header icon>Actions</Header>,
      cell: ({ row }: CellContext<GandalfApi.MetcReport, any>) => {
        return (
          <IconCell>
            <McMenu fit={windowSize} position='left-top'>
              <McButton
                slot='trigger'
                icon='ellipsis-vertical'
                hiddenlabel
                label='actions'
                variant='plain'
                fit={windowSize}
              />
              <McList
                fit={windowSize}
                listchange={onListItemClick(row.original.reportId)}
              >
                <McListItem
                  fit={windowSize}
                  icon='file'
                  label={Action.ViewResults}
                />
                <McListItem
                  fit={windowSize}
                  icon='pencil'
                  label={Action.EditTest}
                />
                <McListItem
                  fit={windowSize}
                  icon='trash'
                  label={Action.Delete}
                />
              </McList>
            </McMenu>
          </IconCell>
        )
      },
    }),
  ]
}

const getWarningsCount = (result: GandalfApi.Metc.Result): number | null => {
  const meWc = Object.values(result.meEfficiency).reduce((acc, curr) => {
    if (curr.rangeValidation) {
      return acc + 1
    }
    return acc
  }, 0)

  const tcWc = Object.values(result.tcEfficiency).reduce((acc, curr) => {
    if (curr.rangeValidation) {
      return acc + 1
    }
    return acc
  }, 0)

  return meWc + tcWc || null
}

const equipmentFilterFn =
  (mainEngines?: MasterDataApi.VesselParameters.Machinery.MainEngine.Data) =>
  (row: any, columnId: string, filterStr: string) => {
    const { equipment } = row.original.data as GandalfApi.Metc.ReportData

    const isTwinEngineVessel = mainEngines?.engines.length === 2

    let instance: number | string = ''
    if (equipment.staticModelType === StaticModelType.MainEng) {
      if (isTwinEngineVessel) {
        instance = MainEngInstanceName[equipment.instanceNo]
      }
    } else {
      instance = equipment.instanceNo
    }

    return (
      `${StaticModelTypeNames[equipment.staticModelType]} ${instance}` ===
      filterStr
    )
  }

const reportNoFilterFn: FilterFn<GandalfApi.MetcReport> = (
  row,
  columnId,
  filterStr,
) => {
  return row.original.reportNo === +filterStr
}
