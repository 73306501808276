import { useNotificationsApiHealthcheck } from '../../queries/NotificationsApi/NotificationsApi'
import Notifications from '../Notifications/Notifications'
import { useTemAdvice as useHydraTemAdvice } from '../../queries/HydraApi/HydraApi'
import TemNotifications from '../TemNotifications/TemNotifications'
import { isFeatureEnabled } from '../../utils'

type Props = {
  imoNo: string
  timestamp?: string
}

const TemNotificationsSwitch = ({ imoNo, timestamp }: Props) => {
  const {
    data: notificationsApiAvailable,
    isSuccess: notificationsApiAvailableIsSuccess,
  } = useNotificationsApiHealthcheck(+imoNo)

  const hydraQueryEnabled =
    notificationsApiAvailableIsSuccess &&
    !notificationsApiAvailable &&
    timestamp &&
    isFeatureEnabled('tem-advice')

  const { data: hydraTemAdvices, isSuccess: hydraTemAdvicesIsSuccess } =
    useHydraTemAdvice(+imoNo, timestamp ?? '', !!hydraQueryEnabled)

  if (!notificationsApiAvailableIsSuccess) return null

  if (hydraQueryEnabled && hydraTemAdvicesIsSuccess && hydraTemAdvices) {
    /* Fall back to Hydra TEM advice. */
    const advices = hydraTemAdvices.calculatedResults
      ? [hydraTemAdvices.calculatedResults]
      : []
    return <Notifications advices={advices} />
  }

  if (!hydraQueryEnabled && notificationsApiAvailable) {
    return <TemNotifications />
  }

  return null
}

export default TemNotificationsSwitch
