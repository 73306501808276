import styled from 'styled-components'

import { formatValue } from '../../../utils'
import { UNITS } from '../../../utils/constants'

const Wrapper = styled.p`
  margin-top: 4px;
  font-size: 14px;
  color: #6d7278;
`

type Props = {
  leakageMass: number
  sludge: {
    total?: number
  }
}

const LeakageAndSludge = ({ leakageMass, sludge }: Props) => {
  return (
    <Wrapper>
      {typeof leakageMass === 'number' &&
        `ME leakage ${formatValue(leakageMass, leakageMass ? 3 : 0)} ${
          UNITS.METRIC_TON
        }`}
      {typeof leakageMass === 'number' &&
        typeof sludge?.total === 'number' &&
        ' - '}
      {typeof sludge?.total === 'number' &&
        `Sludge deducted from ROB: ${formatValue(
          sludge?.total,
          sludge?.total ? 3 : 0,
        )} ${UNITS.METRIC_TON}`}
    </Wrapper>
  )
}

export default LeakageAndSludge
