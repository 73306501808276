import { useEffect, useState } from 'react'
import { McButton, McInput } from '@maersk-global/mds-react-wrapper'

import { Wrapper } from './TrimVisualizer.styles'
import { TrimTable } from '../TrimTable/TrimTable'
import { NotFound } from '../../../commons'
import { UNITS } from '../../../utils/constants'
import { getTrim } from '../../../services/hydra'
import { type Hydra } from '../../../api-models/hydra'
import { displayErrorModal } from '../../../utils'

type Props = {
  imoNo: string
}

export const TrimVisualizer = ({ imoNo }: Props) => {
  const [speedErrorMsg, setSpeedErrorMsg] = useState<string>()
  const [draughtErrorMsg, setDraughtErrorMsg] = useState<string>()
  const [isLoading, setIsLoading] = useState(false)
  const [meanDraught, setMeanDraught] = useState(0)
  const [trimAdvice, setTrimAdvice] = useState<Hydra.Trim.Trim>()

  useEffect(() => setTrimAdvice(undefined), [imoNo])

  const handleSubmit = (event) => {
    event.preventDefault()
    const speed = event.target.speed.value
    const draughtMean = event.target.draughtMean.value
    setMeanDraught(+draughtMean)
    setIsLoading(true)
    getTrim(imoNo, speed.toString(), draughtMean.toString())
      .then((res: Hydra.Trim.Trim) => {
        setTrimAdvice(res)
        setIsLoading(false)
      })
      .catch((res: ResponseError) => {
        setTrimAdvice(undefined)
        if (res.statusCode === 400) {
          const body: Hydra.ValidationErrorResponseBody = res.body
          body.errors.forEach((error) =>
            error.paths.forEach((path) => {
              if (path === 'speed') {
                setSpeedErrorMsg(error.message)
              }
              if (path === 'draughtMean') {
                setDraughtErrorMsg(error.message)
              }
            }),
          )
        } else {
          void displayErrorModal(res)
        }
        setIsLoading(false)
      })
  }

  return (
    <Wrapper>
      <form onSubmit={handleSubmit}>
        <McInput
          name='speed'
          type='number'
          suffix={UNITS.KNOTS_ABR}
          placeholder='0.0'
          label='STW'
          invalid={!!speedErrorMsg}
          input={() => setSpeedErrorMsg(undefined)}
          errormessage={speedErrorMsg}
          data-e2e='STW'
        />
        <McInput
          name='draughtMean'
          type='number'
          suffix={UNITS.METER}
          placeholder='0.0'
          label='Mean draught'
          invalid={!!draughtErrorMsg}
          input={() => setDraughtErrorMsg(undefined)}
          errormessage={draughtErrorMsg}
          data-e2e='MeanDraught'
        />
        <McButton
          type='submit'
          label='Calculate'
          loading={isLoading}
          data-e2e='submitTrim'
        />
      </form>
      {!!trimAdvice ? (
        <TrimTable
          className='trim-table'
          res={trimAdvice}
          meanDraughtReference={meanDraught}
        />
      ) : (
        <div className='null-state-message' data-e2e='emptyTrimTable'>
          <NotFound text='Please input STW and mean draught to get a calm water trim advice' />
        </div>
      )}
    </Wrapper>
  )
}
