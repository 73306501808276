import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import {
  displayToast,
  doDelete,
  doGet,
  doPost,
  getManagementApiUrl,
} from '../../utils'

const STALE_TIME = 1000 * 60 * 60 * 3 // 3 hours

const favouritesKeys = {
  all: ['ManagementApi', 'Favourites'] as const,
}

export const useFavourites = () => {
  return useQuery({
    queryFn: getFavourites,
    queryKey: favouritesKeys.all,
    staleTime: STALE_TIME,
    meta: {
      errorMessage: 'A problem occurred retrieving favourites.',
    },
  })
}

const getFavourites = (): Promise<ManagementApi.Favourites.Response> => {
  return doGet(`${getManagementApiUrl()}/Favourites`)
}

export const useSaveFavourite = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: postFavourite,
    onError: handleMutationError('A problem occurred saving favourites.'),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: favouritesKeys.all,
      }),
  })
}

const postFavourite = (
  payload: ManagementApi.Favourites.Model,
): Promise<void> => {
  return doPost(`${getManagementApiUrl()}/Favourites`, payload)
}

export const useDeleteFavourite = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: deleteFavourite,
    onError: handleMutationError('A problem occurred deleting favourites.'),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: favouritesKeys.all,
      }),
  })
}

const deleteFavourite = (favouriteName: string): Promise<void> => {
  return doDelete(`${getManagementApiUrl()}/Favourites/${favouriteName}`)
}

/*******************************************************************************
 Error Handling
 ******************************************************************************/

const handleMutationError = (msg: string) => (error: unknown) => {
  if (isError(error)) {
    const {
      body: { title, errors },
    } = error

    const errorsMappedToStringList = Object.values(errors)
      .flatMap((error) => error)
      .join('\n')

    void displayToast('error', title, errorsMappedToStringList, 8000)
  } else {
    void displayToast('error', 'Something went wrong...', msg, 8000)
  }
}

const isError = (error: unknown): error is ManagementApi.Favourites.Error => {
  return (error as ManagementApi.Favourites.Error).body?.errors !== undefined
}
