import React from 'react'
import * as ECharts from 'echarts'
import moment from 'moment'

import {
  ActiveEngineIcon,
  ChartsWrapper,
  ContentWrapper,
  Economizer,
  StatusWrapper,
  Wrapper,
} from './AESfocChartContainer.styles'
import { initialState, SAMPLE_PERIOD } from './AESfocChartContainer.utils'
import * as colors from '../../themes'
import { AeSfocVsPowerChart } from '../AESFOCvsPowerChart/AESFOCvsPowerChart'
import { AePowerVsTimeChart } from '../AEPowerVsTimeChart'
import { connectChartsLineScatter } from '../../chartConnector'
import { Performance } from '../../../../api-models'
import { getEngineTheme } from '../../auxiliary-util'
import { type IVesselPageContext } from '../../../../contexts'
import {
  type DefaultFilters,
  type Filters,
  filtersChanged,
  getDateRange,
  sortDateRange,
} from '../../../filter'
import {
  type Chart,
  displayErrorModal,
  formatValue,
  getDefaultFilters,
  isNumber,
} from '../../../../utils'
import { layoutSizes } from '../../../../theme'
import {
  Block,
  ChartContainer,
  ChartStencil,
  ContentCard,
  FancyLabel,
  Icon,
  NotFound,
  ValueLabel,
  VesselStatusBlock,
} from '../../../../commons'
import { withVesselPageContext } from '../../../../higher-order-components'
import { UNITS } from '../../../../utils/constants'
import { getAeSfocData } from '../../../../services/performance'
import ShopTestLegends from '../../components/ShopTestLegends'

type Props = IVesselPageContext & {
  filters?: Filters
  staticModels: Array<AuxEngPage.StaticModel>
}

type State = {
  data?: Performance.Sfoc.AeSfocData
  chartData?: Array<Array<number | undefined>>
  sfocShopTestCurves: Array<SfocShopTestCurve>
  selectedShopTestCurve: number | undefined
  showEngineInChart: Array<boolean>
  chartsMounted: boolean
  dimensions: Array<string>
  defaultFilter: DefaultFilters
}

class AESfocChartContainer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = initialState
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { filters, imoNo, vesselStatus } = this.props
    const { chartData, chartsMounted } = this.state
    if (
      filtersChanged(prevProps.filters, filters) ||
      prevProps.vesselStatus?.timestamp !== vesselStatus?.timestamp ||
      prevProps.imoNo !== imoNo
    ) {
      this.setState(
        {
          data: undefined,
          chartsMounted: false,
          defaultFilter: getDefaultFilters(14, 'd'),
        },
        () => this.loadData(),
      )
    }

    if (!chartsMounted && document.getElementById('aesfoc-vs-power-chart')) {
      this.setState({ chartsMounted: true })
    }

    if (!!chartData && !!chartsMounted && !prevState.chartsMounted) {
      const [power, time] = [
        document.getElementById('aesfoc-vs-power-chart'),
        document.getElementById('aepower-vs-time'),
      ] as Array<HTMLDivElement | null>
      if (power && time) {
        const powerChart = ECharts.getInstanceByDom(power) as Chart
        const timeChart = ECharts.getInstanceByDom(time) as Chart
        connectChartsLineScatter(timeChart, powerChart, chartData)
      }
    }
  }

  componentDidMount() {
    this.loadData()
  }

  loadData() {
    const { imoNo, filters } = this.props
    const { defaultFilter } = this.state

    if (!imoNo) return

    const { to, from } = getDateRange(defaultFilter.dateRange, filters)
    const sortedFilters = sortDateRange(moment.utc(from), moment.utc(to))
    // This is because we always fetch 90 days of data for the scatter chart, but we only show data according to filter in timeline chart
    const fromDate = moment
      .utc(sortedFilters.to)
      .subtract(moment.duration(SAMPLE_PERIOD, 'days'))
    const queryPeriod = {
      to: sortedFilters.to.toISOString(),
      from: fromDate.toISOString(),
    }

    getAeSfocData(imoNo, queryPeriod)
      .then((data) => {
        const chartData: Array<Array<number | undefined>> =
          this.createChartDataArray(data, this.state.dimensions)

        const curves = this.createSfocShopTestCurves()

        this.setState({
          data: data,
          chartData: chartData,
          sfocShopTestCurves: curves,
        })
      })
      .catch((e) => {
        void displayErrorModal(e)
      })
  }

  padEntryWithZero(
    newEntry: Array<number | any>,
    pastEntry: Array<number | any>,
    dimensions: Array<string>,
  ) {
    for (
      let i = dimensions.indexOf('powerAE1');
      i <= dimensions.indexOf('powerAE5');
      i++
    ) {
      if (
        typeof pastEntry[i] === 'number' &&
        typeof newEntry[i] !== 'number' &&
        pastEntry[i] > 0
      ) {
        newEntry[i] = 0
      } else if (
        typeof pastEntry[i] !== 'number' &&
        typeof newEntry[i] === 'number' &&
        newEntry[i] > 0
      ) {
        pastEntry[i] = 0
      }
    }
  }

  createSfocShopTestCurves() {
    return this.props.staticModels.map((staticModel) => {
      const { mcr, sfocTable } = staticModel.data
      const curve = sfocTable.map((tableItem) => {
        const power = (tableItem.load * mcr) / 100
        return [power, tableItem.sfoc]
      })

      return {
        staticModelId: staticModel.id,
        staticModelName: staticModel.data.name,
        curve,
      }
    })
  }

  createChartDataArray(
    data: Performance.Sfoc.AeSfocData,
    dimensions: string[],
  ): Array<Array<number | undefined>> {
    const chartData: Array<Array<number | undefined>> = []
    if (data.hasData) {
      data.runningEnginesSeries.forEach(
        (
          entryArr: Array<Performance.Sfoc.RunningEnginesSerie>,
          index: number,
        ) => {
          if (entryArr.length === 1) {
            const powerEntry = [...Array(dimensions.length)]
            powerEntry[dimensions.indexOf(`fpowerAE${entryArr[0].number}`)] =
              data.powerSeries[index]
            powerEntry[dimensions.indexOf(`powerAE${entryArr[0].number}`)] =
              data.powerSeries[index]
            powerEntry[dimensions.indexOf('timestamp')] = data.timestamps[index]
            powerEntry[dimensions.indexOf('sfoc')] = data.sfocSeries[index]

            powerEntry[dimensions.indexOf('totalpower')] =
              data.powerSeries[index]

            if (chartData.length > 0) {
              this.padEntryWithZero(
                powerEntry,
                chartData[chartData.length - 1],
                dimensions,
              )
            }

            chartData.push(powerEntry)
          } else {
            const powerEntry = [...Array(dimensions.length)]
            powerEntry[dimensions.indexOf('powerMix')] = data.powerSeries[index]

            entryArr.forEach((entry: Performance.Sfoc.RunningEnginesSerie) => {
              powerEntry[dimensions.indexOf(`powerAE${entry.number}`)] =
                entry.power
            })
            powerEntry[dimensions.indexOf('timestamp')] = data.timestamps[index]
            powerEntry[dimensions.indexOf('sfoc')] = data.sfocSeries[index]

            powerEntry[dimensions.indexOf('totalpower')] =
              data.powerSeries[index]

            if (chartData.length > 0) {
              this.padEntryWithZero(
                powerEntry,
                chartData[chartData.length - 1],
                dimensions,
              )
            }

            chartData.push(powerEntry)
          }
        },
      )
    }
    return chartData
  }

  getEngineMaxPowerValue(engineNumber: number) {
    const { configuration } = this.props
    if (configuration) {
      const { auxEngs } = configuration
      const auxEng = auxEngs.find((eng) => eng.number === engineNumber)
      return auxEng ? auxEng.maxPower : 0
    }
  }

  getTimelineChartMinValue(filters: Filters | undefined): number {
    const { defaultFilter } = this.state
    const queryFilterPeriod = getDateRange(defaultFilter.dateRange, filters)
    return moment.utc(queryFilterPeriod.from).valueOf()
  }

  engineHasEconomizer(engineNbr: number): boolean {
    if (!this.props.configuration) return false
    const { auxEngs } = this.props.configuration
    const engineConfig = auxEngs.find((eng) => eng.number === engineNbr)
    if (engineConfig && engineConfig.hasEconomizer)
      return engineConfig.hasEconomizer
    else return false
  }

  renderStatusBlock() {
    const { vesselStatus, configuration } = this.props
    if (vesselStatus) {
      return (
        <VesselStatusBlock
          timestamp={vesselStatus.timestamp}
          lastSixHours={true}
        >
          {this.renderMixEngineLabel()}
          {configuration &&
            configuration.auxEngs &&
            configuration.auxEngs.map((eng) => {
              if (eng.isInstalled) return this.renderAeEngineLabel(eng.number)
              return null
            })}
        </VesselStatusBlock>
      )
    }
  }

  renderMixEngineLabel() {
    const { vesselStatus } = this.props
    const { showEngineInChart } = this.state
    return (
      <Block>
        <FancyLabel
          colorScheme={getEngineTheme(0)}
          value={showEngineInChart[0]}
          onChange={() => {
            const showEnginesCopy = showEngineInChart.slice()
            showEnginesCopy[0] = !showEngineInChart[0]
            return this.setState({
              showEngineInChart: showEnginesCopy,
            })
          }}
        >
          <div className='ae-label'>
            <p>Mixed AEs</p>
          </div>
        </FancyLabel>
        <ValueLabel unit={UNITS.SFOC}>
          {vesselStatus &&
          vesselStatus.hasData &&
          vesselStatus.auxEng &&
          typeof vesselStatus.auxEng.mixSfoc === 'number'
            ? vesselStatus.auxEng.mixSfoc
            : '-'}
        </ValueLabel>
      </Block>
    )
  }

  renderAeEngineLabel(engineNumber: number) {
    const { vesselStatus } = this.props
    const { showEngineInChart } = this.state
    let auxEngStatus
    if (vesselStatus && vesselStatus.hasData && vesselStatus.auxEngs) {
      auxEngStatus = vesselStatus.auxEngs.find(
        (eng) => eng.number === engineNumber,
      )
    }
    const aeMaxPowerValue = this.getEngineMaxPowerValue(engineNumber)
    return (
      <Block key={engineNumber}>
        <FancyLabel
          colorScheme={getEngineTheme(engineNumber)}
          value={showEngineInChart[engineNumber]}
          onChange={() => {
            const showEnginesCopy = showEngineInChart.slice()
            showEnginesCopy[engineNumber] = !showEngineInChart[engineNumber]
            return this.setState({
              showEngineInChart: showEnginesCopy,
            })
          }}
        >
          <div className='ae-label'>
            <ActiveEngineIcon
              className='fas fa-circle green'
              style={{ opacity: !!auxEngStatus && !!auxEngStatus.load ? 1 : 0 }}
            />
            <p>AE {engineNumber}</p>
            <Economizer>
              <Icon
                icon='cv-economizer'
                verticalAlign={-0.3}
                title='This AE has an economizer'
                style={
                  this.engineHasEconomizer(engineNumber)
                    ? {}
                    : { opacity: 0, pointerEvents: 'none' }
                }
              />
            </Economizer>
          </div>
        </FancyLabel>
        <ValueLabel unit={UNITS.SFOC}>
          {!!auxEngStatus && isNumber(auxEngStatus.sfoc)
            ? formatValue(auxEngStatus.sfoc, 1)
            : '-'}
        </ValueLabel>
        <div className='engine-size-label' title='Max electrical power'>
          max {formatValue(aeMaxPowerValue, 0)} kW
        </div>
      </Block>
    )
  }

  handleChangeSelectedShopTestCurve = (instanceNumber: number | undefined) => {
    this.setState({ selectedShopTestCurve: instanceNumber })
  }

  public render() {
    const { filters } = this.props
    const { data, showEngineInChart, chartData, dimensions, defaultFilter } =
      this.state
    const queryFilterPeriod = getDateRange(defaultFilter.dateRange, filters)
    const timeLineMinValue = this.getTimelineChartMinValue(filters)

    return (
      <Wrapper>
        <ContentCard
          id='sfoc_vs_power'
          title='AE SFOC vs AE Power'
          helpTextKey='auxiliary-engines/sfoc-vs-power'
          width={layoutSizes.full}
          notification={
            'The content in this graph displays 90 days of data regardless of what date range you have selected'
          }
        >
          <ContentWrapper>
            <ChartsWrapper>
              <ShopTestLegends
                staticModels={this.props.staticModels}
                onClick={this.handleChangeSelectedShopTestCurve}
                selectedInstance={this.state.selectedShopTestCurve}
              />
              <ChartContainer
                y1Label={{
                  name: UNITS.SFOC,
                  colorScheme: colors.normal,
                }}
                xLabel={{
                  name: UNITS.KILO_WATT,
                  colorScheme: colors.normal,
                }}
                hideSideContent={true}
                minHeight={350}
                sideContentWidth={0}
              >
                {!data && <ChartStencil chartType='scatter' minHeight={350} />}

                {!!data && !!data.hasData && chartData && (
                  <AeSfocVsPowerChart
                    data={data}
                    showEngine={showEngineInChart}
                    chartData={chartData}
                    queryPeriod={queryFilterPeriod}
                    dimensions={dimensions}
                    sfocShopTestCurves={this.state.sfocShopTestCurves}
                    selectedShopTestCurve={this.state.selectedShopTestCurve}
                  />
                )}
              </ChartContainer>
              {!!data && !data.hasData && (
                <NotFound text='No data for the selected period found.' />
              )}
              <ChartContainer
                y1Label={{
                  name: UNITS.KILO_WATT,
                  colorScheme: colors.normal,
                }}
                hideSideContent={true}
                minHeight={350}
                sideContentWidth={0}
              >
                {!data && <ChartStencil chartType='line' minHeight={350} />}

                {!!data && !!data.hasData && !!chartData && (
                  <AePowerVsTimeChart
                    data={data}
                    showEngine={showEngineInChart}
                    chartData={chartData}
                    timeLineMinValue={timeLineMinValue}
                    dimensions={dimensions}
                  />
                )}
              </ChartContainer>
              {!!data && !data.hasData && (
                <NotFound text='No data for the selected period found.' />
              )}
            </ChartsWrapper>
            <StatusWrapper>{this.renderStatusBlock()}</StatusWrapper>
          </ContentWrapper>
        </ContentCard>
      </Wrapper>
    )
  }
}

export default withVesselPageContext(AESfocChartContainer)
