import { Link } from 'react-router-dom'
import { HelpSection } from '../help-section'
import '../style.scss'
import { HELP_SECTION_TOPICS } from './constants'

export const TopicsOverview = () => {
  const topics = (
    <>
      <div className='mds-headline--small'>Help sorted by topics</div>
      <div className='topics-overview-grid'>
        {Object.values(HELP_SECTION_TOPICS).map((group) => (
          <div key={group.name}>
            <h4>{group.name}</h4>
            {Object.values(group.topics).map((topic) => (
              <Link
                to={(location) => `${location.pathname}/${topic.path}`}
                key={topic.path}
              >
                <div className='topic-section'>
                  <div className='mds-text--medium-bold'>{topic.name}</div>
                  <div className='mds-text--small-italic update-info'>
                    Last updated {topic.updatedAt}
                  </div>
                  <div>
                    {topic.subtopics.map((subtopic, idx) => (
                      <div key={idx} className='mds-text--small-bold subtopic'>
                        {subtopic.name}
                      </div>
                    ))}
                  </div>
                </div>
              </Link>
            ))}
          </div>
        ))}
      </div>
    </>
  )

  return <HelpSection topics={topics} />
}
