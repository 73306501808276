import styled from 'styled-components'

export const SimpleFormRow = styled.div`
  display: flex;
  flex-wrap: wrap;

  gap: ${(props) => props.theme.gaps.small}px;
  margin-top: ${(props) => props.theme.gaps.small}px;
  @media (min-width: ${(props) => props.theme.breakPoints.medium}px) {
    gap: ${(props) => props.theme.gaps.medium}px;
    margin-top: ${(props) => props.theme.gaps.medium}px;
  }
  @media (min-width: ${(props) => props.theme.breakPoints.large}px) {
    gap: ${(props) => props.theme.gaps.large}px;
    margin-top: ${(props) => props.theme.gaps.large}px;
  }

  > mc-input,
  mc-textarea,
  mc-c-typeahead,
  mc-checkbox,
  mc-select:not(.time-select),
  mc-select-native:not(.time-select),
  div {
    width: calc(50% - ${(props) => props.theme.gaps.small / 2}px);
    @media (min-width: ${(props) => props.theme.breakPoints.medium}px) {
      width: calc(33.33% - ${(props) => props.theme.gaps.medium / 1.5}px);
    }
    @media (min-width: ${(props) => props.theme.breakPoints.large}px) {
      width: calc(33.33% - ${(props) => props.theme.gaps.large}px / 1.5);
    }
  }

  mc-checkbox {
    align-self: center;
  }

  > div {
    display: flex;

    gap: ${(props) => props.theme.gaps.small / 2}px;
    @media (min-width: ${(props) => props.theme.breakPoints.medium}px) {
      gap: ${(props) => props.theme.gaps.medium / 2}px;
    }
    @media (min-width: ${(props) => props.theme.breakPoints.large}px) {
      gap: ${(props) => props.theme.gaps.large / 2}px;
    }

    > mc-input {
      width: calc(50% - ${(props) => props.theme.gaps.small / 4}px);
      @media (min-width: ${(props) => props.theme.breakPoints.medium}px) {
        width: calc(50% - ${(props) => props.theme.gaps.medium / 4}px);
      }
      @media (min-width: ${(props) => props.theme.breakPoints.large}px) {
        width: calc(50% - ${(props) => props.theme.gaps.large}px / 4);
      }
    }
  }
`

const FormRow = styled.div<{
  direction?: 'column' | 'row'
  hidden?: boolean
  maxWidth?: string
}>`
  display: ${({ hidden }) => (hidden ? 'none' : 'flex')};
  gap: 8px;
  margin-bottom: 16px;
  flex-direction: ${({ direction = 'row' }) => direction};

  h3 {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 16px;
  }

  > div {
    flex: 1 1 auto;

    > div {
      max-width: ${({ maxWidth }) => maxWidth || '225px'};
      box-sizing: border-box;
    }

    > textarea {
      max-width: ${({ maxWidth }) => maxWidth || '335px'};
      box-sizing: border-box;
    }
  }
`

export default FormRow
