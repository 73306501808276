import {
  maerskBlue,
  fillOpacityColor,
  orangeTheme,
  fillOpacity,
  green,
  purple,
} from '../../theme'

export const normal = {
  stroke: '#e0e0e0',
  fill: '#fff',
  font: '#000',
}

export const sfoc = {
  stroke: orangeTheme.border,
  fill: fillOpacity(orangeTheme, 0.2).bg,
  font: orangeTheme.fg,
}

export const meEng1 = {
  stroke: maerskBlue[500],
  fill: fillOpacityColor(maerskBlue[500], 0.2),
  font: '#000',
}

export const meEng2 = {
  stroke: green[500],
  fill: fillOpacityColor(green[500], 0.2),
  font: '#000',
}

export const meMix = {
  stroke: purple[500],
  fill: fillOpacityColor(purple[500], 0.2),
  font: '#000',
}

export const meMixInvisible = {
  stroke: purple[500],
  fill: fillOpacityColor(purple[500], 0.1),
  font: '#000',
}
