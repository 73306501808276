import * as React from 'react'
import { McButton } from '@maersk-global/mds-react-wrapper'

import styled from '../theme'

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
      margin-bottom: 14px;
    }
  }
`

interface ErrorBoundaryProps {
  children?: React.ReactNode
}

interface ErrorBoundaryState {
  hasError: boolean
  error?: any
}

export class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false }
  }

  componentDidCatch(error: any, info: React.ErrorInfo) {
    this.setState({ hasError: true, error })
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Wrapper>
          <div>
            <span className='fal fa-ambulance fa-4x' />
            <h1>Something went wrong.</h1>
            <p>Error message: {this.state.error.message}</p>
            <McButton
              label='Reload the page'
              appearance='primary'
              click={() => window.location.reload()}
            />
          </div>
        </Wrapper>
      )
    }
    return this.props.children
  }
}
