import styled from '../theme'

export const FormContainer = styled.div<{
  width?: string
  height?: string
  labelWidth?: string
  flexDirection?: string
}>`
  ${(props) => (props.width ? `width: ${props.width};` : '')};
  ${(props) => (props.height ? `height: ${props.height};` : '')}

  padding: 16px;
  display: flex;
  flex-direction: ${(props) => props.flexDirection || 'column'};
  flex-wrap: wrap;

  .field-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .field-label {
      width: ${(props) => props.labelWidth || '130px'};
      font-size: 14px;
      text-align: right;
      margin-right: 8px;

      &.extended {
        width: 230px;
      }
    }
  }
`
