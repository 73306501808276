import React, { FunctionComponent } from 'react'
import { Field, getIn, FieldArrayRenderProps, useFormikContext } from 'formik'
import { Performance } from '../../../../api-models'
import { Icon, InfoBox } from '../../../../commons'
import styled, { IColorScheme, errorTheme } from '../../../../theme'
import { FormInputWithUnit } from '../../../../commons'
import { RecoveryAndStartupCommonRobType } from '../form-consumption-selection/FormBatchSelection'

const SumRow = styled.tr<IColorScheme>`
  background: ${({ fill }) => fill};
  color: ${({ font }) => font};

  &:hover {
    background-color: ${({ fill }) => fill} !important;
  }

  td {
    border: ${({ font }) => `1px solid ${font}`} !important;
    padding-top: 4px !important;
    padding-bottom: 4px !important;

    &:first-child {
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
    }

    &:last-child {
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
    }

    &:not(:first-child) {
      border-left: none !important;
    }
  }
`

interface RecoverySoundingTableRowProps {
  onCalcClick: (val: string) => void
  scheme: IColorScheme
  disabled: boolean
}

const roundedValue = (val: number): number =>
  Math.round((val + Number.EPSILON) * 100) / 100

const DISPLAY_NAME_MAPPER = {
  hsValues: 'HS',
  ulsValues: 'ULS',
  vlsValues: 'VLS',
  mdoValues: 'MDO',
}

const validateRob = (
  value,
  batch: Performance.FuelOilStock.BatchResponse,
): string => {
  let error
  if (value < 0) {
    error = 'Remaining on board must be more than or equal to 0'
  }

  if (value > batch.quantityPerChiefEngineer) {
    error =
      'Remaining on board must be lower than or equal to the quantity per chief'
  }
  return error
}

export const RecoverySoundingTableSection: FunctionComponent<
  FieldArrayRenderProps & RecoverySoundingTableRowProps
> = ({ form, name: fuelTypeArrayName, scheme, onCalcClick, disabled }) => {
  const { values } = useFormikContext<RecoveryAndStartupCommonRobType>()
  const batches = getIn(form.values, fuelTypeArrayName)
  const displayName = DISPLAY_NAME_MAPPER[fuelTypeArrayName]
  return (
    <>
      <SumRow {...scheme}>
        <td>{displayName}</td>
        <td>
          {batches
            ? roundedValue(
                batches.reduce(
                  (acc, batch) =>
                    acc +
                    ((values?.manualrob.rob &&
                      values.manualrob.rob[batch.id]) ||
                      0),
                  0,
                ),
              )
            : 0}
        </td>
      </SumRow>

      {batches?.length > 0 &&
        batches.map((batch, index) => (
          <React.Fragment key={batch.id}>
            <tr>
              <td title={batch.displayName}>{batch.displayName}</td>
              <td
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div style={{ display: 'inherit' }}>
                  <div className='input-cell'>
                    <Field
                      component={FormInputWithUnit}
                      name={`manualrob.rob.${batch.id}`}
                      validate={(value) => validateRob(value, batch)}
                      placeholder='0.00'
                      unit='MT'
                      type='number'
                      disabled={disabled}
                      unitClassName='input-unit'
                    />
                  </div>
                  <span
                    className='calculator'
                    onClick={() =>
                      onCalcClick(`${fuelTypeArrayName}[${index}]`)
                    }
                  >
                    <Icon icon='fal fa-calculator' />
                  </span>
                </div>
              </td>
            </tr>
            <ErrorMessage name={`manualrob.rob.${batch.id}`} />
          </React.Fragment>
        ))}
    </>
  )
}

const ErrorMessage = ({ name }) => (
  <Field name={name}>
    {({ form }) => {
      const error = getIn(form.errors, name)
      const touch = getIn(form.touched, name)
      return touch && error ? (
        <tr>
          <td colSpan={2}>
            <InfoBox hideIcons={true} theme={errorTheme}>
              {error}
            </InfoBox>
          </td>
        </tr>
      ) : null
    }}
  </Field>
)
