import React from 'react'
import styled from 'styled-components'
import { McSelectNative } from '@maersk-global/mds-react-wrapper'

export interface NumberOfDigitsFilterConfig {
  show: boolean
  selections: number[]
}

export interface NumberOfDigitsFilterProps {
  value?: number
  config: NumberOfDigitsFilterConfig
  onChange: (numberOfDigits: number) => void
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 15px;

  label {
    font-size: 14px;
    margin-right: 5px;
  }
`

export interface NumberOfDigitsFilterState {}

class NumberOfDigitsFilter extends React.Component<
  NumberOfDigitsFilterProps,
  NumberOfDigitsFilterState
> {
  render() {
    const { config, value, onChange } = this.props

    if (config.show) {
      const options = config.selections.map((number) => ({
        label: number.toString(),
        value: number.toString(),
      }))

      const handleChange = ({ detail }: CustomEvent) => {
        const value = detail.selectedOptions[0]?.value
        if (value !== undefined) {
          onChange(+value)
        } else {
          onChange(+_value[0].value)
        }
      }

      /* Transform value into what McSelect expects. */
      const _value = value
        ? [
            {
              label: value.toString(),
              value: value.toString(),
            },
          ]
        : [options[1]]

      return (
        <Wrapper>
          <label>Number of digits</label>
          <McSelectNative
            hiddenlabel
            placeholder='Select...'
            options={options}
            value={_value}
            change={handleChange}
          />
        </Wrapper>
      )
    }

    return null
  }
}

export default NumberOfDigitsFilter
