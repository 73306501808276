import React, { useRef, useState } from 'react'
import { useReactToPrint } from 'react-to-print'
import { McButton } from '@maersk-global/mds-react-wrapper'

type Props = {
  component: React.ForwardRefExoticComponent<
    React.RefAttributes<HTMLDivElement>
  >
  name: string
}

export const Subtopic = (props: Props) => {
  const componentRef = useRef<HTMLDivElement>(null)
  const [isLoading, setIsLoading] = useState(false)

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforeGetContent: () => setIsLoading(true),
    onAfterPrint: () => setIsLoading(false),
    documentTitle: `Help Section - ${props.name}`,
    pageStyle: 'margin: 2rem',
    removeAfterPrint: true,
  })

  return (
    <>
      <McButton
        appearance='secondary'
        label='Download this guide as PDF'
        trailingicon='tray-arrow-down'
        loading={isLoading}
        click={handlePrint}
      />
      <props.component ref={componentRef} />
    </>
  )
}
