import styled from 'styled-components'

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const Description = styled.p`
  margin-bottom: 16px;
`

export const EventModalContainer = styled.div`
  max-height: 700px;
  overflow: auto;
  width: 1000px;
`

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${(props) => props.theme.gaps.small}px;
  @media (min-width: ${(props) => props.theme.breakPoints.medium}px) {
    gap: ${(props) => props.theme.gaps.medium}px;
  }
  @media (min-width: ${(props) => props.theme.breakPoints.large}px) {
    gap: ${(props) => props.theme.gaps.large}px;
  }
`

export const FormColumn = styled.div`
  width: 50%;
`

export const FormColumnField = styled.div`
  margin-top: ${(props) => props.theme.gaps.small}px;
  @media (min-width: ${(props) => props.theme.breakPoints.medium}px) {
    margin-top: ${(props) => props.theme.gaps.medium}px;
  }
  @media (min-width: ${(props) => props.theme.breakPoints.large}px) {
    margin-top: ${(props) => props.theme.gaps.large}px;
  }
`

export const RadioButtonWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.grey[200]};
  border-radius: 4px;
  margin: 4px 0;
  padding: 8px;
`

export const Wrapper = styled.div`
  padding: 20px;
  position: relative;
`
