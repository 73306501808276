import { GRID } from '../utils/constants'

/**
 * EChart tooltip position function.
 * https://echarts.apache.org/en/option.html#tooltip.position
 * @param point Mouse position, `point[0]' is `x` and `point[1]' is `y`.
 * @param params The same as formatter.
 * @param element The DOM object of tooltip.
 * @param rect It is valid only when mouse is on graphic elements, which stands for a bounding box with `x`, `y`, `width`, and `height`.
 * @param size The size of dom echarts container. For example: `{contentSize: [width, height], viewSize: [width, height]}`.
 */
export type PositionTooltipFunc = (
  point: number[],
  params: object | object[],
  element: HTMLElement,
  rect: object,
  size: any,
) => number[]

/**
 * Position tooltip so that it does not hide content i.e. show centered over mouse pointer with an offset.
 * Also; dont move outside plot area in x direction and stick to grid top in y direction.
 */
export const positionTooltip: PositionTooltipFunc = (
  point,
  params,
  element,
  rect,
  size,
) => {
  const x = Math.min(
    Math.max(GRID.left, point[0] - size.contentSize[0] / 2),
    size.viewSize[0] - size.contentSize[0] - GRID.right,
  )
  const y = Math.max(
    point[1] - size.contentSize[1] - 48,
    size.contentSize[1] * -1 + GRID.top,
  )

  return [x, y]
}
