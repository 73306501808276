import React, { useEffect, useState } from 'react'
import { Header, Title, Content } from './style'

type Props = {
  depth?: number
  isDisabled?: boolean
  isOpen?: boolean
  setIsOpen?: Function
  toggle?: React.FC | string
  itemRenderer?: () => void
  children?: React.ReactNode
}

const Accordion = ({
  children,
  depth = 0,
  isDisabled = false,
  isOpen,
  setIsOpen,
  toggle,
  itemRenderer,
}: Props) => {
  const [open, setOpen] = useState(isOpen)

  useEffect(() => {
    if (typeof isOpen === 'undefined' || open === isOpen) {
      return
    }
    setOpen(isOpen)
  }, [isOpen, open])

  return (
    <>
      {typeof toggle === 'string' && (
        <Header
          isDisabled={isDisabled}
          onClick={() => {
            if (isDisabled) {
              return
            }

            setOpen(!open)
            setIsOpen?.(!open)
          }}
          depth={depth}
          isOpen={open}
        >
          <Title>{(itemRenderer && itemRenderer()) || toggle}</Title>
          <span>{open ? '-' : '+'}</span>
        </Header>
      )}
      {typeof toggle === 'function' && { toggle }}

      <Content isOpen={!!open}>{children}</Content>
    </>
  )
}

export default Accordion
