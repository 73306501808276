import { Wrapper } from './Footer.styles'
import { PadContent } from '../../../layout/styles'

type Props = {
  dataFormat: number
}

const Footer = ({ dataFormat }: Props) => {
  return (
    <PadContent>
      <Wrapper>
        <p>Report data format: {dataFormat}</p>
      </Wrapper>
    </PadContent>
  )
}

export default Footer
