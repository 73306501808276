import { useContext } from 'react'
import { McIcon } from '@maersk-global/mds-react-wrapper'

import styled, { grey, media, sm, md, lg } from '../../../theme'
import { WindowContext } from '../../../contexts'

const Wrapper = styled.button`
  color: ${grey[700]};
  background-color: transparent;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  position: relative;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  ${media.sm`height: ${sm.BIG_BAR_HEIGHT};`};
  ${media.md`height: ${md.BIG_BAR_HEIGHT};`};
  ${media.lg`height: ${lg.BIG_BAR_HEIGHT};`};

  h3 {
    padding-left: 8px;
  }

  &.expanded {
    padding: 16px;
    ${media.sm`line-height: ${md.H3_SIZE};`};
    ${media.md`line-height: normal;`};
    ${media.lg`line-height: normal;`};

    #collapse-filters {
      position: absolute;
      top: 20px;
      right: 16px;
    }
  }

  &.collapsed {
    justify-content: center;
    padding: 4px;

    #collapse-filters {
      display: none;
    }
  }
`

type Props = {
  onClick: () => void
  expanded: boolean
}

const FilterPanelHeader = ({ onClick, expanded }: Props) => {
  const { windowSize } = useContext(WindowContext)

  return (
    <Wrapper onClick={onClick} className={expanded ? 'expanded' : 'collapsed'}>
      <McIcon
        id='filter-icon'
        icon='funnel'
        size={windowSize === 'small' ? '20' : '24'}
      />
      {expanded && <h3>Filters</h3>}
      <McIcon
        id='collapse-filters'
        icon='arrow-to-right'
        size={windowSize === 'small' ? '20' : '24'}
      />
    </Wrapper>
  )
}

export default FilterPanelHeader
