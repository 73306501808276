import styled from 'styled-components'

export const Nav = styled.nav`
  z-index: 11 !important;
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  box-shadow: ${(props) => props.theme.shadows[5]};
  height: 56px;
  width: 100%;
  white-space: nowrap;

  .bar-item {
    display: flex;
    flex-direction: row;
  }

  .utc-time {
    flex-basis: 33.3%;
    text-align: center;
  }

  .logo {
    align-items: center;
    flex-basis: 33.3%;
    padding: 0 12px 0 16px;
    box-sizing: border-box;
    height: 56px;

    > a {
      text-decoration: none;
      color: black;
    }

    .vl {
      border-left: 4px solid ${(props) => props.theme.colors.blue[300]};
      margin: 0 8px;
    }

    sup {
      font-size: 12px;
      margin-left: 6px;
    }
  }

  .toolpane {
    justify-content: flex-end;
    align-self: flex-end;
    flex-basis: 33.3%;
    padding: 0 12px 0;

    > li {
      padding: 0 16px;

      a {
        text-decoration: none;
        display: inline-block;
        border-bottom: 4px solid transparent;
        transition: border-bottom-color 0.1s linear;

        &.active {
          border-bottom-color: ${(props) => props.theme.colors.blue[300]};
        }
      }

      button {
        border: none;
        height: unset;
        padding: 0;
        cursor: pointer;
      }

      a,
      button {
        padding: 0 0 12px 0;
        color: ${(props) => props.theme.colors.grey[900]};

        &:not(.active):hover {
          color: ${(props) => props.theme.colors.blue[600]};
        }
      }
    }
  }
`
