import React, { FunctionComponent } from 'react'
import { formatValue } from '../../../../utils'
import { MergedBatch } from './MixedBatchDetails'
import styled, { grey } from '../../../../theme'
import { LabReportTooltip } from './LabReportTooltip'
import { Icon } from '../../../../commons'

const TooltipContainer = styled.div`
  padding: 8px 0px;
  width: 100%;
  cursor: default;
`

interface TableRowProps {
  batches: MergedBatch[]
}

export const MixedBatchTableRow: FunctionComponent<TableRowProps> = ({
  batches,
}) => {
  return (
    <>
      {batches.length > 0 &&
        batches.map((entry, index) => (
          <React.Fragment key={`name-${index}`}>
            <tr>
              <td>
                <TooltipContainer
                  data-tip
                  data-for={`mixed-batch-lab-report-${entry.id}`}
                >
                  {entry.displayName}{' '}
                  <Icon icon='fas fa-vial' style={{ color: grey[700] }} />
                  <LabReportTooltip
                    toolTipId={`mixed-batch-lab-report-${entry.id}`}
                    labReport={entry.labReport}
                    batchDisplayName={entry.displayName}
                  />
                </TooltipContainer>
              </td>
              <td>{formatValue(entry.quantity, 2)}</td>
            </tr>
          </React.Fragment>
        ))}
    </>
  )
}
