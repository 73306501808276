import { getDifference } from './EnginePerformanceTestReportsComparison.utils'
import { formatValue } from '../../../utils'
import { useVesselConfiguration } from '../../../hooks'
import {
  MainEngInstanceName,
  StaticModelType,
  StaticModelTypeNames,
} from '../../../queries/MasterDataApi/MasterDataApi.consts'

type Props = {
  reportsCompared: Array<GandalfApi.MetcReport>
}

const OperatingConditions = ({ reportsCompared }: Props) => {
  const a = reportsCompared[0].data
  const b = reportsCompared[1].data
  const configuration = useVesselConfiguration(reportsCompared[0].imoNo)

  const equipmentName = (equipment: GandalfApi.Metc.Equipment) => {
    if (!configuration) return '-'

    let instance = ''
    if (
      equipment.staticModelType === StaticModelType.MainEng &&
      configuration.hasTwoMainEngines
    ) {
      instance = MainEngInstanceName[equipment.instanceNo]
    }

    return `${StaticModelTypeNames[equipment.staticModelType]} ${instance}`
  }

  return (
    <>
      <tr>
        <th>Operating conditions</th>
        <th />
        <th />
        <th />
      </tr>
      <tr>
        <td>Equipment</td>
        <td className='mds-table__cell--text-right'>
          {equipmentName(a.equipment)}
        </td>
        <td className='mds-table__cell--text-right'>
          {equipmentName(b.equipment)}
        </td>
        <td className='mds-table__cell--number'>-</td>
      </tr>
      <tr>
        <td>Engine name</td>
        {reportsCompared.map((rc) => (
          <td key={rc.reportId} className='mds-table__cell--text-right'>
            {`${rc.data.equipment.designer} ${rc.data.equipment.engineType}`}
          </td>
        ))}
        <td className='mds-table__cell--number'>-</td>
      </tr>
      <tr>
        <td>Model name</td>
        {reportsCompared.map((rc) => (
          <td key={rc.reportId} className='mds-table__cell--text-right'>
            {rc.data.equipment.name}
          </td>
        ))}
        <td className='mds-table__cell--number'>-</td>
      </tr>
      <tr>
        <td>Engine shaft power [kW]</td>
        {reportsCompared.map((rc) => (
          <td key={rc.reportId} className='mds-table__cell--number'>
            {rc.data.general.enginePower.value}
          </td>
        ))}
        <td className='mds-table__cell--number'>
          {getDifference(
            a.general.enginePower.value!,
            b.general.enginePower.value!,
          )}
        </td>
      </tr>
      <tr>
        <td>Engine load [%MCR]</td>
        {reportsCompared.map((rc) => (
          <td key={rc.reportId} className='mds-table__cell--number'>
            {formatValue(rc.data.result.general.engineLoad, 2)}
          </td>
        ))}
        <td className='mds-table__cell--number'>
          {getDifference(
            a.result.general.engineLoad!,
            b.result.general.engineLoad!,
          )}
        </td>
      </tr>
      <tr>
        <td>Engine run condition [%]</td>
        {reportsCompared.map((rc) => (
          <td key={rc.reportId} className='mds-table__cell--number'>
            {formatValue(rc.data.result.general.engineRunCondition, 1)}
          </td>
        ))}
        <td className='mds-table__cell--number'>
          {getDifference(
            a.result.general.engineRunCondition!,
            b.result.general.engineRunCondition!,
          )}
        </td>
      </tr>
      <tr>
        <td>Engine RPM</td>
        {reportsCompared.map((rc) => (
          <td key={rc.reportId} className='mds-table__cell--number'>
            {formatValue(rc.data.general.shaftRpm.value, 0)}
          </td>
        ))}
        <td className='mds-table__cell--number'>
          {getDifference(a.general.shaftRpm.value!, b.general.shaftRpm.value!)}
        </td>
      </tr>
      <tr>
        <td>Engine running hours</td>
        {reportsCompared.map((rc) => (
          <td key={rc.reportId} className='mds-table__cell--number'>
            {formatValue(rc.data.general.engineRunningHours.value, 0)}
          </td>
        ))}
        <td className='mds-table__cell--number'>
          {getDifference(
            a.general.engineRunningHours.value!,
            b.general.engineRunningHours.value!,
          )}
        </td>
      </tr>
      <tr>
        <td>SFOC measured [g/kWh]</td>
        {reportsCompared.map((rc) => (
          <td key={rc.reportId} className='mds-table__cell--number'>
            {formatValue(rc.data.result.general.sfoc, 2)}
          </td>
        ))}
        <td className='mds-table__cell--number'>
          {getDifference(a.result.general.sfoc!, b.result.general.sfoc!)}
        </td>
      </tr>
      <tr>
        <td>SFOC LCV Corrected to 42700 kg/kJ [g/kWh]</td>
        {reportsCompared.map((rc) => (
          <td key={rc.reportId} className='mds-table__cell--number'>
            {formatValue(rc.data.result.general.sfocLcvCorrected, 2)}
          </td>
        ))}
        <td className='mds-table__cell--number'>
          {getDifference(
            a.result.general.sfocLcvCorrected!,
            b.result.general.sfocLcvCorrected!,
          )}
        </td>
      </tr>
      <tr>
        <td>Pcomp measured [bar]</td>
        {reportsCompared.map((rc) => (
          <td key={rc.reportId} className='mds-table__cell--number'>
            {formatValue(rc.data.result.cylinders.pcompAvg, 2)}
          </td>
        ))}
        <td className='mds-table__cell--number'>
          {getDifference(
            a.result.cylinders.pcompAvg!,
            b.result.cylinders.pcompAvg!,
          )}
        </td>
      </tr>
      <tr>
        <td>Pmax measured [bar]</td>
        {reportsCompared.map((rc) => (
          <td key={rc.reportId} className='mds-table__cell--number'>
            {formatValue(rc.data.result.cylinders.pmaxAvg, 2)}
          </td>
        ))}
        <td className='mds-table__cell--number'>
          {getDifference(
            a.result.cylinders.pmaxAvg!,
            b.result.cylinders.pmaxAvg!,
          )}
        </td>
      </tr>
    </>
  )
}

export default OperatingConditions
