import { useContext } from 'react'

import PerformanceOverview from './PerformanceOverview/PerformanceOverview'
import EquipmentWaste from './EquipmentWaste/EquipmentWaste'
import EnginePerformanceTestReportsCard from './EnginePerformanceTestReportsCard/EnginePerformanceTestReportsCard'
import { useSisterVesselInfo } from '../../queries/MasterDataApi/MasterDataApi'

import { ContentLayout } from '../../layout'
import { VesselPageContext } from '../../contexts'

const ClassComparisonPage = () => {
  const imoNo = useContext(VesselPageContext).imoNo!
  const { data: sisterVesselsData, isSuccess } = useSisterVesselInfo(+imoNo)

  if (!isSuccess) return null

  return (
    <ContentLayout header='Class Comparison'>
      <div className='full-components'>
        <PerformanceOverview sisterVesselsData={sisterVesselsData} />
        <EquipmentWaste sisterVesselsData={sisterVesselsData} />
        <EnginePerformanceTestReportsCard
          sisterVesselsData={sisterVesselsData}
        />
      </div>
    </ContentLayout>
  )
}

export default ClassComparisonPage
