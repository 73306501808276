import * as React from 'react'
import * as ReactDOM from 'react-dom'
import styled, { media, sm, md, lg, maerskBlue } from '../theme'
import { ModalControls, Ul } from '../commons'
import { HelpTextModalContent, getHelpText } from '../features/help-texts'
import { McButton } from '@maersk-global/mds-react-wrapper'

const portalsRoot = document.getElementById('portals')

const ModalComponent = styled.div<{
  minWidth?: string
  notificationBorderLeft?: string
  notificationBorderRight?: string
  notificationBorderRadius?: string
  notificationMargin?: string
}>`
  background-color: white;
  align-self: center;
  border: 1px solid #777;
  border-radius: 4px;
  min-width: ${({ minWidth }) => (minWidth ? minWidth : '320px')};
  max-width: calc(100vw - 72px * 2);

  #closeModal {
    background-color: transparent;
  }

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    ${media.sm`padding: ${sm.CONTENT_CARD_HEADER_PADDING};`};
    ${media.md`padding: ${md.CONTENT_CARD_HEADER_PADDING};`};
    ${media.lg`padding: ${lg.CONTENT_CARD_HEADER_PADDING};`};
    ${media.sm`height: ${sm.BIG_BAR_HEIGHT};`};
    ${media.md`height: ${md.BIG_BAR_HEIGHT};`};
    ${media.lg`height: ${lg.BIG_BAR_HEIGHT};`};
    border-bottom: 1px solid #e0e0e0;
    box-sizing: border-box;
    background-color: #013653;
    color: white;

    h3 {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: normal;

      .help-text-trigger {
        display: inline-flex;
        align-items: center;
        margin-left: 4px;
        background-color: transparent;
      }
    }
    svg {
      color: white;
      height: 18px;
      width: 18px;
    }
  }

  .notification {
    div {
      margin: 0;
      border-left: ${(props) =>
        props.notificationBorderLeft ? props.notificationBorderLeft : 0};
      border-right: ${(props) =>
        props.notificationBorderRight ? props.notificationBorderRight : 0};
      border-radius: ${(props) =>
        props.notificationBorderRadius ? props.notificationBorderRadius : 0};
      ${(props) =>
        props.notificationMargin && `margin: ${props.notificationMargin}`};
    }
    a {
      color: ${maerskBlue[900]};
    }
  }

  .modal-content {
    overflow-y: auto;
    max-height: 80vh;
  }
`

const Backdrop = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100001;
  background-color: rgba(0, 0, 0, 0.4);
`

interface ModalProps {
  visible: boolean
  unClosable?: boolean
  closeHandler: Function
  title?: string | ReadonlyArray<JSX.Element> | JSX.Element
  helpTextKey?: string
  id?: string
  notification?: ReadonlyArray<JSX.Element> | JSX.Element | string
  minWidth?: string
  notificationBorderLeft?: string
  notificationBorderRight?: string
  notificationBorderRadius?: string
  notificationMargin?: string
  className?: string
  children?: React.ReactNode
}

interface ModalState {
  helpTextActive?: boolean
}

export class Modal extends React.Component<ModalProps, ModalState> {
  constructor(props: ModalProps) {
    super(props)
    this.state = {}
  }

  closeOnEscape: (event: any) => void = (event) => {
    if (event.keyCode === 27) {
      this.props.closeHandler(event)
    }
  }

  componentDidMount() {
    if (this.props.visible) {
      document.body.classList.add('scroll-lock')
      document.addEventListener('keydown', this.closeOnEscape, false)
    }
  }

  componentDidUpdate(prevProps: ModalProps) {
    if (!prevProps.visible && this.props.visible) {
      document.body.classList.add('scroll-lock')
      document.addEventListener('keydown', this.closeOnEscape, false)
    } else if (prevProps.visible && !this.props.visible) {
      document.body.classList.remove('scroll-lock')
      document.removeEventListener('keydown', this.closeOnEscape, false)
    }
  }

  componentWillUnmount() {
    if (this.props.visible) {
      document.body.classList.remove('scroll-lock')
      document.removeEventListener('keydown', this.closeOnEscape, false)
    }
  }

  renderHelpText() {
    const helpTextKey = this.props.helpTextKey
    const helpText = helpTextKey ? getHelpText(helpTextKey) : ''
    return (
      <React.Fragment>
        <div className={'title'}>Did you know that...</div>
        <div className='modal-content'>
          <HelpTextModalContent>
            <Ul dangerouslySetInnerHTML={{ __html: helpText }} />
          </HelpTextModalContent>
        </div>
        <div>
          <ModalControls>
            <McButton
              label='Back'
              appearance='primary'
              click={() => {
                this.setState({ helpTextActive: false })
              }}
            />
          </ModalControls>
        </div>
      </React.Fragment>
    )
  }

  renderContent() {
    const {
      helpTextKey,
      title,
      unClosable,
      closeHandler,
      children,
      notification,
    } = this.props
    return (
      <React.Fragment>
        {(title || !unClosable) && (
          <div className={'title'}>
            <h3>
              {title}
              {helpTextKey && (
                <button
                  className='help-text-trigger'
                  onClick={() => this.setState({ helpTextActive: true })}
                >
                  <i className='fal fa-question-circle' />
                </button>
              )}
            </h3>
            {!unClosable && (
              <button
                id='closeModal'
                onClick={(e) => {
                  closeHandler(e)
                }}
              >
                <span className='fal fa-times' />
              </button>
            )}
          </div>
        )}

        {notification && <div className='notification'>{notification}</div>}
        <div className='modal-content-wrapper'>{children}</div>
      </React.Fragment>
    )
  }

  render() {
    const {
      id,
      visible,
      minWidth,
      notificationBorderLeft,
      notificationBorderRight,
      notificationBorderRadius,
      notificationMargin,
      className,
    } = this.props
    const { helpTextActive } = this.state
    if (portalsRoot && visible) {
      return ReactDOM.createPortal(
        <Backdrop>
          <ModalComponent
            id={id}
            className={className}
            minWidth={minWidth}
            notificationBorderLeft={notificationBorderLeft}
            notificationBorderRight={notificationBorderRight}
            notificationBorderRadius={notificationBorderRadius}
            notificationMargin={notificationMargin}
          >
            {helpTextActive && this.renderHelpText()}
            {!helpTextActive && this.renderContent()}
          </ModalComponent>
        </Backdrop>,
        portalsRoot,
      ) as React.ReactNode
    }
    return null
  }
}
