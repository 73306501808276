import * as React from 'react'
import * as ECharts from 'echarts'
import { TorqueOffsetGraphModel } from '../types'
import { Hydra } from '../../../api-models/hydra'
import { formatValue, tooltip } from '../../../utils'
import { grey, maerskBlue, maritimeBlue, red } from '../../../theme'
import { Chart } from '../../../utils/models'
import {
  ACTIVATE_ZOOM_ACTION,
  AXIS_LABEL,
  AXIS_LINE,
  AXIS_SPLIT_LINE,
  AXIS_TICK,
  DATA_ZOOM_TIMELINE,
  GRID,
  TOOLBOX_TIMELINE,
  UNITS,
  X_AXIS_TIMELINE,
} from '../../../utils/constants'

export interface TorqueOffsetChartProps {
  queryPeriod: Hydra.Period
  graphData: TorqueOffsetGraphModel
}

export class TorqueOffsetChart extends React.Component<
  TorqueOffsetChartProps,
  any
> {
  private chartNode: React.RefObject<HTMLDivElement>
  private chart?: Chart

  constructor(props: TorqueOffsetChartProps) {
    super(props)
    this.state = {}
    this.chartNode = React.createRef()
  }

  torqueOffsetOptions(): ECharts.EChartOption {
    const { queryPeriod, graphData } = this.props
    return {
      xAxis: {
        ...X_AXIS_TIMELINE,
        min: queryPeriod.from,
        max: queryPeriod.to,
      },
      yAxis: {
        type: 'value',
        axisTick: AXIS_TICK,
        axisLabel: {
          ...AXIS_LABEL,
          formatter: (value: any) => formatValue(value, 2),
        },
        axisLine: AXIS_LINE,
        splitLine: AXIS_SPLIT_LINE,
      },
      axisPointer: { label: { backgroundColor: 'grey' } },
      grid: { ...GRID, left: 30 },
      toolbox: TOOLBOX_TIMELINE,
      tooltip: {
        trigger: 'axis',
        axisPointer: { type: 'line' },
        textStyle: {
          color: grey[50],
        },
        backgroundColor: 'rgba(50,50,50,0.9)',
        formatter: (params: any) => this.formatTooltip(params),
      },
      animation: false,
      dataZoom: [DATA_ZOOM_TIMELINE, { type: 'select', yAxisIndex: 0 }],
      dataset: {
        source: graphData,
      },
      series: [
        {
          name: 'dynamicOffset',
          type: 'line',
          xAxisIndex: 0,
          yAxisIndex: 0,
          dimensions: ['timestamp', 'dynamicOffsetSeries'],
          itemStyle: { color: maritimeBlue[500] },
        },
        {
          name: 'staticOffset',
          type: 'line',
          xAxisIndex: 0,
          yAxisIndex: 0,
          dimensions: ['timestamp', 'staticOffsetSeries'],
          itemStyle: { color: maerskBlue[500] },
        },
        ...this.thresholdLines(),
      ],
    }
  }

  thresholdLines(): any[] {
    const { lowerThreshold, upperThreshold, timestamp } = this.props.graphData
    const firstTimestamp = timestamp.length > 0 ? timestamp[0] : undefined
    if (upperThreshold !== null && lowerThreshold !== null && firstTimestamp) {
      return [
        {
          name: 'upperThreshold',
          type: 'line',
          yAxisIndex: 0,
          itemStyle: { opacity: 0 },
          markLine: {
            silent: true,
            symbol: 'none',
            lineStyle: { color: red[500] },
            data: [{ yAxis: upperThreshold, label: { show: false } }],
          },
          //Need to set a data point in order for the line to be visible if it's far away from
          // the static and dynamic data series
          data: [[firstTimestamp, upperThreshold]],
        },
        {
          name: 'lowerThreshold',
          type: 'line',
          yAxisIndex: 0,
          itemStyle: { opacity: 0 },
          markLine: {
            silent: true,
            symbol: 'none',
            lineStyle: { color: red[500] },
            data: [{ yAxis: lowerThreshold, label: { show: false } }],
          },
          // Same as previous element (above)
          data: [[firstTimestamp, lowerThreshold]],
        },
      ]
    }
    return []
  }

  formatTooltip(params: any) {
    const {
      timestamp,
      lowerThreshold,
      upperThreshold,
      staticOffsetSeries,
      dynamicOffsetSeries,
    } = this.props.graphData
    const index = params[0].dataIndex
    let tooltipData = [
      {
        label: 'Dynamic',
        value: formatValue(dynamicOffsetSeries[index], 2),
        unit: UNITS.KILO_NEWTON_METER,
      },
      {
        label: 'Static',
        value: formatValue(staticOffsetSeries[index], 2),
        unit: UNITS.KILO_NEWTON_METER,
      },
      {
        label: 'Threshold',
        value: formatValue(upperThreshold, 2),
        unit: UNITS.KILO_NEWTON_METER,
      },
      {
        label: 'Lower threshold',
        value: formatValue(lowerThreshold, 2),
        unit: UNITS.KILO_NEWTON_METER,
      },
    ]
    return tooltip(timestamp[index], tooltipData)
  }

  zoomIn = (action: object) => {
    if (this.chart) {
      this.chart.dispatchAction(action, true)
    }
  }

  componentDidUpdate() {
    if (this.chart) {
      this.chart.setOption(this.torqueOffsetOptions())
      this.chart.on('dataZoom', this.zoomIn)
    }
  }

  componentDidMount() {
    if (this.chartNode.current) {
      const chart = ECharts.init(this.chartNode.current) as Chart
      chart.setOption(this.torqueOffsetOptions())
      chart.dispatchAction(ACTIVATE_ZOOM_ACTION)
      chart.group = 'torque-offset'
      this.chart = chart
    }
  }

  public render() {
    return (
      <div
        onDoubleClick={() => {
          if (this.chart) {
            this.chart.dispatchAction({
              type: 'dataZoom',
              start: 0,
              end: 100,
            })
          }
        }}
        ref={this.chartNode}
      />
    )
  }
}
