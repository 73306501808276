import { McIcon } from '@maersk-global/mds-react-wrapper'
import React from 'react'
import { ApiReportType } from '../../../../api-models/hdc/report'
import { EventTypeName } from '../../../../features/hybrid-data-collector/types'
import { capitalize } from '../../../../utils'

const ORDERED_REPORTS = [
  ApiReportType.DEPARTURE,
  ApiReportType.SEA,
  ApiReportType.ARRIVAL,
  ApiReportType.ALONGSIDE,
  ApiReportType.CANAL,
]

const EVENT_TO_ORDERED_REPORTS = {
  [EventTypeName.ETA_CHANGE]: [false, true, false, false, true],
  [EventTypeName.ROUTE_CHANGE]: [false, true, true, true, true],
  [EventTypeName.TECHNICAL_OFF_SERVICE]: [true, true, true, true, true],
  [EventTypeName.OPERATIONAL_OFF_SERVICE]: [true, true, true, true, true],
  [EventTypeName.ANCHORAGE]: [true, true, true, false, true],
  [EventTypeName.DISCHARGE_SLUDGE]: [true, true, true, true, true],
}

export const Events = React.forwardRef<HTMLDivElement>((props, ref) => (
  <div ref={ref} id='help-section-subtopic'>
    <h4>In this guide you will learn</h4>
    <ul>
      <li>Which events to be reported</li>
      <li>When and how to report events</li>
    </ul>
    <h4>Purpose</h4>
    <p>
      At this point in time there are some events we cannot source and track
      automatically, and we still need your help to report them. Purpose of this
      guide is to give you a quick overview of the event types that must be
      reported in StarConnect.
    </p>
    <h4>Prerequisites</h4>
    <ul>
      <li>
        All data losses within the report period (if any) should be repaired on
        the Consumption page for the report period. You will see Alert in
        StarConnect if there is data loss
      </li>
      <li>
        Your Stock module should be up to date - make sure all bunkering's are
        reported and soundings are up to date
      </li>
    </ul>
    <h4>Event types</h4>
    <p>
      We have following event types that can be reported in the various
      StarConnect report types:
    </p>
    <table>
      <thead>
        <tr>
          <th>Event type / Report type</th>
          {ORDERED_REPORTS.map((reportType, idx) => (
            <th key={idx}>{capitalize(reportType)}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {Object.keys(EVENT_TO_ORDERED_REPORTS).map((eventType, idx) => (
          <tr key={idx}>
            <td>{eventType}</td>
            {EVENT_TO_ORDERED_REPORTS[eventType].map((isInReportType, idx) => (
              <td className='centered' key={idx}>
                {isInReportType && <McIcon icon='check-circle' />}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
    <p>Some rules that apply for all events:</p>
    <ul>
      <li>Must be reported within the report period when the event happens</li>
      <li>Event period cannot exceed the report period</li>
      <li>
        Can be added to / edited / deleted from existing report within the
        15-days period for editing of reports
      </li>
      <li>
        Multiple events of same type can be created within the same report
      </li>
      <li>Visible in both Bridge and Engine section of the report</li>
      <li>All timestamps are UTC time</li>
    </ul>
    <h4>How to report the events</h4>
    <p className='title'>ETA & Route change events</p>
    <p>
      These 2 events are special because they will trigger update of
      respectively ETA, Arrival port and Remaining distance in the Voyage
      section. In the Departure report, you will enter ETA/Arrival
      port/remaining distance for the new voyage directly in the Voyage sections
      and therefore you will not see these events untill the report have been
      submitted.
    </p>
    <span className='note'>
      Please note that we are asking for remaining distance @ closing time of
      the report and not at time for change of route.
    </span>
    <p className='title'>Technical & Operational off service</p>
    <p>
      Selection of off service reason codes can be done either via search field
      or by expanding the blue accordion step by step.
    </p>
    <p>
      Time loss will be calculate based on start/end date but you can overwrite
      the time loss fields if the impact differs from the actual off service
      period.
    </p>
    <p className='title'>Anchorage</p>
    <p>Anchorage periods are simply to be reported with start & end date.</p>
    <p>
      Downstream this information will be used for both various optimisation
      projects but also for enviromental reporting with both EU and IMO.
    </p>
    <p className='title'>Discharge of sludge to shore</p>
    <p>
      It is very important to note that this event reporting do not have any
      impact on the stock module or ROB calculation in SC. All ROB calculations
      are including sludge deduction from stock and is handled automatically
      automatically in the stock module.
    </p>
    <p>
      Reporting of “Discharge of sludge to shore” is for financial follow up
      shore side
    </p>
  </div>
))
