import { createColumnHelper } from '@tanstack/react-table'
import { McIcon, McTooltip } from '@maersk-global/mds-react-wrapper'

import {
  Header,
  IconCell,
  NumericHeader,
  NumericTooltipCell,
} from '../../../../components/Table/Table.styles'
import { formatValue, numberOfMinutesToTime } from '../../../../utils'
import { Performance } from '../../../../api-models'

const columnHelper = createColumnHelper<CounterPage.MainEngine.TableModel>()

export const getColumns = (
  configuration: Performance.VesselConfiguration.Configuration,
) => {
  const hasTcco =
    configuration.mainEng1.hasTcco || configuration.mainEng2.hasTcco

  return [
    columnHelper.accessor('engineName', {
      header: 'Engine',
      enableSorting: false,
    }),
    columnHelper.accessor('power', {
      header: () => <NumericHeader>Power (kW)</NumericHeader>,
      cell: (props) => (
        <McTooltip position='top-center' appearance='inverse'>
          <NumericTooltipCell slot='trigger'>
            {formatValue(props.getValue().value, 0)}
          </NumericTooltipCell>
          <span>
            Data was available for{' '}
            <b>{props.row.original.power.dataAvailability}%</b> of the period
          </span>
        </McTooltip>
      ),
      footer: ({ table }) => {
        const rows = table.getCoreRowModel().rows

        if (rows.length <= 1) return null

        const availability = Math.min(
          ...rows.map((row) => row.original.power.dataAvailability),
        )

        return (
          <McTooltip position='top-center' appearance='inverse'>
            <NumericTooltipCell boldFont slot='trigger'>
              {formatValue(
                rows.reduce(
                  (acc, row) => acc + (row.original.power.value ?? 0),
                  0,
                ),
                0,
              )}
            </NumericTooltipCell>
            <span>
              Data was available for <b>{availability}%</b> of the period
            </span>
          </McTooltip>
        )
      },
      enableSorting: false,
    }),
    columnHelper.accessor('runningMinutes', {
      header: () => <NumericHeader>Running hours (hh:mm)</NumericHeader>,
      cell: (props) => (
        <McTooltip position='top-center' appearance='inverse'>
          <NumericTooltipCell slot='trigger'>
            {numberOfMinutesToTime(props.getValue().value)}
          </NumericTooltipCell>
          <span>
            Data was available for{' '}
            <b>{props.row.original.runningMinutes.dataAvailability}%</b> of the
            period
          </span>
        </McTooltip>
      ),
      enableSorting: false,
    }),
    columnHelper.accessor('shaftRpm', {
      header: () => <NumericHeader>RPM</NumericHeader>,
      cell: (props) => (
        <McTooltip position='top-center' appearance='inverse'>
          <NumericTooltipCell slot='trigger'>
            {props.getValue().value}
          </NumericTooltipCell>
          <span>
            Data was available for{' '}
            <b>{props.row.original.shaftRpm.dataAvailability}%</b> of the period
          </span>
        </McTooltip>
      ),
      enableSorting: false,
    }),
    ...(hasTcco
      ? [
          columnHelper.accessor('tccoPercentage', {
            header: () => <Header icon>TCCO</Header>,
            cell: (props) => (
              <McTooltip position='top-center' appearance='inverse'>
                <IconCell slot='trigger'>
                  {props.getValue().value && props.getValue().value! >= 80 ? (
                    <McIcon icon='check-circle' />
                  ) : props.row.original.tccoPercentage.value ? null : (
                    '—'
                  )}
                </IconCell>
                <span>
                  Data was available for{' '}
                  <b>{props.row.original.tccoPercentage.dataAvailability}%</b>{' '}
                  of the period
                </span>
              </McTooltip>
            ),
            enableSorting: false,
          }),
        ]
      : []),
  ]
}
