import { useState } from 'react'
import Select, { components } from 'react-select'
import styled from 'styled-components'
import { McButton } from '@maersk-global/mds-react-wrapper'

import { InfoBox, Modal, ModalControls } from '../../../../commons'
import { warningTheme } from '../../../../theme'
import { FuelType } from '../../../../utils'
import { type BatchOption } from './FormBatchSelection'

const BatchSelectionContent = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
`

const SelectContainer = styled.div`
  width: 200px;
`

const OptionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const FieldText = styled.span`
  width: 280px;
  font-size: 14px;
  text-align: right;
  margin-right: 8px;
`

const Option = (props) => {
  const { label, hasReport } = props
  return (
    <components.Option {...props}>
      <OptionContainer>
        <div>{label}</div>{' '}
        {hasReport && (
          <InfoBox hideIcons={true} theme={warningTheme}>
            Lab report missing
          </InfoBox>
        )}
      </OptionContainer>
    </components.Option>
  )
}

type Props = {
  batchOptions: BatchOption[]
  closeHandler: () => void
  fuelType?: FuelType
  onSave: (selectedBatchId: string) => void
}

export const FormBatchSelectionModal = ({
  batchOptions,
  closeHandler,
  fuelType,
  onSave,
}: Props) => {
  const [selectedBatchOption, setSelectedBatchOption] = useState<BatchOption>()

  return (
    <Modal
      helpTextKey='consumption-selection/batch-Recovery'
      title={'Change of transfer from storage'}
      visible
      closeHandler={closeHandler}
    >
      <BatchSelectionContent>
        <FieldText>
          {`Which batch of ${
            fuelType ? FuelType[fuelType] : 'the current fuel type'
          } are you consuming?`}
        </FieldText>
        <SelectContainer>
          <Select
            components={{ Option }}
            options={batchOptions}
            isClearable={false}
            isSearchable={false}
            value={selectedBatchOption}
            isOptionDisabled={(option: BatchOption) => option.isDisabled}
            styles={{
              menu: (provided) => ({
                ...provided,
                width: '300px',
              }),
              option: (provided) => ({
                ...provided,
                fontSize: '14px',
              }),
              valueContainer: (provided) => ({
                ...provided,
                fontSize: '14px',
              }),
            }}
            onChange={(option) => setSelectedBatchOption(option as BatchOption)}
          />
        </SelectContainer>
      </BatchSelectionContent>

      <ModalControls>
        <McButton label='Cancel' click={closeHandler} type='button' />
        <McButton
          label='Add transfer'
          appearance='primary'
          disabled={!selectedBatchOption}
          click={() => {
            if (selectedBatchOption) {
              onSave(selectedBatchOption.value)
            }
          }}
          type='button'
        />
      </ModalControls>
    </Modal>
  )
}
