import Operational from './Operational/Operational'
import Technical from './Technical/Technical'
import ScheduleEnginePerformanceTest from './ScheduleMetcReport/ScheduleEnginePerformanceTest'
import { type TagAppearance } from '@maersk-global/mds-components-core/mc-tag/types'

export const Status: Record<Diagnostics.Status, Diagnostics.Status> = {
  Active: 'Active',
  Followed: 'Followed',
  Dismissed: 'Dismissed',
  TimedOut: 'TimedOut',
  AwaitingAction: 'AwaitingAction',
} as const

export const StatusDisplay: Record<Diagnostics.Status, string> = {
  Active: 'Active',
  Followed: 'Followed',
  Dismissed: 'Dismissed',
  TimedOut: 'Timed out',
  AwaitingAction: 'Awaiting action',
} as const

export const StatusTagAppearance: Record<Diagnostics.Status, TagAppearance> = {
  Active: 'info',
  Followed: 'success',
  Dismissed: 'error',
  TimedOut: 'neutral',
  AwaitingAction: 'warning',
} as const

export enum StepState {
  Current = 'current',
  Pending = 'pending',
  Completed = 'completed',
}

export enum CloseAction {
  Submitted = 'Submitted',
  Dismissed = 'Dismissed',
}

export enum Actor {
  Vessel = 'Vessel',
  System = 'System',
  Shore = 'Shore',
}

export enum StepId {
  OperationalIssues = 'operationalIssues',
  TechnicalIssues = 'technicalIssues',
  ScheduleMetc = 'scheduleMetc',
}

export const getDefaultStepsState = (): Diagnostics.StepsState => ({
  operationalIssues: StepState.Pending,
  technicalIssues: StepState.Pending,
  scheduleMetc: StepState.Pending,
})

export const steps: Array<Diagnostics.Step> = [
  {
    idx: 0,
    id: StepId.OperationalIssues,
    heading: 'Operational issues',
    component: Operational,
  },
  {
    idx: 1,
    id: StepId.TechnicalIssues,
    heading: 'Technical issues',
    component: Technical,
  },
  {
    idx: 2,
    id: StepId.ScheduleMetc,
    heading: 'Schedule engine test',
    component: ScheduleEnginePerformanceTest,
  },
]
