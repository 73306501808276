import { Filters, QueryPeriod } from '.'
import * as moment from 'moment'
import { DateRangeFilter } from './components/DateRangeFilterPane'

interface QueryParams {
  [key: string]: number | string | boolean
}

export const setFilterQueryParams = (filters: Filters) => {
  const { dateRange, numberOfDigits, chartSmoothing } = filters
  const queryParams: QueryParams = {}
  if (dateRange) {
    if (dateRange.from) queryParams.from = dateRange.from.toISOString()
    if (dateRange.to) queryParams.to = dateRange.to.toISOString()
    if (dateRange.quickSelected)
      queryParams.quickSelected = dateRange.quickSelected
  }
  if (numberOfDigits) {
    queryParams.numberOfDigits = numberOfDigits
  }
  if (chartSmoothing) {
    queryParams.chartSmoothing = chartSmoothing
  }
  return setQueryParams(queryParams)
}

export const getFiltersFromQueryParams = (): Filters | undefined => {
  if (window.location.search) {
    const filters: Filters = {}
    const search = window.location.search.split('?')[1]
    const queryParams = search.split('&')
    for (let queryParam of queryParams) {
      const [param, value] = queryParam.split('=')
      if (param === 'from')
        filters.dateRange = { ...filters.dateRange, from: moment.utc(value) }
      if (param === 'to')
        filters.dateRange = { ...filters.dateRange, to: moment.utc(value) }
      if (param === 'quickSelected')
        filters.dateRange = {
          ...filters.dateRange,
          quickSelected: parseInt(value),
        }
      if (param === 'numberOfDigits') filters.numberOfDigits = parseInt(value)
      if (param === 'numberOfDigits') filters.chartSmoothing = value === 'true'
    }
    if (Object.keys(filters).length > 0) {
      filters.filtersActive = true
      filters.filterString = window.location.search
    }
    return filters
  }
  return
}

export const setQueryParams = (queryParams: QueryParams): string => {
  const url =
    window.location.pathname +
    `?${Object.keys(queryParams)
      .map((key) => `${key}=${queryParams[key]}`)
      .join('&')}`
  if (url.split('?')[1] !== '') {
    return url
  }
  return window.location.pathname
}

export const clearQueryParams = (): string => {
  return window.location.pathname
}

export const sortDateRange = (
  t1: moment.Moment,
  t2: moment.Moment,
): { to: moment.Moment; from: moment.Moment } => {
  if (t1.isAfter(t2)) {
    return {
      to: t1,
      from: t2,
    }
  }
  return {
    to: t2,
    from: t1,
  }
}

export const getDateRange = (
  defaultDateRange: DateRangeFilter,
  filters?: Filters,
) => {
  if (
    filters &&
    filters.dateRange &&
    filters.dateRange.to &&
    filters.dateRange.from
  ) {
    const { to, from } = sortDateRange(
      filters.dateRange.to,
      filters.dateRange.from,
    )
    return {
      to: to.toISOString(),
      from: from.toISOString(),
    }
  }
  if (defaultDateRange.to && defaultDateRange.from) {
    const { to, from } = sortDateRange(
      defaultDateRange.to,
      defaultDateRange.from,
    )
    return {
      to: to.toISOString(),
      from: from.toISOString(),
    }
  }

  return { to: 'Not available', from: 'Not available' }
}

export const filtersChanged = (f1?: Filters, f2?: Filters) => {
  if (f1 && f2) {
    return f1.filterString !== f2.filterString
  } else if (f1 && !f2) {
    return true
  } else if (!f1 && f2) {
    return true
  }
  return false
}

export const queryPeriodChanged = (q1: QueryPeriod, q2: QueryPeriod) => {
  return q1.from !== q2.from || q1.to !== q2.to
}
