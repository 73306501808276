import * as React from 'react'
import styled, { grey } from '../theme'

const Label = styled.label`
  color: #000;
  font-weight: bold;
  font-size: 14px;
  span {
    padding-left: 4px;
    font-size: 12px;
    font-weight: normal;
    color: ${grey[700]};
  }
`

interface ValueLabelProps {
  unit: string
  children?: React.ReactNode
}

export const ValueLabel = ({ unit, children }: ValueLabelProps) => {
  return (
    <Label>
      {children}
      <span>{unit}</span>
    </Label>
  )
}
