import * as yup from 'yup'

const destinationSchema = yup.object().shape({
  bridge: yup.object().shape({
    arrivalTerminal: yup
      .object()
      .nullable()
      .when('$useCustomArrival', {
        is: false,
        then: yup.object().required('Please enter arrival port'),
      }),
    departureTerminal: yup
      .object()
      .nullable()
      .when('$useCustomDeparture', {
        is: false,
        then: yup.object().required('Please enter a departure or canal port'),
      }),
    alternativeArrivalTerminal: yup
      .object()
      .nullable()
      .when('$useCustomArrival', {
        is: true,
        then: yup
          .object()
          .required('Please enter arrival port information')
          .shape({
            code: yup
              .string()
              .nullable()
              .required('Please enter arrival port code'),
            name: yup
              .string()
              .nullable()
              .required('Please enter arrival port name'),
          }),
      }),
    alternativeDepartureTerminal: yup
      .object()
      .nullable()
      .when('$useCustomDeparture', {
        is: true,
        then: yup
          .object()
          .required('Please enter depature or canal port information')
          .shape({
            code: yup
              .string()
              .nullable()
              .required('Please enter a depature or canal port code'),
            name: yup
              .string()
              .nullable()
              .required('Please enter a depature or canal port name'),
          }),
      }),
  }),
})

export default destinationSchema
