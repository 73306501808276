import { getLatestNotificationResponse } from '../../../pages/ActivityLogPage/TemAdviceLog/TemAdviceLog.utils'
import moment from 'moment'
import {
  DATE_FORMAT,
  TIME_FORMAT,
} from '../../formik/InputDateTime/InputDateTime.utils'

export const TEM_SUBMIT_MODAL = {
  ADVICE_STATIC_TEXT:
    'An alarm is visible to Vessel Performance Management Team. Please give a reason for dismissing the energy advice.',
  ROWS: 4,
  MAX_LENGTH: 200,
}

export const getDefaultValues = (
  notification: NotificationsAPI.TemAdvice,
): TemSubmitModalTypes.SubmitModalInputs => {
  const notificationResponse = getLatestNotificationResponse(notification)

  if (!notificationResponse) {
    return {
      reason: '',
      comment: '',
      date: '',
      time: '',
      noSparePartsOnBoard: false,
    }
  }

  const { data: notificationResponseData } = notificationResponse

  const _date = notificationResponse.ends_at
  const date = moment.utc(_date).format(DATE_FORMAT)
  const time = moment.utc(_date).format(TIME_FORMAT)
  const reason = notificationResponseData.reason || ''
  const comment = notificationResponseData.comment || ''

  return {
    reason: reason,
    comment: comment,
    date: date,
    time: time,
    noSparePartsOnBoard: notificationResponseData.noSparePartsOnBoard,
  }
}

export const getExpectedTimeSetting = (
  noSparePartsOnBoard: boolean,
  reasonConfig?: TemSubmitModalTypes.ReasonConfigType,
) => {
  let maxExpectedDate = ''
  let dateLimitHint = ''

  if (reasonConfig && reasonConfig.maxDays) {
    if (noSparePartsOnBoard && reasonConfig.increasedMaxDays !== undefined) {
      maxExpectedDate = getMaxExpectedDate(
        reasonConfig.increasedMaxDays,
        'days',
      )
      dateLimitHint = getDateLimitHint(reasonConfig.increasedMaxDays, 'day')
    } else {
      maxExpectedDate = getMaxExpectedDate(reasonConfig.maxDays, 'days')
      dateLimitHint = getDateLimitHint(reasonConfig.maxDays, 'day')
    }
  } else if (reasonConfig && reasonConfig.maxHours) {
    maxExpectedDate = getMaxExpectedDate(reasonConfig.maxHours, 'hours')
    dateLimitHint = getDateLimitHint(reasonConfig.maxHours, 'hour')
  }

  return {
    minExpectedDate: moment.utc().format(DATE_FORMAT),
    maxExpectedDate,
    dateLimitHint,
  }
}

const getMaxExpectedDate = (count: number, unit: 'hours' | 'days') => {
  return moment.utc().add(count, unit).format(DATE_FORMAT)
}

const getDateLimitHint = (count: number, unit: 'hour' | 'day') => {
  let hint = `Maximum ${count} ${unit}`
  if (count > 1) {
    hint += 's'
  }
  return hint
}
