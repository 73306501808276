import { Management } from '../../api-models/management'
import { mapVesselMasterDataToVesselRegistry } from '../MasterDataApi/MasterDataApi.utils'

export const getFavouriteLists = (
  favourites: ManagementApi.Favourites.Response,
  basicInfo: MasterDataApi.VesselParameters.General.BasicInfo.VesselsResponse,
): Array<Management.Favourites.FavouriteList> => {
  return favourites.map((favouriteList) => {
    const vessels = favouriteList.imoNos.flatMap((imoNo) => {
      const vessel = basicInfo.find((vessel) => vessel.imoNo === imoNo)
      if (!vessel) return []
      return mapVesselMasterDataToVesselRegistry([vessel])
    })

    return {
      name: favouriteList.name,
      vessels: vessels,
    }
  })
}
