import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  > p {
    opacity: 0.3;
    font-size: var(--mds_brand_typography_text_x-small_desktop_font-size);
    text-transform: uppercase;
  }
`
