import * as React from 'react'
import styled from '../theme'

interface DropdownProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  triggerChildren: ReadonlyArray<JSX.Element> | JSX.Element | string
  menuPosition?: {
    bottom?: string
    top?: string
    left?: string
    right?: string
  }
  liHoverColor?: string
  liPadding?: string
  ulClassName?: string
  triggerId?: string
  menuContentWidth?: string
}

interface DropdownState {
  showMenu: boolean
}

const MenuWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;

  .trigger {
    height: 100%;
    width: 100%;
    background-color: transparent;

    &:focus {
      outline: none;
    }

    > mc-icon {
      display: inline-block;
    }
  }
`

const MenuContent = styled.ul<{
  liHoverColor?: string
  width?: string
  liPadding?: string
}>`
  ${(props) => props.width && `width: ${props.width}`}
  position: absolute;
  background-color: #fff;
  border: 1px solid #ededed;
  border-radius: 3px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  min-width: 168px;
  list-style-type: none !important;
  margin: 0;
  padding: 0;
  z-index: 1000;

  li {
    padding: ${({ liPadding }) => (liPadding ? liPadding : '8px')} !important;
    min-width: fit-content !important;
    &:hover {
      background-color: ${(props) =>
        props.liHoverColor ? props.liHoverColor : '#e0f0f6'};
    }
  }

  > hr {
    padding: 0;
    &:hover {
      background-color: transparent;
    }
  }
`

export class Dropdown extends React.Component<DropdownProps, DropdownState> {
  constructor(props: DropdownProps) {
    super(props)
    this.state = {
      showMenu: false,
    }
  }

  render() {
    const {
      ulClassName,
      liHoverColor,
      liPadding,
      children,
      triggerChildren,
      menuPosition,
      triggerId,
      menuContentWidth,
      ...restProps
    } = this.props
    return (
      <MenuWrapper id={restProps.id}>
        <button
          className='trigger'
          id={triggerId ? triggerId : ''}
          onClick={() => {
            this.setState({ showMenu: !this.state.showMenu })
          }}
        >
          {triggerChildren}
        </button>
        {this.state.showMenu ? (
          <MenuContent
            width={menuContentWidth}
            className={ulClassName ? ulClassName : ''}
            liHoverColor={liHoverColor}
            liPadding={liPadding}
            style={menuPosition}
            onMouseLeave={() => {
              this.setState({ showMenu: false })
            }}
          >
            {children}
          </MenuContent>
        ) : null}
      </MenuWrapper>
    )
  }
}
