import { Table, Tbody, Th, Thead, Tr } from '../../../commons/tables/styles'
import { UNITS } from '../../../utils/constants'
import { FormEquipment, HDCReportFormValues } from '../types'
import { equipmentName, getOriginalValueNote } from '../utils'
import ReadonlyField from './readonly-field'
import { EquipmentTh, ReadonlyRuntimeTh } from './styles'

type Props = {
  report: HDCReportFormValues
}

const ReadonlyElectricalConsumersTable = ({ report }: Props) => {
  const { engineRoom } = report
  const { auxBlowers, scrubberSystem, shaftMotors } = engineRoom

  const getSection = (consumer: FormEquipment, title: string, idx = 0) => (
    <Tr key={`${title}-${idx}`}>
      <td>{title}</td>
      <td>
        <ReadonlyField
          addon={UNITS.KILO_WATT_HOUR}
          name={`${title}-${idx}-energy`}
          note={getOriginalValueNote(consumer.energy)}
          value={consumer.energy.value ?? '-'}
        />
      </td>
      <td>
        <ReadonlyField
          name={`${title}-${idx}-runtime`}
          note={getOriginalValueNote(consumer.runTime)}
          value={`${consumer.runTime.hours.value ?? '-'}h ${
            consumer.runTime.minutes.value ?? '-'
          }m`}
        />
      </td>
    </Tr>
  )

  return (
    <Table>
      <Thead>
        <Tr>
          <EquipmentTh>Consumer</EquipmentTh>
          <Th>Energy</Th>
          <ReadonlyRuntimeTh>Runtime</ReadonlyRuntimeTh>
        </Tr>
      </Thead>
      <Tbody striped={true}>
        {shaftMotors.map((motor, idx) =>
          getSection(
            motor,
            `Shaft motors ${equipmentName(shaftMotors.length, motor)}`,
            idx,
          ),
        )}
        {auxBlowers.map((blower, idx) =>
          getSection(
            blower,
            `Aux blowers ${equipmentName(auxBlowers.length, blower)}`,
            idx,
          ),
        )}
        {scrubberSystem && getSection(scrubberSystem, 'Scrubber system')}
      </Tbody>
    </Table>
  )
}

export default ReadonlyElectricalConsumersTable
