import React from 'react'

const DisconnectedIcon = (props) => {
  return (
    <svg
      {...props}
      xmlns='http://www.w3.org/2000/svg'
      data-name='Layer 1'
      viewBox='0 0 150 150.9'
    >
      <path d='m0 140.9 19.4-19c-5.7-9.7-10.6-24.9 2.3-40.2l14.4-14.3 6.9 6.8 20.1-20.1a4.7 4.7 0 0 1 6.7 0 4.8 4.8 0 0 1 0 6.8l-20.2 20L69.8 101l20.8-20.5a4.5 4.5 0 0 1 6.2 0 4.2 4.2 0 0 1 .1 6l-20.8 20.8L83 114s-10.7 11.5-14.5 15c-11.2 10.3-27.8 9.6-39.8 2.5-6 5.4-19.2 19.3-19.2 19.3ZM66.7 36.4 114 83.8l16.2-16.5c11.5-11.8 8.5-31.2 1-38L150 10l-9.3-10-19.3 19.4c-7-6-26-10.7-38.8 1.6L66.7 36.4Z' />
    </svg>
  )
}

const EconomizerIcon = (props) => {
  return (
    <svg
      {...props}
      xmlns='http://www.w3.org/2000/svg'
      data-name='Layer 1'
      viewBox='0 0 36.8 64.8'
    >
      <title>{props.title ? props.title : 'economizer'}</title>
      <path
        fill='#003e5e'
        d='M37 14 28 3l3-3H6l2 3-8 11v37l8 11-2 3h25l-3-3 9-11V14z'
      />
      <path
        fill='#FFF'
        d='m28 43-14-3 15-6a2 2 0 0 0 0-3l-15-6 14-3a2 2 0 0 0 1-3l-9-5V0h-3v15a2 2 0 0 0 1 1l6 4-16 3a2 2 0 0 0 0 3l16 6-16 7a2 2 0 0 0 0 3l16 3-6 5a2 2 0 0 0-1 1v14h3V52l9-6a2 2 0 0 0-1-3Z'
      />
    </svg>
  )
}

export default {
  'cv-disconnected': DisconnectedIcon,
  'cv-economizer': EconomizerIcon,
} as { [key: string]: any }
