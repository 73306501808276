import { useMemo, useRef } from 'react'
import { Field, useField, useFormikContext } from 'formik'
import { type Moment } from 'moment/moment'

import { type HDCReportFormValues } from '../types'

import DateField, {
  type DateFieldProps,
} from '../../../components/form/date-field'

interface Props extends DateFieldProps {
  error?: string
  hasWarning?: boolean
  maxWidth?: string
  name: string
  icon?: string
  showSensorValue?: boolean
  dateStart: string
  dateEnd: string
  openToDate?: Moment
}

const HDCDateField = ({
  error,
  hasWarning,
  maxWidth,
  name,
  icon,
  note,
  openToDate,
  showSensorValue = true,
  ...dateFieldProps
}: Props) => {
  const [field, meta] = useField(name)
  const fieldValue = useRef(field.value).current
  const renderField = useMemo(
    () => typeof fieldValue !== 'undefined',
    [fieldValue],
  )
  const { setFieldValue } = useFormikContext<HDCReportFormValues>()

  if (!renderField) {
    console.error(
      `HDCInputField for name "${name}" was unable to render as it was not part of a form.`,
    )
    return null
  }

  const handleBlur = (event) => {
    const target = event.target as HTMLInputElement
    let value: string = target.value

    const shouldValidate = !!error || !!meta.error
    void setFieldValue(name, value, shouldValidate)
  }

  const handleDateFocus = () => {
    if (field.value !== '' || !openToDate) return
    void setFieldValue(name, openToDate.utc().toISOString())
  }

  return (
    <Field
      {...dateFieldProps}
      component={DateField}
      hasWarning={hasWarning}
      id={name}
      isInvalid={!!error || !!meta.error}
      maxWidth={maxWidth}
      icon={icon}
      onBlur={handleBlur}
      onFocus={handleDateFocus}
      value={field.value ?? ''}
    />
  )
}

export default HDCDateField
