import { BatchTransferForm } from './BatchTransferForm'
import { Performance } from '../../../../api-models'
import { Modal } from '../../../../commons'
import { FuelType } from '../../../../utils'

type Props = {
  fuelTypeForBatchSelection: FuelType
  currentlySelectedBatch?: Performance.FuelOilStock.CurrentBatchSelectionResponse
  closeHandler: () => void
  submit: (
    batchSelection: Performance.FuelOilStock.BatchSelection,
  ) => Promise<Performance.FuelOilStock.BatchSelection>
}

export const BatchTransferModal = ({
  fuelTypeForBatchSelection,
  closeHandler,
  submit,
  currentlySelectedBatch,
}: Props) => {
  return (
    <Modal
      helpTextKey='consumption-selection/batch'
      title={'Change of transfer from storage'}
      visible
      closeHandler={closeHandler}
    >
      <BatchTransferForm
        fuelTypeForBatchSelection={fuelTypeForBatchSelection}
        currentlySelectedBatch={currentlySelectedBatch}
        closeHandler={closeHandler}
        submit={submit}
      />
    </Modal>
  )
}
