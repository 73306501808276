import { useState, useEffect, useContext } from 'react'
import moment from 'moment'
import { McButton } from '@maersk-global/mds-react-wrapper'

import { VesselPageContext } from '../../../../contexts'
import { Modal, ModalControls, Loading } from '../../../../commons'
import styled, { FuelColors, grey, sizes } from '../../../../theme'
import {
  displayErrorModal,
  FuelType as FuelTypeEnum,
  FuelType,
} from '../../../../utils'
import { Performance } from '../../../../api-models'
import * as PerformanceApi from '../../../../services/performance'
import { UNITS } from '../../../../utils/constants'

const ModalContent = styled.section`
  padding: ${sizes.spacing}px 32px 0;
  overflow-y: auto;
  max-height: 80vh;
`

const Header = styled.header`
  width: 100%;
  color: ${grey[700]};
  font-size: 16px;
  margin-top: 16px;
`

const DetailSectionContainer = styled.div`
  width: 100%;
  display: flex;
  margin: 15px 0 0 0;
`

const HorizontalLine = styled.hr<{ width?: string }>`
  width: ${({ width }) => width || '100%'};
  margin: 8px 0;
`

const DetailSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: unset;
  width: 50%;
`

const RowContainer = styled.div`
  margin-bottom: 16px;
`

const ValueRow = styled.div`
  display: flex;
  align-items: center;
`

const Title = styled.div`
  font-weight: bold;
  padding-right: 5px;
  margin-bottom: 8px;
  display: block;
  font-size: 14px;
`

const Value = styled.div`
  display: block;
  margin-right: 5px;
  font-size: 14px;
  color: ${grey[700]};
`

const Notes = styled.div`
  overflow-y: scroll;
  min-height: 32px;
  max-height: 200px;
  max-width: 632px;
  font-size: 14px;
  white-space: pre-line;
`

const Unit = styled.div`
  display: block;
  font-size: 14px;
`

const FuelTypeBadge = styled.div<{
  fill: string
  font: string
  stroke: string
}>`
  display: flex;
  align-items: center;
  border: ${({ stroke }) => `1px solid ${stroke}`};
  background: ${({ fill }) => fill};
  color: ${({ font }) => font};
  cursor: not-allowed;
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 14px;
  margin-right: 8px;
`

interface BatchDetail {
  dataPath: string
  label?: string
  unit?: string
}

const batchDetailsCols: Array<BatchDetail[]> = [
  [
    { dataPath: 'timestamp', label: 'Time of bunkering' },
    { dataPath: 'fuel.grade', label: 'Fuel grade' },
    { dataPath: 'fuel.type', label: 'Fuel type' },
    {
      dataPath: 'fuel.bioPercentage',
      label: 'Percentage bio',
      unit: UNITS.MASS,
    },
    { dataPath: 'deliveryDetails.portCode', label: 'Bunker Port' },
  ],
  [
    { dataPath: 'deliveryDetails.orderId', label: 'Order ID' },
    {
      dataPath: 'deliveryDetails.quantityOrdered',
      label: 'Quantity ordered',
      unit: UNITS.METRIC_TON,
    },
    {
      dataPath: 'quantityPerChiefEngineer',
      label: 'Quantity received according to C/E',
      unit: UNITS.METRIC_TON,
    },
    {
      dataPath: 'deliveryDetails.quantityAgreed',
      label: 'Quantity agreed with supplier (BDN)',
      unit: UNITS.METRIC_TON,
    },
  ],
]

const batchLabCols: Array<BatchDetail[]> = [
  [
    { dataPath: 'labReport.marpolSeal', label: 'Marpol seal' },
    {
      dataPath: 'labReport.density15',
      label: 'Density 15',
      unit: UNITS.DENSITY_FIFTEEN,
    },
    { dataPath: 'labReport.lcv', label: 'LCV', unit: UNITS.KJ_PER_KILO },
  ],
  [
    {
      dataPath: 'labReport.water',
      label: 'Water',
      unit: UNITS.PERCENTAGE_VOLUME,
    },
    { dataPath: 'labReport.ash', label: 'Ash', unit: UNITS.MASS },
    { dataPath: 'labReport.sulphur', label: 'Sulphur', unit: UNITS.MASS },
  ],
]

const FuelTypeRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  p {
    font-size: 12px;
    color: ${grey[700]};
  }
`

const renderFuelType = (
  batch: Performance.FuelOilStock.BunkeredBatch,
  key?: string,
) => {
  const { isDistillate, type } = batch.fuel
  const showDistillateLabel = type === 2 || type === 4
  const colorScheme = FuelColors[FuelTypeEnum[type]]

  return (
    <RowContainer {...(key && { key: key })}>
      <Title>Fuel type</Title>
      <FuelTypeRow>
        <FuelTypeBadge {...colorScheme}>{FuelTypeEnum[type]}</FuelTypeBadge>
        {showDistillateLabel && (
          <p>{isDistillate ? 'Distillate' : 'Residual'}</p>
        )}
      </FuelTypeRow>
    </RowContainer>
  )
}

const getObjectValue = (rootObject, accessor) =>
  accessor.split('.').reduce((obj, accessor) => obj[accessor], rootObject)

const shouldPrintProp = (
  batch: Performance.FuelOilStock.BunkeredBatch,
  accessor: string,
): boolean => {
  if (accessor === 'fuel.isDistillate') {
    return [FuelType.ULS, FuelType.VLS].includes(batch.fuel.type)
  }
  return true
}

type Props = {
  visible: boolean
  closeHandler: () => void
  bunkeredBatch?: Performance.FuelOilStock.BunkeredBatchResponse
  batchId?: string
}

const BatchDetailsModal = ({
  visible,
  batchId,
  bunkeredBatch,
  closeHandler,
}: Props) => {
  const [batch, setBatch] = useState<Performance.FuelOilStock.BunkeredBatch>()
  const imoNo = useContext(VesselPageContext).imoNo!
  useEffect(() => {
    if (!!batchId) {
      PerformanceApi.getBunkeredBatch(imoNo, batchId)
        .then(setBatch)
        .catch((e) =>
          displayErrorModal({
            statusText: 'Could not get debunkering',
            message: e.message,
          }),
        )
    } else if (!!bunkeredBatch) {
      setBatch(bunkeredBatch)
    }
  }, [batchId, bunkeredBatch, imoNo])
  return (
    <Modal
      visible={visible}
      closeHandler={closeHandler}
      title='Bunkered batch details'
      minWidth='600px'
      helpTextKey='stock/bunkering/view'
    >
      {batch ? (
        <>
          <ModalContent>
            <Header>{batch.displayName}</Header>
            <HorizontalLine />
            <DetailSectionContainer>
              {batchDetailsCols.map((rows: BatchDetail[], idx) => (
                <DetailSection key={`batchDetailsCols-${idx}`}>
                  {rows.map((row) => {
                    let value = getObjectValue(batch, row.dataPath)
                    if (row.dataPath === 'timestamp') {
                      value = moment.utc(value).format('DD MMM YYYY HH:mm UTC')
                    }

                    const key = `${row.dataPath}-1-container`

                    if (row.label === 'Fuel type') {
                      return renderFuelType(batch, key)
                    }

                    return (
                      shouldPrintProp(batch, row.dataPath) && (
                        <RowContainer key={key}>
                          <Title>{row.label}</Title>
                          {row.label === 'Fuel type' && renderFuelType(batch)}
                          <ValueRow>
                            <Value>{value}</Value>
                            {row.unit && <Unit>{row.unit}</Unit>}
                          </ValueRow>
                        </RowContainer>
                      )
                    )
                  })}
                </DetailSection>
              ))}
            </DetailSectionContainer>
            <Header>Notes</Header>
            <HorizontalLine />
            {(batch.notes && <Notes>{batch.notes}</Notes>) || '-'}
            <Header>Lab report</Header>
            <HorizontalLine />
            <DetailSectionContainer>
              {batchLabCols.map((rows: BatchDetail[], idx) => (
                <DetailSection key={`batchLabCols-${idx}`}>
                  {rows.map(
                    (row) =>
                      shouldPrintProp(batch, row.dataPath) && (
                        <RowContainer key={`${row.dataPath}-1-container`}>
                          <Title>{row.label}</Title>
                          <ValueRow>
                            <Value>{getObjectValue(batch, row.dataPath)}</Value>
                            {row.unit && <Unit>{row.unit}</Unit>}
                          </ValueRow>
                        </RowContainer>
                      ),
                  )}
                </DetailSection>
              ))}
            </DetailSectionContainer>
          </ModalContent>
          <ModalControls>
            <McButton
              label='Close'
              appearance='primary'
              click={closeHandler}
              type='button'
            />
          </ModalControls>
        </>
      ) : (
        <Loading />
      )}
    </Modal>
  )
}

export default BatchDetailsModal
