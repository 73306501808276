import React from 'react'
import styled, { grey } from '../theme'

interface ModalControlsProps {
  errorMessage?: string
  children?: any
}

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  box-sizing: border-box;
  background-color: ${grey[100]};
  border-top: 1px solid ${grey[200]};
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  position: relative;

  .description {
    display: block;
    width: 100%;
    font-size: 14px;
    padding-bottom: 8px;
  }

  .left {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
`

const ButtonContainer = styled.div`
  flex-direction: row;
  justify-content: flex-end;
  text-align: right;

  button,
  mc-button {
    text-align: center;
    display: inline-block;
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }
`

const ErrorMessage = styled.div`
  flex-direction: row;
  justify-content: flex-end;
  text-align: right;
  margin-bottom: 10px;
`

export const ModalControls = (props: ModalControlsProps) => (
  <ModalContainer>
    {props.errorMessage && (
      <ErrorMessage className='form-error'>{props.errorMessage}</ErrorMessage>
    )}
    <ButtonContainer>{props.children}</ButtonContainer>
  </ModalContainer>
)
