import { Performance } from '../../api-models'
import {
  FuelConsumptionStatusResponse,
  FuelLineTotalizer,
} from '../../api-models/performance/fuel-consumption'
import {
  ManualCorrectionRepair,
  ManualCorrectionOverwrite,
  ManualCorrectionRepairs,
  ManualCorrections,
  ManualCorrectionConsumedMass,
} from '../../api-models/performance/manual-corrections'
import { QueryPeriod } from '../../features/filter'
import { LUBE_OIL_ENTRY_TYPE_NAME } from './../../pages/lube-oil/constants'
import { doGet, doPost, doPut, getPerformanceApiUrl } from '../../utils'
import { doDelete, createQueryParams } from './../../utils/request-utils'
import {
  TLubeOilOpenWindow,
  TLubeOilStatus,
} from '../../api-models/performance/lube-oil'
import {
  ELubeOilEntryType,
  TLubeOilBunkering,
  TLubeOilEntry,
  TLubeOilLoss,
  TLubeOilSounding,
  TLubeOilTransfer,
} from '../../pages/lube-oil/types'

// System

export const getSystemTime = (): Promise<Performance.System.Clock> =>
  doGet(`${getPerformanceApiUrl()}/System/Clock`)

export const getSystemMode = (): Promise<Performance.System.Mode> =>
  doGet(`${getPerformanceApiUrl()}/System/Mode`)

export const getConnectedVesselsImoNo = (): Promise<number[]> =>
  doGet(`${getPerformanceApiUrl()}/System/ImoNos`)

// Shore mode

export const getConfigurations = (
  imoNos: string[],
): Promise<Performance.Shore.ConfigurationResponse[]> =>
  doPost(`${getPerformanceApiUrl()}/Shore/Vessel/Configurations`, {
    imoNos: imoNos,
  })

export const getVesselConnectionStatuses = (): Promise<
  Performance.Shore.ConnectionStatusResponse[]
> => doGet(`${getPerformanceApiUrl()}/Shore/Vessel/ConnectionStatuses`)

// Vessel mode
export const getVesselSettings = (
  imoNo: string,
): Promise<Performance.VesselSettings.Settings> =>
  doGet(`${getPerformanceApiUrl()}/Vessel/${imoNo}/Settings`)

export const getVesselStatus = (
  imoNo: string,
): Promise<Performance.Status.Status> =>
  doGet(`${getPerformanceApiUrl()}/Vessel/${imoNo}/Status`)

export const getVesselConfiguration = (
  imoNo: number,
): Promise<Performance.VesselConfiguration.Configuration> =>
  doGet(`${getPerformanceApiUrl()}/Vessel/${imoNo.toString()}/Configuration`)

export const postVesselSettings = (imoNo: string, data: any): Promise<any> =>
  doPost(`${getPerformanceApiUrl()}/Vessel/${imoNo}/Settings`, data)

export const getExport = (
  imoNo: string,
  selectedExport: number,
  period: Performance.Common.QueryPeriod,
): Promise<Performance.Export.Report> =>
  doGet(
    `${getPerformanceApiUrl()}/Vessel/${imoNo}/Export/Report?reportType=${selectedExport}&period.from=${
      period.from
    }&period.to=${period.to}`,
  )

export const getTrimMonitor = (
  imoNo: string,
  period: QueryPeriod,
): Promise<Performance.Trim.Monitor> =>
  doGet(
    `${getPerformanceApiUrl()}/Vessel/${imoNo}/Trim/Monitor?period.from=${
      period.from
    }&period.to=${period.to}`,
  )

export const getWHRPowerMainEngLoad = (
  imoNo: string,
  queryPeriod: QueryPeriod,
): Promise<Performance.WHR.PowerMainEngLoadResponse> =>
  doGet(
    `${getPerformanceApiUrl()}/Vessel/${imoNo}/WHR/PowerMainEngLoad?period.from=${
      queryPeriod.from
    }&period.to=${queryPeriod.to}`,
  )

export const getCounter = (
  imoNo: string,
  period: QueryPeriod,
): Promise<Performance.Counter.CounterResponse> =>
  doGet(
    `${getPerformanceApiUrl()}/Vessel/${imoNo}/Counter?period.from=${
      period.from
    }&period.to=${period.to}`,
  )

// FuelOilStock
export const getCurrentBatchSelections = (
  imoNo: string,
): Promise<Performance.FuelOilStock.CurrentBatchSelectionResponse[]> =>
  doGet(
    `${getPerformanceApiUrl()}/Vessel/${imoNo}/FuelOil/Stock/BatchSelections/Current`,
  )

export const postBatchSelection = (
  imoNo: string,
  data: Performance.FuelOilStock.BatchSelection,
): Promise<Performance.FuelOilStock.BatchSelection> =>
  doPost(
    `${getPerformanceApiUrl()}/Vessel/${imoNo}/FuelOil/Stock/BatchSelection`,
    data,
  )

export const putBatchSelection = (
  imoNo: string,
  id: string,
  data: Performance.FuelOilStock.BatchSelection,
): Promise<any> =>
  doPut(
    `${getPerformanceApiUrl()}/Vessel/${imoNo}/FuelOil/Stock/BatchSelection/${id}`,
    data,
  )

export const deleteBatchSelection = (imoNo: string, id: string): Promise<any> =>
  doDelete(
    `${getPerformanceApiUrl()}/Vessel/${imoNo}/FuelOil/Stock/BatchSelection/${id}`,
  )

export const getBatchSelection = (imoNo: string, id: string): Promise<any> =>
  doGet(
    `${getPerformanceApiUrl()}/Vessel/${imoNo}/FuelOil/Stock/BatchSelection/${id}`,
  )

// FuelOilStock
export const getFuelOilUserEntries = (
  imoNo: string,
): Promise<Performance.FuelOilStock.UserEntry[]> =>
  doGet(`${getPerformanceApiUrl()}/Vessel/${imoNo}/FuelOil/UserEntries`)

// VesselStockFuelOil
export const getBatches = (
  imoNo: string,
  ids?: string | string[],
  isOnBoard?: boolean,
): Promise<Performance.FuelOilStock.BatchResponse[]> => {
  const params: { [key: string]: any } = {}
  if (ids) {
    params.ids = ids
  }
  if (typeof isOnBoard !== 'undefined') {
    params.isOnBoard = isOnBoard
  }
  const queryParams = createQueryParams(params)
  return doGet(
    `${getPerformanceApiUrl()}/Vessel/${imoNo}/FuelOil/Stock/Batches${queryParams}`,
  )
}

export const getBatchesById = (
  imoNo: string,
  batchIds: string[],
  isOnBoard?: boolean,
): Promise<Performance.FuelOilStock.BatchResponse[]> =>
  doGet(
    `${getPerformanceApiUrl()}/Vessel/${imoNo}/FuelOil/Stock/Batches?ids=${batchIds.join(
      ',',
    )}${typeof isOnBoard === 'boolean' ? '&isOnBoard=' + isOnBoard : ''}`,
  )

export const getBunkeredBatch = (
  imoNo: string,
  id: string,
): Promise<Performance.FuelOilStock.BunkeredBatchResponse> =>
  doGet(
    `${getPerformanceApiUrl()}/Vessel/${imoNo}/FuelOil/Stock/BunkeredBatch/${id}`,
  )

export const getMixedBatch = (
  imoNo: string,
  id: string,
): Promise<Performance.FuelOilStock.MixedBatchEntryResponse> =>
  doGet(
    `${getPerformanceApiUrl()}/Vessel/${imoNo}/FuelOil/Stock/MixedBatch/${id}`,
  )

export const postMixedBatch = (
  imoNo: string,
  data: Performance.FuelOilStock.MixedBatchEntryRequest,
): Promise<any> =>
  doPost(
    `${getPerformanceApiUrl()}/Vessel/${imoNo}/FuelOil/Stock/MixedBatch`,
    data,
  )

export const deleteMixedBatch = (imoNo: string, id: string): Promise<any> =>
  doDelete(
    `${getPerformanceApiUrl()}/Vessel/${imoNo}/FuelOil/Stock/MixedBatch/${id}`,
  )

export const putMixedBatch = (
  imoNo: string,
  id: string,
  data: Performance.FuelOilStock.MixedBatchEntryUpdateRequest,
) =>
  doPut(
    `${getPerformanceApiUrl()}/Vessel/${imoNo}/FuelOil/Stock/MixedBatch/${id}`,
    data,
  )

export const postBunkeredBatch = (
  imoNo: string,
  data:
    | Performance.FuelOilStock.BunkeredBatch
    | Performance.FuelOilStock.BunkeredBatchResponse,
): Promise<any> =>
  doPost(
    `${getPerformanceApiUrl()}/Vessel/${imoNo}/FuelOil/Stock/BunkeredBatch`,
    data,
  )

export const putBunkeredBatch = (
  imoNo: string,
  data:
    | Performance.FuelOilStock.BatchResponse
    | Performance.FuelOilStock.BunkeredBatch,
  id: string,
): Promise<any> =>
  doPut(
    `${getPerformanceApiUrl()}/Vessel/${imoNo}/FuelOil/Stock/BunkeredBatch/${id}`,
    data,
  )

export const deleteBunkeredBatch = (imoNo: string, id: string): Promise<any> =>
  doDelete(
    `${getPerformanceApiUrl()}/Vessel/${imoNo}/FuelOil/Stock/BunkeredBatch/${id}`,
  )

export const postSounding = (
  imoNo: string,
  data: Performance.FuelOilStock.Sounding,
): Promise<any> =>
  doPost(
    `${getPerformanceApiUrl()}/Vessel/${imoNo}/FuelOil/Stock/Sounding`,
    data,
  )

export const getSounding = (
  imoNo: string,
  id: string,
): Promise<Performance.FuelOilStock.SoundingEntry> =>
  doGet(
    `${getPerformanceApiUrl()}/Vessel/${imoNo}/FuelOil/Stock/Sounding/${id}`,
  )

export const putSounding = (
  imoNo: string,
  data: Performance.FuelOilStock.Sounding,
  id: string,
): Promise<any> =>
  doPut(
    `${getPerformanceApiUrl()}/Vessel/${imoNo}/FuelOil/Stock/Sounding/${id}`,
    data,
  )

export const deleteSounding = (imoNo: string, id: string): Promise<any> =>
  doDelete(
    `${getPerformanceApiUrl()}/Vessel/${imoNo}/FuelOil/Stock/Sounding/${id}`,
  )

export const postLoss = (
  imoNo: string,
  data: Performance.FuelOilStock.Loss,
): Promise<any> =>
  doPost(`${getPerformanceApiUrl()}/Vessel/${imoNo}/FuelOil/Stock/Loss`, data)

export const getLoss = (
  imoNo: string,
  id: string,
): Promise<Performance.FuelOilStock.LossEntry> =>
  doGet(`${getPerformanceApiUrl()}/Vessel/${imoNo}/FuelOil/Stock/Loss/${id}`)

export const putLoss = (
  imoNo: string,
  data: Performance.FuelOilStock.Loss,
  id: string,
): Promise<any> =>
  doPut(
    `${getPerformanceApiUrl()}/Vessel/${imoNo}/FuelOil/Stock/Loss/${id}`,
    data,
  )

export const deleteLoss = (imoNo: string, id: string): Promise<any> =>
  doDelete(`${getPerformanceApiUrl()}/Vessel/${imoNo}/FuelOil/Stock/Loss/${id}`)

export const postDebunkering = (
  imoNo: string,
  data: Performance.FuelOilStock.Debunkering,
): Promise<any> =>
  doPost(
    `${getPerformanceApiUrl()}/Vessel/${imoNo}/FuelOil/Stock/Debunkering`,
    data,
  )

export const getDebunkering = (
  imoNo: string,
  id: string,
): Promise<Performance.FuelOilStock.DebunkeringEntry> =>
  doGet(
    `${getPerformanceApiUrl()}/Vessel/${imoNo}/FuelOil/Stock/Debunkering/${id}`,
  )

export const putDebunkering = (
  imoNo: string,
  data: Performance.FuelOilStock.Debunkering,
  id: string,
): Promise<any> =>
  doPut(
    `${getPerformanceApiUrl()}/Vessel/${imoNo}/FuelOil/Stock/Debunkering/${id}`,
    data,
  )

export const deleteDebunkering = (imoNo: string, id: string): Promise<any> =>
  doDelete(
    `${getPerformanceApiUrl()}/Vessel/${imoNo}/FuelOil/Stock/Debunkering/${id}`,
  )

export const postRobMark = (
  imoNo: string,
  data: Performance.FuelOilStock.RobMark,
): Promise<any> =>
  doPost(
    `${getPerformanceApiUrl()}/Vessel/${imoNo}/FuelOil/Stock/RobMark`,
    data,
  )

export const getRob = (
  imoNo: string,
  timestamp: string,
  excludeEntryId?: string,
): Promise<Performance.FuelOilStock.RobResponse> =>
  doGet(
    `${getPerformanceApiUrl()}/Vessel/${imoNo}/FuelOil/Stock/Rob?timestamp=${timestamp}${
      excludeEntryId ? `&excludeId=${excludeEntryId}` : ''
    }`,
  )

export const getRobMark = (
  imoNo: string,
  id: string,
): Promise<Performance.FuelOilStock.RobMarkEntry> =>
  doGet(`${getPerformanceApiUrl()}/Vessel/${imoNo}/FuelOil/Stock/RobMark/${id}`)

export const deleteRobMark = (imoNo: string, id: string): Promise<any> =>
  doDelete(
    `${getPerformanceApiUrl()}/Vessel/${imoNo}/FuelOil/Stock/RobMark/${id}`,
  )

export const getStockStatus = (
  imoNo: string,
  includeBurndown?: boolean,
): Promise<Performance.FuelOilStock.StatusResponse> =>
  doGet(
    `${getPerformanceApiUrl()}/Vessel/${imoNo}/FuelOil/Stock/Status${
      includeBurndown ? '?includeBurndown=true' : ''
    }`,
  )

export const getStockOpenWindow = (
  imoNo: string,
): Promise<Performance.FuelOilStock.OpenWindow> =>
  doGet(
    `${getPerformanceApiUrl()}/Vessel/${imoNo}/FuelOil/Stock/Status/OpenWindow`,
  )

export const postStockStartup = (
  imoNo: string,
  data: Performance.FuelOilStock.Startup,
): Promise<any> =>
  doPost(
    `${getPerformanceApiUrl()}/Vessel/${imoNo}/FuelOil/Stock/Startup`,
    data,
  )

export const postRecovery = (
  imoNo: string,
  data: Performance.FuelOilStock.RecoveryRequest,
): Promise<any> =>
  doPost(
    `${getPerformanceApiUrl()}/Vessel/${imoNo}/FuelOil/Stock/Recover`,
    data,
  )

export const getStartupBatches = (
  imoNo: string,
): Promise<Performance.FuelOilStock.BunkeredBatchResponse[]> =>
  doGet(
    `${getPerformanceApiUrl()}/Vessel/${imoNo}/FuelOil/Stock/Startup/Batches`,
  )

// FDL 4.0
export const postFuelTypeSelection = (
  imoNo: string,
  data: Performance.FDL.FuelTypeSelection,
): Promise<any> =>
  doPost(
    `${getPerformanceApiUrl()}/Vessel/${imoNo}/FDL/FuelTypeSelection`,
    data,
  )

export const getCurrentFuelTypeSelections = (
  imoNo: string,
): Promise<Performance.FDL.FuelTypeSelectionResponse[]> =>
  doGet(
    `${getPerformanceApiUrl()}/Vessel/${imoNo}/FDL/FuelTypeSelections/Current`,
  )

export const getFuelTypeSelections = (
  imoNo: string,
): Promise<Performance.FDL.FuelTypeSelectionResponse[]> =>
  doGet(`${getPerformanceApiUrl()}/Vessel/${imoNo}/FDL/FuelTypeSelection`)

export const getFuelTypeSelectionsById = (
  imoNo: string,
  id: string,
): Promise<Performance.FDL.FuelTypeSelectionResponse> =>
  doGet(`${getPerformanceApiUrl()}/Vessel/${imoNo}/FDL/FuelTypeSelection/${id}`)

export const putFuelTypeSelection = (
  imoNo: string,
  id: string,
  fuelTypeSelection: Performance.FDL.FuelTypeSelection,
) =>
  doPut(
    `${getPerformanceApiUrl()}/Vessel/${imoNo}/FDL/FuelTypeSelection/${id}`,
    fuelTypeSelection,
  )

export const deleteFuelTypeSelection = (
  imoNo: string,
  id: string,
): Promise<any> =>
  doDelete(
    `${getPerformanceApiUrl()}/Vessel/${imoNo}/FDL/FuelTypeSelection/${id}`,
  )

// SFOC
export const getMeSfoc = (
  imoNo: string,
  queryPeriod: Performance.Common.QueryPeriod,
): Promise<Performance.Sfoc.MainEngSfocCurve> =>
  doGet(
    `${getPerformanceApiUrl()}/Vessel/${imoNo}/SFOC/MainEng?period.from=${
      queryPeriod.from
    }&period.to=${queryPeriod.to}`,
  )

export const getAeSfocData = (
  imoNo: string,
  queryPeriod: Performance.Common.QueryPeriod,
): Promise<Performance.Sfoc.AeSfocData> =>
  doGet(
    `${getPerformanceApiUrl()}/Vessel/${imoNo}/SFOC/AuxEng?period.from=${
      queryPeriod.from
    }&period.to=${queryPeriod.to}`,
  )

// Fuel consumption
export const getFuelConsumptionMonitor = (
  imoNo: string,
  period: Performance.Common.QueryPeriod,
): Promise<Performance.FuelConsumption.Monitor> =>
  doGet(
    `${getPerformanceApiUrl()}/Vessel/${imoNo}/FuelConsumption/Monitor?period.from=${
      period.from
    }&period.to=${period.to}`,
  )

export const getFuelTypeMonitor = (
  imoNo: string,
  period: Performance.Common.QueryPeriod,
): Promise<Performance.FuelConsumption.FuelTypeMonitorResponse> =>
  doGet(
    `${getPerformanceApiUrl()}/Vessel/${imoNo}/FuelConsumption/FuelTypeMonitor?period.from=${
      period.from
    }&period.to=${period.to}`,
  )

export const getFuelConsumptionMonitorConsolidated = (
  imoNo: string,
  period: Performance.Common.QueryPeriod,
): Promise<Performance.FuelConsumption.MonitorConsolidated> =>
  doGet(
    `${getPerformanceApiUrl()}/Vessel/${imoNo}/FuelConsumption/Monitor/Consolidated?period.from=${
      period.from
    }&period.to=${period.to}`,
  )

export const getFuelConsumptionBatchMonitor = (
  imoNo: string,
  period: Performance.Common.QueryPeriod,
): Promise<Performance.FuelConsumption.BatchMonitor> =>
  doGet(
    `${getPerformanceApiUrl()}/Vessel/${imoNo}/FuelConsumption/BatchMonitor?period.from=${
      period.from
    }&period.to=${period.to}`,
  )

export const getFuelConsumptionStatus = async (
  imoNo: string,
): Promise<FuelConsumptionStatusResponse> =>
  doGet(`${getPerformanceApiUrl()}/Vessel/${imoNo}/FuelConsumption/Status`)

/**
 * getTotalizer
 * @param {string} imoNo imoNo of the vessel.
 * @param {string} timestamp timestamp to query for totalizer values
 * @returns {object} list of totalizer reading at the queried timestamp
 */
export const getTotalizerResponse = async (
  imoNo: string,
  timestamp: string,
): Promise<FuelLineTotalizer> =>
  doGet(
    `${getPerformanceApiUrl()}/Vessel/${imoNo}/FuelConsumption/Totalizers?timestamp=${timestamp}`,
  )

/**
 * postManualCorrectionRepairs
 * @param {string} imoNo imoNo of the vessel the corrections should be posted to.
 * @param {ManualCorrectionRepairs} data payload that represents a repair of a dataloss, for X fuel lines
 */
export const postManualCorrectionRepairs = async (
  imoNo: string,
  data: ManualCorrectionRepairs,
): Promise<FuelConsumptionStatusResponse> =>
  doPost(
    `${getPerformanceApiUrl()}/Vessel/${imoNo}/ManualCorrection/Repair`,
    data,
  )

/**
 * putManualCorrectionRepairs
 * @param {string} imoNo imoNo of the vessel the corrections should be posted to.
 * @param {string} id ID of the repair to update
 * @param {ManualCorrectionRepairs} data payload that represents a repair of a dataloss, for X fuel lines
 */
export const putManualCorrectionRepairs = async (
  imoNo: string,
  id: string,
  data: ManualCorrectionRepairs,
): Promise<FuelConsumptionStatusResponse> =>
  doPut(
    `${getPerformanceApiUrl()}/Vessel/${imoNo}/ManualCorrection/Repair/${id}`,
    data,
  )

/**
 * delManualCorrectionRepairs
 * @param {string} imoNo imoNo of the vessel the corrections should be posted to.
 * @param {string} id ID of the repair to update
 * @param {ManualCorrectionRepairs} data payload that represents a repair of a dataloss, for X fuel lines
 */
export const delManualCorrectionRepairs = async (
  imoNo: string,
  id: string,
): Promise<FuelConsumptionStatusResponse> =>
  doDelete(
    `${getPerformanceApiUrl()}/Vessel/${imoNo}/ManualCorrection/Repair/${id}`,
  )

/**
 * postManualCorrectionOverwrite
 * @param {string} imoNo imoNo of the vessel the corrections should be posted to.
 * @param {ManualCorrectionOverwrite} data payload that represents a manual overwrite.
 */
export const postManualCorrectionOverwrite = async (
  imoNo: string,
  data: ManualCorrectionOverwrite,
): Promise<void> =>
  doPost(
    `${getPerformanceApiUrl()}/Vessel/${imoNo}/ManualCorrection/Overwrite`,
    data,
  )

/**
 * putManualCorrectionOverwrite
 * @param {string} imoNo imoNo of the vessel the corrections should be posted to.
 * @param {string} id ID of the repair to update
 * @param {ManualCorrectionOverwrite} data payload that represents a repair of a dataloss, for X fuel lines
 */
export const putManualCorrectionOverwrite = async (
  imoNo: string,
  id: string,
  data: ManualCorrectionOverwrite,
): Promise<FuelConsumptionStatusResponse> =>
  doPut(
    `${getPerformanceApiUrl()}/Vessel/${imoNo}/ManualCorrection/Overwrite/${id}`,
    data,
  )

/**
 * delManualCorrectionOverwrite
 * @param {string} imoNo imoNo of the vessel the corrections should be posted to.
 * @param {string} id ID of the repair to update
 */
export const delManualCorrectionOverwrite = async (
  imoNo: string,
  id: string,
): Promise<FuelConsumptionStatusResponse> =>
  doDelete(
    `${getPerformanceApiUrl()}/Vessel/${imoNo}/ManualCorrection/Overwrite/${id}`,
  )

/**
 * UserEntries
 * @description fetches the corrections made to fuel consumption, returns both Repairs (of dataloses) and Manual overwirtes.
 * @param {string} imoNo imoNo of the vessel the corrections should be posted to
 * @param {}
 */
export const getManualCorrectionUserEntries = async (
  imoNo: string,
  period: Performance.Common.QueryPeriod,
): Promise<ManualCorrections> =>
  doGet(
    `${getPerformanceApiUrl()}/Vessel/${imoNo}/ManualCorrection/UserEntries?period.from=${
      period.from
    }&period.to=${period.to}`,
  )

/**
 * putManualCorrectionRepair
 * @description updatea Dataloss repairs.
 * @param {string} imoNo imoNo of the vessel the corrections should be posted to
 * @param {string} id id of the rapair that needs updated
 * @param {ManualCorrectionRepair} data object representing the repair.
 */
export const putManualCorrectionRepair = async (
  imoNo: string,
  id: string,
  data: ManualCorrectionRepair,
): Promise<void> =>
  doPut(
    `${getPerformanceApiUrl()}/Vessel/${imoNo}/ManualCorrection/Repair/${id}`,
    data,
  )

/**
 * putManualCorrectionRepair
 * @description updates Manual overwirtes.
 * @param {string} imoNo imoNo of the vessel the corrections should be posted to
 * @param {string} id id of the rapair that needs updated
 * @param {ManualCorrectionOverwrite} data object representing the overwrite.
 */
export const putManualCorrectionOverwirte = async (
  imoNo: string,
  id: string,
  data: ManualCorrectionOverwrite,
): Promise<void> =>
  doPut(
    `${getPerformanceApiUrl()}/Vessel/${imoNo}/ManualCorrection/Overwrite/${id}`,
    data,
  )

/**
 * getConsumedMassInPeriod
 * @description Fetch the consumed mass in query period
 * @param {string} imoNo imoNo of the vessel the corrections should be posted to
 * @param {period} object including a to and from date string
 */
export const getConsumedMassInPeriod = async (
  imoNo: string,
  period: Performance.Common.QueryPeriod,
  fuelLineType: string | number,
): Promise<ManualCorrectionConsumedMass> =>
  doGet(
    `${getPerformanceApiUrl()}/Vessel/${imoNo}/ManualCorrection/ConsumedMass?fuelLineType=${fuelLineType}&period.from=${
      period.from
    }&period.to=${period.to}`,
  )

export const getLubeOilEntries = (imoNo: string): Promise<TLubeOilEntry[]> =>
  doGet(`${getPerformanceApiUrl()}/Vessel/${imoNo}/LubeOil/Stock/UserEntries`)

export const deleteLubeOilEntry = (
  entry: (typeof LUBE_OIL_ENTRY_TYPE_NAME)[ELubeOilEntryType],
  id: string,
  imoNo: number,
): Promise<null> =>
  doDelete(
    `${getPerformanceApiUrl()}/Vessel/${imoNo}/LubeOil/Stock/${entry}/${id}`,
  )

export const getLubeOilEntry = (
  entry: (typeof LUBE_OIL_ENTRY_TYPE_NAME)[ELubeOilEntryType],
  id: string,
  imoNo: number,
): Promise<
  TLubeOilBunkering | TLubeOilLoss | TLubeOilSounding | TLubeOilTransfer
> =>
  doGet(
    `${getPerformanceApiUrl()}/Vessel/${imoNo}/LubeOil/Stock/${entry}/${id}`,
  )

export const postLubeOilEntry = (
  entry: (typeof LUBE_OIL_ENTRY_TYPE_NAME)[ELubeOilEntryType],
  imoNo: number,
  data: TLubeOilBunkering | TLubeOilLoss | TLubeOilSounding | TLubeOilTransfer,
): Promise<string> =>
  doPost(
    `${getPerformanceApiUrl()}/Vessel/${imoNo}/LubeOil/Stock/${entry}`,
    data,
  )

export const putLubeOilEntry = (
  entry: (typeof LUBE_OIL_ENTRY_TYPE_NAME)[ELubeOilEntryType],
  imoNo: number,
  id: string,
  data: TLubeOilBunkering | TLubeOilLoss | TLubeOilSounding | TLubeOilTransfer,
): Promise<null> =>
  doPut(
    `${getPerformanceApiUrl()}/Vessel/${imoNo}/LubeOil/Stock/${entry}/${id}`,
    data,
  )

export const getLubeOilStatus = (imoNo: number): Promise<TLubeOilStatus> =>
  doGet(`${getPerformanceApiUrl()}/Vessel/${imoNo}/LubeOil/Stock/Status`)

export const getLubeOilOpenWindow = (
  imoNo: string,
): Promise<TLubeOilOpenWindow> =>
  doGet(`${getPerformanceApiUrl()}/Vessel/${imoNo}/LubeOil/Stock/OpenWindow`)
