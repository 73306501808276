import { FuelLineType } from '../../../utils'
import { UNITS } from '../../../utils/constants'

/***
 * The consumption is always returned as MT/h by the backend.
 * For all fuellines except the ME MAIN the unit should be kg/h.
 */
export const convertToFuelLineUnit = (
  consumptionVal: number | null,
  fuelLineType: FuelLineType,
) => {
  if (!consumptionVal) return consumptionVal
  if (fuelLineType === FuelLineType.MEMain) return consumptionVal
  return consumptionVal * 1000
}

/**
 * Get number of decimals for display from unit.
 *
 * @param unit  The unit to get number of decimals for.
 * @param detailed Whether to get number of decimals for detailed display in e.g. a tooltip.
 */
export const getDecimalsFromUnit = (unit: string, detailed: boolean) => {
  switch (unit) {
    case UNITS.KG_PER_HOUR:
      return 0
    case UNITS.MT_PER_HOUR:
      return detailed ? 2 : 1
    case UNITS.KILO_GRAM_PER_CUBIC_METER:
      return detailed ? 1 : 0
    default:
      return 0
  }
}

/**
 * Zero values must be rendered at start/end of series if chart is stacked and area below line is filled.
 *
 * @param series stacked series
 */
export const mapSeriesZero = (
  series: Array<number | null>,
): Array<number | null> => {
  return series.map((val, index) => {
    if (
      val === null &&
      (series[index + 1] !== null || series[index - 1] !== null)
    ) {
      return 0
    }
    return val
  })
}

/**
 * Take an ISO date string and set the seconds to 0.
 * @param {string} dateStr Date string in ISO format
 * @returns {string} Date string in ISO format
 */
export const zeroSecondsDate = (dateStr: string): string => {
  const dateObj = new Date(dateStr)
  dateObj.setSeconds(0)
  return dateObj.toISOString()
}

/**
 * Calculate an amount per hour, based on a date range and a number
 * @param {string} start Date string in ISO format
 * @param {string} end Date string in ISO format
 * @param {number} amount Value to calculate per hour from
 * @returns {number} Amount/Hour
 */
export const amountPerHour = (
  start: string,
  end: string,
  amount: number,
  decimals: number = 3,
): number => {
  const startDate = new Date(start)
  const endDate = new Date(end)
  const hours = (endDate.valueOf() - startDate.valueOf()) / (60 * 60 * 1000)
  return parseFloat((amount / hours).toFixed(decimals))
}
