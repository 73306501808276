export interface FavoriteVessel {
  [key: string]: string | number
  vesselClass: string
  vesselName: string
  imoNo: number
  vesselType: string
  techManager: string
}

export interface UserFavoriteList {
  name: string
  vessels: FavoriteVessel[]
}
