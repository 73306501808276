import { FavoriteVessel } from './models'

export interface VesselsTree {
  [primary: string]: {
    [secondary: string]: {
      vessels: FavoriteVessel[]
    }
  }
}

export const mapVesselsToTree: (
  list: FavoriteVessel[],
  primaryKey: string,
  secondaryKey: string,
) => VesselsTree = (list, primaryKey, secondaryKey) => {
  let tree = {} as VesselsTree
  list.forEach((vessel) => {
    const firstLevel =
      vessel[primaryKey] === undefined ? primaryKey : vessel[primaryKey]
    const secondLevel =
      vessel[secondaryKey] === undefined ? secondaryKey : vessel[secondaryKey]
    switch (true) {
      case tree[firstLevel] === undefined:
        tree[firstLevel] = {
          [secondLevel]: { vessels: [vessel] },
        }
        break
      case tree[firstLevel][secondLevel] === undefined:
        tree[firstLevel][secondLevel] = { vessels: [vessel] }
        break
      default:
        tree[firstLevel][secondLevel].vessels.push(vessel)
        break
    }
  })

  const ordered = {} as VesselsTree
  Object.keys(tree)
    .sort()
    .forEach((key) => (ordered[key] = tree[key]))
  return ordered
}

export const vesselSelected: (
  selectedTree: VesselsTree | null,
  vessel: FavoriteVessel,
  primaryKey: string,
  secondaryKey?: string,
) => boolean = (selectedTree, vessel, primaryKey, secondaryKey) => {
  if (!selectedTree) return false
  const selectedVesselsInList = getVesselListFromTree(
    selectedTree,
    primaryKey,
    secondaryKey,
  )
  return selectedVesselsInList.some(
    (selectedVessel) => selectedVessel.imoNo === vessel.imoNo,
  )
}

export const allVesselsSelected: (
  tree: VesselsTree,
  selectedTree: VesselsTree | null,
  primaryKey: string,
  secondaryKey?: string,
) => boolean = (tree, selectedTree, primaryKey, secondaryKey) => {
  if (!selectedTree) return false
  const vesselsInList = getVesselListFromTree(tree, primaryKey, secondaryKey)
  const selectedVesselsInList = getVesselListFromTree(
    selectedTree,
    primaryKey,
    secondaryKey,
  )
  return vesselsInList.every((vessel) =>
    selectedVesselsInList.some(
      (selectedVessel) => vessel.imoNo === selectedVessel.imoNo,
    ),
  )
}

export const getVesselListFromTree: (
  list: VesselsTree,
  primaryKey: string,
  secondaryKey?: string,
) => FavoriteVessel[] = (list, primaryKey, secondaryKey) => {
  if (
    secondaryKey &&
    list[primaryKey] &&
    list[primaryKey][secondaryKey] &&
    list[primaryKey][secondaryKey].vessels
  ) {
    return list[primaryKey][secondaryKey].vessels
  }
  if (list[primaryKey]) {
    let vessels = [] as FavoriteVessel[]
    Object.keys(list[primaryKey]).forEach((secondaryKey) => {
      vessels = [...vessels, ...list[primaryKey][secondaryKey].vessels]
    })
    return vessels
  }
  return [] as FavoriteVessel[]
}
