import styled from 'styled-components'

export const PadContent = styled.div`
  margin: 24px 16px;
  @media (min-width: ${(props) => props.theme.breakPoints.medium}px) {
    margin: 32px 24px;
  }
  @media (min-width: ${(props) => props.theme.breakPoints.large}px) {
    margin: 40px 32px;
  }
`
