import React, { FC, useState } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { grey, red } from '../../../theme'
import { Performance } from '../../../api-models'
import { CalculatedTooltip } from './CalculatedTooltip'

interface SelectedPeriodProps {
  queryPeriod: Performance.Common.Period
  resultPeriod: Performance.Common.Period
}

const Wrapper = styled.div`
  color: ${grey[700]};
  font-size: 12px;
`

const ColorWrapper = styled.span<{ shouldHighlight: boolean }>`
  ${(props) => (props.shouldHighlight ? `color: ${red[800]}` : '')};
`

const formatSelectedPeriod = (period: Performance.Common.Period): any => {
  const selectedPeriod = { from: '', to: '', duration: '' }
  selectedPeriod.from = moment.utc(period.from).format('DD MMM HH:mm UTC')
  selectedPeriod.to = moment.utc(period.to).format('DD MMM HH:mm UTC')

  const ms = moment.duration(moment(period.to).diff(moment(period.from)))

  selectedPeriod.duration = `${ms.days() ? ms.days() + 'd ' : ''}${
    ms.hours() ? ms.hours() + 'h ' : ''
  }${ms.minutes() ? ms.minutes() + 'm' : ''}`

  return selectedPeriod
}

export const SelectedPeriod: FC<SelectedPeriodProps> = ({
  queryPeriod,
  resultPeriod,
}) => {
  const periodsAreNotEqual =
    queryPeriod.from !== resultPeriod.from || queryPeriod.to !== resultPeriod.to

  const [showCalculatedTooltip, setShowCalculatedTooltip] =
    useState<boolean>(periodsAreNotEqual)
  const { from, to, duration } = formatSelectedPeriod(resultPeriod)

  return (
    <Wrapper>
      Selected period: {from} -{' '}
      <ColorWrapper shouldHighlight={periodsAreNotEqual}>{to}</ColorWrapper>{' '}
      {` (${duration})`}
      {showCalculatedTooltip && (
        <CalculatedTooltip
          closeHandler={() => setShowCalculatedTooltip(false)}
        />
      )}
    </Wrapper>
  )
}
