import React, { useContext, useState } from 'react'
import {
  useMutation,
  useQuery,
  useQueryClient,
  type UseQueryResult,
} from '@tanstack/react-query'
import { Form, Formik } from 'formik'
import { McButton, McModal } from '@maersk-global/mds-react-wrapper'

import {
  FormInputDateTime,
  FormRadioAlt,
  FormSelect,
  FormTextArea,
  FormTextInput,
} from '../../../components/formik'
import { VesselPageContext } from '../../../contexts'
import OverlayLoader from '../../../features/hybrid-data-collector/components/overlay-loader'
import {
  deleteLubeOilEntry,
  getLubeOilEntry,
  postLubeOilEntry,
  putLubeOilEntry,
} from '../../../services/performance'
import { displayErrorModal, isShoreContext } from '../../../utils'
import * as S from '../style'
import {
  ELubeOilContainer,
  ELubeOilType,
  type IModalProps,
  type ITransferFormValues,
  type TLubeOilTransfer,
} from '../types'
import transferValidationSchema from '../validation/transfer'
import {
  getFromOilContainerOptions,
  getInitialValues,
  getToOilContainerOptions,
  oilTypeOptions,
} from './helpers'

const Transfer = (props: IModalProps) => {
  const { hasTwoMainEngines, imoNo } =
    useContext(VesselPageContext).configuration!

  const [isConfirmingDeletion, setConfirmingDeletion] = useState(false)

  /**
   * @description `undefined` if adding new entry; `string` if editing existing entry
   */
  const entryId = props.entryId

  const queryClient = useQueryClient()

  const {
    data,
    isFetching,
    isSuccess,
    isError,
    error,
  }: UseQueryResult<TLubeOilTransfer> = useQuery(
    ['lubeOilEntry', 'Transfer', entryId, imoNo],
    () => getLubeOilEntry('Transfer', entryId!, imoNo),
    { enabled: !!entryId },
  )

  const putMutation = useMutation({
    mutationFn: (entry: TLubeOilTransfer) =>
      putLubeOilEntry('Transfer', imoNo, entry.id!, entry),
    onSuccess: handleMutationSuccess,
    onError: handleMutationError,
  })

  const postMutation = useMutation({
    mutationFn: (entry: TLubeOilTransfer) =>
      postLubeOilEntry('Transfer', imoNo, entry),
    onSuccess: handleMutationSuccess,
    onError: handleMutationError,
  })

  const deleteMutation = useMutation({
    mutationFn: (entry: TLubeOilTransfer) =>
      deleteLubeOilEntry('Transfer', entry.id!, imoNo),
    onSuccess: handleMutationSuccess,
    onError: (error: any) => {
      void displayErrorModal({
        statusText: 'Failed to delete entry',
        message: error.message || 'Could not delete entry. Please try again.',
      })
    },
  })

  async function handleMutationSuccess() {
    await queryClient.invalidateQueries({
      queryKey: ['lubeOilEntries', String(imoNo)],
    })
    props.onClose()
  }

  function handleMutationError(error) {
    void displayErrorModal({
      statusText: 'Failed to save transfer',
      message: error.message || 'Could not save transfer. Please try again.',
    })
  }

  const handleSubmit = (values: ITransferFormValues) => {
    if (entryId) {
      putMutation.mutate(values as TLubeOilTransfer)
    } else {
      postMutation.mutate(values as TLubeOilTransfer)
    }
  }

  const handleDelete = () => {
    if (!isConfirmingDeletion) {
      setConfirmingDeletion(true)
      return
    }

    deleteMutation.mutate(data!)
  }

  let initialValues: ITransferFormValues
  if (!entryId) {
    initialValues = getInitialValues()
  } else if (isFetching) {
    return <OverlayLoader />
  } else if (isSuccess) {
    initialValues = data
  } else {
    if (isError) console.error(error)
    return null
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={transferValidationSchema}
    >
      {({ isSubmitting, setFieldValue, submitForm }) => (
        <McModal
          open
          closed={() => props.onClose()}
          heading='Transfer'
          width='600px'
          height='auto'
        >
          <Form>
            <S.Description>
              Same event type cannot have multiple entries with same timestamp.
            </S.Description>
            <FormInputDateTime
              fit='medium'
              name='timestamp'
              label='Date and time of transfer, UTC'
              min={props.openWindow?.from ?? undefined}
              max={props.openWindow?.to ?? undefined}
            />
            <S.InputRow>
              <S.Input width='50%'>
                <FormRadioAlt
                  name='oilType'
                  id='lube-transfer-oil-type'
                  legend='Oil type'
                  onChange={(value) =>
                    value === ELubeOilType.AE &&
                    setFieldValue(
                      'fromOilContainer',
                      ELubeOilContainer.SpareClean,
                    )
                  }
                  options={oilTypeOptions}
                />
              </S.Input>
              <S.Input width='50%'>
                <FormTextInput
                  name='quantity'
                  id='lube-transfer-quantity'
                  data-e2e={'Quantity'}
                  label='Quantity'
                  suffix='kg'
                />
              </S.Input>
            </S.InputRow>
            <S.InputRow>
              <S.Input width='50%'>
                <FormSelect
                  fit='medium'
                  name='fromOilContainer'
                  id='lube-transfer-from'
                  data-e2e={'From'}
                  label='From'
                  options={getFromOilContainerOptions(hasTwoMainEngines)}
                />
              </S.Input>
              <S.Input width='50%'>
                <FormSelect
                  fit='medium'
                  name='toOilContainer'
                  id='lube-transfer-to'
                  data-e2e={'To'}
                  label='To'
                  options={getToOilContainerOptions(hasTwoMainEngines)}
                />
              </S.Input>
            </S.InputRow>
            <FormTextArea
              name='notes'
              id='lube-transfer-comment'
              data-e2e={'Comment'}
              label='Comment'
              placeholder='Write your comment here'
              rows={3}
            />
            {!isShoreContext() && entryId && !data?.readonly && (
              <S.DeletionRow>
                <McButton
                  type='button'
                  data-e2e={'Delete'}
                  appearance='neutral'
                  trailingicon='trash'
                  click={handleDelete}
                >
                  Delete
                </McButton>
                {isConfirmingDeletion && (
                  <p className='mds-text--medium-bold'>
                    Are you sure you want to delete this entry?
                  </p>
                )}
              </S.DeletionRow>
            )}
            {(isSubmitting || deleteMutation.isLoading) && <OverlayLoader />}
          </Form>
          {((isShoreContext() || data?.readonly) && (
            <McButton slot='primaryAction' click={() => props.onClose()}>
              Close
            </McButton>
          )) || (
            <>
              <McButton
                slot='primaryAction'
                data-e2e={'Save'}
                click={submitForm}
              >
                Save
              </McButton>
              <McButton
                slot='secondaryAction'
                appearance='neutral'
                click={() => props.onClose()}
              >
                Cancel
              </McButton>
            </>
          )}
        </McModal>
      )}
    </Formik>
  )
}

export default Transfer
