import { useContext, useRef } from 'react'
import { useField } from 'formik'
import moment from 'moment'
import { McInputDate, McInputTime } from '@maersk-global/mds-react-wrapper'

import { Wrapper } from './MfeInputDateTime.styles'
import { WindowContext } from '../../../../contexts'
import {
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  TIME_FORMAT,
} from '../../../../components/formik/InputDateTime/InputDateTime.utils'

type Props = {
  name: string
  label: string
  hint?: string
  /**
   * Maximal selectable date, unlimited if empty (ISO format YYYY-MM-DD)
   */
  maxDate?: string
}

const MfeInputDateTime = ({ name, label, hint, maxDate }: Props) => {
  const { windowSize } = useContext(WindowContext)
  const [field, meta, helpers] = useField<string | null>(name)

  const dateInputRef = useRef<HTMLInputElement>()
  const timeInputRef = useRef<HTMLInputElement>()

  const handleDateChange = (event: Event) => {
    const target = event.target as HTMLInputElement
    const dateValue = target.value
    const timeValue = timeInputRef.current?.value
    setDateTime(dateValue, timeValue)
  }

  const handleTimeChange = (event: Event) => {
    const target = event.target as HTMLInputElement
    const timeValue = target.value
    const dateValue = dateInputRef.current?.value
    setDateTime(dateValue, timeValue)
  }

  const setDateTime = (date?: string, time?: string) => {
    if (!date && !time) {
      void helpers.setValue(null)
      return
    }

    if (!date || !time) {
      return
    }

    const dateTimeValue = moment
      .utc(`${date} ${time}`, DATE_TIME_FORMAT)
      .toISOString()
    void helpers.setValue(dateTimeValue)
  }

  const _date = !field.value
    ? null
    : moment.utc(field.value).format(DATE_FORMAT)

  const _time = !field.value
    ? null
    : moment.utc(field.value).format(TIME_FORMAT)

  return (
    <Wrapper>
      <McInputDate
        // @ts-ignore
        ref={dateInputRef}
        fit={windowSize}
        width='100'
        label={label}
        value={_date}
        invalid={Boolean(meta.error)}
        errormessage={meta.error}
        hint={hint}
        blur={handleDateChange}
        max={maxDate}
      />
      <McInputTime
        // @ts-ignore
        ref={timeInputRef}
        fit={windowSize}
        width='100'
        label='&nbsp;'
        value={_time}
        invalid={Boolean(meta.error)}
        blur={handleTimeChange}
      />
    </Wrapper>
  )
}

export default MfeInputDateTime
