import { useContext, useMemo } from 'react'
import { useFormikContext } from 'formik'

import FormSection from '../../../../components/form/section'
import { SimpleFormRow } from '../../components/form-row'
import HDCTextareaField from '../../components/hdc-textarea-field'
import OverlayLoader from '../../components/overlay-loader'
import { HDCContext } from '../../context'
import { type HDCReportFormValues } from '../../types'
import {
  flattenFormikErrors,
  getFilteredByStepErrors,
  getFilteredByStepWarnings,
} from '../../utils'
import EventsOverview from '../events/overview'
import Notifications from '../../../../components/Notifications/Notifications'

const EventsStep = () => {
  const { errors, isSubmitting } = useFormikContext<HDCReportFormValues>()
  const { state: HDCState } = useContext(HDCContext)
  const {
    form: { currentStep, steps, type },
    report,
  } = HDCState

  const warnings = useMemo(
    () =>
      getFilteredByStepWarnings(report!.warnings, type!, steps[currentStep]),
    [currentStep, report, steps, type],
  )

  return (
    <>
      <Notifications
        alerts={flattenFormikErrors(
          getFilteredByStepErrors(errors, type!, steps[currentStep]),
        )}
        warnings={[...new Set(Object.values(warnings))]}
      />
      <EventsOverview />
      <FormSection header='Report comments'>
        <SimpleFormRow>
          <HDCTextareaField
            name='engineRoom.comment'
            label='Additional reporting comments'
          />
        </SimpleFormRow>
      </FormSection>
      {isSubmitting && <OverlayLoader padding='0px' />}
    </>
  )
}

export default EventsStep
