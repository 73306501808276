import { FieldProps } from 'formik'
import { TextArea } from './TextArea'

interface Props extends FieldProps {
  width?: string
  disabled?: boolean
  onChange?: (value) => void
}

export const FormTextArea = ({
  field,
  form,
  width,
  disabled,
  onChange,
}: Props) => {
  return (
    <TextArea
      disabled={disabled}
      onBlur={() => form.setFieldTouched(field.name)}
      onChange={(event) => {
        form.setFieldValue(field.name, event.target.value)
        if (onChange) onChange(event.target.value)
      }}
      value={field.value}
      width={width}
    />
  )
}
