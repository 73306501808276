import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  margin-top: 48px;

  > mc-button:first-of-type {
    margin-right: auto;
  }
`
