import { useContext, useState, useEffect, useMemo } from 'react'
import { Field, Form, useFormikContext } from 'formik'
import Select from 'react-select'
import moment from 'moment'
import { McButton } from '@maersk-global/mds-react-wrapper'

import * as PerformanceApi from '../../../../services/performance'
import { Performance } from '../../../../api-models'
import styled, { maritimeBlue } from '../../../../theme'
import {
  Loading,
  ModalControls,
  Icon,
  FormContainer,
} from '../../../../commons'
import { displayErrorModal, formatValue } from '../../../../utils'
import { BatchSelect } from '../../../fdl-4/components/batch-selection/BatchSelect'
import { StockRobNotifications } from '../StockRobNotifications'
import {
  FormDateTimeInput,
  FormTextArea,
  FormInputWithUnit,
} from '../../../../commons'
import { VolumeToMassCalculator } from '../../../volume-to-mass-calculator'
import { VesselPageContext } from '../../../../contexts'
import { type Option, useBatchOptions } from '../../hooks'
import { type DebunkeringFormValues } from './DebunkeringModal'
import { STOCK_DEBUNKERING_REASON_OPTIONS } from '../../../../utils/constants'
import { ErrorMessage } from '../ErrorMessage'
import { useTerminals } from '../../../../queries/MasterDataApi/MasterDataApi'
import { getTerminalOption } from '../../../hybrid-data-collector/reports/reports.utils'

const Wrapper = styled.div`
  max-height: 80vh;
  overflow-y: auto;
`

const CalculatorContainer = styled.div`
  padding-left: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;

  > i {
    font-size: 16px;
    line-height: 16px;
  }

  svg {
    color: ${maritimeBlue[500]};
    height: 24px;
    width: 24px !important;
  }
`

const FieldContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`

const SelectWrapper = styled.div`
  width: 290px;
  font-size: 14px;
`

const QuantityFieldContainer = styled.div`
  width: 290px;
`

const FieldLabel = styled.span`
  width: 172px;
  font-size: 14px;
  text-align: right;
  margin-right: 8px;
`

const QuantityInputContainer = styled.div`
  display: flex;
`

const RobContainer = styled.div`
  text-align: right;
  font-size: 14px;
  margin: 0 10px;
`
const InfoStyle = styled.div`
  margin: 0 0 16px 0;
`

type Props = {
  disabled: boolean
  debunkering?: Performance.FuelOilStock.DebunkeringEntry
  closeHandler: () => void
  deleteHandler: (
    debunkeringId: string,
    setSubmitting: (isSubmitting: boolean) => void,
  ) => void
  openWindow?: Performance.FuelOilStock.OpenWindow
}

export const DebunkeringForm = (props: Props) => {
  const { closeHandler, deleteHandler, openWindow, debunkering, disabled } =
    props
  const imoNo = useContext(VesselPageContext).imoNo!
  const batchOptions = useBatchOptions(imoNo)
  const [currentBatchRob, setCurrentBatchRob] = useState<number | null>()
  const [batchOptionsAtTimeSelected, setBatchOptionsAtTimeSelected] =
    useState<Option[]>()
  const [rob, setRob] = useState<Performance.FuelOilStock.RobResponse>()
  const formikProps = useFormikContext<DebunkeringFormValues>()
  const {
    setFieldValue,
    initialValues,
    values,
    isValid,
    dirty,
    isSubmitting,
    setSubmitting,
  } = formikProps
  const submitText = !!debunkering ? 'Save changes' : 'Add new debunkering'
  const [shouldShowCalculator, setShouldShowCalculator] = useState(false)
  /*
   * The state variable validateQuantity is supposed to be a function
   * used for validating against a dynamically changing value (rob for current batch).
   * To use a function as state we have to return a function which returns another function,
   * this is because the state will call the function and set the return value as
   * the state variable in the case when it is a function.
   */
  const [validateQuantity, setValidateQuantity] = useState(() => () => {})

  const { data: terminals, isSuccess: isTerminalsSuccess } = useTerminals()

  useEffect(() => {
    if (values.timestamp) {
      PerformanceApi.getRob(imoNo, moment.utc(values.timestamp).toISOString())
        .then(setRob)
        .catch((_) =>
          displayErrorModal({
            statusText: 'Could not receive ROB',
            message:
              'Could not receive remaining on board at time of debunkering',
          }),
        )
      void setFieldValue('batchId', '')
      void setFieldValue('quantity', '')
      void setFieldValue('notes', '')
    }
  }, [values.timestamp, imoNo, setFieldValue])

  useEffect(() => {
    /*
     * If Stock is not in an operational state, rob returns no data.
     * Last known batches on board must then be fetched from batch endpoint
     */
    if (rob?.hasData) {
      const batchIdsAtTimeSelected = rob?.batchQuantities
        .filter(({ quantity }) => quantity > 0)
        .map((x) => x.batch.id)
      setBatchOptionsAtTimeSelected(
        batchOptions?.filter(({ value }) =>
          batchIdsAtTimeSelected?.includes(value),
        ),
      )
    } else {
      setBatchOptionsAtTimeSelected(batchOptions)
    }
  }, [rob, batchOptions])

  useEffect(() => {
    if (rob?.hasData && values.batchId) {
      const { batchQuantities } = rob
      const newRob = batchQuantities.find(
        (x) => x.batch.id === values.batchId,
      )?.quantity
      if (typeof newRob === 'number') {
        setCurrentBatchRob(newRob)
      } else {
        setCurrentBatchRob(null)
      }
    } else {
      setCurrentBatchRob(null)
    }
  }, [values.batchId, rob])

  useEffect(() => {
    setValidateQuantity(
      () => (value) =>
        currentBatchRob &&
        value > currentBatchRob &&
        `Quantity must be lower than remaining quantity of batch at time of debunkering (maximum ${currentBatchRob} MT)`,
    )
  }, [currentBatchRob])

  const activeTerminalOptions = useMemo(() => {
    if (!isTerminalsSuccess) return []

    const result = terminals
      .filter((t) => t.data.isActive)
      .map(getTerminalOption)

    if (
      initialValues.portCode &&
      !result.find((t) => t.value === initialValues.portCode)
    ) {
      // Workaround: Add the port code from the initial values if it is not in the list
      result.push({
        label: initialValues.portCode,
        value: initialValues.portCode,
      })
    }

    return result
  }, [terminals, isTerminalsSuccess, initialValues.portCode])

  if (!batchOptions || !openWindow) {
    return <Loading />
  }

  return (
    <Form>
      <Wrapper>
        <FormContainer>
          {rob?.notifications && (
            <InfoStyle>
              <StockRobNotifications rob={rob} />
            </InfoStyle>
          )}
          <FieldContainer>
            <FieldLabel>Time of debunkering UTC</FieldLabel>
            <Field
              component={(props) => (
                <FormDateTimeInput
                  {...props}
                  minDate={moment.utc(openWindow.period.from)}
                  maxDate={moment.utc(openWindow.period.to)}
                  minuteSpecific={false}
                />
              )}
              name='timestamp'
              disabled={disabled}
            />
          </FieldContainer>
          <ErrorMessage name='timestamp'></ErrorMessage>

          <FieldContainer>
            <FieldLabel>Batch debunkered</FieldLabel>
            <Field
              component={(props) => (
                <BatchSelect
                  {...props}
                  width='300px'
                  menuHeaderText='Select debunkering from'
                  noOptionsMessage={() =>
                    'Select time of debunkering to see options'
                  }
                />
              )}
              name='batchId'
              options={batchOptionsAtTimeSelected}
              width='300px'
              disabled={disabled}
            />
            <RobContainer>
              <div>{`${formatValue(currentBatchRob, 3)} MT`}</div>
              <div>at time of debunkering</div>
            </RobContainer>
          </FieldContainer>
          <ErrorMessage name='batchId'></ErrorMessage>

          <FieldContainer>
            <FieldLabel>Quantity debunkered</FieldLabel>
            <QuantityFieldContainer>
              <QuantityInputContainer>
                <Field
                  component={FormInputWithUnit}
                  name='quantity'
                  type='number'
                  unit='MT'
                  width='120px'
                  disabled={disabled}
                  validate={validateQuantity}
                  placeholder='0.000'
                />
                <CalculatorContainer
                  onClick={() => setShouldShowCalculator(true)}
                >
                  <Icon icon='fal fa-calculator' />
                </CalculatorContainer>
              </QuantityInputContainer>
            </QuantityFieldContainer>
            {shouldShowCalculator && (
              <VolumeToMassCalculator
                onCancel={() => setShouldShowCalculator(false)}
                onClose={() => setShouldShowCalculator(false)}
                onSave={(totalSum: string) => {
                  let quantity
                  if (totalSum === '-' || isNaN(+totalSum) || disabled) {
                    quantity = ''
                  } else {
                    quantity = parseFloat(totalSum)
                  }
                  void setFieldValue('quantity', quantity)
                  setShouldShowCalculator(false)
                }}
              />
            )}
          </FieldContainer>
          <ErrorMessage name='quantity'></ErrorMessage>

          <FieldContainer>
            <FieldLabel>Port code</FieldLabel>
            <Field
              component={(props) => (
                <SelectWrapper>
                  <Select
                    onBlur={() => props.form.setFieldTouched(props.field.name)}
                    onChange={(option: any) => {
                      if (option?.value)
                        props.form.setFieldValue(props.field.name, option.value)
                    }}
                    value={
                      props.field?.value &&
                      props.options?.find(
                        ({ value }) => value === props.field.value,
                      )
                    }
                    options={props.options}
                    isOptionDisabled={({ isDisabled }) => isDisabled ?? false}
                    isSearchable={true}
                    styles={{
                      placeholder: (provided) => ({
                        ...provided,
                        fontSize: '13px',
                      }),
                      option: (provided) => ({
                        ...provided,
                        fontSize: '14px',
                      }),
                      valueContainer: (provided) => ({
                        ...provided,
                        fontSize: '13px',
                      }),
                    }}
                    menuPosition='fixed'
                  />
                </SelectWrapper>
              )}
              options={activeTerminalOptions}
              name='portCode'
              disabled={disabled}
            />
          </FieldContainer>
          <ErrorMessage name='portCode'></ErrorMessage>

          <FieldContainer>
            <FieldLabel>Reason for debunkering</FieldLabel>
            <Field
              component={(props) => (
                <SelectWrapper>
                  <Select
                    onBlur={() => props.form.setFieldTouched(props.field.name)}
                    onChange={(option: any) => {
                      if (option?.value)
                        props.form.setFieldValue(props.field.name, option.value)
                    }}
                    value={
                      props.field?.value &&
                      props.options?.find(
                        ({ value }) => value === props.field.value,
                      )
                    }
                    options={props.options}
                    isOptionDisabled={({ isDisabled }) => isDisabled ?? false}
                    isSearchable={true}
                    styles={{
                      placeholder: (provided) => ({
                        ...provided,
                        fontSize: '13px',
                      }),
                      option: (provided) => ({
                        ...provided,
                        fontSize: '14px',
                      }),
                      valueContainer: (provided) => ({
                        ...provided,
                        fontSize: '13px',
                      }),
                    }}
                    menuPosition='fixed'
                  />
                </SelectWrapper>
              )}
              options={STOCK_DEBUNKERING_REASON_OPTIONS}
              name='reasonCode'
              disabled={disabled}
            />
          </FieldContainer>
          <ErrorMessage name='reasonCode'></ErrorMessage>

          <FieldContainer>
            <FieldLabel>Notes</FieldLabel>
            <Field component={FormTextArea} name='notes' width='290px' />
          </FieldContainer>
          <ErrorMessage name='notes'></ErrorMessage>
        </FormContainer>
      </Wrapper>
      <ModalControls>
        {!!debunkering && (
          <McButton
            label='Delete'
            appearance='error'
            className='left'
            type='button'
            disabled={isSubmitting || disabled}
            click={() => deleteHandler(debunkering.id, setSubmitting)}
          />
        )}
        <McButton
          label='Cancel'
          appearance='neutral'
          type='button'
          click={closeHandler}
        />
        <McButton
          label={submitText}
          appearance='primary'
          type='submit'
          disabled={isSubmitting || disabled || !isValid || !dirty}
        />
      </ModalControls>
    </Form>
  )
}
