import { Performance } from '../../api-models'
import moment from 'moment'
import { QueryPeriod } from '../filter'
import { MappedWHrCurveData } from './models'

export const mapWhrCurveData = (
  s: Performance.WHR.PowerMainEngLoadResponse,
  DateRangeFilter: QueryPeriod,
): MappedWHrCurveData[] =>
  s.timestamps.map((time, i) => {
    return {
      timestamp: time,
      load: s.loadSeries[i],
      power: s.powerSeries[i],
      filtered: isFiltered(time, DateRangeFilter),
    }
  })

export const recalculateWhrCurveData = (
  data: MappedWHrCurveData[],
  DateRangeFilter: QueryPeriod,
): MappedWHrCurveData[] => {
  const newData: MappedWHrCurveData[] = []
  data.forEach((entry) => {
    newData.push({
      timestamp: entry.timestamp,
      load: entry.load,
      power: entry.power,
      filtered: isFiltered(entry.timestamp, DateRangeFilter),
    })
  })
  return newData
}

function isFiltered(timestamp: number, DateRangeFilter: QueryPeriod) {
  return (
    moment.utc(timestamp).isSameOrAfter(DateRangeFilter.from) &&
    moment.utc(timestamp).isSameOrBefore(DateRangeFilter.to)
  )
}
