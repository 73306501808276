import styled from 'styled-components'

export const Pill = styled.span<{ bg: string }>`
  height: 20px;
  font-size: var(--mds_brand_typography_text_x-small_desktop_font-size);
  @media (min-width: ${(props) => props.theme.breakPoints.medium}px) {
    height: 24px;
    font-size: var(--mds_brand_typography_text_small_desktop_font-size);
  }

  border-radius: 11px;
  padding: 0 8px;
  background-color: ${(props: { bg: string }) => `${props.bg}`};
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.7;
  text-align: center;
`
