import styled from 'styled-components'
import { McInput } from '@maersk-global/mds-react-wrapper'

export const StyledMcInput = styled(McInput)`
  ::part(field) {
    background-color: var(
      --mds_core_input_disabled_background-color
    ) !important;
  }

  ::part(input) {
    font-variant: tabular-nums;
  }
`
