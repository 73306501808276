import * as React from 'react'
import { McButton } from '@maersk-global/mds-react-wrapper'

import styled, { grey, primary } from '../../../theme'
import { commaAgnostic, formatValue } from '../../../utils'
import { volumeToMassCalculation } from '../volume-to-mass-calculation'
import {
  FormElements,
  Icon,
  InputFieldWithUnit,
  Modal,
  ModalContent,
  ModalControls,
  SortableTableHeader,
} from '../../../commons'

const Wrapper = styled.div`
  padding: 16px;

  .total-column {
    text-align: right;
  }
`

const Table = styled.table`
  border-collapse: collapse;

  th,
  td {
    max-width: 136px;
    width: 136px;
    border: 1px solid ${grey[200]};
    padding: 8px;
  }

  thead {
    background-color: ${grey[50]};
    box-shadow: none !important;
    border-top: 1px solid ${grey[200]};
    border-bottom: 1px solid ${grey[200]};
    font-weight: 500;
    font-size: 12px;
    color: #474e56;

    th {
      min-height: 36px;
      padding: 8px;
      border-color: ${grey[200]};
    }
  }

  th:first-of-type {
    border-left: none;
  }

  td:last-of-type {
    border-right: none;
  }

  tfoot {
    height: 51px;

    td:first-of-type {
      border: none;
    }

    td:last-of-type {
      line-height: 34px;
      font-weight: bold;
    }

    button {
      background-color: transparent;
      color: ${primary.bg};
      outline: none;
      display: flex;
      align-items: center;

      &:hover {
        color: ${primary.hover};
      }

      svg {
        height: 19px;
        width: 19px !important;
        margin-right: 8px;
      }
    }
  }
`

export interface CalculationLine {
  volume: string
  temperature: string
  density: string
  total: string
}

const newCalculationLine: CalculationLine = {
  volume: '',
  temperature: '',
  density: '',
  total: '-',
}

interface VolumeToMassCalculatorProps {
  onSave: (totalSum: string, state: VolumeToMassCalculatorState) => void
  onCancel: () => void
  onClose: () => void
  labelText?: string
  storedState?: VolumeToMassCalculatorState
  toolContext?: boolean
}

export interface VolumeToMassCalculatorState {
  calculations: CalculationLine[]
  totalSum: number | string
}

const initialState = {
  totalSum: '-',
  calculations: [
    { ...newCalculationLine },
    { ...newCalculationLine },
    { ...newCalculationLine },
  ],
}

export class VolumeToMassCalculator extends React.Component<
  VolumeToMassCalculatorProps,
  VolumeToMassCalculatorState
> {
  constructor(props: VolumeToMassCalculatorProps) {
    super(props)

    this.state = initialState
  }

  calculateRows(calculations: CalculationLine[]) {
    let totalSum: number | null = null
    const calcs: CalculationLine[] = calculations.map((calc) => {
      const volume = parseFloat(calc.volume)
      const temperature = parseFloat(calc.temperature)
      const density = parseFloat(calc.density)
      const total =
        !!volume && !!temperature && !!density
          ? volumeToMassCalculation(volume, temperature, density)
          : null
      if (total) totalSum = totalSum ? totalSum + total : total
      return {
        ...calc,
        total: formatValue(total, 3),
      }
    })
    this.setState({ calculations: calcs, totalSum: formatValue(totalSum, 3) })
  }

  componentDidMount() {
    if (!!this.props.storedState) {
      this.setState(this.props.storedState)
    } else {
      this.setState({
        totalSum: '-',
        calculations: [
          { ...newCalculationLine },
          { ...newCalculationLine },
          { ...newCalculationLine },
        ],
      })
    }
  }

  public render() {
    const { onSave, onCancel, onClose, toolContext, labelText } = this.props
    const { calculations, totalSum } = this.state
    return (
      <Modal visible title='MT calculator' closeHandler={onClose}>
        <FormElements.Form
          onSubmit={(e) => {
            e.preventDefault()
            e.stopPropagation()
            onSave(`${totalSum}`, this.state)
          }}
        >
          <ModalContent>
            <Wrapper>
              {labelText && (
                <FormElements.Label>{labelText}</FormElements.Label>
              )}
              <Table>
                <thead>
                  <tr>
                    <th>
                      <SortableTableHeader
                        headerText='Observed volume'
                        unit='m³'
                        disableSort
                      />
                    </th>
                    <th>
                      <SortableTableHeader
                        headerText='Temperature'
                        unit='°C'
                        disableSort
                      />
                    </th>
                    <th>
                      <SortableTableHeader
                        headerText='Density @ 15°C Vac'
                        unit='kg/m³'
                        disableSort
                      />
                    </th>
                    <th>
                      <SortableTableHeader
                        headerText='Total'
                        unit='MT'
                        disableSort
                      />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {calculations.map((line, index) => {
                    return (
                      <tr key={`calc-line-${index}`}>
                        <td>
                          <InputFieldWithUnit
                            type='number'
                            unit='m³'
                            placeholder='0.0'
                            value={calculations[index].volume}
                            onChange={(event) => {
                              const calc = [...calculations]
                              calc[index].volume = commaAgnostic(
                                event.target.value,
                              )
                              this.calculateRows(calc)
                            }}
                          />
                        </td>
                        <td>
                          <InputFieldWithUnit
                            type='number'
                            unit='°C'
                            placeholder='0.0'
                            value={calculations[index].temperature}
                            onChange={(event) => {
                              const calc = [...calculations]
                              calc[index].temperature = commaAgnostic(
                                event.target.value,
                              )
                              this.calculateRows(calc)
                            }}
                          />
                        </td>
                        <td>
                          <InputFieldWithUnit
                            type='number'
                            unit='kg/m³'
                            placeholder='0.0'
                            value={calculations[index].density}
                            onChange={(event) => {
                              const calc = [...calculations]
                              calc[index].density = commaAgnostic(
                                event.target.value,
                              )
                              this.calculateRows(calc)
                            }}
                          />
                        </td>
                        <td className='total-column'>{line.total}</td>
                      </tr>
                    )
                  })}
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan={3}>
                      <button
                        onClick={(e) => {
                          e.preventDefault()
                          e.stopPropagation()
                          const calc = calculations.slice()
                          calc.push({ ...newCalculationLine })
                          this.setState({ calculations: calc })
                        }}
                      >
                        <Icon icon='fal fa-plus-circle' /> Add new line
                      </button>
                    </td>
                    <td className='total-column'>{totalSum}</td>
                  </tr>
                </tfoot>
              </Table>
            </Wrapper>
          </ModalContent>
          <ModalControls>
            {!toolContext && (
              <McButton
                label='Cancel'
                appearance='neutral'
                click={onCancel}
                type='button'
              />
            )}
            <McButton
              label={toolContext ? 'Close' : 'Save'}
              appearance='primary'
              type='submit'
            />
          </ModalControls>
        </FormElements.Form>
      </Modal>
    )
  }
}
