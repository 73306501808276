import { useEffect, useState } from 'react'
import { Field, Form, Formik, validateYupSchema, yupToFormErrors } from 'formik'
import moment, { type Moment } from 'moment'
import { McButton } from '@maersk-global/mds-react-wrapper'

import { Performance } from '../../../../api-models'
import {
  FormDateTimeInput,
  FormTextArea,
  InfoBox,
  Loading,
  ModalControls,
} from '../../../../commons'
import * as PerformanceApi from '../../../../services/performance'
import { postSounding } from '../../../../services/performance'
import styled, { errorTheme } from '../../../../theme'
import { displayErrorModal, FuelType, isShoreContext } from '../../../../utils'
import { type ExistingSounding, type FormBatchSounding } from '../../models'
import { SoundingTable } from './SoundingTable'
import { castingSchema, validationSchema } from './validation-schemas'
import { StockRobNotifications } from '../StockRobNotifications'
import { ErrorMessage } from '../ErrorMessage'

const Container = styled.div`
  padding: 16px;
  max-height: 60vh;
  overflow-y: auto;

  .notes-label {
    text-align: right;
    font-size: 14px;
    min-width: 48px;
    margin-right: 12px;
    white-space: nowrap;
  }
`
const TimeFormContainer = styled.div`
  padding: 16px;
  width: 450px;

  .field-container {
    display: flex;
    align-items: center;

    .field-label {
      font-size: 14px;
      text-align: right;
      margin-right: 8px;
      white-space: nowrap;
    }
  }
`

const InfoStyle = styled.div`
  margin: 16px 16px 0 16px;
  width: 450px;
`

interface SoundingFormValues {
  timestamp?: Moment
  hsValues: FormBatchSounding[]
  vlsValues: FormBatchSounding[]
  ulsValues: FormBatchSounding[]
  mdoValues: FormBatchSounding[]
  notes: string
}

const defaultInitialValues: SoundingFormValues = {
  notes: '',
  hsValues: [],
  vlsValues: [],
  ulsValues: [],
  mdoValues: [],
}

enum STEPS {
  SELECT_TIME,
  ADD_SOUNDING,
}

const mapCalculatedRobToSoundingBatch = (
  calculatedRob: Performance.FuelOilStock.RobResponse,
  fuelType: FuelType,
): FormBatchSounding[] =>
  calculatedRob.batchQuantities
    .filter((x) => x.batch.fuel.type === fuelType)
    .map((b) => ({
      id: b.batch.id,
      displayName: b.batch.displayName,
      fuelType: b.batch.fuel.type,
      rob: b.quantity,
      adjustment: 0,
      quantity: b.quantity,
    }))

const mapExistingSoundingBatch = (
  sounding: ExistingSounding,
  fuelType: FuelType,
): FormBatchSounding[] =>
  sounding.batchQuantities
    .filter((b) => b.fuelType === fuelType)
    .map((b) => ({ ...b, rob: b.rob, adjustment: b.adjustment }))

const mapSoundingFormValuesToSoundingRequest = (
  values: SoundingFormValues,
): Performance.FuelOilStock.Sounding => {
  const [timestamp, batches, notes] = [
    values.timestamp!.toISOString(),
    [
      ...values.hsValues,
      ...values.vlsValues,
      ...values.ulsValues,
      ...values.mdoValues,
    ],
    values.notes,
  ]

  // Schema cast is used to force number type values instead of strings (ROB is the main culprit)
  // @ts-ignore
  return castingSchema.cast({
    timestamp,
    batches,
    notes,
  })
}

const getInitialValues = (sounding?: ExistingSounding) =>
  sounding
    ? {
        timestamp: moment.utc(sounding.timestamp),
        hsValues: mapExistingSoundingBatch(sounding, FuelType.HS),
        vlsValues: mapExistingSoundingBatch(sounding, FuelType.VLS),
        ulsValues: mapExistingSoundingBatch(sounding, FuelType.ULS),
        mdoValues: mapExistingSoundingBatch(sounding, FuelType.MDO),
        notes: sounding.notes,
      }
    : defaultInitialValues

type Props = {
  sounding?: ExistingSounding
  imoNo: string
  closeHandler: (refreshAdjustments?: boolean) => void
}

export const SoundingForm = ({ sounding, closeHandler, imoNo }: Props) => {
  const startingStep = !!sounding ? STEPS.ADD_SOUNDING : STEPS.SELECT_TIME
  const [currentStep, setCurrentStep] = useState<STEPS>(startingStep)
  const [rob, setRob] = useState<Performance.FuelOilStock.RobResponse>()
  const isReadMode = Boolean(sounding)
  const isShoreMode = isShoreContext()
  const isChangeAllowed = !!!sounding?.readonly
  const [openWindow, setOpenWindow] =
    useState<Performance.FuelOilStock.OpenWindow>()

  useEffect(() => {
    PerformanceApi.getStockOpenWindow(imoNo)
      .then(setOpenWindow)
      .catch((e) =>
        displayErrorModal({
          statusText: 'Could not get allowed time interval',
          message: e.message,
        }),
      )
  }, [imoNo])

  const initialValues = getInitialValues(sounding)

  const deleteSounding = (setSubmitting) => {
    if (isChangeAllowed && !isShoreMode && sounding?.id) {
      PerformanceApi.deleteSounding(imoNo, sounding?.id)
        .then(() => closeHandler(true))
        .catch((e) => {
          setSubmitting(false)
          displayErrorModal({
            statusText: 'Could not delete sounding',
            message: e.message,
          })
        })
    }
  }

  const getCalculatedRob = (
    imoNo: string,
    timestamp: Moment,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined,
    ) => void,
    setFieldError: (field: string, message: string) => void,
    setFieldTouched: (field: string, isTouched: boolean) => void,
    setSubmitting: (submitting: boolean) => void,
    soundingId?: string,
  ): void => {
    setRob(undefined)
    setSubmitting(true)
    PerformanceApi.getRob(imoNo, timestamp.toISOString(), soundingId)
      .then((data: Performance.FuelOilStock.RobResponse) => {
        setRob(data)
        if (data.hasData) {
          setFieldValue(
            'hsValues',
            mapCalculatedRobToSoundingBatch(data, FuelType.HS),
          )
          setFieldValue(
            'vlsValues',
            mapCalculatedRobToSoundingBatch(data, FuelType.VLS),
          )
          setFieldValue(
            'ulsValues',
            mapCalculatedRobToSoundingBatch(data, FuelType.ULS),
          )
          setFieldValue(
            'mdoValues',
            mapCalculatedRobToSoundingBatch(data, FuelType.MDO),
          )
        }
        /* setting field to touched to ensure form is validated when rob finishes fetching */
        setFieldTouched('timestamp', true)
      })
      .catch((e) => {
        if (e.statusCode === 400 && !!e.message)
          setFieldError('timestamp', e.message)
        else {
          void displayErrorModal(e)
        }
      })
      .finally(() => setSubmitting(false))
  }

  if (!openWindow) {
    return <Loading width='unset' />
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validate={(values: SoundingFormValues) => {
          try {
            void validateYupSchema<SoundingFormValues>(
              values,
              validationSchema,
              true,
              {
                rob: rob,
              },
            )
          } catch (err) {
            return yupToFormErrors(err)
          }
          return {}
        }}
        onSubmit={(values, { setSubmitting }) => {
          if (!isShoreMode && currentStep === STEPS.ADD_SOUNDING) {
            postSounding(imoNo, mapSoundingFormValuesToSoundingRequest(values))
              .then(() => closeHandler(true))
              .catch((e) => {
                setSubmitting(false)
                void displayErrorModal(e)
              })
          }

          if (currentStep === STEPS.SELECT_TIME) {
            setCurrentStep(STEPS.ADD_SOUNDING)
            setSubmitting(false)
          }
        }}
      >
        {({
          isSubmitting,
          setSubmitting,
          values,
          setFieldValue,
          setFieldError,
          setFieldTouched,
          isValid,
          isValidating,
        }) => (
          <Form>
            {currentStep === STEPS.SELECT_TIME && (
              <>
                {rob?.notifications && rob.notifications.length > 0 && (
                  <InfoStyle>
                    <StockRobNotifications rob={rob} />
                  </InfoStyle>
                )}
                <TimeFormContainer>
                  <div className='field-container'>
                    <span className='field-label'>Time of sounding UTC</span>
                    <Field
                      component={(props) => (
                        <FormDateTimeInput
                          {...props}
                          minuteSpecific={false}
                          minDate={moment.utc(openWindow.period.from)}
                          maxDate={moment.utc(openWindow.period.to)}
                        />
                      )}
                      name='timestamp'
                      disabled={!!sounding}
                      shouldValidate={false}
                      onChange={(value: Moment) => {
                        getCalculatedRob(
                          imoNo,
                          value,
                          setFieldValue,
                          setFieldError,
                          setFieldTouched,
                          setSubmitting,
                          sounding?.id,
                        )
                      }}
                    />
                  </div>
                  <ErrorMessage name='timestamp'></ErrorMessage>
                </TimeFormContainer>
              </>
            )}

            {currentStep === STEPS.ADD_SOUNDING && (
              <Container>
                <>
                  <p
                    style={{
                      fontSize: '14px',
                      padding: '6px 0',
                      color: '#666E79',
                    }}
                  >
                    Time of sounding UTC{' '}
                    <span style={{ color: '#000' }}>
                      {values.timestamp
                        ? values.timestamp.format('DD MMM YYYY HH:mm')
                        : ''}
                    </span>
                  </p>
                  <SoundingTable
                    isShoreMode={isShoreMode}
                    readonly={isReadMode}
                  />
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginTop: '16px',
                    }}
                  >
                    <div className='notes-label'>Notes</div>
                    <Field
                      component={FormTextArea}
                      disabled={isShoreMode || isReadMode}
                      name='notes'
                      width='100%'
                    />
                  </div>
                  <ErrorMessage name='notes'>
                    <>
                      {(msg) => (
                        <InfoBox hideIcons={true} theme={errorTheme}>
                          {msg}
                        </InfoBox>
                      )}
                    </>
                  </ErrorMessage>
                </>
              </Container>
            )}
            <ModalControls>
              {currentStep === STEPS.SELECT_TIME && (
                <div>
                  <McButton
                    label='Cancel'
                    appearance='neutral'
                    type='button'
                    click={() => closeHandler()}
                  />
                  <McButton
                    label='Next'
                    appearance='primary'
                    disabled={
                      !values?.timestamp ||
                      !isValid ||
                      isValidating ||
                      isSubmitting
                    }
                    type='submit'
                  />
                </div>
              )}
              {currentStep === STEPS.ADD_SOUNDING && sounding && (
                <div>
                  {isChangeAllowed && (
                    <McButton
                      label='Delete'
                      appearance='error'
                      className='left'
                      disabled={isSubmitting || isShoreMode}
                      type='button'
                      click={() => deleteSounding(setSubmitting)}
                    />
                  )}
                  <McButton
                    label='Close'
                    appearance='neutral'
                    type='button'
                    click={() => closeHandler()}
                  />
                </div>
              )}
              {currentStep === STEPS.ADD_SOUNDING && !sounding && (
                <div>
                  <McButton
                    label='Back'
                    appearance='neutral'
                    type='button'
                    click={() => setCurrentStep(STEPS.SELECT_TIME)}
                  />
                  <McButton
                    label='Add Sounding'
                    appearance='primary'
                    disabled={
                      isSubmitting ||
                      !isChangeAllowed ||
                      isShoreMode ||
                      !isValid
                    }
                    type='submit'
                  />
                </div>
              )}
            </ModalControls>
          </Form>
        )}
      </Formik>
    </>
  )
}
