import styled from 'styled-components'

export const Ul = styled.ul`
  color: #000;
  text-align: left;
  line-height: 1.6rem;
  list-style: none;

  li {
    font-size: var(--mds_brand_typography_text_medium_desktop_font-size);
    padding-bottom: 12px !important;

    ul {
      list-style: inside;

      li {
        padding: 0 !important;
      }
    }
  }

  p {
    padding-bottom: 8px;
  }
`
