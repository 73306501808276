import React from 'react'
import imgArrival from '../../../../assets/images/help-section/reporting-canal-arrival.png'
import imgEta from '../../../../assets/images/help-section/reporting-canal-eta.png'
import imgNoPortCode from '../../../../assets/images/help-section/reporting-canal-no-port-code.png'
import imgSequence1 from '../../../../assets/images/help-section/reporting-canal-sequence-1.png'
import imgSequence2 from '../../../../assets/images/help-section/reporting-canal-sequence-2.png'
import imgSequence3 from '../../../../assets/images/help-section/reporting-canal-sequence-3.png'

const GSIS_CANALS = [
  { code: 'TRBSUTM', name: 'Bosporus Canal' },
  { code: 'TRCANDT', name: 'Canakkale' },
  { code: 'DEKECTM', name: 'Kiel Canal' },
  { code: 'PAPTYTM', name: 'Panama Canal' },
  { code: 'EGSUCCN', name: 'Suez Canal' },
  { code: 'AUTORTM', name: 'Torres Strait' },
]

const OTHER_CANALS = [
  'Cape Cod Canal',
  'Chesapeake-Delaware Canal',
  'Inner Danish Waters',
  'Kanmon Kaikyo',
  'Strait of Magellan E',
  'Strait of Magellan W',
  'Strait of Messina',
]

export const CanalTransit = React.forwardRef<HTMLDivElement>((props, ref) => (
  <div ref={ref} id='help-section-subtopic'>
    <h4>In this guide you will learn</h4>
    <ul>
      <li>How to report a canal transit</li>
      <li>How to include port calls before/after canal</li>
      <li>How to report waiting time</li>
    </ul>
    <h4>Purpose</h4>
    <p>
      Purpose of this guide is to introduce you to a new separate Canal report
      that replaces the old MSPS process. The canal report contains same data as
      a sea report but it is clearly separated from the sea passage.
    </p>
    <h4>Prerequisites</h4>
    <ul>
      <li>
        All data losses within the report period (if any) should be repaired on
        the Consumption page for the report period. You will see Alert in
        StarConnect if there is data loss
      </li>
      <li>
        Your Stock module should be up to date - make sure all bunkerings are
        reported and soundings are up to date
      </li>
    </ul>
    <h4>Canal transits</h4>
    <p>You have 2 different scenarios where you can report a canal transit:</p>
    <ul>
      <li>The canal is part of your GSIS schedule</li>
      <li>
        You are transitting other canal where you are taking pilot / are under
        special constraints for manouvering
      </li>
    </ul>
    <div className='flex-container'>
      <table>
        <thead>
          <tr>
            <th>GSIS Code</th>
            <th>Canal name</th>
          </tr>
        </thead>
        <tbody>
          {GSIS_CANALS.map((canal, idx) => (
            <tr key={`${idx}-${canal.code}`}>
              <td>{canal.code}</td>
              <td>{canal.name}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <table>
        <thead>
          <tr>
            <th>Other Canals - not part of GSIS schedule</th>
          </tr>
        </thead>
        <tbody>
          {OTHER_CANALS.map((name, idx) => (
            <tr key={idx}>
              <td>{name}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    <p>
      The timestamps that normally defines the period for the Canal report is
      from Arrival Pilot station till Drop of Canal pilot. If port calls are
      involved immediately before/after the Canal report can include the
      Departure/Arrival period.
    </p>
    <p className='title'>Canals with GSIS schedule</p>
    <p>
      When you are departing from previous port and your next arrival will be
      the canal. When creating the canal report you will see the Canal
      destination transferred to the canal code and you will be asked to add new
      arrival port, ETA and remaining distance to next port.
    </p>
    <div className='image-container'>
      <img src={imgArrival} alt='Arrival' />
      <img src={imgEta} alt='ETA' />
    </div>
    <p className='title'>Canals without GSIS schedule</p>
    <p>
      When you are transitting other canals that is not part of the GSIS
      schedule you can create a canal report and add the name of the canal. You
      will still see your scheduled port and ETA which was reported in your
      previous departure report. If the canal does not appear in your search you
      can add the name by clicking “Canal not in list”.
    </p>
    <img src={imgNoPortCode} alt='No port code' />
    <p>
      Normally you will be reporting both Arrival, Alongside and Departure for a
      port call.
    </p>
    <img src={imgSequence1} alt='Report sequence' />
    <p className='title'>Portcall</p>
    <p>
      If you have a port call immediately before or after the canal report you
      can include the arrival or departure period in the canal report. Your
      report sequence will be as follows.
    </p>
    <div className='image-container'>
      <img src={imgSequence2} alt='Report sequence - port call before canal' />
      <img src={imgSequence3} alt='Report sequence - port call after canal' />
    </div>
  </div>
))
