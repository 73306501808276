import * as React from 'react'
import { Datepicker } from './DatePicker'
import { TimePicker } from './TimePicker'
import moment, { Moment } from 'moment'
import { pad } from '../utils'
import styled, { grey, formSizes, orange, red } from '../theme'
import { getClosestTenMinute } from '../utils/time-utils'

const Wrapper = styled.div<{ height?: string }>`
  box-sizing: border-box;
  display: flex;
  label {
    display: flex;
    align-items: center;
    text-align: right;
    margin-right: 8px;
    vertical-align: top;
    max-height: 42px;
  }

  .form-content {
    .form-inputs {
      align-items: center;
      display: flex;
      width: ${formSizes.wideInput}px;

      .calendar {
        margin-right: 8px;
        input {
          height: ${(props) => props.height || '38px'};
          border-color: ${grey[300]};
        }
      }

      &.error-brand {
        input {
          border-color: ${red[700]};
        }

        .calendar span {
          color: ${red[700]};
          background-color: ${red[100]};
          border-color: ${red[700]};
        }

        svg {
          fill: ${red[700]};
        }

        #id-hour-picker,
        #id-minute-picker {
          > div > div {
            border-color: ${red[700]};
          }
        }
      }

      &.warning {
        input {
          border-color: ${orange[400]};
        }

        .calendar span {
          color: ${orange[400]};
          background-color: ${orange[100]};
          border-color: ${orange[400]};
        }

        svg {
          fill: ${orange[400]};
        }

        #id-hour-picker,
        #id-minute-picker {
          > div > div {
            border-color: ${orange[400]};
          }
        }
      }
    }
  }
`

export interface DateTimeInputProps {
  disabled?: boolean
  disabledValue?: Moment
  error?: boolean
  errorDescription?: string
  hasWarning?: boolean
  height?: string
  id?: string
  maxDate?: Moment
  minDate?: Moment
  minuteSpecific?: boolean
  onBlur?: () => void
  onChange: (moment: Moment) => void
  selected: Moment | null
}

interface DateTimeInputState {
  date: Moment
  hour: any
  minute: any
  hasClicked?: boolean
}

export class DateTimeInput extends React.Component<
  DateTimeInputProps,
  DateTimeInputState
> {
  constructor(props: DateTimeInputProps) {
    super(props)
    const dateTime = props.selected || moment.utc()
    this.state = {
      date: dateTime,
      hour: dateTime.hours(),
      minute: props.minuteSpecific
        ? dateTime.minutes()
        : getClosestTenMinute(dateTime).minutes(),
    }
  }

  componentDidUpdate(
    prevProps: DateTimeInputProps,
    prevState: DateTimeInputState,
  ) {
    const { date, hour, minute, hasClicked } = this.state
    const { maxDate, minDate } = this.props
    switch (true) {
      case !prevState.hasClicked && hasClicked:
      case prevState.date.valueOf() !== date.valueOf():
      case prevState.hour !== hour:
      case prevState.minute !== minute:
        date.hour(hour)
        date.minute(minute)
        if (maxDate && date.isAfter(maxDate)) {
          /* snap to max date if user tried selecting a time after maxDate */
          this.props.onChange(moment(maxDate).startOf('minute'))
        } else if (minDate && date.isBefore(minDate)) {
          /* snap to min date if user tried selecting a time before minDate */
          this.props.onChange(moment(minDate).startOf('minute'))
        } else {
          this.props.onChange(moment(date).startOf('minute'))
        }
        break
    }
  }

  render() {
    const {
      disabled,
      error,
      errorDescription,
      hasWarning,
      height,
      id,
      maxDate,
      minDate,
      minuteSpecific,
      onBlur,
      selected,
    } = this.props
    const selectedDateTime = !selected
      ? moment.utc().startOf('minute')
      : selected.startOf('minute')
    const [date, hour, minute] = [
      selectedDateTime,
      selectedDateTime.get('h'),
      minuteSpecific
        ? selectedDateTime.minutes()
        : getClosestTenMinute(selectedDateTime).minutes(),
    ]

    const isMaxDaySelected = selectedDateTime.isSame(maxDate, 'day')
    const isMinDaySelected = selectedDateTime.isSame(minDate, 'day')

    const isMaxDayAndHourSelected = selectedDateTime.isSame(maxDate, 'hour')
    const isMinDayAndHourSelected = selectedDateTime.isSame(minDate, 'hour')

    return (
      <Wrapper height={height}>
        <div className='form-content'>
          <div
            className={`form-inputs ${
              error ? 'error-brand' : hasWarning ? 'warning' : ''
            }`}
          >
            <div className='calendar'>
              {/* converting moment to date with full ISO string may result in time zone issues - therefore stripping any info other than date away */}
              <Datepicker
                id={`${id || 'id'}-date-picker`}
                disabled={disabled}
                selected={
                  selected || selected === null
                    ? selected
                      ? new Date(selected?.format('DD MMM YYYY'))
                      : undefined
                    : date.toDate()
                }
                dateFormat='dd MMM yyyy'
                onBlur={onBlur}
                onChange={(newDate) => {
                  /* the datepicker will serve us the date (newDate) in local timezone, but it is already UTC. That's why we get the LocaleDateString and make store it as utc */
                  if (newDate) {
                    this.setState({
                      date: moment.utc(
                        Array.isArray(newDate)
                          ? newDate[0].toLocaleDateString('en-us')
                          : newDate.toLocaleDateString('en-US'),
                        'MM/DD/YYYY',
                      ),
                      hasClicked: true,
                    })
                  }
                }}
                // prevent Date to be converted to local time by keeping only year/month/date info in moment object
                minDate={
                  minDate ? new Date(minDate.format('DD MMM YYYY')) : undefined
                }
                maxDate={
                  maxDate
                    ? new Date(maxDate.format('DD MMM YYYY'))
                    : moment.utc().toDate()
                }
              />
            </div>
            <div className='time'>
              <TimePicker
                id={`${id || 'id'}-hour-picker`}
                disabled={disabled}
                isHour
                value={
                  selected === null
                    ? { value: -1, label: '' }
                    : {
                        value: hour,
                        label: `${pad(hour, 2)}`,
                      }
                }
                maxValue={
                  maxDate && isMaxDaySelected
                    ? maxDate.utc().hours()
                    : undefined
                }
                minValue={
                  minDate && isMinDaySelected
                    ? minDate.utc().hours()
                    : undefined
                }
                onChange={(selectedOption) => {
                  this.setState({ hour: selectedOption.value })
                }}
                onFocus={() => this.forceUpdate()}
              />
            </div>
            <span>:</span>
            <TimePicker
              id={`${id || 'id'}-minute-picker`}
              disabled={disabled}
              specificSelectable={minuteSpecific}
              value={
                selected === null
                  ? { value: -1, label: '' }
                  : {
                      value: minute,
                      label: `${pad(minute, 2)}`,
                    }
              }
              maxValue={
                maxDate && isMaxDayAndHourSelected
                  ? maxDate.utc().minutes()
                  : undefined
              }
              minValue={
                minDate && isMinDayAndHourSelected
                  ? minDate.utc().minutes()
                  : undefined
              }
              onChange={(selectedOption) => {
                this.setState({
                  minute: selectedOption.value,
                })
              }}
              onFocus={() => this.forceUpdate()}
            />
          </div>
          {error && errorDescription && (
            <div className='error'>{errorDescription}</div>
          )}
        </div>
      </Wrapper>
    )
  }
}
