import * as yup from 'yup'

import { ApiReportType, DataPoint } from '../../../api-models/hdc/report'
import { isNumber } from '../../../utils'
import { FormEquipment, HDCHoursMinutes, ValidationErrorType } from '../types'

declare module 'yup' {
  interface ObjectSchema<T> {
    equipmentIsRequired(
      equipmentName: string,
      message: string,
      allowZero?: boolean,
    ): ObjectSchema<T>
    runtimeIsRequired(
      equipmentName: string,
      message?: string,
      allowZero?: boolean,
    ): ObjectSchema<T>
  }
  interface StringSchema<T> {
    shorePowerUsedIsRequired(
      reportType: string,
      message: string,
    ): StringSchema<T>
    shorePowerReasonIsRequired(
      reportType: string,
      message: string,
    ): StringSchema<T>
    periodDateIsRequired(
      reportType: string,
      periodType: string,
      message: string,
      allowZero?: boolean,
    ): StringSchema<T>
  }
  interface NumberSchema<T> {
    periodEnergyIsRequired(
      reportType: string,
      periodType: string,
      message: string,
      allowZero?: boolean,
    ): NumberSchema<T>
    periodHourIsRequired(
      reportType: string,
      periodType: string,
      message: string,
      allowZero?: boolean,
    ): NumberSchema<T>
    periodMinuteIsRequired(
      reportType: string,
      periodType: string,
      message: string,
      allowZero?: boolean,
    ): NumberSchema<T>
  }
}
export function shorePowerUsedIsRequired(
  this: yup.StringSchema<string>,
  reportType,
  message,
) {
  return this.test(
    ValidationErrorType.REQUIRED,
    message,
    function (value: string) {
      if (reportType === ApiReportType.ALONGSIDE && !value) {
        return this.createError({
          message: `${message}`,
          path: `${this.path}.value`,
        })
      }
      if (
        reportType === ApiReportType.ALONGSIDE &&
        value === 'yes' &&
        this.parent.numberOfPeriods <= 0
      ) {
        return this.createError({
          message: `At least one period is required because shore power used is selected as "Yes"`,
          path: `${this.path}.value`,
        })
      }
      return true
    },
  )
}

export function shorePowerReasonIsRequired(
  this: yup.StringSchema<string>,
  reportType,
  message,
) {
  return this.test(
    ValidationErrorType.REQUIRED,
    message,
    function (value: string) {
      if (
        reportType === ApiReportType.ALONGSIDE &&
        this.parent.shorePowerUsed === 'no' &&
        !value
      ) {
        return this.createError({
          message: `${message}`,
          path: `${this.path}.value`,
        })
      }
      return true
    },
  )
}

export function periodEnergyIsRequired(
  this: yup.NumberSchema<number>,
  reportType,
  periodType,
  message,
  allowZero,
) {
  return this.test(
    ValidationErrorType.REQUIRED,
    message,
    function (value: number) {
      if (reportType === ApiReportType.ALONGSIDE) {
        if (!isNumber(value)) {
          return this.createError({
            message: `Shore power ${periodType} ${periodNumberFunction(
              this.path,
            )} ${message}`,
            path: `${this.path}.value`,
          })
        }

        if (allowZero) {
          if (value < 0) {
            return this.createError({
              message: `Shore power ${periodType} ${periodNumberFunction(
                this.path,
              )} energy value cannot be negative! Must at least be 0.`,
              path: `${this.path}.value`,
            })
          }
        } else {
          if (value <= 0) {
            return this.createError({
              message: `Shore power ${periodType} ${periodNumberFunction(
                this.path,
              )} energy value must be more than 0.`,
              path: `${this.path}.value`,
            })
          }
        }
      }
      return true
    },
  )
}

export function periodDateIsRequired(
  this: yup.StringSchema<string>,
  reportType,
  periodType,
  message,
) {
  return this.test(
    ValidationErrorType.REQUIRED,
    message,
    function (value: string) {
      if (reportType === ApiReportType.ALONGSIDE && !value) {
        return this.createError({
          message: `Shore power ${periodType} ${periodNumberFunction(
            this.path,
          )} ${message}`,
          path: `${this.path}.value`,
        })
      }
      return true
    },
  )
}
export function periodHourIsRequired(
  this: yup.NumberSchema<number>,
  reportType,
  periodType,
  message,
  allowZero = true,
) {
  return this.test(
    ValidationErrorType.REQUIRED,
    message,
    function (value: number) {
      if (reportType === ApiReportType.ALONGSIDE) {
        if (!isNumber(value)) {
          return this.createError({
            message: `Shore power ${periodType} ${periodNumberFunction(
              this.path,
            )} ${message}`,
            path: `${this.path}.value`,
          })
        }

        if (allowZero) {
          if (value < 0) {
            return this.createError({
              message: `Shore power ${periodType} ${periodNumberFunction(
                this.path,
              )} hour value cannot be negative! Must at least be 0.`,
              path: `${this.path}.value`,
            })
          }
        } else {
          if (value <= 0) {
            return this.createError({
              message: `Shore power ${periodType} ${periodNumberFunction(
                this.path,
              )} hour value must be more than 0.`,
              path: `${this.path}.value`,
            })
          }
        }
      }
      return true
    },
  )
}

export function periodMinuteIsRequired(
  this: yup.NumberSchema<number>,
  reportType,
  periodType,
  message,
  allowZero = true,
) {
  return this.test(
    ValidationErrorType.REQUIRED,
    message,
    function (value: number) {
      if (reportType === ApiReportType.ALONGSIDE) {
        if (!isNumber(value)) {
          return this.createError({
            message: `Shore power ${periodType} ${periodNumberFunction(
              this.path,
            )} ${message}`,
            path: `${this.path}.value`,
          })
        }
        if (allowZero) {
          if (value < 0) {
            return this.createError({
              message: `Shore power ${periodType} ${periodNumberFunction(
                this.path,
              )} minute value cannot be negative! Must at least be 0.`,
              path: `${this.path}.value`,
            })
          }
        } else {
          if (value <= 0) {
            return this.createError({
              message: `Shore power ${periodType} ${periodNumberFunction(
                this.path,
              )} minute value must be more than 0.`,
              path: `${this.path}.value`,
            })
          }
        }
      }
      return true
    },
  )
}

export function equipmentIsRequired(
  this: yup.ObjectSchema<DataPoint<number>>,
  equipmentName,
  message,
  allowZero = true,
) {
  return this.test(
    ValidationErrorType.REQUIRED,
    message,
    function (datapoint: DataPoint<number>) {
      const equipment = this.parent as FormEquipment

      if (!isNumber(datapoint.value)) {
        return this.createError({
          message: `${equipmentName} ${equipment.number || ''} ${message}`,
          path: `${this.path}.value`,
        })
      }

      if (allowZero) {
        if (datapoint.value < 0) {
          return this.createError({
            message: `${equipmentName} ${
              equipment.number || ''
            } cannot be negative! Must at least be 0.`,
            path: `${this.path}.value`,
          })
        }
      } else {
        if (datapoint.value <= 0) {
          return this.createError({
            message: `${equipmentName} ${
              equipment.number || ''
            } must be more than 0.`,
            path: `${this.path}.value`,
          })
        }
      }

      return true
    },
  )
}

export function runtimeIsRequired(
  this: yup.ObjectSchema<HDCHoursMinutes>,
  equipmentName,
  message,
  allowZero = true,
) {
  return this.test(
    ValidationErrorType.REQUIRED,
    message,
    function ({ hours, minutes }: HDCHoursMinutes) {
      const equipment = this.parent as FormEquipment

      if (!isNumber(hours.value)) {
        return this.createError({
          message: `${equipmentName} ${equipment.number || ''} ${
            message || 'run hours cannot be empty!'
          }`,
        })
      }

      if (!isNumber(minutes.value)) {
        return this.createError({
          message: `${equipmentName} ${equipment.number || ''} ${
            message || 'run minutes cannot be empty!'
          }`,
        })
      }

      if (allowZero) {
        if (hours.value < 0) {
          return this.createError({
            message: `${equipmentName} ${
              equipment.number || ''
            } run hours cannot be negative! Must at least be 0.`,
          })
        }

        if (minutes.value < 0) {
          return this.createError({
            message: `${equipmentName} ${
              equipment.number || ''
            } run minutes cannot be negative! Must at least be 0.`,
          })
        }
      } else {
        if (hours.value <= 0 && minutes.value <= 0) {
          return this.createError({
            message: `${equipmentName} ${
              equipment.number || ''
            } runtime must be more than 0.`,
          })
        }
      }

      return true
    },
  )
}
function periodNumberFunction(path) {
  return Number(path.replace(/[^\d]/g, '')) + 1
}
