import { ChartType } from '../PerformanceMetricsCharts.consts'

export const chartTypeToId = {
  [ChartType.Sfoc]: 'sfoc',
  [ChartType.Pmax]: 'pmax',
  [ChartType.Pcomp]: 'pcom',
}

export const chartTypeToYAxisName = {
  [ChartType.Sfoc]: 'SFOC [g/kWh]',
  [ChartType.Pmax]: 'Pmax [bar]',
  [ChartType.Pcomp]: 'Pcomp [bar]',
}
export const unitsConfig = {
  [ChartType.Sfoc]: 'g/kWh',
  [ChartType.Pmax]: 'bar',
  [ChartType.Pcomp]: 'bar',
}
