import { createColumnHelper } from '@tanstack/react-table'
import moment from 'moment'

import { type SettingsLog } from '../../../../api-models/performance/vessel-settings'
import { VESSEL_SETTINGS_NAMES } from '../../../../utils/constants'
import { formatValue } from '../../../../utils'

const columnHelper = createColumnHelper<SettingsLog>()

export const columns = [
  columnHelper.accessor('timestamp', {
    header: 'Changed',
    cell: (props) => moment.utc(props.getValue()).format('DD MMM YYYY HH:mm'),
  }),
  columnHelper.accessor('type', {
    header: 'Type',
    cell: (props) => VESSEL_SETTINGS_NAMES[props.getValue()],
  }),
  columnHelper.accessor('value', {
    header: 'Change',
    cell: (props) => (
      <span>
        {/* @ts-ignore */}
        from&nbsp;<b>{formatValue(props.row.original.previousValue, 3)}</b>
        &nbsp;to&nbsp;
        {/* @ts-ignore */}
        <b>{formatValue(props.getValue(), 3)}</b>
      </span>
    ),
  }),
]
