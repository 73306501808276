import React from 'react'

import { Table, Tbody, Th, Thead, Tr } from '../../../../commons/tables/styles'
import ReadonlyField from '../readonly-field'
import styled from 'styled-components'
import { HDCReportFormValues, Period } from '../../types'

type Props = {
  report: HDCReportFormValues
}

const ShorePowerSummary = styled.div`
  display: inline-flex;
  gap: 40px;
`
const ReadonlyShorePowerComponent = ({ report }: Props) => {
  const { shorePowerPeriods } = report.engineRoom

  const getPeriodSection = (period: Period, title: string) => (
    <Tr key={`${title}`}>
      <td>{title}</td>
      <td>
        <ReadonlyField
          name={`${title}-energy`}
          value={period.totalEnergy ?? '-'}
        />
      </td>
      <td>
        <ReadonlyField name={`${title}-date`} value={period.totalDays ?? '-'} />
      </td>
      <td>
        <ReadonlyField
          name={`${title}-runtime`}
          value={period.totalHoursMinutes ?? '-'}
        />
      </td>
    </Tr>
  )

  if (!shorePowerPeriods) return null

  return (
    <>
      <ShorePowerSummary>
        <ReadonlyField
          label='Shore power used'
          name='shore-power-used'
          value={shorePowerPeriods.shorePowerUsed?.toUpperCase() ?? '-'}
        />
        {shorePowerPeriods.shorePowerUsed === 'no' && (
          <ReadonlyField
            label='Reason'
            name='reason'
            value={shorePowerPeriods.reason ?? '-'}
          />
        )}
        {shorePowerPeriods.comment !== '' && (
          <ReadonlyField
            label='Comments'
            name='comments'
            value={shorePowerPeriods.comment ?? '-'}
          />
        )}
      </ShorePowerSummary>
      {shorePowerPeriods.shorePowerUsed === 'yes' && (
        <Table>
          <Thead>
            <Tr>
              <Th>Shore Power</Th>
              <Th>Energy counter [kWh]</Th>
              <Th>Days</Th>
              <Th>Time [hh:mm]</Th>
            </Tr>
          </Thead>
          <Tbody striped={true}>
            {shorePowerPeriods.periods.map((period: Period, idx: number) =>
              getPeriodSection(period, `Period ${idx + 1}`),
            )}
            <Tr>
              <td>Total shore power in report</td>
              <td>
                <ReadonlyField
                  name={`shorePowerPeriods-grandTotalEnergy`}
                  value={shorePowerPeriods.grandTotalEnergy ?? '-'}
                />
              </td>
              <td>
                <ReadonlyField
                  name={`shorePowerPeriods-grandTotalDays`}
                  value={shorePowerPeriods.grandTotalDays ?? '-'}
                />
              </td>
              <td>
                <ReadonlyField
                  name={`shorePowerPeriods-grandTotalHoursMinutes`}
                  value={shorePowerPeriods.grandTotalHoursMinutes ?? '-'}
                />
              </td>
            </Tr>
          </Tbody>
        </Table>
      )}
    </>
  )
}

export default ReadonlyShorePowerComponent
