import { useContext } from 'react'
import { useField } from 'formik'
import { McTextarea } from '@maersk-global/mds-react-wrapper'

import { WindowContext } from '../../../../contexts'

type Props = {
  mandatory?: boolean
}

const Comment = (props: Props) => {
  const { windowSize } = useContext(WindowContext)
  const [field, meta, helpers] = useField<string | null>('data.comment')

  const handleBlur = (event: FocusEvent) => {
    const target = event.target as HTMLInputElement
    let newValue = target.value as string | null
    if (newValue === '') {
      newValue = null
    }

    if (field.value === newValue) {
      // No change. Do nothing.
      return
    }

    void helpers.setValue(newValue)
  }

  return (
    <McTextarea
      fit={windowSize}
      label='Kindly share any learnings or underlying reason for malfunction'
      placeholder={props.mandatory ? undefined : 'Optional'}
      maxlength={1000}
      rows={5}
      value={field.value}
      invalid={Boolean(meta.error)}
      blur={handleBlur}
    />
  )
}

export default Comment
