import { FieldArray, getIn, useFormikContext } from 'formik'

import { type HDCReportFormValues } from '../types'
import { Table, Tbody, Th, Thead, Tr } from '../../../commons/tables/styles'
import { EquipmentTh, RuntimeTd, RuntimeTh } from './styles'
import { UNITS } from '../../../utils/constants'
import HDCInputField from '../components/hdc-input-field'

type Props = {
  warnings: { [path: string]: string }
}

const ElectricalProducersTable = ({ warnings }: Props) => {
  const { values, errors } = useFormikContext<HDCReportFormValues>()
  const { auxEngines, shaftGenerators, wasteHeatRecovery } = values.engineRoom

  const getSection = (producer: string, title: string) => (
    <Tr key={producer}>
      <td>{title}</td>
      <td>
        <HDCInputField
          addon={UNITS.KILO_WATT_HOUR}
          decimals={0}
          error={getIn(errors, `engineRoom.${producer}.energy`)}
          hasWarning={!!warnings[`engineRoom.${producer}.energy`]}
          name={`engineRoom.${producer}.energy.value`}
          placeholder='Energy'
          type='number'
        />
      </td>
      <RuntimeTd>
        <HDCInputField
          addon='hr'
          decimals={0}
          error={getIn(errors, `engineRoom.${producer}.runTime`)}
          hasWarning={!!warnings[`engineRoom.${producer}.runTime`]}
          name={`engineRoom.${producer}.runTime.hours.value`}
          placeholder='Hours'
          type='number'
        />
        <HDCInputField
          addon='mins'
          decimals={0}
          error={getIn(errors, `engineRoom.${producer}.runTime`)}
          hasWarning={!!warnings[`engineRoom.${producer}.runTime`]}
          name={`engineRoom.${producer}.runTime.minutes.value`}
          placeholder='Minutes'
          type='number'
        />
      </RuntimeTd>
    </Tr>
  )

  return (
    <Table>
      <Thead>
        <Tr>
          <EquipmentTh>Producer</EquipmentTh>
          <Th>Energy</Th>
          <RuntimeTh>Runtime</RuntimeTh>
        </Tr>
      </Thead>
      <Tbody striped={true}>
        <FieldArray
          name='engineRoom.auxEngines'
          render={() =>
            auxEngines.map((engine, idx) =>
              getSection(`auxEngines.${idx}`, `AE ${engine.number}`),
            )
          }
        />
        <FieldArray
          name='engineRoom.shaftGenerators'
          render={() =>
            shaftGenerators.map((generator, idx) =>
              getSection(
                `shaftGenerators.${idx}`,
                `Shaft generator ${generator.number}`,
              ),
            )
          }
        />
        {wasteHeatRecovery &&
          getSection('wasteHeatRecovery', 'Waste heat recovery')}
      </Tbody>
    </Table>
  )
}

export default ElectricalProducersTable
