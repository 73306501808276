import { type QueryFunctionContext, useQuery } from '@tanstack/react-query'
import { displayErrorModal, doGet, getHydraApiUrl } from '../../utils'
import { useVesselMainEngines } from '../MasterDataApi/MasterDataApi'
import { filterDataByMaxSetpoint } from '../../pages/PropulsionPage/PropulsionPage.utils'

const keys = {
  TemAdvice: [{ HydraApi: ['TemAdvice'] }] as const,
  // prettier-ignore
  temAdvice: (imoNo: number, timestamp: string) => [{
    ...keys.TemAdvice[0], imoNo, timestamp
  }] as const,
  SFOC: [
    { HydraApi: ['VesselSpecificBaseline'] },
    { HydraApi: ['filteredSFOC'] },
    { HydraApi: ['ShopTestModels'] },
  ] as const,
  VesselSpecificBaseline: (imoNo: string, queryPeriod: HydraApi.QueryPeriod) =>
    [{ ...keys.SFOC[0], imoNo, queryPeriod }] as const,
  filteredSFOC: (imoNo: string, queryPeriod: HydraApi.QueryPeriod) =>
    [{ ...keys.SFOC[1], imoNo, queryPeriod }] as const,
  shopTestModels: (imoNo: string) => [{ ...keys.SFOC[2], imoNo }] as const,
}

const getTemAdvice = async ({
  queryKey: [{ imoNo, timestamp }],
}: QueryFunctionContext<
  ReturnType<(typeof keys)['temAdvice']>
>): Promise<HydraApi.TemAdvice> => {
  return doGet(
    `${getHydraApiUrl()}/Vessels/TemAdvice/${imoNo}?timestamp=${timestamp}`,
  )
}

export const useTemAdvice = (
  imoNo: number,
  timestamp: string,
  enabled = true,
) => {
  return useQuery({
    queryKey: keys.temAdvice(imoNo, timestamp),
    queryFn: getTemAdvice,
    staleTime: 600000,
    enabled,
  })
}

export const useVesselSpecificBaseline = (
  imoNo: string,
  queryPeriod: HydraApi.QueryPeriod,
) => {
  const vesselMainEngineQuery = useVesselMainEngines(imoNo!)

  return useQuery({
    queryKey: keys.VesselSpecificBaseline(imoNo, queryPeriod),
    queryFn: getVesselSpecificBaselineModel,
    select: (data) =>
      filterDataByMaxSetpoint(
        data.baselineModel,
        vesselMainEngineQuery.data?.engines[0].maxSetpoint,
      ),
    onError: displayErrorModal,
    staleTime: 1 * 60 * 60 * 1000, // 1 hour
  })
}

const getVesselSpecificBaselineModel = async ({
  queryKey: [{ imoNo, queryPeriod }],
}: QueryFunctionContext<
  ReturnType<(typeof keys)['VesselSpecificBaseline']>
>): Promise<HydraApi.SfocBaselineModel.VesselSpecific> => {
  return doGet(
    `${getHydraApiUrl()}/Vessels/SFOCBaselineModel/MainEng/${imoNo}?startDate=${
      queryPeriod.from
    }&endDate=${queryPeriod.to}`,
  )
}

export const useFilteredSFOC = (
  imoNo: string,
  queryPeriod: HydraApi.QueryPeriod,
) => {
  return useQuery({
    queryKey: keys.filteredSFOC(imoNo, queryPeriod),
    queryFn: getFilteredSFOC,
    onError: displayErrorModal,
  })
}

const getFilteredSFOC = async ({
  queryKey: [{ imoNo, queryPeriod }],
}: QueryFunctionContext<
  ReturnType<(typeof keys)['filteredSFOC']>
>): Promise<HydraApi.Sfoc.MainEngine> => {
  return doGet(
    `${getHydraApiUrl()}/Vessels/SFOC/MainEng/${imoNo}?startDate=${
      queryPeriod.from
    }&endDate=${queryPeriod.to}`,
  )
}
