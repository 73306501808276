import { grey } from '../../../theme'

// Returns the values contained in the cells of the trim table css grid
export const getTableValues = (
  meanDraughts: number[],
  trimValues: number[],
  propPowerMatrix: any,
  meanDraughtReference: number,
): [TrimTableVal[], (number | null)[]] => {
  const reverseTrim = trimValues.slice().reverse()
  const reverseMeanDraught = meanDraughts.slice().reverse()
  const tableValues = reverseMeanDraught.reduce(
    (cellAcc: TrimTableVal[], draughtVal: number, rowIndex: number) => {
      return [
        ...cellAcc,
        {
          val: draughtVal,
          isReference: false,
        },
      ].concat(
        reverseTrim.map((trimVal, colIndex) => {
          return {
            val:
              // Picking values out from propulsion power matrix in reverse order
              propPowerMatrix[meanDraughts.length - 1 - rowIndex][
                trimValues.length - 1 - colIndex
              ],
            isReference: trimVal === 0 && draughtVal === meanDraughtReference,
          } as TrimTableVal
        }),
      ) as TrimTableVal[]
    },
    [],
  )
  const trims = [...trimValues, null].reverse() // null value is empty cell under mean draught column
  return [tableValues, trims]
}

export const getColor = (value: number | null) => {
  // This is a very ad hoc function that I've sneaked in without consulting
  // UX people, any changes are welcome
  if (value === null) return grey[100]
  if (value <= 0) {
    const frac = Math.abs(Math.max(value, -5) / 5)
    return `rgba(${Math.round(255 - 190 * frac)}, ${Math.round(
      222 - 15 * frac,
    )}, ${Math.round(87 + 62 * frac)}, 1)`
  }
  const frac = Math.abs(Math.min(value, 5) / 5)
  return `rgba(${Math.round(255 - 30 * frac)}, ${Math.round(
    222 - 147 * frac,
  )}, ${Math.round(87 + 26 * frac)}, 1)`
}
