import { Modal } from '../../../../commons'
import { type BunkeringFormValues } from '../bunkering/BunkeringForm'
import { BunkeringFormInitialRob } from './BunkeringFormInitialRob'
import { type FormBatch, type InitialRobBatch } from '../../models'

type Props = {
  batch?: FormBatch
  batches: InitialRobBatch[]
  closeHandler: (refresh?: boolean) => void
  submitBunkering: (values: BunkeringFormValues, batchId?: string) => void
  deleteBunkering: (batchId: string) => void
}

export const BunkeringModalInitialRob = ({
  closeHandler,
  batch,
  batches,
  submitBunkering,
  deleteBunkering,
}: Props) => {
  return (
    <Modal title='Add new bunkered batch' visible closeHandler={closeHandler}>
      <BunkeringFormInitialRob
        closeHandler={closeHandler}
        batches={batches}
        batch={batch}
        submitBunkering={submitBunkering}
        deleteBunkering={deleteBunkering}
      />
    </Modal>
  )
}
