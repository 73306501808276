import debounce from 'lodash/debounce'
import { Chart } from '../../utils'

export function connectChartsLineScatter(
  timelineChart: Chart,
  scatterChart: Chart,
  timestamps: number[],
) {
  scatterChart.on('hidetip', (event: any) => {
    const action = timelineChart.makeActionFromEvent(event)
    timelineChart.dispatchAction(
      { ...action, tooltipOption: { show: true } },
      true,
    )
  })

  timelineChart.on('hidetip', (event: any) => {
    const action = scatterChart.makeActionFromEvent(event)
    scatterChart.dispatchAction(
      { ...action, tooltipOption: { show: true } },
      true,
    )
  })
  const dataSeries = [
    'ME1',
    'ME2',
    'Mix',
    'ME1NonStable',
    'ME2NonStable',
    'MixNonStable',
  ]
  timelineChart.on(
    'updateAxisPointer',
    debounce((event: any) => {
      scatterChart.dispatchAction({
        type: 'downplay',
        seriesName: dataSeries,
      })
      if (event.axesInfo.length === 1) {
        scatterChart.dispatchAction(
          {
            type: 'highlight',
            seriesName: dataSeries,
            dataIndex: event.dataIndex,
          },
          true,
        )
        scatterChart.dispatchAction(
          {
            type: 'showTip',
            seriesIndex: 0,
            dataIndex: event.dataIndex,
          },
          true,
        )
      }
    }, 100),
  )

  scatterChart.on('mouseover', (event: any) => {
    const eventObj = {
      axesInfo: [
        {
          axisDim: 'x',
          axisIndex: 0,
          value: timestamps[event.dataIndex],
        },
      ],
      dataIndex: event.dataIndex,
      dataIndexInside: event.dataIndex,
      seriesIndex: 0,
      type: 'updateaxispointer',
    }
    const action = timelineChart.makeActionFromEvent(eventObj)
    timelineChart.dispatchAction(
      { ...action, tooltipOption: { show: true } },
      true,
    )
  })
}

/*
  Something like this might be needed in near future, highlights scatter chart datapoints inside zoom of timeline chart
*/
// export function connectZoomAndHighlight(
//   timelineChart: Chart,
//   scatterChart: Chart
// ) {
//   timelineChart.on("dataZoom", (params: any) => {
//     if (params.batch) {
//       scatterChart.dispatchAction(
//         {
//           type: "highlight",
//           seriesName: ["AE1", "AE2", "AE3", "AE4", "AE5", "Mix"],
//           dataIndex: [0, 1, 2, 3, 4, 5, 4000, 4001, 4002, 40003, 4004],
//           tooltipOption: { show: false }
//         },
//         true
//       );
//     } else {
//       scatterChart.dispatchAction({
//         type: "downplay",
//         seriesName: ["AE1", "AE2", "AE3", "AE4", "AE5", "Mix"]
//       });
//     }
//   });
// }
