import * as React from 'react'
import styled, {
  grey,
  maerskBlue,
  maritimeBlue,
  seaGreen,
  yellow,
  red,
  green,
  purple,
  gold,
  orange,
} from '../../../theme'
import { PieChart, Pie, Cell, ResponsiveContainer, Sector } from 'recharts'
import { withFeatureToggling } from '../../../higher-order-components'
import { sizes } from '../../../theme'
import { Performance } from '../../../api-models'
import { stripPort, isNumber } from '../../../utils'
import {
  ELECTRICAL_CONSUMERS,
  ELECTRICAL_CONSUMER_NAMES,
} from '../../../utils/constants'

const ELECTRICAL_CONSUMER_COLOR_MAP = {
  [-1]: grey[200],
  [ELECTRICAL_CONSUMERS.Basic]: maerskBlue[500],
  [ELECTRICAL_CONSUMERS.AcUnit]: yellow[500],
  [ELECTRICAL_CONSUMERS.ErBlowers]: grey[500],
  [ELECTRICAL_CONSUMERS.ReeferCfw]: seaGreen[500],
  [ELECTRICAL_CONSUMERS.Reefers]: maritimeBlue[500],
  [ELECTRICAL_CONSUMERS.SeaWaterPump]: red[500],
  [ELECTRICAL_CONSUMERS.CargoHoldFans]: green[500],
  [ELECTRICAL_CONSUMERS.ShaftMotor1]: purple[500],
  [ELECTRICAL_CONSUMERS.ShaftMotor2]: gold[500],
  [ELECTRICAL_CONSUMERS.AuxBlowers1]: orange[500],
  [ELECTRICAL_CONSUMERS.AuxBlowers2]: '#ff00ff',
}

export const ELECTRICAL_CONSUMER_TOOLTIP_TEXT = {
  [ELECTRICAL_CONSUMERS.Basic]:
    'Total electrical production - consumption of all available consumers with signal',
  [ELECTRICAL_CONSUMERS.AcUnit]: 'A/C Unit',
  [ELECTRICAL_CONSUMERS.ErBlowers]: 'ER Blowers',
  [ELECTRICAL_CONSUMERS.ReeferCfw]: 'Reefer CFW',
  [ELECTRICAL_CONSUMERS.Reefers]: 'Reefers',
  [ELECTRICAL_CONSUMERS.SeaWaterPump]: 'Sea water pump',
  [ELECTRICAL_CONSUMERS.CargoHoldFans]: 'Cargo Hold Fans',
  [ELECTRICAL_CONSUMERS.ShaftMotor1]: 'Shaft motor port',
  [ELECTRICAL_CONSUMERS.ShaftMotor2]: 'Shaft motor stbd',
  [ELECTRICAL_CONSUMERS.AuxBlowers1]: 'Aux Blowers port',
  [ELECTRICAL_CONSUMERS.AuxBlowers2]: 'Aux Blowers stbd',
}

export interface ElectricalConsumptionPieChartProps {
  data: Performance.Status.Status
  hasTwoMainEngines: boolean
}

interface ElectricalConsumptionPieChartState {
  hoverIndex: number
}

const ElectricalConsumptionPieChartWrapper = styled.div`
  padding: 16px 16px 16px 6px;
  display: flex;
  justify-content: space-between;
  height: ${sizes.quarter}px;
  .legends {
    max-width: 50%;
    display: flex;
    align-self: center;
    .legend {
      max-width: 100%;
      border-spacing: 4px 0;

      .inactive {
        opacity: 0.2;
      }

      .badge {
        width: 12px;
        height: 12px;
        border-radius: 50%;
      }

      .label {
        font-size: 12px;
        color: ${grey[700]};
        padding-top: 4px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-width: 80px;
      }

      p {
        max-width: 80px;
        width: 80px;
        margin-top: -4px;
      }
    }
  }
`

class ElectricalConsumptionPieChart extends React.Component<
  ElectricalConsumptionPieChartProps,
  ElectricalConsumptionPieChartState
> {
  constructor(props: ElectricalConsumptionPieChartProps) {
    super(props)

    this.state = {
      hoverIndex: -1,
    }
  }

  renderLegend: (
    consumer: Performance.Status.ElectricalConsumer[],
    activeType: number,
  ) => JSX.Element = (consumer, activeType) => {
    return (
      <table className='legend'>
        <tbody>
          {consumer.map((entry, index) => {
            const name = this.props.hasTwoMainEngines
              ? ELECTRICAL_CONSUMER_NAMES[entry.type]
              : stripPort(ELECTRICAL_CONSUMER_NAMES[entry.type])
            const tooltip = this.props.hasTwoMainEngines
              ? ELECTRICAL_CONSUMER_TOOLTIP_TEXT[entry.type]
              : stripPort(ELECTRICAL_CONSUMER_TOOLTIP_TEXT[entry.type])
            const state =
              activeType !== -1 && activeType !== entry.type ? 'inactive' : ''
            return (
              <React.Fragment key={entry.type}>
                <tr title={tooltip} className={state}>
                  <td rowSpan={2}>
                    <div
                      className={'badge'}
                      style={{
                        backgroundColor:
                          ELECTRICAL_CONSUMER_COLOR_MAP[entry.type],
                      }}
                    />
                  </td>
                  <td className='label'>{name}</td>
                </tr>
                <tr className={state} title={tooltip}>
                  <td>
                    <p>{`${entry.power} kW`}</p>
                  </td>
                </tr>
              </React.Fragment>
            )
          })}
        </tbody>
      </table>
    )
  }

  renderActiveShape = (props: any) => {
    const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill } =
      props
    return (
      <g>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 4}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
      </g>
    )
  }

  public render() {
    const { electricalConsumption } = this.props.data
    const { hoverIndex } = this.state
    let chartData = electricalConsumption
      ? electricalConsumption.consumers
      : [
          {
            percentage: 100,
            power: 0,
            type: -1,
          } as Performance.Status.ElectricalConsumer,
        ]
    const total = electricalConsumption ? electricalConsumption.totalPower : '-'
    const width = sizes.quarter
    const hoverEntryType = hoverIndex !== -1 ? chartData[hoverIndex].type : -1
    return (
      <ElectricalConsumptionPieChartWrapper>
        <ResponsiveContainer width={'50%'} height='100%'>
          <PieChart height={300}>
            <Pie
              data={chartData}
              dataKey='percentage'
              cx='50%'
              cy='50%'
              innerRadius={50}
              startAngle={0}
              endAngle={360}
              outerRadius={width / 3}
              paddingAngle={0}
              activeIndex={isNumber(hoverIndex) ? hoverIndex : -1}
              activeShape={this.renderActiveShape}
              onMouseEnter={(data, index) =>
                this.setState({ hoverIndex: index })
              }
              onMouseLeave={() => this.setState({ hoverIndex: -1 })}
            >
              {chartData.map((entry) => (
                <Cell
                  key={entry.type}
                  opacity={
                    hoverEntryType !== -1 && hoverEntryType !== entry.type
                      ? 0.2
                      : 1
                  }
                  fill={ELECTRICAL_CONSUMER_COLOR_MAP[entry.type]}
                />
              ))}
            </Pie>
            <g>
              <text
                x='50%'
                y='44%'
                textAnchor='middle'
                fontSize='12'
                fill={grey[700]}
                dominantBaseline='baseline'
              >
                Total
              </text>
              <text
                x='50%'
                y='50%'
                textAnchor='middle'
                fill={grey[900]}
                fontSize='22px'
                dominantBaseline='middle'
              >
                {total}
              </text>
              <text
                x='50%'
                y='53%'
                textAnchor='middle'
                fontSize='14'
                fill={grey[700]}
                dominantBaseline='hanging'
              >
                kW
              </text>
            </g>
          </PieChart>
        </ResponsiveContainer>
        <div className='legends'>
          {this.renderLegend(
            chartData.filter((entry, i) => i % 2 === 0 && entry.type !== -1),
            hoverEntryType,
          )}
          {this.renderLegend(
            chartData.filter((entry, i) => i % 2 !== 0 && entry.type !== -1),
            hoverEntryType,
          )}
        </div>
      </ElectricalConsumptionPieChartWrapper>
    )
  }
}

export default withFeatureToggling(ElectricalConsumptionPieChart)
