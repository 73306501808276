import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  width: calc(100% + 32px);
  margin: 8px -16px 40px;
`

export const ChartWrapper = styled.div`
  position: relative;
  flex-grow: 0;
  max-width: 50%;
  flex-basis: 50%;
  margin: 32px 0 24px;
  height: 350px;
  width: 50%;
`
