import { useContext, useEffect, useMemo, useRef } from 'react'
import styled from 'styled-components'
import type { EChartsType } from 'echarts/types/dist/shared'
import type { IDateRangeValue } from '@maersk-global/mds-components-core/mc-date-range/types'

import { initChart, updateChart } from './EquipmentWasteChart.utils'
import { ChartContainer } from '../EquipmentWaste.styles'
import { useGetWasteData } from '../../../../queries/BalrogApi/BalrogApi'
import { VesselPageContext } from '../../../../contexts'

const CHART_ID = 'svc-equipment-fuel-waste-chart'

type Props = {
  dateRange?: IDateRangeValue
  sisterVesselImoList: string
}

const EquipmentWasteChart = ({ dateRange, sisterVesselImoList }: Props) => {
  const imoNo = useContext(VesselPageContext).imoNo!
  const chartRef = useRef<EChartsType>()

  const getWasteData = useGetWasteData(sisterVesselImoList, dateRange)

  const chartHeight = useMemo(() => {
    if (!getWasteData.isSuccess) {
      return 440
    }
    const imoNos = new Set(getWasteData.data.map((metric) => metric.imo))
    return 120 + 40 * imoNos.size
  }, [getWasteData.isSuccess, imoNo])

  useEffect(() => {
    // IMO number changed, reset the chart
    chartRef.current?.clear()
    chartRef.current = initChart(CHART_ID)
  }, [imoNo])

  useEffect(() => {
    if (!getWasteData.isSuccess || !chartRef.current) return

    updateChart(getWasteData.data, chartRef.current)
  }, [getWasteData.isSuccess, dateRange, imoNo])

  return <Chart height={chartHeight} id={CHART_ID} />
}

export default EquipmentWasteChart

const Chart = styled(ChartContainer)<{ height: number }>`
  height: ${({ height }) => height}px;
`
