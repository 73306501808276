import ReactDOM from 'react-dom/client'
import { v1 as uuidv1 } from 'uuid'
import { McNotification, McToast } from '@maersk-global/mds-react-wrapper'

import { ErrorModal } from '../commons'
import ConfirmModal from '../components/ConfirmModal/ConfirmModal'
import TemSubmitModal from '../components/TemNotifications/TemSubmitModal/TemSubmitModal'

interface ErrorMessage {
  statusText?: string
  message?: string
}

export const displayErrorModal = (error: ErrorMessage): Promise<any> => {
  return new Promise<void>((resolve) => {
    const modalContainer = document.createElement('div')
    modalContainer.setAttribute('id', uuidv1())

    const root = ReactDOM.createRoot(document.body.appendChild(modalContainer))

    const element = (
      <ErrorModal
        error={error}
        closeHandler={() => {
          root.unmount()
          document.body.removeChild(modalContainer)
          resolve()
        }}
      />
    )

    root.render(element)
  })
}

export const displayConfirmModal = (
  content?: {
    title?: string
    message?: string | JSX.Element
  },
  confirmButtonText?: string,
  cancelButtonText?: string,
  hideCancelButton?: boolean,
): Promise<any> => {
  return new Promise((resolve, reject) => {
    const modalContainer = document.createElement('div')
    modalContainer.setAttribute('id', uuidv1())

    const root = ReactDOM.createRoot(document.body.appendChild(modalContainer))

    const element = (
      <ConfirmModal
        content={content}
        confirmHandler={() => {
          root.unmount()
          resolve('confirm')
        }}
        cancelHandler={() => {
          root.unmount()
          reject('cancel')
        }}
        cancelButtonText={cancelButtonText}
        confirmButtonText={confirmButtonText}
        hideCancelButton={hideCancelButton}
      />
    )

    root.render(element)
  })
}

export const displayToast = (
  appearance: 'success' | 'error' | 'warning' | 'info',
  title: string,
  body: string,
  duration?: number,
): Promise<void> => {
  return new Promise((resolve, _) => {
    const container = document.createElement('div')
    container.setAttribute('id', uuidv1())

    const root = ReactDOM.createRoot(document.body.appendChild(container))

    const element = (
      <McToast
        appearance={appearance}
        open
        position='bottom-right'
        duration={duration ?? 5000}
        close={() => {
          root.unmount()
          container.remove()
          resolve()
        }}
      >
        <McNotification heading={title} body={body} />
      </McToast>
    )

    root.render(element)
  })
}

export const displayTemSubmitModal = (
  notification: NotificationsAPI.TemAdvice,
): Promise<TemSubmitModalTypes.SubmitModalInputs | null> => {
  return new Promise((resolve) => {
    let modalContainer = document.createElement('div')
    modalContainer.setAttribute('id', notification.id)

    const root = ReactDOM.createRoot(document.body.appendChild(modalContainer))
    const closeHandler = (
      action: string,
      data: TemSubmitModalTypes.SubmitModalInputs | null,
    ) => {
      root.unmount()
      if (action === 'confirm') {
        resolve(data)
      } else {
        resolve(null)
      }
    }
    const element = (
      <TemSubmitModal
        notification={notification}
        submitHandler={(data: TemSubmitModalTypes.SubmitModalInputs | null) =>
          closeHandler('confirm', data)
        }
        closeHandler={() => closeHandler('cancel', null)}
      />
    )

    root.render(element)
  })
}
