import styled, { grey, maerskBlue } from '../../../theme'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  .label {
    color: ${grey[500]};

    &.active {
      color: #000;
      border-bottom: 2px solid ${maerskBlue[500]};
      margin-bottom: -2px;
    }
  }

  .toggle {
    margin: 0 4px;
    width: 32px;
    height: 16px;
    border: 1px solid ${grey[200]};
    border-radius: 8px;
    position: relative;

    .bullet {
      height: 16px;
      width: 16px;
      border-radius: 8px;
      background-color: ${maerskBlue[500]};
      position: absolute;

      &.left {
        left: 0;
      }

      &.right {
        right: 0;
      }
    }
  }

  .toggle-label {
    color: #000;
    margin-right: 8px;
  }
`

type Props = {
  id: string
  toggleLabel?: string
  onChange: (value: string | number) => void
  options: [
    { label: string; value: string | number },
    { label: string; value: string | number },
  ]
  value: string
}

export const Toggle = ({
  id,
  onChange,
  options,
  value,
  toggleLabel,
}: Props) => {
  return (
    <Wrapper>
      {!!toggleLabel && <span className='toggle-label'>{toggleLabel}</span>}
      <label
        htmlFor={id}
        className={`label ${value === options[0].value ? 'active' : ''}`}
        onClick={() => {
          onChange(options[0].value)
        }}
      >
        {options[0].label}
      </label>
      <div
        role='listitem'
        id={id}
        className='toggle'
        onClick={() => {
          onChange(
            value === options[0].value ? options[1].value : options[0].value,
          )
        }}
      >
        <div
          className={`bullet ${value === options[0].value ? 'left' : 'right'}`}
        />
      </div>
      <label
        htmlFor={id}
        className={`label ${value === options[1].value ? 'active' : ''}`}
        onClick={() => {
          onChange(options[1].value)
        }}
      >
        {options[1].label}
      </label>
    </Wrapper>
  )
}
