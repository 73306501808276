import { useContext, useEffect, useState } from 'react'
import moment from 'moment'
import { type Table } from '@tanstack/react-table'
import { McInputDate } from '@maersk-global/mds-react-wrapper'

import { WindowContext } from '../../../../contexts'
import { getInitialFilterDates } from './helpers'
import { Wrapper } from './styles'

type Props = {
  table: Table<any>
  columnId: 'timestamp' | 'created_at' | 'createdAt' | 'startTimestamp'
}

const DateFilter = ({ table, columnId }: Props) => {
  const { windowSize } = useContext(WindowContext)
  const [dates, setDates] = useState(() =>
    getInitialFilterDates(table, columnId),
  )

  useEffect(() => {
    const column = table.getColumn(columnId)

    column!.setFilterValue({
      from: moment.utc(dates.from).startOf('day').valueOf(),
      to: moment.utc(dates.to).endOf('day').valueOf(),
    })
  }, [dates, table, columnId])

  const handleDateChange = ({ target }: Event) => {
    const { name, value } = target as HTMLInputElement
    setDates({ ...dates, [name]: value })
  }

  const _max = moment
    .max(moment.utc(), moment.utc(dates.to))
    .format('YYYY-MM-DD')

  return (
    <Wrapper>
      <McInputDate
        fit={windowSize}
        name='from'
        label='Date from'
        value={dates.from}
        max={_max}
        input={handleDateChange}
        data-e2e='dateFrom'
      />
      <McInputDate
        fit={windowSize}
        name='to'
        label='Date to'
        max={_max}
        value={dates.to}
        input={handleDateChange}
        data-e2e='dateTo'
      />
    </Wrapper>
  )
}

export default DateFilter
