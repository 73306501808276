import * as React from 'react'
import styled, { css, keyframes } from 'styled-components'
import { grey, lg, md, media, sm } from '../../../theme'
import FilterPanelHeader from './FilterPanelHeader'
import FilterPanelFooter from './FilterPanelFooter'
import DateRangeFilterPane from './DateRangeFilterPane'
import FilterPane from './FilterPane'
import NumberOfDigitsFilter from './NumberOfDigitsFilter'
import { clearQueryParams, setFilterQueryParams } from '../utils'
import { RouteComponentProps, withRouter } from 'react-router'
import { FilterConfig, Filters } from '..'
import moment from 'moment'
import { routerParams } from '../../../routes'
import { getClosestTenMinute } from '../../../utils'

const animationKeyframes = keyframes`
  0% {
    opacity: 0;
  }
`

const animation = () => css`
  ${animationKeyframes} ease-in 0.2s backwards
`

const Panel = styled.div`
  background-color: #fff;
  position: fixed;
  z-index: 2;
  bottom: 0;
  right: 0;
  box-shadow: -2px 2px 2px 0 rgba(28, 37, 42, 0.16);
  color: ${grey[700]};

  ${media.sm`top: ${
    sm.BIG_BAR_HEIGHT
  };min-height: ${`calc(100% - ${sm.BIG_BAR_HEIGHT})`};`};
  ${media.md`top: ${
    md.BIG_BAR_HEIGHT
  };min-height: ${`calc(100% - ${md.BIG_BAR_HEIGHT})`};`};
  ${media.lg`top: ${
    lg.BIG_BAR_HEIGHT
  };min-height: ${`calc(100% - ${lg.BIG_BAR_HEIGHT})`};`};

  &.expanded {
    width: 344px;
    transition-duration: 0.3s;
  }

  &.collapsed {
    transition-duration: 0.3s;
    overflow: hidden;
    cursor: pointer;
    ${media.sm`width: ${sm.FILTER_PANEL_WIDTH_COLLAPSED};`};
    ${media.md`width: ${md.FILTER_PANEL_WIDTH_COLLAPSED};`};
    ${media.lg`width: ${lg.FILTER_PANEL_WIDTH_COLLAPSED};`};
  }

  .filter-panes {
    * {
      animation: ${animation};
    }
  }
`

const STATES: { [key: string]: FilterPanelState } = {
  noFilters: {
    filtersActive: false,
    dateRange: undefined,
    numberOfDigits: undefined,
    filterString: undefined,
    expanded: false,
    chartSmoothing: false,
  },
}

export interface FilterPanelProps extends RouteComponentProps<routerParams> {
  onChange?: (filters: Filters) => void
  onReset?: () => void
  config?: FilterConfig
  value?: Filters
}

export interface FilterPanelState extends Filters {
  expanded: boolean
}

const MAX_RANGE_IN_DAYS = 60

class FilterPanel extends React.Component<FilterPanelProps, FilterPanelState> {
  constructor(props: FilterPanelProps) {
    super(props)

    this.state = {
      expanded: false,
    }
  }

  togglePanel = () => {
    this.setState({ expanded: !this.state.expanded })
  }

  onApply = () => {
    const { onChange } = this.props
    const { expanded, ...filters } = this.state
    const newFilters = this.formatDateRangeFilter({
      ...filters,
      filtersActive: true,
    })
    const url = setFilterQueryParams(newFilters)
    this.props.history.replace(url)
    this.togglePanel()
    if (onChange) onChange({ ...newFilters, filterString: url })
  }

  onClear = () => {
    const { onReset } = this.props
    const url = clearQueryParams()
    this.props.history.replace(url)
    this.setState(STATES.noFilters)
    if (onReset) onReset()
  }

  formatDateRangeFilter(filters: Filters) {
    if (!filters.dateRange) return filters
    const { from, to } = filters.dateRange
    // Using JSON parse and stringify to get rid of nested references in object
    const formattedDateRangeFilter = JSON.parse(JSON.stringify(filters))
    const onlyOneDateIsSelected = [from, to].filter(Boolean).length === 1
    if (onlyOneDateIsSelected) {
      const startDate = from || to
      formattedDateRangeFilter.dateRange.from = startDate
      const isCounterPage = window.location.pathname.includes('/counter')
      const maxRangeFromSelectedDate = startDate!
        .clone()
        .add(MAX_RANGE_IN_DAYS, 'days')
      // counter uses minute precision, all other pages use 10 minute precision
      const maxRangeFromSelectedDateWithPrecision = isCounterPage
        ? maxRangeFromSelectedDate?.startOf('minute')
        : getClosestTenMinute(maxRangeFromSelectedDate)
      const latestTime = isCounterPage
        ? moment.utc().startOf('minute')
        : getClosestTenMinute(moment.utc())
      const latestTimeIsBeforeMaxDateRange = latestTime.isBefore(
        maxRangeFromSelectedDateWithPrecision,
      )
      formattedDateRangeFilter.dateRange.to = latestTimeIsBeforeMaxDateRange
        ? latestTime
        : maxRangeFromSelectedDateWithPrecision
    } else if (from && to) {
      const toIsBeforeFrom = to.isBefore(from)
      if (toIsBeforeFrom) {
        formattedDateRangeFilter.dateRange.to = from
        formattedDateRangeFilter.dateRange.from = to
      } else {
        formattedDateRangeFilter.dateRange.to = to
        formattedDateRangeFilter.dateRange.from = from
      }
    }
    return formattedDateRangeFilter
  }

  componentDidMount() {
    const { onChange, onReset, value, config } = this.props
    if (!onChange || !onReset || !value || !config) {
      console.warn(
        'One or more of the following parameters is unset, this may cause unexpected results',
        { value, onChange, onReset, config },
      )
    } else {
      this.setState({ ...value })
    }
  }

  componentDidUpdate(prevProps: FilterPanelProps) {
    if (prevProps.value && !this.props.value) {
      this.setState(STATES.noFilters)
    }
  }

  render() {
    const { expanded, dateRange, numberOfDigits } = this.state
    const { config } = this.props
    if (config) {
      const showOther = config.numberOfDigits.show || config.chartSmoothing.show
      return (
        <Panel
          className={expanded ? 'expanded' : 'collapsed'}
          role='toolbar'
          onClick={() => {
            if (!expanded) this.togglePanel()
          }}
        >
          <FilterPanelHeader onClick={this.togglePanel} expanded={expanded} />
          {expanded && <hr />}
          {expanded && (
            <div className='filter-panes'>
              <DateRangeFilterPane
                value={dateRange}
                config={config.dateRange}
                onChange={(value) => {
                  this.setState({ dateRange: value })
                }}
              />
              {showOther && (
                <FilterPane title='Other'>
                  <NumberOfDigitsFilter
                    config={config.numberOfDigits}
                    value={numberOfDigits}
                    onChange={(value) => {
                      this.setState({ numberOfDigits: value })
                    }}
                  />
                </FilterPane>
              )}
            </div>
          )}
          <FilterPanelFooter
            expanded={expanded}
            onSubmit={this.onApply}
            onReset={this.onClear}
            onToggle={this.togglePanel}
            disabled={!dateRange && !numberOfDigits}
          />
        </Panel>
      )
    } else {
      return null
    }
  }
}

export default withRouter(FilterPanel)
