import { useContext } from 'react'
import { StockEntryContext } from '../../../../contexts'
import { UNITS } from '../../../../utils/constants'
import { formatValue, FuelType } from '../../../../utils'
import BioFuelIcon from '../../../../assets/images/bio.svg'
import styled, {
  FuelColors,
  greenColorScheme,
  greyColorScheme,
  type IColorScheme,
  redColorScheme,
} from '../../../../theme'
import { Performance } from '../../../../api-models'
import { Icon } from '../../../../commons'
import { McButton } from '@maersk-global/mds-react-wrapper/components-core/mc-button'

const BatchesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 25%;
  align-items: center;
`

const Batch = styled.div<{
  colorScheme: IColorScheme
  error?: boolean
}>`
  width: 100%;
  box-sizing: border-box;
  background-color: ${({ error }) => (error ? redColorScheme.fill : '#FFFFFF')};
  border: 1px solid
    ${({ colorScheme, error }) =>
      error ? redColorScheme.stroke : colorScheme.stroke};
  border-radius: 3px;
  padding: 14px;
  margin-bottom: 16px;
`

const Paragraph = styled.div<{
  colorScheme?: IColorScheme
  error?: boolean
  weight?: 'normal' | 'bold' | 'light'
  casing?: 'uppercase' | 'lowercase' | 'capitalized'
}>`
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 3px;
  text-transform: ${({ casing }) => (casing ? casing : 'normal')};
  font-weight: ${({ weight }) => (weight ? weight : 'normal')};
  color: ${({ colorScheme, error }) => {
    if (error) {
      return redColorScheme.font
    }

    if (colorScheme) {
      return colorScheme.stroke
    }

    return '#000000'
  }};

  small {
    font-size: 12px;
  }
`

const Metric = styled.div<{ small?: boolean }>`
  font-size: ${({ small }) => (small ? '14px' : '18px')};
  line-height: ${({ small }) => (small ? '17px' : '22px')};
  margin-bottom: 15px;
  text-transform: uppercase;
`

const BioIcon = styled.img`
  height: 20px;
  width: 20px;
  vertical-align: middle;
  float: right;
`

type Props = {
  fuelType: FuelType
  fuelTypeBatches: Performance.FuelOilStock.BatchQuantity[]
}

export const FuelTypeQuantity = ({ fuelType, fuelTypeBatches }: Props) => {
  const { setCurrentEntry } = useContext(StockEntryContext)
  const fuelColorScheme = FuelColors[FuelType[fuelType]]

  return (
    <BatchesWrapper>
      {fuelTypeBatches.map(({ batch, quantity }, index) => (
        <Batch
          key={`fuel-type-batch-${index}`}
          colorScheme={fuelColorScheme}
          error={!batch.hasLabReport}
          data-e2e={batch.displayName}
        >
          {!batch.hasLabReport && (
            <div style={{ marginBottom: '15px' }}>
              <Icon
                icon='fal fa-exclamation-circle'
                style={{
                  color: redColorScheme.font,
                  display: 'inline-block',
                  marginRight: '8px',
                }}
              />
              <Paragraph
                colorScheme={greenColorScheme}
                style={{ display: 'inline-block' }}
                weight='bold'
                casing='uppercase'
                error
              >
                Lab report missing
              </Paragraph>
            </div>
          )}
          {batch.isSelected && (
            <div style={{ marginBottom: '15px' }}>
              <Icon
                icon='fal fa-check-circle'
                style={{
                  color: greenColorScheme.stroke,
                  display: 'inline-block',
                  marginRight: '8px',
                }}
              />
              <Paragraph
                colorScheme={greenColorScheme}
                style={{ display: 'inline-block' }}
              >
                Selected
              </Paragraph>
            </div>
          )}
          <Paragraph
            colorScheme={fuelColorScheme}
            style={{ marginBottom: '15px' }}
            error={!batch.hasLabReport}
          >
            {batch.displayName}
          </Paragraph>
          <Paragraph colorScheme={greyColorScheme}>
            <small>ROB</small>
          </Paragraph>
          <Metric small>
            {formatValue(quantity, 3)} {UNITS.METRIC_TON}
          </Metric>
          <McButton
            label='See details'
            appearance='neutral'
            padding='none'
            style={{ textDecoration: 'underline' }}
            variant='plain'
            click={(e) => setCurrentEntry(batch.type, batch.id)}
            data-e2e='edit-batch'
          />
          {batch.fuel.bioPercentage > 0 && (
            <BioIcon
              src={BioFuelIcon}
              alt='Bio fuel icon'
              data-e2e='bioFuelIcon'
            />
          )}
        </Batch>
      ))}
    </BatchesWrapper>
  )
}
