import styled from 'styled-components'
import { McNotification } from '@maersk-global/mds-react-wrapper'

export const StyledMcNotification = styled(McNotification)`
  display: block;
  margin-bottom: 12px;

  a,
  a:visited {
    color: var(--mds_core_notification_warning_on-background-color);
  }

  .action {
    cursor: pointer;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
`

export const Wrapper = styled.div`
  margin-bottom: 28px;
  padding: 0 8px 0;
`

export const LoadingDots = styled.span`
  display: inline-block;
  margin-left: 2px;
  width: 16px;
  aspect-ratio: 6;
  background: radial-gradient(circle closest-side, rgb(77, 62, 12) 90%, #0000) 0 /
    calc(100% / 3) 100% space;
  clip-path: inset(0 100% 0 0);
  animation: l1 1s steps(4) infinite;
  @keyframes l1 {
    to {
      clip-path: inset(0 -34% 0 0);
    }
  }
`
