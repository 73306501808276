import { type FieldProps } from 'formik'

import { isNumber } from '../utils'
import { InputFieldWithUnit } from './InputFieldWithUnit'

interface Props extends FieldProps {
  disabled?: boolean
  onChange?: (value) => void
  placeholder?: string
  type: string
  unit?: string
  unitClassName?: string
  width?: string
  formatInputValue?: (value: string) => string | number
}

export const FormInputWithUnit = ({
  disabled,
  field,
  form,
  formatInputValue,
  onChange,
  placeholder,
  type,
  unit,
  unitClassName,
  width,
  ...restProps
}: Props) => {
  return (
    <InputFieldWithUnit
      disabled={disabled}
      {...restProps}
      placeholder={placeholder}
      type={type}
      unit={unit}
      unitClassName={unitClassName}
      value={field.value}
      width={width}
      onBlur={() => form.setFieldTouched(field.name)}
      onChange={(event) => {
        let { value } = event.target
        if (formatInputValue) {
          value = formatInputValue(value)
        }
        if (type === 'number' && isNumber(event.target.value)) {
          value = parseFloat(value)
        }
        form.setFieldValue(field.name, value)
        if (onChange) onChange(value)
      }}
    />
  )
}
