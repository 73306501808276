import * as React from 'react'
import styled, { grey } from '../theme'

interface CustomTooltipProps {
  style?: React.CSSProperties
  children?: React.ReactNode
}

interface CustomTooltipState {}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  .tooltip-content {
    /* width: 100%; */
    background-color: ${grey[800]};
    color: ${grey[100]};
    max-width: 350px;
    /* min-width: 72px; */
  }

  /* .arrow-down {
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid ${grey[800]};
  } */
`

export class CustomTooltip extends React.Component<
  CustomTooltipProps,
  CustomTooltipState
> {
  render() {
    return (
      <Wrapper {...this.props}>
        <div className='tooltip-content'>{this.props.children}</div>
        <div className='arrow-down' />
      </Wrapper>
    )
  }
}
