import styled, { FuelColors } from '../../../../theme'
import { fuelTypeByDisplayOrder } from '../../utils'
import { Loading, NotFound } from '../../../../commons'
import { UNITS } from '../../../../utils/constants'
import { formatValue, FuelType } from '../../../../utils'
import { type StockOnBoard as IStockOnBoard, StockState } from '../../models'

const StockContainer = styled.div`
  height: 100%;
  display: flex;
  padding: 16px;

  button {
    margin-left: 10px;
    padding: 6px 8px;

    svg {
      margin-right: 8px;
    }

    a {
      color: inherit;
      text-decoration: inherit;
    }
  }
`

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;

  span,
  div {
    font-size: 14px;
  }
`

const FuelTypeContainer = styled.div<{
  stroke?: string
  fill?: string
  font?: string
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background-color: ${(props) => props.fill || '#FFFFFF'};
  border: 1px solid ${(props) => props.stroke || '#000000'};
  border-radius: 2px;
  padding: 8px;
  width: 100%;
  height: 64px;
  margin-bottom: 16px;
  box-sizing: border-box;
  &:last-child {
    margin-bottom: 0;
  }
  .fuel-type {
    color: ${(props) => props.font || '#000000'};
  }
`

const Timestamp = styled.div`
  color: ${(props) => props.theme.colors.grey[700]};
  padding-bottom: 4px;
`

type Props = {
  stockOnBoard?: IStockOnBoard | null
  stateOfStock?: StockState | null
}

export const StockOnBoardLight = ({ stockOnBoard, stateOfStock }: Props) => {
  return (
    <StockContainer className='stock-container'>
      {stockOnBoard === undefined && <Loading />}
      {stockOnBoard === null && stateOfStock !== StockState.STARTUP && (
        <NotFound text='No data available' />
      )}
      {stockOnBoard === null && stateOfStock === StockState.STARTUP && (
        <NotFound text='Stock has not been started up' />
      )}
      {stockOnBoard && (
        <Wrapper>
          {stockOnBoard.fuelTypeQuantities
            .filter(
              // TODO: This is a temporary fix to hide methanol from the UI.
              //  In the future we should have a flag in 3MDS static models specifying if the engine is a dual fuel engine.
              (fuelTypeQuantity) => fuelTypeQuantity.fuelType !== FuelType.MM,
            )
            .sort(fuelTypeByDisplayOrder)
            .map((fuelTypeQuantity, index) => {
              const fuelColorScheme =
                FuelColors[FuelType[fuelTypeQuantity.fuelType]]
              return (
                <FuelTypeContainer
                  key={`fuel-type-container-${index}`}
                  stroke={fuelColorScheme.stroke}
                  fill={fuelColorScheme.fill}
                  font={fuelColorScheme.font}
                >
                  <div className='fuel-type'>
                    {FuelType[fuelTypeQuantity.fuelType]}
                  </div>
                  <div className='fuel-quantity'>
                    {formatValue(fuelTypeQuantity.quantity, 3)}{' '}
                    {UNITS.METRIC_TON}
                  </div>
                </FuelTypeContainer>
              )
            })}
          {stockOnBoard?.timestamp && (
            <Timestamp>
              {stockOnBoard.timestamp.format('DD MMM YYYY HH:mm UTC')}
            </Timestamp>
          )}
        </Wrapper>
      )}
    </StockContainer>
  )
}
