import React from 'react'
import ReactDOM from 'react-dom/client'

import 'react-datepicker/dist/react-datepicker.css'
import './global.scss'
import './assets/icons/brands'
import './assets/icons/light'
import './assets/icons/regular'
import './assets/icons/solid'
import * as serviceWorker from './serviceWorker'
import { MsalProvider } from '@azure/msal-react'
import App from './app'
import { msalInstance } from './utils'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <App />
    </MsalProvider>
  </React.StrictMode>,
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
