import { useField } from 'formik'
import { McTextarea } from '@maersk-global/mds-react-wrapper'

import { type TWindowSize } from '../../theme_new/styled'

type Props = {
  id: string
  name: string
  label: string
  placeholder?: string
  rows?: number
  required?: boolean
  fit?: TWindowSize
  disabled?: boolean
}

/**
 * A wrapper for McTextarea that provides two-way data binding for Formik form
 * values based on the `name` prop.
 */
export const FormTextArea = (props: Props) => {
  const [field, meta, helpers] = useField(props.name)

  const handleInputChange = ({ target }: InputEvent) => {
    const value = (target as HTMLTextAreaElement).value
    void helpers.setValue(value)
  }

  const handleBlur = ({ target }: FocusEvent) => {
    const value = (target as HTMLTextAreaElement).value
    void helpers.setValue(value?.trim())
  }

  return (
    <McTextarea
      {...props}
      invalid={meta.touched && !!meta.error}
      errormessage={meta.touched ? meta.error : undefined}
      input={handleInputChange}
      blur={handleBlur}
      value={field.value}
    />
  )
}
