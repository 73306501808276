import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import moment from 'moment'

import { Performance } from '../api-models'
import { Management } from '../api-models/management'
import { Sensors } from '../api-models/sensors'
import { routerParams } from '../routes'
import { isShoreContext } from '../utils'
import {
  getConfigurations,
  getConnectedVesselsImoNo,
  getSystemMode,
  getSystemTime,
  getVesselConfiguration,
} from '../services/performance'
import { getSensorsStatus, getSensorStatuses } from '../services/sensors'

export interface AppContextInterface
  extends ShoreModeInterface,
    VesselModeInterface {
  refreshSensorsStatuses: () => void
}

interface ShoreModeInterface {
  imoNos?: string[]
  configurations?: Performance.VesselConfiguration.Configuration[]
  sensorsStatuses?: Sensors.VesselSensorsStatus[]
  installations?: Management.Vessel.SoftwareComponentsResponse[]
  selectedFavouriteList?: number
  changeSelectedFavouriteList?: (index?: number, redirectUrl?: string) => void
  selectDefaultFavouriteList?: () => void
}

interface VesselModeInterface {
  configuration?: Performance.VesselConfiguration.Configuration
  sensorsStatus?: Sensors.VesselSensorsStatus
  vesselName?: string
  imoNo?: number
}

export const AppContext = React.createContext<AppContextInterface>({
  imoNos: [],
  selectedFavouriteList: 0,
  changeSelectedFavouriteList: (index?: number, redirectUrl?: string) => {},
  selectDefaultFavouriteList: () => {},
  refreshSensorsStatuses: () => {},
})

// Provider used in Shore/Fleet mode

interface FleetContextProviderProps extends RouteComponentProps<routerParams> {
  children?: React.ReactNode
}

interface FleetContextProviderState extends ShoreModeInterface {
  hasCheckedForFavouriteLists: boolean
  fetchSensorsStatusesInterval?: any
  refreshSensorsStatuses?: () => void
}

class FleetContextProvider extends React.Component<
  FleetContextProviderProps,
  FleetContextProviderState
> {
  constructor(props: FleetContextProviderProps) {
    super(props)
    this.refreshSensorsStatuses = this.refreshSensorsStatuses.bind(this)
    this.selectDefaultFavouriteList = this.selectDefaultFavouriteList.bind(this)
    this.state = {
      imoNos: [],
      changeSelectedFavouriteList: this.changeSelectedFavouriteList,
      selectDefaultFavouriteList: this.selectDefaultFavouriteList,
      refreshSensorsStatuses: this.refreshSensorsStatuses,
      hasCheckedForFavouriteLists: false,
      fetchSensorsStatusesInterval: undefined,
    }
  }

  componentDidMount() {
    Promise.all([getConnectedVesselsImoNo(), synchronizeMomentWithPapi()]).then(
      ([imoNosResponse]) => {
        const imoNos = imoNosResponse.map((imoNo) => imoNo.toString())
        Promise.all([
          getConfigurations(imoNos),
          getSensorStatuses(imoNos),
        ]).then(([configurations, sensorsStatuses]) => {
          this.setState(
            {
              imoNos: imoNos,
              selectedFavouriteList: this.getSelectedFavouriteListFromStorage(),
              configurations:
                configurations as Performance.VesselConfiguration.Configuration[],
              sensorsStatuses: sensorsStatuses as Sensors.VesselSensorsStatus[],
            },
            this.setFetchSensorsStatusesInterval,
          )
        })
      },
    )
  }

  setFetchSensorsStatusesInterval() {
    clearInterval(this.state.fetchSensorsStatusesInterval)
    const fetchSensorsStatusesInterval = setInterval(() => {
      this.refreshSensorsStatuses()
    }, 600000)
    this.setState({
      fetchSensorsStatusesInterval: fetchSensorsStatusesInterval,
    })
  }

  getSelectedFavouriteListFromStorage(): number {
    let selectedUserListIndex = parseInt(
      window.localStorage.getItem('selectedUserListIndex') ?? '-1',
    )
    if (selectedUserListIndex === -1) {
      window.localStorage.setItem('selectedUserListIndex', '0')
      return 0
    }
    return selectedUserListIndex
  }

  changeSelectedFavouriteList = (index?: number, redirectUrl?: string) => {
    this.setState({ selectedFavouriteList: index }, () => {
      window.localStorage.setItem(
        'selectedUserListIndex',
        JSON.stringify(index),
      )
      const { history } = this.props
      history.push(redirectUrl || '/MaerskStarConnect/shore/vessel-overview')
    })
  }

  selectDefaultFavouriteList = () => {
    this.setState({ selectedFavouriteList: 0 }, () => {
      window.localStorage.setItem('selectedUserListIndex', '0')
    })
  }

  refreshSensorsStatuses() {
    const { imoNos } = this.state
    if (typeof imoNos !== 'string') return
    return getSensorStatuses(imoNos).then(
      (sensorsStatuses: Sensors.VesselSensorsStatus[]) => {
        this.setState({
          sensorsStatuses: sensorsStatuses,
        })
      },
    )
  }

  render() {
    return (
      <AppContext.Provider value={this.state as AppContextInterface}>
        {this.props.children}
      </AppContext.Provider>
    )
  }
}

// Provider used in Vessel mode

interface VesselContextProviderProps {
  children?: React.ReactNode
}

interface VesselContextProviderState extends AppContextInterface {
  imoNo?: number
  fetchSensorsStatusesInterval?: any
}

export class VesselContextProvider extends React.Component<
  VesselContextProviderProps,
  VesselContextProviderState
> {
  constructor(props: VesselContextProviderProps) {
    super(props)
    this.refreshSensorsStatuses = this.refreshSensorsStatuses.bind(this)
    this.state = {
      imoNos: [],
      refreshSensorsStatuses: this.refreshSensorsStatuses,
    }
  }

  componentDidMount() {
    getSystemMode()
      .then((json: Performance.System.Mode) => {
        const imoNo = json.vessel.imoNo
        const vesselName = json.vessel.name

        this.setState({ imoNo, vesselName })

        return Promise.all([
          getVesselConfiguration(imoNo),
          getSensorsStatus(imoNo),
          synchronizeMomentWithPapi(),
        ])
      })
      .then(([configuration, sensorStatus]) =>
        this.setState(
          {
            configuration:
              configuration as Performance.VesselConfiguration.Configuration,
            sensorsStatus: sensorStatus as Sensors.VesselSensorsStatus,
            refreshSensorsStatuses: this.state.refreshSensorsStatuses,
          },
          this.setFetchSensorsStatusesInterval,
        ),
      )
  }

  setFetchSensorsStatusesInterval() {
    clearInterval(this.state.fetchSensorsStatusesInterval)
    const fetchSensorsStatusesInterval = setInterval(() => {
      this.refreshSensorsStatuses()
    }, 600000)
    this.setState({
      fetchSensorsStatusesInterval: fetchSensorsStatusesInterval,
    })
  }

  refreshSensorsStatuses() {
    const { imoNo } = this.state
    if (typeof imoNo !== 'number') return
    return getSensorsStatus(imoNo).then(
      (sensorStatus: Sensors.VesselSensorsStatus) =>
        this.setState({
          ...this.state,
          sensorsStatus: sensorStatus,
        }),
    )
  }

  render() {
    return (
      <AppContext.Provider value={this.state}>
        {this.props.children}
      </AppContext.Provider>
    )
  }
}

const synchronizeMomentWithPapi = (): Promise<any> => {
  return new Promise<void>((resolve, reject) => {
    getSystemTime().then((json: Performance.System.Clock) => {
      let offset = new Date(json.utcNow).getTime() - Date.now()
      moment.now = function () {
        return offset + Date.now()
      }
      resolve()
    })
  })
}

export const AppContextProvider = isShoreContext()
  ? withRouter(FleetContextProvider)
  : VesselContextProvider
export const AppContextConsumer = AppContext.Consumer
