import { Vessel, Response } from './common'

export interface VesselConnectionStatus {
  vessel: Vessel
  lastLogSeconds: number
  lastLogText: string
  lastLogStatus: number
  lastContactSeconds: number
  lastContactText: string
  lastContactStatus: number
}

export interface Status extends Response {
  timestamp: string
  hasData: boolean
  fuelConsumption: FuelConsumption | null
  draught: Draught | null
  voyage: Voyage | null
  propulsion: Propulsion | null
  mainEng: MainEngineStatusTotal | null
  mainEng1: MainEngineStatus | null
  mainEng2: MainEngineStatus | null
  shaftMotor1: ShaftStatus | null
  shaftMotor2: ShaftStatus | null
  shaftGenerator1: ShaftStatus | null
  shaftGenerator2: ShaftStatus | null
  whr: WHRStatus | null
  electricalConsumption: ElectricalConsumption | null
  auxEngs: Array<AuxEngineStatus> | null
  auxEng: { mixSfoc: number | null; power: number | null }
}

export interface FuelConsumption {
  consolidated: Consolidated
  mainEngFuelLine: MainEngFuelLine | null
  auxEngFuelLines: FuelLine[] | null
  boilerFuelLines: FuelLine[] | null
}

interface Consolidated {
  mainEng: number | null
  mainEngFuelType: number | null
  auxEng: number | null
  auxEngFuelTypes: number
  boiler: number | null
  boilerFuelTypes: number
}

export interface MainEngFuelLine {
  fuelLineType: number
  fuelType: number
  consumption: number | null
  consumption1: number | null
  consumption2: number | null
}

export interface FuelLine {
  fuelType: number
  fuelLineType: number
  consumption: number
}

interface Draught {
  fore: number | null
  midPort: number | null
  midStbd: number | null
  aft: number | null
  trim: number | null
  trimDegrees: number | null
  mean: number | null
  hullDeflection: number | null
  isHogging: boolean | null
}

interface Voyage {
  sog: number
  stw: number
  waterDepth: number | null
  waterTemperature: number
  airTemperature: number
}

interface Propulsion {
  shaftPower: number
  shaftPower1: number
  shaftPower2: number
}

interface MainEngineStatusTotal {
  consumption: number
  power: number
  shaftRpm: number
  load?: number
  sfoc?: number
}

interface MainEngineStatus {
  number: number
  consumption: number
  power: number
  load: number
  shaftRpm: number
  sfoc: number
}

export interface ShaftStatus {
  number: number
  power: number
  load: number
}

export interface WHRStatus {
  power: number
  steamTurbinePower: number
  powerTurbinePower: number
  load: number
  maxPower: number
}

export interface ElectricalConsumption {
  totalPower: number | null
  consumers: ElectricalConsumer[]
}

export interface ElectricalConsumer {
  type: number
  power: number
  percentage: number
}

export interface AuxEngineStatus {
  number: number
  load: number
  power: number
  sfoc: number
}
