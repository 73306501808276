import * as React from 'react'
import * as moment from 'moment'
import { Performance } from '../../../../api-models'
import styled from '../../../../theme'
import { McButton } from '@maersk-global/mds-react-wrapper'

import { FormElements, ModalControls } from '../../../../commons'
import { STOCK_LOSS_REASON_OPTIONS } from '../../../../utils/constants'
import { isShoreContext } from '../../../../utils'

const Wrapper = styled.div`
  table {
    padding: 16px;
  }
`

const Notes = styled.div`
  display: flex;
  overflow: scroll;
  margin-right: 5px;
  min-height: 32px;
  max-height: 200px;
  max-width: 450px;
  font-size: 14px;
  white-space: pre-line;
`

interface StockLossInfoProps {
  closeHandler: () => void
  deleteHandler: () => void
  loss?: Performance.FuelOilStock.LossEntry
  batches?: Performance.FuelOilStock.BatchResponse[]
}

export class StockLossInfo extends React.Component<StockLossInfoProps> {
  constructor(props: StockLossInfoProps) {
    super(props)

    this.state = {}
  }

  public render() {
    const { closeHandler, deleteHandler, loss, batches } = this.props
    if (!loss || !batches) {
      return null
    }
    const {
      timestamp,
      batchId,
      destinationBatchId,
      quantity,
      notes,
      reasonCode,
      readonly,
    } = loss
    const lossFromFuelType = batches.find(
      ({ id }) => id === batchId,
    )?.displayName
    const lossToFuelType = destinationBatchId
      ? batches.find(({ id }) => id === destinationBatchId)?.displayName ||
        'N/A'
      : 'Waste'

    return (
      <Wrapper>
        <FormElements.ReadonlyTable>
          <tbody>
            <tr>
              <td>
                <FormElements.Label>Time of loss UTC</FormElements.Label>
              </td>
              <td>
                <FormElements.Value>
                  {timestamp
                    ? moment.utc(timestamp).format('DD MMM YYYY - HH:mm')
                    : ''}
                </FormElements.Value>
              </td>
            </tr>
            <tr>
              <td>
                <FormElements.Label>Loss from</FormElements.Label>
              </td>
              <td>
                <FormElements.Value>{lossFromFuelType}</FormElements.Value>
              </td>
            </tr>
            <tr>
              <td>
                <FormElements.Label>Loss to</FormElements.Label>
              </td>
              <td>
                <FormElements.Value>{lossToFuelType}</FormElements.Value>
              </td>
            </tr>
            <tr>
              <td>
                <FormElements.Label>Quantity</FormElements.Label>
              </td>
              <td>
                <FormElements.Value>{quantity} MT</FormElements.Value>
              </td>
            </tr>
            <tr>
              <td>
                <FormElements.Label>Reason for loss</FormElements.Label>
              </td>
              <td>
                <FormElements.Value>
                  {
                    STOCK_LOSS_REASON_OPTIONS.find(
                      (reasonOption) => reasonOption.value === reasonCode,
                    )?.label
                  }
                </FormElements.Value>
              </td>
            </tr>
            <tr>
              <td>
                <FormElements.Label>Notes</FormElements.Label>
              </td>
              <td>
                <Notes>{notes}</Notes>
              </td>
            </tr>
          </tbody>
        </FormElements.ReadonlyTable>
        <ModalControls>
          <McButton
            label='Close'
            appearance='neutral'
            click={closeHandler}
            type='button'
          />
          {!readonly && (
            <McButton
              label='Delete'
              appearance='error'
              className='left'
              disabled={isShoreContext()}
              click={deleteHandler}
              type='button'
            />
          )}
        </ModalControls>
      </Wrapper>
    )
  }
}
