import { useContext, useMemo } from 'react'
import { AppContext } from '../contexts'
import { isShoreContext } from '../utils'
import { Performance } from '../api-models'

export function useVesselConfiguration(imoNo: string) {
  const appContext = useContext(AppContext)
  return useMemo(() => {
    if (isShoreContext()) {
      return appContext.configurations
        ? appContext.configurations.find(
            (configurations: Performance.VesselConfiguration.Configuration) =>
              configurations.imoNo.toString() === imoNo,
          )
        : undefined
    }
    return appContext.configuration //vessel context
  }, [imoNo, appContext.configurations, appContext.configuration])
}
