import { useContext, useState } from 'react'
import { Field, Form, Formik } from 'formik'
import Moment from 'moment'
import * as Yup from 'yup'
import { v4 as uuidv4 } from 'uuid'
import { McButton } from '@maersk-global/mds-react-wrapper'

import {
  delManualCorrectionRepairs,
  putManualCorrectionRepairs,
} from '../../../../services/performance'
import { ManualCorrectionRepair } from '../../../../api-models/performance/manual-corrections'
import { displayErrorModal, formatValue } from '../../../../utils'
import { VesselPageContext } from '../../../../contexts'
import {
  DateTimeInput,
  FormInputWithUnit,
  Loading,
  Modal,
  TextArea,
} from '../../../../commons'
import * as S from './style'
import TotalizerModal from '../totalizer-modal'
import { amountPerHour, zeroSecondsDate } from '../../utils/fuel-consumption'

type Props = {
  visible: boolean
  handleClose: () => void
  refreshData: Function
  data: ManualCorrectionRepair
}

export const EditRepairModal = ({
  visible,
  handleClose,
  refreshData,
  data,
}: Props) => {
  const imoNo = useContext(VesselPageContext).imoNo!
  const [totalizerActive, setTotalizerActive] = useState(false)
  const initialValues = {
    startTimestamp: zeroSecondsDate(data.startTimestamp),
    endTimestamp: zeroSecondsDate(data.endTimestamp),
    fuelLineType: data.fuelLineType,
    consumedMass: data.consumedMass,
    notes: data.notes,
  }

  const handleSubmit = async (values, helpers) => {
    try {
      await putManualCorrectionRepairs(imoNo, data.id, {
        ...values,
        fuelLineType: data.fuelLineType,
      })
      refreshData()
    } catch (err) {
      void displayErrorModal({
        statusText: 'Failed to update repair',
        message: err.message,
      })
    } finally {
      helpers.setSubmitting(false)
    }
  }

  const handleDelete = async () => {
    await delManualCorrectionRepairs(imoNo, data.id)
    handleClose()
    refreshData()
  }

  // Formik validation schema
  const validationSchema = Yup.object().shape({
    startTimestamp: Yup.date().required('Required'),
    endTimestamp: Yup.date().required('Required'),
    consumedMass: Yup.string()
      .nullable()
      .required('Please provide an estimated consumed mass.'),
    notes: Yup.string().max(1000, 'Limit of 1000 characters exceeded'),
  })

  return (
    <Modal
      title={data.readonly ? 'View repair' : 'Edit repair'}
      visible={visible}
      closeHandler={handleClose}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({
          values,
          touched,
          errors,
          handleChange,
          setFieldValue,
          isSubmitting,
        }) => (
          <>
            <Form>
              <S.Container>
                {isSubmitting && (
                  <S.Loading>
                    <Loading />
                  </S.Loading>
                )}
                <S.Wrapper>
                  <S.Header>Repaired period</S.Header>
                  <Field
                    value={values.startTimestamp}
                    name='startTimestamp'
                    component={(props) => (
                      <DateTimeInput
                        id={uuidv4()}
                        {...props}
                        selected={Moment.utc(values.startTimestamp)}
                      />
                    )}
                    disabled
                  />
                  <S.Label>Data loss from</S.Label>

                  <Field
                    value={values.endTimestamp}
                    name='endTimestamp'
                    component={(props) => (
                      <DateTimeInput
                        id={uuidv4()}
                        {...props}
                        selected={Moment.utc(values.endTimestamp)}
                        onChange={(val) =>
                          setFieldValue('endTimestamp', val.toISOString())
                        }
                      />
                    )}
                    disabled={data.readonly}
                  />
                  <S.Label>Data loss to</S.Label>

                  {!data.readonly && (
                    <S.Button>
                      <McButton
                        type='button'
                        label='Totalizer at specific time'
                        variant='outlined'
                        icon='cog'
                        click={() => setTotalizerActive(true)}
                      />
                    </S.Button>
                  )}

                  <TextArea
                    name='notes'
                    value={values.notes}
                    onChange={handleChange}
                    disabled={data.readonly}
                  />
                  {touched.notes && errors.notes ? (
                    <S.ErrorLabel>{errors.notes}</S.ErrorLabel>
                  ) : (
                    <S.Label>Insert comment</S.Label>
                  )}

                  <S.Header>Consumption & Reason</S.Header>
                  <S.ConsumptionField>
                    <Field
                      value={values.consumedMass}
                      name='consumedMass'
                      component={FormInputWithUnit}
                      formatInputValue={(value) => formatValue(value, 3, '')}
                      type='number'
                      unit='MT'
                      width='220px'
                      disabled={data.readonly}
                    />
                    <S.ConsumptionPerHour>
                      {values.consumedMass > 0
                        ? `${amountPerHour(
                            values.startTimestamp,
                            values.endTimestamp,
                            values.consumedMass,
                          )} MT/h`
                        : ''}
                    </S.ConsumptionPerHour>
                  </S.ConsumptionField>
                  {touched.consumedMass && errors.consumedMass ? (
                    <S.ErrorLabel>{errors.consumedMass}</S.ErrorLabel>
                  ) : (
                    <S.Label>Insert est. consumption</S.Label>
                  )}
                </S.Wrapper>
                <S.Actions>
                  {data.readonly && (
                    <McButton
                      label='Ok'
                      appearance='primary'
                      click={handleClose}
                    />
                  )}

                  {!data.readonly && (
                    <>
                      <McButton
                        label='Save'
                        appearance='primary'
                        type='submit'
                      />
                      <McButton
                        label='Delete'
                        appearance='error'
                        type='button'
                        click={handleDelete}
                      />
                    </>
                  )}
                </S.Actions>
              </S.Container>
            </Form>

            <TotalizerModal
              handleClose={() => setTotalizerActive(false)}
              visible={totalizerActive}
              timestamp={values.endTimestamp}
            />
          </>
        )}
      </Formik>
    </Modal>
  )
}

export default EditRepairModal
