import React from 'react'
import imgAdjust from '../../../../assets/images/help-section/reporting-rd-adjust.png'
import imgRouteChange from '../../../../assets/images/help-section/reporting-rd-route-change.png'

export const RemainingDistance = React.forwardRef<HTMLDivElement>(
  (props, ref) => (
    <div ref={ref} id='help-section-subtopic'>
      <h4>In this guide you will learn</h4>
      <ul>
        <li>The difference between a route change and an adjustment</li>
        <li>How edits / updates are cascaded</li>
        <li>Limits for adjustments</li>
      </ul>
      <h4>Purpose</h4>
      <p>
        The purpose of this guide is to give you an overview of how reporting of
        remaining distance can be updated in StarConnect. The reason why we have
        to work with 2 different ways of updating data is that on one hand we
        would like to automate as much as possible to have an easy reporting
        process, but we also have to deal with the small “real world” deviations
        that sometimes occur.
      </p>
      <h4>Prerequisites</h4>
      <p>SC Reporting has been started with first Departure report</p>
      <h4>Route change and adjustment</h4>
      <p className='title'>Route change</p>
      <p>
        This should be the “happy flow” and the usual way of updating remaining
        distance. If you make changes to your voyage plan that results in a
        change of remaining distance we ask you to report a “Route change” in
        the Event tab. At some point we hope to be able source this information
        directly from Voyage planner but for now we need your input in SC
        reporting.
      </p>
      <p className='title'>Adjustment</p>
      <p>
        Small changes to the calculated remaining distance can be made directly
        in the voyage tab. This should only be done for minor adjustments e.g
        due to navigation in traffic or other small deviations from the planned
        route and distance.
      </p>
      <h4>Edit and update of remaining distance</h4>
      <p>
        When you create your Departure report you enter the remaining distance
        [NM] till Pilot station in the Voyage tab. In the following sea reports
        StarConnect calculates new remaining distance and the field is
        automatically updated.
      </p>
      <p className='note'>
        Rem. distance previous report - distance observed this report =
        Rem.distance @ closing of this report
      </p>
      <p>
        If you have a route change that impacts the distance, you should report
        this in the Evet tab and the field will automatically be updated in the
        Voyage tab.
      </p>
      <img src={imgRouteChange} alt='Route change' />
      <p>
        If you need to adjust the remaining distance with minor updates e.g due
        to traffic or other small deviations to your planned route, you can do
        that in the voyge tab.
      </p>
      <p>
        Simply tick the “Adjust” box below and the field will open for editing.
      </p>
      <img src={imgAdjust} alt='Remaining distance adjustment' />
      <p className='note'>
        Please note: If you edit already submitted reports:
      </p>
      <ul>
        <li>
          Route change event will trigger recalculation of remaining distance if
          there are reports after the one you are editing
        </li>
        <li>
          Adjustments: Will ONLY update the remaining distance in the current
          report that you are editing. If you have later reports you need to
          check and update the remaining distance there as well
        </li>
      </ul>
      <h4>Limits for adjustment</h4>
      <p>
        Limit for adjustment is +/- 10% of the calculated remaing distance for
        the report.
      </p>
    </div>
  ),
)
