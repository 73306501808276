import { useContext } from 'react'
import { useField } from 'formik'
import { McError } from '@maersk-global/mds-react-wrapper'

import { WindowContext } from '../../../contexts'

type Props = {
  name: string
}

export const Error = ({ name }: Props) => {
  const { windowSize } = useContext(WindowContext)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta, helpers] = useField(name)

  return (
    <McError
      fit={windowSize}
      invalid={Boolean(meta.error)}
      errormessage={meta.error}
    />
  )
}
