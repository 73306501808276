import { default as FilterPanel } from './components/FilterPanel'
import {
  DateRangeFilter,
  DateRangeConfig,
} from './components/DateRangeFilterPane'
import { NumberOfDigitsFilterConfig } from './components/NumberOfDigitsFilter'

export interface Filters {
  filtersActive?: boolean
  dateRange?: DateRangeFilter
  numberOfDigits?: number
  chartSmoothing?: boolean
  filterString?: string
}

export interface DefaultFilters extends Filters {
  dateRange: DateRangeFilter
}

export interface FilterConfig {
  dateRange: DateRangeConfig
  numberOfDigits: NumberOfDigitsFilterConfig
  chartSmoothing: {
    show: boolean
  }
}

export interface QueryPeriod {
  to: string
  from: string
}

export * from './utils'
export { default as getFilterNotification } from './components/FiltersNotification'

export default FilterPanel
