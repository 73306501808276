import * as React from 'react'
import styled, {
  grey,
  errorTheme,
  warningTheme,
  IThemeInterface,
  inputDefault,
} from '../theme'
import { Icon } from '../commons'

const InputFieldWithUnitWrapper = styled.div<{
  width: string | number | undefined
  height: string | number | undefined
  theme: IThemeInterface
}>`
  position: relative;
  height: ${(props) => (props.height ? props.height : '34px')};
  width: ${(props) => props.width};
  input {
    height: 100%;
    width: calc(100% - 0px);
    border-radius: 4px;
    border: 1px solid ${(props) => props.theme.border};
    box-sizing: border-box;
    padding-left: 8px;
  }

  input.number {
    text-align: right;
  }

  .input-span {
    position: absolute;
    right: 0;
    top: 0;
    border: 1px solid ${(props) => props.theme.border};
    background-color: ${(props) => props.theme.bg};
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${(props) => props.theme.fg};
    pointer-events: none;
    font-size: 12px;
  }

  /* stylelint-disable */
  input.number::-webkit-inner-spin-button,
  input.number::-webkit-outer-spin-button {
    appearance: none;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }
  /* stylelint-enable */

  input::placeholder {
    color: ${grey[400]};
  }
`

interface InputFieldWithUnitProps extends React.InputHTMLAttributes<any> {
  unit?: string
  icon?: string
  error?: boolean
  warning?: boolean
  unitClassName?: string
  dataE2E?: string
  step?: string
  min?: string
  pattern?: string
}

export class InputFieldWithUnit extends React.Component<
  InputFieldWithUnitProps,
  any
> {
  render() {
    const {
      unit,
      unitClassName,
      placeholder,
      style,
      width,
      error,
      warning,
      height,
      type,
      icon,
      pattern,
      value,
      ...restProps
    } = this.props

    const spanWidth = unit ? unit.length * 8 + 18 : 0
    let theme = inputDefault
    if (error) theme = errorTheme
    else if (warning) theme = warningTheme
    return (
      <InputFieldWithUnitWrapper width={width} height={height} theme={theme}>
        <input
          placeholder={placeholder || '0.00'}
          style={{ paddingRight: `${spanWidth + 8}px`, ...style }}
          type={type || 'text'}
          value={value ?? ''}
          {...restProps}
          step='any'
          className={`${
            unitClassName ? unitClassName + ' number-field' : 'number-field'
          } ${type === 'number' ? 'number' : ''}`}
        />
        {!!unit && (
          <span
            className={
              unitClassName ? unitClassName + ' input-span' : 'input-span'
            }
            style={{ width: `${spanWidth}px` }}
          >
            {unit}
          </span>
        )}
        {!!icon && (
          <span
            className={
              unitClassName ? unitClassName + ' input-span' : 'input-span'
            }
          >
            <Icon icon={icon} />
          </span>
        )}
      </InputFieldWithUnitWrapper>
    )
  }
}
