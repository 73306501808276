import * as React from 'react'
import styled, { grey, maerskBlue } from '../theme'
import { getXPercentageOfNumber } from '../utils'

export const BAR_HEIGHT = 88
export const BAR_DEFAULT_WIDTH = 80

const SVG = styled.svg`
  user-select: none;
`

export interface SimpleBarProps {
  id: string
  percentage: number | null
  value?: number | string
  unit?: string
  label?: {
    fill?: string
  }
  fill?: string
  stroke?: string
  barWidth?: number
  title?: string
  fontSize?: string
  unitSize?: string
  hasRefLabel?: boolean
  tooltip?: any
  height?: string
  children?: React.ReactNode
}

interface SimpleBarState {
  showTooltip?: boolean
  mouseX?: number
  mouseY?: number
}

export class SimpleBar extends React.Component<SimpleBarProps, SimpleBarState> {
  constructor(props: SimpleBarProps) {
    super(props)

    this.state = {
      showTooltip: false,
      mouseX: undefined,
      mouseY: undefined,
    }
  }

  public render() {
    const {
      percentage,
      value,
      unit,
      fill,
      stroke,
      title,
      children,
      barWidth,
      label,
      fontSize,
      unitSize,
      hasRefLabel,
      tooltip,
      height,
    } = this.props
    const { showTooltip, mouseX, mouseY } = this.state
    const validPercentage = percentage && percentage > 100 ? 100 : percentage
    const barHeight = validPercentage
      ? getXPercentageOfNumber(BAR_HEIGHT, validPercentage)
      : 0
    const barY =
      BAR_HEIGHT -
      (validPercentage
        ? getXPercentageOfNumber(BAR_HEIGHT, validPercentage)
        : 0)
    const width = barWidth ? barWidth : BAR_DEFAULT_WIDTH
    const barStart = !!children && hasRefLabel ? width : 0
    const barEnd = !!children && hasRefLabel ? width * 2 : width
    const valueTextMargin = fontSize
      ? parseInt(fontSize.slice(0, fontSize.indexOf('px'))) / 2
      : 7
    const unitTextMargin = unitSize
      ? parseInt(unitSize.slice(0, unitSize.indexOf('px'))) / 2
      : 7
    return (
      <div
        onMouseLeave={() => this.setState({ showTooltip: false })}
        onMouseMove={(event) => {
          this.setState({
            showTooltip: true,
            mouseX: event.pageX,
            mouseY: event.pageY,
          })
        }}
      >
        {tooltip && showTooltip && (
          <div
            style={{
              position: 'absolute',
              top: mouseY,
              left: mouseX,
            }}
          >
            {tooltip()}
          </div>
        )}

        <SVG
          height={height ? height : '100%'}
          width={!!children && hasRefLabel ? width * 2 : width}
        >
          <g className='bar'>
            <rect
              height={`${BAR_HEIGHT}%`}
              width={width}
              rx={8}
              ry={8}
              y={0}
              x={barStart}
              fill={grey[100]}
            />
            <rect
              height={`${barHeight}%`}
              width={width}
              rx={4}
              ry={4}
              y={`${barY}%`}
              x={barStart}
              fill={fill ? fill : maerskBlue[300]}
            />
            <line
              y1={`${barY}%`}
              x1={barStart}
              x2={barEnd}
              y2={`${barY}%`}
              stroke={stroke ? stroke : maerskBlue[500]}
              strokeWidth='3'
            />
            <text
              y={`${barY + (barHeight + valueTextMargin > 65 ? 5 : -16)}%`}
              fill={label && label.fill ? label.fill : '#000'}
              x={barStart + width / 2}
              fontSize={fontSize ? fontSize : '14px;'}
              textAnchor='middle'
              dominantBaseline={
                barHeight + valueTextMargin > 65 ? 'hanging' : 'baseline'
              }
              alignmentBaseline={
                barHeight + valueTextMargin > 65 ? 'hanging' : 'baseline'
              }
            >
              {value}
              <tspan
                x={barStart + width / 2}
                dy={barHeight + unitTextMargin > 65 ? 20 : 16}
                fontSize={unitSize ? unitSize : '14px'}
              >
                {unit}
              </tspan>
            </text>
          </g>
          <text
            y='100%'
            fill='#000'
            x={barStart + width / 2}
            textAnchor='middle'
            dominantBaseline='baseline'
            alignmentBaseline='baseline'
          >
            {title}
          </text>
          {children}
        </SVG>
      </div>
    )
  }
}
