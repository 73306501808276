import * as React from 'react'
import { InfoBox } from '../../commons'
import styled, { info } from '../../theme'

const Wrapper = styled.div`
  .info-box {
    width: fit-content;
  }
`

export class ContentUnavailable extends React.Component<any, any> {
  public render() {
    return (
      <Wrapper>
        <InfoBox hideIcons={true} theme={info} className='info-box'>
          The content of this page is not available for the selected vessel
        </InfoBox>
      </Wrapper>
    )
  }
}
