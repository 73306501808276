import { useContext, useEffect, useState } from 'react'
import { Formik } from 'formik'
import * as yup from 'yup'
import { type Moment } from 'moment'

import { Performance } from '../../../../api-models'
import { Loading, Modal } from '../../../../commons'
import { VesselPageContext } from '../../../../contexts'
import * as PerformanceApi from '../../../../services/performance'
import { displayErrorModal, isShoreContext } from '../../../../utils'
import { StockLossInfo } from './StockLossInfo'
import { LossForm } from './LossForm'
import { useBatchOptions } from '../../hooks'

export interface LossFormValues {
  timestamp?: Moment
  batchId: string
  destinationBatchId: string
  quantity: string
  reasonCode: string
  notes: string
}

let defaultInitialValues: LossFormValues = {
  batchId: '',
  destinationBatchId: '',
  quantity: '',
  reasonCode: '',
  notes: '',
}

const lossValidationSchema = yup.object().shape({
  batchId: yup.string().required('Loss from is a required field'),
  quantity: yup
    .number()
    .required('Quantity is a required field')
    .moreThan(0, 'Quantity must be higher than 0'),
  notes: yup
    .string()
    .notRequired()
    .max(1000, 'Notes cannot be more than 1000 characters long'),
  reasonCode: yup.string().required('Reason for loss is a required field'),
  timestamp: yup.date().required('Time of loss is a required field'),
  destinationBatchId: yup
    .string()
    .nullable()
    .required('Loss to is a required field'),
})

type Props = {
  entryId?: string
  closeHandler: (refreshAdjustments?: boolean) => void
}

export const LossModal = ({ entryId, closeHandler }: Props) => {
  const imoNo = useContext(VesselPageContext).imoNo!
  const [loss, setLoss] = useState<Performance.FuelOilStock.LossEntry>()
  const batchOptions = useBatchOptions(imoNo)
  const [openWindow, setOpenWindow] =
    useState<Performance.FuelOilStock.OpenWindow>()
  const [batchesFromLoss, setBatchesFromLoss] =
    useState<Performance.FuelOilStock.BatchResponse[]>()

  const deleteLoss = (
    entryId: string,
    setSubmitting?: (isSubmitting: boolean) => void,
  ) => {
    if (setSubmitting) setSubmitting(true)
    PerformanceApi.deleteLoss(imoNo, entryId)
      .then(() => closeHandler(true))
      .catch((e) => {
        if (setSubmitting) setSubmitting(false)
        void displayErrorModal(e)
      })
  }

  useEffect(() => {
    if (entryId) {
      PerformanceApi.getLoss(imoNo, entryId)
        .then(setLoss)
        .catch((e) =>
          displayErrorModal({
            statusText: 'Could not get loss',
            message: e.message,
          }),
        )
    }
  }, [entryId, imoNo])

  useEffect(() => {
    if (loss) {
      const { batchId, destinationBatchId } = loss
      const batchIds = [batchId]
      if (destinationBatchId) {
        batchIds.push(destinationBatchId)
      }
      PerformanceApi.getBatchesById(imoNo, batchIds).then(setBatchesFromLoss)
    }
  }, [loss, imoNo])

  useEffect(() => {
    if (imoNo) {
      PerformanceApi.getStockOpenWindow(imoNo)
        .then(setOpenWindow)
        .catch((e) =>
          displayErrorModal({
            statusText: 'Could not get allowed time of loss interval',
            message: e.message,
          }),
        )
    }
  }, [imoNo])

  const submitLoss = (
    values: LossFormValues,
    setSubmitting: (isSubmitting: boolean) => void,
  ) => {
    setSubmitting(true)
    const {
      batchId,
      quantity,
      reasonCode,
      notes,
      timestamp,
      destinationBatchId,
    } = values
    const lossRequestBody: Performance.FuelOilStock.Loss = {
      batchId,
      quantity,
      reasonCode,
      notes,
      destinationBatchId:
        destinationBatchId === 'waste' ? null : values.destinationBatchId,
      timestamp: timestamp?.toISOString() ?? '',
    }

    PerformanceApi.postLoss(imoNo, lossRequestBody)
      .then(() => closeHandler(true))
      .catch((e) => {
        void displayErrorModal({
          statusText: 'Could not submit loss',
          message: e.message,
        })
      })
      .finally(() => setSubmitting(false))
  }
  const disabled = isShoreContext() || (!!loss && loss.readonly)
  const shouldShowCreateNewLoss = !entryId

  return (
    <Modal
      title={shouldShowCreateNewLoss ? 'Add loss' : 'Loss'}
      visible
      closeHandler={closeHandler}
      helpTextKey={shouldShowCreateNewLoss ? 'stock/loss' : ''}
    >
      {(entryId && !loss) || (loss && !batchesFromLoss) || !openWindow ? (
        <Loading />
      ) : shouldShowCreateNewLoss ? (
        <Formik
          initialValues={defaultInitialValues}
          onSubmit={(values, { setSubmitting }) =>
            submitLoss(values, setSubmitting)
          }
          validationSchema={lossValidationSchema}
        >
          <LossForm
            disabled={disabled}
            batchOptions={batchOptions}
            closeHandler={closeHandler}
            openWindow={openWindow}
          />
        </Formik>
      ) : (
        <StockLossInfo
          batches={batchesFromLoss}
          loss={loss}
          closeHandler={closeHandler}
          deleteHandler={() => {
            if (!!entryId) {
              deleteLoss(entryId, undefined)
            }
          }}
        />
      )}
    </Modal>
  )
}
