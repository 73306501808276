import * as yup from 'yup'

declare module 'yup' {
  interface ObjectSchema<T> {
    uniqueProperty(propertyName: string, message?: any): ArraySchema<T>
  }
}

yup.addMethod(yup.object, 'uniqueProperty', function (propertyName, message) {
  return this.test('unique', message, function (value) {
    if (!value || !value[propertyName]) {
      return true
    }

    if (
      this.parent
        .filter((v: any) => v !== value)
        .some(
          (v: { [x: string]: any }) => v[propertyName] === value[propertyName],
        )
    ) {
      throw this.createError({
        path: `${this.path}.${propertyName}`,
      })
    }

    return true
  })
})

export const validationSchema = yup.object().shape({
  timestamp: yup.date().required('Time of mixing is a required field'),
  mixedBatches: yup
    .array()
    .of(
      yup
        .object()
        .shape({
          id: yup.string().required('A batch must be selected'),
          quantity: yup
            .number()
            .required('Quantity must be filled out')
            .moreThan(0, 'Quantity must be more than 0')
            .test({
              name: 'max',
              test: function (value) {
                const currentRob = this.parent.currentRob
                /* allow mix batches even when rob can't be calculated */
                if (currentRob === undefined) return true
                return value > currentRob
                  ? this.createError({
                      message: `Quantity cannot be more than remaining quantity (maximum ${currentRob} MT)`,
                      path: this.path,
                    })
                  : true
              },
            }),
        })
        .uniqueProperty('id', 'The same batch can only be selected once'),
    )
    .min(2, 'Please select more than one batch'),
  notes: yup
    .string()
    .notRequired()
    .max(1000, 'Notes cannot be more than 1000 characters long'),
})

export const editMixedBatchValidationSchema = yup.object().shape({
  notes: yup
    .string()
    .notRequired()
    .max(1000, 'Notes cannot be more than 1000 characters long'),
  bioPercentage: yup
    .number()
    .notRequired()
    .max(100, 'Percentage bio must be a value between 0 and 100')
    .min(0, 'Percentage bio must be a value between 0 and 100'),
  water: yup
    .number()
    .required('Water is a required field')
    .min(0, 'Water must have a value between 0 and 10')
    .max(10, 'Water must have a value between 0 and 10'),
  ash: yup
    .number()
    .required('Ash is a required field')
    .min(0, 'Ash must have a value between 0 and 10')
    .max(10, 'Ash must have a value between 0 and 10'),
  sulphur: yup
    .number()
    .required('Sulphur is a required field')
    .min(0, 'Sulphur must have a value between 0 and 10')
    .max(10, 'Sulphur must have a value between 0 and 10'),
  lcv: yup
    .number()
    .required('LCV is a required field')
    .min(35000, 'LCV must have a value between 35000 and 45000')
    .max(45000, 'LCV must have a value between 35000 and 45000'),
  density15: yup
    .number()
    .required('Density 15 is a required field')
    .min(700, 'Density 15 must have a value between 700 and 1200')
    .max(1200, 'Density 15 must have a value between 700 and 1200'),
})
