import { getApplicationConfig } from '../utils'
import { Management } from '../api-models/management'
import { isLocalhost, isSit, isShoreContext } from './application-utils'

/**
 * This function maps the frontend version string to a release number.
 * At this time (260620) the format of the version string differs
 * depending on platform, i.e.
 *  ITMOD: 20200213.1008.master
 *  Tactical: 2.7.0+master.548df2b1
 *
 *  @param buildNumber version string according to format described above
 */
export function getAppVersion(buildNumber: string): string {
  return getApplicationConfig().APP_VERSION
}

export function hasVersion(buildNumber: string, version: string) {
  return getAppVersion(buildNumber) >= version // strings compared lexicographically
}

export function getBuildVersion() {
  return getApplicationConfig().APP_BUILD_NUMBER
}

export function getShortAppVersion() {
  const index = getApplicationConfig().APP_VERSION.indexOf('-g')
  if (index !== -1) {
    return getApplicationConfig().APP_VERSION.substring(0, index)
  }
  return getApplicationConfig().APP_VERSION
}

export function getLastMinorVersion(fullVersion: string) {
  const result = fullVersion.match(/\d+\.\d+/)
  return result ? result[0] : '1.0'
}

export function getFrontendVersion(
  imoNo: string,
  installations: Management.Vessel.SoftwareComponentsResponse[],
): string {
  if (isShoreContext()) {
    if (installations) {
      const vesselComponents = installations.find(
        (v) => v.imoNo.toString() === imoNo,
      )
      if (vesselComponents) {
        const frontend = vesselComponents.components.find(
          (v) => v.name === 'CV.Frontend',
        )
        if (frontend) {
          return getAppVersion(frontend.version)
        }
      }
    }
    return ''
  }
  return getAppVersion(getApplicationConfig().APP_VERSION)
}

export function hasFrontendVersion(
  version: string,
  installation: Management.Vessel.SoftwareComponentsResponse,
): boolean {
  /* as this is often used to hide/toggle new features it is disabled locally and in SIT */
  /* agreement with SMEs that SIT should always show latest */
  if (isSit() || isLocalhost()) return true
  if (installation) {
    const frontend = installation.components.find(
      (v) => v.name === 'CV.Frontend',
    )
    if (frontend) {
      return hasVersion(frontend.version, version)
    }
  }
  return false
}
