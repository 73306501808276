import * as React from 'react'
import styled, { grey } from '../../../theme'
import { McButton } from '@maersk-global/mds-react-wrapper'

import { commaAgnostic, formatValue } from '../../../utils'
import { lcvCalculation } from '../lcv-calculation'
import {
  FormElements,
  InputFieldWithUnit,
  Modal,
  ModalContent,
  ModalControls,
  SortableTableHeader,
} from '../../../commons'
import { UNITS } from '../../../utils/constants'

const Wrapper = styled.div`
  padding: 16px;

  .total-column {
    text-align: right;
  }
`

const Table = styled.table`
  border-collapse: collapse;

  th,
  td {
    max-width: 136px;
    width: 136px;
    border: 1px solid ${grey[200]};
    padding: 8px;
  }

  thead {
    background-color: ${grey[50]};
    box-shadow: none !important;
    border-top: 1px solid ${grey[200]};
    border-bottom: 1px solid ${grey[200]};
    font-weight: 500;
    font-size: 12px;
    color: #474e56;

    th {
      min-height: 36px;
      padding: 8px;
      border-color: ${grey[200]};
    }
  }
`

export interface CalculationLine {
  density: string
  sulphur: string
  water: string
  ash: string
  lcv: string
}

const initialCalculationLine: CalculationLine = {
  density: '',
  sulphur: '',
  water: '',
  ash: '',
  lcv: '-',
}

interface LcvCalculatorProps {
  onClose: () => void
}

export interface LcvCalculatorState {
  calculation: CalculationLine
}

export const LcvCalculator: React.FunctionComponent<LcvCalculatorProps> = ({
  onClose,
}) => {
  const [calculation, setCalculation] = React.useState<CalculationLine>(
    initialCalculationLine,
  )

  const calculateLcv = (calculation: CalculationLine) => {
    const density = parseFloat(calculation.density)
    const water = parseFloat(calculation.water)
    const sulphur = parseFloat(calculation.sulphur)
    const ash = parseFloat(calculation.ash)

    const lcv =
      !!density && !!water && !!sulphur && !!ash
        ? lcvCalculation(density, water, sulphur, ash)
        : null

    setCalculation({
      ...calculation,
      lcv: formatValue(lcv, 0),
    })
  }

  return (
    <Modal
      visible
      title='LCV Calculator'
      closeHandler={onClose}
      helpTextKey='tools/lcv-calculator'
    >
      <FormElements.Form>
        <ModalContent>
          <Wrapper>
            <Table>
              <thead>
                <tr>
                  <th>
                    <SortableTableHeader
                      headerText='Density @ 15°C Vac'
                      unit={UNITS.KILO_GRAM_PER_CUBIC_METER}
                      disableSort
                    />
                  </th>
                  <th>
                    <SortableTableHeader
                      headerText='Sulphur'
                      unit={UNITS.MASS}
                      disableSort
                    />
                  </th>
                  <th>
                    <SortableTableHeader
                      headerText='Water'
                      unit={UNITS.PERCENTAGE_VOLUME}
                      disableSort
                    />
                  </th>
                  <th>
                    <SortableTableHeader
                      headerText='Ash'
                      unit={UNITS.MASS}
                      disableSort
                    />
                  </th>
                  <th>
                    <SortableTableHeader
                      headerText='LCV'
                      unit={UNITS.KJ_PER_KILO}
                      disableSort
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr key={`calc-line-0`}>
                  <td>
                    <InputFieldWithUnit
                      type='number'
                      unit={UNITS.KILO_GRAM_PER_CUBIC_METER}
                      placeholder='0.0'
                      value={calculation.density}
                      onChange={(event) => {
                        calculation.density = commaAgnostic(event.target.value)
                        calculateLcv(calculation)
                      }}
                    />
                  </td>
                  <td>
                    <InputFieldWithUnit
                      type='number'
                      unit={UNITS.MASS}
                      placeholder='0.0'
                      value={calculation.sulphur}
                      onChange={(event) => {
                        calculation.sulphur = commaAgnostic(event.target.value)
                        calculateLcv(calculation)
                      }}
                    />
                  </td>
                  <td>
                    <InputFieldWithUnit
                      type='number'
                      unit={UNITS.PERCENTAGE_VOLUME}
                      placeholder='0.0'
                      value={calculation.water}
                      onChange={(event) => {
                        calculation.water = commaAgnostic(event.target.value)
                        calculateLcv(calculation)
                      }}
                    />
                  </td>
                  <td>
                    <InputFieldWithUnit
                      type='number'
                      unit={UNITS.MASS}
                      placeholder='0.0'
                      value={calculation.ash}
                      onChange={(event) => {
                        calculation.ash = commaAgnostic(event.target.value)
                        calculateLcv(calculation)
                      }}
                    />
                  </td>
                  <td className='lcv-column'>{calculation.lcv}</td>
                </tr>
              </tbody>
            </Table>
          </Wrapper>
        </ModalContent>
        <ModalControls>
          <McButton
            label='Close'
            appearance='primary'
            click={onClose}
            type='button'
          />
        </ModalControls>
      </FormElements.Form>
    </Modal>
  )
}
