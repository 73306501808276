import { SfocSeries } from './models'
import { getClosestTenMinute } from '../../utils'
import moment from 'moment'

export const mapShopTestModelsData = (
  data:
    | MasterDataApi.StaticModels.VesselStaticModels<MasterDataApi.StaticModels.StaticModelData>
    | undefined,
): MasterDataApi.StaticModels.VesselStaticModels<MasterDataApi.StaticModels.StaticModelData> => {
  if (!data || !data.staticModels[0].data.operatingConditions) {
    return {
      imoNo: 0,
      staticModels: [
        {
          id: '',
          type: 4,
          instanceNos: [],
          dataFormat: 0,
          data: {
            name: '',
            mcr: 0,
            tccoLimit: 0,
            operatingConditions: [
              {
                code: '',
                referenceCondition: {
                  load: [],
                },
                isoCondition: {
                  sfoc: [],
                },
              },
            ],
          },
        },
      ],
    }
  }

  return data
}

export const checkIfTccoStaticModelExists = (
  staticModels:
    | MasterDataApi.StaticModels.StaticModelWithInstanceNumbers<MasterDataApi.StaticModels.StaticModelData>[]
    | undefined,
): boolean => {
  if (!staticModels) {
    return false
  }

  return (
    staticModels[0]?.data.operatingConditions?.some(
      (condition) => condition.code === 'TCCO',
    ) ?? false
  )
}

/**
 * This function maps sfoc data points to a filtered and a stable series for each type of ME1, ME2, Mix
 */
export const mapSfocSeries = (
  data: HydraApi.Sfoc.MainEngine,
  hasTwoMainEngines: boolean,
): SfocSeries => {
  return data.stableSeries.reduce(
    (acc: any, isStable, i) => {
      acc.mainEng1StableSfocSeries.push(
        isStable ? data.mainEng1.sfocSeries[i] : null,
      )
      acc.mainEng1NonstableSfocSeries.push(
        isStable ? null : data.mainEng1.sfocSeries[i],
      )
      if (hasTwoMainEngines) {
        acc.mainEng2StableSfocSeries.push(
          isStable ? data.mainEng2.sfocSeries[i] : null,
        )
        acc.mainEngMixStableSfocSeries.push(
          isStable ? data.mainEngMix.sfocSeries[i] : null,
        )
        acc.mainEng2NonstableSfocSeries.push(
          isStable ? null : data.mainEng2.sfocSeries[i],
        )
        acc.mainEngMixNonstableSfocSeries.push(
          isStable ? null : data.mainEngMix.sfocSeries[i],
        )
      }
      return acc
    },
    {
      mainEng1StableSfocSeries: [],
      mainEng2StableSfocSeries: [],
      mainEngMixStableSfocSeries: [],
      mainEng1NonstableSfocSeries: [],
      mainEng2NonstableSfocSeries: [],
      mainEngMixNonstableSfocSeries: [],
    } as SfocSeries,
  )
}

/**
 * Map to sfoc series data to series with stable data points from last six hours.
 */
export const mapLastSixHours = (
  data: HydraApi.Sfoc.MainEngine,
  hasTwoMainEngines: boolean,
) => {
  const sinceLastSixHours = getClosestTenMinute(
    moment.utc().subtract(6, 'h'),
  ).valueOf()
  const { mainEng1, mainEng2, mainEngMix, timestamps } = data
  return timestamps.reduce(
    (acc: any, timestamp, i) => {
      if (timestamp > sinceLastSixHours) {
        acc.ME1LastSixHours.push([
          mainEng1.loadSeries[i],
          mainEng1.sfocSeries[i],
        ])
        if (hasTwoMainEngines) {
          acc.ME2LastSixHours.push([
            mainEng2.loadSeries[i],
            mainEng2.sfocSeries[i],
          ])
          acc.MixLastSixHours.push([
            mainEngMix.loadSeries[i],
            mainEngMix.sfocSeries[i],
          ])
        }
      } else {
        acc.ME1LastSixHours.push([null, null])
        acc.ME2LastSixHours.push([null, null])
        acc.MixLastSixHours.push([null, null])
      }
      return acc
    },
    {
      ME1LastSixHours: [],
      ME2LastSixHours: [],
      MixLastSixHours: [],
    },
  )
}
