import { IconProps } from './types'

export const ExclamationCircle = ({
  size = 20,
  fill = '#545454',
}: IconProps) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={size}
    height={size}
    viewBox='0 0 20 20'
  >
    <path
      fill={fill}
      fillRule='evenodd'
      d='M9.5 4a6.5 6.5 0 1 0 0 13 6.5 6.5 0 0 0 0-13zM2 10.5a7.5 7.5 0 1 1 15 0 7.5 7.5 0 0 1-15 0zM9 12V6.5h1V12H9zm0 2.5v-1.25h1v1.25H9z'
      clipRule='evenodd'
    />
  </svg>
)

export default ExclamationCircle
