import { useEffect } from 'react'
import { useFormikContext } from 'formik'

import { Performance } from '../../../../../api-models'

type Props = {
  rob: Performance.FuelOilStock.RobResponse | undefined
}

/* CurrentBatchRobUpdater sets the current ROB for the batch the user selected to be displayed next to the selector */
export const CurrentBatchRobUpdater = ({ rob }: Props) => {
  const { values, setFieldValue } = useFormikContext<any>()

  useEffect(() => {
    if (values.mixedBatches) {
      values.mixedBatches.forEach((mixedBatch, index) => {
        if (rob?.hasData) {
          const { batchQuantities } = rob
          const batchRob = batchQuantities.find(
            (x) => x.batch.id === mixedBatch.id,
          )
          if (typeof batchRob?.quantity === 'number') {
            setFieldValue(
              `mixedBatches[${index}].currentRob`,
              batchRob.quantity,
            )
            setFieldValue(`mixedBatches[${index}].fuel`, batchRob.batch.fuel)
          } else {
            setFieldValue(`mixedBatches[${index}].currentRob`, undefined)
            setFieldValue(`mixedBatches[${index}].fuel`, undefined)
          }
        } else {
          setFieldValue(`mixedBatches[${index}].currentRob`, undefined)
          setFieldValue(`mixedBatches[${index}].fuel`, undefined)
        }
      })
    }
  }, [values.mixedBatches, rob, setFieldValue])

  return null
}
