import * as React from 'react'
import * as moment from 'moment'
import styled, { grey } from '../theme'
import { getTodayOrTimestamp } from '../utils'

const Wrapper = styled.div<{ lastSixHours?: boolean; width?: number }>`
  display: flex;
  flex-direction: column;
  margin: 16px 8px 8px;
  align-items: center;
  border-radius: 3px;
  border: 1px solid #e0e0e0;
  width: ${(props) => (props.width ? props.width : '96')}px;

  .date {
    font-weight: normal;
    font-size: 12px;
    color: ${grey[700]};
    padding-top: 8px;
  }
  .time {
    font-size: 16px;
    color: #000;
    font-weight: bold;
    padding-bottom: ${(props) => (props.lastSixHours ? '0px' : '8px')};
  }
  .filter-info {
    font-weight: normal;
    font-size: 12px;
    color: ${grey[700]};
    padding-bottom: 8px;

    svg.red {
      margin: auto 2px auto auto;
      color: red;
      font-size: 8px;
      vertical-align: 0;
    }
  }
`

export const Block = styled.div`
  div {
    width: 100%;
  }
  display: flex;
  justify-content: center;
  padding: 8px;
  flex-wrap: wrap;
  border-top: 1px solid ${grey[200]};
`

interface VesselStatusBlockProps {
  timestamp?: string | number | null
  hideTimestamp?: boolean
  lastSixHours?: boolean
  width?: number
  children?: React.ReactNode
}

export const VesselStatusBlock = (props: VesselStatusBlockProps) => {
  const { timestamp, lastSixHours, children } = props
  const [...content] = React.Children.toArray(children).map((child) => child)
  return (
    <Wrapper lastSixHours={!!lastSixHours} width={props.width}>
      <div className='date'>
        {timestamp ? `${getTodayOrTimestamp(timestamp)} UTC` : '-'}
      </div>
      <div className='time'>
        {timestamp ? moment.utc(timestamp).format('HH:mm') : '-'}
      </div>
      {lastSixHours && (
        <div className='filter-info'>
          <span className='fas fa-circle red' /> Last 6 hours
        </div>
      )}
      {content}
    </Wrapper>
  )
}
