import { useContext, useState } from 'react'
import moment from 'moment'
import {
  McButton,
  McLabel,
  McMonthYearPicker,
  McPopover,
} from '@maersk-global/mds-react-wrapper'
import type { IDateRangeValue } from '@maersk-global/mds-components-core/mc-date-range/types'
import type {
  IMcMonthYearSelectedDetail,
  MonthYearPickerValue,
} from '@maersk-global/mds-components-core/mc-month-year-picker/types'

import { Wrapper } from './MonthYearPicker.styles'
import { WindowContext } from '../../contexts'
import {
  DATE_FORMAT,
  MONTH_YEAR_DATE_FORMAT_FULL,
} from '../formik/InputDateTime/InputDateTime.utils'
import {
  getInitialMonthYearFrom,
  getInitialMonthYearTo,
  NumericMonthYearPickerValue,
} from './MonthYearPicker.utils'

type Props = {
  dateRange: IDateRangeValue
  setDateRange: (dateRange: IDateRangeValue) => void
  min: MonthYearPickerValue
  max: MonthYearPickerValue
  isLoading?: boolean
}

const MonthYearPicker = (props: Props) => {
  const { dateRange, setDateRange, min, max, isLoading } = props
  const { windowSize } = useContext(WindowContext)

  const [monthYearFrom, setMonthYearFrom] =
    useState<NumericMonthYearPickerValue>(() =>
      getInitialMonthYearFrom(dateRange),
    )
  const [monthYearTo, setMonthYearTo] = useState<NumericMonthYearPickerValue>(
    () => getInitialMonthYearTo(),
  )

  const handleMonthYearFromInput = (
    event: CustomEvent<IMcMonthYearSelectedDetail>,
  ) => {
    const newMonthYearFrom = event.detail.value as NumericMonthYearPickerValue
    setMonthYearFrom(newMonthYearFrom as NumericMonthYearPickerValue)
    // If the month of the 'from' date is greater than the month of the 'to'
    // date, update the 'to' date to the 'from' date
    if (
      monthYearTo.year === newMonthYearFrom.year &&
      monthYearTo.month < newMonthYearFrom.month
    ) {
      setMonthYearTo({ ...newMonthYearFrom })
    }
  }

  const handleMonthYearToInput = (
    event: CustomEvent<IMcMonthYearSelectedDetail>,
  ) => {
    setMonthYearTo(event.detail.value as NumericMonthYearPickerValue)
  }

  const handleMonthYearApply = () => {
    const { month: monthFrom, year: yearFrom } = monthYearFrom
    const { month: monthTo, year: yearTo } = monthYearTo

    const newDateRange: IDateRangeValue = {
      from: moment.utc(`${yearFrom}-${monthFrom + 1}-01`).format(DATE_FORMAT),
      to: moment.utc(`${yearTo}-${monthTo + 1}-01`).format(DATE_FORMAT),
    }

    setDateRange(newDateRange)
  }

  // Hack to circumvent a bug in `McMonthYearPicker`: Re-render the component to
  // force the pickers to reflect the current month and year
  const triggerReRender = () => {
    setMonthYearFrom({ ...monthYearFrom })
    setMonthYearTo({ ...monthYearTo })
  }

  // The `monthYearTo` lower limit shall always match the `monthYearFrom` month and year
  const monthYearToMin: MonthYearPickerValue = {
    month: monthYearFrom.month,
    year: monthYearFrom.year,
  }

  return (
    <McPopover fit={windowSize} arrow>
      <McButton
        fit={windowSize}
        slot='trigger'
        trailingicon='calendar'
        click={triggerReRender}
        appearance='neutral'
      >
        {moment.utc(dateRange.from).format(MONTH_YEAR_DATE_FORMAT_FULL)} —{' '}
        {moment.utc(dateRange.to).format(MONTH_YEAR_DATE_FORMAT_FULL)}
      </McButton>
      <Wrapper>
        <div>
          <div>
            <McLabel fit={windowSize}>From</McLabel>
            <McMonthYearPicker
              fit={windowSize}
              min={min}
              max={max}
              value={monthYearFrom}
              monthyearselected={handleMonthYearFromInput}
              preventinitialeventdispatch
            />
          </div>
          <div>
            <McLabel fit={windowSize}>To</McLabel>
            <McMonthYearPicker
              fit={windowSize}
              min={monthYearToMin}
              max={max}
              value={monthYearTo}
              monthyearselected={handleMonthYearToInput}
              preventinitialeventdispatch
            />
          </div>
        </div>
        <div>
          <McButton
            fit={windowSize}
            click={handleMonthYearApply}
            loading={isLoading}
          >
            Apply
          </McButton>
        </div>
      </Wrapper>
    </McPopover>
  )
}

export default MonthYearPicker
