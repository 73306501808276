export enum DismissalReason {
  PlannedMaintenance = 'Planned maintenance',
  UnplannedMaintenance = 'Unplanned maintenance',
  EquipmentBreakdown = 'Equipment breakdown',
  AdviceNotAccurate = 'Advice not accurate',
  Weather = 'Weather',
  Safety = 'Safety',
  BallastOperations = 'Ballast operations',
  ConstrainedWatersOrManoeuvring = 'Constrained waters or Manoeuvring',
  OtherIssues = 'Other issues',
  LoadTestOfMainEngine = 'Load test of Main Engine',
  LoadTestOfAuxiliaryEngine = 'Load test of Auxiliary Engine',
  AuxiliaryEngineTurboChargerCleaning = 'Auxiliary Engine Turbo Charger cleaning',
  MainEngineTurboChargerCleaning = 'Main Engine Turbo Charger cleaning',
}

export const reasonConfig: TemSubmitModalTypes.ReasonConfigTypes = {
  [DismissalReason.PlannedMaintenance]: {
    maxDays: 14,
    increaseTimeFrame: true,
    increasedMaxDays: 90,
  },
  [DismissalReason.UnplannedMaintenance]: {
    maxDays: 14,
    increaseTimeFrame: true,
    increasedMaxDays: 90,
  },
  [DismissalReason.EquipmentBreakdown]: {
    maxDays: 14,
    increaseTimeFrame: true,
    increasedMaxDays: 90,
  },
  [DismissalReason.AdviceNotAccurate]: {
    maxDays: 14,
  },
  [DismissalReason.Weather]: {
    maxDays: 1,
  },
  [DismissalReason.Safety]: {
    maxDays: 1,
  },
  [DismissalReason.BallastOperations]: {
    maxDays: 1,
  },
  [DismissalReason.ConstrainedWatersOrManoeuvring]: {
    maxDays: 1,
  },
  [DismissalReason.OtherIssues]: {
    maxDays: 1,
  },
  [DismissalReason.LoadTestOfMainEngine]: {
    maxHours: 8,
  },
  [DismissalReason.LoadTestOfAuxiliaryEngine]: {
    maxHours: 8,
  },
  [DismissalReason.AuxiliaryEngineTurboChargerCleaning]: {
    maxHours: 8,
  },
  [DismissalReason.MainEngineTurboChargerCleaning]: {
    maxHours: 8,
  },
}
