import { useContext, useMemo } from 'react'

import { getMessage } from './EnginePerformanceTestNotifications.utils'
import Notifications from '../Notifications/Notifications'
import { VesselPageContext } from '../../contexts'
import { useGetReports } from '../../queries/GandalfApi/GandalfApi'
import { ReportType } from '../../queries/GandalfApi/GandalfApi.consts'

const EnginePerformanceTestNotifications = () => {
  const imoNo = useContext(VesselPageContext).imoNo!
  const getReports = useGetReports<GandalfApi.Metc.ReportData>(
    imoNo,
    ReportType.Metc,
  )

  const msg = useMemo(() => {
    if (!getReports.isSuccess) {
      return
    }
    return getMessage(getReports.data.reports)
  }, [getReports.data])

  if (!msg) {
    return
  }

  if (msg.alert) {
    return <Notifications alerts={[msg.alert]} />
  }

  if (msg.warning) {
    return <Notifications warnings={[msg.warning]} />
  }
}

export default EnginePerformanceTestNotifications
