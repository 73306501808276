import styled from 'styled-components'

export const ActionButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
`

export const AverageCol = styled.col`
  min-width: 144px;
  @media (min-width: ${(props) => props.theme.breakPoints.medium}px) {
    min-width: 160px;
  }
  @media (min-width: ${(props) => props.theme.breakPoints.large}px) {
    min-width: 184px;
  }
`
