import { useContext, useState } from 'react'
import { type Table } from '@tanstack/react-table'
import moment from 'moment/moment'
import { McInput, McLabel } from '@maersk-global/mds-react-wrapper'
import type { IDateRangeValue } from '@maersk-global/mds-components-core/mc-date-range/types'
import type { MonthYearPickerValue } from '@maersk-global/mds-components-core/mc-month-year-picker/types'

import { getInitialDateRange } from '../EnginePerformanceTestReportsCard.utils'
import { FilterWrapper } from '../../../../components/Table/Table.styles'
import MonthYearPicker from '../../../../components/MonthYearPicker/MonthYearPicker'
import { WindowContext } from '../../../../contexts'

type Props = {
  table: Table<GandalfApi.Metc.ReportSummary>
}

const EnginePerformanceTestReportsFilters = ({ table }: Props) => {
  const { windowSize } = useContext(WindowContext)

  const [dateRange, setDateRange] = useState<IDateRangeValue>(() =>
    getInitialDateRange(),
  )

  const handleDateRangeInput = (dateRange: IDateRangeValue) => {
    setDateRange(dateRange)
    table.getColumn('start')?.setFilterValue({
      from: moment.utc(dateRange.from).valueOf(),
      to: moment.utc(dateRange.to).endOf('month').valueOf(),
    })
  }

  const handleVesselNameFilterChange = (event: Event) => {
    const value = (event.target as HTMLInputElement).value
    table.getColumn('vesselName')?.setFilterValue(value)
  }

  const now = moment.utc()
  const dateRangeFromMin = now.clone().subtract(1, 'year').startOf('month')
  const monthYearMin: MonthYearPickerValue = {
    month: dateRangeFromMin.month(),
    year: dateRangeFromMin.year(),
  }
  const monthYearMax: MonthYearPickerValue = {
    month: now.month(),
    year: now.year(),
  }

  return (
    <FilterWrapper>
      <div>
        <McLabel>Period</McLabel>
        <MonthYearPicker
          dateRange={dateRange}
          setDateRange={handleDateRangeInput}
          min={monthYearMin}
          max={monthYearMax}
        />
      </div>
      <McInput
        fit={windowSize}
        label='Vessel Name'
        placeholder='Search by vessel name'
        input={handleVesselNameFilterChange}
      />
    </FilterWrapper>
  )
}

export default EnginePerformanceTestReportsFilters
