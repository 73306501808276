import { type QueryFunctionContext, useQuery } from '@tanstack/react-query'
import type { IDateRangeValue } from '@maersk-global/mds-components-core/mc-date-range/types'

import { doGet, getBalrogApiUrl } from '../../utils'

const STALE_TIME_12_HOURS = 1000 * 60 * 60 * 12

const BALROG_API = 'BalrogApi' as const
export const ENERGY_METRICS = { [BALROG_API]: 'energyMetrics' } as const
export const LOAD_PROFILE = { [BALROG_API]: 'loadProfile' } as const
export const PERFORMANCE_METRICS = {
  [BALROG_API]: 'performanceMetrics',
} as const
export const ADOPTION_DATA = { [BALROG_API]: 'adoptionData' } as const
export const WASTE_DATA = { [BALROG_API]: 'wasteData' } as const

const keys = {
  energyMetrics: (imo?: string | null, dateRange?: IDateRangeValue) =>
    [{ ...ENERGY_METRICS, imo, dateRange }] as const,
  loadProfile: (imo?: string | null, dateRange?: IDateRangeValue) =>
    [{ ...LOAD_PROFILE, imo, dateRange }] as const,
  performanceMetrics: (imo?: string | null, dateRange?: IDateRangeValue) =>
    [{ ...PERFORMANCE_METRICS, imo, dateRange }] as const,
  adoptionData: (imo?: string | null) => [{ ...ADOPTION_DATA, imo }] as const,
  wasteData: (imo?: string | null, dateRange?: IDateRangeValue) =>
    [{ ...WASTE_DATA, imo, dateRange }] as const,
}

export const useGetEnergyMetrics = (
  imo?: string | null,
  dateRange?: IDateRangeValue,
) => {
  return useQuery({
    queryKey: keys.energyMetrics(imo, dateRange),
    queryFn: getEnergyMetrics,
    staleTime: STALE_TIME_12_HOURS,
    enabled: !!imo && !!dateRange?.from && !!dateRange.to,
  })
}

const getEnergyMetrics = async ({
  queryKey: [{ imo, dateRange }],
}: QueryFunctionContext<
  ReturnType<(typeof keys)['energyMetrics']>
>): Promise<BalrogApi.EnergyMetricsResponse> => {
  const searchParams = new URLSearchParams({
    imo: imo!,
    start_date: dateRange!.from!,
    end_date: dateRange!.to!,
    aggregation_time: 'month',
    aggregation_level: 'vessel',
  })

  return doGet(`${getBalrogApiUrl()}/energy/metrics?${searchParams.toString()}`)
}

const getLoadProfile = async ({
  queryKey: [{ imo, dateRange }],
}: QueryFunctionContext<
  ReturnType<(typeof keys)['loadProfile']>
>): Promise<BalrogApi.LoadProfileResponse> => {
  const searchParams = new URLSearchParams({
    imo: imo!,
    start_date: dateRange!.from!,
    end_date: dateRange!.to!,
  })

  return doGet(
    `${getBalrogApiUrl()}/sister-vessel-comparison/load-profile-graph?${searchParams.toString()}`,
  )
}

export const useGetLoadProfile = (
  imo: string | null,
  dateRange?: IDateRangeValue,
) => {
  return useQuery({
    queryKey: keys.loadProfile(imo, dateRange),
    queryFn: getLoadProfile,
    staleTime: STALE_TIME_12_HOURS,
    enabled: !!imo && !!dateRange?.from && !!dateRange.to,
  })
}

export const useGetPerformanceMetrics = (
  imo?: string | null,
  dateRange?: IDateRangeValue,
) => {
  return useQuery({
    queryKey: keys.performanceMetrics(imo, dateRange),
    queryFn: getPerformanceMetrics,
    staleTime: STALE_TIME_12_HOURS,
    enabled: !!imo && !!dateRange?.from && !!dateRange.to,
  })
}

const getPerformanceMetrics = async ({
  queryKey: [{ imo, dateRange }],
}: QueryFunctionContext<
  ReturnType<(typeof keys)['performanceMetrics']>
>): Promise<BalrogApi.PerformanceMetricsResponse> => {
  const searchParams = new URLSearchParams({
    imo: imo!,
    start_date: dateRange!.from!,
    end_date: dateRange!.to!,
  })

  return doGet(
    `${getBalrogApiUrl()}/sister-vessel-comparison/common-graph?${searchParams.toString()}`,
  )
}

export const useGetAdoptionData = (imo?: string | null) => {
  return useQuery({
    queryKey: keys.adoptionData(imo),
    queryFn: getAdoptionData,
    staleTime: STALE_TIME_12_HOURS,
    enabled: !!imo,
  })
}

const getAdoptionData = async ({
  queryKey: [{ imo }],
}: QueryFunctionContext<
  ReturnType<(typeof keys)['adoptionData']>
>): Promise<BalrogApi.AdoptionResponse> => {
  const searchParams = new URLSearchParams({
    imos: imo!,
  })

  return doGet(
    `${getBalrogApiUrl()}/sister-vessel-comparison/adoption-data?${searchParams.toString()}`,
  )
}

const getWasteData = async ({
  queryKey: [{ imo, dateRange }],
}: QueryFunctionContext<
  ReturnType<(typeof keys)['wasteData']>
>): Promise<BalrogApi.WasteResponse> => {
  const searchParams = new URLSearchParams({
    imo: imo!,
    start_date: dateRange!.from!,
    end_date: dateRange!.to!,
  })

  return doGet(
    `${getBalrogApiUrl()}/sister-vessel-comparison/waste?${searchParams.toString()}`,
  )
}

export const useGetWasteData = (
  imo: string | null,
  dateRange?: IDateRangeValue,
) => {
  return useQuery({
    queryKey: keys.wasteData(imo, dateRange),
    queryFn: getWasteData,
    staleTime: STALE_TIME_12_HOURS,
    enabled: !!imo && !!dateRange?.from && !!dateRange.to,
  })
}
