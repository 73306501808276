/**
 * Use component to wrap a FancyLabel component to get a dropdown menu.
 *
 * <ChartLegendDropdown>
 *  <FancyLabel
 *      ....
 * />
 * <div id="menu">
 *  <div id="menu-options">
 *      <div class=""menu-items">
 *          {.... option elements}
 *      </div>
 *  </div>
 * </div>
 * </ChartLegendDropdown>
 */

import styled from 'styled-components'
import { maerskBlue, grey } from '../theme'

export const ChartLegendDropdown = styled.div`
  position: relative;
  #vessel-specific {
    background: transparent;
    &:focus {
      outline: none;
    }
  }
  #menu {
    position: absolute;
    background: white;
    white-space: nowrap;
    border: 1px solid #d1d3d5;
    border-radius: 4px;
    box-shadow: 2px 4px 8px 0 rgba(40, 47, 54, 0.12);
    box-sizing: border-box;
    z-index: 1;
    #menu-options {
      padding: 4px;
    }
    .menu-items {
      display: flex;
      align-items: center;
      span {
        padding-left: 4px;
        font-size: 12px;
        cursor: pointer;
      }
      svg {
        color: ${maerskBlue[500]};
        cursor: pointer;
      }
    }
    #controls {
      background-color: ${grey[100]};
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      padding: 8px;
      display: flex;
      justify-content: center;
      border-top: 1px solid #d1d3d5;
    }
  }
`
