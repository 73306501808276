import * as echarts from 'echarts/core'
import { type EChartsOption, type EChartsType } from 'echarts/types/dist/shared'
import { BarChart } from 'echarts/charts'
import {
  DatasetComponent,
  GridComponent,
  LegendComponent,
  TooltipComponent,
} from 'echarts/components'
import { UniversalTransition } from 'echarts/features'
import { CanvasRenderer } from 'echarts/renderers'

import {
  AXIS_LABEL,
  AXIS_LINE,
  AXIS_TICK,
  COLOR,
  GRID,
  MARK_LINE_STYLE,
  NAME_LOCATION,
  TEXT_STYLE,
  TOOLTIP,
  X_AXIS_NAME_GAP,
} from '../../Common/ChartConfig'
import { formatValue } from '../../../../../utils'

echarts.use([
  BarChart,
  CanvasRenderer,
  DatasetComponent,
  GridComponent,
  LegendComponent,
  TooltipComponent,
  UniversalTransition,
])

export const initChart = (domId: string, yAxisName: string) => {
  const chartDom = document.getElementById(domId)

  if (!chartDom) {
    console.error(`Chart DOM element with ID '${domId}' not found`)
    return
  }

  const options: EChartsOption = {
    grid: {
      ...GRID,
      bottom: 32,
      top: 32,
      left: 40,
    },
    legend: {
      top: 0,
      right: 80,
      textStyle: TEXT_STYLE,
      lineStyle: { opacity: 0 },
      itemWidth: 16,
      selected: {
        'Pmax measured': false,
        'Pcomp measured': false,
      },
    },
    // @ts-ignore
    tooltip: {
      ...TOOLTIP,
      formatter: (params: any) => {
        let result = `Cylinder <strong>${params[0].axisValue}</strong>`
        params.forEach((item: any) => {
          result += `<br/>${item.marker} ${
            item.seriesName
          }: <strong style="font-variant: tabular-nums">${formatValue(
            item.data[1],
            2,
          )} bar</strong>`
        })
        return result
      },
    },
    xAxis: {
      type: 'category',
      name: 'Cylinder',
      nameLocation: NAME_LOCATION,
      nameGap: X_AXIS_NAME_GAP,
      axisLabel: AXIS_LABEL,
      axisTick: AXIS_TICK,
      nameTextStyle: AXIS_LABEL,
    },
    yAxis: {
      name: yAxisName,
      nameLocation: NAME_LOCATION,
      nameGap: 32,
      nameTextStyle: AXIS_LABEL,
      axisLabel: AXIS_LABEL,
      axisLine: AXIS_LINE,
    },
  }

  const chart = echarts.init(chartDom)
  chart.setOption(options)

  return chart
}

export const updateChart = (
  y1: Array<number | null>,
  y2: Array<number | null> | null,
  yAvg: number | null,
  yModel: number | null,
  seriesNames: Array<string>,
  chart?: EChartsType,
) => {
  if (!chart) {
    console.error('Chart not initialized')
    return
  }

  let y1Values = y1.filter((val) => val !== null) as Array<number>
  if (y1Values.length === 0) {
    y1Values = [0, 100]
  }
  let y2Values = (y2 ?? []).filter((val) => val !== null) as Array<number>

  const yMin = Math.min(...y1Values, ...y2Values)
  const yMax = Math.max(...y1Values, ...y2Values)

  const yAxisMin = Math.floor(Math.max(yMin - 4, 0) / 10) * 10
  const yAxisMax = Math.ceil(Math.max(yMax, yModel ?? 0) + 1)

  chart.setOption({
    yAxis: { min: yAxisMin, max: yAxisMax },
    dataset: [
      { source: y1.map((val, i) => [i + 1, val]) },
      ...(y2 ? [{ source: y2.map((val, i) => [i + 1, val]) }] : []),
    ],
    series: [
      {
        color: y2 ? COLOR.primary : COLOR.secondary,
        type: 'bar',
        barGap: '-100%',
        datasetIndex: 0,
        barWidth: '48%',
        name: seriesNames[0],
        markLine: {
          silent: true,
          symbol: 'none',
          lineStyle: MARK_LINE_STYLE,
          label: AXIS_LABEL,
          data: [
            ...(yAvg
              ? [
                  {
                    yAxis: yAvg,
                    lineStyle: { color: y2 ? COLOR.primary : COLOR.secondary },
                    label: { formatter: 'Average' },
                  },
                ]
              : []),

            ...(yModel
              ? [
                  {
                    yAxis: yModel,
                    lineStyle: { color: '#B80012' },
                    label: { formatter: 'Shop test' },
                  },
                ]
              : []),
          ],
        },
      },
      ...(y2
        ? [
            {
              color: COLOR.secondary,
              type: 'line',
              lineStyle: { opacity: 0 },
              symbolSize: 16,
              symbol: 'diamond',
              datasetIndex: 1,
              name: seriesNames[1],
            },
          ]
        : []),
    ],
  })
}

export const updateDeviationChart = (
  y: Array<number | null>,
  yAvg: number | null,
  limit: number,
  seriesName: string,
  chart?: EChartsType,
) => {
  if (!chart) {
    console.error('Chart not initialized')
    return
  }

  let yCentered =
    yAvg === null ? [] : y.map((y) => (y === null ? null : y - yAvg))
  const yCenteredValues =
    yCentered === null
      ? []
      : (yCentered.filter((val) => val !== null) as Array<number>)
  const yCenteredMin = Math.min(...yCenteredValues)
  const yCenteredMax = Math.max(...yCenteredValues)

  const lowerLimit = -1 * limit
  const upperLimit = limit

  const yAxisMin = Math.floor(Math.min(yCenteredMin, lowerLimit) - 0.5)
  const yAxisMax = Math.ceil(Math.max(yCenteredMax, upperLimit) + 0.5)

  if (yCentered.length === 0) {
    yCentered = Array.from({ length: y.length }, () => null)
  }

  chart.setOption({
    yAxis: { min: yAxisMin, max: yAxisMax },
    dataset: [{ source: yCentered.map((val, i) => [i + 1, val]) }],
    series: [
      {
        type: 'bar',
        barWidth: '48%',
        name: seriesName,
        color: COLOR.secondary,
        markLine: {
          silent: true,
          symbol: 'none',
          lineStyle: { ...MARK_LINE_STYLE, color: '#B80012' },
          label: { ...AXIS_LABEL, formatter: 'Limit' },
          data: [{ yAxis: upperLimit }, { yAxis: lowerLimit }],
        },
      },
    ],
  })
}
