import * as React from 'react'
import * as ECharts from 'echarts'
import { QueryPeriod } from '../../../features/filter'
import { Chart } from '../../../utils/models'
import { stripDecimals, formatValue, tooltip } from '../../../utils'
import { orange, maerskBlue, maritimeBlue, grey } from '../../../theme'
import {
  ACTIVATE_ZOOM_ACTION,
  GRID,
  AXIS_LABEL,
  AXIS_LINE,
  AXIS_TICK,
  AXIS_SPLIT_LINE,
  TOOLBOX_SCATTER,
  UNITS,
} from '../../../utils/constants'
import { MappedWHrCurveData } from '../models'

export interface WasteHeatRecoveryChartProps {
  id: 'whr-vs-me-chart'
  queryPeriod: QueryPeriod
  data: MappedWHrCurveData[]
}

export interface WasteHeatRecoveryChartState {}

export default class WasteHeatRecoveryChart extends React.Component<
  WasteHeatRecoveryChartProps,
  WasteHeatRecoveryChartState
> {
  private chartNode: React.RefObject<HTMLDivElement>
  private chart?: Chart
  constructor(props: WasteHeatRecoveryChartProps) {
    super(props)

    this.state = {}
    this.chartNode = React.createRef()
  }

  componentDidMount() {
    const { data, id } = this.props
    if (this.chartNode.current) {
      const chart = ECharts.init(this.chartNode.current) as Chart
      chart.setOption(this.WHRvsMEOptions(id, data))
      chart.dispatchAction(ACTIVATE_ZOOM_ACTION)
      this.chart = chart
    }
  }

  componentDidUpdate(prevProps: WasteHeatRecoveryChartProps) {
    const { data, id } = this.props
    if (this.chart && prevProps.data !== data) {
      this.chart.setOption(this.WHRvsMEOptions(id, data))
    }
  }

  WHRvsMEOptions = (
    id: string,
    data: MappedWHrCurveData[],
  ): ECharts.EChartOption | any => {
    // Mapping full dataset
    const fullSeries = data.map(
      ({ timestamp, load, power, filtered }, index) => [
        load,
        power,
        { timestamp: timestamp, filtered: filtered },
      ],
    )

    // Creating options
    return {
      animation: false,
      grid: GRID,
      yAxis: [
        {
          type: 'value',
          axisLabel: {
            formatter: (value: any) => stripDecimals(value, 1),
            ...AXIS_LABEL,
          },
          axisLine: AXIS_LINE,
          axisTick: AXIS_TICK,
          splitLine: AXIS_SPLIT_LINE,
        },
      ],
      xAxis: [
        {
          min: 0,
          max: 100,
          type: 'value',
          axisLabel: {
            formatter: (value: any) => stripDecimals(value, 1),
            ...AXIS_LABEL,
          },
          axisLine: AXIS_LINE,
          axisTick: AXIS_TICK,
          splitLine: AXIS_SPLIT_LINE,
        },
      ],
      dataZoom: [
        {
          type: 'select',
          xAxisIndex: 0,
        },
        {
          type: 'select',
          yAxisIndex: 0,
        },
      ],
      tooltip: {
        trigger: 'item',
        axisPointer: {
          type: 'cross',
          label: {
            show: false,
          },
        },
        textStyle: {
          color: grey[50],
        },
        backgroundColor: 'rgba(50,50,50,0.9)',
        formatter: (params: any) => {
          return tooltip(params.data[2].timestamp, [
            {
              label: 'Power',
              value: formatValue(params.data[1], 1),
              unit: 'kW',
            },
            {
              label: 'Load',
              value: formatValue(params.data[0], 1),
              unit: UNITS.ENGINE_LOAD,
            },
          ])
        },
      },
      toolbox: TOOLBOX_SCATTER,
      series: [
        {
          animation: true,
          name: 'WHR vs ME',
          type: 'scatter',
          symbolSize: 4,
          data: fullSeries,
          itemStyle: {
            color: (params: any) => {
              if (params.data[2].filtered) {
                return orange[500]
              }

              return maerskBlue[500]
            },
          },
          emphasis: {
            label: {
              show: true,
              position: 'inside',
              offset: [0, 0],
              formatter: () => '●',
              color: maritimeBlue[500],
              fontSize: 24,
            },
            itemStyle: {
              color: maritimeBlue[500],
            },
          },
        },
      ],
    }
  }

  public render() {
    return (
      <div
        onDoubleClick={() => {
          if (this.chart) {
            this.chart.dispatchAction({
              type: 'dataZoom',
              start: 0,
              end: 100,
            })
          }
        }}
        id={this.props.id}
        ref={this.chartNode}
      />
    )
  }
}
