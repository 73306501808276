import { useContext, useEffect, useRef, useState } from 'react'
import { EChartsType } from 'echarts/types/dist/shared'
import styled from 'styled-components'
import type { IDateRangeValue } from '@maersk-global/mds-components-core/mc-date-range/types'
import { McCheckbox } from '@maersk-global/mds-react-wrapper'

import { getChartId, initChart, updateChart } from './CommonMetricsChart.utils'
import { CheckboxWrapper } from '../../EquipmentWaste.styles'
import { useGetPerformanceMetrics } from '../../../../../queries/BalrogApi/BalrogApi'
import { Selection } from '../../../../../utils'
import { VesselPageContext, WindowContext } from '../../../../../contexts'

type Props = {
  sisterVesselImoList: string
  dateRange?: IDateRangeValue
  chartType: string
}

const CommonMetricsChart = ({
  sisterVesselImoList,
  dateRange,
  chartType,
}: Props) => {
  const imoNo = useContext(VesselPageContext).imoNo!
  const { windowSize } = useContext(WindowContext)
  const chartRef = useRef<EChartsType>()
  const CHART_ID = getChartId(chartType)
  const [selection, setSelection] = useState(Selection.ALL)

  const getPerformanceMetrics = useGetPerformanceMetrics(
    sisterVesselImoList,
    dateRange,
  )

  useEffect(() => {
    // IMO number changed, reset the chart
    chartRef.current?.clear()
    chartRef.current = initChart(CHART_ID, chartType)

    // Attach event listener to the chart to update the checkbox state
    chartRef.current?.on('legendselectchanged', (event: any) => {
      if (Object.values(event.selected).every((isSelected) => isSelected)) {
        setSelection(Selection.ALL)
      } else if (
        Object.values(event.selected).every((isSelected) => !isSelected)
      ) {
        setSelection(Selection.NONE)
      } else {
        setSelection(Selection.PARTIAL)
      }
    })

    // Reset the checkbox state
    setSelection(Selection.ALL)
  }, [imoNo])

  useEffect(() => {
    if (!chartRef.current || !getPerformanceMetrics.isSuccess) return
    updateChart(getPerformanceMetrics.data, chartType, chartRef.current)
  }, [chartType, dateRange, getPerformanceMetrics.isSuccess, imoNo])

  const handleCheckboxClick = (event: MouseEvent) => {
    if (!chartRef.current) return

    event.preventDefault()

    const selectedVessels = chartRef.current?.getOption()?.legend?.[0]?.selected
    const allTrue = Object.values(selectedVessels).every(
      (isSelected) => isSelected,
    )

    chartRef.current?.dispatchAction({
      type: allTrue ? 'legendInverseSelect' : 'legendAllSelect',
    })

    if (allTrue) {
      setSelection(Selection.NONE)
    } else {
      setSelection(Selection.ALL)
    }
  }

  return (
    <>
      <CheckboxWrapper>
        <McCheckbox
          fit={windowSize}
          label={'Select all vessels'}
          checked={selection !== Selection.NONE}
          indeterminate={selection === Selection.PARTIAL}
          click={handleCheckboxClick}
        />
      </CheckboxWrapper>
      <ChartContainer id={CHART_ID} />
    </>
  )
}

export default CommonMetricsChart

const ChartContainer = styled.div`
  height: 480px;
`
