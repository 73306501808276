import React, { useContext, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router'
import { useFormikContext } from 'formik'
import { useTheme } from 'styled-components'
import { McButton } from '@maersk-global/mds-react-wrapper'

import { WindowContext } from '../../../../contexts'
import { discardReportChanges } from '../../../../services/hdc'
import { displayConfirmModal, displayErrorModal } from '../../../../utils'
import { ReportTypeNames } from '../../constants'
import { HDCContext } from '../../context'
import { ReportTimestamp } from '../../context/reducer'
import HDCBridgeReport from '../../reports/bridge'
import HDCEngineReport from '../../reports/engine'
import {
  BridgeFormStep,
  EngineRoomFormStep,
  FormMainEngine,
  HDCFormType,
  HDCReportFormValues,
} from '../../types'
import { isReportSubmitted, prettyDuration } from '../../utils'
import ModalContent from '../modal-content'
import OverlayLoader from '../overlay-loader'
import {
  ContentCard,
  ContentCardBody,
  ContentCardFooter,
  ContentCardTitleChild,
} from '../styles'
import ReportStart from './start'
import { ApiReportType } from '../../../../api-models/hdc/report'
import InfoModal from '../InfoModal/InfoModal'

interface ReportCardHeaderProps {
  timestamp: ReportTimestamp
  seqNo: number | undefined
}

const eventExists = (events?: HDCReportFormValues['engineRoom']['events']) =>
  events?.some((event) => /(Operational|Technical)/.test(event.typeName)) ||
  false

// Validate if the ME running hours is less than the report period duration
const mainEngineRunTimePreCheck = (
  mainEngines: Array<FormMainEngine>,
  values: HDCReportFormValues,
) => {
  if (mainEngines && mainEngines.length > 0) {
    const reportPeriodDuration = values.periodEnd.diff(
      values.periodStart,
      'minutes',
    )

    const mainEngineRuntimes = mainEngines.map(
      (me) =>
        (me.runTime.hours.value ?? 0) * 60 + (me.runTime.minutes.value ?? 0),
    )

    return Math.max(...mainEngineRuntimes) < reportPeriodDuration
  }
}

const ReportCardHeaderChildren = ({
  timestamp: { from, to },
  seqNo,
}: ReportCardHeaderProps) => (
  <>
    <ContentCardTitleChild bold>
      No. {seqNo ? seqNo : '-'}
    </ContentCardTitleChild>
    <ContentCardTitleChild bold>
      Duration {prettyDuration(from, to)}
    </ContentCardTitleChild>
    <ContentCardTitleChild>
      From {from.utc().format('DD MMM YYYY HH:mm')} UTC
    </ContentCardTitleChild>
    <ContentCardTitleChild>
      To {to.utc().format('DD MMM YYYY HH:mm')} UTC
    </ContentCardTitleChild>
  </>
)

const HDCReportCard = () => {
  const theme = useTheme()
  const { windowSize } = useContext(WindowContext)
  const { values, errors, submitForm } = useFormikContext<HDCReportFormValues>()
  const { engineRoom } = values
  const { mainEngines } = engineRoom
  const { setFormCurrentStep, state: HDCState } = useContext(HDCContext)
  const { reportType, reportTimestamp, report, form } = HDCState
  const { currentStep, hasUnsavedEvent, steps, type } = form
  const { push } = useHistory()
  const refErrors = useRef(errors)
  const refHasUnsavedEvent = useRef(hasUnsavedEvent)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [infoModalOpen, setInfoModalOpen] = useState(false)
  const [infoModalClosed, setInfoModalClosed] = useState(false)

  useEffect(() => {
    refErrors.current = errors
    refHasUnsavedEvent.current = hasUnsavedEvent
  }, [errors, hasUnsavedEvent])

  const displayDiscardWarning = async () => {
    const title = 'Discard changes'
    const message = (
      <ModalContent
        icon='exclamation-triangle-solid'
        iconColor={theme.colors.feedback.warning.main}
        message={
          <div>
            You are about to discard changes made to this report after it has
            been submitted.
          </div>
        }
        title='Be aware!'
      />
    )
    return displayConfirmModal({ title, message }, 'Discard changes', 'Cancel')
  }

  const handleDiscardClick = async () => {
    setIsLoading(true)
    try {
      await displayDiscardWarning()
      await discardReportChanges(report!.imo, report!.id, type!)
      push(`/MaerskStarConnect/vessel/${report!.imo}/hdc/overview`)
    } catch (err) {
      if (err === 'cancel') {
        return
      }
      void displayErrorModal({
        statusText: 'Failed to discard changes',
        message: err.body.error || 'Error occurred, please try again',
      })
    } finally {
      setIsLoading(false)
    }
  }

  const handleInfoModalClose = () => {
    setInfoModalClosed(true)
    setInfoModalOpen(false)
  }

  const handleSubmitClick = async () => {
    //check if the report is sea and the current step is Propulsion and the info modal is not closed
    if (
      reportType === ApiReportType.SEA &&
      steps[currentStep] === EngineRoomFormStep.PROPULSION &&
      !infoModalClosed
    ) {
      if (
        !eventExists(HDCState.report?.engineRoom.events) &&
        !eventExists(HDCState.report?.bridge.events) &&
        mainEngineRunTimePreCheck(mainEngines, values)
      ) {
        setInfoModalOpen(true)
        return
      }
    }

    await submitForm()
    if (
      [BridgeFormStep.EVENTS, EngineRoomFormStep.EVENTS].includes(
        steps[currentStep],
      ) &&
      refHasUnsavedEvent.current
    ) {
      return
    }
    window.scrollTo({ top: 0, behavior: 'smooth' })
    if (
      [BridgeFormStep.SUMMARY, EngineRoomFormStep.SUMMARY].includes(
        steps[currentStep],
      )
    ) {
      return
    }

    // TODO: We need to wait for the side effect triggered by `errors` to run,
    //  i.e. if any errors are set on Formik we have to wait a bit to make sure
    //  we prevent the user from navigating away the current step.
    //  NOTE: This is obviously a hack and very bad practice! However, as long
    //  as we're using Formik there's no proper way to fix this.
    await new Promise((resolve) => setTimeout(resolve, 100))

    // Do not allow user to go to the next page if there are validation errors
    if (!Object.keys(refErrors.current).length) {
      setFormCurrentStep(currentStep + 1)
    }
  }

  if (
    !report ||
    reportType === null ||
    !reportTimestamp?.from ||
    !reportTimestamp?.to
  ) {
    return null
  }

  const getSubmitButtonLabel = () =>
    currentStep === steps.length - 1 ? 'Submit' : 'Save & Next'

  return (
    <>
      <ContentCard
        id='report-card'
        title={ReportTypeNames[reportType!]}
        headerChildren={
          <ReportCardHeaderChildren
            timestamp={reportTimestamp}
            seqNo={report?.seqNo}
          />
        }
        footerContent={
          type ? (
            <ContentCardFooter>
              <McButton
                fit={windowSize}
                type='button'
                click={handleSubmitClick}
                data-e2e='next-or-submit'
              >
                {getSubmitButtonLabel()}
              </McButton>
              {isReportSubmitted(report) && (
                <McButton
                  fit={windowSize}
                  appearance='neutral'
                  type='button'
                  click={handleDiscardClick}
                >
                  Close and discard changes
                </McButton>
              )}
            </ContentCardFooter>
          ) : null
        }
      >
        <ContentCardBody>
          {type === null && <ReportStart />}
          {type === HDCFormType.BRIDGE_FORM && <HDCBridgeReport />}
          {type === HDCFormType.ENGINE_FORM && <HDCEngineReport />}
        </ContentCardBody>
        {isLoading && <OverlayLoader padding='0px' />}
      </ContentCard>
      <InfoModal open={infoModalOpen} onClose={handleInfoModalClose} />
    </>
  )
}

export default HDCReportCard
