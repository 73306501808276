import styled from 'styled-components'

export const Heading = styled.h3`
  padding-bottom: 24px !important;
  margin-bottom: -12px !important;
  border-bottom: 1px solid black;
`

export const Item = styled.div`
  margin-bottom: 16px;

  > label {
    font-weight: bold;
  }

  > p {
    margin-top: 4px;
  }
`

export const Comment = styled(Item)`
  flex-basis: 100% !important;
`

export const Ruler = styled.hr`
  margin: 32px 0 !important;
`
