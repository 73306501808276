import type { VesselsConsumption } from './PerformanceOverview.types'
import { calcVectorSum } from '../../../utils'

export const buildVesselsTotalConsumption = (
  energyMetrics: Array<BalrogApi.EnergyMetrics>,
  wasteData: Array<BalrogApi.WasteData>,
): VesselsConsumption => {
  const vesselsMeConsumption = buildVesselsMeConsumption(wasteData)
  const vesselsAeConsumption = buildVesselsAeConsumption(wasteData)
  const vesselsBoilerConsumption = buildVesselsBoilerConsumption(energyMetrics)
  const vesselsWhrConsumption = buildVesselsWhrConsumption(energyMetrics)

  const result: VesselsConsumption = {}
  for (const vesselName in vesselsMeConsumption) {
    const consumptionTotal = calcVectorSum([
      vesselsMeConsumption[vesselName].consumption,
      vesselsAeConsumption[vesselName].consumption,
      vesselsBoilerConsumption[vesselName].consumption,
      vesselsWhrConsumption[vesselName].consumption,
    ])

    const wasteTotal = calcVectorSum([
      vesselsMeConsumption[vesselName].waste,
      vesselsAeConsumption[vesselName].waste,
      vesselsBoilerConsumption[vesselName].waste,
      vesselsWhrConsumption[vesselName].waste,
    ])

    result[vesselName] = {
      consumption: consumptionTotal ?? 0,
      waste: wasteTotal ?? 0,
    }
  }

  return result
}

const buildVesselsBoilerConsumption = (
  data: Array<BalrogApi.EnergyMetrics>,
) => {
  const result: VesselsConsumption = {}
  data.forEach(
    ({
      vesselName,
      boilerConsumptionMt: consumption,
      boilerWasteMt: waste,
    }) => {
      addConsumptionEntry(result, vesselName, consumption, waste)
    },
  )
  return result
}

const buildVesselsWhrConsumption = (data: Array<BalrogApi.EnergyMetrics>) => {
  const result: VesselsConsumption = {}
  data.forEach(
    ({ vesselName, whrConsumptionMt: consumption, whrWasteMt: waste }) => {
      addConsumptionEntry(result, vesselName, consumption, waste)
    },
  )
  return result
}

export const buildVesselsMeConsumption = (data: Array<BalrogApi.WasteData>) => {
  const result: VesselsConsumption = {}
  data.forEach(
    ({ vesselName, meConsumptionMt: consumption, meWasteMt: waste }) => {
      addConsumptionEntry(result, vesselName, consumption, waste)
    },
  )
  return result
}

export const buildVesselsAeConsumption = (data: Array<BalrogApi.WasteData>) => {
  const result: VesselsConsumption = {}
  data.forEach((x) => {
    const consumption = calcVectorSum([
      x.ae1ConsumptionMt,
      x.ae2ConsumptionMt,
      x.ae3ConsumptionMt,
      x.ae4ConsumptionMt,
      x.ae5ConsumptionMt,
    ])
    const waste = calcVectorSum([
      x.ae1WasteMt,
      x.ae2WasteMt,
      x.ae3WasteMt,
      x.ae4WasteMt,
      x.ae5WasteMt,
    ])
    const { vesselName } = x
    addConsumptionEntry(result, vesselName, consumption, waste)
  })
  return result
}

const addConsumptionEntry = (
  result: VesselsConsumption,
  vesselName: string,
  consumption: number | null,
  waste: number | null,
) => {
  const name = vesselName.toUpperCase()
  if (name in result) {
    // @ts-ignore
    result[name].consumption += consumption
    // @ts-ignore
    result[name].waste += waste
  } else {
    result[name] = {
      consumption,
      waste,
    }
  }
}

export const calculateVesselsConsumptionDeviation = (
  vesselsConsumption: VesselsConsumption,
) => {
  return Object.entries(vesselsConsumption).map(
    ([vesselName, { consumption, waste }]) => {
      if (consumption === null || consumption === 0 || waste === null) {
        return {
          name: vesselName,
          value: 0,
        }
      }
      const diff = consumption + waste
      return {
        name: vesselName,
        value: ((diff - consumption) / consumption) * 100,
      }
    },
  )
}

export const calculateAdoptionPercentage = ({
  tem,
  maxfuel,
}: BalrogApi.Adoption) => {
  const adoption = (tem.adoption ?? 0) + (maxfuel.adoption ?? 0)
  const nonAdoption = (tem.nonAdoption ?? 0) + (maxfuel.nonAdoption ?? 0)
  const total = adoption + nonAdoption
  return total > 0 ? (adoption / total) * 100 : 0
}
