import { useContext, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { McTag } from '@maersk-global/mds-react-wrapper'

import {
  getVoyageLegDisplayString,
  handleDismissAdvice,
} from './TemNotifications.utils'
import { McTagWrapper } from './TemNotifications.styles'

import {
  Wrapper,
  StyledMcNotification,
} from '../Notifications/Notifications.styles'
import { VesselPageContext, WindowContext } from '../../contexts'
import {
  NotificationCategories,
  NotificationState,
  NotificationTypes,
} from '../../queries/NotificationsApi/NotificationsApi.consts'
import {
  useDismissNotification,
  useNotifications,
} from '../../queries/NotificationsApi/NotificationsApi'
import { useTerminals } from '../../queries/MasterDataApi/MasterDataApi'
import {
  ActivityLogTab,
  ActivityLogTabSearchParam,
} from '../../pages/ActivityLogPage/ActivityLogPage.consts'

const TemNotifications = () => {
  const imoNo = useContext(VesselPageContext).imoNo!
  const { windowSize } = useContext(WindowContext)

  const filter: NotificationsAPI.NotificationsQueryFilter = {
    state: NotificationState.Active,
    type: NotificationTypes.EPA,
    category: NotificationCategories.Energy,
    imo_no: +imoNo,
  }

  const dismissNotification = useDismissNotification(filter)

  const { data: notifications, isSuccess: isNotificationsSuccess } =
    useNotifications<NotificationsAPI.TemAdvice>(filter)

  const { data: terminals } = useTerminals()

  const voyageLegDisplayString = useMemo(
    () => getVoyageLegDisplayString(notifications, terminals),
    [notifications, terminals],
  )

  if (!isNotificationsSuccess || notifications.length === 0) return null

  const handleDismissAdviceClick =
    (notification: NotificationsAPI.TemAdvice) => async () => {
      const notificationResponse = await handleDismissAdvice(notification)
      if (!notificationResponse) return
      dismissNotification.mutate(notificationResponse)
    }

  const notification = notifications[0]
  const additionalNotificationsCount = notifications.length - 1
  const activityLogUrl = `/MaerskStarConnect/vessel/${imoNo}/activity-log?${ActivityLogTabSearchParam}=${ActivityLogTab.EnergyAdvice}`

  return (
    <Wrapper>
      <StyledMcNotification
        key={notification.id}
        fit={windowSize}
        heading={voyageLegDisplayString}
        appearance='warning'
        icon='light-bulb'
      >
        {notification.data.description}
        {additionalNotificationsCount > 0 && (
          <McTagWrapper>
            <Link to={activityLogUrl}>
              <McTag
                fit={windowSize}
                label={`+ ${additionalNotificationsCount} more`}
                appearance='info'
              />
            </Link>
          </McTagWrapper>
        )}
        <span slot='actions'>
          <span
            className='action'
            onClick={handleDismissAdviceClick(notification)}
          >
            Dismiss advice
          </span>
        </span>
      </StyledMcNotification>
    </Wrapper>
  )
}

export default TemNotifications
