export const filterTechnicalEventTypes = (
  eventTypes: Array<GandalfApi.EventTypes>,
) => {
  return eventTypes
    .filter((eventType) => eventType.name === 'Technical Off-service')
    .flatMap((data) => data.eventTypes) as GandalfApi.EventTypes[]
}

export const getEquipmentOptions = (
  eventTypes: Array<GandalfApi.EventTypes>,
) => {
  return eventTypes.map((t) => ({ value: t.name, label: t.name }))
}

export const getComponentOptions = (
  eventTypes: Array<GandalfApi.EventTypes>,
  equipment: string | null,
) => {
  if (equipment === null) {
    return []
  }
  return (
    eventTypes
      .find((t) => t.name === equipment)
      ?.eventTypes?.map((t) => ({ value: t.name, label: t.name })) || []
  )
}

export const getSubComponentOptions = (
  eventTypes: Array<GandalfApi.EventTypes>,
  equipment: string | null,
  component: string | null,
) => {
  if (equipment === null || component === null) {
    return []
  }
  return (
    eventTypes
      .find((t) => t.name === equipment)
      ?.eventTypes?.find((t) => t.name === component)
      ?.eventTypes?.map((t) => ({ value: t.name, label: t.name })) || []
  )
}

export const getEventTypeId = (
  eventTypes: Array<GandalfApi.EventTypes>,
  equipment: string | null,
  component: string | null,
  subComponent: string | null,
) => {
  return eventTypes
    ?.find((t) => t.name === equipment)
    ?.eventTypes?.find((t) => t.name === component)
    ?.eventTypes?.find((t) => t.name === subComponent)?.id
}
