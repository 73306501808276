import React from 'react'

import { isFeatureEnabled } from '../utils'

export interface WithFeatureTogglingProps {
  [key: string]: any
  featureId?: string
}

const withFeatureToggling = (
  WrappedComponent: React.ComponentClass<any> | React.FC<any>,
) => {
  return class extends React.Component<WithFeatureTogglingProps, any> {
    render() {
      const { featureId, ...restProps } = this.props
      const featureEnabled = !featureId ? true : isFeatureEnabled(featureId)
      switch (true) {
        case featureEnabled:
          return <WrappedComponent {...restProps} />
        default:
          return null
      }
    }
  }
}

export default withFeatureToggling
