import { LUBE_OIL_TYPE_NAME } from '../constants'
import {
  ELubeOilContainer,
  ELubeOilType,
  ESoundingType,
  type ISoundingFormValues,
  TLubeOilSounding,
  type TLubeOilSoundingItem,
} from '../types'

export const OIL_TYPE_TO_FORM_NAME = {
  [LUBE_OIL_TYPE_NAME[ELubeOilType.AE]]: 'ae',
  [LUBE_OIL_TYPE_NAME[ELubeOilType.CLO]]: 'clo',
  [LUBE_OIL_TYPE_NAME[ELubeOilType.CLOAdditive]]: 'cloAdditive',
  [LUBE_OIL_TYPE_NAME[ELubeOilType.ME]]: 'me',
  [LUBE_OIL_TYPE_NAME[ELubeOilType.Other]]: 'other',
}

const getItems = (
  container: ELubeOilContainer,
  oilType: ELubeOilType,
  soundingItems?: Array<TLubeOilSoundingItem>,
  twinContainer?: ELubeOilContainer,
) => {
  const items = (soundingItems || []).filter(
    (item) =>
      item.oilType === oilType &&
      [container, twinContainer].includes(item.oilContainer),
  )
  if (!items.length) {
    items.push(getEmptyItem(container, oilType))
  }
  if (twinContainer && items.length === 1) {
    items.push(getEmptyItem(twinContainer, oilType))
  }
  return items
}

export const getEmptyItem = (
  container: ELubeOilContainer,
  oilType: ELubeOilType,
) => ({
  description: '',
  oilContainer: container,
  oilType,
  rob: null,
  tbn: null,
  unpumpable: null,
})

export const getInitialValues = (
  hasTwoMainEngines: boolean,
  data?: TLubeOilSounding,
): ISoundingFormValues => ({
  ae: getItems(ELubeOilContainer.SpareClean, ELubeOilType.AE, data?.items),
  clo: [
    ...getItems(ELubeOilContainer.SpareClean, ELubeOilType.CLO, data?.items),
    ...getItems(
      ELubeOilContainer.DayTank,
      ELubeOilType.CLO,
      data?.items,
      hasTwoMainEngines ? ELubeOilContainer.DayTankStbd : undefined,
    ),
  ],
  cloAdditive: getItems(
    ELubeOilContainer.SpareClean,
    ELubeOilType.CLOAdditive,
    data?.items,
  ),
  me: [
    ...getItems(ELubeOilContainer.SpareClean, ELubeOilType.ME, data?.items),
    ...getItems(
      ELubeOilContainer.Working,
      ELubeOilType.ME,
      data?.items,
      hasTwoMainEngines ? ELubeOilContainer.WorkingStbd : undefined,
    ),
    ...getItems(ELubeOilContainer.SpareDirty, ELubeOilType.ME, data?.items),
  ],
  notes: data?.notes || '',
  other: getItems(
    ELubeOilContainer.SpareClean,
    ELubeOilType.Other,
    data?.items,
  ),
  timestamp: data?.timestamp ?? null,
  type: data?.isPartial ? ESoundingType.Partial : ESoundingType.Full,
})
