import styled, { IColorScheme, IThemeInterface } from '../../theme'
import { media, sm, md, lg } from '../../theme/sizes'

interface NotificationBarProps {
  colorScheme?: IColorScheme
  theme?: IThemeInterface
}

export const NotificationBar = styled.div<NotificationBarProps>`
  background-color: ${(props) =>
    props.colorScheme ? props.colorScheme.fill : props.theme.bg};
  color: ${(props) =>
    props.colorScheme ? props.colorScheme.font : props.theme.font};
  font-size: 13px;
  border-bottom: 1px solid
    ${(props) =>
      props.colorScheme ? props.colorScheme.stroke : props.theme.border};
  padding: 4px 16px;
  z-index: 2;
  position: sticky;
  ${media.sm`top: ${sm.BIG_BAR_HEIGHT}`};
  ${media.md`top: ${md.BIG_BAR_HEIGHT}`};
  ${media.lg`top: ${lg.BIG_BAR_HEIGHT}`};
`
