import { useContext, useMemo, useState } from 'react'
import {
  McButton,
  McIcon,
  McInput,
  McList,
  McListItem,
  McMenu,
  McOption,
  McSelect,
} from '@maersk-global/mds-react-wrapper'

import {
  getInitialState,
  groupingOptions,
  groupings,
} from './ManageFavouritesPage.utils'
import { Wrapper } from './ManageFavouritesPage.styles'
import {
  useDeleteFavourite,
  useFavourites,
  useSaveFavourite,
} from '../../queries/ManagementApi/ManagementApi'
import { getFavouriteLists } from '../../queries/ManagementApi/ManagementApi.utils'
import { useVesselsBasicInfo } from '../../queries/MasterDataApi/MasterDataApi'
import { mapVesselMasterDataToVesselRegistry } from '../../queries/MasterDataApi/MasterDataApi.utils'
import { debounce, searchUserList } from '../../utils'
import { type Management } from '../../api-models/management'
import { ContentCard, Loading } from '../../commons'
import { ContentLayout } from '../../layout'
import { FavoriteTree } from '../../features/manage-favorites'
import { AppContext } from '../../contexts'

const ManageFavouritesPage = () => {
  const vesselsBasicInfo = useVesselsBasicInfo()
  const favourites = useFavourites()
  const saveFavourite = useSaveFavourite()
  const deleteFavourite = useDeleteFavourite()

  const appContext = useContext(AppContext)
  const [state, setState] = useState(getInitialState)

  const vesselList = useMemo(
    () => mapVesselMasterDataToVesselRegistry(vesselsBasicInfo.data ?? []),
    [vesselsBasicInfo.data],
  )

  const filteredList = useMemo(
    () => searchUserList(state.filter, vesselList),
    [state.filter, vesselList],
  )

  const imoNos = useMemo(() => {
    if (!appContext.imoNos || appContext.imoNos.length === 0) return undefined
    return new Set(appContext.imoNos)
  }, [appContext.imoNos])

  const favouriteLists = useMemo(() => {
    if (!favourites.isSuccess || !vesselsBasicInfo.isSuccess) {
      return []
    }

    return getFavouriteLists(favourites.data, vesselsBasicInfo.data)
  }, [favourites.isSuccess, vesselsBasicInfo.isSuccess])

  const saveVesselList = () => {
    setState({ ...state, isSaving: true })
    saveFavourite.mutate(
      {
        name: state.listName,
        imoNos: state.userList.map((vessel) => vessel.imoNo),
      },
      {
        onSettled: () => setState({ ...state, isSaving: false }),
      },
    )
  }

  const deleteUserList = () => {
    appContext.selectDefaultFavouriteList?.()
    setState((prevState) => ({
      ...prevState,
      listName: '',
      userList: [],
      selectedUserList: -1,
    }))
    deleteFavourite.mutate(state.listName)
  }

  const handleFilterChange = debounce((event: InputEvent) => {
    const target = event.target as HTMLInputElement
    setState({ ...state, filter: target.value })
  }, 300)

  if (favourites.isLoading || vesselsBasicInfo.isLoading) {
    return <Loading />
  }

  if (!vesselsBasicInfo.isSuccess || !imoNos || imoNos.size === 0) {
    return null
  }

  const listOptions = favouriteLists.map((list, index) => ({
    label: list.name,
    value: index.toString(),
  }))
  listOptions.unshift({ label: 'New list', value: '-1' })

  return (
    <ContentLayout header='Manage lists'>
      <Wrapper>
        <div id='list-selector'>
          {favouriteLists.length > 0 && (
            <McSelect
              hiddenlabel
              placeholder='Select an item'
              value={state.selectedUserList.toString()}
              optionselected={({ detail }: CustomEvent) => {
                const value = detail.value
                const favouriteListsIndex = parseInt(`${value}`)
                if (favouriteListsIndex === -1) {
                  setState({
                    ...state,
                    userList: [],
                    filter: '',
                    grouping: groupings[0],
                    groupingIndex: 0,
                    listName: '',
                    isSaving: false,
                    selectedUserList: -1,
                  })
                } else if (favouriteListsIndex < favouriteLists.length) {
                  setState({
                    ...state,
                    userList: favouriteLists[favouriteListsIndex].vessels,
                    listName: favouriteLists[favouriteListsIndex].name,
                    selectedUserList: favouriteListsIndex,
                  })
                }
              }}
            >
              {listOptions.map((option) => (
                <McOption key={option.value} value={option.value}>
                  {option.label}
                </McOption>
              ))}
            </McSelect>
          )}
        </div>
        <div id='vessels'>
          <ContentCard
            id='available-vessels'
            title='Available vessels'
            helpTextKey='manage-lists/available'
          >
            <div className='card-content'>
              <div className='controls'>
                <McInput
                  hiddenlabel
                  input={handleFilterChange}
                  trailingicon='magnifying-glass'
                />
                <McSelect
                  hiddenlabel
                  placeholder='Select a grouping'
                  value={state.groupingIndex.toString()}
                  optionselected={({ detail }: CustomEvent) => {
                    const value = detail.value
                    if (!isNaN(value)) {
                      setState({
                        ...state,
                        grouping: groupings[value],
                        groupingIndex: value,
                      })
                    }
                  }}
                >
                  {groupingOptions.map((option) => (
                    <McOption key={option.value} value={option.value}>
                      {option.label}
                    </McOption>
                  ))}
                </McSelect>
              </div>
              <FavoriteTree
                imoNos={imoNos}
                list={filteredList}
                treeName='available-vessels'
                allOpen={!!state.filter}
                isDeselect={false}
                selectedList={state.userList}
                primaryKey={state.grouping.primaryKey}
                secondaryKey={state.grouping.secondaryKey}
                onClick={(vessels) => {
                  const selectedVessels = state.userList.slice()
                  vessels.forEach((vessel) => {
                    if (
                      !selectedVessels.some(
                        (selectedVessel) =>
                          selectedVessel.imoNo === vessel.imoNo,
                      )
                    )
                      selectedVessels.push(vessel)
                  })
                  setState({
                    ...state,
                    userList: selectedVessels,
                  })
                }}
              />
            </div>
          </ContentCard>
        </div>
        <div id='list'>
          <ContentCard
            id='my-list'
            title='My list'
            helpTextKey='manage-lists/my-list'
            additionalInfo={
              favouriteLists.length > 0 &&
              state.selectedUserList !== -1 && (
                <McMenu position='bottom-center'>
                  <McIcon slot='trigger' icon='ellipsis-horizontal' size='24' />
                  <McList>
                    <McListItem
                      label='Delete list'
                      onClick={deleteUserList}
                      icon='trash'
                    />
                  </McList>
                </McMenu>
              )
            }
          >
            <div className='card-content'>
              <form
                className='controls'
                onSubmit={(event) => {
                  event.preventDefault()
                  event.stopPropagation()
                  if (state.userList.length === 0 || !state.listName) {
                    setState({ ...state, saveError: true })
                  } else {
                    saveVesselList()
                  }
                }}
              >
                <McInput
                  id='list-name-input'
                  labelposition='left'
                  label='List name'
                  value={state.listName}
                  invalid={state.saveError}
                  errormessage='Please give the list a name and add at least one vessel'
                  input={(event) => {
                    const target = event.target as HTMLInputElement
                    setState({ ...state, listName: target.value })
                  }}
                />
                <McButton type='submit' loading={state.isSaving} label='Save' />
              </form>
              <FavoriteTree
                imoNos={imoNos}
                list={state.userList}
                treeName={state.listName}
                isDeselect={true}
                allOpen
                primaryKey={state.grouping.primaryKey}
                secondaryKey={state.grouping.secondaryKey}
                onClick={(vessels) => {
                  const output = [] as Management.Vessel.VesselRegistry[]
                  state.userList.forEach((vessel) => {
                    if (
                      !vessels.some(
                        (removeVessel) => removeVessel.imoNo === vessel.imoNo,
                      )
                    ) {
                      output.push(vessel)
                    }
                  })
                  setState({
                    ...state,
                    userList: output,
                  })
                }}
              />
            </div>
          </ContentCard>
        </div>
      </Wrapper>
    </ContentLayout>
  )
}

export default ManageFavouritesPage
