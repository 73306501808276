import { useField } from 'formik'
import { McRadio, McRadioGroup } from '@maersk-global/mds-react-wrapper'

type Props = {
  name: string
  id: string
  legend?: string
  hint?: string
  options: Array<{
    value: string | number
    label: string
  }>
  onChange?: (value: number | string) => void
}

/**
 * @description A thin wrapper for RadioAlt that provides two-way data binding
 * for Formik form values based on `name` prop
 */
export const FormRadioAlt = (props: Props) => {
  const [field, meta, helpers] = useField(props.name)
  const { options, onChange, ...rest } = props

  const handleChange = (v: string | number) => (_: Event) => {
    /*
     * The McRadioGroup component only works with string options, so we need to
     * determine if given options are numbers or strings and cast selected
     * option to number if needed.
     */
    const areOptionsNumeric = typeof options[0].value === 'number'
    const value = areOptionsNumeric ? +v : v

    onChange?.(value)
    void helpers.setValue(value)
  }

  return (
    <McRadioGroup
      {...rest}
      invalid={meta.touched && !!meta.error}
      errormessage={meta.touched ? meta.error : undefined}
      orientation='horizontal'
    >
      {options.map((option) => (
        <McRadio
          key={option.value}
          name={props.name}
          value={String(option.value)}
          label={option.label}
          checked={field.value === option.value}
          change={handleChange(option.value)}
          data-e2e={option.label}
        />
      ))}
    </McRadioGroup>
  )
}
