import * as React from 'react'
import {
  allVesselsSelected,
  vesselSelected,
  mapVesselsToTree,
  VesselsTree,
} from '../mapper'
import TreeCollapse from './TreeCollapse'
import VesselItem from './VesselItem'
import { FavoriteVessel } from '../models'

export interface FavoriteTreeProps {
  imoNos: Set<string>
  list: Array<FavoriteVessel>
  isDeselect: boolean
  onClick: (vessels: Array<FavoriteVessel>) => void
  primaryKey: string
  secondaryKey: string
  allOpen?: boolean
  selectedList?: Array<FavoriteVessel>
  treeName?: string
}

export interface FavoriteTreeState {
  vesselTree: VesselsTree
  selectedVesselTree: VesselsTree | null
}

export class FavoriteTree extends React.Component<
  FavoriteTreeProps,
  FavoriteTreeState
> {
  constructor(props: FavoriteTreeProps) {
    super(props)
    const { list, primaryKey, secondaryKey, selectedList } = props
    this.state = {
      vesselTree: mapVesselsToTree(list, primaryKey, secondaryKey),
      selectedVesselTree: selectedList
        ? mapVesselsToTree(selectedList, primaryKey, secondaryKey)
        : null,
    }
  }

  componentDidUpdate(prevProps: FavoriteTreeProps) {
    const { list, primaryKey, secondaryKey, selectedList, treeName } =
      this.props
    switch (true) {
      case prevProps.treeName !== treeName:
      case prevProps.primaryKey !== primaryKey:
      case prevProps.secondaryKey !== secondaryKey:
      case prevProps.list.length !== list.length:
      case prevProps.selectedList &&
        selectedList &&
        prevProps.selectedList.length !== selectedList.length:
        this.setState({
          vesselTree: mapVesselsToTree(list, primaryKey, secondaryKey),
          selectedVesselTree: selectedList
            ? mapVesselsToTree(selectedList, primaryKey, secondaryKey)
            : null,
        })
        break
      default:
        break
    }
  }

  render() {
    const { isDeselect, onClick, allOpen, treeName, imoNos } = this.props
    const { vesselTree, selectedVesselTree } = this.state
    return (
      <div>
        {Object.keys(vesselTree).map((primaryKey) => (
          <TreeCollapse
            key={primaryKey}
            name={`${treeName}-${primaryKey}`}
            title={primaryKey}
            isDeselect={isDeselect}
            isOpened={allOpen}
            level='primary'
            isSelected={allVesselsSelected(
              vesselTree,
              selectedVesselTree,
              primaryKey,
            )}
            onClick={() => {
              let vessels = [] as FavoriteVessel[]
              Object.keys(vesselTree[primaryKey]).forEach((secondaryKey) => {
                vessels = [
                  ...vessels,
                  ...vesselTree[primaryKey][secondaryKey].vessels,
                ]
              })
              onClick(vessels)
            }}
          >
            {Object.keys(vesselTree[primaryKey]).map((secondaryKey) => (
              <TreeCollapse
                key={secondaryKey}
                title={secondaryKey}
                name={`${treeName}-${primaryKey}-${secondaryKey}`}
                isDeselect={isDeselect}
                isOpened={allOpen}
                level='secondary'
                isSelected={allVesselsSelected(
                  vesselTree,
                  selectedVesselTree,
                  primaryKey,
                  secondaryKey,
                )}
                onClick={() => {
                  onClick(vesselTree[primaryKey][secondaryKey].vessels)
                }}
              >
                {vesselTree[primaryKey][secondaryKey].vessels.map((vessel) => (
                  <VesselItem
                    key={vessel.imoNo}
                    imoNos={imoNos}
                    imoNo={`${vessel.imoNo}`}
                    name={vessel.vesselName}
                    isSelected={vesselSelected(
                      selectedVesselTree,
                      vessel,
                      primaryKey,
                      secondaryKey,
                    )}
                    isDeselect={isDeselect}
                    onClick={() => onClick([vessel])}
                  />
                ))}
              </TreeCollapse>
            ))}
          </TreeCollapse>
        ))}
      </div>
    )
  }
}
