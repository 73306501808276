import { createColumnHelper } from '@tanstack/react-table'
import { McTooltip } from '@maersk-global/mds-react-wrapper'

import { type Consumer } from '../../../../api-models/performance/counter'
import { ELECTRICAL_CONSUMER_NAMES } from '../../../../utils/constants'
import {
  NumericHeader,
  NumericTooltipCell,
} from '../../../../components/Table/Table.styles'
import { formatValue, numberOfMinutesToTime } from '../../../../utils'

const columnHelper = createColumnHelper<Consumer>()

export const columns = [
  columnHelper.accessor('type', {
    header: 'Electrical consumer',
    cell: (props) => ELECTRICAL_CONSUMER_NAMES[props.getValue()],
    enableSorting: false,
  }),
  columnHelper.accessor('energy', {
    header: () => <NumericHeader>Energy consumption (kWh)</NumericHeader>,
    cell: (props) => (
      <McTooltip position='top-center' appearance='inverse'>
        <NumericTooltipCell slot='trigger'>
          {formatValue(props.getValue(), 0)}
        </NumericTooltipCell>
        <span>
          Data was available for <b>{props.row.original.dataAvailability}%</b>{' '}
          of the period
        </span>
      </McTooltip>
    ),
    footer: ({ table }) => {
      const rows = table.getCoreRowModel().rows

      if (rows.length <= 1) return null

      const availability = Math.min(
        ...rows.map((row) => row.original.dataAvailability),
      )

      return (
        <McTooltip position='top-center' appearance='inverse'>
          <NumericTooltipCell boldFont slot='trigger'>
            {rows.reduce((acc, curr) => acc + curr.original.energy, 0)}
          </NumericTooltipCell>
          <span>
            Data was available for <b>{availability}%</b> of the period
          </span>
        </McTooltip>
      )
    },
    enableSorting: false,
  }),
  columnHelper.accessor('runningMinutes', {
    header: () => <NumericHeader>Running hours (hh:mm)</NumericHeader>,
    cell: (props) => (
      <McTooltip position='top-center' appearance='inverse'>
        <NumericTooltipCell slot='trigger'>
          {numberOfMinutesToTime(props.getValue())}
        </NumericTooltipCell>
        <span>
          Data was available for <b>{props.row.original.dataAvailability}%</b>{' '}
          of the period
        </span>
      </McTooltip>
    ),
    enableSorting: false,
  }),
]
