import { Hydra } from '../../api-models/hydra'
import {
  EnergyManagementSimulatorData,
  AuxEngParams,
  MeOperationalMode,
  TEMConfiguration,
} from './models'
import { Performance } from '../../api-models'
import { formatValue } from '../../utils'

export enum TEMResults {
  WHR,
  AE_1,
  AE_2,
  AE_3,
  AE_4,
  AE_5,
  AE_TOTAL_POWER,
  SHAFT_GENERATOR,
  SHAFT_MOTOR,
  MAIN_ENGINE_POWER,
  PROPULSION_POWER,
}

const mapInitialValues = (
  vesselConfiguration: Performance.VesselConfiguration.Configuration,
  auxEngParams: AuxEngParams,
  temConfiguration: TEMConfiguration,
) => [
  ...(vesselConfiguration.whr.isInstalled
    ? [
        {
          name: 'WHR',
          value: '0',
          visible: vesselConfiguration.whr.isInstalled,
        },
      ]
    : []),

  ...(auxEngParams.installedEngines.has(1)
    ? [
        {
          name: 'AE 1',
          value: '0',
          visible: auxEngParams.installedEngines.has(1),
        },
      ]
    : []),
  ...(auxEngParams.installedEngines.has(2)
    ? [
        {
          name: 'AE 2',
          value: '0',
          visible: auxEngParams.installedEngines.has(2),
        },
      ]
    : []),

  ...(auxEngParams.installedEngines.has(3)
    ? [
        {
          name: 'AE 3',
          value: '0',
          visible: auxEngParams.installedEngines.has(3),
        },
      ]
    : []),

  ...(auxEngParams.installedEngines.has(4)
    ? [
        {
          name: 'AE 4',
          value: '0',
          visible: auxEngParams.installedEngines.has(4),
        },
      ]
    : []),
  ...(auxEngParams.installedEngines.has(5)
    ? [
        {
          name: 'AE 5',
          value: '0',
          visible: auxEngParams.installedEngines.has(5),
        },
      ]
    : []),
  {
    name: 'AE total power',
    value: '0',
    visible: true,
  },
  ...(vesselConfiguration.shaftMotor1.isInstalled
    ? [
        {
          name: 'Shaft motor',
          value: '0',
          visible: vesselConfiguration.shaftMotor1.isInstalled,
        },
      ]
    : []),
  ...(vesselConfiguration.shaftGenerator1.isInstalled
    ? [
        {
          name: 'Shaft generator',
          value: '0',
          visible: vesselConfiguration.shaftGenerator1.isInstalled,
        },
      ]
    : []),
  {
    name: 'Main engine power',
    value: '0',
    visible: true,
  },
  ...(temConfiguration === TEMConfiguration.BOTH
    ? [
        {
          name: 'Propulsion power',
          value: '0',
          visible: true,
        },
      ]
    : []),
]

export const mapEnergyManagementResponse = (
  vesselConfiguration: Performance.VesselConfiguration.Configuration,
  auxEngParams: AuxEngParams,
  temConfiguration: TEMConfiguration,
  data?: Hydra.TEM.EnergyManagement,
): EnergyManagementSimulatorData[] => {
  if (!data) {
    return mapInitialValues(vesselConfiguration, auxEngParams, temConfiguration)
  }
  return [
    ...(vesselConfiguration.whr.isInstalled
      ? [
          {
            name: 'WHR',
            value: formatValue(data.whr_power, 0),
            visible: vesselConfiguration.whr.isInstalled,
          },
        ]
      : []),

    ...(auxEngParams.installedEngines.has(1)
      ? [
          {
            name: 'AE 1',
            value: formatValue(data.aux_engines.ae1_power, 0),
            visible: auxEngParams.installedEngines.has(1),
          },
        ]
      : []),
    ...(auxEngParams.installedEngines.has(2)
      ? [
          {
            name: 'AE 2',
            value: formatValue(data.aux_engines.ae2_power, 0),
            visible: auxEngParams.installedEngines.has(2),
          },
        ]
      : []),

    ...(auxEngParams.installedEngines.has(3)
      ? [
          {
            name: 'AE 3',
            value: formatValue(data.aux_engines.ae3_power, 0),
            visible: auxEngParams.installedEngines.has(3),
          },
        ]
      : []),

    ...(auxEngParams.installedEngines.has(4)
      ? [
          {
            name: 'AE 4',
            value: formatValue(data.aux_engines.ae4_power, 0),
            visible: auxEngParams.installedEngines.has(4),
          },
        ]
      : []),
    ...(auxEngParams.installedEngines.has(5)
      ? [
          {
            name: 'AE 5',
            value: formatValue(data.aux_engines.ae5_power, 0),
            visible: auxEngParams.installedEngines.has(5),
          },
        ]
      : []),
    {
      name: 'AE total power',
      value: formatValue(data.aux_engines.ae_power, 0),
      visible: true,
    },
    ...(vesselConfiguration.shaftMotor1.isInstalled
      ? [
          {
            name: 'Shaft motor',
            value: formatValue(data.sm_electrical_power, 0),
            visible: vesselConfiguration.shaftMotor1.isInstalled,
          },
        ]
      : []),
    ...(vesselConfiguration.shaftGenerator1.isInstalled
      ? [
          {
            name: 'Shaft generator',
            value: formatValue(data.sg_electrical_power, 0),
            visible: vesselConfiguration.shaftGenerator1.isInstalled,
          },
        ]
      : []),
    {
      name: 'Main engine power',
      value: formatValue(data.me_power, 0),
      visible: true,
    },
    ...(temConfiguration === TEMConfiguration.BOTH
      ? [
          {
            name: 'Propulsion power',
            value: formatValue(data.propulsion_power, 0),
            visible: true,
          },
        ]
      : []),
  ]
}

export function mapMeOperationalModeToString(
  operationalMode: MeOperationalMode,
): string {
  switch (operationalMode) {
    case MeOperationalMode.FIXED_POWER:
      return 'FixedPower'
    case MeOperationalMode.VARIABLE_POWER:
      return 'VariablePower'
  }
}
