import React from 'react'

interface AccordionSectionProps {
  onClick: Function
  label: string
  isOpen: boolean
  children: any
  underscore?: boolean
}

class AccordionSection extends React.Component<AccordionSectionProps> {
  onClick = () => {
    this.props.onClick(this.props.label)
  }

  render() {
    const {
      onClick,
      props: { isOpen, label, underscore },
    } = this

    return (
      <div
        style={{
          padding: '5px 10px',
          background: underscore ? '#f1f2f3' : 'inherit',
          border: underscore ? '1px solid #d1d3d5' : 'none',
          borderRadius: '3px',
          marginBottom: '4px',
        }}
      >
        <div
          onClick={onClick}
          style={{
            cursor: 'pointer',
            color: '#003E5E',
          }}
        >
          {label}
          <div style={{ float: 'left', marginRight: '4px', marginTop: '-1px' }}>
            {!isOpen && <span style={{ fontSize: '11px' }}>&#9658;</span>}
            {isOpen && <span style={{ fontSize: '11px' }}>&#9660;</span>}
          </div>
        </div>
        {isOpen && (
          <div
            style={{
              marginTop: 10,
              padding: '10px 20px',
            }}
          >
            {this.props.children}
          </div>
        )}
      </div>
    )
  }
}

export default AccordionSection
