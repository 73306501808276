import { useContext } from 'react'

import RangeValidationTag from './RangeValidationTag'
import { TableContainer } from '../GeneralTab/GenaralTab.styles'
import { formatValue } from '../../../../utils'
import { WindowContext } from '../../../../contexts'
import { MetcDataFormat } from '../../../../queries/GandalfApi/GandalfApi.consts'

type Props = {
  tcEfficiency: GandalfApi.Metc.TcEfficiencyResults
  dataFormat: number
}

const TurbochargerEfficiency = ({ tcEfficiency, dataFormat }: Props) => {
  const { windowSize } = useContext(WindowContext)

  return (
    <TableContainer className={`mds-table mds-table--${windowSize}`}>
      <table>
        <thead>
          <tr>
            <th>Turbocharger Efficiency</th>
            <th>Adjusted to ISO Conditions</th>
            <th>Model</th>
            <th>Deviation to model</th>
            <th className='mds-table__cell--text-center'>Validation</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Scavenging Air Pressure</td>
            <td>
              {formatValue(
                tcEfficiency.scavAirPres.isoConditionAdjustedValue,
                2,
              )}{' '}
              bar
            </td>
            <td>{formatValue(tcEfficiency.scavAirPres.modelValue, 2)} bar</td>
            <td>{formatValue(tcEfficiency.scavAirPres.modelDeviation, 2)} %</td>
            <td className='mds-table__cell--text-center'>
              <RangeValidationTag
                rangeValidation={tcEfficiency.scavAirPres.rangeValidation}
              />
            </td>
          </tr>
          <tr>
            <td>Temperature before TC</td>
            <td>
              {formatValue(
                tcEfficiency.tempBeforeTc.isoConditionAdjustedValue,
                1,
              )}{' '}
              °C
            </td>
            <td>{formatValue(tcEfficiency.tempBeforeTc.modelValue, 1)} °C</td>
            <td>
              {formatValue(tcEfficiency.tempBeforeTc.modelDeviation, 2)} %
            </td>
            <td className='mds-table__cell--text-center'>
              <RangeValidationTag
                rangeValidation={tcEfficiency.tempBeforeTc.rangeValidation}
              />
            </td>
          </tr>
          {dataFormat >= MetcDataFormat.physicalCondition && (
            <tr>
              <td>Air Filter Pressure Drop</td>
              <td>
                {formatValue(
                  tcEfficiency.airFilterPresDrop.isoConditionAdjustedValue,
                  1,
                )}{' '}
                mmH2O
              </td>
              <td>
                {formatValue(tcEfficiency.airFilterPresDrop.modelValue, 1)}{' '}
                mmH2O
              </td>
              <td>
                {formatValue(tcEfficiency.airFilterPresDrop.modelDeviation, 2)}{' '}
                %
              </td>
              <td className='mds-table__cell--text-center'>
                <RangeValidationTag
                  rangeValidation={
                    tcEfficiency.airFilterPresDrop.rangeValidation
                  }
                />
              </td>
            </tr>
          )}
          {dataFormat >= MetcDataFormat.physicalCondition && (
            <tr>
              <td>Air Cooler Pressure Drop</td>
              <td>
                {formatValue(
                  tcEfficiency.airCoolerPresDrop.isoConditionAdjustedValue,
                  1,
                )}{' '}
                mmH2O
              </td>
              <td>
                {formatValue(tcEfficiency.airCoolerPresDrop.modelValue, 1)}{' '}
                mmH2O
              </td>
              <td>
                {formatValue(tcEfficiency.airCoolerPresDrop.modelDeviation, 2)}{' '}
                %
              </td>
              <td className='mds-table__cell--text-center'>
                <RangeValidationTag
                  rangeValidation={
                    tcEfficiency.airCoolerPresDrop.rangeValidation
                  }
                />
              </td>
            </tr>
          )}
          <tr>
            <td>TC RPM</td>
            <td>
              {formatValue(tcEfficiency.tcSpeed.isoConditionAdjustedValue, 0)}
            </td>
            <td>{formatValue(tcEfficiency.tcSpeed.modelValue, 0)}</td>
            <td>{formatValue(tcEfficiency.tcSpeed.modelDeviation, 2)} %</td>
            <td className='mds-table__cell--text-center'>
              <RangeValidationTag
                rangeValidation={tcEfficiency.tcSpeed.rangeValidation}
              />
            </td>
          </tr>
          <tr>
            <td>Total Efficiency</td>
            <td>
              {formatValue(
                tcEfficiency.totalEfficiency.isoConditionAdjustedValue,
                1,
              )}{' '}
              %
            </td>
            <td>-</td>
            <td>-</td>
            <td className='mds-table__cell--text-center'>
              <RangeValidationTag
                rangeValidation={tcEfficiency.totalEfficiency.rangeValidation}
              />
            </td>
          </tr>
          <tr>
            <td>Compressor Efficiency</td>
            <td>
              {formatValue(
                tcEfficiency.compressorEfficiency.isoConditionAdjustedValue,
                1,
              )}{' '}
              %
            </td>
            <td>-</td>
            <td>-</td>
            <td className='mds-table__cell--text-center'>
              <RangeValidationTag
                rangeValidation={
                  tcEfficiency.compressorEfficiency.rangeValidation
                }
              />
            </td>
          </tr>
          <tr>
            <td>Turbine Efficiency</td>
            <td>
              {formatValue(
                tcEfficiency.turbineEfficiency.isoConditionAdjustedValue,
                1,
              )}{' '}
              %
            </td>
            <td>-</td>
            <td>-</td>
            <td className='mds-table__cell--text-center'>
              <RangeValidationTag
                rangeValidation={tcEfficiency.turbineEfficiency.rangeValidation}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </TableContainer>
  )
}

export default TurbochargerEfficiency
