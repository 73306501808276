import * as yup from 'yup'
import { ELubeOilContainer, ELubeOilType } from '../types'
import {
  getQuantityValidationSchema,
  REQUIRED_MESSAGE,
  timestampValidationSchema,
} from './utils'

const MAX_QUANTITY = 150000
const MIN_QUANTITY = 1

const transferValidationSchema = yup.object().shape({
  fromOilContainer: yup
    .number()
    .nullable()
    .required(REQUIRED_MESSAGE)
    .when('oilType', {
      is: ELubeOilType.ME,
      then: yup
        .number()
        .oneOf(
          [
            ELubeOilContainer.SpareClean,
            ELubeOilContainer.SpareDirty,
            ELubeOilContainer.Working,
            ELubeOilContainer.WorkingStbd,
            null,
          ],
          'Not applicable for oil type ME',
        ),
      otherwise: yup
        .number()
        .oneOf(
          [ELubeOilContainer.SpareClean, null],
          'Not applicable for oil type AE',
        ),
    }),
  oilType: yup
    .number()
    .nullable()
    .required(REQUIRED_MESSAGE)
    .oneOf([ELubeOilType.AE, ELubeOilType.ME, null], 'Not applicable'),
  quantity: getQuantityValidationSchema(MIN_QUANTITY, MAX_QUANTITY),
  timestamp: timestampValidationSchema,
  toOilContainer: yup
    .number()
    .nullable()
    .required(REQUIRED_MESSAGE)
    .when('oilType', {
      is: ELubeOilType.ME,
      then: yup
        .number()
        .when('fromOilContainer', {
          is: ELubeOilContainer.SpareClean,
          then: yup
            .number()
            .oneOf(
              [
                ELubeOilContainer.Working,
                ELubeOilContainer.WorkingStbd,
                ELubeOilContainer.BOB,
                null,
              ],
              'Not applicable for Spare Clean and oil type ME',
            ),
        })
        .when('fromOilContainer', {
          is: ELubeOilContainer.SpareDirty,
          then: yup
            .number()
            .oneOf(
              [
                ELubeOilContainer.Working,
                ELubeOilContainer.WorkingStbd,
                ELubeOilContainer.BOB,
                null,
              ],
              'Not applicable for Spare Dirty and oil type ME',
            ),
        })
        .when('fromOilContainer', {
          is: (value) =>
            [ELubeOilContainer.Working, ELubeOilContainer.WorkingStbd].includes(
              value,
            ),
          then: yup
            .number()
            .oneOf(
              [ELubeOilContainer.SpareDirty, ELubeOilContainer.BOB, null],
              'Not applicable for Working and oil type ME',
            ),
        }),
      otherwise: yup
        .number()
        .oneOf(
          [
            ELubeOilContainer.AE1,
            ELubeOilContainer.AE2,
            ELubeOilContainer.AE3,
            ELubeOilContainer.AE4,
            ELubeOilContainer.AE5,
            null,
          ],
          'Not applicable for oil type AE',
        ),
    }),
})

export default transferValidationSchema
