import { useContext } from 'react'
import { type Table } from '@tanstack/react-table'
import { McSelectNative } from '@maersk-global/mds-react-wrapper'

import { WindowContext } from '../../../../contexts'
import { UserEntryTypes } from '../../../../features/stock-management'
import { ELubeOilEntryType } from '../../../lube-oil/types'
import { getEntryTypeFilterOptions } from './helpers'

type Props = {
  table: Table<any>
  availableEntryTypes: { [k: number]: string }
  allowedEntryTypes?: Array<UserEntryTypes | ELubeOilEntryType>
  columnId: string
  isStringValues?: boolean
  label: string
}

const EntryTypeFilter = (props: Props) => {
  const { windowSize } = useContext(WindowContext)

  const handleTypeChange = (event: CustomEvent) => {
    const name = (event.target as HTMLSelectElement).name
    const column = props.table.getColumn(name)
    if (event.detail.selectedOptions.length === 0) {
      column!.setFilterValue(undefined)
    } else {
      if (props.isStringValues) {
        column!.setFilterValue(event.detail.selectedOptions[0].value)
      } else {
        column!.setFilterValue(+event.detail.selectedOptions[0].value)
      }
    }
  }

  return (
    <McSelectNative
      className='select-filter'
      fit={windowSize}
      name={props.columnId}
      label={props.label}
      placeholder='All'
      options={getEntryTypeFilterOptions(
        props.availableEntryTypes,
        props.allowedEntryTypes,
      )}
      change={handleTypeChange}
      data-e2e='EntryType'
    />
  )
}

export default EntryTypeFilter
