import { QueryResponse } from './common'

export interface Monitor extends QueryResponse {
  timestamps: number[]
  consumption: number[]
  shaftRpm: number[]
  shaftPower: number[]
  trim: number[]
  sog: number[]
  stw: number[]
  waterDepth: number[]
  shallowWaterSeries: boolean[]
}
