import styled from 'styled-components'

import { type TWindowSize } from '../../../theme_new/styled'

export const ModalHeight: Record<keyof Diagnostics.FormData, string> = {
  operationalIssues: '80vh',
  scheduleMetc: '80vh',
  technicalIssues: '80vh',
}

export const ModalWidth: Record<TWindowSize, string> = {
  small: '1136px',
  medium: '1336px',
  large: '1536px',
}

export const Wrapper = styled.div`
  margin-bottom: -8px;
  width: 100%;
  align-self: center;
  overflow-y: hidden;

  mc-step-indicator {
    display: block;
    margin: 8px 0 24px;
    padding: 0 360px;
    @media (min-width: ${(props) => props.theme.breakPoints.medium}px) {
      padding: 0 410px;
    }
    @media (min-width: ${(props) => props.theme.breakPoints.large}px) {
      padding: 0 460px;
    }

    > mc-step-indicator-item {
      > span {
        white-space: nowrap;
      }

      &[state='current'] > span {
        font-weight: bold;
      }

      &[state='current']::part(marker),
      &[state='completed']::part(marker) {
        cursor: pointer;
      }

      &[state='completed'] {
        ::part(step)::after {
          background-color: var(
            --mds_brand_appearance_success_default_background-color
          );
        }

        ::part(marker) {
          background-color: var(
            --mds_brand_appearance_success_default_background-color
          );
          border-color: var(
            --mds_brand_appearance_success_default_background-color
          );
        }

        ::part(icon) {
          fill: var(--mds_brand_appearance_success_default_background-color);
        }
      }
    }
  }
`

export const SplitView = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  height: calc(80vh - 220px);
  @media (min-width: ${(props) => props.theme.breakPoints.medium}px) {
    height: calc(80vh - 240px);
  }
  @media (min-width: ${(props) => props.theme.breakPoints.large}px) {
    height: calc(80vh - 248px);
  }

  > div {
    flex-basis: 50%;
    overflow-y: scroll;
  }

  > div:first-of-type {
    padding: 0 24px 0 12px;
    @media (min-width: ${(props) => props.theme.breakPoints.medium}px) {
      padding: 0 32px 0 24px;
    }

    > p {
      margin-bottom: 24px;
      max-width: 752px;
    }
  }

  ::before {
    content: '';
    position: absolute;
    left: 50%;
    top: 0;
    bottom: 0;
    width: 1px;
    background-color: var(--mds_foundations_horizontal-rule_border-color);
  }
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  > p {
    font-weight: bold;
  }

  > hr {
    margin: 24px 0;
  }
`

export const CheckboxWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 24px;
  margin-top: 16px;

  > label {
    display: flex;

    > span {
      font-variant: tabular-nums;
      font-weight: 500;
      margin-right: 12px;

      &::after {
        content: '.';
      }
    }

    > div {
      max-width: 488px;
    }
  }

  > mc-checkbox {
    flex-shrink: 0;
    width: 152px;
    @media (min-width: ${(props) => props.theme.breakPoints.medium}px) {
      width: 184px;
    }
  }
`

export const DateTimeWrapper = styled.div`
  width: 100%;
  @media (min-width: ${(props) => props.theme.breakPoints.medium + 1}px) {
    width: 50%;
  }
  margin-top: 16px;
`

export const ErrorWrapper = styled.div`
  margin-left: 28px;
`
