import styled from 'styled-components'

export const McTagWrapper = styled.span`
  a {
    position: relative;
    float: right;
    cursor: pointer;
    bottom: -36px;
    @media (min-width: ${(props) => props.theme.breakPoints.medium}px) {
      bottom: -46px;
    }
    @media (min-width: ${(props) => props.theme.breakPoints.large}px) {
      bottom: -52px;
    }
  }
`
