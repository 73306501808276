import * as React from 'react'
import { getFrontendVersion } from '../../../utils'
import { AppContext } from '../../../contexts'
import { FormElements } from '../../../commons'

interface SystemInfoTableProps {
  imoNo?: string
  flowMeterScenario?: string
  vesselName?: string
}

export function SystemInfoTable(props: SystemInfoTableProps) {
  const appContext = React.useContext(AppContext)
  const frontendVersion = getFrontendVersion(
    props.imoNo as string,
    appContext.installations as any,
  )
  return (
    <FormElements.ReadonlyTable>
      <tbody>
        <tr>
          <td>
            <FormElements.Label>Vessel name</FormElements.Label>
          </td>
          <td style={{ whiteSpace: 'nowrap' }}>{props.vesselName}</td>
        </tr>
        <tr>
          <td>
            <FormElements.Label>IMO</FormElements.Label>
          </td>
          <td>{props.imoNo}</td>
        </tr>
        <tr>
          <td>
            <FormElements.Label>Flow meter scenario</FormElements.Label>
          </td>
          <td>{props.flowMeterScenario ? props.flowMeterScenario : 'N/A'}</td>
        </tr>
        <tr>
          <td>
            <FormElements.Label>Application version</FormElements.Label>
          </td>
          <td>{frontendVersion}</td>
        </tr>
      </tbody>
    </FormElements.ReadonlyTable>
  )
}
