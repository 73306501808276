import { useFormikContext } from 'formik'

import OperatingConditions from './OperatingConditions'
import AdjustedToIsoConditions from './AdjustedToIsoConditions'
import MainEngineEfficiency from './MainEngineEfficiency'
import TurbochargerEfficiency from './TurbochargerEfficiency'
import MetcTextareaField from '../Common/MetcTextArea'
import IsoCharts from './Charts/IsoCharts'
import TcEfficiencyCharts from './Charts/TcEfficiencyCharts'

type Props = {
  isActive: boolean
  dataFormat: number
}

const ResultsTab = ({ isActive, dataFormat }: Props) => {
  const { values } = useFormikContext<GandalfApi.MetcReport>()
  const { data } = values

  return (
    <div slot='panel'>
      <OperatingConditions
        general={data.general}
        equipment={data.equipment}
        result={data.result}
      />
      <AdjustedToIsoConditions
        general={data.general}
        adjustedIsoConditions={data.result.adjustedIsoConditions}
      />
      <IsoCharts
        isActive={isActive}
        general={data.general}
        equipment={data.equipment}
        result={data.result}
      />
      <MainEngineEfficiency meEfficiency={data.result.meEfficiency} />
      <TurbochargerEfficiency
        tcEfficiency={data.result.tcEfficiency}
        dataFormat={dataFormat}
      />
      <TcEfficiencyCharts
        isActive={isActive}
        general={data.general}
        equipment={data.equipment}
        result={data.result}
      />
      <MetcTextareaField label='Comment' name='data.comment' />
    </div>
  )
}

export default ResultsTab
