import { type Sensors } from '../../api-models/sensors'
import { getSensorApiUrl, doPost, doGet } from '../../utils'

export const getVersion = (): Promise<SensorStatusApi.Home.Version> =>
  doGet(`${getSensorApiUrl()}/Version`)

export const getSensorStatuses = (vesselImoNoList: string[]) =>
  doPost(`${getSensorApiUrl()}/Sensors/Status`, vesselImoNoList)

export const getSensorsStatus = (imoNo: number) =>
  doGet(`${getSensorApiUrl()}/Sensors/Status?imoNo=${imoNo}`)

export const getSensorsData = (imoNo: string, id: number) =>
  doGet(
    `${getSensorApiUrl()}/Sensors/Data?imoNo=${imoNo}&sensorId=${id}&hoursBackInTime=${168}`,
  )

export const getSystemDataPointGroups = (
  imoNo: string,
): Promise<Array<SensorStatusApi.System.DataPointGroup>> =>
  doGet(`${getSensorApiUrl()}/System/DataPointGroups?imoNo=${imoNo}`)

export const getCurrentErrors = (
  imoNo: string,
): Promise<Sensors.CurrentErrorsResponse> =>
  doGet(`${getSensorApiUrl()}/Sensors/CurrentErrors?imoNo=${imoNo}`)

export const getAllSensors = (
  imoNo: string,
): Promise<Sensors.AllSensorsResponse> =>
  doGet(`${getSensorApiUrl()}/Sensors/All?imoNo=${imoNo}`)
