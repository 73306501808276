import { Hydra } from '../../api-models/hydra'

export interface RunningModeStatus {
  hasData: boolean
  queryPeriod: Hydra.Period
  calculatedResults: {
    timestamp: number | null
    deviationFromPredictedMe1: number | null
    loadPercentageMe1: number | null
    deviationFromPredictedMe2: number | null
    loadPercentageMe2: number | null
  }
}
