import styled from 'styled-components'

import { Performance } from '../../api-models'
import { FuelConsumptionTable } from '.'
import { layoutSizes } from '../../theme'
import { ContentCard, Loading, NotFound } from '../../commons'
import { formatValue } from '../../utils'

const HeaderChildren = styled.div`
  color: ${(props) => props.theme.colors.grey[700]};
  font-size: 12px;
`

type Props = {
  batchEntries?: Performance.FuelOilStock.BatchResponse[]
  fuelConsumption?: Performance.Counter.FuelConsumption
  hasData?: boolean
  isLoading?: boolean
  numberOfDigits: number
  showBatchDetails: (batchId: string) => void
}

const FuelConsumptionOverview = ({
  batchEntries,
  fuelConsumption,
  hasData,
  isLoading,
  numberOfDigits,
  showBatchDetails,
}: Props) => {
  return (
    <ContentCard
      id='counter-fc'
      title='Fuel consumption'
      helpTextKey='counter/fuelconsumptiontable'
      headerChildren={
        <HeaderChildren>
          <div>
            {typeof fuelConsumption?.leakageMass === 'number' &&
              `ME leakage ${formatValue(
                fuelConsumption.leakageMass,
                fuelConsumption.leakageMass ? numberOfDigits : 0,
              )} MT`}
          </div>
          <div>
            {typeof fuelConsumption?.sludge?.total === 'number' &&
              `Sludge deducted from ROB: ${formatValue(
                fuelConsumption?.sludge?.total,
                fuelConsumption?.sludge?.total ? numberOfDigits : 0,
              )} MT`}
          </div>
        </HeaderChildren>
      }
      width={layoutSizes.full}
      contentPadding='16px'
    >
      {fuelConsumption && batchEntries && (
        <FuelConsumptionTable
          fuelConsumption={fuelConsumption}
          batchEntries={batchEntries}
          numberOfDigits={numberOfDigits}
          showBatchDetails={showBatchDetails}
        />
      )}
      {isLoading && <Loading />}
      {!isLoading && !hasData && (
        <NotFound text='No data found for selected period' />
      )}
    </ContentCard>
  )
}

export default FuelConsumptionOverview
