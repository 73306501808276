import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { getIn, useFormikContext } from 'formik'
import moment from 'moment'
import { McCheckbox } from '@maersk-global/mds-react-wrapper'

import { HDCContext } from '../../context'
import { WindowContext } from '../../../../contexts'
import { ApiReportType } from '../../../../api-models/hdc/report'
import InputField from '../../../../components/form/input-field'
import FormSection from '../../../../components/form/section'
import { UNITS } from '../../../../utils/constants'
import { SimpleFormRow } from '../../components/form-row'
import HDCInputField from '../../components/hdc-input-field'
import HDCTextareaField from '../../components/hdc-textarea-field'
import OverlayLoader from '../../components/overlay-loader'
import { FormInputDateTime, FormTypeahead } from '../../../../components/formik'
import { HDCReportFormValues } from '../../types'
import {
  calculateMeanDraught,
  convertMinutes,
  distanceAndSpeedObservations,
  flattenFormikErrors,
  getCalculatedRDPS,
  getFilteredByStepErrors,
  getFilteredByStepWarnings,
  isGSISCanalReport,
  isVoyageEditable,
  shouldCalculateRDPS,
} from '../../utils'
import Notifications from '../../../../components/Notifications/Notifications'
import { useTerminals } from '../../../../queries/MasterDataApi/MasterDataApi'
import { getTerminalOption } from '../reports.utils'

const VoyageStep = () => {
  const { windowSize } = useContext(WindowContext)
  const { errors, values, initialValues, isSubmitting, setFieldValue } =
    useFormikContext<HDCReportFormValues>()
  const { bridge, engineRoom } = values
  const {
    draughtAft,
    draughtFore,
    alternativeArrivalTerminal,
    alternativeDepartureTerminal,
  } = bridge
  const { mainEngines } = engineRoom

  const { data: terminals, isSuccess: isTerminalsSuccess } = useTerminals()

  const {
    state: HDCState,
    setFormUseCustomArrival,
    setFormUseCustomDeparture,
  } = useContext(HDCContext)

  const {
    form: { currentStep, steps, type, useCustomArrival, useCustomDeparture },
    report,
    reports,
    reportTimestamp,
    reportType,
  } = HDCState

  const [adjustRDPS, setAdjustRDPS] = useState(false)

  useEffect(() => {
    if (!!alternativeArrivalTerminal?.code) {
      setFormUseCustomArrival(true)
    }
  }, [alternativeArrivalTerminal, setFormUseCustomArrival])

  useEffect(() => {
    if (!!alternativeDepartureTerminal?.code) {
      setFormUseCustomDeparture(true)
    }
  }, [alternativeDepartureTerminal, setFormUseCustomDeparture])

  useEffect(() => {
    if (
      report?.bridge.remainingDistanceToPilotStation.value !==
      bridge.remainingDistanceToPilotStation.value
    ) {
      void setFieldValue(
        'bridge.remainingDistanceToPilotStation.isOverridden',
        true,
      )
    }
  }, [
    report?.bridge.remainingDistanceToPilotStation.value,
    bridge.remainingDistanceToPilotStation.value,
    setFieldValue,
  ])

  const activeTerminalOptions = useMemo(() => {
    if (!isTerminalsSuccess) return []
    return terminals.filter((t) => t.data.isActive).map(getTerminalOption)
  }, [terminals, isTerminalsSuccess])

  const destinationDescription = useMemo(() => {
    return reportType === ApiReportType.CANAL &&
      !isGSISCanalReport(report!, reports!)
      ? "Please enter Canal name. Select 'Canal not in list' if you cannot find the canal you need."
      : "Please enter the arrival port. You can search for port code or name in the port code field. Select 'Arrival port not in list' if you cannot find the port you need."
  }, [report, reportType, reports])

  const getMeanDraught = useCallback((): string | number => {
    return calculateMeanDraught(draughtAft, draughtFore)
  }, [draughtAft, draughtFore])

  const hasRouteChangeEvent = (report?.bridge.events || [])
    .concat(report?.engineRoom.events || [])
    .some((event) => !!event.metadata?.remainingDistanceToPilotStation)

  const disableVoyage = useCallback(
    (): boolean => !isVoyageEditable(report!, reports!),
    [report, reports],
  )

  const toggleCustomArrival = () => {
    setFormUseCustomArrival(!useCustomArrival)
    void setFieldValue('bridge.alternativeArrivalTerminal.code', null, false)
    void setFieldValue('bridge.alternativeArrivalTerminal.name', null, false)
    void setFieldValue('bridge.arrivalTerminal.code.value', null, false)
    void setFieldValue('bridge.arrivalTerminal.name.value', null, false)
  }

  const toggleCustomDeparture = () => {
    setFormUseCustomDeparture(!useCustomDeparture)
    void setFieldValue('bridge.alternativeDepartureTerminal.code', null, false)
    void setFieldValue('bridge.alternativeDepartureTerminal.name', null, false)
    void setFieldValue('bridge.departureTerminal.code.value', null, false)
    void setFieldValue('bridge.departureTerminal.name.value', null, false)
  }

  const renderDestinationTypeahead = (
    code: string,
    disabled: boolean,
    error: any,
    hasWarning: boolean,
    name: string,
    label: string,
  ) => {
    return (
      <FormTypeahead
        name={name}
        id={name}
        label={label}
        disabled={disabled}
        error={error}
        options={activeTerminalOptions}
        placeholder={getIn(values, code) || 'Type to search'}
        onChange={(portCode) => {
          const terminal = terminals?.find((t) => t.data.code === portCode)
          if (!terminal) return
          void setFieldValue(name, terminal.data.city.name, !!error)
          void setFieldValue(code, portCode as string, !!error)
        }}
      />
    )
  }

  const renderDestinationField = (
    disabled: boolean,
    error: any,
    hasWarning: boolean,
    name: string,
    label: string,
    placeholder?: string,
  ) => {
    return (
      <HDCInputField
        disabled={disabled}
        error={error}
        hasWarning={hasWarning}
        label={label}
        name={name}
        placeholder={placeholder}
        showSensorValue={false}
        type='text'
      />
    )
  }

  const hideDeparture = () => {
    if (
      reportType === ApiReportType.CANAL ||
      reportType === ApiReportType.DEPARTURE
    ) {
      return false
    }

    const departureTerminal = getIn(initialValues, 'bridge.departureTerminal')
    const customDepartureTerminal = getIn(
      initialValues,
      'bridge.alternativeDepartureTerminal',
    )

    return !!departureTerminal || !!customDepartureTerminal
  }

  const warnings = useMemo(
    () =>
      getFilteredByStepWarnings(report!.warnings, type!, steps[currentStep]),
    [currentStep, report, steps, type],
  )

  return (
    <>
      <Notifications
        alerts={flattenFormikErrors(
          getFilteredByStepErrors(errors, type!, steps[currentStep]),
        )}
        warnings={[...new Set(Object.values(warnings))]}
      />
      <FormSection header='Destination' description={destinationDescription}>
        <>
          <h4 className='mds-headline--x-small'>Arrival</h4>
          <SimpleFormRow>
            {useCustomArrival ? (
              <>
                {renderDestinationField(
                  disableVoyage(),
                  getIn(errors, 'bridge.alternativeArrivalTerminal'),
                  !!warnings['bridge.alternativeArrivalTerminal'],
                  'bridge.alternativeArrivalTerminal.code',
                  'Port code',
                )}
                {renderDestinationField(
                  disableVoyage(),
                  getIn(errors, 'bridge.alternativeArrivalTerminal'),
                  !!warnings['bridge.alternativeArrivalTerminal'],
                  'bridge.alternativeArrivalTerminal.name',
                  'Port name',
                )}
              </>
            ) : (
              <>
                {renderDestinationTypeahead(
                  'bridge.arrivalTerminal.code.value',
                  disableVoyage(),
                  getIn(errors, 'bridge.arrivalTerminal'),
                  !!warnings['bridge.arrivalTerminal'],
                  'bridge.arrivalTerminal.name.value',
                  'Port code',
                )}
                {renderDestinationField(
                  true,
                  getIn(errors, 'bridge.arrivalTerminal'),
                  !!warnings['bridge.arrivalTerminal'],
                  'bridge.arrivalTerminal.name.value',
                  'Port name',
                  '-',
                )}
              </>
            )}
            <McCheckbox
              fit={windowSize}
              label='Arrival port not in list'
              checked={useCustomArrival}
              disabled={disableVoyage()}
              change={toggleCustomArrival}
            />
          </SimpleFormRow>
          {!hideDeparture() && (
            <>
              <h4 className='mds-headline--x-small'>
                {reportType === ApiReportType.CANAL ? 'Canal' : 'Departure'}
              </h4>
              <SimpleFormRow>
                {useCustomDeparture ? (
                  <>
                    {renderDestinationField(
                      !!getIn(
                        initialValues,
                        'bridge.alternativeDepartureTerminal',
                      ),
                      getIn(errors, 'bridge.alternativeDepartureTerminal'),
                      !!warnings['bridge.alternativeDepartureTerminal'],
                      'bridge.alternativeDepartureTerminal.code',
                      `${
                        reportType === ApiReportType.CANAL ? 'Canal' : 'Port'
                      } code`,
                    )}
                    {renderDestinationField(
                      !!getIn(
                        initialValues,
                        'bridge.alternativeDepartureTerminal',
                      ),
                      getIn(errors, 'bridge.alternativeDepartureTerminal'),
                      !!warnings['bridge.alternativeDepartureTerminal'],
                      'bridge.alternativeDepartureTerminal.name',
                      `${
                        reportType === ApiReportType.CANAL ? 'Canal' : 'Port'
                      } name`,
                    )}
                  </>
                ) : (
                  <>
                    {renderDestinationTypeahead(
                      'bridge.departureTerminal.code.value',
                      !!getIn(initialValues, 'bridge.departureTerminal'),
                      getIn(errors, 'bridge.departureTerminal'),
                      !!warnings['bridge.departureTerminal'],
                      'bridge.departureTerminal.name.value',
                      `${
                        reportType === ApiReportType.CANAL ? 'Canal' : 'Port'
                      } code`,
                    )}
                    {renderDestinationField(
                      true,
                      getIn(errors, 'bridge.departureTerminal'),
                      !!warnings['bridge.departureTerminal'],
                      'bridge.departureTerminal.name.value',
                      `${
                        reportType === ApiReportType.CANAL ? 'Canal' : 'Port'
                      } name`,
                    )}
                  </>
                )}
                <McCheckbox
                  fit={windowSize}
                  label={`${
                    reportType === ApiReportType.CANAL ? 'Canal' : 'Departure'
                  } not in list`}
                  checked={useCustomDeparture}
                  disabled={
                    !!getIn(initialValues, 'bridge.departureTerminal') ||
                    !!getIn(
                      initialValues,
                      'bridge.alternativeDepartureTerminal',
                    )
                  }
                  change={toggleCustomDeparture}
                />
              </SimpleFormRow>
            </>
          )}
        </>
      </FormSection>
      <FormSection
        header='ETA'
        description='Your current ETA and remaining distance to pilot station at closing time for this report. If your voyage is changed please report this in the Event tab and this section will be updated.'
      >
        <SimpleFormRow>
          <FormInputDateTime
            name='bridge.eta.value'
            label='ETA to pilot station, UTC'
            min={moment(reportTimestamp?.to).utc().add(1, 'm')}
            max={moment().utc().add(1, 'year')}
            disabled={disableVoyage()}
            hasWarning={!!warnings['bridge.eta']}
            minuteSpecific
            passThroughMoment
            hideErrorMessage
          />
        </SimpleFormRow>
        <SimpleFormRow>
          {shouldCalculateRDPS(
            report!,
            reports!,
            bridge.remainingDistanceToPilotStation.isOverridden,
          ) && !adjustRDPS ? (
            <InputField
              addon={UNITS.NAUTICAL_MILE}
              disabled
              hasWarning={!!warnings['bridge.remainingDistanceToPilotStation']}
              isInvalid={
                !!errors.bridge?.remainingDistanceToPilotStation?.value
              }
              label='Remaining distance to PS'
              value={getCalculatedRDPS(values, reports!)}
            />
          ) : adjustRDPS ? (
            <HDCInputField
              name='bridge.remainingDistanceToPilotStation.value'
              addon={UNITS.NAUTICAL_MILE}
              // TODO: see how this works with brand new report
              // defaultValue={
              //   shouldCalculateRDPS(
              //     report!,
              //     reports!,
              //     bridge.remainingDistanceToPilotStation.isOverridden,
              //   )
              //     ? getCalculatedRDPS(values, reports!)
              //     : bridge.remainingDistanceToPilotStation.value || 0
              // }
              hasWarning={!!warnings['bridge.remainingDistanceToPilotStation']}
              isInvalid={
                !!errors.bridge?.remainingDistanceToPilotStation?.value
              }
              label='Remaining distance to PS'
              note={`Previous report value was: ${report?.bridge.remainingDistanceToPilotStationOriginal.value}`}
              type='number'
              decimals={2}
            />
          ) : (
            <HDCInputField
              addon={UNITS.NAUTICAL_MILE}
              decimals={2}
              disabled={disableVoyage()}
              hasWarning={!!warnings['bridge.remainingDistanceToPilotStation']}
              label='Remaining distance to PS'
              name='bridge.remainingDistanceToPilotStation.value'
              type='number'
            />
          )}
          <div>
            <InputField
              value={
                convertMinutes(report?.bridge.remainingTimeToPilotStation.value)
                  .hours
              }
              label='Remaining time'
              addon='hr'
              disabled
            />
            <InputField
              addon='mins'
              value={
                convertMinutes(report?.bridge.remainingTimeToPilotStation.value)
                  .minutes
              }
              label='&nbsp;'
              disabled
            />
          </div>
        </SimpleFormRow>
        {disableVoyage() && !hasRouteChangeEvent && (
          <SimpleFormRow>
            <McCheckbox
              fit={windowSize}
              hint='e.g. due to small traffic deviations. Route changes should still be reported on the Events tab and not as an adjustment.'
              checked={adjustRDPS}
              change={() => setAdjustRDPS(!adjustRDPS)}
            >
              <span slot='label'>
                Adjust <em>Remaining distance to PS</em>
              </span>
            </McCheckbox>
          </SimpleFormRow>
        )}
      </FormSection>
      <FormSection
        header='Distance & speed'
        description='Please enter/confirm the logged and observed distance for this report period.'
      >
        <SimpleFormRow>
          <HDCInputField
            addon={UNITS.NAUTICAL_MILE}
            decimals={2}
            hasWarning={!!warnings['bridge.distanceLogged']}
            label='Distance logged'
            name='bridge.distanceLogged.value'
            placeholder='Distance logged'
            type='number'
          />
          <InputField
            addon={UNITS.KNOTS_ABR}
            disabled
            hasWarning={!!warnings['bridge.speedLogged']}
            label='Speed logged'
            value={distanceAndSpeedObservations(
              bridge.distanceLogged,
              mainEngines,
            )}
          />
        </SimpleFormRow>
        <SimpleFormRow>
          <HDCInputField
            addon={UNITS.NAUTICAL_MILE}
            decimals={2}
            hasWarning={!!warnings['bridge.distanceObserved']}
            label='Distance observed'
            name='bridge.distanceObserved.value'
            placeholder='Distance observed'
            type='number'
          />
          <InputField
            addon={UNITS.KNOTS_ABR}
            disabled
            hasWarning={!!warnings['bridge.speedObserved']}
            label='Speed observed'
            value={distanceAndSpeedObservations(
              bridge.distanceObserved,
              mainEngines,
            )}
          />
        </SimpleFormRow>
      </FormSection>
      <FormSection
        header='Draught & seawater'
        description={
          reportType === ApiReportType.SEA || reportType === ApiReportType.CANAL
            ? `Please enter/confirm draught fore and aft.
              Sea water temperature and water depth should be the estimated
              average for the report period.`
            : `Please enter visual draught fore and
              aft @ arrival / departure. Sea water temperature and water depth
              should be the estimated average for the report period.`
        }
      >
        <SimpleFormRow>
          <HDCInputField
            addon={UNITS.METER}
            decimals={2}
            hasWarning={!!warnings['bridge.draughtFore']}
            label={`${
              reportType === ApiReportType.DEPARTURE
                ? 'Visual draught'
                : 'Draught'
            } fore`}
            name='bridge.draughtFore.value'
            placeholder='Draught Fore'
            type='number'
          />
          <HDCInputField
            addon={UNITS.METER}
            decimals={2}
            hasWarning={!!warnings['bridge.draughtAft']}
            label={`${
              reportType === ApiReportType.DEPARTURE
                ? 'Visual draught'
                : 'Draught'
            } aft`}
            name='bridge.draughtAft.value'
            placeholder='Draught aft'
            type='number'
          />
          <InputField
            value={getMeanDraught()}
            placeholder='-'
            addon={UNITS.METER}
            label='Mean draught'
            disabled
          />
        </SimpleFormRow>
        <SimpleFormRow>
          <HDCInputField
            addon={UNITS.METRIC_TON}
            hasWarning={!!warnings['bridge.ballastWater']}
            label='Ballast water'
            name='bridge.ballastWater.value'
            placeholder='Ballast water'
            type='number'
          />
          <HDCInputField
            addon={UNITS.TEMPERATURE}
            decimals={1}
            hasWarning={!!warnings['bridge.waterTemperature']}
            label='Water temperature'
            name='bridge.waterTemperature.value'
            placeholder='Water temperature'
            type='number'
          />
          <HDCInputField
            addon={UNITS.METER}
            decimals={1}
            hasWarning={!!warnings['bridge.waterDepth']}
            label='Water depth'
            name='bridge.waterDepth.value'
            placeholder='Water depth'
            type='number'
          />
        </SimpleFormRow>
      </FormSection>
      <FormSection header='Report comments'>
        <SimpleFormRow>
          <HDCTextareaField
            name='bridge.comment'
            label='Additional reporting comments'
          />
        </SimpleFormRow>
      </FormSection>
      {isSubmitting && <OverlayLoader padding='0px' />}
    </>
  )
}

export default VoyageStep
