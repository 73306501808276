import { Period } from '../../types'
export const defaultPeriod: Period = {
  periodStart: {
    energy: 0,
    date: '',
    dateFormat: {
      dateValue: '',
      hoursValue: 0,
      minutesValue: 0,
    },
  },
  periodEnd: {
    energy: 0,
    date: '',
    dateFormat: {
      dateValue: '',
      hoursValue: 0,
      minutesValue: 0,
    },
  },
  totalEnergy: 0,
  totalDays: 0,
  totalHoursMinutes: '',
}

export const SHORE_POWER = {
  MAX_PERIOD_COUNT: 5,
}
