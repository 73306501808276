import { useContext } from 'react'
import { useField } from 'formik'
import { McRadio, McRadioGroup } from '@maersk-global/mds-react-wrapper'

import { WindowContext } from '../../../../contexts'

type Props<T> = {
  name: string
  label: string
  options: Array<{
    label: string
    value: T
  }>
  onChange?: (value: T) => void
}

const MfeInputRadioGroup = <T extends string | boolean>({
  name,
  label,
  options,
  onChange,
}: Props<T>) => {
  const { windowSize } = useContext(WindowContext)
  const [field, meta, helpers] = useField(name)

  const handleChange = (value: T) => async (_: CustomEvent) => {
    await helpers.setValue(value)
    onChange?.(value)
  }

  return (
    <McRadioGroup
      fit={windowSize}
      orientation='horizontal'
      legend={label}
      invalid={Boolean(meta.error)}
      errormessage={meta.error}
    >
      {options.map((option) => (
        <McRadio
          key={option.value.toString()}
          fit={windowSize}
          label={option.label}
          value={option.value.toString()}
          checked={field.value === option.value}
          change={handleChange(option.value)}
        />
      ))}
    </McRadioGroup>
  )
}

export default MfeInputRadioGroup
