import { type FieldProps } from 'formik'
import { type Moment } from 'moment'
import styled from 'styled-components'

import { DateTimeInput } from './DateTimeInput'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const Label = styled.p`
  margin: 4px 0 8px;
  font-size: 14px;
`

interface Props extends FieldProps {
  id?: string
  disabled?: boolean
  onChange?: (value) => void
  minDate?: Moment
  maxDate?: Moment
  minuteSpecific?: boolean
  label?: string
  error?: string | undefined
}

export const FormDateTimeInput = ({
  field,
  form,
  disabled,
  onChange,
  minDate,
  maxDate,
  minuteSpecific,
  label,
  error,
  id,
}: Props) => {
  const { touched, errors } = form
  const hasError = !!error || (!!touched[field.name] && !!errors[field.name])

  return (
    <Wrapper>
      <DateTimeInput
        id={id}
        selected={field.value ? field.value : null}
        minuteSpecific={minuteSpecific ?? true}
        disabled={disabled}
        error={hasError}
        minDate={minDate}
        maxDate={maxDate}
        onBlur={() => form.setFieldTouched(field.name)}
        onChange={async (value: Moment) => {
          if (onChange) {
            await onChange(value)
          }
          form.setFieldValue(field.name, value)
        }}
      />
      <Label>{label}</Label>
    </Wrapper>
  )
}
