import * as React from 'react'
import { isShoreContext } from '../utils'
import { Performance } from '../api-models'
import { displayErrorModal } from '../utils/modal-utils'
import { getVesselConnectionStatuses } from '../services/performance'

export interface ConnectionStatuses {
  [imoNumber: number]: Performance.Status.VesselConnectionStatus
}

interface IConnectionStatusesContext {
  seconds: number
  connectionStatuses?: ConnectionStatuses
}

export const ConnectionStatusesContext =
  React.createContext<IConnectionStatusesContext>({
    seconds: 0,
    connectionStatuses: undefined,
  })

interface ConnectionStatusesProviderProps {
  children?: React.ReactNode
}

interface ConnectionStatusesProviderState {
  seconds: number
  connectionStatuses?: ConnectionStatuses
  secondsCounterInterval?: any
  fetchConnectionStatusInterval?: any
}

export class ConnectionStatusesProvider extends React.Component<
  ConnectionStatusesProviderProps,
  ConnectionStatusesProviderState
> {
  constructor(props: ConnectionStatusesProviderProps) {
    super(props)
    this.state = {
      seconds: 0,
      secondsCounterInterval: undefined,
      fetchConnectionStatusInterval: undefined,
    }
  }

  componentDidMount() {
    if (isShoreContext()) {
      this.getConnectionStatuses()
      const fetchConnectionStatusInterval = setInterval(() => {
        this.getConnectionStatuses()
      }, 600000)
      this.setState({
        fetchConnectionStatusInterval: fetchConnectionStatusInterval,
      })
    }
  }

  componentWillUnmount() {
    clearInterval(this.state.secondsCounterInterval)
    clearInterval(this.state.fetchConnectionStatusInterval)
    this.setState({
      secondsCounterInterval: undefined,
      fetchConnectionStatusInterval: undefined,
    })
  }

  getConnectionStatuses() {
    getVesselConnectionStatuses()
      .then((json: Performance.Status.VesselConnectionStatus[]) => {
        const connectionStatuses = {} as ConnectionStatuses
        json.forEach(
          (status: Performance.Status.VesselConnectionStatus) =>
            (connectionStatuses[status.vessel.imoNo] = status),
        )
        // Resetting the second interval function
        clearInterval(this.state.secondsCounterInterval)
        const secondsCounterInterval = setInterval(() => {
          this.setState({
            seconds: this.state.seconds + 1,
          })
        }, 1000)
        this.setState({
          connectionStatuses: connectionStatuses,
          seconds: 0,
          secondsCounterInterval: secondsCounterInterval,
        })
      })
      .catch((e) => displayErrorModal(e))
  }

  public render() {
    return (
      <ConnectionStatusesContext.Provider value={this.state}>
        {this.props.children}
      </ConnectionStatusesContext.Provider>
    )
  }
}
