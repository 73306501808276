import { createColumnHelper } from '@tanstack/react-table'
import moment from 'moment'
import { McIcon } from '@maersk-global/mds-react-wrapper'

import { type IReport } from '../../../../api-models/hdc/report'
import {
  Header,
  IconCell,
  NumericCell,
  NumericHeader,
} from '../../../../components/Table/Table.styles'
import { isReportEditable, prettyDuration } from '../../utils'
import { REPORT_STATUS_MAP, ReportTypeNames } from '../../constants'
import { StatusTypeEnum } from './ReportStatus.consts'
import { ReportStatus } from './ReportStatus'
import { ReportsTableActionMenu } from './ReportsTableActionMenu'

const columnHelper = createColumnHelper<IReport>()

export const columns = [
  columnHelper.accessor('seqNo', {
    header: () => <NumericHeader leftAlignText>No.</NumericHeader>,
    cell: (props) => (
      <NumericCell leftAlignText>{props.getValue()}</NumericCell>
    ),
    enableSorting: false,
  }),
  columnHelper.accessor('periodEnd', {
    header: 'Reporting period (UTC)',
    cell: (props) => moment.utc(props.getValue()).format(`DD MMM YYYY HH:mm`),
    enableSorting: false,
  }),
  columnHelper.display({
    id: 'duration',
    header: 'Report duration (dd:hh:mm)',
    cell: ({ row }) =>
      prettyDuration(
        moment.utc(row.original.periodStart),
        moment.utc(row.original.periodEnd),
      ),
    enableSorting: false,
  }),
  columnHelper.accessor('reportType', {
    header: 'Report type',
    cell: (props) => `${ReportTypeNames[props.getValue()]}`,
    enableSorting: false,
  }),
  columnHelper.display({
    id: 'port-code-from',
    header: 'Port code: From',
    cell: ({ row }) => {
      const value = getDepartureTerminal(row.original)
      return value ? <span title={value.code ?? ''}>{value.name}</span> : ''
    },
    enableSorting: false,
  }),
  columnHelper.display({
    id: 'port-code-to',
    header: 'Port code: To',
    cell: ({ row }) => {
      const value = getArrivalTerminal(row.original)
      return value ? <span title={value.code ?? ''}>{value.name}</span> : ''
    },
    enableSorting: false,
  }),
  columnHelper.accessor('eventsCount', {
    header: () => <NumericHeader>Events</NumericHeader>,
    cell: (props) => <NumericCell>{props.getValue() || undefined}</NumericCell>,
    enableSorting: false,
  }),
  columnHelper.accessor('bridge.comment', {
    header: () => <Header icon>Comments</Header>,
    cell: (props) =>
      props.getValue() ? (
        <IconCell>
          <McIcon icon='comments' />
        </IconCell>
      ) : null,
    enableSorting: false,
  }),
  columnHelper.display({
    id: 'status',
    header: () => <Header icon>Status</Header>,
    cell: ({ table, row }) => {
      const value = getReportStatus(
        row.original,
        table.getCoreRowModel().rows.map((row) => row.original),
      )
      return (
        <IconCell>
          <ReportStatus status={value} id={+row.id} />
        </IconCell>
      )
    },
    enableSorting: false,
  }),
  columnHelper.display({
    id: 'actions',
    header: () => <Header icon>Actions</Header>,
    cell: ({ row }) => <ReportsTableActionMenu report={row.original} />,
    enableSorting: false,
  }),
]

const getDepartureTerminal = (report: IReport) => {
  if (!report.bridge) return

  const { alternativeDepartureTerminal, departureTerminal } = report.bridge

  return alternativeDepartureTerminal?.code &&
    alternativeDepartureTerminal?.name
    ? alternativeDepartureTerminal
    : {
        code: departureTerminal?.code.value,
        name: departureTerminal?.name.value,
      }
}

const getArrivalTerminal = (report: IReport) => {
  if (!report.bridge) return

  const { arrivalTerminal, alternativeArrivalTerminal } = report.bridge
  return alternativeArrivalTerminal?.code && alternativeArrivalTerminal?.name
    ? alternativeArrivalTerminal
    : {
        code: arrivalTerminal?.code.value,
        name: arrivalTerminal?.name.value,
      }
}

const getReportStatus = (report: IReport, reports: Array<IReport>) => {
  const status =
    REPORT_STATUS_MAP[report.bridge.state + report.engineRoom.state]

  if (status === StatusTypeEnum.DONE && isReportEditable(report, reports!)) {
    return StatusTypeEnum.EDITABLE
  }

  if (!status) {
    return StatusTypeEnum.UNKNOWN
  }

  return status
}
